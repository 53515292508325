import { ContainerName, useAppSelector, useDialog, useDialogWithParameter, useMemberClan, useTournamentParticipant } from 'components/_hooks'
import { ConfirmActionModal, DropDownTable, LoadingBox } from 'components/_shared'
import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useDeleteLineUp, useReloadLineUp } from 'store/logic/line-ups-logic'
import { useCancelParticipationByOrganizer, useDeleteParticipant } from 'store/logic/participants-logic'
import { getAppUser } from 'store/selectors/app-selectors'
import { getParticipantLineUps, makeGetParticipantLineUps } from 'store/selectors/line-ups-selectors'
import { ApplicationState } from 'store/types/common'
import LineUpRow from './LineUpRow'
import styles from './participants.module.scss'
import ParticipantOnlineAvatar from 'components/_shared/ParticipantAvatar/ParticipantOnlineAvtar'
import { makeGetTournament } from 'store/selectors/tournaments-selectors'
import { TournamentViewModel_kl } from 'models/tournament-viewmodel'
import ClanMemberSignUpModal from 'components/_pages/common/TournamentDetailRegistration/ClanMemberSignUpModal/ClanMemberSignUpModal'
import { ClanMemberData, TournamentClanSignUpModel } from 'models/clan-member-view-model'
import { makeGetClanMembersIds } from 'store/selectors/clan-member-selectors'
import { makeGetUsers } from 'store/selectors/user-selectors'
import { getIsAdmin, getIsOrganizer, PublicUserType } from 'store/types/user-types'
import { DeleteTournamentChat } from 'store/chat/chat/chat-logic'
import { AlertModal } from 'components/_modals'
import { useReloadPendingMatches } from 'store/logic/match-logic'
import { TournamentUtils } from 'server/utils/tournament-utils'
import { TournamentModeEnum } from 'consts'
import { EventTypeEnum } from 'models/tournament-models'

interface Props {
  participantId: number
  isPending: boolean
  tournament: TournamentViewModel_kl
  reloadTournament: (tournamentId: number) => void
  openActionCompletedModal : (message: string) => void
}

const ParticipantRow = ({ participantId, isPending,tournament,reloadTournament,openActionCompletedModal }: Props) => {

  const user = useSelector(getAppUser)
  const {clan, isClanLeader} = useMemberClan(user?.Id)
  const [opened, setOpened] = React.useState<boolean>(false)
  const isLineUpLoading = false
  const isOrganizer = getIsOrganizer(user?.Role) || getIsAdmin(user?.Role)

  const { name, participant,clanLeaderId,playerId,IsBot } = useTournamentParticipant(participantId)
  const deleteParticipant = useDeleteParticipant()
  const cancelParticipation = useCancelParticipationByOrganizer()
  const dispatch = useDispatch()
  const reloadPendingMatches = useReloadPendingMatches()
  
  const totalMembers = tournament?.PlayersPerClan+(tournament?.NumberOfSubstitutes==null?0:tournament?.NumberOfSubstitutes)
  const getClanMember = useMemo(makeGetClanMembersIds, [])
  const clanMember = useSelector((state: ApplicationState) => getClanMember(state, { clanId: clan?.Id }))
  const getUsers = useMemo(makeGetUsers, [])
  
  const clanMemberUsers = useAppSelector(state => getUsers(state, clanMember? clanMember:[]))
  const handleConfirmParticipantDelete = async () => {
    const userId = (clanLeaderId ? clanLeaderId : playerId)
    await handleParticipantLeave(userId)
    //return await deleteParticipant(participant.TournamentDetailId, participant.Id)
  }
  
  const handleParticipantLeave = async (userId:number) => {
    try {
      let isClanMember = false
      await cancelParticipation(tournament.Id, userId)
      await reloadTournament(tournament.Id)
      openActionCompletedModal('Cancel participation from tournament successfully.')
      void reloadPendingMatches()
    }
    catch (e) {
      openActionCompletedModal(e.message)
    }
  }


  const [deleteParticipantModal, openDeleteParticipantModal] = useDialog(closeDialog => (
    <ConfirmActionModal onConfirm={handleConfirmParticipantDelete} onClose={closeDialog} text={clanLeaderId? 'Are you sure you want to remove this clan?':'Are you sure you want to remove this participant?'} buttonText="Confirm" buttonColor="red" />
  ))
  const closeModal=()=>{

  }
  const ClanMembersSignUpModal = React.memo(ClanMemberSignUpModal);
  const [ClanMemberModal, openClanMemberModal] = useDialogWithParameter<TournamentClanSignUpModel>((clanMemberUsers, closeDialog) => (
    <ClanMembersSignUpModal
      closeDialog={closeDialog}
      clanMemberUsers={clanMemberUsers}
      close={closeModal}
    />
  ))

  const handleOpenButtonClick = () => setOpened(!opened)

  const onDeleteParticipant = () => openDeleteParticipantModal()

  const canRemoveParticipant = isPending && user && ((user.Role == 'Organizer' || user.Role == 'SuperAdmin' || user.Role == 'StuffAdmin')
  || participant.ClanId && isClanLeader && clan && clan.Id == participant.ClanId
  )
  let lineUps = useSelector((state: ApplicationState) => getParticipantLineUps(state, participantId))
  let ClanMemberlineUps=lineUps.filter(x=>x.IsDelete !== true)
  const canDeleteMember = isPending && user && (
    (user.Role == 'Organizer' || user.Role == 'SuperAdmin' || user.Role == 'StuffAdmin')
    ||  participant.ClanId && isClanLeader && clan && clan.Id == participant.ClanId)

  const removeLineUp = useDeleteLineUp()
  const reloadLineUp = useReloadLineUp()
  const onConfirmDeleteMember = async (id: number) => {
 
     await removeLineUp(id)
     await reloadLineUp(tournament.Id)
     return true
  }
  const onAddClanLineUpMember=async()=>{
    let TournamentClanSignUpModel:TournamentClanSignUpModel = Object()
    TournamentClanSignUpModel.tournamentId = tournament.Id
    TournamentClanSignUpModel.clanId = (isOrganizer ? playerId as number : clan.Id) 
    TournamentClanSignUpModel.SubstituteCount = Number(tournament.NumberOfSubstitutes==null?0:tournament.NumberOfSubstitutes)
    TournamentClanSignUpModel.TotalPlayers = tournament.PlayersPerClan + (Number(tournament.NumberOfSubstitutes==null?0:tournament.NumberOfSubstitutes))
    TournamentClanSignUpModel.IsAddOnlyClanMember = true
    TournamentClanSignUpModel.isPrivate = isOrganizer ? isOrganizer : false
    
    let clanMemberData:ClanMemberData[]=[]
  //     clanMemberData=clanMemberUsers.map(function(value,index){
  //     return {
  //       Username:value.Username,
  //       PlayerId : value.Id,
  //       IsSubstitute : ClanMemberlineUps.filter(x=>x.PlayerId==value.Id).length > 0?lineUps.filter(x=>x.PlayerId==value.Id)[0].IsSubstitute:false,
  //       IsClanLeader:value.Id === user.Id?true:false,
  //       IsSelected:ClanMemberlineUps.filter(x=>x.PlayerId==value.Id).length > 0?true:false,
  //       IsDisabled:ClanMemberlineUps.filter(x=>x.PlayerId==value.Id).length > 0?true:false,
  //       IsDefault:ClanMemberlineUps.filter(x=>x.PlayerId==value.Id).length > 0?ClanMemberlineUps.filter(x=>x.PlayerId==value.Id)[0].IsDefault:false
  //     }
  //   })
    const resultClanMembers = await TournamentUtils.GetTournamentClanMembes(isOrganizer ? playerId : clan.Id)
    if (!resultClanMembers?.isError) {
      const data = resultClanMembers?.data
              const users = data?.Model.Users
              clanMemberData=users.map(function(value,index){
                return {
                  Username:value.Username,
                  PlayerId : value.Id,
                  IsSubstitute : ClanMemberlineUps.filter(x=>x.PlayerId==value.Id).length > 0?lineUps.filter(x=>x.PlayerId==value.Id)[0].IsSubstitute:false,
                  IsClanLeader: isOrganizer ? (value.Id ===clanLeaderId ? true:false) : (value.Id === user.Id?true:false),
                  IsSelected:ClanMemberlineUps.filter(x=>x.PlayerId==value.Id).length > 0?true:false,
                  IsDisabled:ClanMemberlineUps.filter(x=>x.PlayerId==value.Id).length > 0?true:false,
                  IsDefault:ClanMemberlineUps.filter(x=>x.PlayerId==value.Id).length > 0?ClanMemberlineUps.filter(x=>x.PlayerId==value.Id)[0].IsDefault:false
                }
              })
    }
    TournamentClanSignUpModel.clanMemberData = clanMemberData as ClanMemberData[]         
    openClanMemberModal(TournamentClanSignUpModel as TournamentClanSignUpModel)
  }
  const [deleteMemberModal, openDeleteMembertModal] = useDialogWithParameter<number>((lineUpId, closeDialog) => (
    <ConfirmActionModal
      onConfirm={() => onConfirmDeleteMember(lineUpId)}
      onClose={closeDialog}
      text={'Are you sure you want to remove this member?'}
      buttonText="Confirm"
      buttonColor="red"
    />
  ))

  const isShowRemoveButton = tournament.EventTypeId !== EventTypeEnum.Main ? canRemoveParticipant : (isPending && user && isOrganizer && participant?.IsPrivate)
  const isShowLineUpRemoveButton = tournament.EventTypeId !== EventTypeEnum.Main ? canDeleteMember : (canDeleteMember && participant?.IsPrivate)

  
  return (
    
    <DropDownTable.Row>
      <DropDownTable.MainRow opened={opened}>
        <DropDownTable.RowColumn>
          <div className="align-items-center m-0 parti-user">
            <ParticipantOnlineAvatar
              parentClassName={null}
              withLink={false}
              participantId={participantId}
              className="article__participant-picture"
              containerName={ContainerName.AvatarsNormal}
            />
            <div className={styles.article__participantUsername}>
              {!IsBot ?name:'By-Pass'}
              { isOrganizer && participant?.IsPrivate === true ? ' (Private)' :'' }
            </div>
            
          </div>
        </DropDownTable.RowColumn>
        
        <DropDownTable.RowColumn>
          <div className="justify-content-sm-end m-0 party-btn">
          {isPending && user && (isClanLeader && participant.ClanId == clan.Id || isOrganizer) && ClanMemberlineUps.length > 1 && ClanMemberlineUps.length !== totalMembers && <DropDownTable.Button onClick={onAddClanLineUpMember} color="white" hoverColor="redHover" className={styles.delete__button}>
                Add Member
              </DropDownTable.Button>}
            { isShowRemoveButton  && (
              <DropDownTable.Button onClick={onDeleteParticipant} color="white" hoverColor="redHover" className={styles.delete__button}>
                Remove
              </DropDownTable.Button>
            )}
            {participant?.ClanId && !IsBot && (
              <DropDownTable.OpenButton onClick={handleOpenButtonClick} opened={opened} className={styles.openTableButton} />
            )}
          </div>
        </DropDownTable.RowColumn>
      </DropDownTable.MainRow>
      {!IsBot && <DropDownTable.SecondaryRowContainer opened={opened}>
        <LoadingBox size="average" loading={isLineUpLoading}>
          <>
            {ClanMemberlineUps.map(lineUp => (
              <LineUpRow key={lineUp.Id} canDeleteMember={isShowLineUpRemoveButton} lineUp={lineUp} onDelete={openDeleteMembertModal} />
            )
            )}
          </>
        </LoadingBox>
      </DropDownTable.SecondaryRowContainer>}
      {deleteParticipantModal}
      {deleteMemberModal}
      {ClanMemberModal}
    </DropDownTable.Row>
    
  )
}

export default ParticipantRow
