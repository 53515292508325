import { useCanManageMatch, useDialog, useMatch, useMatchParticipant } from 'components/_hooks'
import { Button } from 'components/_shared/Button'
import { Option, optionLabelExtractor, optionValueExtractor, Select } from 'components/_shared'
import { isTwoPlayersStructure } from 'consts'
import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeGetMatchParticipantsIds } from 'store/selectors/match-participant-selectors'
import { getPlaceByMatchParticipantId, makeGetPlace } from 'store/selectors/pages/tournament-bracket-page-selectors'
import { ApplicationState } from 'store/types/common'
import { placeChanged } from 'store/types/pages/tournament-bracket-types'
import { getOrdinalNumber } from 'utils/numberUtils'
import { WinnerModal } from '../WinnerModal/WinnerModal'

type PlaceOption = Option<number>

const getPlaceLabel = (place: number) => {
  return getOrdinalNumber(place)
}

interface PlaceSelectorProps {
  matchId: number
  matchParticipantId?: number
}

export const PlaceSelector = ({ matchId, matchParticipantId }: PlaceSelectorProps) => {
  const dispatch = useDispatch()
  const getPlace = useMemo(makeGetPlace, [])
  const getMatchParticipantsIds = useMemo(makeGetMatchParticipantsIds, [])
  const matchParticipantsIds = useSelector((state: ApplicationState) => getMatchParticipantsIds(state, matchId))
  const placeByMatchParticipantId = useSelector(getPlaceByMatchParticipantId)
  const place = useSelector((state: ApplicationState) => getPlace(state, { matchParticipantId }))
  const { tournament } = useMatch(matchId)
  const canManageBracket = useCanManageMatch(matchId)
  const { matchParticipant } = useMatchParticipant(matchParticipantId)

  const matchParticipantIdByPlace = useMemo<{ [place: number]: number }>(() => {
    return matchParticipantsIds.reduce((a, matchParticipantsId) => {
      const place = placeByMatchParticipantId[matchParticipantsId]
      if (place) {
        a[place] = matchParticipantsId
      }
      return a
    }, {})
  }, [matchParticipantsIds, placeByMatchParticipantId])

  const placeOption = useMemo<PlaceOption>(() => {
    if (!place) {
      return undefined
    }
    return {
      label: getPlaceLabel(place),
      value: place,
    }
  }, [place])
  const places = useMemo<PlaceOption[]>(() => {
    return matchParticipantsIds
      .map((_, index) => {
        const place = index + 1
        return {
          value: place,
          label: getPlaceLabel(place),
        }
      })
  }, [matchParticipantsIds])
  const isWinner = matchParticipant?.Place === 1

  const tooltipExtractor = (option: PlaceOption) => {
    const matchParticipantIdOnPlace = matchParticipantIdByPlace[option.value]
    if (!matchParticipantIdOnPlace || matchParticipantIdOnPlace === matchParticipantId) {
      return undefined
    }

    const ordinalNumber = getOrdinalNumber(matchParticipantsIds.indexOf(matchParticipantIdOnPlace) + 1)

    return (
      <>
        {getPlaceLabel(option.value)} place has already gone to {ordinalNumber} player
      </>
    )
  }

  const handleWinnerClick = () => {
    openWinnerModal()
  }

  const handlePlaceChange = (option: PlaceOption) => {
    const value = option?.value
    const matchParticipantIdOnPlace = value && matchParticipantIdByPlace[value]
    if (!matchParticipantIdOnPlace || matchParticipantIdOnPlace === matchParticipantId) {
      dispatch(placeChanged({ matchParticipantId, place: value }))
    }
  }

  const [winnerModal, openWinnerModal] = useDialog(closeDialog => (
    <WinnerModal
      onClose={closeDialog}
      matchParticipantId={matchParticipantId}
      matchId={matchId}
    />
  ))

  return (
    <>
      {isWinner && (
        <div className="eli_usr_r">
          <span className="eli_usr_place eli_usr_place-1">
            <span>winner</span>
          </span>
        </div>
      )}
      {canManageBracket &&
        <div className="eli_usr_r">
          {isTwoPlayersStructure(tournament?.TournamentStructureId)
            ? <Button className="btn-winnr open_modal_winner" onClick={handleWinnerClick}>Winner</Button>
            : (
              <Select
                type="single"
                options={places}
                labelExtractor={optionLabelExtractor}
                valueExtractor={optionValueExtractor}
                selected={placeOption}
                onChange={handlePlaceChange}
                placeHolder="-"
                alwaysOpenBelow={false}
                tooltipExtractor={tooltipExtractor}
                optionTooltipIcon="info"
                tipPlace="top-center"
                containerClassName="eliminationBracketSelectContainer"
                className="eliminationBracketSelect"
              />
            )
          }
        </div>
      }
      {winnerModal}
    </>
  )
}
