import { Reducer } from 'redux'
import { KnownAction, State } from 'store/types/match-link-types'
import { arrayToObject, groupArrayToObject } from 'utils'

export const initialMatchLinksState: State = {
  matchLinkIdsByMatchId: {},
  matchLinks: {},
  approvedMatchLinkCount: 0,
  pendingMatchListCount: 0,
  pendingMatchLinkIds: [],
  approvedMatchLinksIds: [],
}

const reducer: Reducer<State, KnownAction> = (state: State | undefined = initialMatchLinksState, action: KnownAction) => {
  switch (action.type) {
    case 'MATCH_LINKS_LOADED': {
      return {
        ...state, 
        matchLinks: {
          ...state.matchLinks,
          ...arrayToObject(action.payload.matchLinks, x => x.Id),
        },
        matchLinkIdsByMatchId: {
          ...state.matchLinkIdsByMatchId,
          [action.payload.matchId]: action.payload.matchLinks.map(ml => ml.Id),
        }, 
      }
    }
    case 'MATCH_LINK_ADDED': {
      return {
        ...state,
        matchLinks: {
          ...state.matchLinks,
          [action.payload.matchLink.Id]: action.payload.matchLink,
        },
        matchLinkIdsByMatchId: {
          ...state.matchLinkIdsByMatchId,
          [action.payload.matchLink.MatchId]: state.matchLinkIdsByMatchId[action.payload.matchLink.MatchId]
            ? [
              ...state.matchLinkIdsByMatchId[action.payload.matchLink.MatchId].filter(
                s => s != action.payload.matchLink.Id
              ),
              action.payload.matchLink.Id,
            ]
            : [action.payload.matchLink.Id],   
        },
      }
    }
    case 'MATCH_LINK_PUBLISHED':{
      return {
        ...state,
        matchLinks: {
          ...state.matchLinks,
          [action.payload.matchLink.Id]: action.payload.matchLink,
        },
        matchLinkIdsByMatchId: {
          ...state.matchLinkIdsByMatchId,
          [action.payload.matchLink.MatchId]: state.matchLinkIdsByMatchId[action.payload.matchLink.MatchId]
            ? [
              ...state.matchLinkIdsByMatchId[action.payload.matchLink.MatchId].filter(
                s => s != action.payload.matchLink.Id
              ),
              action.payload.matchLink.Id,
            ]
            : [action.payload.matchLink.Id],   
        },
        pendingMatchLinkIds: state.pendingMatchLinkIds.filter(m => m != action.payload.matchLink.Id),
        approvedMatchLinkIds: [action.payload.matchLink.Id, ...state.approvedMatchLinksIds],
        approvedMatchLinkCount: state.approvedMatchLinkCount + 1,
        pendingMatchLinkCount: state.approvedMatchLinkCount - 1,
      }
    }
    case 'MATCH_LINK_DELETED': {
      return {
        ...state,
        matchLinks: {
          ...state.matchLinks,
          [action.payload.matchLink.Id]: undefined,
        },
        matchLinkIdsByMatchId: {
          ...state.matchLinkIdsByMatchId,
          [action.payload.matchLink.MatchId]: state.matchLinkIdsByMatchId[action.payload.matchLink.MatchId]
            ? [
              ...state.matchLinkIdsByMatchId[action.payload.matchLink.MatchId].filter(
                s => s != action.payload.matchLink.Id
              ),
            ]
            : [],   
        },
        pendingMatchLinkIds: state.pendingMatchLinkIds.filter(m => m != action.payload.matchLink.Id),
        approvedMatchLinkIds: state.approvedMatchLinksIds.filter(m => m != action.payload.matchLink.Id),
        approvedMatchLinkCount: state.approvedMatchLinkCount - 1,
        pendingMatchLinkCount: state.approvedMatchLinkCount - 1,
      }
    }
    case 'TOURNAMENT_MATCH_LINKS_LOADED': {
      return {
        ...state,
        matchLinks: {
          ...state.matchLinks,
          ...arrayToObject(action.payload.matchLinks, x => x.Id),
        },
        matchLinkIdsByMatchId: {
          ...state.matchLinkIdsByMatchId,
          ...groupArrayToObject(action.payload.matchLinks, x => x.MatchId, x => x.Id),
        },
      }
    }    
    case 'PENDING_MATCH_LINKS_LOADED': {
      return {
        ...state,
        matchLinks: {
          ...state.matchLinks,
          ...arrayToObject(action.payload.MatchLinks, x => x.Id),
        },
        matchLinkIdsByMatchId: {
          ...state.matchLinkIdsByMatchId,
          ...groupArrayToObject(action.payload.MatchLinks, x => x.MatchId, x => x.Id),
        },
        pendingMatchListCount: action.payload.AllMatchLinksCount,
        pendingMatchLinkIds: action.payload.MatchLinks.map(ml => ml.Id),
      }
    }
    case 'APPROVED_MATCH_LINKS_LOADED': {
      return {
        ...state,
        matchLinks: {
          ...state.matchLinks,
          ...arrayToObject(action.payload.MatchLinks, x => x.Id),
        },
        matchLinkIdsByMatchId: {
          ...state.matchLinkIdsByMatchId,
          ...groupArrayToObject(action.payload.MatchLinks, x => x.MatchId, x => x.Id),
        },
        approvedMatchLinkCount: action.payload.AllMatchLinksCount,
        pendingMatchLinkIds: action.payload.MatchLinks.map(ml => ml.Id),
      }
    }    
    default:
      return state
  }
}


export default reducer
