import React, { useEffect, useMemo } from 'react'
import { loadUserGames, useAddUserGame, useRemoveUserGame } from 'store/logic/game-logic'
import { useAppUser } from 'components/_hooks'
import { Button } from 'components/_shared'
import styles from '../GameTournaments.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { makeGetGamesIdsByUserId, makeGetIsAddUserGameLoading, makeGetIsRemoveUserGameLoading, makeGetIsUserGamesLoaded } from 'store/selectors/game-selectors'
import { ApplicationState } from 'store/types/common'
import { getIsPlayer } from 'store/types/user-types'

interface AddToFavoriteProps {
  gameId: number
}

export const AddToFavorite = ({ gameId }: AddToFavoriteProps) => {
  const dispatch = useDispatch()
  const appUser = useAppUser()
  const addUserGame = useAddUserGame(appUser?.Id)
  const removeUserGame = useRemoveUserGame(appUser?.Id)

  const getGamesIds = useMemo(makeGetGamesIdsByUserId, [])
  const getIsUserGamesLoaded = useMemo(makeGetIsUserGamesLoaded, [])
  const getIsAddUserGameLoading = useMemo(makeGetIsAddUserGameLoading, [])
  const getIsRemoveUserGameLoading = useMemo(makeGetIsRemoveUserGameLoading, [])
  
  const isUserGamesLoaded = useSelector((state: ApplicationState) => getIsUserGamesLoaded(state, { userId: appUser?.Id }))
  const isAddUserGameLoading = useSelector((state: ApplicationState) => getIsAddUserGameLoading(state, { userId: appUser?.Id }))
  const isRemoveUserGameLoading = useSelector((state: ApplicationState) => getIsRemoveUserGameLoading(state, { userId: appUser?.Id }))
  const gamesIds = useSelector((state: ApplicationState) => getGamesIds(state, { userId: appUser?.Id }))

  const isExistingGame = gamesIds.includes(gameId)

  const isPlayer = getIsPlayer(appUser?.Role)

  const handleAddUserGame = async () => {
    await addUserGame(gameId)
  }

  const handleDeleteUserGame = async () => {
    await removeUserGame(gameId)
  }

  useEffect(() => {
    if (appUser) {
      dispatch(loadUserGames(appUser?.Id))
    }
  }, [dispatch, appUser])

  return (
    <>
      {gameId && isPlayer && isUserGamesLoaded &&
        <>
          {isExistingGame
            ? <Button loading={isRemoveUserGameLoading} onClick={handleDeleteUserGame} filled color="red" className={styles.articleHeader__button}>Remove from favorite</Button>
            : <Button loading={isAddUserGameLoading} onClick={handleAddUserGame} filled color="green" className={styles.articleHeader__button}>Add to favorite</Button>
          }
        </>
      }
    </>
  )
}
