import React from 'react'
import SeeMoreLink from './SeeMoreLink'
import styles from '../Home.module.scss'

interface Props {
  title: string
  link: string
}

const HomeHeader = ({title, link}: Props) => {
  return (
    <div className="col-12">
      <div className={styles.quickHeadWrap}>
        <div className={styles.quickHead}>{title}</div>
        <SeeMoreLink link={link} />           
      </div>
    </div>
  )
}

export default HomeHeader
