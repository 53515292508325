import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadRecentOrganizerTournaments } from 'store/logic/tournaments-logic'
import { getOrganizerRecentTournamentsIds, makeGetOrganizerRecentTournamentsLoading } from 'store/selectors/tournaments-selectors'
import { ApplicationState } from 'store/types/common'
import { RecentTournamentCard } from './RecentTournamentCard'
import styles from '../OrganizerProfile.module.scss'
import { cn } from 'utils'
import { loadGames } from 'store/logic/game-logic'
import { useHistory } from 'react-router'
import { defaultTournamentsFilters, filtersChanged } from 'store/types/pages/tournaments-page-types'
import { useUser } from 'components/_hooks'
import { LoadingBox } from 'components/_shared'

interface Props {
  organizerId: number
}

export const RecentTournaments = ({organizerId}: Props) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { user } = useUser(organizerId)

  useEffect(() => {
    organizerId && dispatch(loadRecentOrganizerTournaments(organizerId))
  }, [dispatch, organizerId])

  
  useEffect(() => {
    dispatch(loadGames)
  }, [dispatch])
  
  const recentTournaments = useSelector((state: ApplicationState) => getOrganizerRecentTournamentsIds(state, organizerId))
  const getGetOrganizerRecentTournamentsLoading = useMemo(makeGetOrganizerRecentTournamentsLoading, [])
  const isTournamentsLoading = useSelector((state: ApplicationState) => getGetOrganizerRecentTournamentsLoading(state, organizerId))

  const hanldeViewAll = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) : void => {
    event.preventDefault()
    dispatch(filtersChanged(
      {
        filters: {
          ...defaultTournamentsFilters,
          selectedOrganizers: [user],         
        },
      }
    ))
    history.push('/tournaments/upcoming')
  }

  return (
    <div className={cn('col-12', styles.pflColRight)}>
      <div className={styles.recentCCcontainer}>
        <div className={styles.sectionTtl}>
          <div className={styles.sectionTtl_l}>
            <h2 className={styles.sectionTtl_ttl}>Recent Created Tournament</h2>
          </div>
          <div className={styles.sectionTtl_r}>
            {recentTournaments && recentTournaments.length >= 3 && (
              <a
                href="#"
                onClick={hanldeViewAll}
                className={styles.btnViewMore}
              >
                View All &nbsp; <img src="https://kombatlink-assets.azureedge.net/img/arrow-right.svg" />
              </a>
            )}
          </div>
        </div>
        <LoadingBox loading={isTournamentsLoading} size="average" >
          {recentTournaments && recentTournaments.length < 1 && (
            <p style={{ color: 'rgba(255, 255, 255, 0.5)', fontSize: '85%' }}>
              <br /> <i> No Recent Tournament found....</i>
            </p>
          )}
          {recentTournaments && recentTournaments.length > 0 && (
            <div className={styles.recentCRow}>
              {recentTournaments &&
            recentTournaments.map(id => {
              return (
                <RecentTournamentCard tournamentDetailId={id} key={id} />
              )
            })}
            </div>
          )}
        </LoadingBox>
      </div>
    </div>
  )
}
