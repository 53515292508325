import { createSelector } from 'reselect'
import { OperationTypes } from 'store/logic/operations-logic'
import { makeGetIsOperationWithTypeRunning, makeGetIsOperationWithTypeSuccessOrRunning } from 'store/selectors/operations-selectors'
import { ApplicationState } from 'store/types/common'

export const getState = (state: ApplicationState) => state.chatMembers

const getIdsbyChatId = createSelector(getState, state => state?.idsbyChatId ?? {})

const getById = createSelector(getState, state => state?.byId ?? {})

export const makeGetChatFilteredUserIds = () => createSelector(getState, state => state?.filteredUsersIds ?? [])

export const makeGetChatMemebersIds = () =>
  createSelector(
    getIdsbyChatId,
    (_: ApplicationState, { chatId }: { chatId: number }) => chatId,
    (idsbyChatId, chatId) => idsbyChatId[chatId] ?? []
  )

export const makeGetChatMemebers = () => {
  const getChatMemebersIds = makeGetChatMemebersIds()

  return createSelector(
    getById,
    getChatMemebersIds,
    (byId, ids) => ids.map(id => byId[id])
  )
}

export const makeGetChatMemeber = () => 
  createSelector(
    getById,
    (_: ApplicationState, { chatMemberId }: { chatMemberId: number }) => chatMemberId,
    (byId, chatMemberId) => byId[chatMemberId]
  )


const getLoadPrivateChatMembersOperationType = createSelector(
  (_, { userId }: { userId: number }) => userId,
  userId => ({ type: OperationTypes.loadPrivateChatMembers, objectId: userId })
)
export const makeGetIsPrivateChatMembersLoading = () => makeGetIsOperationWithTypeRunning(getLoadPrivateChatMembersOperationType)
export const makeGetIsPrivateChatMembersRequested = () => makeGetIsOperationWithTypeSuccessOrRunning(getLoadPrivateChatMembersOperationType)
