import { ButtonColor } from 'components/_shared'
import React, { useEffect, useState } from 'react'
import { ModalBase, ModalBaseProps, ModalType } from './ModalBase'
import { ModalBackground } from './_shared/ModalBackground'
import { ModalContainer } from './_shared/ModalContainer'
import { ModalFileInput } from './_shared/ModalFileInput'
import { ModalFileLabel } from './_shared/ModalFileLabel'
import { ModalSelect } from './_shared/ModalSelect/ModalSelect'
import { WindowButton } from './_shared/WindowButton'
import { WindowButtonsContainer } from './_shared/WindowButtonsContainer'
import { WindowHeader } from './_shared/WindowHeader'
import { WindowHr } from './_shared/WindowHr'
import { WindowInput } from './_shared/WindowInput'
import { WindowRadio } from './_shared/WindowRadio'
import { WindowSubHeader } from './_shared/WindowSubHeader'
import { WindowTextarea } from './_shared/WindowTextarea'
import { WindowWarning } from './_shared/WindowWarning'

interface ModalOwnProps {
  asyncOperation?: () => Promise<boolean>
  allowOk?: boolean
  hideOk?: boolean
  hideCancel?: boolean
  childError?: string | undefined
  cancelButtonText?: string
  okButtonText?: string
  buttonColor: ButtonColor
  buttonClassName?: string
  fieldsetClassName?: string
  className?:string
  onOperationInProgress?: (isOperationInProgress: boolean) => void
}

export type ModalProps = ModalOwnProps & ModalBaseProps

export const Modal = ({
  onClose,
  asyncOperation,
  allowOk,
  children,
  hideOk,
  hideCancel,
  childError,
  cancelButtonText = 'Cancel',
  okButtonText = 'Confirm',
  buttonColor,
  className,
  buttonClassName,
  onOperationInProgress,
  fieldsetClassName,
  ...props
}: ModalProps) => {
  const [isOperationInProgress, setOperationInProgress] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | JSX.Element>('')

  useEffect(() => {
    setErrorMessage(childError ?? '')
  }, [childError])

  const handleClose = () => {
    _closeDialog()
  }

  const _closeDialog = () => {
    if (!isOperationInProgress) {
      onClose()
    }
  }

  const executeOperation = async () => {
    setErrorMessage('')
    if (asyncOperation) {
      setOperationInProgress(true)
    }
    try {
      if (asyncOperation) {
        const result = await asyncOperation()
        setOperationInProgress(false)
        if (result) {
          _closeDialog()
        }
      } else {
        _closeDialog()
      }
    } catch (e) {
      setOperationInProgress(false)
      setErrorMessage(e.toString())
    }
  }

  useEffect(() => {
    if (onOperationInProgress) {
      onOperationInProgress(isOperationInProgress)
    }
  }, [isOperationInProgress, onOperationInProgress])

  const animationType = props.type === ModalType.animated ? props.animationType : undefined
  const show = props.type === ModalType.animated ? props.show : undefined

  return (
    <ModalBase onClose={handleClose} className={className} disabled={isOperationInProgress} type={props.type} animationType={animationType} show={show}>
      {errorMessage ? <div className="alert alert-danger mt-3">{errorMessage}</div> : null}
      <fieldset className={fieldsetClassName} disabled={isOperationInProgress}>{children}</fieldset>
      <WindowButtonsContainer>
        {!hideCancel && (
          <WindowButton color={buttonColor} onClick={_closeDialog} disabled={isOperationInProgress} className={buttonClassName}>
            {cancelButtonText}
          </WindowButton>
        )}
        {!hideOk && (
          <WindowButton
            color={buttonColor}
            onClick={executeOperation}
            disabled={!allowOk}
            loading={isOperationInProgress}
            className={buttonClassName}
            filled
          >
            {okButtonText}
          </WindowButton>
        )}
      </WindowButtonsContainer>
    </ModalBase>
  )
}

Modal.Background = ModalBackground
Modal.Container = ModalContainer
Modal.Button = WindowButton
Modal.ButtonsContainer = WindowButtonsContainer
Modal.Header = WindowHeader
Modal.SubHeader = WindowSubHeader
Modal.Textarea = WindowTextarea
Modal.Select = ModalSelect
Modal.Radio = WindowRadio
Modal.Hr = WindowHr
Modal.Input = WindowInput
Modal.Warning = WindowWarning
Modal.FileInput = ModalFileInput
Modal.FileLabel = ModalFileLabel
