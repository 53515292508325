import React, { useEffect } from 'react'
import { useDialogWithParameter } from 'components/_hooks/useComponents/useDialog'
import { useDispatch, useSelector } from 'react-redux'
import { loadActiveMatches } from 'store/logic/match-logic'
import { getActiveMatchesIds, getIsActiveMatchesLoading } from 'store/selectors/match-selectors'
import ActiveBattleRow from './ActiveBattleRow'
import {
  BattleHeaderRow,
  ButtonsColumn,
  DeadlineColumn,
  EventNameColumn,
  RoundColumn,
  StartDateColumn,
  TypeColumn,
} from '../_shared/BattleRow/BattleRow'
import MatchResultModal, { ParticipantsResults } from 'components/_shared/MatchResultModal/MatchResultModal'
import { LoadingBox } from 'components/_shared'
import { useHighlight } from 'components/_hooks'

export interface ActiveBattlesProps {
  roundId?: number
}

export const ActiveBattles = ({ roundId }: ActiveBattlesProps) => {
  const dispatch = useDispatch()
  const matchesIds = useSelector(getActiveMatchesIds)
  const matchesLoading = useSelector(getIsActiveMatchesLoading)
  const [highlightedRoundId, removeHighlightedRoundId] = useHighlight(roundId)

  useEffect(() => {
    dispatch(loadActiveMatches)
  }, [dispatch])

  const [matchResultModal, openMatchResultModal] = useDialogWithParameter<ParticipantsResults>((participantsResults, closeDialog) => (
    <MatchResultModal closeDialog={closeDialog} participantsResults={participantsResults} />
  ))

  return (
    <>
      <BattleHeaderRow>
        <EventNameColumn>Name of the event</EventNameColumn>
        <TypeColumn>Type</TypeColumn>
        <StartDateColumn>Date</StartDateColumn>
        <RoundColumn>Round</RoundColumn>
        <DeadlineColumn>Deadline</DeadlineColumn>
        <ButtonsColumn />
      </BattleHeaderRow>
      <LoadingBox loading={matchesLoading} size="big">
        {matchesIds.map(id => (
          <ActiveBattleRow
            key={id}
            matchId={id}
            openMatchResultModal={openMatchResultModal}
            highlightedRoundId={highlightedRoundId}
            removeHighlightedRoundId={removeHighlightedRoundId}
          />
        ))}
      </LoadingBox>
      {matchResultModal}
    </>
  )
}
