import { ContainerName } from 'components/_hooks'
import { UserAvatar } from 'components/_shared'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { makeGetUser } from 'store/selectors/user-selectors'
import { ApplicationState } from 'store/types/common'
import styles from '../PlayerPage.module.scss'

interface UserFriendProps {
  userId: number
  containerName?:ContainerName
}

export const UserFriend = ({ userId,containerName } : UserFriendProps) => {
  const getUser = useMemo(makeGetUser, [])
  const user = useSelector((state: ApplicationState) => getUser(state, userId))
  
  return (
    <UserAvatar containerName={containerName} withLink userId={user?.Id} placeholder="user" linkClassName={'ml-2 mt-2'} className={styles.friendAvatar}/>
  )
}
