import { useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import { makeGetIsClanRequested } from 'store/selectors/clan-selectors'
import { clansMembersLoaded, ClansMembersModel } from 'store/types/clan-member-types'
import { clanDeleted, clansLoaded, ClansModel } from 'store/types/clan-types'
import { GetState } from 'store/types/common'
import { fetchApi } from 'utils'
import { OperationTypes, run, useRun } from './operations-logic'

export interface SaveClanModel {
  name: string
  aboutUs: string
  wantToBeFound: boolean
  avatarId?: number
}

const getClanRequested = makeGetIsClanRequested()

export const loadClan = (clanId: number) => async (dispatch: Dispatch, getState: GetState) => {
  if (getClanRequested(getState(), { clanId })) {
    return
  }
  await run(OperationTypes.loadClan, clanId, dispatch, getState, async () => {
    try {
      const model = await fetchApi<ClansModel>(`clan/${clanId}`, 'GET')
      dispatch(clansLoaded(model))
    } catch (error) {
      return false
    }
    return true
  })
}

export const useAddClan = () => {
  const dispatch = useDispatch()
  return useRun(OperationTypes.addClan, null, async (saveClanModel: SaveClanModel) => {
    const model = await fetchApi<ClansMembersModel>('clan', 'POST', saveClanModel)
    dispatch(clansMembersLoaded(model))
    return true
  })
}

export const useUpdateClan = () => {
  const dispatch = useDispatch()
  return useRun(OperationTypes.updateClan, null, async (saveClanModel: SaveClanModel) => {
    const model = await fetchApi<ClansModel>('clan', 'PUT', saveClanModel)
    dispatch(clansLoaded(model))
    return true
  })
}

export const useDisbandClan = () => {
  const dispatch = useDispatch()
  return useRun(OperationTypes.disbandClan, null, async (clanId: number) => {
    await fetchApi('clan/disband', 'POST')
    dispatch(clanDeleted({ clanId }))

    return true
  })
}
