import { Button } from 'components/_shared'
import React, { useCallback, useMemo } from 'react'
import { cn } from 'utils'
import styles from './Dropzone.module.scss'
import { DropzoneOptions, FileRejection, useDropzone } from 'react-dropzone'
import { useAlertModal } from 'components/_hooks'
import { MediaType } from 'store/types/image-types'

export type FileType = 'image/jpeg' | 'image/jpg' | 'image/png' | 'image/gif' | 'video/mp4'

const getFileType = (file: File): MediaType => {
  return file.type.includes('image') ? MediaType.Image : MediaType.Video
}

export interface DropzoneProps {
  dropClassName?: string
  dropText: string
  onChange: (file?: File, mediaType?: MediaType) => void
  file: File
  fileTypes: FileType[]
  recommendedText: string
  disabled?: boolean
}

export const Dropzone = ({ dropClassName, dropText, onChange, fileTypes, recommendedText, disabled }:  DropzoneProps) => {
  const [errorModal, openErrorModal] = useAlertModal()

  const handleDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      const acceptedFile = acceptedFiles[0]

      const acceptedFileType = getFileType(acceptedFile)
      onChange(acceptedFile, acceptedFileType)
    }
  }, [onChange])

  const handleDropRejected = useCallback((fileRejections: FileRejection[]) => {
    const errorMessage = fileRejections[0]?.errors[0]?.message
    openErrorModal(errorMessage)
  }, [openErrorModal])

  const dropzoneOptions = useMemo<DropzoneOptions>(() => {
    return {
      onDrop: handleDrop,
      onDropRejected: handleDropRejected,
      accept: fileTypes.join(', '),
      multiple: false,
      maxSize: 209715200,
      disabled,
    }
  }, [disabled, fileTypes, handleDrop, handleDropRejected])

  const { getRootProps, getInputProps, isDragActive } = useDropzone(dropzoneOptions)

  return (
    <>
      <section className={styles.cropDropContainer}>
        <div  {...getRootProps()}>
          <input {...getInputProps()} />
          <div className="container-fluid">
            <div className="row">
              <div className={cn(styles.drop, isDragActive && styles.dropHighlighted, dropClassName)}>
                <div className="row h-100 align-items-center m-0">
                  <div className="col-12 d-flex flex-column">
                    <div className="row justify-content-center">
                      <div className={styles.dropIcon}></div>
                    </div>
                    <div className="row justify-content-center">
                      <div className={styles.dropText}>
                        {dropText}
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className={styles.dropText}>or</div>
                    </div>
                    <div className="row justify-content-center">
                      <Button disabled={disabled} className={styles.dropLabel} color="blue" filled>
                        Select a file on the computer
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className={styles.cropRecommendedSize}>
                <div>{recommendedText}</div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {errorModal}
    </>
  )
}
