import React, { ReactNode } from 'react'
import { cn } from 'utils'
import styles from '../modals.module.scss'

interface WindowLabelProps {
  children: ReactNode
  id?: string
  className?: string
  disabled?: boolean
}

export const WindowLabel = ({ children, className, id, disabled }: WindowLabelProps) => {
  return (
    <label className={cn(styles.window__label, disabled && styles.disabled, className)} htmlFor={id}>
      {children}
    </label>
  )
}
