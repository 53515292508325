import { makeActionCreator, ReduxAction } from './common'
import { ImageType } from './image-types'
import { PublicUserType } from './user-types'

export interface Review {
  Id: number
  ReviewerId: number
  UserId: number
  Rating: number
  Text: string
  Date: string
}

export interface ReviewState {
  byId: {
    [key: number]: Review
  }
  idsByUserId: {
    [key: number]: number[]
  }
  allReviewsCount: number
  myReviewIdByUserId: {
    [key: number]: number
  }
}

export interface ReviewsModel {
  Reviews: Review[]
  Users: PublicUserType[]
  Images: ImageType[]
  AllReviewsCount: number
}

export const REVIEWS_LOADED = 'REVIEWS_LOADED'
export type ReviewsLoadedAction = ReduxAction<typeof REVIEWS_LOADED, ReviewsModel>
export const reviewsLoaded = makeActionCreator<ReviewsModel>(REVIEWS_LOADED)

interface MyReviewLoadedPayload {
  review: Review
  userId: number
}
export const MY_REVIEW_LOADED = 'MY_REVIEW_LOADED'
export type MyReviewLoadedAction = ReduxAction<typeof MY_REVIEW_LOADED, MyReviewLoadedPayload>
export const myReviewLoaded = makeActionCreator<MyReviewLoadedPayload>(MY_REVIEW_LOADED)

interface ReviewAddedPayload {
  review: Review
  userId: number
}
export const REVIEW_ADDED = 'REVIEW_ADDED'
export type ReviewAddedAction = ReduxAction<typeof REVIEW_ADDED, ReviewAddedPayload>
export const reviewAdded = makeActionCreator<ReviewAddedPayload>(REVIEW_ADDED)

export type KnownAction = ReviewsLoadedAction
  | MyReviewLoadedAction
  | ReviewAddedAction
