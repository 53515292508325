import { AddReview } from 'components/UserReview/_shared/AddReview'
import { useAppUser } from 'components/_hooks'
import { useAppSelector } from 'components/_hooks/useAppSelector'
import React, { useMemo } from 'react'
import { makeGetMyReviewId } from 'store/selectors/review-selectors'
import { getIsPlayer } from 'store/types/user-types'

export interface AddOrganizerReviewProps {
  userId: number
}

export const AddOrganizerReview = ({ userId }:  AddOrganizerReviewProps) => {
  const getMyReviewIdByUserId = useMemo(makeGetMyReviewId, [])
  const myReviewId = useAppSelector(state => getMyReviewIdByUserId(state, { userId }))
  const appUser = useAppUser()

  return (
    <>
      {appUser && getIsPlayer(appUser.Role) && !myReviewId && <AddReview userId={userId} />}
    </>
  )
}
