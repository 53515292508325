import { ExecuteAPIGet, ExecuteAPIPost } from '../server-utils'
import { EndPoints } from '../endpoints'
import { kl_TournamentModeModel, kl_Tournament } from '../../models/tournament-models'
import { TournamentFormDataViewModel } from '../../models/tournament-form-data-viewmodel'
import { OrganizerProfileModel } from '../../models/organizer-profile-models'
import { VictoryRoadMapViewModel } from '../../models/victory-road-map-viewmodel'
import { TicketValidationPostModel, TournamentTicketViewModel } from '../../models/tournament-tickets-viewmodel'
import { TournamentViewModel_kl } from '../../models/tournament-viewmodel'
import { TournamentTicketsCartDetail } from 'models/tournament-cart-model'
import {
  TournamentEventPostModelView,
  TournamentEventViewModel,
} from '../../models/tournament-event-view-model'
import { Tournament_Members_IncomeViewModel } from '../../models/Player_OrganizerHeaderViewModel'
import { KlTournamentModel, kl_TournamentRoundsViewModel } from '../../models/kl_tournament_viewmodel'
import { OrganizerTopTournamentsViewModel } from '../../models/organizer-tournaments-viewmodel'
import { kl_DraftTournament } from '../../models/kl_DraftTournament-models'
import { DraftTournamentCardsPaginationViewModel, TournamentCardsPaginationViewModel, TournamentCardViewModelNew, TournamentFilterViewModel } from 'models/tournament-filter-viewmodel'
import { kl_UpdateTournamentStatus } from 'models/tournament-update-status-viewmodel'
import { UserParticipantViewModel } from 'models/user-participant-model'
import { ResumeEventStatusType } from 'consts/ResumeEventStatusType'
import { AdminTournamentStatsViewModel } from 'models/admin-tournament-stats-viewmodel'
import { ListClanMembersDto } from 'store/types/pages/clan-page-types'

export const TournamentUtils = {
  GetTournamentViewModelByID: async function GetTournamentViewModelByID(id: number,isIncludeCancelEvent =false) {
    return await ExecuteAPIGet<TournamentViewModel_kl>(EndPoints.Tournament.GetTournamentViewModelByID, `id=${id}&isIncludeCancelEvent=${isIncludeCancelEvent}`)
  },
  SaveTournament: async function SaveTournament(model: KlTournamentModel) {
    return await ExecuteAPIPost<number>(EndPoints.Tournament.SaveTournament, model)
  },
  UpdateTournament: async function UpdateTournament(model: KlTournamentModel) {
    return await ExecuteAPIPost<number>(EndPoints.Tournament.UpdateTournament, model)
  },
  GetTournamentRounds: async function GetTournamentRounds(tournamentDetailId: number) {
    return await ExecuteAPIGet<kl_TournamentRoundsViewModel[]>(EndPoints.Tournament.GetTournamentRounds, `tournamentDetailId=${tournamentDetailId}`)
  },
  GetTournamentModes:  async function GetTournamentModes() {
    return await ExecuteAPIGet<kl_TournamentModeModel[]>(EndPoints.Tournament.GetTournamentModes)
  },
  GetFiltredTournamentsInternal: async function GetFiltredTournamentsInternal(filter: TournamentFilterViewModel) {
    return await ExecuteAPIPost<TournamentCardsPaginationViewModel>(EndPoints.Tournament.GetFiltredTournaments, filter)
  },
  GetFiltredTournaments: async function GetFiltredTournaments(eventType: number, form: TournamentFormDataViewModel, pageNo: number) {
    const filter = {
      ConsoleId: form.console || 0,
      CurrentPageNumber: pageNo,
      EventTypeId: eventType || 0,
      GameId: form.games || 0,
      OrganizerId: form.organizerId || 0,
      Priod: form.priod || '',
      RegionId: form.Region || 0,
      SelectKombatOnly: form.selectKombatOnly || false,
      TournamentModeId: form.types || 0,
      TournamentStatusId: form.status || 0,
      TournamentStructureId: form.tournamentStructure || 0,
      VenueTypeId: form.vanueType || 0,
      DateFrom: form.dateFrom || null,
      DateTo: form.dateTo || null,
    }
    return await this.GetFiltredTournamentsInternal(filter)
  },
  GetTop3Tournaments: async function GetTop3Tournaments(topCount: number) {
    if (topCount < 1 || !topCount) {
      topCount = 3
    }
    return await ExecuteAPIGet<TournamentCardViewModelNew[]>(EndPoints.Tournament.GetTop3Tournaments, `topCount=${topCount}`)
  },
  GetTournamentsForHomePageSlider: async function GetTournamentsForHomePageSlider() {
    return await ExecuteAPIGet<TournamentViewModel_kl[]>(EndPoints.Tournament.GetTournamentsForHomePageSlider)
  },
  GetCityTournaments: async function GetCityTournaments(CountryName :string,pageNo:number,recordPerPage:number){
    return await ExecuteAPIGet<any>(EndPoints.Tournament.GetCityTournaments, `CountryName=${CountryName}&PageNo=${pageNo}&RecordPerPage=${recordPerPage}`)
  },
  UpdateTournamentStatus: async function UpdateTournamentStatus(model : kl_UpdateTournamentStatus) {
    return await ExecuteAPIPost<boolean>(EndPoints.Tournament.UpdateTournamentStatus, model)
  },
  DeleteTournament: async function DeleteTournament(id:number){
    return await ExecuteAPIGet<boolean>(EndPoints.Tournament.DeleteTournament, `TournamentId=${id}`)
  },
  GetMainTournaments: async function GetMainTournaments() {
    return await ExecuteAPIGet<kl_Tournament[]>(EndPoints.Tournament.GetMainTournaments)
  },
  GetDraftTournaments: async function GetDraftTournaments(currentPageNumber : number) {
    return await ExecuteAPIGet<DraftTournamentCardsPaginationViewModel>(EndPoints.Tournament.GetDraftTournaments, `CurrentPageNumber=` + currentPageNumber)
  },
  DeleteDraftTournament: async function DeleteDraftTournament(Id: number) {
    return await ExecuteAPIGet<kl_DraftTournament[]>(EndPoints.Tournament.DeleteDraftTournament, `Id=${Id}`)
  },
  CreateDraftTournament: async function CreateDraftTournament(model: kl_DraftTournament) {
    return await ExecuteAPIPost<number>(EndPoints.Tournament.CreateDraftTournament, model)
  },
  OrganizerProfile: async function OrganizerProfile(userId: number, eventType: number, fromDate?: Date, toDate?: Date) {
    if (fromDate == undefined || toDate == undefined) {
      return await ExecuteAPIGet<OrganizerProfileModel>(EndPoints.Tournament.OrganizerProfile, `userId=${userId}&eventType=${eventType}`)
    } else {
      return await ExecuteAPIGet<OrganizerProfileModel>(
        EndPoints.Tournament.OrganizerProfile,
        `userId=${userId}&eventType=${eventType}&fromDate=${fromDate}&toDate=${toDate}`
      )
    }
  },
  VictoryRoadMap: async function VictoryRoadMap() {
    return await ExecuteAPIGet<VictoryRoadMapViewModel[]>(EndPoints.Tournament.VictoryRoadMap)
  },
  TournamentTicket: async function TournamentTicket(ticketId:number) {
    return await ExecuteAPIGet<TournamentTicketViewModel>(
      EndPoints.Tournament.TournamentTicket,
      `ticketId=${ticketId}`
    )
  },
  TournamentAndMembersAndIncome: async function TournamentAndMembersAndIncome(organizerId: number) {
    if (organizerId) {
      return await ExecuteAPIGet<Tournament_Members_IncomeViewModel>(EndPoints.Tournament.TournamentAndMembersAndIncome, `organizerId=${organizerId}`)
    }
  },
  TournametTicketValidate: async function TournametTicketValidate(model: TicketValidationPostModel) {
    return await ExecuteAPIPost<TournamentTicketViewModel>(EndPoints.Tournament.TournamentTicketValidate, model)
  },
  UpdateTournamentCaurosel: async function UpdateTournamentCaurosel(tournamentDetailId: number, caurosel: boolean) {
    return await ExecuteAPIGet<number>(EndPoints.Tournament.UpdateTournamentCaurosel, `tournamentDetailId=${tournamentDetailId}&caurosel=${caurosel}`)
  },
  OrganizerTopTournaments: async function OrganizerTopTournaments(organizerId: number) {
    return await ExecuteAPIGet<OrganizerTopTournamentsViewModel[]>(EndPoints.Tournament.OrganizerTopTournaments, `organizerId=${organizerId}`)
  },
  UserTicketDetail: async function UserTicketDetail(model: TournamentEventPostModelView) {
    return await ExecuteAPIPost<TournamentEventViewModel[]>(EndPoints.Tournament.TournamentEvent, model)
  },
  GetRepeatTournament: async function GetRepeatTournament(id: number) {
    return await ExecuteAPIGet<TournamentViewModel_kl[]>(EndPoints.Tournament.GetRepeatTournament, `tournamentId=${id}`)
  },
  DeleteRepeatTournaments: async function DeleteRepeatTournaments(ids: number[]) {
    return await ExecuteAPIPost<boolean>(EndPoints.Tournament.DeleteRepeatTournaments, ids)
  },
  GetAllQualifierTournamentsByMainTournamentId: async function GetAllQualifierTournamentsByMainTournamentId(id: number) {
    return await ExecuteAPIGet<TournamentViewModel_kl[]>(EndPoints.Tournament.GetAllQualifierTournamentsByMainTournamentId, `mainTournamentId=${id}`)
  },
  GetTournamentCartDetail: async function GetTournamentCartDetail(id:number){
    return await ExecuteAPIGet<TournamentTicketsCartDetail[]>(EndPoints.Tournament.GetTournamentCartDetail, `id=${id}`)
  },
  CheckUserParticipantInTournamentSeriesOrNot: (tournamentDetailId: number) => {
    return ExecuteAPIGet<UserParticipantViewModel>(EndPoints.Tournament.CheckUserEnrollIntoQualifireOrMainEventOrNormalEvent, `tournamentDetailId=${tournamentDetailId}`)
  },
  CheckResumeTournamentOrNot: (tournamentDetailId: number) => {
    return ExecuteAPIGet<ResumeEventStatusType>(EndPoints.Tournament.CheckResumeTournamentOrNot, `tournamentDetailId=${tournamentDetailId}`)
  },
  SaveCoverFromExistingBlob: async function SaveCoverFromExistingBlob(existingTournamentId:number, tournamentId : number, isCopyCover : boolean, isCopyTicketImage : boolean){
    return await ExecuteAPIGet<boolean>(EndPoints.Tournament.SaveCoverFromExistingBlob, `existingTournamentId=${existingTournamentId}&tournamentId=${tournamentId}&isCopyCover=${isCopyCover}&isCopyTicketImage=${isCopyTicketImage}`)
  },
  GetTournamentStats: async function GetTournamentStats(duration:string){
    return await ExecuteAPIGet<AdminTournamentStatsViewModel>(EndPoints.Tournament.GetTournamentStats, `duration=${duration}`)
  },
  GetTournamentClanMembes:async function GetTournamentClanMembes(clanId:number){
    return await ExecuteAPIPost<ListClanMembersDto>(`clanmember/${clanId}/listclanwithmember`)
  },
  CheckUserParticipantInTournamentSeriesOrNotByOrganizer: (tournamentDetailId: number,userId:number) => {
    return ExecuteAPIGet<UserParticipantViewModel>(EndPoints.Tournament.CheckUserEnrollIntoQualifireOrMainEventOrNormalEventByOrganizer, `tournamentDetailId=${tournamentDetailId}&userId=` + userId)
  }
}
