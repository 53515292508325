import React from 'react'
import { Theme } from 'store/types/common'
import { cn } from 'utils'
import styles from './Zoom.module.scss'

interface ZoomProps {
  value: number
  onChange: (value: number) => void
  theme?: Theme
}

export const Zoom = ({ value, onChange, theme = 'dark' }: ZoomProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(parseInt(event.target.value))
  }

  const isLightTheme = theme === 'light'

  return (
    <div className="row flex-column m-0 zoom-slider">
      <div className="col w-200 p-0">
        <div className="row m-0 justify-content-between">
          <div className={cn(styles.previewText, isLightTheme && styles.light)}>Zoom In</div>
          <div className={cn(styles.previewText, isLightTheme && styles.light)}>{value}%</div>
        </div>
      </div>
      <div className="col-auto p-0">
        <input
          type="range"
          className={cn(!isLightTheme && 'preview__zoom')}
          value={value}
          min="1"
          max="100"
          onChange={handleChange}
        />
      </div>
    </div>
  )
}
