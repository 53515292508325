import { ContainerName } from 'components/_hooks'
import { LoadingBox } from 'components/_shared'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadBlogs } from 'store/logic/blogs-logic'
import { getBlogsIds, getBlogsLoading } from 'store/selectors/blog-selectors'
import { BlogCard } from './BlogCard'


export const TopBlogs = () => {
  const dispatch = useDispatch()

  const blogsIds = useSelector(getBlogsIds)
  const blogsLoading = useSelector(getBlogsLoading)

  useEffect(() => {
    dispatch(loadBlogs({
      page: 0,
      pageSize: 2,
    }))
  }, [dispatch])


  return (
    <>
      <LoadingBox loading={blogsLoading} size="average" centered >
        {blogsIds?.map(id => <BlogCard containerName={ContainerName.Blogs} key={id} id={id}/>)}
      </LoadingBox>
    </>
  )
}
