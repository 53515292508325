import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { makeGetMatchParticipantsIds } from 'store/selectors/match-participant-selectors'
import { getPlaceByMatchParticipantId } from 'store/selectors/pages/tournament-bracket-page-selectors'
import { ApplicationState } from 'store/types/common'

export const useAllPlacesSelected = (matchId?: number) => {
  const getMatchParticipantsIds = useMemo(makeGetMatchParticipantsIds, [])
  const matchParticipantsIds = useSelector((state: ApplicationState) => getMatchParticipantsIds(state, matchId))
  const placeByMatchParticipantId = useSelector(getPlaceByMatchParticipantId)
  
  const isAllPlacesSelected = useMemo(() => {
    return matchParticipantsIds.every(x => placeByMatchParticipantId[x] !== undefined)
  }, [matchParticipantsIds, placeByMatchParticipantId])

  return isAllPlacesSelected
}
