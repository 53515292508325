import { PaymentMethod } from 'consts/PaymentMethod'
import { TournamentCartRefundStatus } from 'consts/TournamentCartRefundStatus'
import * as React from 'react'
import { defaultFormatDate } from 'utils/dateUtils'
import { LoyalCreditsBonusViewModel } from '../../../models/my-transaction-viewmodel'

interface Props {
  isPurchased?: boolean
  refunded?: TournamentCartRefundStatus
  tickets: LoyalCreditsBonusViewModel
}

interface State {}

class PlayerTransactionsTicketsCard extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {}
  }

  render() {
    const { isPurchased, refunded, tickets } = this.props
    const {} = this.state
    return (
      <>
        <div className="row table__row">
          <div className="table__column table__column--date">{tickets && tickets.date && defaultFormatDate(tickets.date)}</div>
          <div className="table__column table__column--currency">{tickets && (tickets.ticketPrice === 0 ? 'Free' : (tickets.UserPaymentId !== PaymentMethod.LoyaltyPoints ? tickets.currencySymbol : '' ) + tickets.ticketPrice + (tickets.UserPaymentId === PaymentMethod.LoyaltyPoints ? ' LP' : '' ))}</div>
          <div className="table__column table__column--tournament">{tickets && tickets.tournament}</div>
          <div className="table__column table__column--type">{tickets && tickets.type}</div>
          <div className="table__column table__column--venue">{tickets && tickets.venue}</div>
          <div className="table__column table__column--status">
            {isPurchased && <div className="table__label table__label--green">purchased</div>}
            {refunded && refunded !== null && <div className="table__label table__label--orange w-auto">{refunded === TournamentCartRefundStatus.Pending ? 'Refund Pending' : refunded === TournamentCartRefundStatus.Canceled ? 'Refund Canceled': 'Refunded'}</div>}
          </div>
        </div>
      </>
    )
  }
}

export default PlayerTransactionsTicketsCard
