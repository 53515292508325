import { ContainerName, useGetImageSize } from 'components/_hooks'
import { Img } from 'components/_shared'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { makeGetBlog } from 'store/selectors/blog-selectors'
import { ApplicationState } from 'store/types/common'
import { defaultFormatDate } from 'utils'

import styles from './Home.module.scss'


export interface HomeBlogsRowProps {
  blogId: number
  isRead: boolean
  containerName?: ContainerName
}

export const HomeBlogsRow = ({ blogId, isRead, containerName }: HomeBlogsRowProps) => {
  const getBlog = useMemo(makeGetBlog, [])
  const cdn = 'https://kombatlink-assets.azureedge.net/img'
  const blog = useSelector((state: ApplicationState) => getBlog(state, { blogId }))
  const getImageSize = useGetImageSize(containerName)
  console.log(isRead)
  const title = (blog?.Title.toLowerCase().replace(/\s-/g, "").replace(/,/g, "").trim().replace(/\s/g, "-")) || ''
  return (
    <>
      <>
        
        {/* Orignal Design */}
        <div className={"col-xl-4 col-l-4 col-m-6 col-s-6 " + styles.blog_box} data-aos="zoom-in">
        {!isRead && (
          <>
            <div>
              <div className={styles.isNewLabelBackground} />
              <div className={styles.isNewLabel} style={{}}>
                New
              </div>
            </div>
          </>
        )}
          <Link to={`/news/${blogId}/${title}`}>
            <div className={styles.blog_img}>
              <Img parentContainerSize={getImageSize} alt={blog?.Title} imageId={blog?.PreviewImageId} />
            </div>
          </Link>
          <div className={styles.blog_txt}>
            <h6 className={styles.subtitle + " "+ styles.restrict2Lines}>{blog?.Title}</h6>
            <span className={styles.postedby}>Published: <b>{defaultFormatDate(blog?.Date)}</b></span>
            <p className={styles.preview+ " "+ styles.restrict3Lines} dangerouslySetInnerHTML={{ __html: blog?.ShortDescription }}></p>
            
            <Link to={`/news/${blogId}/${title}`} className={styles.btnBlack}>Read More</Link>
        </div>
      </div>
      </>
    </>
  )
}
