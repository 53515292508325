import { Popup } from 'components/_modals'
import { Loading } from 'components/_shared/Loading'
import React from 'react'
import { cn } from 'utils'
import styles from './UploadPopup.module.scss'

interface UploadPopupProps {
  fileName: string
  progressText: string
  loading: boolean
}

export const UploadPopup = ({ fileName, progressText, loading }: UploadPopupProps) => {
  return (
    <Popup windowClassName={cn(styles.uploadPopup, styles.window)}>
      <div>Uploading cover video</div>
      <hr className={styles.hr} />
      <div className={cn('d-flex justify-content-between', styles.container)}>
        <div className={styles.fileName}>
          {fileName}
        </div>
        <div className="d-flex">
          <div>{progressText}</div>
          {loading && <Loading size="small" className="ml-2" />}
        </div>
      </div>
    </Popup>
  )
}
