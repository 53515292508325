import { Reducer } from 'redux'
import { defaultFilters, KnownAction, MembersPageState, MEMBERS_PAGE_CLANS_FILTERS_CHANGED, MEMBERS_PAGE_CLANS_LOADED, MEMBERS_PAGE_ORGANIZERS_FILTERS_CHANGED, MEMBERS_PAGE_ORGANIZERS_LOADED, MEMBERS_PAGE_PLAYERS_FILTERS_CHANGED, MEMBERS_PAGE_PLAYERS_LOADED } from 'store/types/pages/members-page-types'

const membersPageInitialState: MembersPageState = {
  allPlayersCount: 0,
  playersFilters: defaultFilters,
  playersIds: [],

  allClansCount: 0,
  clansFilters: defaultFilters,
  clansIds: [],

  allOrganizersCount: 0,
  organizersFilters: defaultFilters,
  organizersIds: [],
}

const reducer: Reducer<MembersPageState, KnownAction> = (state: MembersPageState | undefined = membersPageInitialState, action: KnownAction) => {
  switch (action.type) {
    case MEMBERS_PAGE_PLAYERS_LOADED: {
      return {
        ...state,
        playersIds: action.payload.Model.Users.map(x => x.Id),
        allPlayersCount: action.payload.AllPlayersCount,
      }
    }
    case MEMBERS_PAGE_CLANS_LOADED: {
      return {
        ...state,
        clansIds: action.payload.Model.Clans.map(x => x.Id),
        allClansCount: action.payload.AllPlayersCount,
      }
    }
    case MEMBERS_PAGE_ORGANIZERS_LOADED: {
      return {
        ...state,
        organizersIds: action.payload.UsersModel.Users.map(x => x.Id),
        allOrganizersCount: action.payload.AllUsersCount,
      }
    }
    case MEMBERS_PAGE_PLAYERS_FILTERS_CHANGED: {
      return {
        ...state,
        playersFilters: action.payload,
      }
    }
    case MEMBERS_PAGE_CLANS_FILTERS_CHANGED: {
      return {
        ...state,
        clansFilters: action.payload,
      }
    }
    case MEMBERS_PAGE_ORGANIZERS_FILTERS_CHANGED: {
      return {
        ...state,
        organizersFilters: action.payload,
      }
    }
    default:
      return state
  }
}

export default reducer
