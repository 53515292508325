import React from 'react'
import { DropDownTable } from 'components/_shared'
import { VenueTypeEnum } from 'consts'
import { kl_DraftTournament } from 'models/kl_DraftTournament-models'
import { useHistory } from 'react-router-dom'
import { cn, getDefaultDateFormats } from 'utils'
import styles from '../EventsOrganizer.module.scss'
import { kl_TournamentDetailViewModel, kl_TournamentViewModel } from 'models/kl_tournament_viewmodel'
import { getIsAdmin } from 'store/types/user-types'
import { useAppUser } from 'components/_hooks/useAppUser'
import { Modal } from 'components/_modals'
import { useDialogWithParameter } from 'components/_hooks'
import { TournamentUtils } from 'server/utils/tournament-utils'

interface OwnProps {
  draft: kl_DraftTournament 
  onResetData: CallableFunction
}
interface DraftTournament {
  tournament: kl_TournamentViewModel
}

const DraftTournamentsRow = ({draft, onResetData}: OwnProps) => {

  const history = useHistory()
  const user = useAppUser()
  const isAdmin = getIsAdmin(user.Role)

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const draftTournament: DraftTournament = JSON.parse(draft.TournamentJson)
  const tournament: kl_TournamentViewModel = draftTournament.tournament
  const tournamentDetails: kl_TournamentDetailViewModel = tournament.TournamentDetails[0] ? tournament.TournamentDetails[0] : null
  const startDate = tournamentDetails?.StartDate ? getDefaultDateFormats(tournamentDetails.StartDate) : {date: '', time: ''}
    
  const handleDelete = (draftId: number) => {
    openConfirmDeleteModal(draftId)
  }
    
  const deleteDraftTournament = async (draftId: number) => {
    await TournamentUtils.DeleteDraftTournament(draftId)   
    onResetData()
  }


  const handleEdit = (draft: kl_DraftTournament) => {
    localStorage.setItem('DraftTournament', draft.TournamentJson)
    history.push(isAdmin ? '/admin/create-tournament/' : '/create-tournament/')
  }

  const [confirmDeleteModal, openConfirmDeleteModal] = useDialogWithParameter<number>((draftId, closeDialog) => (
    <Modal
      onClose={closeDialog}
      allowOk
      asyncOperation={async () => {
        await deleteDraftTournament(draftId)
        return true
      }}
      buttonColor="blue"
      className={styles.confirm__modal}
      okButtonText="Confirm"
    >
      <Modal.Header>Are you sure you want to delete draft tournament?</Modal.Header>
    </Modal>
  ))

  return (
    <>
      <DropDownTable.Row>
        <DropDownTable.MainRow>
          <DropDownTable.RowColumn className={cn(styles.table__column, styles.eventName)}>
            {tournament.Name}
          </DropDownTable.RowColumn>
          <DropDownTable.RowColumn className={cn(styles.table__column, styles.date)}>
            {startDate.date}
          </DropDownTable.RowColumn>
          <DropDownTable.RowColumn className={cn(styles.table__column, styles.time)}>
            {startDate.time}
          </DropDownTable.RowColumn>
          <DropDownTable.RowColumn className={cn(styles.table__column, styles.venue)}>
            {VenueTypeEnum[tournament.VenueTypeId] ? VenueTypeEnum[tournament.VenueTypeId] : ''}
          </DropDownTable.RowColumn>               
          <DropDownTable.RowColumn className={cn(styles.table__column, styles.buttons)}>
            <div className="row justify-content-sm-end m-0">
              <DropDownTable.Button onClick={() => handleEdit(draft)} color="blue" filled>Edit</DropDownTable.Button>  
                  
              <DropDownTable.Button onClick={() => handleDelete(draft.Id)} color="red" filled>Delete</DropDownTable.Button>  
            </div>
          </DropDownTable.RowColumn>
        </DropDownTable.MainRow>     
      </DropDownTable.Row>  
      {confirmDeleteModal}
    </>     
  )
}

export default DraftTournamentsRow

