import { useAlertModal, useAppSelector, useAppUser, useIntRouteParam, useUser } from 'components/_hooks'
import { ArticleHeader, HeaderButton, LoadingBox } from 'components/_shared'
import React, { useEffect, useMemo, useState } from 'react'
import { getClanInvitationsIds } from 'store/selectors/clan-invitations-selectors'
import { makeGetConsolesByUserId, makeGetIsUserConsolesLoading } from 'store/selectors/console-selector'
import { makeGetCountry } from 'store/selectors/country-selectors'
import { getIncomingRequestsIds } from 'store/selectors/friend-request-selectors'
import { makeGetGamesIdsByUserId, makeGetIsUserGamesLoading } from 'store/selectors/game-selectors'
import { makeGetRegion } from 'store/selectors/region-selectors'
import { PageMode } from 'store/types/common'
import { cn } from 'utils'
import { LeftPlayerMenu } from './_shared/LeftPlayerMenu'
import { ReadonlyPlayerFields } from './_shared/ReadonlyPlayerFields'
import styles from './PlayerPage.module.scss'
import { PlayerFields, PlayerFieldsModel } from './_shared/PlayerFields'
import { getConsoleTypeOptions } from 'consts/ConsoleType'
import { useDispatch } from 'react-redux'
import { loadUserGames, reLoadUserGames } from 'store/logic/game-logic'
import { loadUserConsoles, reLoadUserConsoles } from 'store/logic/console-logic'
import { UpdateUserModel, useUpdateUser } from 'store/logic/user-logic'
import { loadRegions } from 'store/logic/region-logic'

interface PlayerPageRouteParams {
  userId: string
}

export const PlayerPage = () => {
  const dispatch = useDispatch()
  const appUser = useAppUser()
  const userId: number = useIntRouteParam<PlayerPageRouteParams>(x => x.userId, appUser?.Id)

  const getIsUserGamesLoading = useMemo(makeGetIsUserGamesLoading, [])
  const getIsUserConsolesLoading = useMemo(makeGetIsUserConsolesLoading, [])
  const getRegion = useMemo(makeGetRegion, [])
  const getCountry = useMemo(makeGetCountry, [])
  const getUserGamesIds = useMemo(makeGetGamesIdsByUserId, [])
  const getUserConsoles = useMemo(makeGetConsolesByUserId, [])

  const updateUser = useUpdateUser(userId)

  const { user, isUserLoading } = useUser(userId)
  const incomingRequestsIds = useAppSelector(getIncomingRequestsIds)
  const clanInvitationsIds = useAppSelector(getClanInvitationsIds)
  const isUserGamesLoading = useAppSelector(state => getIsUserGamesLoading(state, { userId }))
  const isUserConsolesLoading = useAppSelector(state => getIsUserConsolesLoading(state, { userId }))
  const userRegion = useAppSelector(state => getRegion(state, { regionId: user?.RegionId }))
  const userCountry = useAppSelector(state => getCountry(state, { countryId: user?.CountryId }))
  const usersGamesIds = useAppSelector(state => getUserGamesIds(state, { userId }))
  const userConsoles = useAppSelector(state => getUserConsoles(state, { userId }))
  const [playerFields, setPlayerFields] = useState<PlayerFieldsModel>()
  const [pageMode, setPageMode] = useState<PageMode>('read')
  const [errorModal, openErrorModal] = useAlertModal()
  
  const loading = isUserLoading || isUserGamesLoading || isUserConsolesLoading

  const isMyProfile = appUser && appUser.Id === userId

  const header = useMemo(() => {
    if (pageMode === 'update') {
      return 'Update Profile'
    } 
    if (isMyProfile) {
      return 'My Profile'
    }

    if (user?.Username) {
      return `${user.Username}'s Profile`
    }

    return 'Player profile'
  }, [isMyProfile, pageMode, user?.Username])
  
  const [isSaving, setIsSaving] = useState(false)

  const handleCancel = () => {
    setPageMode('read')
  }

  const handleUpdateClick = () => {
    setPageMode('update')
  }

  const handleSaveClick = async () => {
    if (!playerFields.Username) {
      openErrorModal('Username is required')
      return
    }
    if (!playerFields.Region) {
      openErrorModal('Region is required')
      return
    }
    if (!playerFields.Country) {
      openErrorModal('Country is required')
      return
    }

    const userModel: UpdateUserModel = {
      Username: playerFields.Username,
      About: playerFields.About,
      Organizationname: user.Organizationname,
      CountryId: playerFields.Country.Id,
      RegionId: playerFields.Region.Id,
      AndroidId: playerFields.AndroidId,
      BattlenetUsername: playerFields.BattlenetUsername,
      Epicgameid: playerFields.Epicgameid,
      IOsId: playerFields.IOsId,
      NintendoId: playerFields.NintendoId,
      Ps4Id: playerFields.Ps4Id,
      RiotUsername: playerFields.RiotUsername,
      SteamId: playerFields.SteamId,
      ValveUsername: playerFields.ValveUsername,
      XboxId: playerFields.XboxId,
      Consoles: playerFields.Consoles.map(x => x.id),
      Games: playerFields.Games,
    }
    setIsSaving(true)
    try {
      await updateUser(userModel)
      dispatch(reLoadUserGames(user.Id))
      dispatch(reLoadUserConsoles(user.Id))
      setPageMode('read')
    }
    catch {
      openErrorModal('Something went wrong')
      return
    }
    finally {
      setIsSaving(false)
    }
  }

  useEffect(() => {
    if (user) {
      setPlayerFields({
        About: user.About,
        AndroidId: user.AndroidId,
        BattlenetUsername: user.BattlenetUsername,
        Epicgameid: user.Epicgameid,
        IOsId: user.Epicgameid,
        NintendoId: user.NintendoId,
        Organizationname: user.Organizationname,
        Ps4Id: user.Organizationname,
        RiotUsername: user.RiotUsername,
        SteamId: user.SteamId,
        Username: user.Username,
        ValveUsername: user.ValveUsername,
        XboxId: user.XboxId,
        Region: userRegion,
        Country: userCountry,
        Games: usersGamesIds,
        Consoles: getConsoleTypeOptions(userConsoles),
      })
    }
  }, [userRegion, userCountry, usersGamesIds, userConsoles, user])

  useEffect(() => {
    if (user) {
      dispatch(loadUserGames(user.Id))
      dispatch(loadUserConsoles(user.Id))
    }
  }, [dispatch, user])
  
  useEffect(() => {
    dispatch(loadRegions)
  }, [dispatch])

  return (
    <>
      <ArticleHeader text={header}>
        {pageMode === 'update' && <HeaderButton disabled={isSaving} onClick={handleCancel} display="always" color="blue" clasName="mr-2">Cancel</HeaderButton>}
        {pageMode === 'update' && <HeaderButton onClick={handleSaveClick} display="always" color="green" loading={isSaving}>Save Profile</HeaderButton>}
        {pageMode === 'read' && isMyProfile && <HeaderButton onClick={handleUpdateClick} display="always">Change Profile</HeaderButton>}
      </ArticleHeader>
      <div className="d-flex mt-5">
        <LeftPlayerMenu
          isEditing={pageMode === 'update'}
          isPublicView={pageMode === 'read' && !isMyProfile}
          userId={userId}
          clanInvitationsIds={clanInvitationsIds}
          incomingRequestsIds={incomingRequestsIds}
        />
        <div className="d-flex flex-column flex-fill pl-4 overflow-hidden">
          <div className={cn(styles.rightTile, 'pb-2')}>Profile</div>
          <LoadingBox loading={loading} size="big">
            {pageMode === 'read' && <ReadonlyPlayerFields
              user={user}
              userRegion={userRegion}
              userCountry={userCountry}
              usersGamesIds={usersGamesIds}
              userConsoles={userConsoles}
            />}
            {pageMode === 'update' && <PlayerFields
              value={playerFields}
              onChange={setPlayerFields}
            />}
          </LoadingBox>
        </div>
      </div>
      {errorModal}
    </>
  )
}
