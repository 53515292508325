import { Button, ButtonProps } from 'components/_shared'
import { cn } from 'utils'
import { useDropDownTableStyles } from './styles/useDropDownTableStyles'

export type DropDownTableButtonProps = ButtonProps

export const DropDownTableButton = ({ className, ...otherProps }: DropDownTableButtonProps) => {
  const styles = useDropDownTableStyles()

  return <Button {...otherProps} className={cn(styles.tableButton, className)} />
}
