import React, { ReactNode } from 'react'
import { cn } from 'utils'
import styles from '../modals.module.scss'

interface WindowSubHeaderProps {
  children: ReactNode
  className?: string
}

export const WindowSubHeader = ({ children, className }: WindowSubHeaderProps) => {
  return (
    <div className="row">
      <div className={cn(styles.window__subheader, className)}>{children}</div>
    </div>
  )
}
