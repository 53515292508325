import { createSelector } from 'reselect'
import { OperationTypes } from 'store/logic/operations-logic'
import { ApplicationState } from '../types/common'
import { makeGetIsOperationWithTypeRunning, makeGetIsOperationWithTypeSuccessOrRunning } from './operations-selectors'

const getState = (state: ApplicationState) => state.disputeDetails

const getById = createSelector(getState, state => state?.byId || {})

const getIdByMatchParticipantId = createSelector(getState, state => state?.idByMatchParticipantId || {})

const getIdsByDisputeId = createSelector(getState, state => state?.idsByDisputeId || [])

export const makeGetDisputeDetailByMatchParticipantId = () =>
  createSelector(
    getById,
    getIdByMatchParticipantId,
    (_: ApplicationState, matchParticipantId: number) => matchParticipantId,
    (byId, byMatchParticipantId, matchParticipantId) => {
      const id = byMatchParticipantId[matchParticipantId]
      return id ? byId[id] : undefined
    }
  )

const getLoadDisputeDetailsOperationType = createSelector(
  (_, disputeId: number) => disputeId,
  disputeId => ({ type: OperationTypes.loadDisputeDetails, objectId: disputeId })
)
export const makeGetIsDisputeDetailsLoading = () => makeGetIsOperationWithTypeRunning(getLoadDisputeDetailsOperationType)
export const makeGetIsDisputeDetailsRequested = () => makeGetIsOperationWithTypeSuccessOrRunning(getLoadDisputeDetailsOperationType)

const getAddDisputeDetailsOperationType = createSelector(
  (_, disputeId: number) => disputeId,
  disputeId => ({ type: OperationTypes.addDisputeDetails, objectId: disputeId })
)
export const makeGetAddDisputeDetailsRunning = () => makeGetIsOperationWithTypeRunning(getAddDisputeDetailsOperationType)

export const makeGetDisputeDetailsIdsByDisputeId = () =>
  createSelector(
    getIdsByDisputeId,
    (_: ApplicationState, disputeId: number) => disputeId,
    (ids, disputeId) => ids[disputeId] || []
  )

export const makeGetDisputeDetailsByDisputeId = () => {
  const getDisputeDetailsIdsByDisputeId = makeGetDisputeDetailsIdsByDisputeId()
  return createSelector(getById, getDisputeDetailsIdsByDisputeId, (byId, ids) => {
    return ids.map(id => byId[id])
  })
}
