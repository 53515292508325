import * as React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { UserUtils } from '../../../../server/utils/user-utils'
import images from 'images'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { loadAppUser } from 'store/logic/app-logic'
import { useSignInUser,useUserLoginHistory } from 'store/logic/auth-logic'
import { Button } from 'components/_shared'
import { useAppSelector } from 'components/_hooks'
import { getIsSignInLoading } from 'store/selectors/auth-selectors'
import { isMobile } from "react-device-detect";

const Login = () => {
  const dispatch = useDispatch()
  const [isOrganizer, setIsOrganizer] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const history = useHistory()
  const signInUser = useSignInUser()
  const isSignInLoading = useAppSelector(getIsSignInLoading)
  const userLoginHistory = useUserLoginHistory();
  const isMobileView = isMobile

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value)
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }

  const clearEmail = () => {
    setEmail('')
  }

  const login = async () => {
    if (!UserUtils.ValidateEmail(email)) {
      alert('Please Enter a Valid Email Address')
      return
    }

    if (!password) {
      alert('Please enter your password')
      return
    }

    try {
      const user = await signInUser({
        email,
        password,
        isOrganizer,
      })

      localStorage.setItem('user', JSON.stringify(user))
      localStorage.setItem('userid', user.id.toString())
      localStorage.setItem('isorganizer', user.isorganizer ? '1' : '0')
      localStorage.setItem('currency', JSON.stringify(user.DefaultCurrencyId))
      localStorage.setItem('timezone', JSON.stringify(user.timezone))
      
      dispatch(loadAppUser)
      await userLoginHistory({IsMobile:isMobileView})
      history.push('/')
    } catch (error) {
      let message = (error as Error).message
      message = message === 'Failed to fetch' ? 'An error has occurred. Please reload the page.' : message
      alert(message)
    }
  }
    
  return (
    <div className="old-kl-css">
      <div id="login-page">
        <div className="video-container">
          <video className="background-video" autoPlay muted loop>
            <source src={images.awesome} type="video/mp4" />
          </video>
        </div>

        <div className="background" />
        <div className="container-fluid">
          <div className="row justify-content-end back-container">
            <a
              className="back"
              onClick={() => {
                window.history.back()
              }}
              style={{ cursor: 'pointer', color: 'white' }}
            >
              Back
            </a>
          </div>
        </div>

        <article className="sign-in d-flex flex-row align-items-center">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1 className="sign-in__header">Let the battle begin!</h1>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-sm-12">
                <div className="d-flex flex-direction-row justify-content-between">
                  <input
                    type="radio"
                    id="role-1"
                    className="sign-in__role"
                    name="role"
                    checked={!isOrganizer}
                    onChange={() => { setIsOrganizer(false) }}
                  />
                  <label htmlFor="role-1" className="sign-in__role-label role-label">
                    <span className="role-label__svg role-label__svg--gamepad">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                        <path d="M480.07 96H160a160 160 0 1 0 114.24 272h91.52A160 160 0 1 0 480.07 96zM248 268a12 12 0 0 1-12 12h-52v52a12 12 0 0 1-12 12h-24a12 12 0 0 1-12-12v-52H84a12 12 0 0 1-12-12v-24a12 12 0 0 1 12-12h52v-52a12 12 0 0 1 12-12h24a12 12 0 0 1 12 12v52h52a12 12 0 0 1 12 12zm216 76a40 40 0 1 1 40-40 40 40 0 0 1-40 40zm64-96a40 40 0 1 1 40-40 40 40 0 0 1-40 40z" />
                      </svg>
                    </span>
                    {/* {'I\'m a Player'} */}
                    {'Member'}
                  </label>
                  <input
                    type="radio"
                    id="role-2"
                    className="sign-in__role"
                    name="role"
                    checked={isOrganizer}
                    onChange={() => { setIsOrganizer(true) }}
                  />
                  <label htmlFor="role-2" className="sign-in__role-label role-label">
                    <span className="role-label__svg role-label__svg--person">
                      <svg focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                        <path d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z" />
                      </svg>
                    </span>
                    {/* {'I\'m a Organizer'} */}
                    {'Organizer'}
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="d-flex flex-column">
                  <div id="email-container">
                    <div className="sign-in__input-title">Email Address</div>
                    <div className="sign-in__container">
                      <input
                        name="email"
                        id="email"
                        type="email"
                        className="sign-in__default-input"
                        tabIndex={1}
                        placeholder="Email"
                        value={email}
                        onChange={handleEmailChange}
                        onKeyPress={async (event: React.KeyboardEvent<HTMLButtonElement>) => {
                          if (event.key === 'Enter' || event.charCode === 13) {
                            await login()
                          }
                        }}
                      />
                      <button
                        className="sign-in__clean-button"
                        onClick={clearEmail}
                      />
                    </div>
                  </div>
                </div>
                <div id="password-container">
                  <div className="sign-in__input-title">Password</div>
                  <div className="sign-in__container">
                    <input
                      name="password"
                      id="password"
                      type={showPassword ? 'text' : 'password'}
                      tabIndex={2}
                      className="sign-in__default-input"
                      placeholder="Enter password"
                      value={password}
                      onChange={handlePasswordChange}
                      onKeyPress={async (event: React.KeyboardEvent<HTMLButtonElement>) => {
                        if (event.key === 'Enter' || event.charCode === 13) {
                          await login()
                        }
                      }}
                    />
                    <button
                      className="sign-in__visible-button"
                      onClick={togglePasswordVisibility}
                    />
                  </div>
                </div>
                <div className="sign-in__password-detail-container m-40 justify-content-end">
                  <div className="col-md-6 col-sm-6 sign-in__forgot-container">
                    <div>
                      <Link to="/password-recovery" className="color-blue">
                        Forgot password?
                      </Link>
                    </div>
                  </div>
                </div>
                <div id="login-container">
                  <Button
                    onClick={login}
                    className="sign-in__login blue-button blue-button--filled"
                    loading={isSignInLoading}
                  >
                    login
                  </Button>
                </div>
              </div>
            </div>
            <div className="row m-40">
              <div className="col-md-8 col-sm-12 sign-in__no-account">
                <div>
                  {'Don\'t have Account?'}
                  <Link to="/register" className="color-blue">
                    &nbsp;Sign up
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>
    </div>
  )
}

export default Login
