import { LoadingBox, Pagination } from 'components/_shared'
import TournamentCardV2 from 'components/_shared/TournamentCard/TournamentCardV2'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadMyOrganizerActiveTournaments } from 'store/logic/pages/organizer-events-page-logic'
import { TournamentFilters } from 'store/logic/tournaments-logic'
import { getAllMyOrganizerActiveTournamentsCount, getIsMyOrganizerActiveTournamentsLoading, getMyOrganizerActiveTournamentsIds, getOrganizerEventsPageFilters } from 'store/selectors/pages/organizer-events-page-selectors'
import { ApplicationState } from 'store/types/common'
import { organizerEventsFiltersChanged } from 'store/types/pages/organizer-events-page-types'
import NotFoundTournaments from './NotFoundTournaments'

interface Props {
  filters: TournamentFilters
}
const pageSize = 8

const ActiveTournaments = ({filters} : Props) => {

  const dispatch = useDispatch()

  const storedFilters = useSelector((state: ApplicationState) => getOrganizerEventsPageFilters(state)) 

  const page = storedFilters.currentPage
  const tournaments = useSelector(getMyOrganizerActiveTournamentsIds)
  const allTournamentsCount = useSelector(getAllMyOrganizerActiveTournamentsCount)
  const isTournamentsLoading = useSelector(getIsMyOrganizerActiveTournamentsLoading)

  const loadTournaments = useCallback((filters: TournamentFilters, pageNumber: number) => {  
    dispatch(loadMyOrganizerActiveTournaments(filters, {
      page: pageNumber,
      pageSize,
    }))  
  }, [dispatch])

  useEffect(() => {
    loadTournaments(filters, page) 
  }, [dispatch, filters, loadTournaments, page])

  const onChangePage = (pageNumber: number) => {
    dispatch(organizerEventsFiltersChanged(
      {
        filters: {
          ...storedFilters,
          currentPage: pageNumber,          
        },
      }
    ))
  }

  return (
    <>
      <LoadingBox size="big" loading={isTournamentsLoading}>
        <div className="row m-0--31">
          {tournaments.length == 0 && (
            <NotFoundTournaments />
          )}
          {tournaments.map(id =>
            <TournamentCardV2 key={id} tournamentId={id} displayBannerOption={null} />
          )}
        </div>
      </LoadingBox>
      {tournaments.length > 0 && (
        <Pagination page={page} itemsPerPage={pageSize} allItemsCount={allTournamentsCount} onChangePage={onChangePage} />
      )}
    </>
  )
}

export default ActiveTournaments
