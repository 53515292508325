import { ContainerName } from 'components/_hooks'
import { SliderTournamentCover } from 'components/_shared'
import { HomePopupViewModel } from 'models/home-page-popup-viewmodel'
import React from 'react'
import { cn } from 'utils'
import styles from './HomePopupModalNew.module.scss'
import SliderHomePopupModalInfo from './SliderHomePopupModalNewInfo'

interface Props {
  onClose: CallableFunction
  homePopup: HomePopupViewModel | undefined
}

const HomePopupModalNew = ({ onClose, homePopup }: Props) => {
  
  return (
    <>
      {homePopup && 
        <>
          {homePopup && homePopup.Id && homePopup.DisplayStart && (
            <>
                <div className={cn(styles.home_popup)}>
                  <div className={cn(styles.home_popup_title)}>
                    <div className={cn(styles.title)}>{homePopup.Title}</div>
                  </div>
                  <div className={cn(styles.popup_image_container)}>
                    <SliderTournamentCover containerName={ContainerName.HomeAdPoup} coverId={homePopup.ImageId} isActive={true} autoPlay={true} isStopMouseEvent={true} />
                  </div>
                  <div className={cn(styles.popup_info)}>
                    {homePopup && (
                      <SliderHomePopupModalInfo homePopup={homePopup} />
                    )}
                  </div>
                </div>
            </>
          )}
        </>
      }
    </>
  )
}

export default HomePopupModalNew
