import * as React from 'react'
import MyTicketsCard from './MyTicketsCard'
import { TournamentEventViewModel, TournamentEventPostModelView } from '../../models/tournament-event-view-model'
import { UserModel } from '../../models/user-models'
import { GeneralUtils } from '../../server/utils/general-utils'
import { TournamentUtils } from '../../server/utils/tournament-utils'
import MyTicketsSearchBar from './MyTicketsSearchBar'
import './MyTickets.scss'

interface Props {}

interface State {
  pageNo: number
  totalPages: number
  allEventList: TournamentEventViewModel[]
  eventList: TournamentEventViewModel[]
  tournamentFilters: TournamentEventPostModelView
  filterId: string
  filterClass: string
}

class MyTickets extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      pageNo: 1,
      totalPages: 1,
      eventList: [],
      allEventList: [],
      tournamentFilters: Object(),
      filterId: '',
      filterClass: 'row m-0--30 p-r-15 article__selects-popup',
    }

    this.getLiveEventList = this.getLiveEventList.bind(this)
    this.tournamentFiltersChange = this.tournamentFiltersChange.bind(this)
    this.refereshMyTickets = this.refereshMyTickets.bind(this)
  }

  async componentDidMount() {
    await this.getLiveEventList()
  }

  async getLiveEventList() {
    const tournamentFilters = this.state.tournamentFilters
    let eventList = []
    eventList = []
    const pageNo = this.state.pageNo
    const user: UserModel = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : Object()
    tournamentFilters.pageNo = pageNo
    tournamentFilters.userId = user.id
    GeneralUtils.showHideLoadingDiv(true)
    tournamentFilters.venueTypeId = 2
    const result = await TournamentUtils.UserTicketDetail(tournamentFilters)
    GeneralUtils.showHideLoadingDiv(false)

    if (!result.isError) {
      eventList = [...result.data]
      this.setState({
        eventList,
        allEventList: result.data,
        totalPages: result.data[0].totalPages,
      })
    } else {
      GeneralUtils.showError(result.message)
      this.setState({ eventList: [] })
    }
  }

  tournamentFiltersChange(_tournamentFilters: TournamentEventPostModelView) {
    if (_tournamentFilters.name && _tournamentFilters.name.length > 0 && _tournamentFilters.name != this.state.tournamentFilters.name) {
      let eventList = this.state.eventList
      eventList = this.state.allEventList.filter(x => x.Name.toLocaleLowerCase().includes(_tournamentFilters.name.toLocaleLowerCase()))
      if (eventList && eventList.length < 1) {
        this.setState({ eventList: this.state.allEventList })
      }
    } else {
      this.setState({ eventList: [], totalPages: 1, tournamentFilters: _tournamentFilters }, async () => {
        await this.getLiveEventList()
      })
    }
  }
  filterButtonHandler() {
    this.setState({
      filterId: 'live-events-filters',
      filterClass: 'row m-0--30 p-r-15 article__selects-popup article__selects-popup--opened',
    })
  }

  async refereshMyTickets(){
    await this.getLiveEventList()
  }

  render() {
    const {} = this.props
    const { eventList, totalPages, pageNo, tournamentFilters } = this.state
    const pages = []
    for (let i = 1; i <= totalPages; i++) {
      if (i == pageNo + 5) {
        pages.push(<div className="article__page-dots">...</div>)
      } else if ((!(i < pageNo - 1) && (i < pageNo + 5 || i == totalPages)) || i == 1) {
        pages.push(
          <a
            className={`article__page ${i == pageNo ? 'article__page--active' : ''}`}
            href="javascript:void(0)"
            onClick={() => {
              this.setState({ pageNo: i }, () => {
                this.getLiveEventList()
              })
            }}
          >
            {' '}
            {i}
          </a>
        )
      }
    }

    return (
      <>
        <div className="row m-0" />
        <div className="col-md-12 ticket-row-wrapper">
          <div className="justify-content-between">
            <h1 className="article__header mb-5">My Tickets</h1>
            <a
              className="article__open-selects blue-button blue-button--filled"
              id="open-selects"
              onClick={() => {
                document.getElementById('tickets-filter').className = 'row m-0--15 p-r-15 article__selects-popup article__selects-popup--opened'
              }}
              href="javascript:void(0)"
            >
              Filter Options
            </a>
          </div>
          <div id="tickets-filter" className="row m-0--15 p-r-15 article__selects-popup">
            <div className="col-12">
              <div className="row justify-content-lg-between justify-content-sm-start align-items-start">
                <div className="col-12 article__selects-popup-header">
                  Filter Options
                  <a
                    className="article__selects-popup-close"
                    onClick={() => {
                      document.getElementById('tickets-filter').className = 'row m-0--30 p-r-15 article__selects-popup'
                    }}
                  />
                </div>

                <MyTicketsSearchBar
                  tournamentFilters={tournamentFilters}
                  tournamentFiltersChange={this.tournamentFiltersChange}
                  filterId={this.state.filterId}
                  filterClass={this.state.filterClass}
                />
              </div>
            </div>
          </div>

          {eventList &&
            eventList.map((event, i) => {
              return <MyTicketsCard event={event} i={i} refereshMyTickets={this.refereshMyTickets} />
            })}

          <div className="row article__hr" />
            
          {eventList && eventList.length > 0 && (
            <div className="row justify-content-center mtb-30">
              <div className="col-auto article__pages-container">
                <div className="row align-items-center">
                  {pageNo > 1 && (
                    <a
                      className="article__prev-page"
                      href="javascript:void(0)"
                      onClick={() => {
                        this.setState({ pageNo: pageNo - 1 }, () => {
                          this.getLiveEventList()
                        })
                      }}
                    >
                      PREV
                    </a>
                  )}
                  <div className="col-auto">
                    <div className="row align-items-center" id="pages">
                      {pages}
                    </div>
                  </div>
                  {pageNo < totalPages && (
                    <a
                      className="article__next-page"
                      href="javascript:void(0)"
                      onClick={() => {
                        this.setState({ pageNo: pageNo + 1 }, () => {
                          this.getLiveEventList()
                        })
                      }}
                    >
                      NEXT
                    </a>
                  )}
                </div>
              </div>
            </div>
          )}
            </div>
      </>
    )
  }
}

export default MyTickets
