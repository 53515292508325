import { ContainerName, useAppSelector, useGetImageSize } from 'components/_hooks'
import { Img, KlLink } from 'components/_shared'
import CalendarSVG from 'components/_svg/CalendarSVG'
import React, { useMemo } from 'react'
import { makeGetBlog } from 'store/selectors/blog-selectors'
import { defaultFormatDate } from 'utils'
import styles from '../Home.module.scss'

interface BlogCardProps {
  id: number,
  containerName?:ContainerName
}

export const BlogCard = ({id,containerName}: BlogCardProps) => {

  const getBlog = useMemo(makeGetBlog, [])

  const blog = useAppSelector(state => getBlog(state, { blogId: id }))
  const getImageSize = useGetImageSize(containerName)

  return (
    <>
      <div className={styles.item}>
        <KlLink to={`/news/${blog?.Id}`}>
          <span className={styles.thumbnail}>
            <Img  parentContainerSize={getImageSize} imageId={blog?.PreviewImageId} alt={blog?.Title}/>
          </span>
          <span className={styles.info}>
            <span className={styles.head} title={blog?.Title}>
              {blog?.Title}
            </span>
            <span className={styles.detailDate}>
              <CalendarSVG /> {defaultFormatDate(blog?.Date)}
            </span>
          </span>
        </KlLink>
      </div>
    </>
  )
}
