import { createSelector } from 'reselect'
import { OperationTypes } from 'store/logic/operations-logic'
import { ApplicationState } from '../types/common'
import { makeGetIsAnyOperationWithTypeRunning, makeGetIsOperationWithTypeRunning, makeGetIsOperationWithTypeSuccessOrRunning } from './operations-selectors'

const getState = (state: ApplicationState) => state.clans

const getById = createSelector(getState, state => state?.byId || {})

export const makeGetClan = () =>
  createSelector(
    getById,
    (_: ApplicationState, id: number) => id,
    (byId, id) => byId[id]
  )

const getLoadClanOperationType = createSelector(
  (_, { clanId }: { clanId: number }) => clanId,
  userId => ({ type: OperationTypes.loadClan, objectId: userId })
)
export const makeGetIsClanLoading = () => makeGetIsOperationWithTypeRunning(getLoadClanOperationType)
export const makeGetIsClanRequested = () => makeGetIsOperationWithTypeSuccessOrRunning(getLoadClanOperationType)

export const getIsAddClanLoading = makeGetIsAnyOperationWithTypeRunning(() => ({
  type: OperationTypes.addClan,
}))
export const getIsUpdateClanLoading = makeGetIsAnyOperationWithTypeRunning(() => ({
  type: OperationTypes.updateClan,
}))

