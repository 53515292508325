import { createSelector } from 'reselect'
import { OperationTypes } from 'store/logic/operations-logic'
import { kombatlinkOrganizer } from 'store/types/user-types'
import { compareStrings } from 'utils'
import { ApplicationState } from '../types/common'
import { makeGetIsAnyOperationWithTypeRunning, makeGetIsOperationWithTypeRunning, makeGetIsOperationWithTypeSuccessOrRunning } from './operations-selectors'

const getState = (state: ApplicationState) => state.users

const getById = createSelector(getState, state => state?.byId || {})

const getOrganizersIds = createSelector(getState, state => state?.organizersIds ?? [])

const getTopFriendsIdsByUserId = createSelector(getState, state => state?.topFriendsIdsByUserId ?? [])
const getTopMembersIdsByOrganizerId = createSelector(getState, state => state?.topMembersIdsByOrganizerId || [])

export const makeGetTotalOnlineUsersCount = createSelector(getState, state => state?.totalOnlineUsersCount)
export const makeGetIsChangeTotalOnlineUsersCount = createSelector(getState, state => state?.isChangeTotalOnlineUsersCount)

export const makeGetUser = () =>
  createSelector(
    getById,
    (_: ApplicationState, id: number) => id,
    (byId, id) => byId[id]
  )

export const makeGetUsers = () =>
  createSelector(
    getById,
    (_: ApplicationState, ids: number[]) => ids,
    (byId, ids) => ids.map(id => byId[id]).filter(u => !!u)
  )

export const makeGetFriendsIds = () => 
  createSelector(
    getTopFriendsIdsByUserId,
    (_: ApplicationState, { userId }: { userId: number }) => userId,
    (ids, userId) => (ids[userId]?.ids ?? [])
  )

export const makeGetTopFriendsIds = () => 
  createSelector(
    getTopFriendsIdsByUserId,
    (_: ApplicationState, { userId }: { userId: number }) => userId,
    (ids, userId) => (ids[userId]?.ids ?? []).slice(0, 12)
  )

export const makeGetFriendsCount = () => 
  createSelector(
    getTopFriendsIdsByUserId,
    (_: ApplicationState, { userId }: { userId: number }) => userId,
    (ids, userId) => ids[userId]?.allFriendsCount ?? 0
  )

export const makeGetTopFriends = () => 
  createSelector(
    getTopFriendsIdsByUserId,
    getById,
    (_: ApplicationState, { userId }: { userId: number }) => userId,
    (ids, byId, userId) => (ids[userId]?.ids ?? []).slice(0, 12).map(id => byId[id])
  )


const getLoadTopFriendsOperationType = createSelector(
  (_: ApplicationState, { userId }: { userId: number }) => userId,
  userId => ({ type: OperationTypes.loadTopUserFriends, objectId: userId })
)
export const makeGetIsTopFriendsLoading = () => makeGetIsOperationWithTypeRunning(getLoadTopFriendsOperationType)
export const makeGetIsTopFriendsRequested = () => makeGetIsOperationWithTypeSuccessOrRunning(getLoadTopFriendsOperationType)

export const getOrganizersLoading = makeGetIsAnyOperationWithTypeRunning(() => ({
  type: OperationTypes.loadOrganizers,
}))

export const getOrganizersRequested = makeGetIsOperationWithTypeSuccessOrRunning(() => ({
  type: OperationTypes.loadOrganizers,
  objectId: null,
}))

export const getOrganizers = createSelector(
  getById,
  getOrganizersIds,
  (byId, ids) => ids.map(id => byId[id])
    .sort((a, b) => compareStrings(a.Organizationname, b.Organizationname))
    .concat([kombatlinkOrganizer])
)

const getLoadUserOperationType = createSelector(
  (_: ApplicationState, { userId }: { userId: number }) => userId,
  userId => ({ type: OperationTypes.loadUser, objectId: userId })
)
export const makeGetIsUserLoading = () => makeGetIsOperationWithTypeRunning(getLoadUserOperationType)
export const makeGetIsUserRequested = () => makeGetIsOperationWithTypeSuccessOrRunning(getLoadUserOperationType)

const getPlayerProfileByUserIdOperationType = createSelector(
  (_: ApplicationState, { userId }: { userId: number }) => userId,
  userId => ({ type: OperationTypes.loadPlayerProfileByUserId, objectId: userId })
)
export const makeGetIsPlayerProfileByUserIdLoading = () => makeGetIsOperationWithTypeRunning(getPlayerProfileByUserIdOperationType)
export const makeGetIsPlayerProfileByUserIdRequested = () => makeGetIsOperationWithTypeSuccessOrRunning(getPlayerProfileByUserIdOperationType)

const getUpdateUserOperationType = createSelector(
  (_: ApplicationState, { userId }: { userId: number }) => userId,
  userId => ({ type: OperationTypes.updateUser, objectId: userId })
)
export const makeGetIsUpdateUserLoading = () => makeGetIsOperationWithTypeRunning(getUpdateUserOperationType)

const getSendFriendRequestOperationType = createSelector(
  (_: ApplicationState, { userId }: { userId: number }) => userId,
  userId => ({ type: OperationTypes.sendFriendRequest, objectId: userId })
)
export const makeGetIsSendFriendRequestLoading = () => makeGetIsOperationWithTypeRunning(getSendFriendRequestOperationType)


export const getTopOrganizerMembers =  createSelector(
  (state: ApplicationState) => state.users.byId,
  getTopMembersIdsByOrganizerId,
  (_: ApplicationState, organizerId: number) => organizerId,
  (byId, idsByOrganizer, organizerId) => (idsByOrganizer[organizerId]?.ids || []).map(id => byId[id]) ?? []  
)  

export const getOrganizerMembersCount = createSelector(
  getTopMembersIdsByOrganizerId,
  (_: ApplicationState, organizerId: number ) => organizerId,
  (idsByOrganizer, organizerId) => idsByOrganizer[organizerId]?.allMembersCount ?? 0
)

const getTopOrganizerMembersOperationType = createSelector(
  (_: ApplicationState, organizerId: number ) => organizerId,
  organizerId => ({ type: OperationTypes.loadTopOrganizerMembers, objectId: organizerId })
)

export const makeGetTopOrganizerMembersLoading = () => makeGetIsAnyOperationWithTypeRunning(getTopOrganizerMembersOperationType)
export const makeGetTopOrganizerMembersRequested = () => makeGetIsOperationWithTypeSuccessOrRunning(getTopOrganizerMembersOperationType)

const getUpdateAvatarOperationType = createSelector(
  (_: ApplicationState, { userId }: { userId: number }) => userId,
  userId => ({ type: OperationTypes.updateAvatar, objectId: userId })
)
export const makeGetIsUpdateAvatarLoading = () => makeGetIsOperationWithTypeRunning(getUpdateAvatarOperationType)

const getUpdateCoverImageOperationType = createSelector(
  (_: ApplicationState, { userId }: { userId: number }) => userId,
  userId => ({ type: OperationTypes.updateCoverImage, objectId: userId })
)
export const makeGetIsUpdateCoverImageLoading = () => makeGetIsOperationWithTypeRunning(getUpdateCoverImageOperationType)
