import { Modal, ModalType, ModalWindowAnimationType } from 'components/_modals'
import { useDialogWithParameter } from 'components/_hooks'
import { useKlHistory } from 'components/_hooks/useKlHistory'
import LobbyDetailModal from 'components/_shared/TournamentBracket/_shared/LobbyDetailModal'
import { NotificationType } from 'consts'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GeneralUtils } from 'server/utils/general-utils'
import { getFriendRequest, useAcceptFriendRequest } from 'store/logic/friend-request-logic'
import { notificationActionCreators, useReadNotification } from 'store/logic/notification-logic'
import { reLoadTopFriends } from 'store/logic/user-logic'
import { getAppUserRole } from 'store/selectors/app-selectors'
import { dequeueNotification, NotificationModel } from 'store/types/notification-types'
import { fromNow } from 'utils'
import NotificationButton from '../NotificationRow/NotificationButton'
import styles from './Notifications.module.scss'
import { participantsActionCreators } from 'store/logic/participants-logic'
import { lineUpsActionCreators } from 'store/logic/line-ups-logic'
import { notificationPageSize } from '../NotificationsList/NotificationsList'
import StorageUtils from 'server/utils/storage-utils'

interface Props {
  onClose: CallableFunction
  notification: NotificationModel
}

const NotificationModal = ({ onClose, notification }: Props) => {
  const dispatch = useDispatch()
  const acceptRequest = useAcceptFriendRequest()
  const readNotification = useReadNotification(notification.Id)
  const history = useKlHistory()
  const userRole = useSelector(getAppUserRole)
  const [showActionModal, setShowActionModal] = useState(false)

  const closeNotification = async (skipNotification = false) => {
    try {
      dispatch(dequeueNotification(notification.Id))
      if (!skipNotification) {
        await readNotification()
      }
      if (notification.Type === NotificationType.OrganizerAddSoloPlayer || notification.Type === NotificationType.OrganizerRemoveSoloPlayer || notification.Type === NotificationType.OrganizerAddClanLeaderAndMembers || notification.Type === NotificationType.OrganizerRemoveClanAndMembers
        || notification.Type === NotificationType.OrganizerRemoveClanMember || notification.Type === NotificationType.SendClanLeaderWhenOrganizerRemoveClanMember
        || notification.Type === NotificationType.AddClanMemberByOrganizer || notification.Type === NotificationType.SendClanLeaderWhenOrganizerAddClanMember
        || notification.Type === NotificationType.RefundParticipantClanMemberTicket || notification.Type === NotificationType.TournamentInitialized) {
        await processOrganizerAddSoloPlayerNotification(notification, false)
      }
    }
    finally {
      onClose()
    }
  }

  const [matchSettingsModal, openMatchSettingsModal] = useDialogWithParameter<NotificationModel>((notification, closeDialog) => (
    <LobbyDetailModal
      onClose={() => {
        closeDialog()
        void closeNotification()
      }}
      matchId={notification.SubjectId}
      isAnimated
      isShow={showActionModal}
    />
  ))

  const moveToLink = () => {
    if (notification.Button?.Uri) {
      history.push(notification.Button.Uri)
    }
  }
  const processOrganizerAddSoloPlayerNotification = async (notification: NotificationModel, isClose: boolean) => {
    if (notification.Type === NotificationType.OrganizerAddSoloPlayer || notification.Type === NotificationType.OrganizerRemoveSoloPlayer || notification.Type === NotificationType.OrganizerAddClanLeaderAndMembers || notification.Type === NotificationType.OrganizerRemoveClanAndMembers
      || notification.Type === NotificationType.OrganizerRemoveClanMember || notification.Type === NotificationType.SendClanLeaderWhenOrganizerRemoveClanMember
      || notification.Type === NotificationType.AddClanMemberByOrganizer || notification.Type === NotificationType.SendClanLeaderWhenOrganizerAddClanMember || notification.Type === NotificationType.LiveTournamentParticipantTicket
      || notification.Type === NotificationType.RefundParticipantClanMemberTicket || notification.Type === NotificationType.LiveMainTournamentParticipantTicket || notification.Type === NotificationType.LiveMainTournamentParticipantTicketForSolo
      || notification.Type === NotificationType.TournamentInitialized
      ) {
      if (notification.Button?.Uri) {
        const tournamentDetailid = notification.SubjectId
        if(notification.Type === NotificationType.LiveTournamentParticipantTicket || notification.Type === NotificationType.LiveMainTournamentParticipantTicket || notification.Type === NotificationType.LiveMainTournamentParticipantTicketForSolo){
          if(!isClose){
            dispatch(participantsActionCreators.reloadParticpants(tournamentDetailid))
            dispatch(lineUpsActionCreators.reloadLineUps(tournamentDetailid))
            await closeNotification(true)
            await readNotification()
            
          }
          else{
            StorageUtils.setCartTournamentDetailId(tournamentDetailid)
            await readNotification()
            onClose()
            window.location.href='/cart'
          }
         
        }
        else{
          if(notification.Type === NotificationType.OrganizerRemoveSoloPlayer || notification.Type === NotificationType.OrganizerRemoveClanAndMembers || notification.Type === NotificationType.OrganizerRemoveClanMember
            || notification.Type === NotificationType.SendClanLeaderWhenOrganizerRemoveClanMember)
          {
            dispatch(participantsActionCreators.notificationfordeleteAllParticipantsFromState(tournamentDetailid))
          }
  
          dispatch(participantsActionCreators.reloadParticpants(tournamentDetailid))
          dispatch(lineUpsActionCreators.reloadLineUps(tournamentDetailid))
          if (isClose) {
            await closeNotification()
            moveToLink()
          }
          else{
            if (window.location.href.includes(notification.Button?.Uri)) {
              moveToLink()
            }
          }
        }
        
      }
    }
  }
  const proccessNotification = async () => {
    switch (notification.Type) {
      case NotificationType.SendFriendRequest: {
        try {
          const friendRequest = await getFriendRequest(notification.SubjectId)
          await acceptRequest(friendRequest.Id)

          refreshNotifications()
          dispatch(reLoadTopFriends(friendRequest.ToUserId))
          dispatch(reLoadTopFriends(friendRequest.FromUserId))
        } catch (error) {
          GeneralUtils.showError(error)
        }
        await closeNotification(true)
        moveToLink()
        break
      }
      case NotificationType.MatchHostSelected: {
        handleAction(() => openMatchSettingsModal(notification))
        break
      }
      case NotificationType.HostSettingsReminder: {
        if (userRole == 'Player') {
          openMatchSettingsModal(notification)
          await closeNotification()
        } else {
          moveToLink()
        }
        await closeNotification()
        break
      }
      case NotificationType.TournamentParticipantJoin:
        {
          if (notification.Button?.Uri) {
            const tournamentDetailid = Number(notification.Button.Uri.replace(/\D/g, ''))
            dispatch(participantsActionCreators.reloadParticpants(tournamentDetailid))
            dispatch(lineUpsActionCreators.reloadLineUps(tournamentDetailid))
            await closeNotification()
            moveToLink()
          }
          break
        }
      case NotificationType.TournamentParticipantUnjoin:
        {
          const tournamentDetailid = Number(notification.Button.Uri.replace(/\D/g, ''))
          dispatch(participantsActionCreators.notificationfordeleteAllParticipantsFromState(tournamentDetailid))
          dispatch(participantsActionCreators.reloadParticpants(tournamentDetailid))
          dispatch(lineUpsActionCreators.reloadLineUps(tournamentDetailid))
          await closeNotification()
          moveToLink()
          break
        }
      case NotificationType.OrganizerRemoveSoloPlayer:
      case NotificationType.OrganizerAddSoloPlayer:
      case NotificationType.LiveTournamentParticipantTicket:
      case NotificationType.RefundParticipantClanMemberTicket:
      case NotificationType.LiveMainTournamentParticipantTicket:
      case NotificationType.LiveMainTournamentParticipantTicketForSolo:
      case NotificationType.TournamentInitialized:
      {
          await processOrganizerAddSoloPlayerNotification(notification, true)
          break
      }
      default: {
        await closeNotification()
        moveToLink()
      }
    }
  }


  const handleAction = (openModal: CallableFunction) => {
    setShowActionModal(true)
    openModal()
  }

  const refreshNotifications = () => {
    dispatch(notificationActionCreators.loadNotifications(0, notificationPageSize))
    dispatch(notificationActionCreators.loadUnreadNotificationsCount())
  }

  return (
    <Modal.Container>
      <Modal.Background />
      <Modal
        hideOk
        hideCancel
        onClose={closeNotification}
        buttonColor="blue"
        className={styles.notificationModal}
        show={!showActionModal}
        animationType={ModalWindowAnimationType.prev}
        type={ModalType.animated}
      >
        <Modal.Header>{notification.Message}</Modal.Header>
        <Modal.SubHeader>{fromNow(notification.CreatedDate)}</Modal.SubHeader>
        {notification.Button &&
          <Modal.ButtonsContainer>
            <NotificationButton notification={notification} onButtonClickAsync={proccessNotification} />
          </Modal.ButtonsContainer>
        }
      </Modal>
      {matchSettingsModal}
    </Modal.Container>
  )
}

export default NotificationModal
