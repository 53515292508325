import React from 'react'
import { DropDownTable, LoadingBox, Pagination } from 'components/_shared'
import { useDispatch, useSelector } from 'react-redux'
import { getAllApprovedMatchLinkCount, getApprovedMatchLinksLoading, makeGetApprovedMatchLinks } from 'store/selectors/match-links-selectors'
import { matchLinksActionCreators } from 'store/logic/match-links-logic'
import { ApplicationState, defaultPageSize } from 'store/types/common'
import styles from './events.module.scss'
import { useEffect } from 'react'
import LinkRow from './LinkRow'
import { useHighlight } from 'components/_hooks/useHighlight'

interface Props {
  matchLinkId?: number
}


const ApprovedLinks = ({matchLinkId}: Props) => {
  const dispatch = useDispatch()
  const [page, setPage] = React.useState(0)
  const itemsCount = useSelector(getAllApprovedMatchLinkCount)

  const loadMatchLinks = React.useCallback(() => {
    dispatch(matchLinksActionCreators.loadApprovedMatchLinks({page, pageSize: defaultPageSize}))
  }, [dispatch, page])

  useEffect(() => {
    loadMatchLinks()
  }, [loadMatchLinks])
  
  const [highlightedId, removeHighlighted] = useHighlight(matchLinkId)
  const getApprovedMatchLinks = React.useMemo(makeGetApprovedMatchLinks, [])
  const matchLinks = useSelector((state: ApplicationState) => getApprovedMatchLinks(state))

  const isLoading =  useSelector(getApprovedMatchLinksLoading)

  return (
    <>
      <DropDownTable.HeadersRow className={styles.table__headersRow}>
        <DropDownTable.Column className={styles.table__column__tournament}>Tournament</DropDownTable.Column>
        <DropDownTable.Column className={styles.table__column__date}>Date</DropDownTable.Column>
        <DropDownTable.Column className={styles.table__column__round}>Round</DropDownTable.Column>
        <DropDownTable.Column className={styles.table__column__match}>Match</DropDownTable.Column>
        <DropDownTable.Column className={styles.table__column__venue}>Venue</DropDownTable.Column>
        <DropDownTable.Column className={styles.table__column__brackets}>Brackets</DropDownTable.Column>      
        <DropDownTable.Column className={styles.table__column__type}>Type</DropDownTable.Column>
        <DropDownTable.Column className={styles.table__column__link}>Link</DropDownTable.Column>
        <DropDownTable.Column className={styles.table__column__buttons} /> 
      </DropDownTable.HeadersRow>
      <LoadingBox loading={isLoading} size="big">
        <>
          {matchLinks.map(matchLink => (
            <LinkRow 
              key={matchLink.Id} 
              matchLink={matchLink}
              onChangeCount={loadMatchLinks}
              highlighted={matchLink.Id === highlightedId}
              removeHighlighted={removeHighlighted} 
            />        
          ))}
        </>
      </LoadingBox>
      <Pagination itemsPerPage={defaultPageSize} allItemsCount={itemsCount} onChangePage={setPage} page={page} /> 
    </>
  )
}

export default ApprovedLinks
