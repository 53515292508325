import React from 'react'
import ReactPlayer from 'react-player'

interface Props {
  url: string
  height?: string
  width?: string
  className?: string
  playing?: boolean
}

interface State {
  playing?: boolean
}

class ReactPlayerComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      playing: true,
    }
  }

  componentDidMount() {
    this.setState({ playing: this.props.playing })
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.playing !== this.props.playing) {
      this.setState({ playing: this.props.playing })
    }
  }

  render() {
    const { url, width, height } = this.props
    const { playing } = this.state
    return (
      <>
        <ReactPlayer className="" url={url} width={width ? width : '100%'} height={height ? height : '100%'} controls playing={playing} />
      </>
    )
  }
}

export default ReactPlayerComponent
