import { Reducer } from 'redux'
import { PlayerBattlesAndLoyaltyPointKnownAction, PlayerBattlesAndLoyaltyPointState, PLAYER_BATTLES_AND_LOYALTY_POINTS_LOADED } from 'store/types/player-battle-and-loyaltypoint-type'

const initialHeaderState: PlayerBattlesAndLoyaltyPointState = {
  PlayerBattlesAndLoyaltyPoints: undefined
}

const reducer: Reducer<PlayerBattlesAndLoyaltyPointState, PlayerBattlesAndLoyaltyPointKnownAction> = (state: PlayerBattlesAndLoyaltyPointState | undefined = initialHeaderState, action: PlayerBattlesAndLoyaltyPointKnownAction) => {
  switch (action.type) {
    case PLAYER_BATTLES_AND_LOYALTY_POINTS_LOADED: {
      return {
        ...state,
        PlayerBattlesAndLoyaltyPoints: action.payload.PlayerBattlesAndLoyaltyPoints
      }
    }
    default:
      return state
  }
}

export default reducer