import { useAppSelector } from 'components/_hooks'
import { Button, ButtonColor } from 'components/_shared'
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useOpenChat } from 'store/chat/chat/chat-hooks'
import { loadTournamentChat } from 'store/chat/chat/chat-logic'
import { makeGetTournamentChat } from 'store/chat/chat/chat-selectors'
import { getAppUser } from 'store/selectors/app-selectors'
import styles from './TournamentChatButton.module.scss'

interface Props {
  tournamentDetailId: number
  color?: ButtonColor
}

const TournamentChatButton = ({tournamentDetailId, color = 'blue'}: Props) => {
  
  const user = useSelector(getAppUser)
  const dispatch = useDispatch()

  const openChat = useOpenChat()
  useEffect(() => {
    if (user?.Id) {
      dispatch(loadTournamentChat(tournamentDetailId))
    }
  }, [dispatch, tournamentDetailId, user?.Id])

  const getTournamentChat = useMemo(makeGetTournamentChat, [])
  const chat = useAppSelector(state => getTournamentChat(state, {torunamentDetailId: tournamentDetailId}))

  const handleOpenChat = () => {
    if (chat?.Id) {
      openChat(chat.Id)
    }
  }

  if (!chat) {
    return (
      <>
      </>
    )
  }

  
  return (
    <div className="col-sm-auto col-12">
      <Button color={color} filled className={styles.chatButton} onClick={handleOpenChat}>  
        Group chat
      </Button>   
    </div>
  )
}

export default TournamentChatButton
