import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { StripeUtils } from 'server/utils/stripe-utils'
import { UserModel } from 'models/user-models'
import styles from './Home.module.scss'
import TopTournaments from './_shared/TopTournaments'
import { DataLoader } from './_shared/DataLoader'
import { GameLists } from './_shared/GameLists'
import { TopBlogs } from './_shared/TopBlogs'
import { Slider } from './Slider/Slider'
import { useKlHistory } from 'components/_hooks/useKlHistory'
import { useDialog, useDialogWithParameter } from 'components/_hooks'
import CookiePolicyModal from './CookiePolicyModal/CookiePolicyModal'
import StorageUtils from 'server/utils/storage-utils'
import HomePopupModal from './HomePopupModal/HomePopupModal'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import { isTablet, isDesktop, isMobile } from 'react-device-detect';
import { HomePopupViewModel } from 'models/home-page-popup-viewmodel'
import { HomePopupUtils } from 'server/utils/home-popup-utils'
import HomePopupModalNew from './HomePopupModalNew/HomePopupModalNew'
import { Modal } from 'components/_modals'
import homeStyles from './HomePopupModalNew/HomePopupModalNew.module.scss'
import { cn } from 'utils'

const Home = () => {

  const history = useKlHistory()
  const isCalledRef = React.useRef(null);
  const [cookiePolicyModal, openCookiePolicyModal] = useDialog(onClose => (
    <CookiePolicyModal onClose={onClose} />
  ))

  const homePopupCloseDialog = () => {
    onChangeHomePopup()
  }
  const [showHomePopup, setShowHomePopup] = useState<boolean>(false)

  const [activeIndex, setActiveIndex] = useState<number>(0)
  const [homePopup, setHomePopup] = useState<HomePopupViewModel>()
  const [homePopups, setHomePopups] = useState<HomePopupViewModel[]>([])
  const isShowHomePopup = sessionStorage.getItem("isShowHomePopup") || "false"

  const [homePopupModal, openHomePopupModal] = useDialogWithParameter<HomePopupViewModel | undefined>((homePopupData, closeDialog) => (
    <>
      <Modal buttonColor="white" onClose={() => { homePopupCloseDialog(); closeDialog(); }} hideOk hideCancel className={cn(homeStyles.home_popup_container) + ' home-popup-container'}>
        <HomePopupModalNew onClose={closeDialog} homePopup={homePopupData} />
      </Modal>
    </>
  ))

  useEffect(() => {
    const loadHomePopups = async () => {
      const result: any = await HomePopupUtils.GetAllRunningHomePopups()
      if (!result.isError) {
        setHomePopup(result.data.length > 0 ? result.data[0] : undefined)
        setHomePopups(result.data)
        setActiveIndex(0);
      }
    }
    void loadHomePopups()
  }, [])

  const onChangeHomePopup = () => {
    let index = activeIndex
    const lastIndex = homePopups.length - 1
    if (index >= homePopups.length - 1) {
      sessionStorage.setItem("isShowHomePopup", "true")
    }

    if (index < 0) {
      index = 0
    }
    if (index < lastIndex) {
      index += 1
    }
    if (index >= 0 && index < homePopups.length) {
      if (homePopups && homePopups.length > 0) {
        setHomePopup(homePopups[index])
        setActiveIndex(index)
      }
    }
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const accId = urlParams.get('accId')
    if (accId && accId !== null) {
      void (async () => {
        const result = await StripeUtils.UpdateUserVerificationStatus(accId)
        if (!result.isError) {
          const user: UserModel = JSON.parse(localStorage.getItem('user'))
          user.IsVerified = result.data.isVerified
          user.Stripe_IdentityUrl = result.data.identity_url
          localStorage.setItem('user', JSON.stringify(user))
          if (localStorage.getItem('currentroute') !== null) {
            const redirectUrl = localStorage.getItem('currentroute')
            history.replace(redirectUrl)
          }
        }
      })()
    }

    if (!StorageUtils.getItem('cookie-consent-status') || StorageUtils.getItem('cookie-consent-status') !== 'allowed') {
      openCookiePolicyModal()
    }
    else {
      setShowHomePopup(true)
    }

  }, [history, openCookiePolicyModal])


  useEffect(() => {
    if (showHomePopup && homePopup && isShowHomePopup === "false") {
      openHomePopupModal(homePopup)
    }
  }, [showHomePopup, homePopup])

  useEffect(() => {
    if (isCalledRef.current) {
      document.getElementById("preload-image")?.classList.add(isMobile || isTablet || isDesktop ? styles.createTournamentMediumImg : styles.createTournamentImg)
    }
  }, [isCalledRef])

  return (
    <div className="p-t-32">
      <DataLoader />


      <div className="row m-0--30" >
        <div className="col-12" >
          <div className="row">
            <div className={styles.createTournament}>
              <div className={styles.homeHead}>
                <p className={styles.slogan}>Robust. Scalable. Reliable. </p>
                <h2 className={styles.home_h2}>Make Your Own Brackets</h2>
                <h1 className={styles.home_h1}>Custom Tournament Builder and Management for Organizers</h1>
                <p>A Global Esport Event Directory </p>
                <Link to="/register">SIGN UP NOW </Link>
              </div>
              <div id="preload-image" ref={isCalledRef}></div>
            </div>
          </div>
          <Slider />
          <div className={styles.mb_45}>
            <TopTournaments />
          </div>
          <div className="row">
            <GameLists />
          </div>
          <div className="row">
            <div className="col-12">
              <div className={styles.homeContent}>
                <div className={styles.left}>
                  <div className={styles.training}>
                    <div className={styles.homeHead}>
                      <span>Need help?</span>
                      <p>Check out these guides to find your way around! </p>
                      <Link className={styles.btnBlueGradient} to="/faq">
                        See more
                        <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M5.52989e-07 6.41669L9.28571 6.41669L6.0357 9.62501L7.42855 11L13 5.5L7.42858 -4.66605e-07L6.03573 1.37499L9.28571 4.58334L3.92712e-07 4.58334L5.52989e-07 6.41669Z"
                            fill="white"
                          />
                        </svg>
                      </Link>
                    </div>
                    <div className={styles.bars}>
                      <ul>
                        <li style={{ overflow: 'hidden' }}>
                          <a href="/faq/why-choose">
                            <span>Why Choose KombatLink?</span>
                          </a>
                        </li>
                        <li style={{ overflow: 'hidden' }}>
                          <a href="/faq/how-to-create-tournament">
                            <span>How to create Tournaments?</span>
                          </a>
                        </li>
                        <li style={{ overflow: 'hidden' }}>
                          <Link to="/faq/how-to-manage-tournament">
                            <span>How to manage tournament?</span>
                          </Link>
                        </li>
                        <li style={{ overflow: 'hidden' }}>
                          <Link to="/faq/how-to-play">
                            <span>How to play tournaments?</span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className={styles.right}>
                  <div className={styles.recentNews}>
                    <div className={styles.homeHead}>
                      <span>Recent News</span>
                      <Link className={styles.seeMore} to="/news">
                        See more
                        <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M5.52989e-07 6.41669L9.28571 6.41669L6.0357 9.62501L7.42855 11L13 5.5L7.42858 -4.66605e-07L6.03573 1.37499L9.28571 4.58334L3.92712e-07 4.58334L5.52989e-07 6.41669Z"
                            fill="white"
                          />
                        </svg>
                      </Link>
                    </div>
                    <div className={styles.content}>
                      <TopBlogs />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {cookiePolicyModal}
      {homePopupModal}
    </div>
  )
}


export default Home
