import { useAppUser } from 'components/_hooks'
import React, { useState } from 'react'
import ReactPlayer from 'react-player'
import { Link } from 'react-router-dom'
import styles from './Home.module.scss'

function HomeVideo() {

  const user = useAppUser()
  const isUserLoggedIn = !!user
  const cdn = 'https://kombatlink-assets.azureedge.net'
  const howToSetUpVenueSeatingLayout = `${cdn}/video/how-to-setup-venue-seating-layout.mp4` 
  const playPauseVideo = ()=> {
    const videos = document.querySelectorAll("video");
    videos.forEach((video) => {
        video.muted = true;
        let playPromise = video.play();
        if (playPromise !== undefined) {
            playPromise.then((_) => {
                let observer = new IntersectionObserver(
                    (entries) => {
                        entries.forEach((entry) => {
                            if (
                                entry.intersectionRatio !== 1 &&
                                !video.paused
                            ) {
                                video.pause();
                            } else if (video.paused) {
                                video.play();
                            }
                        });
                    },
                    { threshold: 0.2 }
                );
                observer.observe(video);
            });
        }
    });
  }
  const onVideoReady=()=>{
    playPauseVideo()
  }

  return (
    <div className={styles.content_wrapper + " m-0 fullpage-content " + styles.video_wrap}>
      <div className={"row row-xl-3 row-l-3 row-m-1 " + styles.flexm}>
        <div className="col-xl-2 col-l-2 col-m-12"></div>
        <div className={"col-xl-8 col-l-8 col-m-12 " + styles.creat_box} data-aos="zoom-in">
          <div className={styles.img_full}>
          <div className={styles.overflowHidden}>
            <ReactPlayer className={styles.videoWrapper} onReady={onVideoReady} 
              url={howToSetUpVenueSeatingLayout}
              controls
              width={765}
              height={500}
            />
            </div>
          </div>
          <h2>Providing the right tools to help sell out your next event</h2>
          <h6>User friendly drag and drop mechanics for any custom stage layout. Map out your seating and set your price.</h6>
          {isUserLoggedIn ? <Link to="/create-tournament/" className={styles.btn}>SIGN UP FREE</Link>
            : <Link to="/register" className={styles.btn}>SIGN UP FREE</Link>}
        </div>
        <div className="col-xl-2 col-l-2 col-m-12"></div>
      </div>
    </div>
  )
}

export default HomeVideo