import { Modal } from 'components/_modals'
import React, { useMemo } from 'react'
import ReactPlayer from 'react-player'
import styles from './VideoPlayer.module.scss'

interface OwnProps {
  closeDialog: CallableFunction
  videoUrl: string
  className?: string
}

export const VideoPlayerModal = ({ closeDialog, videoUrl, className }: OwnProps) => {
  const [isPlaying, setIsPlaying] = React.useState(false)

  const link = useMemo(() => {
    if (!videoUrl) {
      return {
        isTwitchClip: false,
        url: '',
      }
    }
    const splitVideoUrl = videoUrl.split('/')
    const isTwitchClip = splitVideoUrl.includes('clip')
    const clipId = isTwitchClip ? splitVideoUrl[splitVideoUrl.length - 1] : null
    return {
      isTwitchClip,
      url: isTwitchClip 
        ? `https://clips.twitch.tv/embed?clip=${clipId}&autoplay=0&muted=0&parent=${window.location.hostname}`
        : videoUrl,
    }
  }, [videoUrl])

  return (
    <Modal
      onClose={() => {
        setIsPlaying(false)
        closeDialog()
      }}
      hideOk
      hideCancel
      buttonColor="blue"
    >
      <div className={className || styles.playerWrapper}>
        {link.isTwitchClip ? 
          <iframe
            title={'Twitch Clip Embed - 1'}
            src={link.url}
            id="1"
            height="300"
            width="400"
            allowFullScreen
            frameBorder={0}
          /> :
          <ReactPlayer className={styles.reactPlayer} url={link.url} width="100%" controls playing={isPlaying} />
        }
      </div>
    </Modal>
  )
}

export default VideoPlayerModal
