import { ContainerName, useGetImageSize } from 'components/_hooks'
import { Img } from 'components/_shared'
import React from 'react'
import { cn } from 'utils'
import styles from '../MemberCard.module.scss'

interface MemberAvatarColumnProps {
  imageId: number,
  containerName?:ContainerName
}

export const MemberAvatarColumn = ({ imageId,containerName }: MemberAvatarColumnProps) => {
  const getImageSize = useGetImageSize(containerName)
  return (
    <div className={cn('col-auto', styles.memberFirstColumn, 'd-flex align-items-center')}>
      <Img parentContainerSize={getImageSize} imageId={imageId} className={styles.memberPicture} placeholder="user" />
    </div>
  )
}
