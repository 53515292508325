import { ExecuteAPIGet, ExecuteAPIPost } from '../server-utils'
import { EndPoints } from '../endpoints'
import { LoyaltyPointsModel } from '../../models/loyalty-points-models'
import { LoyaltyPointsSalesRevnue } from '../../models/loyaltypoints-sale-revnues-viewmodel'

export const LoyaltyPointsUtils = {
  LoyalityPointsSalesRevnue: async function LoyalityPointsSalesRevnue(date: string) {
    return await ExecuteAPIGet<LoyaltyPointsSalesRevnue[]>(EndPoints.LoyaltyPoint.LoyalityPointsSalesRevnue, `date=${date}`)
  },
  Save: async function Save(loyaltypoints: LoyaltyPointsModel) {
    return await ExecuteAPIPost<number>(EndPoints.LoyaltyPoint.Save, loyaltypoints)
  },
  SaveLoyaltyPointByAdmin: async function Save(loyaltypoints: LoyaltyPointsModel) {
    return await ExecuteAPIPost<number>(EndPoints.LoyaltyPoint.SaveLoyaltyPointByAdmin, loyaltypoints)
  }
}
