import React from 'react'
import { cn } from 'utils'
import { KlLink } from '..'
import ReactStars from 'react-rating-stars-component'
import styles from './UserRating.module.scss'

export interface UserRatingProps {
  rating: number
  userId: number
  reviewCount: number
}

export const UserRating = ({rating, userId, reviewCount}: UserRatingProps) => {

  return (
    <>
      <div className={cn(styles.reviewTitle, 'mt-2')}>Average rating by members</div>
      <div className="d-flex flex-wrap align-items-center">
        <ReactStars
          count={5}
          size={24}
          activeColor="#ec5418"
          classNames={cn(styles.star, 'mr-2')}
          value={rating}
          edit={false}
          isHalf
        />
        <KlLink to={`/user/${userId}/review`} className={styles.reviewLink}>{reviewCount} review</KlLink>
      </div>
    </>
  )
}
