import React from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor'

interface TextEditorProps {
  value: string
  id?: number
  disabled?: boolean
  onChange?: (event, editor) => void
}

export const TextEditor = ({id, value, disabled, onChange}: TextEditorProps) => {
  const config = {
    toolbar: ['undo', 'redo', '|', 'bold', 'italic', 'underline', 'blockQuote', 'link', '|', 'numberedList', 'bulletedList', '|', 'outdent', 'indent', '|', 'fontSize', 'heading', '|', 'removeFormat'],
    heading: {
      options: [
        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
        { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
        { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
        { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
        { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
        { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
      ],
    },
  }
  
  return (
    <>
      <CKEditor 
        id={id}
        disabled={disabled}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        editor={Editor} 
        config={config}
        data={value} 
        onChange={onChange}
      />
    </>
  )
}
