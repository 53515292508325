import { createSelector } from 'reselect'
import { OperationTypes } from 'store/logic/operations-logic'
import { ApplicationState } from 'store/types/common'
import { makeGetIsOperationWithTypeRunning, makeGetIsOperationWithTypeSuccessOrRunning } from './operations-selectors'

const getState = (state: ApplicationState) => state.images

const getImageById = createSelector(getState, state => state?.byId ?? {})

export const makeGetImage = () =>
  createSelector(
    getImageById,
    (_: ApplicationState, { imageId }: { imageId: number }) => imageId,
    (imageById, imageId) => imageById[imageId],
  )

const getLoadImageOperationType = createSelector(
  (_, { imageId }: { imageId: number }) => imageId,
  imageId => ({ type: OperationTypes.loadImage, objectId: imageId })
)
export const makeGetIsImageLoading = () => makeGetIsOperationWithTypeRunning(getLoadImageOperationType)
export const makeGetIsImageRequested = () => makeGetIsOperationWithTypeSuccessOrRunning(getLoadImageOperationType)

const getUploadImageOperationType = createSelector(
  (_, { id }: { id: string }) => id,
  id => ({ type: OperationTypes.uploadImage, objectId: id })
)
export const makeGetIsUploadImageLoading = () => makeGetIsOperationWithTypeRunning(getUploadImageOperationType)
export const makeGetIsUploadImageRequested = () => makeGetIsOperationWithTypeSuccessOrRunning(getUploadImageOperationType)

const getUploadImagesOperationType = createSelector(
  (_, { id }: { id: string }) => id,
  id => ({ type: OperationTypes.uploadImages, objectId: id })
)
export const makeGetIsUploadImagesLoading = () => makeGetIsOperationWithTypeRunning(getUploadImagesOperationType)
  
