import { ExecuteAPIGet, ExecuteAPIPost } from '../server-utils'
import { EndPoints } from '../endpoints'
import { kl_CityAddModel, kl_CityModel, kl_CountryModel, kl_StateModel } from '../../models/kl_country-model'
import { TimeZoneInfoDTO } from 'store/types/app-types'

export const CountryUtils = {
  GetAll: async function GetAll() {
    return await ExecuteAPIGet<kl_CountryModel[]>(EndPoints.Country.GetAll)
  },
  GetAllCities: async function GetAllCities(countryId: number) {
    return await ExecuteAPIGet<kl_CityModel[]>(EndPoints.City.GetAll, `CountryId=${countryId}`)
  },
  GetAllCitiesByStateId: async function GetAllCitiesByStateId(stateId: number) {
    return await ExecuteAPIGet<kl_CityModel[]>(EndPoints.City.GetAllCitiesByStateId, `StateId=${stateId}`)
  },
  GetAllStateByCountryId: async function GetAllStateByCountryId(countryId: number) {
    return await ExecuteAPIGet<kl_StateModel[]>(EndPoints.State.GetAllStateByCountryId, `CountryId=${countryId}`)
  },
  GetAllTimeZoneByCountryCode: async function GetAllTimeZoneByCountryCode(countryCode: string) {
    return await ExecuteAPIGet<TimeZoneInfoDTO[]>(EndPoints.TimeZone.GetAllTimeZoneByCountrycode, `CountryCode=${countryCode}`)
  },
  CheckStateExistsOrNot: async function CheckStateExistsOrNot(countryId: number,stateName : string) {
    return await ExecuteAPIGet<boolean>(EndPoints.State.CheckStateExistsOrNot, `CountryId=${countryId}&StateName=${stateName}`)
  },
  AddState: async function AddState(state: kl_StateModel) {
    return await ExecuteAPIPost<kl_StateModel>(EndPoints.State.AddState, state)
  },
  CheckCityExistsOrNot: async function CheckCityExistsOrNot(countryId: number,stateId:number,cityName : string) {
    return await ExecuteAPIGet<boolean>(EndPoints.City.CheckCityExistsOrNot, `CountryId=${countryId}&StateId=${stateId}&CityName=${cityName}`)
  },
  AddCity: async function AddCity(state: kl_CityAddModel) {
    return await ExecuteAPIPost<kl_CityModel>(EndPoints.City.AddCity, state)
  },
}
