import React, { ReactNode } from 'react'
import { cn } from 'utils'
import styles from './ArticleHeader.module.scss'

export interface ArticleHeaderProps {
  text: string
  className?: string
  children?: ReactNode
  secondText?:string
}

export const ArticleHeader = ({ text, secondText, children, className }: ArticleHeaderProps) => {
  return (
    <div className="row">
      <div className="col p-0">
        <h1 className={cn(styles.articleHeader, className)}>{text}</h1>
        <h2 className={cn(styles.articleHeaderH2, className)}>{secondText}</h2>
      </div>
      {children}
    </div>
  )
}
