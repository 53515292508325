import React from 'react'
import { cn } from 'utils'
import styles from './TextField.module.scss'

interface TextFieldProps {
  value?: string
  label: string
  className?: string
}

export const TextField = ({ label, className, value } : TextFieldProps) => {
  return (
    <div className={cn(styles.card, 'd-flex align-items-center mb-2', className)}>
      <div className={styles.label}>{label}</div>
      <div className={styles.value}>{value || 'None'}</div>
    </div>
  )
}
