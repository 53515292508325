import React from 'react'
import QRCode from 'qrcode.react'
import { TournamentCartRefundStatus } from 'consts/TournamentCartRefundStatus'

export interface TicketQRData {
  
  TournamentDetailId: number | null
  TicketDate: string | null
  TicketCartDetailId?: number
  Quantity?: number
  DayNumber: number | null
  TournamentStatusId: number
  AttendenceCount?: number
  userId: number
  ticketPrice: string
  CoverImage:string
  CoverImageThumbnail:string
  CoverImageSmall:string
  CoverImageMedium:string
  Name:string
  Username:string
  TournamentTicketQuantityId:number|null
  Refunded? : TournamentCartRefundStatus
  SectionName?:string
  
}

interface Props {
  ticket: TicketQRData
}


const TicketQRCode = ({ticket}: Props) => {
  return (
    <QRCode
      style={{ verticalAlign: 'middle', alignContent: 'center' }}
      id="QRCode"
      value={JSON.stringify(ticket)}
      size={250}
      bgColor={'#ffffff'}
      fgColor={'#000000'}
      level={'L'}
      includeMargin
      renderAs={'canvas'}
    />
  )
}

export default TicketQRCode
