
import { LoadingBox, Pagination } from 'components/_shared'
import { kl_DraftTournament } from 'models/kl_DraftTournament-models'
import React, { useEffect, useState } from 'react'
import { TournamentUtils } from 'server/utils/tournament-utils'
import DraftTournaments from './DraftTournaments'


const DraftTournamentTab = () => {
  const [draftTournaments, setDraftTournaments] = useState<kl_DraftTournament[]>()
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(8)
  const [allTournamentsCount, setAllTournamentsCount] = useState(0)
  const [isTournamentsLoading, setIsTournamentsLoading] = useState(false)

  const loadDraftTournaments = async function(pageNumber: number) {
    setIsTournamentsLoading(true)
    const result = await TournamentUtils.GetDraftTournaments(pageNumber)

    if (!result.isError) {
      if (result && result.data && result.data && result.data.TotalCount) {
        setAllTournamentsCount(result.data.TotalCount)
      }
      if (result && result.data && result.data && result.data.CountPerPage) {
        setPageSize(result.data.CountPerPage)
      }
      setDraftTournaments(result.data.DraftTournamentsCards)
    }
    setIsTournamentsLoading(false)
  }

  useEffect(() => {
    void loadDraftTournaments(0)
  }, [])

  const onChangePage = (pageNumber: number) => {
    setPage(pageNumber)
    void loadDraftTournaments(pageNumber)
  }

  const onResetData = () => {
    onChangePage(0)
  }
 

  return (
    <>
      <LoadingBox size="big" loading={isTournamentsLoading}>
        <DraftTournaments draftTournaments={draftTournaments} onResetData={onResetData} />
      </LoadingBox>
      <Pagination page={page} itemsPerPage={pageSize} allItemsCount={allTournamentsCount} onChangePage={setPage} />
    </>
  )
}

export default DraftTournamentTab
