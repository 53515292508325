import React, { useEffect, useMemo } from 'react'
import { TournamentModeEnum, TournamentModeName } from 'consts/TournamentModeId'
import { useDispatch, useSelector } from 'react-redux'
import { makeGetIsMatchParticipantsLoading, makeGetMatchParticipantsByMatchId } from 'store/selectors/match-participant-selectors'
import { ApplicationState } from 'store/types/common'
import { defaultFormatDate, defaultFormatTime } from 'utils'
import { makeGetParticipantsByTournamentDetailId } from 'store/selectors/participant-selectors'
import {
  BattleRow,
  ButtonsColumn,
  ClassColumn,
  DateColumn,
  EventNameColumn,
  LinkColumn,
  RoundColumn,
  TableButton,
  TimeColumn,
  TypeColumn,
} from '../_shared/BattleRow/BattleRow'
import { ParticipantsResults } from 'components/_shared/MatchResultModal/MatchResultModal'
import { UpdateLinkModalParams } from './UpdateLinkModal'
import { DropDownTable, LoadingBox } from 'components/_shared'
import { matchLinksActionCreators } from 'store/logic/match-links-logic'
import { makeGetIsMatchLinksLoading, makeGetMatchLinks } from 'store/selectors/match-links-selectors'
import { MatchLinkStatusEnum } from 'store/types/match-link-types'
import { useMemberClan, useOpponents, useScrollToElement } from 'components/_hooks'
import { makeGetRound } from 'store/selectors/rounds-selectors'
import { makeGetMatch } from 'store/selectors/match-selectors'
import { makeGetTournament } from 'store/selectors/tournaments-selectors'
import { getRoundTitle } from 'store/types/rounds-types'
import { loadMatchParticipants } from 'store/logic/match-participant-logic'
import { TournamentParticipantRow } from '../_shared/TournamentParticipantRow'
import { getAppUser } from 'store/selectors/app-selectors'

interface OwnProps {
  matchId: number
  highlightedRoundId?: number
  removeHighlighted: () => void
  openUpdateLinkModal: (params: UpdateLinkModalParams) => void
  openMatchResultModal: (participantResults: ParticipantsResults) => void
}

const FinishedBattleRow = ({
  matchId,
  highlightedRoundId,
  removeHighlighted,
  openUpdateLinkModal,
  openMatchResultModal,
}: OwnProps) => {
  const dispatch = useDispatch()
  const user = useSelector(getAppUser)
  const {isClanLeader} = useMemberClan(user?.Id)

  const getMatch = useMemo(makeGetMatch, [])
  const getRound = useMemo(makeGetRound, [])
  const getTournament = useMemo(makeGetTournament, [])
  const getMatchParticipants = useMemo(makeGetMatchParticipantsByMatchId, [])
  const getParticipants = useMemo(makeGetParticipantsByTournamentDetailId, [])
  const getMatchLinks = useMemo(makeGetMatchLinks, [])
  const getIsMatchParticipantLoading = useMemo(makeGetIsMatchParticipantsLoading, [])
  const getIsMatchLinksLoading = useMemo(makeGetIsMatchLinksLoading, [])

  const match = useSelector((state: ApplicationState) => getMatch(state, matchId))
  const round = useSelector((state: ApplicationState) => getRound(state, match.RoundId))
  const tournament = useSelector((state: ApplicationState) => getTournament(state, round.TournamentDetailId))
  const matchParticipants = useSelector((state: ApplicationState) => getMatchParticipants(state, matchId))
  const participants = useSelector((state: ApplicationState) => getParticipants(state, round.TournamentDetailId))
  const matchLinks = useSelector((state: ApplicationState) => getMatchLinks(state, matchId))
  const matchParticipantsLoading = useSelector((state: ApplicationState) => getIsMatchParticipantLoading(state, matchId))
  const matchLinksLoading = useSelector((state: ApplicationState) => getIsMatchLinksLoading(state, matchId))

  const { me, opponents } = useOpponents(matchId)

  const myLink = useMemo(() => {
    return matchLinks.find(x => x.TournamentParticipantId == me?.TournamentParticipantId)
  }, [matchLinks, me?.TournamentParticipantId])

  const hasLink = !!myLink?.Link
  const isLinkApproved = myLink?.Status == MatchLinkStatusEnum.Approved
  const highlighted = highlightedRoundId === round?.Id

  const ref = useScrollToElement(highlighted, matchParticipantsLoading ? 'auto' : 'smooth')

  const handleChangeLink = () => {
    openUpdateLinkModal({ matchId, matchLink: myLink?.Link })
  }

  const handleShowResults = () => {
    openMatchResultModal({ matchParticpants: matchParticipants, participants, match })
  }

  useEffect(() => {
    dispatch(loadMatchParticipants(matchId))
    dispatch(matchLinksActionCreators.loadMatchLinks(matchId))
  }, [dispatch, matchId])

  const isProvideLinkButtonVisible = useMemo(() => tournament?.TournamentModeId == TournamentModeEnum.Solo
    || isClanLeader
  , [tournament?.TournamentModeId, isClanLeader])

  return (
    <>
      <div ref={ref} className="row table__row battle-row finished-row" onMouseEnter={highlighted ? removeHighlighted : undefined}>
        <div className="col-12">
          <input className="table__checkbox" type="checkbox" id={`open-table-${matchId}`} />
          <BattleRow highlighted={highlighted}>
            <EventNameColumn>
              <DropDownTable.Link to={`/tournament-detail/${tournament.TournamentDetailId}`}>{tournament.Name}</DropDownTable.Link>
            </EventNameColumn>
            <ClassColumn>Tournament</ClassColumn>
            <TypeColumn>{TournamentModeName[tournament.TournamentModeId]}</TypeColumn>
            <DateColumn>{defaultFormatDate(round.PlayDateTime)}</DateColumn>
            <TimeColumn>{defaultFormatTime(round.PlayDateTime)}</TimeColumn>
            <RoundColumn>{getRoundTitle(round, tournament.TournamentStructureId)}</RoundColumn>
            <LinkColumn><a href={myLink?.Link} target='_blank'>{myLink?.Link || '-'}</a></LinkColumn>
            <ButtonsColumn>
              <div className="row justify-content-sm-end flex-sm-row flex-column m-0">
                {isProvideLinkButtonVisible && (
                  <TableButton
                    color="blue"
                    filled={!hasLink}
                    onClick={handleChangeLink}
                    loading={matchLinksLoading}
                    disabled={isLinkApproved}
                    dataTip={isLinkApproved ? 'Link has already approved' : ''}
                  >
                    {hasLink ? 'Change link' : 'Provide link'}
                  </TableButton>
                )}
                <TableButton color="grey" filled onClick={handleShowResults}>
                  Results
                </TableButton>
                <label className="table__open" htmlFor={`open-table-${matchId}`} />
              </div>
            </ButtonsColumn>
          </BattleRow>
          <div className="row table__secondary-row-container">
            <div className="container-fluid">
              <LoadingBox loading={matchParticipantsLoading} size="average">
                {opponents.map(x => (
                  <TournamentParticipantRow key={x.TournamentParticipantId} participantId={x.TournamentParticipantId} />
                ))}
              </LoadingBox>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FinishedBattleRow
