import { HubConnectionState } from '@microsoft/signalr'
import { Reducer } from 'redux'
import { calcById, calcByIdWithoutIds, mapArrayToObject } from 'utils'
import { CHAT_LEFT, PRIVATE_CHAT_MEMBERS_LOADED } from '../chat-member/chat-member-types'
import { NEW_CHAT_MESSAGE_LOADED, UNREAD_CHATS_LOADED } from '../chat-message/chat-message-types'
import { ChatKnownAction, ChatState, CHATS_CLOSED, CHATS_LOADED, CHAT_CLOSED, CHAT_DELETED, CHAT_DELETED_NOTIFICATION_RECEIVED, CHAT_LIST_OPENED, CHAT_LOADED, CHAT_OPENED, DELETE_TOURNAMENT_CHAT_FROM_STATE_LOADED, DISPUTE_CHAT_LOADED, GROUP_CHAT_CREATED, HUB_CONNECTION_STATE_CHANGED, TOURNAMENT_CHAT_LOADED, USER_CHAT_OPENED } from './chat-types'

const initialChatState: ChatState = {
  isOpened: false,
  hubConnectionState: HubConnectionState.Disconnected,
  byId: {},
  privateChatIdByUserId: {},
  chatIdByTournamentId: {},
  disputChatIdByMatchId: {},
}

const reducer: Reducer<ChatState, ChatKnownAction> = (state: ChatState | undefined = initialChatState, action: ChatKnownAction) => {
  switch (action.type) {
    case CHAT_LIST_OPENED: {
      return {
        ...state,
        isOpened: action.payload,
        openedChatId: undefined,
        openedUserId: undefined,
      }
    }
    case CHAT_OPENED: {
      return {
        ...state,
        isOpened: true,
        openedChatId: action.payload,
        openedUserId: undefined,
      }
    }
    case USER_CHAT_OPENED: {
      return {
        ...state,
        isOpened: true,
        openedUserId: action.payload,
        openedChatId: undefined,
      }
    }
    case CHAT_CLOSED: {
      return {
        ...state,
        openedChatId: undefined,
        openedUserId: undefined,
      }
    }
    case CHATS_CLOSED: {
      return {
        ...state,
        isOpened: false,
        openedChatId: undefined,
        openedUserId: undefined,
      }
    }
    case HUB_CONNECTION_STATE_CHANGED: {
      return {
        ...state,
        hubConnectionState: action.payload,
      }
    }
    case PRIVATE_CHAT_MEMBERS_LOADED: {
      return {
        ...state,
        byId: calcById(state, action.payload.Chats),
        privateChatIdByUserId: {
          ...state.privateChatIdByUserId,
          ...mapArrayToObject(action.payload.ChatMembers, x => x.UserId, x => x.ChatId),
        },
      }
    }
    case TOURNAMENT_CHAT_LOADED: {
      return {
        ...state,
        byId: {
          ...calcById(state, action.payload.chat.Chats),
        },
        chatIdByTournamentId: {
          ...state.chatIdByTournamentId,          
          [action.payload.tournamentDetailId]: action.payload.chat?.Chats[0]?.Id,
        },
      }
    }
    case DELETE_TOURNAMENT_CHAT_FROM_STATE_LOADED: {
      const byid = {...state.byId}
      const chatIdByTournamentId ={...state.chatIdByTournamentId}
      const chatid =chatIdByTournamentId[action.payload.tournamentDetailId]
      delete byid[chatid]
      delete chatIdByTournamentId[action.payload.tournamentDetailId]
      return {
        ...state,
        byId: byid,
        chatIdByTournamentId: chatIdByTournamentId
      }
    }
    case DISPUTE_CHAT_LOADED: {
      return {
        ...state,
        byId: {
          ...calcById(state, action.payload.chat.Chats),
        },
        disputChatIdByMatchId: {
          ...state.disputChatIdByMatchId,
          [action.payload.matchId]: action.payload.chat?.Chats[0]?.Id,
        },
      }
    }
    case CHAT_LOADED: {
      return {
        ...state,
        byId: action ? {
          ...calcById(state, action.payload.Chats),
        } : { 
          ...state.byId,
        },
      }
    }
    case CHAT_LEFT:
    case CHAT_DELETED: {
      return {
        ...state,
        byId: {
          ...calcByIdWithoutIds(state, [action.payload]),     
        },
        openedChatId: action.payload == state.openedChatId ? undefined : action.payload,
      }
    }  
    case CHAT_DELETED_NOTIFICATION_RECEIVED: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload]: {
            ...state.byId[action.payload],
            IsDeleted: true,
          }
        },
      }
    }
    case GROUP_CHAT_CREATED: {
      return {
        ...state,
        byId: action ? {
          ...calcById(state, action.payload.Chats),
        } : { 
          ...state.byId,
        },
        openedUserId: undefined,
        openedChatId: action.payload.Chats[0].Id,
      }
    }
    case UNREAD_CHATS_LOADED: {
      return {
        ...state,
        byId: {
          ...calcById(state, action.payload.Chats.map(ch => ch.Chat)),
        },
      }
    }
    case NEW_CHAT_MESSAGE_LOADED:
    case CHATS_LOADED: {
      return {
        ...state,
        byId: {
          ...calcById(state, action.payload.Chats),
        },
      }
    }
    default:
      return state
  }
}

export default reducer

