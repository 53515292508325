import { Reducer } from 'redux'
import { FRIENDS_PAGE_LOADED, FriendsPageState, KnownAction } from 'store/types/pages/friends-page-types'


const friendsPageInitialState: FriendsPageState = {
  friendsIdsByUserId: {
  },
}


const reducer: Reducer<FriendsPageState, KnownAction> = (state: FriendsPageState | undefined = friendsPageInitialState, action: KnownAction) => {
  switch (action.type) {
    case FRIENDS_PAGE_LOADED: {
      return {
        ...state,
        friendsIdsByUserId: {
          ...state.friendsIdsByUserId,
          [action.payload.userId]: {
            friendIds: action.payload.friends.UsersModel.Users.map(x => x.Id),
            allFriendsCount: action.payload.friends.AllUsersCount,
          },
        },
      }
    }
    default:
      return state
  }
}

export default reducer
