import React from 'react'
import { RouteWithoutLayout } from './Route/RouteWithoutLayout'
import { PolicyPages } from 'components/_pages/policy'

export const PolicyRoutes = () => {
  return (
    <>
      <RouteWithoutLayout oldCss exact path="/privacy-policy" component={PolicyPages.PrivacyPolicy} />
      <RouteWithoutLayout oldCss exact path="/terms-of-service" component={PolicyPages.TermsOfService} />
      <RouteWithoutLayout oldCss exact path="/privacy-policy/:section" component={url => <PolicyPages.PrivacyPolicy section={url.match.params.section} />} />
    </>
  )
}

export default PolicyRoutes
