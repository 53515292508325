import { Modal } from 'components/_modals'
import React from 'react'
import { cn } from 'utils'
import { ButtonColor } from '..'
import styles from './ConfirmActionModal.module.scss'

export interface ModalProps {
  onClose: CallableFunction
  onConfirm(): Promise<boolean>
  text: string
  buttonText: string
  buttonColor?: ButtonColor 
}

export const ConfirmActionModal = ({onClose, onConfirm, text, buttonText, buttonColor = 'blue'}: ModalProps) => {
  return (
    <Modal 
      allowOk
      show
      className={cn(styles.window)}
      onClose={onClose}
      buttonColor={buttonColor}
      okButtonText={buttonText}
      asyncOperation={onConfirm}
    >
      <Modal.Header 
        className={cn(styles.window__header, styles.window__header__center)}
      >
        {text}
      </Modal.Header>
    </Modal>
  )
}
