import { useThemeStyles } from 'components/_hooks'
import React, { useMemo } from 'react'
import { cn, formatDate, parseDate } from 'utils'
import stylesDark from './DateInputDark.module.scss'
import stylesLight from './DateInputLight.module.scss'

export interface DateInputProps {
  value?: Date
  onChange: (value: Date) => void
  className?: string
  inputClassName?: string
  min?: string
  checkOnBlur?:boolean
}

export const DateInput = ({ onChange, value,min }: DateInputProps) => {
  const styles = useThemeStyles(stylesDark, stylesLight)
  
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const date = parseDate(event.target.value, 'YYYY-MM-DD')
    if (date.toString() === 'Invalid Date') {
      onChange(undefined)
    } else {
      onChange(date)
    }
  }

  const date = useMemo(() => {
    return value ? formatDate(value, 'YYYY-MM-DD') : ''
  }, [value])

  return (
    <div className={cn(styles.articleSelectInputDateContainer, styles.articleSelectSelect)}>
      <input className={styles.articleSelectInputDate} type="date" onChange={handleChange} value={date} min={min}/>
    </div>
  )
}

export const SimpleDateInput = ({ onChange, value, className, inputClassName, min, checkOnBlur }: DateInputProps) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const date = parseDate(event.target.value, 'YYYY-MM-DD')
        validateDate(date,null)
    }
    const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
        const date = parseDate(event.target.value, 'YYYY-MM-DD')
        if (checkOnBlur) {
            validateDate(date, min)
        }
    }
    const validateDate = (date: Date, min?: string) => {
        let isValidDate = true
        if (min === null || !min || min === undefined || min === '') {
            if (date.toString() === 'Invalid Date')
                isValidDate = false
            else 
                isValidDate = true
        }
        else {
            const minDateToCompare = min
            const minDate = parseDate(minDateToCompare, 'YYYY-MM-DD')
            if (date.toString() === 'Invalid Date' || date < minDate) 
                isValidDate = false
            else 
                isValidDate = true
        }
        if (!isValidDate) 
            onChange(undefined)
        else
            onChange(date)
    }

  const date = useMemo(() => {
    return value ? formatDate(value, 'YYYY-MM-DD') : ''
  }, [value])

  return (
      <div className={className}>
          <input className={inputClassName} type="date" onBlur={handleBlur} onChange={handleChange} value={date} min={min} />
          
    </div>
  )
}
