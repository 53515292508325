import { TournamentStatusEnum } from 'consts'
import React, { useMemo } from 'react'
import { getIsStartDatePassed, TournamentType } from 'store/types/tournaments-types'
import { getIsAdmin } from 'store/types/user-types'

import stylesLight from '../TournamentCardLight.module.scss'
import stylesDark from '../TournamentCardDark.module.scss'
import { useAppUser, useThemeStyles } from 'components/_hooks'

export enum TournamentBannerDisplayFlag {
  Upcoming = 0,
  Running = 1,
  Finished = 2,
  Canceled = 4,
  Draft = 5
}
interface Props {
  tournament: TournamentType
  isDraft: boolean
  displayOption: TournamentBannerDisplayFlag
}

const TournamentFlashingBanner = ({tournament, isDraft, displayOption}: Props) => {
  
  const styles = useThemeStyles(stylesDark, stylesLight)  

  const appUser = useAppUser()

  const isAdmin = getIsAdmin(appUser?.Role)

  const isStartDatePassed = getIsStartDatePassed(tournament)

  const status = useMemo(() => {
    if (isDraft) {      
      return displayOption & TournamentBannerDisplayFlag.Draft ? 'Draft' : ''
    }
    if (isStartDatePassed && tournament.TournamentStatusId == TournamentStatusEnum.Active) {
      return displayOption & TournamentBannerDisplayFlag.Running ? 'Running' : ''
    }
    if (tournament.TournamentStatusId == TournamentStatusEnum.Active || tournament.TournamentStatusId == TournamentStatusEnum.Pending) { 
      return displayOption & TournamentBannerDisplayFlag.Upcoming ? 'Upcoming' : ''
    }    
    if (tournament.TournamentStatusId == TournamentStatusEnum.Finished) {
      return displayOption & TournamentBannerDisplayFlag.Finished ? 'Finished' : ''
    }
    if (tournament.TournamentStatusId == TournamentStatusEnum.Cancel) {
      return displayOption & TournamentBannerDisplayFlag.Canceled ? 'Canceled' : ''
    }
  }, [displayOption, isDraft, isStartDatePassed, tournament.TournamentStatusId])

  if (isAdmin || !status) {
    return <> </>
  }

  return (
    <div
      className={styles.tournament__running}
    >
      {status}
    </div>
  )
}

export default TournamentFlashingBanner
