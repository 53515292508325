import React, { ReactNode } from 'react'
import { cn } from 'utils'
import styles from '../modals.module.scss'

interface WindowHeaderProps {
  children: ReactNode
  className?: string
}

export const WindowHeader = ({ children, className }: WindowHeaderProps) => {
  return (
    <div className="row">
      <div className={cn(styles.window__header, className)}>{children}</div>
    </div>
  )
}
