import React, { ReactNode } from 'react'
import stylesDark from '../Tabs.module.scss'
import stylesLight from '../TabsLight.module.scss'
import { useThemeStyles } from 'components/_hooks'
import { cn } from 'utils'

export interface TabCheckboxProps {
  children: ReactNode
  id: string
  checked: boolean
  onChange: (checked: boolean) => void
  textClassName?: string
  containerClassName?: string
}

export const TabCheckbox = ({ children, id, checked, onChange, containerClassName, textClassName }: TabCheckboxProps) => {
  const styles = useThemeStyles(stylesDark, stylesLight)
  
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked)
  }

  return (
    <div className={cn(styles.tabMenuRadioContainer, containerClassName)}>
      <input className={styles.tabMenuRadio} type="checkbox" id={id} onChange={handleChange} checked={checked} />
      <label className={styles.tabMenuRadioLabel} htmlFor={id}></label>
      <label className={cn(styles.tabMenuRadioText, textClassName)} htmlFor={id}>{children}</label>
    </div>
  )
}
