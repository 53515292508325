import { ConsoleEnum } from 'models/kl_game-consoles-model'
import React from 'react'
import { cn } from 'utils'

import stylesLight from '../TournamentCardLight.module.scss'
import stylesDark from '../TournamentCardDark.module.scss'
import { useThemeStyles } from 'components/_hooks'

interface Props {
  consoles: number[]
}

const TournamentPlatforms = ({consoles}: Props) => {

  const styles = useThemeStyles(stylesDark, stylesLight)  

  return (
    <div className={cn('row', 'justify-content-start', styles.game__platforms)}>
      {consoles && consoles.length > 1 && (
        <div className={cn(styles.game__platform, styles.game__platform__crossPlatform)} />
      )}
      {consoles && consoles.find(x => x == ConsoleEnum.Xbox) && (
        <div className={cn(styles.game__platform, styles.game__platform__xbox)} />
      )}
      {consoles && consoles.find(x => x == ConsoleEnum.Playstation) && (
        <div className={cn(styles.game__platform, styles.game__platform__ps)} /> 
      )}
      {consoles && consoles.find(x => x == ConsoleEnum.PC) && (
        <div className={cn(styles.game__platform, styles.game__platform__pc)} />
      )}
      {consoles && consoles.find(x => x == ConsoleEnum.Nintendo) && (
        <div className={cn(styles.game__platform, styles.game__platform__nintendo)} />
      )}
      {consoles && consoles.find(x => x == ConsoleEnum.Android) && (
        <div className={cn(styles.game__platform, styles.game__platform__android)} />
      )}
      {consoles && consoles.find(x => x == ConsoleEnum.iOS) && (
        <div className={cn(styles.game__platform, styles.game__platform__ios)} />
      )}
    </div>
  )
}

export default TournamentPlatforms
