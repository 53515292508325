import React from 'react'
import { useSelector } from 'react-redux'
import { makeGetApprovedMatchLinksByMatchIds } from 'store/selectors/match-links-selectors'
import { makeGetRoundMatches } from 'store/selectors/match-selectors'
import { ApplicationState } from 'store/types/common'
import VictoryRoadMatch from './VictoryRoadMatch'
import styles from './list.module.scss'
import { cn } from 'utils'
import { useMemo } from 'react'
interface Props {
  roundId: number
}

const VictoryRoadMatches = ({ roundId }: Props) => {

  const getMatches = React.useMemo(makeGetRoundMatches, [])
  const matches = useSelector((state: ApplicationState) => getMatches(state, roundId))

  const matchLinkIds = React.useMemo(() => matches.map(x => x.Id), [matches])
  
  const getMatchLinks = React.useMemo(makeGetApprovedMatchLinksByMatchIds, [])
  const matchLinks = useSelector((state: ApplicationState) => getMatchLinks(state, matchLinkIds))

  const sortedMatches = useMemo(() => {
    return matches.sort(x => x.Id)
  }, [matches])

  if (matchLinks.length == 0) {
    return (
      <div className={cn(styles.movies__stream, styles.movies__stream__empty)}>        
        <div className={styles.movies__stream__empty__header}>Currently no video available</div>
      </div>)
  }
  return (  
    <>
      {sortedMatches.map((match,i) => (
        <VictoryRoadMatch
          match={match}
          key={match.Id}
        />
      ))}      
    </>
  )
}


export default VictoryRoadMatches
