import { Reducer } from 'redux'
import { arrayToObject } from 'utils'
import { KnownAction, REGIONS_LOADED, RegionState } from '../types/region-types'

export const initialRegionsState: RegionState = {
  byId: {},
  ids: [],
}

const reducer: Reducer<RegionState, KnownAction> = (state: RegionState | undefined = initialRegionsState, action: KnownAction) => {
  switch (action.type) {
    case REGIONS_LOADED:
      return {
        ...state,
        byId: {
          ...arrayToObject(action.payload.regions, x => x.Id),
        },
        ids: action.payload.regions.map(x => x.Id),
      }
    default:
      return state
  }
}

export default reducer
