export enum ConsoleEnum {
  Xbox = 1,
  Playstation = 2,
  PC = 3,
  Nintendo = 4,
  Android = 5,
  iOS = 6,
}


export const ConsoleType = {
  [ConsoleEnum.Xbox]: 'Xbox',
  [ConsoleEnum.Playstation]: 'Playstation',
  [ConsoleEnum.PC]: 'PC',
  [ConsoleEnum.Nintendo]: 'Nintendo',
  [ConsoleEnum.Android]: 'Android',
  [ConsoleEnum.iOS]: 'iOS',
}

export interface ConsoleOption {
  id: ConsoleEnum
  name: string
}

export const getConsoleTypeOptions = (consoles: ConsoleEnum[]) => {
  return ConsoleTypeOptions.filter(c => consoles.indexOf(c.id) >= 0)
}

export const ConsoleTypeOptions: ConsoleOption[] = [
  ...Object.keys(ConsoleType).map(key => ({
    id: +key,
    name: ConsoleType[+key as ConsoleEnum],
  })),
]

export const consoleValueExtractor = (console: ConsoleOption) => console.id
export const consoleLabelExtractor = (console: ConsoleOption) => console.name
