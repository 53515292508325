import React from 'react'
import { Modal } from 'components/_modals'
import styles from './SaveChangesModal.module.scss'
import { MatchParticipantIdPlace, useFinishMatch } from 'store/logic/match-logic'
import { useDispatch, useSelector } from 'react-redux'
import { getPlaceByMatchParticipantId } from 'store/selectors/pages/tournament-bracket-page-selectors'
import { useMatch } from 'components/_hooks'
import { loadBracket } from '../utils/loadBracket'
import { cn } from 'utils'

interface SaveChangesModalProps {
  onClose: CallableFunction
  matchId: number
}

export const SaveChangesModal = ({ onClose, matchId }: SaveChangesModalProps) => {
  const dispatch = useDispatch()
  const finishMatch = useFinishMatch(matchId)
  const placeByMatchParticipantId = useSelector(getPlaceByMatchParticipantId)
  const { tournament } = useMatch(matchId)

  const asyncOperation = async () => {
    const matchParticipantPlaces: MatchParticipantIdPlace[] = Object.keys(placeByMatchParticipantId).map((key => {
      const matchParticipantId = parseInt(key)
      return {
        matchParticipantId,
        place: placeByMatchParticipantId[matchParticipantId],
      }
    }))
    await finishMatch({ matchParticipantPlaces })
    loadBracket(tournament?.TournamentDetailId, dispatch)
    return true
  }

  return (
    <Modal
      onClose={onClose}
      allowOk
      buttonColor="blue"
      className={cn(styles.saveChangesModal, styles.window)}
      okButtonText="Save"
      asyncOperation={asyncOperation}
    >
      <Modal.SubHeader className={styles.windowSubheader}>Do you want to save changes?</Modal.SubHeader>
    </Modal>
  )
}
