import { ClanMemberRole } from 'consts'
import { useMemo } from 'react'
import { makeGetClan } from 'store/selectors/clan-selectors'
import { useAppSelector } from './useAppSelector'
import { useClanMember } from './useClanMember'

export const useMemberClan = (userId: number) => {
  const getClan = useMemo(makeGetClan, [])
  const clanMember = useClanMember(userId)
  const clan = useAppSelector(state => getClan(state, clanMember?.ClanId))

  return {
    isClanLeader: clanMember?.Role === ClanMemberRole.Leader,
    clan,
    clanMember,
  }
}
