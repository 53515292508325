import { Modal } from 'components/_modals'
import { TournamentModeEnum } from 'consts'
import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { lineUpsActionCreators } from 'store/logic/line-ups-logic'
import { makeGetParticipantsByTournamentDetailId } from 'store/selectors/participant-selectors'
import { ApplicationState } from 'store/types/common'
import { TournamentType } from 'store/types/tournaments-types'
import styles from './list.module.scss'
import ParticipantResultRow from './ParticipantResultRow'

interface OwnProps {
  tournament: TournamentType
  closeDialog: CallableFunction
}

const TournamentResultModel = ({ tournament, closeDialog }: OwnProps) => {
  const getParticipants = useMemo(makeGetParticipantsByTournamentDetailId, [])
  const participants = useSelector((state: ApplicationState) => getParticipants(state, tournament.TournamentDetailId))
  const orderedParticipants = participants.filter(p => !!p.PlaceFrom).sort((a, b) => a.PlaceFrom - b.PlaceFrom)
  const isClanMode = tournament.TournamentModeId == TournamentModeEnum.Clans

  const dispatch = useDispatch()
  React.useEffect(() => {
    dispatch(lineUpsActionCreators.loadLineUps(tournament.TournamentDetailId))
  }, [dispatch, tournament.TournamentDetailId])

  return (
    <Modal
      onClose={closeDialog}
      buttonColor="white"
      className={styles.window}
      hideOk
      hideCancel
    >
      <Modal.Header className={styles.window__header}>Winners</Modal.Header>
      <Modal.SubHeader className={styles.window__subheader}>{`${tournament.Name} (T#${tournament.TournamentDetailId})`}</Modal.SubHeader>

      {orderedParticipants && orderedParticipants.map(participant => (
        <ParticipantResultRow key={participant.Id} participantId={participant.Id} isClanMode={isClanMode} />
      ))}

    </Modal>
  )
}

export default TournamentResultModel
