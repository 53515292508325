import React, { useRef, useEffect, useCallback, useState } from "react"
import { Circle as KonvaCircle, Group, Transformer } from "react-konva"

import { LIMITS } from "../../constant/constant"
import { StickyNote } from "./StickeyNote"
import { ToolTipHover } from "./ToolTip/ToolTipHover"
const boundBoxCallbackForCircle = (oldBox, newBox) => {
  if (
    newBox.width < LIMITS.CIRCLE.MIN ||
    newBox.height < LIMITS.CIRCLE.MIN ||
    newBox.width > LIMITS.CIRCLE.MAX ||
    newBox.height > LIMITS.CIRCLE.MAX
  ) {
    return oldBox
  }
  return newBox
}

export function Circle({ Id, isSelected,setSelectedShape,moveShape,transformShape,operationType,openSectionDetail,handleUpdateText,IsCartPage, ...shapeProps }) {
  const shapeRef = useRef();
  const transformerRef = useRef();
  const [text,setText] = useState<string>(shapeProps.SectionName)
  const [visible,setVisible] = useState<boolean>(false)
  const [currentOperation,setCurrentOperation] = useState<string>(operationType)
  
  useEffect(() => {
    if (isSelected && currentOperation !== 'ticketArrangement' && IsCartPage != true) {
      transformerRef.current.nodes([shapeRef.current])
      transformerRef.current.getLayer().batchDraw()
    }
  }, [isSelected,operationType])
  
  useEffect(()=>{
    setCurrentOperation(operationType)
  },[operationType])

  // useEffect(() => {
  //   let isTransform = false
  //   if (!isSelected) {
  //     if(currentOperation !== 'ticketArrangement' && IsCartPage !== true && transformerRef && transformerRef.current && transformerRef.current!==null && shapeRef && shapeRef.current && shapeRef.current!==null){
  //       isTransform = true
  //     }
  //   }
  //   else{
  //       isTransform = true 
  //   }
  //   if(isTransform){
  //     transformerRef.current.nodes([shapeRef.current]);
  //     transformerRef.current.getLayer().batchDraw();
  //   }
  // }, [isSelected,operationType,currentOperation]);

  // useEffect(()=>{
  //   setCurrentOperation(operationType)
  // },[operationType])

  const handleSelect = (event) => {
    if(currentOperation === 'sectionArrangement'){
      event.cancelBubble = true;
      setSelectedShape(Id);
    }
  }

  const handleDrag = useCallback(
    (event) => {
      if(IsCartPage === true){
        event.preventDefault()
        return false
      }
      if(currentOperation === 'sectionArrangement' && IsCartPage != true){
        moveShape(Id, event,shapeProps,shapeProps.SectionShapeType);
      }
    },
    [Id]
  )

  const handleTransform = useCallback(
    (event) => {
      if(currentOperation === 'sectionArrangement' && IsCartPage !== true){
        transformShape(shapeRef.current, Id, event)
      }
    },
    [Id]
  )
  const handleDblClick = useCallback(
    (event) => {
      if(operationType === 'sectionArrangement'){
        openSectionDetail(Id,event)
      }
    },
    [Id,currentOperation]
  )

  const handleTextPropsUpdate = useCallback(
    (prop: any, value: any) => {
      if (currentOperation === 'sectionArrangement' && IsCartPage != true) {
        handleUpdateText(Id, prop, value)
      }
    },
    [Id, currentOperation]
  )
  const handleMouseMove = (e)=>{
   // if(shapeProps.SectionName && shapeProps.SectionName!='' && shapeProps.SectionName!==null){
    //   setText("Section Name :"+shapeProps.SectionName)
    // }
    setText(IsCartPage!== true?"Click on the shape to explore more options":"Double click on section to select seats.")
    setVisible(true)
  }
  const handleMouseOut=()=>{
    setText('')
    setVisible(false)
  }

  return (
    <>
    {!shapeProps.IsLayOut && 
          <ToolTipHover 
            x={shapeProps.x} 
            y={shapeProps.y/2+shapeProps.radius + 5} 
            opacity={0.75} 
            visible={visible}
            toolTipfill='black'
            pointerDirection="down"
            pointerWidth={10}
            pointerHeight={10}
            lineJoin="round"
            shadowColor="black"
            shadowBlur={10}
            shadowOffsetX={10} 
            shadowOffsetY={10}
            shadowOpacity={0.5}
            text={text}
            fontSize={18}
            padding={5}
            fill="white"
          />
        }
      <Group
        name={"group_" + shapeProps.Id}
        draggable={IsCartPage !== true}
        onClick={handleSelect}
        ref={shapeRef}
        onTap={handleSelect}
        onDragStart={handleSelect}
        onDblClick={shapeProps.IsLayOut === true ? false : handleDblClick}
        onDragEnd={handleDrag}
        onMouseMove={handleMouseMove}
        onMouseOut={handleMouseOut}
        

        {...shapeProps}
      >
        <KonvaCircle
          radius={shapeProps.radius}
          fill={shapeProps.fill}
          stroke={shapeProps.stroke}
          
        />

        {shapeProps.IsLayOut && <StickyNote
          x={-40}
          y={shapeProps.IsLayOut === true ? -10 : -10}
          text={shapeProps.IsLayOut === true ? "STAGE" : shapeProps.SectionName ? shapeProps.SectionName : "SECTION NAME"}
          color={shapeProps.IsLayOut === true ? "#162541" : "#a1a7b4"}
          onTextChange={(value) => shapeProps.IsLayOut === true ? false : handleTextPropsUpdate("SectionName", value)}
          width={shapeProps.SectionTextWidth ? shapeProps.SectionTextWidth : shapeProps.width}
          height={shapeProps.SectionTextHeight ? shapeProps.SectionTextHeight : shapeProps.height}
          selected={shapeProps.IsTextSelected ? shapeProps.IsTextSelected : false}
          fontsize={shapeProps.IsLayOut === true ? 18 : 10}
          letterSpacing={shapeProps.IsLayOut === true ? 5 : 0}
          IsLayOut={shapeProps.IsLayOut}
          onTextResize={(newWidth, newHeight) => {
            handleTextPropsUpdate("SectionTextWidth", newWidth)
            handleTextPropsUpdate("SectionTextHeight", newHeight)
          }}
          onClick={() => {
            handleTextPropsUpdate("IsTextSelected", !selected)

          }}
          onTextClick={(newSelected) => {
            handleTextPropsUpdate("IsTextSelected", newSelected)
          }}
        />}


      </Group>
      {isSelected && (
        <Transformer
          anchorSize={5}
          borderDash={[6, 2]}
          ref={transformerRef}
          rotateEnabled={false}
          onTransformEnd={handleTransform}
          enabledAnchors={[
            "top-left",
            "top-right",
            "bottom-right",
            "bottom-left",
          ]}
          boundBoxFunc={boundBoxCallbackForCircle}
         
        />
      )}
    </>
  );
}
