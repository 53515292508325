import { ContainerName, useGetImageSize } from 'components/_hooks'
import { Img } from 'components/_shared'
import { LoadingBox } from 'components/_shared/Loading'
import { ChatType } from 'consts'
import React from 'react'
import { ChatRelationTypeEnum, getGroupChatName } from 'store/chat/chat/chat-types'
import { cn } from 'utils'
import styles from './ChatName.module.scss'

export interface ChatNameProps {
  name: string
  imageId?: number
  loading?: boolean
  chatType: ChatType
  chatReleationType?: ChatRelationTypeEnum
  chatStatus: string
}


export const ChatName = ({ name, imageId, loading, chatType, chatReleationType, chatStatus }: ChatNameProps) => {
  const getImageSize = useGetImageSize(ContainerName.AvatarsNormal)
  return (
    <div className="col">
      <div className="row">
        <LoadingBox loading={loading} size="average" className="col-auto" loadingClassName={styles.loading}>
          <div className="col-auto p-r-5">
            {chatType == ChatType.Private && (
              <Img parentContainerSize={getImageSize} className={styles.chatHeaderProfilePicture} imageId={imageId} placeholder="dark" />
            )}
            {chatType == ChatType.Group && (
              <div className={cn(styles.chatHeaderProfilePicture, styles.chatHeaderProfilePictureGroup)}>{getGroupChatName(chatReleationType)}</div>
            )}
          </div>
          <div className="col p-l-5 d-flex flex-column">
            <div className={styles.chatHeaderUsername}>{name}</div>
            {chatStatus && (
              <>
                <div className={styles.chatHeaderStatus}>{chatStatus}
                  {chatStatus && chatStatus.indexOf('Typing') >= 0 && (
                    <div className={cn(styles.chattypingdots, styles.chattypingdots__white, 'd-inline-flex', 'flex-row')}>
                      <div></div><div></div><div></div>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </LoadingBox>
      </div>
    </div>

  )
}
