import { Modal, ModalType, ModalWindowAnimationType } from 'components/_modals'
import { ConfirmSubmitModal } from 'components/_shared/SubmissionModal/ConfirmSubmitModal/ConfirmSubmitModal'
import { RowSpaceData, TournamentSeatModel, TournamentTicketSectionDetail } from 'models/tournament-models'
import React, { useState,useEffect,useRef } from 'react'
import { useSetMatchSettings } from 'store/logic/match-settings-logic'
import { cn } from 'utils'
import  './TicketSeatingArrangement.scss'
// import styles from './TicketSeatingArrangement.scss'
import {TicketSectionArrangement} from './SectionArrangement/TicketSectionArrangement'
import { ShoppingCardModel } from 'models/shopping-card-model'
interface Props {
  closeDialog: CallableFunction
  tournamentSeatModel:TournamentSeatModel
  close:CallableFunction
  IsCartPage:boolean
  onAddRemoveSeats:(IsAdd:boolean,SectionRowSeatId:any,SectionId:number,SectionPrice:number)=>void
  ShoppingCard:ShoppingCardModel
  getSelectedSeats:(Id:number)=>void
  updateQuantityLoader:number
  setUpdateQuantityLoader:(Id:number)=>void
  paymentMethod:string
  CurrentCryptoCurrencyExchangeRate:any
  CurrentLoyaltyPointsExchangeRate:any
  LoyaltypointsValue:any
  isOrganizerVerified:boolean
  organizerCurrency?:string
  handleTicketPriceChange:(Id:number,value:number)=>void
  
}
interface SectionModal{
  id:number
  selected:boolean

}

export const TicketSeatingArrangementModal= ({packageTicket,closeDialog,tournamentSeatModel,close,IsCartPage,onAddRemoveSeats,ShoppingCard,getSelectedSeats,updateQuantityLoader,paymentMethod,CurrentCryptoCurrencyExchangeRate,CurrentLoyaltyPointsExchangeRate,LoyaltypointsValue,isOrganizerVerified,organizerCurrency,handleTicketPriceChange}: Props) => {
  const [isOperationInProgress, setOperationInProgress] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [operationType,setOperationType]=useState<string>("sectionArrangement")
  
  useEffect(()=>{
    setOperationType(operationType)
  },[operationType])

  useEffect(()=>{
    setOperationType(operationType)
  },[operationType])
 
  
  useEffect(()=>{
    setOperationType(operationType)
  },[operationType])
  const onCloseDilouge = (operationType:any)=>{
    closeDialog()
    setOperationType(operationType)
  }
  const onSubmit = async (data:TournamentTicketSectionDetail[],selectedLayout:any) =>{
    tournamentSeatModel.LayOutSectionId = selectedLayout
    tournamentSeatModel.TournamentTicketSection=data
    close(tournamentSeatModel)
    closeDialog()
    return true
   }
  const handleOperationType=(type:string)=>{
    setOperationType(type)
  }
  return (
    <>
    <div id="modalTicket">
    <Modal.Container>
    <Modal.Background onClose={onCloseDilouge} disabled={isOperationInProgress} />
    <ConfirmSubmitModal show={false} onClose={onCloseDilouge} onConfirm={()=>{}} />
    <Modal
    onClose={onCloseDilouge}
    allowOk={true}
    asyncOperation={onSubmit}
    buttonColor="blue"
    okButtonText="Submit"
    hideOk={true}
    cancelButtonText={'Close'}
    className="window  seat-popup-container"
    hideCancel={true}
    type={ModalType.animated}
    animationType={ModalWindowAnimationType.next}
    show={!showConfirmation}
    onOperationInProgress={setOperationInProgress} >
      

    <TicketSectionArrangement
      packageTicket={packageTicket} 
      ShoppingCard={ShoppingCard}
      onCloseDilouge={onCloseDilouge}
      operationType={operationType}
      onSave={onSubmit}
      handleOperationType={handleOperationType}
      IsCartPage={IsCartPage}
      onAddRemoveSeats={onAddRemoveSeats}
      getSelectedSeats={getSelectedSeats}
      updateQuantityLoader={updateQuantityLoader}
      isOrganizerVerified={isOrganizerVerified}
      //setUpdateQuantityLoader={setUpdateQuantityLoader}
      paymentMethod={paymentMethod}
      CurrentCryptoCurrencyExchangeRate={CurrentCryptoCurrencyExchangeRate}
      CurrentLoyaltyPointsExchangeRate={CurrentLoyaltyPointsExchangeRate}
      LoyaltypointsValue={LoyaltypointsValue}
      organizerCurrency={organizerCurrency}
      handleTicketPriceChange={handleTicketPriceChange}
      />
  

     
</Modal>
     
</Modal.Container>
</div>
</>
  )
 

}