import React from 'react'
import { ChatMessageModel } from 'store/chat/chat-message/chat-message-types'
import { cn, defaultFormatDateTime } from 'utils'
import styles from '../ChatMessages.module.scss'

export interface OwnChatMessageProps {
  chatMessage: ChatMessageModel
}

export const OwnChatMessage = ({ chatMessage }:  OwnChatMessageProps) => {
  return (

    <section className={cn(styles.chatMessageContainer, styles.chatMessageContainerOwn)}>
      <div className="container-fluid">
        <div className="row m-0">
          <div className="col p-0 p-l-7 d-flex flex-column align-items-end">
            <div className={styles.chatMessage}>{chatMessage.Text}</div>
            <div className={styles.chatMessageDate}>{defaultFormatDateTime(chatMessage.SentDate)}</div>
          </div>
        </div>
      </div>
    </section>
  )
}
