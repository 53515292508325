import React from 'react'
import ReactTooltip from 'react-tooltip'
import { cn } from 'utils'
import styles from './buttons.module.scss'

const FullScreenButton = ({ onClick }) => {
  return (
    <>
      <button
        className={cn(styles.eli_btn_screen)}
        type="button"
        aria-expanded="false"
        data-tip="Full Screen"
        data-for="FullScreenTip"
        onClick={onClick}
      >
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M17.5753 0.0625H13.5339C13.2988 0.0625 13.1083 0.253046 13.1083 0.488079V1.62296C13.1083 1.85799 13.2988 2.04853 13.5339 2.04853H14.6105L11.3688 5.29014C11.2026 5.45634 11.2026 5.72576 11.3687 5.89197L12.1712 6.69449C12.2511 6.77433 12.3593 6.81916 12.4722 6.81916C12.5851 6.81916 12.6933 6.77433 12.7731 6.69449L16.0148 3.45283V4.52949C16.0148 4.76452 16.2054 4.95507 16.4404 4.95507H17.5753C17.8103 4.95507 18.0009 4.76452 18.0009 4.52949V0.488079C18.0009 0.253046 17.8104 0.0625 17.5753 0.0625Z"
            fill="#fff"
          />
          <path
            d="M5.82952 11.3063C5.74968 11.2265 5.64147 11.1816 5.52855 11.1816C5.41569 11.1816 5.30742 11.2265 5.22764 11.3063L1.98603 14.5479V13.4712C1.98603 13.2362 1.79549 13.0456 1.56046 13.0456H0.425579C0.190546 13.0456 0 13.2362 0 13.4712V17.5127C0 17.7477 0.190546 17.9383 0.425579 17.9383H4.46705C4.70208 17.9383 4.89262 17.7477 4.89262 17.5127V16.3778C4.89262 16.1428 4.70208 15.9522 4.46705 15.9522H3.39039L6.63194 12.7107C6.79814 12.5445 6.79814 12.2751 6.63194 12.1089L5.82952 11.3063Z"
            fill="#fff"
          />
          <path
            d="M17.9375 17.5734L17.9375 13.5319C17.9375 13.2969 17.747 13.1063 17.5119 13.1063L16.377 13.1063C16.142 13.1063 15.9515 13.2969 15.9515 13.5319L15.9515 14.6085L12.7099 11.3668C12.5437 11.2006 12.2742 11.2006 12.108 11.3668L11.3055 12.1693C11.2257 12.2491 11.1808 12.3573 11.1808 12.4702C11.1808 12.5832 11.2257 12.6914 11.3055 12.7711L14.5472 16.0129L13.4705 16.0129C13.2355 16.0129 13.0449 16.2034 13.0449 16.4384L13.0449 17.5733C13.0449 17.8084 13.2355 17.9989 13.4705 17.9989L17.5119 17.9989C17.747 17.999 17.9375 17.8084 17.9375 17.5734Z"
            fill="#fff"
          />
          <path
            d="M6.69565 5.82952C6.77543 5.74968 6.82031 5.64147 6.82031 5.52855C6.82031 5.41569 6.77548 5.30742 6.69565 5.22764L3.45404 1.98603L4.53075 1.98603C4.76579 1.98603 4.95633 1.79549 4.95633 1.56046L4.95633 0.425579C4.95633 0.190546 4.76579 -8.98062e-08 4.53075 -1.0008e-07L0.489282 -2.76738e-07C0.254249 -2.87012e-07 0.0637026 0.190546 0.0637026 0.425579L0.0637024 4.46705C0.0637024 4.70208 0.254248 4.89262 0.489282 4.89262L1.62416 4.89262C1.85919 4.89262 2.04974 4.70208 2.04974 4.46705L2.04974 3.39039L5.29123 6.63194C5.45744 6.79814 5.72686 6.79814 5.89306 6.63194L6.69565 5.82952Z"
            fill="#fff"
          />
        </svg>
      </button>
      <ReactTooltip effect="solid" type="light" id="FullScreenTip" />
    </>
  )
}

export default FullScreenButton
