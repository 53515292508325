import { useAppSelector, useAppUser } from 'components/_hooks'
import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useOpenChat } from 'store/chat/chat/chat-hooks'
import { loadDisputeChat } from 'store/chat/chat/chat-logic'
import { makeGetDisputeChat } from 'store/chat/chat/chat-selectors'
import { TableButton } from '../_shared/BattleRow/BattleRow'


interface Props {
  matchId: number
}

const DisputeChatButton = ({matchId}: Props) => {
  
  const openChat = useOpenChat()
  const dispatch = useDispatch()
  const user = useAppUser()

  useEffect(() => {
    if (user?.Id) {
      dispatch(loadDisputeChat(matchId))
    }
  }, [dispatch, matchId, user?.Id])

  const getDisputeChat = useMemo(makeGetDisputeChat, [])
  const chat = useAppSelector(state => getDisputeChat(state, {matchId: matchId}))

  const handleOpenChat = () => {
    if (chat?.Id) {
      openChat(chat.Id)
    }
  }

  if (!chat) {
    return (
      <>
      </>
    )
  }

  return (
    <TableButton
      color="green"
      filled
      onClick={handleOpenChat}
    >
      Dispute chat
    </TableButton>
  )
}

export default DisputeChatButton
