import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadImage } from 'store/logic/image-logic'
import { makeGetImage } from 'store/selectors/image-selectors'
import { ApplicationState } from 'store/types/common'
import { MediaType } from 'store/types/image-types'
import { cn } from 'utils'
import { DivImg, Video } from '../Img'
import styles from './TournamentCover.module.scss'
import sliderStyles from 'components/_pages/common/Home/Slider/Slider.module.scss'
import { useGetImageSize,ContainerName } from 'components/_hooks'

interface SliderTournamentCoverProps {
  coverId: number
  isActive: boolean
  autoPlay:boolean|undefined
  isStopMouseEvent:boolean|undefined
  containerName?:ContainerName
}

export const SliderTournamentCover = ({ coverId, isActive,autoPlay,isStopMouseEvent,containerName } : SliderTournamentCoverProps) => {
  const dispatch = useDispatch()
  const getCover = useMemo(makeGetImage, [])
  const cover = useSelector((state: ApplicationState) => getCover(state, { imageId: coverId }))
  useEffect(() => {
    if (coverId) {
      dispatch(loadImage(coverId))
    }
    
  }, [coverId, dispatch])

  const handleVideoMouseOver = (e: React.MouseEvent<HTMLVideoElement, MouseEvent>) => {
    if(isStopMouseEvent !== true)
    {
    if (isActive) {
      void e.currentTarget.play()
    }
  }
  }

  const handleVideoMouseLeave = (e: React.MouseEvent<HTMLVideoElement, MouseEvent>) => {
    if(isStopMouseEvent !== true)
    {
    void e.currentTarget.pause()
  }
  }
  const getContainerImageSize = useGetImageSize(containerName)
  return (
    <div className={cn(sliderStyles.sldrNavIInn, styles.coverWrapper,'home-popup-cover-wrapper')}>
      {cover?.MediaType === MediaType.Video ? (
        <Video
          onMouseOver={handleVideoMouseOver}
          onMouseLeave={handleVideoMouseLeave}
          muted
          loop
          className={cn(styles.tournamentCarouselVideoCover, styles.tournamentCarouselCover,'home-pop-carousel-cover')}
          imageId={coverId}    
          autoPlay={autoPlay}      
        />
      ) : (
        <DivImg parentContainerSize={getContainerImageSize} imageId={coverId} className={cn(sliderStyles.sldrNavImg, styles.tournamentCarouselCover,"homePopupCoverImage")} placeholder="tournament" />
      )}

    </div>
  )
}
