import { createSelector } from 'reselect'
import { OperationTypes } from 'store/logic/operations-logic'
import { ApplicationState } from 'store/types/common'
import { makeGetIsOperationWithTypeRunning, makeGetIsOperationWithTypeSuccessOrRunning } from './operations-selectors'


const getState = (state: ApplicationState) =>  state.lineUps

const getLineUpById = createSelector(getState, state => state?.byId || {})

const getLineUpIdsByParticipantId = createSelector(getState, state => state?.lineUpIdsByParticipantId || {})

export const makeGetParticipantLineUps = () =>
  createSelector(
    getLineUpById, 
    getLineUpIdsByParticipantId,
    (_: ApplicationState, participantId: number) => participantId,
    (byId, byParticipant, participantId) => {
      const ids = byParticipant[participantId] || []
      return ids.map(id => byId[id])
    }
  )

export const getParticipantLineUps = createSelector(
  getLineUpById, 
  getLineUpIdsByParticipantId,
  (_: ApplicationState, participantId: number) => participantId,
  (byId, byParticipant, participantId) => {
    const ids = byParticipant[participantId] || []
    return ids.map(id => byId[id])
  }
)

const getLoadLineUpsOperationType = createSelector(
  (_, tournamentDetailId: number) => tournamentDetailId,
  tournamentDetailId => ({ type: OperationTypes.loadLineUps, objectId: tournamentDetailId })
)
export const makeGetIsLineUpsLoading = () => makeGetIsOperationWithTypeRunning(getLoadLineUpsOperationType)
export const makeGetIsLinUpsRequested = () => makeGetIsOperationWithTypeSuccessOrRunning(getLoadLineUpsOperationType)
