import { Reducer } from 'redux'
import { KnownAction, MATCH_SUBMISSIONS_LOADED, MATCH_SUBMISSION_ADDED  } from 'store/types/match-submissions-types'
import { State } from 'store/types/match-submissions-types'
import { arrayToObject, getNewObjectWithArray, groupArrayToObject, mapArrayToObject } from 'utils'

export const initialMatchSubmissionState: State = {
  byId: {},
  idsByMatchId: [],
  idByMatchParticipantId: {},
}

const reducer: Reducer<State, KnownAction> = (state: State | undefined = initialMatchSubmissionState, action: KnownAction) => {
  switch (action.type) {
    case MATCH_SUBMISSIONS_LOADED: {
      return {
        ...state,
        byId: {
          ...state.byId,
          ...arrayToObject(action.payload.Submissions, x => x.Id),
        },
        idsByMatchId: {
          ...state.idsByMatchId,
          ...groupArrayToObject(
            action.payload.Submissions,
            x => x.MatchId,
            x => x.Id
          ),
        },
        idByMatchParticipantId: {
          ...state.idByMatchParticipantId,
          ...mapArrayToObject(
            action.payload.Submissions,
            x => x.MatchParticipantId,
            x => x.Id
          ),
        },
      }
    }
    case MATCH_SUBMISSION_ADDED: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.Submission.Id]: action.payload.Submission,
        },
        idsByMatchId: {
          ...state.idsByMatchId,
          ...getNewObjectWithArray(state.idsByMatchId, [action.payload.Submission], x => x.MatchId, x => x.Id),
        },
        idsByParticipantId: {
          ...state.idByMatchParticipantId,
          [action.payload.Submission.MatchParticipantId]: action.payload.Submission,
        },
      }
    }   
    default:
      return state
  }
}

export default reducer
