import { makeActionCreator, ReduxAction } from './common'
import { ImageType } from './image-types'

export interface MatchSubmissionsModel {
  Submissions: ParticipantSubmissionModel[]
  Images: ImageType[]
}

export interface MatchSubmissionModel {
  Submission: ParticipantSubmissionModel
  Images: ImageType[] 
}

export interface ParticipantSubmissionModel {
  Id: number
  IsFirstPlace?: boolean
  Place?: number
  SubmiteDate?: string
  EvidenceLink?: string
  Notes: string
  MatchParticipantId: number
  MatchId: number
  CanResubmit: boolean
  ScreenshotIds: number[]
}

export interface ScreenshotType {
  imageId: number
  name: string
}


export interface State {
  byId: {
    [key: number]: ParticipantSubmissionModel
  }
  idsByMatchId: {
    [key: number]: number[]
  }
  idByMatchParticipantId: {
    [key: number]: number
  }
}

export const MATCH_SUBMISSIONS_LOADED = 'MATCH_SUBMISSIONS_LOADED'
export const MATCH_SUBMISSION_ADDED = 'MATCH_SUBMISSION_ADDED'


export type MatchSubmissionsLoadedAction = ReduxAction<typeof MATCH_SUBMISSIONS_LOADED, MatchSubmissionsModel>
export const matchSubmissionsLoaded = makeActionCreator<MatchSubmissionsModel>(MATCH_SUBMISSIONS_LOADED)

export type MatchSubmissionAddedAction = ReduxAction<typeof MATCH_SUBMISSION_ADDED, MatchSubmissionModel>
export const matchSubmissionAdded = makeActionCreator<MatchSubmissionModel>(MATCH_SUBMISSION_ADDED)

export type KnownAction = MatchSubmissionsLoadedAction | MatchSubmissionAddedAction
