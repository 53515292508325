import React, { ReactNode } from 'react'
import { cn } from 'utils'
import styles from '../modals.module.scss'

interface WindowWarningProps {
  children: ReactNode
  className?: string
}

export const WindowWarning = ({ children, className }: WindowWarningProps) => {
  return (
    <div className="row">
      <div className={cn(styles.window__warning, className)}>
        <div>{children}</div>
      </div>
    </div>
  )
}
