import React, { ReactNode } from 'react'
import { cn } from 'utils'
import styles from '../modals.module.scss'
import { WindowLabel } from './WindowLabel'

export interface WindowRadioProps {
  className?: string
  labelClassName?: string
  id: string
  name?: string
  value: string
  checked: boolean
  onChange: () => void
  disabled?: boolean
  children: ReactNode
}

export const WindowRadio = ({ className, labelClassName, id, name, value, checked, onChange, disabled, children }: WindowRadioProps) => {
  return (
    <>
      <input
        className={cn(styles.window__checkbox, className)}
        id={id}
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />

      <WindowLabel id={id} disabled={disabled} className={labelClassName}>
        {children}
      </WindowLabel>
    </>
  )
}
