import { TournamentStructureEnum } from 'consts'
import { kl_TournamentRoundsViewModel } from 'models/kl_tournament_viewmodel'

export const getMaxNumberOfParticipantOptions = (tournamentStructure: TournamentStructureEnum, participientsPerMatch: number): any[] => {
  if (tournamentStructure == TournamentStructureEnum.SingleElimination) {
    return [{ id: 2 }, { id: 4 }, { id: 8 }, { id: 16 }, { id: 32 }, { id: 64 }, { id: 128 }, { id: 256 }, { id: 512 }, { id: 1024 }]
  }
  if (tournamentStructure == TournamentStructureEnum.DoubleElimination) {
    return [{ id: 4 }, { id: 8 }, { id: 16 }, { id: 32 }, { id: 64 }, { id: 128 }, { id: 256 }, { id: 512 }, { id: 1024 }]
  }

  if (tournamentStructure == TournamentStructureEnum.EliminationRoundFfa && participientsPerMatch) {
    return [
      { id: participientsPerMatch },
      { id: Math.pow(participientsPerMatch, 2) },
      { id: Math.pow(participientsPerMatch, 3) },
      { id: Math.pow(participientsPerMatch, 4) },
      { id: Math.pow(participientsPerMatch, 5) },
      { id: Math.pow(participientsPerMatch, 6) },
    ]
  }
  return []
}

const generateRound = (
  tournamentDetailId: number,
  matchCount: number,
  roundNumber: number,
  playDateTime: Date,
  isLosersBracket = false,
  isExtraRound = false,
  isFinal = false
): kl_TournamentRoundsViewModel => {
  const roundTitle = isFinal ? 'Final' : isExtraRound ? 'Extra Round' : `Round ${isLosersBracket ? 'L' : ''}${roundNumber}`
  const tournamentRound = {
    Id: roundNumber,
    TournamentDetailId: tournamentDetailId,
    RoundNumber: roundNumber,
    RoundTitle: roundTitle,
    PlayDateTime: playDateTime,
    ResultSubmissionDeadline: 30,
    IsLosersBracket: isLosersBracket,
    IsExtraRound: isExtraRound,
    TournamentSchedule: [],
    ZonedPlayDateTime: null,
    ResultSubmissionDeadlineDate: null,
    ZonedResultSubmissionDeadlineDate: null,
  }
  let playerNumber = 1
  for (let matchNumber = 1; matchNumber <= matchCount; matchNumber++) {
    const matchTitle = isFinal ? 'Final' : isExtraRound ? 'Extra match' : `${roundTitle} Participant ${playerNumber} and ${playerNumber + 1}`
    tournamentRound.TournamentSchedule.push({
      Id: 0,
      TournamentRoundId: roundNumber,
      MatchNumber: matchNumber,
      MatchTitle: matchTitle,
    })

    playerNumber = playerNumber + 2
  }
  return tournamentRound
}

export const generateDoubleEliminationRounds = (
  tournamentDetailId: number,
  maxNumberOfParticipients: number,
  playDateTime: Date
): kl_TournamentRoundsViewModel[] => {
  let roundNumber = 1
  let loserRoundNumber = 1
  let matchesCount = maxNumberOfParticipients / 2

  let tournamentRounds: kl_TournamentRoundsViewModel[] = []
  while (matchesCount >= 1) {
    tournamentRounds.push(generateRound(tournamentDetailId, matchesCount, roundNumber, playDateTime, false))
    if (playDateTime) {
      playDateTime = new Date(playDateTime)
      playDateTime.setTime(playDateTime.getTime() + 1000 * 60 * 60)
    }
    roundNumber++

    if (loserRoundNumber > 1) {
      tournamentRounds.push(generateRound(tournamentDetailId, matchesCount, loserRoundNumber, playDateTime, true))
      if (playDateTime) {
        playDateTime = new Date(playDateTime)
        playDateTime.setTime(playDateTime.getTime() + 1000 * 60 * 60)
      }
      loserRoundNumber++
    }

    if (loserRoundNumber % 2 == 1) {
      if (matchesCount / 2 >= 1) {
        tournamentRounds.push(generateRound(tournamentDetailId, matchesCount / 2, loserRoundNumber, playDateTime, true))
        if (playDateTime) {
          playDateTime = new Date(playDateTime)
          playDateTime.setTime(playDateTime.getTime() + 1000 * 60 * 60)
        }
        loserRoundNumber++
      }
    }
    matchesCount /= 2
  }

  //final winners
  tournamentRounds.push(generateRound(tournamentDetailId, 1, roundNumber, playDateTime, false, false, true))
  if (playDateTime) {
    playDateTime = new Date(playDateTime)
    playDateTime.setTime(playDateTime.getTime() + 1000 * 60 * 60)
  }
  roundNumber++

  //extra round
  tournamentRounds.push(generateRound(tournamentDetailId, 1, roundNumber, playDateTime, false, true))

  return tournamentRounds
}
