import { makeActionCreator, ReduxAction } from './common'
import { MatchModel } from './match-types'
import { RoundType } from './rounds-types'
import { TournamentType } from './tournaments-types'
import { PublicUserType } from './user-types'

export enum DisputeStatus {
  Open,
  Closed,
}

export interface DisputeModel {
  Id: number
  MatchId: number
  Status: DisputeStatus
  OpenDate: string
  CloseDate?: string
  RoundId: number
  TournamentDetailId: number
}

export interface State {
  disputesById: {
    [key: number]: DisputeModel
  }
  disputesIds: number[]
  allDisputesCount: number
}

export const SET_DISPUTES = 'SET_DISPUTES'
export interface SetDisputesAction {
  type: typeof SET_DISPUTES
  disputes: DisputeModel[]
  allDisputesCount: number
  reload: boolean
}
export function setDisputes(disputes: DisputeModel[], allDisputesCount: number, reload: boolean) {
  return {
    type: SET_DISPUTES,
    disputes,
    allDisputesCount,
    reload,
  }
}

export const DISPUTES_LOADED = 'DISPUTES_LOADED'
export interface DisputesLoadedPayload {
  Disputes: DisputeModel[]
  Tournaments: TournamentType[]
  Rounds: RoundType[]
  Matches: MatchModel[]
  Organizers: PublicUserType[]
  AllDisputesCount: number
  Reload: boolean
}
export type DisputesLoadedAction = ReduxAction<typeof DISPUTES_LOADED, DisputesLoadedPayload>
export const disputesLoaded = makeActionCreator<DisputesLoadedPayload>(DISPUTES_LOADED)

export const DISPUTE_UPDATED = 'DISPUTE_UPDATED'
export interface DisputeUpdatedPayload {
  dispute: DisputeModel
}
export type DisputeUpdatedAction = ReduxAction<typeof DISPUTE_UPDATED, DisputeUpdatedPayload>
export const disputeUpdated = makeActionCreator<DisputeUpdatedPayload>(DISPUTE_UPDATED)

export type KnownAction = DisputesLoadedAction | DisputeUpdatedAction
