import { kl_CityAddModel } from 'models/kl_country-model'
import React, { useEffect, useState } from 'react'
import { CountryUtils } from 'server/utils/country-utils'
import { GeneralUtils } from 'server/utils/general-utils'

interface CityDailogProps {
  countryId: number
  stateId:number
  show: boolean
  showCityDailog?: (isShow) => void
  saveCity?: (state: kl_CityAddModel) => void
}

const CityDailog = ({ countryId,stateId, show, showCityDailog, saveCity }: CityDailogProps) => {
  const [city, setCity] = useState<kl_CityAddModel>(Object())
  const [error, setError] = useState({
    errorCityname: false,
    errorExistingCityName: false,
  })

  useEffect(() => {
    clearData()
  }, [show])

  const clearData = () =>{
    setError({
      ...error,
      errorCityname: false ,
      errorExistingCityName: false
    })
    setCity({...city, CityName:''})
  }
  
  const addCity = async () => {
    if (isDataValid()) {
      const result = await CountryUtils.CheckCityExistsOrNot(countryId,stateId, city.CityName)
      if (!result.isError) {
        if (result.data === false) {
          city.CountryId = countryId
          city.StateId = stateId
          saveCity(city)
        }
        else {
          setError({
            ...error,
            errorExistingCityName: true
          })
        }
      }
      else {
        GeneralUtils.showError(result.message)
      }

    }
  }

  const isDataValid = () => {
    let isValid = true
    let isValidCityName = true

    if (city.CityName === undefined || city.CityName === '') {
      isValidCityName = false
      isValid = false
    }

    setError({
      ...error,
      errorCityname: (isValidCityName ? false : true)
    })

    return isValid
  }

  return (
    <>
      <aside className={'bil_modal-container modal-container ' + `${show === true ? 'modal-container--opened' : ''}`} id="modal-add-state">
        <div className="modal-container__background" />
        <div className="bil_modal-container__window modal-container__window window">
          <a
            href="javascript:void(0)"
            className="window__close"
            onClick={() => {
              showCityDailog(false)
            }}
          />
          <div className="window__header-container">
            <div className="row">
              <div className="col-12">
                <div className="bil_modal_hdr">
                  <div className="bil_modal_hdr_i">
                    <h2 className="bil_modal_hdr_ttl">Add City</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="window__container">
            <div className="container-fluid p-0">
              <div className="row overflow-auto">
                <div className="col-md-12 col-12">
                  <div className="row m-0 flex-column">
                    <h4 className="window__subheader">City Name</h4>
                    <input type="text" className="form-control window__input" placeholder="City Name" value={city.CityName} onChange={e => {
                      setCity({
                        ...city,
                        CityName: e.target.value
                      })
                    }} />
                  </div>
                  {error && error.errorCityname && (
                    <div className={'article__error' + (error.errorCityname ? ' error-visible' : '')}>
                      <div className="error__text">City name is required.</div>
                    </div>
                  )}
                  {error && error.errorExistingCityName && (
                    <div className={'article__error' + (error.errorExistingCityName ? ' error-visible' : '')}>
                      <div className="error__text">City name already exists.</div>
                    </div>
                  )}
                </div>
                <div className="col-12">
                  <div className="bil_modal_btns">
                    <div className="bil_modal_btns_i">
                      <button
                        className="bttn_blue-outline window__close"
                        type="button"
                        onClick={() => {
                          showCityDailog(false)
                        }}
                      >
                        Close
                        </button>
                    </div>
                    <div className="bil_modal_btns_i">
                      <button
                        className="bttn_blue-primery"
                        type="button"
                        onClick={() => {
                          addCity()
                        }}
                      >
                        ADD
                        </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </aside>
    </>

  )
}

export default CityDailog


