import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadImage } from 'store/logic/image-logic'
import { makeGetImage } from 'store/selectors/image-selectors'
import { ApplicationState } from 'store/types/common'
import { ImageType } from 'store/types/image-types'

interface TournamentImageLoaderProps {
  ticketImageId?: number
  imageId?: number
  onImageLoaded: (image: ImageType) => void
}

export const TournamentImageLoader = ({ imageId, onImageLoaded }: TournamentImageLoaderProps) => {
  const dispatch = useDispatch()
  const getImage = useMemo(makeGetImage, [])
  const image = useSelector((state: ApplicationState) => getImage(state, { imageId: imageId }))

  useEffect(() => {
    if (imageId) {
      dispatch(loadImage(imageId))
    }
  }, [imageId, dispatch])

  useEffect(() => {
    if (image) {
      onImageLoaded(image)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image])

  return <></>
}
