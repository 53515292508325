import React from 'react'
import { cn } from 'utils'
import styles from './ChatInput.module.scss'

export interface ChatInputProps {
  placeholder?: string
  className?: string
  value: string
  onChange: (value: string) => void
  disabled?: boolean
}

export const ChatInput = ({ placeholder, className, value, onChange, disabled }:  ChatInputProps) => {
  const handleChange =  (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value)
  }
  return (
    <div className={cn('row m-0', className)}>
      <input type="text" className={cn(styles.chatInput)} placeholder={placeholder}  value={value} onChange={handleChange} disabled={disabled} />
    </div>
  )
}
