import React from 'react'
import { cn } from 'utils'
import { DropDownTableColumnProps } from './DropDownTableColumn'
import { DropDownTableRowColumn } from './DropDownTableRowColumn'
import { useDropDownTableStyles } from './styles/useDropDownTableStyles'

export type DropDownTableSecondaryRowColumnProps = DropDownTableColumnProps

export const DropDownTableSecondaryRowColumn = ({ className, ...otherProps }: DropDownTableSecondaryRowColumnProps) => {
  const styles = useDropDownTableStyles()

  return <DropDownTableRowColumn {...otherProps} className={cn(styles.tableSecondaryRowColumn, className)} />
}
