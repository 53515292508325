import { ClanMember } from './clan-member-types'
import { ClanType } from './clan-types'
import { makeActionCreator, ReduxAction } from './common'
import { ImageType } from './image-types'
import { MatchParticipantsLoadedAction } from './match-participant-types'
import { PublicUserType } from './user-types'

export const getParticipantName = (participant: ParticipantType) => participant.Clan?.Name || participant.Player?.Username

export interface ParticipantType {
  Id: number
  TournamentDetailId?: number
  PlayerId?: number
  ClanId?: number

  Player?: PublicUserType // obsolete
  Clan?: ClanType // obsolete

  PlaceFrom?: number
  PlaceTo?: number
  PlaceTitle: string
  IsOnline?: boolean
  IsPrivate?:boolean
}

export interface ParticipantsModel {
  TournamentParticipants: ParticipantType[]
  Users: PublicUserType[]
  Clans: ClanType[]
  Images: ImageType[]  
  ClansMembers: ClanMember[]
}

export interface State {
  idsByTournamentDetailId: {
    [key: number]: number[]
  }
  byId: {
    [key: number]: ParticipantType
  }  
}

export const PARTICIPANTS_LOADED = 'PARTICIPANTS_LOADED'
export type ParticipantsLoadedAction = ReduxAction<typeof PARTICIPANTS_LOADED, ParticipantsModel>
export const participantsLoaded = makeActionCreator<ParticipantsModel>(PARTICIPANTS_LOADED)

export const NOTIFICATION_PARTICIPANTS_All_DELETED_FROM_STATE = 'NOTIFICATION_PARTICIPANTS_All_DELETED_FROM_STATE'
export type NotificationForParticipantsAllDeletedActionFromState = ReduxAction<typeof NOTIFICATION_PARTICIPANTS_All_DELETED_FROM_STATE, ParticipantAllDeletedPayload>
export const notificationForParticipantsAllDeletedFromState = makeActionCreator<ParticipantAllDeletedPayload>(NOTIFICATION_PARTICIPANTS_All_DELETED_FROM_STATE)

export const PARTICIPANT_ADDED = 'PARTICIPANT_ADDED'
export type ParticipantAddedAction = ReduxAction<typeof PARTICIPANT_ADDED, ParticipantType>
export const participantAdded = makeActionCreator<ParticipantType>(PARTICIPANT_ADDED)

export interface ParticipantDeletedPayload {
  participantId: number
  tournamentDetailId: number
}

export interface ParticipantAllDeletedPayload {
  tournamentDetailId: number
}

export const PARTICIPANT_DELETED = 'PARTICIPANT_DELETED'
export type ParticipantDeletedAction = ReduxAction<typeof PARTICIPANT_DELETED, ParticipantDeletedPayload>
export const participantDeleted = makeActionCreator<ParticipantDeletedPayload>(PARTICIPANT_DELETED)


export type KnownAction = MatchParticipantsLoadedAction
  | ParticipantsLoadedAction
  | ParticipantAddedAction
  | ParticipantDeletedAction
  | NotificationForParticipantsAllDeletedActionFromState
