import React, { ComponentType } from 'react'
import { Elements, injectStripe, StripeProvider } from 'react-stripe-elements'

const apiKey = 'pk_test_7aZ5gN0pYLommXQ2yLtRcv8O00AS3EbmWe'

export const withStripe = (WrappedComponent: ComponentType<any>) => {
  const ComponentWithStripe = injectStripe(WrappedComponent)

  return () => {
    return (
      <StripeProvider apiKey={apiKey}>
        <Elements>
          <ComponentWithStripe />
        </Elements>
      </StripeProvider>
    )
  }
}
