import React, { useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { matchesActionCreators } from 'store/logic/match-logic'
import { ApplicationState, BoundActions } from 'store/types/common'
import { MatchModel } from 'store/types/match-types'
import styles from './CheckIn.module.scss'
import { Modal } from 'components/_modals'

interface OwnProps {
  closeDialog: CallableFunction
  match: MatchModel
}

type DispatchProps = BoundActions<typeof matchesActionCreators>

type Props = OwnProps & DispatchProps

const CheckInModal = ({ closeDialog, checkIn, match }: Props) => {
  const [streamingLink, setStreamingLink] = useState('')

  const asyncOperation = async () => {
    await checkIn(match.Id, {
      streamingLink,
    })
    return true
  }

  return (
    <Modal
      onClose={closeDialog}
      className={styles.window}
      buttonColor="green"
      okButtonText="check-in"
      allowOk
      asyncOperation={asyncOperation}
    >
      <Modal.Header>Do you want to check-in?</Modal.Header>
      <Modal.SubHeader>Provide your streaming channel if you want to share your gameplay</Modal.SubHeader>
      <Modal.Input placeholder="Enter link here..." value={streamingLink} onChange={setStreamingLink}></Modal.Input>
    </Modal>
  )
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  ...bindActionCreators(matchesActionCreators, dispatch),
})

export default connect<{}, DispatchProps, OwnProps, ApplicationState>(null, mapDispatchToProps)(CheckInModal)
