import { useIntMatchParam } from 'components/_hooks'
import { Tabs, useDropDownTableStyles } from 'components/_shared'
import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { cn } from 'utils'
import VictoryRoadTab from '../VictoryRoadTab'
import ApprovedLinks from './ApprovedLinks'
import PendingLinks from './PendingLinks'

export type EventsTab = 'pending' | 'approved'

export const EventsTabs = {
  pending: 'pending',
  approved: 'approved',
}

export const EventsTabsNames = {
  [EventsTabs.pending]: 'Pending',
  [EventsTabs.approved]: 'Approved',
}


interface VictoryRoadEventsRouteProps {
  tab: EventsTab
  matchLinkId?: string 
}

type OwnProps = RouteComponentProps<VictoryRoadEventsRouteProps>

const VictoryRoadMyEvents = ({ match }: OwnProps) => {
  const dropDownTableStyles = useDropDownTableStyles()
  const { tab } = match.params
  const matchLinkId = useIntMatchParam(match, m => m.matchLinkId)
  return (
    <div className={cn(dropDownTableStyles.dropDownTableMediaQueries)}>
      <VictoryRoadTab />
      <Tabs>
        <Tabs.Menu>
          <Tabs.Link 
            url={`/victory-road-events/${EventsTabs.pending}`} 
            active={tab == EventsTabs.pending}
          >
            Pending
          </Tabs.Link>
          <Tabs.Link 
            url={`/victory-road-events/${EventsTabs.approved}`} 
            active={tab == EventsTabs.approved}
          >
            Approved
          </Tabs.Link>
        </Tabs.Menu>
      </Tabs>
      {tab == EventsTabs.pending && (<PendingLinks matchLinkId={matchLinkId}/>)}
      {tab == EventsTabs.approved && (<ApprovedLinks matchLinkId={matchLinkId}/>)}
    </div>
  )
}

export default VictoryRoadMyEvents
