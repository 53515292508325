import { createSelector } from 'reselect'
import { OperationTypes } from '../logic/operations-logic'
import { ApplicationState } from '../types/common'
import {
  makeGetIsAnyOperationWithTypeRunning,
  makeGetIsOperationWithTypeRunning,
  makeGetIsOperationWithTypeSuccessOrRunning,
} from './operations-selectors'

const getState = (state: ApplicationState) => state.tournaments

const getRecentTournamentsIdsByOrganizerId = createSelector(getState, state => state?.recentTournamentsIdsByOrganizerId || {})


export const getTournamentsById = createSelector(getState, state => state?.byId || {})

export const getTopTournamentsIds = createSelector(getState, state => (state?.topTournamentsIds ?? []))

export const getGameUpcomingTournamentsIds = createSelector(getState, state => (state?.gameUpcomingTournamentsIds ?? []))
export const getGameQualifyTournamentsIds = createSelector(getState, state => (state?.gameQualifyTournamentsIds ?? []))
export const getGameMainTournamentsIds = createSelector(getState, state => (state?.gameMainTournamentsIds ?? []))

export const getAllGameUpcomingTournamentsCount = createSelector(getState, state => state?.allGameUpcomingTournamentsCount ?? 0)
export const getAllGameMainTournamentsCount = createSelector(getState, state => state?.allGameMainTournamentsCount ?? 0)
export const getAllGameQualifyTournamentsCount = createSelector(getState, state => state?.allGameQualifyTournamentsCount ?? 0)

const getFinishedTournamentsIds = createSelector(getState, state => (state?.finishedTournamentsIds ?? []))
export const getAllFinishedTournamentsCount = createSelector(getState, state => state?.allFinishedTournamentsCount ?? 0)
export const getFinishedTournaments = createSelector(getTournamentsById, getFinishedTournamentsIds, (tournaments, ids) => {
  return ids.map(id => tournaments[id])
})

export const getOrganizerRecentTournamentsIds = createSelector(
  getRecentTournamentsIdsByOrganizerId,
  (_: ApplicationState, organizerId: number) => organizerId,
  (tournamentsByOrganizer, organizerId) => tournamentsByOrganizer[organizerId] || []
)

export const getTournament = createSelector(
  getTournamentsById,
  (_: ApplicationState, tournamentId: number) => tournamentId,
  (tournaments, id) => {
    return tournaments[id]
  }
)

export const makeGetTournament = () =>
  createSelector(
    getTournamentsById,
    (_: ApplicationState, id: number) => id,
    (byId, id) => byId[id]
  )

const getLoadTournamentOperationType = createSelector(
  (_, tournamentDetailId: number) => tournamentDetailId,
  tournamentDetailId => ({ type: OperationTypes.loadTournament, objectId: tournamentDetailId })
)
export const makeGetTournamentLoading = () => makeGetIsOperationWithTypeRunning(getLoadTournamentOperationType)


export const getFinishedTournamentsLoading = makeGetIsAnyOperationWithTypeRunning(() => ({
  type: OperationTypes.loadFinishedTournaments,
}))


export const getTopTournamentsLoading = makeGetIsAnyOperationWithTypeRunning(() => ({
  type: OperationTypes.loadTopTournaments,
}))

export const getGameUpcomingTournamentsLoading = makeGetIsAnyOperationWithTypeRunning(() => ({
  type: OperationTypes.loadGameUpcomingTournaments,
}))

export const getGameMainTournamentsLoading = makeGetIsAnyOperationWithTypeRunning(() => ({
  type: OperationTypes.loadGameMainTournaments,
}))
export const getGameQualifyTournamentsLoading = makeGetIsAnyOperationWithTypeRunning(() => ({
  type: OperationTypes.loadGameQualifyTournaments,
}))


export const getTempTournamentsIds = createSelector(
  getTournamentsById,
  byId => Object.keys(byId).map(id => parseInt(id)).filter(id => id < 0)
)


const getLoadOrganizerRecentTournamentsOperationType = createSelector(
  (_, organizerId: number) => organizerId,
  organizerId => ({ type: OperationTypes.loadOrganizerRecentTournaments, objectId: organizerId })
)
export const makeGetOrganizerRecentTournamentsLoading = () => makeGetIsOperationWithTypeRunning(getLoadOrganizerRecentTournamentsOperationType)
export const makeGetOrganizerRecentTournamentsRequested = () => makeGetIsOperationWithTypeSuccessOrRunning(getLoadOrganizerRecentTournamentsOperationType)
