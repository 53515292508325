import { AppUserLoadedAction } from './app-types'
import { ClanInvitationsLoadedAction } from './clan-invitations-types'
import { ClansMembersLoadedAction } from './clan-member-types'
import { ClansLoadedAction } from './clan-types'
import { makeActionCreator, ReduxAction } from './common'
import { LineUpsLoadedAction } from './line-up-types'
import { MatchParticipantsLoadedAction } from './match-participant-types'
import { MatchSubmissionAddedAction, MatchSubmissionsLoadedAction } from './match-submissions-types'
import { MyOrganizerActiveTournamentsLoadedAction, MyOrganizerCanceledTournamentsLoadedAction, MyOrganizerFinishedTournamentsLoadedAction, MyOrganizerUpcomingTournamentsLoadedAction } from './pages/organizer-events-page-types'
import { FilteredPlayersLoadedAction, MembersPageLoadedAction } from './pages/clan-page-types'
import { MainTournamentsLoadedAction, QualifyTournamentsLoadedAction, UpcomingTournamentsLoadedAction } from './pages/tournaments-page-types'
import { ParticipantsLoadedAction } from './participants-types'
import { GameMainTournamentsLoadedAction, GameQualifyTournamentsLoadedAction, GameUpcomingTournamentsLoadedAction, OrganizerRecentTournamentsLoadedAction, TopTournamentsLoadedAction } from './tournaments-types'
import { CoverImageUpdatedAction, TopFriendsLoadedAction, UserAvatarUpdatedAction, UsersLoadedAction } from './user-types'
import { MembersPageClansLoadedAction, MembersPageOrganizersLoadedAction, MembersPagePlayersLoadedAction } from './pages/members-page-types'
import { ReviewsLoadedAction } from './review-types'
import { FriendsPageLoadedAction } from './pages/friends-page-types'
import { GamesLoadedAction } from './game-types'
import { FriendRequestsLoadedAction } from './friend-requests-types'
import { AdminActiveTournamentsLoadedAction, AdminCanceledTournamentsLoadedAction, AdminFinishedTournamentsLoadedAction, AdminUpcomingTournamentsLoadedAction } from './pages/admin-tournaments-page-types'
import { BlogAddedAction, BlogByIdLoadedAction, BlogsLoadedAction, BlogUpdatedAction } from './blog-types'
import { NewChatMessageLoadedAction, UnreadChatsLoadedAction } from 'store/chat/chat-message/chat-message-types'
import { ChatLoadedAction, ChatsLoadedAction, GroupChatCreatedAction } from 'store/chat/chat/chat-types'
import { ChatFilteredUsersLoadedAction } from 'store/chat/chat-member/chat-member-types'
import { AdminUsersLoadedAction } from 'store/admin-user/admin-user-types'

export enum ImageTypeEnum {
  Avatar = 1,
  ClanAvatar = 2,
  TournamentCoverImage = 3,
  TournamentCoverVideo = 4,
  Evidence = 5,
  OrganizerCoverImage = 6,
  TournamentTicketImage = 7,
  GameImage = 8,
  BlogImage = 9,
  BlogVideo = 10,
}

export enum MediaType {
  Image = 1,
  Video = 2,
}

export interface ImageType {
  Id: number
  Url: string
  CdnUrl?: string
  Type: ImageTypeEnum
  MediaType: MediaType
  ThumbnailUrl:string
  SmallUrl:string
  MediumUrl:string
  ThumbnailCdnUrl:string
  SmallCdnUrl:string
  MediumCdnUrl:string
}

export interface ImageState {
  byId: {
    [key: number]: ImageType
  }
}

export const IMAGE_LOADED = 'IMAGE_LOADED'
interface ImageLoadedPayload {
  image: ImageType
}
export type ImageLoadedAction = ReduxAction<typeof IMAGE_LOADED, ImageLoadedPayload>
export const imageLoaded = makeActionCreator<ImageLoadedPayload>(IMAGE_LOADED)

export const IMAGES_LOADED = 'IMAGES_LOADED'
interface ImagesLoadedPayload {
  images: ImageType[]
}
export type ImagesLoadedAction = ReduxAction<typeof IMAGES_LOADED, ImagesLoadedPayload>
export const imagesLoaded = makeActionCreator<ImagesLoadedPayload>(IMAGES_LOADED)

export type KnownAction =
  | MatchParticipantsLoadedAction
  | ParticipantsLoadedAction
  | UsersLoadedAction
  | LineUpsLoadedAction
  | ImageLoadedAction
  | MatchSubmissionsLoadedAction 
  | MatchSubmissionAddedAction
  | ClansLoadedAction
  | TopFriendsLoadedAction
  | AppUserLoadedAction
  | UpcomingTournamentsLoadedAction
  | MainTournamentsLoadedAction
  | QualifyTournamentsLoadedAction
  | MyOrganizerUpcomingTournamentsLoadedAction
  | MyOrganizerActiveTournamentsLoadedAction
  | MyOrganizerFinishedTournamentsLoadedAction
  | MyOrganizerCanceledTournamentsLoadedAction
  | TopTournamentsLoadedAction
  | FilteredPlayersLoadedAction
  | GameUpcomingTournamentsLoadedAction
  | ReviewsLoadedAction
  | GameMainTournamentsLoadedAction
  | GameQualifyTournamentsLoadedAction
  | ClanInvitationsLoadedAction
  | ClansMembersLoadedAction
  | MembersPageLoadedAction
  | MembersPagePlayersLoadedAction
  | FriendsPageLoadedAction
  | MembersPageClansLoadedAction
  | MembersPageOrganizersLoadedAction
  | OrganizerRecentTournamentsLoadedAction
  | GamesLoadedAction
  | FriendRequestsLoadedAction
  | BlogsLoadedAction
  | BlogByIdLoadedAction
  | BlogAddedAction
  | BlogUpdatedAction
  | ImagesLoadedAction
  | AdminUpcomingTournamentsLoadedAction
  | AdminActiveTournamentsLoadedAction
  | AdminFinishedTournamentsLoadedAction
  | AdminCanceledTournamentsLoadedAction
  | UnreadChatsLoadedAction
  | NewChatMessageLoadedAction
  | ChatLoadedAction
  | ChatFilteredUsersLoadedAction
  | GroupChatCreatedAction
  | ChatsLoadedAction
  | AdminUsersLoadedAction
  | UserAvatarUpdatedAction
  | CoverImageUpdatedAction

export const getFileType = (image: ImageType): MediaType => {
  if (image.Type === ImageTypeEnum.TournamentCoverVideo) {
    return MediaType.Video
  }
  return MediaType.Image
}
