import { TournamentModeEnum } from 'consts'
import { useMemo } from 'react'
import { useLineUps, useTournament, useTournamentParticipants, useUser } from './useModels'
import { useMemberClan } from './useMemberClan'

export const useFindTournamentParticipant = (tournametnDetailId: number, userId: number) => {
  const { user } = useUser(userId)
  const { clan } = useMemberClan(user?.Id)
  const { tournament } = useTournament(tournametnDetailId)
  const { tournamentParticipants } = useTournamentParticipants(tournametnDetailId)

  const tournamentParticipant = useMemo(() => {
    return tournamentParticipants.find(x => x.PlayerId === user?.Id || x.ClanId === clan?.Id)
  }, [clan?.Id, user?.Id, tournamentParticipants])

  const { lineUps } = useLineUps(tournamentParticipant?.Id)

  if (!tournament || !tournamentParticipant) {
    return undefined
  }

  if (tournament.TournamentModeId === TournamentModeEnum.Solo) {
    return tournamentParticipant
  }

  const inLineUp = lineUps.some(x => x.PlayerId === user?.Id)
  if (inLineUp) {
    return tournamentParticipant
  }

  return undefined
}
