export const EndPoints = {
  Games: {
    GetAll: 'games/getall',
  },
  LoyaltyPoint: {
    LoyalityPointsSalesRevnue: 'loyaltypoint/loyaltypointssalesrevnue',
    Save: 'loyaltypoint/save',
    SaveLoyaltyPointByAdmin:'loyaltypoint/saveloyaltypointbyadmin'
  },
  Tournament: {
    GetTournamentViewModelByID: 'tournament/gettournamentviewmodelbyid',
    SaveTournament: 'tournament/savetournament',
    UpdateTournament: 'tournament/updatetournament',
    GetTournamentRounds: 'tournament/gettournamentrounds',
    GetTournamentModes: 'tournament/gettournamentmodes',
    GetFiltredTournaments: 'tournament/filter',
    GetTop3Tournaments: 'tournament/gettop3tournaments',
    GetTournamentsForHomePageSlider: 'tournament/gettournamentsforhomepageslider',
    GetMainTournaments: 'tournament/getmaintournaments',
    GetDraftTournaments: 'tournament/getdrafttournaments',
    DeleteDraftTournament: 'tournament/deletedrafttournament',
    CreateDraftTournament: 'tournament/createdrafttournament',
    OrganizerProfile: 'tournament/organizerprofile',
    VictoryRoadMap: 'tournament/victoryroadmap',
    TournamentTicket: 'tournament/tournamentticket',
    TournamentEvent: 'tournament/tournamentevent',
    TournamentAndMembersAndIncome: 'tournament/tournamentandmemberandincome',
    TournamentTicketValidate: 'tournament/tournamentticketvalidate',
    UpdateTournamentCaurosel: 'tournament/updatecaurosel',
    OrganizerTopTournaments: 'tournament/organizertoptournaments',
    UpdateTournamentStatus: 'tournament/updatetournamentstatus',
    DeleteTournament: 'tournament/deletetournament',
    GetCityTournaments: 'tournament/getcitytournaments',
    GetRepeatTournament: 'tournament/getrepeattournament',
    DeleteRepeatTournaments: 'tournament/deleterepeattournaments',
    GetAllQualifierTournamentsByMainTournamentId: 'tournament/getallqualifiertournamentsbymaintournamentid',
    GetTournamentCartDetail: 'tournament/gettournamentcartdetail',
    CheckUserEnrollIntoQualifireOrMainEventOrNormalEvent: 'tournament/checkuserenrollintoqualifireormaineventornormalevent',
    CheckResumeTournamentOrNot:'tournament/checkresumetournamentornot',
    SaveCoverFromExistingBlob:'tournament/savecoverfromexistingblob',
    GetTournamentStats:'tournament/gettournamentstats',
    CheckUserEnrollIntoQualifireOrMainEventOrNormalEventByOrganizer: 'tournament/checkuserenrollintoqualifireormaineventornormaleventbyorganizer',
  },
  TournamentCart: {
    SaveToCart: 'tournamentcart/savetocart',
    GetCart: 'tournamentcart/getcartlist',
    DeleteCart: 'tournamentcart/deletecart',
    UpdateCartAttendenceCount: 'tournamentcart/updateattendencecount',
    UpdateCartAndCartDetailAfterPurchase: 'tournamentcart/updatecartandcartdetail',
    OrganizerWithDrawlFees: 'tournamentcart/organizerwithdrawlfees',
    UpdateRefunded: 'tournamentcart/updaterefund',
    DeleteUserCart: 'tournamentcart/deleteusercart',
    GetCartStatus: 'tournamentcart/getcartstatus',
    UpdateRefundedStatus:'tournamentcart/updaterefundedstatus',
    AddOrUpdateCart: 'tournamentcart/addupdatecartdetail',
    GetTournamentTicketCartDetailById: 'tournamentcart/gettournamentticketcartdetailbyid',
    ValidateTournamentTickets:'tournamentcart/validatetournamenttickets',
    ValidateCartOnCheckOut:'tournamentcart/validatecartoncheckout',
    GetTournamentTicketsByTournamentDetailId: 'tournamentcart/gettournamentticketsbytournamentdetailId',
    SaveToCartByOrganizer: 'tournamentcart/savetocartbyorganizer',
    RemoveParticipantTicketByOrganizer: 'tournamentcart/removeparticipantticketbyorganizer',
    GetUserSelectedSeats:'tournamentcart/getuserselectedseats',
  },
  Transaction: {
    MyTransaction: 'transaction/mytransaction',
    OrganizerTransaction: 'transaction/organizertransaction',
    OrganizerAdminTransaction: 'transaction/organizeradmintransaction',
    OrganizerAmount: 'transaction/organizeramount',
    OrganizerAdminAmount: 'transaction/organizeradminamount',
    OrganizerTournamentTransactions: 'transaction/organizertournamenttransaction',
    OrganizerTournamentAdminTransactions: 'transaction/organizertournamentadmintransaction',
    TournamentTicketSalesDetails: 'transaction/tournamentticketsalesdetails',
    GetExchangeCurrencyPrice: 'transaction/getexchangecurrencyprice',
    GetCountryCurrencyDataById: 'transaction/getcountrycurrencydatabyid',
    OrganizerWithdrawTransactionList:'transaction/organizerwithdrawtransaction',
    FreezeUnfreezeTransactions:'transaction/freezeunfreezetransactions',
    GetCurrencyDataFromCountry:'transaction/getcurrencydatafromcountry'
  },
  User: {
    GetAllOrganizers: 'user/getallorganizers',
    GetByID: 'user/getbyid',
    Save: 'user/save',
    Logout: 'user/logout',
    ValidateUserExist: 'user/validateuserexist',
    ValidateSecurityCode: 'user/validatesecuritycode',
    ResetPassword: 'user/resetpassword',
    ManageUsersList: 'user/manageusers',
    UpdateDisabled: 'user/updatedisabled',
    GetOrganizers: 'user/getorganizers',
    AdminManageUsers: 'user/adminmanageusers',
    UserRegistrationRecord: 'user/userregistrationrecord',
    UserCoutryWiseRegistration: 'user/usercountrywiseregistration',
    PlayerBattlesAndLoyaltyPoints: 'user/playerbattlesandloyaltypoints',
    UpdateExpPoints: 'user/updateexppoints',
    TotalOnlineUsersCount:'user/totalonlineuserscount',
    UpgradeToPremiumForFree:'user/upgradetopremiumforfree',
    FilterPlayers:'user/filterplayers',
    FilterClans:'clan/filterclans'
  },
  Stripe: {
    StripeSubscription: 'stripe/stripesubscription',
    GetUserSubscription: 'stripe/getusersubscription',
    GetUserPayment: 'stripe/getuserpayment',
    StripeChargeOneTimePayment: 'stripe/stripechargeonetimepayment',
    CreatePaymentMethod: 'stripe/createpaymentmethod',
    GetPaymentMethod: 'stripe/getpaymentmethod',
    DeletePaymentMethod: 'stripe/deletepaymentmethod',
    GetPrice: 'stripe/getprice',
    RemoveUserSubscription: 'stripe/removeusersubscription',
    WithdrawlBalance: 'stripe/withdrawlbalance',
    GetStripeBalance: 'stripe/getstripebalance',
    StripeOrganizerPayoutBalance: 'stripe/stripeorganizerpayoutbalance',
    OrganizerStripeOnboarding: 'stripe/organizerstripeonboarding',
    GetBankAccountFields: 'stripe/getbankaccountfields',
    UpdateUserVerificationStatus: 'stripe/updateuserverificationstatus',
    GetStripeSupportedCountry: 'stripe/getstripesupportedcountry',
    ConvertToCurrency: 'stripe/converttocurrency',
    StripeRefundFund:'stripe/striperefundfund',
    TransferFundToOrganization: 'stripe/transferfundtoorganization',
    UpdatePaymentMethodToPrimary:'stripe/updatepaymentmethodtoprimary'
  },
  CoinBase: {
    CreateCoinBaseCharge: 'coinbase/createcoinbasecharge',
  },
  AdminRedemption: {
    GetAdminRedemption: 'adminredemption/getadminredemption',
    Save: 'adminredemption/saveadminredemption',
    Add: 'adminredemption/addadminredemption',
    UpdateStatus: 'adminredemption/updatestatus',
    GetFiltredAdminRedemptions: 'adminredemption/filter',

  },
  BillingOption: {
    SaveOrganizerPayout: 'billingoption/saveorganizerpayout',
    GetOrganizerPayout: 'billingoption/getorganizerpayout',
    GetCurrencyByCountry: 'billingoption/getcurrency',
    GetExchangeRateFromCurrency: 'billingoption/getexchangeratefromcurrency'
  },
  Poll: {
    GetActivePoll: 'poll/getactivepoll',
    Vote: 'poll/vote',
    CheckVotesByIP: 'poll/checkvotesbyip',
    GetAllPolls: 'poll/getallpolls',
    GetPollById: 'poll/getpollbyid',
    RemovePollChoice: 'poll/removepollchoice',
    AddPoll: 'poll/addpoll',
    UpdatePoll: 'poll/updatepoll',
    StopPoll: 'poll/stoppoll',
    DeletePoll: 'poll/deletepoll',
    GetMaxFinishDate:'poll/getmaxfinishdate',
    ResumePoll: 'poll/resumepoll',
    GetTotalPollCount:'poll/gettotalpollcount',
  },
  Country: {
    GetAll: 'country/getall',
  },
  Region: {
    GetAll: 'region/getall',
  },
  Faq: {
    GetFaqContentByKeyword: 'faq/getfaqcontentbykeyword',
  },
  Currency: {
    GetAll: 'currency/getall',
    GetCurrencyFromCountryName: 'currency/getcurrencyfromcountryname'
  },
  Console: {
    GetAll: 'console/getall',
  },
  TournamentTicket: {
    TicketSale: 'tournamentticket/ticketsale',
    UpdateScanTicketCount:'tournamentticket/updatescanticketcount'
  },
  Penalty: {
    Save: 'penalty/save',
  },
  City: {
    GetAll: 'country/getallcities',
    GetAllCitiesByStateId: 'country/getallcitiesbystateid',
    CheckCityExistsOrNot: 'country/checkcityexistsornot',
    AddCity: 'country/addcity',
  },
  State: {
    GetAllStateByCountryId: 'country/getallstatesbycountryid',
    CheckStateExistsOrNot: 'country/checkstateexistsornot',
    AddState: 'country/addstate',
  },
  TimeZone: {
    GetAllTimeZoneByCountrycode: 'country/gettimezonebycountrycode',
  },
  HomePopup: {
    GetAllHomePopups: 'homepopup/getallhomepopups',
    GetHomePopupById: 'homepopup/gethomepopupbyid',
    AddHomePopup: 'homepopup/addhomepopup',
    UpdateHomePopup: 'homepopup/updatehomepopup',
    DeleteHomePopup: 'homepopup/deletehomepopup',
    UploadHomePopupImage : 'homepopup/uploadhomepopupimage',
    GetAllRunningHomePopups : 'homepopup/getallrunninghomepopups'
  },
  UserLoginHistory: {
    Add: 'userloginhistory/adduserloginhistory',
    GetLastFourUserLoginHistoryDetails: 'userloginhistory/getlastfouruserloginhistorydetails',
    GetDeviceWiseUserLoginHistoryDetails: 'userloginhistory/getdevicewiseuserloginhistorydetails',
  },
  Match:{
    GetSubmissionsResult: 'match/getsubmissionsresult',
  }
}
