import { Reducer } from 'redux'
import { arrayToObject } from 'utils'
import { AdminUserState, KnownAction, ADMIN_USERS_LOADED, ADMIN_USER_STATUS_CHANGED } from './admin-user-types'

const adminUsersStateInitialState: AdminUserState = {
  byId: {},
  ids: []
}

export const adminUserReducer: Reducer<AdminUserState, KnownAction> = (state: AdminUserState | undefined = adminUsersStateInitialState, action: KnownAction) => {
  switch (action.type) {
    case ADMIN_USERS_LOADED: {
      return {
        byId: arrayToObject(action.payload.Users, x => x.Id),
        ids: action.payload.Users.map(x => x.Id),
      }
    }
    case ADMIN_USER_STATUS_CHANGED: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.userId]: {
            ...state.byId[action.payload.userId],
            Disabled: action.payload.isEnabled ? 'no' : 'yes',
          }
        },
      }
    }
    default:
      return state
  }
}
