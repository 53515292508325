import { PlayerType } from 'store/types/common'
import { MatchParticipantModel } from 'store/types/match-participant-types'
import { ParticipantType } from 'store/types/participants-types'
import { useMatchParticipant } from './useModels'
import { useTournamentPlayer } from './useTournamentPlayer'

interface UseMatchPlayerResult {
  matchParticipant?: MatchParticipantModel
  tournamentParticipant?: ParticipantType
  name?: string
  imageId?: number
  profileUrl?: string
  playerType?: PlayerType
}

export const useMatchPlayer = (matchParticipantId: number): UseMatchPlayerResult => {
  const { matchParticipant } = useMatchParticipant(matchParticipantId)
  const { tournamentParticipant, name, imageId, profileUrl, playerType } = useTournamentPlayer(matchParticipant?.TournamentParticipantId)

  return {
    matchParticipant,
    tournamentParticipant,
    name,
    imageId,
    profileUrl,
    playerType,
  }
}
