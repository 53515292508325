import { makeActionCreator, ReduxAction } from 'store/types/common'
import { UsersModel, UserType } from 'store/types/user-types'

export interface AdminUserState {
  byId: {
    [id: number]: UserType
  }
  ids: number[]
}
 
export const ADMIN_USERS_LOADED = 'ADMIN_USERS_LOADED'
export type AdminUsersLoadedAction = ReduxAction<typeof ADMIN_USERS_LOADED, UsersModel>
export const adminUsersLoaded = makeActionCreator<UsersModel>(ADMIN_USERS_LOADED)

export interface AdminUserStatusChangedPayload {
  userId: number
  isEnabled: boolean
}
export const ADMIN_USER_STATUS_CHANGED = 'ADMIN_USER_STATUS_CHANGED'
export type AdminUserStatusChangedAction = ReduxAction<typeof ADMIN_USER_STATUS_CHANGED, AdminUserStatusChangedPayload>
export const adminUserStatusChanged = makeActionCreator<AdminUserStatusChangedPayload>(ADMIN_USER_STATUS_CHANGED)

export type KnownAction = AdminUsersLoadedAction
  | AdminUserStatusChangedAction
