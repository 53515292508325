import { createSelector } from 'reselect'
import { OperationTypes } from '../logic/operations-logic'
import { ApplicationState } from '../types/common'
import { makeGetIsAnyOperationWithTypeRunning, makeGetIsOperationWithTypeRunning, makeGetIsOperationWithTypeSuccessOrRunning } from './operations-selectors'

const getState = (state: ApplicationState) => state.blogs

export const getBlogById = createSelector(getState, state => state?.byId || {})

export const getBlogsIds = createSelector(getState, state => (state?.ids ?? []))

export const getReadBlogsIds = createSelector(getState, state => (state?.readBlogsIds ?? []))

export const getAllBlogsCount = createSelector(getState, state => state?.allBlogsCount ?? 0)

export const getUnreadNewsCount = createSelector(getState, state => state?.unreadCount ?? 0)

const getBlogSectionsIdsByBlogId = createSelector(getState, state => state?.blogSectionsIdsByBlogId || {})

const getBlogSectionById = createSelector(getState, state => state?.blogSectionById || {})

export const makeGetBlog = () =>
  createSelector(
    getBlogById,
    (_: ApplicationState, { blogId }: { blogId: number }) => blogId,
    (blogs, id) => blogs[id]
  )

export const makeGetBlogSection = () => 
  createSelector(
    getBlogSectionById,
    (_: ApplicationState, { sectionId }: { sectionId: number }) => sectionId,
    (sections, id) => sections[id]
  )

export const makeGetBlogSectionsIdsByBlogId = () => 
  createSelector(
    getBlogSectionsIdsByBlogId,
    (_: ApplicationState, { blogId }: { blogId: number }) => blogId,
    (sectionsIdsByBlogId, blogId) => sectionsIdsByBlogId[blogId] ?? []
  )

export const makeGetBlogSectionsByBlogId = () => 
  createSelector(
    getBlogSectionsIdsByBlogId,
    getBlogSectionById,
    (_: ApplicationState, { blogId }: { blogId: number }) => blogId,
    (sectionsIdsByBlogId, sectionById, blogId) => sectionsIdsByBlogId[blogId]?.map(id => sectionById[id]) ?? []
  )

export const getBlogsLoading = makeGetIsAnyOperationWithTypeRunning(() => ({
  type: OperationTypes.loadBlogs,
}))

const getLoadBlogOperationType = createSelector(
  (_, { blogId }: { blogId: number }) => blogId,
  blogId => ({ type: OperationTypes.loadBlogById, objectId: blogId })
)
export const makeGetIsBlogLoading = () => makeGetIsOperationWithTypeRunning(getLoadBlogOperationType)
export const makeGetIsBlogRequested = () => makeGetIsOperationWithTypeSuccessOrRunning(getLoadBlogOperationType)

export const getIsAddBlogLoading = makeGetIsAnyOperationWithTypeRunning(() => ({
  type: OperationTypes.addBlog,
}))

export const getIsDeleteBlogLoading = makeGetIsAnyOperationWithTypeRunning(() => ({
  type: OperationTypes.deleteBlog,
}))

const getUpdateBlogOperationType = createSelector(
  (_, { blogId }: { blogId: number }) => blogId,
  blogId => ({ type: OperationTypes.updateBlog, objectId: blogId })
)
export const makeGetIsUpdateBlogLoading = () => makeGetIsOperationWithTypeRunning(getUpdateBlogOperationType)
