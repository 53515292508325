import React from 'react'
import { useHistory } from 'react-router-dom'
import { useChatMember } from 'store/chat/chat-member/chat-member-hooks'
import { cn } from 'utils'
import { ChatPerson } from '../ChatPerson/ChatPerson'
import styles from './ChatMember.module.scss'

export interface ChatMemberProps {
  chatMemberId: number  
  hasLink?: boolean
}

export const ChatMember = ({ chatMemberId, hasLink }:  ChatMemberProps) => {

  const chatMember = useChatMember(chatMemberId)

  const history = useHistory()
  const handleMemberClick = () => {
    if (hasLink) {
      history.push(`/chat/members/${chatMemberId}`)
    }
  }
  return (
    <div className={cn(styles.chatMember, hasLink && styles.chatMemberLink)} onClick={handleMemberClick}>
      <ChatPerson personStatusClasnName={styles.chatPersonStatus} userId={chatMember.UserId} />
    </div>
  )
}
