import React, { useState } from 'react'
import styles from './FinishedBattles.module.scss'
import { useAddMatchLink } from 'store/logic/match-links-logic'
import { Modal } from 'components/_modals'

export interface UpdateLinkModalParams {
  matchId: number
  matchLink?: string
}

interface OwnProps extends UpdateLinkModalParams {
  closeDialog: CallableFunction
}

const UpdateLinkModal = ({ closeDialog, matchId, matchLink }: OwnProps) => {
  const [link, setLink] = useState(matchLink)

  const addMatchLink = useAddMatchLink()
  const onSubmit = React.useCallback(async () => {   
    await addMatchLink(matchId, link)
    return true
  }, [addMatchLink, link, matchId])

  return (
    <Modal
      onClose={closeDialog}
      allowOk
      asyncOperation={onSubmit}
      buttonColor="blue"
      okButtonText="share"
      className={styles.window}
    >
      <Modal.Header>{matchLink ? 'Change Link' : 'Provide Link'}</Modal.Header>
      <Modal.SubHeader>Share your gameplay by providing link to the audience</Modal.SubHeader>
      <Modal.Input placeholder="Enter link here..." value={link} onChange={setLink}></Modal.Input>
    </Modal>
  )
}

export default UpdateLinkModal
