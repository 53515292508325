import React from 'react'
import { OrganizerProfileModel } from '../../models/organizer-profile-models'
import { GeneralUtils } from '../../server/utils/general-utils'
import { TournamentUtils } from '../../server/utils/tournament-utils'
import { UserModel } from '../../models/user-models'
import { UserUtils } from '../../server/utils/user-utils'
import { StripeUtils } from '../../server/utils/stripe-utils'
import { Tournament_Members_IncomeViewModel } from '../../models/Player_OrganizerHeaderViewModel'
import { Redirect } from 'react-router-dom'
import { Kl_OrganizerPayout } from '../../models/billing-option-model'
import { BillingOptionUtils } from 'server/utils/billing-option-utils'
import BillingOptionDailog from '../BillingOptionPlayer/BillingOptionDailog'
import { LocationUtils } from '../../server/utils/location-utils'
import { getUser } from 'utils'
import { OrganizerAvatar } from './_shared/OrganizerAvatar'
import { OrganizerCoverImage } from './_shared/OrganizerCoverImage'
import { RecentTournaments } from './_shared/RecentTournaments'
import { OrganizerReviews } from './_shared/OrganizerReviews'

interface State {
  _organizerProfileObj: OrganizerProfileModel
  pageNo: number
  tournamentMembersIncome: Tournament_Members_IncomeViewModel
  upgradetoPremium: boolean
  showWarning: boolean
  organizerPayout: Kl_OrganizerPayout
  showDialouge: boolean
  showClass: string
  userCountry: string
  userCountryCode: string
}

class OrganizerProfile extends React.Component<unknown, State> {
  state: State = {
    _organizerProfileObj: Object(),
    pageNo: 1,
    tournamentMembersIncome: Object(),
    upgradetoPremium: false,
    showWarning: false,
    organizerPayout: Object(),
    showDialouge: false,
    showClass: '',
    userCountry: '',
    userCountryCode: '',
  }

  async componentDidMount() {
    this.getData()
    const cordinates: any = await LocationUtils.getCoords()
    const userLocation = await LocationUtils.GetLocationInfo('country', cordinates.latitude, cordinates.longitude)
    this.setState({
      userCountry: userLocation.CountryName,
      userCountryCode: userLocation.CountryCode,
    })
  }

  getData = () => {
    const user = getUser()
    void this.organizerProfile(user.id, 1)
    void this.getTournamentMemberIncome()
    void this._getOrganizerPayout()
  }

  saveProfile = async () => {
    const user = getUser()
    const userId = localStorage.getItem('userid')
    const _organizerProfileObj = this.state._organizerProfileObj
    GeneralUtils.showHideLoadingDiv(true)
    const result = await UserUtils.GetAllByID(parseInt(userId))
    if (!result.isError) {
      result.data.emailaddress = _organizerProfileObj.emailaddress
      result.data.About = _organizerProfileObj.about
      result.data.country = _organizerProfileObj.country
      result.data.Facebook = _organizerProfileObj.facebook
      result.data.Instagram = _organizerProfileObj.instagram
      result.data.Twitter = _organizerProfileObj.twitter
      result.data.Discordchannel = _organizerProfileObj.discord
      result.data.tel = _organizerProfileObj.tel
      const useSave = await UserUtils.Save(result.data)
      if (!useSave.isError) {
        this.setState({ _organizerProfileObj: Object() }, () => {
          this.organizerProfile(user.id, 1)
        })
        GeneralUtils.showHideLoadingDiv(false)
      } else {
        GeneralUtils.showHideLoadingDiv(false)
      }
    } else {
      GeneralUtils.showError(result.message)
      GeneralUtils.showHideLoadingDiv(false)
    }
  }

  organizerProfile = async (profileId: number, eventType: number, fromDate?: Date, toDate?: Date) => {
    if (profileId > 0) {
      GeneralUtils.showHideLoadingDiv(true)
      const result = await TournamentUtils.OrganizerProfile(profileId, eventType, fromDate, toDate)
      if (!result.isError) {
        if (!result.data.isVerified) {
          document.getElementById('modal_identity-verify-warning').className = 'modal-container modal-container--opened'
        }
        this.setState({ _organizerProfileObj: result.data })
        GeneralUtils.showHideLoadingDiv(false)
      } else {
        GeneralUtils.showError(result.message)
        GeneralUtils.showHideLoadingDiv(false)
      }
    }
  }

  onchangeHandler = (name, value) => {
    const _organizerProfileObj = this.state._organizerProfileObj
    _organizerProfileObj[name] = value
    this.setState({ _organizerProfileObj })
  }

  removeUserSubscription = async () => {
    const userId = localStorage.getItem('userid')
    GeneralUtils.showHideLoadingDiv(true)
    const result = await StripeUtils.RemoveUserSubscription()
    GeneralUtils.showHideLoadingDiv(false)
    if (!result.isError) {
      const userData: UserModel = JSON.parse(localStorage.getItem('user'))
      userData.IsPremium = false
      localStorage.setItem('user', JSON.stringify(userData))
      this.organizerProfile(parseInt(userId), 1)
    } else {
      GeneralUtils.showError(result.message)
    }
    document.getElementById('modal_disable-premium').className = 'modal-container'
  }

  getTournamentMemberIncome = async () => {
    const userId: string = localStorage.getItem('userid')
    const result = await TournamentUtils.TournamentAndMembersAndIncome(parseInt(userId))
    if (!result.isError) {
      this.setState({ tournamentMembersIncome: result.data })
    } else {
      GeneralUtils.showError(result.message)
    }
  }

  updateDisabled = async () => {
    const { _organizerProfileObj } = this.state
    const disableUser = await UserUtils.UpdateDisabled('yes', _organizerProfileObj.userid)
    if (!disableUser.isError) {
      window.location.href = '/login'
    }
  }

  getOrganizerRank(RSPercentage:any) {
    let organizerRank = ''
    switch (RSPercentage) {
      case 0:
      case 5:
        organizerRank = 'Starting'
        break
      case 6:
        organizerRank = 'Bronze'
        break
      case 7:
        organizerRank = 'Silver'
        break
      case 8:
        organizerRank = 'Gold'
        break
      case 9:
        organizerRank = 'Platinum'
        break
      case 10:
        organizerRank = 'Legendary'
        break
      default:
        organizerRank = ''
        break
    }
    return organizerRank
  }

  _getOrganizerPayout = async () => {
    const user = getUser()
    if (user.id && user.isorganizer) {
      const res = await BillingOptionUtils.getOrganizerPayout()
      if (res.data) {
        this.setState({
          organizerPayout: res.data,
        })
      }
    }
  }

  isOrganizerVerified = (data, result) => {
    const user = getUser()
    const _organizerProfileObj = this.state._organizerProfileObj
    _organizerProfileObj.isVerified = false
    user.IsVerified = _organizerProfileObj.isVerified
    this.setState(
      {
        showDialouge: false,
        _organizerProfileObj,
      },
      () => {
        if (result !== null) {
          user.IsVerified = result.PaymentMethod == 2 ? true : false
          _organizerProfileObj.isVerified = user.IsVerified
          const { organizerPayout } = this.state
          organizerPayout.PaymentMethodId = result.PaymentMethod
          this.setState({ organizerPayout })
        }

        localStorage.setItem('user', JSON.stringify(user))
        if (data && data.hasOwnProperty('AccountLinkUrl') && data.AccountLinkUrl !== '') {
          user.Stripe_IdentityUrl = data.AccountLinkUrl
          localStorage.setItem('user', JSON.stringify(user))
          window.location.href = data.AccountLinkUrl
        }
      }
    )
  }

  render() {
    if (this.state.upgradetoPremium) {
      localStorage.setItem('returnURL', '/my-profile')
      return <Redirect push to="/upgrade-account" />
    }
    const { showWarning, _organizerProfileObj, tournamentMembersIncome } = this.state
    const user = getUser()

    return (
      <>
        <div className="pfl">
          <div className="row m-0">
            <div className="col-12 p-0">
              <div className="pfl_ttl">
                <div className="pfl_ttl_l">
                  <h1 className="article__header">My profile</h1>
                </div>
                <div className="pfl_ttl_r">
                  <button
                    onClick={() => {
                      // if (!user.IsPremium) {
                      //   document.getElementById('modal_disable-premium').className = 'modal-container modal-container--opened'
                      // } else {
                      //   document.getElementById('modal_disable-premium').className = 'modal-container modal-container--opened'
                      // }
                    }}
                    className="btn-premium open_modal_disable-premium"
                  >
                    <span className="btn-ico-premium" />
                    {/* {user.IsPremium ? 'Premium account' : 'Go to Premium'}{' '} */}
                    {'Premium account'}{' '}
                  </button>
                </div>
              </div>
            </div>

            <div className="col-12">
              <OrganizerCoverImage />
            </div>

            <div className="col-12 p-0">
              <div className="pfl_company">
                <div className="pfl_company_l">
                  <div className="pfl_company_l_l">
                    <OrganizerAvatar />
                  </div>
                  <div className="pfl_company_l_r">
                    <div className="pfl_title">
                      <h2 className="pfl_title_ttl">{_organizerProfileObj.username}</h2>
                      <div className="pfl_verif white-tooltip-theme">
                        {_organizerProfileObj.isVerified == true && (
                          <div className="pfl_verif_active">
                            <span className="pfl_verif_active_ico" />
                            <span>Verified</span>
                          </div>
                        )}
                        {_organizerProfileObj.isVerified == false && (
                          <div className="pfl_verif_not-active">
                            <p id="bankAccountDetail">
                              Not Verified
                            </p>
                            {this.state.showDialouge && user.Stripe_IdentityUrl == null && (
                              <BillingOptionDailog
                                organizerPayout={this.state.organizerPayout}
                                showClass="modal-container--opened"
                                verified={this.isOrganizerVerified}
                                userCountry={this.state.userCountry}
                                userCountryCode={this.state.userCountryCode}
                                currentRoute={window.location.pathname}
                              />
                            )}
                            <button
                              className="btn-more-info btn-tooltip d-flex mt-1"
                              onClick={async () => {
                                document.getElementById('modal_identity-verify-warning').className = 'modal-container modal-container--opened'
                              }}
                            >
                              <img src="https://kombatlink-assets.azureedge.net/img/info.svg" alt="" />
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pfl_company_r">
                  <div className="pfl_company_r_inn">
                    <ul className="social-links">
                      <li>
                        <a onClick={(e) => e.preventDefault()}>
                          <img src="https://kombatlink-assets.azureedge.net/img/facebook.svg" />
                        </a>
                      </li>
                      <li>
                        <a onClick={(e) => e.preventDefault()}>
                          <img src="https://kombatlink-assets.azureedge.net/img/twitter.svg" />
                        </a>
                      </li>
                      <li>
                        <a onClick={(e) => e.preventDefault()}>
                          <img src="https://kombatlink-assets.azureedge.net/img/instagram.svg" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 p-0">
              <div className="row m-0">
                <div className="col-12 pfl_col-left">
                  <div className="pfl_info-container">
                    <div className="section-ttl">
                      <div className="section-ttl_l">
                        <h2 className="section-ttl_ttl">Additional Information</h2>
                      </div>
                      <div className="section-ttl_r" />
                    </div>
                    <div className="pfl_info_row mt-2">
                      <div className="pfl_info_col pfl_info_col-level">
                        <div className="pfl_info-level pfl_info" style={{ background: 'rgba(255,255,255,0.1)', height: '90px' }}>
                          <div className="pfl_info_l">
                            <div className="pfl_info_l_inn">
                              <img src="https://kombatlink-assets.azureedge.net/img/award-5.svg" alt="" />
                            </div>
                          </div>
                          <div className="pfl_info_r">
                            <h4 className="pfl_info-level_num">{this.getOrganizerRank(_organizerProfileObj.RSPercentage)}</h4>
                            <span className="pfl_info-level_ttl">level</span>
                          </div>
                        </div>
                      </div>
                      <div className="pfl_info_col pfl_info_col-number">
                        <div className="pfl_info-number pfl_info" style={{ background: '#682FBF', height: '90px' }}>
                          <div className="pfl_info_l">
                            <div className="pfl_info_l_inn">
                              <img src="https://kombatlink-assets.azureedge.net/img/trophy.svg" alt="" />
                            </div>
                          </div>
                          <div className="pfl_info_r">
                            <h4 className="pfl_info_num">{tournamentMembersIncome.TotalTournament}&nbsp;</h4>
                            <span className="pfl_info_ttl">Tournament Created</span>
                          </div>
                        </div>
                      </div>
                      <div className="pfl_info_col pfl_info_col-number">
                        <div className="pfl_info-number pfl_info" style={{ background: '#FD6969', height: '90px' }}>
                          <div className="pfl_info_l">
                            <div className="pfl_info_l_inn">
                              <img src="https://kombatlink-assets.azureedge.net/img/people.svg" alt="" />
                            </div>
                          </div>
                          <div className="pfl_info_r">
                            <h4 className="pfl_info_num">{tournamentMembersIncome?.TotalMembers?.length}&nbsp;</h4>
                            <span className="pfl_info_ttl">Total members</span>
                          </div>
                        </div>
                      </div>
                      <div className="pfl_info_col pfl_info_col-number">
                        <div className="pfl_info-number pfl_info" style={{ background: '#49C3FD', height: '90px' }}>
                          <div className="pfl_info_l">
                            <div className="pfl_info_l_inn">
                              <img src="https://kombatlink-assets.azureedge.net/img/cash.svg" alt="" />
                            </div>
                          </div>
                          <div className="pfl_info_r">
                            <h4 className="pfl_info_num">${tournamentMembersIncome.Income}&nbsp;</h4>
                            <span className="pfl_info_ttl">Money earned</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="pfl_pers-container">
                    <div className="section-ttl">
                      <div className="section-ttl_l">
                        <h2 className="section-ttl_ttl">Personal Information</h2>
                      </div>
                      <div className="section-ttl_r">
                        <button
                          id="open-edit-profile"
                          onClick={() => {
                            document.getElementById('edit-profile').className = 'modal-container modal-container--opened'
                          }}
                          className="btn-edit"
                          type="button"
                        >
                          <img src="https://kombatlink-assets.azureedge.net/img/pencil.svg" alt="" />
                          Edit
                        </button>
                      </div>
                    </div>
                    <div className="pfl_pers_row row">
                      <div className="col-12 col-md-6 col-xl-4">
                        <div className="pfl_pers pfl_pers-small">
                          <h3 className="pfl_pers_ttl">Email Address</h3>
                          <div className="pfl_pers_txt">
                            <a href={`mailto:${_organizerProfileObj.emailaddress}`}>{_organizerProfileObj.emailaddress}</a>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-4">
                        <div className="pfl_pers pfl_pers-small">
                          <h3 className="pfl_pers_ttl">Location</h3>
                          <div className="pfl_pers_txt">
                            <span>
                              {_organizerProfileObj.RegionName != null && this.state.userCountryCode != null
                                ? _organizerProfileObj.RegionName + ', ' + GeneralUtils.capitalizeFirstLetter(this.state.userCountryCode)
                                : ''}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-4">
                        <div className="pfl_pers pfl_pers-small">
                          <h3 className="pfl_pers_ttl">Phone number</h3>
                          <div className="pfl_pers_txt">
                            <span>
                              <a href="tel:9059671111">{_organizerProfileObj.tel}</a>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="pfl_pers pfl_pers-big">
                          <h3 className="pfl_pers_ttl">About</h3>
                          <div className="pfl_pers_txt">
                            <p>{_organizerProfileObj.about}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <OrganizerReviews userId={this.state._organizerProfileObj?.userid}/>

                </div>
                <RecentTournaments organizerId={this.state._organizerProfileObj?.userid} />         
              </div>
            </div>
          </div>
        </div>
        <aside className="modal-container" id="modal_Warning-Close" style={{ zIndex: 999999 }}>
          <div className="modal-container__background" />
          <div className="profile-modal profile-modal-medium window">
            <div
              className="window__close"
              onClick={async () => {
                this.setState({
                  showWarning:false,
                },()=>{
                  document.getElementById('modal_Warning-Close').className = 'modal-container'
                })
                
              }}
            />
            <div className="profile-modal_inn">
              <h3 className="profile-modal_ttl--big text-center">Warning,You are about to close your account, would you like to proceed</h3>
              <div className="disable-premium_buttons">
                <button
                  className="btn-modal-primery open-modal_disable-success"
                  onClick={async () => {
                    this.updateDisabled()
                    document.getElementById('modal_Warning-Close').className = 'modal-container'
                  }}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </aside>
        <aside className="modal-container" id="modal_disable-premium">
          <div className="modal-container__background" />
          <div className="profile-modal profile-modal-medium disable-premium modal-container__window window">
            <div
              className="window__close"
              onClick={() => {
                document.getElementById('modal_disable-premium').className = 'modal-container'
              }}
            />
            <div className="profile-modal_inn">
              {!user.IsPremium && (
                <>
                  <h3 className="profile-modal_ttl--big text-center">You don't have Premium account</h3>
                  <div className="disable-premium_buttons">
                    <button
                      className="btn-violet-ountline"
                      onClick={() => {
                        this.setState({ upgradetoPremium: true })
                      }}
                    >
                      Upgrade To Premium Account
                    </button>
                    <button
                      className="btn-violet-primery open-modal_disable-success"
                      onClick={async () => {
                        document.getElementById('modal_disable-premium').className = 'modal-container'
                      }}
                    >
                      CANCEL
                    </button>
                  </div>
                </>
              )}
              {user.IsPremium && (
                <>
                  <h3 className="profile-modal_ttl--big text-center">Premium account</h3>
                  <p className="profile-modal_txt--small text-center">
                    You can always cancel your premium subscription. Premium will end when your current payment cycle is over. Do you still want to
                    cancel subscription?
                  </p>
                  <div className="disable-premium_buttons">
                    <button
                      className="btn-violet-ountline"
                      onClick={() => {
                        document.getElementById('modal_disable-premium').className = 'modal-container'
                      }}
                    >
                      Stay premium
                    </button>
                    <button
                      className="btn-violet-primery open-modal_disable-success"
                      onClick={async () => {
                        this.removeUserSubscription()
                      }}
                    >
                      CANCEL
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </aside>
        <div id="notic" />
        <aside className="modal-container" id="edit-profile">
          <div className="modal-container__background"></div>
          <div className="modal-container__window window">
            <a
              className="window__close" onClick={() => {
                this.setState({
                  showWarning:false,
                },()=>{
                  document.getElementById('edit-profile').className = 'modal-container'
                })
                    
              }}
            >
            </a>
            <div className="window__header-container">
              <div className="row">
                <div className="col-auto popup-title">
                  <h2 className="window__header">Edit profile</h2>
                  <div className="prof-btn">
                    <input type="checkbox" id="show-warning" className="window__checkbox" />
                    <div className="center m-auto">
                      {showWarning && (
                        <div className="window__profile-status-warning-container-edit-profile">
                          <div className="window__profile-status-warning-container-active-edit-profile" id="notic">
                            {' '}
                            Caution, this will close your account permanently. To activate the account again you will need to contact &nbsp;
                            <a onClick={(e) => e.preventDefault()}>Kombatlink support</a>&nbsp; continue.{' '}
                          </div>
                        </div>
                      )}
                      <label
                        className="window__profile-status m-auto"
                        onClick={() => {
                          this.setState({ showWarning: !this.state.showWarning })
                          setTimeout(() => {
                            document.getElementById('modal_Warning-Close').className = 'modal-container modal-container--opened'
                          }, 1000)
                          document.getElementById('show-warning').click()
                        }}
                      >
                        {' '}
                        Close profile{' '}
                      </label>
                    </div>
                  </div>
                </div>
                <div
                  className="col-sm-auto col-12" 
                  onClick={e => {
                    e.currentTarget.className = ' row m-0 rowActive'
                  }}
                >
                 
                 
                </div>
              </div>
            </div>
            <div className="window__container">
              <div className="container-fluid p-0">
                <div className="row overflow-auto">
                  <div className="col-md-6 col-12">
                    <div className="row m-0 flex-column">
                      <h4 className="window__subheader">Email Address</h4>
                      <input
                        type="email"
                        className="window__input"
                        name="emailaddress"
                        value={_organizerProfileObj.emailaddress || ''}
                        onChange={e => {
                          this.onchangeHandler(e.target.name, e.target.value)
                        }}
                        placeholder="Email"
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="row m-0 flex-column">
                      <h4 className="window__subheader">Location</h4>
                      <input
                        type="text"
                        className="window__input"
                        name="country"
                        readOnly
                        value={
                          _organizerProfileObj.RegionName != null && this.state.userCountryCode != null
                            ? _organizerProfileObj.RegionName + ', ' + GeneralUtils.capitalizeFirstLetter(this.state.userCountryCode)
                            : ''
                        }
                        onChange={e => {
                          this.onchangeHandler(e.target.name, e.target.value)
                        }}
                        placeholder="Location"
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="row m-0 flex-column">
                      <h4 className="window__subheader">Phone number</h4>
                      <input
                        type="tel"
                        name="tel"
                        value={_organizerProfileObj.tel || ''}
                        onChange={e => {
                          this.onchangeHandler(e.target.name, e.target.value)
                        }}
                        className="window__input"
                        placeholder="905 9671111"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="row m-0 flex-column">
                      <h4 className="window__subheader">About</h4>
                      <textarea
                        name="about"
                        value={_organizerProfileObj.about}
                        style={{ minHeight: '100%', overflow: 'visible' }}
                        onChange={e => {
                          this.onchangeHandler(e.target.name, e.target.value)
                        }}
                        className="window__textarea"
                        placeholder="About me..."
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <hr className="window__hr"></hr>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="row m-0 flex-column">
                      <h4 className="window__subheader">Discord</h4>
                      <input
                        type="tel"
                        name="discord"
                        value={_organizerProfileObj.discord || ''}
                        onChange={e => {
                          this.onchangeHandler(e.target.name, e.target.value)
                        }}
                        className="window__input"
                        placeholder="http://www.discord.com"
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="row m-0 flex-column">
                      <h4 className="window__subheader">Facebook</h4>
                      <input
                        name="facebook"
                        value={_organizerProfileObj.facebook || ''}
                        onChange={e => {
                          this.onchangeHandler(e.target.name, e.target.value)
                        }}
                        type="tel"
                        className="window__input"
                        placeholder="www.facebook.com"
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="row m-0 flex-column">
                      <h4 className="window__subheader">Twitter</h4>
                      <input
                        name="twitter"
                        value={_organizerProfileObj.twitter || ''}
                        onChange={e => {
                          this.onchangeHandler(e.target.name, e.target.value)
                        }}
                        type="tel"
                        className="window__input"
                        placeholder="donuthouse@twitter.com"
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="row m-0 flex-column">
                      <h4 className="window__subheader">Instagram</h4>
                      <input
                        name="instagram"
                        value={_organizerProfileObj.instagram || ''}
                        onChange={e => {
                          this.onchangeHandler(e.target.name, e.target.value)
                        }}
                        type="tel"
                        className="window__input"
                        placeholder="donuthouse@instagram"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="row m-0 justify-content-center">
                      <button
                        className="window__button-edit-profile" onClick={() => {
                          this.saveProfile()
                          document.getElementById('edit-profile').className = 'modal-container'
                        }}
                      >SAVE CHANGES
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </aside> 
        <aside className="modal-container" id="modal_identity-verify-warning" style={{ zIndex: 999999 }}>
          <div className="modal-container__background" />
          <div className="identity-verify-modal identity-verify-modal-medium window">
            <div
              className="window__close"
              onClick={async () => {
                document.getElementById('modal_identity-verify-warning').className = 'modal-container'
              }}
            />
            <div className="identity-verify-modal_inn">
              <p className="profile-modal_ttl--big mt-4 mb-4 ml-2 text-center">
                Verify your identity and start accepting payments,
                {user.isorganizer && user.Stripe_IdentityUrl == null && (
                  <a
                    title="Verify your identity and start accepting payments"
                    onClick={async () => {
                      this.setState(
                        {
                          showDialouge: true,
                        },
                        () => {
                          document.getElementById('modal_identity-verify-warning').className = 'modal-continer'
                        }
                      )
                    }}
                    className="alink ml-2"
                  >
                    Click Here
                  </a>
                )}
                {user.isorganizer && user.Stripe_IdentityUrl !== null && (
                  <a
                    title="Verify your identity and start accepting payments"
                    onClick={() => (window.location.href = user.Stripe_IdentityUrl)}
                    className="alink ml-2"
                  >
                    Click Here
                  </a>
                )}{' '}
                To Confirm
              </p>
            </div>
          </div>
        </aside>
      </>
    )
  }
}

export default OrganizerProfile
