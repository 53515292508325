import React, { useState,useEffect,useRef, useCallback } from 'react'
import  '../../../_shared/TicketSeatingArrangement/TicketSeatingArrangement.scss'
import { APP_NAMESPACE, DEFAULTS, DEFAULT_LAYOUT, DEFAULT_LAYOUT_COLOR, DRAG_DATA_KEY, getPosition, LIMITS, SHAPE_TYPES } from './utils/constant/constant'
import { Layer, Stage } from 'react-konva'
import { Shape } from './utils/components/Shape'
import clamp from "clamp";
import { RowSpaceData, TournamentTicketSectionDetail } from 'models/tournament-models'
import { defaultFormatDate, defaultFormatTime, round2Places } from 'utils'
import { SelectedSeatsModel } from 'models/shopping-card-model'
interface canvasObj{
  shapes:any
}

export const TicketSectionArrangement=({packageTicket,onSave,operationType,handleOperationType,IsCartPage,onAddRemoveSeats,getSelectedSeats,updateQuantityLoader,paymentMethod,CurrentCryptoCurrencyExchangeRate,CurrentLoyaltyPointsExchangeRate,LoyaltypointsValue,ShoppingCard,isOrganizerVerified,organizerCurrency,handleTicketPriceChange})=>{
  const currentLayout = localStorage.getItem("DefaultLayOut")!=null?localStorage.getItem("DefaultLayOut"):0
  const [canvasShapes,setCanvasData] = useState<canvasObj[]>(localStorage.getItem(APP_NAMESPACE)!=null?JSON.parse(localStorage.getItem(APP_NAMESPACE)):[])
  const [selectedShapeId,setSelectedShape] = useState<string>('')
  const [mainWidth,setMainWidth] = useState<number>(0)
  const [currentSelectedShape,setCurrentSelectedShape] = useState<canvasObj>(Object())
  const [selectedLayout, setselectedLayout] = useState(Number(currentLayout))
  const [isValidNumberOfRows, setValidNumberOfRows] = useState(true)
  const [isValidNumberOfSeats, setValidNumberOfSeats] = useState(true)
  const [numberOfRows,setTotalRows]=useState(currentSelectedShape && currentSelectedShape?.RowsPerSection?Number(currentSelectedShape.RowsPerSection):0)
  const [numberOfSeats,setTotalSeats]=useState(currentSelectedShape && currentSelectedShape?.SeatsPerRow?Number(currentSelectedShape.SeatsPerRow):0)
  const [sectionTicketPrice,setSectionTicketPrice]=useState(currentSelectedShape && currentSelectedShape?.SectionTicketPrice?Number(currentSelectedShape.SectionTicketPrice):0)
  const [packageSectionTicketPrice,setPackageSectionTicketPrice]=useState(currentSelectedShape && currentSelectedShape?.PackageSectionTicketPrice?Number(currentSelectedShape.PackageSectionTicketPrice):0)
  const [rowSpaceData,setRowSpaceData]=useState<RowSpaceData[]>([])
  const [currentSectionId,setCurrentSectionId] = useState<number>(0)
  
  const stageRef = useRef()
  const mainRef = useRef()
  let timeOutFunctionId:any=0
  const fitStageIntoParentContainer=()=>{
    if(stageRef?.current){
      const resizableCanvasShapes=localStorage.getItem("ticketsections") == null?[]:JSON.parse(localStorage.getItem("ticketsections"))
      const layoutShapeIndex = resizableCanvasShapes.findIndex(x=>x.IsLayOut === true)
      if(layoutShapeIndex >= 0){
        const shapeProps = JSON.parse(resizableCanvasShapes[layoutShapeIndex].ShapeProperties)
        document.getElementsByClassName('canvas-div')[0]?.scrollTo((shapeProps.x+(shapeProps.width/2)),0)
      }
    //     if(resizableCanvasShapes.length > 0){
    //       const layoutShapeIndex = resizableCanvasShapes.findIndex(x=>x.IslayOut === true)

    }
    // clearTimeout(timeOutFunctionId)
    // timeOutFunctionId = setTimeout(function(){
    //   var container = document.querySelector('.canvas-div')
    //   var mainContainer = document.querySelector('.main-container')
    //   const width = container?.offsetWidth
    //   if(stageRef?.current){
    //     var scale = width / mainContainer.offsetWidth;
    //     stageRef?.current.width(scale * mainContainer.offsetWidth);
    //     stageRef?.current.height(scale * mainContainer.offsetHeight);
    //     stageRef?.current.scale({ x: scale, y: scale });
    //     const resizableCanvasShapes=localStorage.getItem("ticketsections") == null?[]:JSON.parse(localStorage.getItem("ticketsections"))
        
    //     if(resizableCanvasShapes.length > 0){
    //       const layoutShapeIndex = resizableCanvasShapes.findIndex(x=>x.IslayOut === true)
    //       resizableCanvasShapes.map((x:any)=>{
    //         let shapeProps = JSON.parse(x.ShapeProperties)
    //         if(x.IsLayOut){
    //           shapeProps.x = (container.offsetWidth * scale/2) - 50
    //         }
    //         else{
    //           if(layoutShapeIndex >= 0){
    //             if(shapeProps.x){
    //               shapeProps.x = (container.offsetWidth * scale/2) + resizableCanvasShapes[layoutShapeIndex].x
    //             }
    //             else{
    //               shapeProps.groupoffSetX = (container.offsetWidth * scale/2) + resizableCanvasShapes[layoutShapeIndex].x
    //             }
    //           }
    //           else{
    //             if(shapeProps.x){
    //               shapeProps.x = (container.offsetWidth * scale/2) + 50
    //             }
    //             else{
    //               shapeProps.groupoffSetX = (container.offsetWidth * scale/2) + 50
    //             }
    //           } 
    //         }
    //         x.ShapeProperties = JSON.stringify(shapeProps)
    //       })
    //       setCanvasData([...resizableCanvasShapes])
    //   }
    //   }
    // }, 500)
   }
  window.addEventListener('resize', fitStageIntoParentContainer);

  
  useEffect(()=>{
    if(mainRef?.current){
      const { width } = mainRef.current.getBoundingClientRect()
      setMainWidth(width)
    } 
  },[])

 
            

  useEffect(()=>{
    
    if(canvasShapes){
     localStorage.setItem(APP_NAMESPACE,JSON.stringify(canvasShapes))
    }
    if(currentSelectedShape && Object.keys(currentSelectedShape).length > 0){
      setTotalRows(currentSelectedShape?.RowsPerSection)
      setTotalSeats(currentSelectedShape?.SeatsPerRow)
      setSectionTicketPrice(currentSelectedShape?.SectionTicketPrice)
      setPackageSectionTicketPrice(currentSelectedShape?.PackageSectionTicketPrice)
      setRowSpaceData(currentSelectedShape?.RowSpaceArrangement)
 
     }  
  },[canvasShapes,currentSelectedShape])

  
  const getMaxId = (data: any) => {
    if (data) {
      let Ids: number[] = []
      data.map((x: any, value: any) => {
        Ids.push(x.Id)
      })

      if (Ids.length > 0) {
        return Math.max(...Ids) + 1
      }
      else {
        return 1
      }
    }
    else {
      return 1
    }
  }
  const onDataSave=useCallback(()=>{
    const data = JSON.parse(localStorage.getItem(APP_NAMESPACE))
    let sectionDetails:TournamentTicketSectionDetail[] =[]
    data.map((x:any,value:any)=>{
        const sectionProps = JSON.parse(x.ShapeProperties)
        sectionProps.Id = x.Id
        let sectionDetail:TournamentTicketSectionDetail=Object()
        sectionDetail.Id = x.Id
        sectionDetail.IsLayOut = x.IsLayOut
        sectionDetail.RowsPerSection = x.RowsPerSection
        sectionDetail.SeatsPerRow = x.SeatsPerRow
        sectionDetail.SectionName = sectionProps?.SectionName
        sectionDetail.SectionShapeType = x.SectionShapeType
        sectionDetail.SectionTicketPrice = x.SectionTicketPrice
        sectionDetail.PackageSectionTicketPrice = x.PackageSectionTicketPrice
        sectionDetail.ShapeProperties = x.ShapeProperties
        sectionDetail.RowSpaceArrangement = x.RowSpaceArrangement
        sectionDetails.push({...sectionDetail})
    })
    if(confirm("Are you sure to submit section Arrangement Details?")){
      onSave(sectionDetails,selectedLayout)
    }
  },[selectedLayout])
  
  const createSquare =(x:number,y:number,type:string = ''):any=>{
    const data = localStorage.getItem(APP_NAMESPACE)!=null?JSON.parse(localStorage.getItem(APP_NAMESPACE)):[]
    if(type === 'layout'){
      return{
        Id: getMaxId(data),
        SectionShapeType: SHAPE_TYPES.SQUARE,
        width: DEFAULT_LAYOUT.SQUARE.WIDTH,
        height: DEFAULT_LAYOUT.SQUARE.HEIGHT,
        fill: DEFAULT_LAYOUT_COLOR.SQUARE.FILL,
        stroke: DEFAULT_LAYOUT_COLOR.SQUARE.STROKE,
        rotation: DEFAULT_LAYOUT.SQUARE.ROTATION,
        x,
        y,
	      IsLayOut:true
      }
    }
    else{
      return{
        Id: getMaxId(data),
        SectionShapeType: SHAPE_TYPES.SQUARE,
        width: DEFAULTS.SQUARE.WIDTH,
        height: DEFAULTS.SQUARE.HEIGHT,
        fill: DEFAULTS.SQUARE.FILL,
        stroke: DEFAULTS.SQUARE.STROKE,
        rotation: DEFAULTS.SQUARE.ROTATION,
        x,
        y,
        IsLayOut:false
    }
    }
 }
  const createRectangle =(x:number,y:number):any=>{
    const data = localStorage.getItem(APP_NAMESPACE)!=null?JSON.parse(localStorage.getItem(APP_NAMESPACE)):[]
    return{
        Id: getMaxId(data),
        SectionShapeType: SHAPE_TYPES.RECT,
        width: DEFAULTS.RECT.WIDTH,
        height: DEFAULTS.RECT.HEIGHT,
        fill: DEFAULTS.RECT.FILL,
        stroke: DEFAULTS.RECT.STROKE,
        rotation: DEFAULTS.RECT.ROTATION,
        x,
        y
    }
 }
  const createCircle =(x:number,y:number,type:string=''):any =>{
    const data = localStorage.getItem(APP_NAMESPACE)!=null?JSON.parse(localStorage.getItem(APP_NAMESPACE)):[]
    if(type === 'layout'){
      return{
        Id: getMaxId(data),
        SectionShapeType: SHAPE_TYPES.CIRCLE,
        radius: DEFAULT_LAYOUT.CIRCLE.RADIUS,
        fill: DEFAULT_LAYOUT_COLOR.CIRCLE.FILL,
        stroke: DEFAULT_LAYOUT_COLOR.CIRCLE.STROKE,
        x,
        y,
        IsLayOut:true
      }
    }
    else{
      return{
        Id: getMaxId(data),
        SectionShapeType: SHAPE_TYPES.CIRCLE,
        radius: DEFAULTS.CIRCLE.RADIUS,
        fill: DEFAULTS.CIRCLE.FILL,
        stroke: DEFAULTS.CIRCLE.STROKE,
        x,
        y,
        IsLayOut:false
      }
    }
    
  }
  const createEllipse =(x:number,y:number,type:string=''):any =>{
    const data = localStorage.getItem(APP_NAMESPACE)!=null?JSON.parse(localStorage.getItem(APP_NAMESPACE)):[]
    
      return{
        Id: getMaxId(data),
        SectionShapeType: SHAPE_TYPES.ELLIPSE,
        radius:{
          x:DEFAULT_LAYOUT.ELLIPSE.RADIUSX,
          y:DEFAULT_LAYOUT.ELLIPSE.RADIUSY
        },
        fill: DEFAULT_LAYOUT_COLOR.ELLIPSE.FILL,
        stroke: DEFAULT_LAYOUT_COLOR.ELLIPSE.STROKE,
        x,
        y,
	      IsLayOut:true
      }
  }
  const createTriangle =(x:number,y:number,type:string=''):any =>{
    const data = localStorage.getItem(APP_NAMESPACE)!=null?JSON.parse(localStorage.getItem(APP_NAMESPACE)):[]
    
      return{
        Id: getMaxId(data),
        SectionShapeType: SHAPE_TYPES.TRIANGLE,
        points:[x+100,y,x,y+200,x+200,y+200],
        fill: DEFAULTS.TRIANGLE.FILL,
        stroke: DEFAULTS.TRIANGLE.STROKE,
        radius:DEFAULTS.TRIANGLE.RADIUS,
        x,
        y
      }
  }
  const createArc =(x:number,y:number,offSet:any):any =>{
    const data = localStorage.getItem(APP_NAMESPACE)!=null?JSON.parse(localStorage.getItem(APP_NAMESPACE)):[]
      return{
        Id: getMaxId(data),
        SectionShapeType: SHAPE_TYPES.ARC,
        radius:{
          innerRadius:DEFAULTS.ARC.INNERRADIUS,
          outerRadius:DEFAULTS.ARC.OUTERADIUS
        },
        innerAnchor:{
          name:'inner',
          position:getPosition({x:x,y:y},DEFAULTS.ARC.INNERRADIUS,DEFAULTS.ARC.ROTATION)
        },
        outerAnchor:{
          name:'outer',
          position:getPosition({x:x,y:y},DEFAULTS.ARC.OUTERADIUS,Number(DEFAULTS.ARC.ROTATION) + Number(DEFAULTS.ARC.ANGLE)),
        },
        dragAnchor:{
          name:'drag',
          position:{x:x,y:y}
        },
        fill: DEFAULTS.ARC.FILL,
        stroke: DEFAULTS.ARC.STROKE,
        angle:DEFAULTS.ARC.ANGLE,
        rotation:DEFAULTS.ARC.ROTATION,
        x,
        y,
        groupoffSetX:offSet.offsetX,
        groupoffSetY:offSet.offsetY
      }
  }
  const handleDragStart = (event:any) => {
    const SectionShapeType = event.target.dataset.shape;
  
    if (SectionShapeType) {
      // x,y coordinates of the mouse pointer relative to the position of the padding edge of the target node
      const offsetX = event.nativeEvent.offsetX;
      const offsetY = event.nativeEvent.offsetY;
  
      // dimensions of the node on the browser
      const clientWidth = event.target.clientWidth;
      const clientHeight = event.target.clientHeight;
  
      const dragPayload = JSON.stringify({
        SectionShapeType,
        offsetX,
        offsetY,
        clientWidth,
        clientHeight,
      });
  
      event.nativeEvent.dataTransfer.setData(DRAG_DATA_KEY, dragPayload);
    }
  };
  
  
  const handleDragOver = (event:any) => event.preventDefault()
  const handleDrop = useCallback((event) => {
    const draggedData = event.nativeEvent.dataTransfer.getData(DRAG_DATA_KEY);
  
    if (draggedData) {
      const { offsetX, offsetY, SectionShapeType, clientHeight, clientWidth } = JSON.parse(
        draggedData
      );
  
      stageRef?.current.setPointersPositions(event);
  
      const coords = stageRef?.current.getPointerPosition();
      if (SectionShapeType === SHAPE_TYPES.SQUARE) {
        // rectangle x, y is at the top,left corner
        const rectangleObj = createSquare(
          coords.x - offsetX,
          coords.y - offsetY,
        )
        let CanvasObj:canvasObj=Object()
        CanvasObj.Id = rectangleObj.Id
        CanvasObj.SectionShapeType = rectangleObj.SectionShapeType
        CanvasObj.ShapeProperties = JSON.stringify(rectangleObj)
        CanvasObj.IsLayOut = false
        setCanvasData(prevState => [...prevState, CanvasObj])
        
      }
      else if (SectionShapeType === SHAPE_TYPES.RECT) {
        // rectangle x, y is at the top,left corner
        const rectangleObj = createRectangle(
          coords.x - offsetX,
          coords.y - offsetY,
        )
        let CanvasObj:canvasObj=Object()
        CanvasObj.Id = rectangleObj.Id
        CanvasObj.SectionShapeType = rectangleObj.SectionShapeType
        CanvasObj.ShapeProperties = JSON.stringify(rectangleObj)
        CanvasObj.IsLayOut = false
        setCanvasData(prevState => [...prevState, CanvasObj])
        
      } else if (SectionShapeType === SHAPE_TYPES.CIRCLE) {
        // circle x, y is at the center of the circle
        const circleObj = createCircle(
          coords.x - (offsetX - clientWidth / 2),
          coords.y - (offsetY - clientHeight / 2),
        )
        let CanvasObj:canvasObj=Object()
        CanvasObj.Id = circleObj.Id
        CanvasObj.SectionShapeType = circleObj.SectionShapeType
        CanvasObj.ShapeProperties = JSON.stringify(circleObj)
        CanvasObj.IsLayOut = false
        
        setCanvasData(prevState => [...prevState, CanvasObj])
        
        
      }
      else if(SectionShapeType === SHAPE_TYPES.ARC){
        const arcObj = createArc(coords.x - (offsetX - clientWidth / 2),coords.y - (offsetY - clientHeight / 2),{offsetX,offsetY}
          )
        let CanvasObj:canvasObj=Object()
        CanvasObj.Id = arcObj.Id
        CanvasObj.SectionShapeType = arcObj.SectionShapeType
        CanvasObj.ShapeProperties = JSON.stringify(arcObj)
        CanvasObj.IsLayOut = false
        
        setCanvasData(prevState => [...prevState, CanvasObj])
       
      }
      else if(SectionShapeType === SHAPE_TYPES.TRIANGLE){
        const arcObj = createTriangle(
          coords.x-(offsetX-clientWidth),
          coords.y+5-(offsetY-clientHeight),
          )
        let CanvasObj:canvasObj=Object()
        CanvasObj.Id = arcObj.Id
        CanvasObj.SectionShapeType = arcObj.SectionShapeType
        CanvasObj.ShapeProperties = JSON.stringify(arcObj)
        CanvasObj.IsLayOut = false
        
        setCanvasData(prevState => [...prevState, CanvasObj])
       
      }
    }
  }, []);
  
  const moveShape = (Id:any, event:any,props:any,shapeType:string='') => {
     
    const canvasShapeObj = localStorage.getItem(APP_NAMESPACE)!=null? JSON.parse(localStorage.getItem(APP_NAMESPACE)):[]
    const shapeIndex = canvasShapeObj.findIndex(x=>x.Id == Id);
    if (shapeIndex >=0) {
      let shapeProperties = JSON.parse(canvasShapeObj[shapeIndex].ShapeProperties)
      if(event !== null){
        shapeProperties.x = event.target.x();
        shapeProperties.y = event.target.y();
      }
      if(shapeType == SHAPE_TYPES.TRIANGLE || shapeType == SHAPE_TYPES.ARC){
        props.Id = Id
        canvasShapeObj[shapeIndex].ShapeProperties = JSON.stringify(props)
      }
      else{
        shapeProperties.Id = Id
        canvasShapeObj[shapeIndex].ShapeProperties = JSON.stringify(shapeProperties)
      }
      setCanvasData([...canvasShapeObj])
      setCurrentSelectedShape({...canvasShapeObj[shapeIndex]})
      setTotalRows(canvasShapeObj[shapeIndex]?.RowsPerSection)
      setTotalSeats(canvasShapeObj[shapeIndex]?.SeatsPerRow)
      setSectionTicketPrice(canvasShapeObj[shapeIndex]?.SectionTicketPrice)
      setPackageSectionTicketPrice(canvasShapeObj[shapeIndex]?.PackageSectionTicketPrice)
      
      setRowSpaceData(canvasShapeObj[shapeIndex]?.RowSpaceArrangement)
    }
  }
  const clearSelection =()=>{
    setSelectedShape('')
  }
  const handleSelectedShape =(Id:any)=>{
    if(Id!=''){
      const canvasShapeObj = localStorage.getItem(APP_NAMESPACE)!=null? JSON.parse(localStorage.getItem(APP_NAMESPACE)):[]
      const shapeIndex = canvasShapeObj.findIndex(x=>x.Id == Id);
      setCurrentSelectedShape({...canvasShapeObj[shapeIndex]})
      setSelectedShape(Id)
    }
  }
  const deleteShape=()=>{
    const canvasShapeObj = localStorage.getItem(APP_NAMESPACE)!=null? JSON.parse(localStorage.getItem(APP_NAMESPACE)):[]
    const shapeIndex = canvasShapeObj.findIndex(x=>x.Id == selectedShapeId);
    if(shapeIndex >= 0){
      canvasShapes.splice(shapeIndex,1)
      setCanvasData([...canvasShapes])
      clearSelection()
      setCurrentSelectedShape(Object())
    }
  }
  const resetCanvas=()=>{
    setCanvasData([])
    clearSelection()
  }
  const handleUpdateText=useCallback((Id,textProp:any,value:any)=>{
    const canvasShapeObj = localStorage.getItem(APP_NAMESPACE)!=null? JSON.parse(localStorage.getItem(APP_NAMESPACE)):[]
    const shapeIndex = canvasShapeObj.findIndex(x=>x.Id == Id);
    if(shapeIndex >= 0){
      let shapeProperties = JSON.parse(canvasShapeObj[shapeIndex].ShapeProperties)
      shapeProperties[textProp] = value
      canvasShapeObj[shapeIndex].ShapeProperties = JSON.stringify(shapeProperties)
      setCanvasData([...canvasShapeObj])
      setCurrentSelectedShape({...canvasShapeObj[shapeIndex]})
    }
  },[canvasShapes])
  const setDefaultStageLayOut=(LayOutType:number)=>{
    const canvasShapeObj = localStorage.getItem(APP_NAMESPACE)!=null? JSON.parse(localStorage.getItem(APP_NAMESPACE)):[]
    const layOutIndex = canvasShapeObj.findIndex(x=>x.IsLayOut == 1);
      if(LayOutType == 1){
        
        const { left,right,top,bottom } = mainRef.current.getBoundingClientRect()
        const x = ((right - left) / 2)
        const y = ((bottom - top) / 3) 
        var obj = createSquare(x, y, 'layout')
        let CanvasObj:canvasObj=Object()
        CanvasObj.SectionShapeType = SHAPE_TYPES.SQUARE
        CanvasObj.ShapeProperties=JSON.stringify(obj)
        CanvasObj.IsLayOut = true
        CanvasObj.Id = obj.Id
        if(layOutIndex >= 0){
          
          canvasShapeObj[layOutIndex] = CanvasObj
          
          setCanvasData([...canvasShapeObj])
        }
        else{
          setCanvasData(prevState => [...prevState, CanvasObj])
        }
        //createRectangle(Math.random() * window.innerWidth,Math.random() * window.innerHeight)
        //createRectangle(Math.random() * window.innerWidth,Math.random() * window.innerHeight)
        //createRectangle(Math.random() * window.innerWidth,Math.random() * window.innerHeight)
      }
      if(LayOutType == 2){
        
      	const { left, right, top, bottom } = mainRef.current.getBoundingClientRect()
      	const x = ((right - left) / 2) + 50
      	const y = ((bottom - top) / 2)
      	//var obj = createEllipse((left + right) / 2 - 400, ((bottom + top) / 2) - 400, 'layout')
      	var obj = createEllipse(x, y, 'layout')
        let CanvasObj:canvasObj=Object()
        CanvasObj.SectionShapeType = SHAPE_TYPES.ELLIPSE
        CanvasObj.ShapeProperties=JSON.stringify(obj)
        CanvasObj.IsLayOut = true
        CanvasObj.Id = obj.Id
        if(layOutIndex >= 0){
          canvasShapeObj[layOutIndex] = CanvasObj
          setCanvasData([...canvasShapeObj])
        }
        else{
          setCanvasData(prevState => [...prevState, CanvasObj])
        }
        //createRectangle(Math.random() * window.innerWidth,Math.random() * window.innerHeight)
        //createRectangle(Math.random() * window.innerWidth,Math.random() * window.innerHeight)
        //createRectangle(Math.random() * window.innerWidth,Math.random() * window.innerHeight)
      }
      if(LayOutType == 3){
        
        const { left,right,top,bottom } = mainRef.current.getBoundingClientRect()
      	const x = ((right - left) / 2) + 50
      	const y = ((bottom - top) / 3) + 100
      	//var obj = createCircle((left + right) / 2 - 400, ((bottom + top) / 2) - 400, 'layout')
      	var obj = createCircle(x, y, 'layout')
       
        let CanvasObj:canvasObj=Object()
        CanvasObj.SectionShapeType = SHAPE_TYPES.CIRCLE
        CanvasObj.ShapeProperties=JSON.stringify(obj)
        CanvasObj.IsLayOut = true
        CanvasObj.Id = obj.Id
        if(layOutIndex >= 0){
          canvasShapeObj[layOutIndex] = CanvasObj
          
          setCanvasData([...canvasShapeObj])
        }
        else{
          setCanvasData(prevState => [...prevState, CanvasObj])
        }
        //createRectangle(Math.random() * window.innerWidth,Math.random() * window.innerHeight)
        //createRectangle(Math.random() * window.innerWidth,Math.random() * window.innerHeight)
        //createRectangle(Math.random() * window.innerWidth,Math.random() * window.innerHeight)
      }
  }
  const setLayOutType = (type:number)=>{
    localStorage.setItem("DefaultLayOut",type.toString())
    setselectedLayout(type)
    setDefaultStageLayOut(type)
  }
  
  const updateAttribute = (attr:any, value:any) => {
    const canvasShapeObj = localStorage.getItem(APP_NAMESPACE)!=null? JSON.parse(localStorage.getItem(APP_NAMESPACE)):[]
    const shapeIndex = canvasShapeObj.findIndex(x=>x.Id == selectedShapeId);
      if (shapeIndex >= 0) {
        const shape = JSON.parse(canvasShapeObj[shapeIndex].ShapeProperties)
        shape[attr] = value;
        canvasShapeObj[shapeIndex].ShapeProperties=JSON.stringify(shape)
        setCanvasData([...canvasShapeObj])
        setCurrentSelectedShape({...canvasShapeObj[shapeIndex]})
      }
  };
  const updateAttr = (event:any) => {
    const attr = event.target.name;

    updateAttribute(attr, event.target.value);
  }
  
  const transformShape = (node:any, Id:any, event:any) => {
    const scaleX = node.scaleX()
    const scaleY = node.scaleY()
    node.scaleX(1)
    node.scaleY(1)
    const canvasShapeObj = localStorage.getItem(APP_NAMESPACE)!=null? JSON.parse(localStorage.getItem(APP_NAMESPACE)):[]
    const shapeIndex = canvasShapeObj.findIndex(x=>x.Id == Id)
      if (shapeIndex >= 0) {
        const shape = JSON.parse(canvasShapeObj[shapeIndex].ShapeProperties)
        if(shape.SectionShapeType === SHAPE_TYPES.CIRCLE){
          shape.x = node.x();
          shape.y = node.y();
    
          shape.radius = clamp(
            (node.findOne("Circle").width() * scaleX) / 2,
            LIMITS.CIRCLE.MIN,
            LIMITS.CIRCLE.MAX
          );
        }
        else if(shape.SectionShapeType === SHAPE_TYPES.ELLIPSE){
          shape.rotation = node.rotation();
          shape.x = node.x();
          shape.y = node.y();
          const x=clamp(node.findOne("Ellipse").width() * scaleX / 2,LIMITS.ELLIPSE.MIN, LIMITS.ELLIPSE.MAX)
          const y=clamp(node.findOne("Ellipse").height() * scaleY / 2,LIMITS.ELLIPSE.MIN, LIMITS.ELLIPSE.MAX)
          
          shape.radius = {
              x:x,
              y:y
          }
          
        }
        else if(shape.SectionShapeType === SHAPE_TYPES.SQUARE){
          shape.x = node.x();
          shape.y = node.y();
          shape.width = clamp(
            // increase the width in order of the scale
            node.width() * scaleX,
            // should not be less than the minimum width
            LIMITS.SQUARE.MIN,
            // should not be more than the maximum width
            LIMITS.SQUARE.MAX
          );
          shape.height = clamp(
            node.height() * scaleY,
            LIMITS.SQUARE.MIN,
            LIMITS.SQUARE.MAX
          );
        }
        else if(shape.SectionShapeType === SHAPE_TYPES.RECT){
          shape.x = node.x();
          shape.y = node.y();
          shape.rotation = node.rotation();
          shape.width = clamp(
            // increase the width in order of the scale
            node.width() * scaleX,
            // should not be less than the minimum width
            LIMITS.RECT.MIN,
            // should not be more than the maximum width
            LIMITS.RECT.MAX
          );
          shape.height = clamp(
            node.height() * scaleY,
            LIMITS.RECT.MIN,
            LIMITS.RECT.MAX
          );
        }
        else if(shape.SectionShapeType === SHAPE_TYPES.TRIANGLE){
          shape.rotation = node.rotation();
          shape.x = node.x();
          shape.y = node.y();
          shape.width = clamp(
            // increase the width in order of the scale
            node.width() * scaleX,
            // should not be less than the minimum width
            LIMITS.TRIANGLE.MIN,
            // should not be more than the maximum width
            LIMITS.TRIANGLE.MAX
          );
          shape.height = clamp(
            node.height() * scaleY,
            LIMITS.TRIANGLE.MIN,
            LIMITS.TRIANGLE.MAX
          );
          
        }
        else if(shape.SectionShapeType === SHAPE_TYPES.ARC){
          shape.rotation = node.rotation();
          shape.x = node.x();
          shape.y = node.y();
          shape.width = clamp(
            // increase the width in order of the scale
            node.width() * scaleX,
            // should not be less than the minimum width
            LIMITS.CIRCLE.MIN,
            // should not be more than the maximum width
            LIMITS.CIRCLE.MAX
          );
          shape.height = clamp(
            node.height() * scaleY,
            LIMITS.CIRCLE.MIN,
            LIMITS.CIRCLE.MAX
          );
          shape.radius.innerRadius = node.innerRadius() 
          shape.radius.outerRadius = node.outerRadius()
        }
        canvasShapeObj.IsLayOut = false
        canvasShapeObj[shapeIndex].ShapeProperties=JSON.stringify(shape)
        setCanvasData([...canvasShapeObj])
        setSelectedShape("")
      }
    
  }
  const setDataForSection=(key:string,e:any)=>{
    if(key === 'RowsPerSection'){
      setTotalRows(Number(e.target.value))
      setValidNumberOfRows(true)
    }
    if(key === 'SeatsPerRow'){
      setTotalSeats(Number(e.target.value))
     
      setValidNumberOfSeats(true)
    }
    if(isOrganizerVerified){
      if(key === 'SectionTicketPrice'){
        setSectionTicketPrice(Number(e.target.value))
      }
      if(key === 'PackageSectionTicketPrice'){
        setPackageSectionTicketPrice(Number(e.target.value))
      }
    }
    else{
      handleTicketPriceChange(0,0)
    }
    const canvasShapeObj = localStorage.getItem(APP_NAMESPACE)!=null? JSON.parse(localStorage.getItem(APP_NAMESPACE)):[]
    const shapeIndex = canvasShapeObj.findIndex(x=>x.Id == selectedShapeId);
    if (shapeIndex >= 0) {
      const shape = canvasShapeObj[shapeIndex]
        shape[key] = Number(e.target.value)
        setCanvasData([...canvasShapeObj])
        setCurrentSelectedShape({...canvasShapeObj[shapeIndex]})
    }
  } 
  const openSectionDetail=(Id:any,event:any)=>{
    handleSelectedShape(Id)
    
    handleOperationType("ticketArrangementDetail")
    if(IsCartPage && Id > 0){
      setCurrentSectionId(Id)
      getSelectedSeats(Id)
    }
  }
  const addRowSpace=()=>{
    if(numberOfRows > 0){
      const canvasShapeObj = localStorage.getItem(APP_NAMESPACE)!=null? JSON.parse(localStorage.getItem(APP_NAMESPACE)):[]
      const shapeIndex = canvasShapeObj.findIndex(x=>x.Id == selectedShapeId);
      const currentRowSpaceData = canvasShapeObj[shapeIndex]?.RowSpaceArrangement 
      const obj:RowSpaceData={
        Id:currentRowSpaceData == undefined?1:currentRowSpaceData.length+1,
        PreSpaceRow:0,
        PostSpaceRow:0,
        SectionId:selectedShapeId
      }
      if(currentRowSpaceData == undefined){
        canvasShapeObj[shapeIndex].RowSpaceArrangement=[]
        
      }
      canvasShapeObj[shapeIndex].RowSpaceArrangement.push({...obj})
      
      
      setCanvasData([...canvasShapeObj])
      setCurrentSelectedShape({...canvasShapeObj[shapeIndex]})
      setRowSpaceData(canvasShapeObj[shapeIndex].RowSpaceArrangement)
    }
    else{
      setValidNumberOfRows(false)
    }
  }
  const setRowValueForSpace=(type:string,e:any,index:number)=>{
    if(e.target.value == "0"){
      e.preventDefault()
      return false
    }
    const canvasShapeObj = localStorage.getItem(APP_NAMESPACE)!=null? JSON.parse(localStorage.getItem(APP_NAMESPACE)):[]
      const shapeIndex = canvasShapeObj.findIndex(x=>x.Id == selectedShapeId);
      const currentSectionRowSpaceData = canvasShapeObj[shapeIndex]?.RowSpaceArrangement 
      const currentRowSpaceDataIndex = currentSectionRowSpaceData.findIndex(x=>x.Id == index)
      currentSectionRowSpaceData[currentRowSpaceDataIndex][type]= e.target.value
      canvasShapeObj[shapeIndex].RowSpaceArrangement[currentRowSpaceDataIndex] = currentSectionRowSpaceData[currentRowSpaceDataIndex]
      setCanvasData([...canvasShapeObj])
      setCurrentSelectedShape({...canvasShapeObj[shapeIndex]})
      setRowSpaceData(canvasShapeObj[shapeIndex].RowSpaceArrangement)
  }
  const removeSpace=(Id:number)=>{
    const canvasShapeObj = localStorage.getItem(APP_NAMESPACE)!=null? JSON.parse(localStorage.getItem(APP_NAMESPACE)):[]
    const shapeIndex = canvasShapeObj.findIndex(x=>x.Id == selectedShapeId);
   
    const rowSpaceArrangements = canvasShapeObj[shapeIndex].RowSpaceArrangement
    const currentIndex=rowSpaceArrangements.findIndex(x=>x.Id===Id)
    rowSpaceArrangements.splice(currentIndex,1)
    setRowSpaceData([...rowSpaceArrangements])
    canvasShapeObj[shapeIndex].RowSpaceArrangement = rowSpaceArrangements
    setCanvasData([...canvasShapeObj])
    setCurrentSelectedShape({...canvasShapeObj[shapeIndex]})
  }
  const isRowSpace =(rowNumber:number)=>{
    
    let rowClassName ="row no-wrap"
    const rowSpaceData = currentSelectedShape?.RowSpaceArrangement
    if(rowSpaceData && rowSpaceData.length > 0){
      const isPreSpaceExist = rowSpaceData.filter(x=>x.PreSpaceRow !== '' && x.PreSpaceRow == rowNumber).length > 0
      if(isPreSpaceExist){
        rowClassName +=" mt-5"
      }
      const isPostSpaceExist = rowSpaceData.filter(x=>x.PostSpaceRow !=='' && x.PostSpaceRow == rowNumber).length > 0
      if(isPostSpaceExist){
        rowClassName +=" mb-5"
      }
    }
    if(updateQuantityLoader > 0)
      return rowClassName +" disable-cart"
    else
      return rowClassName 
  }
  const renderShape=useCallback((shape:any)=>{
    return JSON.parse(shape.ShapeProperties)
  },[])
  const calculateTotalSeats=()=>{
      let totalSectionSeats:SelectedSeatsModel[]=[]
      if(currentSelectedShape && currentSelectedShape?.TicketSectionRows){
        totalSectionSeats =currentSelectedShape?.TicketSectionRows.flatMap(x=>x.TicketRowSeats)
      }
      return totalSectionSeats
 
  }
  const IsPackageTicket =()=>{
    return ShoppingCard && ShoppingCard.DayNumber === null && ShoppingCard.Time === null
  }
  const IsPackageTicketSelected =()=>{
    if(ShoppingCard.SelectedSeats.length > 0){
      
      return !IsPackageTicket() && ShoppingCard.SelectedSeats.filter(x=>x.IsTaken === true && x.IsPurchased === true && x.IsPackageTicket === true).length > 0
    }
    return false
  }
  const IsDayTicketSelected =()=>{
    if(ShoppingCard.SelectedSeats.length > 0){
      
      return IsPackageTicket() && ShoppingCard.SelectedSeats.filter(x=>x.IsTaken === true && x.IsPurchased === true && x.IsPackageTicket === false).length > 0
    }
    return false
  }
  const IsSoldOutSectionSeats=(SectionId:number)=>{
    if(ShoppingCard.SelectedSeats && ShoppingCard.SelectedSeats.length === 0){
      return false
    }
    return ShoppingCard.SelectedSeats.filter(x=>x.SectionId === SectionId && x.IsSoldOutSectionSeats=== true).length > 0
  }
  const IsPurchasedSeats=(SectionId:number)=>{
    if(ShoppingCard.SelectedSeats && ShoppingCard.SelectedSeats.length === 0){
      return false
    }
    const totalSeats = calculateTotalSeats()
    let totalSolOutSeats = ShoppingCard.SelectedSeats.filter(x=>x.SectionId === SectionId && x.IsSoldOutSectionSeats!= true && x.IsPurchased === true && x.IsTaken === false)?.flatMap(x=>x.SeatId)
    totalSolOutSeats =[...new Set(totalSolOutSeats)]
    return totalSolOutSeats.length === totalSeats.length
  }
  const IsValidTournament=(SectionId:number)=>{
    if(ShoppingCard.SelectedSeats && ShoppingCard.SelectedSeats.length === 0){
      return true
    }
    
    return ShoppingCard.SelectedSeats.filter(x=>x.SectionId === SectionId && x.IsTournamentValid === false).length === 0
  }
  const getSeatStatus=(seatId:number)=>{
    if(ShoppingCard.SelectedSeats!=null && ShoppingCard.SelectedSeats && ShoppingCard.SelectedSeats.length > 0)
    {   
      const index = ShoppingCard.SelectedSeats.findIndex(x=>x.SeatId === seatId)
      if(index >= 0){
        if(IsPackageTicket()){
          if(ShoppingCard.SelectedSeats[index].IsPackageTicket){
            if(!ShoppingCard.SelectedSeats[index].IsPurchased && ShoppingCard.SelectedSeats[index].IsTaken){
              return "seat selected "+ (updateQuantityLoader > 0?"disable-cart":"")
            }
            else{
              return "seat occupied "+ (updateQuantityLoader > 0?"disable-cart":"")
            }
          }
          else if(!ShoppingCard.SelectedSeats[index].IsPackageTicket){
            if(ShoppingCard.SelectedSeats[index].IsPurchased && ShoppingCard.SelectedSeats[index].IsTaken){
              return "seat packageticketselected "+ (updateQuantityLoader > 0?"disable-cart":"")
            }
            else{
              return "seat occupied "+ (updateQuantityLoader > 0?"disable-cart":"")
            }
          }
          
        }
        else if(!IsPackageTicket()){

          if(ShoppingCard.SelectedSeats[index].IsPackageTicket){
            if(ShoppingCard.SelectedSeats[index].IsPurchased && ShoppingCard.SelectedSeats[index].IsTaken){
              return "seat packageticketselected "+ (updateQuantityLoader > 0?"disable-cart":"")
            }
            else{
              return "seat occupied "+ (updateQuantityLoader > 0?"disable-cart":"")
            }
          }
          else if(!ShoppingCard.SelectedSeats[index].IsPackageTicket){
            if(!ShoppingCard.SelectedSeats[index].IsPurchased && ShoppingCard.SelectedSeats[index].IsTaken){
              return "seat selected "+ (updateQuantityLoader > 0?"disable-cart":"")
            }
            else{
              return "seat occupied "+ (updateQuantityLoader > 0?"disable-cart":"")
            }
          }
          
        }
        
      }
      else{
        return "seat "+ (updateQuantityLoader > 0?"disable-cart":"")
      }    
    }
    return "seat "+ (updateQuantityLoader > 0?"disable-cart":"")
  }
  const calculateSeatTicketDetail=(key:string)=>{
      if(key === "TotalSeats" && ShoppingCard.SelectedSeats!=null){
        const totalSeats = ShoppingCard.SelectedSeats.filter(x=>x.SectionId == currentSectionId && x.TicketId === ShoppingCard.TicketId)
        return totalSeats.length > 0 ?totalSeats.filter(x=>x.IsTaken === true && x.IsPurchased == false && x.TicketId === ShoppingCard.TicketId).length:0
      }
      else if((key === "SectionTicketPrice" || key === "PackageSectionTicketPrice") && ShoppingCard.SelectedSeats!=null){
        const totalSeats = ShoppingCard.SelectedSeats.filter(x=>x.SectionId == currentSectionId && x.TicketId === ShoppingCard.TicketId)
        if(key === "SectionTicketPrice")
          return  calculatePrice(!currentSelectedShape.SectionTicketPrice?0:currentSelectedShape.SectionTicketPrice *totalSeats.filter(x=>x.IsTaken === true && x.IsPurchased == false).length)
        else
        return  calculatePrice(!currentSelectedShape.SectionTicketPrice?0:currentSelectedShape.PackageSectionTicketPrice *totalSeats.filter(x=>x.IsTaken === true && x.IsPurchased == false).length)
      }
  }
  const calculatePrice = (price:any)=>{
      let sectionPrice=''
      if(paymentMethod === "Credit"){
        sectionPrice = ShoppingCard.CurrencySymbol +" " + round2Places(Number(
          price *
            round2Places(ShoppingCard.ExchangeRate,2)
            
        ),
        2)+" "+ShoppingCard.CurrencyCode 
      }
      else if(paymentMethod === "Crypto"){
        sectionPrice = '$ '+ round2Places(
          CurrentCryptoCurrencyExchangeRate *
            Number(
              price *
                (round2Places(ShoppingCard.ExchangeRate,2))
            ),
          2
        )+" USD"
      }
      else if(paymentMethod === "LoyaltyPoints"){
        sectionPrice = Math.round(round2Places(
          CurrentLoyaltyPointsExchangeRate *
            LoyaltypointsValue *
            (price *
              round2Places(ShoppingCard.ExchangeRate,2)),
          2
        ))+" LP"
      }
      return sectionPrice
  }
  const handleAddRemoveSeats=(isAdd:boolean,seatId:number,sectionId:number)=>{
    const price = ShoppingCard.DayNumber === null && ShoppingCard.Time === null?currentSelectedShape?.PackageSectionTicketPrice:currentSelectedShape?.SectionTicketPrice
    onAddRemoveSeats(isAdd,seatId,sectionId,price)
  }
  return(
    <>
      {operationType === 'sectionArrangement' &&
        <div className="row">
          <div className="col-md-8 pr-md-0 mb-3 mb-md-0 main-container">
            <div className="section-head">
            {IsCartPage !== true && <h4 className="text-white section-title-header">Please choose one of the stage layouts and then select one of the seating layouts and drag and drop onto the floor area to best replicate the venue setup.</h4>}
              {/* {IsCartPage !== true && <h4 className="text-white section-title">Please drag and drop the shapes to best replicate the venue seating</h4>} */}
              {IsCartPage === true && <h4 className="text-white section-title">Venue seating layout</h4>}
              {operationType === 'sectionArrangement' && IsCartPage !== true && <button className="btn btn-gray" onClick={() => { resetCanvas() }}>Reset</button>}
            </div>
            <main className="canvas-div" onDrop={handleDrop} onDragOver={handleDragOver} ref={mainRef}>
              <Stage
                ref={stageRef}
                width={mainWidth}
                height={window.innerHeight}
                onClick={clearSelection}>
                {canvasShapes.map((shape, key) => (
                  <Layer>
                    <Shape key={key} shape={renderShape({ ...shape })} selectedShape={selectedShapeId} setSelectedShape={handleSelectedShape} moveShape={moveShape} transformShape={transformShape} operationType={operationType} openSectionDetail={openSectionDetail} handleUpdateText={handleUpdateText} IsCartPage={IsCartPage} />
                  </Layer>
                ))}
              </Stage>
            </main>
          </div>

          {IsCartPage === true && <div className="col-md-4 pl-md-0">
          <aside className="panel">
              <div className="inner-panel showcase-wrap">
                <div className="properties">
                    <h3 className="container-title font-white">{ShoppingCard.Title}</h3>
                    <div className="row datetime">
                        <div className="col-md-6">
                          Date
                          <span>{defaultFormatDate(ShoppingCard.DisplayTicketDate)}</span>
                        </div>
                        <div className="col-md-4">
                          Time
                          <span>{defaultFormatTime(ShoppingCard.DisplayTicketDate)}</span>
                         </div>
                         <div className="col-md-2"></div>
                    </div>
                    
                </div>
              </div>
          </aside>
            
          </div>}
          {IsCartPage !== true && <div className="col-md-4 pl-md-0">

          <div className="row h-100 align-items-stretch justify-content-md-end">
            <div className="col-12 col-md-10">
                <div className="text seats-sum row">
                    <div className="seats-set d-flex justify-content-between flex-column col-12">
                        <div className="row m-0">
                            <div className="col-12 p-0">
                                <div className="sign-up__input-title mb-3">
                                    Stage layout
                                </div>
                                <div className="d-flex justify-content-between align-items-end ">
                                  <div className="d-flex flex-direction-row justify-content-between shape-wrap">
                                    <div className="checkbox-btn">
                                      <input value={selectedLayout} checked={selectedLayout === 1} type="radio" id="role-1" className="sign-up__role shape" name="role" onClick={() => setLayOutType(1)} />
                                      <label htmlFor="role-1" className="sign-up__role-label role-label">
                                        <div className="role-label__svg role-label__svg--gamepad">
                                          <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x={1} y={1} width={16} height={16} rx={3} stroke="#5F6D8C" strokeWidth={2} />
                                          </svg>
                                          <span>square</span>
                                        </div>
                                      </label>
                                    </div>
                                    <div className="checkbox-btn">
                                      <input value={selectedLayout} checked={selectedLayout === 2} type="radio" id="role-2" className="sign-up__role shape" name="role" onClick={() => setLayOutType(2)} />
                                      <label htmlFor="role-2" className="sign-up__role-label role-label">
                                        <div className="role-label__svg role-label__svg--person">
                                          <svg width={16} height={24} viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x={1} y={1} width={14} height={22} rx={7} stroke="#5F6D8C" strokeWidth={2} />
                                          </svg>
                                          <span>Oval</span>
                                        </div>
                                      </label>
                                    </div>
                                    <div className="checkbox-btn">
                                      <input value={selectedLayout} checked={selectedLayout === 3} type="radio" id="role-3" className="sign-up__role shape" name="role" onClick={() => setLayOutType(3)} />
                                      <label htmlFor="role-3" className="sign-up__role-label role-label">
                                        <div className="role-label__svg role-label__svg--person">
                                          <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x={1} y={1} width={16} height={16} rx={8} stroke="#5F6D8C" strokeWidth={2} />
                                          </svg>
                                          <span>Circle</span>
                                        </div>
                                      </label>
                                    </div>
                                  
                                      {/* <input value={1} checked={selectedLayout === 1}  id="role-1" className="sign-up__role shape" name="role" onClick={() => setLayOutType(1)} />
                                      <label checked={selectedLayout === 1} htmlFor="role-1" className="sign-up__role-label role-label">
                                          <span className="role-label__svg role-label__svg--gamepad d-flex align-items-center w-100 justify-content-center">
                                              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <rect x="1" y="1" width="16" height="16" rx="1" stroke="#7584A5" stroke-width="2" />
                                              </svg>
                                              Square
                                          </span>
                                      </label> */}
                                      {/* <input value={2} checked={selectedLayout === 2}  id="role-2" className="sign-up__role shape" name="role" onClick={() => setLayOutType(2)} />
                                      <label checked={selectedLayout === 2} htmlFor="role-2" className="sign-up__role-label role-label">
                                          <span checked={selectedLayout === 2} className="role-label__svg role-label__svg--person d-flex align-items-center w-100 justify-content-center">
                                              <svg width="16" height="24" viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <rect x="1" y="1" width="14" height="22" rx="7" stroke="#5F6D8C" stroke-width="2" />
                                              </svg>
                                              Oval
                                          </span>
                                      </label> */}
                                      {/* <input value={3} checked={selectedLayout === 3}  id="role-3" className="sign-up__role shape" name="role" onClick={() => setLayOutType(3)} />
                                      <label checked={selectedLayout === 3} htmlFor="role-3" className="sign-up__role-label role-label">
                                          <span checked={selectedLayout === 2} className="role-label__svg role-label__svg--person d-flex align-items-center w-100 justify-content-center">
                                              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <rect x="1" y="1" width="16" height="16" rx="8" stroke="#7584A5" stroke-width="2" />
                                              </svg>
                                              Сircle
                                          </span>
                                      </label> */}
                                  </div>
                                </div>
                                <div className="sign-up__input-title">
                                  Seating layout
                                </div>
                                <div className="d-flex flex-row seats_list">
                                  <div className="shape-wrapper">
                                    <div className="shape rectangle"
                                      data-shape={SHAPE_TYPES.RECT}
                                      draggable
                                      onDragStart={handleDragStart}
                                    />
                                  </div>
                                  <div className="shape-wrapper">
                                    <div
                                      className="shape square"
                                      data-shape={SHAPE_TYPES.SQUARE}
                                      draggable
                                      onDragStart={handleDragStart}
                                    />
                                  </div>
                                  <div className="shape-wrapper">
                                    <div
                                      className="shape circle"
                                      data-shape={SHAPE_TYPES.CIRCLE}
                                      draggable
                                      onDragStart={handleDragStart}
                                    />
                                  </div>
                                  <div className="shape-wrapper">
                                    <div className="triangle"
                                      data-shape={SHAPE_TYPES.TRIANGLE}
                                      draggable
                                      onDragStart={handleDragStart}
                                    />
                                  </div>
                                  <div className="shape-wrapper">
                                    <div
                                      className="shape arc"
                                      data-shape={SHAPE_TYPES.ARC}
                                      draggable
                                      onDragStart={handleDragStart}
                                    />
                                  </div>
                                </div>

                                <div className="d-flex flex-column">
                                {selectedShapeId !== '' ? (<div id="listed-container-prop">
                                      <div className="sign-up__input-title">
                                          Properties
                                      </div>
                                      {currentSelectedShape && !renderShape(currentSelectedShape)?.IsLayOut &&<div className="list-prop d-flex justify-content-between align-items-center">
                                        <span className="text-left">Section Name{" "}</span>
                                        <input
                                          className="form-control"
                                          name="SectionName"
                                          type="text"
                                          value={currentSelectedShape && renderShape(currentSelectedShape)?.SectionName?renderShape(currentSelectedShape)?.SectionName:''}
                                          onChange={updateAttr}
                                        />
                                        
                                      </div>}
                                      <div className="list-prop d-flex justify-content-between align-items-center">
                                        <span>Fill{" "}</span>
                                        <input style={{background:"#394664"}}
                                          className="value"
                                          name="fill"
                                          type="color"
                                          value={currentSelectedShape && renderShape(currentSelectedShape)?.fill}
                                          onChange={updateAttr}
                                        />
                                      </div>
                                      <div className="list-prop d-flex justify-content-between align-items-center">
                                        <span>Stroke{" "}</span>
                                        <input style={{background:"#394664"}}
                                          className="value"
                                          name="stroke"
                                          type="color"
                                          value={currentSelectedShape && renderShape(currentSelectedShape)?.stroke}
                                          onChange={updateAttr}
                                        />
                                      </div>
                                  </div>):""}
                                  {operationType === 'sectionArrangement' && selectedShapeId !== '' && 
                                  currentSelectedShape && !renderShape(currentSelectedShape)?.IsLayOut && <div className="justify-content-center controller-btn">
                                  <button className="prop__button btn btn-danger  mr-1 darkred-button mt-1 btn-section-detail" onClick={() => { deleteShape() }}>Delete</button>
                                  <button className="prop__button btn btn-primary darkred-button mt-1  btn-section-detail" onClick={() => { handleOperationType("ticketArrangementDetail") }}>Add Seating Arrangements</button>
                                  </div>}
                                  {operationType === 'sectionArrangement' && selectedShapeId !== '' && 
                                  currentSelectedShape && renderShape(currentSelectedShape)?.IsLayOut && <div className="justify-content-left controller-btn">
                                  <button className="prop__button btn btn-danger  mr-1 darkred-button mt-1 btn-section-detail" onClick={() => { deleteShape() }}>Delete</button>
                                  
                                  </div>}
                                </div>

                            </div>
                        </div>
                    </div>
                    {operationType === 'sectionArrangement' && <div className="row m-0 align-items-end modal-section-save-btn">
                    <div className="col-12 m-sm-20" id="shape-container-1">
                        <button className="seats__button blue-button green-button--filled w-100" onClick={() => { onDataSave("ticketArrangement") }}>Save</button>
                    </div>
                </div>}
                </div>
            </div>
            
          </div>
          
          </div>}
        </div>}
      

      {operationType === 'ticketArrangementDetail' &&
        <>
          <section className="seat-arrangement mb-3">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-2 text-left">
                <a className="seat-back-arrow" onClick={() => { handleOperationType("sectionArrangement") }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" stroke="#fff" className="bi bi-arrow-left" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                  </svg>
                  go back
                </a>
              </div>
              <div className="col-md-8 text-left">
                <h4 className="font-white mb-0">Choose seats for event</h4>
              </div>
              
            </div>
          </section>
          <section className="square-section">
            <div className="row">
              <div className="col-md-8">
                <div className="seat-left-box">
                  <div>
                    <h5 className="text-center all-eyes-title">All eyes this way please!!</h5>
                  </div>
                  <div className="scroll-seat-container mt-4 mb-4" style={{ position: 'relative' }}>
                   {IsCartPage!=true && [...Array(numberOfRows)].map((x, i) =>
                      <>
                        <div className={isRowSpace(i + 1 )} style={{ width: (numberOfSeats * 28) + 'px' }}>
                          {numberOfSeats > 0 && [...Array(numberOfSeats)].map((x, i) =>
                            <>
                              <span className="seat" style={{ left: (23 * (i)) + 'px' }}></span>
                            </>
                          )}
                        </div>
                      </>
                    )}
                    { IsCartPage && currentSelectedShape.TicketSectionRows && currentSelectedShape.TicketSectionRows.map((x, i) =>{
                     
                      if(IsSoldOutSectionSeats(currentSectionId) || !IsValidTournament(currentSectionId) || IsPurchasedSeats(currentSectionId)){
                        return <>
                         <div className={isRowSpace(i + 1)+(updateQuantityLoader > 0 ?" disable-cart":"")} style={{ width: (numberOfSeats * 28) + 'px' }}>
                        {x.TicketRowSeats.length > 0  && x.TicketRowSeats.map((y, j) =>{
                          
                         
                             return <span title={"Row "+y.RowNumber+" - Seat "+y.SeatNumber} data-sectionrowseatid={y.Id} className="seat occupied" style={{ left: (23 * (j)) + 'px' }}></span> 
                         
                        })}
                        
                         </div>
                         
                         <div className="sold-out-seats">{ !IsValidTournament(currentSectionId) ?'Event has been Expired':IsSoldOutSectionSeats(currentSectionId) || IsPurchasedSeats(currentSectionId) ?'Sold Out!!':''}</div>
                         </>
                        
                      }
                      else{
                        return <div className={isRowSpace(i + 1)+(updateQuantityLoader > 0 ?" disable-cart":"")} style={{ width: (numberOfSeats * 28) + 'px' }}>
                        {x.TicketRowSeats.length > 0  && x.TicketRowSeats.map((y, j) =>{
                          
                          if(getSeatStatus(y.Id).includes("seat occupied") || getSeatStatus(y.Id).includes("seat packageticketselected"))
                             return <span title={"Row "+y.RowNumber+" - Seat "+y.SeatNumber} data-sectionrowseatid={y.Id} className={getSeatStatus(y.Id)} style={{ left: (23 * (j)) + 'px' }}></span> 
                         else
                           return <span title={"Row "+y.RowNumber+" - Seat "+y.SeatNumber} onClick={()=>{handleAddRemoveSeats(!getSeatStatus(y.Id).includes("seat selected"),y.Id,x.SectionId)}} data-sectionrowseatid={y.Id} className={getSeatStatus(y.Id)} style={{ left: (23 * (j)) + 'px' }}></span> 
                        })}
                         </div>
                      }
                      
                           
                           
                           
                     
                    }
                      
                    )}
                  </div>
                </div>
              </div>

              {IsCartPage !== true && <div className="col-md-4">
                <div className="seats-set">
                  <div id="price-container">
                    <div className="sign-up__input-title">Price for Ticket section (in {organizerCurrency === undefined?'USD':organizerCurrency})</div>
                    <div className="sign-up__container movie-container">
                      <input name="price-username" id="movie" type="text" className="sign-up__default-input" placeholder="Enter Price" value={currentSelectedShape?.SectionTicketPrice} onChange={(e) => { setDataForSection('SectionTicketPrice', e) }} />
                    </div>
                    {packageTicket && currentSelectedShape && <><div className="sign-up__input-title">Price for Package Ticket Section (in {organizerCurrency === undefined?'USD':organizerCurrency})</div>
                    <div className="sign-up__container movie-container">
                      <input name="price-package" id="movie" type="text" className="sign-up__default-input" placeholder="Enter Package Section Price" value={currentSelectedShape?.PackageSectionTicketPrice} onChange={(e) => { setDataForSection('PackageSectionTicketPrice', e) }} />
                    </div></>}
                  </div>
                  <div className="row">
                    <div id="row-container" className="col-12 col-lg-6">
                      <div className="sign-up__input-title">Number of rows(*)</div>
                      <div className="sign-up__container">
                        <input name="row" id="numOfRows" type="text" className="sign-up__default-input" placeholder="Enter Number Of Rows" value={currentSelectedShape?.RowsPerSection} onChange={(e) => { setDataForSection('RowsPerSection', e) }} />
                      </div>
                      <div className={'w-100 flex-row article__error error error--rounds-0' + (isValidNumberOfRows === false ? ' error-visible' : '')}>
                        <div className="error__icon" />
                        <div className="error__text">Please enter number of rows </div>
                      </div>
                    </div>
                    <div id="seats-container" className="col-12 col-lg-6">
                      <div className="sign-up__input-title">Number of seats(*)</div>
                      <div className="sign-up__container">
                        <input name="row" id="numOfSeats" type="text" className="sign-up__default-input" placeholder="Enter Number Of Seats" value={currentSelectedShape?.SeatsPerRow} onChange={(e) => { setDataForSection('SeatsPerRow', e) }} />

                      </div>
                      <div className={'w-100 flex-row article__error error error--rounds-0' + (!isValidNumberOfSeats ? ' error-visible' : '')}>
                        <div className="error__icon" />
                        <div className="error__text">Please enter number of seats </div>
                      </div>

                    </div>
                    <div id="space-container" className="col-12">
                      <div className="sign-up__input-title">Space between rows</div>
                      <div className="">
                        <div className="row m-0">
                          <button type="button" className="btn btn-blue" onClick={() => { addRowSpace() }}>Add</button>
                        </div>
                        {rowSpaceData && rowSpaceData.length >= 1 && <div className="row m-0">
                          <div className="col-6 col-lg-6 pl-lg-0 sign-up__input-title">
                            Pre space row number
                          </div>
                          <div className="col-6 col-lg-6 sign-up__input-title">
                            Post space row number
                          </div>
                        </div>}
                        <div className="row m-0 row-space-scroll align-items-center">
                          {rowSpaceData && rowSpaceData?.length > 0 && rowSpaceData?.map((x: RowSpaceData, i) => {
                            return <>
                              <div className="col-6 col-md-6 col-xl-5 pl-lg-0 mb-3">
                                <input name={"rowPre" + i} className="sign-up__default-input" onChange={(e) => { setRowValueForSpace('PreSpaceRow', e, i + 1) }} type="number" value={x.PreSpaceRow} />
                              </div>
                              <div className="col-6 col-md-6 col-xl-5 mb-3">
                                <input name={"rowPost" + i} className="sign-up__default-input" onChange={(e) => { setRowValueForSpace('PostSpaceRow', e, i + 1) }} type="number" value={x.PostSpaceRow} />
                              </div>
                              <div className="col-12 col-md-12 pl-0 pl-md-1 col-xl-2 mb-3">
                                {/* <button type="button" className="seats__button btn-w-40" onClick={() => { removeSpace(x.Id) }}>X</button> */}

                                <button className="btn red-button--filled ml-2" onClick={() => { removeSpace(x.Id) }}>
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="s-ion-icon"><path d="M128 405.429C128 428.846 147.198 448 170.667 448h170.667C364.802 448 384 428.846 384 405.429V160H128v245.429zM416 96h-80l-26.785-32H202.786L176 96H96v32h320V96z"></path></svg>
                                </button>
                              </div>
                              {/* <AlwaysScrollToBottom /> */}
                            </>

                          })}

                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>}
              {IsCartPage === true && <div className="col-md-4">
              <aside className="panel">
                <div className="showcase-wrap">
                  <div className="properties">
                      <h3 className="container-title font-white">{ShoppingCard.Title}</h3>
                      <div className="row datetime">
                          <div className="col-lg-6 mb-3">
                            Date
                            <span>{defaultFormatDate(ShoppingCard.DisplayTicketDate)}</span>
                          </div>
                          <div className="col-lg-6">
                            Time
                            <span>{defaultFormatTime(ShoppingCard.DisplayTicketDate)}</span>
                          </div> 
                      </div>
                      <div className="row datetime">
                          <div className="col-md-12">
                            Single Seat Price
                            <span>{ShoppingCard.DayNumber == null && ShoppingCard.Time == null?calculatePrice(Number(currentSelectedShape.PackageSectionTicketPrice)):calculatePrice(Number(currentSelectedShape.SectionTicketPrice))}</span>
                          </div>
                          
                      </div>
                        <ul className="showcase">
                          <li>
                            <div className="seat occupied"></div>
                            <small className="small-font">Purchased</small>
                          </li>                    
                          <li>
                            <div className="seat"></div>
                            <small className="small-font">Available</small>
                          </li>
                          <li>
                            <div className="seat selected"></div>
                            <small className="small-font">Taken</small>
                          </li>
                          {IsPackageTicketSelected() && <li>
                            <div className="seat packageticketselected"></div>
                            <small className="small-font">Package Ticket Seat Selected</small>
                          </li>}
                          {IsDayTicketSelected() && <li>
                            <div className="seat packageticketselected"></div>
                            <small className="small-font">Single Day Ticket Seat Selected</small>
                          </li>}

                        </ul>
                  </div>
                  <hr/>
                  <div className="text seats-sum-ticket row mt-2">
                     <div className="col-lg-12 col-xl-4">
                        Section
                        <div className="total-wrap">
                          <span id="count">{currentSelectedShape?.SectionName}</span>
                        </div>      
                     </div>
                            
                     <div className="col-lg-12 col-xl-4">
                      Seat
                        <div className="total-wrap">
                          <span id="count">{calculateSeatTicketDetail('TotalSeats')}</span>
                        </div>
                      </div>
                      {ShoppingCard.DayNumber === null && ShoppingCard.Time === null? <div className="col-lg-12 col-xl-4">
                         Price
                         <div className="total-wrap"><span id="total">{calculateSeatTicketDetail('PackageSectionTicketPrice')}</span></div>
                       </div>:
                       <div className="col-lg-12 col-xl-4">
                       Price
                       <div className="total-wrap"><span id="total">{calculateSeatTicketDetail('SectionTicketPrice')}</span></div>
                     </div>
                     }
                     
                              
                  </div>
                  {ShoppingCard.SelectedSeats!=null && ShoppingCard.SelectedSeats.length > 0 && ShoppingCard.SelectedSeats.filter(a=>a.SectionId === currentSectionId  && a.IsPurchased === false && a.IsTaken === true).length > 0 &&
                  <div>
                  
                    <h3 className="font-white mt-1 small-font">Seat Details</h3>
                     <div className="col-md-12 properties p-0 tableFixHead">
                       <table className="table table-bordered">
                         <thead>
                         <tr>
                            <th className="font-white">Section #</th>
                            <th className="font-white">Row #</th>
                            <th className="font-white">Seat #</th>
                         </tr>
                         </thead>
                         <tbody>
                         {ShoppingCard.SelectedSeats.filter(a=>a.SectionId === currentSectionId && a.TicketId == ShoppingCard.TicketId && a.IsPurchased === false && a.IsTaken === true).map((x:SelectedSeatsModel)=>{
                            return <tr>
                             <td className="font-white">{x.SectionName}</td>
                             <td className="font-white">{x.RowNumber}</td>
                             <td className="font-white">{x.SeatNumber}</td>
                            </tr>
                         })}
                            
                         </tbody>
                       </table>
                     </div>
                     </div>
                     }
                     
                  
                </div>
                <div className="showcase-wrap-btn confirm-btn">
                  <button className="seats__button blue-button green-button--filled w-100" onClick={() => { onSave() }}>Confirm</button>
                </div>
                    
                
              </aside>
              
            
          </div>}
            </div>
          </section>
          

        </>
      }
      {/* {ShoppingCard.TicketMessage!=='' && (ShoppingCard.IsTournamentExpired || ShoppingCard.IsValid) &&
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 mt-2 text-center">
                  <span className="text-danger text-center" style={{whiteSpace: 'normal'}}>
                 {ShoppingCard.TicketMessage}
                  </span>
                </div>
              </div>
      } */}







    </>
  )
}