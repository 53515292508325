import { createSelector } from 'reselect'
import { OperationTypes } from 'store/logic/operations-logic'
import { makeGetIsOperationWithTypeRunning, makeGetIsOperationWithTypeSuccessOrRunning } from 'store/selectors/operations-selectors'
import { ApplicationState } from 'store/types/common'

export const getState = (state: ApplicationState) => state.chatMessages

const getById = createSelector(getState, state => state?.byId ?? {})

const getIdsbyChatId = createSelector(getState, state => state?.idsbyChatId ?? {})

const unreadChats = createSelector(getState, state => state?.unreadChats ?? [])


export const getChatMessagesIds = createSelector(getState, state => state.idsbyChatId ?? {})

export const makeGetChatMessagesIds = () =>
  createSelector(
    getIdsbyChatId,
    (_: ApplicationState, { chatId }: { chatId: number }) => chatId,
    (idsbyChatId, chatId) => idsbyChatId[chatId] ?? []
  )

export const makeGetChatMessage = () =>
  createSelector(
    getById,
    (_: ApplicationState, { chatMessageId }: { chatMessageId: number }) => chatMessageId,
    (byId, id) => byId[id]
  )

export const makeGetUnreadMessagesCount = () => 
  createSelector(
    unreadChats,
    unreadChats => unreadChats.reduce((pv: number, ch) => pv + ch.unreadMessagesCount, 0)
  )

export const makeGetUnreadLastMessages = () => 
  createSelector(
    unreadChats,
    unreadChats => unreadChats.map(uc => uc.lastUnreadMessageId)
  )


export const makeGetLastMessage = () => 
  createSelector(
    getById,
    getIdsbyChatId,
    (_: ApplicationState, { chatId }: { chatId: number }) => chatId,
    (byId, byChat, chatId) => {
      const chatMessages =  byChat[chatId]
      if (!chatMessages || chatMessages.length == 0) {
        return null
      }
      const lastId = chatMessages.sort((a, b) => b - a)[0]

      return lastId ? byId[lastId] : null
    }
  )


const getLoadChatMessagesOperationType = createSelector(
  (_, { chatId }: { chatId: number }) => chatId,
  chatId => ({ type: OperationTypes.loadChatMessages, objectId: chatId })
)
export const makeGetIsChatMessagesLoading = () => makeGetIsOperationWithTypeRunning(getLoadChatMessagesOperationType)
export const makeGetIsChatMessagesRequested = () => makeGetIsOperationWithTypeSuccessOrRunning(getLoadChatMessagesOperationType)

const getSendChatMessageOperationType = createSelector(
  (_, { chatId }: { chatId: number }) => chatId,
  chatId => ({ type: OperationTypes.sendChatMessage, objectId: chatId })
)
export const makeGetIsSendChatMessageLoading = () => makeGetIsOperationWithTypeRunning(getSendChatMessageOperationType)

export const makeGetUnreadMessagesCountByChatId = () => 
  createSelector(
    unreadChats,
    (_: ApplicationState, { chatId }: { chatId: number }) => chatId,
    (unreadChats, id) => unreadChats.find(x => x.chatId === id)
  )