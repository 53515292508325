import { ParticipantsResults } from 'components/_shared/MatchResultModal/MatchResultModal'
import React from 'react'
import { cn } from 'utils'
import MovieCard from './MovieCard'
import styles from './TournamentBracket.module.scss'

interface OwnProps {
  matchId: number
  openMatchResultModal: (participantResults: ParticipantsResults) => void
  openPlayerModal: (videoUrl: string) => void
  filterName: string
}

const MovieCardRow = ({matchId, openMatchResultModal, openPlayerModal, filterName} : OwnProps) => {
  return (
    <div className={cn('row', styles.movies__row, styles.movies__rowLast)}>
      <MovieCard key={matchId}  matchId={matchId} openMatchResultModal={openMatchResultModal} openPlayerModal={openPlayerModal} filterName={filterName} />
    </div>
  )
}

export default MovieCardRow
