import { Kl_OrganizerPayout } from '../../models/billing-option-model'
import { ExecuteAPIPost, ExecuteAPIGet } from '../server-utils'
import { EndPoints } from '../endpoints'

export const BillingOptionUtils = {
  saveOrganizerPayout: async function saveOrganizerPayout(organizerPayout: Kl_OrganizerPayout) {
    return await ExecuteAPIPost<any>(EndPoints.BillingOption.SaveOrganizerPayout, organizerPayout)
  },
  getOrganizerPayout:  async function getOrganizerPayout() {
    return await ExecuteAPIGet<Kl_OrganizerPayout>(EndPoints.BillingOption.GetOrganizerPayout)
  },
  getCurrencyByCountry: async function getCurrencyByCountry(name: string) {
    return await ExecuteAPIGet<string>(EndPoints.BillingOption.GetCurrencyByCountry, `countryName=${name}`)
  },
  getExchangeRateFromCurrency: async function getExchangeRateFromCurrency(currencyId:number){
    return await ExecuteAPIGet<any>(EndPoints.BillingOption.GetExchangeRateFromCurrency, `currencyId=${currencyId}`)
  }
}
