import { NotificationVisibility } from 'consts'
import { Reducer } from 'redux'
import { arrayToObject, distinct } from 'utils'
import {
  State,
  KnownAction,
  SET_NOTIFICATIONS,
  SET_UNREAD_NOTIFICATIONS_COUNT,
  SET_NOTIFICATION,
  ADD_NEW_NOTIFICATIONS,
  DELETE_NOTIFICATION,
  NOTIFICATION_READ,
  NOTIFICATION_QUEUE_LOADED,
  DEQUEUE_NOTIFICATION,
} from '../types/notification-types'

const initialState: State = {
  notificationsById: {},
  notificationQueueIds: [],
  notificationsIds: [],
  allNotificationsCount: 0,
  unreadNotificationsCount: 0,
}

const reducer: Reducer<State, KnownAction> = (state: State | undefined = initialState, action: KnownAction) => {
  switch (action.type) {
    case SET_NOTIFICATIONS: {
      const notificationsIds = action.notifications.map(x => x.Id)
      return {
        ...state,
        notificationsIds: action.reload ? notificationsIds : distinct(state.notificationsIds.concat(notificationsIds)),
        notificationsById: {
          ...state.notificationsById,
          ...arrayToObject(action.notifications, x => x.Id),
        },
        allNotificationsCount: action.allNotificationsCount,
      }
    }
    case SET_UNREAD_NOTIFICATIONS_COUNT:
      return {
        ...state,
        unreadNotificationsCount: action.unreadNotificationsCount,
      }
    case SET_NOTIFICATION:
      return {
        ...state,
        notificationsById: {
          ...state.notificationsById,
          [action.notification.Id]: action.notification,
        },
      }
    case DELETE_NOTIFICATION:
      return {
        ...state,
        notificationQueueIds: [...state.notificationQueueIds.filter(id => id != action.notificationId)],
        notificationsIds: [...state.notificationsIds.filter(id => id != action.notificationId)],
        notificationsById: {
          ...state.notificationsById,
          [action.notificationId]: undefined,
        },
      }
    case ADD_NEW_NOTIFICATIONS: {
      const newNotificationsIds = action.notifications.map(x => x.Id)
      const newNotificationsQueueIds = action.notifications
        .filter(n => !n.Read && n.Visibility == NotificationVisibility.Visible)
        .map(x => x.Id)
      return {
        ...state,
        notificationQueueIds: [...state.notificationQueueIds, ...newNotificationsQueueIds],
        notificationsIds: distinct(state.notificationsIds.concat(newNotificationsIds)),
        notificationsById: {
          ...state.notificationsById,
          ...arrayToObject(action.notifications, x => x.Id),
        },
      }
    }
    case DEQUEUE_NOTIFICATION: {
      return {
        ...state,
        notificationQueueIds: [...state.notificationQueueIds.filter(id => id != action.payload)],
      }
    }
    case NOTIFICATION_READ: {
      return {
        ...state,
        notificationQueueIds: [...state.notificationQueueIds.filter(id => id != action.payload)],
        notificationsById: {
          ...state.notificationsById,
          [action.payload]: {
            ...state.notificationsById[action.payload], 
            Read: true,
          },          
        },
        unreadNotificationsCount: state.unreadNotificationsCount > 0 ? state.unreadNotificationsCount - 1 : 0,
      }
    }
    case NOTIFICATION_QUEUE_LOADED: {
      return {
        ...state,
        notificationQueueIds: action.payload.map(n => n.Id),
        notificationsById: {
          ...state.notificationsById,
          ...arrayToObject(action.payload, x => x.Id),
        },
      }
    }
    default:
      return state
  }
}

export default reducer
