import { Button, ButtonColor } from 'components/_shared'
import { NotificationButtonType } from 'consts/NotificationButtonType'
import React from 'react'
import { NotificationModel } from 'store/types/notification-types'
import { cn } from 'utils'
import styles from './NotificationRow.module.scss'

interface Props {
  notification: NotificationModel
  onButtonClick?: (notification: NotificationModel) => void
  onButtonClickAsync?: (notification: NotificationModel) => Promise<void>
  className?: string
}

export const notificationLinkButtonColorByType: { [key: number]: ButtonColor } = {
  [NotificationButtonType.Positive]: 'green',
  [NotificationButtonType.Negative]: 'red',
  [NotificationButtonType.Neutral]: 'white',
}

const NotificationButtonLink = ({
  notification, 
  onButtonClick, 
  onButtonClickAsync,
  className = cn(styles.full, styles.button),
}: Props) => {  
  const handleButtonClick = async () => {    
    if (onButtonClickAsync) {
      await onButtonClickAsync(notification)      
    } else if (onButtonClick) {
      onButtonClick(notification)
    }
  }
  

  return (
    <Button
      onClick={handleButtonClick}
      className={className}
      filled      
      color={notificationLinkButtonColorByType[notification.Button.Type]}
    >
      {notification.Button.Text}
    </Button>
  )
}

export default NotificationButtonLink
