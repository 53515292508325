import { Modal } from 'components/_modals'
import { TournamentViewModel_kl } from 'models/tournament-viewmodel'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import QualifierTournamentDate from './QualifierTournamentDate'
import './QualifireTournamentModal.scss'

interface Props {
  onClose: CallableFunction
  qualifierTournaments: QualificationTournament[]
}

export interface QualificationTournament {
  name: string
  tournaments: TournamentViewModel_kl[]
}

const QualifierTournamentsModal = ({onClose, qualifierTournaments}: Props) => {

  const [selectedTournamentId, setSelectedTournamentId] = useState<number>(null)
  
  const history = useHistory()

  const handleViewSelectedTournament = () =>{
    if(selectedTournamentId){
      history.push(`/tournament-detail/${selectedTournamentId}`)
    } else{
      alert('Select Tournament Date For View.')
    }
  }

  return (    
    <Modal 
      hideOk 
      hideCancel 
      onClose={onClose} 
      buttonColor="blue" 
      className="modal-container__window window"
    >
      <Modal.Header>
        Qualifier
      </Modal.Header>     
      {qualifierTournaments && (
        <>
          {qualifierTournaments.length === 0 ?  (
            <Modal.SubHeader className="window__big-subheader">
              No qualifier tournament connected. 
            </Modal.SubHeader>  
          ) : (
            <>
              <Modal.SubHeader className="window__big-subheader">
                Click a tournament you want to view.
              </Modal.SubHeader>            
              <div className="row m-0--20 justify-content-start">
                {qualifierTournaments.map(item => {
                  return (
                    <>
                      <label
                        className="window__cancel-container _borderless"
                      >
                        <span className="window__cancel-time">{item.name}</span>
                      </label>
                      {item.tournaments.map(item => (
                        <QualifierTournamentDate 
                          key={item.Id}
                          onSelectTournament={setSelectedTournamentId}
                          selectedTournamentDetailId={selectedTournamentId}
                          startDate={item.StartDate}
                          tournamentDetailId={item.Id}
                        />
                      ))}                     
                    </>
                  )
                })
                }
              </div>
            </>
          )}
        </>
      )}     
      <Modal.ButtonsContainer>
        <Modal.Button 
          color="blue"
          onClick={() => { onClose() }}          
        >
          Cancel
        </Modal.Button>
        {qualifierTournaments && qualifierTournaments.length > 0 && (
          <Modal.Button
            onClick={handleViewSelectedTournament}
            color="blue"
            filled
          >
            View
          </Modal.Button>          
        )}
      </Modal.ButtonsContainer>
   
    </Modal>
    
  )
}

export default QualifierTournamentsModal
