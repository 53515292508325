import { useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import { makeGetIsBlogRequested } from 'store/selectors/blog-selectors'
import { blogAdded, blogByIdLoaded, blogDeleted, BlogSectionType, blogsLoaded, BlogsModel, BlogType, blogUpdated, unreadBlogsCountLoaded } from 'store/types/blog-types'
import { ImageType } from 'store/types/image-types'
import { fetchApi } from '../../server/server-utils'
import { GetState, PaginationModel } from '../types/common'
import { OperationTypes, run, useRun } from './operations-logic'

interface ListBlogsResult {
  AllBlogsCount: number
  Blogs: BlogType[]
  BlogSections: BlogSectionType[]
  Images: ImageType[]
  ReadBlogsIds: number[]
}

export interface BlogSectionModel {
  OrderNumber: number
  ImageId?: number
  Text?: string
}

export interface CreateBlogModel {
  title: string
  shortDescription: string
  previewImageId: number
  blogSections: BlogSectionModel[]
}

export const loadBlogs = (pagination: PaginationModel) => async (dispatch: Dispatch, getState: GetState) => {
  await run(OperationTypes.loadBlogs, null, dispatch, getState, async () => {
    try {
      const result = await fetchApi<ListBlogsResult>('blog', 'POST', { pagination })    
      dispatch(blogsLoaded(result))
    } catch (error) {
      return false
    }
    return true
  })
}

const getBlogRequested = makeGetIsBlogRequested()

export const loadBlog = (id: number) => async (dispatch: Dispatch, getState: GetState) => {
  if (getBlogRequested(getState(), { blogId: id })) {
    return
  }
  await run(OperationTypes.loadBlogById, id, dispatch, getState, async () => {
    try {
      const blog = await fetchApi<BlogsModel>(`blog/${id}`, 'GET')
      dispatch(blogByIdLoaded(blog))
    } catch (error) {
      return false
    }
    return true
  })
}

export const loadUnreadBlogsCount = () => async (dispatch: Dispatch, getState: GetState) => {
  await run(OperationTypes.loadUnreadBlogsCount, null, dispatch, getState, async() => {
    try {
      const count = await fetchApi<number>('blog/unreadcount', 'GET')
      dispatch(unreadBlogsCountLoaded(count))
    } catch (error) {
      return false
    }
    return true
  })
}

export const useAddBlog = () => {
  const dispatch = useDispatch()
  return useRun(OperationTypes.addBlog, null, async (model: CreateBlogModel) => {
    const blog = await fetchApi<BlogsModel>('blog/create', 'POST', model)
    dispatch(blogAdded(blog))
    return true
  })
}

export const useUpdateBlog = (blogId: number) => {
  const dispatch = useDispatch()
  return useRun(OperationTypes.updateBlog, blogId, async (id: number, model: CreateBlogModel) => {
    const blog = await fetchApi<BlogsModel>(`blog/${id}`, 'PUT', model)
    dispatch(blogUpdated(blog))
    return true
  })
}

export const useDeleteBlog = () => {
  const dispatch = useDispatch()
  return useRun(OperationTypes.deleteBlog, null, async (blogId: number) => {
    await fetchApi(`blog/${blogId}`, 'DELETE')
    dispatch(blogDeleted(blogId))

    return true
  })
}
