export interface PaymentMethodModel {
  id?: number
  userid: number
  cardNo: string
  expMonth: number
  expYear: number
  cvc: string
  fname: string
  lname: string
  email: string
  City: string
  country: string
  postal: string
  address: string
  cardType: string
  stripeToken?: string
  brand: string
  isPrimary?:boolean

}

export interface Kl_OrganizerPayout {
  Id: number | null
  OrganizerId: number
  PaymentMethodId: number
  CurrencyId: number
  TransitNumber: string
  InstitutionNumber: string
  AccountNumber: string
  Stripe_BaId?: string
  CoinAddress?: string
  CoinType: number | null
  RoutingNumber:string
  CurrencyCode:string |''
}

export interface kl_Currency {
  Id: number | null
  CurrencyCode: string
  CurrencySymbol: string
  CurrencyName: string
  ExchangeRate: number
}
export interface PricePlanModel {
  active: boolean
  amount: number
  amount_decimal: number
  billing_scheme: string
  created: number
  currency: string
  id: string
  interval: string
  interval_count: number
  livemode: boolean
  metadata: unknown
  nickname: string
  object: string
  product: string
  tiers?: string
  tiers_mode?: string
  transform_usage?: string
  trial_period_days?: string
  usage_type: string
}

export interface kl_PayoutPaymentMethod {
  Id: number | null
  PaymentMethod: string
}

export interface BrandImage {
  brand: string
  imgSrc: string
}
export interface kl_CurrencyModel {
  Id: number | null
  CurrencyCode: string
  CurrencySymbol: string
  CurrencyName: string
  ExchangeRate: number
  CountryName:string
}
export const payoutPaymentMethod: kl_PayoutPaymentMethod[] = [
  {
    Id: 1,
    PaymentMethod: 'Bank Deposit',
  },
  {
    Id: 2,
    PaymentMethod: 'Cryptocurrency',
  },
  // {
  //   Id: 1,
  //   PaymentMethod: 'Debit Card',
  // },
  // {
  //   Id: 2,
  //   PaymentMethod: 'Credit Card',
  // },
  // {
  //   Id: 3,
  //   PaymentMethod: 'Direct Debit',
  // },
  // {
  //   Id: 4,
  //   PaymentMethod: 'Cryptocurency',
  // },
]
export const currency: kl_Currency[] = [
  {
    Id: 1,
    CurrencyCode: 'Us',
    CurrencySymbol: '$',
    CurrencyName: 'Dollar',
    ExchangeRate: 100,
  },
]

export const brandImagesGrey: BrandImage[] = [
  {
    brand: 'visa',
    imgSrc: 'https://kombatlink-assets.azureedge.net/img/visa_grey.svg',
  },
  {
    brand: 'mastercard',
    imgSrc: 'https://kombatlink-assets.azureedge.net/img/mastercard_grey.svg',
  },
  {
    brand: 'directdebit',
    imgSrc: 'https://kombatlink-assets.azureedge.net/img/mastercard_grey.svg',
  },
  {
    brand: 'cryptocurency',
    imgSrc: 'https://kombatlink-assets.azureedge.net/img/crypto_grey.svg',
  },
]
export const brandImages: BrandImage[] = [
  {
    brand: 'visa',
    imgSrc: 'https://kombatlink-assets.azureedge.net/img/visa.svg',
  },
  {
    brand: 'mastercard',
    imgSrc: 'https://kombatlink-assets.azureedge.net/img/mastercard.svg',
  },
  {
    brand: 'directdebit',
    imgSrc: 'https://kombatlink-assets.azureedge.net/img/mastercard.svg',
  },
  {
    brand: 'cryptocurency',
    imgSrc: 'https://kombatlink-assets.azureedge.net/img/crypto.svg',
  },
]
