import { useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import { fetchApi } from 'server/server-utils'
import { makeGetIsMatchSettingsRequested } from 'store/selectors/match-settings-selectors'
import { AppThunkAction, GetState } from 'store/types/common'
import { matchesSettingsLoaded, MatchesSettingsModel, matchSettingsSet, MatchSettingsType } from 'store/types/match-settings-types'
import { cancel, OperationTypes, run, useRun } from './operations-logic'

const getIsMatchSettingsRequested = makeGetIsMatchSettingsRequested()

export const loadMatchSettings = async (matchId: number, dispatch: Dispatch, getState: GetState) => {
  if (getIsMatchSettingsRequested(getState(), matchId)) {
    return
  }
  await run(OperationTypes.loadMatchSettings, matchId, dispatch, getState, async () => {
    try {     
      const matchSettings = await fetchApi<MatchesSettingsModel>(`matchsettings/${matchId}/`, 'GET')
      dispatch(matchesSettingsLoaded(matchSettings))
    } catch (error) {
      return false
    }
    return true 
  })
}
export const reloadMatchSettings = async (matchId: number, dispatch: Dispatch, getState: GetState) => {
  cancel(OperationTypes.loadMatchSettings, matchId, dispatch, getState)
  loadMatchSettings(matchId, dispatch, getState)
}
export const useSetMatchSettings = (matchId: number) => {
  const dispatch = useDispatch()
  return useRun(OperationTypes.setMatchSettings, matchId, async (roomName: string, password: string) => {
    const matchSettings = await fetchApi<MatchSettingsType>('matchsettings', 'POST', {
      RoomName: roomName,
      Password: password,
      MatchId: matchId,
    })
    dispatch(matchSettingsSet(matchSettings))
   
    return true
  })
}


export const matchSettingsActionCreators = {
  loadMatchSettings: (matchId: number): AppThunkAction => (dispatch: Dispatch, getState: GetState) => {
    return loadMatchSettings(matchId, dispatch, getState)
  },
  reloadMatchSettings: (matchId: number): AppThunkAction => (dispatch: Dispatch, getState: GetState) => {
    return reloadMatchSettings(matchId, dispatch, getState)
  },
}
