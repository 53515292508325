import { Dispatch } from 'redux'
import { makeGetIsMatchParticipantsRequested } from 'store/selectors/match-participant-selectors'
import { matchParticipantsLoaded, MatchParticipantsModel } from 'store/types/match-participant-types'
import { fetchApi } from '../../server/server-utils'
import { AppThunkAction, GetState } from '../types/common'
import { cancel, OperationTypes, run } from './operations-logic'

const getIsMatchParticipantsRequested = makeGetIsMatchParticipantsRequested()

export const loadMatchParticipants = (matchId: number): AppThunkAction => async (dispatch: Dispatch, getState: GetState) => {
  if (getIsMatchParticipantsRequested(getState(), matchId)) {
    return
  }
  await run(OperationTypes.loadMatchParticipants, matchId, dispatch, getState, async () => {
    try {
      const result = await fetchApi<MatchParticipantsModel>(`match/${matchId}/match-participants`, 'GET')
      dispatch(matchParticipantsLoaded(result))
    } catch (error) {
      return false
    }
    return true
  })
}

export const reLoadMatchParticipants = (matchId: number): AppThunkAction => async (dispatch: Dispatch, getState: GetState) => {
  cancel(OperationTypes.loadMatchParticipants, matchId, dispatch, getState)
  await loadMatchParticipants(matchId)(dispatch, getState)
}

export const loadTournamentMatchesParticipants = (tournamentDetailId: number): AppThunkAction => async (dispatch: Dispatch, getState: GetState) => {
  await run(OperationTypes.loadTournamentMatchesParticipants, tournamentDetailId, dispatch, getState, async () => {
    try {
      const result = await fetchApi<MatchParticipantsModel>(`match/match-participants?tournamentDetailId=${tournamentDetailId}`, 'GET')
      dispatch(matchParticipantsLoaded(result))
    } catch (error) {
      return false
    }
    return true
  })
}
