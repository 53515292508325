import React from 'react'
import { cn } from 'utils'
import styles from '../modals.module.scss'

interface WindowInputProps {
  placeholder?: string
  className?: string
  value: string
  onChange: (value: string) => void
  disabled?: boolean
}

export const WindowInput = ({ placeholder, className, value, onChange, disabled }: WindowInputProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value)
  }

  return (
    <div className="row">
      <input
        className={cn(styles.window__input, disabled && styles.disabled, className)}
        type="text"
        placeholder={placeholder}
        onChange={handleChange}
        value={value}
        disabled={disabled}
      />
    </div>
  )
}
