import { ContainerName, useDialogWithParameter } from 'components/_hooks'
import { AlertModal, Modal } from 'components/_modals'
import { LoadingBox, SelfLoadedUserAvatar } from 'components/_shared'
import { ClanMemberRole, TournamentModeEnum } from 'consts'
import { FilterPlayersDto } from 'models/filterplayer-viewmodel'
import { TournamentViewModel_kl } from 'models/tournament-viewmodel'
import React, { useEffect, useState } from 'react'
import { DebounceInput } from 'react-debounce-input'
import { GeneralUtils } from 'server/utils/general-utils'
import { UserUtils } from 'server/utils/user-utils'
import { ClanMember } from 'store/types/clan-member-types'
import { ClanType } from 'store/types/clan-types'
import { ParticipantType } from 'store/types/participants-types'
import { PublicUserType } from 'store/types/user-types'
import { cn } from 'utils'
import { ClanAvatar } from './ClanAvatar'
import styles from './PrivateParticipantModal.module.scss'

interface Props {
  onClose: CallableFunction
  tournament: TournamentViewModel_kl | undefined
  joinUser: (v?: PublicUserType, p?: ClanType, close?: CallableFunction) => void
  participants: ParticipantType[]
}

const PrivateParticipantModal = ({ onClose, tournament, joinUser, participants }: Props) => {
  if (tournament?.TournamentModeId === TournamentModeEnum.Solo) {
    participants.map(x => { return x.Player?.Id })
  }

  const getExcludePlayerIds = () => {
    let ids: number[] = []
    if (participants) {
      if (tournament?.TournamentModeId === TournamentModeEnum.Solo) {
        ids = participants.map(x => { return x.Player?.Id as number })
      }
      else {
        ids = participants.map(x => { return x.Clan?.Id as number })
      }
    }
    return ids
  }

  const defaultFilters: FilterPlayersDto = Object()
  defaultFilters.name = ''
  defaultFilters.pagination = Object()
  defaultFilters.pagination.page = 0
  defaultFilters.pagination.pageSize = 10
  defaultFilters.ExcludePlayerIds = getExcludePlayerIds()

  const [playersFilters, setPlayersFilters] = useState<FilterPlayersDto>(defaultFilters)
  const [players, setPlayers] = useState<PublicUserType[]>([])
  const [clans, setClans] = useState<ClanType[]>([])
  const [clansMembers, setClansMembers] = useState<ClanMember[]>([])
  const [showLoadMore, setShowMore] = useState<boolean>(false)
  const [loading, setloading] = useState(false)

  const filterPlayers = async () => {
    setloading(true)
    const result = await UserUtils.FilterPlayers(playersFilters)
    if (!result.isError && result?.data) {
      const allPlayersCount = result?.data?.AllPlayersCount
      let users = result?.data?.Players?.Users ? result?.data?.Players?.Users : []
      setShowMore(false)
      if (playersFilters.pagination.page === 0) {
        setPlayers(users)
      }
      else {
        let morePlayers = [...players]
        users = morePlayers.concat(users)
        setPlayers(users)
      }
      if (users.length < allPlayersCount) {
        setShowMore(true)
      }
      setloading(false)
    } else {
      GeneralUtils.showError(result.message)
      setloading(false)
    }
  }

  const filterClans = async () => {
    setloading(false)
    const result = await UserUtils.FilterClans(playersFilters)
    if (!result.isError && result?.data) {
      const allPlayersCount = result?.data?.AllPlayersCount
      let users = result?.data?.Model?.Users ? result?.data?.Model?.Users : []
      let allClans = result?.data?.Model?.Clans ? result?.data?.Model?.Clans : []
      let allClansMembers = result?.data?.Model?.ClansMembers ? result?.data?.Model?.ClansMembers : []
      setShowMore(false)
      if (playersFilters.pagination.page === 0) {
        setPlayers(users)
        setClans(allClans)
        setClansMembers(allClansMembers)
      }
      else {
        let morePlayers = [...players]
        let moreClans = [...clans]
        let moreClanMembers = [...clansMembers]
        users = morePlayers.concat(users)
        allClans = moreClans.concat(allClans)
        allClansMembers = moreClanMembers.concat(allClansMembers)
        setPlayers(users)
        setClans(allClans)
        setClansMembers(allClansMembers)
      }

      if (allClans.length < allPlayersCount) {
        setShowMore(true)
      }
      setloading(false)
    } else {
      GeneralUtils.showError(result.message)
      setloading(false)
    }
  }

  const searchPlayers = (value: string) => {
    const filters = { ...playersFilters }
    filters.name = value
    filters.pagination.page = 0
    setPlayersFilters(filters)
  }

  const addParticipant = (user: PublicUserType) => {
    openConfirmAddParticipantModal({ user, undefined, onClose })
  }

  const addClan = (clan: ClanType) => {
    const leader = clansMembers.find(x => { return x.ClanId === clan.Id && x.Role === ClanMemberRole.Leader })
    const members = clansMembers.filter(x => { return x.ClanId === clan.Id })
    const numberOfMembers = members ? members.length : 0
    const isHaveEnoughMembers = numberOfMembers >= (tournament?.PlayersPerClan ? tournament?.PlayersPerClan : 0) + (tournament?.NumberOfSubstitutes ? tournament?.NumberOfSubstitutes : 0)
    const user = players.find(x => { return x.Id === leader?.UserId })
    if (isHaveEnoughMembers) {
      if (user) {
        openConfirmAddParticipantModal({ user, clan, onClose })
      }
    }
    else {
      openActionCompletedModal("This clan doesn't have enough members for signup")
    }
  }

  const loadMoreParticipants = () => {
    const filters = { ...playersFilters }
    filters.pagination.page = filters.pagination.page + 1
    setPlayersFilters(filters)
  }


  useEffect(() => {
    if (tournament) {
      if (tournament?.TournamentModeId === TournamentModeEnum.Solo) {
        filterPlayers()
      }
      else {
        filterClans()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playersFilters?.name, playersFilters?.pagination?.page])

  const [confirmAddParticipantModal, openConfirmAddParticipantModal] = useDialogWithParameter<any>((data, closeDialog) => (
    <Modal
      onClose={closeDialog}
      allowOk
      asyncOperation={async () => {
        await joinUser(data.user, data.clan, data.onClose)
        return true
      }}
      buttonColor="blue"
      className="window confirm-close"
      okButtonText="Confirm"
    >
      <Modal.Header>Are you sure you want to add this participant?</Modal.Header>
    </Modal>
  ))

  const [actionCompletedModal, openActionCompletedModal] = useDialogWithParameter<string>((text, closeDialog) => (
    <Modal
      onClose={closeDialog}
      hideOk={true}
      asyncOperation={async () => {
        return true
      }}
      buttonColor="blue"
      className="window private-modal-close"
    >
      <Modal.Header>{text}</Modal.Header>
    </Modal>
  ))

  return (
    <>
      {tournament &&
        <>
          <div className={cn(styles.user_container)}>
            <div className={cn('row', styles.model_wrapper)}>
              <div className='col-12 search'>
                <DebounceInput
                  className='search__input w-100'
                  placeholder="Search a participant"
                  minLength={3}
                  debounceTimeout={300}
                  onChange={(e) => { searchPlayers(e.target.value) }} />
                <a className='search__clear' href="#" />
              </div>
              <div className={cn(styles.user_container_wrapper)}>
                <div className={cn(styles.user_list_wrapper, 'col-12', 'mt-4')}>
                  <div className={cn(styles.user_list, 'col-12', 'mt-4')}>
                    {tournament && tournament.TournamentModeId === TournamentModeEnum.Solo && players && players.map((user, i) => (
                      <div key={'User_' + i} className={cn('row', 'col-4', styles.user_card)} onClick={() => { addParticipant(user) }}>
                        <div className={cn('col-4', styles.user_picture)}>
                          <SelfLoadedUserAvatar
                            userId={user.Id}
                            className={cn(styles.header__profilePicture, user.IsOnline)}
                            style={{ border: '0px' }}
                            placeholder="user"
                            containerName={ContainerName.AvatarsNormal}
                            isReload={true}
                          />
                        </div>
                        <div className={cn('col-8', styles.user_name)}>{user.Username}</div>
                      </div>
                    ))
                    }

                    {tournament && tournament.TournamentModeId === TournamentModeEnum.Clans && clans && clans.map((clan, i) => (
                      <div key={'User_' + i} className={cn('row', 'col-4', styles.user_card)} onClick={() => { addClan(clan) }}>
                        <div className={cn('col-4', styles.user_picture)}>
                          <ClanAvatar containerName={ContainerName.AvatarsNormal} imageId={clan.AvatarId} className={cn(styles.header__profilePicture)} isReload={true} />
                        </div>
                        <div className={cn('col-8', styles.user_name)}>{clan.Name}</div>
                      </div>
                    ))
                    }
                  </div>
                </div>
                {showLoadMore && (
                  <div className='col-12'>
                    <div className={cn(styles.popup_btn_wrapper)}>
                      <a className={cn(styles.load_more_btn)} onClick={() => { loadMoreParticipants() }}>
                        Load More
                  </a>
                    </div>
                  </div>
                )}

                {loading && (
                  <div className={cn(styles.loader_container)}>
                    <LoadingBox loading={true} size="average" loadingClassName={styles.loading} />
                  </div>
                )}
              </div>
            </div>
          </div>
          {confirmAddParticipantModal}
          {actionCompletedModal}
        </>
      }
    </>
  )
}

export default PrivateParticipantModal