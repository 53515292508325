import { Button, HorizontalScroll, SearchInput } from 'components/_shared'
import { isTwoPlayersStructure, TournamentStructure, TournamentStructureEnum } from 'consts'
import React, { useEffect, useMemo, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { useDispatch, useSelector } from 'react-redux'
import { makeGetTournament } from 'store/selectors/tournaments-selectors'
import { ApplicationState } from 'store/types/common'
import FullScreenButton from './_shared/Buttons/FullScreenButton'
import RepositionButton from './_shared/Buttons/RepositionButton'
import { BracketContainer } from './_shared'
import './elimination-brackets.scss'
import './elimination.scss'
import { cn, TournamentBuilder } from 'utils'
import { setTournament, TournamentType } from 'store/types/tournaments-types'
import { setTournamentMatches } from 'store/types/match-types'
import { roundsLoaded } from 'store/types/rounds-types'
import getId from 'utils/getId'
import { placeCleared } from 'store/types/pages/tournament-bracket-types'
import { loadBracket } from './_shared/utils/loadBracket'
import TournamentChatButton from 'components/TournamentDetail/_shared/TournamentChatButton/TournamentChatButton'
import { useDialogWithParameter } from 'components/_hooks'
import { SubmissionResultModal } from './_shared/SumbissionResultModal/SumbissionResultModal'

type TournamentBracketType = 'store' | 'mock'

interface BaseTournamentBracketProps {
  isSearchEnabled: boolean
  fullSreenDisabled: boolean
  type: TournamentBracketType
}

interface StoreTournamentBracketProps extends BaseTournamentBracketProps {
  type: 'store'
  tournamentDetailId: number
}

interface MockTournamentBracketProps extends BaseTournamentBracketProps {
  type: 'mock'
  participantsPerMatch: number
  maxNumberOfParticipants: number
  structure: TournamentStructureEnum
}

const labelColorByStructure = {
  [TournamentStructureEnum.SingleElimination]: 'eli-header_type--green',
  [TournamentStructureEnum.DoubleElimination]: 'eli-header_type--blue',
  [TournamentStructureEnum.EliminationRoundFfa]: 'eli-header_type--pink',
  [TournamentStructureEnum.SingleRoundFfa]: 'eli-header_type--orange',
}

export type TournamentBracketProps = MockTournamentBracketProps | StoreTournamentBracketProps

export const TournamentBracket = ({ fullSreenDisabled, isSearchEnabled, ...props }: TournamentBracketProps) => {
  const dispatch = useDispatch()
  const getTournament = useMemo(makeGetTournament, [])

  const tournamentDetailId = useMemo(() => {
    return props.type === 'store' ? props.tournamentDetailId : -getId()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.type])

  const tournament = useSelector((state: ApplicationState) => getTournament(state, tournamentDetailId))

  const [isFullScreen, setIsFullScreen] = useState(fullSreenDisabled)
  const [filter, setFilter] = useState('')
  const [scrollPosition, setScrollPosition] = useState(0)
  const [scrollLBPosition, setScrollLBPosition] = useState(0)

  const handleRepositionClick = () => {
    setScrollPosition(0)
    setScrollLBPosition(0)
  }

  const handleFullScreenClick = () => {
    setIsFullScreen(true)
  }

  const handleBackClick = () => {
    if (fullSreenDisabled) {
      window.history.back()
    } else {
      setIsFullScreen(false)
    }
  }  

  useEffect(() => {
    if (props.type === 'store') {
      loadBracket(tournamentDetailId, dispatch)
    }
    else {
      const participientsPerMatch = isTwoPlayersStructure(props.structure)
        ? 2
        : props.structure === TournamentStructureEnum.SingleRoundFfa
          ? props.maxNumberOfParticipants
          : props.participantsPerMatch

      const builder = new TournamentBuilder(tournamentDetailId, props.structure, props.maxNumberOfParticipants, participientsPerMatch)
      const bracket = builder.build()
      dispatch(setTournament({
        TournamentStructureId: props.structure,
        TournamentDetailId: tournamentDetailId,
        MaxNumberOfParticipients: props.maxNumberOfParticipants,
        ParticipientsPerMatch: participientsPerMatch,
      } as TournamentType))
      dispatch(setTournamentMatches({ Matches: bracket.matches }))
      dispatch(roundsLoaded({ Rounds: bracket.rounds }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, props.type, tournamentDetailId])

  useEffect(() => {
    dispatch(placeCleared())
  }, [dispatch])


  const [submissionResultModal, openSubmissionResult] = useDialogWithParameter<any>((submission, closeDialog) => (
    <SubmissionResultModal
      closeDialog={closeDialog}
      matchParticipant={submission}
    />
  ))

  const openSubmissionResultModal = (match:any) =>{
    openSubmissionResult(match)
  }

  if (!tournament) {
    return <></>
  }

  return (
    <div className="eliminationBracket">      
      <div className={isFullScreen ? 'grid-full-screen' : 'padding-top'}>
        <div className="eli-page">
          <div className="eli-container content-container">
            <div className="eli_fscreen-hdr eli_general-padding">
              <div className="eli-header">
                <div className="eli-header_l_i">
                  <h1 className="eli-header_ttl">{tournament.Name} (T#{tournament.Id})</h1>
                </div>
                <div className="eli-header_r ">
                  <div className="eli-header_r_i">
                    <span className={cn('eli-header_type', labelColorByStructure[tournament.TournamentStructureId])}><span>{TournamentStructure[tournament.TournamentStructureId]}</span></span>
                  </div>
                </div>
              </div>
              <div className="eli_fscreen-hdr_r ml-auto mr-2">
                <TournamentChatButton tournamentDetailId={tournament.TournamentDetailId} color="green" />              
              </div>
              <div className="eli_fscreen-hdr_r">
                <Button onClick={handleBackClick} className="backButton" color="blue" filled>
                  {fullSreenDisabled ? 'Back' : 'Exit Full Screen'}
                </Button>
              </div>
            </div>
            <div className="eli_ttl">
              <div className="eli_ttl_l">
                {tournament?.TournamentStructureId === TournamentStructureEnum.DoubleElimination && (
                  <>
                    <div>
                      <h2 className="eli_ttl_ttl">Winners’ Bracket</h2>
                    </div>
                    <div>
                      <div className="white-tooltip-theme">
                        <button
                          className="btn-more-info btn-tooltip"
                          data-tip="If you lose in this bracket you will get<br>a second chance to play in the Loser’s Bracket"
                          data-for="WinnerBracketTip"
                          aria-expanded="false"
                        >
                          <span className="btn-more-info_icon"></span>
                        </button>
                        <ReactTooltip effect="solid" type="light" place="top" multiline id="WinnerBracketTip" />
                      </div>
                    </div>
                  </>
                )}
              </div>
              {tournament && (
                <div className="eli_ttl_r eli-header-top">
                  {isSearchEnabled &&
                    <div className="eli_ttl_r_i">
                      <SearchInput value={filter} onChange={setFilter} inputClassName="eli_search_input" placeholder="Search by name" />
                    </div>
                  }
                  <div className="eli_ttl_r_i white-tooltip-theme">
                    {!isFullScreen &&(
                      <TournamentChatButton tournamentDetailId={tournament.TournamentDetailId} color="green" />              
                    )}
                  </div>
                  <div className="eli_ttl_r_i white-tooltip-theme">
                    <RepositionButton onClick={handleRepositionClick} />
                  </div>
                  <div className="eli_ttl_r_i white-tooltip-theme">
                    <FullScreenButton onClick={handleFullScreenClick} />
                  </div>
                </div>
              )}
            </div>
            <div className="eli-content next-arrow">
              <HorizontalScroll leftPosition={scrollPosition} onChangeLeftPosition={setScrollPosition}>
                <BracketContainer tournamentDetailId={tournamentDetailId} filter={filter} openSubmissionResultModal= {openSubmissionResultModal} />
              </HorizontalScroll>
            </div>
            {tournament?.TournamentStructureId === TournamentStructureEnum.DoubleElimination && (
              <div className="eli_ttl eli_general-padding">
                <div className="eli_ttl_l">
                  <div>
                    <h2 className="eli_ttl_ttl">Losers&apos; Bracket</h2>
                  </div>
                  <div>
                    <div className="white-tooltip-theme">
                      <button
                        className="btn-more-info btn-tooltip"
                        data-tip="If you lose in any of these matches,<br>you will be eliminated from the tournament"
                        data-for="LoserBracketTip"
                        aria-expanded="false"
                      >
                        <span className="btn-more-info_icon"></span>
                      </button>
                      <ReactTooltip effect="solid" type="light" place="top" multiline id="LoserBracketTip" />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="eli-content prev-arrow">
              {tournament?.TournamentStructureId === TournamentStructureEnum.DoubleElimination && (
                <HorizontalScroll leftPosition={scrollLBPosition} onChangeLeftPosition={setScrollLBPosition}>
                  <BracketContainer tournamentDetailId={tournamentDetailId} isLosersBracket filter={filter} openSubmissionResultModal= {openSubmissionResultModal} />
                </HorizontalScroll>
              )}
            </div>
          
            {submissionResultModal}
          
          </div>
        </div>
      </div>
    </div>
  )
}
