
import React, { useCallback, useEffect, useState } from 'react'


import { ContainerName, useAppUser } from 'components/_hooks'
import { ArticleHeader, LoadingBox, Pagination } from 'components/_shared'

import { useDispatch, useSelector } from 'react-redux'
import { loadBlogs } from 'store/logic/blogs-logic'
import { getAllBlogsCount, getBlogsIds, getBlogsLoading, getReadBlogsIds } from 'store/selectors/blog-selectors'

import styles from './Home.module.scss'

import {HomeBlogsRow} from './HomeBlogsRow'
import { Link } from 'react-router-dom'


const pageSize = 3
function HomeBlog() {
  const dispatch = useDispatch()
  const [page, setPage] = useState(0)
  const appUser = useAppUser()

  const blogsIds = useSelector(getBlogsIds)
  const readBlogsIds = useSelector(getReadBlogsIds)
  const allBlogsCount = useSelector(getAllBlogsCount)
  const blogsLoading = useSelector(getBlogsLoading)

  const load = useCallback((page: number) => {
    dispatch(loadBlogs({
      page,
      pageSize,
    }))
  }, [dispatch])

  useEffect(() => {
    load(page)
  }, [load, page])
  
  const cdn = 'https://kombatlink-assets.azureedge.net/img'
  return (
    <div className={styles.content_wrapper + " m-0"}>
      <div className={"row row-xl-3 row-l-3 row-m-1 " + styles.flexm}>
        <div className="col-xl-1 col-l-1 col-m-12" data-aos="fade-right"></div>
        <div className={"col-xl-10 col-l-10 col-m-12 align-center" + " " + styles.loopWrapper} data-aos="zoom-in">
          <h2 className={styles.colorBlack +" " + styles.tt_u + " " + styles.stayLoop}>STAY IN THE LOOP</h2>
          <p className="text-center">
            Keep up to date with the latest esport news,
            find out who is moving up the ranks and where it is taking place.
            {/* Keep up to date with everything and anything to do with esports.
            See who is moving up in the ranks and where it is taking place. */}
          </p>
        </div>
        <div className="col-xl-1 col-l-1 col-m-12" data-aos="fade-right"></div>
      </div>
      {allBlogsCount > pageSize && <div className={"row text-right row-xl-3 row-l-3 row-m-2 row-s-2 m-0 " + styles.flexs + " " + styles.equal_height + " " + styles.gap_top +" "+styles.blogSeeMorePadding}>
         
          <Link to="/news" className="text-right">See More..</Link>
              
      </div>}
      
      <div className={"row row-xl-3 row-l-3 row-m-2 row-s-2 m-0 " + styles.blogflexs + " " + styles.equal_height + " " + styles.gap_top}>
          
          <LoadingBox size="big" loading={blogsLoading} centered className="w-100">
            { blogsIds.map(id =>
              <HomeBlogsRow containerName={ContainerName.Blogs} key={id} blogId={id} isRead={!appUser || readBlogsIds.includes(id)}/>
            )}
          </LoadingBox>
      </div>
    </div>
  )
}

export default HomeBlog