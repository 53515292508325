import { Modal } from 'components/_modals'
import React from 'react'
import { ScreenshotType } from 'store/types/match-submissions-types'
import ImgPreview from './ImgPreview'

interface OwnProps {
  screenshot: ScreenshotType
  onRemove: (screenshot: ScreenshotType) => void
}

const Screenshot = ({screenshot, onRemove} : OwnProps) => {
  return (  
    <Modal.FileLabel onClick={() => onRemove(screenshot)}>
      <ImgPreview imageId={screenshot.imageId} />
    </Modal.FileLabel>
  )
}

export default Screenshot
