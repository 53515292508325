import React from 'react'
import { cn } from 'utils'
import styles from './Structures.module.scss'

export const DoubleEliminationStructure = () => {
  return (
    <span className={cn('article__label-structure', styles.structure)}>
      <span className="row m-0 align-items-center">
        <span className="col-auto p-0">
          <span className="row m-0 align-items-center m-b-13">
            <span className="col-auto p-0">
              <span className={styles.text}>Winner&apos;s Bracket</span>
            </span>
            <span className="col-auto p-0">
              <span className={cn(styles.container, styles.width_1)}>
                <span className="row">
                  <span className="col-12">
                    <span className="row m-0 justify-content-between">
                      <span className={cn(styles.player, styles.playerBlue)}></span>
                      <span className={cn(styles.director, styles.directorBlue)}></span>
                    </span>
                  </span>
                  <span className="col-12">
                    <span className="row m-0 justify-content-between">
                      <span className={styles.player}></span>
                      <span className={cn(styles.director, styles.directorGreen)}></span>
                    </span>
                  </span>
                </span>
              </span>
              <span className={cn(styles.container, styles.width_1)}>
                <span className="row">
                  <span className="col-12">
                    <span className="row m-0 justify-content-between">
                      <span className={styles.player}></span>
                      <span className={cn(styles.director, styles.directorBlue)}></span>
                    </span>
                  </span>
                  <span className="col-12">
                    <span className="row m-0 justify-content-between">
                      <span className={cn(styles.player, styles.playerPurple)}></span>
                      <span className={cn(styles.director, styles.directorPurple)}></span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
            <span className="col-auto p-0">
              <svg className={styles.arrow_1}>
                <path d="M0 0.5H10V15M10 15H20M10 15V27.5H0" strokeOpacity="0.5"></path>
              </svg>
            </span>
            <span className="col-auto p-0">
              <span className={cn(styles.container, styles.width_1)}>
                <span className="row">
                  <span className="col-12">
                    <span className="row m-0 justify-content-between">
                      <span className={cn(styles.player, styles.playerBlue)}></span>
                      <span className={cn(styles.director, styles.directorBlue)}></span>
                    </span>
                  </span>
                  <span className="col-12">
                    <span className="row m-0 justify-content-between">
                      <span className={styles.player}></span>
                      <span className={cn(styles.director, styles.directorPurple)}></span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
          <span className="row m-0 align-items-center">
            <span className="col-auto p-0">
              <span className={styles.text}>Loser&apos;s Bracket</span>
            </span>
            <span className="col-auto p-0">
              <span className={cn(styles.container, styles.width_1)}>
                <span className="row">
                  <span className="col-12">
                    <span className="row m-0 justify-content-between">
                      <span className={styles.player}></span>
                      <span className={cn(styles.director, styles.directorPurple)}></span>
                    </span>
                  </span>
                </span>
              </span>
              <span className={cn(styles.container, styles.width_1)}>
                <span className="row">
                  <span className="col-12">
                    <span className="row m-0 justify-content-between">
                      <span className={cn(styles.player, styles.playerGreen)}></span>
                      <span className={cn(styles.director, styles.directorGreen)}></span>
                    </span>
                  </span>
                  <span className="col-12">
                    <span className="row m-0 justify-content-between">
                      <span className={styles.player}></span>
                      <span className={cn(styles.director, styles.directorBlue)}></span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
            <span className="col-auto p-0">
              <svg className={styles.arrow_1}>
                <path d="M0 0.5H10V15M10 15H20M10 15V27.5H0" strokeOpacity="0.5"></path>
              </svg>
            </span>
            <span className="col-auto p-0">
              <span className={cn(styles.container, styles.width_1)}>
                <span className="row">
                  <span className="col-12">
                    <span className="row m-0 justify-content-between">
                      <span className={styles.player}></span>
                      <span className={cn(styles.director, styles.directorPurple)}></span>
                    </span>
                  </span>
                  <span className="col-12">
                    <span className="row m-0 justify-content-between">
                      <span className={cn(styles.player, styles.playerGreen)}></span>
                      <span className={cn(styles.director, styles.directorGreen)}></span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
        <span className="col-auto p-0">
          <svg className={cn(styles.arrow_3, 'm-t-5')}>
            <path d="M0 0.5H10V31.5M10 31.5H20M10 31.5V60.5H0" strokeOpacity="0.5"></path>
          </svg>
        </span>
      </span>
      <span className="col-auto p-0 m-t-5">
        <span className={cn(styles.container, styles.width_1)}>
          <span className="row">
            <span className="col-12">
              <span className="row m-0 justify-content-between">
                <span className={cn(styles.player, styles.playerBlue)}></span>
                <span className={cn(styles.director, styles.directorBlue)}></span>
              </span>
            </span>
            <span className="col-12">
              <span className="row m-0 justify-content-between">
                <span className={styles.player}></span>
                <span className={cn(styles.director, styles.directorGreen)}></span>
              </span>
            </span>
          </span>
        </span>
      </span>
    </span>
  )
}
