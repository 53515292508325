import React, { CSSProperties, ReactNode } from 'react'

export interface EmptyComponentProps {
  children: ReactNode
  style?: CSSProperties
}

export const EmptyComponent = ({ children }: EmptyComponentProps) => {
  return <>{children}</>
}
