import { UserModel } from '../../models/user-models'

class StorageUtils {
  private static appPrefix = ''

  public static setItem(key: string, value: string, days = 365): void {
    const date: Date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    const expires: string = '; expires=' + date.toUTCString()

    document.cookie = this.appPrefix + key + '=' + encodeURIComponent(value) + expires + '; path=/'
  }

  public static getItem(key: string): string {
    const nameEQ: string = this.appPrefix + key + '='
    const ca: string[] = document.cookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c: string = ca[i]
      while (c.charAt(0) == ' ') c = c.substring(1, c.length)
      if (c.indexOf(nameEQ) == 0) return decodeURIComponent(c.substring(nameEQ.length, c.length))
    }
    return null
  }

  public static getUser() {
    const user = localStorage.getItem('user')
    if (user) {
      return JSON.parse(user) as UserModel
    }
  }
  public static getCartTournamentDetailId() {
    const cartTournamentDetailId = localStorage.getItem('carttournamentdetailid')
    return cartTournamentDetailId
  }
  public static setCartTournamentDetailId(id) {
    localStorage.setItem('carttournamentdetailid',id)
    return id
  }
}

export default StorageUtils
