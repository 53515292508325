import { useMemo } from 'react'

export type Dictionary = { [key: string] : string }

export const useUnion = <TUnion>(unionValues: Dictionary, value?: TUnion, defaultValue?: TUnion) => {
  const tab = useMemo<TUnion>(() => {
    if (value && Object.prototype.hasOwnProperty.call(unionValues, value)) {
      return value
    }
    return defaultValue
  }, [defaultValue, unionValues, value])

  return tab
}
