import React, { useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { TournamentModeEnum, TournamentModeName } from 'consts/TournamentModeId'
import { useDispatch, useSelector } from 'react-redux'
import { makeGetIsMatchParticipantsLoading } from 'store/selectors/match-participant-selectors'
import { ApplicationState } from 'store/types/common'
import { MatchModel } from 'store/types/match-types'
import { defaultFormatDate, defaultFormatTime } from 'utils'
import { MatchParticipantStatus } from 'consts/MatchParticipantStatus'
import {
  TableButton,
  ButtonsColumn,
  DateColumn,
  DeadlineColumn,
  EventNameColumn,
  RoundColumn,
  TypeColumn,
  BattleRow,
} from '../_shared/BattleRow/BattleRow'
import { DropDownTable, LoadingBox } from 'components/_shared'
import { useMemberClan, useOpponents, useScrollToElement } from 'components/_hooks'
import { makeGetMatch } from 'store/selectors/match-selectors'
import { makeGetRound } from 'store/selectors/rounds-selectors'
import { makeGetTournament } from 'store/selectors/tournaments-selectors'
import { getRoundTitle } from 'store/types/rounds-types'
import { loadMatchParticipants } from 'store/logic/match-participant-logic'
import { TournamentParticipantRow } from '../_shared/TournamentParticipantRow'
import { getAppUser } from 'store/selectors/app-selectors'

interface OwnProps {
  matchId: number
  openCheckInModal: (match: MatchModel) => void
  highlightedRoundId?: number
  removeHighlightedRoundId: () => void
}

type Props = OwnProps

const CheckInBattleRow = ({
  matchId,
  openCheckInModal,
  highlightedRoundId,
  removeHighlightedRoundId,
}: Props) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const getMatch = useMemo(makeGetMatch, [])
  const getRound = useMemo(makeGetRound, [])
  const getTournament = useMemo(makeGetTournament, [])
  const getIsMatchParticipantsLoading = useMemo(makeGetIsMatchParticipantsLoading, [])
  const user = useSelector(getAppUser)
  const {isClanLeader} = useMemberClan(user?.Id)

  const match = useSelector((state: ApplicationState) => getMatch(state, matchId))
  const round = useSelector((state: ApplicationState) => getRound(state, match.RoundId))
  const tournament = useSelector((state: ApplicationState) => getTournament(state, round.TournamentDetailId))
  const matchParticipantsLoading = useSelector((state: ApplicationState) => getIsMatchParticipantsLoading(state, matchId))

  const { me, opponents } = useOpponents(matchId)
  const highlighted = highlightedRoundId === round.Id
  
  const isCheckedIn = me?.ParticipantStatus === MatchParticipantStatus.CheckedIn
  const ref = useScrollToElement(highlighted, matchParticipantsLoading ? 'auto' : 'smooth')

  const handleCheckIn = () => {
    if (isCheckedIn || matchParticipantsLoading) {
      return
    }
    openCheckInModal(match)
  }

  useEffect(() => {
    dispatch(loadMatchParticipants(matchId))
  }, [dispatch, matchId])

  const isCheckInButtonVisible = useMemo(() => tournament?.TournamentModeId == TournamentModeEnum.Solo
    || isClanLeader
    || isCheckedIn
  , [isCheckedIn, tournament?.TournamentModeId, isClanLeader])

  const warningText = React.useMemo(() => {
    if (tournament?.TournamentModeId == TournamentModeEnum.Clans && !isClanLeader) {
      return 'Your captain needs to check-in before the battle starts'
    }

    return 'You need to check-in before the battle starts'
  }, [isClanLeader, tournament?.TournamentModeId])

  return (
    <>
      <div ref={ref} className="row table__row" onMouseEnter={highlighted ? removeHighlightedRoundId : undefined}>
        <div className="col-12">
          <input className="table__checkbox" type="checkbox" id={`open-table-${matchId}`} />
          <BattleRow highlighted={highlighted}>
            <EventNameColumn>
              <DropDownTable.Link to={`/tournament-detail/${round.TournamentDetailId}`}>{tournament.Name}</DropDownTable.Link>
            </EventNameColumn>
            <TypeColumn>{TournamentModeName[tournament.TournamentModeId]}</TypeColumn>
            <RoundColumn>{getRoundTitle(round, tournament.TournamentStructureId)}</RoundColumn>
            <DateColumn>{defaultFormatDate(round.PlayDateTime)}</DateColumn>
            <DeadlineColumn>
              {defaultFormatTime(round.PlayDateTime)}
              {!matchParticipantsLoading && !isCheckedIn && (
                <div className="table__warning">
                  <div className="table__warning-content">
                    <div className="table__warning-text">{warningText}</div>
                  </div>
                </div>
              )}
            </DeadlineColumn>
            <ButtonsColumn>
              <div className="row justify-content-sm-end flex-sm-row flex-column m-0">
                {isCheckInButtonVisible && (
                  <TableButton color="green" filled disabled={isCheckedIn} onClick={handleCheckIn} loading={matchParticipantsLoading}>
                    check in
                  </TableButton>
                )}
                <TableButton
                  color="grey"
                  filled
                  onClick={() => {
                    history.push(`/tournament-bracket/${round.TournamentDetailId}`)
                  }}
                >
                  play schedule
                </TableButton>
                <label className="table__open" htmlFor={`open-table-${matchId}`} />
              </div>
            </ButtonsColumn>
          </BattleRow>
          <div className="row table__secondary-row-container">
            <div className="container-fluid">
              <LoadingBox loading={matchParticipantsLoading} size="average">
                {opponents.map(x => (
                  <TournamentParticipantRow key={x.TournamentParticipantId} participantId={x.TournamentParticipantId} />
                ))}
              </LoadingBox>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CheckInBattleRow
