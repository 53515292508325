import { ClanMemberRole } from 'consts'
import { Reducer } from 'redux'
import { APP_USER_LOADED } from 'store/types/app-types'
import { ClanMember, ClansMemberState, CLANS_MEMBERS_LOADED, CLAN_MEMBER_REMOVED, KnownAction } from 'store/types/clan-member-types'
import { CLAN_DELETED } from 'store/types/clan-types'
import { MATCH_PARTICIPANTS_LOADED } from 'store/types/match-participant-types'
import { MEMBERS_PAGE_LOADED } from 'store/types/pages/clan-page-types'
import { MEMBERS_PAGE_CLANS_LOADED, MEMBERS_PAGE_PLAYERS_LOADED } from 'store/types/pages/members-page-types'
import { PARTICIPANTS_LOADED } from 'store/types/participants-types'
import { arrayToObject, getNewObjectWithArray, mapArrayToObject } from 'utils'

const calcState = (state: ClansMemberState, clansMembers: ClanMember[]): ClansMemberState => {
  return {
    byUserId: {
      ...state.byUserId,
      ...arrayToObject(clansMembers, x => x.UserId),
    },
    byClanId: {
      ...state.byClanId,
      ...getNewObjectWithArray(state.byClanId, clansMembers, x => x.ClanId, x => x.UserId),
    },
    leaderIdByClanId: {
      ...state.leaderIdByClanId,
      ...mapArrayToObject(clansMembers.filter(x => x.Role === ClanMemberRole.Leader), x => x.ClanId, x => x.UserId),
    },
  }
}

const initialClansMemberState: ClansMemberState = {
  byUserId: {},
  byClanId: {},
  leaderIdByClanId: {},
}

const reducer: Reducer<ClansMemberState, KnownAction> = (state: ClansMemberState | undefined = initialClansMemberState, action: KnownAction) => {
  switch (action.type) {
    case APP_USER_LOADED:
    case CLANS_MEMBERS_LOADED:    
    case PARTICIPANTS_LOADED:
    case MATCH_PARTICIPANTS_LOADED: {
      return calcState(state, action.payload.ClansMembers)
    }
    case MEMBERS_PAGE_CLANS_LOADED:
    case MEMBERS_PAGE_PLAYERS_LOADED:
    case MEMBERS_PAGE_LOADED:
      return calcState(state, action.payload.Model.ClansMembers)
    case CLAN_MEMBER_REMOVED: {
      return {
        ...state,
        byUserId: {
          ...state.byUserId,
          [action.payload.userId]: undefined,
        },
        byClanId: {
          ...state.byClanId,
          [action.payload.clanId]: state.byClanId[action.payload.clanId].filter(id => id !== action.payload.userId),
        },
      }
    }
    case CLAN_DELETED: {
      return {
        ...state,
        byClanId: {
          ...state.byClanId,
          [action.payload.clanId]: [],
        },
      }
    }
    default:
      return state
  }
}

export default reducer
