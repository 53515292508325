import { Dispatch } from 'redux'
import { fetchApi } from 'server/server-utils'
import { getRegionsRequested } from 'store/selectors/region-selectors'
import { GetState } from 'store/types/common'
import { regionsLoaded, RegionType } from 'store/types/region-types'
import { OperationTypes, run } from './operations-logic'

export const loadRegions = async (dispatch: Dispatch, getState: GetState) => {
  if (getRegionsRequested(getState())) {
    return
  }
  await run(OperationTypes.loadRegions, null, dispatch, getState, async () => {
    try {
      const regions = await fetchApi<RegionType[]>('region', 'GET')
      dispatch(regionsLoaded({ regions }))
    } catch (error) {
      return false
    }
    return true
  })
}
