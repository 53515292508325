'use strict'
import { createSelector } from 'reselect'
import { ApplicationState } from '../types/common'
import { OperationTypes } from '../logic/operations-logic'
import { makeGetIsAnyOperationWithTypeRunning, makeGetIsOperationWithTypeSuccessOrRunning } from './operations-selectors'

const getApp = (state: ApplicationState) => state.app

export const GetTimeZones = createSelector(getApp, state => {
  return state?.timeZones ?? []
})

export const getIsAppLoading = makeGetIsAnyOperationWithTypeRunning(() => ({
  type: OperationTypes.loadApp,
}))


export const getIsAppRequested = makeGetIsOperationWithTypeSuccessOrRunning(() => ({
  type: OperationTypes.loadApp,
  objectId: null,
}))

export const getIsAppUserLoading = makeGetIsAnyOperationWithTypeRunning(() => ({
  type: OperationTypes.loadAppUser,
}))

export const getIsAppUserRequested = makeGetIsOperationWithTypeSuccessOrRunning(() => ({
  type: OperationTypes.loadAppUser,
  objectId: null,
}))

export const getAppUser = createSelector(getApp, app => app?.user)

export const getAppUserRole = createSelector(getAppUser, user => user?.Role)

export const getLastNotificationId = createSelector(getApp, app => app?.lastNotificationId)

export const getAppOffline = createSelector(getApp, app => app?.offline ?? false)

export const getFeatures = createSelector(getApp, app => app?.features)
