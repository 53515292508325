import * as React from 'react'

interface Props {
  hover?: boolean
  fieldId: string
  indexInsideCollection?: number
  data: any[]
  textField: string
  valueField: string
  selectedValue?: any
  selectedValues?: any[]
  placeholder?: string
  onSelectionChange?: (fieldId: string, value: any, index?: number) => void
  onSelectionsChange?: (values: any[]) => void
  searchable?: boolean
  multiSelect?: boolean
  zIndex: number
  class1?: string
  class2?: string
  class3?: string
  class4?: string
  isDisable? : string
  isImage?: boolean
}

interface State {
  searchText: string
  isOpened: boolean
}

class SelectComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { searchText: '', isOpened: false }
  }

  filterList(searchText: string) {
    this.setState({
      searchText,
    })
  }

  render() {
    let class1 = this.props.class1 ? this.props.class1 : 'article-select__select article-select__select--type select'
    const class2 = this.props.class2 ? this.props.class2 : 'article-select__select--type__title select__title white'
    const class3 = this.props.class3 ? this.props.class3 : 'article-select__select--type__container select__container'
    const class4 = this.props.class4 ? this.props.class4 : 'article-select__select--type__option select__option'
    const data: any[] = this.state.searchText
      ? this.props.data.filter(x => x[this.props.textField].toString().toLowerCase().indexOf(this.state.searchText.toLowerCase()) !== -1)
      : this.props.data
    let selectedText = this.props.placeholder

    const addHoverStyles = () => {
      if (!this.props.multiSelect && this.props.hover) {
        const currentState = this.state.isOpened
        this.setState({ isOpened: !currentState })
      }
    }

    if (this.props.data &&  this.props.multiSelect && this.props.selectedValues && this.props.selectedValues.length) {
      selectedText = ''
      let selectedItem: any = null

      for (let i = 0; i < this.props.selectedValues.length; i++) {
        selectedItem = this.props.data.find(x => x[this.props.valueField] == this.props.selectedValues[i])

        if (selectedItem && selectedItem[this.props.textField]) {
          selectedText += (selectedText ? ', ' : '') + selectedItem[this.props.textField]
        }
      }
    } else if (!this.props.multiSelect && this.props.data) {
      const selectedItem: any = this.props.data.find(x => x[this.props.valueField] == this.props.selectedValue)

      if (selectedItem && selectedItem[this.props.textField]) {
        selectedText = selectedItem[this.props.textField]
      }
    }

    return (
      <div
        onClick={e => {
          if (!this.props.multiSelect)
            e.currentTarget.className = `${class1} ${
              e.currentTarget.className.indexOf('select--opened') > -1 ? (this.props.multiSelect ? 'select--opened' : '') : 'select--opened'
            } `
        }}

        onMouseEnter={addHoverStyles}

        onMouseLeave={()=> {
          if (!this.props.multiSelect && this.props.hover) {
            this.setState({ isOpened: false })
          }
        }}

        className={`${class1 + ' ' + this.props.isDisable} ${this.state.isOpened ? 'select--opened' : ''} ${this.state.isOpened && this.props.multiSelect ? 'select--opened' : ''}`}
        style={{ zIndex: this.props.zIndex ? this.props.zIndex : 'initial' }}
      >
        <div
          onClick={e => {
            if (this.props.multiSelect)
              e.currentTarget.parentElement.className = `${class1} ${
                e.currentTarget.parentElement.className.indexOf('select--opened') > -1 ? '' : 'select--opened'
              } `
          }}
          className={class2}
          style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
        >
          {selectedText}
        </div>
        <section className={class3}>
          {this.props.searchable && (
            <section className="select__search-container">
              <input
                className="select__search text-light"
                placeholder="Search"
                value={this.state.searchText}
                onChange={e => {
                  this.filterList(e.target.value)
                }}
                onClick={e => {
                  class1 = 'article-select__select article-select__select--type article__input article__width-2 article__height-1 select ct__select select--opened'
                }}
              />
            </section>
          )}
          {data &&
            data.length > 0 &&
            data.map((item, i) => {
              if (this.props.multiSelect) {
                const valueIndex = this.props.selectedValues && this.props.selectedValues.findIndex(x => x == item[this.props.valueField])
                return (
                  <div
                    key={i}
                    className={`${class4}
                                    ${valueIndex !== -1 ? 'select__option--selected' : ''}`}
                    onClick={() => {
                      const valuesArray: any[] = Object.assign(this.props.selectedValues)
                      if (valueIndex == -1) {
                        valuesArray.push(item[this.props.valueField])
                      } else {
                        valuesArray.splice(valueIndex, 1)
                      }
                      this.props.onSelectionsChange(valuesArray)
                    }}
                  >
                    {item[this.props.textField]}
                  </div>
                )
              } else {
                return (
                  <div
                    key={i}
                    className={`${class4}
                                    ${this.props.selectedValue == item[this.props.valueField] ? 'select__option--selected' : ''}`}
                    onClick={() => {
                      this.props.onSelectionChange(this.props.fieldId, item[this.props.valueField], this.props.indexInsideCollection)
                    }}
                  >
                    {item[this.props.textField]}
                  </div>
                )
              }
            })}
        </section>
      </div>
    )
  }
}

export default SelectComponent
