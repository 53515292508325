import { TournamentStructureEnum } from 'consts/TournamentStructure'
import { makeActionCreator, ReduxAction } from './common'
import { DisputesLoadedAction } from './dispute-types'
import { ApprovedMatchLinksLoadedAction, PendingMatchLinksLoadedAction } from './match-link-types'
import { MatchesSettingsLoadedAction } from './match-settings-types'
import { getBattlesPageByRoundStatus, RoundType } from './rounds-types'
import { TournamentType } from './tournaments-types'

export enum MatchStatusEnum {
  Pending = 0,
  Dispute = 1,
  Finished = 2,
}

export interface MatchModel {
  Id: number
  TournamentRoundId: number
  RoundId: number
  MatchNumber: number
  Status: MatchStatusEnum
  Link: string

  TournamentStructureId: TournamentStructureEnum
  PlayDate: string
  RoundNumber: number
  TournamentDetailId: number
  IsLosersBracket: boolean
}

export interface MatchesModel {
  Matches: MatchModel[]
  Rounds: RoundType[]
  Tournaments: TournamentType[]
}

export function getRoundTitle(match: MatchModel): string {
  if (match.TournamentStructureId == TournamentStructureEnum.DoubleElimination) {
    return `${match.IsLosersBracket ? 'LB' : 'WB'} ${match.RoundNumber}`
  }
  return match.RoundNumber.toString()
}

export interface TournamentMatchesTree {
  Match: MatchModel
  Matches: TournamentMatchesTree[]
}

export interface State {
  byId: {
    [id: number]: MatchModel
  }
  idsByTournamentDetailId: {
    [tournamentDetailId: number]: number[]
  }
  idsByRoundId: {
    [roundId: number]: number[]
  }
  pendingMatchesIds: number[]
  checkInMatchesIds: number[]
  activeMatchesIds: number[]
  finishedMatchesIds: number[]
}

export const PENDING_MATCHES_LOADED = 'PENDING_MATCHES_LOADED'
export type PendingMatchesLoadedAction = ReduxAction<typeof PENDING_MATCHES_LOADED, MatchesModel>
export const pendingMatchesLoaded = makeActionCreator<MatchesModel>(PENDING_MATCHES_LOADED)

export const CHECKIN_MATCHES_LOADED = 'CHECKIN_MATCHES_LOADED'
export type CheckinMatchesLoadedAction = ReduxAction<typeof CHECKIN_MATCHES_LOADED, MatchesModel>
export const checkinMatchesLoaded = makeActionCreator<MatchesModel>(CHECKIN_MATCHES_LOADED)

export const ACTIVE_MATCHES_LOADED = 'ACTIVE_MATCHES_LOADED'
export type ActiveMatchesLoadedAction = ReduxAction<typeof ACTIVE_MATCHES_LOADED, MatchesModel>
export const activeMatchesLoaded = makeActionCreator<MatchesModel>(ACTIVE_MATCHES_LOADED)

export interface SetTournamentMatchesPayload {
  Matches: MatchModel[]
}
export const SET_TOURNAMENT_MATCHES = 'SET_TOURNAMENT_MATCHES'
export type SetTournamentMatchesAction = ReduxAction<typeof SET_TOURNAMENT_MATCHES, SetTournamentMatchesPayload>
export const setTournamentMatches = makeActionCreator<SetTournamentMatchesPayload>(SET_TOURNAMENT_MATCHES)

export const FINISHED_MATCHES_LOADED = 'FINISHED_MATCHES_LOADED'
export type FinishedMatchesLoadedAction = ReduxAction<typeof FINISHED_MATCHES_LOADED, MatchesModel>
export const finishedMatchesLoaded = makeActionCreator<MatchesModel>(FINISHED_MATCHES_LOADED)

export const CLEAR_MATCHES = 'CLEAR_MATCHES'
interface ClearMatchesPayload {
  matchesIds: number[]
  roundsIds: number[]
  tournamentsIds: number[]
}
export type ClearMatchesAction = ReduxAction<typeof CLEAR_MATCHES, ClearMatchesPayload>
export const clearMatches = makeActionCreator<ClearMatchesPayload>(CLEAR_MATCHES)

export type KnownAction = SetTournamentMatchesAction 
| PendingMatchLinksLoadedAction 
| ApprovedMatchLinksLoadedAction
| PendingMatchesLoadedAction
| FinishedMatchesLoadedAction
| CheckinMatchesLoadedAction
| ActiveMatchesLoadedAction
| ClearMatchesAction
| DisputesLoadedAction
| MatchesSettingsLoadedAction

export const getBattlesPageByMatchStatus = (match: MatchModel, round: RoundType) => {
  switch (match.Status) {
    case MatchStatusEnum.Pending:
      return getBattlesPageByRoundStatus(round)
    case MatchStatusEnum.Dispute:
      return `/battles/submission/${round.Id}`
    case MatchStatusEnum.Finished:
      return `/battles/finished/${round.Id}`
  }
}
