import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PendingBattleRow } from './PendingBattleRow'
import { useDialogWithParameter } from 'components/_hooks/useComponents/useDialog'
import {
  BattleHeaderRow,
  ButtonsColumn,
  ClassColumn,
  EventNameColumn,
  RoundColumn,
  StartDateColumn,
  TypeColumn,
} from '../_shared/BattleRow/BattleRow'
import { LoadingBox } from 'components/_shared'
import { CancelParticipationModal } from 'components/_modals'
import { getPendingRoundsIds } from 'store/selectors/rounds-selectors'
import { getIsPendingMatchesLoading, getPendingMatchIdByRoundId } from 'store/selectors/match-selectors'
import { useHighlight } from 'components/_hooks'
import { loadPendingMatches } from 'store/logic/match-logic'
import { TournamentType } from 'store/types/tournaments-types'

export interface PendingBattlesProps {
  roundId?: number
}

export const PendingBattles = ({ roundId }: PendingBattlesProps) => {
  const dispatch = useDispatch()
  const pendingRoundsIds = useSelector(getPendingRoundsIds)
  const pendingMatchIdByRoundId = useSelector(getPendingMatchIdByRoundId)
  const isPendingMatchesLoading = useSelector(getIsPendingMatchesLoading)
  const [highlightedRoundId, removeHighlightedRoundId] = useHighlight(roundId)

  useEffect(() => {
    dispatch(loadPendingMatches)
  }, [dispatch])

  const [cancelParticipationModal, openCancelParticipationModal] = useDialogWithParameter<TournamentType>((tournament, closeDialog) => (
    <CancelParticipationModal
      onClose={closeDialog}
      EventTypeId={tournament.EventTypeId}
      tournamentDetailId={tournament.TournamentDetailId}
    />
  ))

  return (
    <>
      <BattleHeaderRow>
        <EventNameColumn>Name of the event</EventNameColumn>
        <ClassColumn>Class</ClassColumn>
        <TypeColumn>Type</TypeColumn>
        <RoundColumn>Round</RoundColumn>
        <StartDateColumn>Start Date</StartDateColumn>
        <ButtonsColumn />
      </BattleHeaderRow>
      <LoadingBox loading={isPendingMatchesLoading} size="big">
        {pendingRoundsIds.map(roundId => (
          <PendingBattleRow
            key={roundId}
            roundId={roundId}
            matchId={pendingMatchIdByRoundId[roundId]}
            openCancelParticipationModal={openCancelParticipationModal}
            highlightedRoundId={highlightedRoundId}
            removeHighlightedRoundId={removeHighlightedRoundId}
          />
        ))}
      </LoadingBox>
      {cancelParticipationModal}
    </>
  )
}
