import * as React from 'react'
import { OrganizerTransactionViewModel, OrganizerTournamentTransactionsSalesDetailsModel } from 'models/organizer-transaction-viewmodel'
import { TransactionUtils } from '../../server/utils/transaction-utils'
import styles from './Transactions.module.scss'
import { defaultFormatDate, defaultFormatTime, round2Places } from 'utils'
import { TournamentCartRefundStatus } from 'consts/TournamentCartRefundStatus'
interface Props {
  transaction: OrganizerTransactionViewModel
  id: number
  CurrencySymbol:string
  CurrencyCode : string
  currentElement:string
}

interface State {
  showhid: boolean
  sale: number
  fees: number
  balance: number
  salesDetailsData: OrganizerTournamentTransactionsSalesDetailsModel[],
  headertitle:string
  
}

class TransactionsCard extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      showhid: false,
      sale: 0,
      fees: 0,
      balance: 0,
      salesDetailsData: [],
      headertitle:''
    }

    this.ticketCalculation = this.ticketCalculation.bind(this)
    this.salesDetails = this.salesDetails.bind(this)
  }
  componentDidMount() {
    this.ticketCalculation()
  }
  ticketCalculation() {
    const { transaction } = this.props
    const sale: number = transaction && transaction.TicketPrice * transaction.EventTickets
    const fees: number = transaction.IsPremium
      ? ((transaction.TicketPrice * transaction.EventTickets) / 100) * 5
      : ((transaction.TicketPrice * transaction.EventTickets) / 100) * 10
    const balance: number = sale - fees
    this.setState({ sale, fees, balance })
  }
  async salesDetails(id, isparticipant) {
    const result = await TransactionUtils.TournamentTransactionSalesDetails(id, isparticipant)
    if (!result.isError) {
      this.setState(
        {
          salesDetailsData: result.data,
          headertitle:isparticipant?"Sales Detail for Participants":"Sales Detail for Spectators"
        },
        () => {
          document.getElementById(this.props.currentElement).className = 'modal-container modal-container--opened'
          
        }
      )
    }
  }
  removeElementsByClass(className:string){
    var elements = document.getElementsByClassName(className)
    elements[0].innerHTML=''
  }
  render() {
    const { transaction, id,CurrencySymbol,CurrencyCode,currentElement} = this.props
    const { showhid, salesDetailsData } = this.state

    return (
      <>
        <input className="table__checkbox-hide" checked={false} onChange={()=>{}} type="checkbox" id="hide-table-1" />
        <div className="col-12 p-0">
          <div className={`${styles.table__row} p-0`}>
            <div className="col-12 p-0">
              <input className={`table__checkbox ${styles.table_checkbox}`} onChange={()=>{}} checked={showhid} type="checkbox" id="open-table-1" />
              <div className={`${styles.table__main_row} table_main_row`}>
                <div className={`${styles.table__column} ${styles.table__column__id}`}>{transaction.EventId}</div>
                
                <div className={`${styles.table__column} ${styles.table__column__name}`}>{transaction.TournamentName}</div>
                <div className={`${styles.table__column} ${styles.table__column__sales}`}>{transaction.EventTickets}</div>
                 
                <div className={`${styles.table__column} ${styles.table__column__venue}`}>{transaction.VenueType}</div>
                <div className={`${styles.table__column} ${styles.table__column__date}`}>
                  {transaction.StartDate && defaultFormatDate(transaction.StartDate)}
                </div>
                
                {/* <div className={`${styles.table__column} ${styles.table__column__status}`}>
                  {this.state.balance > 10 && <div className="table__label table__label--green">active</div>}
                  {this.state.balance < 10 && <div className="table__label table__label--red">canceled</div>}
                </div> */}
                <div className={`col-12 col-lg-6 col-xl-1 p-0 d-flex ${styles.table__column__buttons}`}>
                  <label className="ml-0 mr-1 mb-1 mt-0 table__hide" />
                  <label
                    className={`ml-1 mr-1 mb-1 mt-0 table__open ${styles.table_open}`}
                    onClick={() => {
                      this.setState({ showhid: !this.state.showhid })
                    }}
                  />
                </div>
              </div>
              <div className={`table__secondary-row-container ${styles.table__secondary_row_container}`}>
                <div className="container-fluid">
                  {transaction.ViewerParticipants &&
                  transaction.ViewerParticipants.map((viewerPart, i) => {
                    return (
                      <div key={'VPT'+ i.toString()}>
                        {/* <div className="m-2 table__secondary-row d-block  d-md-inline-flex d-lg-inline-flex d-xl-inline-flex col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0"> */}
                        <div className={`row ${styles.table__secondary_row}`}>
                          <div className={`${styles.table__column} ${styles.table__column__id}`} />
                          <div className={`${styles.table__column} ${styles.table__column__type}`}>Participants</div>
                          {/* <div className={`${styles.table__column} ${styles.table__column__price}`}>FREE</div> */}
                          <div className={`${styles.table__column} ${styles.table__column__sales}`}>{viewerPart.ParticipantTickets} / { viewerPart.MaxNumberOfParticipants }</div>
                          {/* <div className={`${styles.table__column} ${styles.table__column__refunded}`}>-</div> */}
                          <div className={`col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 d-flex ${styles.table__column__button}`}>
                            <button
                              style={{ width: 'auto' }}
                              className={`btn-sm table__button table__details-button blue-button ${styles.table_button} ${styles.table__details_button}`}
                              onClick={() => {
                                this.setState({
                                  salesDetailsData:[],
                                  headertitle:''
                                },async ()=>{
                                  await this.salesDetails(`${id}`, true)
                                })
                                

                              }}
                            >
                              SALES DETAILS
                            </button>
                          </div>
                        </div>
                        <div className={`row ${styles.table__secondary_row}`}>
                          <div className={`${styles.table__column} ${styles.table__column__id}`} />
                          <div className={`${styles.table__column} ${styles.table__column__type}`}>Spectator</div>
                          {/* <div className={`${styles.table__column} ${styles.table__column__price}`}>{transaction.CurrencySymbol}{transaction.TicketPrice == null ? 0 : transaction.TicketPrice}</div> */}
                          <div className={`${styles.table__column} ${styles.table__column__sales}`}>{viewerPart.ViewerTickets == null ? 0 : viewerPart.ViewerTickets} / {viewerPart.VenueSeatingCapacity}</div>
                          {/* <div className={`${styles.table__column} ${styles.table__column__refunded}`}>-</div> */}
                          <div className={`col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 d-flex ${styles.table__column__button}`}>
                            <button
                              style={{ width: 'auto' }}
                              className={`btn-sm table__button table__details-button blue-button ${styles.table_button} ${styles.table__details_button}`}
                              onClick={() => {
                                this.setState({
                                  salesDetailsData:[],
                                  headertitle:''
                                },async ()=>{
                                  await this.salesDetails(`${id}`, false)
                                })
                              }}
                            >
                              SALES DETAILS
                            </button>
                          </div>
                        </div>
                        </div>
                    )
                  })}
                </div>
              </div>
            </div>
            <aside className="modal-container" id={currentElement}>
              <div className="modal-container__background" />
              <div className="modal-container__window window m-5 p-5">
                <div
                  className="window__close"
                  onClick={() => {
                    this.setState({
                      salesDetailsData:[],
                      headertitle:''
                    },()=>{
                      document.getElementsByClassName(`${'headerTitle '+currentElement}`)[0].innerHTML=''
                      this.removeElementsByClass(this.props.currentElement)
                      document.getElementById(this.props.currentElement).className = 'modal-container'
                      
                    })
                   
                  }}
                />
                <div className="container-fluid py-5">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div className="row text-center">
                      <span className={`window__header header-sales-title ${'headerTitle '+currentElement}`}>{this.state.headertitle}</span>
                    </div>
                    {/* <div className="row">
                  <h3 className="window__subheader" style={{ color: '#49c3fd' }}>
                    Clash Royale Tournament
                  </h3>
                </div> */}
                    <div className="row window__headers-row flex-nowrap">
                      <div className="window__column window__column--name ml-2">Purchaser name</div>
                      <div className="window__column window__column--date ml-2">Date</div>
                      <div className="window__column window__column--day ml-2">Day</div>
                      <div className="window__column window__column--time ml-2">Time</div>
                      
                      <div className="window__column window__column--id ml-2">Ticket #</div>
                      <div className="window__column window__column--section ml-2">Section</div>
                      <div className="window__column window__column--row ml-2">Row #</div>
                      <div className="window__column window__column--seat ml-2">Seat #</div>
                      <div className="window__column window__column--ticketprice ml-2">Price</div>
                      <div className="window__column window__column--quantity ml-2">Quantity</div>
                      <div className="window__column window__column--status ml-2">Status</div>
                      <div className="window__column window__column--refunded ml-2">Refunded</div>
                    </div>
                    <div className={currentElement}>
                    {salesDetailsData &&
                    salesDetailsData.length !== 0 &&
                    salesDetailsData.map((data, i) => {
                      return (
                        <div key={'TC' + i.toString()} className={`row window__row flex-md-nowrap flex-wrap flex-md-row flex-column`}>
                          <div className="window__column window__column--name ml-2">{data.Purchasername}</div>
                          <div className="window__column window__column--date ml-2">{data.PaymentDate? defaultFormatDate(data.PaymentDate) :''}</div>
                          <div className="window__column window__column--day ml-2">{data.DayNumber}</div>
                          <div className="window__column window__column--time ml-2">{data.PaymentTime?defaultFormatTime(data.PaymentTime):''}</div>
                          <div className="window__column window__column--id ml-2">{'#' + data.TicketId}</div>
                          <div className="window__column window__column--section ml-2">{data.SectionName}</div>
                          <div className="window__column window__column--row ml-2">{data.RowNumber}</div>
                          <div className="window__column window__column--seat ml-2">{data.SeatNumber}</div>
                          <div className="window__column window__column--ticketprice ml-2">{data.SectionPrice!=="Free"?(data.CurrencySymbol+" "+round2Places(Number(data.SectionPrice),2)+" "+data.CurrencyCode):"Free"}</div>
                          <div className="window__column window__column--quantity ml-2">{data.Quantity}</div>
                          <div className="window__column window__column--status ml-2">{data.Status}</div>
                          <div className="window__column window__column--refunded ml-2">
                          {(data.Refunded !== null ? data.Refunded === TournamentCartRefundStatus.Pending ? 'Pending' : (data.Refunded === TournamentCartRefundStatus.Refunded ? 'Refunded' : '-') : '-')}
                          </div>
                         
                        </div>
                      )
                    })}
                    </div>
                    {salesDetailsData && salesDetailsData.length === 0 && (
                      <div className="row window__row flex-md-nowrap flex-wrap flex-md-row flex-column">
                        <div className="window__column window__column--status window__column--purchased justify-data-center">No records found</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </>
    )
  }
}

export default TransactionsCard
