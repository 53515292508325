import { Reducer } from 'redux'
import { USER_UPDATED, USER_AVATAR_UPDATED, COVER_IMAGE_UPDATED } from 'store/types/user-types'
import { State, KnownAction, APP_LOADED, APP_USER_LOADED } from '../types/app-types'

const appInitialState: State = {
  lastNotificationId: undefined,
  timeZones: undefined,
  user: undefined,
  offline: false,
  features: {
    LeadGeneration: false,
  },
}

const reducer: Reducer<State, KnownAction> = (state: State | undefined = appInitialState, action: KnownAction): State => {
  switch (action.type) {
    case APP_LOADED:
      return {
        ...state,
        timeZones: action.payload.TimeZones,
        offline: action.payload.Offline,
        features: action.payload.Features,
      }
    case COVER_IMAGE_UPDATED:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload.Users[0]
        },
      }
    case APP_USER_LOADED:
      return {
        ...state,
        user: action.payload.Users[0],
        lastNotificationId: action.payload.LastNotificationId,
      }
    case USER_UPDATED:
      return {
        ...state,
        user: action.payload.user,
      }
    case USER_AVATAR_UPDATED:
      return {
        ...state,
        user: action.payload.Users[0],
      }
    default:
      return state
  }
}

export default reducer
