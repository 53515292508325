import { Reducer } from 'redux'
import { calcById, mapArrayToObject } from 'utils'
import { CLAN_INVITATIONS_LOADED, KnownAction, ClanInvitationState } from '../types/clan-invitations-types'

const initialClanInvitationsState: ClanInvitationState = {
  byId: {},
  idByClanId: {},
  ids: [],
}

const reducer: Reducer<ClanInvitationState, KnownAction> = (state: ClanInvitationState | undefined = initialClanInvitationsState, action: KnownAction) => {
  switch (action.type) {
    case CLAN_INVITATIONS_LOADED: {  
      return {
        ...state,
        ids: action.payload.ClansInvitations.map(x => x.Id),
        byId: calcById(state, action.payload.ClansInvitations),
        idByClanId: {
          ...mapArrayToObject(
            action.payload.ClansInvitations,
            x => x.ClanId,
            x => x.Id
          ),
        },
      }
    }
    default:
      return state
  }
}

export default reducer
