import * as React from 'react'
import styles from './TournamentRules.module.scss'

interface Props {
  rules: string
}

const TournamentRules = ({rules}: Props) => {


  return (
    <>
      <div>
        <div className={styles.pageHead}>
          <h1>Rules</h1>
        </div>
      </div>
      {rules && (
        <div className={styles.pageContent}>
          <div className="row m-0 tournament-column">
            <div className="article-detail__container article-detail__container--details">
              <div className="row m-0">
                <div className="article__detail-text" dangerouslySetInnerHTML={{ __html: rules }} /> 
              </div>
            </div>
          </div>
        </div>
      )}    
    </>
  )
}

export default TournamentRules
