import AllTransactions from 'components/Transactions/AllTransactions'
import AvailableTransactions from 'components/Transactions/AvailableTransactions'
import Battles from 'components/_pages/player/Battles/Battles'
import BillingOptionPlayer from 'components/BillingOptionPlayer/BillingOptionPlayer'
import BitcoinBase from 'components/BitcoinBase/BitcoinBase'
import OrganizerDisputes from 'components/OrganizerDisputes/OrganizerDisputes'
import EventsOrganizer from 'components/_pages/organizer/EventsOrganizer/EventsOrganizer'
import GameTournaments from 'components/_pages/common/GameTournaments/GameTournaments'
import Home from 'components/_pages/common/Home/Home'
import HomeNew from 'components/_pages/common/Home/HomeNew'
import OrganizerProfile from 'components/OrganizerProfile/OrganizerProfile'
import { PlayerPage } from 'components/PlayerPage/PlayerPage'
import FriendsPage from 'components/FriendsPage/FriendsPage'
import { ClanPage } from './ClanPage/ClanPage'
import MyTickets from 'components/MyTickets/MyTickets'
import { NewsList } from './NewsList/NewsList'
import { News } from './News/News'
import Payment from 'components/Stripe/Payment'
import PlayerTransactions from 'components/Transactions/PlayerTransactions/PlayerTransactions'
import UserReview from '../../UserReview/UserReview'
import PublicProfileView from 'components/OrganizerProfile/PublicProfileView'
import QrScanner from 'components/QRScanner/QRScannerNew'
import ShoppingCard from 'components/Shopping/ShoppingCardPage'
import TournamentBracketPage from 'components/TournamentBracketPage/TournamentBracketPage'
import TournamentTicket from 'components/TournamentTickets/TournamentTicket'
import UpgradeAccount from 'components/UpgradeAccount/UpgradeAccount'
import VictoryBracket from 'components/VictoryRoad/TournamentBracket/VictoryBracket'
import VictoryRoadList from 'components/VictoryRoad/List/VictoryRoadList'
import VictoryRoadMap from 'components/VictoryRoad/VictoryRoadMap'
import VictoryRoadMyEvents from 'components/VictoryRoad/MyEvents/VictoryRoadMyEvents'
import WithdrawTransactions from 'components/Transactions/WithdrawTransactions/WithdrawTransactions'
import { Events } from './Events/Events'
import { CreateTournament } from './CreateTournament/CreateTournament'
import { TournamentDetailsPage } from './TournamentDetailsPage/TournamentDetailsPage'
import { Tournaments } from './Tournaments/Tournaments'
import { Offline } from './Offline/Offline'
import { Disclaimer } from './Disclaimer/Disclaimer'
import { Rules } from './Rules/Rules'
import { MembersPage } from './MembersPage/MembersPage'
import { Games } from './Games/Games'
import TransactionWithdrawList from 'components/Transactions/WithdrawTransactions/TransactionWithdrawList'

export const CommonPages = {
  AllTransactions,
  AvailableTransactions,
  Battles,
  BillingOptionPlayer,
  BitcoinBase,
  OrganizerDisputes,
  EventsOrganizer,
  GameTournaments,
  Games,
  Home,
  HomeNew,
  OrganizerProfile,
  PlayerPage,
  FriendsPage,
  MyTickets,
  NewsList,
  News,
  Payment,
  PlayerTransactions,
  UserReview,
  PublicProfileView,
  QrScanner,
  ShoppingCard,
  TournamentBracketPage,
  TournamentTicket,
  Tournaments,
  UpgradeAccount,
  VictoryBracket,
  VictoryRoadList,
  VictoryRoadMap,
  VictoryRoadMyEvents,
  WithdrawTransactions,
  Events,
  CreateTournament,
  TournamentDetailsPage,
  Offline,
  Disclaimer,
  Rules,
  ClanPage,
  MembersPage,
  TransactionWithdrawList
}
