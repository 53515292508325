import { Modal } from 'components/_modals'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useResolveDispute } from 'store/logic/dispute-logic'
import { reLoadMatchParticipants } from 'store/logic/match-participant-logic'

import { matchesSumbissionsActionCreators } from 'store/logic/match-submissions-logic'
import { DisputeModel } from 'store/types/dispute-types'
import { cn } from 'utils'
import styles from './ResolveDisputeModal.module.scss'

export interface ResolveDisputeModalProps {
  closeDialog: CallableFunction
  dispute: DisputeModel
}

export const ResolveDisputeModal = ({ closeDialog, dispute }: ResolveDisputeModalProps) => {
  const resolveDispute = useResolveDispute(dispute.Id)
  const dispatch = useDispatch()

  const asyncOperation = async () => {
    try {
      await resolveDispute()
    } finally {
      dispatch(matchesSumbissionsActionCreators.loadMatchSubmissions(dispute.MatchId))
    }
    dispatch(reLoadMatchParticipants(dispute.MatchId))
    return true
  }

  return (
    <Modal
      onClose={closeDialog}
      asyncOperation={asyncOperation}
      buttonColor="blue"
      okButtonText="Resolve dispute"
      className={cn(styles.resolveDisputeModal, styles.window)}
      allowOk
    >
      <Modal.Header className={cn(styles.resolveDisputeModal, styles.window__header, styles.window__header__center)}>
        Do you want to resolve this dispute?
      </Modal.Header>
    </Modal>
  )
}
