import React, { useState } from 'react'
import { cn } from 'utils'
import styles from './FilePreview.module.scss'
import { Area, AspectRatioType } from 'components/_shared'
import { useCropper } from 'components/_hooks/useComponents/useCropper'
import { useAlertModal } from 'components/_hooks'
import { MediaType } from 'store/types/image-types'
import { Video } from 'components/_shared/Img'

export interface ImagePreviewProps {
  src?: string
  previewClassName?: string
  onReset: () => void
  disabled?: boolean
  withCrop?: boolean
  onCrop?: (file: File) => void
  aspectRatioType?: AspectRatioType
  fileMediaType?: MediaType
  round?: boolean
}

export const FilePreview = ({ src, previewClassName, onReset, disabled, withCrop, onCrop, aspectRatioType, fileMediaType, round }:  ImagePreviewProps) => {
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>()
  const [errorModal, openErrorModal] = useAlertModal()

  const handleChangeCroppedAreaPixels = (pixels: Area) => {
    setCroppedAreaPixels(pixels)
  }

  const [getCroppedImg, cropper, setZoom] = useCropper({
    aspectRatioType,
    onChangeCroppedAreaPixels: handleChangeCroppedAreaPixels,
    src: src || '',
    containerClassName: styles.cropContainer,
    zoomClassName: styles.previewZoom,
    round,
  })

  const handleApply = async () => {
    try {
      const croppedImage = await getCroppedImg(
        src || '',
        croppedAreaPixels
      )

      if (croppedImage.image && onCrop) {
        croppedImage.canvas.toBlob((blob: Blob) => {
          const file = new File([blob], croppedImage.image.localName)
          onCrop(file)
          setZoom(1)
        })
      }

    } catch (e) {
      openErrorModal('An error has occurred!')
      console.error(e)
    }
  }

  return (
    <>
      <section className={styles.preview}>
        <div className="container-fluid">
          <div className="row">
            {fileMediaType === MediaType.Video 
              ? (
                <div className={cn(styles.previewContainer, previewClassName)}>
                  <Video style={{ height: '100%' }} controls muted src={src}/>
                </div>
              ) : ( 
                <>
                  {!withCrop &&
                    <div className={cn(styles.previewContainer, previewClassName)}>
                      <img className={styles.previewImage} src={src} alt="preview image"/>
                    </div>
                  }
                  {withCrop && cropper}
                </>
              )
            }
          </div>
          <div className="row justify-content-end">
            {withCrop && fileMediaType === MediaType.Image && <button disabled={disabled} className={styles.previewReset} type="button" onClick={handleApply}>Apply</button> }
            <button disabled={disabled} className={styles.previewReset} type="button" onClick={onReset}>Reset</button>
          </div>
        </div>
      </section>
      {errorModal}
    </>
  )
}
