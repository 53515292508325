import { TextField } from 'components/_shared/TextField/TextField'
import React from 'react'
import { ClanType } from 'store/types/clan-types'

export interface ReadonlyClanFieldsProps {
  clan?: ClanType
  isClanLeader: boolean
}

export const ReadonlyClanFields = ({ clan, isClanLeader }:  ReadonlyClanFieldsProps) => {
  const listed = clan?.WantToBeFound
    ? 'Yes'
    : 'No'

  return (
    <>
      <TextField label="Name" value={clan?.Name} />
      <TextField label="About clan" value={clan?.AboutUs} />
      {isClanLeader && <TextField label="Listed in clan directory" value={listed} />}
      <TextField label="Members" value={clan?.MembersCount.toString()} />
    </>
  )
}
