import React from 'react'
import { cn } from 'utils'
import styles from '../modals.module.scss'

interface ModalCloseProps {
  onClick: () => void
  disabled?: boolean
}

export const ModalClose = ({ onClick, disabled }: ModalCloseProps) => {
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault()
    if (!disabled && onClick) {
      onClick()
    }
  }

  return <a href="#" onClick={handleClick} className={cn(styles.window__close, disabled && 'disabled','model-popup-close')} />
}
