import { useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import { makeGetIsLoadClanMemberRequested } from 'store/selectors/clan-member-selectors'
import { clanMemberRemoved, clansMembersLoaded, ClansMembersModel } from 'store/types/clan-member-types'
import { GetState } from 'store/types/common'
import { fetchApi } from 'utils'
import { OperationTypes, run, useRun } from './operations-logic'

const getIsLoadUserRequested = makeGetIsLoadClanMemberRequested()

export const loadClanMember = (userId: number) => async (dispatch: Dispatch, getState: GetState) => {
  if (getIsLoadUserRequested(getState(), { userId })) {
    return
  }
  await run(OperationTypes.loadClanMember, userId, dispatch, getState, async () => {
    try {
      const model = await fetchApi<ClansMembersModel>(`clanmember/${userId}`, 'GET')
      dispatch(clansMembersLoaded(model))
    } catch (error) {
      return false
    }
    return true
  })
}

export const useLeaveClan = () => {
  const dispatch = useDispatch()
  return useRun(OperationTypes.leaveClan, null, async (clanId: number, userId: number) => {
    await fetchApi('clanmember/leave', 'DELETE')
    dispatch(clanMemberRemoved({ clanId, userId }))
    return true
  })
}
