import React, { useMemo } from 'react'
import { cn, div } from 'utils'
import stylesLight from './PaginationLight.module.scss'
import stylesDark from './PaginationDark.module.scss'
import { useThemeStyles } from 'components/_hooks'

export interface PaginationProps {
  page: number
  itemsPerPage: number
  allItemsCount: number
  onChangePage: (page: number) => void
  disabled?: boolean
}

const windowSize = 5
const windowHalfSize = div(windowSize, 2)

export const Pagination = ({ allItemsCount, page, itemsPerPage, onChangePage, disabled }: PaginationProps) => {
  const styles = useThemeStyles(stylesDark, stylesLight)

  const pagesCount = useMemo(() => {
    if (itemsPerPage == 0) {
      return 0
    }
    return Math.ceil(allItemsCount / itemsPerPage)
  }, [allItemsCount, itemsPerPage])

  const pageNumbers = useMemo(() => {
    return Array.from(Array(pagesCount).keys())
  }, [pagesCount])

  const firstPageNumber = 0
  const lastPageNumber = pagesCount - 1

  const window = useMemo(() => {
    const exceptFirstLast = pageNumbers.slice(1, -1)
    if (exceptFirstLast.length <= windowSize) {
      return exceptFirstLast
    }
    if (div(page, windowHalfSize) < windowHalfSize) {
      return exceptFirstLast.slice(firstPageNumber, windowSize)
    }
    if (div(pagesCount -  page, windowHalfSize) < windowHalfSize) {
      return exceptFirstLast.slice(-windowSize)
    }
    
    return pageNumbers.slice(page - windowHalfSize, page + windowHalfSize + 1)
  }, [page, pageNumbers, pagesCount])

  const displayeLeftDots = window[0] ? window[0] - firstPageNumber > 1 : false
  const displayeRightDots = window[window.length - 1] ? lastPageNumber - window[window.length - 1] > 1 : false

  const handlePrevClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault()
    if (disabled) {
      return
    }
    onChangePage(page - 1)
  }

  const handleNextClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault()
    if (disabled) {
      return
    }
    onChangePage(page + 1)
  }

  const handleFirstPageClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault()
    if (disabled) {
      return
    }
    onChangePage(firstPageNumber)
  }

  const handleLastPageClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault()
    if (disabled) {
      return
    }
    onChangePage(lastPageNumber)
  }

  const handlePageClick = (pageNumber: number) => (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault()
    if (disabled) {
      return
    }
    onChangePage(pageNumber)
  }

  if (pagesCount <= 1) {
    return <></>
  }

  return (
    <div className="row justify-content-center">
      <div className={cn('col-auto', styles.articlePagesContainer)}>
        <div className="row align-items-center">
          <a 
            className={cn(styles.articlePageNavigation, styles.prevPage, page > firstPageNumber && styles.activeNavigation)} 
            href="#" 
            onClick={handlePrevClick}
          >
            PREV
          </a>
          <div className="col-auto">
            <div className="row align-items-center">
              <a className={cn(styles.articlePage, page === firstPageNumber && styles.articlePageActive)} href="#" onClick={handleFirstPageClick}>
                {firstPageNumber + 1}
              </a>
              {displayeLeftDots && <div className={styles.articlePageDots}>...</div>}
              {window.map(x => (
                <a key={x} className={cn(styles.articlePage, page === x && styles.articlePageActive)} href="#" onClick={handlePageClick(x)}>
                  {x + 1}
                </a>
              ))}
              {displayeRightDots && <div className={styles.articlePageDots}>...</div>}
              <a className={cn(styles.articlePage, page === lastPageNumber && styles.articlePageActive)} href="#" onClick={handleLastPageClick}>
                {lastPageNumber + 1}
              </a>
            </div>
          </div>
          <a 
            className={cn(styles.articlePageNavigation, styles.nextPage, page < lastPageNumber && styles.activeNavigation)} 
            href="#"  
            onClick={handleNextClick} 
          >
            NEXT
          </a>
        </div>
      </div>
    </div>
  )
}
