import { isActiveRoundStatus } from 'consts'
import { createSelector } from 'reselect'
import { OperationTypes } from 'store/logic/operations-logic'
import { ApplicationState } from '../types/common'
import { makeGetIsOperationWithTypeRunning, makeGetIsOperationWithTypeSuccessOrRunning } from './operations-selectors'

const getState = (state: ApplicationState) => state.rounds

export const getRoundById = createSelector(getState, state => state?.byId || {})

const getIdsByTournamentDetailId = createSelector(getState, state => state?.idsByTournamentDetailId || {})

export const getPendingRoundsIds = createSelector(getState, state => state?.pendingRoundsIds ?? [])

export const getRoundsByTournamentDetailId = createSelector(
  getRoundById,
  getIdsByTournamentDetailId,
  (_: ApplicationState, tournamentDetailId: number) => tournamentDetailId,
  (byId, ids, tournamentDetailId) => {
    return (ids[tournamentDetailId] ?? []).map(id => byId[id])
  }
)

const getLoadRoundsOperationType = createSelector(
  (_, tournamentDetailId: number) => tournamentDetailId,
  tournamentDetailId => ({ type: OperationTypes.loadRounds, objectId: tournamentDetailId })
)
export const makeGetIsRoundsLoading = () => makeGetIsOperationWithTypeRunning(getLoadRoundsOperationType)
export const makeGetIsRoundsRequested = () => makeGetIsOperationWithTypeSuccessOrRunning(getLoadRoundsOperationType)

export const makeGetRound = () =>
  createSelector(
    getRoundById,
    (_: ApplicationState, id: number) => id,
    (byId, id) => byId[id]
  )

export const makeGetRoundsIdsByTournamentDetailId = () =>
  createSelector(
    getIdsByTournamentDetailId,
    (_: ApplicationState, { tournamentDetailId }: { tournamentDetailId: number }) => tournamentDetailId,
    (ids, tournamentDetailId) => ids[tournamentDetailId] ?? []
  )

export const makeGetSortedRoundsIdsByTournamentDetailId = () =>
  createSelector(
    getRoundById,
    getIdsByTournamentDetailId,
    (_: ApplicationState, { tournamentDetailId }: { tournamentDetailId: number }) => tournamentDetailId,
    (byId, ids, tournamentDetailId) => (ids[tournamentDetailId] ?? []).sort((a, b) => byId[a].RoundNumber - byId[b].RoundNumber)
  )

export const makeGetRoundsByTournamentDetailId = () => {
  const getRoundsIdsByTournamentDetailId = makeGetSortedRoundsIdsByTournamentDetailId()
  return createSelector(getRoundById, getRoundsIdsByTournamentDetailId, (byId, ids) => ids.map(id => byId[id]))
}

export const makeGetFirstRoundId = () => {
  const getRoundsIdsByTournamentDetailId = makeGetSortedRoundsIdsByTournamentDetailId()

  return createSelector(getRoundsIdsByTournamentDetailId, ids => ids[0])
}

export const makeGetActiveRound = () => {
  const getRounds = makeGetRoundsByTournamentDetailId()

  return createSelector(getRounds, rounds => rounds.find(x => isActiveRoundStatus(x.Status)))
}

export const getTempRoundsIds = createSelector(
  getRoundById,
  byId => Object.keys(byId).map(id => parseInt(id)).filter(id => id < 0)
)
