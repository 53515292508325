import React, { useRef, useEffect, useCallback, useState } from "react";
import { Group, Label, Rect as KonvaRectangle,Tag,Text as KonvaText, Text, Transformer } from "react-konva";
import { StickyNote } from "./StickeyNote";
import { LIMITS } from "../../constant/constant"
import { ToolTipHover } from "./ToolTip/ToolTipHover"
// import { selectShape, transformRectangleShape, moveShape } from "./state";

const boundBoxCallbackForRectangle = (oldBox, newBox) => {
  // limit resize
  if (
    newBox.width < LIMITS.RECT.MIN ||
    newBox.height < LIMITS.RECT.MIN ||
    newBox.width > LIMITS.RECT.MAX ||
    newBox.height > LIMITS.RECT.MAX
  ) {
    return oldBox;
  }
  return newBox;
}

export const Rectangle =({ Id, isSelected,setSelectedShape,moveShape,transformShape,operationType,openSectionDetail,handleUpdateText,IsCartPage, ...shapeProps }) =>{
  const shapeRef = useRef();
  const transformerRef = useRef();
  const labelRef = useRef();
  const [text,setText] = useState<string>(shapeProps.SectionName)
  const [visible,setVisible] = useState<boolean>(false)
  const [currentOperation,setCurrentOperation] = useState<string>(operationType)
  useEffect(() => {
    
    if (isSelected && currentOperation !== 'ticketArrangement' && IsCartPage !== true) {
      transformerRef.current.nodes([shapeRef.current]);
      transformerRef.current.getLayer().batchDraw();
      
    }
  }, [isSelected,operationType,currentOperation]);

  useEffect(()=>{
    setCurrentOperation(operationType)
  },[operationType])
  const handleSelect = (event) => {
      if(currentOperation === 'sectionArrangement'){
        event.cancelBubble = true;
        setSelectedShape(Id);
      }
    }
    
  
  const handleDrag = useCallback(
    (event) => {
      if(IsCartPage === true){
        event.preventDefault()
        return false
      }
      if(currentOperation === 'sectionArrangement' && IsCartPage !== true){
        moveShape(Id, event,shapeProps,shapeProps.SectionShapeType)
      }
    },
    [Id]
  );
  const handleTransform = useCallback(
    (event) => {
      if(currentOperation === 'sectionArrangement' && IsCartPage !== true){
        transformShape(shapeRef.current, Id, event)
      }
    },
    [Id]
  );
  const handleDblClick = useCallback(
    (event) => {
      if(currentOperation === 'sectionArrangement'){
        openSectionDetail(Id,event)
      }
    },
    [Id,currentOperation]
  )
  const handleTextPropsUpdate = useCallback(
    (prop:any,value:any) => {
      if(currentOperation === 'sectionArrangement' && IsCartPage !== true){
        handleUpdateText(Id,prop,value)
      }
    },
    [Id,currentOperation]
  )
  const handleMouseMove = ()=>{
   // if(shapeProps.SectionName && shapeProps.SectionName!='' && shapeProps.SectionName!==null){
    //   setText("Section Name :"+shapeProps.SectionName)
    // }
    setText(IsCartPage!== true?"Click on the shape to explore more options":"Double click on section to select seats.")
    setVisible(true)
  }
  const handleMouseOut=()=>{
    setText('')
    setVisible(false)
  }

  return (
    <>
        {!shapeProps.IsLayOut && 
          <ToolTipHover 
            x={shapeProps.x} 
            y={shapeProps.y-50} 
            opacity={0.75} 
            visible={visible}
            toolTipfill='black'
            pointerDirection="down"
            pointerWidth={10}
            pointerHeight={10}
            lineJoin="round"
            shadowColor="black"
            shadowBlur={10}
            shadowOffsetX={10} 
            shadowOffsetY={10}
            shadowOpacity={0.5}
            text={text}
            fontSize={18}
            padding={5}
            fill="white"
          />
        }
        <Group
            name={"group_" + shapeProps.Id}
            draggable={IsCartPage !== true}
            width={shapeProps.width}
            height={shapeProps.height}
            onClick={handleSelect}
            ref={shapeRef}
            onTap={handleSelect}
            onDragStart={handleSelect}
            onDblClick={handleDblClick}
            onDragEnd={handleDrag}
            {...shapeProps}
            onMouseMove={handleMouseMove}
            onMouseOut={handleMouseOut}
          >
            
          <KonvaRectangle
              width={shapeProps.width}
              height={shapeProps.height}
              name="rectangle"
              fill={shapeProps.fill}
              stroke={shapeProps.stroke}
              onTransformEnd={handleTransform}
         />
         
         
         {shapeProps.IsLayOut && <StickyNote
          x={1}
          y={15}
          text={shapeProps.SectionName ? shapeProps.SectionName:"SECTION NAME"}
          color="#a1a7b4"
          onTextChange={(value) => shapeProps.IsLayOut === true ? false : handleTextPropsUpdate("SectionName",value)}
          width={shapeProps.SectionTextWidth ? shapeProps.SectionTextWidth:shapeProps.width}
          height={shapeProps.SectionTextHeight?shapeProps.SectionTextHeight:shapeProps.height}
          selected={shapeProps.IsTextSelected ? shapeProps.IsTextSelected:false}
          fontsize={10}
          
          onTextResize={(newWidth, newHeight) => {
            handleTextPropsUpdate("SectionTextWidth",newWidth)
            handleTextPropsUpdate("SectionTextHeight",newHeight)
          }}
          onClick={() => {
            handleTextPropsUpdate("IsTextSelected",!selected)
          }}
          onTextClick={(newSelected) => {
            handleTextPropsUpdate("IsTextSelected",newSelected)
          }}
        />}
      </Group>
     {isSelected && (
        <Transformer
          anchorSize={5}
          borderDash={[6, 2]}
          ref={transformerRef}
          onTransformEnd={handleTransform}
          boundBoxFunc={boundBoxCallbackForRectangle}
        />
      )}
    </>
  );
}
