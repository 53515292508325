import { makeActionCreator, ReduxAction } from './common'
import { ImageType } from './image-types'

export interface BlogType {
  Id: number
  Title: string
  PreviewImageId: number
  Date?: Date
  ShortDescription: string
}

export interface BlogSectionType {
  Id: number
  BlogId: number
  OrderNumber: number
  ImageId?: number
  Text?: string
}

export interface BlogsModel {
  Blogs: BlogType[]
  BlogSections: BlogSectionType[]
  Images: ImageType[]
}

export interface BlogState {
  byId: {
    [key: number]: BlogType
  }
  ids: number[]
  readBlogsIds: number[]
  allBlogsCount: number
  unreadCount: number
  blogSectionById: {
    [key: number]: BlogSectionType
  }
  blogSectionsIdsByBlogId: {
    [key: number]: number[]
  }
}

export const BLOGS_LOADED = 'BLOGS_LOADED'
interface BlogsLoadedPayload extends BlogsModel {
  AllBlogsCount: number
  ReadBlogsIds: number[]
}
export type BlogsLoadedAction = ReduxAction<typeof BLOGS_LOADED, BlogsLoadedPayload>
export const blogsLoaded = makeActionCreator<BlogsLoadedPayload>(BLOGS_LOADED)

export const BLOG_BY_ID_LOADED = 'BLOG_BY_ID_LOADED'
export type BlogByIdLoadedAction = ReduxAction<typeof BLOG_BY_ID_LOADED, BlogsModel>
export const blogByIdLoaded = makeActionCreator<BlogsModel>(BLOG_BY_ID_LOADED)

export const UNREAD_BLOGS_COUNT_LOADED = 'UNREAD_BLOGS_COUNT_LOADED'
export type UnreadBlogsCountLoadedAction = ReduxAction<typeof UNREAD_BLOGS_COUNT_LOADED, number>
export const unreadBlogsCountLoaded = makeActionCreator<number>(UNREAD_BLOGS_COUNT_LOADED)

export const BLOG_ADDED = 'BLOG_ADDED'
export type BlogAddedAction = ReduxAction<typeof BLOG_ADDED, BlogsModel>
export const blogAdded = makeActionCreator<BlogsModel>(BLOG_ADDED)

export const BLOG_DELETED = 'BLOG_DELETED'
export type BlogDeletedAction = ReduxAction<typeof BLOG_DELETED, number>
export const blogDeleted = makeActionCreator<number>(BLOG_DELETED)

export const BLOG_UPDATED = 'BLOG_UPDATED'
export type BlogUpdatedAction = ReduxAction<typeof BLOG_UPDATED, BlogsModel>
export const blogUpdated = makeActionCreator<BlogsModel>(BLOG_UPDATED)

export type KnownAction = BlogsLoadedAction
  | BlogByIdLoadedAction
  | UnreadBlogsCountLoadedAction
  | BlogAddedAction
  | BlogDeletedAction
  | BlogUpdatedAction
