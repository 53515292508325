import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { loadGames, loadTopGames } from 'store/logic/game-logic'
import { GameCard } from './GameCard'
import styles from '../Home.module.scss'
import HomeHeader from './HomeHeader'
import { getTopGames } from 'store/selectors/game-selectors'
import { useAppSelector } from 'components/_hooks'


export const GameLists = () => {
  const dispatch = useDispatch()

  const topGames = useAppSelector(getTopGames)

  const popularGamesIds = topGames.PopularGamesIds
  const upcomingGamesIds = topGames.UpcomingGamesIds
  
  useEffect(() => {
    dispatch(loadGames)
    dispatch(loadTopGames)
  }, [dispatch])

  return (
    <>
      <div className={styles.ownedGamesContent}>
        <HomeHeader link="/games" title="Popular tournament games" />
        <div className={styles.owlCarousel}>
          <div className="content">{popularGamesIds?.map(gameId => <GameCard key={gameId} id={gameId} />)}</div>
        </div>
      </div>

      <div className={styles.ownedGamesContent}>
        <HomeHeader link="/tournaments" title="Upcoming tournaments" />
        <div className={styles.owlCarousel}>
          <div className={styles.content}>
            {upcomingGamesIds?.map(upcomingGameId => <GameCard key={upcomingGameId} id={upcomingGameId} />)}
          </div>
        </div>
      </div>
    </>
  )
}
