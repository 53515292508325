import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getOrganizersLoading, makeGetUser } from 'store/selectors/user-selectors'
import { ApplicationState } from 'store/types/common'
import { TournamentType } from 'store/types/tournaments-types'
import { getIsAdmin } from 'store/types/user-types'

export const useTournamentOrganizer = (tournament: TournamentType): string => {
  const getOrginizer = useMemo(makeGetUser, [])
  const organizer = useSelector((state: ApplicationState) => getOrginizer(state, tournament.OrganizerId))
  const organizersLoading = useSelector(getOrganizersLoading)

  if (organizersLoading) {
    return ''
  }

  if (!organizer) {
    return 'Kombatlink'
  }

  if (getIsAdmin(organizer.Role)) {
    return 'Kombatlink'
  }

  return organizer.Organizationname 
}
