const timezonesMap: { [key: string]: string } = {
  'Etc/GMT+12': 'Dateline Standard Time',
  'Etc/GMT+11': 'UTC-11',
  'America/Adak': 'Aleutian Standard Time',
  'Pacific/Honolulu': 'Hawaiian Standard Time',
  'Pacific/Marquesas': 'Marquesas Standard Time',
  'America/Anchorage': 'Alaskan Standard Time',
  'Etc/GMT+9': 'UTC-09',
  'America/Tijuana': 'Pacific Standard Time (Mexico)',
  'Etc/GMT+8': 'UTC-08',
  'America/Los_Angeles': 'Pacific Standard Time',
  'America/Phoenix': 'US Mountain Standard Time',
  'America/Chihuahua': 'Mountain Standard Time (Mexico)',
  'America/Denver': 'Mountain Standard Time',
  'America/Guatemala': 'Central America Standard Time',
  'America/Chicago': 'Central Standard Time',
  'Pacific/Easter': 'Easter Island Standard Time',
  'America/Mexico_City': 'Central Standard Time (Mexico)',
  'America/Regina': 'Canada Central Standard Time',
  'America/Bogota': 'SA Pacific Standard Time',
  'America/Cancun': 'Eastern Standard Time (Mexico)',
  'America/New_York': 'Eastern Standard Time',
  'America/Port-au-Prince': 'Haiti Standard Time',
  'America/Havana': 'Cuba Standard Time',
  'America/Indianapolis': 'US Eastern Standard Time',
  'America/Asuncion': 'Paraguay Standard Time',
  'America/Halifax': 'Atlantic Standard Time',
  'America/Caracas': 'Venezuela Standard Time',
  'America/Cuiaba': 'Central Brazilian Standard Time',
  'America/La_Paz': 'SA Western Standard Time',
  'America/Santiago': 'Pacific SA Standard Time',
  'America/Grand_Turk': 'Turks And Caicos Standard Time',
  'America/St_Johns': 'Newfoundland Standard Time',
  'America/Araguaina': 'Tocantins Standard Time',
  'America/Sao_Paulo': 'E. South America Standard Time',
  'America/Cayenne': 'SA Eastern Standard Time',
  'America/Buenos_Aires': 'Argentina Standard Time',
  'America/Godthab': 'Greenland Standard Time',
  'America/Montevideo': 'Montevideo Standard Time',
  'America/Punta_Arenas': 'Magallanes Standard Time',
  'America/Miquelon': 'Saint Pierre Standard Time',
  'America/Bahia': 'Bahia Standard Time',
  'Etc/GMT+2': 'UTC-02',
  'Atlantic/Azores': 'Azores Standard Time',
  'Atlantic/Cape_Verde': 'Cape Verde Standard Time',
  'Etc/GMT': 'UTC',
  'Africa/Casablanca': 'Morocco Standard Time',
  'Europe/London': 'GMT Standard Time',
  'Atlantic/Reykjavik': 'Greenwich Standard Time',
  'Europe/Berlin': 'W. Europe Standard Time',
  'Europe/Budapest': 'Central Europe Standard Time',
  'Europe/Paris': 'Romance Standard Time',
  'Europe/Warsaw': 'Central European Standard Time',
  'Africa/Lagos': 'W. Central Africa Standard Time',
  'Asia/Amman': 'Jordan Standard Time',
  'Europe/Bucharest': 'GTB Standard Time',
  'Asia/Beirut': 'Middle East Standard Time',
  'Africa/Cairo': 'Egypt Standard Time',
  'Europe/Chisinau': 'E. Europe Standard Time',
  'Asia/Damascus': 'Syria Standard Time',
  'Asia/Hebron': 'West Bank Standard Time',
  'Africa/Johannesburg': 'South Africa Standard Time',
  'Europe/Kiev': 'FLE Standard Time',
  'Asia/Jerusalem': 'Israel Standard Time',
  'Europe/Kaliningrad': 'Kaliningrad Standard Time',
  'Africa/Khartoum': 'Sudan Standard Time',
  'Africa/Tripoli': 'Libya Standard Time',
  'Africa/Windhoek': 'Namibia Standard Time',
  'Asia/Baghdad': 'Arabic Standard Time',
  'Europe/Istanbul': 'Turkey Standard Time',
  'Asia/Riyadh': 'Arab Standard Time',
  'Europe/Minsk': 'Belarus Standard Time',
  'Europe/Moscow': 'Russian Standard Time',
  'Africa/Nairobi': 'E. Africa Standard Time',
  'Asia/Tehran': 'Iran Standard Time',
  'Asia/Dubai': 'Arabian Standard Time',
  'Europe/Astrakhan': 'Astrakhan Standard Time',
  'Asia/Baku': 'Azerbaijan Standard Time',
  'Europe/Samara': 'Russia Time Zone 3',
  'Indian/Mauritius': 'Mauritius Standard Time',
  'Europe/Saratov': 'Saratov Standard Time',
  'Asia/Tbilisi': 'Georgian Standard Time',
  'Asia/Yerevan': 'Caucasus Standard Time',
  'Asia/Kabul': 'Afghanistan Standard Time',
  'Asia/Tashkent': 'West Asia Standard Time',
  'Asia/Yekaterinburg': 'Ekaterinburg Standard Time',
  'Asia/Karachi': 'Pakistan Standard Time',
  'Asia/Calcutta': 'India Standard Time',
  'Asia/Colombo': 'Sri Lanka Standard Time',
  'Asia/Katmandu': 'Nepal Standard Time',
  'Asia/Almaty': 'Central Asia Standard Time',
  'Asia/Dhaka': 'Bangladesh Standard Time',
  'Asia/Omsk': 'Omsk Standard Time',
  'Asia/Rangoon': 'Myanmar Standard Time',
  'Asia/Bangkok': 'SE Asia Standard Time',
  'Asia/Barnaul': 'Altai Standard Time',
  'Asia/Hovd': 'W. Mongolia Standard Time',
  'Asia/Krasnoyarsk': 'North Asia Standard Time',
  'Asia/Novosibirsk': 'N. Central Asia Standard Time',
  'Asia/Tomsk': 'Tomsk Standard Time',
  'Asia/Shanghai': 'China Standard Time',
  'Asia/Irkutsk': 'North Asia East Standard Time',
  'Asia/Singapore': 'Singapore Standard Time',
  'Australia/Perth': 'W. Australia Standard Time',
  'Asia/Taipei': 'Taipei Standard Time',
  'Asia/Ulaanbaatar': 'Ulaanbaatar Standard Time',
  'Australia/Eucla': 'Aus Central W. Standard Time',
  'Asia/Chita': 'Transbaikal Standard Time',
  'Asia/Tokyo': 'Tokyo Standard Time',
  'Asia/Pyongyang': 'North Korea Standard Time',
  'Asia/Seoul': 'Korea Standard Time',
  'Asia/Yakutsk': 'Yakutsk Standard Time',
  'Australia/Adelaide': 'Cen. Australia Standard Time',
  'Australia/Darwin': 'AUS Central Standard Time',
  'Australia/Brisbane': 'E. Australia Standard Time',
  'Australia/Sydney': 'AUS Eastern Standard Time',
  'Pacific/Port_Moresby': 'West Pacific Standard Time',
  'Australia/Hobart': 'Tasmania Standard Time',
  'Asia/Vladivostok': 'Vladivostok Standard Time',
  'Australia/Lord_Howe': 'Lord Howe Standard Time',
  'Pacific/Bougainville': 'Bougainville Standard Time',
  'Asia/Srednekolymsk': 'Russia Time Zone 10',
  'Asia/Magadan': 'Magadan Standard Time',
  'Pacific/Norfolk': 'Norfolk Standard Time',
  'Asia/Sakhalin': 'Sakhalin Standard Time',
  'Pacific/Guadalcanal': 'Central Pacific Standard Time',
  'Asia/Kamchatka': 'Russia Time Zone 11',
  'Pacific/Auckland': 'New Zealand Standard Time',
  'Etc/GMT-12': 'UTC+12',
  'Pacific/Fiji': 'Fiji Standard Time',
  'Pacific/Chatham': 'Chatham Islands Standard Time',
  'Etc/GMT-13': 'UTC+13',
  'Pacific/Tongatapu': 'Tonga Standard Time',
  'Pacific/Apia': 'Samoa Standard Time',
  'Pacific/Kiritimati': 'Line Islands Standard Time',
}
export function getLocalTimezone(): string | null {
  try {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
    return timezonesMap[tz] || null
  } catch {}
  return null
}

export function getJSTimezone(timeZoneId: string): string | null {
  try {
    return Object.keys(timezonesMap).find(key => timezonesMap[key] === timeZoneId)
  } catch {}
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
  return tz
}
