import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { makeGetRound } from 'store/selectors/rounds-selectors'
import { ApplicationState } from 'store/types/common'
import { RoundType } from 'store/types/rounds-types'
import { TournamentType } from 'store/types/tournaments-types'
import { useTournament } from './useTournament'

interface UseRoundResult {
  tournament?: TournamentType
  round?: RoundType
}

export const useRound = (roundId?: number): UseRoundResult => {
  const getRound = useMemo(makeGetRound, [])
  const round = useSelector((state: ApplicationState) => getRound(state, roundId))
  const { tournament } = useTournament(round?.TournamentDetailId)

  return {
    tournament,
    round,
  }
}
