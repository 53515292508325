import React from 'react'
import styles from './Home.module.scss'

import {
  SideBySideMagnifier
} from "react-image-magnifiers";

function HomeIntro() {
  const cdn = 'https://kombatlink-assets.azureedge.net/img'
  return (
    <>
      <div className={styles.content_wrapper + " m-0"} >
        <div className={"row row-xl-2 row-l-2 row-m-1 m-0 " + styles.flexm + " " + styles.tournamentWrapper}>
          <div className={"col-xl-6 col-l-6 col-m-12 m-0 " + styles.dash_txt} data-aos="fade-right">
            <h3>Flexible Tournament Solution</h3>
            <h6>Take full control of your event and watch it unfold</h6>
            <ul>
              <li className={styles.cup}>
                <h6>Tournament overview</h6>
                <p>Craft your event details, pin the location, and take it live. </p>
              </li>
              <li className={styles.people}>
                <h6>Simplify team sign up</h6>
                <p>Manage team registration and lineups all in one place.</p>
              </li>
              <li className={styles.chat}>
                <h6>In-app messaging</h6>
                <p>Create private and group chats to stay connected for all events.</p>
              </li>
            </ul>
          </div>
          <div className={"col-xl-6 col-l-6 col-m-12 " + styles.img + " " + styles.img_full + " " + styles.right_img} data-aos="fade-left">
            <a className="group1" href="javascript:void(0)" title="dashboard">
              {/* <PictureInPictureMagnifier cursorStyleActive="string" 
                previewSizePercentage={20}
                previewOpacity={1}
                previewVerticalPos="bottom"
                previewHorizontalPos="left"
                imageSrc={`${cdn}/dashboard.png`}
                /> */}
                <div className={styles.overflowHidden}>
                <SideBySideMagnifier
                  alwaysInPlace
                  cursorStyle="string"
                  fillAvailableSpace 
                  imageSrc={`${cdn}/dashboard.png`}/>
                  </div>
                
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default HomeIntro