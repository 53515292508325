// -----------------
// STATE
// -----------------

export enum OperationStatus {
  Running,
  Success,
  Failed,
}

export interface OperationObject {
  id: number
  type: string
  objectId: any
  status: OperationStatus
  canceled: boolean
  description?: string
  timeStart: Date
  timeStop?: Date
}

export interface State {
  list: OperationObject[]
}
// -----------------
// ACTIONS
// -----------------

export const SET_OPERATION_STATUS = 'SET_OPERATION_STATUS'
interface SetOperationStatusAction {
  type: typeof SET_OPERATION_STATUS
  operation: OperationObject
}
export function SetOperationStatus(operation: OperationObject): SetOperationStatusAction {
  return {
    type: SET_OPERATION_STATUS,
    operation,
  }
}

export type OperationsAction = SetOperationStatusAction
