import { makeActionCreator, ReduxAction } from './common'
import { MatchesModel } from './match-types'

export enum MatchLinkStatusEnum {
  Pending = 1,
  Approved = 2,
  Deleted = 3
}

export interface MatchLinkModels extends MatchesModel {
  MatchLinks: MatchLinkModel[]
}

export interface ListMatchLinkModel extends MatchLinkModels {
  AllMatchLinksCount: number
}

export interface MatchLinkModel {
  Id: number
  MatchId: number
  TournamentParticipantId: number
  Link: string
  Status: MatchLinkStatusEnum
}

export interface State {
  matchLinks:  {
    [key: number]: MatchLinkModel
  } 
  matchLinkIdsByMatchId: {
    [key: number]: number[]
  } 
  pendingMatchListCount: number
  approvedMatchLinkCount: number
  pendingMatchLinkIds: number[]
  approvedMatchLinksIds: number[]
}


export const MATCH_LINKS_LOADED = 'MATCH_LINKS_LOADED'
interface MatchLinksLoadedPayload {
  matchLinks: MatchLinkModel[]
  matchId: number
}

export type MatchLinksLoadedAction = ReduxAction<typeof MATCH_LINKS_LOADED, MatchLinksLoadedPayload>
export const matchLinksLoaded = makeActionCreator<MatchLinksLoadedPayload>(MATCH_LINKS_LOADED)


export const MATCH_LINK_ADDED = 'MATCH_LINK_ADDED' 
interface MatchLinkLoadedPayload {
  matchLink: MatchLinkModel
}

export type MatchLinkAddedAction = ReduxAction<typeof MATCH_LINK_ADDED, MatchLinkLoadedPayload>
export const matchLinkAdded = makeActionCreator<MatchLinkLoadedPayload>(MATCH_LINK_ADDED)


export const MATCH_LINK_PUBLISHED = 'MATCH_LINK_PUBLISHED' 
interface MatchLinkPusblishedPayload {
  matchLink: MatchLinkModel
}

export type MatchLinkPusblishedAction = ReduxAction<typeof MATCH_LINK_PUBLISHED, MatchLinkPusblishedPayload>
export const matchLinkPusblished = makeActionCreator<MatchLinkPusblishedPayload>(MATCH_LINK_PUBLISHED)


export const MATCH_LINK_DELETED = 'MATCH_LINK_DELETED' 
interface MatchLinkDeletedPayload {
  matchLink: MatchLinkModel
}

export type MatchLinkDeletedAction = ReduxAction<typeof MATCH_LINK_DELETED, MatchLinkDeletedPayload>
export const matchLinkDeleted = makeActionCreator<MatchLinkDeletedPayload>(MATCH_LINK_DELETED)


export const TOURNAMENT_MATCH_LINKS_LOADED = 'TOURNAMENT_MATCH_LINKS_LOADED'
interface TournamentMatchLinksLoadedPayload {
  matchLinks: MatchLinkModel[]
}

export type TournamentMatchLinksLoadedAction = ReduxAction<typeof TOURNAMENT_MATCH_LINKS_LOADED, TournamentMatchLinksLoadedPayload>
export const tournamentMatchLinksLoaded = makeActionCreator<TournamentMatchLinksLoadedPayload>(TOURNAMENT_MATCH_LINKS_LOADED)

export const PENDING_MATCH_LINKS_LOADED = 'PENDING_MATCH_LINKS_LOADED'
export type PendingMatchLinksLoadedAction = ReduxAction<typeof PENDING_MATCH_LINKS_LOADED, ListMatchLinkModel>
export const pendingMatchLinksLoaded = makeActionCreator<ListMatchLinkModel>(PENDING_MATCH_LINKS_LOADED)


export const APPROVED_MATCH_LINKS_LOADED = 'APPROVED_MATCH_LINKS_LOADED'
export type ApprovedMatchLinksLoadedAction = ReduxAction<typeof APPROVED_MATCH_LINKS_LOADED, ListMatchLinkModel>
export const approvedMatchLinksLoaded = makeActionCreator<ListMatchLinkModel>(APPROVED_MATCH_LINKS_LOADED)


export type KnownAction = MatchLinksLoadedAction 
| MatchLinkAddedAction 
| TournamentMatchLinksLoadedAction 
| PendingMatchLinksLoadedAction 
| MatchLinkPusblishedAction 
| MatchLinkDeletedAction
| ApprovedMatchLinksLoadedAction
