import React from 'react'
import { cn } from 'utils'
import styles from './LoadingLine.module.scss'

export const LoadingLine = () => {
  return (
    <div className={styles.loading}>
      <div className={styles.loading_line_wrapper}>
        <div className={styles.loading_line}>
          <div className={cn(styles.loading_line_inner, styles.loading_line_inner_first)}></div>
          <div className={cn(styles.loading_line_inner, styles.loading_line_inner_second)}></div>
        </div>
      </div>
    </div>
  )
}
