import React from 'react'
import ReactDatePicker from 'react-date-picker'
import { cn } from 'utils'
import './DatePicker.css'

export interface DatePickerProps {
  onChange: (value: Date) => void
  value?: Date
  className?: string
}

export const DatePicker = ({ onChange, value, className }:  DatePickerProps) => {
  return (
    <ReactDatePicker
      onChange={onChange}
      value={value}
      className={cn('form-control', className)}
    />
  )
}
