import React, { useEffect, useState } from 'react'
import { asyncFileToDataUrl } from 'utils'
import { FilePreview } from './_shared/FilePreview/FilePreview'
import { Dropzone, DropzoneProps } from './_shared/Dropzone/Dropzone'
import { useImage } from 'components/_hooks'
import { AspectRatioType } from './_shared/KlCropper/KlCropper'
import { MediaType } from 'store/types/image-types'

interface KlFileSelectorProps extends DropzoneProps {
  previewClassName?: string
  disabled?: boolean
  crop?: boolean
  imageId?: number
  aspectRatioType?: AspectRatioType
  onCrop?: (file: File) => void
  round?: boolean
}

export const KlFileSelector = ({
  file,
  fileTypes,
  onChange,
  dropText,
  dropClassName,
  previewClassName,
  recommendedText,
  disabled,
  crop,
  imageId,
  aspectRatioType,
  onCrop,
  round,
}:  KlFileSelectorProps) => {
  const [dataUrl, setDataUrl] = useState<string>()
  const [fileMediaType, setFileMediaType] = useState<MediaType>()
  const { imageSrc } = useImage(imageId)
  
  useEffect(() => {
    const calcDataUrl = async () => {
      const value = await asyncFileToDataUrl(file)
      setDataUrl(value)
    }

    if (file) {
      void calcDataUrl()
      const mediaType = file.type.includes('image') || !file.type ? MediaType.Image : MediaType.Video
      setFileMediaType(mediaType)
    } else {
      setDataUrl(undefined)
    }
  }, [file])

  useEffect(() => {
    setDataUrl(imageSrc)
  }, [imageSrc])

  const handleReset = () => {
    setDataUrl(undefined)
    onChange()
  }

  return (
    <>
      {!dataUrl &&
        <Dropzone
          dropText={dropText}
          onChange={onChange}
          file={file}
          fileTypes={fileTypes}
          dropClassName={dropClassName}
          recommendedText={recommendedText}
          disabled={disabled}
        />
      }
      {!!dataUrl &&
        <FilePreview round={round} fileMediaType={fileMediaType} aspectRatioType={aspectRatioType} onCrop={onCrop} src={dataUrl} previewClassName={previewClassName} onReset={handleReset} disabled={disabled} withCrop={crop} />
      }
    </>
  )
}
