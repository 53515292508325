import { SimpleDateInput } from 'components/_shared'
import React from 'react'
import { cn } from 'utils'
import styles from '../SignUp.module.scss'

interface SignUpDatePickerProps {
  title: string
  value?: Date
  onChange: (value: Date) => void
  min:string
  checkOnBlur?:boolean
}

export const SignUpDatePicker = ({ title, onChange, value, min,checkOnBlur  }: SignUpDatePickerProps) => {
  return (
    <>
      <div className={styles.signUpInputTitle}>{title}</div>
      <SimpleDateInput
       onChange={onChange}
       value={value}
       className={cn(styles.signUpContainer, styles.signUpDateContainer)}
       inputClassName={cn(styles.signUpDefaultInput, styles.signUpInputDate)}
       min={min}
       checkOnBlur={checkOnBlur}

      />
    </>
  )
}
