import { Reducer } from 'redux'
import { KnownAction, POINTS_REWARDS_LOADED, PointsRewardState, POINTS_REWARDS_UPDATED } from '../types/points-reward-types'

const initialState: PointsRewardState = {
  pointsRewards: {
    Extra: 0,
    Loser: 0,
    Winner: 0,
  },
}

const reducer: Reducer<PointsRewardState, KnownAction> = (state: PointsRewardState | undefined = initialState, action: KnownAction) => {
  switch (action.type) {
    case POINTS_REWARDS_LOADED:
    case POINTS_REWARDS_UPDATED: {
      return {
        pointsRewards: action.payload
      }
    }
    default:
      return state
  }
}

export default reducer
