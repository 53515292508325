import { Button, ButtonProps } from 'components/_shared'
import React from 'react'
import { cn } from 'utils'
import styles from './BattleRow.module.scss'

interface BattleRowProps {
  children: React.ReactNode
  highlighted?: boolean
}

export const BattleRow = ({ children, highlighted }: BattleRowProps) => {
  return <div className={cn('row', 'table__main-row', styles.tableMainRow, highlighted && styles.highlighted)}> {children}</div>
}

export const BattleSecondaryRow = ({ children }: BattleRowProps) => {
  return <div className={cn('row', 'table__secondary-row', styles.table__secondary_row)}> {children}</div>
}

export const BattleHeaderRow = ({ children }: BattleRowProps) => {
  return <div className="row table__headers-row"> {children}</div>
}

interface ColumnProps {
  children?: React.ReactNode
  className?: string
}

const MakeBattleColumn = (battleColumnClass: string) => ({ children, className }: ColumnProps) => {
  return <div className={cn('table__column', styles.table__column, battleColumnClass, className)}>{children}</div>
}

export const EventNameColumn = MakeBattleColumn(styles.eventName)
export const UserColumn = MakeBattleColumn(styles.user)
export const ClassColumn = MakeBattleColumn(styles.class)
export const TypeColumn = MakeBattleColumn(styles.type)
export const DateColumn = MakeBattleColumn(styles.date)
export const LinkColumn = MakeBattleColumn(styles.link)
export const TimeColumn = MakeBattleColumn(styles.time)
export const RoundColumn = MakeBattleColumn(styles.round)
export const DeadlineColumn = MakeBattleColumn(styles.deadline)
export const StartDateColumn = MakeBattleColumn(styles.startDate)
export const ButtonsColumn = MakeBattleColumn(styles.buttons)
export const ButtonColumn = MakeBattleColumn(styles.button)

export const TableButton = (props: ButtonProps) => {
  return <Button {...props} className={cn('table__button', props.className)} />
}
