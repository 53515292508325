import React from 'react'
import { cn } from 'utils'
import styles from './ConfirmSubmitModal.module.scss'
import { Modal, ModalType, ModalWindowAnimationType } from 'components/_modals'

export interface SubmitModalProps {
  onClose: CallableFunction
  onConfirm: () => void
  show: boolean
}

export const ConfirmSubmitModal = ({ onClose, onConfirm, show }: SubmitModalProps) => {
  return (
    <Modal
      onClose={onClose}
      allowOk
      asyncOperation={() => {
        onConfirm()
        return Promise.resolve(false)
      }}
      buttonColor="blue"
      okButtonText="Submit result"
      className={cn(styles.confirmSubmitModal, styles.window)}
      animationType={ModalWindowAnimationType.prev}
      type={ModalType.animated}
      show={show}
    >
      <Modal.Header className={cn(styles.confirmSubmitModal, styles.window__header, styles.window__header__center)}>
        Do you want to submit the result?
      </Modal.Header>
    </Modal>
  )
}
