import * as React from 'react'
import { Kl_OrganizerPayout } from '../../models/billing-option-model'
import { BillingOptionUtils } from '../../server/utils/billing-option-utils'
import { UserModel } from '../../models/user-models'
import { CryptoCurrencyData } from '../../consts/CryptoCurrencyType'
import SelectComponent from 'components/_shared/SelectComponent'
interface Props {
  organizerPayout: Kl_OrganizerPayout
}

interface State {
  organizerPayout: Kl_OrganizerPayout
  coinAddresMatched: string
  coinAddressMatchedStr: boolean
  currencyData: any
  selectedCurrency: string
}

class CoinDialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      organizerPayout: Object(),
      coinAddresMatched: '',
      coinAddressMatchedStr: false,
      currencyData: [],
      selectedCurrency: '1',
    }

    this.onChangeOrganizerPayout = this.onChangeOrganizerPayout.bind(this)
    this._saveOrganizerPayout = this._saveOrganizerPayout.bind(this)
    this.handleAccountNumber = this.handleAccountNumber.bind(this)
    this.handleCurrencyChange = this.handleCurrencyChange.bind(this)
  }
  componentWillReceiveProps(props: Props) {
    if (props.organizerPayout) {
      
      this.setState({
        organizerPayout: props.organizerPayout,
        coinAddresMatched: props.organizerPayout.CoinAddress,
        selectedCurrency:props.organizerPayout.CoinType?props.organizerPayout.CoinType.toString():'1',
      })
    }
  }

  componentDidMount() {
    if (this.props.organizerPayout) {
      this.setState({
        organizerPayout: this.props.organizerPayout,
        coinAddresMatched: this.props.organizerPayout.CoinAddress,
        selectedCurrency:this.props.organizerPayout.CoinType?this.props.organizerPayout.CoinType.toString():'1',
      })
    }
    this.intializeCurrencies()
  }

  handleAccountNumber(val) {
    let { organizerPayout, coinAddresMatched } = this.state
    coinAddresMatched = val
    if (organizerPayout.CoinAddress == coinAddresMatched)
      this.setState({
        coinAddressMatchedStr: false,
      })
    else this.setState({ coinAddressMatchedStr: true })
    this.setState({ coinAddresMatched })
  }

  onChangeOrganizerPayout(e) {
    const { organizerPayout } = this.state
    organizerPayout[e.target.name] = e.target.value
    this.setState({
      organizerPayout,
    })
  }

  async _saveOrganizerPayout() {
    const { organizerPayout,selectedCurrency } = this.state
    organizerPayout.CoinType = parseInt(selectedCurrency)
    if (!this.state.coinAddressMatchedStr) await BillingOptionUtils.saveOrganizerPayout(organizerPayout)
    else alert('Address not matched')
  }
  intializeCurrencies() {
    this.setState({
      currencyData: CryptoCurrencyData,
    })
  }
  handleCurrencyChange(id, val, index) {
    const { currencyData } = this.state
    const setCurrency = currencyData[val - 1]
    if (setCurrency) {
      this.setState(
        {
          selectedCurrency: val,
        })
    }
  }
  render() {
    const {} = this.props
    const { organizerPayout, coinAddresMatched } = this.state
    return (
      <>
        <div className="bil_inf_t_r">
          <button
            className="bttn-blue-outline open_modal-edit-payout"
            onClick={() => {
              document.getElementById('modal-edit-coin').className = 'bil_modal-container modal-container modal-container--opened'
            }}
          >
            edit Details
          </button>
        </div>
        <aside className="bil_modal-container modal-container" id="modal-edit-coin">
          <div className="modal-container__background" />
          <div className="bil_modal-container__window modal-container__window window">
            <a
              href="#"
              className="window__close"
              onClick={() => {
                document.getElementById('modal-edit-coin').className = 'bil_modal-container modal-container'
              }}
            />
            <div className="window__header-container">
              <div className="row">
                <div className="col-12">
                  <div className="bil_modal_hdr">
                    <div className="bil_modal_hdr_i">
                      <h2 className="bil_modal_hdr_ttl">Edit Coin Section</h2>
                    </div>
                    <div className="bil_modal_hdr_i">
                      <button
                        className="bttn_blue-light"
                        type="button"
                        onClick={() => {
                          this._saveOrganizerPayout()
                          document.getElementById('modal-edit-coin').className = 'bil_modal-container modal-container'
                        }}
                      >
                        Change Method
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="window__container">
              <div className="container-fluid p-0">
                <div className="row overflow-auto">
                  <div className="col-md-6 col-12" style={{ paddingTop: '15px' }}>
                    <div className="row m-0 flex-column">
                      <h4 className="window__subheader">Receiving Coin Address</h4>
                      <input
                        type="text"
                        name="CoinAddress"
                        className="window__input"
                        value={organizerPayout.CoinAddress || ''}
                        onChange={e => {
                          this.onChangeOrganizerPayout(e)
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 col-12" style={{ paddingTop: '15px' }}>
                    <div className="row m-0 flex-column">
                      <h4 className="window__subheader">Confirm Receiving Address</h4>
                      <input
                        type="text"
                        name="CoinAddress"
                        className="window__input"
                        value={coinAddresMatched || ''}
                        onChange={e => {
                          this.handleAccountNumber(e.target.value)
                        }}
                      />
                      {this.state.coinAddressMatchedStr && (
                        <span className="text-danger" style={{ marginTop: '-25px', fontSize: 'small' }}>
                          ! Coin address is not matched
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 col-12" style={{ paddingTop: '15px' }}>
                    <div className="row m-0 flex-column">
                      <h4 className="window__subheader">Coin Selector</h4>
                      <SelectComponent
                        zIndex={499}
                        fieldId="value"
                        valueField="value"
                        textField="lable"
                        placeholder="Select cryptocurrency"
                        searchable={false}
                        data={this.state.currencyData}
                        selectedValue={this.state.selectedCurrency}
                        onSelectionChange={this.handleCurrencyChange}
                        class1="article-select__select article-select__select-coin select withdraw__input"
                        class2="article-select__select--organizer__title select__title select__title-coin"
                        class3="article-select__select--organizer__container select__container select-coin"
                        class4="article-select__select--organizer__option select__option-coin d-block"
                        isImage
                      />
                      {/* <input type="text" disabled={true} name="coinType" className="window__input" value="USD Coin" /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </aside>
      </>
    )
  }
}

export default CoinDialog
