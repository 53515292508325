import { useFormatDateTime } from 'components/_hooks/useFormatDate'
import { isActiveRoundStatus } from 'consts'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import { makeGetRound } from 'store/selectors/rounds-selectors'
import { makeGetTournament } from 'store/selectors/tournaments-selectors'
import { ApplicationState } from 'store/types/common'
import { cn, parseDate } from 'utils'

export interface BracketHeaderItemProps {
  roundId: number
}

export const BracketHeaderItem = ({ roundId }: BracketHeaderItemProps) => {
  const getRound = useMemo(makeGetRound, [])
  const round = useSelector((state: ApplicationState) => getRound(state, roundId))
  const isActive = isActiveRoundStatus(round.Status)
  const getTournament = useMemo(makeGetTournament,[])
  const tournament = useSelector((state: ApplicationState) => getTournament(state, round.TournamentDetailId))
  const formatedDateTime = useFormatDateTime(round.TournamentDetailId,  round.PlayDateTime, 'localStorage')
  let formatedDeadlineDateTime = null
  if(round.ResultSubmissionDeadlineDate!=null){
    formatedDeadlineDateTime = useFormatDateTime(round.TournamentDetailId,  round.ResultSubmissionDeadlineDate, 'localStorage')
  }

  return (
    <div className={cn('eli_hdr_i eli_general-width eli_general-padding', isActive && 'round-active-state')}>
      <div className="eli_hdr_i_i">
        <h3 className="eli_hdr_ttl">{round.RoundTitle}</h3>
        {round.PlayDateTime && (
          <span className="eli_hdr_date">
            {(!tournament.IsManualControl || (tournament.IsManualControl && !tournament.IsAnyTime)) && <span>{formatedDateTime.dateTime}</span>}
            {tournament.IsManualControl && tournament.IsAnyTime && <span>{formatedDateTime.date}</span>}
          </span>
        )}
        {round.IsExtraRound && (
          <div className="white-tooltip-theme ml10">
            <button
              className="btn-more-info btn-tooltip"
              data-tip="This round will be played if it is<br>the first lost for one of the players"
              aria-expanded="false"
            >
              <span className="btn-more-info_icon" />
            </button>
            <ReactTooltip effect="solid" type="light" place="bottom" multiline />   
          </div>
        )}
      </div>
      <div className="eli_hdr_i_i">
        {!tournament.IsManualControl && <span className="eli_hdr_result">{`Result Deadline: ${formatedDeadlineDateTime?.dateTime}`}</span>}
        {tournament.IsManualControl && !tournament.IsAnyTime && formatedDeadlineDateTime !== null && <span className="eli_hdr_result">{`Result Deadline: ${formatedDeadlineDateTime.dateTime}`}</span>}
      </div>
    </div>
  )
}
