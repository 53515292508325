import { useAppUser } from 'components/_hooks'
import { LoadingBox } from 'components/_shared/Loading'
import React, { useEffect, useMemo, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useDispatch } from 'react-redux'
import { useChatMembers } from 'store/chat/chat-member/chat-member-hooks'
import { useChatMessagesIds, useIsChatMessagesLoading } from 'store/chat/chat-message/chat-message-hooks'
import { messageRead, useLoadChatMessages } from 'store/chat/chat-message/chat-message-logic'
import { useChatById } from 'store/chat/chat/chat-hooks'
import { ChatContainer } from '../ChatContainer/ChatContainer'
import styles from './ChatMessages.module.scss'
import { ChatMessage } from './_shared/ChatMessage'
export interface ChatMessagesProps {
  chatId: number
  loading?: boolean
}

export const ChatMessages = ({ loading, chatId }: ChatMessagesProps) => {
  const dispatch = useDispatch()
  const chat = useChatById(chatId)
  const chatMessagesIds = useChatMessagesIds(chatId)
  const appUser = useAppUser()
  const fetchMessagePerRequest = 10
  const [hasMoreData, setHasMoreData] = useState(false)


  const chatMemebers = useChatMembers(chatId)
  const loadChatMessages = useLoadChatMessages(chatId)
  const isChatMessagesLoading = useIsChatMessagesLoading(chatId)
  const [chatIds, setChatIds] = useState<any>([])
  loading = loading || isChatMessagesLoading

  const myChatMember = useMemo(() => {
    return chatMemebers.find(x => x.UserId === appUser?.Id)
  }, [appUser?.Id, chatMemebers])
  const [totalShowMessages, setTotalShowMessages] = useState(0)

  useEffect(() => {
    if (chatId) {
      setHasMoreData(true)
      let x = loadChatMessages(totalShowMessages, fetchMessagePerRequest)
      x.then(d=>{
        if(d === undefined || d === null || d.length <= 0)
        {
          if(d?.length === 0){
            setHasMoreData(false)
          }
        }
        setChatIds(d)
      })
    }
  }, [chatId, loadChatMessages])

  useEffect(() => {
    if (!myChatMember || chatMessagesIds.length == 0) {
      return
    } else {
      const lastChatMesageId = Math.max(...chatMessagesIds)

      if (!myChatMember.LastReadMessageId || myChatMember.LastReadMessageId < lastChatMesageId) {
        dispatch(messageRead(chatId, lastChatMesageId))
      }
    }

  }, [chatId, chatMessagesIds, dispatch, myChatMember, myChatMember?.LastReadMessageId])

  
  const getData = () => {
    setTotalShowMessages(totalShowMessages + fetchMessagePerRequest)
    loadChatMessages(totalShowMessages+ fetchMessagePerRequest, fetchMessagePerRequest).then(d=>{
      if(d?.length === 0){
        setHasMoreData(false)
      }
      let y = chatIds
      y = y.concat(d)
      setChatIds(y)
    }).catch((e)=>console.log(e))
  }
  return (
    <ChatContainer className={styles.chat}>
      {/* <LoadingBox loading={loading} size="average" loadingClassName={styles.loading}> */}
        <InfiniteScroll
          dataLength={chatIds.length}
          next={getData}
          style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
          inverse={true} //
          hasMore={hasMoreData}
          loader={<LoadingBox loading={loading} size="average" loadingClassName={styles.loading}/>}
          scrollableTarget="scrollableDiv"
        >
          {chatMessagesIds.map((id, index) => {
            return (
              <ChatMessage chatMessageId={id} key={id} myChatMemberId={myChatMember?.Id} chatType={chat.Type} />
            )
          }
          )}
        </InfiniteScroll>
      {/* </LoadingBox> */}
    </ChatContainer>
  )
}
