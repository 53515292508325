import { ContainerName, useFilters, useGetImageSize, useIntMatchParam, useUnionMatchParam } from 'components/_hooks'
import { FilterContainer, Filters, Tabs, HeaderButton, Select } from 'components/_shared'
import { DivImg } from 'components/_shared/Img'
import { TournamentModeEnum, TournamentModeOptions, TournamentStatusEnum } from 'consts'
import { ConsoleEnum, getConsoleTypeOptions } from 'consts/ConsoleType'
import { DateIntervalEnum, DateIntervalOptions } from 'consts/DateInterval'
import { TournamentEventTypeEnum } from 'consts/TournamentEventType'
import { TournamentSortFieldEnum, TournamentSortFieldOptions } from 'consts/TournamentSortField'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { loadGameDetail } from 'store/logic/game-logic'
import { TournamentFilters } from 'store/logic/tournaments-logic'
import { makeGetConsolesByGameId } from 'store/selectors/console-selector'
import { makeGetGame } from 'store/selectors/game-selectors'
import { ApplicationState } from 'store/types/common'
import { IDictionary } from 'store/types/dictionary-types'
import { regionLabelExtractor, RegionType, regionValueExtractor } from 'store/types/region-types'
import { organizerLabelExtractor, organizerValueExtractor, PublicUserType } from 'store/types/user-types'
import { cn } from 'utils'
import styles from './GameTournaments.module.scss'
import { AddToFavorite } from './_shared/AddToFavorite'
import GameTournamentsMain from './_shared/GameTournamentsMain'
import GameTournamentsQualifier from './_shared/GameTournamentsQualifier'
import GameTournamentsUpcoming from './_shared/GameTournamentsUpcoming'

export const gameTournamentsTabs = {
  upcoming: 'upcoming',
  qualifier: 'qualifier',
  main: 'main',
}


export type GameTournamentsTab = keyof typeof gameTournamentsTabs

interface GameTournamentsRouteProps {
  gameId: string
  tab: GameTournamentsTab
}

type OwnProps = RouteComponentProps<GameTournamentsRouteProps>


const getEventType = (tab: GameTournamentsTab) => {
  if (tab == gameTournamentsTabs.qualifier) {
    return TournamentEventTypeEnum.Qualifier
  } 
  if (tab == gameTournamentsTabs.main) {
    return TournamentEventTypeEnum.Main
  } 
  return null
}


const GameTournaments = ({ match }: OwnProps) => {

  const tab = useUnionMatchParam(gameTournamentsTabs, match, m => m.tab, 'upcoming')

  const gameId = useIntMatchParam(match, m => m.gameId)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadGameDetail(gameId))
  }, [dispatch, gameId])

  const getGame = useMemo(makeGetGame, [])

  const game = useSelector((state: ApplicationState) => getGame(state, {gameId}))
  const getGameConsoles = useMemo(makeGetConsolesByGameId, [])
  const gameConsoles = useSelector((state: ApplicationState) => getGameConsoles(state, gameId))
  
  const [filtersModalOpened, setFiltersModalOpened] = useState(false)

  const { organizers, organizersLoading, regions, regionsLoading } = useFilters()
  
  const handleFilterClick = () => {
    setFiltersModalOpened(true)
  }
  const handleCloseFiltersModal = () => {
    setFiltersModalOpened(false)
  }  

  const [selectedOrganizers, setSelectedOrganizers] = useState<PublicUserType[]>([])
  const [selectedConsoles, setSelectedConsoles] = React.useState<IDictionary<ConsoleEnum>[]>([])
  const [selectedInterval, setSelectedInterval] = React.useState<IDictionary<DateIntervalEnum>>(null)  
  const [selectedSortField, setSelectedSortField] = React.useState(TournamentSortFieldOptions[0])  
  const [selectedRegion, setSelectedRegion] = useState<RegionType>()
  const [selectedMode, setSelectedMode] = React.useState<IDictionary<TournamentModeEnum>>(null)

  const handleOrganizersChange = (value: PublicUserType[]) => {
    setSelectedOrganizers(value)
  }

  const handleRegionChange = (value: RegionType) => {
    setSelectedRegion(value)
  }
  const handleChangeInterval = (value: IDictionary<DateIntervalEnum>) => {
    setSelectedInterval(value)
  }
  const handleChangeSortField = (value: IDictionary<TournamentSortFieldEnum>) => {
    setSelectedSortField(value)
  }

  const handleChangeConsoles = (value: IDictionary<ConsoleEnum>[]) => {
    setSelectedConsoles(value)
  }

  const handleChangeMode = (value: IDictionary<TournamentModeEnum>) => {
    setSelectedMode(value)
  }

  const setMetaData = (content:string) => {
    const el = document.querySelector("meta[name='description']")
    el?.setAttribute('content', content)
  }
  
  useEffect(() => {
    if(tab === gameTournamentsTabs.upcoming){
      document.title = `${ game?.Gamename} ' Bracket | ${ game?.Gamename} Upcoming Tournament | Kombatlink'`
      const constent = `Discover ${game?.Gamename} Upcoming Tournament Bracket at Kombatlink. Get details of Organizers and Consoles for Tournament. Visit us!`
      setMetaData(constent)
    }else if(tab === gameTournamentsTabs.qualifier){
      document.title = `${ game?.Gamename} ' Bracket | ${ game?.Gamename} Quilifier Tournaments | Kombatlink'`
      const constent = `Discover ${game?.Gamename} Quilifier Tournaments Bracket at Kombatlink. Get details of Organizers and Consoles for Tournament. Visit us!`
      setMetaData(constent)
    }else if(tab === gameTournamentsTabs.main){
      document.title = `${ game?.Gamename} ' Bracket | ${ game?.Gamename} Main Tournaments | Kombatlink'`
      const constent = `Discover ${game?.Gamename} Main Tournaments Bracket at Kombatlink. Get details of Organizers and Consoles for Tournament. Visit us!`
      setMetaData(constent)
    }
  }, [game?.Gamename, tab])

  const filters = useMemo<TournamentFilters>(() => {
    return {
      name: '',
      venueTypes: [],
      organizersIds: selectedOrganizers.filter(x => x.Id !== 0).map(x => x.Id),
      gamesIds: [gameId],
      regionsIds: selectedRegion ? [selectedRegion.Id] : [],
      kombatlinkOrganizer: selectedOrganizers.some(x => x.Id === 0),
      tournamentStructures: [],
      tournamentMode: selectedMode?.id,
      consoles: selectedConsoles.map(x => x.id),
      tournamentSortField: selectedSortField?.id,
      dateInterval: selectedInterval?.id,
      eventType: getEventType(tab),
      tournamentStatuses: [TournamentStatusEnum.Pending, TournamentStatusEnum.Active],      
    }
  }, [gameId, selectedConsoles, selectedInterval?.id, selectedMode?.id, selectedOrganizers, selectedRegion, selectedSortField?.id, tab])

  const hasConsole = (console: ConsoleEnum) => {
    return gameConsoles.findIndex(gc => gc == console) >= 0
  }

  const isCrossPlatform = gameConsoles.length > 1

  const consoleOptions = useMemo(() => getConsoleTypeOptions(gameConsoles), [gameConsoles])
  const gameContainerSize = useGetImageSize(ContainerName.GamesImagesCardDetail)

  return (
    <>
      <div className="row m-0">
        <div className={cn(styles.article__headerContainer, styles.articleHeader)}>
          <DivImg parentContainerSize={gameContainerSize}
            useBackgroundStyles={false}
            imageId={game?.ImageId} 
            src={!game?.ImageId ? `/images/games/${gameId}.jpg` : undefined } 
            className={styles.articleHeader__background} 
          />
          <div className={cn(styles.articleHeader__content, 'row', 'flex-xl-nowrap', 'flex-wrap', 'justify-content-start', 'align-items-start')}>
            <div className="col-xl col-12 h-xl-100">
              <div className={cn('row', 'flex-column', styles.articleHeader__container)}>
                <div className={cn('col','p-0', styles.articleHeader__consolesContainer)}>
                  <div className="row m-0 flex-xl-wrap flex-nowrap">
                    {isCrossPlatform && (
                      <div className={cn(styles.articleHeader__platform, styles.articleHeader__platform__crossPlatform)} />
                    )}
                    {hasConsole(ConsoleEnum.Xbox) && (
                      <div className={cn(styles.articleHeader__platform, styles.articleHeader__platform__xbox)} />
                    )}
                    {hasConsole(ConsoleEnum.Playstation) && (
                      <div className={cn(styles.articleHeader__platform, styles.articleHeader__platform__ps)} />
                    )}
                    {hasConsole(ConsoleEnum.PC) && (
                      <div className={cn(styles.articleHeader__platform, styles.articleHeader__platform__pc)} />
                    )}
                    {hasConsole(ConsoleEnum.Nintendo) && (
                      <div className={cn(styles.articleHeader__platform, styles.articleHeader__platform__nintendo)} />
                    )}
                    {/* hasConsole(ConsoleEnum.Android) && (
                      <div className="game__platform game__platform--android">android</div>
                    ) */}
                    {/* hasConsole(ConsoleEnum.iOS) && (
                      <div className="game__platform game__platform--ios">ios</div>
                    ) */}
                  </div>
                </div>
              </div>
              <div className={cn('row', styles.articleHeader__headerContainer)}>
                <h1 className={styles.articleHeader__header}>{game?.Gamename}</h1>
                <h2>{game?.Gamename} Tournament Bracket</h2>
                <p className={styles.articleHeader__p}>Host your upcoming event using our bracket generator to create your first 
                {game?.Gamename} Tournament Bracket for all your loyal followers.</p>
              </div>
            </div>
            <div className="col-sm-auto col-12 p-0">
              <AddToFavorite gameId={gameId}></AddToFavorite>
            </div>
          </div>
          <Tabs className={styles.articleHeader__tabMenuContainer}>
            <Tabs.Menu>
              <Tabs.Link url={`/game-detail/${gameId}/upcoming`} active={tab === gameTournamentsTabs.upcoming}>Upcoming Tournaments</Tabs.Link>
              <Tabs.Link url={`/game-detail/${gameId}/qualifier`} active={tab === gameTournamentsTabs.qualifier}>Quilifier Tournaments</Tabs.Link>
              <Tabs.Link url={`/game-detail/${gameId}/main`} active={tab === gameTournamentsTabs.main}>Main Tournaments</Tabs.Link>             
            </Tabs.Menu>
          </Tabs>             
        </div>
      </div>
      <div className="row">
        <HeaderButton onClick={handleFilterClick} clasName="article__open-selects">Filter Options</HeaderButton>
      </div>
      <Filters title="Filter Options" opened={filtersModalOpened} close={handleCloseFiltersModal} className={styles.article__selectsPopup}>      
        <FilterContainer title="Organizer" className={styles.articleSelect}>
          <Select
            options={organizers}
            onChange={handleOrganizersChange}
            selected={selectedOrganizers}
            labelExtractor={organizerLabelExtractor}
            placeHolder="All"
            valueExtractor={organizerValueExtractor}
            withSearch
            type="multi"
            loading={organizersLoading}
          />
        </FilterContainer>
        <FilterContainer title="Consoles" className={styles.articleSelect}>
          <Select
            options={consoleOptions}
            onChange={handleChangeConsoles}
            selected={selectedConsoles}
            labelExtractor={(option: IDictionary<ConsoleEnum>) => option.name}
            placeHolder="All"
            valueExtractor={(option: IDictionary<ConsoleEnum>)  => option.id}
            type="multi"
          />
        </FilterContainer>
        <FilterContainer title="Type" className={styles.articleSelect}>
          <Select
            options={TournamentModeOptions}
            onChange={handleChangeMode}
            selected={selectedMode}
            labelExtractor={(option: IDictionary<TournamentModeEnum>) => option.name}
            placeHolder="All"
            valueExtractor={(option: IDictionary<TournamentModeEnum>)  => option.id}
            type="single"
          />
        </FilterContainer>  
        <FilterContainer title="Select time" className={styles.articleSelect}>
          <Select
            options={DateIntervalOptions}
            onChange={handleChangeInterval}
            selected={selectedInterval}
            labelExtractor={(option: IDictionary<DateIntervalEnum>) => option.name}
            placeHolder="All"
            valueExtractor={(option: IDictionary<DateIntervalEnum>)  => option.id}
            type="single"
          />
        </FilterContainer>
        <FilterContainer title="Sort by" className={styles.articleSelect}>
          <Select
            options={TournamentSortFieldOptions}
            onChange={handleChangeSortField}
            selected={selectedSortField}
            labelExtractor={(option: IDictionary<TournamentSortFieldEnum>) => option.name}
            placeHolder="Sort by"
            valueExtractor={(option: IDictionary<TournamentSortFieldEnum>)  => option.id}
            type="single"
          />
        </FilterContainer>
        <FilterContainer title="Region" className={styles.articleSelect}>
          <Select
            options={regions}
            onChange={handleRegionChange}
            selected={selectedRegion}
            labelExtractor={regionLabelExtractor}
            valueExtractor={regionValueExtractor}
            type="single"
            loading={regionsLoading}
            placeHolder="All"
          />
        </FilterContainer>
      </Filters>   

      {tab === gameTournamentsTabs.upcoming && (
        <GameTournamentsUpcoming filters={filters} />
      )}
      {tab === gameTournamentsTabs.main && (
        <GameTournamentsMain filters={filters} />
      )}
      {tab === gameTournamentsTabs.qualifier && (
        <GameTournamentsQualifier filters={filters} />
      )}
    </>
  )
}

export default GameTournaments
