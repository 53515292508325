import { getClanProfileUrl } from 'store/types/clan-types'
import { PlayerType } from 'store/types/common'
import { ParticipantType } from 'store/types/participants-types'
import { getUserProfileUrl } from 'store/types/user-types'
import { useClan } from './useModels/useClan'
import { useParticipant } from './useModels/useParticipant'
import { useUser } from './useModels/useUser'

interface UseTournamentPlayerResult {
  tournamentParticipant?: ParticipantType
  name?: string
  imageId?: number
  profileUrl?: string
  playerType?: PlayerType
}

export const useTournamentPlayer = (tournamentParticipantId: number): UseTournamentPlayerResult => {
  const { tournamentParticipant } = useParticipant(tournamentParticipantId)
  const { user } = useUser(tournamentParticipant?.PlayerId)
  const { clan } = useClan(tournamentParticipant?.ClanId)

  if (clan) {
    return {
      tournamentParticipant,
      name: clan.Name,
      imageId: clan.AvatarId,
      playerType: 'clan',
      profileUrl: getClanProfileUrl(clan.Id),
    }
  }

  if (user) {
    
    return {
      tournamentParticipant,
      name: user.Username,
      imageId: user.AvatarId,
      playerType: 'user',
      profileUrl: getUserProfileUrl(user.Id),
    }
  }

  return {
    tournamentParticipant,
  }
}
