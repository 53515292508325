export enum RoundStatus {
  Pending,
  ChekIn,
  Active,
  Finished,
  Dispute,
}

export const isActiveRoundStatus = (status: RoundStatus) => {
  return status === RoundStatus.ChekIn || status === RoundStatus.Active
}
