import { LoadingBox } from 'components/_shared/Loading'
import { ParticipantAvatar } from 'components/_shared/ParticipantAvatar/PartcipantAvatar'
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { participantsActionCreators } from 'store/logic/participants-logic'
import { makeGetIsParticipantsLoading, makeGetParticipantsByTournamentDetailId } from 'store/selectors/participant-selectors'
import { ApplicationState } from 'store/types/common'
import { cn } from 'utils'

import stylesLight from '../TournamentCardLight.module.scss'
import stylesDark from '../TournamentCardDark.module.scss'
import { ContainerName, useThemeStyles } from 'components/_hooks'

export interface ParticipantsProps {
  tournamentDetailId: number
  maxNumberOfParticipients: number,
  containerName?:ContainerName
}

export const Participants = ({ tournamentDetailId, maxNumberOfParticipients,containerName }: ParticipantsProps) => {

  const styles = useThemeStyles(stylesDark, stylesLight)  

  const dispatch = useDispatch()
  const getParticipants = useMemo(makeGetParticipantsByTournamentDetailId, [])
  const getIsParticipantsLoading = useMemo(makeGetIsParticipantsLoading, [])
  const participants = useSelector((state: ApplicationState) => getParticipants(state, tournamentDetailId))
  const isParticipantsLoading = useSelector((state: ApplicationState) => getIsParticipantsLoading(state, tournamentDetailId))

  const fourParticipants = useMemo(() => {
    return participants.slice(0, 4)
  }, [participants])

  useEffect(() => {
    dispatch(participantsActionCreators.loadParticpants(tournamentDetailId))
  })

  return (
    <div className={cn('col-sm-6', 'col-12', styles.game__signed)}>
      <div className="row align-items-center">
        <LoadingBox size="small" loading={isParticipantsLoading}>
          {fourParticipants.map(x => <ParticipantAvatar containerName={containerName} key={x.Id} participantId={x.Id} />)}
          <span>
            {participants.length}/{maxNumberOfParticipients}&nbsp;
          </span>
          Signed up
        </LoadingBox>
      </div>
    </div>
  )
}
