import { kl_StateModel } from 'models/kl_country-model'
import React, { useEffect, useState } from 'react'
import { CountryUtils } from 'server/utils/country-utils'
import { GeneralUtils } from 'server/utils/general-utils'

interface StateDailogProps {
  countryId: number
  show: boolean
  showStateDailog?: (isShow) => void
  saveState?: (state: kl_StateModel) => void
}

const StateDailog = ({ countryId, show, showStateDailog, saveState }: StateDailogProps) => {
  const [stateModel, setState] = useState<kl_StateModel>(Object())
  const [error, setError] = useState({
    errorStatename: false,
    errorExistingStateName: false,
    errorStateCode: false,
  })

  useEffect(() => {
    clearData()
  }, [show])

  const clearData = () =>{
    setError({
      ...error,
      errorStatename: false ,
      errorExistingStateName:false,
      errorStateCode: false
    })
    setState({...stateModel, StateName:'',StateCode:''})
  }
  
  const addState = async () => {
    if (isDataValid()) {
      const result = await CountryUtils.CheckStateExistsOrNot(countryId, stateModel.StateName)
      if (!result.isError) {
        if (result.data === false) {
          stateModel.CountryId = countryId
          saveState(stateModel)
        }
        else {
          setError({
            ...error,
            errorExistingStateName: true
          })
        }
      }
      else {
        GeneralUtils.showError(result.message)
      }

    }
  }

  const isDataValid = () => {
    let isValid = true
    let isValidStateName = true
    let isValidStateCode = true

    if (stateModel.StateName === undefined || stateModel.StateName === '') {
      isValidStateName = false
      isValid = false
    }

    if (stateModel.StateCode === undefined || stateModel.StateCode === '') {
      isValidStateCode = false
      isValid = false
    }

    setError({
      ...error,
      errorStatename: (isValidStateName ? false : true),
      errorStateCode: (isValidStateCode ? false : true),
    })

    return isValid
  }

  return (
    <>
      <aside className={'bil_modal-container modal-container ' + `${show === true ? 'modal-container--opened' : ''}`} id="modal-add-state">
        <div className="modal-container__background" />
        <div className="bil_modal-container__window modal-container__window window">
          <a
            href="javascript:void(0)"
            className="window__close"
            onClick={() => {
              showStateDailog(false)
            }}
          />
          <div className="window__header-container">
            <div className="row">
              <div className="col-12">
                <div className="bil_modal_hdr">
                  <div className="bil_modal_hdr_i">
                    <h2 className="bil_modal_hdr_ttl">Add State</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="window__container">
            <div className="container-fluid p-0">
              <div className="row overflow-auto">
                <div className="col-md-12 col-12">
                  <div className="row m-0 flex-column">
                    <h4 className="window__subheader">State Name</h4>
                    <input type="text" className="form-control window__input" placeholder="State Name" value={stateModel.StateName} onChange={e => {
                      setState({
                        ...stateModel,
                        StateName: e.target.value
                      })
                    }} />
                  </div>
                  {error && error.errorStatename && (
                    <div className={'article__error' + (error.errorStatename ? ' error-visible' : '')}>
                      <div className="error__text">State name is required.</div>
                    </div>
                  )}
                  {error && error.errorExistingStateName && (
                    <div className={'article__error' + (error.errorExistingStateName ? ' error-visible' : '')}>
                      <div className="error__text">Already exists State name .</div>
                    </div>
                  )}
                  <div className="row m-0 flex-column">
                    <h4 className="window__subheader">State Code</h4>
                    <input type="text" className="form-control window__input" placeholder="State Code" value={stateModel.StateCode} onChange={e => {
                      setState({
                        ...stateModel,
                        StateCode: e.target.value
                      })
                    }} />
                  </div>
                  {error && error.errorStateCode && (
                    <div className={'article__error' + (error.errorStateCode ? ' error-visible' : '')}>
                      <div className="error__text">State code is required.</div>
                    </div>
                  )}
                </div>
                <div className="col-12">
                  <div className="bil_modal_btns">
                    <div className="bil_modal_btns_i">
                      <button
                        className="bttn_blue-outline window__close"
                        type="button"
                        onClick={() => {
                          showStateDailog(false)
                        }}
                      >
                        Close
                        </button>
                    </div>
                    <div className="bil_modal_btns_i">
                      <button
                        className="bttn_blue-primery"
                        type="button"
                        onClick={() => {
                          addState()
                        }}
                      >
                        ADD
                        </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </aside>
    </>

  )
}

export default StateDailog


