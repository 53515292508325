import images from 'images'
import React from 'react'
import styles from './Offline.module.scss'

export const Offline = () => {
  return (
    <div className={styles.offlinePage}>
      <div className={styles.videoContainer}>
        <video autoPlay loop muted>
          <source src={images.awesome} type="video/mp4" />
        </video>
      </div>
      <div className={styles.background}></div>
      <div className={styles.containerFluid}>
        <div className="row">
          <div className={styles.logo}></div>
        </div>
        <div
          className="row justify-content-xl-center justify-content-start h-100 align-items-center flex-column flex-nowrap"
        >
          <h1>Dear Kombatlink subscribers,</h1>
          <p>
            We are temporarily going offline while we work on improvements to our website.
            <br/>Please stay tuned for exciting developments and new features to come!
          </p>
          <p>In the meanwhile, stay safe and healthy during these challenging times!</p>
        </div>
      </div>
    </div>
  )
}
