import React from 'react'
import ReactDatePicker from 'react-datepicker'
import SelectComponent from '../../SelectComponent'

const TimeList: any[] = [
  { text: '12:00 AM', value: 0 },
  { text: '12:30 AM', value: 30 },
  { text: '1:00 AM', value: 60 },
  { text: '1:30 AM', value: 90 },
  { text: '2:00 AM', value: 120 },
  { text: '2:30 AM', value: 150 },
  { text: '3:00 AM', value: 180 },
  { text: '3:30 AM', value: 210 },
  { text: '4:00 AM', value: 240 },
  { text: '4:30 AM', value: 270 },
  { text: '5:00 AM', value: 300 },
  { text: '5:30 AM', value: 330 },
  { text: '6:00 AM', value: 360 },
  { text: '6:30 AM', value: 390 },
  { text: '7:00 AM', value: 420 },
  { text: '7:30 AM', value: 450 },
  { text: '8:00 AM', value: 480 },
  { text: '8:30 AM', value: 510 },
  { text: '9:00 AM', value: 540 },
  { text: '9:30 AM', value: 570 },
  { text: '10:00 AM', value: 600 },
  { text: '10:30 AM', value: 630 },
  { text: '11:00 AM', value: 660 },
  { text: '11:30 AM', value: 690 },
  { text: '12:00 PM', value: 720 },
  { text: '12:30 PM', value: 750 },
  { text: '1:00 PM', value: 780 },
  { text: '1:30 PM', value: 810 },
  { text: '2:00 PM', value: 840 },
  { text: '2:30 PM', value: 870 },
  { text: '3:00 PM', value: 900 },
  { text: '3:30 PM', value: 930 },
  { text: '4:00 PM', value: 960 },
  { text: '4:30 PM', value: 990 },
  { text: '5:00 PM', value: 1020 },
  { text: '5:30 PM', value: 1050 },
  { text: '6:00 PM', value: 1080 },
  { text: '6:30 PM', value: 1110 },
  { text: '7:00 PM', value: 1140 },
  { text: '7:30 PM', value: 1170 },
  { text: '8:00 PM', value: 1200 },
  { text: '8:30 PM', value: 1230 },
  { text: '9:00 PM', value: 1260 },
  { text: '9:30 PM', value: 1290 },
  { text: '10:00 PM', value: 1320 },
  { text: '10:30 PM', value: 1350 },
  { text: '11:00 PM', value: 1380 },
  { text: '11:30 PM', value: 1410 },
]

const getTimeFromDate = (date: Date) => {
  return date.getHours() * 60 + date.getMinutes()
}

interface OwnProps {
  date: Date | null
  onChange: (date: Date) => void
  minDate?: Date | null
  maxDate?: Date | null
  dateContainerClassName?: string
  dateWrapperClassName?: string
  dateLabel: string
  dateLabelClassName: string
  dateClassName?: string
  dateZIndex?: number
  timeLabel: string
  timeLabelClassName?: string
  timeContainerClassName?: string
  timeClass1?: string
  timeClass2?: string
  timeClass3?: string
  timeClass4?: string
  timeZIndex: number,
  showTimePicker?:boolean
}

export const DateTimePicker = ({
  date,
  onChange,
  minDate,
  maxDate,
  dateContainerClassName,
  dateWrapperClassName,
  dateLabel,
  dateLabelClassName,
  dateClassName,
  dateZIndex,
  timeLabel,
  timeLabelClassName,
  timeContainerClassName,
  timeClass1,
  timeClass2,
  timeClass3,
  timeClass4,
  timeZIndex,
  showTimePicker
}: OwnProps) => {
  const handleDateChnage = (value: Date | null) => {
    const newDate = new Date(value.getFullYear(), value.getMonth(), value.getDate(), date.getHours(), date.getMinutes(), 0, 0)
    onChange(newDate)
  }

  const handleTimeChange = (_: string, value: number) => {
    const hours = value / 60
    const minutes = value % 60
    const newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes, 0, 0)
    onChange(newDate)
  }

  return (
    <>
      <div className={dateContainerClassName} style={{ zIndex: dateZIndex }}>
        <div className={dateLabelClassName}>{dateLabel}</div>
        <div className={dateWrapperClassName}>
          <ReactDatePicker
            data-placement="bottom-start"
            className={`opacity-4 ${dateClassName}`}
            selected={date}
            onChange={handleDateChnage}
            minDate={minDate}
            maxDate={maxDate}
            placeholderText="Select Date"
            dateFormat="MMMM d, yyyy"
          />
        </div>
      </div>
      {showTimePicker && <div className={timeContainerClassName} style={{ zIndex: timeZIndex }}>
        <div className={timeLabelClassName}>{timeLabel}</div>
        <SelectComponent
          fieldId="DateTimePicker_time"
          textField="text"
          data={TimeList}
          valueField="value"
          selectedValue={getTimeFromDate(date)}
          onSelectionChange={handleTimeChange}
          zIndex={494}
          placeholder="Select time"
          isImage={false}
          class1={timeClass1}
          class2={timeClass2}
          class3={timeClass3}
          class4={timeClass4}
        />
      </div>}
    </>
  )
}
