import { Pagination, LoadingBox } from 'components/_shared'
import React, { ReactNode, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import { PlayersFiltersDto } from 'store/logic/pages/members-page-logic'
import { GetState } from 'store/types/common'
import { membersPageSize, PlayersFilters } from 'store/types/pages/members-page-types'
import { MembersFilters } from '../MembersFilters'

const mapPlayersFilters = (playersFilters: PlayersFilters): PlayersFiltersDto => {
  return {
    consoles: playersFilters.consoles.map(x => x.id),
    gamesIds: playersFilters.games.map(x => x.Id),
    name: playersFilters.name.length > 2 ? playersFilters.name : '',
    regionsIds: playersFilters.regions.map(x => x.Id),
    pagination: {
      page: playersFilters.page,
      pageSize: membersPageSize,
    },
  }
}

interface MembersTabProps {
  filtersModalOpened: boolean
  closeFiltersModal: () => void
  loadMembers: (dto: PlayersFiltersDto) => (dispatch: Dispatch, getState: GetState) => Promise<void>
  filtersChanged: (payload?: PlayersFilters) => ({ type: string; payload: PlayersFilters })
  children: ReactNode
  playersFilters: PlayersFilters
  allPlayersCount: number
  isMembersLoading: boolean
}

export const MembersTab = ({
  closeFiltersModal,
  filtersModalOpened,
  loadMembers,
  filtersChanged,
  children,
  playersFilters,
  allPlayersCount,
  isMembersLoading,
}: MembersTabProps) => {
  const dispatch = useDispatch()

  const changePlayersFilters = (playersFilters: PlayersFilters) => {
    dispatch(filtersChanged(playersFilters))
  }

  const handlePageChange = (page: number) => {
    changePlayersFilters({
      ...playersFilters,
      page,
    })
  }

  useEffect(() => {
    dispatch(loadMembers(mapPlayersFilters(playersFilters)))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, playersFilters.consoles, playersFilters.games, playersFilters.page, playersFilters.regions])

  useEffect(() => {
    if (playersFilters.name.length === 0 || playersFilters.name.length > 2) {
      dispatch(loadMembers(mapPlayersFilters(playersFilters)))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, playersFilters.name])

  return (
    <>
      <MembersFilters
        closeFiltersModal={closeFiltersModal}
        filtersModalOpened={filtersModalOpened}
        onChange={changePlayersFilters}
        playersFilters={playersFilters}
      />
      <LoadingBox size="big" loading={isMembersLoading}>
        <div className="row m-0--30">
          {children}
        </div>
        <Pagination page={playersFilters.page} itemsPerPage={membersPageSize} allItemsCount={allPlayersCount} onChangePage={handlePageChange} />
      </LoadingBox>
    </>
  )
}
