import * as React from 'react'
import GoogleMapComponent from './GoogleMapComponent'
import GoogleMapReact from 'google-map-react'
import { GeneralUtils } from '../../../../server/utils/general-utils'

interface Props {
  lat: number
  lng: number
  pinText: string
}

class GoogleMap extends React.Component<Props> {
  render() {
    const { lat, lng, pinText } = this.props
    return (
      <>
        {lat && lng && pinText && (
          <div style={{ height: '50vh', width: '100%' }}>
            <GoogleMapReact
              width="400"
              height="400"
              bootstrapURLKeys={{
                key: GeneralUtils.GoogleAPIKey,
              }} /* YOUR KEY HERE */
              defaultCenter={{ lat, lng }}
              defaultZoom={11}
            >
              <GoogleMapComponent lat={lat ? lat : 56.1304} lng={lng ? lng : 106.3468} text={pinText} isMarkerShown />
            </GoogleMapReact>
          </div>
        )}
      </>
    )
  }
}

export default GoogleMap
