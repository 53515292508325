import { TournamentWizard } from 'components/_shared'
import React from 'react'

interface Props {
  tournamentDetailId?: number
  history: any
}

export const CreateTournament = ({ history, tournamentDetailId }: Props) => {
  return (
    <TournamentWizard tournamentDetailId={tournamentDetailId} isAdmin={false} history={history}/>
  )
}
