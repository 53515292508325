

import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { TransactionUtils } from 'server/utils/transaction-utils'
import { getAppUser } from 'store/selectors/app-selectors'
import { cn, defaultFormatDate, round2Places } from 'utils'
import styles from './../Transactions.module.scss'
import { OrganizerWithdrawTransactionsViewModel } from 'models/organizer-transaction-viewmodel'
import { GeneralUtils } from 'server/utils/general-utils'
import { LocationUtils } from 'server/utils/location-utils'

// export interface TransactionWithdrawListProps {
//   AvailableTournament: number
//   CurrencySymbol: string
//   RunningTournament:number
//   PendingTournament:number
// }

const TransactionWithdrawList = () => {
  const[withdrawList,setWithdrawList] = useState<OrganizerWithdrawTransactionsViewModel>()
  const[pageNo,setPageNo] = useState(1)
  const[totalPages,setTotalPages] = useState(0)
  const[AvailableTournament,setAvailableTournament]=useState(0)
  const[CurrencySymbol,setCurrencySymbol]=useState('')
  const[CurrencyCode,setCurrencyCode]=useState('')
  const[RunningTournament,setRunningTournamentTournament]=useState(0)
  const[PendingTournament,setPendingTournamentTournament]=useState(0)
  const[withdrawAmount,setWithdrawAmount]=useState(0)
  const[pages,setPagination]=useState([])
  const user = useSelector(getAppUser)
  
 useEffect(()=>{
    void getWithdawTransactionList(pageNo)
  },[])
  const setPages= useCallback((totalPages:number,pageNo:number)=>{
    setTotalPages(totalPages)
    setPageNo(pageNo)
    setPaginationData(pageNo,totalPages)
  },[]);
  const getCurrencyFromCountry = useCallback(() => {
    (async () => {
    GeneralUtils.showHideLoadingDiv(true)
    const cordinates: any = await LocationUtils.getCoords()
    const userLocation = await LocationUtils.GetLocationInfo(
      "country",
      cordinates.latitude,
      cordinates.longitude
    )
    const result = await TransactionUtils.GetCurrencyDataFromCountry(userLocation.CountryName)
      if (!result?.isError) {
        setCurrencyCode(result?.data.currencyCode)
        setCurrencySymbol(result?.data.currencySymbol)
      } else {
      GeneralUtils.showError(result?.message)
      GeneralUtils.showHideLoadingDiv(false)
    }
    
  })();
  }, []);
  const getWithdawTransactionList = useCallback((pageNo) => {
    (async () => {
      const result = await TransactionUtils.OrganizerWithdrawTransaction(user.Id, pageNo, 4)
      if(!result.isError){
        getCurrencyFromCountry()
        setWithdrawList(result.data)
       
        setAvailableTournament(result.data.AvailableTournament)
        setRunningTournamentTournament(result.data.RunningTournament)
        setPendingTournamentTournament(result.data.PendingTournament)
        setWithdrawAmount(result.data.withdrawAmount)
        if(result.data && result.data.Transactions.length > 0)
        {
        setPages(result.data && result.data.Transactions && result.data.Transactions[0].TotalPages?result.data.Transactions[0].TotalPages:1,result.data && result.data.Transactions && result.data.Transactions[0].PageNo?result.data.Transactions[0].PageNo:1)   
        }
      }
      
    })();
  }, []);
  

  
  const setPaginationData=(pageNo,totalPages)=>{
    const pagesNew = [...pages]
    for (let i = 1; i <= totalPages; i++) {
      if (i == pageNo + 5) {
        pagesNew.push(<div className="article__page-dots">...</div>)
      } else if ((!(i < pageNo - 1) && (i < pageNo + 5 || i == totalPages)) || i == 1) {
        pagesNew.push(
          <a
            className={`article__page ${i == pageNo ? 'article__page--active' : ''}`}
            onClick={() => {
              getWithdawTransactionList(i)
            }}
          >
            {' '}
            {i}
          </a>
        )
      }
      setPagination([...pagesNew])
      
    }
  }
  
  
  
  return(
    
    <>
    
    
    <div className="row">
      <div className="col p-0">
        <h1 className={cn(styles.article__header)}>Withdraw Transactions</h1>
      </div>
    </div>
    <article className={cn(`article ${cn(styles.article__fix)}`)}>
          <div className="container-fluid">
            <div className="row m-0">
              <div className={cn(`col-12 ${cn(styles.article__tab_menu_before_container)} p-0`)}>
                <div className="row m-0 p-0">
                  <div className={cn(`col-md col-12 ${styles.article__tab_menu_container} p-0`)}>
                    <button
                      style={{ marginLeft: '-12px', zIndex: 999 }}
                      onClick={() => {
                        document.getElementById('transactionall').scrollLeft -= 50
                      }}
                      className="article__tab-menu-button article__tab-menu-button--left"
                    />
                    <div id="transactionall" className="row article__tab-menu tab-menu align-items-center">
                      <Link
                        id="tab1"
                        className={`${styles.tab_menu__link} ${
                          window.location.pathname.indexOf('transactions-all') > -1 ? `${styles.tab_menu__link__active}` : ''
                        }`}
                        to="/transactions-all"
                      >
                        All
                      </Link>
                      <Link
                        id="tab2"
                        className={`${styles.tab_menu__link} ${
                          window.location.pathname.indexOf('transactions-running') > -1 ? `${styles.tab_menu__link__active}` : ''
                        }`}
                        to="/transactions-running"
                      >
                        {CurrencySymbol}{RunningTournament ? ' ' +round2Places(RunningTournament,2)+' '+CurrencyCode : ' 0'+' '+CurrencyCode}
                        <span>Running Tournaments</span>
                      </Link>
                      <Link
                        id="tab3"
                        className={`${styles.tab_menu__link} ${
                          window.location.pathname.indexOf('transactions-pending') > -1 ? `${styles.tab_menu__link__active}` : ''
                        }`}
                        to="/transactions-pending"
                      >
                        {CurrencySymbol}{PendingTournament ? ' ' +round2Places(PendingTournament,2)+' '+CurrencyCode: ' 0'+' '+CurrencyCode}
                        <span>Pending Payment</span>
                      </Link><Link
                        id="tab5"
                        className={`${styles.tab_menu__link} ${
                          window.location.pathname.indexOf('/transactions-withdraw-list') > -1 ? `${styles.tab_menu__link__active}` : ''
                        }`}
                        to="/transactions-withdraw-list"
                      >
                        {CurrencySymbol}{withdrawAmount ? ' ' +round2Places(withdrawAmount,2)+' '+CurrencyCode : ' 0'+' '+CurrencyCode}
                        <span>Withdraw Transactions</span>
                      </Link>
                      <Link
                        id="tab4"
                        className={`${styles.tab_menu__link} ${
                          window.location.pathname.indexOf('transactions-available') > -1 ? `${styles.tab_menu__link__active}` : ''
                        }`}
                        to="/transactions-available"
                      >
                        {CurrencySymbol}{AvailableTournament ? ' ' +round2Places(AvailableTournament,2)+' '+CurrencyCode : ' 0'+' '+CurrencyCode}
                        <span>Available</span>
                      </Link>
                      
                    </div>
                    <button
                      style={{ marginRight: '-12px', zIndex: 999 }}
                      onClick={() => {
                        document.getElementById('transactionall').scrollLeft += 50
                      }}
                      className="article__tab-menu-button article__tab-menu-button--right"
                    />
                  </div>
                  
                  
                </div>
              </div>
            </div>
            <div className="col-12 p-0">
            <div className={styles.table__headers_row_all_transaction}>
            <div className={cn(`${styles.table__column} ${styles.table__column__sales}`,'col-md-2 col-lg-2 col-sm-2')}>Requested Date</div>
            <div className={cn(`${styles.table__column} ${styles.table__column__sales}`,'col-md-3 col-lg-3 col-sm-3')}>Requested Withdraw Amount</div>
            <div className={cn(`${styles.table__column} ${styles.table__column__name}`,'col-md-2 col-lg-2 col-sm-2')}>Withdraw Service Charge</div>
              <div className={cn(`${styles.table__column} ${styles.table__column__name}`,'col-md-3 col-lg-3 col-sm-3')}>Received Withdraw Amount</div>
              
              <div className={cn(`${styles.table__column} ${styles.table__column__price}`,'col-md-2 col-lg-2 col-sm-2')}>Status</div>
              
              
            </div>
            </div>
            {withdrawList && withdrawList.Transactions &&
             
             withdrawList.Transactions.map((trans, i) => {
              return (
               <div key={'WT' + i.toString()} className="col-12 p-0">
                <div className={`${styles.table__row} p-0`}>
                    <div className="col-12 p-0">
                      <div className={`${styles.table__main_row} table_main_row`}>
                        <div className={cn(`${styles.table__column} `,'col-md-2 col-lg-2 col-sm-2')}>{defaultFormatDate(trans.RequestedDate)}</div>
                        <div className={cn(`${styles.table__column}`,'col-md-3 col-lg-3 col-sm-3')}>{CurrencySymbol+' '+round2Places(trans.OrignalAmount,2)}</div>
                        <div className={cn(`${styles.table__column}`,'col-md-2 col-lg-2 col-sm-2')}>{CurrencySymbol+' '+round2Places(trans.ServiceFee,2)}</div>
                        <div className={cn(`${styles.table__column}`,'col-md-3 col-lg-3 col-sm-3')}>{CurrencySymbol+' '+round2Places(trans.Amount,2)}</div>
                    
                        <div className={cn(`${styles.table__column}`,'col-md-2 col-lg-2 col-sm-2')}>{trans.Status}</div>
                      </div>
                    </div>
                  </div>
              </div>
              )
            })
            }
            
            {totalPages > 1 && (
              <>
                <div className="row article__hr" />
                <div className="row justify-content-center mtb-30">
                  <div className="col-auto article__pages-container">
                    <div className="row align-items-center">
                      {pageNo > 1 && (
                        <a
                          className="article__prev-page"
                          onClick={async () => {
                            await getWithdawTransactionList(pageNo-1)
                          }}
                        >
                          PREV
                        </a>
                      )}
                      <div className="col-auto">
                        <div className="row align-items-center" id="pages">
                          {pages}
                        </div>
                      </div>
                      {pageNo < totalPages && (
                        <a
                          className="article__next-page"
                          onClick={async() => {
                           await getWithdawTransactionList(pageNo+1)
                          }}
                        >
                          NEXT
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </article>
    </>
  )
}
export default TransactionWithdrawList
