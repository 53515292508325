import { Reducer } from 'redux'
import { defaultPlayerEventsFilters, KnownAction, PlayerEventsPageState, PLAYER_EVENTS_FILTERS_CHANGED, PLAYER_LIVE_TOURNAMENTS_LOADED, PLAYER_ONLINE_TOURNAMENTS_LOADED } from 'store/types/pages/player-events-page-types'

const initialState: PlayerEventsPageState = {
  filters: defaultPlayerEventsFilters,
  allLiveTournamentsCount: 0,
  allOnlineTournamentsCount: 0,
  liveTournamentsIds: [],
  onlineTournamentsIds: [],
}

const reducer: Reducer<PlayerEventsPageState, KnownAction> = (state: PlayerEventsPageState | undefined = initialState, action: KnownAction) => {
  switch (action.type) {
    case PLAYER_EVENTS_FILTERS_CHANGED: {
      console.log(action.payload.filters)
      return {
        ...state,
        filters: action.payload.filters,
      }
    }
    case PLAYER_LIVE_TOURNAMENTS_LOADED: {
      return {
        ...state,
        liveTournamentsIds: action.payload.tournaments.map(x => x.TournamentDetailId),
        allLiveTournamentsCount: action.payload.allPlayerLiveTournamentsCount,
      }
    }
    case PLAYER_ONLINE_TOURNAMENTS_LOADED: {
      return {
        ...state,
        onlineTournamentsIds: action.payload.tournaments.map(x => x.TournamentDetailId),
        allOnlineTournamentsCount: action.payload.allPlayerOnlineTournamentsCount,
      }
    }
    default:
      return state
  }
}

export default reducer
