import React, { ReactNode } from 'react'
import { cn } from 'utils'

interface WindowButtonsContainerProps {
  children: ReactNode
  className?: string
}

export const WindowButtonsContainer = ({ children, className }: WindowButtonsContainerProps) => {
  return <div className={cn('row', 'justify-content-center', className)}>{children}</div>
}
