import { Button } from 'components/_shared'
import React from 'react'
import { useKlHistory } from 'components/_hooks/useKlHistory'

import stylesLight from '../TournamentCardLight.module.scss'
import stylesDark from '../TournamentCardDark.module.scss'
import buttonStyles from 'components/_shared/Button/Button.module.scss'

import { useIsAdminRoute, useTheme, useThemeStyles } from 'components/_hooks'
import { cn } from 'utils'

interface Props {
  tournamentId: number
}

const TournamentCardButton = ({tournamentId}: Props) => {
  const history = useKlHistory()
  const styles = useThemeStyles(stylesDark, stylesLight)  
  const theme = useTheme() 
  const isLightTheme = theme == 'light'
  const isAdminRoute = useIsAdminRoute()
  

  const handleShowDeatail = () => {
    if (isAdminRoute) {
      history.push(`/admin/tournament-detail/${tournamentId}`)
    } else {
      history.push(`/tournament-detail/${tournamentId}`)
    }
  }
  

  return (
    <Button 
      color={(isLightTheme ?  'blue' : 'grey')} 
      filled={!isLightTheme}
      className={cn(styles.game__button, isLightTheme && buttonStyles.blueButtonTransparentFilled) } 
      onClick={handleShowDeatail}
    >
      detail
    </Button>
  )
}

export default TournamentCardButton
