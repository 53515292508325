import { useAppSelector } from 'components/_hooks'
import { useMemo } from 'react'
import { makeGetChatMessage, makeGetChatMessagesIds, makeGetIsChatMessagesLoading, makeGetIsSendChatMessageLoading } from './chat-message-selectors'

export const useChatMessagesIds = (chatId: number) => {
  const getChatMessagesIds = useMemo(makeGetChatMessagesIds, [])
  return useAppSelector(state => getChatMessagesIds(state, { chatId }))
}

export const useChatMessage = (chatMessageId: number) => {
  const getChatMessage = useMemo(makeGetChatMessage, [])
  return useAppSelector(state => getChatMessage(state, { chatMessageId }))
}

export const useIsSendChatMessageLoading = (chatId: number) => {
  const getIsSendChatMessageLoading = useMemo(makeGetIsSendChatMessageLoading, [])
  return useAppSelector(state => getIsSendChatMessageLoading(state, { chatId }))
}

export const useIsChatMessagesLoading = (chatId: number) => {
  const getIsChatMessagesLoading = useMemo(makeGetIsChatMessagesLoading, [])
  return useAppSelector(state => getIsChatMessagesLoading(state, { chatId }))
}
