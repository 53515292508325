import { useAppSelector, useFilters, useUnionMatchParam } from 'components/_hooks'
import { ArticleHeader, SearchInput, Tabs, HeaderButton, Filters, FilterContainer, DateInput, DropDownTable, Select, useDropDownTableStyles } from 'components/_shared'
import React, { useEffect, useMemo, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { addDays, cn } from 'utils'
import styles from './Events.module.scss'
import './Events-common.scss'
import { organizerLabelExtractor, organizerValueExtractor, PublicUserType } from 'store/types/user-types'
import { gameLabelExtractor, GameType, gameValueExtractor } from 'store/types/game-types'
import { regionLabelExtractor, RegionType, regionValueExtractor } from 'store/types/region-types'
import { useDispatch, useSelector } from 'react-redux'
import { TournamentFilters } from 'store/logic/tournaments-logic'
import { getAppUser } from 'store/selectors/app-selectors'
import { VenueTypeEnum } from 'consts'
import { loadActiveMatches, loadCheckInMatches, loadFinishedMatches, loadPendingMatches } from 'store/logic/match-logic'
import { LiveEvents } from './LiveEvents'
import { getPlayerEventsPageFilters } from 'store/selectors/pages/player-events-page-selectors'
import { playerEventsFiltersChanged } from 'store/types/pages/player-events-page-types'
import { OnlineEvents } from './OnlineEvents'

export const eventsTabs = {
  live: 'live',
  online: 'online',
  challenge: 'challenge',
}

export type EventsTab = keyof typeof eventsTabs

interface EventsRouteProps {
  tab: EventsTab
  id?: string
}

export type EventsProps = RouteComponentProps<EventsRouteProps>

export const Events = ({ match }: EventsProps) => {
  const dropDownTableStyles = useDropDownTableStyles()
  const dispatch = useDispatch()
  
  const storedFilters = useAppSelector(state => getPlayerEventsPageFilters(state))

  const tab = useUnionMatchParam(eventsTabs, match, m => m.tab, storedFilters.currentTab)

  const [filtersModalOpened, setFiltersModalOpened] = useState(false)
  const name = storedFilters.name
  const selectedOrganizers = storedFilters.selectedOrganizers
  const selectedGames = storedFilters.selectedGames
  const dateFrom = storedFilters.dateFrom
  const dateTo = storedFilters.dateTo
  const selectedRegion = storedFilters.selectedRegion
  const { games, gamesLoading, organizers, organizersLoading, regions, regionsLoading } = useFilters()
  
  const user = useSelector(getAppUser)

  useEffect(() => {
    if (tab !== storedFilters.currentTab) {
      dispatch(playerEventsFiltersChanged(
        {
          filters: {
            ...storedFilters,
            currentPage: 0,
            currentTab: tab,           
          },
        }
      ))
    }
  }, [storedFilters, dispatch, tab])


  //#region vars
  const filters = useMemo<TournamentFilters | undefined>(() => {
    return {
      userId: user?.Id,
      venueTypes: [tab === 'online' ? VenueTypeEnum.Online : VenueTypeEnum.Live],
      organizersIds: selectedOrganizers.filter(x => x.Id !== 0).map(x => x.Id),
      gamesIds: selectedGames.map(x => x.Id),
      regionsIds: selectedRegion ? [selectedRegion.Id] : [],
      from: dateFrom,
      to: dateTo && addDays(dateTo, 1),
      name,
      kombatlinkOrganizer: selectedOrganizers.some(x => x.Id === 0),
      tournamentStructures: [],
    }
  }, [dateFrom, dateTo, name, selectedGames, selectedOrganizers, selectedRegion, user, tab])
  //#endregion

  //#region handlers


  const handleClick = () => {
    setFiltersModalOpened(true)
  }

  const handleCloseFiltersModal = () => {
    setFiltersModalOpened(false)
  }

  const handleNameChange = (value: string) => {
    dispatch(playerEventsFiltersChanged(
      {
        filters: {
          ...storedFilters,
          currentPage: 0,
          name: value,
        },
      }
    ))
  }

  const handleOrganizersChange = (value: PublicUserType[]) => {
    dispatch(playerEventsFiltersChanged(
      {
        filters: {
          ...storedFilters,
          currentPage: 0,
          selectedOrganizers: value,
        },
      }
    ))
  }

  const handleGamesChange = (value: GameType[]) => {
    dispatch(playerEventsFiltersChanged(
      {
        filters: {
          ...storedFilters,
          currentPage: 0,
          selectedGames: value,
        },
      }
    ))
  }

  const handleRegionChange = (value: RegionType) => {
    dispatch(playerEventsFiltersChanged(
      {
        filters: {
          ...storedFilters,
          selectedRegion: value,          
        },
      }
    ))
  }

  const handleDateFromChange = (value: Date) => {
    dispatch(playerEventsFiltersChanged(
      {
        filters: {
          ...storedFilters,
          dateFrom: value,          
        },
      }
    ))
  }

  const handleDateToChange = (value: Date) => {
    dispatch(playerEventsFiltersChanged(
      {
        filters: {
          ...storedFilters,
          dateTo: value,          
        },
      }
    ))
  }
  //#endregion

  useEffect(() => {
    dispatch(loadPendingMatches)
    dispatch(loadCheckInMatches)
    dispatch(loadActiveMatches)
    dispatch(loadFinishedMatches)
  }, [dispatch])

  return (
    <>
      <div className={cn(dropDownTableStyles.dropDownTableMediaQueries, styles.eventsDropDownTableMediaQueries)}>
        <ArticleHeader text="My Events">
          <HeaderButton onClick={handleClick}>Filter Options</HeaderButton>
        </ArticleHeader>
        <div className="row m-0--30">
          <div className="col-12">
            <Tabs className={styles.articleTabMenuBeforeContainer}>
              <Tabs.Menu>
                <Tabs.Link url="/events/live" active={tab === eventsTabs.live}>Live</Tabs.Link>
                <Tabs.Link url="/events/online" active={tab === eventsTabs.online}>Online</Tabs.Link>
                {/* <Tab.Link url="/events/challenge" active={tab === eventsTabs.challenge}>Challenges</Tab.Link> */}
              </Tabs.Menu>
            </Tabs>
          </div>
        </div>
        <Filters title="Filter Options" opened={filtersModalOpened} close={handleCloseFiltersModal} className={styles.articleSelectsPopup}>
          <FilterContainer title="Name" className={styles.articleSelect}>
            <SearchInput onChange={handleNameChange} placeholder="Search by name" value={name} />
          </FilterContainer>
          <FilterContainer title="Organizer" className={styles.articleSelect}>
            <Select
              options={organizers}
              onChange={handleOrganizersChange}
              selected={selectedOrganizers}
              labelExtractor={organizerLabelExtractor}
              placeHolder="All"
              valueExtractor={organizerValueExtractor}
              withSearch
              type="multi"
              loading={organizersLoading}
            />
          </FilterContainer>
          <FilterContainer title="Game" className={styles.articleSelect}>
            <Select
              options={games}
              onChange={handleGamesChange}
              selected={selectedGames}
              labelExtractor={gameLabelExtractor}
              placeHolder="All Games"
              valueExtractor={gameValueExtractor}
              type="multi"
              loading={gamesLoading}
              withSearch
            />
          </FilterContainer>
          <FilterContainer title="Date from" className={styles.articleSelect}>
            <DateInput onChange={handleDateFromChange} value={dateFrom} />
          </FilterContainer>
          <FilterContainer title="Date to" className={styles.articleSelect}>
            <DateInput onChange={handleDateToChange} value={dateTo} />
          </FilterContainer>
          <FilterContainer title="Region" className={styles.articleSelect}>
            <Select
              options={regions}
              onChange={handleRegionChange}
              selected={selectedRegion}
              labelExtractor={regionLabelExtractor}
              valueExtractor={regionValueExtractor}
              type="single"
              loading={regionsLoading}
              placeHolder="All"
            />
          </FilterContainer>
        </Filters>

        {tab == eventsTabs.live && (
          <LiveEvents filters={filters} />
        )}   
        {tab == eventsTabs.online && (
          <OnlineEvents filters={filters} />
        )}     

      </div>
    </>
  )
}
