import { ChatExternalHistoryContext } from 'components/_shared/Chat/ChatWindow/ChatWindow'
import React, { ReactNode, useContext } from 'react'
import { Link } from 'react-router-dom'
import styles from './ChatMenuLink.module.scss'

export interface ChatMenuLinkProps {
  children: ReactNode
  to?: string
  onClick?: () => void
  external?: boolean
}

export const ChatMenuLink = ({ children, to = '#', onClick, external }:  ChatMenuLinkProps) => {
  const history = useContext(ChatExternalHistoryContext)

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (external || to === '#' || onClick) {
      event.preventDefault()

      if (external) {
        history.push(to)
        return
      }
      
      if (onClick) {
        onClick()
      }
    }
  }

  return (
    <Link className={styles.chatMenuLink} to={to} onClick={handleClick}>{children}</Link>
  )
}
