import { Modal } from 'components/_modals'
import { isTwoPlayersStructure, TournamentStructureEnum } from 'consts'
import React, { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAddDisputeDetail, useUpdateDisputeDetail } from 'store/logic/dispute-detail-logic'
import { matchesSumbissionsActionCreators } from 'store/logic/match-submissions-logic'
import { DisputeDetailType } from 'store/types/dispute-details-types'
import styles from '../Disputes.module.scss'

export interface DisputeDetailModalParams {
  participantName: string
  disputeDetail: DisputeDetailType
}

export interface DisputeDetailModalProps extends DisputeDetailModalParams {
  closeDialog: CallableFunction
  tournamentStructure: TournamentStructureEnum
  matchParticipantsLength: number
  matchId: number
  readonly?: boolean
}

export const DisputeDetailModal = ({
  closeDialog,
  participantName,
  disputeDetail,
  tournamentStructure,
  matchParticipantsLength,
  matchId,
  readonly,
}: DisputeDetailModalProps) => {
  const dispatch = useDispatch()
  const [comment, setComment] = useState(disputeDetail.Comment || '')
  const [place, setPlace] = useState(disputeDetail.Place)
  const updateDisputeDetail = useUpdateDisputeDetail(disputeDetail.Id)
  const addDisputeDetail = useAddDisputeDetail(disputeDetail.DisputeId)

  const isTwoPlayers = isTwoPlayersStructure(tournamentStructure)

  const asyncOperation = async () => {
    if (disputeDetail.Id) {
      await updateDisputeDetail(disputeDetail.DisputeId, {
        comment,
        place: isTwoPlayers ? 1 : place,
      })
    } else {
      await addDisputeDetail({
        comment,
        place: isTwoPlayers ? 1 : place,
        matchParticipantId: disputeDetail.MatchParticipantId,
      })
    }
    dispatch(matchesSumbissionsActionCreators.loadMatchSubmissions(matchId))
    return true
  }

  const handleCommentChange = (value: string) => {
    setComment(value)
  }

  const placementOptions = useMemo(() => {
    return Array(matchParticipantsLength)
      .fill(0)
      .map((x, ind) => ind + 1)
  }, [matchParticipantsLength])

  const keyValueExtractor = (option: number) => option

  return (
    <Modal
      onClose={closeDialog}
      asyncOperation={asyncOperation}
      className={styles.window}
      buttonColor="blue"
      buttonClassName={styles.windowButton}
      allowOk={isTwoPlayers || place > 0}
      hideOk={readonly}
    >
      <Modal.Header className={styles.windowHeader}>
        {isTwoPlayers ? (
          <>
            Do you want to make <span className="color-blue">{participantName}</span> the winner?
          </>
        ) : (
          <>
            Please select placement for <span className="color-blue">{participantName}</span>
          </>
        )}
      </Modal.Header>
      {!isTwoPlayers && (
        <Modal.Select
          options={placementOptions}
          keyExtractor={keyValueExtractor}
          valueExtractor={keyValueExtractor}
          onChange={setPlace}
          selectedOption={place}
          disabled={readonly}
        />
      )}
      <Modal.SubHeader className={styles.windowSubheader}>You may enter a reason for decision here</Modal.SubHeader>
      <Modal.Textarea onChange={handleCommentChange} value={comment} placeholder="Enter your comment here..." disabled={readonly} />
    </Modal>
  )
}
