import { ReactNode } from 'react'
import { cn } from 'utils'
import commonStyles from './styles/DropDownTableCommon.module.scss'
import { useDropDownTableStyles } from './styles/useDropDownTableStyles'

export type DropDownTableLabelColor = 'grey' | 'green' | 'red' | 'orange'

const classByColor = {
  green: commonStyles.green,
  red: commonStyles.red,
  orange: commonStyles.orange,
  grey: commonStyles.grey,
}

export interface DropDownTableLabelProps {
  children: ReactNode
  color: DropDownTableLabelColor
}

export const DropDownTableLabel = ({ children, color }: DropDownTableLabelProps) => {
  const styles = useDropDownTableStyles()

  return <div className={cn(styles.tableLabel, classByColor[color])}>{children}</div>
}
