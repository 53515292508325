import { TournamentViewModel_kl } from 'models/tournament-viewmodel'
import React from 'react'
import { Link } from 'react-router-dom'
import styles from './Slider.module.scss'
import { cn, fullFormatDate } from 'utils'

interface Props {
  tournament: TournamentViewModel_kl
}

const SliderTournamentInfo = ({tournament}: Props) => {
  return (
    <div
      className={cn(styles.sldrCarI, styles.slickSlide, styles.slickCurrent, styles.slickActive)}
      data-slick-index="0"
      aria-hidden="false"
      tabIndex={0}
      role="tabpanel"
      id="slick-slide00"
      aria-describedby="slick-slide-control00"
      style={{
        position: 'relative',
        left: '0px',
        top: '0px',
        float: 'inherit',
        zIndex: 999,
        opacity: 1,
      }}
    >
      <div className={cn(styles.sldrInf)}>
        <div className={styles.sldrInfInn}>
          <div className={styles.sldrDetailsInf}>
            <div className={styles.sldrInfInfo}>
              <div className={styles.sldrInfInfoL}>
                <span className={styles.sldrInfDate}>
                  {tournament?.StartDate && fullFormatDate(tournament.StartDate)}
                </span>
              </div>
              <div className={styles.sldrInfInfoR}>
                <span className={styles.sldrInfPlace}>{tournament && tournament.Region}</span>
              </div>
            </div>
          </div>
          <h2 className={styles.sldrInfTtl}>{tournament && tournament.Name}</h2>
          <div className={styles.sldrInfBtnWrap}>
            <Link to={`/tournament-detail/${tournament.Id}`} className={styles.sldrInfBtn} tabIndex={0}>
              Go to event
            </Link>
          </div>
          <div className={styles.sldrInfAuthor}>
            <span>Hosted by</span>
            <a href="#" tabIndex={0}>
              {tournament && tournament.Organizerid == 0 ? 'Kombatlink' : tournament.Organizationname}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SliderTournamentInfo
