import { Reducer } from 'redux'
import { BlogState, KnownAction, BLOGS_LOADED, BlogType, BLOG_BY_ID_LOADED, UNREAD_BLOGS_COUNT_LOADED, BlogSectionType, BLOG_UPDATED, BLOG_ADDED } from 'store/types/blog-types'
import { arrayToObject, groupArrayToObject } from 'utils'

export const initialBlogsState: BlogState = {
  byId: {},
  ids: [],
  allBlogsCount: 0,
  unreadCount: 0,
  readBlogsIds: [],
  blogSectionById: {},
  blogSectionsIdsByBlogId: {},
}

export const calcBlogsById = (state: BlogState, models: BlogType[]): { [id: number]: BlogType } => {
  return {
    ...state.byId,
    ...arrayToObject(models, x => x.Id),
  }
}

export const calcBlogSectionsById = (state: BlogState, models: BlogSectionType[]): { [id: number]: BlogSectionType } => {
  return {
    ...state.blogSectionById,
    ...arrayToObject(models, x => x.Id),
  }
}

const reducer: Reducer<BlogState, KnownAction> = (state: BlogState | undefined = initialBlogsState, action: KnownAction) => {
  switch (action.type) {
    case BLOGS_LOADED:
    {
      return {
        ...state,
        byId: calcBlogsById(state, action.payload.Blogs),
        ids: action.payload.Blogs.map(x => x.Id),
        allBlogsCount: action.payload.AllBlogsCount,
        readBlogsIds: action.payload.ReadBlogsIds,
        blogSectionById: calcBlogSectionsById(state, action.payload.BlogSections),
        blogSectionsIdsByBlogId: {
          ...state.blogSectionsIdsByBlogId,
          ...groupArrayToObject(action.payload.BlogSections, key => key.BlogId, res => res.Id),
        },
      }
    }
    case BLOG_BY_ID_LOADED:
    case BLOG_ADDED:
    case BLOG_UPDATED: {
      return {
        ...state,
        byId: calcBlogsById(state, action.payload.Blogs),
        blogSectionById: calcBlogSectionsById(state, action.payload.BlogSections),
        blogSectionsIdsByBlogId: {
          ...state.blogSectionsIdsByBlogId,
          ...groupArrayToObject(action.payload.BlogSections, key => key.BlogId, res => res.Id),
        },
      }
    }
    case UNREAD_BLOGS_COUNT_LOADED: {
      return {
        ...state,
        unreadCount: action.payload,
      }
    }
    default:
      return state
  }
}

export default reducer
