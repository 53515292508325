import { ContainerName, useGetImageSize } from 'components/_hooks'
import { Img, KlLink } from 'components/_shared'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { makeGetGame } from 'store/selectors/game-selectors'
import { ApplicationState } from 'store/types/common'
import styles from '../Home.module.scss'

interface Props {
  id: number
}

export const GameCard = ({ id }: Props) => {
  const getGame = useMemo(makeGetGame, [])

  const game = useSelector((state: ApplicationState) => getGame(state, { gameId: id }))
  const gameContainerSize = useGetImageSize(ContainerName.GamesImagesCard)
  return (
    <>
      <div className={styles.item}>
        <KlLink to={`/game-detail/${game?.Id}`}>
          <div className={styles.gPoster}>
            <Img parentContainerSize={gameContainerSize} alt={game?.Gamename} imageId={game?.ImageId} src={!game?.ImageId ? `/images/games/${game?.Id}.jpg` : undefined}/>
          </div>
          <div className={styles.gInfo}>
            <span className={styles.gname}>{game?.Gamename}</span>
          </div>
        </KlLink>
      </div>
    </>
  )
}
