import { useTheme } from 'components/_hooks'
import styles from './Button.module.scss'
import stylesLight from './ButtonLight.module.scss'

export type ButtonColor = 'blue' | 'blueNoGradient' | 'green' | 'red' | 'orange' | 'pink' | 'purple' | 'grey' | 'white' | 'darkGrey' | 'gold'

export type ButtonHoverColor = 'greenHover' | 'redHover'

export const DarkButtonClassByColor = {
  blue: styles.blueButton,
  blueNoGradient: styles.blueButton,
  green: styles.greenButton,
  red: styles.redButton,
  orange: styles.orangeButton,
  pink: styles.pinkButton,
  purple: styles.purpleButton,
  grey: styles.greyButton,
  white: styles.whiteButton,
  darkGrey: styles.darkGreyButton,
  gold: styles.goldButton,
}

export const LightButtonClassByColor = {
  blue: stylesLight.blueButton,
  blueNoGradient: stylesLight.blueButton,
  green: stylesLight.greenButton,
  red: stylesLight.redButton,
  orange: stylesLight.orangeButton,
  pink: stylesLight.pinkButton,
  purple: stylesLight.purpleButton,
  grey: stylesLight.greyButton,
  white: stylesLight.whiteButton,
  darkGrey: stylesLight.darkGreyButton,
  gold: stylesLight.goldButton,
}

export const useButtonClassByColor = (buttonColor: ButtonColor | undefined) => {
  const theme = useTheme()
  
  if (!buttonColor) {
    return undefined
  }

  if (theme === 'dark') {
    return DarkButtonClassByColor[buttonColor]
  }

  return LightButtonClassByColor[buttonColor]
}

export const useFilledButtonClassByColor = (buttonColor: ButtonColor | undefined) => {
  const theme = useTheme()
  
  if (!buttonColor) {
    return undefined
  }

  if (theme === 'dark') {
    return filledClassByColor[buttonColor]
  }

  return filledLigthClassByColor[buttonColor]
}

export const ButtonHoverClassByColor = {
  greenHover: styles.greenHoverButton,
  redHover: styles.redHoverButton,
}

export const filledClassByColor = {
  blue: styles.blueButtonFilled,
  blueNoGradient: styles.blueButtonFilledNoGradient,
  green: styles.greenButtonFilled,
  red: styles.redButtonFilled,
  orange: styles.orangeButtonFilled,
  pink: styles.pinkButtonFilled,
  purple: styles.pinkButtonFilled,
  grey: styles.greyButtonFilled,
  darkGrey: styles.darkGreyButtonFilled,
  gold: styles.goldButtonFilled,
  white: stylesLight.whiteButton,
}

export const filledLigthClassByColor = {
  blue: stylesLight.blueButtonFilled,
  blueNoGradient: stylesLight.blueButtonFilledNoGradient,
  green: stylesLight.greenButtonFilled,
  red: stylesLight.redButtonFilled,
  orange: stylesLight.orangeButtonFilled,
  pink: stylesLight.pinkButtonFilled,
  purple: stylesLight.pinkButtonFilled,
  grey: stylesLight.greyButtonFilled,
  darkGrey: stylesLight.darkGreyButtonFilled,
  gold: stylesLight.goldButtonFilled,
  white: stylesLight.whiteButton,
}
