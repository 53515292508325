import { useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import { OperationTypes, run, useRun, useRunWithResult } from 'store/logic/operations-logic'
import { GetState } from 'store/types/common'
import { fetchApi } from 'utils'
import { getIsChatsRequested } from '../chat/chat-selectors'
import { ChatsListModel, chatsLoaded } from '../chat/chat-types'
import { ChatMessageModel, chatMessageRead, chatMessagesLoaded, ChatMessagesModel, ChatsUnreadsModel, newChatMessageLoaded, unreadChatsLoaded } from './chat-message-types'

export const useLoadChatMessages = (chatId: number,end:number,fetchMessagePerRequest:number) => {
  const dispatch = useDispatch()
  return useRunWithResult(OperationTypes.loadChatMessages, chatId, async (end,fetchMessagePerRequest) => {
    const model = await fetchApi<ChatMessageModel[]>(`chatmessage/list/${chatId}/${end}/${fetchMessagePerRequest}`, 'GET')
    dispatch(chatMessagesLoaded({
      chatId: chatId,
      messages: model,
    }))

    return model
  })
}

export const useSendChatMessage = (chatId: number) => {
  return useRun(OperationTypes.sendChatMessage, chatId, async (text: string) => {
    await fetchApi<ChatMessageModel>(`chatmessage/${chatId}`, 'POST', text)
    return true
  })
}

export const loadNewChatMessage = (chatId: number, messageId: number) => async (dispatch: Dispatch, getState: GetState) => {
  await run(OperationTypes.loadNewMessage, null,  dispatch, getState, async () => {
    try {
      const model = await fetchApi<ChatMessagesModel>(`chatmessage/${chatId}/${messageId}`, 'GET')
      dispatch(newChatMessageLoaded(model))      
    } catch {
      return false
    }
    return true
  })
}


export const loadUnreadChats = () => async (dispatch: Dispatch, getState: GetState) => {
  await run(OperationTypes.loadUnreadChats, null,  dispatch, getState, async () => {
    try {
      const model = await fetchApi<ChatsUnreadsModel>('chat/messages/unread', 'GET')
      dispatch(unreadChatsLoaded(model))      
    } catch {
      return false
    }
    return true
  })
}


export const loadChats = () => async (dispatch: Dispatch, getState: GetState) => {
  // if (getIsChatsRequested(getState())) {
  //   return
  // }
  await run(OperationTypes.loadChats, null,  dispatch, getState, async () => {
    try {
      const model = await fetchApi<ChatsListModel>('chat/list', 'GET')
      dispatch(chatsLoaded(model))      
    } catch {
      return false
    }
    return true
  })
}


export const messageRead = (chatId: number, messageId: number) => async (dispatch: Dispatch, getState: GetState) => {
  await run(OperationTypes.chatMessageRead, null,  dispatch, getState, async () => {
    try {
      await fetchApi(`chatmember/read/${chatId}/${messageId}`, 'POST')  
      dispatch(chatMessageRead({
        ChatId: chatId,
        ChatMessageId: messageId
      })) 
    } catch {
      return false
    }
    return true
  })
}
