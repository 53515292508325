import { useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import { OperationTypes, run, useRun, useRunWithResult } from 'store/logic/operations-logic'
import { GetState } from 'store/types/common'
import { FriendsFilters } from 'store/types/pages/friends-page-types'
import { ListUserResultModel } from 'store/types/user-types'
import { fetchApi } from 'utils'
import { privateChatMembersLoaded, ChatMembersModel, chatFilteredUsersLoaded, chatLeft, AddToChatModel, ChatMembersPayload, chatMembersAdded } from './chat-member-types'

export const useLoadPrivateChatMembers = (userId: number) => {
  const dispatch = useDispatch()
  return useRunWithResult(OperationTypes.loadPrivateChatMembers, userId, async () => {
    const model = await fetchApi<ChatMembersModel>(`chatMember/private/${userId}`, 'GET')
    dispatch(privateChatMembersLoaded(model))
    return model
  })
}

export const useCreatePrivateChat = (userId: number) => {
  const dispatch = useDispatch()
  return useRunWithResult(OperationTypes.createPrivateChat, userId, async () => {
    const model = await fetchApi<ChatMembersModel>(`chat/private/${userId}`, 'POST')
    dispatch(privateChatMembersLoaded(model))
    return model
  })
}


export const useLeaveChat = (chatId: number) => {
  const dispatch = useDispatch()
  return useRun(OperationTypes.leaveChat, chatId, async () => {
    try {
      await fetchApi(`chatmember/${chatId}/leave`, 'POST')
      dispatch(chatLeft(chatId))
    } catch {
      return false
    }
    return true
  })
}


export const loadChatFilteredMembers = (userId: number, name: string) => async (dispatch: Dispatch, getState: GetState) => {
  await run(OperationTypes.loadChatFilteredUsers, null, dispatch, getState, async () => {
    try {    
      const filters: FriendsFilters = {
        userId: userId,
        name: name,
        withClan: true,
      }      
      const model = await fetchApi<ListUserResultModel>('chatmember/members', 'POST', {
        filters: filters,
      })
      if (model) {
        dispatch(chatFilteredUsersLoaded(model.UsersModel))
      }
    } catch {
      return false
    }

    return true
  })
}

export const addToChat = (model: AddToChatModel)  => async (dispatch: Dispatch, getState: GetState) => {
  await run(OperationTypes.addToChat, null , dispatch, getState, async () => {
    try {
      const result = await fetchApi<ChatMembersPayload>('chatmember/add', 'POST', model)
      dispatch(chatMembersAdded(result))
    } catch {
      return false
    }

    return true
  })
}

export const blockMember = (memberId: number) => async (dispatch: Dispatch, getState: GetState) => {
  await run(OperationTypes.blockMember, null , dispatch, getState, async () => {
    try {
      await fetchApi(`chatmember/${memberId}/block`, 'POST')
    } catch {
      return false
    }

    return true
  })
}

export const unblockMember = (memberId: number) => async (dispatch: Dispatch, getState: GetState) => {
  await run(OperationTypes.blockMember, null , dispatch, getState, async () => {
    try {
      await fetchApi(`chatmember/${memberId}/unblock`, 'POST')
    } catch {
      return false
    }

    return true
  })
}
