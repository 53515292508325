import {
  DropDownTableColumn,
  HeadersRowButtonsColumn,
  MainRowButtonsColumn,
  SecondaryRowButtonsColumn,
  DropDownTableRowColumn,
  DropDownTableSecondaryRowColumn,
} from './DropDownTableColumns'
import { DisputeRow } from './DisputeRow/DisputeRow'
import { DisputeParticipantRow } from './DisputeParticipantRow'

const DisputeDropDownTable = {
  Column: DropDownTableColumn,
  RowColumn: DropDownTableRowColumn,
  SecondaryRowColumn: DropDownTableSecondaryRowColumn,
  HeadersRowButtonsColumn,
  MainRowButtonsColumn,
  SecondaryRowButtonsColumn,
  DisputeRow,
  DisputeParticipantRow,
}

export { DisputeDropDownTable }
