import { ChatMemberStatus } from 'consts'
import React, { useMemo } from 'react'
import { useChatMembers } from 'store/chat/chat-member/chat-member-hooks'
import { useChat } from 'store/chat/chat/chat-hooks'
import { ChatRelationTypeEnum } from 'store/chat/chat/chat-types'
import { ChatContainer, ChatHeader, ChatHr, ChatMember, ChatSubheader, ChatTransparentLabel } from '../../_shared'


export const AboutChat = () => {

  const {chat} = useChat()
  const chatMembers = useChatMembers(chat.Id)
  const members = useMemo(() => chatMembers.filter(cm => cm.Status == ChatMemberStatus.Active || cm.Status == ChatMemberStatus.Blocked), [chatMembers])

  const chatNameHeader = useMemo(() => {
    if (chat && (chat.RelationType == ChatRelationTypeEnum.Tournament || chat.RelationType == ChatRelationTypeEnum.Dispute)) {
      return 'Tournament name'
    }
    return 'Chat name'
  }, [chat])

  const chatName = useMemo(() => {
    if (!chat) {
      return 'Chat'
    }
    return chat.Name
  }, [chat])

  return (
    <>
      <ChatHeader>
        <ChatHeader.BackButton />
        <ChatHeader.Container>About chat</ChatHeader.Container>
      </ChatHeader>
      <ChatContainer>
        <div className="container-fluid">
          <ChatTransparentLabel>{chatNameHeader}</ChatTransparentLabel>
          <ChatSubheader className="m-b-0">{chatName}</ChatSubheader>
          <ChatHr />
          <ChatSubheader>
            {'Members '}
            <span className="opacity-5">{`(${members.length})`}</span>
          </ChatSubheader>
        </div>
        {members.map(cm => (
          <ChatMember key={cm.Id} chatMemberId={cm.Id} hasLink />
        ))}       
      </ChatContainer>
    </>
  )
}
