import { RoundStatus, TournamentStatusEnum } from 'consts'
import { Permission } from 'store/types/common'
import { MatchStatusEnum } from 'store/types/match-types'
import { useMatch, useTournament } from './useModels'
import { useTournamentPermission } from './usePermissions'

export const useCanManageMatch = (matchId?: number) => {
  const { match, round, tournament } = useMatch(matchId)
  const tournamentPermission = useTournamentPermission(tournament?.TournamentDetailId)

  const canManageBracket =
    tournament?.TournamentStatusId === TournamentStatusEnum.Active
    && round?.Status === RoundStatus.Active
    && match?.Status === MatchStatusEnum.Pending
    && tournament?.IsManualControl
    && tournamentPermission === Permission.write
  
  return canManageBracket
}

export const useCanManageTournament = (tournamentDetailId?: number) => {
  const { tournament } = useTournament(tournamentDetailId)
  const tournamentPermission = useTournamentPermission(tournamentDetailId)

  const canManageBracket =
    tournament?.TournamentStatusId === TournamentStatusEnum.Active
    && tournament?.IsManualControl
    && tournamentPermission === Permission.write
  
  return canManageBracket
}
