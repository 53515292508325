import * as React from 'react'
import { PaymentMethodModel, PricePlanModel } from '../../models/billing-option-model'
import { StripeUtils } from '../../server/utils/stripe-utils'
import { UserModel } from '../../models/user-models'
import { GeneralUtils } from '../../server/utils/general-utils'
import { Redirect } from 'react-router'
import { withStripe } from 'components/_shared/withStripe'
import config from '../../config'
import { LocationUtils } from 'server/utils/location-utils'
const stripeElements = require('react-stripe-elements')
const CardNumberElement = stripeElements.CardNumberElement
const CardExpiryElement = stripeElements.CardExpiryElement
const CardCvcElement = stripeElements.CardCvcElement

interface State {
  paymentMethod: PaymentMethodModel
  isAgreed: boolean
  planPrice: PricePlanModel
  isRedirect: boolean
  returnBack: boolean
  userCountry: string
  userCountryCode: string
}

class UpgradeAccount extends React.Component<any, State> {
  constructor(props: any) {
    super(props)
    this.state = {
      paymentMethod: Object(),
      isAgreed: false,
      planPrice: Object(),
      isRedirect: false,
      returnBack: false,
      userCountry: '',
      userCountryCode: '',
    }

    this.handlePayment = this.handlePayment.bind(this)
    this.getStripeToken = this.getStripeToken.bind(this)
    this.handleSubscription = this.handleSubscription.bind(this)
    this.onChangeAgreed = this.onChangeAgreed.bind(this)
    this.getPrice = this.getPrice.bind(this)
    this.getLocalPrice = this.getLocalPrice.bind(this)
  }
  async componentDidMount() {
    GeneralUtils.showHideLoadingDiv(true)
    const cordinates: any = await LocationUtils.getCoords()
    const userLocation = await LocationUtils.GetLocationInfo('country', cordinates.latitude, cordinates.longitude)
    this.setState(
      {
        userCountry: userLocation.CountryName,
        userCountryCode: userLocation.CountryCode,
      },
      () => {
        this.getPrice()
      }
    )
    GeneralUtils.showHideLoadingDiv(false)
  }

  async getPrice() {
    const planId = config.stripe_obj.stripe_priceid
    const res = await StripeUtils.getPrice(planId)
    if (!res.isError) {
      const price = JSON.parse(res.data)
      await this.getLocalPrice(price)
    }
  }
  async getLocalPrice(price: any) {
    const { userCountry } = this.state
    const res = await StripeUtils.ConvertToCurrency(price.currency, userCountry, price ? price.amount / 100 : 0)
    if (!res.isError) {
      const objResponse: any = {}
      objResponse.amount = parseFloat(res.data.price).toFixed(2)
      objResponse.currency = res.data.currency
      this.setState({
        planPrice: objResponse,
      })
    }
  }
  handlePayment(e) {
    this.getStripeToken(e)
  }
  onChangeAgreed(e) {
    this.setState({
      isAgreed: !this.state.isAgreed,
    })
  }

  async getStripeToken(e) {
    const { paymentMethod } = this.state
    const token = await this.props.stripe.createToken({})
    paymentMethod.stripeToken = token.token.id
    this.setState(
      {
        paymentMethod,
      },
      () => {
        this.handleSubscription()
      }
    )
  }
  async handleSubscription() {
    const { paymentMethod } = this.state
    const user: UserModel = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : Object()
    const res = await StripeUtils.stripSubscription(paymentMethod)
    if (!res.isError) {
      const userDate: UserModel = user
      userDate.IsPremium = true
      localStorage.setItem('user', JSON.stringify(userDate))
      this.setState({
        isRedirect: true,
      })
      const result = await StripeUtils.stripSubscription(paymentMethod)

      if (!result.isError) {
        fbq('track', 'Subscribe')
        gtag('event','Subscribe')
        GeneralUtils.showSuccess()
        user.IsPremium = true
        localStorage.setItem('user', JSON.stringify(user))
        this.setState({ returnBack: true })
      } else {
        GeneralUtils.showError('Subscription Failed')
      }
    }
  }

  render() {
    const { isAgreed, planPrice, isRedirect } = this.state
    if (isRedirect) {
      return <Redirect push to="/my-profile" />
    }
    if (this.state.returnBack) {
      const returnURL = localStorage.getItem('returnURL')
      return <Redirect push to={returnURL ? returnURL : '/my-profile'} />
    }

    return (
      <>
        <div className="upg">
          <div className="upg_l">
            <div className="upg_l_inn">
              <div className="upg_prem">
                <div className="container">
                  <a
                    href="javascript:void(0)"
                    className="upg_back"
                    onClick={() => {
                      window.history.back()
                    }}
                  >
                    Back
                  </a>
                  <h1 className="upg_ttl">
                    Upgrade to <span>Premium account</span>
                  </h1>
                  <p className="upg_desc">
                    Have full access to all the four types of tournament bracket creation along with the ability to set up Qualifier events to run
                    your season events that will lead to your Championship Cups. The upgrade will provide you with a large variety of options for your
                    Online and Live tournament events.
                  </p>
                  <div className="row mb-4">
                    <div className="col-12 col-lg-6">
                      <ul className="check-list">
                        <li>Access to Single Elimination Bracket</li>
                        <li>Access to Double Elimination Bracket</li>
                        <li>Access to Single Round FFA (Free For All) Bracket</li>
                        <li>Access to Elimination Round FFA (Free For All) Bracket</li>
                      </ul>
                    </div>
                    <div className="col-12 col-lg-6">
                      <ul className="check-list">
                        <li>Access to Single Elimination Qualifier Bracket</li>
                        <li>Access to Double Elimination Qualifier Bracket</li>
                        <li>Access to Single Round FFA (Free For All) Qualifier Bracket</li>
                        <li>Access to Elimination Round FFA (Free For All) Qualifier Bracket</li>
                      </ul>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-lg-6">
                      <div className="check-block check-block--h100 check-block--big">
                        <div className="check-block_inn">
                          <div className="check-block_icon">
                            <img src="https://kombatlink-assets.azureedge.net/img/upgrade-img-1.svg" alt="" />
                          </div>
                          <h3 className="check-block_ttl">Access to special features</h3>
                          <p className="check-block_txt">
                            Let us monitor your sign ups for you and resize the tournament on your behalf when the signups do not reach your initial
                            goal. This will provide you a higher success rate in launching your tournaments.
                          </p>
                          <ul className="check-list">
                            <li>Automated Tournament Bracket Resizing</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-6">
                      <div className="check-block check-block--h50">
                        <div className="check-block_inn">
                          <div className="check-block_icon">
                            <img src="https://kombatlink-assets.azureedge.net/img/upgrade-img-2.svg" alt="" />
                          </div>
                          <h3 className="check-block_ttl">Lower services charges</h3>
                          <p className="check-block_txt">Pay less fees and increase your revenue.</p>
                          <ul className="check-list">
                            <li>Service charge for tickets sales will be reduce to 5%</li>
                          </ul>
                        </div>
                      </div>
                      <div className="check-block check-block--h50">
                        <div className="check-block_inn">
                          <div className="check-block_icon">
                            <img src="https://kombatlink-assets.azureedge.net/img/upgrade-img-3.svg" alt="" />
                          </div>
                          <h3 className="check-block_ttl">Attractive tournament view</h3>
                          <p className="check-block_txt">Replace the tournament cover with your custom 10 sec. video clip.</p>
                          <ul className="check-list">
                            <li>Capture your audience attention</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="upg_r">
            <div className="upg_r_inn">
              <div className="upg_pay">
                <div className="row flex-column">
                  <div className="col">
                    <h2 className="payment__header">Payment info</h2>
                  </div>
                  <div className="col">
                    <div className="payment__subheader">Payment method</div>
                  </div>
                  <div className="col">
                    <div className="row">
                      <div className="col-sm-6 col-12 p-r-5">
                        <input className="payment__radio payment_tabs-contol" id="method-1" type="radio" name="method" value="1" />
                        <label className="payment__input payment__label payment__label--credit">CREDIT CARD</label>
                      </div>
                    </div>
                  </div>
                  <div className="payment_tabs">
                    <div className="payment_tab payment_tab-active payment_tab-1">
                      <div className="col">
                        <div className="payment__subheader">Card Number</div>
                      </div>
                      <div className="col">
                        {/* <input className="payment__input" type="text" placeholder="0000 0000 0000 0000" /> */}
                        <CardNumberElement className="payment__input" style={{ base: { color: '#fff' } }} />
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col-6">
                            <div className="payment__subheader">Expiration</div>
                          </div>
                          <div className="col-6">
                            <div className="payment__subheader">CVC</div>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col-6 p-r-5">
                            {/* <div className="row align-items-stretch">
                                                            <div className="col">
                                                                <input className="payment__input payment__expiration-input" type="text" placeholder="1" />
                                                            </div>
                                                            <div className="col-auto p-0 d-flex align-items-center">
                                                                <div className="payment__expiration-slash">/</div>
                                                            </div>
                                                            <div className="col">
                                                                <input className="payment__input payment__expiration-input" type="text" placeholder="23" />
                                                            </div>
                                                        </div> */}
                            <CardExpiryElement className="payment__input" style={{ base: { color: '#fff' } }} />
                          </div>
                          <div className="col-6 p-l-5">
                            {/* <input className="payment__input" type="text" placeholder="123" /> */}
                            <CardCvcElement className="payment__input" style={{ base: { color: '#fff' } }} />
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="payment__agree-licence-container" onClick={e => this.onChangeAgreed(e)}>
                          <input className="payment__agree-licence form-control" id="agree-licence" type="checkbox" checked={isAgreed} />
                          <label className="payment__agree-licence-label" />
                          <div>
                            Agree with Kombatlink
                            <a className="color-blue" href="javascript:void(0)">
                              &nbsp;Terms and Conditions
                            </a>
                            &nbsp;&amp;
                            <a className="color-blue" href="javascript:void(0)">
                              &nbsp;Privacy Policy
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="payment_tab payment_tab-2" />
                  </div>
                  <div className="col">
                    <div className="pay_pri">
                      <div className="pay_pri_head">
                        <div className="pay_pri_head_l">
                          <h3 className="pay_pri_ttl">Premium account</h3>
                        </div>
                        <div className="pay_pri_head_r">
                          <span className="pay_pri_pri">
                            {planPrice.amount ? planPrice.amount : ''} {planPrice.currency ? planPrice.currency : ''} / month
                          </span>
                        </div>
                      </div>
                      <div className="pay_pri_bod">
                        <div className="pay_pri_txt">
                          Month recurring billing will start after the trial period. This card detail will be used for future billing.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col mt-5">
                    <div className="row">
                      <div className="col-12 col-sm-6">
                        {isAgreed && (
                          <button
                            className="open-modal_payment-succes payment__checkout blue-button blue-button--filled"
                            onClick={e => {
                              this.handlePayment(e)
                            }}
                          >
                            Upgrade
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default withStripe(UpgradeAccount)
