import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { makeGetParticipantLineUps } from 'store/selectors/line-ups-selectors'
import { ApplicationState } from 'store/types/common'

export const useLineUps = (tournamentParticipantId: number) => {
  const getParticipantLineUps = useMemo(makeGetParticipantLineUps, [])
  const lineUps = useSelector((state: ApplicationState) => getParticipantLineUps(state, tournamentParticipantId))
  return { lineUps }
}
