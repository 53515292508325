import { createSelector } from 'reselect'
import { OperationTypes } from 'store/logic/operations-logic'
import { ApplicationState } from 'store/types/common'
import { MatchLinkModel, MatchLinkStatusEnum } from 'store/types/match-link-types'
import { makeGetIsAnyOperationWithTypeRunning, makeGetIsOperationWithTypeRunning } from './operations-selectors'


const getState = (state: ApplicationState) =>  state.matchLinks
const getMatchLinks = createSelector(getState, state => state.matchLinks || {})
const getIdsByMatchId = createSelector(getState, state => state?.matchLinkIdsByMatchId || {})

const getPendingMatchLinkIds = createSelector(getState, state => state.pendingMatchLinkIds || [])
const getApprovedMatchLinkIds = createSelector(getState, state => state.pendingMatchLinkIds || [])


export const makeGetMatchLinks = () =>
  createSelector(
    getIdsByMatchId,
    getMatchLinks,
    (_: ApplicationState, matchId: number) => matchId,
    (byId, matchLinks, id) => {
      return byId[id]?.map(mlId => matchLinks[mlId]) || []
    }
  )

export const makeGetApprovedMatchLinksByMatchIds = () =>
  createSelector(
    getIdsByMatchId,
    getMatchLinks,
    (_: ApplicationState, matchIds: number[]) => matchIds,
    (byId, matchLinks, matchIds) => {
      return matchIds.reduce((res: number[], id: number) => {
        const links = byId[id] ?? []
        return [...res, ...links] 
      }, []).map(id => matchLinks[id])
        .filter(ml => ml.Status == MatchLinkStatusEnum.Approved)
        .map(ml => ml.Id)
    }
  )

export const makeGetPendingMatchLinks = () => 
  createSelector(
    getMatchLinks,
    getPendingMatchLinkIds,
    (matchLinks, ids) => {
      return  ids.map<MatchLinkModel>(key => matchLinks[key])
    }
  )

export const makeGetApprovedMatchLinks = () => 
  createSelector(
    getMatchLinks,
    getApprovedMatchLinkIds,
    (matchLinks, ids) => {
      return  ids.map<MatchLinkModel>(key => matchLinks[key])
    }
  )

export const getAllPendingMatchLinkCount = createSelector(getState, state => state?.pendingMatchListCount || 0)
export const getAllApprovedMatchLinkCount = createSelector(getState, state => state?.approvedMatchLinkCount || 0)

const getLoadMatchLinksOperationType = createSelector(
  (_, matchId: number) => matchId,
  matchId => ({ type: OperationTypes.loadMatchLinks, objectId: matchId })
) 
export const makeGetIsMatchLinksLoading = () => makeGetIsOperationWithTypeRunning(getLoadMatchLinksOperationType)


export const getPendingMatchLinksLoading = makeGetIsAnyOperationWithTypeRunning(() => ({
  type: OperationTypes.loadPendingMatchLinks,
}))

export const getApprovedMatchLinksLoading = makeGetIsAnyOperationWithTypeRunning(() => ({
  type: OperationTypes.loadApprovedMatchLinks,
}))
