import { useTheme } from 'components/_hooks'
import React, { CSSProperties } from 'react'
import { cn } from 'utils'
import css from './LoadingBox.module.scss'

export type LoadingSize = 'small' | 'average' | 'big' | 'extra'

interface LoadingProps {
  size: LoadingSize
  centered?: boolean
  className?: string
}
export const Loading = ({ size, centered, className }: LoadingProps) => {
  const theme = useTheme()
  const colorClass = theme === 'light' ? css.loadingLight : css.loadingDark

  return (
    <div className={cn( centered && 'text-center')}>
      <div className={cn('spinner-border', colorClass, className)} style={styles[size]} />
    </div>
  )
}

const styles: { [key in LoadingSize]: CSSProperties } = {
  small: { width: '1rem', height: '1rem' },
  average: { width: '2rem', height: '2rem', margin: '0em' },
  big: { width: '3rem', height: '3rem', margin: '1em' },
  extra: { width: '10rem', height: '10rem', margin: '1em' },
}
