import React from 'react'
import styles from './ProfileCard.module.scss'

export interface ProfileCardCheckboxProps {
  id: string
  checked: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
}

export const ProfileCardCheckbox = ({ id, checked, onChange, disabled }: ProfileCardCheckboxProps) => {
  return (
    <div className={styles.checkBoxContainer}>
      <input className={styles.checkBox} type="checkbox" id={id} onChange={onChange} checked={checked} disabled={disabled} />
      <label className={styles.checkBoxLabel} htmlFor={id}></label>
    </div>
  )
}
