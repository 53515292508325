import React from 'react'
import { AnimatedModalWindow, AnimatedModalWindowProps } from './_shared/AnimatedModalWindow'
import { ModalBackground } from './_shared/ModalBackground'
import { ModalContainer } from './_shared/ModalContainer'
import { ModalWindow, ModalWindowProps } from './_shared/ModalWindow'

const SimpleModal = ({ onClose, children, className, disabled }: ModalWindowProps) => {
  return (
    <ModalContainer>
      <ModalBackground onClose={onClose} disabled={disabled} />
      <ModalWindow onClose={onClose} className={className} disabled={disabled}>
        {children}
      </ModalWindow>
    </ModalContainer>
  )
}

const AnimatedModal = ({ onClose, children, className, disabled, animationType, show }: AnimatedModalWindowProps) => {
  return (
    <AnimatedModalWindow onClose={onClose} className={className} disabled={disabled} animationType={animationType} show={show}>
      {children}
    </AnimatedModalWindow>
  )
}

export enum ModalType {
  simple,
  animated,
}

export interface SimpleModalProps extends ModalWindowProps {
  type?: ModalType.simple
}

export interface AnimatedModalProps extends AnimatedModalWindowProps {
  type?: ModalType.animated
}

export type ModalBaseProps = SimpleModalProps | AnimatedModalProps

export const ModalBase = ({ type = ModalType.simple, ...props }: ModalBaseProps) => {
  const ModalComponent = type === ModalType.animated ? AnimatedModal : SimpleModal
  return <ModalComponent {...props} />
}
