import { createSelector } from 'reselect'
import { ApplicationState } from '../types/common'

const getState = (state: ApplicationState) => state.playerProfiles

const getByUserId = createSelector(getState, state => state?.playerProfileIdByUserId || {})

export const makeGetPlayerProfileByUser = () =>
  createSelector(
    getByUserId,
    (_: ApplicationState, { userId }: { userId: number }) => userId,
    (byId, id) => byId[id]
  )
