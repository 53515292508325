import React, { useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { TournamentModeName } from 'consts/TournamentModeId'
import {
  BattleRow,
  TableButton,
  ButtonsColumn,
  ClassColumn,
  EventNameColumn,
  TypeColumn,
  RoundColumn,
  StartDateColumn,
} from '../_shared/BattleRow/BattleRow'
import { getIsRegistrationClosed, TournamentType } from 'store/types/tournaments-types'
import { makeGetTournament } from 'store/selectors/tournaments-selectors'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from 'store/types/common'
import { makeGetRound } from 'store/selectors/rounds-selectors'
import { getRoundTitle } from 'store/types/rounds-types'
import { makeGetIsMatchParticipantsLoading } from 'store/selectors/match-participant-selectors'
import { DropDownTable, LoadingBox } from 'components/_shared'
import { useFormatDateTime, useOpponents, useScrollToElement } from 'components/_hooks'
import { loadMatchParticipants } from 'store/logic/match-participant-logic'
import { TournamentParticipantRow } from '../_shared/TournamentParticipantRow'
import { EventTypeEnum } from 'models/tournament-models'

interface OwnProps {
  roundId: number
  matchId?: number
  openCancelParticipationModal: (tournament: TournamentType) => void
  highlightedRoundId?: number
  removeHighlightedRoundId: () => void
}

export const PendingBattleRow = ({ matchId, roundId, openCancelParticipationModal, removeHighlightedRoundId, highlightedRoundId }: OwnProps) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const getRound = useMemo(makeGetRound, [])
  const getTournament = useMemo(makeGetTournament, [])
  const getIsMatchParticipantLoading = useMemo(makeGetIsMatchParticipantsLoading, [])

  const round = useSelector((state: ApplicationState) => getRound(state, roundId))
  const tournament = useSelector((state: ApplicationState) => getTournament(state, round.TournamentDetailId))
  const matchParticipantsLoading = useSelector((state: ApplicationState) => getIsMatchParticipantLoading(state, matchId))

  const { opponents } = useOpponents(matchId)
  const isRegistraionClosed = useMemo(() => {
    return getIsRegistrationClosed(tournament)
  }, [tournament])
  const playDate = useFormatDateTime(tournament.TournamentDetailId, round.PlayDateTime, 'redux')
  const deadlineDate = useFormatDateTime(tournament.TournamentDetailId, tournament.RegistrationClosesOn, 'redux')
  const highlighted = highlightedRoundId && highlightedRoundId === roundId

  const ref = useScrollToElement(highlighted, matchParticipantsLoading ? 'auto' : 'smooth')

  const handleCancel = () => {
    openCancelParticipationModal(tournament)
  }

  useEffect(() => {
    if (matchId) {
      dispatch(loadMatchParticipants(matchId))
    }
  }, [dispatch, matchId])

  return (
    <>
      <div ref={ref} className="row table__row" onMouseEnter={highlighted ? removeHighlightedRoundId : undefined}>
        <div className="col-12">
          <input className="table__checkbox" type="checkbox" id={`open-table-${roundId}`} />
          <BattleRow highlighted={highlighted}>
            <EventNameColumn>
              <DropDownTable.Link to={`/tournament-detail/${tournament.TournamentDetailId}`}>{tournament.Name}</DropDownTable.Link>
            </EventNameColumn>
            <ClassColumn>Tournament</ClassColumn>
            <TypeColumn>{TournamentModeName[tournament.TournamentModeId]}</TypeColumn>
            <RoundColumn>{getRoundTitle(round, tournament.TournamentStructureId)}</RoundColumn>
            <StartDateColumn>
              {playDate.dateTime}
              {round.RoundNumber === 1 && !round.IsLosersBracket && !isRegistraionClosed && (
                <div className="table__warning">
                  <div className="table__warning-content">
                    <div className="table__warning-text">You may cancel your participation before the deadline ({deadlineDate.dateTime})</div>
                  </div>
                </div>
              )}
            </StartDateColumn>
            <ButtonsColumn>
              <div className="row justify-content-sm-end flex-sm-row flex-column m-0">
                {round.RoundNumber === 1 && !round.IsLosersBracket &&
                  tournament.EventTypeId !== EventTypeEnum.Main &&
                  <TableButton onClick={handleCancel} color="red" filled disabled={isRegistraionClosed}>
                    cancel
                  </TableButton>
                }
                <TableButton
                  color="grey"
                  filled
                  onClick={() => {
                    history.push(`/tournament-bracket/${tournament.TournamentDetailId}`)
                  }}
                >
                  play schedule
                </TableButton>
                <label className={'table__open'} htmlFor={`open-table-${roundId}`} />
              </div>
            </ButtonsColumn>
          </BattleRow>
          <div className="row table__secondary-row-container">
            <div className="container-fluid">
              <LoadingBox loading={matchParticipantsLoading} size="average">
                {opponents.length === 0 && <span style={{ color: 'white' }}>No opponents</span>}
                {opponents.map(x => (
                  <TournamentParticipantRow key={x.TournamentParticipantId} participantId={x.TournamentParticipantId} />
                ))}
              </LoadingBox>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
