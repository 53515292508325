import React from 'react'
import { cn } from 'utils'
import styles from '../modals.module.scss'

interface WindowTextareaProps {
  placeholder?: string
  className?: string
  value: string
  onChange: (value: string) => void
  disabled?: boolean
}

export const WindowTextarea = ({ placeholder, className, value, onChange, disabled }: WindowTextareaProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(event.target.value)
  }

  return (
    <div className="row">
      <textarea
        className={cn(styles.window__textarea, disabled && styles.disabled, className)}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        disabled={disabled}
      />
    </div>
  )
}
