import { Reducer } from 'redux'
import { arrayToObject } from 'utils'
import { State, KnownAction, DISPUTES_LOADED, DISPUTE_UPDATED } from '../types/dispute-types'

const initialState: State = {
  disputesById: {},
  disputesIds: [],
  allDisputesCount: 0,
}

const reducer: Reducer<State, KnownAction> = (state: State | undefined = initialState, action: KnownAction) => {
  switch (action.type) {
    case DISPUTES_LOADED: {
      return {
        ...state,
        disputesIds: action.payload.Disputes.map(x => x.Id),
        disputesById: {
          ...state.disputesById,
          ...arrayToObject(action.payload.Disputes, x => x.Id),
        },
        allDisputesCount: action.payload.AllDisputesCount,
      }
    }
    case DISPUTE_UPDATED:
      return {
        ...state,
        disputesById: {
          ...state.disputesById,
          [action.payload.dispute.Id]: action.payload.dispute,
        },
      }
    default:
      return state
  }
}

export default reducer
