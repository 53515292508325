import React from 'react'
import { Modal } from 'components/_modals'
import { cn } from 'utils'
import styles from './ImgModal.module.scss'
import { Img } from 'components/_shared'

interface ImgModalProps {
  onClose: CallableFunction
  imageId: number
}

export const ImgModal = ({onClose, imageId}: ImgModalProps) => {
  return (
    <Modal 
      allowOk
      okButtonText="Close"
      hideCancel
      show
      className={cn(styles.imgModal, styles.window)}
      onClose={onClose}
      buttonColor="blue"
      fieldsetClassName={styles.fieldset}
    >
      <Img imageId={imageId}/>
    </Modal>
  )
}
