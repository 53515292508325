import React, { useMemo } from 'react'
import { cn } from 'utils'
import { ContainerName, useAppSelector } from 'components/_hooks'
import { makeGetUser } from 'store/selectors/user-selectors'
import { UserAvatar } from '../UserAvatar/UserAvatar'
import styles from './ParticipantAvatar.module.scss'

export interface ParticipantOnlineAvatarProps {
  className: string
  parentClassName?: string
  withLink: boolean
  userId: number
  containerName?:ContainerName
}

const MemberOnlineAvatar = ({ userId, withLink, className, parentClassName,containerName }: ParticipantOnlineAvatarProps) => {

  const getUser = useMemo(makeGetUser, [])    

  const user = useAppSelector(state => getUser(state, userId))

  return (
    <div className={cn(styles.prsnIconIndicator, parentClassName, user?.IsOnline && styles.prsnIconIndicatorActive)}>
      <UserAvatar containerName={containerName} userId={userId} className={className} placeholder="dark" withLink={withLink} />
    </div>
  )
 
}
export default MemberOnlineAvatar
