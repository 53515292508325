import * as React from 'react'
import VictoryRoadListCard from './VictoryRoadListCard'
import { FilterContainer, Filters, HeaderButton, LoadingBox, Pagination, SearchInput, Select } from 'components/_shared'
import { useEffect, useState } from 'react'
import styles from './list.module.scss'
import { kl_RegionModel } from 'models/kl_region-model'
import { TournamentModeEnum, TournamentModeOptions, TournamentStatusEnum, TournamentStructureEnum, TournamentStructureOptions, VenueTypeEnum, VenueTypeOptions } from 'consts'
import { IDictionary } from 'store/types/dictionary-types'
import { loadFinishedTournaments, TournamentFilters } from 'store/logic/tournaments-logic'
import { useFilters } from 'components/_hooks'
import { PublicUserType } from 'store/types/user-types'
import { RegionType } from 'store/types/region-types'
import { GameType } from 'store/types/game-types'
import { defaultPageSize } from 'store/types/common'
import { useDispatch, useSelector } from 'react-redux'
import { getAllFinishedTournamentsCount, getFinishedTournaments, getFinishedTournamentsLoading } from 'store/selectors/tournaments-selectors'
import VictoryRoadTab from '../VictoryRoadTab'
import { loadGames } from 'store/logic/game-logic'

const VictoryRoadList = () => {
  const [pageNo, setPageNo] = React.useState<number>(0)

  const [selectedOrganizers, setSelectedOrganizers] = React.useState<PublicUserType[]>([])
  const [selectedRegion, setSelectedRegion] = React.useState<RegionType>(null)
  const [selectedGames, setSelectedGames] = React.useState<GameType[]>([])

  const [selectedStrutures, setSelectedStrutures] = React.useState<IDictionary<TournamentStructureEnum>[]>([])
  const [selectedVenueType, setSelectedVenueType] = React.useState<IDictionary<VenueTypeEnum>>(null)
  const [selectedMode, setSelectedMode] = React.useState<IDictionary<TournamentModeEnum>>(null)

  const [name, setName] = React.useState('')

  const { organizers, games, regions } = useFilters()

  const filters = React.useMemo<TournamentFilters | undefined>(() => {
    return {
      regionsIds: selectedRegion?.Id && [selectedRegion.Id] || [],
      organizersIds: selectedOrganizers.map(org => org.Id),
      name: name,
      gamesIds: selectedGames.map(g => g.Id),
      venueTypes: selectedVenueType ? [selectedVenueType.id] : [],
      tournamentStructures: selectedStrutures.map(s => s.id),
      tournamentMode: selectedMode?.id,
      tournamentStatuses: [TournamentStatusEnum.Finished],
    }
  }, [name, selectedGames, selectedMode?.id, selectedOrganizers, selectedRegion?.Id, selectedStrutures, selectedVenueType])

  const dispatch = useDispatch()

  const load = React.useCallback((filters: TournamentFilters, page: number) => {
    dispatch(loadFinishedTournaments(filters, {
      page,
      pageSize: defaultPageSize,
    }))
  }, [dispatch])

  React.useEffect(() => {
    load(filters, pageNo)
  }, [filters, load, pageNo])

  useEffect(() => {
    dispatch(loadGames)
  })

  const tournaments = useSelector(getFinishedTournaments)
  const allItemsCount = useSelector(getAllFinishedTournamentsCount)
  const isLoading = useSelector(getFinishedTournamentsLoading)

  const [filtersModalOpened, setFiltersModalOpened] = useState(false)
  const closeFiltersModal = () => {
    setFiltersModalOpened(false)
  }
  const handleClick = () => {
    setFiltersModalOpened(true)
  }

  const resetPage = () => {
    setPageNo(0)
  }
  const handleChangeName = (value: string) => {
    setName(value)
    resetPage()
  }
  const handleChangeRegion = (value: RegionType) => {
    setSelectedRegion(value)
    resetPage()
  }

  const handleChangeOrganizers = (value: PublicUserType[]) => {
    setSelectedOrganizers(value)
    resetPage()
  }

  const handleChangeGames = (value: GameType[]) => {
    setSelectedGames(value)
    resetPage()
  }

  const handleChangeStructures = (value: IDictionary<TournamentStructureEnum>[]) => {
    setSelectedStrutures(value)
    resetPage()
  }
  const handleChangeMode = (value: IDictionary<TournamentModeEnum>) => {
    setSelectedMode(value)
    resetPage()
  }

  const handleChangeVenueType = (value: IDictionary<VenueTypeEnum>) => {
    setSelectedVenueType(value)
    resetPage()
  }


  return (
    <>
      <VictoryRoadTab />
      <div className="row">
        <div className="container-fluid" id="list-container">
          <div className="row">
            <HeaderButton onClick={handleClick} clasName={styles.article__openSelects}>Filter Options</HeaderButton>
          </div>
          <Filters title="Filter Options" opened={filtersModalOpened} close={closeFiltersModal} className="article__selectsPopup">
            <FilterContainer title="Name" className={styles.article__select}>
              <SearchInput onChange={handleChangeName} placeholder="Search by name" value={name} />
            </FilterContainer>
            <FilterContainer title="Region" className={styles.article__select}>
              <Select
                options={regions}
                onChange={handleChangeRegion}
                selected={selectedRegion}
                labelExtractor={(region: kl_RegionModel) => region.RegionName}
                valueExtractor={(region: kl_RegionModel) => region.Id}
                type="single"
                placeHolder="Region"
              />
            </FilterContainer>
            <FilterContainer title="Organizer" className={styles.article__select}>
              <Select
                options={organizers}
                onChange={handleChangeOrganizers}
                selected={selectedOrganizers}
                labelExtractor={organizer => organizer.Organizationname}
                placeHolder="All"
                valueExtractor={organizer => organizer.Id}
                withSearch
                type="multi"
              />
            </FilterContainer>
            <FilterContainer title="Games" className={styles.article__select}>
              <Select
                options={games}
                onChange={handleChangeGames}
                selected={selectedGames}
                labelExtractor={game => game.Gamename}
                placeHolder="All"
                valueExtractor={game => game.Id}
                withSearch
                type="multi"
              />
            </FilterContainer>
            <FilterContainer title="Tournament brackets" className={styles.article__select}>
              <Select
                options={TournamentStructureOptions}
                onChange={handleChangeStructures}
                selected={selectedStrutures}
                labelExtractor={(option: IDictionary<TournamentStructureEnum>) => option.name}
                placeHolder="All"
                valueExtractor={(option: IDictionary<TournamentStructureEnum>) => option.id}                
                type="multi"
              />
            </FilterContainer>

            <FilterContainer title="Type" className={styles.article__select}>
              <Select
                options={TournamentModeOptions}
                onChange={handleChangeMode}
                selected={selectedMode}
                labelExtractor={(option: IDictionary<TournamentModeEnum>) => option.name}
                placeHolder="Type"
                valueExtractor={(option: IDictionary<TournamentModeEnum>)  => option.id}
                type="single"
              />
            </FilterContainer>
            <FilterContainer title="Venue" className={styles.article__select}>
              <Select
                options={VenueTypeOptions}
                onChange={handleChangeVenueType}
                selected={selectedVenueType}
                labelExtractor={(option: IDictionary<VenueTypeEnum>) => option.name}
                placeHolder="Venue"
                valueExtractor={(option: IDictionary<VenueTypeEnum>) => option.id}
                type="single"
              />
            </FilterContainer>
          </Filters>
          <LoadingBox size="big" loading={isLoading}>
            <div className="row article__hr mw--fix" />
            {tournaments && tournaments.map((tournament, i) => {
              return (
                <VictoryRoadListCard
                  key={tournament.Id.toString() + '_'+ i.toString()}
                  tournament={tournament}
                  index={i}
                />
              )
            })}
          </LoadingBox>
          <Pagination page={pageNo} itemsPerPage={defaultPageSize} allItemsCount={allItemsCount} onChangePage={setPageNo} />
        </div>
      </div>
    </>
  )
}


export default VictoryRoadList
