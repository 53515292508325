import React, { ReactNode } from 'react'
import { cn } from 'utils'
import stylesDark from '../Tabs.module.scss'
import stylesLight from '../TabsLight.module.scss'
import { useThemeStyles } from 'components/_hooks'

export interface TabCheckboxContainerProps {
  children: ReactNode
}

export const TabCheckboxContainer = ({ children }: TabCheckboxContainerProps) => {
  const styles = useThemeStyles(stylesDark, stylesLight)
  
  return (
    <div className={cn('col-lg-auto col-12', styles.articleRadioContainer)}>
      <div className="row justify-content-lg-end justify-content-sm-start">
        {children}
      </div>
    </div>
  )
}
