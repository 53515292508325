import { useAlertModal } from 'components/_hooks'
import { Button, LoadingBox, Pagination, SearchInput } from 'components/_shared'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useInvitePlayers } from 'store/logic/clan-invitation-logic'
import { searchPlayers } from 'store/logic/pages/clan-page-logic'
import { getIsInvitePlayersLoading } from 'store/selectors/clan-invitations-selectors'
import { getAllPlayersCount, getIsSearchPlayersLoading, getPlayersFilters, getPlayersIds, getSelectedPlayersIds } from 'store/selectors/pages/clan-page-selectors'
import { ClanType } from 'store/types/clan-types'
import { filteredPlayersCleared, playersFiltersChanged, playersInvited, selectedPlayersChanged } from 'store/types/pages/clan-page-types'
import { cn } from 'utils'
import styles from '../../ClanPage.module.scss'
import { PlayerRow } from './PlayerRow'

const pageSize = 9

export interface InvitePlayersProps {
  clan: ClanType
}

export const InvitePlayers = ({ clan }:  InvitePlayersProps) => {
  const dispatch = useDispatch()
  const invitePlayers = useInvitePlayers()
  const [alertModal, openAlertModal] = useAlertModal()
  
  const { page: playersPage, username } = useSelector(getPlayersFilters)
  const isPlayersLoading = useSelector(getIsSearchPlayersLoading)
  const playersIds = useSelector(getPlayersIds)
  const allPlayersCount = useSelector(getAllPlayersCount)
  const selectedPlayersIds = useSelector(getSelectedPlayersIds)
  const isInvitePlayersLoading = useSelector(getIsInvitePlayersLoading)

  const handleNameChange = (value: string) => {
    dispatch(playersFiltersChanged({
      page: 0,
      username: value,
    }))
  }

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    const id = parseInt(event.currentTarget.id)
    const exists = selectedPlayersIds.find(x => x === id)
    const newSelectedPlayers = exists
      ? selectedPlayersIds.filter(x => x !== id)
      : [...selectedPlayersIds, id]
    dispatch(selectedPlayersChanged(newSelectedPlayers))
  }

  const handleChangePlayersPage = (value: number) => {
    dispatch(playersFiltersChanged({
      page: value,
      username,
    }))
  }

  const handleInviteClick = async () => {
    try {
      await invitePlayers(selectedPlayersIds)
      dispatch(playersInvited())
      openAlertModal(`Invitation${selectedPlayersIds.length > 1 ? 's' : ''} sent`)
    } catch (error) {
      openAlertModal((error as Error).message)
    }
  }

  useEffect(() => {
    if (username.length > 2) {
      dispatch(searchPlayers(username, {
        page: playersPage,
        pageSize,
      }))
    } else if (username.length === 0) {
      dispatch(filteredPlayersCleared())
    }
  }, [dispatch, playersPage, username])

  return (
    <>
      <div className={cn(styles.rightTile, 'pb-2 mt-2')}>Invite Members to join {'\''}{clan?.Name}{'\''}</div>
      <div className="d-flex flex-column flex-md-row">
        <div className={cn('d-flex flex-column flex-fill', styles.searchPlayersContainer)}>
          <SearchInput disabled={isInvitePlayersLoading} onChange={handleNameChange} placeholder="Please type here to find players (at least 3 characters)" value={username} />
          <LoadingBox loading={isPlayersLoading} size="average" centered >
            <div className={cn(styles.membersContainer, 'd-flex flex-wrap mt-1')}>
              {playersIds.map(playerId => 
                <PlayerRow
                  onChange={handleCheck}
                  checked={selectedPlayersIds.some(x => x === playerId)}
                  key={playerId}
                  playerId={playerId}
                  className={cn(styles.card, 'm-1')}
                  disabled={isInvitePlayersLoading}
                />
              )}
            </div>
          </LoadingBox>
          <Pagination disabled={isInvitePlayersLoading} page={playersPage} itemsPerPage={pageSize} allItemsCount={allPlayersCount} onChangePage={handleChangePlayersPage} />
        </div>
        <div className={cn('d-flex ml-md-2 flex-column', styles.inviteContainer)}>
          <div className="d-flex mb-2">
            <Button loading={isInvitePlayersLoading} disabled={selectedPlayersIds.length === 0} className={cn('flex-fill', styles.inviteButton)} color="green" filled onClick={handleInviteClick}>Invite to my clan</Button>
          </div>
          <div className={styles.selectedPlayersContainer}>
            {selectedPlayersIds.map(playerId => 
              <PlayerRow
                onChange={handleCheck}
                checked={selectedPlayersIds.some(x => x === playerId)}
                key={playerId}
                playerId={playerId}
                className={cn(styles.card, 'mb-2')}
                disabled={isInvitePlayersLoading}
              />
            )}
          </div>
        </div>
      </div>
      {alertModal}
    </>
  )
}
