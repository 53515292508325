import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { ApplicationState, BoundActions } from 'store/types/common'
import { notificationActionCreators } from 'store/logic/notification-logic'
import { bindActionCreators, Dispatch } from 'redux'
import { NotificationModel } from 'store/types/notification-types'
import { getAllNotificationsCount, getNotifications, getNotificationsLoading } from 'store/selectors/notification-selectors'
import styles from './NotificationsList.module.scss'
import { cn } from 'utils'
import NotificationRow from '../NotificationRow/NotificationRow'
import { InfiniteScroll } from 'components/_shared/InfiniteScroll/InfiniteScroll'

interface OwnProps {
  isOpened: boolean
  page: number
  setPage: React.Dispatch<React.SetStateAction<number>>
  close: (NotificationModel) => void
  className?: string
}

interface StateProps {
  notifications: NotificationModel[]
  loading: boolean
  allNotificationsCount: number
}

type DispatchProps = BoundActions<typeof notificationActionCreators>

type Props = OwnProps & StateProps & DispatchProps

export const notificationPageSize = 10

const NotificationsList = ({
  loading,
  notifications,
  loadNotifications,
  deleteNotification,
  allNotificationsCount,
  loadUnreadNotificationsCount,
  isOpened,
  page,
  setPage,
  close,
  className,
}: Props) => {
  const handlePageChanged = (value: number) => {
    setPage(value)
  }

  const handleDelete = async (notificationId: number) => {
    await deleteNotification(notificationId)
    loadNotifications(0, notificationPageSize * (page + 1))
    loadUnreadNotificationsCount()
  }

  useEffect(() => {
    loadNotifications(page, notificationPageSize)
  }, [loadNotifications, page])

  useEffect(() => {
    if (isOpened) {
      setPage(0)
    }
  }, [isOpened])

  return (
    <>
      <div className={cn(styles.notif_dropdown, isOpened && styles.active, className)}>
        <span className={styles.notif_head}>{notifications.length ? 'Notifications' : 'No notifications'}</span>
        {notifications.length > 0 && (
          <InfiniteScroll
            className={styles.notifDropdownMaxHeight}
            disabled={false}
            page={page}
            onPageChanged={handlePageChanged}
            loading={loading}
            pageSize={notificationPageSize}
            allItemsCount={allNotificationsCount}
          >
            {notifications.map(x => (
              <NotificationRow key={x.Id} notification={x} close={close} onDelete={handleDelete} />
            ))}
          </InfiniteScroll>
        )}
      </div>
    </>
  )
}

const makeMapStateToProps = () => {
  return (state: ApplicationState, props: OwnProps): StateProps => {
    return {
      notifications: getNotifications(state),
      loading: getNotificationsLoading(state),
      allNotificationsCount: getAllNotificationsCount(state),
    }
  }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  ...bindActionCreators(notificationActionCreators, dispatch),
})

export default connect<StateProps, DispatchProps, OwnProps, ApplicationState>(makeMapStateToProps, mapDispatchToProps)(NotificationsList)
