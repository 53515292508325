import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { makeGetTournament } from 'store/selectors/tournaments-selectors'
import { ApplicationState } from 'store/types/common'
import { TournamentType } from 'store/types/tournaments-types'

interface UseTournamentResult {
  tournament?: TournamentType
}

export const useTournament = (tournamentDetailId?: number): UseTournamentResult => {
  const getTournament = useMemo(makeGetTournament, [])
  const tournament = useSelector((state: ApplicationState) => getTournament(state, tournamentDetailId))

  return {
    tournament,
  }
}
