import React from 'react'
import { cn } from 'utils'
import stylesLight from '../TournamentCardLight.module.scss'
import stylesDark from '../TournamentCardDark.module.scss'
import { useThemeStyles } from 'components/_hooks'

interface Props {
  startDate: string
}

const calcDays = (timeLeft: number): number => {
  return (timeLeft && Math.floor(timeLeft / 60 / 60 / 24)) || 0
}

const calcHours = (timeLeft: number): number => {
  return (timeLeft && Math.floor((timeLeft - calcDays(timeLeft) * 60 * 60 * 24) / 60 / 60)) || 0
}

const calcMin = (timeLeft: number): number => {
  return (timeLeft && Math.floor((timeLeft - calcDays(timeLeft) * 60 * 60 * 24 - calcHours(timeLeft) * 60 * 60) / 60)) || 0
}

const calcSec = (timeLeft: number): number => {
  return (timeLeft && Math.floor(timeLeft - calcDays(timeLeft) * 60 * 60 * 24 - calcHours(timeLeft) * 60 * 60 - calcMin(timeLeft) * 60)) || 0
}

const TournamentDateCounter = ({ startDate }: Props) => {
  const styles = useThemeStyles(stylesDark, stylesLight)  

  const timeLeft = React.useMemo(() => {
    if (startDate) {
      const timeLeft = new Date(startDate).getTime() - new Date().getTime()
      if (!timeLeft) return 0
      else return timeLeft / 1000
    }
  }, [startDate])


  const [currentTimeLeft, setCurrentTimeLeft] = React.useState(timeLeft)
  const [days, setDays] = React.useState(calcDays(timeLeft))
  const [hours, setHours] = React.useState(calcHours(timeLeft))
  const [min, setMin] = React.useState(calcMin(timeLeft))
  const [sec, setSec] = React.useState(calcSec(timeLeft))

  React.useEffect(() => {
    const interval = setInterval(() => {
      const newTime = currentTimeLeft && currentTimeLeft > 0 ? currentTimeLeft - 1 : 0
      setCurrentTimeLeft(newTime)
      setDays(calcDays(newTime))
      setHours(calcHours(newTime))
      setMin(calcMin(newTime))
      setSec(calcSec(newTime))
    }, 1000)
    return () => clearInterval(interval)
  }, [timeLeft, currentTimeLeft])

  const remainingTimeContainerClass = cn('col-3', 'p-0', 'd-flex', 'flex-column', 'align-items-center', styles.remainingTime__container)
  return (
    <div className="col-sm-6 col-12 p-0 d-flex flex-row justify-content-sm-end">
      <div className={cn(styles.game__remainingTime, styles.remainingTime, 'ml-0')}>
        <div className="row m-0">
          <div className={remainingTimeContainerClass}>
            <div className={styles.remainingTime__time}>{days < 10 ? `0${days}` : days}</div>
            <div className={styles.remainingTime__text}>days</div>
          </div>
          <div className={remainingTimeContainerClass}>
            <div className={styles.remainingTime__time}>{hours < 10 ? `0${hours}` : hours}</div>
            <div className={styles.remainingTime__text}>hours</div>
          </div>
          <div className={remainingTimeContainerClass}>
            <div className={styles.remainingTime__time}>{min < 10 ? `0${min}` : min}</div>
            <div className={styles.remainingTime__text}>min</div>
          </div>
          <div className={remainingTimeContainerClass}>
            <div className={styles.remainingTime__time}>{sec < 10 ? `0${sec}` : sec}</div>
            <div className={styles.remainingTime__text}>sec</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TournamentDateCounter
