import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { makeGetParticipantById } from 'store/selectors/participant-selectors'
import { ApplicationState } from 'store/types/common'
import { ParticipantType } from 'store/types/participants-types'

interface UseTournamentParticipantResult {
  tournamentParticipant?: ParticipantType
}

export const useParticipant = (tournamentParticipantId?: number): UseTournamentParticipantResult => {
  const getTournamentParticipantById = useMemo(makeGetParticipantById, [])
  const tournamentParticipant = useSelector((state: ApplicationState) => getTournamentParticipantById(state, tournamentParticipantId))

  return {
    tournamentParticipant,
  }
}
