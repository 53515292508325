
export interface kl_Tournament {
  Id: number
  OrganizerId: number
  Name: string
  TournamentModeId: number | null
  TournamentStructureId: number | null
  AutomaticBracketResizing: boolean | null
  VenueTypeId: number | null
  EventTypeId: number | null
  MainTournamentId: number | null
  RegionId: number | null
  CountryId: number | null
  Province: string
  City: string
  VenueName: string
  VenueLocation: string
  VenueLatitude: string
  VenueLongitude: string
  VenueSeatingCapacity: number | null
  StreamingLink: string
  ContactEmail: string
  DiscordLink: string
  GameId: number | null
  ParticipientsPerMatch: number | null
  MaxNumberOfParticipients: number | null
  PlayersPerClan: number | null
  MaxNumberOfRounds: number | null
  NumberOfPlacements: number | null
  NumberOfQualifiersMoveForward: number | null
  HostedByOrganizer: boolean | null 
  Information: string
  IsDraft: boolean | null
  Rules: string
  RepeatTypeId: number | null
  RepeatHoursInterval: number | null
  NumberOfSubstitutes:number | null
}

export interface kl_TournamentDetail {
  Id: number
  TournamentId: number | null
  RepeatSettingId: number | null
  StartDate: Date
  RegistrationOpensOn: Date
  RegistrationClosesOn: Date
  ActualNumberOfParticipients: number | null
  ActualNumberOfRounds: number | null
  NumberOfParticipientsLaidOff: number | null
  TournamentStatusId: number | null
}

export interface kl_TournamentConsoles {
  Id: number
  TournamentId: number
  ConsoleId: number
}

export interface kl_TournamentRounds {
  Id: number
  TournamentDetailId: number
  RoundNumber: number
  RoundTitle: string
  PlayDateTime: Date
  ResultSubmissionDeadline: number
  IsLosersBracket: boolean
}

export interface kl_TournamentSchedule {
  Id: number
  TournamentRoundId: number
  MatchNumber: number
  MatchTitle: string
}

export interface kl_TournamentSettings {
  Id: number
  TournamentId: number
  SettingName: string
  SettingValue: string
}

export interface kl_EventType {
  Id: number
  EventType: string
}
export const EventTypeList: kl_EventType[] = [
  { Id: 1, EventType: 'Main' },
  { Id: 2, EventType: 'Qualifier' },
  { Id: 3, EventType: 'Normal' },
]

export enum EventTypeEnum {
  Main = 1,
  Qualifier = 2,
  Normal = 3,
}
export interface kl_Console {
  Id: number
  ConsoleName: string
}
export const ConsoleList: kl_Console[] = [
  { Id: 1, ConsoleName: 'Xbox' },
  { Id: 2, ConsoleName: 'Playstation' },
  { Id: 3, ConsoleName: 'PC' },
  { Id: 4, ConsoleName: 'Nintendo' },
  { Id: 5, ConsoleName: 'Android' },
  { Id: 6, ConsoleName: 'iOS' },
]

export interface kl_TournamentTicketsAndTicketSaleViewModel {
  Id: number
  TournamentDetailId: number
  DayNumber: number
  TicketDate: Date
  TicketPrice: number
  SoldOut: number
  IsParticipent:boolean
  UserId:number
  CartDetailId?:number
  IsPaid?:number
  TournamentTicketSection?:TournamentTicketSectionDetail[]
}

export interface kl_TournamentModeModel {
  Id: number
  TournamentMode: string
}

export const TournamnetModeList: kl_TournamentModeModel[] = [
  { Id: 1, TournamentMode: 'Solo' },
  { Id: 2, TournamentMode: 'Clans' },
]

export enum ModeTypeEnum {
  Solo = 1,
  Clans = 2,
}

export interface DetailedTimeListItem {
  text: string
  value: string
}

export const DetailedTimeList: DetailedTimeListItem[] = [
  { text: '12:00 AM', value: '12:00:00 AM' },
  { text: '12:10 AM', value: '12:10:00 AM' },
  { text: '12:20 AM', value: '12:20:00 AM' },
  { text: '12:30 AM', value: '12:30:00 AM' },
  { text: '12:40 AM', value: '12:40:00 AM' },
  { text: '12:50 AM', value: '12:50:00 AM' },
  { text: '1:00 AM', value: '1:00:00 AM' },
  { text: '1:10 AM', value: '1:10:00 AM' },
  { text: '1:20 AM', value: '1:20:00 AM' },
  { text: '1:30 AM', value: '1:30:00 AM' },
  { text: '1:40 AM', value: '1:40:00 AM' },
  { text: '1:50 AM', value: '1:50:00 AM' },
  { text: '2:00 AM', value: '2:00:00 AM' },
  { text: '2:10 AM', value: '2:10:00 AM' },
  { text: '2:20 AM', value: '2:20:00 AM' },
  { text: '2:30 AM', value: '2:30:00 AM' },
  { text: '2:40 AM', value: '2:40:00 AM' },
  { text: '2:50 AM', value: '2:50:00 AM' },
  { text: '3:00 AM', value: '3:00:00 AM' },
  { text: '3:10 AM', value: '3:10:00 AM' },
  { text: '3:20 AM', value: '3:20:00 AM' },
  { text: '3:30 AM', value: '3:30:00 AM' },
  { text: '3:40 AM', value: '3:40:00 AM' },
  { text: '3:50 AM', value: '3:50:00 AM' },
  { text: '4:00 AM', value: '4:00:00 AM' },
  { text: '4:10 AM', value: '4:10:00 AM' },
  { text: '4:20 AM', value: '4:20:00 AM' },
  { text: '4:30 AM', value: '4:30:00 AM' },
  { text: '4:40 AM', value: '4:40:00 AM' },
  { text: '4:50 AM', value: '4:50:00 AM' },
  { text: '5:00 AM', value: '5:00:00 AM' },
  { text: '5:10 AM', value: '5:10:00 AM' },
  { text: '5:20 AM', value: '5:20:00 AM' },
  { text: '5:30 AM', value: '5:30:00 AM' },
  { text: '5:40 AM', value: '5:40:00 AM' },
  { text: '5:50 AM', value: '5:50:00 AM' },
  { text: '6:00 AM', value: '6:00:00 AM' },
  { text: '6:10 AM', value: '6:10:00 AM' },
  { text: '6:20 AM', value: '6:20:00 AM' },
  { text: '6:30 AM', value: '6:30:00 AM' },
  { text: '6:40 AM', value: '6:40:00 AM' },
  { text: '6:50 AM', value: '6:50:00 AM' },
  { text: '7:00 AM', value: '7:00:00 AM' },
  { text: '7:10 AM', value: '7:10:00 AM' },
  { text: '7:20 AM', value: '7:20:00 AM' },
  { text: '7:30 AM', value: '7:30:00 AM' },
  { text: '7:40 AM', value: '7:40:00 AM' },
  { text: '7:50 AM', value: '7:50:00 AM' },
  { text: '8:00 AM', value: '8:00:00 AM' },
  { text: '8:10 AM', value: '8:10:00 AM' },
  { text: '8:20 AM', value: '8:20:00 AM' },
  { text: '8:30 AM', value: '8:30:00 AM' },
  { text: '8:40 AM', value: '8:40:00 AM' },
  { text: '8:50 AM', value: '8:50:00 AM' },
  { text: '9:00 AM', value: '9:00:00 AM' },
  { text: '9:10 AM', value: '9:10:00 AM' },
  { text: '9:20 AM', value: '9:20:00 AM' },
  { text: '9:30 AM', value: '9:30:00 AM' },
  { text: '9:40 AM', value: '9:40:00 AM' },
  { text: '9:50 AM', value: '9:50:00 AM' },
  { text: '10:00 AM', value: '10:00:00 AM' },
  { text: '10:10 AM', value: '10:10:00 AM' },
  { text: '10:20 AM', value: '10:20:00 AM' },
  { text: '10:30 AM', value: '10:30:00 AM' },
  { text: '10:40 AM', value: '10:10:00 AM' },
  { text: '10:50 AM', value: '10:50:00 AM' },
  { text: '11:00 AM', value: '11:00:00 AM' },
  { text: '11:10 AM', value: '11:10:00 AM' },
  { text: '11:20 AM', value: '11:20:00 AM' },
  { text: '11:30 AM', value: '11:30:00 AM' },
  { text: '11:40 AM', value: '11:40:00 AM' },
  { text: '11:50 AM', value: '11:50:00 AM' },
  { text: '12:00 PM', value: '12:00:00 PM' },
  { text: '12:10 PM', value: '12:10:00 PM' },
  { text: '12:20 PM', value: '12:20:00 PM' },
  { text: '12:30 PM', value: '12:30:00 PM' },
  { text: '12:40 PM', value: '12:40:00 PM' },
  { text: '12:50 PM', value: '12:50:00 PM' },
  { text: '1:00 PM', value: '1:00:00 PM' },
  { text: '1:10 PM', value: '1:10:00 PM' },
  { text: '1:20 PM', value: '1:20:00 PM' },
  { text: '1:30 PM', value: '1:30:00 PM' },
  { text: '1:40 PM', value: '1:40:00 PM' },
  { text: '1:50 PM', value: '1:50:00 PM' },
  { text: '2:00 PM', value: '2:00:00 PM' },
  { text: '2:10 PM', value: '2:10:00 PM' },
  { text: '2:20 PM', value: '2:20:00 PM' },
  { text: '2:30 PM', value: '2:30:00 PM' },
  { text: '2:40 PM', value: '2:40:00 PM' },
  { text: '2:50 PM', value: '2:50:00 PM' },
  { text: '3:00 PM', value: '3:00:00 PM' },
  { text: '3:10 PM', value: '3:10:00 PM' },
  { text: '3:20 PM', value: '3:20:00 PM' },
  { text: '3:30 PM', value: '3:30:00 PM' },
  { text: '3:40 PM', value: '3:40:00 PM' },
  { text: '3:50 PM', value: '3:50:00 PM' },
  { text: '4:00 PM', value: '4:00:00 PM' },
  { text: '4:10 PM', value: '4:10:00 PM' },
  { text: '4:20 PM', value: '4:20:00 PM' },
  { text: '4:30 PM', value: '4:30:00 PM' },
  { text: '4:40 PM', value: '4:40:00 PM' },
  { text: '4:50 PM', value: '4:50:00 PM' },
  { text: '5:00 PM', value: '5:00:00 PM' },
  { text: '5:10 PM', value: '5:10:00 PM' },
  { text: '5:20 PM', value: '5:20:00 PM' },
  { text: '5:30 PM', value: '5:30:00 PM' },
  { text: '5:40 PM', value: '5:40:00 PM' },
  { text: '5:50 PM', value: '5:50:00 PM' },
  { text: '6:00 PM', value: '6:00:00 PM' },
  { text: '6:10 PM', value: '6:10:00 PM' },
  { text: '6:20 PM', value: '6:20:00 PM' },
  { text: '6:30 PM', value: '6:30:00 PM' },
  { text: '6:40 PM', value: '6:40:00 PM' },
  { text: '6:50 PM', value: '6:50:00 PM' },
  { text: '7:00 PM', value: '7:00:00 PM' },
  { text: '7:10 PM', value: '7:10:00 PM' },
  { text: '7:20 PM', value: '7:20:00 PM' },
  { text: '7:30 PM', value: '7:30:00 PM' },
  { text: '7:40 PM', value: '7:40:00 PM' },
  { text: '7:50 PM', value: '7:50:00 PM' },
  { text: '8:00 PM', value: '8:00:00 PM' },
  { text: '8:10 PM', value: '8:10:00 PM' },
  { text: '8:20 PM', value: '8:20:00 PM' },
  { text: '8:30 PM', value: '8:30:00 PM' },
  { text: '8:40 PM', value: '8:40:00 PM' },
  { text: '8:50 PM', value: '8:50:00 PM' },
  { text: '9:00 PM', value: '9:00:00 PM' },
  { text: '9:10 PM', value: '9:10:00 PM' },
  { text: '9:20 PM', value: '9:20:00 PM' },
  { text: '9:30 PM', value: '9:30:00 PM' },
  { text: '9:40 PM', value: '9:40:00 PM' },
  { text: '9:50 PM', value: '9:50:00 PM' },
  { text: '10:00 PM', value: '10:00:00 PM' },
  { text: '10:10 PM', value: '10:10:00 PM' },
  { text: '10:20 PM', value: '10:20:00 PM' },
  { text: '10:30 PM', value: '10:30:00 PM' },
  { text: '10:40 PM', value: '10:10:00 PM' },
  { text: '10:50 PM', value: '10:50:00 PM' },
  { text: '11:00 PM', value: '11:00:00 PM' },
  { text: '11:10 PM', value: '11:10:00 PM' },
  { text: '11:20 PM', value: '11:20:00 PM' },
  { text: '11:30 PM', value: '11:30:00 PM' },
  { text: '11:40 PM', value: '11:40:00 PM' },
  { text: '11:50 PM', value: '11:50:00 PM' },
]

export interface ImageModel {
  base64: string
  imageName: string
  TournamentId: number
}
export interface TournamentSeatModel{
  LayOutSectionId:number
  TournamentTicketSection:TournamentTicketSectionDetail[]
}
export interface TournamentTicketSectionDetail
{
    Id:number
    TicketId :number
    SectionName:string
    RowsPerSection:number
    SeatsPerRow:number
    OrderNumber:number
    SectionTicketPrice?:number
    PackageSectionTicketPrice?:number
    Selected?:boolean
    IsLayOut:boolean
    SectionShapeType:string
    ShapeProperties:string
    RowSpaceArrangement:RowSpaceData[]
}
export interface RowSpaceData{
  Id:number
  PreSpaceRow:number
  PostSpaceRow:number
  SectionId:string
}