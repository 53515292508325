import React, { ReactNode } from 'react'
import { cn } from 'utils'
import { useDropDownTableStyles } from './styles/useDropDownTableStyles'

export interface DropDownTableWarningProps {
  children: ReactNode
  className?: string
}

export const DropDownTableWarning = ({ children, className }: DropDownTableWarningProps) => {
  const styles = useDropDownTableStyles()

  return (
    <div className={cn(styles.tableWarning, className)}>
      <div className={styles.tableWarningContent}>
        <div className={styles.tableWarningText}>{children}</div>
      </div>
    </div>
  )
}
