import { ButtonLink, ButtonLinkProps } from 'components/_shared'
import { cn } from 'utils'
import { useDropDownTableStyles } from './styles/useDropDownTableStyles'

export type DropDownTableButtonLinkProps = ButtonLinkProps

export const DropDownTableButtonLink = ({ className, ...otherProps }: DropDownTableButtonLinkProps) => {
  const styles = useDropDownTableStyles()
  
  return <ButtonLink {...otherProps} className={cn(styles.tableButton, className)} />
}
