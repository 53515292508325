import { createSelector } from 'reselect'
import { OperationTypes } from '../logic/operations-logic'
import { ApplicationState } from '../types/common'
import { makeGetIsAnyOperationWithTypeRunning, makeGetIsOperationWithTypeSuccessOrRunning } from './operations-selectors'

const getState = (state: ApplicationState) => state.disputes

const getDisputesById = createSelector(getState, state => state?.disputesById || {})

export const getDisputesIds = createSelector(getState, state => (state?.disputesIds || []).sort((a, b) => b - a))

export const makeGetDispute = () =>
  createSelector(
    getDisputesById,
    (_: ApplicationState, id: number) => id,
    (byId, id) => byId[id]
  )

export const getAllDisputesCount = createSelector(getState, state => state?.allDisputesCount || 0)

export const getDisputesLoading = makeGetIsAnyOperationWithTypeRunning(() => ({
  type: OperationTypes.loadDisputes,
}))
export const getDisputesRequested = makeGetIsOperationWithTypeSuccessOrRunning(() => ({
  type: OperationTypes.loadDisputes,
  objectId: null,
}))
