import { Reducer } from 'redux'
import { COUNTRIES_LOADED, KnownAction } from 'store/types/country-types'
import { CountryState } from 'store/types/country-types'
import { calcById, groupArrayToObject } from 'utils'

const initialState: CountryState = {
  byId: {},
  idsByRegionId: {},
}

const reducer: Reducer<CountryState, KnownAction> = (state: CountryState | undefined = initialState, action: KnownAction) => {
  switch (action.type) {
    case COUNTRIES_LOADED:
      return {
        ...state,
        byId: calcById(state, action.payload.countries),
        idsByRegionId: {
          ...state.idsByRegionId,
          ...groupArrayToObject(action.payload.countries, x => x.RegionId, x => x.Id),
        },
      }
      break
    default:
      return state
  }
}

export default reducer
