import React, { ReactNode } from 'react'
import { ChatSearchInput } from '../ChatSearchInput/ChatSearchInput'
import styles from './ChatHeader.module.scss'
import { ChatBackButton, ChatHeaderContainer, ChatMenu, ChatName } from './_shared'
import ChatSearch from './_shared/ChatSearch/ChatSearch'

export interface ChatHeaderProps {
  children: ReactNode
}

export const ChatHeader = ({ children }:  ChatHeaderProps) => {

  return (
    <div className={styles.chatHeader}>
      <div className="row h-100 m-0 align-items-center">
        {children}
      </div>
    </div>
  )
}

ChatHeader.BackButton = ChatBackButton
ChatHeader.Container = ChatHeaderContainer
ChatHeader.Menu = ChatMenu
ChatHeader.Name = ChatName
ChatHeader.Search = ChatSearch
