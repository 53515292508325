import { ContainerName, useTournamentParticipant } from 'components/_hooks'
import { KlLink, Button } from 'components/_shared'
import React from 'react'
import ParticipantOnlineAvatar from 'components/_shared/ParticipantAvatar/ParticipantOnlineAvtar'
import {
  ButtonsColumn,
  DateColumn,
  DeadlineColumn,
  TimeColumn,
  UserColumn,
  BattleSecondaryRow,
  ButtonColumn,
} from './BattleRow/BattleRow'
import { useOpenUserChat } from 'store/chat/chat/chat-hooks'

interface MatchParticipantWithHooksProps {
  participantId: number
}

export const TournamentParticipantRow = ({ participantId }: MatchParticipantWithHooksProps) => {
  const { name, profileUrl, playerId, clanLeaderId,IsBot } = useTournamentParticipant(participantId)
  const openUserChat = useOpenUserChat()

  const handleContactClick = () => {
    openUserChat(clanLeaderId ?? playerId)
  }

  return (
    <BattleSecondaryRow>
      <UserColumn className="d-flex flex-row align-items-center flex-wrap old-kl-css">
        <ParticipantOnlineAvatar
          withLink={!IsBot}
          participantId={participantId}
          className="table__profile-picture"
          parentClassName="prsn_icon-indicator-position"
          containerName={ContainerName.AvatarsNormal}
          
        />
        {!IsBot? <KlLink to={profileUrl} className="table__link">
          {name}
        </KlLink>:
          'By-Pass'
        }
      </UserColumn>
      {!IsBot && <ButtonColumn >
        <Button color="grey" onClick={handleContactClick} low>Contact</Button>
      </ButtonColumn>}
      <DateColumn />
      <TimeColumn />
      <DeadlineColumn />
      <ButtonsColumn />
    </BattleSecondaryRow>
  )
}
