import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { makeGetRoundMatchesIds } from 'store/selectors/match-selectors'
import { makeGetRound } from 'store/selectors/rounds-selectors'
import { makeGetTournament } from 'store/selectors/tournaments-selectors'
import { ApplicationState } from 'store/types/common'
import { getMaxParticipantsNumberPerMatch, TournamentType } from 'store/types/tournaments-types'
import { cn } from 'utils'
import { BracketMatchesGroup } from './BracketMatchesGroup'

const calcOffset = (firstRoundMatches: number, currentRoundMatches: number) => {
  return (firstRoundMatches - currentRoundMatches) / currentRoundMatches
}

const arrayToGroups = (tournament: TournamentType, arr: number[]): number[][] => {
  if (arr.length === 1) {
    return [arr]
  }
  const groups = []
  let group = []

  const maxValue = getMaxParticipantsNumberPerMatch(tournament)

  arr.forEach(value => {
    if (group.length < maxValue) {
      group.push(value)
    }
    if (group.length === maxValue) {
      groups.push(group)
      group = []
    }
  })
  return groups
}

export interface BracketRoundProps {
  roundId: number
  onParticipantSelected: (participantId?: number) => void
  selectedParticipantId?: number
  halfOfUsersGroupHeight: number
  firstRoundId: number
  filter: string
  openSubmissionResultModal: (v?: any) => void
}

export const BracketRound = ({ roundId, onParticipantSelected, selectedParticipantId, halfOfUsersGroupHeight, firstRoundId, filter,openSubmissionResultModal }: BracketRoundProps) => {
  const getRound = useMemo(makeGetRound, [])
  const getRoundMatchesIds = useMemo(makeGetRoundMatchesIds, [])
  const getFirstRoundMatchesIds = useMemo(makeGetRoundMatchesIds, [])
  const getTournament = useMemo(makeGetTournament, [])

  const round = useSelector((state: ApplicationState) => getRound(state, roundId))
  const matchesIds = useSelector((state: ApplicationState) => getRoundMatchesIds(state, roundId))
  const tournament = useSelector((state: ApplicationState) => getTournament(state, round.TournamentDetailId))
  const firstRoundMatchesIds = useSelector((state: ApplicationState) => getFirstRoundMatchesIds(state, firstRoundId))

  const matchesIdsGroups = useMemo(() => {
    return arrayToGroups(tournament, matchesIds)
  }, [matchesIds])

  const margin = useMemo(() => {
    if (matchesIds.length === 0 || firstRoundMatchesIds.length === 0) {
      return 0
    }

    const offset = calcOffset(firstRoundMatchesIds.length, matchesIds.length)

    return offset * halfOfUsersGroupHeight
  }, [round, matchesIds, halfOfUsersGroupHeight])

  const isMajorRound = useMemo(() => {
    return round.IsLosersBracket && round.RoundNumber % 2 === 0
  }, [round])

  const colClassName = round.IsLosersBracket ? (isMajorRound ? 'eli_grid--extra_col' : 'eli_grid--brackets_col') : 'eli_grid--elimination_col'

  return (
    <div className={cn('eli_col', colClassName)}>
      {matchesIdsGroups.map((ids, index) => (
        <BracketMatchesGroup
          key={index}
          matchesIds={ids}
          margin={round.IsLosersBracket ? 0 : margin}
          matchMargin={!round.IsLosersBracket || isMajorRound ? margin : 0}
          onParticipantSelected={onParticipantSelected}
          selectedParticipantId={selectedParticipantId}
          filter={filter}
          openSubmissionResultModal= {openSubmissionResultModal}
        />
      ))}
    </div>
  )
}
