import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { makeGetDispute } from 'store/selectors/dispute-selectors'
import { ApplicationState } from 'store/types/common'
import { DisputeModel } from 'store/types/dispute-types'
import { MatchModel } from 'store/types/match-types'
import { RoundType } from 'store/types/rounds-types'
import { TournamentType } from 'store/types/tournaments-types'
import { useMatch } from './useMatch'

interface UseDisputeResult {
  tournament?: TournamentType
  round?: RoundType
  match?: MatchModel
  dispute?: DisputeModel
}

export const useDispute = (disputeId?: number): UseDisputeResult => {
  const getDispute = useMemo(makeGetDispute, [])
  const dispute = useSelector((state: ApplicationState) => getDispute(state, disputeId))
  const { tournament, round, match } = useMatch(dispute?.MatchId)

  return {
    tournament,
    round,
    match,
    dispute,
  }
}
