import React, { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { cn } from 'utils'
import stylesDark from '../Tabs.module.scss'
import stylesLight from '../TabsLight.module.scss'
import { useThemeStyles } from 'components/_hooks'

export interface TabLinkProps {
  active?: boolean
  url: string
  children: ReactNode
  hasNotifications?: boolean
}

export const TabLink = ({ active, url, children, hasNotifications }: TabLinkProps) => {
  const styles = useThemeStyles(stylesDark, stylesLight)
  
  return (
    <Link className={cn(styles.tabMenuLink, active && styles.active, hasNotifications && styles.notification, 'col-auto')} to={url}>{children}</Link>
  )
}
