import React, { ReactNode, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { notificationActionCreators } from 'store/logic/notification-logic'
import { loadApp, loadAppUser } from 'store/logic/app-logic'
import { getAppUser, getIsAppLoading, getIsAppRequested, getIsAppUserLoading } from 'store/selectors/app-selectors'
import { ChatWindow, LoadingBox } from 'components/_shared'
import { useHistory, useLocation } from 'react-router'
import { useIsChatOpened } from 'store/chat/chat/chat-hooks'
import { useStartSignalr } from 'store/signalr/signalr'
import SignalRConnection from './SignalRConnection'
import { setMeta } from './setMeta'
import { GeneralNotification } from './_shared/GeneralNotification'

interface AppContainerProps {
  children?: ReactNode
}

const AppContainer = ({ children }: AppContainerProps) => {
  const dispatch = useDispatch()
  const user = useSelector(getAppUser)
  const isAppUserLoading = useSelector(getIsAppUserLoading)
  const isAppLoading = useSelector(getIsAppLoading)
  const isAppRequested = useSelector(getIsAppRequested)
  const location = useLocation()
  const isChatOpened = useIsChatOpened()
  const history = useHistory()
  useStartSignalr()

  useEffect(() => {
    dispatch(loadApp)
    dispatch(loadAppUser)
  }, [dispatch])

  useEffect(() => {
    if (user) {
      dispatch(notificationActionCreators.loadNotificationQueue())
    }
  }, [dispatch, user])

  useEffect(() => {
    if (user) {
      dispatch(notificationActionCreators.initNotifications())
    }
  }, [dispatch, user])

  const bodyBackground = useMemo(() => {
    if (location.pathname.includes('/admin')) {
      if (location.pathname === '/admin/login') {
        return '#051534'
      }
      return '#f5f7fa'
    } else {
      return '#051534'
    }
  }, [location.pathname])

  useEffect(() => {
    document.body.style.background = bodyBackground
  }, [bodyBackground])

  useEffect(() => {
    setMeta(location.pathname)
    fbq('track', 'PageView')
    gtag('event','page_view')
  }, [location.pathname])

  const loading = !isAppRequested || isAppLoading || isAppUserLoading

  return (
    <>
      {loading
        ? (
          <LoadingBox loading size="extra" fullScreen />
        )
        : (
          <>
            {children}
            {user && (
              <SignalRConnection />
            )}
            {isChatOpened && <ChatWindow externalHistory={history} />}          
          </>
        )

      }
      <GeneralNotification/>
    </>
  )
}

export default AppContainer
