import React, { ReactNode } from 'react'
import { cn } from 'utils'
import styles from './ChatHeaderContainer.module.scss'

export interface ChatHeaderContainerProps {
  children: ReactNode
}

export const ChatHeaderContainer = ({ children }:  ChatHeaderContainerProps) => {
  return (
    <div className={cn('col', styles.chatHeaderHeaderContainer)}>
      <h4 className={styles.chatHeaderHeader}>{children}</h4>
    </div>
  )
}
