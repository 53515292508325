import React from 'react'
import { useCarousel } from './useCarousel'
import styles from './Carousel.module.scss'
import { cn } from 'utils'

export interface CarouselProps<T> {
  values: T[]
  window: number
  className?: string
  renderItem: (value: T, index: number, onClick: (value: T) => void) => JSX.Element
  onClick?: (value: T) => void
}

export const Carousel = <T extends unknown>({ values, window, className, renderItem, onClick } : CarouselProps<T>) => {
  const { forward, back, items, offset, withTransition } = useCarousel(values, window)
  
  const handleItemClick = (item: T) => () => {
    if (onClick) {
      onClick(item)
    }
  }

  return (
    <div className="d-flex">
      {items.length >= window &&
        <div className="d-flex flex-column">
          <div className={cn(styles.arrowButton, 'd-flex mr-2')} onClick={back}>
            <img src="https://kombatlink-assets.azureedge.net/img/grey_arrow_top.svg" className={cn(styles.arrow, styles.arrowleft)} />
          </div>
        </div>
      }
      <div className="overflow-hidden">
        <div
          className={className}
          style={{
            transform: `translate3d(${offset * 110}px, 0px, 0px)`,
            transition: withTransition ? 'transform 500ms ease 0s' : '',
          }}
        >
          {items.map((value, index) => renderItem(value, index, handleItemClick(value)))}
        </div>
      </div>
      {items.length >= window &&
        <div className="d-flex flex-column">
          <div className={cn(styles.arrowButton, 'd-flex ml-2')} onClick={forward}>
            <img src="https://kombatlink-assets.azureedge.net/img/grey_arrow_top.svg" className={cn(styles.arrow, styles.arrowRight)} />
          </div>
        </div>
      }
    </div>
  )
}
