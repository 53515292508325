import React, { ReactNode } from 'react'
import { cn } from 'utils'
import styles from '../modals.module.scss'
import { ModalClose } from './ModalClose'

export interface ModalWindowProps {
  children?: ReactNode
  className?: string
  onClose: CallableFunction
  disabled?: boolean
}

export const ModalWindow = ({ children, className, onClose, disabled }: ModalWindowProps) => {
  const handleClose = () => {
    onClose()
  }

  return (
    <div className={cn(styles.window, className)}>
      <ModalClose onClick={handleClose} disabled={disabled} />
      <div className="container-fluid">{children}</div>
    </div>
  )
}
