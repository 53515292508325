import React from 'react'
import { DropDownTable, DropDownTableColumnProps, DropDownTableRowColumnProps, DropDownTableSecondaryRowColumnProps } from 'components/_shared'
import { cn } from 'utils'
import styles from '../Disputes.module.scss'

const MakeDropDownTableColumn = (customClassName?: string) => ({ children, className }: DropDownTableColumnProps) => {
  return <DropDownTable.Column className={cn(styles.tableColumn, customClassName, className)}>{children}</DropDownTable.Column>
}

export const DropDownTableRowColumn = ({ children, className }: DropDownTableRowColumnProps) => {
  return <DropDownTable.RowColumn className={cn(styles.tableColumn, className)}>{children}</DropDownTable.RowColumn>
}

export const DropDownTableSecondaryRowColumn = ({ children, className }: DropDownTableSecondaryRowColumnProps) => {
  return <DropDownTable.SecondaryRowColumn className={cn(styles.tableColumn, className)}>{children}</DropDownTable.SecondaryRowColumn>
}

export const DropDownTableColumn = MakeDropDownTableColumn()
export const HeadersRowButtonsColumn = MakeDropDownTableColumn(cn(styles.tableColumnButtons, styles.tableHeadersRowColumnButtons))
export const MainRowButtonsColumn = MakeDropDownTableColumn(cn(styles.tableColumnButtons, styles.tableMainRowColumnButtons))
export const SecondaryRowButtonsColumn = MakeDropDownTableColumn(cn(styles.tableColumnButtons, styles.tableSecondaryRowColumnButtons))
