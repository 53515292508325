import { Dispatch } from 'redux'
import { fetchApi } from '../../server/server-utils'
import { GetState } from '../types/common'
import { appLoaded, App, AppUser, appUserLoaded } from '../types/app-types'
import { cancel, OperationTypes, run } from './operations-logic'
import { getIsAppRequested, getIsAppUserRequested } from 'store/selectors/app-selectors'

export const loadApp = async (dispatch: Dispatch, getState: GetState) => {
  if (getIsAppRequested(getState())) {
    return
  }
  await run(OperationTypes.loadApp, null, dispatch, getState, async () => {
    try {
      const app = await fetchApi<App>('app', 'GET')
      dispatch(appLoaded(app))
    } catch (error) {
      return false
    }
    return true
  })
}

export const loadAppUser = async (dispatch: Dispatch, getState: GetState) => {
  if (getIsAppUserRequested(getState())) {
    return
  }
  await run(OperationTypes.loadAppUser, null, dispatch, getState, async () => {
    try {
      const appUser = await fetchApi<AppUser>('app/user', 'GET')
      dispatch(appUserLoaded(appUser))
    } catch (error) {
      return false
    }
    return true
  })
}

export const reLoadAppUser = async (dispatch: Dispatch, getState: GetState) => {
  cancel(OperationTypes.loadAppUser, null, dispatch, getState)
  await loadAppUser(dispatch, getState)
}
