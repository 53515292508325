import * as React from 'react'
import { UserModel } from '../../models/user-models'
import { UserUtils } from '../../server/utils/user-utils'
import { PaymentMethodModel, brandImages, Kl_OrganizerPayout, payoutPaymentMethod } from '../../models/billing-option-model'
import { StripeUtils } from '../../server/utils/stripe-utils'
import { BillingOptionUtils } from '../../server/utils/billing-option-utils'
import BillingOptionDailog from './BillingOptionDailog'
import CoinDialog from './CoinDialog'
import { GeneralUtils } from '../../server/utils/general-utils'
const stripeElements = require('react-stripe-elements')
const CardNumberElement = stripeElements.CardNumberElement
const CardExpiryElement = stripeElements.CardExpiryElement
const CardCvcElement = stripeElements.CardCvcElement
import './BillingOptionPlayer.scss'
import { withStripe } from 'components/_shared/withStripe'
import { LocationUtils } from 'server/utils/location-utils'
import { Loading } from 'components/_shared'
const postalCodes = require('postal-codes-js');
interface State {
  userPersonalInfo: UserModel
  paymentMethod: PaymentMethodModel
  isCardEdit: boolean
  paymentMethodList: PaymentMethodModel[]
  organizerPayout: Kl_OrganizerPayout
  showDialouge: boolean
  userCountry: string
  userCountryCode: string
  isCountrySupported: boolean
  isVerified:boolean
  loading:boolean
  stripeCardNumberValid:boolean
  stripeCardCvcValid: boolean
  stripeCardExpValid: boolean
  submitted:boolean
  isFormValid:boolean
  primaryCardId?:number
}

class BillingOptionPlayer extends React.Component<any, State> {
  constructor(props: any) {
    super(props)
    this.state = {
      userPersonalInfo: Object(),
      paymentMethod: Object(),
      isCardEdit: false,
      paymentMethodList: [],
      organizerPayout: Object(),
      showDialouge: false,
      userCountry: '',
      userCountryCode: '',
      isCountrySupported: true,
      isVerified:false,
      loading:false,
      stripeCardNumberValid:true,
      stripeCardCvcValid:true,
      stripeCardExpValid:true,
      submitted:false,
      isFormValid:true,
      primaryCardId:0
    }

    this.handlePersonalInfo = this.handlePersonalInfo.bind(this)
    this.onChangePersonalInfo = this.onChangePersonalInfo.bind(this)
    this.updatePersonalInfo = this.updatePersonalInfo.bind(this)
    this.handlePayment = this.handlePayment.bind(this)
    this.getStripeToken = this.getStripeToken.bind(this)
    this.onChangeCard = this.onChangeCard.bind(this)
    this._createPaymentMethod = this._createPaymentMethod.bind(this)
    this._getPaymentMethod = this._getPaymentMethod.bind(this)
    this._deletePaymentMethod = this._deletePaymentMethod.bind(this)
    this._getOrganizerPayout = this._getOrganizerPayout.bind(this)
    this.onChangeTournamentFilters = this.onChangeTournamentFilters.bind(this)
    this.isOrganizerVerified = this.isOrganizerVerified.bind(this)
    this.getStripeSupportedCountry = this.getStripeSupportedCountry.bind(this)
    this.setLoading = this.setLoading.bind(this)
    this.initializePaymentObject = this.initializePaymentObject.bind(this)
    this.setAsPrimary = this.setAsPrimary.bind(this)
    this.validPostalCode = this.validPostalCode.bind(this)
  }

  async componentDidMount() {
    const { paymentMethod } = this.state

    const user: UserModel = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : Object()
    paymentMethod.userid = user.id
    paymentMethod.cardType = '1'
    
    this.setState({
      paymentMethod,
    })
    this.handlePersonalInfo(user.id)
    this._getPaymentMethod()
    const data: any = await LocationUtils.getCoords()
    const location = await LocationUtils.GetLocationInfo('country', data.latitude, data.longitude)
    this.setState(
      {
        userCountry: location.CountryName,
        userCountryCode: location.CountryCode,
      },
      () => {
        if (user.isorganizer) {
          this.getStripeSupportedCountry()
          this._getOrganizerPayout()
        }
        paymentMethod.country = location.CountryName
      }
    )
  }

  async handlePersonalInfo(userid: number) {
    const result = await UserUtils.GetAllByID(userid)
    this.setState({
      userPersonalInfo: result.data,
    })
  }

  onChangePersonalInfo(e) {
    const { userPersonalInfo } = this.state
    userPersonalInfo[e.target.name] = e.target.value
    this.setState({
      userPersonalInfo,
    })
  }

  onChangeCard(e) {
    const { paymentMethod } = this.state
    paymentMethod[e.target.name] = e.target.value
    this.setState({
      paymentMethod,
    })
  }

  async updatePersonalInfo() {
    
    const { userPersonalInfo } = this.state
    var result = await UserUtils.Save(userPersonalInfo)
    if(!result?.isError){
      this.setState({
        loading:false
      },()=>{
        document.getElementById('modal-edit-personal').className = 'bil_modal-container modal-container'
      })
    }
    
  }

  async handlePayment(e) {
    const { elements } = this.props
    const { paymentMethod } = this.state
    const cardNumberError = elements.getElement("cardNumber")
    const cardExpiryError = elements.getElement("cardExpiry")
    const cardCvcError = elements.getElement("cardCvc")
    const isEmpty = paymentMethod.fname && paymentMethod.lname && paymentMethod.country && paymentMethod.address && paymentMethod.postal && paymentMethod.City && this.validPostalCode(paymentMethod.postal)?false:true
    try{
      this.setState(
        {
          
          stripeCardNumberValid: cardNumberError._complete,
          stripeCardExpValid: cardExpiryError._complete,
          stripeCardCvcValid: cardCvcError._complete,
          isFormValid:!isEmpty
        },
        () => {
          const {
            stripeCardNumberValid,
            stripeCardExpValid,
            stripeCardCvcValid,
          } = this.state
          if (stripeCardNumberValid && stripeCardExpValid && stripeCardCvcValid && !isEmpty) {
            this.getStripeToken(e)
          }
          else{
            this.setLoading(false)
          }
        }
      )
    }
    catch{
      this.setState({
        loading:false
      },()=>{
        GeneralUtils.showHideLoadingDiv(false)
      })
    }
    
  }

  async getStripeToken(e) {
    const { paymentMethod } = this.state
    const zip = paymentMethod.postal
    if (!zip && isNaN(parseInt(zip))) {
    } else {
      const token = await this.props.stripe.createToken({
        name: paymentMethod.fname,
        address_zip: zip,
        address_city: paymentMethod.City,
        address_country: paymentMethod.country,
        address_line1: paymentMethod.address,
      })
      if (!token.isError) {
        paymentMethod.stripeToken = token.token.id
        this.setState(
          {
            paymentMethod,
          },
          async () => {
            await this._createPaymentMethod()
          }
        )
      }
    }
  }

  async _createPaymentMethod() {
    const { paymentMethod,paymentMethodList } = this.state
    GeneralUtils.showHideLoadingDiv(true)
    const user: UserModel = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : Object()
    paymentMethod.email = user.emailaddress
    paymentMethod.isPrimary = paymentMethodList.length === 0?true:false
    paymentMethod.country = this.state.userCountry
    const res = await StripeUtils.createPaymentMethod(paymentMethod)
    if (!res?.isError){
      this.setState({
        loading:false,
        submitted:false
      },async ()=>{
        this.initializePaymentObject()
        GeneralUtils.showHideLoadingDiv(true)
        document.getElementById('modal-edit-card').className = 'bil_modal-container modal-container'
        await this._getPaymentMethod()
      })
    }
    
  }

  async _getPaymentMethod() {
    GeneralUtils.showHideLoadingDiv(true)
    const res = await StripeUtils.getPaymentMethod()
    if (!res?.isError) {
      this.setState({
        paymentMethodList: res?.data,
      },()=>{
        GeneralUtils.showHideLoadingDiv(false)
        
      })
    } else {
      GeneralUtils.showHideLoadingDiv(false)
    }
  }

  async _deletePaymentMethod(id: number) {
    GeneralUtils.showHideLoadingDiv(true)
    const res = await StripeUtils.deletePaymentMethod(id)
    if (!res.isError) {
      this._getPaymentMethod()
    }
    else{
      GeneralUtils.showHideLoadingDiv(false)
    }
  }

  async _getOrganizerPayout() {
    const user: UserModel = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : Object()
    if (user.id && user.isorganizer) {
      const res = await BillingOptionUtils.getOrganizerPayout()
      if (res.data) {
        this.setState({
          organizerPayout: res.data,
        })
      }
    }
  }

  onChangeTournamentFilters(properyName: string, val: string) {
    const { paymentMethod } = this.state
    paymentMethod[properyName] = val
    this.setState({
      paymentMethod,
    },()=>{
      document.getElementById('modal-edit-card').className = 'bil_modal-container modal-container modal-container--opened'
    })
  }
  isOrganizerVerified(data, result) {
    const user: UserModel = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : Object()
    this.setState(
      {
        showDialouge: false,
      },
      () => {
        user.IsVerified = false
        if (result !== null) {
          user.IsVerified = result.PaymentMethod == 2 ? true : false
          const { organizerPayout } = this.state
          organizerPayout.PaymentMethodId = result.PaymentMethod
          this.setState({ organizerPayout })
        }
        localStorage.setItem('user', JSON.stringify(user))
        if (data && data.hasOwnProperty('AccountLinkUrl') && data.AccountLinkUrl !== '') {
          user.Stripe_IdentityUrl = data.AccountLinkUrl
          localStorage.setItem('user', JSON.stringify(user))
          window.location.href = data.AccountLinkUrl
        }
      }
    )
  }
  async getStripeSupportedCountry() {
    const country = await StripeUtils.GetStripeSupportedCountry(this.state.userCountry)
    const user: UserModel = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : Object()
    const isCountrySupported = !country.isError
    let isVerified = false
    if(isCountrySupported){
      isVerified = user.IsVerified
    }
    else if(!isCountrySupported){
      isVerified = true
    }
    this.setState({
      isVerified,
    })
  }
  setLoading(loading:boolean){
    this.setState({
      loading:loading
    })
  }
  handleCardElementOnChange= (element, name: string,isCard:boolean) => {
    this.setState({
      ...this.state,
      [name]: isCard?(!element.empty && element.complete ? true : false):!element.empty?true:false,
    })
  }
  initializePaymentObject(){
    const { elements } = this.props
    
    const cardNumberError = elements.getElement("cardNumber")
    const cardExpiryError = elements.getElement("cardExpiry")
    const cardCvcError = elements.getElement("cardCvc")
    const paymentObject = Object() as PaymentMethodModel
    paymentObject.country = ''
    paymentObject.cardNo = ''
    paymentObject.cvc = ''
    paymentObject.email = ''
    paymentObject.expMonth = 0
    paymentObject.expYear = 0
    paymentObject.fname = ''
    paymentObject.lname = ''
    paymentObject.id = 0
    paymentObject.postal = ''
    paymentObject.stripeToken = ''
    paymentObject.userid = 0
    paymentObject.City = ''
    paymentObject.cardType = ''
    paymentObject.address = ''
    if(cardNumberError && cardExpiryError && cardCvcError){
      cardNumberError.clear()
      cardExpiryError.clear()
      cardCvcError.clear()
    }
    this.setState({
      paymentMethod:{...paymentObject},
      submitted:false,
      isFormValid:true
      
    })
  }
  async UpdatePaymentMethodToPrimary(id:number){
    this.setLoading(true)
    const result = await StripeUtils.UpdatePaymentMethodToPrimary(id)
    if(!result?.isError){
      this.setAsPrimary(id)
      this.setLoading(false)
      GeneralUtils.showSuccess("Payment method has been set to primary successfully")
      document.getElementById('modal-update-primary-method').className = 'bil_modal-container modal-container'
    }
    else{
      this.setLoading(false)
    }
    
  }
  setAsPrimary(id:number){
    const {paymentMethodList } = this.state
    paymentMethodList.forEach(x=>x.isPrimary = false)
    this.setState({
      paymentMethodList:[...paymentMethodList]
    },()=>{
      let paymentMethodIndex = paymentMethodList.findIndex(x=>x.id === id)
      paymentMethodList[paymentMethodIndex].isPrimary  = true
      this.setState({
        paymentMethodList:[...paymentMethodList]
      })
    })

  
    
  }
  validPostalCode(postalCode:any){
    const {userCountryCode} = this.state
    const isValid = postalCodes.validate(userCountryCode, postalCode)
    return isValid ===true?true:false
  }
  render() {
    const { submitted,isFormValid,userPersonalInfo, isCardEdit, paymentMethod, paymentMethodList, organizerPayout,stripeCardNumberValid,stripeCardCvcValid,stripeCardExpValid } = this.state
    const user: UserModel = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : Object()
    const payment =
      organizerPayout &&
      organizerPayout.PaymentMethodId &&
      payoutPaymentMethod.find(e => e.Id === (organizerPayout && organizerPayout.PaymentMethodId))
    return (
      <>
        <div className="bil">
          <div className="bil_ttl">
            <div className="bil_ttl_l">
              <h1 className="article__header">Billing option</h1>
            </div>
            <div className="pfl_ttl_r" />
          </div>
          <div className="row">
            <div className="bil_col-left col-12 col-xl-5">
              <div className="bil_inf">
                <div className="bil_inf_t">
                  <div className="bil_inf_t_l">
                    <h2 className="bil_inf_ttl">Personal info</h2>
                  </div>
                  <div className="bil_inf_t_r d-none">
                    <button
                      className="bttn-green-outline open_modal-edit-personal"
                      onClick={() => {
                        document.getElementById('modal-edit-personal').className =
                          document.getElementById('modal-edit-personal').className.indexOf('modal-container--opened') > -1
                            ? 'bil_modal-container modal-container'
                            : 'modal-container modal-container--opened'
                      }}
                    >
                      edit Details
                    </button>
                  </div>
                </div>
                <div className="bil_inf_b">
                  {user.isorganizer && <div className="data_list data_list--vertical">
                    {/*data_list--horisontal */}
                    <div className="data_list_i">
                      <div className="data_list_txt">
                        <div className="data_list_txt_i">
                          <span className="data_list_type">First name</span>
                        </div>
                        <div className="data_list_txt_i">
                          <span className="data_list_ttl">{userPersonalInfo.fname}</span>
                        </div>
                      </div>
                    </div>
                    <div className="data_list_i">
                      <div className="data_list_txt">
                        <div className="data_list_txt_i">
                          <span className="data_list_type">Last name</span>
                        </div>
                        <div className="data_list_txt_i">
                          <span className="data_list_ttl">{userPersonalInfo.lname}</span>
                        </div>
                      </div>
                    </div>
                    {/* <div className="data_list_i">
                      <div className="data_list_txt">
                        <div className="data_list_txt_i">
                          <span className="data_list_type">Address</span>
                        </div>
                        <div className="data_list_txt_i">
                          <span className="data_list_ttl">{userPersonalInfo.address}</span>
                        </div>
                      </div>
                    </div> */}
                  </div>}
                  {!user.isorganizer && <div className="data_list data_list--vertical">
                  <div className="data_list_i">
                      <div className="data_list_txt">
                        <div className="data_list_txt_i">
                          <span className="data_list_type">User name</span>
                        </div>
                        <div className="data_list_txt_i">
                          <span className="data_list_ttl">{userPersonalInfo.username}</span>
                        </div>
                      </div>
                    </div>
                    
                    </div>}
                </div>
              </div>
              {user.isorganizer && (
                <div className="bil_inf">
                  <div className="bil_inf_t">
                    <div className="bil_inf_t_l">
                      <h2 className="bil_inf_ttl">Payout Section</h2>
                    </div>
                    <div className="bil_inf_t_r">
                      {!user.IsVerified && user.Stripe_IdentityUrl === null && (
                        <button
                          className="bttn-blue-outline open_modal-edit-payout"
                          onClick={async () => {
                            this.setState({ showDialouge: true })
                          }}
                        >
                          edit Details
                        </button>
                      )}
                      {!user.IsVerified && user.Stripe_IdentityUrl !== null && (
                        <button
                          className="bttn-blue-outline open_modal-edit-payout"
                          onClick={async () => {
                            window.location.href=user.Stripe_IdentityUrl
                          }}
                        >
                          edit Details
                        </button>
                      )}
                    </div>
                    {this.state.showDialouge && (
                      <BillingOptionDailog
                        organizerPayout={organizerPayout}
                        showClass="modal-container--opened"
                        verified={this.isOrganizerVerified}
                        userCountry={this.state.userCountry}
                        userCountryCode={this.state.userCountryCode}
                        currentRoute="my-profile"
                      />
                    )}
                    {/* <div className="bil_inf_t_r">
                                                                <button className="bttn-blue-outline open_modal-edit-payout">edit Details</button>
                                                            </div> */}
                  </div>
                  <div className="bil_mid">
                    <p className="bil_txt">This is currently your preferred payout option.</p>
                  </div>
                  <div className="bil_inf_b">
                    <div className="data_list data_list--vertical">
                      <div className="data_list_i">
                        <div className="data_list_txt">
                          <div className="data_list_txt_i">
                            <span className="data_list_type">Payment method</span>
                          </div>
                          <div className="data_list_txt_i">
                            <span className="data_list_ttl">{payment ? payment.PaymentMethod : ''}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {user.isorganizer && this.state.isVerified &&  (
                <div className="bil_inf">
                  <div className="bil_inf_t">
                    <div className="bil_inf_t_l">
                      <h2 className="bil_inf_ttl">Cryptocurrency</h2>
                    </div>
                    <CoinDialog organizerPayout={organizerPayout} />
                    {/* <div className="bil_inf_t_r">
                                                                <button className="bttn-blue-outline open_modal-edit-payout">edit Details</button>
                                                            </div> */}
                  </div>
                  <div className="bil_inf_b">
                    <div className="data_list data_list--vertical">
                      <div className="data_list_i">
                        <div className="data_list_txt">
                          <div className="data_list_txt_i">
                            <span className="data_list_type">Payment method</span>
                          </div>
                          <div className="data_list_txt_i">
                            <span className="data_list_ttl">Coin </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div style={{ minHeight: '23vh' }} />
            {!user.isorganizer && (
              <div className="bil_col-right col-12 col-xl-7">
                <div className="bil_payments">
                  <h2 className="bil_payments_ttl">Payment preference section</h2>
                  {paymentMethodList &&
                    paymentMethodList.length > 0 &&
                    paymentMethodList.map((n, i) => {
                      return (
                        <div key={n.id} className={`bil_pay ${n.isPrimary? 'highlight-primary-card':''}`}>
                          <input id={`open-payment-${i}`} className="toggle--bil_pay_body d-none" type="checkbox" />
                          <div className="bil_pay_head">
                            <div className="bil_pay_head_l">
                              <div className="bil_pay_head_l_i">
                                <div className="bil_pay_head_img">
                                  <img src={brandImages.find(b => b.brand == n.brand).imgSrc} alt="" />
                                </div>
                              </div>
                              <div className="bil_pay_head_l_i">
                                <span className="bil_pay_h_lead_ttl">Card</span>
                              </div>
                              <div className="bil_pay_head_l_i">
                                <span className="bil_pay_head_ttl">Ending in {n.cardNo}</span>
                              </div>
                              <div className="bil_pay_head_l_i white-tooltip-theme">
                                <div className="bil_pay_head_btn">
                                  <div className="bil_pay_head_btn_l">
                                    <span className="bil_pay_head_ttl">
                                      {n.expMonth} / {n.expYear}
                                    </span>
                                  </div>
                                  <div className="bil_pay_head_btn_r">
                                    <button
                                      className="btn-more-info btn-tooltip"
                                      data-tippy-content="<p>Expired, please update card details</p>"
                                      data-tippy-interactive="true"
                                      aria-expanded="false"
                                    >
                                      <img src="https://kombatlink-assets.azureedge.net/img/info.svg" alt="" />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="bil_pay_head_r">
                            {n.isPrimary && <div className="bil_pay_head_r_i"><button disabled className="bil_pay_head_edit btn-primary-card">Primary Card</button></div>}
                            {!n.isPrimary && <div className="bil_pay_head_r_i">
                            
                            <button
                                  className="bil_pay_head_edit btn-primary-card"
                                  onClick={async () => {
                                    this.setState({
                                      primaryCardId:n.id
                                    },()=>{
                                      document.getElementById('modal-update-primary-method').className ='modal-container modal-container--opened'
                                    })
                                    
                                  }}
                                >SET AS PRIMARY CARD</button>
                              </div>}
                              <div className="bil_pay_head_r_i">
                                <button
                                  className="bil_pay_head_edit open_modal-edit-card"
                                  type="button"
                                  onClick={() => {
                                    this.setState({
                                      isCardEdit: true,
                                      paymentMethod: n,
                                    })
                                    document.getElementById('modal-edit-card').className =
                                      document.getElementById('modal-edit-card').className.indexOf('modal-container--opened') > -1
                                        ? 'bil_modal-container modal-container'
                                        : 'modal-container modal-container--opened'
                                  }}
                                >
                                  View
                                </button>
                              </div>
                              <div className="bil_pay_head_r_i">
                                <button
                                  className="bil_pay_head_delete"
                                  onClick={async () => {
                                    await this._deletePaymentMethod(n.id)
                                  }}
                                />
                              </div>
                              <div className="bil_pay_head_r_i">
                                <label
                                  className="bil_pay_head_toggle"
                                  onClick={() => {
                                    document.getElementById('open-payment-'+i).click()
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="bil_pay_body">
                            <div className="bil_pay_body_inn">
                              <div className="data_list data_list--horisontal">
                                {/*data_list--vertical */}
                                <div className="data_list_i">
                                  <div className="data_list_txt">
                                    <div className="data_list_txt_i">
                                      <span className="data_list_type">First name</span>
                                    </div>
                                    <div className="data_list_txt_i">
                                      <span className="data_list_ttl">{n.fname} </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="data_list_i">
                                  <div className="data_list_txt">
                                    <div className="data_list_txt_i">
                                      <span className="data_list_type">Last name</span>
                                    </div>
                                    <div className="data_list_txt_i">
                                      <span className="data_list_ttl">{n.lname} </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="data_list_i">
                                  <div className="data_list_txt">
                                    <div className="data_list_txt_i">
                                      <span className="data_list_type">Address</span>
                                    </div>
                                    <div className="data_list_txt_i">
                                      <span className="data_list_ttl">{n.address} </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  <a
                    className="bil_bttn open-modal-new-payment-method"
                    onClick={() => {
                      
                      this.setState({
                        
                        isCardEdit:false
                      },()=>{
                        this.initializePaymentObject()
                        this.onChangeTournamentFilters('cardType', "1")
                      })
                      
                      
                      // if (document.getElementById('modal-new-payment-method') && document.getElementById('modal-new-payment-method').className) {
                      //   document.getElementById('modal-new-payment-method').className =
                      //     'bil_modal-container--small modal-container modal-container--opened'
                      // }
                    }}
                  >
                    <div className="bil_bttn_inn">
                      <div className="bil_bttn_l">
                        <span className="bil_bttn_icon" />
                      </div>
                      <div className="bil_bttn_r">
                        <span>Add new payment method</span>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
        <aside className="bil_modal-container modal-container" id="modal-update-primary-method">
          <div className="modal-container__background" />
          <div className="bil_modal-container__window modal-container__window window">
            <a
              className="window__close"
              onClick={() => {
                document.getElementById('modal-update-primary-method').className = 'bil_modal-container modal-container'
              }}
            />
             <div className="window__header-container">
               
              
              </div>
              <div className="window__container">
              <div className="container-fluid p-0">
              <div className="row overflow-auto">
              <div className="col-12"> 
                <div className="">
                    <div className="bil_modal_hdr_i">
                      <h2 className="profile-modal_ttl--big text-center">Do you want to make this payment method as Primary?</h2>
                    </div>
                  </div>
                  
                  </div>
                <div className="col-12">
                    <div className="bil_modal_btns">
                      <div className="bil_modal_btns_i">
                        <button
                          className="bttn_blue-outline window__close"
                          type="button"
                          onClick={() => {
                            document.getElementById('modal-update-primary-method').className = 'bil_modal-container modal-container'
                          }}
                        >
                          CANCEL
                        </button>
                      </div>
                      <div className="bil_modal_btns_i">
                        <button disabled={this.state.loading}
                          className="bttn_blue-outline blue-button blue-button--filled d-flex"
                          type="button"
                          onClick={async () => {
                              this.UpdatePaymentMethodToPrimary(this.state.primaryCardId)
                          }}
                        >
                          UPDATE{this.state.loading && <Loading size="small" className="ml-2" />}
                        </button>
                      </div>
                    
            </div>
            </div>
            </div>
            </div>
            </div>
            </div>
            
           
        </aside>
        <aside className="bil_modal-container modal-container" id="modal-edit-personal">
          <div className="modal-container__background" />
          <div className="bil_modal-container__window modal-container__window window">
            <a
              className="window__close"
              onClick={() => {
                document.getElementById('modal-edit-personal').className = 'bil_modal-container modal-container'
              }}
            />
            <div className="window__header-container">
              <div className="row">
                <div className="col-12">
                  <div className="bil_modal_hdr">
                    <div className="bil_modal_hdr_i">
                      <h2 className="bil_modal_hdr_ttl">Edit Personal info</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="window__container">
              <div className="container-fluid p-0">
                <div className="row overflow-auto">
                  <div className="col-md-6 col-12">
                    <div className="row m-0 flex-column">
                      <h4 className="window__subheader">First name</h4>
                      <input
                        type="text"
                        name="fname"
                        className="window__input"
                        placeholder="First name"
                        value={userPersonalInfo.fname || ''}
                        onChange={e => {
                          this.onChangePersonalInfo(e)
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="row m-0 flex-column">
                      <h4 className="window__subheader">Last name</h4>
                      <input
                        type="text"
                        name="lname"
                        className="window__input"
                        placeholder="Last name"
                        value={userPersonalInfo.lname || ''}
                        onChange={e => {
                          this.onChangePersonalInfo(e)
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="row m-0 flex-column">
                      <h4 className="window__subheader">Country</h4>
                      <input
                        type="text"
                        name="country"
                        className="window__input"
                        placeholder="Country"
                        value={userPersonalInfo.country || ''}
                        onChange={e => {
                          this.onChangePersonalInfo(e)
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="row m-0 flex-column">
                      <h4 className="window__subheader">City</h4>
                      <input
                        type="text"
                        name="City"
                        className="window__input"
                        placeholder="City"
                        value={userPersonalInfo.City || ''}
                        onChange={e => {
                          this.onChangePersonalInfo(e)
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="row m-0 flex-column">
                      <h4 className="window__subheader">Zip / Postal Code</h4>
                      <input
                        type="text"
                        name="postal"
                        className="window__input"
                        placeholder="Zip / Postal Code"
                        value={userPersonalInfo.postal || ''}
                        onChange={e => {
                          this.onChangePersonalInfo(e)
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="row m-0 flex-column">
                      <h4 className="window__subheader">Street address</h4>
                      <input
                        type="text"
                        name="address"
                        className="window__input"
                        placeholder="Street address"
                        value={userPersonalInfo.address || ''}
                        onChange={e => {
                          this.onChangePersonalInfo(e)
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="bil_modal_btns">
                      <div className="bil_modal_btns_i">
                        <button
                          className="bttn_blue-outline window__close"
                          type="button"
                          onClick={() => {
                            document.getElementById('modal-edit-personal').className = 'bil_modal-container modal-container'
                          }}
                        >
                          BACK
                        </button>
                      </div>
                      <div className="bil_modal_btns_i">
                        <button disabled={this.state.loading}
                          className="bttn_blue-primery"
                          type="button"
                          onClick={() => {
                              this.updatePersonalInfo()
                          }}
                        >
                          ADD METHOD
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </aside>

        <aside className="bil_modal-container modal-container" id="modal-edit-card">
          <div className="modal-container__background" />
          <div className="bil_modal-container__window modal-container__window window">
            <a
              className="window__close"
              onClick={() => {
                this.initializePaymentObject()
                document.getElementById('modal-edit-card').className = 'bil_modal-container modal-container'
              }}
            />
            <div className="window__header-container">
              <div className="row">
                <div className="col-12">
                  <div className="bil_modal_hdr">
                    <div className="bil_modal_hdr_i">
                      <h2 className="bil_modal_hdr_ttl">{isCardEdit ? 'Edit' : 'Add'} Card</h2>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
            <div className="window__container">
              <div className="container-fluid p-0">
                <div className="row overflow-auto">
                
                  <div className="col-md-6 col-12">
                    <div className="row m-0 flex-column">
                      <h4 className="window__subheader">Card Number</h4>
                      {/* <input type="text" className="window__input" placeholder="Card Number" value="5236 2684 7747 5596" /> */}
                      {!isCardEdit && (
                        <div style={{ padding: '15px 20px', borderRadius: '5px', background: 'rgba(0, 16, 48, 0.05)' }}>
                          <CardNumberElement onChange={(e) => {
                                this.handleCardElementOnChange(
                                  e,
                                  "stripeCardNumberValid",true
                                )
                              }} />
                         
                        </div>
                      )
                      
                      }
                      {submitted && !stripeCardNumberValid  && (
                        <div className="col text-danger p-0">
                          Please Enter Valid Card Number
                        </div>
                      )}
                      {isCardEdit && <span>{paymentMethod.cardNo}</span>}
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="row flex-wrap form-card-data">
                      <div className="col-6">
                        <h4 className="window__subheader">Expiration</h4>
                        {/* <input type="text" className="window__input" placeholder="00" /> */}
                        {!isCardEdit && (
                          <div style={{ padding: '15px 20px', borderRadius: '5px', background: 'rgba(0, 16, 48, 0.05)' }}>
                            {' '}
                            <CardExpiryElement onChange={(e) => {
                                this.handleCardElementOnChange(
                                  e,
                                  "stripeCardExpValid",true
                                )
                              }} />
                          </div>

                        )}
                        {submitted && !stripeCardExpValid && (
                            <div className="col text-danger p-0">
                                    Please Enter Valid Expiration Month and Year
                              </div>
                            )}
                        {isCardEdit && (
                          <span>
                            {paymentMethod.expMonth} / {paymentMethod.expYear}
                          </span>
                        )}
                      </div>
                      {/* <div className="col-auto p-0 d-flex align-items-center">
                                                                    <div className="form-card-data_slash">/</div>
                                                                </div>
                                                                <div className="col-3">
                                                                    <h4 className="window__subheader">&ensp;</h4>
                                                                    <input type="text" className="window__input" placeholder="00" />
                                                                </div> */}
                      <div className="col-6">
                        <h4 className="window__subheader">CVC</h4>
                        {/* <input type="text" className="window__input" placeholder="000" /> */}
                        <div style={{ padding: '15px 20px', borderRadius: '5px', background: 'rgba(0, 16, 48, 0.05)' }}>
                          <CardCvcElement onChange={(e) => {
                                this.handleCardElementOnChange(
                                  e,
                                  "stripeCardCvcValid",true
                                )
                              }}/>
                          
                        </div>
                        {submitted && !stripeCardCvcValid && (
                                  <div className="col text-danger p-0">
                                    Please Enter Valid CVC
                                  </div>
                                )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="row m-0 flex-column">
                      <h4 className="window__subheader" style={{ marginTop: '20px' }}>
                        First name
                      </h4>
                      <input
                        type="text"
                        name="fname"
                        className={`window__input ${submitted && paymentMethod && (paymentMethod.fname ==='' || paymentMethod.fname === null || !paymentMethod.fname)?` m-0`:''}`}
                        placeholder="First name"
                        value={paymentMethod.fname || ''}
                        onChange={e => {
                          this.onChangeCard(e)
                        }}
                      />
                     
                    </div>
                    {submitted && paymentMethod && (paymentMethod.fname ==='' || paymentMethod.fname === null || !paymentMethod.fname) && (
                                  <div className="col text-danger p-0">
                                    Please Enter First Name
                                  </div>
                                )}
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="row m-0 flex-column">
                      <h4 className="window__subheader" style={{ marginTop: '20px' }}>
                        Last name
                      </h4>
                      <input
                        type="text"
                        name="lname"
                        className={`window__input ${submitted && paymentMethod && (paymentMethod.lname ==='' || paymentMethod.lname ===null  || !paymentMethod.lname)?` m-0`:''}`}
                        placeholder="Last name"
                        value={paymentMethod.lname || ''}
                        onChange={e => {
                          this.onChangeCard(e)
                        }}
                      />
                    </div>
                    {submitted && paymentMethod && (paymentMethod.lname ==='' || paymentMethod.lname ===null  || !paymentMethod.lname) && (
                                  <div className="col text-danger p-0">
                                    Please Enter Last Name
                                  </div>
                                )}
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="row m-0 flex-column">
                      <h4 className="window__subheader">Country</h4>
                      <input
                        type="text"
                        name="country"
                        className={`window__input ${submitted && paymentMethod && (paymentMethod.country ==='' || paymentMethod.country || !paymentMethod.country)?` m-0`:''}`}
                        placeholder="Country"
                        disabled
                        value={paymentMethod.country || ''}
                        onChange={e => {
                          
                          this.onChangeCard(e)
                        }}
                      />
                    </div>
                    {submitted && paymentMethod && (paymentMethod.country ==='' || paymentMethod.country === null || !paymentMethod.country) && (
                                  <div className="col text-danger p-0">
                                    Please Enter Country
                                  </div>
                    )}
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="row m-0 flex-column">
                      <h4 className="window__subheader">City</h4>
                      <input
                        type="text"
                        name="City"
                        className={`window__input ${submitted && paymentMethod && (paymentMethod.City ==='' || paymentMethod.City ===null || !paymentMethod.City)?` m-0`:''}`}
                        placeholder="City"
                        value={paymentMethod.City || ''}
                        onChange={e => {
                          this.onChangeCard(e)
                        }}
                      />
                    </div>
                    {submitted && paymentMethod && (paymentMethod.City ==='' || paymentMethod.City ===null || !paymentMethod.City) && (
                                  <div className="col text-danger p-0">
                                    Please Enter City
                                  </div>
                                )}
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="row m-0 flex-column">
                      <h4 className="window__subheader">Zip / Postal Code</h4>
                      <input
                        type="text"
                        name="postal"
                        className={`window__input ${submitted && paymentMethod && (paymentMethod.postal ==='' || paymentMethod.postal ===null || !paymentMethod.postal)?` m-0`:''}`}
                        placeholder="Zip / Postal Code"
                        value={paymentMethod.postal || ''}
                        onChange={e => {
                          this.onChangeCard(e)
                        }}
                      />
                    </div>
                    {submitted && paymentMethod && (paymentMethod.postal ==='' || paymentMethod.postal ===null || !paymentMethod.postal) && (
                                  <div className="col text-danger p-0">
                                    Please Enter ZipCode
                                  </div>
                                )}
                                {submitted && paymentMethod && paymentMethod.postal && this.validPostalCode(paymentMethod.postal)===false && (
                                  <div className="col text-danger p-0">
                                    Invalid ZipCode
                                  </div>
                                )}
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="row m-0 flex-column">
                      <h4 className="window__subheader">Street address</h4>
                      <input
                        type="text"
                        name="address"
                        className={`window__input ${submitted && paymentMethod && (paymentMethod.address ==='' || paymentMethod.address ===null || !paymentMethod.address)?` m-0`:''}`}
                        placeholder="Street address"
                        value={paymentMethod.address || ''}
                        onChange={e => {
                          this.onChangeCard(e)
                        }}
                      />
                    </div>
                    {submitted && paymentMethod && (paymentMethod.address ==='' || paymentMethod.address ===null || !paymentMethod.address) && (
                                  <div className="col text-danger p-0">
                                    Please Enter Street address
                                  </div>
                                )}
                  </div>
                  <div className="col-12">
                    <div className="bil_modal_btns">
                      
                      {!isCardEdit && (
                        <div className="bil_modal_btns_i">
                          <button disabled={this.state.loading}
                            className="bttn_blue-outline blue-button blue-button--filled d-flex"
                            type="button"
                            onClick={e => {
                              this.setState({
                                loading:true,
                                submitted:true
                              },()=>{
                                this.handlePayment(e)
                              })
                              
                            }}
                          >
                            ADD METHOD {this.state.loading && <Loading size="small" className="ml-2" />}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </aside>

        <aside className="bil_modal-container modal-container" id="modal-new-payment-method">
          <div className="modal-container__background" />
          <div className="bil_modal-container__window modal-container__window window ">
            <a
              className="window__close"
              onClick={() => {
                if (document.getElementById('modal-new-payment-method'))
                  document.getElementById('modal-new-payment-method').className = 'bil_modal-container--small bil_modal-container modal-container'
              }}
            />
            <div className="window__header-container">
              <div className="row">
                <div className="col-12">
                  <div className="bil_modal_hdr">
                    <div className="bil_modal_hdr_i">
                      <h2 className="bil_modal_hdr_ttl">Add new payment method</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="window__container">
              <div className="container-fluid p-0">
                <div className="row ">
                  <div className="col-12">
                    <div className="row m-0 flex-column">
                      <h4 className="window__subheader">Method</h4>
                      <div className="f_select2 f_select2-white-style">
                        {/* <select onChange={(e) => { this.onChangeTournamentFilters("cardType", e.target.value) }} className="form-control" data-placeholder="Method" data-select2-id="select-add-new-payment" aria-hidden="true"> */}
                        <select
                          onChange={e => {
                            this.onChangeTournamentFilters('cardType', e.target.value)
                          }}
                          className="form-control"
                        >
                          <option value="1">Credit Card</option>
                          <option value="2">Cryptocurrency</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div className="row" style={{ justifyContent: 'center' }}>
                  <div className="col-6">
                    <button
                      className="bttn_blue-primery"
                      type="button"
                      onClick={() => {
                        if (document.getElementById('modal-new-payment-method'))
                          document.getElementById('modal-new-payment-method').className =
                            'bil_modal-container--small bil_modal-container modal-container'

                        if (this.state.paymentMethod.cardType === '1') {
                          if (document.getElementById('modal-edit-card'))
                            document.getElementById('modal-edit-card').className = 'bil_modal-container modal-container modal-container--opened'
                        } else if (this.state.paymentMethod.cardType === '2') {
                          if (document.getElementById('modal-crypto-method'))
                            document.getElementById('modal-crypto-method').className =
                              'bil_modal-container--small modal-container modal-container--opened'
                        }
                      }}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </aside>

        <aside className="bil_modal-container modal-container" id="modal-crypto-method">
          <div className="modal-container__background" />
          <div className="bil_modal-container__window modal-container__window window ">
            <a
              className="window__close"
              onClick={() => {
                if (document.getElementById('modal-crypto-method'))
                  document.getElementById('modal-crypto-method').className = 'bil_modal-container--small bil_modal-container modal-container'
              }}
            />
            <div className="window__header-container">
              <div className="row">
                <div className="col-12">
                  <div className="bil_modal_hdr">
                    <div className="bil_modal_hdr_i">
                      <h2 className="bil_modal_hdr_ttl">Cryptocurrency Method</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="window__container">
              <div className="container-fluid p-0">
                <div className="row ">
                  <div className="col-12">
                    <div className="row m-0 flex-column">
                      <h4 className="window__subheader">Enter email associated with your Cryptocurrency account</h4>
                      <div className="f_select2">
                        <input
                          type="text"
                          name="paypal"
                          className="window__input"
                          placeholder="player1@blackdot.ca"
                          onChange={e => {
                            this.onChangeCard(e)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div className="col-12">
                  <div className="bil_modal_btns">
                    <div className="bil_modal_btns_i">
                      <button
                        className="bttn_blue-outline window__close"
                        type="button"
                        onClick={() => {
                          if (isCardEdit) {
                            this.setState({
                              isCardEdit: false,
                            })
                          }
                          document.getElementById('modal-crypto-method').className = 'bil_modal-container modal-container'
                          document.getElementById('modal-new-payment-method').className =
                            'bil_modal-container--small modal-container modal-container--opened'
                        }}
                      >
                        Back
                      </button>
                    </div>
                    {!isCardEdit && (
                      <div className="bil_modal_btns_i">
                        <button
                          className="bttn_blue-primery"
                          type="button"
                          onClick={e => {
                            this.handlePayment(e)
                          }}
                        >
                          Add Method
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </aside>
      </>
    )
  }
}

export default withStripe(BillingOptionPlayer)
