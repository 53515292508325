import * as React from 'react'
import { kl_DraftTournament } from 'models/kl_DraftTournament-models'
import { DropDownTable, useDropDownTableStyles } from 'components/_shared'
import { cn } from 'utils'
import styles from '../EventsOrganizer.module.scss'
import DraftTournamentsRow from './DraftTournamentsRow'

interface Props {
  draftTournaments: kl_DraftTournament[]
  onResetData: CallableFunction
}


const  DraftTournaments = ({draftTournaments, onResetData}: Props) => {  
  const dropDownTableStyles = useDropDownTableStyles()
  
  return (
    <div className={cn(dropDownTableStyles.dropDownTableMediaQueries)}>
      <DropDownTable.HeadersRow className="">
        <DropDownTable.Column className={cn(styles.table__column, styles.eventName)}>Tournament</DropDownTable.Column>
        <DropDownTable.Column className={cn(styles.table__column, styles.date)}>Start date</DropDownTable.Column>
        <DropDownTable.Column className={cn(styles.table__column, styles.time)}>Time</DropDownTable.Column>
        <DropDownTable.Column className={cn(styles.table__column, styles.venue)}>Venue</DropDownTable.Column>
        <DropDownTable.Column className={cn(styles.table__column, styles.buttons)} /> 
      </DropDownTable.HeadersRow>
      {draftTournaments && draftTournaments.map(draft => (        
        <DraftTournamentsRow key={draft.Id} draft={draft} onResetData={onResetData} />
      ))}
    </div>
  )  
}

export default DraftTournaments
