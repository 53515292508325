import { useAppSelector, useClanMember, useDialogWithParameter, useFormatDateTime, useMemberClan } from 'components/_hooks'
import { TournamentModeEnum, TournamentStatusEnum, VenueTypeEnum } from 'consts'
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAppUser } from 'store/selectors/app-selectors'
import { TournamentViewModel_kl } from 'models/tournament-viewmodel'
import { lineUpsActionCreators } from 'store/logic/line-ups-logic'
import ClanRegistrationButton from './ClanRegistrationButton'
import PlayerRegistrationButton from './PlayerRegistrationButton'
import TicketsRegistrationContainer from './TicketsRegistrationContainer'
import { makeGetParticipantsByTournamentDetailId } from 'store/selectors/participant-selectors'
import { ApplicationState } from 'store/types/common'
import { participantsActionCreators, useAddParticipation, useCancelParticipation } from 'store/logic/participants-logic'
import { useReloadPendingMatches } from 'store/logic/match-logic'
import { EventTypeEnum } from 'models/tournament-models'
import { AlertModal } from 'components/_modals'
// import { kl_TournementTicketsCartDetailViewModel } from 'models/tournament-cart-model'
//import { TournamentCartUtils } from 'server/utils/tournament-cart-utils'

import { TournamentUtils } from 'server/utils/tournament-utils'
import { groupArrayToObject } from 'utils'
import { TournamentCartUtils } from 'server/utils/tournament-cart-utils'
import StorageUtils from 'server/utils/storage-utils'
import { DeleteTournamentChat } from 'store/chat/chat/chat-logic'
import { TournamentPaidStatusEnum } from 'consts/TournamentPaidStatusEnum'
import { loadClanMembers } from 'store/logic/pages/clan-page-logic'
import { makeGetClanMembersIds } from 'store/selectors/clan-member-selectors'
import { makeGetUsers } from 'store/selectors/user-selectors'
import ClanMemberSignUpModal from 'components/_pages/common/TournamentDetailRegistration/ClanMemberSignUpModal/ClanMemberSignUpModal'
import { ClanMemberData, TournamentClanSignUpModel } from 'models/clan-member-view-model'
interface Props {
  tournament: TournamentViewModel_kl,
  reloadTournament: (tournamentId: number) => void
}
const ClanMembersSignUpModal = React.memo(ClanMemberSignUpModal);
const TournamentDetailRegistrationContainer = ({ tournament, reloadTournament }: Props) => {
  const user = useSelector(getAppUser)
  const { isClanLeader, clan } = useMemberClan(user?.Id)
  const dispatch = useDispatch()
  const closeModal=async()=>{
    if(tournament.TournamentModeId === TournamentModeEnum.Clans && tournament.VenueTypeId === VenueTypeEnum.Live)
      await reloadTournament(tournament.Id)

  }
  const [ClanMemberModal, openClanMemberModal] = useDialogWithParameter<TournamentClanSignUpModel>((clanMemberUsers, closeDialog) => (
    <ClanMembersSignUpModal
      closeDialog={closeDialog}
      clanMemberUsers={clanMemberUsers}
      close={closeModal}
    />
  ))
  useEffect(() => {
    if (tournament.Id) {
      void dispatch(lineUpsActionCreators.loadLineUps(tournament.Id))

    }
  }, [dispatch, tournament.Id])

  useEffect(() => {
    if (tournament.Id) {

      dispatch(participantsActionCreators.loadParticpants(tournament.Id))
    }
  }, [dispatch, tournament.Id])
  useEffect(() => {
    if (clan && clan.Id) {
      dispatch(loadClanMembers( clan.Id, {
        page: 0,
        pageSize:12
      }))
    }
  }, [ clan && clan.Id, dispatch])
  
  
  
  const getParticipantsByTournamentDetailId = useMemo(makeGetParticipantsByTournamentDetailId, [])  
  const participants = useSelector((state: ApplicationState) => getParticipantsByTournamentDetailId(state, tournament.Id))
  const isPending =  React.useMemo(() => {
   
    if (!tournament || !participants || tournament.TournamentStatusId != TournamentStatusEnum.Pending) {
      return false
    }

    const now = new Date()
    let isValidDate = false
    if (tournament && tournament.EventTypeId === EventTypeEnum.Main) {
      isValidDate = now < new Date(tournament.RegistrationClosesOn)
    }
    else {
      isValidDate = new Date(tournament.RegistrationOpensOn) <= now && now < new Date(tournament.RegistrationClosesOn)
    }


    if (!isValidDate) {
      return false
    }

    if (tournament.TournamentModeId == TournamentModeEnum.Solo) {
      return participants.length < tournament.MaxNumberOfParticipients
    }


    return participants.length < tournament.MaxNumberOfParticipients || participants.some(p => p.ClanId && clan && p.ClanId == clan.Id)
    //return participants.some(p => !tournamentLineUps[p.Id] || tournamentLineUps[p.Id].length < tournament.PlayersPerClan)
  }, [clan, participants, tournament])

  const isSignUpEnabled = React.useMemo(() => {

    if (!isPending) {
      return false
    }
    if (!user) {
      return false
    }
    if (user.Role == 'Organizer') return false
    if (!tournament) {
      return false
    }
    //comment code for display join button for all users
    // if (tournament.TournamentModeId === TournamentModeEnum.Clans) {
    //   if (!clan) {
    //     return false
    //   }

    //   return isClanLeader || participants.findIndex(p => clan && p.ClanId == clan.Id) >= 0
    // }

    return true
  }, [clan, isClanLeader, isPending, participants, tournament, user])

  const registrationOpensOn = useFormatDateTime(tournament.Id, tournament.RegistrationOpensOn?.toString(), 'localStorage')
  const registrationClosesOn = useFormatDateTime(tournament.Id, tournament.RegistrationClosesOn?.toString(), 'localStorage')

  const [actionCompletedModal, openActionCompletedModal] = useDialogWithParameter<string>((text, closeDialog) => (
    <AlertModal onClose={closeDialog} text={text} buttonText="Ok" />
  ))
  const CheckSpectatorTicketSoldOut = React.useMemo(() => {
    let tickets = tournament.TournamentTickets
    if (!tickets) {
      return false
    }
    tickets = tickets.filter(x => x.IsPaid === TournamentPaidStatusEnum.Paid)
    let totalTickets:any[] = []
    let IsSpectatorTicketsSoldOut = false
    const spectatorTickets = tickets.filter(x => x.IsParticipent === false)
    const packageTicket = spectatorTickets.filter(x => x.IsParticipent === false && x.SoldOut != 0 && x.DayNumber === null && x.TicketDate === null)
    if (packageTicket.length > 0) {
      let soldOutpackageTickets: any = groupArrayToObject(packageTicket.filter(x => x.SoldOut !== 0), (c) => c.Id, (m) => m.SoldOut)
      Object.keys(soldOutpackageTickets).forEach(function (key) {
        totalTickets.push(tournament.SpectatorTickets * soldOutpackageTickets[key].reduce(function (a, b) { return a + b; }, 0))
      });
      const totalsoldOutPackageTickets = totalTickets.reduce(function (a, b) { return a + b; }, 0);
      IsSpectatorTicketsSoldOut = totalsoldOutPackageTickets >= tournament.VenueSeatingCapacity ? true : false
    }
    const dayTickets = spectatorTickets.filter(x => x.IsParticipent === false && x.DayNumber !== null && x.SoldOut !== 0)
    if (dayTickets.length > 0 && !IsSpectatorTicketsSoldOut) {

      let soldOutdayTickets: any = groupArrayToObject(dayTickets.filter(x => x.SoldOut !== 0), (c) => c.Id, (m) => m.SoldOut)
      Object.keys(soldOutdayTickets).forEach(function (key) {

        totalTickets.push(soldOutdayTickets[key].reduce(function (a, b) { return a + b; }, 0))
      });

      const totalsoldOutdayTickets = totalTickets.reduce(function (a, b) { return a + b; }, 0);
      IsSpectatorTicketsSoldOut = totalsoldOutdayTickets >= (tournament.VenueSeatingCapacity) ? true : false
    }
    return IsSpectatorTicketsSoldOut
  }, [tournament])
  const CheckParticipantTicketSoldOut = React.useMemo(() => {

    let tickets = tournament.TournamentTickets
    if (!tickets) {
      return false
    }
    tickets = tickets.filter(x => x.IsPaid === TournamentPaidStatusEnum.Paid)
    const participanttickets = tickets.filter(x => x.IsParticipent === true && x.SoldOut !== 0)
    const totalsoldOutParticipantTickets = participanttickets.map(x => x.SoldOut).reduce(function (a, b) { return a + b; }, 0);
    const isParticipientTicketPurchased = participanttickets.filter(x => x.UserId === user?.Id).length > 0 ? true : false
    const totalTournamentMaxNumberOfParticipants = (tournament.TournamentModeId === TournamentModeEnum.Clans) ? (tournament.MaxNumberOfParticipients * tournament.PlayersPerClan) : totalsoldOutParticipantTickets
    const IsParticipantTicketsSoldOut = totalTournamentMaxNumberOfParticipants === totalsoldOutParticipantTickets || isParticipientTicketPurchased ? true : false
    return IsParticipantTicketsSoldOut
  }, [tournament])


  const cancelParticipation = useCancelParticipation()
  const addParticipation = useAddParticipation()
  const reloadPendingMatches = useReloadPendingMatches()

  const handleParticipantJoin = async () => {
    try {
      let PrivateSlot = (tournament.PrivateSlot !== undefined && tournament.PrivateSlot !== null ? tournament.PrivateSlot : 0)
      let PublicSlot = (tournament.PublicSlot !== undefined && tournament.PublicSlot !== null ? tournament.PublicSlot : 0)

      const allCountPublicParticipants = participants.filter(x => { return x.IsPrivate !== true})
     
      if (PublicSlot === 0 && PrivateSlot !== 0) {
        openActionCompletedModal("No public slots are available.")
        return
      }

      if (PublicSlot != 0) {
        const isFullPublicSlot = ((PublicSlot - (allCountPublicParticipants ? allCountPublicParticipants?.length : 0)) === 0)
        if (isFullPublicSlot) {
          openActionCompletedModal("All public slots are full.")
          return
        }
      }

      const result = await TournamentUtils.CheckUserParticipantInTournamentSeriesOrNot(tournament.Id)
      if (!result.isError)
      {
        if(result.data === undefined || result.data === null || result.data.IsParticipate === false) {
            if(tournament.TournamentModeId == TournamentModeEnum.Clans){
              const resultClanMembers = await TournamentUtils.GetTournamentClanMembes(clan.Id)
              if(!resultClanMembers?.isError){
                 const data = resultClanMembers?.data
                 const users = data?.Model.Users
                 let TournamentClanSignUpModel:TournamentClanSignUpModel = Object()
                 TournamentClanSignUpModel.tournamentId = tournament.Id
                 TournamentClanSignUpModel.clanId = clan.Id
                 TournamentClanSignUpModel.SubstituteCount = Number(tournament.NumberOfSubstitutes==null?0:tournament.NumberOfSubstitutes)
                 TournamentClanSignUpModel.TotalPlayers = tournament.PlayersPerClan + (Number(tournament.NumberOfSubstitutes==null?0:tournament.NumberOfSubstitutes))
                 TournamentClanSignUpModel.IsAddOnlyClanMember = false
                 const clanMemberData=users.map(function(value,index){
                   return {
                     Username:value.Username,
                     PlayerId : value.Id,
                     IsSubstitute : false,
                     IsClanLeader:value.Id === user.Id?true:false,
                     IsSelected:value.Id === user.Id?true:false,
                     IsDisabled:false,
                     IsDefault:true
                   }
                })
                clanMemberData.sort(x=>x.IsClanLeader?-1:1)
                TournamentClanSignUpModel.clanMemberData = clanMemberData as ClanMemberData[]
                openClanMemberModal(TournamentClanSignUpModel as TournamentClanSignUpModel)
              }
            }
            else{
              if (tournament.VenueTypeId === VenueTypeEnum.Online) {
                await addParticipation(tournament.Id)
                openActionCompletedModal('Joined tournament successfully.')
              }
              void reloadPendingMatches()
              if (tournament.VenueTypeId === VenueTypeEnum.Live) {
                await saveToCart('join')
              }
              
            }
 
        } 
        else{
          if(result.data.EventTypeId === EventTypeEnum.Main)
          {
            openActionCompletedModal('You have already won a seat to ' + result.data.EventName)
          }
          else {
            openActionCompletedModal('Our system detects that you are currently enrolled in this qualifier series already')
          }
        }
      }
      else {
        openActionCompletedModal(result.message)
      }
    }
    catch (e) {
      openActionCompletedModal(e.message)
    }
  }

  const handleParticipantLeave = async () => {
    try {
      let isClanMember = false
      const participantTicket = tournament.TournamentTickets.filter(x => x.UserId === user.Id && x.IsParticipent === true)
      if (tournament.TournamentModeId === TournamentModeEnum.Clans && !isClanLeader) {
        isClanMember = true;
      }
      await cancelParticipation(tournament.Id,user?.Id,isClanMember)
      //await cancelParticipation(tournament.Id,participantTicket.length === 1?participantTicket[0].CartDetailId:null,isClanMember)
      if(participantTicket.length === 1){
        
        const index=tournament.TournamentTickets.findIndex(x=>x.Id === participantTicket[0].Id)
        tournament.TournamentTickets.splice(index,1)
        reloadTournament(tournament.Id)
      }
      dispatch(DeleteTournamentChat(tournament.Id))
      openActionCompletedModal('Cancel participation from tournament successfully.')
      void reloadPendingMatches()
    }
    catch (e) {
      openActionCompletedModal(e.message)
    }
  }
  const saveToCart = async (type: string) => {
    await onSaveTicketsToCart(tournament.Id, user.Id, type)
  }
  const onSaveTicketsToCart = async (tournamentId: number, UserId: number, type: string) => {

    StorageUtils.setCartTournamentDetailId(tournamentId)
    const result = await TournamentCartUtils.SaveToCart(tournamentId, type)
    if (!result.isError) {
      window.location.href = '/cart'
    }
  }


  return (
    <>
      <div className="" style={{ background: 'transparent', border: 'none' }}>
        <div className="container-fluid p-0">
          <div className="content">
            <div className="row">
              <div className="col-12">
                <div className="article-header__registration tournament--article-header__registration-container registration ct__registration">
                  <div className="registration__info tournament-padding-0 align-items-stretch">
                    <div className="tournament-margin-0 row">
                      <div className="col-12 d-flex-optional p-0">
                        <div className="col registration__column ct__registration__column">
                          <div className="col-12 px-0 tournament--row">
                            <h5 className="registration__header">
                              {isPending ? 'Registration open' : 'Registration close'}
                            </h5>
                          </div>
                          {tournament.EventTypeId !== EventTypeEnum.Main && (
                            <div className="col-12 p-0 tournament--row">
                              <div className="registration__time tournament--registration__time registration__time--opens">Opens {registrationOpensOn.dateTime}</div>
                            </div>
                          )}
                          <div className="col-12 p-0 tournament--row">
                            <div className="registration__time tournament--registration__time registration__time--closes">Closes {registrationClosesOn.dateTime}</div>
                          </div>
                        </div>
                        <div className="col-auto p-0">
                          <div className="row align-items-center h-100">
                            <div className="col-12 p-2 registration__players-container">
                              <div className="col-12">
                                <div className="registration__players">
                                  {participants?.length ?? 0}
                                  <span>/{tournament.MaxNumberOfParticipients}</span>
                                </div>
                              </div>
                              <div className="col-12 ">
                                <div className="registration__players-text">{tournament.TournamentModeId == TournamentModeEnum.Solo ? 'Players' : 'Clans'}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {
                    <div className="mt-1">
                      {tournament.EventTypeId !== EventTypeEnum.Main && tournament.VenueTypeId == VenueTypeEnum.Online && isSignUpEnabled && (
                        <div className="row flex-sm-nowrap m-0 d-flex tournament--row-btn">
                          <div className="col-12 p-0">
                            {tournament.TournamentModeId == TournamentModeEnum.Clans && isClanLeader && (
                              <ClanRegistrationButton 
                                tournament={tournament} 
                                participants={participants} 
                                onParticipantJoin={handleParticipantJoin}
                                onParticipantLeave={handleParticipantLeave}
                              />
                            )}
                            {tournament.TournamentModeId == TournamentModeEnum.Solo && (
                              <PlayerRegistrationButton
                                tournament={tournament}
                                participants={participants}
                                onParticipantJoin={handleParticipantJoin}
                                onParticipantLeave={handleParticipantLeave}
                              />
                            )}
                          </div>
                        </div>
                      )}
                      {tournament.EventTypeId !== EventTypeEnum.Main && tournament.VenueTypeId == VenueTypeEnum.Live && (
                        <div className="row m-0 flex-sm-nowrap d-flex-optional">
                          {isSignUpEnabled && (
                            
                            <div className={`${tournament.TournamentModeId == TournamentModeEnum.Clans && !isClanLeader? "col-0 pr-1 p-0":"col-6 pr-1 p-0"}`}>
                              {tournament.TournamentModeId == TournamentModeEnum.Clans && isClanLeader &&(
                                <ClanRegistrationButton 
                                  tournament={tournament} 
                                  participants={participants} 
                                  onParticipantJoin={handleParticipantJoin}
                                  onParticipantLeave={handleParticipantLeave}
                                />
                              )}
                              {tournament.TournamentModeId == TournamentModeEnum.Solo && (
                                <PlayerRegistrationButton
                                  tournament={tournament}
                                  participants={participants}
                                  onParticipantJoin={handleParticipantJoin}
                                  onParticipantLeave={handleParticipantLeave}
                                />
                              )}
                            </div>
                          )}
                          <TicketsRegistrationContainer tournament={tournament} participants={participants} saveToCart={saveToCart} checkParticipantTicketSoldOut={CheckParticipantTicketSoldOut} checkSpectatorTicketSoldOut={CheckSpectatorTicketSoldOut} />
                        </div>
                      )}
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>   
      {actionCompletedModal}  
      {ClanMemberModal}
    </>
  )
}

export default TournamentDetailRegistrationContainer
