import { KombatWithDrawlFeeViewModel, TournamentCartAndCartDetailPostModel } from '../../models/tournament-cart-model'
import { ExecuteAPIPost, ExecuteAPIGet } from '../server-utils'
import { EndPoints } from '../endpoints'
import { TournamentCartRefundStatus } from 'consts/TournamentCartRefundStatus'
import { ShoppingCardModel } from 'models/shopping-card-model'

export const TournamentCartUtils = {
  SaveToCart: async function SaveToCart(TournamentDetailId: number,type:string) {
    return await ExecuteAPIGet<any>(EndPoints.TournamentCart.SaveToCart, `TournamentDetailId=${TournamentDetailId}&type=${type}`)
  },
  GetCart: async function GetCart(TournamentDetailId: number,userCountry:string) {
    return await ExecuteAPIGet<any>(EndPoints.TournamentCart.GetCart, `TournamentDetailId=${TournamentDetailId}&userCountry=${userCountry}`)
  },
  DeleteCart: async function DeleteCart(cardDetailId: number) {
    return await ExecuteAPIGet<any>(EndPoints.TournamentCart.DeleteCart, `cartDetailId=${cardDetailId}`)
  },
  UpdateCartAttendenceCount: async function UpdateCartAttendenceCount(ticketCartDetailId: number, quantity: number, attendenceCount: number) {
    return await ExecuteAPIGet<number>(
      EndPoints.TournamentCart.UpdateCartAttendenceCount,
      `ticketCartDetailId=${ticketCartDetailId}&quantity=${quantity}&attendenceCount=${attendenceCount}`
    )
  },
  UpdateCartAndCartDetailAfterPurchase: async function UpdateCartAndCartDetailAfterPurchase(model: TournamentCartAndCartDetailPostModel[]) {
    return await ExecuteAPIPost<any>(EndPoints.TournamentCart.UpdateCartAndCartDetailAfterPurchase, model)
  },
  OrganizerWithDrawlFees: async function OrganizerWithDrawlFees() {
    return await ExecuteAPIGet<KombatWithDrawlFeeViewModel>(EndPoints.TournamentCart.OrganizerWithDrawlFees)
  },
  UpdateRefunded: async function UpdateRefunded(cartDetailId: number, refunded: boolean) {
    return await ExecuteAPIGet<number>(EndPoints.TournamentCart.UpdateRefunded, `cartDetailId=${cartDetailId}&refunded=${refunded}`)
  },
  DeleteUserCart: async function DeleteUserCart(CartIds: string) {
    return await ExecuteAPIGet<boolean>(EndPoints.TournamentCart.DeleteUserCart, `CartId=${CartIds}`)
  },
  GetCartStatus: async function GetCartStatus(CartIds: string) {
    return await ExecuteAPIGet<boolean>(EndPoints.TournamentCart.GetCartStatus, `CartId=${CartIds}`)
  },
  UpdateRefundedStatus: async function UpdateRefundedStatus(tournamentTicketQuantityId: number, status: TournamentCartRefundStatus) {
    return await ExecuteAPIGet<any>(EndPoints.TournamentCart.UpdateRefundedStatus, `tournamentTicketQuantityId=${tournamentTicketQuantityId}&status=${status}`)
  },
  AddOrUpdateCart: async function AddOrUpdateCart(cartDetails:ShoppingCardModel){
    return await ExecuteAPIPost<ShoppingCardModel>(EndPoints.TournamentCart.AddOrUpdateCart, cartDetails)
  },
  GetTournamentTicketCartDetailById: async function GetTournamentTicketCartDetailById(cartDetailId: number) {
    return await ExecuteAPIGet<any>(EndPoints.TournamentCart.GetTournamentTicketCartDetailById, `cartDetailId=${cartDetailId}`)
  },
  ValidateTournamentTickets: async function ValidateTournamentTickets(TournamentDetailId:number,TicketId:number,SectionId:number,SeatId:number,Quantity:number,IsAdd:boolean){
    return await ExecuteAPIGet<any>(EndPoints.TournamentCart.ValidateTournamentTickets, `TournamentDetailId=${TournamentDetailId}&TicketId=${TicketId}&SectionId=${SectionId}&SeatId=${SeatId}&Quantity=${Quantity}&IsAdd=${IsAdd}`)
  },
  ValidateCartOnCheckOut: async function ValidateUserCart(ShoppingCardModel:ShoppingCardModel[]){
    return await ExecuteAPIPost<ShoppingCardModel[]>(EndPoints.TournamentCart.ValidateCartOnCheckOut, ShoppingCardModel)
  },
  GetTournamentTicketsByTournamentDetailId: async function GetTournamentTicketsByTournamentDetailId(tournamentDetailId: number) {
    return await ExecuteAPIGet<any>(EndPoints.TournamentCart.GetTournamentTicketsByTournamentDetailId, `tournamentDetailId=${tournamentDetailId}`)
  },
  SaveToCartByOrganizer: async function SaveToCart(TournamentDetailId: number,type:string,userId:number) {
    return await ExecuteAPIGet<any>(EndPoints.TournamentCart.SaveToCartByOrganizer, `TournamentDetailId=${TournamentDetailId}&type=${type}&userId=${userId}`)
  },
  GetUserSelectedSeats:async function GetUserSelectedSeats(SectionId: number,TicketId:number) {
    return await ExecuteAPIGet<any>(EndPoints.TournamentCart.GetUserSelectedSeats, `SectionId=${SectionId}&TicketId=${TicketId}`)
  },
}
