import { useAppSelector } from 'components/_hooks'
import { LoadingBox } from 'components/_shared'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { loadChats } from 'store/chat/chat-message/chat-message-logic'
import { useChat, useCloseChats } from 'store/chat/chat/chat-hooks'
import { getIsChatsLoading, makeGetChatIds } from 'store/chat/chat/chat-selectors'
import { ChatContainer, ChatHeader } from '../../_shared'
import ChatPreview from './_shared/ChatPreview'
import styles from './ChatList.module.scss'

const ChatList = () => {

  const { openedChatId, openedUserId } = useChat()
  const history = useHistory()
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState('');
  useEffect(() => {
    if (openedChatId || openedUserId) {
      history.push('/chat')
    } else {
      dispatch(loadChats())
    }
  }, [dispatch, history, openedChatId, openedUserId])


  const closeChats = useCloseChats()
  const handleCloseClick = () => {
    closeChats()
  }

  const [isMenuOpened, setIsMenuOpened] = useState(false)

  const getChats = useMemo(makeGetChatIds, [])

  const chats = useAppSelector(state => getChats(state))

  const isLoading = useAppSelector(getIsChatsLoading)
  const [showSearchInput, setshowSearchInput] = useState(false);
  const onShowSearchInput = (show: boolean) => {
    setshowSearchInput(show)
  }

  const onSearch = (value: string) => {
    setSearchText(value)
  }
  return (
    <>
      <ChatHeader>
        <ChatHeader.Container>{!showSearchInput && (<span>Chats</span>)}</ChatHeader.Container>
        <ChatHeader.Search onSearch={onSearch} onShowSearchInput={onShowSearchInput} />
        <ChatHeader.Menu isMenuOpened={isMenuOpened} onIsMenuOpenedChange={setIsMenuOpened}>
          <ChatHeader.Menu.Link to="/group/create">Create group</ChatHeader.Menu.Link>
          <ChatHeader.Menu.Link onClick={handleCloseClick}>Close chats</ChatHeader.Menu.Link>
        </ChatHeader.Menu>
      </ChatHeader>
      <ChatContainer>
        <LoadingBox loading={isLoading} size="big" loadingClassName={styles.loading} className={styles.loadingBox}>
          {chats.map(id => (
            <ChatPreview key={id} chatId={id} searchText={searchText.toLowerCase()} />
          ))}
        </LoadingBox>
      </ChatContainer>
    </>
  )
}

export default ChatList
