import React from 'react'
import { Modal } from 'components/_modals'
import { cn } from 'utils'
import styles from './AlertModal.module.scss'

interface AlertModalProps {
  onClose: CallableFunction
  text: string
  buttonText: string
}

export const AlertModal = ({onClose, text, buttonText}: AlertModalProps) => {
  return (
    <Modal 
      allowOk
      hideCancel
      show
      className={cn(styles.alertModal, styles.window)}
      onClose={onClose}
      buttonColor="blue"
      okButtonText={buttonText}
    >
      <Modal.SubHeader
        className={cn(styles.window__header, styles.window__header__center)}
      >
        {text}
      </Modal.SubHeader>
    </Modal>
  )
}
