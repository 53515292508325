import { ResumeEventStatusType } from "consts/ResumeEventStatusType"
import { ResumeCancelEventModel } from "models/resume-cancel-event-model"
import { TournamentUtils } from "server/utils/tournament-utils"
import { TournamentType } from "store/types/tournaments-types"

export const useResumeEvent = async (tournamentCardObj : TournamentType): Promise<ResumeCancelEventModel> => {
  const resumeCancelEventModel: ResumeCancelEventModel = Object()
  resumeCancelEventModel.tournament = tournamentCardObj
  resumeCancelEventModel.resumeEventStatus = ResumeEventStatusType.AllowResume
  var result = await TournamentUtils.CheckResumeTournamentOrNot(tournamentCardObj.TournamentDetailId);
    if (!result.isError && result.data) {
      resumeCancelEventModel.resumeEventStatus = result.data
    }
    return resumeCancelEventModel
}
