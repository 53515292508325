import * as React from 'react'
import { CoinBaseViewModel } from '../../models/coin-base-models'
import { UserModel } from '../../models/user-models'
import { CoinBaseUtils } from '../../server/utils/coin-base-utils'

interface Props {}
interface State {
  coinBase: CoinBaseViewModel
  startPayment: boolean
  hostedUrl: string
}

class BitcoinBase extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      coinBase: Object(),
      startPayment: false,
      hostedUrl: '',
    }
    this._createCoinBaseCharge = this._createCoinBaseCharge.bind(this)
    this.redirectHostedUrl = this.redirectHostedUrl.bind(this)
  }
  async _createCoinBaseCharge() {
    const { coinBase } = this.state
    const user: UserModel = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : Object()
    coinBase.email = user.emailaddress
    coinBase.CustomerName = user.username
    coinBase.Amount = 2
    coinBase.Name = 'premium Account'
    coinBase.Description = 'Premium Accoun for organizer'
    const res = await CoinBaseUtils.createCoinBaseCharge(coinBase)
    if (res.data) {
      this.setState(
        {
          hostedUrl: res.data,
        },
        () => {
          this.redirectHostedUrl()
        }
      )
    }
  }
  redirectHostedUrl() {
    const { hostedUrl } = this.state
    if (hostedUrl) window.open(hostedUrl)
  }
  render() {
    const {} = this.props
    const {} = this.state
    return (
      <div>
        <h3>abc</h3>
        <button
          className="btn btn-primary"
          onClick={() => {
            this._createCoinBaseCharge()
          }}
        >
          Pay with Coin
        </button>
      </div>
    )
  }
}

export default BitcoinBase
