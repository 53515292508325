import { ContainerName } from 'components/_hooks'
import { MatchParticipantStatus } from 'consts'
import React from 'react'
import { useSelector } from 'react-redux'
import { getMatchSettings } from 'store/selectors/match-settings-selectors'
import { ApplicationState } from 'store/types/common'
import { MatchParticipantModel } from 'store/types/match-participant-types'
import { MatchModel, MatchStatusEnum } from 'store/types/match-types'
import { ParticipantType } from 'store/types/participants-types'
import { cn } from 'utils'
import ParticipantOnlineAvatar from '../ParticipantAvatar/ParticipantOnlineAvtar'
import styles from './MatchResult.module.scss'

interface OwnProps {
  match: MatchModel
  matchParticpants: MatchParticipantModel[]
  participants: ParticipantType[]
}

const FfaMatchResult = ({ matchParticpants, participants, match }: OwnProps) => {

  const matchSettings = useSelector((state: ApplicationState) => getMatchSettings(state, match.Id))


  const matchResults = React.useMemo(() => {
    return matchParticpants
      .map(mp => {
        const participant = participants.find(p => p.Id === mp.TournamentParticipantId)
        return {
          MatchParticipantId: mp.Id,
          ParticipantName: participant?.Player?.Username || participant?.Clan?.Name,
          Place: mp.Place,
          Participant: participant,
          IsPending: mp.ParticipantStatus == MatchParticipantStatus.SignedUp && match.Status == MatchStatusEnum.Pending,
          IsHost: matchSettings?.HostedByMatchParticipantId == mp.Id,
        }
      })
      .sort((a, b) => {
        if (a.Place === b.Place) {
          return 0
        } else if (a.Place === null) {
          return 1
        } else if (b.Place === null) {
          return -1
        }
        return a.Place < b.Place ? -1 : 1
      })
  }, [match.Status, matchParticpants, matchSettings?.HostedByMatchParticipantId, participants])

  return (
    <div className="container">
      {matchResults &&
        matchResults.map(mr => (
          <div key={mr.MatchParticipantId} className={cn('row', 'm-0', 'align-items-center', styles.windowPlayer)}>
            <div className="col-9">
              <div className="row align-items-center flex-nowrap old-kl-css">
                <ParticipantOnlineAvatar 
                  participantId={mr.IsPending ? undefined : mr.Participant.Id} 
                  className={styles.windowPlayerPicture} 
                  parentClassName="prsn_icon-indicator-position"
                  containerName={ContainerName.AvatarsNormal}
                  withLink
                />
                <div className={styles.windowPlayerUsername}>
                  {mr.IsPending ? (
                    <span className={cn(styles.windowPlayerUsernameText, styles.pending)}>Pending</span>
                  ) : (
                    <span className={styles.windowPlayerUsernameText}> {mr.ParticipantName}</span>
                  )}
                  {mr.IsHost && (
                    <span>Host</span>
                  )}
                </div>
              </div>
            </div>
            {match.Status !== MatchStatusEnum.Pending && (
              <div className="col-3 d-flex justify-content-end p-0">
                <div
                  className={cn(
                    styles.windowPlayerLabel,
                    styles.windowPlayerLabelPlace,
                    mr.Place === 1 ? styles.windowPlayerLabelGreen : styles.windowPlayerLabelRed
                  )}
                >
                  {mr.Place}
                </div>
              </div>
            )}
          </div>
        ))}
    </div>
  )
}

export default FfaMatchResult
