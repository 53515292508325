import React, { ReactNode } from 'react'
import styles from './ChatTransparentLabel.module.scss'

export interface ChatTransparentLabelProps {
  children: ReactNode
}

export const ChatTransparentLabel = ({ children }:  ChatTransparentLabelProps) => {
  return (
    <div className="row m-0">
      <h3 className={styles.chatTransparentSubheader}>{children}</h3>
    </div>
  )
}
