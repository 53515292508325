import { Modal } from 'components/_modals'
import { useDialogWithParameter } from 'components/_hooks'
import { AlertModal } from 'components/_modals'
import { DropDownTable, LoadingBox, Pagination, useDropDownTableStyles } from 'components/_shared'
import { TournamentStatusEnum } from 'consts'
import { kl_UpdateTournamentStatus } from 'models/tournament-update-status-viewmodel'
import React, { useCallback, useEffect } from 'react'
import { TournamentUtils } from 'server/utils/tournament-utils'
import { getIsStartDatePassed, TournamentType } from 'store/types/tournaments-types'
import { cn } from 'utils'
import CanceledTournamentsRow from './CanceledTournamentsRow'
import styles from '../EventsOrganizer.module.scss'
import { useHistory } from 'react-router'
import { defaultOrganizerEventsFilters, organizerEventsFiltersChanged } from 'store/types/pages/organizer-events-page-types'
import { ApplicationState } from 'store/types/common'
import { getAllMyOrganizerCanceledTournamentsCount, getIsMyOrganizerCanceledTournamentsLoading, getMyOrganizerCanceledTournamentsIds, getOrganizerEventsPageFilters } from 'store/selectors/pages/organizer-events-page-selectors'
import { useDispatch, useSelector } from 'react-redux'
import { loadMyOrganizerCanceledTournaments } from 'store/logic/pages/organizer-events-page-logic'
import { TournamentFilters } from 'store/logic/tournaments-logic'
import { ResumeCancelEventModel } from 'models/resume-cancel-event-model'
import { ResumeEventStatusType } from 'consts/ResumeEventStatusType'
interface Props {
  filters: TournamentFilters
}

const pageSize = 8

const CanceledTournaments = ({ filters }: Props) => {
  const dropDownTableStyles = useDropDownTableStyles()

  const history = useHistory()
  const dispatch = useDispatch()

  const storedFilters = useSelector((state: ApplicationState) => getOrganizerEventsPageFilters(state))
  const page = storedFilters.currentPage
  const tournaments = useSelector(getMyOrganizerCanceledTournamentsIds)
  const allTournamentsCount = useSelector(getAllMyOrganizerCanceledTournamentsCount)
  const isTournamentsLoading = useSelector(getIsMyOrganizerCanceledTournamentsLoading)

  const loadTournaments = useCallback((filters: TournamentFilters, pageNumber: number) => {
    dispatch(loadMyOrganizerCanceledTournaments(filters, {
      page: pageNumber,
      pageSize,
    }))
  }, [dispatch])

  useEffect(() => {
    loadTournaments(filters, page)
  }, [dispatch, filters, loadTournaments, page])

  const onChangePage = (pageNumber: number) => {
    dispatch(organizerEventsFiltersChanged(
      {
        filters: {
          ...storedFilters,
          currentPage: pageNumber,
        },
      }
    ))
  }

  const resetFilters = () => {
    if (storedFilters.currentPage != 0) {
      dispatch(organizerEventsFiltersChanged(
        {
          filters: {
            ...defaultOrganizerEventsFilters,
            currentPage: 0,
            currentTab: storedFilters.currentTab,
          },
        }
      ))
    }
    else {
      loadTournaments(filters, page)
    }
  }
  const resumeEvent = async (tournamentDetailId: number) => {
    const model: kl_UpdateTournamentStatus = new Object()
    model.id = tournamentDetailId
    model.status = TournamentStatusEnum.Pending
    const result = await TournamentUtils.UpdateTournamentStatus(model)

    if (!result.isError) {
      resetFilters()
    } else {
      throw Error(result.message)
    }
  }

  const deleteEvent = async (tournamentDetailId: number) => {
    const result = await TournamentUtils.DeleteTournament(tournamentDetailId)
    if (!result.isError) {
      resetFilters()
    } else {
      throw Error(result.message)
    }
  }

  const handleConfirmResume = async (tournament: TournamentType) => {
    const isStartDatePassed = getIsStartDatePassed(tournament)
    if (!isStartDatePassed) {
      await resumeEvent(tournament.TournamentDetailId)
    }
    else {
      localStorage.setItem('IsResumeEvent', 'true')
      history.push(`/edit-tournament/${tournament.TournamentDetailId}`)
    }
  }

  const handleConfirmDelete = async (tournament: TournamentType) => {
    await deleteEvent(tournament.TournamentDetailId)
  }


  const [resumeTournamentModal, openResumeTournamentModal] = useDialogWithParameter<ResumeCancelEventModel>((model, closeDialog) => {
    if (model.resumeEventStatus === ResumeEventStatusType.MainEventActiveOrFinished) {
      return (
        <AlertModal text="This event cannot be resume" onClose={closeDialog} buttonText="Close" />
      )
    }
    else if (model.resumeEventStatus === ResumeEventStatusType.RepeatedEvent) {
      return (
        <AlertModal text="You can not resume this tournament because there are repeated tournaments currently still available" onClose={closeDialog} buttonText="Close" />
      )
    }
    else {
      return (
        <Modal
          onClose={closeDialog}
          allowOk
          asyncOperation={async () => {
            await handleConfirmResume(model.tournament)
            return true
          }}
          className={styles.confirm__modal}
          buttonColor="blue"
          okButtonText="Confirm"
        >
          <Modal.Header>Are you sure you want to resume tournament?</Modal.Header>
        </Modal>
      )
    }
  })

  const [deleteTournamentModal, openDeleteTournamentModal] = useDialogWithParameter<TournamentType>((tournament, closeDialog) => (
    <Modal
      onClose={closeDialog}
      allowOk
      asyncOperation={async () => {
        await handleConfirmDelete(tournament)
        return true
      }}
      buttonColor="blue"
      className={styles.confirm__modal}
      okButtonText="Confirm"
    >
      <Modal.Header>Are you sure you want to delete tournament?</Modal.Header>
    </Modal>
  ))

  return (
    <>
      <LoadingBox size="big" loading={isTournamentsLoading}>
        <div className={cn(dropDownTableStyles.dropDownTableMediaQueries)}>
          <DropDownTable.HeadersRow className="">
            <DropDownTable.Column className={cn(styles.table__column, styles.eventName)}>Tournament</DropDownTable.Column>
            <DropDownTable.Column className={cn(styles.table__column, styles.date)}>Start date</DropDownTable.Column>
            <DropDownTable.Column className={cn(styles.table__column, styles.time)}>Time</DropDownTable.Column>
            <DropDownTable.Column className={cn(styles.table__column, styles.venue)}>Venue</DropDownTable.Column>
            <DropDownTable.Column className={cn(styles.table__column, styles.location)}>Location</DropDownTable.Column>
            <DropDownTable.Column className={cn(styles.table__column, styles.id)}>Id</DropDownTable.Column>
            <DropDownTable.Column className={cn(styles.table__column, styles.buttons)} />
          </DropDownTable.HeadersRow>
          {tournaments &&
            tournaments.map(tournament => {
              return <CanceledTournamentsRow key={tournament} tournamentId={tournament} openResumeConfirmDialog={openResumeTournamentModal} deleteTournamentConfirmDialog={openDeleteTournamentModal} />
            })
          }
          {resumeTournamentModal}
          {deleteTournamentModal}
        </div>
      </LoadingBox>
      <Pagination page={page} itemsPerPage={pageSize} allItemsCount={allTournamentsCount} onChangePage={onChangePage} />
    </>

  )
}

export default CanceledTournaments
