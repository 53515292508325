import { LeftPlayerMenu } from 'components/PlayerPage/_shared/LeftPlayerMenu'
import { ContainerName, useAppUser, useIntRouteParam, useUser } from 'components/_hooks'
import { useKlHistory } from 'components/_hooks/useKlHistory'
import { ArticleHeader, HeaderButton, LoadingBox, Pagination } from 'components/_shared'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadPageFriends } from 'store/logic/pages/friends-page-logic'
import { loadPlayerProfileByUserId, loadUser } from 'store/logic/user-logic'
import { makeGetAllFriendsCount, makeGetFriendsIds, makeGetIsFriendsLoading } from 'store/selectors/pages/friends-page-selectors'
import { ApplicationState } from 'store/types/common'
import { cn } from 'utils'
import FriendCard from './FriendCard'
import styles from './FriendsPage.module.scss'

interface FriendsPageRouteParams {
  userId: string
}

const pageSize = 12

const FriendsPage = () => {
  const dispatch = useDispatch()
  const loggedInuser = useAppUser()
  const history = useKlHistory()

  const userId: number = useIntRouteParam<FriendsPageRouteParams>(x => x.userId)
  const { user, isUserLoading } = useUser(userId)
  //const [filter, setFilter] = useState('')
  const [page, setPage] = useState(0)

  useEffect(() => {
    dispatch(loadPageFriends(userId, {page: page, pageSize: pageSize }))
    loadUser(userId)
    dispatch(loadPlayerProfileByUserId(userId))
  }, [dispatch, page, userId])
  
  const getIsFriendsLoading = useMemo(makeGetIsFriendsLoading, [])
  const isFriendsLoading = useSelector((state: ApplicationState) => getIsFriendsLoading(state, { userId: userId }))
  const getFriends = makeGetFriendsIds()
  const friendsIds = useSelector((state: ApplicationState) => getFriends(state, { userId: userId }))

  const getAllFriendsCount = makeGetAllFriendsCount()
  const allFriendsCount =  useSelector((state: ApplicationState) => getAllFriendsCount(state, { userId: userId }))

  const handlePageChanged = (page: number) => {
    setPage(page)   
  }

  const isPublicView = useMemo(() => {
    if (!loggedInuser || !user) {
      return true
    }

    return loggedInuser.Id !== user.Id
  }, [loggedInuser, user])

  const handleBack = () => {
    history.goBack()
  }

  const header = useMemo(() => {
    if (!isPublicView) {      
      return 'My Profile'

    } else if (user?.Username) {
      return `${user.Username}'s Profile`
    }
    return 'Player profile'
  }, [isPublicView, user?.Username])
  
  return (
    <>
      <ArticleHeader  text={header} >
        <HeaderButton onClick={handleBack} display="always">Back</HeaderButton>
      </ArticleHeader>
      <LoadingBox loading={isUserLoading} size="big">
        <div className="d-flex mt-5">
          <LeftPlayerMenu
            isPublicView
            userId={userId}     
            hideAllFriendsButton      
          />
          <div className="d-flex flex-column flex-fill pl-4 overflow-hidden">
            <div className={styles.friendsHeader}>
              <div className={cn(styles.rightTile, 'mt-2')}>
                Friends
              </div>
              {/* <Search value={filter} onChange={setFilter} placeholder="Search by name" className={styles.filterName} /> */}
            </div>
            <LoadingBox loading={isFriendsLoading} size="big">
              <div className={cn(styles.membersContainer, 'd-flex flex-wrap')}>
                {friendsIds.length > 0 
                  ? friendsIds.map(id => <FriendCard containerName={ContainerName.AvatarsNormal} key={id} userId={id} />)
                  : <div className={cn(styles.noFriends, 'pb-2 mt-2')}>No friends</div>
                }
              </div>
            </LoadingBox>            
            <Pagination page={page} itemsPerPage={pageSize} allItemsCount={allFriendsCount} onChangePage={handlePageChanged} />
          </div>
        </div>
      </LoadingBox>
    </>
  )
}

export default FriendsPage
