import { EventEmitter } from 'events'
import { useEffect } from 'react'

export const hubEvents = {
  MessageReceived: 'MessageReceived',
  OnlineStatusReceived: 'OnlineStatusReceived',
  ChatLeftNotificationReceived: 'ChatLeftNotificationReceived',
  ChatDeletedNotificationReceived: 'ChatDeletedNotificationReceived',
  ChatMemberBlockedReceived: 'ChatMemberBlockedReceived',
  SayWhoIsTyping: 'SayWhoIsTyping',
  ReloadTournamentDetail: 'ReloadTournamentDetail',
}

export type HubEvent = keyof typeof hubEvents


export const eventEmitter = new EventEmitter()

const addHubEventListener = (event: HubEvent, listener: (...args: unknown[]) => void) => {
  eventEmitter.addListener(event, listener)
}

const removeHubEventListener = (event: HubEvent, listener: (...args: unknown[]) => void) => {
  eventEmitter.removeListener(event, listener)
}

export const useSubscribe = (event: HubEvent, listener: (...args: unknown[]) => void) => {

  useEffect(() => {
    addHubEventListener(event, listener)

    return () => {
      removeHubEventListener(event, listener)
    }
  }, [event, listener])
}
