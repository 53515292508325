import { ExecuteAPIGet } from '../server-utils'
import { EndPoints } from '../endpoints'
import { MyTransactionViewModel } from '../../models/my-transaction-viewmodel'
import {
  AdminOrganizerTransactionViewModel,
  OrganizerTournamentTransactionsViewModel,
  OrganizerTournamentTransactionsSalesDetailsModel,
  OrganizerWithdrawTransactionsViewModel,
  AdminOrganizerWithdrawTransactionsViewModel,
} from '../../models/organizer-transaction-viewmodel'
import { kl_CurrencyModel } from 'models/billing-option-model'


export const TransactionUtils = {
  MyTransaction: async function MyTransaction(pageNo: number, type: string) {
    return await ExecuteAPIGet<MyTransactionViewModel>(EndPoints.Transaction.MyTransaction, `pageNo=${pageNo}&type=${type}`)
  },
  OrganizerTransaction: async function OrganizerTransaction(pageNo: number, type: number) {
    return await ExecuteAPIGet<AdminOrganizerTransactionViewModel>(
      EndPoints.Transaction.OrganizerTransaction,
      `pageNo=${pageNo}&type=${type}`
    )
  },
  OrganizerAdminTransaction: async function OrganizerAdminTransaction(organizerId: number, pageNo: number, type: number, isAdmin: boolean) {
    return await ExecuteAPIGet<AdminOrganizerTransactionViewModel>(
      EndPoints.Transaction.OrganizerAdminTransaction,
      `organizerId=${organizerId}&pageNo=${pageNo}&type=${type}&isAdmin=${isAdmin}`
    )
  },
  OrganizerAmount: async function OrganizerAmount() {
    return await ExecuteAPIGet<number>(EndPoints.Transaction.OrganizerAmount)
  },
  OrganizerAdminAmount: async function OrganizerAmount(organizerId: number) {
    return await ExecuteAPIGet<number>(EndPoints.Transaction.OrganizerAdminAmount, `organizerId=${organizerId}`)
  },
  OrganizerTournamentTransactions: async function OrganizerTournamentTransactions(organizerId: number) {
    return await ExecuteAPIGet<OrganizerTournamentTransactionsViewModel>(
      EndPoints.Transaction.OrganizerTournamentTransactions
      
    )
  },
  OrganizerTournamentAdminTransactions: async function OrganizerTournamentTransactions(organizerId: number) {
    return await ExecuteAPIGet<OrganizerTournamentTransactionsViewModel>(
      EndPoints.Transaction.OrganizerTournamentAdminTransactions,
      `organizerId=${organizerId}`
    )
  },
  TournamentTransactionSalesDetails: async function TournamentTransactionSalesDetails(tournamentId: number, isparticipant: boolean) {
    return await ExecuteAPIGet<OrganizerTournamentTransactionsSalesDetailsModel[]>(
      EndPoints.Transaction.TournamentTicketSalesDetails,
      `tournamentId=${tournamentId}&IsParticipant=${isparticipant}`
    )
  },
  GetExchangeCurrencyPrice: async function GetExchangeCurrencyPrice(currency: string) {
    return await ExecuteAPIGet<string>(EndPoints.Transaction.GetExchangeCurrencyPrice, `currency=${currency}`)
  },
  GetCountryCurrencyDataById: async function GetCountryCurrencyDataById(country:string) {
    return await ExecuteAPIGet<kl_CurrencyModel>(EndPoints.Transaction.GetCountryCurrencyDataById,`country=${country}`)
  },
  OrganizerWithdrawTransaction: async function OrganizerWithdrawTransaction(organizerId: number, pageNo: number, type: number) {
    return await ExecuteAPIGet<OrganizerWithdrawTransactionsViewModel>(
      EndPoints.Transaction.OrganizerTransaction,
      `organizerId=${organizerId}&pageNo=${pageNo}&type=${type}`
    )
  },
  OrganizerAdminWithdrawTransaction: async function OrganizerAdminWithdrawTransaction(organizerId: number, pageNo: number, type: number,isAdmin?:boolean) {
    return await ExecuteAPIGet<AdminOrganizerWithdrawTransactionsViewModel>(
      EndPoints.Transaction.OrganizerAdminTransaction,
      `organizerId=${organizerId}&pageNo=${pageNo}&type=${type}&isAdmin=${isAdmin}`
    )
  },
  FreezeUnfreezeTransactions: async function FreezeUnfreezeTransactions(TournamentId: number) {
    return await ExecuteAPIGet<boolean>(EndPoints.Transaction.FreezeUnfreezeTransactions, `TournamentId=${TournamentId}`)
  },
  GetCurrencyDataFromCountry:async function GetCurrencyDataFromCountry(Country: string) {
    return await ExecuteAPIGet<any>(EndPoints.Transaction.GetCurrencyDataFromCountry, `Country=${Country}`)
  },
}
