import React from 'react'
import { useOpenUserChat } from 'store/chat/chat/chat-hooks'

interface Props {
  organizerId: number
}

const TournamentChatOrganizerButton = ({organizerId}: Props) => {

  const openUserChat = useOpenUserChat()

  const handleClick = () => {
    openUserChat(organizerId)
  }

  return (
    <a
      id="haveQuestion"
      onClick={handleClick}
    >
      Have a question?
    </a>
  )
}

export default TournamentChatOrganizerButton
