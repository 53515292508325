import { Reducer } from 'redux'
import { OrganizerTournamentAndMembersAndIncomeKnownAction, OrganizerTournamentAndMembersAndIncomeState, ORGANIZER_TOURNAMENT_AND_MEMBERS_AND_INCOME_LOADED } from 'store/types/organizer-tournament-members-income-type'
import { ONLINE_STATUS_CHANGED } from 'store/types/user-types'

const initialState: OrganizerTournamentAndMembersAndIncomeState = {
  OrganizerTournamentAndMembersAndIncome: undefined,
  isLoaded:undefined
}

const reducer: Reducer<OrganizerTournamentAndMembersAndIncomeState, OrganizerTournamentAndMembersAndIncomeKnownAction> = (state: OrganizerTournamentAndMembersAndIncomeState | undefined = initialState, action: OrganizerTournamentAndMembersAndIncomeKnownAction) => {
  switch (action.type) {
    case ORGANIZER_TOURNAMENT_AND_MEMBERS_AND_INCOME_LOADED: {
      return {
        ...state,
        OrganizerTournamentAndMembersAndIncome: action.payload.OrganizerTournamentAndMembersAndIncome,
        isLoaded:true
      }
    }
    case ONLINE_STATUS_CHANGED: {
      return {
        ...state,
        isLoaded:false
      }
    }
    default:
      return state
  }
}

export default reducer