import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { makeGetMatchParticipant } from 'store/selectors/match-participant-selectors'
import { ApplicationState } from 'store/types/common'
import { MatchParticipantModel } from 'store/types/match-participant-types'
import { ParticipantType } from 'store/types/participants-types'
import { useParticipant } from './useParticipant'

interface UseMatchParticipantResult {
  tournamentParticipant?: ParticipantType
  matchParticipant?: MatchParticipantModel
}

export const useMatchParticipant = (matchParticipantId?: number): UseMatchParticipantResult => {
  const getMatchParticipant = useMemo(makeGetMatchParticipant, [])
  const matchParticipant = useSelector((state: ApplicationState) => getMatchParticipant(state, matchParticipantId))
  const { tournamentParticipant } = useParticipant(matchParticipant?.TournamentParticipantId)

  return {
    matchParticipant,
    tournamentParticipant,
  }
}
