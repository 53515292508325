import { ReactNode } from 'react'
import { cn } from 'utils'
import { KlLink } from '../KlLink/KlLink'
import { useThemeStyles } from 'components/_hooks'
import stylesDark from './KlLinkDark.module.scss'
import stylesLight from './KlLinkLight.module.scss'

interface ThemedKlLinkProps {
  to: string
  children?: ReactNode
  loading?: boolean
  disabled?: boolean
  className?: string
  title?: string
  onClick?: () => void
}

export const ThemedKlLink = ({ disabled, to, className, children, title, loading, onClick }: ThemedKlLinkProps) => {
  const styles = useThemeStyles(stylesDark, stylesLight)

  disabled = disabled || loading
  
  return (
    <KlLink onClick={onClick} className={cn(styles.link, className)} disabled={disabled} loading={loading} title={title} to={to} >{children}</KlLink>
  )
}
