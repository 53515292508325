import { Button, ButtonProps } from 'components/_shared'
import React from 'react'
import { cn } from 'utils'
import styles from '../modals.module.scss'

export type WindowButtonProps = ButtonProps

export const WindowButton = ({ className, ...otherProps }: WindowButtonProps) => {
  return <Button {...otherProps} className={cn(styles.window__button, className)} />
}
