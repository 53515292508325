import { useMemberClan } from 'components/_hooks'
import MemberOnlineAvatar from 'components/_shared/ParticipantAvatar/MemberOnlineAvatar'
import React from 'react'
import { useSelector } from 'react-redux'
import { makeGetUser } from 'store/selectors/user-selectors'
import { ApplicationState } from 'store/types/common'
import { LineUpType } from 'store/types/line-up-types'

interface Props {
  lineUp: LineUpType
}

const LineUpRow = ({lineUp}: Props) => {

  const getUser = React.useMemo(makeGetUser, [])
  const user = useSelector((state: ApplicationState) => getUser(state, lineUp.PlayerId))
  const {isClanLeader} = useMemberClan(lineUp.PlayerId)

  return (
    <div className="m-checked_i" key={lineUp.Id}>
      <div className="m-checked_i_main">
        <div className="m-checked_row">
          <div className="m-checked_col">
            <a href="#" className="prsn">
              <div className="prsn_l">
                <MemberOnlineAvatar userId={user?.Id} withLink className="prsn_icon" />
              </div>
              <div className="prsn_r">
                <span className="prsn_ttl">{lineUp.IsSubstitute?(user?.Username + (' - (Substitute)')):(user?.Username + (' - (Playing)'))}</span>
              </div>
            </a>
          </div>
          {isClanLeader && (
            <div className="m-checked_col pl-1">
              <img src="https://kombatlink-assets.azureedge.net/img/crown-accent.svg" alt="" />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default LineUpRow
