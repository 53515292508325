import { NotificationType, NotificationButtonType, NotificationVisibility } from 'consts'
import { makeActionCreator, ReduxAction } from './common'

interface NotificationButtonModel {
  Text: string
  Uri: string
  Type: NotificationButtonType
}

export interface NotificationModel {
  Id: number
  UserId: number
  CreatedDate: string
  Read: boolean
  Type: NotificationType
  SubjectId: number
  Message: string
  Title: string
  Button: NotificationButtonModel
  Visibility: NotificationVisibility
}
  
export interface State {
  notificationsIds: number[]
  notificationQueueIds: number[]
  notificationsById: {
    [key: number]: NotificationModel
  }
  allNotificationsCount: number
  unreadNotificationsCount: number
}

export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'
export interface SetNotificationsAction {
  type: typeof SET_NOTIFICATIONS
  notifications: NotificationModel[]
  allNotificationsCount: number
  reload: boolean
}
export function setNotifications(notifications: NotificationModel[], allNotificationsCount: number, reload: boolean): SetNotificationsAction {
  return {
    type: SET_NOTIFICATIONS,
    notifications,
    allNotificationsCount,
    reload,
  }
}

export const SET_UNREAD_NOTIFICATIONS_COUNT = 'SET_UNREAD_NOTIFICATIONS_COUNT'
export interface SetUnreadNotificationsCountAction {
  type: typeof SET_UNREAD_NOTIFICATIONS_COUNT
  unreadNotificationsCount: number
}
export function setUnreadNotificationsCount(unreadNotificationsCount: number): SetUnreadNotificationsCountAction {
  return {
    type: SET_UNREAD_NOTIFICATIONS_COUNT,
    unreadNotificationsCount,
  }
}

export const SET_NOTIFICATION = 'SET_NOTIFICATION'
export interface SetNotificationAction {
  type: typeof SET_NOTIFICATION
  notification: NotificationModel
}
export function setNotification(notification: NotificationModel): SetNotificationAction {
  return {
    type: SET_NOTIFICATION,
    notification,
  }
}

export const NOTIFICATION_READ = 'NOTIFICATION_READ'
export type NotificationReadAction = ReduxAction<typeof NOTIFICATION_READ, number>
export const notificationRead = makeActionCreator<number>(NOTIFICATION_READ)

export const DEQUEUE_NOTIFICATION = 'DEQUEUE_NOTIFICATION'
export type DequeueNotificationAction = ReduxAction<typeof DEQUEUE_NOTIFICATION, number>
export const dequeueNotification = makeActionCreator<number>(DEQUEUE_NOTIFICATION)

export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION'
export interface DeleteNotificationAction {
  type: typeof DELETE_NOTIFICATION
  notificationId: number
}
export function deleteNotification(notificationId: number): DeleteNotificationAction {
  return {
    type: DELETE_NOTIFICATION,
    notificationId,
  }
}

export const ADD_NEW_NOTIFICATIONS = 'ADD_NEW_NOTIFICATIONS'
export interface AddNewNotificationsAction {
  type: typeof ADD_NEW_NOTIFICATIONS
  notifications: NotificationModel[]
}
export function addNewNotifications(notifications: NotificationModel[]): AddNewNotificationsAction {
  return {
    type: ADD_NEW_NOTIFICATIONS,
    notifications,
  }
}

export const NOTIFICATION_QUEUE_LOADED = 'NOTIFICATION_QUEUE_LOADED'
export type NotificationQueueLoadedAction = ReduxAction<typeof NOTIFICATION_QUEUE_LOADED,  NotificationModel[]>
export const notificationQueueLoaded = makeActionCreator<NotificationModel[]>(NOTIFICATION_QUEUE_LOADED)

export type KnownAction =
  | SetNotificationsAction
  | SetUnreadNotificationsCountAction
  | SetNotificationAction
  | AddNewNotificationsAction
  | DeleteNotificationAction
  | NotificationReadAction
  | NotificationQueueLoadedAction
  | DequeueNotificationAction
