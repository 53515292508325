import { useIntMatchParam } from 'components/_hooks'
import { TournamentBracket } from 'components/_shared'
import React from 'react'
import { RouteComponentProps } from 'react-router-dom'

interface TournamentBracketPageRouteProps {
  tournamentDetailId?: string
}
type TournamentBracketPageProps = RouteComponentProps<TournamentBracketPageRouteProps>

const TournamentBracketPage = ({ match }: TournamentBracketPageProps) => {
  const tournamentDetailId = useIntMatchParam(match, m => m.tournamentDetailId)

  return (
    <div className="eliminationPage">
      <TournamentBracket
        tournamentDetailId={tournamentDetailId}
        fullSreenDisabled type="store"
        isSearchEnabled
      />
    </div>
  )
}

export default TournamentBracketPage
