import { useAppUser, useUnionRouteParam } from 'components/_hooks'
import {
  ArticleHeader,
  HeaderButton,
  Tabs,
} from 'components/_shared'
import React, { useState } from 'react'
import { getIsOrganizer } from 'store/types/user-types'
import styles from './MembersPage.module.scss'
import { MembersTab, membersTabs } from './_shared/consts'
import { ClansTab, OrganizersTab, PlayersTab } from './_shared/MembersTabs'

interface MembersPageRouteParams {
  tab: MembersTab
}

export const MembersPage = () => {
  const tab = useUnionRouteParam<MembersPageRouteParams, MembersTab>(membersTabs, p => p.tab, 'players')
  const [filtersModalOpened, setFiltersModalOpened] = useState(false)
  const appUser = useAppUser()

  const isOrganizer = getIsOrganizer(appUser.Role)

  const openFiltersModal = () => {
    setFiltersModalOpened(true)
  }

  const closeFiltersModal = () => {
    setFiltersModalOpened(false)
  }

  return (
    <>
      <ArticleHeader text="Directory">
        <HeaderButton onClick={openFiltersModal}>Filter Options</HeaderButton>
      </ArticleHeader>
      <div className="row m-0--30">
        <div className="col-12">
          <Tabs className={styles.articleTabMenuBeforeContainer}>
            <Tabs.Menu>
              <Tabs.Link url="/members/players" active={tab === 'players'}>Members</Tabs.Link>
              <Tabs.Link url="/members/clans" active={tab === 'clans'}>Clans</Tabs.Link>
              {!isOrganizer && <Tabs.Link url="/members/organizers" active={tab === 'organizers'}>Organizers</Tabs.Link>}
            </Tabs.Menu>
          </Tabs>
        </div>
      </div>
      {tab === 'players' && <PlayersTab filtersModalOpened={filtersModalOpened} closeFiltersModal={closeFiltersModal} />}
      {tab === 'clans' && <ClansTab filtersModalOpened={filtersModalOpened} closeFiltersModal={closeFiltersModal} />}
      {tab === 'organizers' && !isOrganizer && <OrganizersTab filtersModalOpened={filtersModalOpened} closeFiltersModal={closeFiltersModal} />}
    </>
  ) 
}
