import { useEffect } from 'react'
import { useCreatePrivateChat, useLoadPrivateChatMembers } from 'store/chat/chat-member/chat-member-logic'
import { useChat } from 'store/chat/chat/chat-hooks'

export const useGetOrCreateChat = () => {
  const {
    openedChatId,
    openedUserId,
    privateChatIdByUserId,
    chat,
  } = useChat()
  const loadPrivateChatMembers = useLoadPrivateChatMembers(openedUserId)
  const createPrivateChat = useCreatePrivateChat(openedUserId)

  useEffect(() => {
    const getOrCreateChat = async () => {
      if (chat) {
        return
      }
    
      if (openedChatId) {
        // loadChat(openedChatId)
        return
      }
    
      if (!openedUserId) {
        return
      }
    
      if (privateChatIdByUserId) {
        // loadChat(privateChatIdByUserId)
        return
      }
    
      const { Chats } = await loadPrivateChatMembers()
      if (Chats.length === 0) {
        await createPrivateChat()
      }
    }

    void getOrCreateChat()
  }, [chat, createPrivateChat, loadPrivateChatMembers, openedChatId, openedUserId, privateChatIdByUserId])
}
