import { Button } from 'components/_shared'
import React, { useState } from 'react'
import { checkResetPasswordVerificationCode } from 'store/logic/user-logic'
import { cn } from 'utils'

import styles from '../Login.module.scss'

interface Props {
  email: string
  onCodeConfirmed: (code: string) => void
}

const PasswordRecoveryConfirm = ({email, onCodeConfirmed} : Props) => {

  const [verificartionCode, setVerificartionCode] = useState('')
  const [error, setError] = React.useState('')
  const [isBusy, setIsBusy] = React.useState(false)

  
  const handleChangeCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setVerificartionCode(value)
  }

  const handleSubmit = async () => {
    try {
      setIsBusy(true)
      const isValid = await checkResetPasswordVerificationCode(email, verificartionCode)
      if (isValid) {
        onCodeConfirmed(verificartionCode)
      } else {
        setError('Code invalid')
      }
    } catch(error) {
      setError(error.toString())
    }
    finally {
      setIsBusy(false)
    }
  }

  const canSubmit = !!verificartionCode && !isBusy


  return (
    <>
      <div className="row">
        <div className="col-12">
          <h1 className={cn(styles.signIn__subheader, 'text-center')}>Check your email for verification code</h1>
        </div>
      </div>
      <div className={cn('row', styles.row)}>
        <div className="col-lg-4 col-md-6">
          <div className="d-flex flex-column">
            <div id="email-container">
              <div className={styles.signIn__inputTitle}>Enter verification code</div>
              <div className={styles.signIn__container}>
                <input className={styles.signIn__defaultInput} placeholder="Verification code" value={verificartionCode} onChange={handleChangeCode} />
              </div>
            </div>
            {error && (
              <div className={styles.errorMessContainer}>
                <span className={styles.errorMess}>
                  <span className={styles.errorMess_icon} />
                  {error}
                </span>
              </div>
            )}
          </div>
          <div>
            <Button onClick={handleSubmit} className={styles.signIn__login} color="white" disabled={!canSubmit} loading={isBusy}>
              Confirm Code
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default PasswordRecoveryConfirm
