import { ParticipantsResults } from 'components/_shared/MatchResultModal/MatchResultModal'
import React from 'react'
import { TournamentMatchesTree } from 'store/types/match-types'
import { RoundType } from 'store/types/rounds-types'
import { TournamentType } from 'store/types/tournaments-types'
import { cn, defaultFormatDateTime } from 'utils'
import SingleEliminationBracket from './SingleEliminationBracket'
import styles from './TournamentBracket.module.scss'


interface Props {
  rounds: RoundType[]
  tournament: TournamentType
  tournamentTree: TournamentMatchesTree
  openMatchResultModal: (participantResults: ParticipantsResults) => void
  openPlayerModal: (videoUrl: string) => void
  filterName: string
}

const TournamentBracketSection = ({rounds, tournament, tournamentTree, openMatchResultModal, openPlayerModal, filterName }: Props) => {
  return (               
    <div>
      <div className={cn('d-flex', styles.article__roundsRow, 'align-items-center', 'flex-nowrap')}>
        {rounds.map(round => (
          <div key={round.Id} className={styles.article__roundsColumn}>
            <div className={styles.article__roundTitle}>{round.RoundTitle}</div>
            <div className={styles.article__roundTime}>{defaultFormatDateTime(round.PlayDateTime)}</div>
          </div>
        ))}
      </div>
      {tournament && (
        <div className={cn('row', styles.article__movies, styles.movies)}>
          <div className={cn('col-auto', styles.colAuto)}>
            {tournamentTree && (
              <SingleEliminationBracket
                tournamentTree={tournamentTree}
                openMatchResultModal={openMatchResultModal}
                openPlayerModal={openPlayerModal}
                filterName={filterName}
              />
            )}
          </div>
        </div>  
      )
      }
      
    </div>                
  )
}

export default TournamentBracketSection
