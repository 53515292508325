let closeButtons
let backgrounds
let modals

export const ModalUtils = {
  initModals: function initModals() {
    closeButtons = document.querySelectorAll('.window__close')
    for (const close of closeButtons) {
      close.addEventListener('click', () => closeModals())
    }

    backgrounds = document.querySelectorAll('.modal-container__background')
    for (const background of backgrounds) {
      background.addEventListener('click', () => closeModals())
    }

    modals = document.querySelectorAll('.modal-container')
  },

  openModal: function openModal(id: string) {
    document.querySelector('body').style.overflow = 'hidden'
    document.querySelector(`#${id}`).classList.add('modal-container--opened')
  },

  closeModals: function closeModals() {
    for (const modal of modals) {
      document.querySelector('body').style.overflow = 'auto'
      modal.classList.remove('modal-container--opened')
    }
  }
}
