import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadMatchParticipants } from 'store/logic/match-participant-logic'

import { makeGetMatchLinks } from 'store/selectors/match-links-selectors'
import { ApplicationState } from 'store/types/common'
import { MatchLinkStatusEnum } from 'store/types/match-link-types'
import { MatchModel } from 'store/types/match-types'
import MoviesStreamComponent from './MoviesStreamComponent'

interface Props {
  match: MatchModel
}

const VictoryRoadMatch = ({ match }: Props) => {

  const dispatch = useDispatch()
  React.useEffect(() => {
    dispatch(loadMatchParticipants(match.Id))
  }, [dispatch, match.Id])

  const getMatchLinks = React.useMemo(makeGetMatchLinks, [])
  const matchLinks = useSelector((state: ApplicationState) => getMatchLinks(state, match.Id))

  const approvedLinks = React.useMemo(() => matchLinks.filter(ml => ml.Status == MatchLinkStatusEnum.Approved)
    , [matchLinks])

  return (
    <>
      {approvedLinks.map((matchLink,i) => (
        <MoviesStreamComponent
          matchLink={matchLink}
          match={match}
          key={i.toString() +'_' + match.Id.toString()}
        />
      ))}
    </>
  )
}


export default VictoryRoadMatch
