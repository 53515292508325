import React from 'react'
import { ChatInput } from '../ChatInput/ChatInput'
import styles from './ChatSearchInput.module.scss'

export interface ChatSearchInputProps {  
  value: string
  onChange: (value: string) => void
}

export const ChatSearchInput = ({value, onChange}:  ChatSearchInputProps) => {
  return (
    <ChatInput placeholder="Search by name" className={styles.chatInputSearchContainer} value={value} onChange={onChange} />
  )
}
