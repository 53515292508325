import { useMatch, useRound } from 'components/_hooks'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { makeGetRoundMatchesIds } from 'store/selectors/match-selectors'
import { ApplicationState } from 'store/types/common'

export const useFindSingleFfaMatch = (roundId?: number) => {
  const getRoundMatchesIds = useMemo(makeGetRoundMatchesIds, [])
  const { round } = useRound(roundId)
  const matchesIds = useSelector((state: ApplicationState) => getRoundMatchesIds(state, round?.Id))
  const { match } = useMatch(matchesIds[0])

  return match
}
