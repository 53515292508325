
import React from 'react'

import { ParticipantType } from 'store/types/participants-types'
import { TournamentViewModel_kl } from 'models/tournament-viewmodel'
import { TournamentModeEnum } from 'consts'
import { useSelector } from 'react-redux'
import { getAppUser } from 'store/selectors/app-selectors'
import { useMemberClan } from 'components/_hooks'
import { getIsAdmin, getIsOrganizer } from 'store/types/user-types'
// import TournamentDetailTicketCartComponent from './TournamentDetailTicketCartComponent'


interface Props {
  tournament: TournamentViewModel_kl
  participants: ParticipantType[]
  saveToCart:(type:string)=>void
  checkParticipantTicketSoldOut:boolean
  checkSpectatorTicketSoldOut:boolean
}

const TicketsRegistrationContainer = ({tournament, participants,saveToCart,checkParticipantTicketSoldOut,checkSpectatorTicketSoldOut} : Props) => {

  const user = useSelector(getAppUser)
  const isOrganizer = getIsOrganizer(user?.Role) || getIsAdmin(user?.Role)
  const { isClanLeader, clan } = useMemberClan(user?.Id)
  // const [isTicketCartModalOpen, setIsTicketCartModalOpen] = React.useState(false)
  const isPending =  React.useMemo(() => {
    if (!tournament || !participants) {
      return false
    }

    const now = new Date()
    const isValidDate = new Date(tournament.RegistrationOpensOn) <= now && now < new Date(tournament.RegistrationClosesOn)

    return isValidDate
  }, [participants, tournament]) 

  
  const SaveToCart = async(type:string)=>{
    
    if(!checkSpectatorTicketSoldOut || !checkParticipantTicketSoldOut)
      await saveToCart(type)
  }
  const isClanTournamentWithoutLeader = React.useMemo(()=>{
    if (!tournament) {
      return false
    }
    return isClanLeader
  },[])

  
  const canBuyTicket = React.useMemo(() =>
    isPending && tournament && tournament.TournamentTickets && tournament.TournamentTickets.length > 0,
  [isPending, tournament]
  )

  return (
    <>
      {canBuyTicket && tournament.TournamentTickets && tournament.TournamentTickets.length > 0 && (
        
        <div className={`${(!isClanTournamentWithoutLeader && tournament.TournamentModeId == TournamentModeEnum.Clans) && !isOrganizer? "col-12 pl-1 pr-0":"col-6 pl-1 pr-0"}`}>
          <a
            className="registration__join green-button green-button--filled"
            onClick={async () => {  await SaveToCart('tickets') }}
          >
            {/* {tournament.TournamentTickets &&
            tournament.TournamentTickets[0].SoldOut != tournament.VenueSeatingCapacity
              ? tournament.TournamentTickets &&
              tournament.TournamentTickets[0] &&
              tournament.TournamentTickets[0].TicketPrice
                ? `$ ${tournament.TournamentTickets[0].TicketPrice}`
                : '$ 0'
              : ''}
          &nbsp;{' '} */}
            {tournament.TournamentTickets && (checkParticipantTicketSoldOut && checkSpectatorTicketSoldOut
              ? 'Sold Out'
              : 'TICKETS')}
          </a>
        </div>
      )}
      {/* {canBuyTicket && tournament.TournamentTickets.length > 1 && (
        <div className="col-6 pt-0 pr-0 pl-1">
          {' '}
          <a
            className="registration__join green-button green-button--filled"
            href="javascript:void(0)"
            onClick={() => {
              setIsTicketCartModalOpen(true)
            }}
          >
            {' '}
            {tournament.TournamentTickets &&
          tournament.TournamentTickets[0].SoldOut * 2 == tournament.VenueSeatingCapacity * 2
              ? 'Sold Out'
              : 'Add to cart'}
          </a>
        </div>
      )} */}
      {/* <TournamentDetailTicketCartComponent
        tournamentTickets={tournament.TournamentTickets}
        tournamentId={tournament.Id}
        onSaveToCart={onSaveTicketsToCart}
        isOpen={isTicketCartModalOpen}
        onClose={() => setIsTicketCartModalOpen(false)}
      /> */}
    </>
  )
}

export default TicketsRegistrationContainer
