import Konva from "konva"
import React, { useRef, useEffect, useCallback, useState } from "react"
import { Circle, Line,Transformer } from "react-konva"
import { ToolTipHover } from "./ToolTip/ToolTipHover"
export const Triangle =({ Id, isSelected,setSelectedShape,moveShape,transformShape,operationType,openSectionDetail,handleUpdateText,IsCartPage, ...shapeProps }) =>{
  const shapeRef = useRef()
  const circleRef = useRef()
  const transformerRef = useRef()
  const [text,setText] = useState<string>(shapeProps.SectionName)
  const [visible,setVisible] = useState<boolean>(false)
  useEffect(() => {
    if (isSelected) {
      //transformerRef.current.nodes([shapeRef.current])
      //transformerRef.current.getLayer().batchDraw()
    }
    if (shapeRef.current) {
      shapeRef.current.zIndex(0)
    }

    if (circleRef.current) {
      circleRef.current.zIndex(1)
    }
  }, [isSelected])

  const handleSelect = useCallback(
    (event) => {
      if(operationType!=='ticketArrangement'){
        event.cancelBubble = true
        setSelectedShape(Id)
      }
    },
    [Id]
  );
  const handleDblClick = useCallback(
    (event) => {
      if(operationType === 'sectionArrangement'){
        openSectionDetail(Id,event)
      }
    },
    [Id,operationType]
  )
  const handleDrag = useCallback(
    (e) => {
      if(IsCartPage === true){
          e.preventDefault()
          return false
      }
      if(operationType!=='ticketArrangement' && IsCartPage !== true){
        const absolutePoints = []
        const points = shapeRef.current.points()
        const transform = shapeRef.current.getAbsoluteTransform()
        let i = 0;
        while (i < shapeProps.points.length) {
          const point = {
            x: points[i],
            y: points[i + 1]
          };
          absolutePoints.push(transform.point(point))
          i = i + 2
        }

        const newPoints = []
        for (let val of absolutePoints) {
          newPoints.push(val.x)
          newPoints.push(val.y)
        }

        
        e.target.position({ x: 0, y: 0 })
        e.target.scale({ x: 1, y: 1 })

        shapeProps.points=newPoints
        shapeProps.Id = Id
        moveShape(Id, e,shapeProps,shapeProps.SectionShapeType)
      }
    },
    [Id]
  );
  const handleCircleDrag=(e, circleX, circleY)=> {
    if(IsCartPage === true){
      e.preventDefault()
      return false
    }
    let newPoints = [...shapeProps.points];
    for (let i = 0; i < newPoints.length; i++) {
      if (newPoints[i] === circleX && newPoints[i + 1] === circleY) {
        newPoints[i] = e.target.x()
        newPoints[i + 1] = e.target.y()
        break;
      }
    }
    shapeProps.points=newPoints
    shapeProps.Id = Id
    moveShape(Id, e,shapeProps,shapeProps.SectionShapeType)
  }
  
  const handleMouseDown= useCallback(e=>{
    const clickedOnPoly = e.target instanceof Konva.Line;
    if (clickedOnPoly) {
      handleSelect(e)
    }
    
    
  },[isSelected,Id])
   
  
  function chunkv2(arr:[],size:number) {
    let result = [];
    for(var i = 0; i < arr.length; i++) {
        if(i%size === 0)
            result.push([arr[i]])
        else
            result[result.length-1].push(arr[i])
    }
    return result;
  }
  const handleMouseMove = ()=>{
    // if(shapeProps.SectionName && shapeProps.SectionName!='' && shapeProps.SectionName!==null){
    //   setText("Section Name :"+shapeProps.SectionName)
    // }
    setText(IsCartPage!== true?"Click on the shape to explore more options":"Double click on section to select seats.")
    setVisible(true)
    
  }
  const handleMouseOut=()=>{
    setText('')
    setVisible(false)
  }
  return (
    <>
    {!shapeProps.IsLayOut && 
          <ToolTipHover 
            x={shapeProps.points[0]} 
            y={shapeProps.points[1]} 
            opacity={0.75} 
            visible={visible}
            toolTipfill='black'
            pointerDirection="down"
            pointerWidth={10}
            pointerHeight={10}
            lineJoin="round"
            shadowColor="black"
            shadowBlur={10}
            shadowOffsetX={10} 
            shadowOffsetY={10}
            shadowOpacity={0.5}
            text={text}
            fontSize={18}
            padding={5}
            fill="white"
          />
      }
     {IsCartPage !== true && chunkv2(shapeProps.points, 2).map((coord, i) => (
            <Circle
              ref={circleRef}
              x={coord[0]}
              y={coord[1]}
              key={i}
              radius={4}
              fill="white"
              stroke="black"
              rotateEnabled={false}
              draggable={IsCartPage !== true}
              onDragMove={e => {
                handleCircleDrag(e, coord[0], coord[1]);
              }}
              onMouseDown={handleMouseDown}
            />
          ))}

          <Line
            closed
            draggable={IsCartPage !== true}
            fill={shapeProps.fill}
            ref={shapeRef}
            stroke="black"
            strokeWidth={0}
            points={shapeProps.points}
            onDragEnd={handleDrag}
            onTransformEnd={handleDrag}
            onClick={handleSelect}
            onDblClick={handleDblClick}
            onMouseMove={handleMouseMove}
            onMouseOut={handleMouseOut}
          />
          
    </>
  );
}
