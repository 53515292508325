import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { StripeUtils } from 'server/utils/stripe-utils'
import { UserModel } from 'models/user-models'
import styles from './Home.module.scss'
import { DataLoader } from './_shared/DataLoader'
import { useKlHistory } from 'components/_hooks/useKlHistory'
import { useDialog, useDialogWithParameter } from 'components/_hooks'
import CookiePolicyModal from './CookiePolicyModal/CookiePolicyModal'
import StorageUtils from 'server/utils/storage-utils'
import { isTablet, isDesktop, isMobile } from 'react-device-detect';
import { HomePopupViewModel } from 'models/home-page-popup-viewmodel'
import { HomePopupUtils } from 'server/utils/home-popup-utils'
import HomePopupModalNew from './HomePopupModalNew/HomePopupModalNew'
import { Modal } from 'components/_modals'
import homeStyles from './HomePopupModalNew/HomePopupModalNew.module.scss'
import { cn } from 'utils'
import './aos.scss'

import HomeIntro from "./HomeIntro"
import HomeVideo from './HomeVideo'
import HomeTicket from "./HomeTicket"
import HomeTestimonials from "./HomeTestimonials"
import HomeBlog from "./HomeBlog"

const HomeNew = () => {

  const history = useKlHistory()

  const isCalledRef = React.useRef(null);
  const wrapperRef = React.useRef(null);
  const [cookiePolicyModal, openCookiePolicyModal] = useDialog(onClose => (
    <CookiePolicyModal onClose={onClose} />
  ))

  const homePopupCloseDialog = () => {
    onChangeHomePopup()
  }
  const [showHomePopup, setShowHomePopup] = useState<boolean>(false)

  const [activeIndex, setActiveIndex] = useState<number>(0)
  const [homePopup, setHomePopup] = useState<HomePopupViewModel>()
  const [homePopups, setHomePopups] = useState<HomePopupViewModel[]>([])
  const isShowHomePopup = sessionStorage.getItem("isShowHomePopup") || "false"

  const [homePopupModal, openHomePopupModal] = useDialogWithParameter<HomePopupViewModel | undefined>((homePopupData, closeDialog) => (
    <>
      <Modal buttonColor="white" onClose={() => { homePopupCloseDialog(); closeDialog(); }} hideOk hideCancel className={cn(homeStyles.home_popup_container) + ' home-popup-container'}>
        <HomePopupModalNew onClose={closeDialog} homePopup={homePopupData} />
      </Modal>
    </>
  ))
  useEffect(() => {
    if (wrapperRef.current !== null) {
      (window as any)['initAOS']();
    }
  }, [wrapperRef])

  useEffect(() => {
    const loadHomePopups = async () => {
      const result: any = await HomePopupUtils.GetAllRunningHomePopups()
      if (!result.isError) {
        setHomePopup(result.data.length > 0 ? result.data[0] : undefined)
        setHomePopups(result.data)
        setActiveIndex(0);
      }
    }
    void loadHomePopups()
  }, [])

  const onChangeHomePopup = () => {
    let index = activeIndex
    const lastIndex = homePopups.length - 1
    if (index >= homePopups.length - 1) {
      sessionStorage.setItem("isShowHomePopup", "true")
    }

    if (index < 0) {
      index = 0
    }
    if (index < lastIndex) {
      index += 1
    }
    if (index >= 0 && index < homePopups.length) {
      if (homePopups && homePopups.length > 0) {
        setHomePopup(homePopups[index])
        setActiveIndex(index)
      }
    }
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const accId = urlParams.get('accId')
    if (accId && accId !== null) {
      void (async () => {
        const result: any = await StripeUtils.UpdateUserVerificationStatus(accId)
        if (!result.isError) {
          const user: UserModel = JSON.parse(localStorage.getItem('user') || '')
          user.IsVerified = result.data.isVerified
          user.Stripe_IdentityUrl = result.data.identity_url
          localStorage.setItem('user', JSON.stringify(user))
          if (localStorage.getItem('currentroute') !== null) {
            const redirectUrl = localStorage.getItem('currentroute')
            history.replace(redirectUrl || '')
          }
        }
      })()
    }

    if (!StorageUtils.getItem('cookie-consent-status') || StorageUtils.getItem('cookie-consent-status') !== 'allowed') {
      openCookiePolicyModal()
    }
    else {
      setShowHomePopup(true)
    }

  }, [history, openCookiePolicyModal])


  useEffect(() => {
    if (showHomePopup && homePopup && isShowHomePopup === "false") {
      openHomePopupModal(homePopup)
    }
  }, [showHomePopup, homePopup])

  useEffect(() => {
    if (isCalledRef.current) {
      document.getElementById("preload-image")?.classList.add(isMobile || isTablet || isDesktop ? styles.createTournamentMediumImg : styles.createTournamentImg)
    }
  }, [isCalledRef])

  return (
    <div className="">
      <DataLoader />
      <div className="row m-0--30" >
        <div className="col-12 p-0" >
          <div className="row m-0">
            <div className={styles.wrapper + " m-0"} ref={wrapperRef}>
              <HomeIntro />
              <HomeVideo />
              <HomeTicket />
              <HomeTestimonials />
              <HomeBlog />
            </div>
          </div>
        </div>
      </div>
      {cookiePolicyModal}
      {homePopupModal}
    </div>
  )
}


export default HomeNew
