import { ContainerName, useTournamentParticipant } from 'components/_hooks'
import { DropDownTable } from 'components/_shared'
import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { getParticipantLineUps } from 'store/selectors/line-ups-selectors'
import { ApplicationState } from 'store/types/common'
import { cn } from 'utils'
import LineUpRow from './LineUpRow'
import styles from './list.module.scss'
import ParticipantOnlineAvatar from 'components/_shared/ParticipantAvatar/ParticipantOnlineAvtar'

interface Props {
  participantId: number
  isClanMode: boolean
}

const ParticipantResultRow = ({ participantId, isClanMode }: Props) => {
  const { name, participant } = useTournamentParticipant(participantId)

  const lineUps = useSelector((state: ApplicationState) => getParticipantLineUps(state, participantId))


  const participantLabelClassName = useMemo(() => {
    if (participant.PlaceFrom == 1) {
      return styles.window__winnerLabel__gold
    } else if (participant.PlaceFrom == 2) {
      return styles.window__winnerLabel__silver
    } else if (participant.PlaceFrom == 3) {
      return styles.window__winnerLabel__bronze
    }

    return ''
  }, [participant?.PlaceFrom])

  const [opened, setOpened] = useState(false)
  const handleOpenButtonClick = () => setOpened(!opened)
  return (
    <DropDownTable.Row /*className={styles.window__winner}*/>
      <DropDownTable.MainRow className={cn(styles.table__mainRow, styles.table__mainRowClans, opened && styles.table__mainRow__opened)}>
        <div className="col-sm col-12">
          <div className="row align-items-center old-kl-css">
            <ParticipantOnlineAvatar
              parentClassName={null}
              withLink
              participantId={participant.Id}
              className={styles.window__winnerPciture}
              containerName={ContainerName.AvatarsNormal}
            />
            <div className={styles.window__winnerUsername}>{name}</div>
          </div>
        </div>
        <div className="col-auto p-0">
          <div className={cn(styles.window__winnerLabel, participantLabelClassName)}>
            {participant.PlaceTitle}
          </div>
        </div>
        {isClanMode && (
          <div className="col-auto p-0">
            <DropDownTable.OpenButton onClick={handleOpenButtonClick} opened={opened} className={styles.table__open} />
          </div>
        )}
      </DropDownTable.MainRow>
      <DropDownTable.SecondaryRowContainer opened={opened} className={styles.table__secondaryRowContainer}>
        <div className="container-fluid">
          {lineUps.map(lineUp => (
            <LineUpRow lineUp={lineUp} key={lineUp.Id} />
          ))}
        </div>
      </DropDownTable.SecondaryRowContainer>
    </DropDownTable.Row>
  )
}

export default ParticipantResultRow
