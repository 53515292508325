import React, { useMemo } from 'react'

import { ArticleHeader } from 'components/_shared'
import { Link } from 'react-router-dom'
import { cn } from 'utils'
import styles from './victoryRoad.module.scss'

interface TabLink {
  name: string
  link: string
  isActive: boolean
}

const VictoryRoadTable = () => {
  const isorganizer: number = parseInt(localStorage.getItem('isorganizer'))
  const url = window.location.href

  const links = useMemo<TabLink[]>(() => {
    const defaultLinks = [
      {
        name: 'MAP',
        link: '/victory-road-map',
        isActive: url.indexOf('map') > -1,
      },
      {
        name: 'LIST',
        link: '/victory-road-list',
        isActive: url.indexOf('list') > -1,
      },
    ]

    if (isorganizer != 0 && localStorage.getItem('userid')) {
      return [...defaultLinks, {
        name: 'MY EVENTS',
        link: '/victory-road-events/pending',
        isActive: url.indexOf('events') > -1,
      }]
    }

    return defaultLinks
  }, [url, isorganizer])

  const pathname = window.location.pathname
  const header = pathname === '/victory-road-map' 
    ? 'Interactive Map' 
    : pathname === '/victory-road-list'
      ? 'Esports Brackets'
      : 'Victory Road Events'

  return (
    <ArticleHeader text={header} className={styles.article__header}>
      <div className="col-sm-auto col-12 p-0">
        <div className={cn(styles.article__tabs, styles.tabs)}>
          <div className="row m-0">
            {links.map((link, ind) => (
              <Link key={ind} className={cn(styles.tabs__tab, link.isActive && styles.tabs__tab__active)} to={link.link}>
                {link.name}
              </Link>
            ))}
          </div>

        </div>
      </div>
    </ArticleHeader>
  )
}

export default VictoryRoadTable
