import { makeActionCreator, ReduxAction } from './common'

export interface RegionType {
  Id: number
  RegionName: string
}

export interface RegionState {
  byId: {
    [key: number]: RegionType
  }
  ids: number[]
}

export const REGIONS_LOADED = 'REGIONS_LOADED'
interface RegionsLoadedPayload {
  regions: RegionType[]
}
export type RegionsLoadedAction = ReduxAction<typeof REGIONS_LOADED, RegionsLoadedPayload>
export const regionsLoaded = makeActionCreator<RegionsLoadedPayload>(REGIONS_LOADED)

export type KnownAction = RegionsLoadedAction

export const regionValueExtractor = (region: RegionType) => region.Id
export const regionLabelExtractor = (region: RegionType) => region.RegionName
