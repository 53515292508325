import { createSelector } from 'reselect'
import { OperationTypes } from 'store/logic/operations-logic'
import { ApplicationState } from '../types/common'
import { makeGetIsAnyOperationWithTypeRunning, makeGetIsOperationWithTypeSuccessOrRunning } from './operations-selectors'

const getState = (state: ApplicationState) => state.regions

const getById = createSelector(getState, state => state?.byId ?? {})

const getIds = createSelector(getState, state => state?.ids ?? [])

export const getRegionsLoading = makeGetIsAnyOperationWithTypeRunning(() => ({
  type: OperationTypes.loadRegions,
}))

export const getRegionsRequested = makeGetIsOperationWithTypeSuccessOrRunning(() => ({
  type: OperationTypes.loadRegions,
  objectId: null,
}))

export const getRegions = createSelector(
  getById,
  getIds,
  (byId, ids) => ids.map(id => byId[id]),
)

export const makeGetRegion = () =>
  createSelector(
    getById,
    (_: ApplicationState, { regionId }: { regionId: number }) => regionId,
    (byId, regionId) => byId[regionId]
  )
