
import React, { useRef, useEffect, useCallback, useState } from "react";
import { Arc as KonvaArc, Group, Transformer,Circle } from "react-konva";

import { getAngle, getPosition, LIMITS } from "../../constant/constant";
import { ToolTipHover } from "./ToolTip/ToolTipHover";
// import { selectShape, transformCircleShape, moveShape } from "./state";

const boundBoxCallbackForCircle = (oldBox, newBox) => {
  // limit resize
  if (
    newBox.width < LIMITS.CIRCLE.MIN ||
    newBox.height < LIMITS.CIRCLE.MIN ||
    newBox.width > LIMITS.CIRCLE.MAX ||
    newBox.height > LIMITS.CIRCLE.MAX
  ) {
    return oldBox
  }
  return newBox
};
interface props{
  Id:any
  isSelected:any
  setSelectedShape:()=> void
  moveShape:()=>void
  transformShape:()=>void
  operationType:any
  handleUpdateText:()=>void
}
export function Arc({ Id, isSelected,setSelectedShape,moveShape,transformShape,operationType,openSectionDetail,handleUpdateText,IsCartPage, ...shapeProps }) {
  const [text,setText] = useState<string>(shapeProps.SectionName)
  const [visible,setVisible] = useState<boolean>(false)
  const shapeRef = useRef()
  const transformerRef = useRef()
  const groupRef= useRef()
  const innerAnchorRef= useRef()
  const outerAnchorRef= useRef()
  const dragAnchorRef= useRef()
  
  

  useEffect(() => {
    if (isSelected && IsCartPage !== true) {
      transformerRef.current.nodes([shapeRef.current]);
      transformerRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);
  const handleSelect =(event) => {
      if(operationType === 'sectionArrangement'){
        event.cancelBubble = true
        setSelectedShape(Id)
      }
    }
  const handleDblClick = useCallback(
      (event) => {
        if(operationType === 'sectionArrangement'){
          openSectionDetail(Id,event)
        }
      },
      [Id,operationType]
  )

  const handleDrag = (e:any) => {
    if(IsCartPage === true){
      e.preventDefault()
      return false
    }
      if(operationType === 'sectionArrangement' && e.target.id() === "group" && IsCartPage !== true){
        shapeProps.groupoffSetX=groupRef.current.x()
        shapeProps.groupoffSetY = groupRef.current.y()
        moveShape(Id, null,shapeProps,shapeProps.SectionShapeType)
      }
  }
  const handleTransform = useCallback(
    (event) => {
      if(operationType === 'sectionArrangement' && IsCartPage !== true){
        transformShape(shapeRef.current, Id, event)
      }
    },
    [Id]
  );
  const handleMouseEnter=(e)=>{
    
    if(e.target.id() == "inner"){
      innerAnchorRef.current.getParent().getParent().getParent().container().style.cursor = "pointer";
    }
    else if(e.target.id() == "outer"){
      outerAnchorRef.current.getParent().getParent().getParent().container().style.cursor = "pointer";
    }
    else{
      dragAnchorRef.current.getParent().getParent().getParent().container().style.cursor = "pointer";
    }
    
  }
  const handleMouseLeave=(e)=>{
    if(e.target.id() == "inner"){
      innerAnchorRef.current.getParent().getParent().getParent().container().style.cursor = "default";
    }
    else if(e.target.id() == "outer"){
      outerAnchorRef.current.getParent().getParent().getParent().container().style.cursor = "default";
    }
    else{
      dragAnchorRef.current.getParent().getParent().getParent().container().style.cursor = "default";
    }
  }
  const handleDragMove=(e:any)=>{
    updateArc(e.target,e,Id)
   
  }
  const handleMouseDown=(e,Id:number)=>{
    
    groupRef.current.setDraggable(e.target.id() !== "drag")
  }
  const handleMouseMove = (e:any)=>{
    // if(shapeProps.SectionName && shapeProps.SectionName!='' && shapeProps.SectionName!==null){
    //   setText("Section Name :"+shapeProps.SectionName)
    // }
    setText(IsCartPage!== true?"Click on the shape to explore more options":"Double click on section to select seats")
    setVisible(true)
  }
  const handleMouseOut=()=>{
    setText('')
    setVisible(false)
  }
 
  const updateArc=(activeAnchor:any,e:any,Id:number)=> {
    
    var group = activeAnchor.getParent()
    var arcTmp = group.find("Arc")[0]
    var anchorX = activeAnchor.getX()
    var anchorY = activeAnchor.getY()
    var radius = Math.sqrt(
      Math.pow(anchorX - arcTmp.getX(), 2) +
        Math.pow(anchorY - arcTmp.getY(), 2)
    );

    if (activeAnchor.getId() === "outer") {
      // set outerRadius
      arcTmp.outerRadius(radius)
      shapeProps.radius.outerRadius = radius
      // set angle
      var innerAnchor = group.find("#inner")[0];
      var innerAngle = getAngle(
        { x: arcTmp.getX(), y: arcTmp.getY() },
        { x: innerAnchor.getX(), y: innerAnchor.getY() }
      );
      var outerAngle = getAngle(
        { x: arcTmp.getX(), y: arcTmp.getY() },
        { x: activeAnchor.getX(), y: activeAnchor.getY() }
      );

      var angle = outerAngle - innerAngle;
      arcTmp.angle(angle)
      shapeProps.angle = angle
    } else {
      // set innerRadius
      arcTmp.innerRadius(radius)
      shapeProps.radius.innerRadius = radius
      // set rotation
      var outerAnchor = group.find("#outer")[0]
      var innerAngle = getAngle(
        { x: arcTmp.getX(), y: arcTmp.getY() },
        { x: activeAnchor.getX(), y: activeAnchor.getY() }
      )
      var outerAngle = getAngle(
        { x: arcTmp.getX(), y: arcTmp.getY() },
        { x: outerAnchor.getX(), y: outerAnchor.getY() }
      )

      var angle = outerAngle - innerAngle
      arcTmp.rotation(innerAngle)
      arcTmp.angle(angle)
      shapeProps.angle = angle
      shapeProps.rotation = innerAngle
    }
    moveShape(Id, null,shapeProps,shapeProps.SectionShapeType)
  }
  const moveInnerAnchor = (pos:any) =>{
    var arc = shapeRef.current;
    var layer = groupRef.current.getLayer()
    var centerPoint = arc.absolutePosition()
    var dist = Math.sqrt(
      Math.pow(pos.x - centerPoint.x, 2) +
        Math.pow(pos.y - centerPoint.y, 2)
    );
    if (dist > arc.outerRadius()) {
      var ratio = arc.outerRadius() / dist
      var x = ratio * (pos.x - centerPoint.x) + centerPoint.x;
      var y = ratio * (pos.y - centerPoint.y) + centerPoint.y;
      shapeProps.innerAnchor.position.x = x
      shapeProps.innerAnchor.position.y = y
      layer.draw()
      moveShape(Id, null,shapeProps,shapeProps.SectionShapeType)
      return {
        x: x,
        y: y
      };
    }
    shapeProps.innerAnchor.position.x = pos.x
    shapeProps.innerAnchor.position.y = pos.y
    layer.draw()
    moveShape(Id, null,shapeProps,shapeProps.SectionShapeType)
    return pos
  }
  const moveOuterAnchor =(pos:any) =>{
    var arc = shapeRef.current;
    var centerPoint = arc.absolutePosition();
    var dist = Math.sqrt(
      Math.pow(pos.x - centerPoint.x, 2) +
        Math.pow(pos.y - centerPoint.y, 2)
    );
    if (dist < arc.innerRadius()) {
      var ratio = arc.innerRadius() / dist;
      var x = ratio * (pos.x - centerPoint.x) + centerPoint.x;
      var y = ratio * (pos.y - centerPoint.y) + centerPoint.y;
      shapeProps.outerAnchor.position.x = x
      shapeProps.outerAnchor.position.y = y
      moveShape(Id, null,shapeProps,shapeProps.SectionShapeType)
      return {
        x: x,
        y: y
      };
    }
    shapeProps.outerAnchor.position.x =pos.x
    shapeProps.outerAnchor.position.y =pos.y
    moveShape(Id, null,shapeProps,shapeProps.SectionShapeType)
   
    return pos
  }
  
  return (
    <>
    {!shapeProps.IsLayOut && 
          <ToolTipHover 
            x={shapeProps.groupoffSetX + 830} 
            y={shapeProps.groupoffSetY + 150} 
            opacity={0.75} 
            visible={visible}
            toolTipfill='black'
            pointerDirection="down"
            pointerWidth={10}
            pointerHeight={10}
            lineJoin="round"
            shadowColor="black"
            shadowBlur={10}
            shadowOffsetX={10} 
            shadowOffsetY={10}
            shadowOpacity={0.5}
            text={text}
            fontSize={18}
            padding={5}
            fill="white"
          />
        }
    <Group 
         ref={groupRef} 
         x={shapeProps.groupoffSetX} 
         y={shapeProps.groupoffSetY} 
         draggable={IsCartPage !== true} 
         onDragEnd={handleDrag} 
         id="group" onClick={(e)=>{handleSelect(e)}}
         onMouseMove={handleMouseMove}
         onMouseOut={handleMouseOut}
         >
      <KonvaArc  
        ref={shapeRef}
        {...shapeProps}
        angle={shapeProps.angle}
        innerRadius={shapeProps.radius.innerRadius}
        outerRadius={shapeProps.radius.outerRadius}
        stroke={shapeProps.stroke}
        fill={shapeProps.fill}
        rotation={shapeProps.rotation}
        onDblClick={handleDblClick}
      />
      {/* <Circle ref={innerAnchorRef}
      x={shapeProps.innerAnchor.position.x}
      y={shapeProps.innerAnchor.position.y}
      id={shapeProps.innerAnchor.name}
      draggable
      radius={8}
      dragOnTop={true}
      stroke={shapeProps.stroke}
      strokeWidth={2}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onDragMove={handleDragMove}
      onMouseDown={()=>{handleMouseDown}}
      dragBoundFunc={(pos)=>{
        return moveInnerAnchor(pos)
      }}
      onTouchStart={()=>{handleMouseDown}}
      />
      <Circle  ref={outerAnchorRef}
      id={shapeProps.outerAnchor.name}
      x={shapeProps.outerAnchor.position.x}
      y={shapeProps.outerAnchor.position.y}
      draggable
      radius={8}
      dragOnTop={true}
      stroke={shapeProps.stroke}
      strokeWidth={2}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onDragMove={handleDragMove}
      onMouseDown={()=>{handleMouseDown}}
      dragBoundFunc={(pos)=>{
        return moveOuterAnchor(pos)
      }}
      onTouchStart={()=>{handleMouseDown}}
      /> */}
      {/* <Circle  ref={dragAnchorRef}
      id={shapeProps.dragAnchor.name}
      x={shapeProps.dragAnchor.position.x}
      y={shapeProps.dragAnchor.position.y}
      draggable={false}
      radius={8}
      dragOnTop={false}
      stroke={shapeProps.stroke}
      strokeWidth={2}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseDown={()=>{handleMouseDown}}
      dragBoundFunc={(pos)=>{
        return moveMoveAnchor(pos)
      }}
      
      onTouchStart={()=>{handleMouseDown}}
      /> */}
    </Group>
    {isSelected && <Transformer ref={transformerRef} rotateEnabled={true} onTransformEnd={handleTransform} enabledAnchors={false} />}
     
    </>
  );
}
