import Geocode from 'react-geocode'
import { GeneralUtils } from './general-utils'

export const LocationUtils = {
  getCoords: async (isRefresh?:boolean) => {
        return await new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject)
        }).then((res: GeolocationPosition) => {
            return {
                longitude: res.coords.longitude,
                latitude: res.coords.latitude,
            }
        }).catch(error => {
            switch (error.code) {
                case error.PERMISSION_DENIED:
                    alert("Location information is required for the furthur process. please enable your location")
                    if(isRefresh === true)
                    {
                      document.location.href="/"
                    }
                    break;
                case error.POSITION_UNAVAILABLE:
                    alert("Location information is unavailable.")
                    break;
                case error.TIMEOUT:
                    alert("The request to get user location timed out. Please try again")
                    break;
                case error.UNKNOWN_ERROR:
                    alert("An unknown error occurred.")
                    break;
            }
        })
  },
  GetLocationInfo: async (type: any, latitude: any, longitude: any) => {
    Geocode.setApiKey(GeneralUtils.GoogleAPIKey)
    const countryInfo: any = new Promise((resolve, reject) => {
      Geocode.fromLatLng(latitude, longitude).then(response => {
        for (let i = 0; i < response.results[0].address_components.length; i++) {
          for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
            if (type === response.results[0].address_components[i].types[j]) {
              const obj = {
                CountryName: response.results[0].address_components[i].long_name,
                CountryCode: response.results[0].address_components[i].short_name,
              }
              resolve(obj)
            }
          }
        }
      })
    })
    return countryInfo
  }
}
