import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { makeGetQueueUnreadNotification } from 'store/selectors/notification-selectors'
import NotificationModal from 'components/Layout/partials/Notifications/NotificationModal'
import { useDialogWithParameter } from 'components/_hooks'
import { NotificationModel } from 'store/types/notification-types'

export const GeneralNotification = () => {
  const [notificationModal, openNotificationModal] = useDialogWithParameter<NotificationModel>((notification, closeDialog) => (
    <NotificationModal onClose={closeDialog} notification={notification} />
  ))  
  const getNotification = useMemo(makeGetQueueUnreadNotification, [])
  const unreadNotification = useSelector(getNotification)

  useEffect(() => {
    if (unreadNotification) {
      openNotificationModal(unreadNotification)
    }
  }, [unreadNotification, openNotificationModal])

  return (
    <>
      {notificationModal}
    </>
  )
}
