import { ContainerName } from 'components/_hooks'
import { ParticipantAvatar } from 'components/_shared'
import { DivImg } from 'components/_shared/Img'
import React from 'react'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { makeGetGame } from 'store/selectors/game-selectors'
import { makeGetMatch } from 'store/selectors/match-selectors'
import { makeGetTournament } from 'store/selectors/tournaments-selectors'
import { ApplicationState } from 'store/types/common'
import { ParticipantType } from 'store/types/participants-types'
import { cn } from 'utils'
import styles from './TournamentBracket.module.scss'

export interface ParticipantMovieCard {
  participant: ParticipantType
  name: string
  isWin: boolean
  screenshot: string
  videoUrl: string
  isFiltered: boolean
}

interface OwnProps {
  matchId: number
  participantCard: ParticipantMovieCard
  openPlayerModal: (videoUrl: string) => void
}

type Props = OwnProps
const defaultImage =
  'https://static-cdn.jtvnw.net/cf_vods/d2nvs31859zcd8/e12e78a978_general_mittenz_20040098592_413486355/thumb/customab451ed9d09cc6ed-320x180.jpeg'


const MovieCardDetail = ({ participantCard, openPlayerModal, matchId }: Props) => {
  
  const getTournament = React.useMemo(makeGetTournament, [])  
  const getMatch = React.useMemo(makeGetMatch, [])
  const getGame = useMemo(makeGetGame, [])

  const match = useSelector((state: ApplicationState) => getMatch(state, matchId))
  const tournament = useSelector((state: ApplicationState) => getTournament(state, match.TournamentDetailId))
  const game = useSelector((state: ApplicationState) => getGame(state, { gameId: tournament?.GameId }))

  const onPlay = (event: React.MouseEvent) => {
    event.preventDefault()
    if (participantCard && participantCard.videoUrl) {
      openPlayerModal(participantCard.videoUrl)
    }
  }
  return (
    <div className={styles.movies__stream}>
      <DivImg
        className={cn(styles.movies__streamPreview, participantCard && participantCard.videoUrl && styles.movies__streamPreview__hasStream)}
        onClick={onPlay}
        imageId={game?.ImageId}
        placeholder="games"
        //src={!game?.ImageId ? `/images/games/${game?.Id}.jpg` : defaultImage}
        useBackgroundStyles={false}
      />
      <div className={styles.movies__streamAuthor}>
        {participantCard && (
          <>
            <ParticipantAvatar containerName={ContainerName.AvatarsNormal} className={styles.movies__streamProfilePicture} participantId={participantCard.participant?.Id} withLink />
            <div 
              className={cn(styles.movies__streamUsername, participantCard.isFiltered && styles.movies__streamUsername_highlighted)}
            >
              {participantCard.name}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default MovieCardDetail
