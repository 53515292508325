import { ReactNode } from 'react'
import ReactTooltip from 'react-tooltip'
import { cn } from 'utils'
import { Loading } from '..'
import styles from './Button.module.scss'
import { ButtonColor, ButtonHoverColor, ButtonHoverClassByColor as ButtonHoverClassByColor, useButtonClassByColor, useFilledButtonClassByColor } from './ButtonConsts'

export interface ButtonProps {
  disabled?: boolean
  onClick?: () => void
  className?: string
  children: ReactNode
  loading?: boolean
  filled?: boolean
  color?: ButtonColor
  hoverColor?: ButtonHoverColor
  dataTip?: string
  low?: boolean
}

export const Button = ({ onClick, disabled, className, children, loading, filled, color, hoverColor, dataTip, low }: ButtonProps) => {
  disabled = disabled || loading
  const buttonColor = useButtonClassByColor(color)
  const filledButtonColor = useFilledButtonClassByColor(color)
  
  const customButton = ( 
    <button
      type="button"
      disabled={disabled}
      onClick={onClick}
      className={cn(
        styles.klButton,
        disabled && styles.disabled,
        buttonColor,
        !!hoverColor && ButtonHoverClassByColor[hoverColor],
        filled && filledButtonColor,
        low && styles.low,
        className
      )}
    >
      {children}
      {loading && <Loading size="small" className="ml-2" />}
    </button>
  )

  if (!dataTip) {
    return (customButton)
  } 

  return (
    <>
      <span data-tip={dataTip} data-tip-disable={false} style={{marginRight: '10px'}}>
        {customButton}
      </span>
      <ReactTooltip effect="solid" type="light" place="top" />
    </>
  )
}
