import React from 'react'
import { ImagePlaceholder, useImage } from './useImage'
import { LazyLoadComponent } from 'react-lazy-load-image-component';

export interface VideoProps {
  imageId?: number
  src?: string
  placeholder?: ImagePlaceholder
  width?: string
  height?: string
  controls?: boolean
  onLoadedMetadata?: React.ReactEventHandler<HTMLVideoElement>
  style?: React.CSSProperties
  onMouseOver?: React.MouseEventHandler<HTMLVideoElement>
  onMouseLeave?: React.MouseEventHandler<HTMLVideoElement>
  className?: string
  muted?: boolean
  loop?: boolean
  autoPlay?: boolean
}

export const Video = ({
  imageId,
  src,
  placeholder,
  width,
  height,
  controls,
  onLoadedMetadata,
  style,
  onMouseOver,
  onMouseLeave,
  className,
  muted,
  loop,
  autoPlay,
}: VideoProps) => {
  const { handleError, imageSrc, error } = useImage(imageId, placeholder)

  return (
    <>
      {error
        ? (
          <LazyLoadComponent>
            <img
            className={className}
            src={imageSrc}
            style={style}
          />
          </LazyLoadComponent>
        )
        : (
          <LazyLoadComponent>
            <video
            width={width}
            height={height}
            src={src || imageSrc}
            controls={controls}
            onLoadedMetadata={onLoadedMetadata}
            onError={handleError}
            style={style}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
            className={className}
            muted={muted}
            loop={loop}
            autoPlay={autoPlay}
          />
          </LazyLoadComponent>
        )
      }
    </>
  )
}
