import { ChatMemberRole, ChatMemberStatus } from 'consts'
import { makeActionCreator, ReduxAction } from 'store/types/common'
import { ImageType } from 'store/types/image-types'
import { PublicUserType, PublicUsersModel } from 'store/types/user-types'
import { NewChatMessageLoadedAction, UnreadChatsLoadedAction } from '../chat-message/chat-message-types'
import { ChatLoadedAction, ChatModel, ChatsLoadedAction, DisputeChatLoadedAction, GroupChatCreatedAction, TournamentChatLoadedAction, TypingModel } from '../chat/chat-types'

export interface ChatMemberModel {
  Id: number
  ChatId: number
  UserId: number
  Role: ChatMemberRole
  Status: ChatMemberStatus
  LastReadMessageId?: number
  isTyping?:boolean
}

export interface ChatLefModel {
  ChatId: number
  ChatMemberId: number
  UserId: number
}

export interface ChatMembersModel {
  ChatMembers: ChatMemberModel[]
  Chats: ChatModel[]
  Users: PublicUserType[]
  Images: ImageType[]
}

export interface AddToChatModel {
  Users: number[]
  ChatId: number
}

export interface ChatMembersPayload {
  ChatMembers: ChatMemberModel[]
}


export interface ChatMemberState {
  byId: {
    [id: number]: ChatMemberModel
  }
  idsbyChatId: {
    [chatId: number]: number[]
  }
  filteredUsersIds: number[]
}

export const PRIVATE_CHAT_MEMBERS_LOADED = 'PRIVATE_CHAT_MEMBERS_LOADED'
export type PrivateChatMembersLoadedAction = ReduxAction<typeof PRIVATE_CHAT_MEMBERS_LOADED, ChatMembersModel>
export const privateChatMembersLoaded = makeActionCreator<ChatMembersModel>(PRIVATE_CHAT_MEMBERS_LOADED)


export const CHAT_FILTERED_USERS_LOADED = 'CHAT_FILTERED_USERS_LOADED'
export type ChatFilteredUsersLoadedAction = ReduxAction<typeof CHAT_FILTERED_USERS_LOADED, PublicUsersModel>
export const chatFilteredUsersLoaded = makeActionCreator<PublicUsersModel>(CHAT_FILTERED_USERS_LOADED)

export const CHAT_LEFT = 'CHAT_LEFT'
export type ChatLeftAction = ReduxAction<typeof CHAT_LEFT, number>
export const chatLeft = makeActionCreator<number>(CHAT_LEFT)


export const CHAT_MEMBER_LEFT = 'CHAT_MEMBER_LEFT'
export type ChatMeberLeftAction = ReduxAction<typeof CHAT_MEMBER_LEFT, ChatLefModel>
export const chatMemberLeft = makeActionCreator<ChatLefModel>(CHAT_MEMBER_LEFT)

export const CHAT_MEMBERS_ADDED = 'CHAT_MEMBERS_ADDED'
export type ChatMebersAddedAction = ReduxAction<typeof CHAT_MEMBERS_ADDED, ChatMembersPayload>
export const chatMembersAdded = makeActionCreator<ChatMembersPayload>(CHAT_MEMBERS_ADDED)

export const TYPING_MESSAGE_RECEIVED = 'TYPING_MESSAGE_RECEIVED'
export type TypingMessageReceivedAction = ReduxAction<typeof TYPING_MESSAGE_RECEIVED, TypingModel>
export const typingMessageReceived = makeActionCreator<TypingModel>(TYPING_MESSAGE_RECEIVED)


export type ChatMemberKnownAction = PrivateChatMembersLoadedAction 
  | TournamentChatLoadedAction
  | DisputeChatLoadedAction
  | UnreadChatsLoadedAction
  | NewChatMessageLoadedAction
  | ChatLoadedAction
  | ChatFilteredUsersLoadedAction
  | GroupChatCreatedAction
  | ChatsLoadedAction
  | ChatLeftAction
  | ChatMeberLeftAction
  | ChatMebersAddedAction
  | TypingMessageReceivedAction
