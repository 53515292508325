import { ContainerName } from 'components/_hooks'
import { ProfileCard } from 'components/_shared/ProfileCard/ProfileCard'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { makeGetUser } from 'store/selectors/user-selectors'
import { ApplicationState } from 'store/types/common'
import { getUserProfileUrl } from 'store/types/user-types'

interface PlayerRowProps {
  playerId: number
  className?: string
  checked: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
}

export const PlayerRow = ({ playerId, className, checked, onChange, disabled }: PlayerRowProps) => {
  const getUser = useMemo(makeGetUser, [])
  const player = useSelector((state: ApplicationState) => getUser(state, playerId))


  return (
    <ProfileCard 
      name={player?.Username}
      label="View Profile"
      link={getUserProfileUrl(player?.Id)}
      imageId={player?.AvatarId}
      className={className}
      withCheck
      checked={checked}
      elementId={player?.Id}
      onChange={onChange}
      disabled={disabled}
      containerName={ContainerName.AvatarsNormal}
    />
  )
}
