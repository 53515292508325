import React from 'react'

interface Props {
  lat: number
  lng: number
  text: string
  isMarkerShown: boolean
}

const GoogleMapComponent = ({ text }: Props) => {
  return (
    <div style={{ display: 'inline-flex' }}>
      <h3
        style={{
          color: 'red',
          fontSize: '300%',
          display: 'inline-flex',
          width: '300px',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        <img src="https://kombatlink-assets.azureedge.net/img/map-icon-red.svg" alt="" /> &nbsp; {text}
      </h3>
    </div>
  )
}

export default GoogleMapComponent
