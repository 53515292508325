const onlyUnique = <T>(value: T, index: number, self: T[]) => {
  return self.indexOf(value) === index
}

export const distinct = <T>(array: T[]) => {
  return array.filter(onlyUnique)
}

export const intersect = <T>(a: T[], b: T[]) => {
  return a.filter(value => b.includes(value))
}

export const arrayToObject = <T, M>(items: T[], getKey: (item: T) => number | string): { [key: number]: T | M } => {
  return items.reduce((a: { [key: number]: T }, v) => {
    a[getKey(v)] = v
    return a
  }, {})
}

export const mapArrayToObject = <T, M>(items: T[], getKey: (item: T) => number | string, map: (item: T) => M): { [key: number]: M } => {
  return items.reduce((a: { [key: number]: M }, v) => {
    a[getKey(v)] = map(v)
    return a
  }, {})
}

export const groupArrayToObject = <T, M>(items: T[], getKey: (item: T) => number, map: (item: T) => M): { [key: number]: M[] } => {
  return items.reduce((a: { [key: number]: M[] }, v) => {
    const key = getKey(v)
    a[key] = a[key] || []
    a[key].push(map(v))
    return a
  }, {})
}

export const groupArrayToSets = <T, M>(items: T[], getKey: (item: T) => number, map: (item: T) => M): { [key: number]: Set<M> } => {
  return items.reduce((a: { [key: number]: Set<M> }, v: T) => {
    const key = getKey(v)
    a[key] = a[key] || new Set<M>()
    a[key].add(map(v))
    return a
  }, {})
}

type ObjectWithArray<T> = { [key: number]: T[] }

type ObjectWithNullableArray<T> = { [key: number]: T[] | undefined }

export const concatObjectsWithArray = <T>(existingValues: ObjectWithNullableArray<T>, newValues: ObjectWithArray<T>): ObjectWithArray<T> => {
  return Object.keys(newValues).reduce((a : ObjectWithArray<T>, v: string) => {
    const id = parseInt(v)
    a[id] = distinct((existingValues[id] ?? []).concat(newValues[id]))
    return a
  }, {})
}

export const getNewObjectWithArray = <T, M>(existingValues: ObjectWithNullableArray<M>, items: T[], getKey: (item: T) => number, map: (item: T) => M): ObjectWithArray<M> => {
  const object = groupArrayToObject(items, getKey, map)
  return concatObjectsWithArray(existingValues, object)
}

export const getObjectsWithArrayWithoutIds = <T>(existingValues: ObjectWithNullableArray<T>, ids: number[]): ObjectWithArray<T> => {
  const newValues = {
    ...existingValues,
  }
  ids.forEach(id => {
    newValues[id] = undefined
  })
  return newValues
}
