import React, { useEffect, useRef } from 'react'
import { cn } from 'utils'
import { Loading } from '..'
import styles from './InfiniteScroll.module.scss'

interface OwnProps {
  className: string
  disabled: boolean
  loading: boolean
  page: number
  pageSize: number
  allItemsCount: number
  onPageChanged: (page: number) => void
}

export const InfiniteScroll: React.FC<OwnProps> = ({ children, className, disabled, loading, page, pageSize, allItemsCount, onPageChanged }) => {
  const scrollContainer = useRef<HTMLUListElement>(null)

  const countPages = Math.ceil(allItemsCount / pageSize)

  const handleScroll = (event: React.UIEvent<HTMLUListElement, UIEvent>) => {
    if (disabled || loading) {
      return
    }

    if (page + 1 >= countPages) {
      return
    }

    const { scrollTop, clientHeight, scrollHeight } = event.target as HTMLUListElement

    if (scrollTop + clientHeight >= scrollHeight) {
      onPageChanged(page + 1)
    }
  }

  useEffect(() => {
    if (page === 0) {
      scrollContainer.current.scrollTop = 0
    }
  }, [page])

  return (
    <>
      <ul className={cn(styles.container, className)} onScroll={handleScroll} ref={scrollContainer}>
        {children}
      </ul>
      {loading && (
        <>
          <div className="py-1">
            <Loading size="average" centered />
          </div>
        </>
      )}
    </>
  )
}
