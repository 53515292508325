import React, { useEffect, useMemo, useState } from 'react'
import { useAppUser, useClan, useAlertModal, useIntRouteParam } from 'components/_hooks'
import { ArticleHeader, HeaderButton, LoadingBox } from 'components/_shared'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { SaveClanModel, useAddClan, useUpdateClan } from 'store/logic/clan-logic'
import { makeGetClanMember } from 'store/selectors/clan-member-selectors'
import { getIsAddClanLoading, getIsUpdateClanLoading } from 'store/selectors/clan-selectors'
import { makeGetIsUploadImageLoading } from 'store/selectors/image-selectors'
import { ApplicationState, PageMode } from 'store/types/common'
import { ClanLeftMenu } from './_shared/ClanLeftMenu/ClanLeftMenu'
import { ClanFields } from './_shared/ClanFields/ClanFields'
import { cn } from 'utils'
import styles from './ClanPage.module.scss'
import { ReadonlyClanFields } from './_shared/ClanFields/ReadonlyClanFields'
import { ClanType } from 'store/types/clan-types'
import { ClanMemberRole } from 'consts'
import { InvitePlayers } from './_shared/InvitePlayers/InvitePlayers'
import { ClanMembers } from './_shared/ClanMembers/ClanMembers'
import { useLoadClanInvitations } from 'store/logic/clan-invitation-logic'
import { makeGetClanInvitationIdByClanId } from 'store/selectors/clan-invitations-selectors'


const getPageTitle = (pageMode: PageMode, clan?: ClanType) => {
  if (pageMode === 'read') {
    return `Clan: ${clan?.Name}`
  }
  return 'Clan Settings'
}

interface ClanPageRouteProps {
  clanId: string
}

export const ClanPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const getIsUploadImageLoading = useMemo(makeGetIsUploadImageLoading, [])
  const appUser = useAppUser()
  const addClan = useAddClan()
  const updateClan = useUpdateClan()
  const loadClanInvitations = useLoadClanInvitations(appUser?.Id)
  
  const getInvitationIdByClanId = useMemo(makeGetClanInvitationIdByClanId, [])
  const getClanMember = useMemo(makeGetClanMember, [])

  const clanId = useIntRouteParam<ClanPageRouteProps>(x => x.clanId)
  const [pageMode, setPageMode] = useState<PageMode>('read')
  const isUploadImageLoading = useSelector((state: ApplicationState) => getIsUploadImageLoading(state, { id: 'clanAvatar' }))
  const isAddClanLoading = useSelector(getIsAddClanLoading)
  const isUpdateClanLoading = useSelector(getIsUpdateClanLoading)
  const clanMember = useSelector((state: ApplicationState) => getClanMember(state, { userId: appUser?.Id }))
  const invitationIdByClanId = useSelector((state: ApplicationState) => getInvitationIdByClanId(state, {clanId}))

  const { clan, isClanLoading } = useClan(clanId)
  const [clanFields, setClanFields] = useState<ClanFields>()
  const [avatarId, setAvatarId] = useState<number>()
  const [isClanSaved, setIsClanSaved] = useState(false)
  const [errorModal, openErrorModal] = useAlertModal()

  const isFilled = clanFields && clanFields.name && clanFields.aboutUs
  const isLoading = isClanLoading

  const isMyClan = clanMember?.ClanId == clanId
  const isClanLeader = isMyClan && clanMember?.Role === ClanMemberRole.Leader

  const header = useMemo(() => {
    if (pageMode === 'create') {
      return 'Create Clan'
    } else if (pageMode === 'update') {
      return 'Update Clan'
    } 
    if (isMyClan) {
      return 'My Clan Profile'
    }
    return `${clan?.Name}'s Clan Profile`
  }, [clan?.Name, isMyClan, pageMode])

  const title = getPageTitle(pageMode, clan)

  const handleCancel = () => {
    if (pageMode === 'update') {
      setPageMode('read')
      setAvatarId(clan?.AvatarId)
    } else {
      history.goBack()
    }
  }

  const handleSave = async () => {
    try {
      const model: SaveClanModel = {
        ...clanFields,
        avatarId,
      }
      if (clanId) {
        await updateClan(model)
      } else {
        await addClan(model)
      }
      setIsClanSaved(true)
    }
    catch (error) {
      const message = (error as Error).message
      openErrorModal(message)
    }
  }

  const handleUpdateClick = () => {
    setPageMode('update')
  }

  useEffect(() => {
    if (isClanSaved) {
      if (pageMode === 'update') {
        setPageMode('read')
        setIsClanSaved(false)
      } else if (pageMode === 'create' && clanMember) {
        history.push(`/clan/${clanMember?.ClanId}`)
      }
    }
  }, [clanMember, history, isClanSaved, pageMode])

  useEffect(() => {
    setPageMode(clanId ? 'read' : 'create')
  }, [clanId, dispatch])

  useEffect(() => {
    if (clan) {
      setAvatarId(clan.AvatarId)
    }
  }, [clan])

  useEffect(() => {
    if (appUser?.Id) {
      void loadClanInvitations()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appUser])

  return (
    <LoadingBox loading={isLoading} size="extra">
      <ArticleHeader text={header}>
        {pageMode !== 'read' && <HeaderButton disabled={isAddClanLoading || isUpdateClanLoading} onClick={handleCancel} display="always" color="blue" clasName="mr-2">Cancel</HeaderButton>}
        {pageMode === 'create' && <HeaderButton loading={isAddClanLoading} disabled={isUploadImageLoading || !isFilled} onClick={handleSave} display="always" color="green">Craete Clan</HeaderButton>}
        {pageMode === 'update' && <HeaderButton loading={isUpdateClanLoading} disabled={isUploadImageLoading || !isFilled} onClick={handleSave} display="always" color="green">Save Clan</HeaderButton>}
        {pageMode === 'read' && isClanLeader && <HeaderButton onClick={handleUpdateClick} display="always" color="green">Update Clan</HeaderButton>}
      </ArticleHeader>
      <div className="d-flex flex-column flex-md-row mb-4 mb-sm-0 mt-5">
        <ClanLeftMenu onAvatarIdChange={setAvatarId} avatarId={avatarId} pageMode={pageMode} clan={clan} invitationId={invitationIdByClanId}/>
        <div className="d-flex flex-column flex-fill pl-md-4 overflow-hidden">
          <div className={cn(styles.rightTile, 'pb-2')}>{title}</div>
          {pageMode === 'read'
            ? <ReadonlyClanFields clan={clan} isClanLeader={isClanLeader} />
            : (
              <ClanFields
                onChange={setClanFields}
                clan={clan}
              />
            )
          }
          {pageMode !== 'create' && <ClanMembers clanId={clanId} />}
          {pageMode === 'read' && isClanLeader && <InvitePlayers clan={clan} />}
        </div>
      </div>
      {errorModal}
    </LoadingBox>
  )
}
