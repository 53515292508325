import { Modal, ModalType, ModalWindowAnimationType } from 'components/_modals'
import ImgPreview from 'components/_shared/SubmissionModal/SubmissionModal/ImgPreview'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { matchesSumbissionsActionCreators } from 'store/logic/match-submissions-logic'
import { makeGetSubmissionsByMatchId } from 'store/selectors/match-submissions-selectors'
import { ApplicationState } from 'store/types/common'
import { cn } from 'utils'
import styles from './SumbissionResultModal.module.scss'

export interface SubmissionResultModalProps {
  closeDialog: CallableFunction
  matchParticipant?: any
}

export const SubmissionResultModal = ({ closeDialog, matchParticipant }: SubmissionResultModalProps) => {
  const dispatch = useDispatch()
  const getSubmissionsByMatchId = useMemo(makeGetSubmissionsByMatchId, [])
  const matchSubmissions = useSelector((state: ApplicationState) => getSubmissionsByMatchId(state, matchParticipant?.MatchId))
  const submission = useMemo(() => {
    return matchSubmissions.find(x => x.MatchParticipantId === matchParticipant?.Id)
  }, [matchSubmissions])

  const evidenceLink = submission?.EvidenceLink || ''
  const isWon = submission?.IsFirstPlace ?? null
  const notes = submission?.Notes || ''

  useEffect(() => {
    dispatch(matchesSumbissionsActionCreators.loadMatchSubmissions(matchParticipant?.MatchId))
  }, [dispatch, matchParticipant])

  return (
    <Modal.Container>
      <Modal.Background onClose={closeDialog} />
      <Modal
        onClose={closeDialog}
        hideOk={true}
        buttonColor="blue"
        cancelButtonText={'Close'}
        className={cn(styles.submissionModal, styles.window__big)}
        type={ModalType.animated}
        animationType={ModalWindowAnimationType.next}
        show={true}
      >
        <Modal.Header>{'Submission'}</Modal.Header>

        {submission && (
          <>
            <div className="row">
              <Modal.Radio id="win" value="yes" checked={isWon === true} onChange={() => { }} disabled={true}>
                Won
            </Modal.Radio>
              <Modal.Radio id="lose" value="no" checked={isWon === false} onChange={() => { }} disabled={true}>
                Lost
            </Modal.Radio>
            </div>
            <Modal.Hr />
            <Modal.SubHeader>Notes</Modal.SubHeader>
            <Modal.Textarea value={notes} onChange={() => { }} disabled={true} />
            <Modal.SubHeader>Evidence link</Modal.SubHeader>
            <div className="row mb-2">
              <a href={evidenceLink} target="_blank" className={cn(styles.evidenceLink)}>{evidenceLink}</a>
            </div>

            {submission && submission?.ScreenshotIds?.length > 0 && (
              <>
                <Modal.SubHeader>Screenshots</Modal.SubHeader>
                <div className={styles.screenshotsPreview}>
                  {submission?.ScreenshotIds.map((imageId, ind) => (
                    <ImgPreview key={ind} imageId={imageId} isThumbnailStyle={true} />
                  ))}
                </div>
              </>
            )}
          </>
        )}

        {submission === undefined && (
          <>
            <div><span className='text-danger'>No submission result available. </span></div>
          </>
        )}
      </Modal>
    </Modal.Container>
  )
}

