import { Reducer } from 'redux'
import { MATCH_PARTICIPANTS_LOADED } from 'store/types/match-participant-types'
import { calcById, calcByIdWithoutIds, getNewObjectWithArray } from 'utils'
import { State, KnownAction, ParticipantType, PARTICIPANTS_LOADED, PARTICIPANT_ADDED, PARTICIPANT_DELETED, NOTIFICATION_PARTICIPANTS_All_DELETED_FROM_STATE } from '../types/participants-types'

export const initialParticipantsState: State = {
  idsByTournamentDetailId: {},
  byId: {},
}

const calcIdsByTournamentDetailId = (state: State, participants: ParticipantType[]) => {
  return {
    ...state.idsByTournamentDetailId,
    ...getNewObjectWithArray(state.idsByTournamentDetailId, participants, x => x.TournamentDetailId, x => x.Id),
  }
}

const calcState = (state: State, participants: ParticipantType[]): State => {
  return {
    ...state,
    byId: calcById(state, participants),
    idsByTournamentDetailId: calcIdsByTournamentDetailId(state, participants),
  }
}

const reducer: Reducer<State, KnownAction> = (state: State | undefined = initialParticipantsState, action: KnownAction) => {
  switch (action.type) {
    case PARTICIPANTS_LOADED:
    case MATCH_PARTICIPANTS_LOADED:
      return calcState(state, action.payload.TournamentParticipants)
    case PARTICIPANT_ADDED: 
      return calcState(state, [action.payload])
    case PARTICIPANT_DELETED:  {
      return {
        ...state,
        byId: calcByIdWithoutIds(state, [action.payload.participantId]),
        idsByTournamentDetailId: {
          ...state.idsByTournamentDetailId,
          [action.payload.tournamentDetailId]: state.idsByTournamentDetailId[action.payload.tournamentDetailId]?.filter(id => id != action.payload.participantId) || [],
        },
      }
    }
    case NOTIFICATION_PARTICIPANTS_All_DELETED_FROM_STATE:  
        const allTournamentPaticipants = state.idsByTournamentDetailId[action.payload.tournamentDetailId]
        const allParticipants = {...state.idsByTournamentDetailId}
        delete allParticipants[action.payload.tournamentDetailId]
        return {
          ...state,
          byId: calcByIdWithoutIds(state, allTournamentPaticipants),
          idsByTournamentDetailId: allParticipants
        }
    default:
      return state
  }
}

export default reducer
