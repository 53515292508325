import { Dispatch } from 'redux'
import { GetState, PaginationModel } from 'store/types/common'
import { mainTournamentsLoaded, qualifyTournamentsLoaded, upcomingTournamentsLoaded } from 'store/types/pages/tournaments-page-types'
import { OperationTypes, run } from '../operations-logic'
import { getFilteredTournaments, TournamentFilters } from '../tournaments-logic'

export const loadUpcomingTournaments = (filters: TournamentFilters, pagination: PaginationModel) => async (dispatch: Dispatch, getState: GetState) => {
  await run(OperationTypes.loadUpcomingTournaments, null, dispatch, getState, async () => {
    try {
      const result = await getFilteredTournaments(filters, pagination)
      dispatch(upcomingTournamentsLoaded({
        tournaments: result.Tournaments,
        allUpcomingTournamentsCount: result.AllTournamentsCount,
        TournamentConsoles: result.TournamentConsoles,
        Images: result.Images,
      }))
    } catch (error) {
      return false
    }
    return true
  })
}

export const loadMainTournaments = (filters: TournamentFilters, pagination: PaginationModel) => async (dispatch: Dispatch, getState: GetState) => {
  await run(OperationTypes.loadMainTournaments, null, dispatch, getState, async () => {
    try {
      const result = await getFilteredTournaments(filters, pagination)
      dispatch(mainTournamentsLoaded({
        tournaments: result.Tournaments,
        allMainTournamentsCount: result.AllTournamentsCount,
        TournamentConsoles: result.TournamentConsoles,
        Images: result.Images,
      }))
    } catch (error) {
      return false
    }
    return true
  })
}

export const loadQualifyTournaments = (filters: TournamentFilters, pagination: PaginationModel) => async (dispatch: Dispatch, getState: GetState) => {
  await run(OperationTypes.loadQualifyTournaments, null, dispatch, getState, async () => {
    try {
      const result = await getFilteredTournaments(filters, pagination)
      dispatch(qualifyTournamentsLoaded({
        tournaments: result.Tournaments,
        allQualifyTournamentsCount: result.AllTournamentsCount,
        TournamentConsoles: result.TournamentConsoles,
        Images: result.Images,
      }))
    } catch (error) {
      return false
    }
    return true
  })
}
