export interface ZonedDate {
  Date: string
  Time: string
  TimeZoneAbbr: string
}

export const ConvertDateToFormattedDateString = function (date: Date, zonedDate: ZonedDate, isAdmin: boolean, isOrganizer: boolean) {
  if (!date) {
    return ''
  }

  if ((!isAdmin && !isOrganizer) || !zonedDate) {
    return (typeof date === 'string' ? new Date(date) : date).toLocaleDateString()
  }

  return zonedDate.Date
}

export const ConvertDateToFormattedTimeString = function (date: Date, zonedDate: ZonedDate, isAdmin: boolean, isOrganizer: boolean) {
  if (!date) {
    return ''
  }

  if ((!isAdmin && !isOrganizer) || !zonedDate) {
    return (typeof date === 'string' ? new Date(date) : date).toLocaleTimeString()
  }

  return `${zonedDate.Time} ${zonedDate.TimeZoneAbbr}`
}

export const ConvertDateToFormattedDatetimeString = function (date: Date, zonedDate: ZonedDate, isAdmin: boolean, isOrganizer: boolean) {
  if (!date) {
    return ''
  }
  if ((!isAdmin && !isOrganizer) || !zonedDate) {
    return (typeof date === 'string' ? new Date(date) : date).toLocaleString()
  }

  return `${zonedDate.Date}, ${zonedDate.Time} ${zonedDate.TimeZoneAbbr}`
}
