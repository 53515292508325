import { useAlertModal } from 'components/_hooks'
import { useKlHistory } from 'components/_hooks/useKlHistory'
import { Button, ButtonLink } from 'components/_shared'
import images from 'images'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { UserUtils } from 'server/utils/user-utils'
import { SignUpOrganizerModel, SignUpPlayerModel, useSignUpOrganizer, useSignUpPlayer } from 'store/logic/auth-logic'
import { loadRegions } from 'store/logic/region-logic'
import { getFeatures } from 'store/selectors/app-selectors'
import { getIsSignUpLoading } from 'store/selectors/auth-selectors'
import { UserRole } from 'store/types/app-types'
import { RegionType } from 'store/types/region-types'
import { cn } from 'utils'
import { RegisterSuccessHistoryState } from '../Register/RegisterSuccess'
import styles from './SignUp.module.scss'
import { DisplayInDirectoryType, OrganizerSignUp, PlayerSignUp } from './_shared'

export const SignUp = () => {
  const history = useKlHistory<RegisterSuccessHistoryState>()
  const dispatch = useDispatch()
  const signUpOrganizer = useSignUpOrganizer()
  const signUpPlayer = useSignUpPlayer()
  const isSignUpLoading = useSelector(getIsSignUpLoading)
  const features = useSelector(getFeatures)

  const [username, setUsername] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [organizationName, setOrganizationName] = useState('')
  const [position, setPosition] = useState('')
  const [agreeWithLicense, setAgreeWithLicense] = useState(false)
  const [role, setRole] = useState<UserRole>(features.LeadGeneration ? 'Organizer' : 'Player')
  const [isFilledPlayer, setIsFilledPlayer] = useState(false)
  const [isFilledOrganizer, setIsFilledOrganizer] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [birthDate, setBirthDate] = useState<Date>()
  const [displayInDirectory, setDisplayInDirectory] = useState<DisplayInDirectoryType>('yes')
  const [region, setRegion] = useState<RegionType>()

  const isSignUpEnabled = useMemo(() => {
    return agreeWithLicense
      && (role === 'Player' && isFilledPlayer || role === 'Organizer' && isFilledOrganizer)
  }, [agreeWithLicense, isFilledOrganizer, isFilledPlayer, role])

  const handleAgreeWithLicenseChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgreeWithLicense(event.currentTarget.checked)
  }

  const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRole(event.currentTarget.value as UserRole)
  }

  const [errorModal, openErrorModal] = useAlertModal()

  const handleSignUpClick = async () => {
    if (!UserUtils.ValidatePassword(password)) {
      openErrorModal('Password must be min 8 characters, with at least 1 capital letter, 1 number and 1 symbol.')
      return
    }
    if (password !== passwordConfirm) {
      openErrorModal('Password and Confirm password mismatch')
      return
    }
    if (!UserUtils.ValidateEmail(email)) {
      openErrorModal('Please Enter a Valid Email Address')
      return
    }

    const signUpPlayerModel: SignUpPlayerModel = {
      username,
      birthDate,
      regionId: region.Id,
      email,
      password,
      displayInDirectory: displayInDirectory === 'yes',
    }
    const signUpOrganizerModel: SignUpOrganizerModel = {
      firstName,
      lastName,
      birthDate,
      organizationName,
      position,
      email,
      password,
      regionId: region.Id,
      displayInDirectory: displayInDirectory === 'yes',
    }

    try {
      if (role === 'Player') {
        if (!features.LeadGeneration) {
          await signUpPlayer(signUpPlayerModel)
        }
      }
      else if (role === 'Organizer') {
        await signUpOrganizer(signUpOrganizerModel)
      }
    }
    catch (error) {
      openErrorModal((error as Error).message)
      return
    }
    fbq('track', 'CompleteRegistration')
    gtag('event','sign_up')

    history.push('/register/success', { role })
  }

  useEffect(() => {
    dispatch(loadRegions)
  }, [dispatch])

  return (
    <>
      <div className={styles.videoContainer}>
        <video autoPlay loop muted className={styles.video}>
          <source src={images.awesome} />
        </video>
      </div>
      <div className={styles.background}></div>
      {!features.LeadGeneration &&
        <div className="container-fluid">
          <div className={cn('row justify-content-end', styles.backContainer)}>
            <Link to="/" className={styles.back}>Back</Link>
          </div>
        </div>
      }
      <article className={cn('d-flex flex-row align-items-center', styles.signUp)}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className={styles.signUpHeader}>
                Register Kombatlink
              </h1>
            </div>
          </div>
          {!features.LeadGeneration && 
            <div className="row">
              <div className="col-lg-4 col-sm-12">
                <div className="d-flex flex-direction-row justify-content-between">
                  <input id="playerRole" type="radio" className={styles.signUpRole} name="role" value="Player" onChange={handleRoleChange} checked={role === 'Player'} />
                  <label htmlFor="playerRole" className={styles.roleLabel}>
                    <span className={styles.roleLabelSvg}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                        <path d="M480.07 96H160a160 160 0 1 0 114.24 272h91.52A160 160 0 1 0 480.07 96zM248 268a12 12 0 0 1-12 12h-52v52a12 12 0 0 1-12 12h-24a12 12 0 0 1-12-12v-52H84a12 12 0 0 1-12-12v-24a12 12 0 0 1 12-12h52v-52a12 12 0 0 1 12-12h24a12 12 0 0 1 12 12v52h52a12 12 0 0 1 12 12zm216 76a40 40 0 1 1 40-40 40 40 0 0 1-40 40zm64-96a40 40 0 1 1 40-40 40 40 0 0 1-40 40z"></path>
                      </svg>
                    </span>
                    {'I\'m a Player'}
                  </label>
                  <input id="organizerRole" type="radio" className={styles.signUpRole} name="role" value="Organizer" onChange={handleRoleChange} checked={role === 'Organizer'}/>
                  <label htmlFor="organizerRole" className={styles.roleLabel}>
                    <span className={styles.roleLabelSvg}>
                      <svg focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                        <path d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"></path>
                      </svg>
                    </span>
                    {'I\'m an Organizer'}
                  </label>
                </div>
              </div>
            </div>
          }
          {!features.LeadGeneration && <PlayerSignUp
            email={email}
            onEmailChange={setEmail}
            password={password}
            onPasswordChange={setPassword}
            passwordConfirm={passwordConfirm}
            onPasswordConfirmChange={setPasswordConfirm}
            birthDate={birthDate}
            onBirthDateChange={setBirthDate}
            displayInDirectory={displayInDirectory}
            onDisplayInDirectoryChange={setDisplayInDirectory}
            region={region}
            onRegionChange={setRegion}
            username={username}
            onUsernameChange={setUsername}
            display={role === 'Player'}
            onFilledChange={setIsFilledPlayer}
          />}
          <OrganizerSignUp
            email={email}
            onEmailChange={setEmail}
            password={password}
            onPasswordChange={setPassword}
            passwordConfirm={passwordConfirm}
            onPasswordConfirmChange={setPasswordConfirm}
            birthDate={birthDate}
            onBirthDateChange={setBirthDate}
            displayInDirectory={displayInDirectory}
            onDisplayInDirectoryChange={setDisplayInDirectory}
            region={region}
            onRegionChange={setRegion}
            firstName={firstName}
            onFirstNameChange={setFirstName}
            lastName={lastName}
            onLastNameChange={setLastName}
            organizationName={organizationName}
            onOrganizationNameChange={setOrganizationName}
            position={position}
            onPositionChange={setPosition}
            display={role === 'Organizer'}
            onFilledChange={setIsFilledOrganizer}
          />
          <div className={cn('row', styles.m40)}>
            <div className={cn('col-md-8 col-sm-12', styles.signUpAgreeLicenceContainer)}>
              <input type="checkbox" className={styles.signUpAgreeLicence} id="agreeLicence" name="agreeLicence" checked={agreeWithLicense} onChange={handleAgreeWithLicenseChange} />
              <label htmlFor="agreeLicence" className={styles.signUpAgreeLicenceLabel}></label>
              <div>
                Agree with Kombatlink&nbsp;
                <Link to="/terms-of-service" className={styles.colorBlue}>&nbsp;Terms and Conditions</Link>
                &nbsp;&amp;
                <Link to="/privacy-policy" className={styles.colorBlue}>&nbsp;Privacy Policy</Link>
              </div>
            </div>
            <div className="col-md-4 col-sm-12 p-0 m-sm-20">
              <Button loading={isSignUpLoading} disabled={!isSignUpEnabled} color="blue" filled className={styles.signUpRegister} onClick={handleSignUpClick}>Register Now</Button>
            </div>
          </div>
          {!features.LeadGeneration &&
            <>
              <hr className={styles.hr} />
              <div className={cn('row align-items-stretch', styles.mb40)}>
                <div className="col-lg-auto col-sm-12">
                  <h1 className={styles.signUpBigHeader}>
                    Your eSport journey starts here
                  </h1>
                  <h2 className={styles.signUpSubheader}>
                    KombatLink lets you unleash your skills playing games you already own
                  </h2>
                </div>
                <div className={cn('col-lg col-sm-12 d-flex justify-content-lg-end justify-content-sm-start align-items-center', styles.mSm40)}>
                  <ButtonLink className={styles.signUpSeeMore} to="/faq" color="white">See more</ButtonLink>
                </div>
              </div>
            </>
          }
        </div>
      </article>
      {errorModal}
    </>
  )
}
