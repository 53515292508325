import React, { useEffect, useMemo } from 'react'
import { loadPlayerProfileByUserId } from 'store/logic/user-logic'
import { useDispatch } from 'react-redux'
import { makeGetPlayerProfileByUser } from 'store/selectors/player-profile-selectors'
import { KlLink } from 'components/_shared'
import styles from '../OrganizerProfile.module.scss'
import { loadMyReview, loadReviews } from 'store/logic/review-logic'
import { useAppSelector } from 'components/_hooks/useAppSelector'
import { makeGetIdsByUserId, makeGetMyReviewId } from 'store/selectors/review-selectors'
import { OrganizerReviewCard } from './OrganizerReviewCard'

export interface OrganizerReviewsProps {
  userId: number
}

export const OrganizerReviews = ({userId}: OrganizerReviewsProps) => {
  const dispatch = useDispatch()
  
  const getPlayerProfile = useMemo(makeGetPlayerProfileByUser, [])
  const getReviewIdsByUserId = useMemo(makeGetIdsByUserId, [])
  const getMyReviewIdByUserId = useMemo(makeGetMyReviewId, [])
  
  const playerProfile = useAppSelector(state => getPlayerProfile(state, { userId }))
  const reviewIds = useAppSelector(state => getReviewIdsByUserId(state, { userId }))
  const myReviewId = useAppSelector(state => getMyReviewIdByUserId(state, { userId }))

  const allReviewsIds = useMemo(() => {
    const all: number[] = []
    if (myReviewId) {
      all.push(myReviewId)
    }
    all.push(...reviewIds)
    return all
  }, [myReviewId, reviewIds])

  useEffect(() => {
    if (userId) {
      dispatch(loadPlayerProfileByUserId(userId))
    }
  }, [dispatch, userId])

  useEffect(() => {
    if (userId) {
      dispatch(loadMyReview(userId))
      dispatch(loadReviews(userId, {
        page: 0,
        pageSize: 4,
      }))
    }
  }, [dispatch, userId])

  return (
    <div className={styles['rvws-container']}>
      <div className={styles.sectionTtl}>
        <div className={styles.sectionTtl_l}>
          <h2 className={styles.sectionTtl_ttl}>
            Reviews <span className={styles.sectionTtl_ttl_num}>({playerProfile?.ReviewsCount ?? 0})</span>
          </h2>
        </div>
        <div className={styles.sectionTtl_r}>
          <KlLink to={`/user/${userId}/review`} className={styles.btnViewMore}>
            View All &nbsp; <img src="https://kombatlink-assets.azureedge.net/img/arrow-right.svg" />
          </KlLink>
        </div>
      </div>
      <div className="row rvws-row">
        {allReviewsIds.map(id => <OrganizerReviewCard key={id} reviewId={id} />)}
      </div>
    </div>
  )
}
