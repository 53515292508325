import { Reducer } from 'redux'
import { FriendsRequestState, KnownAction, FRIEND_REQUEST_ADDED, FRIEND_REQUESTS_LOADED, FRIEND_REQUEST_RESOLVED } from 'store/types/friend-requests-types'
import { calcById, mapArrayToObject } from 'utils'

export const initialFriendRequestsState: FriendsRequestState = {
  incomingIds: [],
  byId: {},
  pendingFriendsIds: [],
  idByUserId: {},
}

const reducer: Reducer<FriendsRequestState, KnownAction> = (state: FriendsRequestState | undefined = initialFriendRequestsState, action: KnownAction) => {
  switch (action.type) {
    case FRIEND_REQUESTS_LOADED:
      return {
        ...state,
        incomingIds: action.payload.IncomingRequests.map(x => x.Id),
        pendingFriendsIds: action.payload.OutgoingRequests.map(x => x.ToUserId),
        byId: calcById(state, action.payload.IncomingRequests),
        idByUserId: {
          ...mapArrayToObject(
            action.payload.IncomingRequests,
            x => x.FromUserId,
            x => x.Id
          ),
        },
      }
    case FRIEND_REQUEST_ADDED: {
      return {
        ...state,
        pendingFriendsIds: [ ...state.pendingFriendsIds, action.payload.userId ],
      }
    }
    case FRIEND_REQUEST_RESOLVED: {
      const newIds = state.incomingIds.filter(x => x !== action.payload.friendRequestId)
      return {
        ...state,
        incomingIds: newIds,
        byId: {
          ...state.byId,
          [action.payload.friendRequestId]: undefined,
        },
      }
    }
    default:
      return state
  }
}

export default reducer
