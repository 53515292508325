import { LoadingBox } from 'components/_shared'
import TournamentCardV2 from 'components/_shared/TournamentCard/TournamentCardV2'
import { TournamentBannerDisplayFlag } from 'components/_shared/TournamentCard/_shared/TournamentFlashingBanner'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadTopTournaments } from 'store/logic/tournaments-logic'
import { getTopTournamentsIds, getTopTournamentsLoading } from 'store/selectors/tournaments-selectors'
import { cn } from 'utils'
import styles from '../Home.module.scss'
import HomeHeader from './HomeHeader'


const topCount = 8

const TopTournaments = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadTopTournaments(topCount))
  }, [dispatch])

  const tournaments = useSelector(getTopTournamentsIds)
  const isTournamentsLoading = useSelector(getTopTournamentsLoading)

  return (
    <>
      <HomeHeader link="/tournaments" title="Tournaments" />
      <LoadingBox loading={isTournamentsLoading} size="average"  >
        <div className={cn('row', styles.articleGamesContainer)}>
          {tournaments && tournaments.map(id => (
            <TournamentCardV2 tournamentId={id} key={id} displayBannerOption={TournamentBannerDisplayFlag.Running} />
          ))}
        </div>      
      </LoadingBox> 
    </>
  )
}

export default TopTournaments
