import { Reducer } from 'redux'
import { defaultTournamentsFilters, FILTERS_CHANGED, KnownAction, MAIN_TOURNAMENTS_LOADED, QUALIFY_TOURNAMENTS_LOADED, TournamentsPageState, UPCOMING_TOURNAMENTS_LOADED } from 'store/types/pages/tournaments-page-types'


const initialState: TournamentsPageState = {
  filters: defaultTournamentsFilters,
  allMainTournamentsCount: 0,
  allQualifyTournamentsCount:0,
  allUpcomingTournamentsCount: 0,
  mainTournamentsIds: [],
  qualifyTournamentsIds: [],
  upcomingTournamentsIds: [],
}


const reducer: Reducer<TournamentsPageState, KnownAction> = (state: TournamentsPageState | undefined = initialState, action: KnownAction) => {
  switch (action.type) {
    case FILTERS_CHANGED:
      return {
        ...state,
        filters: action.payload.filters,
      }
    case UPCOMING_TOURNAMENTS_LOADED: {
      return {
        ...state,
        upcomingTournamentsIds: action.payload.tournaments.map(x => x.TournamentDetailId),
        allUpcomingTournamentsCount: action.payload.allUpcomingTournamentsCount,
      }
    }
    case MAIN_TOURNAMENTS_LOADED: {
      return {
        ...state,
        mainTournamentsIds: action.payload.tournaments.map(x => x.TournamentDetailId),
        allMainTournamentsCount: action.payload.allMainTournamentsCount,
      }
    }
    case QUALIFY_TOURNAMENTS_LOADED: {
      return {
        ...state,
        qualifyTournamentsIds: action.payload.tournaments.map(x => x.TournamentDetailId),
        allQualifyTournamentsCount: action.payload.allQualifyTournamentsCount,
      }
    }
    default:
      return state
  }
}

export default reducer
