
import React from "react"
import { Text, Label,Tag } from "react-konva";
export function ToolTipHover({
x,
y,
toolTipfill,
text,
fill,
pointerDirection,
pointerWidth,
pointerHeight,
lineJoin,
shadowColor,
shadowBlur,
shadowOffsetX,
shadowOffsetY,
shadowOpacity,
fontSize,
padding,
visible,
opacity

}){
  return(
    <Label x={x} y={y} opacity={opacity} visible={visible}>
         <Tag 
            fill={toolTipfill}
            pointerDirection={pointerDirection}
            pointerWidth={pointerWidth}
            pointerHeight={pointerHeight}
            lineJoin={lineJoin}
            shadowColor={shadowColor}
            shadowBlur={shadowBlur}
            shadowOffsetX={shadowOffsetX} 
            shadowOffsetY={shadowOffsetY}
            shadowOpacity={shadowOpacity}
            >
          </Tag>
          <Text
            text={text}
            fontSize={fontSize}
            padding={padding}
            fill={fill}>
          </Text>
         </Label>
  )
}