import { useAppUser, useDialogWithParameter } from 'components/_hooks'
import { CancelParticipationModal } from 'components/_modals/CancelParticipationModal/CancelParticipationModal'
import { LoadingBox, Pagination } from 'components/_shared'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadPlayerLiveTournaments } from 'store/logic/pages/player-events-page-logic'
import { TournamentFilters } from 'store/logic/tournaments-logic'
import { getAllPlayerLiveTournamentsCount, getPlayerEventsPageFilters, getPlayerLiveTournamentsIds, getPlayerLiveTournamentsLoading } from 'store/selectors/pages/player-events-page-selectors'
import { ApplicationState, defaultPageSize } from 'store/types/common'
import { playerEventsFiltersChanged } from 'store/types/pages/player-events-page-types'
import { TournamentType } from 'store/types/tournaments-types'
import { EventRow } from './EventRow'
import NotFoundTournaments from './NotFoundTournaments'

interface Props {
  filters: TournamentFilters
}


export const LiveEvents = ({filters}: Props) => {
  const dispatch = useDispatch()

  const storedFilters = useSelector((state: ApplicationState) => getPlayerEventsPageFilters(state)) 

  const page = storedFilters.currentPage
  const tournaments = useSelector(getPlayerLiveTournamentsIds)
  const allTournamentsCount = useSelector(getAllPlayerLiveTournamentsCount)
  const isTournamentsLoading = useSelector(getPlayerLiveTournamentsLoading)

  const user = useAppUser()

  const loadTournaments = useCallback((filters: TournamentFilters, pageNumber: number) => {  
    dispatch(loadPlayerLiveTournaments(filters, {
      page: pageNumber,
      pageSize: defaultPageSize,
    }))  
  }, [dispatch])

  useEffect(() => {
    if (user) {      
      loadTournaments(filters, page) 
    }
  }, [dispatch, filters, loadTournaments, page, user])

  const handleChangePage = (pageNumber: number) => {
    dispatch(playerEventsFiltersChanged(
      {
        filters: {
          ...storedFilters,
          currentPage: pageNumber,          
        },
      }
    ))
  }

  const onCancelDone = () => {
    loadTournaments(filters, page)
  }

  const [cancelParticipationModal, openCancelParticipationModal] = useDialogWithParameter<TournamentType>((tournament, closeDialog) => (
    <CancelParticipationModal
      onClose={closeDialog}
      tournamentDetailId={tournament.TournamentDetailId}
      EventTypeId={tournament.EventTypeId}
      onDone={onCancelDone}
    />
  ))
  

  return (
    <>
      <LoadingBox size="big" loading={isTournamentsLoading}>
        {tournaments.length == 0 && (
          <NotFoundTournaments />
        )}
        {tournaments.map(id => (
          <EventRow
            key={id}
            tournamentDetailId={id}
            openCancelParticipationModal={openCancelParticipationModal} 
          />
        ))}
      </LoadingBox>
      {tournaments.length > 0 && (
        <Pagination page={page} itemsPerPage={defaultPageSize} allItemsCount={allTournamentsCount} onChangePage={handleChangePage} />      
      )}
      {cancelParticipationModal}
    </>
  )
}
