import { Modal } from 'components/_modals'
import { useImageUploader } from 'components/_hooks'
import { AspectRatioType, FileType } from 'components/_shared'
import React, { ReactNode } from 'react'
import { ImageType, ImageTypeEnum, MediaType } from 'store/types/image-types'
import { cn } from 'utils'
import styles from './UpdateImageModal.module.scss'
import { useState } from 'react'

export interface UpdateImageModalProps {
  onClose: CallableFunction
  dropText: string
  recommendedText: string
  fileTypes: FileType[]
  header: ReactNode
  getImageTypeCallback: (mediaType: MediaType) => ImageTypeEnum
  saveImage: (image: ImageType) => Promise<void>
  aspectRatioType?: AspectRatioType
  crop?: boolean
  round?: boolean
}

export const UpdateImageModal = ({
  onClose,
  dropText,
  fileTypes,
  recommendedText,
  header,
  saveImage,
  aspectRatioType,
  crop,
  getImageTypeCallback,
  round,
}:  UpdateImageModalProps) => {
  const [image, setImage] = useState<File>()
  
  const handleOnChange = (file: File, mediaType: MediaType) => {
    if (mediaType === MediaType.Video || !crop) {
      setImage(file)
    }
  }

  const [upload, fileSelector] = useImageUploader({
    dropText,
    fileTypes,
    getImageTypeCallback,
    recommendedText,
    crop,
    aspectRatioType,
    saveImage,
    onCrop: setImage,
    onChange: handleOnChange,
    round,
  })

  const operation = async () => {
    await upload()
    return true
  }

  return (
    <Modal
      onClose={onClose}
      allowOk={!!image}
      asyncOperation={operation}
      buttonColor="blue"
      className={cn(styles.updateImageModal, styles.window)}
      okButtonText="Update"
      buttonClassName={styles.button}
    >
      <Modal.Header>{header}</Modal.Header>
      {fileSelector}
    </Modal>
  )
}
