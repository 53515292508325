import { useTheme } from 'components/_hooks'
import { RadioInput, Select, TextInput, TournamentWizardRadioInput } from 'components/_library'
import { InfoText } from 'components/_library/InfoText/InfoText'
import { InputError } from 'components/_library/InputError/InputError'
import { Label } from 'components/_library/Label/Label'
import { Button } from 'components/_shared/Button'
import { ClansModeSvg } from 'components/_svg/ClansModeSvg'
import { SoloModeSvg } from 'components/_svg/SoloModeSvg'
import { TournamentStructureEnum, TournamentStructureOptions } from 'consts'
import { EventTypeEnum, EventTypeList, kl_EventType, kl_Tournament, ModeTypeEnum as TournamentModeEnum, TournamnetModeList } from 'models/tournament-models'
import { useEffect, useState } from 'react'
import { cn } from 'utils'
import { DoubleEliminationStructure } from './DoubleEliminationStructure'
import { EliminationRoundFFAStructure } from './EliminationRoundFFAStructure'
import { SingleEliminationStructure } from './SingleEliminationStructure'
import { SingleRoundFFAStructure } from './SingleRoundFFAStructure'
import styles from '../Steps.module.scss'

const Structures = {
  [TournamentStructureEnum.DoubleElimination]: <DoubleEliminationStructure />,
  [TournamentStructureEnum.EliminationRoundFfa]: <EliminationRoundFFAStructure />,
  [TournamentStructureEnum.SingleElimination]: <SingleEliminationStructure />,
  [TournamentStructureEnum.SingleRoundFfa]: <SingleRoundFFAStructure />,
}

const Modes: { [key: number]: JSX.Element } = {
  [TournamentModeEnum.Solo]: <SoloModeSvg />,
  [TournamentModeEnum.Clans]: <ClansModeSvg className={styles.clansSvg} />,
}

interface TournamentSetupProps {
  name: string
  isPremium: boolean
  mode: number
  eventTypeId: number
  structure: number
  isAutoResize: boolean
  readOnly?: boolean
  mainTournamentsList: kl_Tournament[]
  mainTournamentId: number
  onChangeMainTournament: (value: number) => void
  onChangeMode: (value: string) => void
  onChangeName: (value: string) => void
  onChangeStructure: (value: string) => void
  onChangeEventType: (value: kl_EventType) => void
  onChengeAutoResize: (value: boolean) => void
  onSaveInDarft: () => void
  onMoveToStep: () => void
  onOpenUpgradeToPremium: () => void
}

export const TournamentSetup = ({
  mainTournamentId,
  mainTournamentsList,
  eventTypeId,
  structure,
  readOnly,
  isAutoResize,
  name,
  isPremium,
  mode,
  onMoveToStep,
  onSaveInDarft,
  onChangeMode,
  onChangeName,
  onChengeAutoResize,
  onChangeEventType,
  onChangeStructure,
  onChangeMainTournament,
  onOpenUpgradeToPremium
}: TournamentSetupProps) => {
  const [eventType, setEventType] = useState<kl_EventType>()
  const [mainTournament, setMainTournament] = useState<kl_Tournament>()

  const [isNameValid, setIsNameValid] = useState(true)
  const [isMainTournamentValid, setIsMainTournamentValid] = useState(true)
  const [isEventTypeValid, setIsEventTypeValid] = useState(true)
  const [isStructureValid, setIsStructureValid] = useState(true)
  const [isModeValid, setIsModeValid] = useState(true)

  const theme = useTheme()

  const handleNameChange = (value: string) => {
    setIsNameValid(true)
    onChangeName(value)
  }

  const handleAutoResizeChange = (value: string) => {
    onChengeAutoResize(value === 'yes')
  }

  const handleMainTournamentChange = (value: kl_Tournament) => {
    onChangeMainTournament(value.Id)
    setMainTournament(value)
    setIsMainTournamentValid(true)
  }

  const handleEventTypeChange = (value: kl_EventType) => {
    onChangeEventType(value)
    setEventType(value)
    setIsEventTypeValid(true)
  }

  const handleModeChange = (value: string) => {
    onChangeMode(value)
    setIsModeValid(true)
  }

  const handleStructureChange = (value: string) => {
    onChangeStructure(value)
    setIsStructureValid(true)
  }

  const handleValidate = () => {
    setIsNameValid(!!name?.trim())
    setIsEventTypeValid(!!eventTypeId)
    setIsModeValid(!!mode)
    setIsStructureValid(!!structure)
    if (eventTypeId === EventTypeEnum.Qualifier) {
      setIsMainTournamentValid(!!mainTournamentId)
    }

    if (isNameValid && isModeValid && isEventTypeValid && isStructureValid) {
      if (eventTypeId === EventTypeEnum.Qualifier) {
        if (isMainTournamentValid) {
          onMoveToStep()
        } else {
          return
        }
      }
      onMoveToStep()
    }
  }
  const handleUpgradeToPremium = () => {
    onOpenUpgradeToPremium()
  }
  useEffect(() => {
    if (eventTypeId) {
      setEventType({ Id: eventTypeId, EventType: EventTypeEnum[eventTypeId] })
    }
  }, [eventTypeId])

  useEffect(() => {
    if (mainTournamentId && mainTournamentsList) {
      setMainTournament(mainTournamentsList.find(x => x.Id === mainTournamentId))
    }
  }, [mainTournamentId, mainTournamentsList])

  return (
    <>
      <div className="row m-0">
        <Label className={styles.ct__article__subheader}>Tournament Name</Label>
      </div>
      <div className="row m-l-0">
        <TextInput valid={isNameValid} inputClassName={styles.article__input} className={cn(styles.article__height_1, styles.article__width_2)} value={name || ''} onChange={handleNameChange} placeholder="Name" />
      </div>

      <div className="row m-0">
        <Label className={styles.ct__article__subheader}>Select Event Type</Label>
      </div>
      <div className="row m-l-0">
        <Select
          containerClassName={cn('m-0', styles.article__height_1, styles.article__width_2, styles.article__input, styles.zIndex_2)}
          options={EventTypeList}
          onChange={handleEventTypeChange}
          selected={eventType}
          labelExtractor={(type: kl_EventType) => type.EventType}
          valueExtractor={(type: kl_EventType) => type.Id}
          type="single"
          required
          disabled={readOnly || !isPremium}
          placeHolder="Select Event Type"
          valid={isEventTypeValid}
        />
      </div>

      <div className="row m-0 w-100 flex-nowrap">
        {eventType?.Id === EventTypeEnum.Normal && (
          <InfoText>
            Create a single tournament event that is not linked to any other events.
          </InfoText>
        )}
        {eventType?.Id === EventTypeEnum.Main && (
          <InfoText>
            Create a tournament event for participants to be entered into when they have won a qualifier event.
          </InfoText>
        )}
        {eventType?.Id === EventTypeEnum.Qualifier && (
          <InfoText>
            You must first create a Main event that will be linked to this qualifier series.
          </InfoText>
        )}
      </div>

      {eventType?.Id === EventTypeEnum.Qualifier &&
        <>
          <div className="row m-0">
            <Label className={styles.ct__article__subheader}>Select Main Event</Label>
          </div>
          <div className="row m-l-0">
            <Select
              containerClassName={cn(styles.article__height_1, styles.article__width_2, styles.article__input, styles.zIndex_1)}
              options={mainTournamentsList}
              onChange={handleMainTournamentChange}
              selected={mainTournament}
              labelExtractor={(type: kl_Tournament) => type.Name}
              valueExtractor={(type: kl_Tournament) => type.Id}
              type="single"
              required
              disabled={readOnly}
              placeHolder="Select Main Event"
              valid={isMainTournamentValid}
            />
          </div>
        </>
      }

      <div className="row m-0">
        <Label className={styles.ct__article__subheader}>Tournament mode</Label>
      </div>
      <div className="row m-0">
        <div className="d-flex flex-row justify-content-between">
          {TournamnetModeList.map(x =>
            <div key={x.Id} className="col-sm-auto col-6 p-0 p-r-8">
              <RadioInput
                name="mode"
                withCheck
                checked={mode === x.Id}
                label={x.TournamentMode}
                onChange={handleModeChange}
                value={x.TournamentMode}
                labelClassName={cn(styles.article__height_2, styles.article__width_1)}
                valid={isModeValid}
              >
                {Modes[x.Id]}
              </RadioInput>
            </div>)
          }
        </div>
      </div>
      { !isModeValid && <div className="row m-0"><InputError text="Please Select a Tournament mode" /></div>}


      <div className="row m-0">
        <Label className={styles.ct__article__subheader}>Select a tournament structure</Label>
      </div>
      <div className="row m-0 m-r--30">
        {TournamentStructureOptions.map(x =>
          <TournamentWizardRadioInput
            key={x.id}
            name="structure"
            isBlock={!isPremium && x.id !== TournamentStructureEnum.SingleElimination}
            readOnly={readOnly}
            linkText={!isPremium ? 'Upgrade ' : ''}
            blockedText={!isPremium ? 'to Premium account to unlock feature (Try the 30 day Free Trial).' : ''}
            withCheck
            checked={structure === x.id}
            label={x.name}
            onChange={handleStructureChange}
            value={x.name}
            labelClassName={cn(styles.article__height_3, styles.article__width_2)}
            valid={isStructureValid}
            onClick={handleUpgradeToPremium}
          >
            {Structures[x.id]}
          </TournamentWizardRadioInput>)
        }
      </div>
      { !isStructureValid && <div className={cn('row', styles.errorMessage)}><InputError text="Please Select a tournament structure" /></div>}

      <div className="row m-0">
        <Label className={styles.ct__article__subheader}>Smart Bracket</Label>
      </div>
      <div className="row m-0">
        <RadioInput
          name="resize"
          checked={!isAutoResize}
          label="1"
          onChange={handleAutoResizeChange}
          labelClassName={cn(styles.article__height_1, styles.article__width_0, 'mt-0 my-0')}
          value="no"
        />
        <TournamentWizardRadioInput
          name="resize"
          checked={isAutoResize}
          label="2"
          linkText={!isPremium ? 'Upgrade ' : ''}
          onChange={handleAutoResizeChange}
          labelClassName={cn(styles.article__height_1, styles.article__width_0, 'mt-0 my-0')}
          value="yes"
          isBlock={!isPremium}
          blockedText={!isPremium ? 'to Premium account to unlock feature (Try the 30 day Free Trial).' : ''}
          onClick={handleUpgradeToPremium}
        />
      </div>

      <div className="row m-0 w-100 flex-nowrap">
      {!isAutoResize && <InfoText>
        When the sign-up does not fill the intended number of slots, our system will keep the current number of registrants and will resize the slot to the next possible bracket formation which means some participants will not have opponents until the next round to prevent the event from failing.
        </InfoText>}
        {isAutoResize && <InfoText>
          When the sign-ups do not fill the intended number of slots, our system will remove a number of registrants and resize the slot to the next lowest possible bracket formation so all participants are matched up with an opponent to be able to start on the same day.
        </InfoText>}
      </div>

      <div className={cn(styles.article__buttonsContainer, 'row justify-content-end')}>
        <div className="col-sm-auto col-6 p-0 p-r-5">
          <Button onClick={onSaveInDarft} className={styles.article__saveInDraft} color={theme === 'dark' ? 'grey' : 'lightPurple'} filled >Save in draft</Button>
        </div>
        <div className="col-sm-auto col-6 p-0 p-l-5">
          <Button onClick={handleValidate} className={styles.article__nextStep} color="blue" filled >Next step</Button>
        </div>
      </div>

    </>
  )
}
