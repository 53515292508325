import React, { useMemo } from 'react'
import { DropDownTable, ParticipantAvatar, Loading, LoadingBox } from 'components/_shared'
import { DisputeDropDownTable } from '.'
import stylesCommon from '../Disputes.module.scss'
import stylesDark from '../DisputesDark.module.scss'
import stylesLight from '../DisputesLight.module.scss'

import { cn } from 'utils'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store/types/common'
import { makeGetIsMatchSubmissionsLoading, makeGetSubmissionByMatchParticipantId } from 'store/selectors/match-submissions-selectors'
import { isTwoPlayersStructure, TournamentStructureEnum } from 'consts'
import { makeGetDisputeDetailByMatchParticipantId, makeGetIsDisputeDetailsLoading } from 'store/selectors/dispute-detail-selectors'
import { DisputeDetailModalParams } from './DisputeDetailModal'
import { ParticipantSubmissionModel } from 'store/types/match-submissions-types'
import { makeGetDispute } from 'store/selectors/dispute-selectors'
import { DisputeStatus } from 'store/types/dispute-types'
import { getOrdinalNumber } from 'utils/numberUtils'
import { ContainerName, useMatchPlayer, useThemeStyles } from 'components/_hooks'
import { ThemedKlLink } from 'components/_shared/ThemedKlLink/ThemedKlLink'

export interface DisputeMatchParticipantRowProps {
  matchParticipantId: number
  openVideoPlayerModal: (videoUrl: string) => void
  openDisputeDetailModal: (params: DisputeDetailModalParams) => void
  openSubmissionModal: (submission: ParticipantSubmissionModel) => void
  tournamentStructure: TournamentStructureEnum
  disputeId: number
  inDispute: boolean
  winner: boolean
  place?: number
}


export const DisputeParticipantRow = ({
  matchParticipantId,
  openVideoPlayerModal,
  openDisputeDetailModal,
  openSubmissionModal,
  tournamentStructure,
  disputeId,
  inDispute,
  winner,
  place,
}: DisputeMatchParticipantRowProps) => {
  const styles = useThemeStyles(stylesDark, stylesLight)

  const getSubmissionByMatchParticipantId = useMemo(makeGetSubmissionByMatchParticipantId, [])
  const getDisputeDetailByMatchParticipantId = useMemo(makeGetDisputeDetailByMatchParticipantId, [])
  const getIsMatchSubmissionsLoading = useMemo(makeGetIsMatchSubmissionsLoading, [])
  const getDispute = useMemo(makeGetDispute, [])
  const getIsDisputeDetailsLoading = useMemo(makeGetIsDisputeDetailsLoading, [])

  const { name, profileUrl, tournamentParticipant } = useMatchPlayer(matchParticipantId)
  const submission = useSelector((state: ApplicationState) => getSubmissionByMatchParticipantId(state, matchParticipantId))
  const disputeDetail = useSelector((state: ApplicationState) => getDisputeDetailByMatchParticipantId(state, matchParticipantId))
  const dispute = useSelector((state: ApplicationState) => getDispute(state, disputeId))
  const matchSubmissionsLoading = useSelector((state: ApplicationState) => getIsMatchSubmissionsLoading(state, dispute.MatchId))
  const disputeDetailsLoading = useSelector((state: ApplicationState) => getIsDisputeDetailsLoading(state, dispute.Id))

  const handleMakeWinnerClick = () => {
    openDisputeDetailModal({
      disputeDetail: {
        ...disputeDetail,
        DisputeId: disputeId,
        MatchParticipantId: matchParticipantId,
      },
      participantName: name,
    })
  }

  const handleViewVideoClick = () => {
    if (submission?.EvidenceLink) {
      openVideoPlayerModal(submission.EvidenceLink)
    }
  }

  const handleOpenSubmissionClick = () => {
    if (submission) {
      openSubmissionModal(submission)
    }
  }

  const playerLabel = useMemo(() => {
    if (inDispute) {
      return 'IN DISPUTE'
    }
    if (place > 0) {
      return isTwoPlayersStructure(tournamentStructure) ? 'WINNER' : `${getOrdinalNumber(place)} Place`
    }
    if (winner) {
      return 'WINNER'
    }
    return ''
  }, [inDispute, place, winner, tournamentStructure])

  return (
    <DropDownTable.SecondaryRow>
      <DisputeDropDownTable.SecondaryRowColumn className={stylesCommon.tableColumnWinner}>
        <LoadingBox loading={matchSubmissionsLoading || disputeDetailsLoading} size="average">
          {!!playerLabel && <div className={stylesCommon.tableWinner}>{playerLabel}</div>}
        </LoadingBox>
      </DisputeDropDownTable.SecondaryRowColumn>
      <DisputeDropDownTable.SecondaryRowColumn className={stylesCommon.tableColumnPlayer}>
        <div className="row m-0 h-100 align-items-center">
          <ParticipantAvatar
            participantId={tournamentParticipant?.Id}
            className={stylesCommon.tableUserProfilePicture}
            withLink
            containerName={ContainerName.AvatarsNormal}
          />
          <ThemedKlLink className={stylesCommon.tableUsername} to={profileUrl}>
            {name}
          </ThemedKlLink>
        </div>
      </DisputeDropDownTable.SecondaryRowColumn>
      <DisputeDropDownTable.SecondaryRowColumn className={cn(stylesCommon.tableColumnExplanation, styles.tableColumnExplanation)}>
        {matchSubmissionsLoading ? (
          <Loading size="small" />
        ) : (
          <>
            {submission ? (
              <ThemedKlLink to="#" onClick={handleOpenSubmissionClick}>
                Open Submission
              </ThemedKlLink>
            ) : (
              '-'
            )}
            {' / '}
            {submission?.EvidenceLink ? (
              <ThemedKlLink to="#" onClick={handleViewVideoClick}>
                Video Evidence Link
              </ThemedKlLink>
            ) : (
              '-'
            )}
          </>
        )}
      </DisputeDropDownTable.SecondaryRowColumn>
      <DisputeDropDownTable.SecondaryRowColumn className={stylesCommon.tableColumnButton}>
        <DropDownTable.Button
          color="blue"
          onClick={handleMakeWinnerClick}
          className={cn(stylesCommon.tableButton, stylesCommon.tableMakeWinner, styles.tableMakeWinner)}
          disabled={matchSubmissionsLoading || disputeDetailsLoading}
        >
          {dispute.Status === DisputeStatus.Closed ? 'OPEN NOTES' : isTwoPlayersStructure(tournamentStructure) ? 'MAKE WINNER' : 'SELECT PLACE'}
        </DropDownTable.Button>
      </DisputeDropDownTable.SecondaryRowColumn>
      <DisputeDropDownTable.SecondaryRowButtonsColumn />
    </DropDownTable.SecondaryRow>
  )
}
