export const GeneralUtils = {
  randomHash:  Math.random().toString(36).substring(7),
  GoogleAPIKey:  'AIzaSyAuWpjADcaiZURjjvG4pcsY3HPw5_s_Py4',
  NotSupportedPayoutMessage:  'Your geoLocation is not being supported by Stripe, Crypto-Currency will be the only form of payout',
  getRandomNumber: function getRandomNumber(numberOfDigits: number) {
    return Math.floor(Math.pow(10, numberOfDigits - 1) + Math.random() * 9 * Math.pow(10, numberOfDigits - 1))
  },

  showHideLoadingDiv: function showHideLoadingDiv(showHideLoadingDiv: boolean) {
    if (showHideLoadingDiv) {
      document.getElementById('loadingDiv') && document.getElementById('loadingDiv').setAttribute('style', 'display: block')
      setTimeout(() => {
        document.getElementById('loadingDiv') && document.getElementById('loadingDiv').setAttribute('style', 'display: none')
      }, 5000)
    } else document.getElementById('loadingDiv') && document.getElementById('loadingDiv').setAttribute('style', 'display: none')
  },

  showSuccess: function showSuccess(text?: string, time?: number) {
    this.showHideLoadingDiv(true)

    const successMessageArea = document.getElementById('successmessage')
    if (successMessageArea) {
      successMessageArea.innerText = text
    }

    const successPhotoArea = document.getElementById('success-photo')
    if (successPhotoArea) {
      successPhotoArea.className = 'modal-container modal-container--opened'
    }
    this.showHideLoadingDiv(false)
    setTimeout(
      () => {
        if (successPhotoArea) {
          successPhotoArea.className = 'modal-container'
        }
      },
      time ? time : 5000
    )
  },

  showError: function showError(text: string, time?: number) {
    this.showHideLoadingDiv(true)
    const errorMessage = document.getElementById('errormessage')
    if (errorMessage) {
      errorMessage.innerText = text
    }
    const errorPhoto = document.getElementById('error-photo')
    if (errorPhoto) {
      errorPhoto.className = 'modal-container modal-container--opened'
    }
    this.showHideLoadingDiv(false)
    setTimeout(
      () => {
        if (errorPhoto) {
          errorPhoto.className = 'modal-container'
        }
      },
      time ? time : 4000
    )
  },

  NumbersToWordsFromLength: function NumbersToWordsFromLength(numbersLength: number) {
    const result: string[] = []
    for (let item = 1; item <= numbersLength; item++) {
      result.push(item + ([, 'st', 'nd', 'rd'][~~((item / 10) % 10) - 1 ? item % 10 : 0] || 'th'))
    }
    return result
  },

  getDateString: function getDateString(date: Date) {
    function pad(value: number) {
      return value.toString().padStart(2, '0')
    }

    return date.getFullYear() + '-' + pad(date.getMonth() + 1) + '-' + pad(date.getDate()) + 'T' + pad(date.getHours()) + ':' + pad(date.getMinutes())
  },
  capitalizeFirstLetter: function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
  },
  uniqueByProperties(arr:any, keyProps:any) {
    const kvArray = arr.map(entry => {
     const key = keyProps.map(k => entry[k]).join('|');
     return [key, entry];
    });
    const map = new Map(kvArray);
    return Array.from(map.values());
   }
}
