import { TournamentModeEnum, TournamentStatusEnum, TournamentStructureEnum, VenueTypeEnum } from 'consts'
import { DateIntervalEnum } from 'consts/DateInterval'
import { TournamentEventTypeEnum } from 'consts/TournamentEventType'
import { TournamentSortFieldEnum } from 'consts/TournamentSortField'
import { ConsoleEnum } from 'models/kl_game-consoles-model'
import { useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import { TournamentConsolesType } from 'store/types/console-types'
import { ImageType } from 'store/types/image-types'
import { fetchApi } from '../../server/server-utils'
import { AppThunkAction, GetState, PaginationModel } from '../types/common'
import { finishedTournamentsLoaded, gameMainTournamentsLoaded, gameQualifyTournamentsLoaded, gameUpcomingTournamentsLoaded, 
  organizerRecentTournamentsLoaded, setTournament, topTournamentsLoaded, TournamentType } from '../types/tournaments-types'
import { OperationTypes, run, useRun } from './operations-logic'

export interface TournamentFilters {
  userId?: number
  venueTypes: VenueTypeEnum[]
  organizersIds: number[]
  gamesIds: number[]
  regionsIds: number[]
  from?: Date
  to?: Date
  name: string
  kombatlinkOrganizer?: boolean
  tournamentMode?: TournamentModeEnum
  tournamentStructures: TournamentStructureEnum[]
  tournamentStatuses?: TournamentStatusEnum[]
  consoles?: ConsoleEnum[]
  dateInterval?: DateIntervalEnum
  tournamentSortField?: TournamentSortFieldEnum
  eventType?: TournamentEventTypeEnum
}

interface ListTournamentsResult {
  AllTournamentsCount: number
  Tournaments: TournamentType[]
  TournamentConsoles: TournamentConsolesType[] 
  Images: ImageType[]
}

export const loadTournament = async (tournamentDetailId: number, dispatch: Dispatch, getState: GetState) => {
  await run(OperationTypes.loadTournament, tournamentDetailId, dispatch, getState, async () => {
    try {
      const tournament = await fetchApi<TournamentType>(`tournament/${tournamentDetailId}`, 'GET')
      dispatch(setTournament(tournament))
    } catch (error) {
      return false
    }
    return true
  })
}

//#region tournament list
export const getFilteredTournaments = (filters: TournamentFilters, pagination: PaginationModel) => {
  return fetchApi<ListTournamentsResult>('tournament/list', 'POST', {
    filters,
    pagination,
  })    
}

export const loadFinishedTournaments = (filters: TournamentFilters, pagination: PaginationModel) => async (dispatch: Dispatch, getState: GetState) => {
  await run(OperationTypes.loadFinishedTournaments, null, dispatch, getState, async () => {
    try {
      const result = await getFilteredTournaments(filters, pagination)
      dispatch(finishedTournamentsLoaded({
        tournaments: result.Tournaments,
        allFinishedTournamentsCount: result.AllTournamentsCount,
      }))
    } catch (error) {
      return false
    }
    return true
  })
}

export const loadTopTournaments = (count: number) => async (dispatch: Dispatch, getState: GetState) => {
  await run(OperationTypes.loadTopTournaments, null, dispatch, getState, async () => {
    try {
      const filters: TournamentFilters = {
        tournamentStatuses: [TournamentStatusEnum.Pending],
        venueTypes: [],
        organizersIds: [],
        gamesIds: [],
        name: '',
        regionsIds: [],
        tournamentStructures: [],
      }
      const pagination: PaginationModel = {
        page: 0,
        pageSize: count,
      }
      const result = await getFilteredTournaments(filters, pagination)
      dispatch(topTournamentsLoaded({
        tournaments: result.Tournaments,
        TournamentConsoles: result.TournamentConsoles,
        Images: result.Images,
      }))
    } catch (error) {
      return false
    }
    return true
  })
}

export const loadRecentOrganizerTournaments = (organizerId: number) => async (dispatch: Dispatch, getState: GetState) => {
  await run(OperationTypes.loadOrganizerRecentTournaments, organizerId, dispatch, getState, async () => {
    try {
      const filters: TournamentFilters = {
        venueTypes: [],
        organizersIds: [organizerId],
        gamesIds: [],
        name: '',
        regionsIds: [],
        tournamentStructures: [],        
      }
      const pagination: PaginationModel = {
        page: 0,
        pageSize: 3,
      }
      const result = await getFilteredTournaments(filters, pagination)
      dispatch(organizerRecentTournamentsLoaded({
        organizerId: organizerId,
        tournaments: result.Tournaments,
        TournamentConsoles: result.TournamentConsoles,
        Images: result.Images,
      }))
    } catch (error) {
      return false
    }
    return true
  })
}


export const loadGameUpcomingTournaments = (filters: TournamentFilters, pagination: PaginationModel) => async (dispatch: Dispatch, getState: GetState) => {
  await run(OperationTypes.loadGameUpcomingTournaments, null, dispatch, getState, async () => {
    try {
      const result = await getFilteredTournaments(filters, pagination)
      dispatch(gameUpcomingTournamentsLoaded({
        tournaments: result.Tournaments,
        allGameUpcomingTournamentsCount: result.AllTournamentsCount,
        TournamentConsoles: result.TournamentConsoles,
        Images: result.Images,
      }))
    } catch (error) {
      return false
    }
    return true
  })
}

export const loadGameMainTournaments = (filters: TournamentFilters, pagination: PaginationModel) => async (dispatch: Dispatch, getState: GetState) => {
  await run(OperationTypes.loadGameMainTournaments, null, dispatch, getState, async () => {
    try {
      const result = await getFilteredTournaments(filters, pagination)
      dispatch(gameMainTournamentsLoaded({
        tournaments: result.Tournaments,
        allGameMainTournamentsCount: result.AllTournamentsCount,
        TournamentConsoles: result.TournamentConsoles,
        Images: result.Images,
      }))
    } catch (error) {
      return false
    }
    return true
  })
}

export const loadGameQualifyTournaments = (filters: TournamentFilters, pagination: PaginationModel) => async (dispatch: Dispatch, getState: GetState) => {
  await run(OperationTypes.loadGameQualifyTournaments, null, dispatch, getState, async () => {
    try {
      const result = await getFilteredTournaments(filters, pagination)
      dispatch(gameQualifyTournamentsLoaded({
        tournaments: result.Tournaments,
        allGameQualifyTournamentsCount: result.AllTournamentsCount,
        TournamentConsoles: result.TournamentConsoles,
        Images: result.Images,
      }))
    } catch (error) {
      return false
    }
    return true
  })
}

//#endregion

export const useUpdateTournamentCoverImage = (tournamentId: number) => {
  return useRun(OperationTypes.updateTournamentCover, tournamentId, async (imageId: number) => {
    await fetchApi(`tournament/${tournamentId}/updatecover/${imageId}`, 'PUT')
    return true
  })
}

export const cancelTournament = (tournamentDetailId: number) => {
  return fetchApi<TournamentType>(`tournament/${tournamentDetailId}/cancel`, 'POST')
}

export const uploadTournamentCoverImage = async (tournamentDetailId: number, file: File) => {
  const formData = new FormData()
  formData.append('file', file)
  await fetchApi(`tournament/${tournamentDetailId}/cover`, 'POST', formData)
}

export const uploadTournamentTicketImage = async (tournamentDetailId: number, file: File) => {
  const formData = new FormData()
  formData.append('file', file)
  await fetchApi(`tournament/${tournamentDetailId}/ticket-image`, 'POST', formData)
}

export const tournamentsActionCreators = {
  loadTournament: (tournamentDetailId: number): AppThunkAction => async (dispatch: Dispatch, getState: GetState) => {
    await loadTournament(tournamentDetailId, dispatch, getState)
  },
}
