import { useFilters, useUnionMatchParam } from 'components/_hooks'
import { ArticleHeader, FilterContainer, Filters, HeaderButton, Tabs, SearchInput, DateInput, Select } from 'components/_shared'
import { TournamentModeEnum, TournamentModeOptions, TournamentStatusEnum, VenueTypeEnum } from 'consts'
import { ConsoleEnum, ConsoleTypeOptions } from 'consts/ConsoleType'
import { DateIntervalEnum, DateIntervalOptions } from 'consts/DateInterval'
import React, { useState, useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { IDictionary } from 'store/types/dictionary-types'
import { gameLabelExtractor, GameType, gameValueExtractor } from 'store/types/game-types'
import { TournamentFilters } from 'store/logic/tournaments-logic'
import { useAppUser } from 'components/_hooks/useAppUser'
import CanceledTournaments from './Shared/CanceledTournaments'
import DraftTournamentTab from './Shared/DraftTournamentTab'
import { TournamentEventTypeEnum, TournamentEventTypeOptions } from 'consts/TournamentEventType'
import styles from './EventsOrganizer.module.scss'
import { getOrganizerEventsPageFilters } from 'store/selectors/pages/organizer-events-page-selectors'
import { organizerEventsFiltersChanged } from 'store/types/pages/organizer-events-page-types'
import ActiveTournaments from './Shared/ActiveTournaments'
import FinishedTournaments from './Shared/FinishedTournaments'
import UpcomingTournaments from './Shared/UpcomingTournaments'


export const organizerEventsTabs = {
  upcoming: 'upcoming',
  active: 'active',
  finished: 'finished',
  canceled: 'canceled',
  draft: 'draft',
}


export type OrganizerEventsTab = keyof typeof organizerEventsTabs

interface EventsOrganizerRouteProps {
  type: OrganizerEventsTab
}

export type EventsProps = RouteComponentProps<EventsOrganizerRouteProps>


const getStatusTypes = (tab: OrganizerEventsTab) => {
  if (tab == organizerEventsTabs.draft) {
    return [TournamentStatusEnum.Draft]
  } 
  if (tab ==  organizerEventsTabs.canceled) {
    return [TournamentStatusEnum.Cancel]
  } 

  if (tab == organizerEventsTabs.finished) {
    return [TournamentStatusEnum.Finished]
  } 
  if (tab == organizerEventsTabs.active) {
    return [TournamentStatusEnum.Active]
  } 
  if (tab == organizerEventsTabs.upcoming) {
    return [TournamentStatusEnum.Pending]
  } 
  return null
}

const EventsOrganizer = ({ match }: EventsProps) => {
  const dispatch = useDispatch()
  
  const storedFilters = useSelector(getOrganizerEventsPageFilters) 
  const tab = useUnionMatchParam(organizerEventsTabs, match, m => m.type, storedFilters.currentTab)

  const user = useAppUser()

  const [filtersModalOpened, setFiltersModalOpened] = useState(false)

  const { games, gamesLoading } = useFilters()
  
  const isOnlineEvent = storedFilters.isOnlineEvent
  const isLiveEvent = storedFilters.isLiveEvent

  const name = storedFilters.name
  const selectedConsoles = storedFilters.selectedConsoles
  const selectedGames = storedFilters.selectedGames

  const selectedEventType = storedFilters.selectedEventType

  const dateFrom = storedFilters.dateFrom
  const dateTo = storedFilters.dateTo

  const selectedInterval = storedFilters.selectedInterval
  const selectedMode = storedFilters.selectedMode

  const handleFilterClick = () => {
    setFiltersModalOpened(true)
  }
  const handleCloseFiltersModal = () => {
    setFiltersModalOpened(false)
  }  


  const filters = useMemo<TournamentFilters>(() => {
    const filter = {
      name: '',
      venueTypes: [isOnlineEvent && VenueTypeEnum.Online, isLiveEvent && VenueTypeEnum.Live].filter(x => x),
      gamesIds: [],
      tournamentStructures: [],
      tournamentStatuses: getStatusTypes(tab),
      organizersIds: [user.Id],
      regionsIds: [],
    }

    if (tab == organizerEventsTabs.canceled || tab == organizerEventsTabs.draft) {
      return filter
    }

    return {
      ...filter,
      name: name,     
      gamesIds: selectedGames.map(x => x.Id),
      tournamentMode: selectedMode?.id,
      consoles: selectedConsoles.map(x => x.id),
      dateInterval: selectedInterval?.id,
      from: dateFrom,
      to: dateTo,
      eventType: selectedEventType?.id,
    }
  }, [dateFrom, dateTo, selectedEventType?.id, isLiveEvent, isOnlineEvent, name, selectedConsoles, selectedGames, selectedInterval?.id, selectedMode?.id, tab, user.Id])

  useEffect(() => {
    if (tab !== storedFilters.currentTab) {
      dispatch(organizerEventsFiltersChanged(
        {
          filters: {
            ...storedFilters,
            currentPage: 0,
            currentTab: tab,           
          },
        }
      ))
    }
  }, [storedFilters, dispatch, tab])
  
  const onChangeIsOnlineEvent = () => {
    dispatch(organizerEventsFiltersChanged(
      {
        filters: {
          ...storedFilters,
          isOnlineEvent: !storedFilters.isOnlineEvent,
          currentPage: 0,
        },
      }
    ))
  }

  const onChangeIsLiveEvent = () => {
    dispatch(organizerEventsFiltersChanged(
      {
        filters: {
          ...storedFilters,
          isLiveEvent: !storedFilters.isLiveEvent,
          currentPage: 0,
        },
      }
    ))
  }


  const handleNameChange = (value: string) => {
    dispatch(organizerEventsFiltersChanged(
      {
        filters: {
          ...storedFilters,
          name: value,
          currentPage: 0,
        },
      }
    ))
  }
  const handleChangeConsoles = (value: IDictionary<ConsoleEnum>[]) => {
    dispatch(organizerEventsFiltersChanged(
      {
        filters: {
          ...storedFilters,
          selectedConsoles: value,
          currentPage: 0,
        },
      }
    ))
  }
  const handleGamesChange = (value: GameType[]) => {
    dispatch(organizerEventsFiltersChanged(
      {
        filters: {
          ...storedFilters,
          selectedGames: value,
          currentPage: 0,
        },
      }
    ))
  }

  const handleChangeEventType = (value: IDictionary<TournamentEventTypeEnum>) => {
    dispatch(organizerEventsFiltersChanged(
      {
        filters: {
          ...storedFilters,
          selectedEventType: value,
          currentPage: 0,
        },
      }
    ))
  }

  const handleDateFromChange = (value: Date) => {
    dispatch(organizerEventsFiltersChanged(
      {
        filters: {
          ...storedFilters,
          dateFrom: value,
          currentPage: 0,
        },
      }
    ))
  }

  const handleDateToChange = (value: Date) => {
    dispatch(organizerEventsFiltersChanged(
      {
        filters: {
          ...storedFilters,
          dateTo: value,
          currentPage: 0,
        },
      }
    ))
  }

  const handleChangeInterval = (value: IDictionary<DateIntervalEnum>) => {
    dispatch(organizerEventsFiltersChanged(
      {
        filters: {
          ...storedFilters,
          selectedInterval: value,
          currentPage: 0,
        },
      }
    ))
  }
    
  const handleChangeMode = (value: IDictionary<TournamentModeEnum>) => {
    dispatch(organizerEventsFiltersChanged(
      {
        filters: {
          ...storedFilters,
          selectedMode: value,
          currentPage: 0,
        },
      }
    ))
  }


  const hasFilters = tab == organizerEventsTabs.upcoming || tab == organizerEventsTabs.active  || tab == organizerEventsTabs.finished 

  return (
    <>
      <ArticleHeader text="My Events">
        {hasFilters && (
          <HeaderButton onClick={handleFilterClick} clasName="article__open-selects">Filter Options</HeaderButton>
        )}
      </ArticleHeader>

      <div className="row m-0--30">
        <div className="col-12">
          <Tabs>
            <Tabs.Menu>
              <Tabs.Link url="/events/upcoming" active={tab === organizerEventsTabs.upcoming}>Upcoming</Tabs.Link>
              <Tabs.Link url="/events/active" active={tab === organizerEventsTabs.active}>Active</Tabs.Link>
              <Tabs.Link url="/events/finished" active={tab === organizerEventsTabs.finished}>Finished</Tabs.Link> 
              <Tabs.Link url="/events/canceled" active={tab === organizerEventsTabs.canceled}>Canceled</Tabs.Link>             
              <Tabs.Link url="/events/draft" active={tab === organizerEventsTabs.draft}>Draft</Tabs.Link>            
            </Tabs.Menu>
            {tab !== organizerEventsTabs.draft && (
              <Tabs.CheckboxContainer>
                <Tabs.Checkbox checked={isOnlineEvent} onChange={onChangeIsOnlineEvent} id="OnlineEvent">Online event</Tabs.Checkbox>
                <Tabs.Checkbox checked={isLiveEvent} onChange={onChangeIsLiveEvent} id="LiveEvent">Live event</Tabs.Checkbox>
              </Tabs.CheckboxContainer>
          
            )}
          </Tabs>
        </div>
      </div>
      {hasFilters && (
        <Filters title="Filter Options" opened={filtersModalOpened} close={handleCloseFiltersModal} className="">
          <FilterContainer title="Name" className={styles.article__select}>
            <SearchInput onChange={handleNameChange} placeholder="Search by name" value={name} />
          </FilterContainer>
          <FilterContainer title="Consoles" className={styles.article__select}>
            <Select
              options={ConsoleTypeOptions}
              onChange={handleChangeConsoles}
              selected={selectedConsoles}
              labelExtractor={(option: IDictionary<ConsoleEnum>) => option.name}
              placeHolder="All"
              valueExtractor={(option: IDictionary<ConsoleEnum>)  => option.id}
              type="multi"
            />
          </FilterContainer>
          <FilterContainer title="Game" className={styles.article__select}>
            <Select
              options={games}
              onChange={handleGamesChange}
              selected={selectedGames}
              labelExtractor={gameLabelExtractor}
              placeHolder="All Games"
              valueExtractor={gameValueExtractor}
              type="multi"
              loading={gamesLoading}
              withSearch
            />
          </FilterContainer>
          <FilterContainer title="Tournament type" className={styles.article__select}>
            <Select
              options={TournamentEventTypeOptions}
              onChange={handleChangeEventType}
              selected={selectedEventType}
              labelExtractor={(option: IDictionary<TournamentEventTypeEnum>) => option.name}
              placeHolder="All"
              valueExtractor={(option: IDictionary<TournamentEventTypeEnum>)  => option.id}
              type="single"
            />
          </FilterContainer>     
          <FilterContainer title="Date from" className={styles.article__select}>
            <DateInput onChange={handleDateFromChange} value={dateFrom} />
          </FilterContainer>
          <FilterContainer title="Date to" className={styles.article__select}>
            <DateInput onChange={handleDateToChange} value={dateTo} />
          </FilterContainer>
          <FilterContainer title="Select time" className={styles.article__select}>
            <Select
              options={DateIntervalOptions}
              onChange={handleChangeInterval}
              selected={selectedInterval}
              labelExtractor={(option: IDictionary<DateIntervalEnum>) => option.name}
              placeHolder="All"
              valueExtractor={(option: IDictionary<DateIntervalEnum>)  => option.id}
              type="single"
            />
          </FilterContainer>
          <FilterContainer title="Type" className={styles.article__select}>
            <Select
              options={TournamentModeOptions}
              onChange={handleChangeMode}
              selected={selectedMode}
              labelExtractor={(option: IDictionary<TournamentModeEnum>) => option.name}
              placeHolder="All"
              valueExtractor={(option: IDictionary<TournamentModeEnum>)  => option.id}
              type="single"
            />
          </FilterContainer>                
        </Filters>
      )}
      {tab == organizerEventsTabs.draft && (
        <DraftTournamentTab />
      )}        
      {tab == organizerEventsTabs.upcoming && ( 
        <UpcomingTournaments filters={filters} />           
      )}
      {tab == organizerEventsTabs.active && ( 
        <ActiveTournaments filters={filters} />           
      )}
      {tab == organizerEventsTabs.finished && ( 
        <FinishedTournaments filters={filters} />           
      )}
      {tab == organizerEventsTabs.canceled && ( 
        <CanceledTournaments filters={filters} />           
      )} 
    </>
  )
}

export default EventsOrganizer
