import { useImgModal } from 'components/_hooks/useComponents/useImgModal'
import { useImage } from 'components/_shared/Img/useImage'
import styles from './SubmissionModal.module.scss'


interface ImgPreviewProps {
  imageId: number
  isThumbnailStyle?:boolean
}


const ImgPreview = ({
  imageId,
  isThumbnailStyle
}: ImgPreviewProps) => {
  const { handleError, imageSrc  } = useImage(imageId)

  const [imgModal, openImgModal] = useImgModal()

  const handleClick = () => {
    openImgModal(imageId)
  }

  return (
    <>
      <img onClick={handleClick} src={imageSrc} onError={handleError} className={styles.imagePreview + ' '+  (isThumbnailStyle !== undefined ? styles.image_thumbnail :'')} />
      {imgModal}
    </>
  )
}


export default ImgPreview 
