import { useAppSelector } from 'components/_hooks'
import { useMemo } from 'react'
import { makeGetIsConnectToChatLoading, makeGetIsDisconnectFormChatLoading } from './chat-hub-selectors'

export const useIsConnectToChatLoading = (chatId: number) => {
  const getIsConnectToChatLoading = useMemo(makeGetIsConnectToChatLoading, [])
  return useAppSelector(state => getIsConnectToChatLoading(state, chatId))
}

export const useIsDisconnectFromChatLoading = (chatId: number) => {
  const getIsDisconnectFormChatLoading = useMemo(makeGetIsDisconnectFormChatLoading, [])
  return useAppSelector(state => getIsDisconnectFormChatLoading(state, chatId))
}

