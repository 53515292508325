import React from 'react'
import { SideBySideMagnifier } from 'react-image-magnifiers'
import styles from './Home.module.scss'
import PopularGames from './PopularGames'

function HomeTicket() {
  const cdn = 'https://kombatlink-assets.azureedge.net/img'
  return (
    <div className={styles.content_wrapper + " m-0"}>
      <div className={styles.pdr_area}>
        <div className={styles.tab_area}>
          <ul data-aos="zoom-in" className={styles.tabs + " pro_tab"}>
            <li className={styles.tab_link} data-tab="tab-1">Print</li>
            <li className={styles.tab_link} data-tab="tab-2">Download</li>
          </ul>
          <div data-aos="zoom-in" className={`pl-4 pr-4`}>
            <div id="tab-1" className={styles.tab_content + " " + styles.tab_content_active}>
              <div className={styles.print_area}>
                <div className={styles.print_left}>
                  <div className={styles.heading}>
                    <h4>Clan ticket (full package)</h4>
                  </div>
                  <ul className={styles.ticket_ul}>
                    <li>
                      <h6>Tournament</h6>
                      <p>100</p>
                    </li>
                    <li>
                      <h6>Date</h6>
                      <p>10/10/2022</p>
                    </li>
                    <li>
                      <h6>Time</h6>
                      <p>04.00pm</p>
                    </li>
                    <li>
                      <h6>Venue</h6>
                      <p>Rogers Centre</p>
                    </li>
                    <li>
                      <h6>Location</h6>
                      <p>1 Blue Jays Way, Toronto</p>
                    </li>
                  </ul>
                  <div><img src={`${cdn}/ticket.jpg`} alt="ticket" /></div>
                </div>
                <div className={styles.print_right}> <img src={`${cdn}/qr.jpg`} alt="qr" /> </div>
              </div>
              <div className={styles.ticket_box}>
                <div className={styles.tic_left}><img src={`${cdn}/ticket-1.png`} alt="ticket" /></div>
                <div className={styles.tic_right}>
                  <h6>Custom Event Ticket</h6>
                  <p>
                  A digital ticketing solution tailored for your next event. Scannable QR code for quick and easy check in for all your guest
                  </p>
                </div>
              </div>
            </div>
            <div id="tab-2" className={styles.tab_content}>
              <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in</p>
            </div>
          </div>
        </div>
      </div>
      <div className={"row row-xl-2 row-l-2 row-m-1 m-0 " + styles.flexm + " " + styles.gap + " " + styles.solutionWrapper}>
        <div className={"col-xl-6 col-l-6 col-m-12 " + styles.dash_txt} data-aos="fade-right">
          <h3>All-in-one Solution</h3>
          <h6 className={styles.gap_small_top}>
            Easy-to-use software for any type of events, from video games to sporting competition. KombatLink doesn’t just offer a custom tournament builder and custom bracket maker, we provide a robust and scalable solution for any size of TOs.
            Take control of your sales, track and monitor all your activities from your own dashboard.
          </h6>
          <h6 className={styles.gap_small_top}>
            Create a unique experience for competitors, fans, and organizers by providing essential tools that allow participants to communicate, track matches, and view results as they happen.
          </h6>
          <a href="#" className={styles.btn}>SIGN UP NOW</a> </div>
        <div className={"col-xl-6 col-l-6 col-m-12 " + styles.img + " " + styles.img_full + " " + styles.right_img} data-aos="fade-left"><a className="group1" href="javascript:void(0)" title="dashboard">
          <div className={styles.overflowHidden}>
            <SideBySideMagnifier
                  alwaysInPlace
                  cursorStyle="string"
                  fillAvailableSpace 
                  imageSrc={`${cdn}/dashboard2.png`}/>
                  </div>
          {/* <img src={`${cdn}/dashboard2.png`} alt="dashboard" /> */}
          </a></div>
      </div>
      <PopularGames />
    </div>
  )
}

export default HomeTicket