import React, { ReactNode, useRef } from 'react'
import { cn } from 'utils'
import getId from 'utils/getId'
import { ChatMenuLink } from '../ChatMenuLink/ChatMenuLink'
import styles from './ChatMenu.module.scss'

export interface ChatMenuProps {
  children: ReactNode
  isMenuOpened: boolean
  onIsMenuOpenedChange: (value: boolean) => void
}

export const ChatMenu = ({ children, isMenuOpened, onIsMenuOpenedChange }:  ChatMenuProps) => {
  const chatInputId = useRef(`chat-menu-${getId()}`)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onIsMenuOpenedChange(event.currentTarget.checked)
  }

  return (
    <div className={cn('col-auto', styles.chatHeaderMenuContainer)}>
      <input className={styles.chatHeaderCheckbox} id={chatInputId.current} type="checkbox" checked={isMenuOpened} onChange={handleChange} />
      <label className={styles.chatHeaderMenuButton} htmlFor={chatInputId.current} />
      <div className={styles.chatMenu}>
        {children}
      </div>
    </div>
  )
}

ChatMenu.Link = ChatMenuLink
