import { useAppSelector } from 'components/_hooks'
import { UserAvatar } from 'components/_shared/UserAvatar/UserAvatar'
import { ChatType } from 'consts'
import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { makeGetChatMemeber } from 'store/chat/chat-member/chat-member-selectors'
import { ChatMessageModel } from 'store/chat/chat-message/chat-message-types'
import { makeGetUser } from 'store/selectors/user-selectors'
import { cn, defaultFormatDateTime } from 'utils'
import styles from '../ChatMessages.module.scss'

export interface MemberChatMessageProps {
  chatMessage: ChatMessageModel
  chatType: ChatType
}

export const MemberChatMessage = ({ chatMessage, chatType }:  MemberChatMessageProps) => {

  const getMember = useMemo(makeGetChatMemeber, [])
  const getUser = useMemo(makeGetUser, [])
  const member = useAppSelector(state => getMember(state, { chatMemberId: chatMessage.ChatMemberId }))
  const user = useAppSelector(state => getUser(state, member?.UserId))

  const history = useHistory()

  const handleUserClick = () => {
    history.push(`/chat/members/${member.Id}`)
  }

  return (
    <section className={styles.chatMessageContainer}>
      <div className="container-fluid">
        <div className="row m-0">
          <div className="col-auto p-0" onClick={handleUserClick}>
            <UserAvatar userId={user?.Id} className={cn(styles.chatMessageProfilePicture, styles.chatMessageProfilePictureLink)} placeholder="dark" />     
          </div>
          <div className="col p-0 p-l-7 d-flex flex-column align-items-start">
            <div className={styles.chatMessage}>
              {chatType == ChatType.Group && (
                <div className={styles.chatMessageUser}>
                  {user?.Username || user?.Organizationname || ''}
                </div>
              )}              
              <div>
                {chatMessage.Text}
              </div>
            </div>
            <div className={styles.chatMessageDate}>
              {defaultFormatDateTime(chatMessage.SentDate)}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
