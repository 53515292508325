import { createSelector } from 'reselect'
import { OperationTypes } from 'store/logic/operations-logic'
import { ApplicationState } from 'store/types/common'
import { makeGetIsOperationWithTypeRunning } from '../operations-selectors'

const getState = (state: ApplicationState) => state.friendsPage

export const getFriendsIdsByUserId = createSelector(getState, state => (state?.friendsIdsByUserId ?? []))

export const makeGetFriendsIds = () => 
  createSelector(
    getFriendsIdsByUserId,
    (_: ApplicationState, { userId }: { userId: number }) => userId,
    (ids, userId) => ids[userId]?.friendIds ?? []
  )


export const makeGetAllFriendsCount = () => 
  createSelector(
    getFriendsIdsByUserId,
    (_: ApplicationState, { userId }: { userId: number }) => userId,
    (ids, userId) => ids[userId]?.allFriendsCount ?? 0
  )

const getLoadFriendsOperationType = createSelector(
  (_: ApplicationState, { userId }: { userId: number }) => userId,
  userId => ({ type: OperationTypes.loadUserFriends, objectId: userId })
)
  
export const makeGetIsFriendsLoading = () => makeGetIsOperationWithTypeRunning(getLoadFriendsOperationType)
