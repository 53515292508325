import React, { useMemo, useState } from 'react'
import { cn } from 'utils'
import styles from './ModalSelect.module.scss'
import modalStyles from '../../modals.module.scss'

interface OwnProps<T> {
  className?: string
  options: T[]
  keyExtractor?: (option: T) => string | number
  valueExtractor?: (option: T) => string | number
  onChange: (option: T) => void
  selectedOption?: T
  disabled?: boolean
}

export const ModalSelect = <T extends any>({ className, options, keyExtractor, valueExtractor, onChange, selectedOption, disabled }: OwnProps<T>) => {
  const [isOpened, setIsOpened] = useState(false)

  const toggleIsOpened = () => {
    if (!disabled) {
      setIsOpened(!isOpened)
    }
  }

  const handleSelect = (option: T) => () => {
    onChange(option)
  }

  const selectedKey = useMemo(() => {
    return selectedOption ? keyExtractor(selectedOption) : undefined
  }, [selectedOption])

  const selectedValue = useMemo(() => {
    return selectedOption ? valueExtractor(selectedOption) : undefined
  }, [selectedOption])

  return (
    <div className="row">
      <div
        className={cn(styles.select, isOpened && styles.select__opened, modalStyles.window__select, disabled && modalStyles.disabled, className)}
        style={{ zIndex: 500 }}
        onClick={toggleIsOpened}
      >
        <div className={cn(styles.select__title, disabled && modalStyles.disabled)} style={selectedOption && { color: 'rgb(0, 16, 48)' }}>
          {selectedValue || '-'}
        </div>
        <section className={styles.select__container}>
          {options.map(x => {
            const key = keyExtractor(x)
            const isSelected = key === selectedKey
            return (
              <div key={key} className={cn(styles.select__option, isSelected && styles.select__option__selected)} onClick={handleSelect(x)}>
                {valueExtractor(x)}
              </div>
            )
          })}
        </section>
      </div>
    </div>
  )
}
