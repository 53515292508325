import { ImgModal } from 'components/_modals/ImgModal/ImgModal'
import { ReactNode } from 'react'
import { useDialogWithParameter } from './useDialog'
import { usePortal } from './usePortal'

interface PopupPortalProps {
  children: ReactNode
}

const ImgModalPortal = ({ children }: PopupPortalProps) => {
  return usePortal(children, 'popup')
}

export const useImgModal = () => {
  return useDialogWithParameter<number>((imageId, closeDialog) => (
    <ImgModalPortal>
      <ImgModal
        onClose={closeDialog}
        imageId={imageId}
      />
    </ImgModalPortal>
  ))
}
