import { DropDownTableButton } from './DropDownTableButton'
import { DropDownTableColumn } from './DropDownTableColumn'
import { DropDownTableHeadersRow } from './DropDownTableHeadersRow'
import { DropDownTableHideButton } from './DropDownTableHideButton'
import { DropDownTableLabel } from './DropDownTableLabel'
import { DropDownTableLink } from './DropDownTableLink'
import { DropDownTableMainRow } from './DropDownTableMainRow'
import { DropDownTableOpenButton } from './DropDownTableOpenButton'
import { DropDownTableButtonLink } from './DropDownTableButtonLink'
import { DropDownTableOpenReplacer } from './DropDownTableOpenReplacer'
import { DropDownTableRadio } from './DropDownTableRadio'
import { DropDownTableRow } from './DropDownTableRow'
import { DropDownTableRowColumn } from './DropDownTableRowColumn'
import { DropDownTableSecondaryRow } from './DropDownTableSecondaryRow'
import { DropDownTableSecondaryRowContainer } from './DropDownTableSecondaryRowContainer'
import { DropDownTableSecondaryRowColumn } from './DropDownTableSecondaryRowColumn'
import { DropDownTableWarning } from './DropDownTableWarning'
export * from './styles/useDropDownTableStyles'

const DropDownTable = {
  Button: DropDownTableButton,
  ButtonLink: DropDownTableButtonLink,
  Column: DropDownTableColumn,
  HeadersRow: DropDownTableHeadersRow,
  HideButton: DropDownTableHideButton,
  Label: DropDownTableLabel,
  Link: DropDownTableLink,
  MainRow: DropDownTableMainRow,
  OpenButton: DropDownTableOpenButton,
  OpenReplacer: DropDownTableOpenReplacer,
  Radio: DropDownTableRadio,
  Row: DropDownTableRow,
  RowColumn: DropDownTableRowColumn,
  SecondaryRow: DropDownTableSecondaryRow,
  SecondaryRowContainer: DropDownTableSecondaryRowContainer,
  SecondaryRowColumn: DropDownTableSecondaryRowColumn,
  Warning: DropDownTableWarning,
}

export {
  DropDownTable
}
