import * as React from 'react'
import { Link } from 'react-router-dom'
import { EventTypeEnum } from '../../../models/tournament-models'
import { TournamentStatus, TournamentStatusEnum } from '../../../consts/TournamentStatusId'
import TournamentDateCounter from './_shared/TournamentDateCounter'

import { TournamentModeName } from 'consts/TournamentModeId'
import { TournamentStructure } from 'consts/TournamentStructure'
import { VenueTypeEnum } from 'consts/VenueType'
import { Participants } from './_shared/Participants'
import QualifierTournamentsModal from './QualifierTournamentsModal/QualifierTournamentsModal'
import { TournamentUtils } from 'server/utils/tournament-utils'
import TournamentPlatforms from './_shared/TournamentPlatforms'
import { useSelector } from 'react-redux'
import { getIsAdmin } from 'store/types/user-types'
import { ContainerName, useAppUser, useDialogWithParameter, useFormatDateTime, useIsAdminRoute, useThemeStyles } from 'components/_hooks'
import { TournamentViewModel_kl } from 'models/tournament-viewmodel'
import TournamentFlashingBanner, { TournamentBannerDisplayFlag } from './_shared/TournamentFlashingBanner'
import { useTournamentOrganizer } from 'components/_hooks/useTournamentOrganizer'
import { cn } from 'utils'

import { ApplicationState } from 'store/types/common'
import { getTournament } from 'store/selectors/tournaments-selectors'
import { makeGetConsolesByTournamentDetailId } from 'store/selectors/console-selector'
import { useMemo } from 'react'
import TournamentCardCover from './_shared/TournamentCardCover'
import { useKlHistory } from 'components/_hooks/useKlHistory'

import stylesLight from './TournamentCardLight.module.scss'
import stylesDark from './TournamentCardDark.module.scss'
import TournamentCardButton from './_shared/TournamentCardButton'
import { TabCheckbox } from 'components/_library/Tabs/_shared/TabCheckbox'

interface Props {
  tournamentId: number
  displayBannerOption: TournamentBannerDisplayFlag
  onChangeCaurosel?: (caurosel: boolean, tournamentDetailId: number) => void
  hideCarousel?: boolean
}

interface QualificationTournament {
  name: string
  tournaments: TournamentViewModel_kl[]
}

const getAllQualifierTournaments = async (id: number): Promise<QualificationTournament[]>  => {
  const qualifierData = await TournamentUtils.GetAllQualifierTournamentsByMainTournamentId(id)
  if (!qualifierData.isError) {
    const tournamentIds = qualifierData.data.map(x => x.TournamentId)
    const uniqueTournamentIds = [...new Set(tournamentIds)]
    const qualifierTournaments: QualificationTournament[] = []
    uniqueTournamentIds.map(value => {
      const filtertournament = qualifierData.data.filter(x => x.TournamentId === value)
      if (filtertournament !== undefined && filtertournament.length > 0) {
        const tournament = { name: '', tournaments: [] }
        tournament.name = filtertournament[0].Name
        tournament.tournaments = filtertournament
        qualifierTournaments.push(tournament)
      }
    })
    return qualifierTournaments
  }
  else {
    return null
  }
}


const TournamentCardV2 = ({ tournamentId, displayBannerOption, onChangeCaurosel }: Props) => {

  const styles = useThemeStyles(stylesDark, stylesLight)  

  const appUser = useAppUser()  
  const isAdmin = getIsAdmin(appUser?.Role)  
  const isAdminRoute = useIsAdminRoute()

  const tournament = useSelector((state: ApplicationState) => getTournament(state, tournamentId))

  const organizerName = useTournamentOrganizer(tournament)
  const tournamentDate = useFormatDateTime(tournament.TournamentDetailId, tournament.StartDate, 'redux')
  
  const history = useKlHistory()


  const [quilifierTournamentsModal, openQuilifierTournamentModal ] = useDialogWithParameter<QualificationTournament[]>((data, closeDialog) => 
    <QualifierTournamentsModal onClose={closeDialog} qualifierTournaments={data}  />
  )

  const onMainEventClick = async (event: React.MouseEvent<HTMLAnchorElement>) => {
    const allQualifierTournaments = await getAllQualifierTournaments(tournament.TournamentDetailId)
    if (allQualifierTournaments) {
      if (allQualifierTournaments.length === 1 && allQualifierTournaments[0].tournaments.length === 1) {
        history.push(`/tournament-detail/${allQualifierTournaments[0].tournaments[0].Id}`)
      } else {
        openQuilifierTournamentModal(allQualifierTournaments) 
      }
    }
    event.preventDefault()
  }

  const isGameOrganizer = tournament.OrganizerId && isAdmin && isAdminRoute

  const getTournamentConsoles = useMemo(makeGetConsolesByTournamentDetailId, [])
  const consoles = useSelector((state: ApplicationState) => getTournamentConsoles(state, tournamentId))

  const isShowTimer = tournament.TournamentStatusId == TournamentStatusEnum.Pending || 
    tournament.TournamentStatusId == TournamentStatusEnum.Active && new Date(tournament.StartDate).getTime() > new Date().getTime()


  return (    
    <>
      <section 
        key={tournament.TournamentDetailId} 
        className={cn(styles.game, isGameOrganizer && styles.gameOrganizer)}
      >
        <TournamentFlashingBanner isDraft={false} tournament={tournament}  displayOption={displayBannerOption} />     
        {!isAdminRoute && (
          <>
            { tournament.EventTypeId !== EventTypeEnum.Normal &&
              <div>
                <div className={styles.game__conditionBackground} />
                <div className={styles.game__condition}>
                  {tournament.EventTypeId == EventTypeEnum.Qualifier ? 'Qualifier' : 'Main Event'}
                  <div className={styles.game__conditionContainer}>
                    {tournament.EventTypeId == EventTypeEnum.Qualifier && (
                      <div className={styles.game__conditionContent}>
                        Winner of this tournament will be signed up directly in &nbsp;
                        <Link to={`/tournament-detail/${tournament.MainTournamentId}`}><strong>Main Event </strong></Link>.  
                      </div>
                    )}
                    {tournament.EventTypeId == EventTypeEnum.Main && (
                      <div className={styles.game__conditionContent}>
                        To participate in the tournament, you have to play Qualifier tournament. &nbsp;
                        <a onClick={onMainEventClick} className={styles.qualifierLink}>
                          &nbsp;Click Here&nbsp;
                        </a>
                        to see all qualifier tournaments.
                      </div>
                    )}
                  </div>
                </div>
              </div>
            }        
          </>
        )}
        <div className="container-fluid">
          <TournamentCardCover tournamentName={tournament?.Name} coverId={tournament.CoverId} />
          <div className="row flex-sm-nowrap">
            <div className="col-sm-6 col-12 p-0">
              <h3
                className={styles.game__title}
                style={{ textOverflow: 'ellipsis', whiteSpace: 'normal' }}
                title={tournament.Name}
              >
                {tournament.Name}
                {isAdmin ? `(#T${tournament.TournamentDetailId})` : ''}
              </h3>
            </div>
            {isShowTimer && (
              <TournamentDateCounter startDate={tournament.StartDate} />
            )}
           
          </div>
          <TournamentPlatforms consoles={consoles}/>
          <div 
            className={cn('row', styles.game__tournament, styles.tournament)}
          >
            <div className="col-6 p-r-0">
              <div className="row">
                <div className="col-12">
                  <div className={styles.tournament__header}>Tournament</div>
                  <div
                    className={styles.tournament__info}
                    title={TournamentModeName[tournament.TournamentModeId] + '-' + TournamentStatus[tournament.TournamentStructureId]}
                  >
                    {TournamentModeName[tournament.TournamentModeId]} - {TournamentStructure[tournament.TournamentStructureId]}
                  </div>
                </div>
                <div className="col-sm-auto col-12">
                  <div className={styles.tournament__header}>Date</div>
                  <div className={styles.tournament__info}>{tournamentDate.date}</div>
                </div>
                <div className={cn('col-sm', 'col-12', 'p-l-sm-1', styles.game__time)}>
                  <div className={styles.tournament__header}>Time</div>
                  <div className={styles.tournament__info} title={tournamentDate.time}>
                    {tournamentDate.time}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 p-l-20">
              <div className="row">
                <div className="col-12">
                  <div className={styles.tournament__header}>Organizer</div>
                  <div className={styles.tournament__info}>
                    {organizerName}
                  </div>
                </div>
                <div className="col-sm-auto col-12">
                  <div className={styles.tournament__header}>Venue</div>
                  <div className={styles.tournament__info}>
                    {tournament.VenueTypeId == VenueTypeEnum.Live ? 'Live Event' : 'Online Event'}
                    {tournament.VenueTypeId == VenueTypeEnum.Live && (
                      <span className={styles.tournament__venueInfo}>
                        <div className={styles.tournament__infoContainer}>
                          <div className={styles.tournament__infoContent}>{tournament.VenueLocation}</div>
                        </div>
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-between align-items-center">
            <Participants containerName={ContainerName.AvatarsNormal} tournamentDetailId={tournament.TournamentDetailId} maxNumberOfParticipients={tournament.MaxNumberOfParticipients} />
            <div className="col-sm-6 col-12 p-l-sm-20 m-t-sm-10 p-l-0 p-r-0">
              <TournamentCardButton tournamentId={tournamentId} />              
            </div>
            {isAdmin && isAdminRoute && (
              <TabCheckbox
                id={`caurosel${tournament.TournamentDetailId}`}
                checked={tournament.ShowOnHomePage}
                onChange={checked => {
                  onChangeCaurosel(checked, tournament.TournamentDetailId)
                }}
              >   
                Carousel
              </TabCheckbox>           
            )}
          </div>
        </div>
      </section>
      {quilifierTournamentsModal}    
    </>
  )
}

export default TournamentCardV2
