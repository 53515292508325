import { ContainerName, useUser } from 'components/_hooks'
import { ProfileCard } from 'components/_shared'
import React from 'react'
import { getUserProfileUrl } from 'store/types/user-types'
import { cn } from 'utils'
import styles from 'components/_shared/ProfileCard/ProfileCard.module.scss'


interface Props {
  userId: number,
  containerName?:ContainerName
}

export const FriendCard = ({ userId,containerName }:  Props) => {
  const { user } = useUser(userId)


  return (
    <ProfileCard
      className={cn(styles.card, 'm-2')}
      link={getUserProfileUrl(user.Id)}
      name={user.Username}
      imageId={user.AvatarId}
      label="View profile"
      containerName={containerName}
    />
  )
}


export default FriendCard
