import { Reducer } from 'redux'
import { DISPUTES_LOADED } from 'store/types/dispute-types'
import { APPROVED_MATCH_LINKS_LOADED, PENDING_MATCH_LINKS_LOADED } from 'store/types/match-link-types'
import { MATCHES_SETTINGS_LOADED } from 'store/types/match-settings-types'
import { ACTIVE_MATCHES_LOADED, CHECKIN_MATCHES_LOADED, FINISHED_MATCHES_LOADED, PENDING_MATCHES_LOADED } from 'store/types/match-types'
import { CLEAR_ROUNDS, KnownAction, ROUNDS_LOADED, RoundType, State } from 'store/types/rounds-types'
import { calcById, calcByIdWithoutIds, getNewObjectWithArray, getObjectsWithArrayWithoutIds } from 'utils'

const initialState: State = {
  byId: {},
  idsByTournamentDetailId: {},
  pendingRoundsIds: [],
}

const calcIdsByTournamentDetailId = (state: State, rounds: RoundType[]) => {
  return {
    ...state.idsByTournamentDetailId,
    ...getNewObjectWithArray(state.idsByTournamentDetailId, rounds, x => x.TournamentDetailId, x => x.Id),
  }
}

const calcState = (state: State, rounds: RoundType[]): State => {
  return {
    ...state,
    byId: calcById(state, rounds),
    idsByTournamentDetailId: calcIdsByTournamentDetailId(state, rounds),
  }
}

const reducer: Reducer<State, KnownAction> = (state: State | undefined = initialState, action: KnownAction) => {
  switch (action.type) {
    case ROUNDS_LOADED:
    case DISPUTES_LOADED:
    case APPROVED_MATCH_LINKS_LOADED:
    case PENDING_MATCH_LINKS_LOADED: 
    case CHECKIN_MATCHES_LOADED:
    case ACTIVE_MATCHES_LOADED:
    case FINISHED_MATCHES_LOADED:
    case MATCHES_SETTINGS_LOADED:
      return calcState(state, action.payload.Rounds)
    case PENDING_MATCHES_LOADED: {
      return {
        ...calcState(state, action.payload.Rounds),
        pendingRoundsIds: action.payload.Rounds.map(x => x.Id),
      }
    }
    case CLEAR_ROUNDS:
      return {
        ...state,
        byId: calcByIdWithoutIds(state, action.payload.roundsIds),
        idsByTournamentDetailId: getObjectsWithArrayWithoutIds(state.idsByTournamentDetailId, action.payload.tournamentsIds),
      }
    default:
      return state
  }
}

export default reducer
