import { makeActionCreator, ReduxAction } from './common'

export interface PointsRewardModel {
  Winner: number
  Loser: number
  Extra: number
}

export interface PointsRewardState {
  pointsRewards: PointsRewardModel
}

export const POINTS_REWARDS_LOADED = 'POINTS_REWARDS_LOADED'
export type PointsRewardsLoadedAction = ReduxAction<typeof POINTS_REWARDS_LOADED, PointsRewardModel>
export const pointsRewardsLoaded = makeActionCreator<PointsRewardModel>(POINTS_REWARDS_LOADED)

export const POINTS_REWARDS_UPDATED = 'POINTS_REWARDS_UPDATED'
export type PointsRewardsUpdatedAction = ReduxAction<typeof POINTS_REWARDS_UPDATED, PointsRewardModel>
export const pointsRewardsUpdated = makeActionCreator<PointsRewardModel>(POINTS_REWARDS_UPDATED)

export type KnownAction = PointsRewardsLoadedAction
  | PointsRewardsUpdatedAction
