import { ClanInvitationStatus } from 'consts'
import { ClanType } from './clan-types'
import { makeActionCreator, ReduxAction } from './common'
import { ImageType } from './image-types'

interface ClanInvitationType {
  Id: number
  ClanId: number
  UserId: number
  Status: ClanInvitationStatus
  Date: string
}

export interface ClansInvitationsModel {
  ClansInvitations: ClanInvitationType[]
  Clans: ClanType[]
  Images: ImageType[]
}

export interface ClanInvitationState {
  byId: {
    [key: number]: ClanInvitationType
  }
  idByClanId: {
    [key: number]: number
  }
  ids: number[]
}

export const CLAN_INVITATIONS_LOADED = 'CLAN_INVITATIONS_LOADED'
export type ClanInvitationsLoadedAction = ReduxAction<typeof CLAN_INVITATIONS_LOADED, ClansInvitationsModel>
export const clanInvitationsLoaded = makeActionCreator<ClansInvitationsModel>(CLAN_INVITATIONS_LOADED)

export type KnownAction = ClanInvitationsLoadedAction
