import { Reducer } from 'redux'
import { APPROVED_MATCH_LINKS_LOADED, PENDING_MATCH_LINKS_LOADED } from 'store/types/match-link-types'
import { calcById, calcByIdWithoutIds, getNewObjectWithArray, getObjectsWithArrayWithoutIds } from 'utils'
import { DISPUTES_LOADED } from 'store/types/dispute-types'
import {
  State,
  KnownAction,
  SET_TOURNAMENT_MATCHES,
  FINISHED_MATCHES_LOADED,
  PENDING_MATCHES_LOADED,
  CHECKIN_MATCHES_LOADED,
  ACTIVE_MATCHES_LOADED,
  MatchModel,
  CLEAR_MATCHES,
} from '../types/match-types'
import { MATCHES_SETTINGS_LOADED } from 'store/types/match-settings-types'

export const initialMatchesState: State = {
  byId: {},
  idsByTournamentDetailId: {},
  idsByRoundId: {},
  pendingMatchesIds: [],
  checkInMatchesIds: [],
  activeMatchesIds: [],
  finishedMatchesIds: [],
}

const calcIdsByTournamentDetailId = (state: State, matches: MatchModel[]) => {
  return {
    ...state.idsByTournamentDetailId,
    ...getNewObjectWithArray(state.idsByTournamentDetailId, matches, x => x.TournamentDetailId, x => x.Id),
  }
}

const calcIdsByRoundId = (state: State, matches: MatchModel[]) => {
  return {
    ...state.idsByRoundId,
    ...getNewObjectWithArray(state.idsByRoundId, matches, x => x.RoundId, x => x.Id),
  }
}

const calcState = (state: State, matches: MatchModel[]): State => {
  return {
    ...state,
    byId: calcById(state, matches),
    idsByTournamentDetailId: calcIdsByTournamentDetailId(state, matches),
    idsByRoundId: calcIdsByRoundId(state, matches),
  }
}

const reducer: Reducer<State, KnownAction> = (state: State | undefined = initialMatchesState, action: KnownAction) => {
  switch (action.type) {
    case SET_TOURNAMENT_MATCHES:
    case APPROVED_MATCH_LINKS_LOADED:  
    case PENDING_MATCH_LINKS_LOADED: 
    case DISPUTES_LOADED:
    case MATCHES_SETTINGS_LOADED:
      return calcState(state, action.payload.Matches)
    case PENDING_MATCHES_LOADED:
      return {
        ...calcState(state, action.payload.Matches),
        pendingMatchesIds: action.payload.Matches.map(x => x.Id),
      }
    case CHECKIN_MATCHES_LOADED:
      return {
        ...calcState(state, action.payload.Matches),
        checkInMatchesIds: action.payload.Matches.map(x => x.Id),
      }
    case ACTIVE_MATCHES_LOADED:
      return {
        ...calcState(state, action.payload.Matches),
        activeMatchesIds: action.payload.Matches.map(x => x.Id),
      }
    case FINISHED_MATCHES_LOADED:
      return {
        ...calcState(state, action.payload.Matches),
        finishedMatchesIds: action.payload.Matches.map(x => x.Id),
      }
    case CLEAR_MATCHES:
      return {
        ...state,
        byId: calcByIdWithoutIds(state, action.payload.matchesIds),
        idsByRoundId: getObjectsWithArrayWithoutIds(state.idsByRoundId, action.payload.roundsIds),
        idsByTournamentDetailId: getObjectsWithArrayWithoutIds(state.idsByTournamentDetailId, action.payload.tournamentsIds),
      }
    default:
      return state
  }
}

export default reducer
