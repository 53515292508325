import { ContainerName, useAppUser, useClan, useClanMember, useUser } from 'components/_hooks'
import { usePlayerProfile } from 'components/_hooks/useModels/usePlayerProfile'
import { Button, ButtonLink } from 'components/_shared'
import { ClanMemberRole } from 'consts'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useOpenUserChat } from 'store/chat/chat/chat-hooks'
import { useSendFriendRequest } from 'store/logic/friend-request-logic'
import { getFriendRequestsLoading, makeGetFriendRequestByUserId, makeGetIsFriendRequested } from 'store/selectors/friend-request-selectors'
import { makeGetIsSendFriendRequestLoading, makeGetIsTopFriendsLoading, makeGetTopFriendsIds } from 'store/selectors/user-selectors'
import { ApplicationState, calcWinRate } from 'store/types/common'
import { getIsPlayer, getUserProfileUrl } from 'store/types/user-types'
import { cn } from 'utils'
import { Column, MemberAvatarColumn, MemberNameColumn } from '../Columns'
import styles from '../MemberCard.module.scss'

interface MemberCardProps {
  userId: number
}

export const PlayerCard = ({ userId }: MemberCardProps) => {
  const { user } = useUser(userId)
  const clanMember = useClanMember(userId)
  const { clan } = useClan(clanMember?.ClanId)
  const playerProfile = usePlayerProfile(userId)
  const appUser = useAppUser()
  const openUserChat = useOpenUserChat()

  const getIsFriendRequested = useMemo(makeGetIsFriendRequested, [])
  const getIsSendFriendRequestLoading = useMemo(makeGetIsSendFriendRequestLoading, [])
  const getIsLoggedInUserFriendsLoading = useMemo(makeGetIsTopFriendsLoading, [])
  const getFriendsIds = useMemo(makeGetTopFriendsIds, [])
  const getFriendRequestByUserId = useMemo(makeGetFriendRequestByUserId, [])

  const isFriendRequested = useSelector((state: ApplicationState) => getIsFriendRequested(state, { friendId: userId }))
  const isSendFriendRequestLoading = useSelector((state: ApplicationState) => getIsSendFriendRequestLoading(state, { userId }))
  const isUserFriendsLoading = useSelector((state: ApplicationState) => getIsLoggedInUserFriendsLoading(state, { userId: appUser.Id }))
  const isFriendRequestsLoading = useSelector(getFriendRequestsLoading)
  const friendsIds = useSelector((state: ApplicationState) => getFriendsIds(state, { userId: appUser.Id }))
  const friendRequestByUserId = useSelector((state: ApplicationState) => getFriendRequestByUserId(state, {userId}))

  const sendFriendRequest = useSendFriendRequest(userId)
  const isPlayer = getIsPlayer(appUser.Role)
  const isFriend = useMemo(() => {
    return isPlayer && friendsIds.some(id => id === userId)
  }, [friendsIds, isPlayer, userId])

  const showSendFriendRequestButton = isPlayer && !isFriend
  const isFriendsLoading = isUserFriendsLoading || isFriendRequestsLoading

  const handleSendMessageClick = () => {
    openUserChat(userId)
  }

  const handleSendFriendRequestClick = async () => {
    await sendFriendRequest({
      FromUserId: appUser.Id,
      ToUserId: userId,
    })
  }

  const winRate = calcWinRate(playerProfile)

  return (
    <div className={cn('col-auto', styles.memberContainer)}>
      <div className={styles.member}>
        <div className="row">
          <MemberAvatarColumn containerName={ContainerName.AvatarsNormal} imageId={user.AvatarId} />
          <MemberNameColumn name={user.Username} clanName={clan?.Name} isClanLeader={clanMember?.Role === ClanMemberRole.Leader} id={userId} type="player" />
          <Column className={styles.memberFirstColumn} label="Win Ratio" value={`${winRate}%`} />
          <Column className={styles.memberSecondColumn} label="Match" value={`${playerProfile?.TotalMatches ?? 0} Battles`} />
          <Column className={styles.memberThirdColumn} label="EXP Points" value={`${playerProfile?.TotalExperience ?? 0} Points`} />
          <div className={cn('col-12', styles.memberButtons)}>
            <div className="row justify-content-center flex-nowrap">
              <ButtonLink to={getUserProfileUrl(userId)} color="grey" filled hoverColor="greenHover" className={cn(styles.memberButton, styles.left)}>
                View profile
              </ButtonLink>
              {isFriendsLoading
                ? <ButtonLink preventDefault to="#" loading={isFriendsLoading} color="grey" filled className={styles.memberButton}>{' '}</ButtonLink>
                : (
                  <>
                    {showSendFriendRequestButton
                      ? (
                        <Button disabled={isFriendRequested || !!friendRequestByUserId} loading={isSendFriendRequestLoading} onClick={handleSendFriendRequestClick} color="grey" filled className={styles.memberButton}>
                          {(isFriendRequested || !!friendRequestByUserId) ? 'Friend Request Sent' : 'Send Friend Request'}
                        </Button>
                      )
                      : (
                        <Button onClick={handleSendMessageClick} color="grey" filled className={styles.memberButton}>
                          Send message
                        </Button>
                      )
                    }
                  </>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
