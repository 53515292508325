import { SimpleTextInput } from 'components/_shared'
import React from 'react'
import styles from '../SignUp.module.scss'

interface SignUpTextInputProps {
  value?: string
  onChange?: (value: string) => void
  placeholder?: string
  withClear?: boolean
  title: string
}

export const SignUpTextInput = ({ value, onChange, placeholder, withClear, title }: SignUpTextInputProps) => {
  return (
    <>
      <div className={styles.signUpInputTitle}>{title}</div>
      <SimpleTextInput
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        withClear={withClear}
        className={styles.signUpContainer}
        inputClassName={styles.signUpDefaultInput}
        clearClassName={styles.signUpCleanButton}
      />
    </>
  )
}
