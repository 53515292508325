import React, { useEffect, useMemo, useState } from 'react'
import { TournamentUtils } from 'server/utils/tournament-utils'
import { TournamentViewModel_kl } from 'models/tournament-viewmodel'
import styles from './Slider.module.scss'
import { cn } from 'utils'
import SliderImage from './SliderImage'
import { ContainerName, useClientRect } from 'components/_hooks'
import SliderTournamentInfo from './SliderTournamentInfo'


export const Slider = () => {
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const [tournament, setTournament] = useState<TournamentViewModel_kl>(Object())
  const [tournaments, setTournaments] = useState<TournamentViewModel_kl[]>([])

  const onChangeEvents = (index: number) => {
    if (index < 0) {
      index = 0
    }
    if (index > tournaments.length - 1) {
      index = tournaments.length - 1
    }
    if (index >= 0 && index < tournaments.length) {
      if (tournaments && tournaments.length > 0) {
        setTournament(tournaments[index])
        setActiveIndex(index)
      }
    }
  }

  useEffect(() => {
    const loadTournaments = async () => {
      const result = await TournamentUtils.GetTournamentsForHomePageSlider()
      if (!result.isError) {
        setTournaments(result.data)
        setTournament(result.data ? result.data[0] : Object())
      }
    }
    void loadTournaments()
  }, [])

  const [rect, ref] = useClientRect<HTMLDivElement>()
  const width = useMemo(() => {
    return rect?.width 
  }, [rect?.width])

  const leftPosition = useMemo(() => {
    return -activeIndex * width
  }, [activeIndex, width])

  return (
    <>
      {tournament && tournament.Id && tournament.StartDate && (
        <div className={styles.sldrContainer}>
          <div className={styles.sldr}>
            <div className={styles.sldrL}  ref={ref}>
              <div className={styles.sldrLInn}>
                <div id="sldr-nav" className={cn(styles.sldrNav, styles.slickInitialized, styles.slickSlider)}>
                  <div className={styles.slickList}>
                    <div
                      className={styles.slickTrack}
                      style={{opacity: '1', width: `${width*(tournaments.length+1)}px`, transform: `translate3d(${leftPosition}px, 0px, 0px)`}}
                    >
                      {tournaments.map((t, index) => (
                        <SliderImage containerName={ContainerName.TournamentSlider} key={t.Id} coverId={t.CoverId} index={index} activeIndex={activeIndex} width={width} />
                      ))}                     
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.sldrR}>
              <div className={styles.sldrRInn}>
                <div id="sldr-car" className={cn(styles.sldrCar, styles.slickInitialized, styles.slickSlider, styles.slickDotted)}>
                  <button
                    className={cn(styles.slickPrev, styles.slickArrow, styles.slickDisabled)}
                    aria-label="Previous"
                    type="button"
                    aria-disabled="true"
                    onClick={() => {
                      onChangeEvents(activeIndex - 1)
                    }}
                  >
                    Previous
                  </button>
                  <div className={cn(styles.slickList, styles.draggable)}>
                    <div className={styles.slickTrack} style={{ opacity: 1 }}>
                      {tournament && (
                        <SliderTournamentInfo tournament={tournament} />
                      )}
                    </div>
                  </div>
                  <button
                    className={cn(styles.slickNext, styles.slickArrow)}
                    aria-label="Next"
                    type="button"
                    aria-disabled="false"
                    onClick={() => {
                      onChangeEvents(activeIndex + 1)
                    }}
                  >
                    Next
                  </button>
                  <ul className={styles.slickDots} role="tablist">
                    {tournaments.map((tour, ind) =>{
                      return (
                        <li key={ind} className={activeIndex ==  ind ? styles.slickActive : ''} role="presentation">
                          <button
                            onClick={() => {
                              onChangeEvents(ind)
                            }}
                            type="button"
                            role="tab"
                            id={`slick-slide-control0${ind}`}
                            aria-controls={`slick-slide0${ind}`}
                            aria-label={`${ind+1} of 3`}
                            tabIndex={activeIndex}
                            aria-selected="true"
                          >
                            {ind+1}
                          </button>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
