import { Dispatch } from 'redux'
import { GetState, PaginationModel } from 'store/types/common'
import { myOrganizerActiveTournamentsLoaded, myOrganizerCanceledTournamentsLoaded, myOrganizerFinishedTournamentsLoaded, myOrganizerUpcomingTournamentsLoaded } from 'store/types/pages/organizer-events-page-types'
import { OperationTypes, run } from '../operations-logic'
import { getFilteredTournaments, TournamentFilters } from '../tournaments-logic'


export const loadMyOrganizerUpcomingTournaments = (filters: TournamentFilters, pagination: PaginationModel) => async (dispatch: Dispatch, getState: GetState) => {
  await run(OperationTypes.loadMyOrganizerUpcomingTournaments, null, dispatch, getState, async () => {
    try {
      const result = await getFilteredTournaments(filters, pagination)
      dispatch(myOrganizerUpcomingTournamentsLoaded({
        tournaments: result.Tournaments,
        allMyOrganizerUpcomingTournamentsCount: result.AllTournamentsCount,
        TournamentConsoles: result.TournamentConsoles,
        Images: result.Images,
      }))
    } catch (error) {
      return false
    }
    return true
  })
}

export const loadMyOrganizerActiveTournaments = (filters: TournamentFilters, pagination: PaginationModel) => async (dispatch: Dispatch, getState: GetState) => {
  await run(OperationTypes.loadMyOrganizerActiveTournaments, null, dispatch, getState, async () => {
    try {
      const result = await getFilteredTournaments(filters, pagination)
      dispatch(myOrganizerActiveTournamentsLoaded({
        tournaments: result.Tournaments,
        allMyOrganizerActiveTournamentsCount: result.AllTournamentsCount,
        TournamentConsoles: result.TournamentConsoles,
        Images: result.Images,
      }))
    } catch (error) {
      return false
    }
    return true
  })
}

export const loadMyOrganizerFinishedTournaments = (filters: TournamentFilters, pagination: PaginationModel) => async (dispatch: Dispatch, getState: GetState) => {
  await run(OperationTypes.loadMyOrganizerFinishedTournaments, null, dispatch, getState, async () => {
    try {
      const result = await getFilteredTournaments(filters, pagination)
      dispatch(myOrganizerFinishedTournamentsLoaded({
        tournaments: result.Tournaments,
        allMyOrganizerFinishedTournamentsCount: result.AllTournamentsCount,
        TournamentConsoles: result.TournamentConsoles,
        Images: result.Images,
      }))
    } catch (error) {
      return false
    }
    return true
  })
}

export const loadMyOrganizerCanceledTournaments = (filters: TournamentFilters, pagination: PaginationModel) => async (dispatch: Dispatch, getState: GetState) => {
  await run(OperationTypes.loadMyOrganizerCanceledTournaments, null, dispatch, getState, async () => {
    try {
      const result = await getFilteredTournaments(filters, pagination)
      dispatch(myOrganizerCanceledTournamentsLoaded({
        tournaments: result.Tournaments,
        allMyOrganizerCanceledTournamentsCount: result.AllTournamentsCount,
        TournamentConsoles: result.TournamentConsoles,
        Images: result.Images,
      }))
    } catch (error) {
      return false
    }
    return true
  })
}
