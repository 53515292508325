import React, { useEffect } from 'react'
import { MatchLinkModel, MatchLinkStatusEnum } from 'store/types/match-link-types'
import { ConfirmActionModal, DropDownTable, ParticipantAvatar, LoadingBox } from 'components/_shared'
import styles from './events.module.scss'

import { makeGetMatch } from 'store/selectors/match-selectors'
import { ApplicationState } from 'store/types/common'
import { useDispatch, useSelector } from 'react-redux'
import { makeGetRound } from 'store/selectors/rounds-selectors'
import { makeGetTournament } from 'store/selectors/tournaments-selectors'
import { TournamentModeName, TournamentStructure,  VenueType } from 'consts'
import { useDeleteMatchLink, usePublishMatchLink } from 'store/logic/match-links-logic'
import { participantsActionCreators } from 'store/logic/participants-logic'
import { makeGetIsParticipantLoading } from 'store/selectors/participant-selectors'
import { ContainerName, useDialogWithParameter, useFormatDateTime, useTournamentParticipant } from 'components/_hooks'
import { useScrollToElement } from 'components/_hooks/useComponents/useScrollToElement'
import { getRoundTitle } from 'store/types/rounds-types'

interface Props {
  matchLink: MatchLinkModel
  onChangeCount?: () => void
  highlighted?: boolean
  removeHighlighted: () => void
}

const LinkRow = ({matchLink, onChangeCount, highlighted, removeHighlighted}: Props) => {

  const [opened, setOpened] = React.useState(false)
  const dispatch = useDispatch()

  const getMatch = React.useMemo(makeGetMatch, [])
  const match = useSelector((state: ApplicationState) => getMatch(state, matchLink.MatchId))

  const getRound = React.useMemo(makeGetRound, [])
  const round = useSelector((state: ApplicationState) => match ? getRound(state, match.RoundId) : null)

  const getTournament = React.useMemo(makeGetTournament, [])
  const tournament = useSelector((state: ApplicationState) => round ? getTournament(state, round.TournamentDetailId) : null)

  useEffect(() => {
    if (tournament.Id && opened) {
      dispatch(participantsActionCreators.loadParticpant(tournament.Id, matchLink.TournamentParticipantId))
    }
  }, [dispatch, matchLink.TournamentParticipantId, opened, tournament.Id])

  const formatedDate = useFormatDateTime(tournament?.TournamentDetailId, round?.PlayDateTime, 'redux')
 
  const { name, profileUrl } = useTournamentParticipant(matchLink.TournamentParticipantId)

  const getIsParticipantLoading = React.useMemo(makeGetIsParticipantLoading, [])
  const isTournamentParticipantLoading =  useSelector((state: ApplicationState) => getIsParticipantLoading(state, matchLink.TournamentParticipantId))

  const ref = useScrollToElement(highlighted, isTournamentParticipantLoading ? 'auto' : 'smooth')

  const publishMatchLink = usePublishMatchLink(matchLink.Id)
  const deleteMatchLink = useDeleteMatchLink(matchLink.Id)

  const handleOpenButtonClick = () => setOpened(!opened)

  const handlePublishButtonClick = () => {
    openPublishLinkModal(matchLink)  
  }
  const handleDeleteButtonClick = (event: React.MouseEvent) => {
    event.preventDefault()
    openDeleteLinkModal(matchLink)  
  }

  const tournamentStructure = tournament?.TournamentStructureId ? TournamentStructure[tournament.TournamentStructureId] : ''
  const tournamentVenue = tournament?.VenueTypeId ? VenueType[tournament.VenueTypeId] : ''
  const tournamentMode = tournament?.TournamentModeId ? TournamentModeName[tournament.TournamentModeId] : ''

  const confirmDeleteHandler = async () => {    
    await deleteMatchLink(matchLink.Id)
    if (onChangeCount) {
      onChangeCount()
    }
    return true
  }

  const confirmPublishHandler = async () => {    
    await publishMatchLink(matchLink.Id)
    if (onChangeCount) {
      onChangeCount()
    }
    return true
  }

  const [deleteLinkModal, openDeleteLinkModal] = useDialogWithParameter<MatchLinkModel>((matchLink, closeDialog) => (
    <ConfirmActionModal onConfirm={confirmDeleteHandler} onClose={closeDialog} text={'Do you want to delete the link?'} buttonText="delete link" />
  ) )   

  const [publishLinkModal, openPublishLinkModal] = useDialogWithParameter<MatchLinkModel>((matchLink, closeDialog) => (
    <ConfirmActionModal onConfirm={confirmPublishHandler} onClose={closeDialog} text={'Do you want to publish the link?'} buttonText="publish link" />
  ) )   

  return (
    <DropDownTable.Row ref={ref}  onMouseEnter={highlighted ? removeHighlighted : undefined}>
      <DropDownTable.MainRow opened={opened} highlighted={highlighted}>
        <DropDownTable.RowColumn className={styles.table__column__tournament}>{tournament?.Name}</DropDownTable.RowColumn>
        <DropDownTable.RowColumn className={styles.table__column__date}>{formatedDate.dateTime}</DropDownTable.RowColumn>
        <DropDownTable.RowColumn className={styles.table__column__round}>{getRoundTitle(round, tournament?.TournamentStructureId)}</DropDownTable.RowColumn>
        <DropDownTable.RowColumn className={styles.table__column__match}>{match.MatchNumber}</DropDownTable.RowColumn>
        <DropDownTable.RowColumn className={styles.table__column__venue}>{tournamentVenue}</DropDownTable.RowColumn>
        <DropDownTable.RowColumn className={styles.table__column__brackets}>{tournamentStructure}</DropDownTable.RowColumn>      
        <DropDownTable.RowColumn className={styles.table__column__type}>{tournamentMode}</DropDownTable.RowColumn>
        <DropDownTable.RowColumn className={styles.table__column__link}>{matchLink.Link}</DropDownTable.RowColumn>
        <DropDownTable.RowColumn className={styles.table__column__buttons}>
          <div className="row justify-content-sm-end m-0">
            {matchLink.Status == MatchLinkStatusEnum.Pending && (
              <DropDownTable.Button onClick={handlePublishButtonClick} color="blue" filled>Publish</DropDownTable.Button>  
            )}
            <a className={styles.table__delete} onClick={handleDeleteButtonClick} href="#" />
            <DropDownTable.OpenButton onClick={handleOpenButtonClick} opened={opened} className={styles.tableOpen} /> 
          </div>
        </DropDownTable.RowColumn>
      </DropDownTable.MainRow>
      <DropDownTable.SecondaryRowContainer opened={opened} className={styles.tableSecondaryRowContainer}>
        <LoadingBox size="average" loading={isTournamentParticipantLoading}>
          <DropDownTable.SecondaryRow>
            <DropDownTable.SecondaryRowColumn className={styles.table__column__user}>
              <ParticipantAvatar containerName={ContainerName.AvatarsNormal} participantId={matchLink.TournamentParticipantId} className={styles.table__profilePicture} withLink />
              <DropDownTable.Link to={profileUrl}>
                {name}
              </DropDownTable.Link> 
            </DropDownTable.SecondaryRowColumn>
            <DropDownTable.Column className={styles.table__column__type} />
            <DropDownTable.Column className={styles.table__column__link} />
            <DropDownTable.Column className={styles.table__column__buttons} />
          </DropDownTable.SecondaryRow>
        </LoadingBox>
      </DropDownTable.SecondaryRowContainer>
      {deleteLinkModal}     
      {publishLinkModal} 
    </DropDownTable.Row>
  )
}

export default LinkRow
