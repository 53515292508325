import { useThemeStyles } from 'components/_hooks'
import React, { ReactNode } from 'react'
import { cn } from 'utils'
import stylesCommon from './RadioInput.module.scss'
import stylesDark from './RadioInputDark.module.scss'
import stylesLight from './RadioInputLight.module.scss'

export interface RadioInputProps {
  name: string
  value: string
  checked: boolean
  label: string
  withDot?: boolean
  withCheck?: boolean
  isBlock?: boolean
  readOnly?: boolean
  valid?: boolean
  blockedText?: string
  labelClassName?: string
  inputClassName?: string
  spanClassName?: string
  children?: ReactNode
  checkClassName?: string
  labelTextClassName?: string
  onChange: (value: string) => void
}

export const RadioInput = ({
  name, 
  value, 
  checked, 
  label, 
  onChange, 
  labelClassName, 
  inputClassName, 
  spanClassName,
  checkClassName,
  labelTextClassName,
  withCheck,
  withDot,
  isBlock,
  blockedText: blockText,
  readOnly,
  children,
  valid = true }: RadioInputProps) => {

  const styles = useThemeStyles(stylesDark, stylesLight)
  
  return (
    <>
      <SimpleRadioInput
        readOnly={readOnly}
        withCheck={withCheck}
        withDot={withDot}
        checked={checked}
        label={label}
        name={name}
        onChange={onChange}
        value={value}
        labelClassName={cn(labelClassName, styles.label, stylesCommon.label)}
        inputClassName={cn(inputClassName, styles.input, stylesCommon.input)}
        spanClassName={cn(spanClassName, styles.span, stylesCommon.span)}
        checkClassName={cn(checkClassName, styles.check)}
        labelTextClassName={cn(labelTextClassName, styles.labelText)}
        isBlock={isBlock}
        blockedText={blockText}
        valid={valid}
      >
        {children}
      </SimpleRadioInput>
    </>
  )
}

export const SimpleRadioInput = ({ 
  name, 
  value, 
  checked, 
  label, 
  onChange, 
  labelClassName, 
  inputClassName, 
  spanClassName, 
  withCheck,
  withDot,
  checkClassName,
  labelTextClassName,
  isBlock,
  blockedText,
  readOnly,
  children,
  valid,
}:  RadioInputProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.currentTarget.value)
  }

  const id = `${name}_${value}`

  return (
    <>
      <input
        id={id}
        checked={checked}
        value={value}
        type="radio"
        name={name}
        className={inputClassName}
        onChange={handleChange}
        readOnly={readOnly}
        disabled={isBlock}
      />
      <label htmlFor={id} className={cn(children ? 'flex-column' : '', labelClassName, !valid && !isBlock && stylesCommon.error)}>
        { children }
        { !withCheck && withDot && <span className={spanClassName}></span> }
        <span className={labelTextClassName}>{label}</span>
        { withCheck && !isBlock && <span className={checkClassName}></span>}
        { isBlock && 
          <span className={stylesCommon.block}>
            {!!blockedText && 
              <span className={stylesCommon.container}>
                <span className={stylesCommon.content}>{blockedText}</span>
              </span>
            }
          </span>
        }
      </label>
    </>
  )
}
