import { createSelector } from 'reselect'
import { OperationTypes } from 'store/logic/operations-logic'
import { ApplicationState } from '../types/common'
import { makeGetIsOperationWithTypeSuccessOrRunning } from './operations-selectors'


const getReadBlogsOperationType = createSelector(
  (_: ApplicationState, { userId }: { userId: number }) => userId,
  userId => ({ type: OperationTypes.readBlogs, objectId: userId })
)
export const makeGetIsReadBlogsRequested = () => makeGetIsOperationWithTypeSuccessOrRunning(getReadBlogsOperationType)
