import React from 'react'
import { Link } from 'react-router-dom'
import styles from '../auth/Login.module.scss'

interface Props {
  section?: string
}

class PrivacyPolicy extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
  }

  componentDidMount() {
    if (this.props.section && document.getElementById(this.props.section)) {
      document.getElementById(this.props.section).scrollIntoView({ block: 'center', behavior: 'smooth' })
    }
  }

  render() {
    return (
      <>
        <div className={styles.background} />
        <div className="container-fluid">
          <div className="row justify-content-end back-container">
            <Link to="/" className="back">
              Back
            </Link>
          </div>
        </div>
        <article className="d-flex flex-row align-items-center">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1 className={styles.signIn__header}>Privacy Policy</h1>
                <h2 className={styles.signIn__subheader}>Last Modified on June 1, 2020</h2>
                <br />
                <div className="additional-info mb-5">
                  <p>
                    Please read this privacy policy carefully, as it contains important information on who we are, how and why we collect, store, use,
                    and share personal information, your rights in relation to your personal information and on how to contact us and supervisory
                    authorities if you have a complaint.
                  </p>

                  <h3 className="subheader">1. Who we are</h3>
                  <p>
                    The Song Group Inc. (“Kombatlink,” “We,” or “Us”) collects, uses, and is responsible for certain personal information about you,
                    as part the Kombatlink service (the “Service”).
                  </p>
                  <p>
                    For those in the European Union, we are regulated under the General Data Protection Regulation which applies across the European
                    Union (including in the United Kingdom) and we are responsible as controller of that personal information for the purposes of
                    those laws.
                  </p>

                  <h3 className="subheader" id="user-data-usage-policy">
                    2. The Personal Information we collect and use
                  </h3>
                  <p>
                    <strong>
                      <span className="subheader">Information collected by us:</span>
                    </strong>{' '}
                    In providing the Service, we collect the following personal information either automatically or when you voluntarily provide it to
                    us:
                  </p>
                  <ul>
                    <li>
                      Your name and email address are collected when you sign up for an account, sign up for our newsletter, contact us about a
                      Service-related issue or support ticket, fill out a survey or send feedback, or enter a contest or sweepstakes on the Service
                      (your “Contact Information”). The legal basis for collecting this information is to fulfill a contract with you, under GDPR Art.
                      6 (1) (b).
                    </li>
                    <li>
                      Your name, username, age, postal address, ethnicity, birthdate, marital status, gender, password, phone number, location,
                      photograph, social media information, hobbies and interests are collected when you sign up for an account on the Service, or
                      connect a Google, Facebook, or other sign-in account with the Service (your “Account Information”). Creating an account is
                      required to use the Service. The legal basis for collecting this information is to fulfill a contract with you, under GDPR Art.
                      6 (1) (b).
                    </li>
                    <li>
                      Certain billing information, such as your credit card or bank account number, is collected when make a purchase on the Service
                      (your “Payment Information”). The legal basis for collecting this information is to fulfill a contract with you, under GDPR Art.
                      6 (1) (b).
                    </li>
                  </ul>
                  <p>
                    <strong>
                      <span className="subheader">How we use your Personal Information:</span>
                    </strong>{' '}
                    We use your personal information as follows:
                  </p>
                  <ul>
                    <li>
                      <strong>Newsletters:</strong> Kombatlink may use your Contact Information or Account Information to send you commercial or
                      marketing messages, including newsletters and email communications about our products or services.
                    </li>
                    <li>
                      <strong>Technical Support:</strong> Kombatlink uses Contact Information, Account Information, and any information you provide us
                      about your device or yourself, as needed, to provide you with technical support for the Service.
                    </li>
                    <li>
                      <strong>Contests, Surveys, and Feedback:</strong> Kombatlink may offer you the chance to participate in contests, surveys, and
                      other Service feedback. We may use your Contact Information to receive and process any contests, surveys, and feedback that you
                      and other users choose to participate in, in order to improve the Service.
                    </li>
                    <li>
                      <strong>Purchases:</strong> Kombatlink may offer the ability to make purchases of virtual items and currencies, tickets, and
                      other items on the Service. We may use your Purchase information to process payments for these purchases.
                    </li>
                    <li>
                      <strong>Accounts:</strong> Kombatlink will use your Contact Information and Account Information to create your account on the
                      Service. Additionally, certain Account Information will be used to construct your public profile, which will be viewable by
                      other users of the Service.
                    </li>
                    <li>
                      <strong>Business Analytics:</strong> Kombatlink may use certain Account Information and other usage information to analyze user
                      account creation, interaction between users, and user demographics in order to improve the functionality of the Service.
                    </li>
                  </ul>
                  <p>
                    <strong>
                      <span className="subheader">Who we share your Personal Information with:</span>
                    </strong>{' '}
                    We share your Personal Information with certain third parties as part of our operation of the Service. This data sharing enables
                    us to provide you with the Service in the optimal way.
                  </p>
                  <p>
                    Some of those third-party recipients may be based outside the European Economic Area — for further information, including how we
                    safeguard your personal data when this occurs, see their individual privacy policies linked to above and the section entitled
                    “Transfer of your Information out of the EEA,” below.
                  </p>
                  <p>We share your Personal Information with the following third parties:</p>
                  <ul>
                    <li>
                      <strong>Organizers:</strong> If you purchase an event ticket on the Service, we may share your Contact Information or Account
                      Information with the Organizer of that event.
                    </li>
                    <li>
                      <strong>Other Sharing:</strong> We will share your personal information if we have a good faith belief that (i) access, use,
                      preservation or disclosure of such information is reasonably necessary to satisfy any applicable law, regulation, legal process,
                      such as a court order or subpoena, or a request by law enforcement or governmental authorities, (ii) such action is necessary to
                      detect, prevent, or otherwise address fraud, security or technical issues associated with the Service, or (iii) such action is
                      appropriate to protect Kombatlink’s or its employees’, clients’, or users’ rights, property, or safety.
                      <br />
                      We will not share your personal information with any other third party.
                    </li>
                  </ul>
                  <p>
                    <strong>
                      <span className="subheader">How long your Personal Information will be kept:</span>
                    </strong>{' '}
                    We will keep your Personal Information for the length of time required to provide you with the Service, unless a longer retention
                    period is required or permitted by law. Afterwards, we delete all aforementioned data in our possession within a reasonable
                    timeframe. We do not verify the correctness of personal data that we collect or you provide.
                  </p>
                  <p>
                    Please note that some data may be retained, if necessary to resolve disputes, enforce Kombatlink user agreements, and comply with
                    technical and legal requirements and constraints related to the security, integrity, and operation of the Service.
                  </p>
                  <p>
                    <strong>
                      <span className="subheader">Children’s Privacy:</span>
                    </strong>{' '}
                    We do not knowingly collect any personal information from children under the age of 13, nor do we allow them to create accounts,
                    sign up for newsletters, make purchases, or browse the Service. In addition, we may limit how we collect, use, and store some of
                    the information of EU users between 13 and 16. Kombatlink takes children’s privacy seriously and encourages parents to play an
                    active role in their children’s online experience at all times. We urge parents to instruct their children never to give out their
                    real names, addresses, or phone numbers, without parental permission, when online. If you have any concerns about your child’s
                    personal information, please contact us at{' '}
                    <a href="mailto:support@kombatlink.com" target="_system">
                      support@kombatlink.com
                    </a>
                    .
                  </p>
                  <p>
                    <strong>
                      <span className="subheader">“Do Not Track” Signals:</span>
                    </strong>{' '}
                    Because there’s not yet a consensus on how companies should respond to web browser-based or other “do not track” mechanisms yet,
                    we do not respond to web browser-based do not track signals.
                  </p>
                  <p>
                    <strong>
                      <span className="subheader">Cookies:</span>
                    </strong>{' '}
                    “Cookies” are small bits of electronic information that a website sends to a visitor’s browser and are stored on the visitor’s
                    hard drive. Like many services, we employ cookies in certain areas of the Service to allow us to provide information to make your
                    online experience more convenient and to enhance your experience with the Service.
                  </p>
                  <p>
                    For example, when you return to the Service after logging in, cookies provide information so that the Service will remember who
                    you are on subsequent visits, speeding up or enhancing your experience of the Service by, for instance, tailoring content to you.
                    Cookies are not spyware or adware, and can’t deliver viruses or run programs on your computer. You may configure your web browsers
                    to accept or reject or delete the cookies, or notify the user when a cookie is being set.
                  </p>
                  <p>
                    <strong>
                      <span className="subheader">Google Analytics:</span>
                    </strong>{' '}
                    We use Google Analytics to gather and pool certain information with other users’ information, for the purposes of improving the
                    Service, such as tracking the total number of users or visitors to the Service, their aggregate activities on the Service, and
                    tracking the sites that refer visitors to the Service. Google anonymizes this information. You can{' '}
                    <a href="https://policies.google.com/privacy?hl=en" target="_blank" rel="noreferrer">
                      view the Google privacy policy here
                    </a>
                    .
                  </p>

                  <h3 className="subheader">3. Transfer of your Information out of the EEA</h3>
                  <p>
                    Kombatlink is based in Canada. No matter where you are located, you consent to the processing, transfer and storage of your
                    information in and to Canada and the U.S., in accordance with the privacy policies of third parties with whom we share your
                    Personal Information. The laws of Canada and the U.S. governing data collection and use may not be as comprehensive or protective
                    as the laws of the country where you live.
                  </p>
                  <p>If you would like further information, please contact us (see “How to contact us” below).</p>

                  <h3 className="subheader">4. EU Residents - Your Rights</h3>
                  <p>
                    Under the laws of some jurisdictions, including the General Data Protection Regulation in the EEA, you have a number of important
                    rights with regard to your Personal Information.
                  </p>
                  <ul>
                    <li>
                      By law, you can ask us what information we hold about you, and you can ask us to correct it if it is inaccurate. If we have
                      asked for your consent to process your personal data, you may withdraw that consent at any time.
                    </li>
                    <li>
                      If we are processing your personal data for reasons of consent or to fulfill a contract, you can ask us to give you a copy of
                      the information in a machine-readable format so that you can transfer it to another provider.
                    </li>
                    <li>
                      If we are processing your personal data for reasons of consent or legitimate interest, you can request that your data be erased.
                    </li>
                    <li>
                      You have the right to ask us to stop using your information for a period of time, if you believe we are not doing so lawfully.
                    </li>
                    <li>
                      Finally, in some circumstances, you can ask us not to reach decisions affecting you using automated processing or profiling.
                    </li>
                  </ul>
                  <p>
                    If you would like to exercise any of those rights, please email us at{' '}
                    <a href="mailto:support@kombatlink.com" target="_system">
                      support@kombatlink.com
                    </a>
                    . We may ask for additional verification information, such as your username and other information required to be sure that you are
                    the owner of that data.
                  </p>

                  <h3 className="subheader">5. California Residents – Your Rights</h3>
                  <p>
                    If you are a California resident, beginning on January 1, 2020, the California Consumer Privacy Act (CCPA) gives you the following
                    rights:
                  </p>
                  <p>
                    <strong>
                      <span className="subheader">Right to Know:</span>
                    </strong>{' '}
                    You can ask us what personal data we hold about you and request a copy of the information. This information includes:
                  </p>
                  <ul>
                    <li>The categories of personal information we have collected about you.</li>
                    <li>The categories of sources from which we collect the personal information.</li>
                    <li>The business or commercial purpose for collecting your personal information.</li>
                    <li>The categories of third parties with whom we share that information.</li>
                    <li>The specific pieces of personal information we have collected about you.</li>
                  </ul>
                  <p>
                    <strong>
                      <span className="subheader">Right to Delete:</span>
                    </strong>{' '}
                    You can request that your personal information be erased. However, there are some exceptions to this right, in situations where
                    we:
                  </p>
                  <ul>
                    <li>
                      Need to complete the transaction for which the personal information was collected, provide a good or service that you requested,
                      or that is reasonably anticipated within our ongoing business relationship with the consumer, or to otherwise perform a contract
                      between us.
                    </li>
                    <li>
                      Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or prosecute those responsible
                      for that activity.
                    </li>
                    <li>Debug to identify and repair errors that impair existing intended functionality.</li>
                    <li>
                      Exercise free speech, ensure the right of another consumer to exercise their right of free speech, or exercise another right
                      provided for by law.
                    </li>
                    <li>
                      Need to comply with the California Electronic Communications Privacy Act pursuant to Chapter 3.6 (commencing with Section 1546)
                      of Title 12 of Part 2 of the Penal Code.
                    </li>
                    <li>
                      Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all
                      other applicable ethics and privacy laws, when the businesses’ deletion of the information is likely to render impossible or
                      seriously impair the achievement of such research, if the consumer has provided informed consent.
                    </li>
                    <li>
                      Enable solely internal uses that are reasonably aligned with the expectations of the consumer based on the consumer’s
                      relationship with us.
                    </li>
                    <li>Need to comply with a legal obligation.</li>
                    <li>
                      Otherwise use your personal information, internally, in a lawful manner that is compatible with the context in which you
                      provided the information.
                    </li>
                  </ul>
                  <p>
                    <strong>
                      <span className="subheader">Right to Opt Out:</span>
                    </strong>{' '}
                    Kombatlink does not sell any of your personal information for any purposes.
                  </p>
                  <p>
                    <strong>
                      <span className="subheader">Other Rights:</span>
                    </strong>{' '}
                    In addition to the rights above, you also have the right to request certain information about our disclosure of personal
                    information to third parties for their own direct marketing purposes during the calendar year preceding your request. This request
                    is free and may be made only once a year.
                  </p>
                  <p>
                    You also have the right not to be discriminated against for exercising any of the rights of California residents listed in this
                    section.
                  </p>
                  <p>
                    If you would like to exercise any of the rights listed above, please contact us at the address below in the section entitled “How
                    to contact us.”
                  </p>

                  <h3 className="subheader">6. Keeping your Personal Information secure</h3>
                  <p>
                    We have taken steps to put appropriate security measures in place to prevent personal information from being accidentally lost, or
                    used or accessed in an unauthorized way.
                  </p>
                  <p>
                    For example, we limit access to your personal information to those who have a genuine business need to know it. Those processing
                    your information will do so only in an authorized manner. The personal information that you provide to us is stored on servers
                    that are located in secured facilities and protected by protocols and procedures designed to ensure the security of such
                    information, including SSL, password protection, and controlled access to the data. In addition, Kombatlink employees and agents
                    who have access to your personal information are trained in the maintenance and security of that information.
                  </p>
                  <p>
                    However, no server, computer or communications network or system, or data transmission over the Internet can be guaranteed to be
                    100% secure. As a result, while we strive to protect user information, we cannot ensure or warrant the security of any information
                    you transmit to us or through the Service and you acknowledge and agree that you provide such information and engage in such
                    transmissions at your own risk.
                  </p>

                  <h3 className="subheader">7. Resolving Disputes</h3>
                  <p>
                    We hope that we can resolve any questions or concern you raise about our use of your Personal Information. Please contact us via
                    the methods listed below in the section entitled “How to contact us” to let us know about any of your questions or concerns, and
                    we will get back to you to resolve the issue.
                  </p>
                  <p>
                    If you are an EU citizen, the General Data Protection Regulation also gives you right to lodge a complaint with a supervisory
                    authority, in particular in the European Union (or European Economic Area) state where you work, normally live, or where any
                    alleged infringement of data protection laws occurred.
                  </p>

                  <h3 className="subheader">8. Changes to this Privacy Notice</h3>
                  <p>This privacy notice was last updated on June 1, 2020.</p>
                  <p>
                    We may change this privacy notice from time to time. When we do, we will inform you via email to the email address you have
                    provided us with through your account, or by posting a message about the change on the Service.
                  </p>

                  <h3 className="subheader">9. How to contact us</h3>
                  <p>Please contact us if you have any questions about this privacy notice or the information we hold about you.</p>
                  <p>
                    If you wish to contact us, you can do so by email to{' '}
                    <a href="mailto:support@kombatlink.com" target="_system">
                      support@kombatlink.com
                    </a>{' '}
                    or fill out a contact form on the Service.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </article>
      </>
    )
  }
}

export default PrivacyPolicy
