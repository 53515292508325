import React, { useState, useEffect } from "react";
import { Group } from "react-konva";
import { EditableText } from "./Text/EditableText";

export function StickyNote({
  color,
  text,
  x,
  y,
  width,
  height,
  onClick,
  onTextResize,
  onTextChange,
  selected,
  onTextClick,
  fontsize,
  draggable,
  IsLayOut,
  letterSpacing
  
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [isTransforming, setIsTransforming] = useState(false);

  useEffect(() => {
    if (!selected && isEditing) {
      setIsEditing(false)
    } else if (!selected && isTransforming) {
      setIsTransforming(false)
    }
  }, [selected, isEditing, isTransforming]);

  function toggleEdit() {
    setIsEditing(!isEditing)
    onTextClick(!isEditing)
  }

  function toggleTransforming() {
    setIsTransforming(!isTransforming);
    onTextClick(!isTransforming)
  }

  return (
    <>
    <Group x={x} y={y}>
      <EditableText
        x={1}
        y={5}
        text={text}
        width={width}
        height={height}
        onResize={onTextResize}
        isEditing={isEditing}
        isTransforming={isTransforming}
        onToggleEdit={toggleEdit}
        onToggleTransform={toggleTransforming}
        onChange={onTextChange}
        fontsize={fontsize}
        draggable={draggable}
        color={color}
        letterSpacing={letterSpacing}
        IsLayOut={IsLayOut}
        
      />
    </Group>
    </>
  );
}
