import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getAppUser } from 'store/selectors/app-selectors'
import { makeGetMatchParticipantsByMatchId } from 'store/selectors/match-participant-selectors'
import { ApplicationState } from 'store/types/common'
import { useMemberClan } from './useMemberClan'

export const useOpponents = (matchId: number) => {
  const getMatchParticipantsByMatchId = useMemo(makeGetMatchParticipantsByMatchId, [])
  
  const user = useSelector(getAppUser)
  const {clan} = useMemberClan(user?.Id)
  const matchParticipants = useSelector((state: ApplicationState) => getMatchParticipantsByMatchId(state, matchId))

  const opponents = useMemo(() => {
    return matchParticipants.filter(x => x.PlayerId !== user?.Id && x.ClanId !== clan?.Id)
  }, [matchParticipants, clan, user?.Id])
  
  const me = useMemo(() => {
    return matchParticipants.find(x => x.PlayerId === user?.Id || x.ClanId === clan?.Id)
  }, [matchParticipants, clan, user?.Id])

  return {
    opponents,
    me,
  }
}
