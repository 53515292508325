import { useMemberClan } from 'components/_hooks'
import { DropDownTable } from 'components/_shared'
import MemberOnlineAvatar from 'components/_shared/ParticipantAvatar/MemberOnlineAvatar'
import React from 'react'
import { useSelector } from 'react-redux'
import { makeGetUser } from 'store/selectors/user-selectors'
import { ApplicationState } from 'store/types/common'
import { LineUpType } from 'store/types/line-up-types'
import styles from './list.module.scss'

interface Props {
  lineUp: LineUpType
}

const LineUpRow = ({lineUp}: Props) => {

  const getUser = React.useMemo(makeGetUser, [])
  const user = useSelector((state: ApplicationState) => getUser(state, lineUp.PlayerId))
  const {isClanLeader} = useMemberClan(lineUp.PlayerId)

  return (
    <DropDownTable.SecondaryRow key={lineUp.Id}>
      <MemberOnlineAvatar userId={user?.Id} withLink className={styles.window__winnerPciture} />  
      <div className={styles.window__winnerUsername}>{user?.Username}</div>
      {isClanLeader && (
        <div className="m-checked_col pl-1 pb-1">
          <img src="../..https://kombatlink-assets.azureedge.net/img/crown-accent.svg" alt="" />
        </div>
      )}
    </DropDownTable.SecondaryRow>  
  )
}

export default LineUpRow
