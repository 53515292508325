import { isTwoPlayersStructure } from 'consts'
import { useMemo } from 'react'
import { DisputeDetailType } from 'store/types/dispute-details-types'
import { DisputeStatus } from 'store/types/dispute-types'
import { MatchParticipantModel } from 'store/types/match-participant-types'
import { ParticipantSubmissionModel } from 'store/types/match-submissions-types'
import { TournamentType } from 'store/types/tournaments-types'

export interface DisputedMatchParticipant {
  matchParticipantId: number
  place?: number
  inDispute: boolean
  winner: boolean
}

const sortByPlace = (a?: number, b?: number) => {
  if (a && !b) {
    return -1
  }
  if (!a && b) {
    return 1
  }

  return a - b
}

export const useDisputedMatchParticipants = (
  matchParticipants: MatchParticipantModel[],
  disputeDetails: DisputeDetailType[],
  submissions: ParticipantSubmissionModel[],
  matchSubmissionsLoading: boolean,
  disputeDetailsLoading: boolean,
  disputeStatus: DisputeStatus,
  tournament: TournamentType
) => {
  const sortedDisputedMatchParticipants = useMemo<DisputedMatchParticipant[]>(() => {
    if (matchParticipants.length === 0) {
      return []
    }

    if (disputeStatus === DisputeStatus.Closed) {
      return matchParticipants
        .map(x => ({
          inDispute: false,
          matchParticipantId: x.Id,
          winner: x.Place === 1,
          place: x.Place,
        }))
        .sort((a, b) => sortByPlace(a.place, b.place))
    }

    const dataLoading = matchSubmissionsLoading || disputeDetailsLoading
    const noSubmissions = submissions.length === 0
    const noDisputeDetails = disputeDetails.length === 0
    const notEnoughData = dataLoading || (noSubmissions && noDisputeDetails)
    if (notEnoughData) {
      return matchParticipants.map(x => ({
        inDispute: false,
        matchParticipantId: x.Id,
        winner: false,
      }))
    }

    const isTwoPlayers = isTwoPlayersStructure(tournament?.TournamentStructureId)
    const disputedMatchParticipants: DisputedMatchParticipant[] = []
    const filteredDisputeDetails =
      !isTwoPlayers || disputeDetails.length === 0 ? disputeDetails : [disputeDetails.sort((a, b) => b.SubmitDateTicks - a.SubmitDateTicks)[0]]

    matchParticipants.forEach(matchParticipant => {
      const disputedMatchParticipant: DisputedMatchParticipant = {
        matchParticipantId: matchParticipant.Id,
        inDispute: false,
        winner: false,
      }

      const disputeDetail = filteredDisputeDetails.find(x => x.MatchParticipantId === matchParticipant.Id)
      if (disputeDetail) {
        disputedMatchParticipant.place = disputeDetail.Place
      } else if (!isTwoPlayers) {
        const submission = submissions.find(x => x.MatchParticipantId === matchParticipant.Id)
        if (submission) {
          disputedMatchParticipant.place = submission.Place
        }
      }

      disputedMatchParticipants.push(disputedMatchParticipant)
    })

    const firstDisputedMatchParticipantPlace = disputedMatchParticipants[0].place
    const allInDispute = disputedMatchParticipants.every(x => x.place === firstDisputedMatchParticipantPlace)
    if (allInDispute) {
      disputedMatchParticipants.forEach(x => {
        x.inDispute = true
      })
      return disputedMatchParticipants
    }

    disputedMatchParticipants.forEach(x => {
      x.inDispute = disputedMatchParticipants.filter(y => y.place === x.place).length > 1
    })

    disputedMatchParticipants.sort((a, b) => {
      if (a.inDispute && b.inDispute) {
        return 0
      }
      if (a.inDispute && !b.inDispute) {
        return -1
      }
      if (!a.inDispute && b.inDispute) {
        return 1
      }

      if (isTwoPlayers) {
        return 0
      }

      return sortByPlace(a.place, b.place)
    })

    return disputedMatchParticipants
  }, [matchParticipants, disputeStatus, matchSubmissionsLoading, disputeDetailsLoading, submissions, disputeDetails, tournament?.TournamentStructureId])

  return sortedDisputedMatchParticipants
}
