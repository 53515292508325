import React, { useCallback, useMemo } from 'react'
import { DropzoneOptions, FileRejection, useDropzone } from 'react-dropzone'
import { MediaType } from 'store/types/image-types'
import { asyncFileToDataUrl } from 'utils'

const getFileType = (file: File): MediaType => {
  return file.type.includes('image') ? MediaType.Image : MediaType.Video
}

interface DropzoneProps {
  fileTypes: MediaType[]
  onChange: (file?: File, dataUrl?: string, type?: MediaType) => void
  isVideoAvailable: boolean
  onRawFileChange: (file: File) => void
  onRawFileDataUrlChange: (dataUrl: string) => void
  onCroppedFileDataUrlChange: (dataUrl: string) => void
}

export const Dropzone = ({
  fileTypes,
  onChange,
  isVideoAvailable,
  onRawFileChange,
  onCroppedFileDataUrlChange,
  onRawFileDataUrlChange,
}: DropzoneProps) => {
  const handleDrop = useCallback(async (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      const acceptedFile = acceptedFiles[0]

      onRawFileChange(acceptedFile)

      const dataUrl = await asyncFileToDataUrl(acceptedFile)
      const acceptedFileType = getFileType(acceptedFile)
      const isGif = acceptedFile.type === 'image/gif'
      if (acceptedFileType === MediaType.Video || isGif) {
        onCroppedFileDataUrlChange(dataUrl)
        if (isGif) {
          onChange(acceptedFile, dataUrl, acceptedFileType)
        }
      } else {
        onRawFileDataUrlChange(dataUrl)
      }
    }
  }, [onChange, onCroppedFileDataUrlChange, onRawFileChange, onRawFileDataUrlChange])

  const handleDropRejected = useCallback((fileRejections: FileRejection[]) => {
    const errorMessage = fileRejections[0]?.errors[0]?.message
    alert(errorMessage)
  }, [])

  const dropzoneOptions = useMemo<DropzoneOptions>(() => {
    const acceptTypes = []
    if (fileTypes.includes(MediaType.Image)) {
      acceptTypes.push(...['image/jpeg', 'image/jpg', 'image/png', 'image/gif'])
    }
    if (isVideoAvailable && fileTypes.includes(MediaType.Video)) {
      acceptTypes.push(...['video/mp4'])
    }
    const accept = acceptTypes.join(', ')

    return {
      onDrop: handleDrop,
      onDropRejected: handleDropRejected,
      accept,
      multiple: false,
      maxSize: 209715200,
    }
  }, [fileTypes, isVideoAvailable, handleDrop, handleDropRejected])

  const { getRootProps, getInputProps } = useDropzone(dropzoneOptions)

  return (
    <section>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <div className="container-fluid">
          <div className="row">
            <div className="drop" draggable="true" style={{ height: '368.932px' }}>
              <div className="row h-100 align-items-center m-0">
                <div className="col-12 d-flex flex-column">
                  <div className="row justify-content-center">
                    <div className="drop__icon" />
                  </div>
                  <div className="row justify-content-center">
                    <div className="drop__text">
                      {isVideoAvailable
                        ? 'Drag a picture or video to this window'
                        : 'Drag a picture to this window'
                      }
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="drop__text">or</div>
                  </div>
                  <div className="row justify-content-center">
                    <label className="drop__label blue-button blue-button--filled">
                      Select a file on the computer
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
