import { useAppSelector } from 'components/_hooks'
import { ButtonLink } from 'components/_shared'
import { DivImg } from 'components/_shared/Img'
import React, { useMemo } from 'react'
import { makeGetGame } from 'store/selectors/game-selectors'
import style from "./gamerow.module.scss"

export interface GameRowProps {
  gameId: number
}

export const GameRow = ({ gameId }:  GameRowProps) => {
  const getGame = useMemo(makeGetGame, [])
  const game = useAppSelector(state => getGame(state, { gameId }))

  return (
    <div className="col-md-6 col-sm-12 m-0">
      <DivImg useBackgroundStyles={false} className={`card ${style.gameCard}`} imageId={game.ImageId} src={!game.ImageId ? `/images/games/${gameId}.jpg` : undefined} style={{ border: 'transparent' }}>
        <div className="article-header__background" />
        <div className="article-header__content row flex-xl-nowrap flex-wrap justify-content-start align-items-start" />
      </DivImg>
      <div className="row gamefooter">
        <div className="col-12">
          <h4 className="game__title" style={{ justifyContent: 'center' }}>
            {game.Gamename}
          </h4>
          <ButtonLink color="grey" filled className="game__button" to={`/game-detail/${gameId}`}>
            detail
          </ButtonLink>
        </div>
      </div>
    </div>
  )
}
