import { useAppSelector, useAppUser, useUser } from 'components/_hooks'
import React, { useContext, useMemo, useState } from 'react'
import { useChat, useCloseChat, useCloseChats } from 'store/chat/chat/chat-hooks'
import { getIsAdmin, getIsOrganizer, getIsPlayer, getOrganizerProfileUrl, getUserProfileUrl } from 'store/types/user-types'
import { useGetOrCreateChat } from './ChatHooks'
import { ChatHeader, ChatMessageInput, ChatMessages } from '../../_shared'
import { useIsConnectToChatLoading } from 'store/signalr/hubs/chat-hub-hooks'
import { ChatExternalHistoryContext } from 'components/_shared'
import { ChatMemberRole, ChatMemberStatus, ChatType } from 'consts'
import { useChatMembers, useTypingUserChatMember } from 'store/chat/chat-member/chat-member-hooks'
import { makeGetUsers } from 'store/selectors/user-selectors'
import { useHistory } from 'react-router'
import { useDeleteChat } from 'store/chat/chat/chat-logic'
import { blockMember, unblockMember, useLeaveChat } from 'store/chat/chat-member/chat-member-logic'

import styles from './Chat.module.scss'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getAppUserRole } from 'store/selectors/app-selectors'

export const Chat = () => {
  useGetOrCreateChat()
  const externalHistory = useContext(ChatExternalHistoryContext)
  const history = useHistory()
  const closeChats = useCloseChats()  
  const closeChat = useCloseChat()
  const dispatch = useDispatch()

  const appUser = useAppUser()
  const [isMenuOpened, setIsMenuOpened] = useState(false)


  const { chat, openedUserId } = useChat()
  const { user } = useUser(openedUserId)
  const isConnectToChatLoading = useIsConnectToChatLoading(chat?.Id)  
  const deleteChat = useDeleteChat(chat?.Id)
  const leaveChat = useLeaveChat(chat?.Id)
  const isAdmin = getIsAdmin(user?.Role)


  useEffect(() => {
    if (!chat && !openedUserId) {
      history.goBack()
    }
  }, [chat, history, openedUserId])

  const name = user?.Username ?? user?.Organizationname ?? chat?.Name ?? 'Chat'

  const closeMenu = () => {
    setIsMenuOpened(false)
  }

  const handleCloseClick = () => {
    closeChats()
  }

  const handleViewProfileClick = () => {
    closeMenu()
    if (user?.Id) {
      const isPlayer = getIsPlayer(user.Role)
      if(isPlayer)
      {
        externalHistory.push(getUserProfileUrl(user.Id))
      }
      else
      {
        externalHistory.push(getOrganizerProfileUrl(user.Id))
      }
    }
  }


  const chatMembers = useChatMembers(chat?.Id)
  const activeMembers = useMemo(() => chatMembers.filter(cm => cm.Status == ChatMemberStatus.Active), [chatMembers])

  const myChatMember = useMemo(() => 
    chatMembers.find(cm => cm.UserId == appUser?.Id), 
  [chatMembers, appUser?.Id])

  const isBlocked = myChatMember?.Status == ChatMemberStatus.Blocked

  const opponentChatMember = useMemo(() =>  {
    if (chat?.Type != ChatType.Private) {
      return null
    } 
    const opponent =  chatMembers.find(cm => cm.UserId != appUser?.Id)
    if (!opponent) {
      return null
    }
    return {
      chatMember: opponent,
      isBlocked: opponent.Status == ChatMemberStatus.Blocked
    }
  }, [chatMembers, appUser?.Id, chat?.Type])

  
  const usersIds = activeMembers?.map(cm => cm.UserId) || []
  const getUsers = useMemo(makeGetUsers, [])
  const users = useAppSelector(state => getUsers(state, usersIds))

  const typingUsers = useTypingUserChatMember(chat?.Id, appUser?.Id)
 const typingUsersIds = typingUsers?.map(cm => cm.UserId) || []
 const typingUsersList = useAppSelector(state => getUsers(state, typingUsersIds))

  const chatStatus = useMemo(() => {
    if (chat?.Type == ChatType.Private) {
      if(typingUsersList !== undefined && typingUsersList.length > 0)
      {
        return 'Typing'
      }
      else{
      return (user?.IsOnline || false) ? 'Online' : 'Offline'
      }
    } else if (chat?.Type == ChatType.Group) {
      if(typingUsersList !== undefined && typingUsersList.length > 0)
      {
        const username =  typingUsersList.map((x,i)=>{
          return x.Username
        })
        if(username.length < 2){
        return username.join(',') + ' Typing'
        }else{
          return 'Several Typing'
        }
      }
      else{
      return !activeMembers ? '' : `${activeMembers.length} members, ${users.filter(u => u.IsOnline).length} online`
      }
    } 
    return ''
  }, [chat?.Type, activeMembers, user?.IsOnline, users])

  const handleBack = () => {
    closeChat()
    history.goBack()
  }

  const canDeleteChat = useMemo(() => {
    return chat 
      && chat.Type == ChatType.Group 
      && !chat.RelationType 
      && myChatMember?.Role == ChatMemberRole.Creator
  }, [chat, myChatMember?.Role])

  const canDeletePrivateChat = useMemo(() => {
    return chat 
      && chat.Type == ChatType.Private 
      && !chat.RelationType 
      && myChatMember?.Role == ChatMemberRole.Creator
  }, [chat, myChatMember?.Role])

  const canLeaveChat = useMemo(() => {
    return chat 
      && chat.Type == ChatType.Group 
      && !chat.RelationType 
      && myChatMember
      && myChatMember.Role != ChatMemberRole.Creator
  }, [chat, myChatMember])

  const canAddToChat = useMemo(() => {
    return chat 
      && chat.Type == ChatType.Group 
      && !chat.RelationType 
      && myChatMember?.Role == ChatMemberRole.Creator
  }, [chat, myChatMember?.Role])

  const handleDeleteChat = async (isPrivate:boolean) => {
    await deleteChat()
    if(isPrivate)
    {
      handleBack()
    }
    else{
      history.goBack()
    }

  }
  const handleLeaveChat = async () => {
    await leaveChat()
    history.goBack()
  }

  const handleBlockUser = () => {
    if (opponentChatMember) {
      if (opponentChatMember.isBlocked) {        
        dispatch(unblockMember(opponentChatMember.chatMember.Id))
      } else {
        dispatch(blockMember(opponentChatMember.chatMember.Id))
      }
    }
  }

  const canBlockUser = useMemo(() => {
    return chat?.Type == ChatType.Private && opponentChatMember?.chatMember.Role == ChatMemberRole.Member
  }, [chat?.Type, opponentChatMember?.chatMember.Role])

  const canWriteMessage = useMemo(() => {
    if (chat?.Type == ChatType.Private && chatMembers.findIndex(m => m.Status == ChatMemberStatus.Blocked) >= 0) {
      return false
    }

    return true
  }, [chat?.Type, chatMembers])

  return (
    <>
      <ChatHeader>
        <ChatHeader.BackButton onClick={handleBack} />
        <ChatHeader.Name 
          name={name} 
          imageId={user?.AvatarId} 
          loading={!user && !chat} 
          chatType={chat?.Type} 
          chatStatus={chatStatus} 
          chatReleationType={chat?.RelationType} 
        />
        {chat && !chat?.IsDeleted && (
          <ChatHeader.Menu isMenuOpened={isMenuOpened} onIsMenuOpenedChange={setIsMenuOpened}>
            {user ? !isAdmin ? <ChatHeader.Menu.Link onClick={handleViewProfileClick}>View profile</ChatHeader.Menu.Link> : '' : (
              <ChatHeader.Menu.Link to="/chat/about">About Chat</ChatHeader.Menu.Link>
            )}
            {canBlockUser && (
              <ChatHeader.Menu.Link onClick={handleBlockUser}>
                {opponentChatMember?.isBlocked ?  'Unblock user' : 'Block user'}
              </ChatHeader.Menu.Link>
            )}
            {canAddToChat && (
              <ChatHeader.Menu.Link to="/group/create">Add to chat</ChatHeader.Menu.Link>
            )}
            {canLeaveChat && (
              <ChatHeader.Menu.Link onClick={handleLeaveChat}>Leave chat</ChatHeader.Menu.Link> 
            )}
            {(canDeleteChat || canDeletePrivateChat) && (
              <ChatHeader.Menu.Link onClick={() => {handleDeleteChat(canDeletePrivateChat)}}>Delete chat</ChatHeader.Menu.Link> 
            )}
            <ChatHeader.Menu.Link onClick={handleCloseClick}>Close chat</ChatHeader.Menu.Link>     
          </ChatHeader.Menu>
        )}
      </ChatHeader>
      {chat?.IsDeleted ? (
        <>
          <div className={styles.chatInfo}>
            Chat has been deleted by the creator
          </div>
        </>
      ) : (
        <>
          <ChatMessages loading={!chat || isConnectToChatLoading} chatId={chat?.Id} />          
          <ChatMessageInput chatId={chat?.Id} disabled={isConnectToChatLoading || !canWriteMessage} isBlocked={isBlocked} />         
        </>
      )}      
    </>
  )
}
