import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { makeGetIsUserLoading, makeGetUser } from 'store/selectors/user-selectors'
import { ApplicationState } from 'store/types/common'
import { ImageType } from 'store/types/image-types'
import { PublicUserType } from 'store/types/user-types'
import { useImage } from './useImage'

interface UseUserResult {
  user?: PublicUserType
  image?: ImageType
  isUserLoading: boolean
}

export const useUser = (userId?: number): UseUserResult => {
  const getUser = useMemo(makeGetUser, [])
  const getIsUserLoading = useMemo(makeGetIsUserLoading, [])

  const user = useSelector((state: ApplicationState) => getUser(state, userId))
  const isUserLoading = useSelector((state: ApplicationState) => getIsUserLoading(state, { userId }))
  const { image } = useImage(user?.AvatarId)

  return {
    user,
    image,
    isUserLoading,
  }
}
