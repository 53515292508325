import React, { forwardRef, ReactNode } from 'react'
import { cn } from 'utils'
import { useDropDownTableStyles } from './styles/useDropDownTableStyles'

export interface DropDownTableRowProps {
  children: ReactNode
  onMouseEnter?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

export const DropDownTableRow = forwardRef<HTMLDivElement, DropDownTableRowProps>(({ children, onMouseEnter }, ref) => {
  const styles = useDropDownTableStyles()

  return (
    <div ref={ref} className={cn('row', styles.tableRow)} onMouseEnter={onMouseEnter}>
      <div className="col-12">{children}</div>
    </div>
  )
})
