import { TournamentsTab } from 'components/_pages/common/Tournaments/Tournaments'
import { TournamentModeEnum } from 'consts'
import { ConsoleEnum } from 'consts/ConsoleType'
import { DateIntervalEnum } from 'consts/DateInterval'
import { TournamentSortFieldEnum, TournamentSortFieldOptions } from 'consts/TournamentSortField'
import { makeActionCreator, ReduxAction } from '../common'
import { TournamentConsolesType } from '../console-types'
import { IDictionary } from '../dictionary-types'
import { GameType } from '../game-types'
import { ImageType } from '../image-types'
import { RegionType } from '../region-types'
import { TournamentType } from '../tournaments-types'
import { PublicUserType } from '../user-types'


export const defaultTournamentsFilters: TournamentPageFilters = {
  currentPage: 0,
  currentTab: 'upcoming',
  isLiveEvent: true,
  isOnlineEvent: true,
  selectedConsoles: [],
  selectedGames: [],
  selectedInterval: null,
  selectedMode: null,
  selectedOrganizers: [],
  selectedRegion: null,
  selectedSortField: TournamentSortFieldOptions[0],
}
export interface TournamentPageFilters {
  currentTab: TournamentsTab
  currentPage: number
  isOnlineEvent: boolean
  isLiveEvent: boolean
  selectedOrganizers: PublicUserType[]
  selectedConsoles: IDictionary<ConsoleEnum>[]
  selectedGames: GameType[]
  selectedMode: IDictionary<TournamentModeEnum>
  selectedInterval: IDictionary<DateIntervalEnum>
  selectedSortField: IDictionary<TournamentSortFieldEnum>
  selectedRegion: RegionType
}


export interface TournamentsPageState {
  filters: TournamentPageFilters
  upcomingTournamentsIds: number[]
  allUpcomingTournamentsCount: number
  mainTournamentsIds: number[]
  allMainTournamentsCount: number
  qualifyTournamentsIds: number[]
  allQualifyTournamentsCount: number
}


export const FILTERS_CHANGED = 'FILTERS_CHANGED'
export interface FilterChangedPayLoad {
  filters: TournamentPageFilters
}

export type FiltersChangedAction = ReduxAction<typeof FILTERS_CHANGED, FilterChangedPayLoad>
export const filtersChanged = makeActionCreator<FilterChangedPayLoad>(FILTERS_CHANGED)

export const UPCOMING_TOURNAMENTS_LOADED = 'UPCOMING_TOURNAMENTS_LOADED'
interface UpcomingTournamentsLoadedPayload {
  tournaments: TournamentType[]
  allUpcomingTournamentsCount: number
  TournamentConsoles: TournamentConsolesType[] 
  Images: ImageType[]
}
export type UpcomingTournamentsLoadedAction = ReduxAction<typeof UPCOMING_TOURNAMENTS_LOADED, UpcomingTournamentsLoadedPayload>
export const upcomingTournamentsLoaded = makeActionCreator<UpcomingTournamentsLoadedPayload>(UPCOMING_TOURNAMENTS_LOADED)

export const MAIN_TOURNAMENTS_LOADED = 'MAIN_TOURNAMENTS_LOADED'
interface MainTournamentsLoadedPayload {
  tournaments: TournamentType[]
  allMainTournamentsCount: number
  TournamentConsoles: TournamentConsolesType[] 
  Images: ImageType[]
}
export type MainTournamentsLoadedAction = ReduxAction<typeof MAIN_TOURNAMENTS_LOADED, MainTournamentsLoadedPayload>
export const mainTournamentsLoaded = makeActionCreator<MainTournamentsLoadedPayload>(MAIN_TOURNAMENTS_LOADED)

export const QUALIFY_TOURNAMENTS_LOADED = 'QUALIFY_TOURNAMENTS_LOADED'
interface  QualifyTournamentsLoadedPayload {
  tournaments: TournamentType[]
  allQualifyTournamentsCount: number
  TournamentConsoles: TournamentConsolesType[] 
  Images: ImageType[]
}
export type QualifyTournamentsLoadedAction = ReduxAction<typeof QUALIFY_TOURNAMENTS_LOADED, QualifyTournamentsLoadedPayload>
export const qualifyTournamentsLoaded = makeActionCreator<QualifyTournamentsLoadedPayload>(QUALIFY_TOURNAMENTS_LOADED)


export type KnownAction = FiltersChangedAction
  | UpcomingTournamentsLoadedAction
  | MainTournamentsLoadedAction
  | QualifyTournamentsLoadedAction
