import { RadioInput } from 'components/_library/RadioInput/RadioInput'
import { TextArea, TextInput } from 'components/_shared'
import React, { useEffect, useState } from 'react'
import { ClanType } from 'store/types/clan-types'
import { YesNoType } from 'store/types/common'
import styles from '../../ClanPage.module.scss'

export interface ClanFields {
  name: string
  aboutUs: string
  wantToBeFound: boolean
}

export interface ClanFieldsProps {
  clan?: ClanType
  onChange: (value: ClanFields) => void
}

export const ClanFields = ({ onChange, clan }:  ClanFieldsProps) => {
  const [name, setName] = useState('') 
  const [aboutUs, setAboutUs] = useState<string>('')
  const [displayInDirectory, setDisplayInDirectory] = useState<YesNoType>('yes')

  const handleDisplayInDirectoryChange = (value: YesNoType) => {
    setDisplayInDirectory(value)
  }

  useEffect(() => {
    if (clan) {
      setName(clan.Name)
      setAboutUs(clan.AboutUs)
      setDisplayInDirectory(clan.WantToBeFound ? 'yes' : 'no')
    }
  }, [clan])

  useEffect(() => {
    onChange({
      aboutUs,
      name,
      wantToBeFound: displayInDirectory === 'yes',
    })
  }, [aboutUs, displayInDirectory, name, onChange])

  return (
    <>
      <TextInput
        placeholder="Name"
        onChange={setName}
        value={name}
        className="mb-2 w-100"
        inputClassName="w-100"
      />
      <TextArea inputClassName={styles.aboutUs} value={aboutUs} onChange={setAboutUs} placeholder="About clan" />
      <div className="col-lg-4 col-md-6">
        <div className="row flex-column">
          <div className={styles.title}>Do you want to be listed in clan directory?</div>
          <div className="d-flex flex-row justify-content-between">
            <RadioInput
              name="listed"
              value="yes"
              checked={displayInDirectory === 'yes'}
              withDot
              label="Yes"
              onChange={handleDisplayInDirectoryChange}
            />
            <RadioInput
              name="listed"
              value="no"
              checked={displayInDirectory === 'no'}
              withDot
              label="No"
              onChange={handleDisplayInDirectoryChange}
            />
          </div>
        </div>
      </div>
    </>
  )
}
