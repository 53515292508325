import { makeActionCreator, ReduxAction } from '../common'
import { ListUserResultModel } from '../user-types'


export interface FriendsFilters {
  userId: number
  name: string
  withClan?: boolean
}

export interface FriendsPageState {
  friendsIdsByUserId: {
    [key: number]: {
      friendIds: number[]
      allFriendsCount: number
    }
  }
}


/*export const FRIENDS_PAGE_FILTERS_CHANGED = 'FRIENDS_PAGE_FILTERS_CHANGED'
interface FriendsPageFiltersChangedPayload {
  friends: UsersModel
  allFriendsCount: number
}

export type FriendsPageFiltersChangedAction = ReduxAction<typeof FRIENDS_PAGE_FILTERS_CHANGED, FriendsPageFiltersChangedPayload>
export const friendsPageFiltersChanged = makeActionCreator<FriendsPageFiltersChangedPayload>(FRIENDS_PAGE_FILTERS_CHANGED)
*/

export const FRIENDS_PAGE_LOADED = 'FRIENDS_PAGE_LOADED'
interface FriendsPageLoadedPayload {
  userId: number
  friends: ListUserResultModel
}

export type FriendsPageLoadedAction = ReduxAction<typeof FRIENDS_PAGE_LOADED, FriendsPageLoadedPayload>
export const friendsPageLoaded = makeActionCreator<FriendsPageLoadedPayload>(FRIENDS_PAGE_LOADED)


export type KnownAction = FriendsPageLoadedAction
