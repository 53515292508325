import { useClientRect, useBodyPortal } from 'components/_hooks'
import React, { ReactNode, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { cn } from 'utils'
import styles from './ToolTip.module.scss'

const createDiv = () => {
  const div = document.createElement('div')
  div.style.position = 'absolute'
  div.style.zIndex = '9999999'
  return div
}

export type TipPlace = 'top-right' | 'top-center'

const calcPosition = (containerRect: DOMRect, targetRect: DOMRect, place: TipPlace, left?: number, top?: number, right?: number, bottom?: number) => {
  switch (place) {
    case 'top-right':
      left = targetRect.right - containerRect.width + (left ?? 0)
      top = targetRect.top - containerRect.height + (top ?? 0)
      break
    case 'top-center':
      left = (targetRect.left - containerRect.width / 2) + (left ?? 0)
      top = targetRect.top - containerRect.height + (top ?? 0)
      break
    default:
      throw new Error('Unsupported position')
  }

  return {
    left,
    top: top + document.scrollingElement.scrollTop,
    right,
    bottom,
  }
}

const calcArrowLeft = (place: TipPlace) => {
  switch (place) {
    case 'top-right':
      return 200 - 16
    case 'top-center':
      return 100
    default:
      throw new Error('Unsupported position')
  }
}

export interface ToolTipProps {
  children: ReactNode
  rect: DOMRect | null
  place: TipPlace
  left?: number
  top?: number
  right?: number
  bottom?: number
}

export const ToolTip = ({ children, rect, place, left, top, right, bottom }: ToolTipProps) => {
  const container = useBodyPortal(createDiv())

  useEffect(() => {
    if (rect !== null) {
      const position = calcPosition(container.getBoundingClientRect(), rect, place, left, top, right, bottom)
      container.style.left = position.left ? `${position.left}px` : ''
      container.style.top = position.top ? `${position.top}px` : ''
      container.style.right = position.right ? `${position.right}px` : ''
      container.style.bottom = position.bottom ? `${position.bottom}px` : ''
    }
  }, [rect, container, place, left, top, right, bottom])

  return ReactDOM.createPortal(children, container)
}

export interface WithToolTipProps {
  tip?: ReactNode
  children: ReactNode
  className?: string
  wrapper?: 'div' | 'span'
  place: TipPlace
  left?: number
  top?: number
  right?: number
  bottom?: number
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  onMouseDown?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

export const WithToolTip = ({
  children,
  tip,
  className,
  wrapper = 'div',
  place = 'top-right',
  left,
  top,
  right,
  bottom,
  onClick,
  onMouseDown,
}: WithToolTipProps) => {
  const Wrapper = wrapper
  const [showTip, setShowTip] = useState(false)
  const [rect, ref, reInit] = useClientRect<HTMLDivElement>()

  const handleMouseEnter = () => {
    reInit()
    setShowTip(true)
  }

  const handleMouseLeave = () => {
    setShowTip(false)
  }

  return (
    <>
      <Wrapper
        ref={ref}
        className={className}
        onMouseDown={onMouseDown}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={onClick}
      >
        {children}
      </Wrapper>
      {tip && showTip &&
        <ToolTip rect={rect} place={place} left={left} top={top} right={right} bottom={bottom}>
          <div className={cn(styles.selectBlockedContainer, styles.show)} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div className={styles.selectBlockedContent}>
              <div className={styles.selectBlockedContentArrow} style={{ left: calcArrowLeft(place) }} />
              {tip}
            </div>
          </div>
        </ToolTip>
      }
    </>
  )
}
