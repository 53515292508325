import { useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import { fetchApi } from 'server/server-utils'
import { makeGetIsImageRequested } from 'store/selectors/image-selectors'
import { GetState } from 'store/types/common'
import { imageLoaded, imagesLoaded, ImageType, ImageTypeEnum } from 'store/types/image-types'
import { OperationTypes, run, useRunWithResult } from './operations-logic'

interface CreateImageModel {
  fileName: string
  type: ImageTypeEnum
}

interface AppendImageModel {
  block: Blob
}

interface UploadImageModel {
  tempId: number
  file: Blob
  type: ImageTypeEnum
}

interface ImageModel {
  image: ImageType
  tempId: number
}

const getIsImageRequested = makeGetIsImageRequested()

export const loadImage = (imageId: number) => async (dispatch: Dispatch, getState: GetState) => {
  if (getIsImageRequested(getState(), { imageId })) {
    return
  }
  await run(OperationTypes.loadImage, imageId, dispatch, getState, async () => {
    try {
      const image = await fetchApi<ImageType>(`image/${imageId}`, 'GET')
      dispatch(imageLoaded({ image }))
    } catch (error) {
      return false
    }
    return true
  })
}

export const reLoadImage = (imageId: number) => async (dispatch: Dispatch, getState: GetState) => {
  await run(OperationTypes.reLoadImage, imageId, dispatch, getState, async () => {
    try {
      const image = await fetchApi<ImageType>(`image/${imageId}`, 'GET')
      dispatch(imageLoaded({ image }))
    } catch (error) {
      return false
    }
    return true
  })
}

export const useUploadImage = () => {
  const dispatch = useDispatch()
  return async (file: Blob, type: ImageTypeEnum) => {
    const formData = new FormData()

    formData.append('file', file)
    formData.append('type', type.toString())
    const image = await fetchApi<ImageType>('image', 'POST', formData)
    dispatch(imageLoaded({ image }))
    return image
  }
}

export const useRunUploadImage = (id: string = null) => {
  const dispatch = useDispatch()
  return useRunWithResult(OperationTypes.uploadImage, id, async (file: Blob, type: ImageTypeEnum) => {
    const formData = new FormData()

    formData.append('file', file)
    formData.append('type', type.toString())
    const image = await fetchApi<ImageType>('image', 'POST', formData)
    dispatch(imageLoaded({ image }))
    return image
  })
}

export const useRunUploadImages = (id: string = null) => {
  const dispatch = useDispatch()
  return useRunWithResult(OperationTypes.uploadImages, id, async (models: UploadImageModel[]) => {

    const formDatas = models.map(m => {
      const formData = new FormData()
      formData.append('file', m.file)
      formData.append('type', m.type.toString())

      return {
        formData,
        tempId: m.tempId,
      }
    })
    
    const promises = formDatas.map(x => async () => {
      const image = await fetchApi<ImageType>('image', 'POST', x.formData)
      return <ImageModel>{
        image,
        tempId: x.tempId,
      }
    })

    const images = await Promise.all(promises.map(x => { return x() }))
    
    dispatch(imagesLoaded({ images: images.map(x => x.image) }))
    return images
  })
}

export const useAppendImage = () => {
  return async (imageId: number, model: AppendImageModel) => {
    const formData = new FormData()

    formData.append('block', model.block)
    await fetchApi<ImageType>(`image/${imageId}`, 'PUT', formData)
  }
}

export const useCreateImage = () => {
  return async (model: CreateImageModel) => {
    return await fetchApi<ImageType>('image/create', 'POST', model)
  }
}
