import { Payments } from '../../models/payment-models'
import { ExecuteAPIPost, ExecuteAPIGet } from '../server-utils'
import { EndPoints } from '../endpoints'
import { UserSubscription } from '../../models/user-subscription-model'
import { UserPayment } from '../../models/user-payment-models'
import { PaymentMethodModel } from '../../models/billing-option-model'
import { StripeAccountViewModel } from '../../models/stripe-account-viewmodel'
import { TransferFundViewModel } from '../../models/transfer-fund-viewmodel'
import { OrganizerBankAccountModel } from 'models/organizer-bank-account-models'

export const StripeUtils = {
  stripSubscription: async function stripSubscription(payment: PaymentMethodModel) {
    return await ExecuteAPIPost<any>(EndPoints.Stripe.StripeSubscription, payment)
  },
  getUserSubscription: async function getUserSubscription() {
    return await ExecuteAPIGet<UserSubscription>(EndPoints.Stripe.GetUserSubscription)
  },
  getUserPayment: async function getUserPayment() {
    return await ExecuteAPIGet<UserPayment>(EndPoints.Stripe.GetUserPayment)
  },
  stripCharge: async function stripCharge(payment: Payments) {
    return await ExecuteAPIPost<any>(EndPoints.Stripe.StripeChargeOneTimePayment, payment)
  },
  createPaymentMethod: async function createPaymentMethod(paymentMethod: PaymentMethodModel) {
    return await ExecuteAPIPost<any>(EndPoints.Stripe.CreatePaymentMethod, paymentMethod)
  },
  getPaymentMethod: async function getPaymentMethod() {
    return await ExecuteAPIGet<PaymentMethodModel[]>(EndPoints.Stripe.GetPaymentMethod)
  },
  deletePaymentMethod: async function deletePaymentMethod(id: number) {
    return await ExecuteAPIGet<any>(EndPoints.Stripe.DeletePaymentMethod, `id=${id}`)
  },
  getPrice: async function getPrice(planId: string) {
    return await ExecuteAPIGet<any>(EndPoints.Stripe.GetPrice, `planId=${planId}`)
  },
  RemoveUserSubscription: async function RemoveUserSubscription() {
    return await ExecuteAPIGet<any>(EndPoints.Stripe.RemoveUserSubscription)
  },
  WithdrawlBalance: async function WithdrawlBalance(stripeAccountObj: StripeAccountViewModel) {
    return await ExecuteAPIPost<any>(EndPoints.Stripe.WithdrawlBalance, stripeAccountObj)
  },
  GetStripeBalance: async function GetStripeBalance() {
    return await ExecuteAPIGet<any>(EndPoints.Stripe.GetStripeBalance)
  },
  StripeOrganizerPayoutBalance: async function StripeOrganizerPayoutBalance(totalAmount: number) {
    return await ExecuteAPIGet<any>(EndPoints.Stripe.StripeOrganizerPayoutBalance, `totalAmount=${totalAmount}`)
  },
  OrganizerStripeOnboarding: async function OrganizerStripeOnboarding(organizerBankAccountObj: OrganizerBankAccountModel) {
    return await ExecuteAPIPost<any>(EndPoints.Stripe.OrganizerStripeOnboarding, organizerBankAccountObj)
  },
  GetBankAccountFields: async function GetBankAccountFields(userCountry:string) {
    return await ExecuteAPIGet<any>(EndPoints.Stripe.GetBankAccountFields, `CountryName=${userCountry}`)
  },
  UpdateUserVerificationStatus: async function UpdateUserVerificationStatus(accId: string) {
    return await ExecuteAPIGet<any>(EndPoints.Stripe.UpdateUserVerificationStatus, `accId=${accId}`)
  },
  GetStripeSupportedCountry: async function GetStripeSupportedCountry(country: string) {
    return await ExecuteAPIGet<any>(EndPoints.Stripe.GetStripeSupportedCountry, `CountryName=${country}`)
  },
  ConvertToCurrency: async function ConvertToCurrency(from: string,to:string,price:number) {
    return await ExecuteAPIGet<any>(EndPoints.Stripe.ConvertToCurrency, `fromCurrency=${from}&toCountry=${to}&price=${price}`)
  },
  StripeRefundFund: async function StripeRefundFund(adminRedaptionId: number) {
    return await ExecuteAPIGet<any>(EndPoints.Stripe.StripeRefundFund, `adminRedaptionId=${adminRedaptionId.toString()}`)
  },
  TransferFundToOrganization: async function TransferFundToOrganization(adminRedaptionId: number) {
    return await ExecuteAPIPost<any>(EndPoints.Stripe.TransferFundToOrganization, {AdminRedaptionId : adminRedaptionId})
  },
  UpdatePaymentMethodToPrimary:async function UpdatePaymentMethodToPrimary(id:number){
    return await ExecuteAPIGet<any>(EndPoints.Stripe.UpdatePaymentMethodToPrimary, `id=${id}`)
  }
}
