import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useMemo, useRef } from 'react'
import { participantsActionCreators } from 'store/logic/participants-logic'
import { ApplicationState } from 'store/types/common'
import VictoryRoadMatches from './VictoryRoadMatches'
import { matchesActionCreators } from 'store/logic/match-logic'
import { roundsActionCreators } from 'store/logic/rounds-logic'
import { makeGetRoundsByTournamentDetailId } from 'store/selectors/rounds-selectors'
import VictoryRoadTournamentCard from './VictoryRoadTournamentCard'
import styles from './list.module.scss'
import { cn } from 'utils'
import { LoadingBox, Select } from 'components/_shared'
import { RoundType } from 'store/types/rounds-types'
import { TournamentType } from 'store/types/tournaments-types'
import { matchLinksActionCreators } from 'store/logic/match-links-logic'
import { makeGetTournamentMatchesLoading } from 'store/selectors/match-selectors'

interface Props {
  tournament: TournamentType
  index: number
}

const VictoryRoadListCard = ({ tournament, index }: Props) => {

  const [selectedRound, setSelectedRound] = React.useState<RoundType>()
  const ref = useRef<HTMLDivElement>()

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(participantsActionCreators.loadParticpants(tournament.TournamentDetailId))
    dispatch(matchesActionCreators.loadTournamentMatches(tournament.TournamentDetailId))
    dispatch(matchLinksActionCreators.loadTournamentMatchLinks(tournament.TournamentDetailId))
    dispatch(roundsActionCreators.loadRounds(tournament.TournamentDetailId))
  }, [dispatch, tournament.TournamentDetailId])

  const getRounds = React.useMemo(makeGetRoundsByTournamentDetailId, [])
  const rounds = useSelector((state: ApplicationState) => getRounds(state, { tournamentDetailId: tournament.TournamentDetailId }))

  const getTournamentMatchesLoading = useMemo(makeGetTournamentMatchesLoading, [])
  const tournamentMatchesLoading = useSelector((state: ApplicationState) => getTournamentMatchesLoading(state, tournament.TournamentDetailId))

  React.useEffect(() => {
    if (!selectedRound) {
      if (rounds && rounds[0] && rounds[0].Id) {
        setSelectedRound(rounds[0])
      }
    }
  }, [rounds, selectedRound])


  const handleMoveLeft = (event: React.MouseEvent) => {
    event.preventDefault()
    ref.current.scrollLeft -= 100
  }

  const handleMoveRight = (event: React.MouseEvent) => {
    event.preventDefault()
    ref.current.scrollLeft += 100
  }

  return (
    <>
      <div className={cn('row', 'm-0--25', styles.article__tournamentContainer, 'flex-xl-nowrap', 'flex-wrap', 'm--fix')}>
        <VictoryRoadTournamentCard tournament={tournament} />
        <div className={cn('col-xl', 'col-12', 'p-0-10', 'article__tournamentMovies', 'movies')}>
          <LoadingBox loading={tournamentMatchesLoading} size="average">
            <div className="row">
              <div className="col">
                <Select
                  options={rounds}
                  onChange={setSelectedRound}
                  selected={selectedRound}
                  labelExtractor={(round: RoundType) => round.RoundTitle}
                  valueExtractor={(round: RoundType) => round.Id}
                  type="single"
                  containerClassName={styles.movies__select}
                />
              </div>
              <div className="col-auto">
                <div className="row m-0">
                  <a
                    className={styles.movies__prev}
                    onClick={handleMoveLeft}
                  />
                  <a
                    className={styles.movies__next}
                    onClick={handleMoveRight}

                  />
                </div>
              </div>
            </div>
            {selectedRound && (

              <div className={cn('row', styles.movies__slides, 'flex-nowrap')} id={`movies__slides${index}`} ref={ref}>
                <VictoryRoadMatches roundId={selectedRound.Id} />
              </div>
            )}
          </LoadingBox>
        </div>
      </div>
    </>
  )
}

export default VictoryRoadListCard
