import { ContainerName, useAlertModal, useAppSelector, useDialog, useGetImageSize } from 'components/_hooks'
import { UpdateImageModal } from 'components/_modals'
import { Img, LoadingBox } from 'components/_shared'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useUploadCoverImage } from 'store/logic/user-logic'
import { getAppUser } from 'store/selectors/app-selectors'
import { makeGetIsUpdateCoverImageLoading } from 'store/selectors/user-selectors'
import { ImageType, ImageTypeEnum } from 'store/types/image-types'

const getImageType = () => ImageTypeEnum.OrganizerCoverImage

export const OrganizerCoverImage = () => {
  const user = useSelector(getAppUser)
  const uploadCoverImage = useUploadCoverImage(user?.Id)

  const getIsUpdateCoverImageLoading = useMemo(makeGetIsUpdateCoverImageLoading, [])
  const isUpdateCoverImageLoading = useAppSelector(state => getIsUpdateCoverImageLoading(state, { userId: user?.Id }))
  
  const handleSaveImage = async (image: ImageType) => {
    try {
      await uploadCoverImage(image.Id)
    }
    catch {
      openAlertModal('Cover Image not saved, please try again')
    }
  }

  const [alertModal, openAlertModal] = useAlertModal()
 
  const [updateImageModal, openUpdateImageModal] = useDialog(closeDialog => (
    <UpdateImageModal
      onClose={closeDialog}
      header="Update the Cover Image"
      dropText="Drag a picture to this window"
      fileTypes={['image/jpeg', 'image/jpg', 'image/png']}
      recommendedText="Note: Please choose a JPG, Png file from your computer"
      getImageTypeCallback={getImageType}
      saveImage={handleSaveImage}
      aspectRatioType="organizerCover"
      crop
    />
  ))
  const imageSize = useGetImageSize(ContainerName.OrganizerCoverProfile)

  return (
    <>
      <div className="pfl_img">
        <div className="pfl_img_img" style={{ height: '300px', overflow: 'hidden' }}>
          {' '}
          <LoadingBox size="big" loading={isUpdateCoverImageLoading}>
            <Img parentContainerSize={imageSize} className="img-fluid" imageId={user?.CoverImageId} style={!user?.AvatarId ? { opacity: '0.5' } : undefined} />
          </LoadingBox>
        </div>
        <div
          className="pfl_img_upload"
          onClick={openUpdateImageModal}
        >
          <label className="btn-upload-dark" style={{ zIndex: 99 }}>
            <img src="https://kombatlink-assets.azureedge.net/img/camera.svg" alt="" />
            Update cover image
          </label>
        </div>
      </div>
      {alertModal}
      {updateImageModal}
    </>
  )
}
