
import { Loading } from "components/_shared"
import { SelectedSeatsModel, ShoppingCardModel } from "models/shopping-card-model"
import { EventTypeEnum } from "models/tournament-models"
import * as React from "react"
import { GeneralUtils } from "server/utils/general-utils"
import { TournamentCartUtils } from '../../server/utils/tournament-cart-utils'
import { round2Places } from "utils/math"
import { defaultFormatDate, defaultFormatTime } from "utils"
import {isMobileOnly,isTablet,isDesktop} from 'react-device-detect';
import { TournamentModeEnum } from "consts"
import { TicketSeatingArrangementModal } from "components/_shared/TournamentWizard/_shared/TicketSeatingArrangement/TicketSeatingArrangementModal"
import { APP_NAMESPACE } from "components/_shared/TournamentWizard/_shared/TicketSeatingArrangement/SectionArrangement/utils/constant/constant"
interface Props {
  ShoppingCard: ShoppingCardModel
  addQuantity: (ShoppingCard: ShoppingCardModel,isAddition:boolean,SeatId:number,SectionId:number) => void
  index: number
  deleteCartItem: (cardDetailId: number, IsParticipant: boolean) => void
  CurrentLoyaltyPointsExchangeRate: number
  CurrentCryptoCurrencyExchangeRate: number
  paymentMethod: string
  LoyaltyPointsValue: number
  updateQuantityLoader:number
 // ShoppingCardList:ShoppingCardModel[]
  setSelectedSeats:(Id:number,seats:SelectedSeatsModel[],CardId:number)=>void
  
  
}

interface State {
  // ShoppingCard: ShoppingCardModel
  CurrentLoyaltyPointsExchangeRate: number
  CurrentCryptoCurrencyExchangeRate: number
  paymentMethod: string
  LoyaltyPointsValue: number
  updateQuantityLoader:number
  IsAdd:boolean
}

class ShoppingCardComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      // ShoppingCard: Object(),
      CurrentLoyaltyPointsExchangeRate: 1,
      CurrentCryptoCurrencyExchangeRate: 1,
      paymentMethod: "",
      LoyaltyPointsValue: 1,
      updateQuantityLoader:0,
      IsAdd:false
      
    }
    this.onchangeShopingCard = this.onchangeShopingCard.bind(this)
    this.init = this.init.bind(this)
    this.validateTicket = this.validateTicket.bind(this)
    this.addRemoveSeats = this.addRemoveSeats.bind(this)
    this.handleGetSelectedSeats = this.handleGetSelectedSeats.bind(this)
    this.handleSetUpdateQuantityLoader = this.handleSetUpdateQuantityLoader.bind(this)
    this.calculateSingleTicketPrice = this.calculateSingleTicketPrice.bind(this)
    this.calculateShoppingCardTotalPrice = this.calculateShoppingCardTotalPrice.bind(this)
  }

  componentDidMount() {
    this.init(this.props)
  }

  componentWillReceiveProps(props: Props) {
    this.init(props)
  }
  
  init(props: Props){
    
    // this.setState({
    //   //  ShoppingCard: {...props.ShoppingCard},
    //   CurrentLoyaltyPointsExchangeRate: props
    //     .CurrentLoyaltyPointsExchangeRate,
    //   CurrentCryptoCurrencyExchangeRate: props
    //     .CurrentCryptoCurrencyExchangeRate,
    //   paymentMethod: props.paymentMethod,
    //   LoyaltyPointsValue: props.LoyaltyPointsValue,
    //   updateQuantityLoader:props.updateQuantityLoader
      
    // })
  }
  closeModel=()=>{
    this.setState({
      IsAdd:false
    })
    return true
  }
  
  async onchangeShopingCard(ShoppingCard:ShoppingCardModel,isAddition: boolean,SeatId:number = 0,SectionId:number = 0) {
    
    
    let isValid: boolean = false
    
    ShoppingCard.IsTournamentExpired=false
    ShoppingCard.IsValid=true
    const quantity = isAddition?ShoppingCard.Quantity + 1:ShoppingCard.Quantity-1
    // if(ShoppingCard.Quantity > ShoppingCard.MaxPurchaseTicketAvailable){
    //   ShoppingCard.IsValid=ShoppingCard.IsTournamentExpired?false:true
    //   ShoppingCard={...ShoppingCard}
    //   this.props.addQuantity(ShoppingCard,isAddition)
    //   return
    // }
    
    var result = await this.validateTicket(ShoppingCard,SectionId,SeatId,quantity,isAddition)
    if(!result?.isError && result?.data){
        
        if(!result?.data.IsValid || !result?.data.IsTournamentValid){
          isValid=false
          if(result.data.Message !== ''){
            ShoppingCard.TicketMessage = result?.data.Message
          }
          else{
            ShoppingCard.TicketMessage = ''
          }
          ShoppingCard.IsTournamentExpired=!result.data.IsTournamentValid
          ShoppingCard.IsValid=false
          ShoppingCard={...ShoppingCard}
          this.props.addQuantity(ShoppingCard,isAddition,SeatId,SectionId)
          
        }
        else{
          ShoppingCard.Quantity = quantity
          isValid=true
          ShoppingCard.IsTournamentExpired=false
          ShoppingCard.IsValid=true
        }
      }
      if(ShoppingCard.ParticipantTicket && isValid){
         
         //const quantity = isAddition?ShoppingCard.Quantity + 1:ShoppingCard.Quantity-1
         if(quantity === 1 || quantity === 0){
           ShoppingCard.Quantity = quantity
           ShoppingCard.IsTournamentExpired=false
           ShoppingCard.IsValid=true
           isValid = true
           this.props.addQuantity(ShoppingCard,isAddition,SeatId,SectionId)
         }
         else{
          isValid=false
         }
  
      }
      // else if(!ShoppingCard.ParticipantTicket && isValid){
        
      //     if (
      //       isAddition &&
      //       ShoppingCard.Time === null
      //     ) {
      //       //const quantity = ShoppingCard.Quantity + 1
      //       isValid=false
      //       if (quantity > ShoppingCard.MaxPurchaseTicketAvailable) {
      //         isValid = false
              
              
      //         return
      //       } else {
      //         isValid = true
      //         ShoppingCard.Quantity = quantity
              
      //       }
      //     } else if (
      //       isAddition &&
      //       ShoppingCard.Time !== null
      //     ) {
            
      //       //const quantity = ShoppingCard.Quantity + 1
      //       if (quantity > ShoppingCard.MaxVenueSeatingCapacityQuantity) {
      //         isValid = false
              
      //         return
      //       } else {
      //         isValid = true
      //         ShoppingCard.Quantity = quantity
      //       }
      //     }
      //     if (!isAddition && ShoppingCard.Quantity > 0) {
      //       isValid = true
      //       //const quantity = ShoppingCard.Quantity - 1
      //       ShoppingCard.Quantity = quantity
      //     }
        
      // }
      if (ShoppingCard.Quantity >= 0 && isValid) {
        ShoppingCard.Price = this.calculateShoppingCardTotalPrice(ShoppingCard).toString()
        // if (paymentMethod === "Credit"){
        //   ShoppingCard.Price = (
        //     round2Places(ShoppingCard.LocalTicketPrice, 2) *
        //     ShoppingCard.Quantity *
        //     round2Places(ShoppingCard.ExchangeRate,2)
        //   ).toString()
        // }
        // else if (paymentMethod === "LoyaltyPoints"){
        //   ShoppingCard.Price = round2Places(
        //     CurrentLoyaltyPointsExchangeRate *
        //       LoyaltyPointsValue *
        //       (ShoppingCard.LocalTicketPrice * ShoppingCard.Quantity * round2Places(ShoppingCard.ExchangeRate,2)),
        //     2
        //   ).toString()
          
        // }
        // else{
        //   ShoppingCard.Price = round2Places(
        //     CurrentCryptoCurrencyExchangeRate *
        //       Number(
        //         ShoppingCard.LocalTicketPrice * (round2Places(ShoppingCard.ExchangeRate,2) * ShoppingCard.Quantity)
        //       ),
        //     2
        //   ).toString()
        // }
        ShoppingCard={...ShoppingCard}
        
        
        this.props.addQuantity(ShoppingCard,isAddition,SeatId,SectionId)
        
        
      }
    //})
    
  }
  calculateShoppingCardTotalPrice(ShoppingCard:ShoppingCardModel){
    const{paymentMethod,CurrentLoyaltyPointsExchangeRate,CurrentCryptoCurrencyExchangeRate,LoyaltyPointsValue} = this.props
    let totalPrice = 0
    if(ShoppingCard.SelectedSeats===null){
      return totalPrice
    }
    if(paymentMethod === "Credit"){
      totalPrice = ShoppingCard.SelectedSeats.filter(x=>x.IsTaken === true && x.IsPurchased === false && x.IsSoldOutSectionSeats!==true && x.IsTournamentValid===true).reduce((sum, record) => {
        return (sum + round2Places(record.LocalTicketPrice, 2) *
        round2Places(ShoppingCard.ExchangeRate,2))
      }, 0)
    }
    else if(paymentMethod === "LoyaltyPoints"){
      totalPrice=ShoppingCard.SelectedSeats.filter(x=>x.IsTaken === true && x.IsPurchased === false && x.IsSoldOutSectionSeats!==true && x.IsTournamentValid===true).reduce((sum, record) => {
          return Math.round(sum + round2Places(
            CurrentLoyaltyPointsExchangeRate *
              LoyaltyPointsValue *
              (record.LocalTicketPrice *  round2Places(ShoppingCard.ExchangeRate,2)),
            2
          ))
        }, 0)
    }
    else{
      totalPrice=ShoppingCard.SelectedSeats.filter(x=>x.IsTaken === true && x.IsPurchased === false && x.IsSoldOutSectionSeats!==true && x.IsTournamentValid===true).reduce((sum, record) => {
        return (sum + round2Places(
          CurrentCryptoCurrencyExchangeRate *
            Number(
              record.LocalTicketPrice * (round2Places(ShoppingCard.ExchangeRate,2))
            ),
          2
        ))
      }, 0)
    }
    
    return totalPrice
    
  }
  calculateSingleTicketPrice(LocalTicketPrice:number,Quantity:number,ShoppingCard:ShoppingCardModel){
    const {paymentMethod,CurrentLoyaltyPointsExchangeRate,LoyaltyPointsValue,CurrentCryptoCurrencyExchangeRate} = this.props
    let price:string = '0'
    if (paymentMethod === "Credit"){
      price = (
        round2Places(LocalTicketPrice, 2) *
        Quantity *
        round2Places(ShoppingCard.ExchangeRate,2)
      ).toString()
    }
    else if (paymentMethod === "LoyaltyPoints"){
      price = Math.round(round2Places(
        CurrentLoyaltyPointsExchangeRate *
          LoyaltyPointsValue *
          (LocalTicketPrice * Quantity* round2Places(ShoppingCard.ExchangeRate,2)),
        2
      )).toString()
      
    }
    else{
      price = round2Places(
        CurrentCryptoCurrencyExchangeRate *
          Number(
            LocalTicketPrice * (round2Places(ShoppingCard.ExchangeRate,2) * Quantity)
          ),
        2
      ).toString()
    }
    return price
  }
  async setCartAdd(SectionRowSeatId:number,IsAdd: boolean,SectionId:number=0,SectionPrice:number=0) {
    
    const { ShoppingCard } = this.props
    if(IsAdd){
      
      let seatModel:SelectedSeatsModel=Object()
      seatModel.IsTaken = true
      seatModel.RowId = 0
      seatModel.SectionId=SectionId
      seatModel.TicketId = ShoppingCard.TicketId
      seatModel.IsPurchased = false
      seatModel.LocalTicketPrice = SectionPrice
      seatModel.Price = this.calculateSingleTicketPrice(SectionPrice,1,ShoppingCard)
      seatModel.SeatId =SectionRowSeatId
      seatModel.SectionId=SectionId
      seatModel.IsAdd = IsAdd
      seatModel.IsTournamentValid= true
      seatModel.IsSoldOutSectionSeats= false
      if(ShoppingCard.SelectedSeats === null || (ShoppingCard.SelectedSeats !== null && ShoppingCard.SelectedSeats.length === 0)){
        seatModel.CartDetailId = 0
      }
      else{
        const index = ShoppingCard.SelectedSeats.findIndex(x=>x.SectionId === SectionId && x.TicketId === ShoppingCard.TicketId && x.IsTaken === true && x.IsPurchased === false && x.IsSoldOutSectionSeats!==true && x.IsTournamentValid===true)
        if(index >= 0)
          seatModel.CartDetailId = ShoppingCard.SelectedSeats[index].CartDetailId
        else
          seatModel.CartDetailId = 0
      }
      
      if(ShoppingCard.SelectedSeats === null || (ShoppingCard.SelectedSeats !== null && ShoppingCard.SelectedSeats.length === 0)){
        seatModel.CartId = 0
      }
      else{
        const index = ShoppingCard.SelectedSeats.findIndex(x=>x.SectionId === SectionId && x.TicketId === ShoppingCard.TicketId && x.IsTaken === true && x.IsPurchased === false && x.IsSoldOutSectionSeats!==true && x.IsTournamentValid===true)
        if(index >= 0)
          seatModel.CartId = ShoppingCard.SelectedSeats[index].CartId
        else
          seatModel.CartId = 0
        
      }
      if(ShoppingCard.SelectedSeats === null || (ShoppingCard.SelectedSeats !== null && ShoppingCard.SelectedSeats.length === 0)){
        ShoppingCard.SelectedSeats=[]
      }
      ShoppingCard.SelectedSeats.push({...seatModel})
    }
    else{
      if(ShoppingCard.SelectedSeats && ShoppingCard.SelectedSeats.length > 0){
        const index = ShoppingCard.SelectedSeats.findIndex(x=>x.SeatId === SectionRowSeatId && x.SectionId == SectionId && x.TicketId == ShoppingCard.TicketId && x.IsTaken === true && x.IsPurchased === false && x.IsSoldOutSectionSeats!==true && x.IsTournamentValid===true)
        ShoppingCard.SelectedSeats[index].IsAdd = IsAdd
        //ShoppingCard.SelectedSeats[index].IsTaken = IsAdd
      }
    }
    
    await this.onchangeShopingCard({...ShoppingCard},IsAdd,SectionRowSeatId,SectionId)
    
    
    
  }
  async validateTicket(ShoppingCard:ShoppingCardModel,SectionId:number,SeatId:number,Quantity:number,isAddition:boolean){
    var result = await TournamentCartUtils.ValidateTournamentTickets(ShoppingCard.TournamentDetailId,ShoppingCard.TicketId,SectionId,SeatId,Quantity,isAddition)
    return result
    
  }
  renderImageAsPerScreenSize(ShoppingCard:ShoppingCardModel){
    if(isMobileOnly){
      return ShoppingCard?.TicketImageThumbnail
    }
    else if(isTablet || isDesktop){
      return ShoppingCard?.TicketImageSmall
    }
    else{
      return ShoppingCard?.TicketImage
    }
  }
  async addRemoveSeats(IsAdd:boolean,SectionRowSeatId:number,SectionId:number,SectionPrice:number){
    await this.setCartAdd(SectionRowSeatId,IsAdd,SectionId,SectionPrice)
  }
  handleGetSelectedSeats= (Id:number)=>{
    const {ShoppingCard} = this.props
    this.props.setSelectedSeats(Id,ShoppingCard.TicketId,ShoppingCard.Id)
    
  }
  handleSetUpdateQuantityLoader=(Id:number)=>{
    this.setState({
      updateQuantityLoader:Id
    })
  }


  render() {
    
    const {updateQuantityLoader,ShoppingCard,CurrentLoyaltyPointsExchangeRate,
      CurrentCryptoCurrencyExchangeRate,
      paymentMethod,
      LoyaltyPointsValue} = this.props
    // const {
      
    //   CurrentLoyaltyPointsExchangeRate,
    //   CurrentCryptoCurrencyExchangeRate,
    //   paymentMethod,
    //   LoyaltyPointsValue
      
    // } = this.state

    //const quantityButtonDisable =
    //   ShoppingCard.Quantity <=
    //  ShoppingCard.VenueSeatingCapacity
    //    ? false
    //    : true
    // const quantityButtonDisable =
    //   ShoppingCard.AttendenceCount + ShoppingCard.Quantity <=
    //   ShoppingCard.VenueSeatingCapacity
    //     ? false
    //     : true
    return (
      <>
        <div
          key={ShoppingCard.Id} data-arr={ShoppingCard.Id}
          className={`orders__order order ${
            ShoppingCard.ParticipantTicket ? "order--participant" : ""
          } ${!ShoppingCard.ParticipantTicket ? "order--viewer" : ""} ${
            !ShoppingCard.IsValid ? "disable-cart" : ""
          } ${
            ShoppingCard.IsTournamentExpired ? "disable-cart" : ""
          }`}
        >
          {/* {!ShoppingCard.IsValid && <label>Not Available</label>}
          {ShoppingCard.IsTournamentExpired && <label>Tournament Expired</label>} */}
          {/* {ShoppingCard.Quantity>=1 && <button
            className="order__remove"
            onClick={async () => {
              this.props.deleteCartItem(
                ShoppingCard.CartDetailId,
                ShoppingCard.ParticipantTicket
              )
            }}
          />} */}
          <div className="bg-trans">
            <div className="row m-0 d-flex ">
              <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 p-0 ">
                <img
                  className="col-12 order__picture lazyload"
                  src={this.renderImageAsPerScreenSize(ShoppingCard)}
                  alt="tournament"
                />
                <div className="col-12 pull-left">
                  <div className="order__organizer d-lg-block d-flex flex-column">
                    {ShoppingCard.Organizer}
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 p-0 ">
                <div className="col-12 col-sm-12">
                  <div className="col-12 col-md-auto p-0">
                    <section className="order__info">
                      <h2 className="order__title">
                        {ShoppingCard.Title}
                      </h2>
                    </section>
                  </div>
                  <div className="row m-0 d-flex">
                    <div className="col-6 p-0">
                      <section className="order__info">
                        <div className="col-6 p-0 order__info-header">Date</div>
                        <div className="col-6 p-0 order__info-text">{defaultFormatDate(ShoppingCard.DisplayTicketDate)}</div>
                      </section>
                    </div>
                    <div className="col-6 p-0">
                      <section className="order__info">
                        <div className="col-6 p-0 order__info-header">Time</div>
                        <div className="col-6 p-0 order__info-text">{defaultFormatTime(ShoppingCard.DisplayTicketDate)}</div>
                      </section>
                    </div>
                    <div className="col-6 p-0">
                      <section className="order__info">
                        <div className="col-6 p-0 order__info-header">
                        Location
                        </div>
                        <div className="col-6 p-0 order__info-text">
                          {ShoppingCard.Location}
                        </div>
                      </section>
                    </div>
                    
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 p-0 ">
                <div className="row adjestPriceSect align-items-center">
                  
                  {ShoppingCard.ParticipantTicket && (
                    ShoppingCard.Quantity === 0?<div aria-disabled={updateQuantityLoader > 0 || ShoppingCard.TournamentModeEnum == TournamentModeEnum.Clans} className={`col-12 col-sm-4 col-md-8  d-flex ${updateQuantityLoader> 0 ? "disable-cart":""}`}>
                    <button
                      className={`blue-button blue-button--filled btn btn-lg col-12 col-sm-4 col-md-8 ${updateQuantityLoader > 0 ? "disable-cart":""}`}
                      onClick={() => {
                        
                          this.setCartAdd(0,true)
                        
                        
                      }}
                    >
                      ADD
                    </button></div>
                    : <div className="col-12 col-sm-4 col-md-8  d-flex">
                    <div  className={`col-4 ${updateQuantityLoader > 0 || ShoppingCard.TournamentModeEnum == TournamentModeEnum.Clans ? "disable-cart" : ""}`}>
                      <button
                        className="order__reduce"
                          disabled={updateQuantityLoader > 0}
                        onClick={async() => {
                         
                            await this.onchangeShopingCard(ShoppingCard,false)
                          
                         
                        }}
                      ></button>
                    </div>

                    <div className="col-4">
                      <div className="order__count">
                        {ShoppingCard.ParticipantTicket &&
                          ShoppingCard.Quantity}
                      </div>
                    </div>

                    <div  className={`col-4 ${updateQuantityLoader > 0 || ShoppingCard.TournamentModeEnum == TournamentModeEnum.Clans? "disable-cart" : ""}`}>
                      <button
                        className="order__add"
                        disabled={updateQuantityLoader > 0}
                        onClick={async() => {
                            await this.onchangeShopingCard(ShoppingCard,true)

                        }}
                      ></button>
                    </div>
                  </div>
                  )}
                  {!ShoppingCard.ParticipantTicket && (
                     <div  className="col-12 col-sm-4 col-md-8  d-flex">
                    <button
                    className={`blue-button blue-button--filled btn btn-lg col-12 col-sm-4 col-md-8 ${
                      updateQuantityLoader > 0 ? "disable-cart" : ""}`}
                      //disabled={updateQuantityLoader > 0}
                      onClick={() => {
                        const detail = ShoppingCard.TicketSection.map((x:any)=>{
                          let shapeProperties =JSON.parse(x.ShapeProperties)
                          shapeProperties.Id = x.Id
                          x.ShapeProperties = JSON.stringify(shapeProperties)
                          return x
                        })
                        localStorage.setItem(APP_NAMESPACE,JSON.stringify(detail))
                        localStorage.setItem("DefaultLayOut","1")
                        
                        this.setState({
                          IsAdd:true
                        })
                          //this.setCartAdd(true)
                        
                        
                      }}
                    >
                      ADD
                    </button>
                  {/* </div>:
                     <div  className="col-12 col-sm-4 col-md-8  d-flex">
                     <div className={`col-4 ${updateQuantityLoader > 0 ? "disable-cart" : ""}`}>
                       <button
                         disabled={updateQuantityLoader > 0}
                         className="order__reduce"
                         onClick={async() => {
                          
                            await this.onchangeShopingCard(false)
                           
                           
                         }}
                       ></button>
                     </div>

                     <div className="col-4">
                       <div className="order__count">
                         {!ShoppingCard.ParticipantTicket &&
                           ShoppingCard.IsAdd &&
                           ShoppingCard.Quantity}
                       </div>
                     </div>

                     <div  className={`col-4 ${updateQuantityLoader > 0 ? "disable-cart" : ""}`}>
                       <button
                         className="order__add"
                         disabled={updateQuantityLoader > 0}
                         onClick={async () => {
                            await this.onchangeShopingCard(true)
                           
                           
                         }}
                       ></button>
                     </div>*/}
                   </div> 
                  )}
                  

                  <div className="col-12 col-sm-4 col-md-4 ">
                    {paymentMethod === "Credit" && (
                      <div className="order__price">
                        {" "}
                        {ShoppingCard.ParticipantTicket
                          ? "Free"
                          : `${ShoppingCard.CurrencySymbol}` +
                            `${round2Places(this.calculateShoppingCardTotalPrice(ShoppingCard),2)}`+' '+ `${ShoppingCard.CurrencyCode}`}
                      </div>
                    )}
                    {paymentMethod === "Crypto" && (
                      <div className="order__price">
                        {" "}
                        {ShoppingCard.ParticipantTicket
                          ? "Free"
                          : "$" +
                            `${round2Places(this.calculateShoppingCardTotalPrice(ShoppingCard),2)} USD`}
                      </div>
                    )}
                    {paymentMethod === "LoyaltyPoints" && (
                      <div className="order__price">
                        {" "}
                        {ShoppingCard.ParticipantTicket
                          ? "Free"
                          : `${round2Places(this.calculateShoppingCardTotalPrice(ShoppingCard),2)} LP`}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* {ShoppingCard.TicketMessage!=='' && (ShoppingCard.IsTournamentExpired || ShoppingCard.IsValid) &&
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 mt-2 text-center">
                  <span className="text-danger text-center" style={{whiteSpace: 'normal'}}>
                 {ShoppingCard.TicketMessage}
                  </span>
                </div>
              </div>
          }
          {} */}
          {this.state.IsAdd && 
          <TicketSeatingArrangementModal
          //ShoppingCardList={this.state.ShoppingCardList}
          ShoppingCard={ShoppingCard} 
          tournamentSeatModel={{TournamentTicketSection:ShoppingCard.TicketSection,LayOutSectionId:1}} 
          close={()=>{this.closeModel()}} 
          closeDialog={()=>{this.closeModel()}} 
          IsCartPage={true} 
          onAddRemoveSeats={this.addRemoveSeats} 
          getSelectedSeats={this.handleGetSelectedSeats}
          updateQuantityLoader={updateQuantityLoader}
          setUpdateQuantityLoader={this.handleSetUpdateQuantityLoader}
          paymentMethod={paymentMethod}
          CurrentLoyaltyPointsExchangeRate={CurrentLoyaltyPointsExchangeRate}
          CurrentCryptoCurrencyExchangeRate={CurrentCryptoCurrencyExchangeRate}
          LoyaltypointsValue={LoyaltyPointsValue} />}
          
        </div>
      </>
    )
  }
}

export default ShoppingCardComponent
