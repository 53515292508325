
import React, { useRef, useState } from 'react'
import QrReader from 'react-qr-reader'
import TicketQRCode, { TicketQRData } from './TicketQRCode'
import styles from './QRScanner.module.scss'
import { cn, round2Places } from 'utils'
import { ArticleHeader, Button } from 'components/_shared'
import TicketDetails from './TicketDetails'
import { TournamentTicketUtils } from 'server/utils/tournament-ticket-utils'
import { TournamentUtils } from 'server/utils/tournament-utils'
import { TournamentCartUtils } from 'server/utils/tournament-cart-utils'
import { TicketValidationPostModel, TournamentTicketViewModel } from 'models/tournament-tickets-viewmodel'
import { TournamentStatusEnum } from 'consts/TournamentStatusId'
import { getAppUser } from 'store/selectors/app-selectors'
import { useSelector } from 'react-redux'
import { TournamentCartRefundStatus } from 'consts/TournamentCartRefundStatus'
import { ScanModeType } from 'consts/ScanModeType'

enum invalidCodeEnum {
  InvalidCode = 0,
  DontMatchData = 1,
  EventCanceled = 2,
  TicketRefunded = 3,
  AlreadyChecedkIn = 4,
  TicketAlreadyScanned = 5,
  EventFinished = 6,
  InvalidPurchase = 7,
  InvalidTicket = 8,
  InternalServerError = 9,
  InvalidOrganizer = 10,
  TicketRefundedPending = 11,
  TournamentNotStarted = 12,
  NotValidForToday = 13,
  EmptyCode = 14,
}

const QRScannerNew = () => {

  const [isQrCameraMode, setIsQrCameraMode] = useState(true)
  const [isShowTicketDetail, setIsShowTicketDetail] = useState(false)
  const [invalidCode, setInvalidCode] = useState<invalidCodeEnum>(null)
  const [ticket, setTicket] = useState<TicketQRData>(null)
  const [scanError, setScanError] = useState(false)
  const [scanMode, setScanMode] = useState(ScanModeType.CameraMode)
  const user = useSelector(getAppUser)
  const reader = useRef(null)
  const [ticketCode, setTicketCode] = useState<number>(0)

  const checkTicketScanStatus = async (tournamentTicketQuantityId) => {
    const result = await TournamentTicketUtils.UpdateScanTicketCount(tournamentTicketQuantityId)
    return !result.isError && result.data
  }

  const getTournament = async (tournamentId) => {
    const result = await TournamentUtils.GetTournamentViewModelByID(tournamentId, true)
    return !result.isError && result.data
  }

  const GetTournamentTicketCartDetailById = async (cartDetailId) => {
    const result = await TournamentCartUtils.GetTournamentTicketCartDetailById(cartDetailId)
    return !result.isError && result.data
  }

  const GetTournamentTicketsByTournamentDetailId = async (tournamentDetailId) => {
    const result = await TournamentCartUtils.GetTournamentTicketsByTournamentDetailId(tournamentDetailId)
    return !result.isError && result.data
  }

  const checkValidTicketForDay = (allTickets, todayDate) => {
    const filterResult = allTickets.filter((x) => {
      const date = new Date(x.TicketDate).setHours(0, 0, 0, 0)
      return todayDate === date
    })
    if (filterResult.length > 0) {
      return true
    }
    else {
      return false
    }
  }

  const setError = (errorCode: invalidCodeEnum) => {
    setInvalidCode(errorCode)
    setIsQrCameraMode(false)
    return
  }

  const setTicketData = (tournamentTickets: TournamentTicketViewModel) => {
    const ticketData: TicketQRData = Object()
    ticketData.TournamentTicketQuantityId = tournamentTickets.TournamentTicketQuantityId
    ticketData.TournamentDetailId = tournamentTickets.TournamentDetailId && tournamentTickets.TournamentDetailId
    ticketData.TicketDate = tournamentTickets.TicketDate && tournamentTickets.TicketDate.toString()
    ticketData.TicketCartDetailId = tournamentTickets.TicketCartDetailId
    ticketData.Quantity = tournamentTickets.Quantity && tournamentTickets.Quantity
    ticketData.DayNumber = tournamentTickets.DayNumber && tournamentTickets.DayNumber
    ticketData.TournamentStatusId = tournamentTickets.TournamentStatusId && tournamentTickets.TournamentStatusId
    ticketData.AttendenceCount = tournamentTickets.AttendenceCount ? tournamentTickets.AttendenceCount : 0
    ticketData.userId = tournamentTickets.UserId
    ticketData.ticketPrice = tournamentTickets.PlayerParticipant
      ? 'FREE'
      : tournamentTickets.ClanMember
        ? 'FREE'
        : tournamentTickets.UserPaymentId === 1?
        tournamentTickets.CurrencySymbol + " " + round2Places(tournamentTickets.TicketPrice, 2).toString()
        :tournamentTickets.UserPaymentId === 2?
        "$" + " " + round2Places(tournamentTickets.TicketPrice, 2).toString()
        :round2Places(tournamentTickets.TicketPrice, 2)+" LP"
    ticketData.Name = tournamentTickets.Name
    ticketData.Username = tournamentTickets.Username
    ticketData.CoverImage = tournamentTickets.CoverImage
    ticketData.CoverImageMedium = tournamentTickets.CoverImageMedium
    ticketData.CoverImageSmall = tournamentTickets.CoverImageSmall
    ticketData.CoverImageThumbnail = tournamentTickets.CoverImageThumbnail
    ticketData.Refunded = tournamentTickets.Refunded
    ticketData.SectionName=tournamentTickets.SectionName
    return ticketData
  }

  const scanTicket = async (TournamentTicketQuantityId: any) => {
    const result = await TournamentUtils.TournamentTicket(TournamentTicketQuantityId)
    if (result && result.data) {
      const tournamentTickets = result.data
      const ticketData = setTicketData(tournamentTickets)
      setTicket(ticketData)
      let isValidTicket = true
      const todayDate = new Date().setHours(0, 0, 0, 0)

      const tournamentTicketCartDetail = await GetTournamentTicketCartDetailById(ticketData.TicketCartDetailId)
      const tournament = await getTournament(ticketData.TournamentDetailId)
      const allTournamentTickets  = await GetTournamentTicketsByTournamentDetailId(ticketData.TournamentDetailId)
      const allTickets = allTournamentTickets.filter((x) => {
        return x.DayNumber != null
      })

      const isParticipant = tournamentTickets.PlayerParticipant
      const isClanMember = tournamentTickets.ClanMember
      const startdate = new Date(tournament.StartDate).setHours(0, 0, 0, 0)

      if (tournament.Organizerid !== user?.Id) {
        return setError(invalidCodeEnum.InvalidOrganizer)
      }

      const IsScanned = await checkTicketScanStatus(ticketData.TournamentTicketQuantityId)
      if (!IsScanned) {
        return setError(invalidCodeEnum.TicketAlreadyScanned)
      }

      if (ticketData.TournamentStatusId == TournamentStatusEnum.Cancel) {
        return setError(invalidCodeEnum.EventCanceled)
      }

      if (ticketData.TournamentStatusId == TournamentStatusEnum.Finished) {
        return setError(invalidCodeEnum.EventFinished)
      }

      if (ticketData.Refunded == TournamentCartRefundStatus.Pending) {
        return setError(invalidCodeEnum.TicketRefundedPending)
      }

      if (ticketData.Refunded == TournamentCartRefundStatus.Refunded) {
        return setError(invalidCodeEnum.TicketRefunded)
      }

      if (todayDate < startdate) {
        return setError(invalidCodeEnum.TournamentNotStarted)
      }

      if (isParticipant || isClanMember) {
        const valid = checkValidTicketForDay(allTickets, todayDate)
        if (!valid) {
          return setError(invalidCodeEnum.NotValidForToday)
        }
      }
      else {
        const currentTicket = allTournamentTickets.find((x) => {
          return x.Id === tournamentTicketCartDetail.TicketId
        })
        if (currentTicket) {
          if (currentTicket.DayNumber !== null && currentTicket.TicketDate !== null) {
            const ticketDate = new Date(currentTicket.TicketDate).setHours(0, 0, 0, 0)
            if (todayDate !== ticketDate) {
              return setError(invalidCodeEnum.NotValidForToday)
            }
          }
          else {
            const valid = checkValidTicketForDay(allTickets, todayDate)
            if (!valid) {
              return setError(invalidCodeEnum.NotValidForToday)
            }
          }
        }
        else{
          return setError(invalidCodeEnum.InvalidTicket)
        }
      }

      if (isValidTicket) {
        if (ticketData.Quantity) {
          if (ticketData.AttendenceCount == null) {
            ticketData.AttendenceCount = 0
          }
          if (ticketData.AttendenceCount <= ticketData.Quantity) {
            const tournamentValidation: TicketValidationPostModel = Object()
            tournamentValidation.DayNumber = ticketData.DayNumber
            tournamentValidation.PlayerId = ticketData.userId
            tournamentValidation.TournamentDetailId = ticketData.TournamentDetailId
            tournamentValidation.TournamentTicketQuantityId = ticketData.TournamentTicketQuantityId
            const validate = await TournamentUtils.TournametTicketValidate(tournamentValidation)

            if (!validate.isError && validate.data) {
              let attendencCount = parseInt(ticketData.AttendenceCount.toString())
              attendencCount += 1
              await TournamentCartUtils.UpdateCartAttendenceCount(
                parseInt(ticketData.TicketCartDetailId.toString()),
                parseInt(ticketData.Quantity.toString()),
                attendencCount
              ).then((result) => {
                if (result.isError) {
                  setError(invalidCodeEnum.InternalServerError)
                } else if (!result.isError) {
                  setTicket(ticketData)
                  setIsQrCameraMode(false)
                  setInvalidCode(null)
                  if (reader.current !== null) {
                    reader.current.clearComponent()
                  }
                }
              }).catch((error) => {
                if (error.message === "Limit Reached") {
                  setError(invalidCodeEnum.AlreadyChecedkIn)
                }
                else {
                  setError(invalidCodeEnum.InternalServerError)
                }
              })
            } else if (validate.isError) {
              setError(invalidCodeEnum.InternalServerError)
            } else {
              setError(invalidCodeEnum.InvalidTicket)
            }
          } else {
            setError(invalidCodeEnum.AlreadyChecedkIn)
          }
        }
        else {
          setError(invalidCodeEnum.InvalidPurchase)
        }
      }
    }
    else {
      setTicket(null)
      setIsQrCameraMode(false)
      setInvalidCode(invalidCodeEnum.InvalidCode)
    }
  }

  const handleScan = async (data) => {
    if (data && !scanError) {
      data = JSON.parse(data)
      const ticketCode = data.TournamentTicketQuantityId
      await scanTicket(ticketCode)
    }
    else {
      setInvalidCode(invalidCodeEnum.InvalidTicket)
    }
  }

  const handleScanError = error => {
    setScanError(true)
  }

  const handleTicketDetails = () => {
    setIsShowTicketDetail(true)
  }

  const handleNextScan = () => {
    setIsQrCameraMode(true)
    setTicket(null)
    setInvalidCode(null)
    setScanError(false)
  }
  const handleCloseDetail = () => {
    setIsShowTicketDetail(false)
  }

  const openImageDialog = () => {
    reader.current.openImageDialog()
  }

  const changeScanMode = (mode: ScanModeType) => {
    setScanMode(mode)
    setInvalidCode(null)
  }

  const onManuallyScanTicketByCode = async () => {
    if (ticketCode !== 0) {
      await scanTicket(ticketCode)
    }
    else {
      setInvalidCode(invalidCodeEnum.EmptyCode)
    }
  }

  if (isShowTicketDetail) {
    return (
      <TicketDetails onClose={handleCloseDetail} ticket={ticket} />
    )
  }

  return (
    <>
      <ArticleHeader text="Scan Your Ticket" />
      <div className={cn('row', 'justify-content-center')} id="screenShot">
        <div className={cn('col-12', 'text-center')}>
          {isQrCameraMode && (
            <>
              {scanMode !== ScanModeType.CameraMode && (
                <Button color="white" onClick={() => changeScanMode(ScanModeType.CameraMode)} className={cn('w-auto', 'mb-4', 'd-inline-block', 'mr-4', 'pl-4', 'pr-4')}>Scan QR Code</Button>
              )}
              {scanMode !== ScanModeType.FromImage && (
                <Button color="white" onClick={() => changeScanMode(ScanModeType.FromImage)} className={cn('w-auto', 'mb-4', 'd-inline-block', 'mr-4', 'pl-4', 'pr-4')}>Scan QR Code from file</Button>
              )}
              {scanMode !== ScanModeType.Manually && (
                <Button color="white" onClick={() => changeScanMode(ScanModeType.Manually)} className={cn('w-auto', 'mb-4', 'd-inline-block', 'pl-4', 'pr-4')}>Manually Scan code</Button>
              )}

              {scanMode === ScanModeType.FromImage && (
                <div className={cn('row', 'justify-content-center')}>
                  <Button color="white" onClick={() => openImageDialog()} className={cn('w-auto', 'mb-4', 'mb-4', 'pl-4', 'pr-4')}>Select File</Button>
                </div>
              )}

              {scanMode === ScanModeType.Manually && (
                <div className={cn('row', 'justify-content-center')}>
                  <div>
                    <input
                      className={cn('article__width-1', 'article__height-1', 'article__input ', invalidCode == invalidCodeEnum.EmptyCode ? styles.highlight_invalid_control : '')}
                      type="number"
                      maxLength={10}
                      placeholder="Enter code"
                      onChange={(e) => setTicketCode(parseInt(e.target.value))}
                    />
                  </div>
                  <Button color="white" onClick={() => onManuallyScanTicketByCode()} className={cn('w-auto', 'mb-4', 'pl-4', 'pr-4', 'ml-4')}>Sumbit</Button>

                </div>
              )}
            </>
          )}
        </div>
        <div id="screen" className={cn('col', styles.ticket__scan__container)}>
          {isQrCameraMode ? (
            <>
              {scanMode !== ScanModeType.Manually && (
                <QrReader
                  delay={500}
                  onError={handleScanError}
                  onScan={handleScan}
                  style={{ width: '100%', height: '600px' }}
                  legacyMode={(scanMode === ScanModeType.FromImage ? true : false)}
                  ref={reader}
                />
              )}
            </>
          ) : (

            <>
              {ticket == null ? (
                <>
                  <div className={styles.validation__invalid}>
                    <span>Invalid</span>
                    <img src="https://kombatlink-assets.azureedge.net/img/warning_white.svg" style={{ width: '23px' }} />
                  </div>
                  <div className={styles.validation__invalid__detail}>
                    <span>Failed to parse ticket</span>
                  </div>
                  <div className="row justify-content-between mt-3 mb-5 ml-0 mr-0">
                    <Button color="white" onClick={handleNextScan} className="w-100">New scan</Button>
                  </div>
                </>
              ) : (
                <>
                  <div className={cn('row', 'justify-content-center')}>
                    <div className={cn('col', styles.ticket__scan__wrapper)}>
                      <div className={styles.ticket__scan}>
                        <div className={styles.ticket_id}>#T{ticket && ticket.TournamentDetailId}</div>
                        <div className={styles.qrCode}>
                          <TicketQRCode ticket={ticket} />
                        </div>
                        <div className={styles.ticket_price}>
                          {ticket.ticketPrice}

                        </div>
                      </div>
                    </div>
                  </div>
                  {invalidCode === null ? (
                    <>
                      <div className={styles.validation__valid}>
                        <span>Valid</span>
                        <img src="https://kombatlink-assets.azureedge.net/img/check-white.svg" style={{ width: '23px' }} />
                      </div>
                      <div className="row justify-content-between mt-3 mb-5 ml-0 mr-0">
                        <Button color="grey" filled onClick={handleTicketDetails}>Ticket details</Button>
                        <Button color="white" onClick={handleNextScan}>Next scan</Button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className={styles.validation__invalid}>
                        <span>Invalid</span>
                        <img src="https://kombatlink-assets.azureedge.net/img/warning_white.svg" style={{ width: '23px' }} />
                      </div>
                      {invalidCode == invalidCodeEnum.TicketAlreadyScanned && (
                        <div className={styles.validation__invalid__detail}>
                          <span>Ticket has been already scanned!</span>
                        </div>
                      )}
                      {invalidCode == invalidCodeEnum.EventFinished && (
                        <div className={styles.validation__invalid__detail}>
                          <span>The event has ended!</span>
                        </div>
                      )}
                      {invalidCode == invalidCodeEnum.EventCanceled && (
                        <div className={styles.validation__invalid__detail}>
                          <span>The event has canceled!</span>
                        </div>
                      )}
                      {invalidCode == invalidCodeEnum.TicketRefunded && (
                        <div className={styles.validation__invalid__detail}>
                          <span>Ticket was refunded</span>
                        </div>
                      )}
                      {invalidCode == invalidCodeEnum.InvalidPurchase && (
                        <div className={styles.validation__invalid__detail}>
                          <span>You didn't purchase any ticket</span>
                        </div>
                      )}
                      {invalidCode == invalidCodeEnum.InvalidCode && (
                        <div className={styles.validation__invalid__detail}>
                          <span>Invalid code</span>
                        </div>
                      )}
                      {invalidCode == invalidCodeEnum.AlreadyChecedkIn && (
                        <div className={styles.validation__invalid__detail}>
                          <span>Ticket already used</span>
                        </div>
                      )}
                      {invalidCode == invalidCodeEnum.InvalidTicket && (
                        <div className={styles.validation__invalid__detail}>
                          <span>Ticket is not valid</span>
                        </div>
                      )}
                      {invalidCode == invalidCodeEnum.InternalServerError && (
                        <div className={styles.validation__invalid__detail}>
                          <span>Internal Server Error</span>
                        </div>
                      )}
                      {invalidCode == invalidCodeEnum.InvalidOrganizer && (
                        <div className={styles.validation__invalid__detail}>
                          <span>Invalid organizer</span>
                        </div>
                      )}
                      {invalidCode == invalidCodeEnum.TicketRefundedPending && (
                        <div className={styles.validation__invalid__detail}>
                          <span>Refund pending</span>
                        </div>
                      )}
                      {invalidCode == invalidCodeEnum.TournamentNotStarted && (
                        <div className={styles.validation__invalid__detail}>
                          <span>Event has not started</span>
                        </div>
                      )}
                      {invalidCode == invalidCodeEnum.NotValidForToday && (
                        <div className={styles.validation__invalid__detail}>
                          <span>Ticket not valid for today</span>
                        </div>
                      )}
                      <div className="row justify-content-between mt-3 mb-5 ml-0 mr-0">
                        <Button color="white" onClick={handleNextScan} className="w-100">Next scan</Button>
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default QRScannerNew
