import { stat } from 'fs'
import { Reducer } from 'redux'
import { calcById, getNewObjectWithArray } from 'utils'
import { NEW_CHAT_MESSAGE_LOADED, UNREAD_CHATS_LOADED } from '../chat-message/chat-message-types'
import { CHATS_LOADED, CHAT_LOADED, DISPUTE_CHAT_LOADED, GROUP_CHAT_CREATED, TOURNAMENT_CHAT_LOADED } from '../chat/chat-types'
import { ChatMemberKnownAction, ChatMemberState, CHAT_FILTERED_USERS_LOADED, CHAT_MEMBERS_ADDED, CHAT_MEMBER_LEFT, PRIVATE_CHAT_MEMBERS_LOADED, TYPING_MESSAGE_RECEIVED } from './chat-member-types'

const initialChatMemberState: ChatMemberState = {
  byId: {},
  idsbyChatId: {},
  filteredUsersIds: [],
}

const reducer: Reducer<ChatMemberState, ChatMemberKnownAction> = (state: ChatMemberState | undefined = initialChatMemberState, action: ChatMemberKnownAction) => {
  switch (action.type) {
    case PRIVATE_CHAT_MEMBERS_LOADED:
    case CHAT_LOADED: {
      return {
        ...state,
        byId: calcById(state, action.payload.ChatMembers),
        idsbyChatId: {
          ...state.idsbyChatId,
          ...getNewObjectWithArray(state.idsbyChatId, action.payload.ChatMembers, x => x.ChatId, x => x.Id),
        },
      }
    }
    case DISPUTE_CHAT_LOADED:
    case TOURNAMENT_CHAT_LOADED: {
      return {
        ...state,
        byId: calcById(state, action.payload.chat.ChatMembers),
        idsbyChatId: {
          ...state.idsbyChatId,
          ...getNewObjectWithArray(state.idsbyChatId, action.payload.chat.ChatMembers, x => x.ChatId, x => x.Id),
        },
      }
    }
    case NEW_CHAT_MESSAGE_LOADED:
    case UNREAD_CHATS_LOADED:    
    case GROUP_CHAT_CREATED:
    case CHATS_LOADED: {
      return {
        ...state,
        byId: calcById(state, action.payload.ChatMembers),
        idsbyChatId: {
          ...state.idsbyChatId,
          ...getNewObjectWithArray(state.idsbyChatId, action.payload.ChatMembers, x => x.ChatId, x => x.Id),
        },
      }
    }
    case CHAT_FILTERED_USERS_LOADED: {
      return {
        ...state,
        filteredUsersIds: action.payload.Users.map(u => u.Id),
      }
    }
    case CHAT_MEMBER_LEFT: {
      return {
        ...state,
        idsbyChatId: {
          ...state.idsbyChatId,
          [action.payload.ChatId]: state.idsbyChatId[action.payload.ChatId]
            .filter(id => id != action.payload.ChatMemberId)
        }
      }
    }
    case CHAT_MEMBERS_ADDED: {
      return {
        ...state,
        byId: calcById(state, action.payload.ChatMembers),
        idsbyChatId: {
          ...state.idsbyChatId,
          ...getNewObjectWithArray(state.idsbyChatId, action.payload.ChatMembers, x => x.ChatId, x => x.Id),
        },
      }
    }
    case TYPING_MESSAGE_RECEIVED: {
       const members = state.idsbyChatId[action.payload.ChatId]
       members.map((x) =>{
         const member = state.byId[x]
         if(member.UserId === action.payload.UserId)
         {
            member.isTyping = action.payload.IsTyping
         }
       })

      return {
        ...state,
        byId : {...state.byId}
      }
    }
    default:
      return state
  }
}

export default reducer
