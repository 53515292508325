import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { makeGetImage } from 'store/selectors/image-selectors'
import { ApplicationState } from 'store/types/common'
import { ImageType } from 'store/types/image-types'

interface UseImageResult {
  image?: ImageType
  imageSrc?: string
}

export const useImage = (imageId?: number): UseImageResult => {
  const getImage = useMemo(makeGetImage, [])
  const image = useSelector((state: ApplicationState) => getImage(state, { imageId }))
  const imageSrc = image?.CdnUrl || image?.Url || ''

  return {
    image,
    imageSrc,
  }
}
