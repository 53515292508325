import { useCanManageTournament } from 'components/_hooks'
import { LoadingBox } from 'components/_shared'
import { isActiveRoundStatus, RoundStatus } from 'consts'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { makeGetRoundMatches, makeGetTournamentMatchesLoading } from 'store/selectors/match-selectors'
import { makeGetIsRoundsLoading, makeGetRoundsByTournamentDetailId } from 'store/selectors/rounds-selectors'
import { makeGetTournamentLoading } from 'store/selectors/tournaments-selectors'
import { ApplicationState } from 'store/types/common'
import { MatchStatusEnum } from 'store/types/match-types'
import { BracketGrid } from './BracketGrid'
import { BracketHeader } from './BracketHeader'
import { LoadingLine } from './LoadingLine/LoadingLine'

export interface BracketContainerProps {
  tournamentDetailId: number
  isLosersBracket?: boolean
  filter: string
  openSubmissionResultModal: (v?: any) => void
}

export const BracketContainer = ({ tournamentDetailId, filter, isLosersBracket = false,openSubmissionResultModal }: BracketContainerProps) => {
  const getIsRoundsLoading = useMemo(makeGetIsRoundsLoading, [])
  const getTournamentLoading = useMemo(makeGetTournamentLoading, [])
  const getRoundsByTournamentDetailId = useMemo(makeGetRoundsByTournamentDetailId, [])
  const getTournamentMatchesLoading = useMemo(makeGetTournamentMatchesLoading, [])
  const getRoundMatches = useMemo(makeGetRoundMatches, [])

  const roundsLoading = useSelector((state: ApplicationState) => getIsRoundsLoading(state, tournamentDetailId))
  const tournamentLoading = useSelector((state: ApplicationState) => getTournamentLoading(state, tournamentDetailId))
  const allRounds = useSelector((state: ApplicationState) => getRoundsByTournamentDetailId(state, { tournamentDetailId }))
  const tournamentMatchesLoading = useSelector((state: ApplicationState) => getTournamentMatchesLoading(state, tournamentDetailId))

  const loading = roundsLoading || tournamentLoading

  const filteredRounds = useMemo(() => {
    return allRounds.filter(x => x.IsLosersBracket === isLosersBracket)
  }, [allRounds, isLosersBracket])
  const activeRound = useMemo(() => {
    return filteredRounds.find(x => isActiveRoundStatus(x.Status))
  }, [filteredRounds])
  const filteredRoundsIds = filteredRounds.map(x => x.Id)
  const canManageTournament = useCanManageTournament(tournamentDetailId)
  const matches = useSelector((state: ApplicationState) => getRoundMatches(state, activeRound?.Id))
  const isAllMatchesFinished = useMemo(() => {
    return matches.every(x => x.Status === MatchStatusEnum.Finished)
  }, [matches])
  const lastRound = useMemo(() => {
    return filteredRounds[filteredRounds.length - 1]
  }, [filteredRounds])
  const showLoading = useMemo(() => {
    return isAllMatchesFinished
      && canManageTournament
      && activeRound?.Status === RoundStatus.Active
      && (isLosersBracket || activeRound?.Id !== lastRound?.Id)
  }, [activeRound, canManageTournament, isAllMatchesFinished, isLosersBracket, lastRound])

  return (
    <div className="eli tournament-has-winner--users-hover">
      <div className="eli_inn">
        <LoadingBox loading={loading} size="big">
          <BracketHeader roundsIds={filteredRoundsIds} tournamentDetailId={tournamentDetailId} />
          {showLoading && <LoadingLine />}
          <LoadingBox loading={tournamentMatchesLoading} size="big">
            <div className="eli_bod eli_bod_show">
              <BracketGrid
                roundsIds={filteredRoundsIds}
                isLosersBracket={isLosersBracket}
                tournamentDetailId={tournamentDetailId}
                filter={filter}
                openSubmissionResultModal= {openSubmissionResultModal}
              />
            </div>
          </LoadingBox>
        </LoadingBox>
      </div>
    </div>
  )
}
