import { UserModel } from 'models/user-models'

export const getUser = () => {
  const emptyUser = Object() as UserModel
  
  const userJson = localStorage.getItem('user')
  if (!userJson) {
    return emptyUser
  }

  const user = JSON.parse(userJson) as UserModel
  return user || emptyUser
}
