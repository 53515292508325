import * as React from 'react'
import TransactionsCard from './TransactionsCard'
import { GeneralUtils } from '../../server/utils/general-utils'
import { Link } from 'react-router-dom'
import { TransactionUtils } from '../../server/utils/transaction-utils'
import styles from './Transactions.module.scss'
import { AdminOrganizerTransactionViewModel } from 'models/organizer-transaction-viewmodel'
import { round2Places } from 'utils'
import { LocationUtils } from 'server/utils/location-utils'
interface Props {
  type: number
}

interface State {
  pageNo: number
  totalPages: number
  organizerTransactions: AdminOrganizerTransactionViewModel
  open: boolean
  userCurrencyCode:string,
  userCurrencySymbol:string
}

class AllTransactions extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      totalPages: 1,
      pageNo: 1,
      organizerTransactions: Object(),
      open: false,
      userCurrencyCode:'',
      userCurrencySymbol:''

    }
    this.getTransactions = this.getTransactions.bind(this)
    this.getCurrencyFromCountry = this.getCurrencyFromCountry.bind(this)
  }

  async componentDidMount() {
    await this.getTransactions()
    await this.getCurrencyFromCountry()
  }
  async getCurrencyFromCountry(){
    GeneralUtils.showHideLoadingDiv(true)
    const cordinates: any = await LocationUtils.getCoords()
    const userLocation = await LocationUtils.GetLocationInfo(
      "country",
      cordinates.latitude,
      cordinates.longitude
    )
    const result = await TransactionUtils.GetCurrencyDataFromCountry(userLocation.CountryName)
      if (!result?.isError) {
        this.setState({
          userCurrencyCode : result?.data.currencyCode,
          userCurrencySymbol : result?.data.currencySymbol
        })
      } else {
      GeneralUtils.showError(result?.message)
      GeneralUtils.showHideLoadingDiv(false)
    }
  }
  async getTransactions() {
    GeneralUtils.showHideLoadingDiv(true)
    const result = await TransactionUtils.OrganizerTransaction(this.state.pageNo, this.getPageType())
    if (!result.isError) {
      
      this.setState({
        organizerTransactions: result.data,
        totalPages:
          result.data && result.data.Transactions && result.data.Transactions[0] && result.data.Transactions[0].TotalPages
            ? result.data.Transactions[0].TotalPages
            : 1,
        pageNo:
          result.data && result.data.Transactions && result.data.Transactions[0] && result.data.Transactions[0].PageNo
            ? result.data.Transactions[0].PageNo
            : 1,
      },()=>{
        GeneralUtils.showHideLoadingDiv(false)
      })
      
    } else {
      GeneralUtils.showError(result.message)
      GeneralUtils.showHideLoadingDiv(false)
    }
  }

  getPageType = () => {
    switch (location.pathname) {
      case '/transactions-all':
        return 0
      case '/transactions-pending':
        return 1
      case '/transactions-running':
        return 2
      case '/transactions-available':
        return 3
        case '/transactions-withdraw-list':
        return 4
    }
  }

  render() {
    const {} = this.props
    const { pageNo, totalPages, organizerTransactions, open,userCurrencyCode,userCurrencySymbol } = this.state
    const url = window.location.href
    const isorganizer: number = parseInt(localStorage.getItem('isorganizer'))
    // const id: number = GeneralUtils.getRandomNumber(8)
    const pages = []
    for (let i = 1; i <= totalPages; i++) {
      if (i == pageNo + 5) {
        pages.push(<div className="article__page-dots">...</div>)
      } else if ((!(i < pageNo - 1) && (i < pageNo + 5 || i == totalPages)) || i == 1) {
        pages.push(
          <a
            className={`article__page ${i == pageNo ? 'article__page--active' : ''}`}
            onClick={() => {
              this.setState({ pageNo: i }, () => {
                this.getTransactions()
              })
            }}
          >
            {' '}
            {i}
          </a>
        )
      }
    }

    return (
      <>
        <div className="row">
          <div className="col p-0">
            <h1 className={styles.article__header}>Transactions</h1>
          </div>
        </div>
        <article className={`article ${styles.article__fix}`}>
          <div className="container-fluid p-0">
            <div className="row">
              <div className={`col-12 ${styles.article__tab_menu_before_container}`}>
                <div className="row">
                  <div className="col-md col-12">
                    <button
                      style={{ marginLeft: '-12px', zIndex: 999 }}
                      onClick={() => {
                        document.getElementById('transactionall').scrollLeft -= 50
                      }}
                      className="article__tab-menu-button article__tab-menu-button--left"
                    />
                    <div id="transactionall" className="row article__tab-menu tab-menu align-items-center">
                      <Link
                        id="tab1"
                        className={`${styles.tab_menu__link} ${
                          url.indexOf('transactions-all') > -1 ? `${styles.tab_menu__link__active}` : ''
                        }`}
                        to="/transactions-all"
                      >
                        All
                      </Link>
                      <Link
                        id="tab2"
                        className={`${styles.tab_menu__link} ${
                          url.indexOf('transactions-running') > -1 ? `${styles.tab_menu__link__active}` : ''
                        }`}
                        to="/transactions-running"
                      >
                        {userCurrencySymbol}{organizerTransactions.RunningTournament ? (' ' +round2Places(organizerTransactions.RunningTournament,2)):' 0'}{organizerTransactions && userCurrencyCode&& ' '+userCurrencyCode}
                        <span>Running Tournaments</span>
                      </Link>
                      <Link
                        id="tab3"
                        className={`${styles.tab_menu__link} ${
                          url.indexOf('transactions-pending') > -1 ? `${styles.tab_menu__link__active}` : ''
                        }`}
                        to="/transactions-pending"
                      >
                        {userCurrencySymbol}{organizerTransactions.PendingTournament ? ' ' +round2Places(organizerTransactions.PendingTournament,2) :' 0'}{organizerTransactions && userCurrencyCode && ' '+userCurrencyCode}
                        <span>Pending Payment</span>
                      </Link>
                      <Link
                        id="tab5"
                        className={`${styles.tab_menu__link} ${
                          url.indexOf('/withdraw-list') > -1 ? `${styles.tab_menu__link__active}` : ''
                        }`}
                        to="/transactions-withdraw-list"
                      >
                        {userCurrencySymbol}{organizerTransactions.withdrawAmount ? ' ' +round2Places(organizerTransactions.withdrawAmount,2) : ' 0'}{organizerTransactions && userCurrencyCode && ' '+userCurrencyCode}
                        <span>Withdraw Transactions</span>
                      </Link>
                      <Link
                        id="tab4"
                        className={`${styles.tab_menu__link} ${
                          url.indexOf('transactions-available') > -1 ? `${styles.tab_menu__link__active}` : ''
                        }`}
                        to="/transactions-available"
                      >
                        {userCurrencySymbol}{organizerTransactions.AvailableTournament ? ' ' +round2Places(organizerTransactions.AvailableTournament,2) : ' 0'}{organizerTransactions && userCurrencyCode &&' '+userCurrencyCode}
                        <span>Available</span>
                      </Link>
                      
                    </div>
                    <button
                      style={{ marginRight: '-12px', zIndex: 999 }}
                      onClick={() => {
                        document.getElementById('transactionall').scrollLeft += 50
                      }}
                      className="article__tab-menu-button article__tab-menu-button--right"
                    />
                  </div>
                </div>
              </div>
            </div>
            <input className="table__checkbox" type="checkbox" onChange={()=> {}} id="show-hidden" />
            <div className="col-12 p-0">
              <div className={styles.table__headers_row_all_transaction}>
                <div className={`${styles.table__column} ${styles.table__column__id}`}>Event ID</div>
                <div className={`${styles.table__column} ${styles.table__column__name}`}>Event</div>
                {/* <div className={`${styles.table__column} ${styles.table__column__price}`}>Price</div> */}
                <div className={`${styles.table__column} ${styles.table__column__sales}`}>Sales</div>
                {/* <div className={`${styles.table__column} ${styles.table__column__refunded}`}>Refunded</div> */}
                <div className={`${styles.table__column} ${styles.table__column__venue}`}>Venue</div>
                <div className={`${styles.table__column} ${styles.table__column__date}`}>Event Date</div>
                
                {/* <div className={`${styles.table__column} ${styles.table__column__sale__date}`}>Sale Date</div> */}
                {/* <div className={`${styles.table__column} ${styles.table__column__status}`}>Status</div> */}

                <div className={`my-ticket-table__column--buttons d-flex justify-content-end ${styles.table__column__buttons}`}>
                  <label
                    className="table__column-label"
                    style={{ background: open == true ? '#6cd000' : 'transparent' }}
                    onClick={() => {
                      this.setState({ open: !this.state.open })
                    }}
                  />
                  <div className="table__column-label-text">Show hidden</div>
                </div>

                {/* <div className="table__column table__column--buttons d-flex">
                    <label className="table__column-label" id="show-hidden" onClick={(e) => {
                        e.currentTarget.className = e.currentTarget.className.indexOf("table__column-label") ?
                            "table__column-label table__column-label--open" : "table__column-label";
                    }}>
                    <div className="table__radio-text">Show hidden</div></label>
                </div> */}
              </div>
            </div>
            {organizerTransactions &&
            organizerTransactions.Transactions &&
            organizerTransactions.Transactions.map((trans, i) => {
              return <TransactionsCard key={i} id={trans.EventId} transaction={trans} key={i} CurrencySymbol={organizerTransactions.CurrencySymbol} CurrencyCode={organizerTransactions.CurrencyCode} currentElement={'currentElement'+i} />
            })}

            {isorganizer == 0 && (
              <div className="article-select__title" style={{ color: 'white' }}>
                <h4>Access Denied</h4>
              </div>
            )}

            {totalPages > 1 && (
              <>
                {' '}
                <div className="row article__hr" />
                <div className="row justify-content-center mtb-30">
                  <div className="col-auto article__pages-container">
                    <div className="row align-items-center">
                      {pageNo > 1 && (
                        <a
                          className="article__prev-page"
                          onClick={() => {
                            this.setState({ pageNo: pageNo - 1 }, () => {
                              this.getTransactions()
                            })
                          }}
                        >
                          PREV
                        </a>
                      )}
                      <div className="col-auto">
                        <div className="row align-items-center" id="pages">
                          {pages}
                        </div>
                      </div>
                      {pageNo < totalPages && (
                        <a
                          className="article__next-page"
                          onClick={() => {
                            this.setState({ pageNo: pageNo + 1 }, () => {
                              this.getTransactions()
                            })
                          }}
                        >
                          NEXT
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </article>
      </>
    )
  }
}

export default AllTransactions
