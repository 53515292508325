import * as React from 'react'
import { TournamentViewModel_kl } from 'models/tournament-viewmodel'
import { UserModel } from 'models/user-models'
import GoogleMap from './_shared/GoogleMap/GoogleMap'
import { kl_RegionModel } from 'models/kl_region-model'
import { GeneralUtils } from 'server/utils/general-utils'
import { TimeZoneInfoDTO } from 'store/types/app-types'
import { EventTypeEnum } from 'models/tournament-models'
import { ModalUtils } from 'server/utils/modal-utils'
import { ParticipantType } from 'store/types/participants-types'
import { TournamentModeEnum, TournamentStatusEnum } from 'consts'
import { SelfLoadedUserAvatar } from 'components/_shared'
import { ConvertDateToFormattedDateString, ConvertDateToFormattedDatetimeString, ConvertDateToFormattedTimeString } from 'components/_shared/TournamentWizard/_shared/TimeZoneSelector/ZonedDate'
import TournamentChatOrganizerButton from './_shared/TournamentChatOrganizerButton'
import { ContainerName } from 'components/_hooks'
interface Props {
  tournament: TournamentViewModel_kl
  organizer: UserModel
  regions: kl_RegionModel[]
  isAdmin?: boolean
  timeZone?: TimeZoneInfoDTO
  history?: any
  participants?: ParticipantType[]
}

interface State {
  showRepeatTournaments?: any
  RepeatTournamentsIndex?: any
  hasNoQualifierTournaments?: any
  EventToView?: any
  isChatOpened?: boolean
}

class TournamentDetailComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      showRepeatTournaments: false,
      RepeatTournamentsIndex: null,
      hasNoQualifierTournaments: false,
      EventToView: false,
      isChatOpened: false
    }
  }
  onMainEventClick = e => {
    const { tournament } = this.props
    e.preventDefault()
    ModalUtils.initModals()
    if (tournament.allQualifierTournaments.length === 0) {
      this.setState({ hasNoQualifierTournaments: true })
      ModalUtils.openModal('QualifierTournaments')
    } else {
      if (tournament.allQualifierTournaments && tournament.allQualifierTournaments.length > 1) {
        this.setState({ hasNoQualifierTournaments: false })
        ModalUtils.openModal('QualifierTournaments')
      } else if (tournament.allQualifierTournaments.length === 1 && tournament.allQualifierTournaments[0].length > 1) {
        tournament.allQualifierTournaments[0].map((data, index) => {
        })
        ModalUtils.openModal('QualifierTournaments')
      }
      else {
        this.setState({ hasNoQualifierTournaments: false })
        window.location.href = '/tournament-detail/' + tournament.allQualifierTournaments[0][0].Id
        ModalUtils.closeModals()
      }
    }

  }

  onQualifierTournamentSelect = (item, index) => {
    if (item.length > 1) {
      this.setState({
        showRepeatTournaments: true,
        RepeatTournamentsIndex: index
      })
    } else {
      window.location.href = '/tournament-detail/' + item[0].Id
      ModalUtils.closeModals()
    }
  }

  onQualifierTournamentDateSelect = () => {
    const { EventToView } = this.state
    if (EventToView) {
      window.location.href = '/tournament-detail/' + EventToView
      ModalUtils.closeModals()
    } else {
      alert('Select Tournament Date For View.')
    }
  }
  closeChat = () => this.setState({ isChatOpened: false })
  render() {
    const { tournament, organizer } = this.props
    const { showRepeatTournaments, hasNoQualifierTournaments, EventToView } = this.state
    const user: UserModel = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : Object()
    const isAdmin = location.href.toLowerCase().indexOf('/admin') != -1
    const startDate =
      tournament.Id > 0
        ? ConvertDateToFormattedDatetimeString(tournament.StartDate, tournament.ZonedStartDate, isAdmin, user.isorganizer)
        : `${new Date(tournament.StartDate).toLocaleString()} ${this.props.timeZone && this.props.timeZone.Abbr}`

    // let reg: kl_RegionModel = (regions && regions.find(x => x.Id == (organizer && organizer.RegionId))) && (regions && regions.find(x => x.Id == (organizer && organizer.RegionId)))
    // let region = reg && reg.RegionName ? reg.RegionName : "";
    let isOrganizer = false
    isOrganizer = user && user.id == tournament.Organizerid ? true : false

    return (
      <>
        <div className="row tournament-detail-bottom-content">
          <div className="col-12 p-0  m-r-sm-5 details td__details">
            <div className="row m-0 tournament-column">
              <div className="col-12">
                {tournament.EventTypeId && tournament.EventTypeId == 1 && (
                  <>
                    <div className="article__warning">
                      You must play in a qualifier event to get enroll in this Main event.&nbsp;
                      <a href="#" onClick={this.onMainEventClick}>Click here</a>&nbsp; to see the list of qualifier tournaments.
                    </div>
                  </>
                )}

                {/* || tournament.EventTypeId && tournament.EventTypeId == 2 */}

                {tournament.EventTypeId && tournament.EventTypeId == 2 && (
                  <div className="article__warning">
                    Winner of this tournament will be signed up to the Main Event.
                    <a href={'/tournament-detail/' + tournament.MainTournamentId}>Click here</a> to see the Main event
                    {/* To participate in the tournament, you have to play Qualifier tournament. &nbsp;
                    <a href="/tournaments#pane3">Click here</a>&nbsp; to see list of all qualifier tournaments. */}
                  </div>
                )}
              </div>
            </div>
            <div className="">
              <div className="row m-0 tournament-column">
                <div className="col-12 col-md-12 col-lg- p-0">
                  <div className="col-md-12 p-0">
                    <div className="row m-0">
                      <div className="article-detail__subheader pl-4">General Tournament Details</div>
                    </div>
                    <div className="row" id="details-container">
                      <div className="col-lg-4 col-md-6 col-sm-6 col-12 ">
                        <div className="article-detail__container article-detail__container--details">
                          <div className="row m-0">
                            <div className="article__detail-title">Tournament #</div>
                          </div>
                          <div className="row m-0">
                            <div className="article__detail-text">{tournament.Id}</div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6 col-12 ">
                        <div className="article-detail__container article-detail__container--details">
                          <div className="row m-0">
                            <div className="article__detail-title">Game</div>
                          </div>
                          <div className="row m-0">
                            <div className="article__detail-text">{tournament.GameName}</div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6 col-12 ">
                        <div className="article-detail__container article-detail__container--details">
                          <div className="row m-0">
                            <div className="article__detail-title">Tournament Mode</div>
                          </div>
                          <div className="row m-0">
                            <div className="article__detail-text">{tournament.TournamentMode}</div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6 col-12 ">
                        <div className="article-detail__container article-detail__container--details">
                          <div className="row m-0">
                            <div className="article__detail-title">Tournament Region</div>
                          </div>
                          <div className="row m-0">
                            <div className="article__detail-text">{tournament.Region}</div>
                          </div>
                        </div>
                      </div>
                      {tournament.VenueTypeId == 2 && (
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 ">
                          <div className="article-detail__container article-detail__container--details">
                            <div className="row m-0">
                              <div className="article__detail-title">Seating Capacity</div>
                            </div>
                            <div className="row m-0">
                              <div className="article__detail-text">{tournament.VenueSeatingCapacity} &nbsp;</div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="col-lg-4 col-md-6 col-sm-6 col-12 ">
                        <div className="article-detail__container article-detail__container--details">
                          <div className="row m-0">
                            {tournament.EventTypeId === EventTypeEnum.Qualifier && (
                              <div className="article__detail-title">Winners to Main event</div>
                            )}
                            {tournament.EventTypeId !== EventTypeEnum.Qualifier && (
                              <div className="article__detail-title">Winning Placement</div>
                            )}
                          </div>
                          <div className="row m-0">
                            <div className="article__detail-text">
                              {tournament.TournamentStatusId === TournamentStatusEnum.Finished ?
                                this.props.participants &&
                                GeneralUtils.NumbersToWordsFromLength(tournament.NumberOfPlacements).map((x, index) => {
                                  return x + ' (' + (tournament.TournamentModeId === TournamentModeEnum.Solo ? this.props.participants[index].Player?.Username : this.props.participants[index].Clan?.Name) + ')' + ((index + 1 === tournament.NumberOfPlacements) ? '' : ', ')
                                }
                                )
                                :
                                GeneralUtils.NumbersToWordsFromLength(tournament.NumberOfPlacements).join(' ') + ' '}

                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6 col-12 ">
                        <div className="article-detail__container article-detail__container--details">
                          <div className="row m-0">
                            <div className="article__detail-title">Type of bracket</div>
                          </div>
                          <div className="row m-0">
                            <div className="article__detail-text">{tournament.TournamentStructure}</div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6 col-12 ">
                        <div className="article-detail__container article-detail__container--details">
                          <div className="row m-0">
                            <div className="article__detail-title">Start Time</div>
                          </div>
                          <div className="row m-0">
                            <div className="article__detail-text">{startDate}</div>
                          </div>
                        </div>
                      </div>
                      {tournament.TournamentSettings &&
                        tournament.TournamentSettings.map((setting,i) => {
                          return (
                            <div key={'TS'+ i.toString()} className="col-lg-4 col-md-6 col-sm-6 col-12 ">
                              <div className="article-detail__container article-detail__container--details">
                                <div className="row m-0">
                                  <div className="article__detail-title">{setting.SettingName}</div>
                                </div>
                                <div className="row m-0">
                                  <div className="article__detail-text">{setting.SettingValue}</div>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            {tournament.Information && (
              <div className="row m-0 tournament-column">
                <div className="col-12 col-md-12 col-lg-  pl-4">
                  <div className="article-detail__subheader">Additional Information</div>
                </div>                
                <div className="col-12 col-md-12 col-lg-">
                  <div className="row m-0">
                    <div className="article-detail__container article-detail__container--additional-info">
                      {tournament.Information}
                    </div>
                  </div>
                </div>
              </div>
            )}
              
            <div className="row m-0 tournament-column">
              <div className="col-12 col-md-12 col-lg- p-0 pl-4">
                <div className="article-detail__subheader">Organizer</div>
              </div>
              <div className="col-12 col-md-12 col-lg- p-0">
                <div className="article-detail__container article-detail__container--organizer organizer">
                  <div className="row">
                    <div className="col-12 col-sm-6 col-md-6 d-flex tournament--border">
                      <div className="col-auto p-0">
                        <SelfLoadedUserAvatar
                          userId={tournament.Organizerid}
                          className="organizer__logo"
                          placeholder="user"
                          containerName={ContainerName.AvatarsNormal}
                        />
                      </div>
                      <div className="col p-0" style={{ margin: 'auto' }}>
                        <div className="organizer__name"> {organizer.Organizationname}</div>
                        {!isOrganizer && (
                          <TournamentChatOrganizerButton organizerId={organizer.id} />
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 p-t-sm-2" style={{ borderLeft: window.innerWidth > 700 ? '1px solid white' : '' }}>
                      <div className="col-12 p-0 d-flex">
                        {organizer && organizer.address && <div className="organizer__info organizer__info--location">{organizer.address}&nbsp;</div>}
                      </div>
                      <div className="col-12 p-0 d-flex">
                        {' '}
                        <div className="organizer__info organizer__info--mail">{isAdmin ? tournament.OrganizerEmail : ((tournament.Organizerid === null || tournament.Organizerid === 0) ? tournament.OrganizerEmail : organizer.emailaddress)}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            {tournament.VenueType == 'Live Event' && (
              <div className="row m-0">
                <div className="col-12 col-md-12 col-lg- google-map-wrapper">
                  <div className="article-detail__container article-detail__container--map">
                    <div className="row m-0">
                      <div className="col-12 article__location">
                        <h4 className="article__location-header">{organizer.Organizationname}</h4>
                        <div className="article__location-address">
                          {tournament.VenueName +
                            ', ' +
                            tournament.VenueLocation +
                            ' ' +
                            '(' +
                            tournament.City +
                            (tournament.Country == undefined ? '' : ', ' + tournament.Country) +
                            ')'}
                        </div>
                        <div className="google-map">
                        <GoogleMap
                          pinText={tournament.VenueName}
                          lat={parseFloat(tournament.VenueLatitude ? tournament.VenueLatitude : '43.7315')}
                          lng={parseFloat(tournament.VenueLongitude ? tournament.VenueLongitude : '79.7624')}
                        />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {(tournament.DiscordLink || tournament.ContactEmail) && (
              <div className="row m-0 helpcol tournament-column">
                <div className="col-12 col-md-12 col-lg- p-0">
                  <div className="col-12 p-0">
                    <div className="row m-0">
                      <div className="col-12 tournament--article__help article__help help">
                        <div className="row">
                          <div className="col-xl-7 col-lg-7 col-12 tournament--help__container help__container">
                            {tournament.DiscordLink && !tournament.ContactEmail && (
                              <div className="row flex-column">
                                <h3 className="help__header">Need help with a tournament?</h3>
                                <div className="help__text">
                                  Please use our Discord link{' '}
                                  <a target="_blank" href={tournament.DiscordLink}>
                                    <u>{tournament.DiscordLink} </u>
                                  </a>{' '}
                                  for general questions and tournament support. Or click on the button below.
                                </div>
                                <a className="help__button blue-button blue-button--filled" href={tournament.DiscordLink}>
                                  HELP ME
                                </a>
                              </div>
                            )}
                            {!tournament.DiscordLink && tournament.ContactEmail && (
                              <div className="row flex-column">
                                <h3 className="help__header">Need help with a tournament?</h3>
                                <div className="help__text">
                                  Please use our Email Address{' '}
                                  <a href={`mailto:${tournament.ContactEmail}`}>
                                    <u>{tournament.ContactEmail} </u>
                                  </a>{' '}
                                  for general questions and tournament support.
                                </div>
                              </div>
                            )}
                            {tournament.DiscordLink && tournament.ContactEmail && (
                              <div className="row flex-column">
                                <h3 className="help__header">Need help with a tournament?</h3>
                                <div className="help__text">
                                  Please use our Discord link{' '}
                                  <a target="_blank" href={tournament.DiscordLink}>
                                    <u>{tournament.DiscordLink} </u>
                                  </a>{' '}
                                  or Email Address for general questions and tournament support. Just click on buttons below.
                                </div>
                                <div className="col-12 d-flex">
                                  <a className="help__button blue-button blue-button--filled" href={`mailto:${tournament.ContactEmail}`}>
                                    Contact By Email
                                  </a>
                                  &nbsp;&nbsp;
                                  <button
                                    className="help__button white-button white-button--filled"
                                    onClick={() => window.open('https://' + tournament.DiscordLink, '_blank')}
                                  >
                                    Contact By Discord
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                          {/* <div className="col-xl-2 col-1"></div> */}
                          <div className="col-xl-5 col-md-4 col-12 d-flex align-items-lg-center align-items-center justify-content-center help__background-container">
                            <div className="help__background" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>


        <aside className="modal-container" id="QualifierTournaments">
          <div className="modal-container__background"></div>
          <div className="modal-container__window window">
            <div className="window__close" onClick={() => ModalUtils.closeModals()}></div>
            <div className="container-fluid">
              <div className="row">
                <div className="window__header pl-0 text-left">
                  <div
                    className="back-btn-qui"
                    onClick={() => {
                      this.setState({ showRepeatTournaments: false })
                    }}
                  >
                    {showRepeatTournaments &&
                      <svg width="13" height="11" viewBox="0 0 13 11" fill="none" className="td__rFix" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M5.52989e-07 6.41669L9.28571 6.41669L6.0357 9.62501L7.42855 11L13 5.5L7.42858 -4.66605e-07L6.03573 1.37499L9.28571 4.58334L3.92712e-07 4.58334L5.52989e-07 6.41669Z"
                          fill="white"
                        />
                      </svg>
                    }
                    Qualifier
                  </div>
                </div>
              </div>
              {hasNoQualifierTournaments ?
                <div className="row">
                  <div className="window__big-subheader"> No qualifier tournament connected. </div>
                </div> :
                <>
                  <div className="row">
                    <div className="window__big-subheader">{showRepeatTournaments ? 'Click a tournament date you want to view.' : 'Click a tournament you want to view.'}</div>
                  </div>
                  <div className="row m-0--20 justify-content-start">
                    {tournament.allQualifierTournaments &&
                      tournament.allQualifierTournaments.map((item, i) => {
                        return (
                          <div key={'QT'+ i.toString()}>
                            <label
                              onClick={e => {
                                null
                              }}
                              className="window__cancel-container _borderless"
                            >
                              <span className="window__cancel-time">{item[0].Name}</span>
                            </label>
                            {
                              item.map((item, index) => {
                                return (
                                  <div key={'QTC' + index.toString()}>
                                    <label
                                      onClick={e => {
                                        this.setState({ EventToView: item.Id })
                                      }}
                                      className={EventToView === item.Id ? 'window__cancel-container _active' : 'window__cancel-container'} htmlFor={'cancellation_' + item.Id}
                                    >
                                      <span className="window__cancel-time">{ConvertDateToFormattedDateString(item.StartDate, item.ZonedRegistrationOpensOn, false, true)}</span>
                                      <span className="window__cancel-date">at {ConvertDateToFormattedTimeString(item.StartDate, item.ZonedRegistrationOpensOn, false, true)}</span>
                                    </label>
                                  </div>
                                )
                              })
                            }
                          </div>
                        )
                      })}
                  </div>
                </>}
              <div className="row m-t-30 justify-content-center">
                <button className="window__button window__button--cancel blue-button" onClick={() => ModalUtils.closeModals()}>CANCEL</button>
                {!hasNoQualifierTournaments && <button disabled={false} className={'window__button window__button--delete blue-button blue-button--filled ' + 'button-cancel-opacity'} onClick={() => this.onQualifierTournamentDateSelect()}>VIEW</button>}
              </div>
            </div>
          </div>
        </aside>
      </>
    )
  }
}

export default TournamentDetailComponent
