import { useAppSelector } from 'components/_hooks'
import { LoadingBox, Pagination } from 'components/_shared'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { loadGames } from 'store/logic/game-logic'
import { getGamesIds, getGamesLoading } from 'store/selectors/game-selectors'
import { GameRow } from './_shared/GameRow'

const pageSize = 10

export const Games = () => {
  const [page, setPage] = useState(0)
  const dispatch = useDispatch()
  const gamesIds = useAppSelector(getGamesIds)
  const gamesIdsPage = gamesIds.slice(page * pageSize, (page + 1) * pageSize) // tmp, remove when add pagination to API
  const isGamesLoading = useAppSelector(getGamesLoading)

  const handlePageChange = (value: number) => {
    setPage(value)
  }

  useEffect(() => {
    dispatch(loadGames)
  })

  return (
    <LoadingBox size="big" loading={isGamesLoading}>
      <div className="row m-0">
        <div className="col col-12">
          <h1 className="game_h1">Game Tournament Bracket</h1>
          <h2 className="game_h2_parent">Online Gaming Tournaments for PC</h2>
        </div>
        <div className="col col-12">
          <h2 className="game_h2">Our solution will provide you with the <b>game bracket generator</b>. Simply create a tournament using any of the games titles for <b>online gaming tournaments for Pc</b>, or any of the other game consoles. Our system will generate the <b>game tournament brackets</b> for your event.</h2>
        </div>
        {gamesIdsPage.map(id => <GameRow key={id} gameId={id} />)}
      </div>
      <Pagination allItemsCount={gamesIds.length} itemsPerPage={pageSize} onChangePage={handlePageChange} page={page} />
    </LoadingBox>
  )
}
