import { Reducer } from 'redux'
import { FeeKnownAction, FeeState, FEE_LOADED, initialFee } from './fee-types'

export const initialFeeState: FeeState = {
  fee: initialFee,
}

const reducer: Reducer<FeeState, FeeKnownAction> = (state: FeeState | undefined = initialFeeState, action: FeeKnownAction) => {
  switch (action.type) {
    case FEE_LOADED: {
      return {
        fee: action.payload,
      }
    }
    default:
      return state
  }
}

export default reducer
