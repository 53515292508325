export const battlesTabs = {
  pending: 'pending',
  checkin: 'checkin',
  submission: 'submission',
  finished: 'finished',
}

export type BattlesTab = keyof typeof battlesTabs

export const BattlesTabsNames = {
  [battlesTabs.pending]: 'Pending',
  [battlesTabs.checkin]: 'Check-in',
  [battlesTabs.submission]: 'Submission',
  [battlesTabs.finished]: 'Finished',
}
