import { ZonedDate } from 'components/_shared/TournamentWizard/_shared/TimeZoneSelector/ZonedDate';
import { TimeZoneInfoDTO } from '../store/types/app-types'
import { TournamentSeatModel, TournamentTicketSectionDetail } from './tournament-models';

export enum RepeatTypeEnum {
  EveryHour = 1,
  EveryDay = 2,
}

export interface kl_TournamentViewModel {
  Id: number
  OrganizerId: number
  Organizationname: string
  Name: string
  TournamentModeId: number | null
  TournamentMode: string | null
  TournamentStructureId: number | null
  AutomaticBracketResizing: boolean | null
  VenueTypeId: number | null
  EventTypeId: number | null
  MainTournamentId: number | null
  RegionId: number | null
  Region: string | null
  CountryId: number | null
  Country: string | null
  Province: string
  ProvinceId: number | null
  City: string
  CityId:number
  TimeZoneId: string | null
  TimeZone: TimeZoneInfoDTO | null
  VenueName: string
  VenueLocation: string
  VenueLatitude: string
  VenueLongitude: string
  VenueSeatingCapacity: number | null
  StreamingLink: string
  ContactEmail: string
  DiscordLink: string
  GameId: number | null
  GameName: string | null
  ParticipientsPerMatch: number | null
  MaxNumberOfParticipients: number | null
  PlayersPerClan: number | null
  MaxNumberOfRounds: number | null
  NumberOfPlacements: number | null
  NumberOfQualifiersMoveForward: number | null
  HostedByOrganizer: boolean | null
  IsManualControl: boolean
  Information: string
  IsDraft: boolean | null
  Rules: string
  RepeatTypeId: RepeatTypeEnum | null
  RepeatHoursInterval: number | null
  TournamentDetails: kl_TournamentDetailViewModel[]
  TournamentConsoles: number[]
  TournamentConsolesString: string[]
  TournamentSettings: kl_TournamentSettingsViewModel[]
  TournamentRepeatSettings: Date[]
  TicketNumber: number
  CoverId?: number
  TicketImageId?: number
  StateId: number | null
  State: string | null
  OrganizerCountry:string
  PrivateSlot:number | undefined
  PublicSlot:number | undefined
  NumberOfSubstitutes:number | null
  IsTicketsAdded:boolean
}

export interface KlTournamentModel {
  Id: number
  OrganizerId: number
  Organizationname: string
  Name: string
  TournamentModeId: number | null
  TournamentMode: string | null
  TournamentStructureId: number | null
  AutomaticBracketResizing: boolean | null
  VenueTypeId: number | null
  EventTypeId: number | null
  MainTournamentId: number | null
  RegionId: number | null
  Region: string | null
  CountryId: number | null
  Country: string | null
  Province: string
  City: string
  CityId:number
  VenueName: string
  VenueLocation: string
  VenueLatitude: string
  VenueLongitude: string
  VenueSeatingCapacity: number | null
  StreamingLink: string
  ContactEmail: string
  DiscordLink: string
  GameId: number | null
  GameName: string | null
  ParticipientsPerMatch: number | null
  MaxNumberOfParticipients: number | null
  PlayersPerClan: number | null
  MaxNumberOfRounds: number | null
  NumberOfPlacements: number | null
  NumberOfQualifiersMoveForward: number | null
  HostedByOrganizer: boolean | null
  IsManualControl: boolean
  Information: string
  IsDraft: boolean | null
  Rules: string
  RepeatTypeId: number | null
  RepeatHoursInterval: number | null
  TournamentDetails: KlTournamentDetailViewModel[]
  TournamentConsoles: number[]
  TournamentConsolesString: string[]
  TournamentSettings: kl_TournamentSettingsViewModel[]
  TournamentRepeatSettings: string[]
  TicketNumber: number
  CoverId?: number
  OrganizerCountry:string
  NumberOfSubstitutes:number | null
}

export interface kl_TournamentDetailViewModel {
  Id: number
  TournamentId: number | null
  RepeatSettingId: number | null
  StartDate: Date
  RegistrationOpensOn: Date
  RegistrationClosesOn: Date
  ActualNumberOfParticipients: number | null
  ActualNumberOfRounds: number | null
  NumberOfParticipientsLaidOff: number | null
  TournamentStatusId: number | null
  TournamentRounds: kl_TournamentRoundsViewModel[]
  TournamentTickets: kl_TournamentTicketsViewModel[]
    IsAnyTime: boolean | null
    PrivateSlot: number | null
    PublicSlot: number | null
    LayOutTypeId:number | null
}

export interface KlTournamentDetailViewModel {
  Id: number
  TournamentId: number | null
  RepeatSettingId: number | null
  StartDate: string
  RegistrationOpensOn: string
  RegistrationClosesOn: string
  ActualNumberOfParticipients: number | null
  ActualNumberOfRounds: number | null
  NumberOfParticipientsLaidOff: number | null
  TournamentStatusId: number | null
  TournamentRounds: klTournamentRoundsViewModel[]
  TournamentTickets: klTournamentTicketsViewModel[]
  TournamentTicketSection:TournamentTicketSectionDetail[]
    IsAnyTime: boolean | null
    PrivateSlot: number | null
    PublicSlot: number | null
    LayOutTypeId:number | null
}

export interface kl_TournamentRoundsViewModel {
  Id: number
  TournamentDetailId: number
  RoundNumber: number
  RoundTitle: string
  PlayDateTime: Date
  ZonedPlayDateTime?: ZonedDate
  ResultSubmissionDeadlineDate: Date
  ZonedResultSubmissionDeadlineDate: ZonedDate
  ResultSubmissionDeadline?: number
  IsLosersBracket: boolean
  IsExtraRound: boolean
  TournamentSchedule: kl_TournamentScheduleViewModel[]
}

export interface klTournamentRoundsViewModel {
  Id: number
  TournamentDetailId: number
  RoundNumber: number
  RoundTitle: string
  PlayDateTime: string
  ResultSubmissionDeadline: number
  IsLosersBracket: boolean
  TournamentSchedule: kl_TournamentScheduleViewModel[]
}

export interface kl_TournamentScheduleViewModel {
  Id: number
  TournamentRoundId: number
  MatchNumber: number
  MatchTitle: string
}

export interface kl_TournamentSettingsViewModel {
  Id: number
  TournamentId: number
  SettingName: string
  SettingValue: string
}

export interface kl_TournamentTicketsViewModel {
  Id: number
  TournamentDetailId: number
  DayNumber: number
  TicketDate: Date
  TicketPrice: number
  TournamentTicketSection:TournamentTicketSectionDetail[]
}

export interface klTournamentTicketsViewModel {
  Id: number
  TournamentDetailId: number
  DayNumber: number
  TicketDate: string
  TicketPrice: number
  TournamentTicketSection:TournamentTicketSectionDetail[]

}
export interface klTournamentDataViewModel{
   tournamentData: KlTournamentModel
}

