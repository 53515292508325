import { IDictionary } from 'store/types/dictionary-types'

export enum TournamentEventTypeEnum {
  Main = 1,
  Qualifier = 2,
  Normal = 3,
}

export const TournamentEventTypeName = {
  [TournamentEventTypeEnum.Main]: 'Main',
  [TournamentEventTypeEnum.Qualifier]: 'Qualifier',  
  [TournamentEventTypeEnum.Normal]: 'Normal',
}


export const TournamentEventTypeOptions = [
  ...Object.keys(TournamentEventTypeName).map<IDictionary<TournamentEventTypeEnum>>(key => ({
    id: +key,
    name: TournamentEventTypeName[+key as TournamentEventTypeEnum],
  })),
]
