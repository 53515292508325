import React from 'react'
import { useSelector } from 'react-redux'
import { loadMembersClans } from 'store/logic/pages/members-page-logic'
import { getAllClansCount, getClansFilters, getClansIds, getIsClansLoading } from 'store/selectors/pages/members-page-selectors'
import { membersPageClansFiltersChanged } from 'store/types/pages/members-page-types'
import { ClanCard } from '../MemberCard'
import { MembersTab } from './MembersTab'

export interface ClansTabProps {
  filtersModalOpened: boolean
  closeFiltersModal: () => void
}

export const ClansTab = ({ closeFiltersModal, filtersModalOpened }:  ClansTabProps) => {
  const clansFilters = useSelector(getClansFilters)
  const allClansCount = useSelector(getAllClansCount)
  const isClansLoading = useSelector(getIsClansLoading)
  const clansIds = useSelector(getClansIds)
  
  return (
    <>
      <MembersTab
        closeFiltersModal={closeFiltersModal}
        filtersModalOpened={filtersModalOpened}
        playersFilters={clansFilters}
        allPlayersCount={allClansCount}
        filtersChanged={membersPageClansFiltersChanged}
        isMembersLoading={isClansLoading}
        loadMembers={loadMembersClans}
      >
        {clansIds.map(id => <ClanCard key={id} clanId={id} />)}
      </MembersTab>
    </>
  )
}
