import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getFeatures } from 'store/selectors/app-selectors'
import styles from '../Login.module.scss'
import { useKlHistory } from 'components/_hooks/useKlHistory'
import { UserRole } from 'store/types/app-types'
import { getRoleName } from 'store/types/user-types'

export interface RegisterSuccessHistoryState {
  role: UserRole
}

const RegisterSuccess = () => {
  const features = useSelector(getFeatures)
  const history = useKlHistory<RegisterSuccessHistoryState>()

  const text = features.LeadGeneration
    ? 'Thank you for signing up, an email will be sent to you with the activation link when Kombatlink is released.'
    : 'Thank you for signing up, an email is sent to you with the activation link. Please click the activation link to activate your account.'

  const roleName = useMemo(() => {
    return getRoleName(history.state?.role)
  }, [history.state?.role])

  return (
    <>
      <div className={styles.background} />
      <div className="container-fluid">
        <div className="row justify-content-end back-container">
          <Link to="/" className="back">
            Back
          </Link>
        </div>
      </div>
      <article className="d-flex flex-row align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className={styles.signIn__header}>{roleName} Registered Successfully</h1>
              <h2 className={styles.signIn__subheader}>
                {text}
              </h2>
            </div>
          </div>
        </div>
      </article>
    </>
  )
}

export default RegisterSuccess
