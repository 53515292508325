import { ExecuteAPIGet, ExecuteAPIPost, executeFormPostData, fetchApi } from '../server-utils'
import { EndPoints } from '../endpoints'
import { HomePopupViewModel, ListHomePopupDto, ListHomePopupResultModel, PostHomePopupViewModel } from '../../models/home-page-popup-viewmodel'

export const HomePopupUtils = {
  GetAllHomePopups: async function GetAllHomePopups(filters:ListHomePopupDto) {
    return await ExecuteAPIPost<ListHomePopupResultModel>(EndPoints.HomePopup.GetAllHomePopups,filters)
  },
  GetHomePopupById: async function GetHomePopupById(id : number) {
    return await ExecuteAPIGet<HomePopupViewModel>(EndPoints.HomePopup.GetHomePopupById, `id=${id}`)
  },
  AddHomePopup: async function AddHomePopup(model: PostHomePopupViewModel) {
    return await ExecuteAPIPost<HomePopupViewModel>(EndPoints.HomePopup.AddHomePopup, model)
  },
  UpdateHomePopup: async function UpdateHomePopup(model: PostHomePopupViewModel) {
    return await ExecuteAPIPost<HomePopupViewModel>(EndPoints.HomePopup.UpdateHomePopup, model)
  },
  DeleteHomePopup: async function DeleteHomePopup(id: number) {
    return await ExecuteAPIGet<boolean>(EndPoints.HomePopup.DeleteHomePopup, `id=${id}`)
  },
  uploadTournamentCoverImage :async function uploadTournamentCoverImage(homePopupId: number, file: File){
    const formData = new FormData()
    formData.append('file', file)
    await fetchApi(`homepopup/${homePopupId}/homepopup-image`, 'POST', formData)
  },
  GetAllRunningHomePopups: async function GetAllRunningHomePopups() {
    return await ExecuteAPIGet<HomePopupViewModel>(EndPoints.HomePopup.GetAllRunningHomePopups)
  },
}
