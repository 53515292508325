import React, { useEffect, useMemo, useState } from 'react'
import { ParticipantType } from 'store/types/participants-types'
import ParticipantRow from './ParticipantRow'
import { useDispatch, useSelector } from 'react-redux'
import { lineUpsActionCreators } from 'store/logic/line-ups-logic'
import { TournamentViewModel_kl } from 'models/tournament-viewmodel'
import { TournamentModeEnum, TournamentStatusEnum, VenueTypeEnum } from 'consts'
import { makeGetParticipantsByTournamentDetailId } from 'store/selectors/participant-selectors'
import { ApplicationState } from 'store/types/common'
import { participantsActionCreators, useAddParticipationByOrganizer, useCancelParticipation, useCancelParticipationByOrganizer } from 'store/logic/participants-logic'
import TournamentChatButton from '../_shared/TournamentChatButton/TournamentChatButton'
import PrivateDefaultRow from './PrivateDefaultRow'
import { AlertModal, Modal } from 'components/_modals'
import { useAppUser, useDialogWithParameter } from 'components/_hooks'
import PrivateParticipantModal from './PrivateParicipantAddModel/PrivateParicipantModel'
import styles from './PrivateParicipantAddModel/PrivateParticipantModal.module.scss'
import { cn } from 'utils'
import { TournamentUtils } from 'server/utils/tournament-utils'
import { useReloadPendingMatches } from 'store/logic/match-logic'
import { getIsAdmin, getIsOrganizer, PublicUserType } from 'store/types/user-types'
import { EventTypeEnum } from 'models/tournament-models'
import { TournamentCartUtils } from 'server/utils/tournament-cart-utils'
import { DeleteTournamentChat } from 'store/chat/chat/chat-logic'
import ClanMemberSignUpModal from 'components/_pages/common/TournamentDetailRegistration/ClanMemberSignUpModal/ClanMemberSignUpModal'
import { ClanMemberData, TournamentClanSignUpModel } from 'models/clan-member-view-model'
import { ClanType } from 'store/types/clan-types'

const getParticipantName = (participant: ParticipantType) => participant.Clan?.Name || participant.Player?.Username
const maxPlayersNameCount = 5

interface Props {
  tournament: TournamentViewModel_kl,
  reloadTournament: (tournamentId: number) => void
}

export const TournamentParticipantsComponent = ({ tournament, reloadTournament }: Props) => {
  const getParticipantsByTournamentDetailId = useMemo(makeGetParticipantsByTournamentDetailId, [])
  const participants = useSelector((state: ApplicationState) => getParticipantsByTournamentDetailId(state, tournament.Id))

  const dispatch = useDispatch()
  let PrivateSlot = (tournament.PrivateSlot !== undefined && tournament.PrivateSlot !== null ? tournament.PrivateSlot : 0)
  const allCountPrivateParticipants = useMemo(() => {
    return participants.filter(x => x.IsPrivate === true)
  }, [participants])

  PrivateSlot = PrivateSlot - (allCountPrivateParticipants ? allCountPrivateParticipants?.length : 0)
  const isPending = React.useMemo(() => {
    if (!tournament || tournament.TournamentStatusId != TournamentStatusEnum.Pending) {
      return false
    }

    const now = new Date()
    const { RegistrationClosesOn, RegistrationOpensOn } = tournament
    const isValidDate = new Date(RegistrationOpensOn) <= now && now < new Date(RegistrationClosesOn)
    return isValidDate
  }, [tournament])

  React.useEffect(() => {
    if (tournament.Id) {
      void dispatch(lineUpsActionCreators.loadLineUps(tournament.Id))
    }
  }, [dispatch, tournament.Id])

  const [filter, setFilter] = useState('')

  const filteredParticipants = useMemo(() => {
    return participants.filter(x => getParticipantName(x).toLowerCase().includes(filter.toLowerCase()))
  }, [participants, filter])
  const filteredParticipantNames = useMemo(() => {
    const names = filteredParticipants
      .map(p => getParticipantName(p))
      .slice(0, maxPlayersNameCount)
      .join(', ')
    return `${names}${filteredParticipants.length > maxPlayersNameCount ? ', ...' : ''}`
  }, [filteredParticipants])

  const appUser = useAppUser()
  const isOrganizer = getIsOrganizer(appUser?.Role) || getIsAdmin(appUser?.Role)

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value)
  }

  useEffect(() => {
    dispatch(participantsActionCreators.loadParticpants(tournament.Id))
  }, [dispatch, tournament.Id])

  const [actionCompletedModal, openActionCompletedModal] = useDialogWithParameter<string>((text, closeDialog) => (
    <AlertModal onClose={closeDialog} text={text} buttonText="Ok" />
  ))
  const addParticipation = useAddParticipationByOrganizer()
  const reloadPendingMatches = useReloadPendingMatches()
  const cancelParticipation = useCancelParticipationByOrganizer()

  const handleParticipantJoin = async (user: PublicUserType, clan: ClanType, close: CallableFunction) => {
    try {
      const result = await TournamentUtils.CheckUserParticipantInTournamentSeriesOrNotByOrganizer(tournament.Id, user.Id)
      if (!result.isError) {
        if (result.data === undefined || result.data === null || result.data.IsParticipate === false) {
          if (tournament.TournamentModeId == TournamentModeEnum.Clans) {
            const resultClanMembers = await TournamentUtils.GetTournamentClanMembes(clan.Id)
            if (!resultClanMembers?.isError) {
              const data = resultClanMembers?.data
              const users = data?.Model.Users
              let TournamentClanSignUpModel: TournamentClanSignUpModel = Object()
              TournamentClanSignUpModel.tournamentId = tournament.Id
              TournamentClanSignUpModel.clanId = clan.Id
              TournamentClanSignUpModel.SubstituteCount = Number(tournament.NumberOfSubstitutes == null ? 0 : tournament.NumberOfSubstitutes)
              TournamentClanSignUpModel.TotalPlayers = tournament.PlayersPerClan + (Number(tournament.NumberOfSubstitutes == null ? 0 : tournament.NumberOfSubstitutes))
              TournamentClanSignUpModel.IsAddOnlyClanMember = false
              TournamentClanSignUpModel.isPrivate = true
              const clanMemberData = users.map(function (value, index) {
                return {
                  Username: value.Username,
                  PlayerId: value.Id,
                  IsSubstitute: false,
                  IsClanLeader: value.Id === user.Id ? true : false,
                  IsSelected: value.Id === user.Id ? true : false,
                  IsDisabled: false,
                  IsDefault: true
                }
              })
              clanMemberData.sort(x => x.IsClanLeader ? -1 : 1)
              TournamentClanSignUpModel.clanMemberData = clanMemberData as ClanMemberData[]
              close()
              openClanMemberModal(TournamentClanSignUpModel as TournamentClanSignUpModel)
            }
          }
          else {
            await addParticipation(tournament.Id, user.Id)
            dispatch(participantsActionCreators.reloadParticpants(tournament.Id))
            dispatch(lineUpsActionCreators.reloadLineUps(tournament.Id))
            openActionCompletedModal('Joined tournament successfully.')
            close()
            void reloadPendingMatches()
            if (tournament.VenueTypeId === VenueTypeEnum.Live) {
              await saveToCart('join', user.Id)
            }
          }
        }
        else {
          if (result.data.EventTypeId === EventTypeEnum.Main) {
            openActionCompletedModal('This user have already won a seat to ' + result.data.EventName)
          }
          else {
            openActionCompletedModal('Our system detects this user is currently enrolled in this qualifier series already')
          }
        }
      }
      else {
        openActionCompletedModal(result.message)
      }
    }
    catch (e) {
      openActionCompletedModal(e.message)
    }
  }

  const saveToCart = async (type: string, userId: number) => {
    await onSaveTicketsToCart(tournament.Id, userId, type)
  }
  const onSaveTicketsToCart = async (tournamentId: number, userId: number, type: string) => {
    await TournamentCartUtils.SaveToCartByOrganizer(tournamentId, type, userId)

  }

  const closeModal = async () => {
    openActionCompletedModal('Joined tournament successfully.')
    await reloadTournament(tournament.Id)
  }

  const [privateParticipantModal, openPrivateParticipantModal] = useDialogWithParameter<any>((model, closeDialog) => (
    <>
      <Modal buttonColor="white" onClose={() => { closeDialog(); }} hideOk hideCancel className={cn(styles.window, styles.privateUserModel) + ' private-participant-modal'}>
        <PrivateParticipantModal onClose={closeDialog} tournament={model.tournament} participants={model.participants} joinUser={handleParticipantJoin} />
      </Modal>
    </>
  ))

  const [ClanMemberModal, openClanMemberModal] = useDialogWithParameter<TournamentClanSignUpModel>((clanMemberUsers, closeDialog) => (
    <ClanMemberSignUpModal
      closeDialog={closeDialog}
      clanMemberUsers={clanMemberUsers}
      close={closeModal}
    />
  ))

  return (
    <>
      <div className="row m-0">
        <div className="col-12 details td__details p-0">
          <div className="participants p-t-32">
            <div className="participants_top">
              <div className="participants_name">
                <h2 className="article__big-subheader-white ct__article__big-subheader-white">Participants({filteredParticipantNames})</h2>
              </div>
              {participants && participants.length > 0 && (
                <div className="search">
                  <input onChange={handleFilterChange} className="search__input" type="text" placeholder="Search a participant" />
                  <a className="search__clear" href="#" />
                </div>
              )}
              <TournamentChatButton tournamentDetailId={tournament.Id} />
            </div>
            <div className="participants_item">
              {filteredParticipants && filteredParticipants.map(participant => (
                <ParticipantRow participantId={participant.Id} key={participant.Id} isPending={isPending} tournament={tournament} reloadTournament={reloadTournament} openActionCompletedModal={openActionCompletedModal} />
              ))}

              {tournament.EventTypeId !== EventTypeEnum.Qualifier && isPending && isOrganizer && PrivateSlot > 0 && (
                [...Array(PrivateSlot)].map((e, i) => {
                  return <PrivateDefaultRow key={'Private_' + i} tournament={tournament} participants={participants} openPrivateParticipantModal={openPrivateParticipantModal} />
                })
              )}
            </div>
          </div>

        </div>
      </div>
      {privateParticipantModal}
      {actionCompletedModal}
      {ClanMemberModal}
    </>
  )
}

export default TournamentParticipantsComponent
