import React from 'react'
import { useSelector } from 'react-redux'
import { loadMembersOrganizers } from 'store/logic/pages/members-page-logic'
import { getAllOrganizersCount, getIsOrganizersLoading, getOrganizersFilters, getOrganizersIds } from 'store/selectors/pages/members-page-selectors'
import { membersPageOrganizersFiltersChanged } from 'store/types/pages/members-page-types'
import { OrganizerCard } from '../MemberCard'
import { MembersTab } from './MembersTab'

export interface OrganizersTabProps {
  filtersModalOpened: boolean
  closeFiltersModal: () => void
}

export const OrganizersTab = ({ closeFiltersModal, filtersModalOpened }:  OrganizersTabProps) => {
  const filters = useSelector(getOrganizersFilters)
  const count = useSelector(getAllOrganizersCount)
  const isLoading = useSelector(getIsOrganizersLoading)
  const ids = useSelector(getOrganizersIds)

  return (
    <>
      <MembersTab
        closeFiltersModal={closeFiltersModal}
        filtersModalOpened={filtersModalOpened}
        playersFilters={filters}
        allPlayersCount={count}
        filtersChanged={membersPageOrganizersFiltersChanged}
        isMembersLoading={isLoading}
        loadMembers={loadMembersOrganizers}
      >
        {ids.map(id => <OrganizerCard key={id} userId={id} />)}
      </MembersTab>
    </>
  )
}
