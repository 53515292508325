import { ContainerName, useFormatDateTime, useTournament } from 'components/_hooks'
import { RecentOrganizerTournamentCover } from 'components/_shared'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { makeGetGame } from 'store/selectors/game-selectors'
import { ApplicationState } from 'store/types/common'
import styles from '../OrganizerProfile.module.scss'


interface Props {
  tournamentDetailId: number
}

export const RecentTournamentCard = ({tournamentDetailId}: Props) => {
  const { tournament } = useTournament(tournamentDetailId)

  const getGame = useMemo(makeGetGame, [])
  const game = useSelector((state: ApplicationState) =>  getGame(state, { gameId: tournament.GameId }))

  const {date} = useFormatDateTime(tournamentDetailId, tournament.StartDate, 'redux')

  return (
    <div className={styles.recentCCol}>
      <div className={styles.recentC}>
        <RecentOrganizerTournamentCover containerName={ContainerName.TournamentCardCover} tournamentName={tournament?.Name} coverId={tournament.CoverId} />
        <div className={styles.recentCR}>
          <div>
            <h3 className={styles.recentCTtl}>
              <a href={`/tournament-detail/${tournament.TournamentDetailId}`}>
                {game?.Gamename ?? ''} {tournament.Name}
              </a>
            </h3>
            <div className={styles.recentCInfo}>
              <div className={styles.recentCInfo_i}>
                <h4 className={styles.recentCInfo_ttl}>Location</h4>
                <span className={styles.recentCInfo_txt}>{tournament.City}</span>
              </div>
              <div className={styles.recentCInfo_i}>
                <h4 className={styles.recentCInfo_ttl}>Date</h4>
                <span className={styles.recentCInfo_txt}>{date}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
