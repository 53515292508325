import { ContainerName, useTournamentParticipant } from 'components/_hooks'
import { MatchParticipantStatus } from 'consts'
import React from 'react'
import { useSelector } from 'react-redux'
import { getMatchSettings } from 'store/selectors/match-settings-selectors'
import { ApplicationState } from 'store/types/common'
import { MatchParticipantModel } from 'store/types/match-participant-types'
import { MatchModel, MatchStatusEnum } from 'store/types/match-types'
import { ParticipantType } from 'store/types/participants-types'
import { cn } from 'utils'
import ParticipantOnlineAvatar from '../ParticipantAvatar/ParticipantOnlineAvtar'
import styles from './MatchResult.module.scss'

interface OwnProps {
  matchParticpants: MatchParticipantModel[]
  participants: ParticipantType[]
  match: MatchModel
}

const SingleMatchResult = ({ matchParticpants, participants, match }: OwnProps) => {

  const matchSettings = useSelector((state: ApplicationState) => getMatchSettings(state, match.Id))
  const makeBotArray =()=>{
    let bots:any[]=[]
    matchParticpants.map(mp => {
      const participant = participants.find(p => p.Id === mp.TournamentParticipantId)
      const { IsBot } = useTournamentParticipant(participant?.Id)
      bots.push({participantId: mp.TournamentParticipantId,IsBot:IsBot})
    })
    return bots;
  }

  const bots = makeBotArray()
  const matchResults = React.useMemo(() => {
    return matchParticpants.map(mp => {
      const participant = participants.find(p => p.Id === mp.TournamentParticipantId)
      
      return {
        MatchParticipantId: mp.Id,
        ParticipantName: participant?.Player?.Username || participant?.Clan?.Name,
        IsWon: mp.Place === 1 ? true : mp.Place === 2 ? false : null,
        Participant: participant,
        IsPending: mp.ParticipantStatus == MatchParticipantStatus.SignedUp && match.Status == MatchStatusEnum.Pending,
        IsHost: matchSettings?.HostedByMatchParticipantId == mp.Id,
        IsBot:bots.find( x => x.participantId === mp.TournamentParticipantId).IsBot
      }
    })
  }, [match.Status, matchParticpants, matchSettings?.HostedByMatchParticipantId, participants])

  
  return (
    <div className="row">
      {matchResults &&
        matchResults.map(mr => (
          <div key={mr.MatchParticipantId} className="col-lg-6 col-12 p-0-5">
            <div className={cn('row', 'm-0', 'align-items-center', styles.windowPlayer)}>
              <div className="col-9">
                <div className="row align-items-center flex-nowrap old-kl-css">
                  <ParticipantOnlineAvatar 
                    participantId={mr.IsPending ? undefined : mr.Participant.Id} 
                    className={styles.windowPlayerPicture} 
                    parentClassName="prsn_icon-indicator-position"
                    containerName={ContainerName.AvatarsNormal} 
                    withLink={!mr.IsBot}
                  />
                  <div className={styles.windowPlayerUsername}>
                    {mr.IsPending ? (
                      <span className={cn(styles.windowPlayerUsernameText, styles.pending)}>Pending</span>
                    ) : (
                      <span className={styles.windowPlayerUsernameText}> {!mr.IsBot?mr.ParticipantName:'By-Pass'}</span>
                    )}
                  </div>
                </div>
              </div>
              {match.Status !== MatchStatusEnum.Pending && (
                <div className="col-3 d-flex justify-content-end p-0">
                  {mr.IsWon === true && <div className={cn(styles.windowPlayerLabel, styles.windowPlayerLabelGreen)}>victory</div>}
                  {mr.IsWon === false && <div className={cn(styles.windowPlayerLabel, styles.windowPlayerLabelRed)}>defeat</div>}
                  {mr.IsWon === null && <div className={cn(styles.windowPlayerLabel, styles.windowPlayerLabelGray)}>processing</div>}
                </div>
              )}
              {mr.IsHost && (
                <span className={cn(styles.window__label, styles.host__label)}>Host</span>
              )}
            </div>
          </div>
        ))}
    </div>
  )
}

export default SingleMatchResult
