import { ContainerName, useAppSelector, useAppUser } from 'components/_hooks'
import { UserAvatar } from 'components/_shared'
import React, { useMemo } from 'react'
import { useChatName, useOpenChat, useOpenUserChat } from 'store/chat/chat/chat-hooks'

import { cn, defaultFormatDate } from 'utils'
import { makeGetLastMessage, makeGetUnreadMessagesCount, makeGetUnreadMessagesCountByChatId } from 'store/chat/chat-message/chat-message-selectors'
import { makeGetUser, makeGetUsers } from 'store/selectors/user-selectors'


import groupStyles from 'components/_shared/Chat/_shared/ChatGroupContainer/ChatGroupContainer.module.scss'
import personStyles from 'components/_shared/Chat/_shared/ChatPerson/ChatPerson.module.scss'
import styles from '../ChatList.module.scss'
import { makeGetChat } from 'store/chat/chat/chat-selectors'
import { ChatType } from 'consts'
import { loadChat } from 'store/chat/chat/chat-logic'
import { useDispatch } from 'react-redux'
import { getGroupChatName } from 'store/chat/chat/chat-types'
import { makeGetChatMemeber } from 'store/chat/chat-member/chat-member-selectors'
import { useTypingUserChatMember } from 'store/chat/chat-member/chat-member-hooks'


interface Props {
  chatId: number,
  searchText: string,
}

const ChatPreview = ({ chatId, searchText }: Props) => {

  const { chatName, chatUserId } = useChatName(chatId)

  const dispatch = useDispatch()
  const openChat = useOpenChat()
  const openUserChat = useOpenUserChat()

  const getLastMessage = useMemo(makeGetLastMessage, [])
  const getUser = useMemo(makeGetUser, [])
  const getChat = useMemo(makeGetChat, [])
  const getChatMember = useMemo(makeGetChatMemeber, [])


  const lastMessage = useAppSelector(state => getLastMessage(state, { chatId: chatId }))

  const user = useAppSelector(state => getUser(state, chatUserId))
  const chat = useAppSelector(state => getChat(state, { chatId: chatId }))

  const date = lastMessage?.SentDate || chat?.CreatedDate

  const lastMessageMember = useAppSelector(state => getChatMember(state, { chatMemberId: lastMessage?.ChatMemberId }))
  const lastMessageUser = useAppSelector(state => getUser(state, lastMessageMember?.UserId))
  const appUser = useAppUser()
  const typingUsers = useTypingUserChatMember(chat?.Id, appUser?.Id)
  const typingUsersIds = typingUsers?.map(cm => cm.UserId) || []
  const getUsers = useMemo(makeGetUsers, [])
  const typingUsersList = useAppSelector(state => getUsers(state, typingUsersIds))
  const getUnreadMessagesCount = useMemo(makeGetUnreadMessagesCountByChatId, [])
  const unreadMessagesCount = useAppSelector(state => getUnreadMessagesCount(state,{chatId:chatId}))


  const handlePersonClick = () => {
    dispatch(loadChat(chat.Id))
    if (chat.Type == ChatType.Private) {
      openUserChat(chatUserId)
    } else {
      openChat(chat.Id)
    }
  }

  return (
    <>
      {chatName.toLowerCase().includes(searchText) ? <section className={groupStyles.chatPersonContainer}>
        <div className="row m-0-15 align-items-stretch h-100">
          <div className="col-auto p-0 d-flex align-items-center position-relative">
            {chat?.Type == ChatType.Private && (
              <>
                <UserAvatar
                  userId={chatUserId}
                  className={cn(personStyles.chatPersonPicture)}
                  placeholder="dark"
                  containerName={ContainerName.AvatarsNormal}
                />
                <div className={cn(personStyles.chatPersonStatus, user?.IsOnline && personStyles.online)} />
              </>
            )}
            {chat?.Type == ChatType.Group && (
              <div className={cn(personStyles.chatPersonPicture, groupStyles.chatPersonPictureGroup)}>{getGroupChatName(chat?.RelationType)}</div>
            )}

          </div>
          <div className="col d-flex flex-column justify-content-center p-0">
            <div className={cn(personStyles.chatPersonName, groupStyles.chatGroup, styles.chatName)}>{chatName}</div>
            {typingUsersList && typingUsersList.length > 0 ? (
              <div className={cn(styles._typingText,styles.chat__person_message__mine)}>
                Typing
                <div className={cn(styles.chattypingdots, styles.chattypingdots__white, 'd-inline-flex', 'flex-row')}>
                  <div></div><div></div><div></div>
                </div>
              </div>
            ) : 
            lastMessage ? (
              <div className={cn(personStyles.chatPersonMessage, styles.chatPreviewMessage)}>
                <>
                  {chat?.Type == ChatType.Group && lastMessageUser && (
                    <span className={styles.chatPreviewUser}>{`${lastMessageUser?.Username || lastMessageUser?.Organizationname}:`}</span>
                  )}
                  <span>
                    {lastMessage.Text}
                  </span>
                </>
              </div>
            ) :
            <div className={cn(personStyles.chatPersonMessage, personStyles.chatPersonMessageMine)}>
                  No messages
                </div>
            }
          </div>
          <div className="col-auto p-0">
            {date && (
              <div className={groupStyles.chatPersonDate}>{defaultFormatDate(date)}</div>
            )}
            {unreadMessagesCount && <div className={styles.unreadMessageCount}>{unreadMessagesCount.unreadMessagesCount}</div>}
          </div>
        </div>
        <a className={groupStyles.chatPersonLink} href="#" onClick={handlePersonClick} />
      </section> : <> </>}
    </>
  )
}

export default ChatPreview
