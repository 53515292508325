import { useDialog } from 'components/_hooks'
import { ButtonColor } from 'components/_shared'
import React, { ReactNode } from 'react'
import { ConfirmModal } from 'components/_modals'

interface UseConfirmModalParams {
  header: ReactNode
  subHeader: ReactNode
  asyncOperation: () => Promise<boolean>
  buttonColor: ButtonColor
}

export const useConfirmModal = ({ header, subHeader, asyncOperation, buttonColor }: UseConfirmModalParams) => {
  return useDialog(closeDialog => (
    <ConfirmModal
      asyncOperation={asyncOperation}
      buttonColor={buttonColor}
      header={header}
      onClose={closeDialog}
      subHeader={subHeader}
    />
  ))
}
