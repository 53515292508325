import React, { useMemo } from 'react'
import { MatchParticipantModel } from 'store/types/match-participant-types'

interface OwnProps {
  matchParticipants: MatchParticipantModel[]
}

const WatchTab = ({matchParticipants} : OwnProps) => {
  const participantsWithLink = useMemo(() => matchParticipants.filter(m => !!m.StreamingLink), [matchParticipants])


  return (
    <div className="m-checked_tabs_tab active" data-tab="1">
      <div className="m-checked_tabs_tab_inn">
        {participantsWithLink?.length > 0 ? (
          participantsWithLink.map(mp => (
            <div className="m-checked_watch" key={mp.Id}>
              <div className="m-checked_watch_i">
                <div>
                  <h4 className="m-checked_watch_ttl">{mp.StreamingLink}</h4>
                </div>
                <div>
                  <a href={mp.StreamingLink} className="m-checked_watch_link"  target="_blank" rel="noreferrer" >
                    Go to game
                  </a>
                </div>
              </div>          
            </div>
          ))
        ) : (
          <p className="m-checked_tabs_tab_empty">This match does not have any games.</p>
        )}
      </div>
    </div>
  )
}

export default WatchTab
