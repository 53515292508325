import React, { ReactNode } from 'react'
import { cn } from 'utils'
import { useDropDownTableStyles } from './styles/useDropDownTableStyles'

export interface DropDownTableSecondaryRowProps {
  children?: ReactNode
  className?: string
}

export const DropDownTableSecondaryRow = ({ children, className }: DropDownTableSecondaryRowProps) => {
  const styles = useDropDownTableStyles()

  return <div className={cn('row', styles.tableSecondaryRow, className)}>{children}</div>
}
