const cdn = 'https://kombatlink-assets.azureedge.net'
const logo = `${cdn}/img/logo.png`
const ok = `${cdn}/img/ok.svg`
const awesome = `${cdn}/video/awesome.mp4`
const howToCreateLiveTournament = `${cdn}/video/how-to-create-live-tournament.mp4`
const howToCreateMainAndQualfier = `${cdn}/video/how-to-create-main-and-qualfier.mp4`
const howToJoinTournament = `${cdn}/video/how-to-join-tournament.mp4`
const manualContolBracket = `${cdn}/video/manual-contol-bracket.mp4`
const createTournamentWithPrivateSlots = `${cdn}/video/how-to-create-a-tournament-with-private-slots(new-feature).mp4`
const createGroupChat = `${cdn}/video/how-to-create-group-chat-with-friends(new-feature).mp4`
const signupTeamToTournament = `${cdn}/video/how-to-sign-up-your-team-to-a-tournament.mp4`
const preTounamentGroupChat = `${cdn}/video/pre-tournament-group-chat(new-feature).mp4`
const createPrivateSlot = `${cdn}/video/how-to-create-private-slots.mp4`
const howToSetUpVenueSeatingLayout = `${cdn}/video/how-to-setup-venue-seating-layout.mp4`

export default {
  logo,
  awesome,
  ok,
  howToCreateLiveTournament,
  howToCreateMainAndQualfier,
  howToJoinTournament,
  manualContolBracket,
  createTournamentWithPrivateSlots,
  createGroupChat,
  signupTeamToTournament,
  preTounamentGroupChat,
  createPrivateSlot,
  howToSetUpVenueSeatingLayout
}
