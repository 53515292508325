import { Dispatch } from 'redux'
import { makeGetIsReadBlogsRequested } from 'store/selectors/user-read-blogs-selectors'
import { unreadBlogsCountLoaded } from 'store/types/blog-types'
import { GetState } from 'store/types/common'
import { fetchApi } from '../../server/server-utils'
import { OperationTypes, run } from './operations-logic'

const getIsReadBlogsRequested = makeGetIsReadBlogsRequested()

export const readBlog = (userId: number, blogId: number) => async (dispatch: Dispatch, getState: GetState) => {
  if (getIsReadBlogsRequested(getState(), { userId })) {
    return
  }
  await run(OperationTypes.readBlogs, null, dispatch, getState, async (): Promise<true> => {
    const unreadCount = await fetchApi<number>(`userreadblogs/${blogId}`, 'POST')
    dispatch(unreadBlogsCountLoaded(unreadCount))
    return true
  })
}
