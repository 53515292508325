import React, { useRef, useEffect } from "react";
import { Text, Transformer } from "react-konva";

export function ResizableText({
  x,
  y,
  text,
  isSelected,
  width,
  onResize,
  onClick,
  onDoubleClick,
  fontsize,
  draggable,
  color,
  letterSpacing,
  IsLayOut
}) {
  const textRef = useRef(null)
  const transformerRef = useRef(null)

  useEffect(() => {
    if (isSelected && transformerRef.current !== null) {
      transformerRef.current.nodes([textRef.current])
      transformerRef.current.getLayer().batchDraw()
    }
  }, [isSelected]);

  function handleResize() {
    if (textRef.current !== null) {
      const textNode = textRef.current;
      const newWidth = textNode.width() * textNode.scaleX()
      const newHeight = textNode.height() * textNode.scaleY()
      textNode.setAttrs({
        width: newWidth,
        scaleX: 1
      });
      onResize(newWidth, newHeight)
    }
  }

  const transformer = isSelected ? (
    <Transformer
      ref={transformerRef}
      rotateEnabled={false}
      flipEnabled={false}
      anchorSize={5}
      borderDash={[6, 2]}
      boundBoxFunc={(oldBox, newBox) => {
        newBox.width = Math.max(25, newBox.width)
        return newBox
      }}
    />
  ) : null;

  return (
    <>
      <Text
        x={x}
        y={y}
        ref={textRef}
        text={text}
        fill={color}
        fontFamily="sans-serif"
        fontSize={fontsize}
        perfectDrawEnabled={false}
        onTransform={IsLayOut === true ? false : handleResize}
        onClick={IsLayOut === true ? false : onClick}
        onTap={IsLayOut === true ? false : onClick}
        onDblClick={IsLayOut === true ? false : onDoubleClick}
        onDblTap={IsLayOut === true ? false : onDoubleClick}
        width={width}
        draggable={IsLayOut === true ? false : draggable}
        align="center"
        fontStyle="bold"
        verticalAlign="middle"
        ellipsis={true}
        letterSpacing={letterSpacing}
      />
      {transformer}
    </>
  );
}
