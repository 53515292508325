import { createSelector } from 'reselect'
import { OperationTypes } from 'store/logic/operations-logic'
import { ApplicationState } from '../types/common'
import { makeGetIsOperationWithTypeRunning, makeGetIsOperationWithTypeSuccessOrRunning } from './operations-selectors'

const getState = (state: ApplicationState) => state.matchSubmissions

const getById = createSelector(getState, state => state?.byId || {})

const getIdsByMatchId = createSelector(getState, state => state?.idsByMatchId || {})

const getIdByMatchParticipantId = createSelector(getState, state => state?.idByMatchParticipantId || {})

const getLoadMatchSubmissionsOperationType = createSelector(
  (_, matchId: number) => matchId,
  matchId => ({ type: OperationTypes.loadMatchSubmissions, objectId: matchId })
)
export const makeGetIsMatchSubmissionsLoading = () => makeGetIsOperationWithTypeRunning(getLoadMatchSubmissionsOperationType)
export const makeGetIsMatchSubmissionsRequested = () => makeGetIsOperationWithTypeSuccessOrRunning(getLoadMatchSubmissionsOperationType)

export const makeGetMatchSubmissionsIdsByMatchId = () =>
  createSelector(
    getIdsByMatchId,
    (_: ApplicationState, matchId: number) => matchId,
    (ids, matchId) => ids[matchId] || []
  )

export const makeGetSubmissionsByMatchId = () =>
  createSelector(
    getById,
    getIdsByMatchId,
    (_: ApplicationState, matchId: number) => matchId,
    (byId, ids, matchId) => (ids[matchId] || []).map(id => byId[id])
  )

export const makeGetSubmissionByMatchParticipantId = () =>
  createSelector(
    getById,
    getIdByMatchParticipantId,
    (_: ApplicationState, matchParticipantId: number) => matchParticipantId,
    (byId, byMatchParticipantId, matchParticipantId) => {
      const id = byMatchParticipantId[matchParticipantId]
      return id ? byId[id] : undefined
    }
  )
