import { ContainerName, useAppUser } from 'components/_hooks'
import { Carousel } from 'components/_shared'
import { TextField } from 'components/_shared/TextField/TextField'
import { ConsoleEnum, ConsoleType } from 'consts/ConsoleType'
import React, { useMemo } from 'react'
import { CountryType } from 'store/types/country-types'
import { RegionType } from 'store/types/region-types'
import { PublicUserType } from 'store/types/user-types'
import { cn } from 'utils'
import styles from '../PlayerPage.module.scss'
import { UserGame } from './UserGame'

export interface PlayerFieldsProps {
  user: PublicUserType
  userConsoles: ConsoleEnum[]
  userRegion: RegionType
  userCountry: CountryType
  usersGamesIds: number[]
}

export const ReadonlyPlayerFields = ({ user, userConsoles, userCountry, userRegion, usersGamesIds }: PlayerFieldsProps) => {
  const consolesText = useMemo(() => {
    return userConsoles.map(x => ConsoleType[x]).join(', ')
  }, [userConsoles])
  const appUser = useAppUser()
  const isMyProfile = appUser && appUser.Id === user?.Id

  return (
    <>
      <TextField label="Player username" value={user?.Username} />
      <TextField label="About profile" value={user?.About} />
      <div className="d-flex">
        <TextField label="Region" value={userRegion?.RegionName} />
        <TextField label="Country" className="ml-2" value={userCountry?.CountryName} />
      </div>
      <div className={cn(styles.rightTile, 'pb-2 mt-2')}>Games</div>
      <>
        {usersGamesIds.length > 0
          ? (
            <Carousel
              renderItem={(value, index) => <UserGame containerName={ContainerName.GamesImagesCard} key={index} gameId={value} />}
              values={usersGamesIds}
              window={10}
              className={styles.games}
            />
          )
          : (
            <div className={cn(styles.rightValue, 'pb-2 mt-2')}>No games</div>
          )
        }
      </>
      <div className={cn(styles.rightTile, 'pb-2 mt-3')}>Platforms</div>
      {(isMyProfile || consolesText !== '') && (
        <TextField label="Platforms" value={consolesText} />
      )}
      <div className="d-flex ">
        {(isMyProfile || user?.Ps4Id) && (
          <TextField label="PS4 Id" value={user?.Ps4Id} />
        )}
        {(isMyProfile || user?.XboxId) && (
          <TextField label="Xbox Id" className={(isMyProfile || (user?.Ps4Id)) ? "ml-2" : ''} value={user?.XboxId} />
        )}
      </div>
      <div className="d-flex ">
        {(isMyProfile || user?.SteamId) && (
          <TextField label="Steam Id" value={user?.SteamId} />
        )}
        {(isMyProfile || user?.BattlenetUsername) && (
          <TextField label="Battlenet Id" className={(isMyProfile || (user?.SteamId)) ? "ml-2" : ''} value={user?.BattlenetUsername} />
        )}
      </div>
      <div className="d-flex ">
        {(isMyProfile || (user?.RiotUsername)) && (
          <TextField label="RIOT ID" value={user?.RiotUsername} />
        )}
        {(isMyProfile || (user?.Epicgameid)) && (
          <TextField label="Epicgame Id" className={(isMyProfile || (user?.RiotUsername)) ? "ml-2" : ''} value={user?.Epicgameid} />
        )}
      </div>
      <div className="d-flex ">
        {(isMyProfile || user?.NintendoId) && (
          <TextField label="Nintendo Id" value={user?.NintendoId} />
        )}
        {(isMyProfile || user?.ValveUsername) && (
          <TextField label="DISCORD ID" className={(isMyProfile || (user?.NintendoId)) ? "ml-2" : ''} value={user?.ValveUsername} />
        )}
      </div>
      <div className="d-flex ">
        {(isMyProfile || user?.IOsId) && (
          <TextField label="Ios Id" value={user?.IOsId} />
        )}
        {(isMyProfile || user?.AndroidId) && (
          <TextField label="Android Id" className={(isMyProfile || (user?.IOsId)) ? "ml-2" : ''} value={user?.AndroidId} />
        )}
      </div>
    </>
  )
}
