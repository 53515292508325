import { useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import { fetchApi } from 'server/server-utils'
import { makeGetIsDisputeDetailsRequested } from 'store/selectors/dispute-detail-selectors'
import { AppThunkAction, GetState } from 'store/types/common'
import { disputeDetailAdded, disputeDetailsLoaded, DisputeDetailType, disputeDetailUpdated } from 'store/types/dispute-details-types'
import { OperationTypes, run, useRun } from './operations-logic'

interface AddDisputeDetailModel {
  place: number
  comment: string
  matchParticipantId: number
}

interface UpdatedDisputeDetailModel {
  place: number
  comment: string
}

export const useAddDisputeDetail = (disputeId: number) => {
  const dispatch = useDispatch()
  return useRun(OperationTypes.updateDisputeDetails, disputeId, async (model: AddDisputeDetailModel) => {
    const disputeDetail = await fetchApi<DisputeDetailType>(`dispute/${disputeId}/details`, 'POST', model)
    dispatch(disputeDetailAdded({ disputeDetail }))
    return true
  })
}

export const useUpdateDisputeDetail = (disputeDetailId: number) => {
  const dispatch = useDispatch()
  return useRun(OperationTypes.updateDisputeDetails, disputeDetailId, async (disputeId: number, model: UpdatedDisputeDetailModel) => {
    const disputeDetail = await fetchApi<DisputeDetailType>(`dispute/${disputeId}/details/${disputeDetailId}`, 'PATCH', model)
    dispatch(disputeDetailUpdated({ disputeDetail }))
    return true
  })
}

const getIsDisputeDetailsRequested = makeGetIsDisputeDetailsRequested()

export const disputeDetailActionCreators = {
  loadDisputeDetails: (disputeId: number): AppThunkAction => async (dispatch: Dispatch, getState: GetState) => {
    if (getIsDisputeDetailsRequested(getState(), disputeId)) {
      return
    }
    await run(OperationTypes.loadDisputeDetails, disputeId, dispatch, getState, async () => {
      try {
        const disputeDetails = await fetchApi<DisputeDetailType[]>(`dispute/${disputeId}/details`, 'GET')
        dispatch(disputeDetailsLoaded({ disputeId, disputeDetails }))
      } catch (error) {
        return false
      }
      return true
    })
  },
}
