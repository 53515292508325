import { useImage } from 'components/_hooks'
import { Img } from 'components/_shared'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { makeGetBlogSection } from 'store/selectors/blog-selectors'
import { ApplicationState } from 'store/types/common'
import { MediaType } from 'store/types/image-types'
import { Video } from '../Img'

interface BlogSectionProps {
  id: number
  className?: string
  textClassName?: string
  imageClassName?: string
  blogTitle?:string
}

export const BlogSection = ({id, textClassName, imageClassName, blogTitle}: BlogSectionProps) => {
  const getBlogSectionById = useMemo(makeGetBlogSection, [])

  const section = useSelector((state: ApplicationState) => getBlogSectionById(state, { sectionId: id }))

  const { image, imageSrc } = useImage(section?.ImageId)

  return (
    <>
      {
        !!image && (
          image.MediaType === MediaType.Image
            ? (<Img alt={blogTitle} imageId={image.Id} className={imageClassName}/>)
            : (<Video controls muted src={imageSrc} className={imageClassName}></Video>)
        )
      }
      {!!section.Text && <div className={textClassName} dangerouslySetInnerHTML={{__html: section.Text}}></div>}
    </>
  )
}
