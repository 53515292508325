import { OrganizerEventsTab } from 'components/_pages/organizer/EventsOrganizer/EventsOrganizer'
import { TournamentModeEnum } from 'consts'
import { ConsoleEnum } from 'consts/ConsoleType'
import { DateIntervalEnum } from 'consts/DateInterval'
import { TournamentEventTypeEnum } from 'consts/TournamentEventType'
import { makeActionCreator, ReduxAction } from '../common'
import { TournamentConsolesType } from '../console-types'
import { IDictionary } from '../dictionary-types'
import { GameType } from '../game-types'
import { ImageType } from '../image-types'
import { TournamentType } from '../tournaments-types'


export const defaultOrganizerEventsFilters: OrganizerEventsPageFilters = {
  currentPage: 0,
  currentTab: 'upcoming',
  isLiveEvent: true,
  isOnlineEvent: true,
  name: '',
  selectedConsoles: [],
  selectedGames: [],
  selectedInterval: null,
  selectedMode: null,
  selectedEventType: null,
  dateFrom: null,
  dateTo: null,
}


export interface OrganizerEventsPageFilters {
  currentTab: OrganizerEventsTab
  currentPage: number
  isOnlineEvent: boolean
  isLiveEvent: boolean
  name: string
  selectedConsoles: IDictionary<ConsoleEnum>[]
  selectedGames: GameType[]
  selectedMode: IDictionary<TournamentModeEnum>
  selectedInterval: IDictionary<DateIntervalEnum>
  selectedEventType: IDictionary<TournamentEventTypeEnum>
  dateFrom: Date
  dateTo: Date
}


export interface OrganizerEventsPageState {
  filters: OrganizerEventsPageFilters
  upcomingTournamentsIds: number[]
  allUpcomingTournamentsCount: number
  activeTournamentsIds: number[]
  allActiveTournamentsCount: number
  finishedTournamentsIds: number[]
  allFinishedTournamentsCount: number
  canceledTournamentsIds: number[]
  allCanceledTournamentsCount: number
}


export const ORGANIZER_EVENTS_FILTERS_CHANGED = 'ORGANIZER_EVENTS_FILTERS_CHANGED'
export interface OrganizerEventsFilterChangedPayLoad {
  filters: OrganizerEventsPageFilters
}

export type OrganizerEventsFiltersChangedAction = ReduxAction<typeof ORGANIZER_EVENTS_FILTERS_CHANGED, OrganizerEventsFilterChangedPayLoad>
export const organizerEventsFiltersChanged = makeActionCreator<OrganizerEventsFilterChangedPayLoad>(ORGANIZER_EVENTS_FILTERS_CHANGED)


export const MY_ORGANIZER_UPCOMING_TOURNAMENTS_LOADED = 'MY_ORGANIZER_UPCOMING_TOURNAMENTS_LOADED'
interface MyOrganizerUpcomingTournamentsLoadedPayload {
  tournaments: TournamentType[]
  allMyOrganizerUpcomingTournamentsCount: number
  TournamentConsoles: TournamentConsolesType[] 
  Images: ImageType[]
}
export type MyOrganizerUpcomingTournamentsLoadedAction = ReduxAction<typeof MY_ORGANIZER_UPCOMING_TOURNAMENTS_LOADED, MyOrganizerUpcomingTournamentsLoadedPayload>
export const myOrganizerUpcomingTournamentsLoaded = makeActionCreator<MyOrganizerUpcomingTournamentsLoadedPayload>(MY_ORGANIZER_UPCOMING_TOURNAMENTS_LOADED)

export const MY_ORGANIZER_ACTIVE_TOURNAMENTS_LOADED = 'MY_ORGANIZER_ACTIVE_TOURNAMENTS_LOADED'
interface MyOrganizerActiveTournamentsLoadedPayload {
  tournaments: TournamentType[]
  allMyOrganizerActiveTournamentsCount: number
  TournamentConsoles: TournamentConsolesType[] 
  Images: ImageType[]
}
export type MyOrganizerActiveTournamentsLoadedAction = ReduxAction<typeof MY_ORGANIZER_ACTIVE_TOURNAMENTS_LOADED, MyOrganizerActiveTournamentsLoadedPayload>
export const myOrganizerActiveTournamentsLoaded = makeActionCreator<MyOrganizerActiveTournamentsLoadedPayload>(MY_ORGANIZER_ACTIVE_TOURNAMENTS_LOADED)

export const MY_ORGANIZER_FINISHED_TOURNAMENTS_LOADED = 'MY_ORGANIZER_FINISHED_TOURNAMENTS_LOADED'
interface MyOrganizerFinishedTournamentsLoadedPayload {
  tournaments: TournamentType[]
  allMyOrganizerFinishedTournamentsCount: number
  TournamentConsoles: TournamentConsolesType[] 
  Images: ImageType[]
}
export type MyOrganizerFinishedTournamentsLoadedAction = ReduxAction<typeof MY_ORGANIZER_FINISHED_TOURNAMENTS_LOADED, MyOrganizerFinishedTournamentsLoadedPayload>
export const myOrganizerFinishedTournamentsLoaded = makeActionCreator<MyOrganizerFinishedTournamentsLoadedPayload>(MY_ORGANIZER_FINISHED_TOURNAMENTS_LOADED)


export const MY_ORGANIZER_CANCELED_TOURNAMENTS_LOADED = 'MY_ORGANIZER_CANCELED_TOURNAMENTS_LOADED'
interface MyOrganizerCanceledTournamentsLoadedPayload {
  tournaments: TournamentType[]
  allMyOrganizerCanceledTournamentsCount: number
  TournamentConsoles: TournamentConsolesType[] 
  Images: ImageType[]
}
export type MyOrganizerCanceledTournamentsLoadedAction = ReduxAction<typeof MY_ORGANIZER_CANCELED_TOURNAMENTS_LOADED, MyOrganizerCanceledTournamentsLoadedPayload>
export const myOrganizerCanceledTournamentsLoaded = makeActionCreator<MyOrganizerCanceledTournamentsLoadedPayload>(MY_ORGANIZER_CANCELED_TOURNAMENTS_LOADED)


export type KnownAction = OrganizerEventsFiltersChangedAction 
  | MyOrganizerUpcomingTournamentsLoadedAction 
  | MyOrganizerActiveTournamentsLoadedAction
  | MyOrganizerFinishedTournamentsLoadedAction
  | MyOrganizerCanceledTournamentsLoadedAction
