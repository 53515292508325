import { Img, KlLink } from 'components/_shared'
import React, { useMemo } from 'react'
import { cn, defaultFormatDateTime } from 'utils'
import styles from '../UserReview.module.scss'
import ReactStars from 'react-rating-stars-component'
import { useSelector } from 'react-redux'
import { makeGetReview } from 'store/selectors/review-selectors'
import { ApplicationState } from 'store/types/common'
import { makeGetUser } from 'store/selectors/user-selectors'
import { getIsOrganizer, getIsPlayer, getOrganizerProfileUrl, getUserProfileUrl } from 'store/types/user-types'
import { ContainerName, useGetImageSize } from 'components/_hooks'

export interface ReviewProps {
  id: number
  starSize?: number
}

export const Review = ({ id, starSize }: ReviewProps) => {
  const getReview = useMemo(makeGetReview, [])
  const getUser = useMemo(makeGetUser, [])

  const review = useSelector((state: ApplicationState) => getReview(state, { id }))
  const user = useSelector((state: ApplicationState) => getUser(state, review?.ReviewerId))

  const url = useMemo(() => {
    if (!user) {
      return '#'
    }
    if (getIsPlayer(user.Role)) {
      return getUserProfileUrl(user.Id)
    }
    if (getIsOrganizer(user.Role)) {
      return getOrganizerProfileUrl(user.Id)
    }
    return '#'
  }, [user])
  const getImageSize = useGetImageSize(ContainerName.AvatarsNormal)
  return (
    <div className={cn('d-flex', styles.reviewContainer)}>
      <Img parentContainerSize={getImageSize} placeholder="user" className={styles.avatar} imageId={user?.AvatarId}/>
      <div className={styles.review}>
        <KlLink className={styles.username} to={url}>{user?.Username}</KlLink>
        <ReactStars
          count={5}
          value={review?.Rating ?? 0}
          size={starSize ?? 36}
          activeColor="#ec5418"
          edit={false}
          classNames={styles.star}
        />
        <div className={styles.text}>{review?.Text}</div>
        <div className={styles.date}>{defaultFormatDateTime(new Date(review?.Date))}</div>
      </div>
    </div>
  )
}
