import React, { ComponentType } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useAppUser } from 'components/_hooks'
import { Redirect } from 'react-router'
import { RouteWithoutLayout } from './RouteWithoutLayout'

interface LoginRouteProps {
  component?: ComponentType<RouteComponentProps<unknown>> | React.ComponentType<unknown>
  path: string
  exact?: boolean
  oldCss?: boolean
}

export const LoginRoute = ({ path, component, exact, oldCss }: LoginRouteProps) => {
  const appUser = useAppUser()

  const renderComponent = (props: RouteComponentProps) => {
    if (appUser) {
      return <Redirect to="/" />
    }

    const Component = component
    return (
      <Component {...props} />
    )
  }

  return (
    <RouteWithoutLayout oldCss={oldCss} exact={exact} path={path} component={renderComponent} />
  )
}
