import React from 'react'
import { ImagePlaceholder, useImage } from './useImage'
import { LazyLoadImage } from 'react-lazy-load-image-component'
interface ImgProps {
  imageId?: number
  src?: string
  className?: string
  style?: React.CSSProperties
  placeholder?: ImagePlaceholder
  alt?: string
  title?: string
  onMouseOver?: React.MouseEventHandler<HTMLImageElement>
  onMouseLeave?: React.MouseEventHandler<HTMLImageElement>
  parentContainerSize?:string
}

export const Img = ({
  imageId,
  src,
  className,
  style,
  placeholder,
  alt,
  onMouseOver,
  onMouseLeave,
  title,
  parentContainerSize
}: ImgProps) => {
  const { handleError, imageSrc } = useImage(imageId, placeholder,parentContainerSize)

  return (
    // <img
    //   className={className +" lazyload"}
    //   data-src={src || imageSrc}
    //   style={style}
    //   onError={handleError}
    //   alt={alt}
    //   title={title}
    //   onMouseOver={onMouseOver}
    //   onMouseLeave={onMouseLeave}
    // />
    <LazyLoadImage
      className={className?className +" lazyload":"lazyload"}
      data-src={src || imageSrc}
      style={style}
      onError={handleError}
      alt={alt}
      title={title}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    />
  )
}
