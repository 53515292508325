import { NewHorizontalScroll } from 'components/_shared'
import React, { ReactNode, useState } from 'react'
import { cn } from 'utils'
import stylesDark from '../Tabs.module.scss'
import stylesLight from '../TabsLight.module.scss'
import { useThemeStyles } from 'components/_hooks'

export interface TabMenuProps {
  children: ReactNode
}

export const TabMenu = ({ children }: TabMenuProps) => {
  const [left, setLeft] = useState(0)
  const [displayLeftArrow, setDisplayLeftArrow] = useState(false)
  const [displayRightArrow, setDisplayRightArrow] = useState(false)
  const styles = useThemeStyles(stylesDark, stylesLight)

  const renderLeftArrow = (onClick: () => void) => {
    return <button className={cn(styles.articleTabMenuButton, styles.left)} onClick={onClick}></button>
  }

  const renderRightArrow = (onClick: () => void) => {
    return <button className={cn(styles.articleTabMenuButton, styles.right)} onClick={onClick}></button>
  }

  return (
    <div className="col">
      <div className="row">
        <div className={cn(styles.articleTabMenuContainer, displayLeftArrow && styles.leftArrowDisplayed, displayRightArrow && styles.rightArrowDisplayed)}>
          <NewHorizontalScroll
            className={cn('row justify-content-start align-items-lg-start align-items-end flex-nowrap', styles.tabMenu)}
            left={left}
            onChange={setLeft}
            renderLeftArrow={renderLeftArrow}
            renderRightArrow={renderRightArrow}
            displayLeftArrow={displayLeftArrow}
            onDisplayLeftArrowChange={setDisplayLeftArrow}
            displayRightArrow={displayRightArrow}
            onDisplayRightArrowChange={setDisplayRightArrow}
          >
            {children}
          </NewHorizontalScroll>
        </div>
      </div>
    </div>
  )
}
