import { HubConnectionState } from '@microsoft/signalr'
import { createSelector } from 'reselect'
import { OperationTypes } from 'store/logic/operations-logic'
import { makeGetIsAnyOperationWithTypeRunning, makeGetIsOperationWithTypeRunning, makeGetIsOperationWithTypeSuccessOrRunning } from 'store/selectors/operations-selectors'
import { ApplicationState } from 'store/types/common'

const getState = (state: ApplicationState) => state.chat

export const getIsChatOpened = createSelector(getState, state => state?.isOpened || false)

export const getOpenedChatId = createSelector(getState, state => state?.openedChatId)

export const getOpenedUserId = createSelector(getState, state => state?.openedUserId)

export const getHubConnectionState = createSelector(getState, state => state.hubConnectionState)

const getPrivateChatIdByUserId = createSelector(getState, state => state?.privateChatIdByUserId || {})

const getById = createSelector(getState, state => state?.byId || {})

const getTournamentChat = createSelector(getState, state => state?.chatIdByTournamentId || {})
const getDisputeChat = createSelector(getState, state => state?.disputChatIdByMatchId || {})


export const makeGetPrivateChatIdByUserId = () => createSelector(
  getPrivateChatIdByUserId,
  (_: ApplicationState, { userId }: { userId: number }) => userId,
  (privateChatIdByUserId, userId) => privateChatIdByUserId[userId]
)
export const makeGetChatIds = () => createSelector(
  getById,
  byId => Object.keys(byId)
    .map(id => byId[Number(id)])
    .filter(ch => ch && !ch.IsDeleted)
    .map(ch => ch.Id)  
)

export const makeGetChat = () => createSelector(
  getById,
  (_: ApplicationState, { chatId }: { chatId: number }) => chatId,
  (byId, chatId) => byId[chatId]
)

export const makeGetTournamentChat = () => createSelector(
  getById,
  getTournamentChat,
  (_: ApplicationState, { torunamentDetailId }: { torunamentDetailId: number }) => torunamentDetailId,
  (byId, byTournamentId, torunamentDetailId) => {
    const chatId = byTournamentId[torunamentDetailId]
    return chatId && byId[chatId] || null
  }
)

export const makeGetDisputeChat = () => createSelector(
  getById,
  getDisputeChat,
  (_: ApplicationState, { matchId }: { matchId: number }) => matchId,
  (byId, disputeByMatchId, matchId) => {
    const chatId = disputeByMatchId[matchId]
    return chatId && byId[chatId] || null
  }
)

export const getIsHubsConnected = createSelector(
  getHubConnectionState,
  hubConnectionState => hubConnectionState === HubConnectionState.Connected
)

export const getIsHubsConnecting = createSelector(
  getHubConnectionState,
  hubConnectionState => hubConnectionState === HubConnectionState.Connecting
    || hubConnectionState === HubConnectionState.Reconnecting
)

const getIsCreatePrivateChatLoadingOperationType = createSelector(
  (_, { userId }: { userId: number }) => userId,
  userId => ({ type: OperationTypes.createPrivateChat, objectId: userId })
)
export const makeGetIsCreatePrivateChatLoading = () => makeGetIsOperationWithTypeRunning(getIsCreatePrivateChatLoadingOperationType)
export const makeGetIsCreatePrivateChatRequested = () => makeGetIsOperationWithTypeSuccessOrRunning(getIsCreatePrivateChatLoadingOperationType)


export const getIsChatsRequested = makeGetIsOperationWithTypeSuccessOrRunning(() => ({
  type: OperationTypes.loadChats,
  objectId: null,
}))

export const getIsChatsLoading = makeGetIsAnyOperationWithTypeRunning(() => ({
  type: OperationTypes.loadChats,
  objectId: null,
}))

