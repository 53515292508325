import { useRouteParam } from 'components/_hooks'
import { LoadingBox } from 'components/_shared'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useActivateUser } from 'store/logic/auth-logic'
import { getIsActivateUserLoading } from 'store/selectors/auth-selectors'
import styles from '../Login.module.scss'

interface ActivateRouteProps {
  hash: string
}

export const Activate = () => {
  const hash = useRouteParam<ActivateRouteProps>(x => x.hash)
  const activateUser = useActivateUser()
  const [isSuccess, setIsSuccess] = useState(false)
  const [error, setError] = useState('')
  const isActivateUserLoading = useSelector(getIsActivateUserLoading)

  useEffect(() => {
    const activate = async () => {
      try {
        await activateUser(hash)
        setIsSuccess(true)
      }
      catch (error) {
        setError((error as Error).message)
      }
    }
    void activate()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className={styles.background} />
      <div className="container-fluid" />
      <article className="d-flex flex-row align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className={styles.signIn__header}>Account Activation</h1>
              <div className="card m-auto" style={{ width: '36rem' }}>
                <div className="card-body">
                  <h5 className="card-title">Welcome To Kombatlink</h5>
                  <LoadingBox loading={isActivateUserLoading} size="average">
                    {error && <p className="card-text">{error}</p>}
                    {isSuccess &&
                    <>
                      <p className="card-text">Thank you for resgistration, your account has successfully activated.</p>
                      <p className="card-text">Click the button to visit login.</p>
                      <Link to="/login" className="btn btn-primary">
                        Login
                      </Link>
                    </>
                    }
                  </LoadingBox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  )
}
