export const fileToDataUrl = (file: File, onChange: (dataUrl: string) => void) => {
  const reader = new FileReader()
  reader.onload = () => {
    onChange(reader.result as string)
  }
  reader.readAsDataURL(file)
}

export const asyncFileToDataUrl = (file: File): Promise<string> => {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.onload = () => {
      resolve(reader.result as string)
    }
    reader.readAsDataURL(file)
  })
}
