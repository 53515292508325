import { useMemberClan } from 'components/_hooks'
import { NotificationType } from 'consts/NotificationType'
import { useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import { getLastNotificationId, getAppUserRole, getAppUser } from 'store/selectors/app-selectors'
import { makeGetClanMember } from 'store/selectors/clan-member-selectors'
import { getIsNotificationQueueRequested } from 'store/selectors/notification-selectors'
import { getMembersPage } from 'store/selectors/pages/clan-page-selectors'
import { makeGetAllParticipants, makeGetParticipants } from 'store/selectors/participant-selectors'
import { makeGetRound } from 'store/selectors/rounds-selectors'
import { clanMemberRemoved } from 'store/types/clan-member-types'
import {
  addNewNotifications,
  deleteNotification,
  NotificationModel,
  notificationQueueLoaded,
  notificationRead,
  setNotification,
  setNotifications,
  setUnreadNotificationsCount,
} from 'store/types/notification-types'
import { delay } from 'utils'
import { fetchApi } from '../../server/server-utils'
import { AppThunkAction, GetState } from '../types/common'
import { reloadClanInvitations } from './clan-invitation-logic'
import { reLoadFriendRequests } from './friend-request-logic'
import { reloadLineUps } from './line-ups-logic'
import { reloadMatchLinks } from './match-links-logic'
import { matchesActionCreators, reloadActiveMatches, reloadCheckInMatches, reloadFinishedMatches, reloadPendingMatches } from './match-logic'
import { loadTournamentMatchesParticipants } from './match-participant-logic'
import { OperationTypes, run, useRun } from './operations-logic'
import { loadClanMembers } from './pages/clan-page-logic'
import { roundsActionCreators } from './rounds-logic'
import { loadTournament } from './tournaments-logic'

let _started = false

interface LoadNotificationsResponse {
  allNotificationsCount: number
  notifications: NotificationModel[]
}

const readNotification = (id: number) => fetchApi<NotificationModel>(`notification/${id}/read`, 'POST')

const loadUnreadNotificationsCount = async (dispatch: Dispatch, getState: GetState) => {
  await run(OperationTypes.loadUnreadNotificationsCount, null, dispatch, getState, async () => {
    try {
      const unreadNotificationsCount = await fetchApi<number>('notification/UnreadCount', 'GET')
      dispatch(setUnreadNotificationsCount(unreadNotificationsCount))
    } catch (error) {
      return false
    }
    return true
  })
}

export const useReadNotification = (id: number) => {
  const dispatch = useDispatch()
  return useRun(OperationTypes.readNotification, id, async () => {
    const notification = await readNotification(id)
    dispatch(notificationRead(notification.Id))
    return true
  })
}


export const notificationActionCreators = {
  loadNotifications: (page: number, pageSize: number): AppThunkAction => async (dispatch: Dispatch, getState: GetState) => {
    await run(OperationTypes.loadNotifications, null, dispatch, getState, async () => {
      try {
        const response = await fetchApi<LoadNotificationsResponse>('notification', 'GET', {
          page,
          pageSize,
        })
        dispatch(setNotifications(response.notifications, response.allNotificationsCount, page === 0))
      } catch (error) {
        return false
      }
      return true
    })
  },
  loadUnreadNotificationsCount: (): AppThunkAction => async (dispatch: Dispatch, getState: GetState) => {
    await loadUnreadNotificationsCount(dispatch, getState)
  },
  readNotification: (id: number): AppThunkAction => async (dispatch: Dispatch, getState: GetState) => {
    await run(OperationTypes.readNotification, id, dispatch, getState, async () => {
      try {
        const notification = await readNotification(id)
        dispatch(setNotification(notification))
      } catch (error) {
        return false
      }
      return true
    })
  },
  deleteNotification: (id: number): AppThunkAction => async (dispatch: Dispatch, getState: GetState) => {
    await run(OperationTypes.deleteNotification, id, dispatch, getState, async () => {
      try {
        await fetchApi<NotificationModel>(`notification/${id}/delete`, 'POST')
        dispatch(deleteNotification(id))
      } catch (error) {
        return false
      }
      return true
    })
  },
  initNotifications: (): AppThunkAction => async (dispatch: Dispatch, getState: GetState) => {
    if (_started) return

    _started = true
    const lastNotificationId = getLastNotificationId(getState())
    await start(lastNotificationId, 10000, dispatch, getState)
  },
  loadNotificationQueue: (): AppThunkAction => async (dispatch: Dispatch, getState: GetState) => {
    if (getIsNotificationQueueRequested(getState())) {
      return
    }
    await run(OperationTypes.loadNotificationQueue, null, dispatch, getState, async () => {
      try {
        const notifications = await fetchApi<NotificationModel[]>('notification/queue', 'GET')
        dispatch(notificationQueueLoaded(notifications))
      } catch (error) {
        return false
      }
      return true
    })
  },  
}

interface AfterResponse {
  notifications: NotificationModel[]
  lastNotificationId: number
}

export const start = async (lastId: number, poolingInterval: number, dispatch: Dispatch, getState: GetState) => {
  const uniqNotifications = new Set<string>()
  
  // eslint-disable-next-line no-constant-condition
  while (true) {
    await delay(poolingInterval)

    let response: AfterResponse = null
    try {
      response = await fetchApi<AfterResponse>(`notification/${lastId}/after`, 'GET')
    } catch (error) {
      console.warn(error)
    }

    if (!response) {
      continue
    }

    if (!response.notifications.length) {
      lastId = response.lastNotificationId
      continue
    }

    dispatch(addNewNotifications(response.notifications))

    await loadUnreadNotificationsCount(dispatch, getState)

    uniqNotifications.clear()

    for (const notification of response.notifications) {
      if (uniqNotifications.has(notification.Type.toString() + notification.SubjectId.toString())) {
        continue
      }

      handleNotification(notification, dispatch, getState)
    }

    lastId = response.lastNotificationId
  }
}

const getRound = makeGetRound()
const getClanMember = makeGetClanMember()
const participants = makeGetAllParticipants()

const handleNotification = (notification: NotificationModel, dispatch: Dispatch, getState: GetState) => {
  const userRole = getAppUserRole(getState())
  switch (notification.Type) {
    case NotificationType.TournamentInitialized:
      if (userRole === 'Player') {
        void reloadPendingMatches(dispatch, getState)
        void reloadCheckInMatches(dispatch, getState)
      } else if (userRole === 'Organizer') {
        void loadTournament(notification.SubjectId, dispatch, getState)
      }
      const round =  getRound(getState(), notification.SubjectId)
      if(round?.TournamentDetailId){
        const tournamentDetailid = Number(round.TournamentDetailId)
        void reloadLineUps(tournamentDetailid,dispatch, getState)
      }
      break
    case NotificationType.CheckInReminder:
    case NotificationType.NewRoundCheckIn: 
    case NotificationType.ExtraRoundCheckIn:
      if (userRole === 'Player') {
        void reloadPendingMatches(dispatch, getState)
        void reloadCheckInMatches(dispatch, getState)
      } 
      break
    case NotificationType.SubmitReminder:
      if (userRole === 'Player') {
        void reloadActiveMatches(dispatch, getState)
      }
      break
    case NotificationType.MatchDisputed:
      if (userRole === 'Player') {        
        void reloadCheckInMatches(dispatch, getState)
        void reloadActiveMatches(dispatch, getState)
      }
      break
    case NotificationType.ParticipantAutoResizeDrop:
    case NotificationType.TournamentCanceled:
      if (userRole === 'Player') {
        void reloadPendingMatches(dispatch, getState)
      }
      void loadTournament(notification.SubjectId, dispatch, getState)
      break
    case NotificationType.TournamentParticipantVictory:
    case NotificationType.TournamentFinished:
      // reload data for victory road
      if (userRole === 'Player') {
        void reloadCheckInMatches(dispatch, getState)
        void reloadActiveMatches(dispatch, getState)
        void reloadFinishedMatches(dispatch, getState)
      }
      break
    case NotificationType.ParticipantMatchDisqualifyNotCheckIn:
      if (userRole === 'Player') {
        void reloadCheckInMatches(dispatch, getState)
        void reloadActiveMatches(dispatch, getState)
        void reloadFinishedMatches(dispatch, getState)
      }
      break
    case NotificationType.ParticipantMatchVictory:
    case NotificationType.ParticipantMatchDisqualifyNotSubmittedResult:
    case NotificationType.OpponentSubmittedLoseResult:
    case NotificationType.ParticipantSubmittedLoseResult:
    case NotificationType.DisputeResolvedParticipantWon:
    case NotificationType.DisputeResolvedParticipantLost:
    case NotificationType.ParticipantMatchLost:
    case NotificationType.ParticipantMovedToLoserBracket:
    case NotificationType.ParticipantMatchVictoryOpponentDisquilifiedNotSubmittedResult:
    case NotificationType.ParticipantMatchVictoryOpponentDisquilifiedNotCheckIn:
      if (userRole === 'Player') {
        void reloadPendingMatches(dispatch, getState)
        void reloadActiveMatches(dispatch, getState)
        void reloadFinishedMatches(dispatch, getState)
      }
      break
    case NotificationType.MatchLinkDeleted: 
    case NotificationType.MatchLinkPublished:
      if (userRole === 'Player') {
        void reloadMatchLinks(notification.SubjectId, dispatch, getState)
      }
      break
    case NotificationType.OrganizerCanceledTournament:
      if (userRole === 'Player') {
        void reloadPendingMatches(dispatch, getState)
        void reloadCheckInMatches(dispatch, getState)
        void reloadActiveMatches(dispatch, getState)
      }
      break
    case NotificationType.RoundStarted:
      if (userRole === 'Player') {
        void reloadCheckInMatches(dispatch, getState)
        void reloadActiveMatches(dispatch, getState)
      }
      else {
        const round = getRound(getState(), notification.SubjectId)
        if (round) {
          void roundsActionCreators.reLoadRounds(round.TournamentDetailId)(dispatch, getState)
          void matchesActionCreators.loadTournamentMatches(round.TournamentDetailId)(dispatch, getState)
          void loadTournamentMatchesParticipants(round.TournamentDetailId)(dispatch, getState)
        }
      }
      break
    case NotificationType.MatchFinished:
      if (userRole === 'Player') {
        void reloadPendingMatches(dispatch, getState)
      }
      break
    case NotificationType.ClanInvitationSent:
      if (userRole === 'Player') {
        const user = getAppUser(getState())
        void reloadClanInvitations(user?.Id)(dispatch, getState)
      }
      break
    case NotificationType.ClanDisbanded:
      if (userRole === 'Player') {
        const user = getAppUser(getState())
        const clanMember = getClanMember(getState(), { userId: user?.Id })
        dispatch(clanMemberRemoved({ clanId: clanMember.ClanId, userId: clanMember.UserId }))
      }
      break
    case NotificationType.MemberLeftClan:
    case NotificationType.InvitationAccepted:
      if (userRole === 'Player') {
        const user = getAppUser(getState())
        const clanMember = getClanMember(getState(), { userId: user?.Id })
        const membersPage = getMembersPage(getState())

        void loadClanMembers(clanMember.ClanId, {
          page: membersPage,
          pageSize: 12,
        })(dispatch, getState)
      }
      break
    case NotificationType.SendFriendRequest:
      if (userRole === 'Player') {
        void reLoadFriendRequests()(dispatch, getState)
      }
      break
  }
}
