import { useAppUser } from 'components/_hooks'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadFriendRequests } from 'store/logic/friend-request-logic'
import { loadMembersPlayers } from 'store/logic/pages/members-page-logic'
import { getAllPlayersCount, getIsPlayersLoading, getPlayersFilters, getPlayersIds } from 'store/selectors/pages/members-page-selectors'
import { membersPagePlayersFiltersChanged } from 'store/types/pages/members-page-types'
import { getIsPlayer } from 'store/types/user-types'
import { PlayerCard } from '../MemberCard'
import { MembersTab } from './MembersTab'

interface PlayersTabProps {
  filtersModalOpened: boolean
  closeFiltersModal: () => void
}

export const PlayersTab = ({ closeFiltersModal, filtersModalOpened }: PlayersTabProps) => {
  const dispatch = useDispatch()
  const playersFilters = useSelector(getPlayersFilters)
  const allPlayersCount = useSelector(getAllPlayersCount)
  const isPlayersLoading = useSelector(getIsPlayersLoading)
  const playersIds = useSelector(getPlayersIds)
  const appUser = useAppUser()

  useEffect(() => {
    if (getIsPlayer(appUser.Role)) {
      dispatch(loadFriendRequests())
    }
  }, [appUser.Role, dispatch])

  return (
    <>
      <MembersTab
        closeFiltersModal={closeFiltersModal}
        filtersModalOpened={filtersModalOpened}
        playersFilters={playersFilters}
        allPlayersCount={allPlayersCount}
        filtersChanged={membersPagePlayersFiltersChanged}
        isMembersLoading={isPlayersLoading}
        loadMembers={loadMembersPlayers}
      >
        {playersIds.map(id => <PlayerCard key={id} userId={id} />)}
      </MembersTab>
    </>
  )
}
