import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { notificationActionCreators } from 'store/logic/notification-logic'
import { getAppUserRole } from 'store/selectors/app-selectors'
import { makeGetReadNotificationLoading } from 'store/selectors/notification-selectors'
import { UserRole } from 'store/types/app-types'
import { ApplicationState, BoundActions } from 'store/types/common'
import { NotificationModel } from 'store/types/notification-types'
import { cn, fromNow } from 'utils'
import NotificationButtonLink from './NotificationButtonLink'
import styles from './NotificationRow.module.scss'

interface OwnProps {
  notification: NotificationModel
  close: (NotificationModel) => void
  onDelete: (notificationId: number) => Promise<void>
}

interface StateProps {
  readNotificationLoading: boolean
  userRole: UserRole
}

type DispatchProps = BoundActions<typeof notificationActionCreators>

type Props = OwnProps & StateProps & DispatchProps

const NotificationRow = ({ notification, readNotification, readNotificationLoading, loadUnreadNotificationsCount, close, onDelete }: Props) => {
  const handleRowClick = async () => {
    if (!notification.Read && !readNotificationLoading) {
      await readNotification(notification.Id)
      loadUnreadNotificationsCount()
    }
  }

  const handleButtonClick = () => {   
    close(notification)
  }

  const handleDelete = async event => {
    event.stopPropagation()
    onDelete(notification.Id)
  }

  return (
    <li className={cn(!notification.Read && styles.new, styles.notificationRow, styles.admin)} onClick={handleRowClick}>
      <div>
        <a href="#" onClick={handleDelete} className={cn(styles.window__close)} />
        <div className={styles.notification_text}>{notification.Message}</div>
        <div className={ styles.date}>{fromNow(notification.CreatedDate)}</div>
        {!!notification.Button && (
          <div className={cn('mt-1', 'd-flex', styles.buttons)}>
            <NotificationButtonLink notification={notification} onButtonClick={handleButtonClick} />         
          </div>
        )}
      </div>
    </li>
  )
}

const makeMapStateToProps = () => {
  const getReadNotificationLoading = makeGetReadNotificationLoading()

  return (state: ApplicationState, props: OwnProps): StateProps => {
    return {
      readNotificationLoading: getReadNotificationLoading(state, props.notification.Id),
      userRole: getAppUserRole(state),
    }
  }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  ...bindActionCreators(notificationActionCreators, dispatch),
})

export default connect<StateProps, DispatchProps, OwnProps, ApplicationState>(makeMapStateToProps, mapDispatchToProps)(NotificationRow)
