import * as React from 'react'
import ReactPlayerComponent from './_shared/ReactPlayerComponent'

interface Props {
  videoLink: string
  oganizerId?: number
}

interface State {}

class TournamentWatchComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {}
  }

  render() {
    const {} = this.props
    const {} = this.state
    return (
      <>
        <br />
        <div className="row m-0">
          <div className="col-12 video-wrapper" >
            <ReactPlayerComponent url={this.props.videoLink} />
          </div>
        </div>
        <br />
        {localStorage.getItem('userid') && parseInt(localStorage.getItem('userid')) == this.props.oganizerId && (
          <div className="row m-0 justify-content-end">
            <a
              className="article__edit-link blue-button blue-button--filled"
              onClick={() => {
                document.getElementById('change-link').className = 'modal-container modal-container modal-container--opened'
              }}
            >
              edit link
            </a>
          </div>
        )}
        <br />
        <aside className="bil_modal-container" id="change-link">
          <div className="modal-container__background" />
          <div className="modal-container__window window window--update-link">
            <div
              className="window__close"
              onClick={() => {
                document.getElementById('change-link').className = 'modal-container modal-container'
              }}
            />
            <div className="container-fluid">
              <div className="row">
                <div className="window__header">Do you want to change the streaming channel?</div>
              </div>
              <div className="row">
                <div className="window__subheader window__subheader--update-link">Streaming Link</div>
              </div>
              <div className="row">
                <input className="window__input" type="text" placeholder="Link" />
              </div>
              <div className="row justify-content-center">
                <a
                  className="window__button blue-button"
                  onClick={() => {
                    document.getElementById('change-link').className = 'modal-container modal-container'
                  }}
                >
                  cancel
                </a>
                <a className="window__button blue-button blue-button--filled" onClick={(e) => e.preventDefault()}>
                  update link
                </a>
              </div>
            </div>
          </div>
        </aside>
      </>
    )
  }
}

export default TournamentWatchComponent
