import { makeActionCreator, ReduxAction } from '../common'

export interface State {
  pageInitialized: boolean
  showOpen: boolean
  showClosed: boolean
}

export const DISPUTES_PAGE_INITIALIZED_CHANGED = 'DISPUTES_PAGE_INITIALIZED_CHANGED'
export interface InitializedChangedPayLoad {
  pageInitialized: boolean
}
export type InitializedChangeAction = ReduxAction<typeof DISPUTES_PAGE_INITIALIZED_CHANGED, InitializedChangedPayLoad>
export const initializedChanged = makeActionCreator<InitializedChangedPayLoad>(DISPUTES_PAGE_INITIALIZED_CHANGED)

export const SHOW_OPEN_CHANGED = 'SHOW_OPEN_CHANGED'
export interface ShowOpenChangedPayLoad {
  showOpen: boolean
}
export type ShowOpenChangedAction = ReduxAction<typeof SHOW_OPEN_CHANGED, ShowOpenChangedPayLoad>
export const showOpenChanged = makeActionCreator<ShowOpenChangedPayLoad>(SHOW_OPEN_CHANGED)

export const SHOW_CLOSED_CHANGED = 'SHOW_CLOSED_CHANGED'
export interface ShowClosedChangedPayLoad {
  showClosed: boolean
}
export type ShowClosedChangedAction = ReduxAction<typeof SHOW_CLOSED_CHANGED, ShowClosedChangedPayLoad>
export const showClosedChanged = makeActionCreator<ShowClosedChangedPayLoad>(SHOW_CLOSED_CHANGED)

export type KnownAction = InitializedChangeAction | ShowOpenChangedAction | ShowClosedChangedAction
