import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { makeGetClan } from 'store/selectors/clan-selectors'
import { makeGetParticipantById } from 'store/selectors/participant-selectors'
import { makeGetUser } from 'store/selectors/user-selectors'
import { getClanProfileUrl } from 'store/types/clan-types'
import { ApplicationState, PlayerType } from 'store/types/common'
import { ParticipantType } from 'store/types/participants-types'
import { getUserProfileUrl } from 'store/types/user-types'
import { useClanLeader } from './useClanLeader'

export interface UsePlayerResult {
  playerId?: number
  imageId?: number
  name: string
  isOnline: boolean
  participantType?: PlayerType
  participant?: ParticipantType
  profileUrl?: string
  clanLeaderId?: number
  IsBot?:boolean
}

export const useTournamentParticipant = (tournamentParticipantId?: number): UsePlayerResult => {
  const getParticipantById = useMemo(makeGetParticipantById, [])
  const getUser = useMemo(makeGetUser, [])
  const getClan = useMemo(makeGetClan, [])

  const participant = useSelector((state: ApplicationState) => getParticipantById(state, tournamentParticipantId))

  const user = useSelector((state: ApplicationState) => getUser(state, participant?.PlayerId))
  const clan = useSelector((state: ApplicationState) => getClan(state, participant?.ClanId))

  const clanLeader = useClanLeader(clan?.Id)
  
  if (clan) {
    return {
      playerId: clan.Id,
      name: clan.Name,
      imageId: clan.AvatarId,
      participantType: 'clan',
      participant,
      profileUrl: getClanProfileUrl(clan.Id),
      isOnline: clanLeader?.user?.IsOnline,
      clanLeaderId: clanLeader?.user?.Id,
      IsBot: clanLeader?.user?.Role === "Bot"?true:false
    }
  }

  if (user) {
    return {
      playerId: user.Id,
      name: user.Username,
      imageId: user.AvatarId,
      participantType: 'user',
      participant,
      isOnline: user.IsOnline,
      profileUrl: getUserProfileUrl(user.Id),
      IsBot:user?.Role === "Bot"?true:false
    }
  }

  return {
    name: '',
    isOnline: user?.IsOnline,
    participant: participant,
  }
}
