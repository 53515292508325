import { ImageSize } from 'components/_hooks/useGetImageSize'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { makeGetImage } from 'store/selectors/image-selectors'
import { ApplicationState } from 'store/types/common'
import { ImageType } from 'store/types/image-types'

export const imagePlaceholders = {
  dark: 'https://kombatlink-assets.azureedge.net/img/user-placeholder-dark.png',
  user: 'https://kombatlink-assets.azureedge.net/img/user-placeholder.png',
  noavatar: 'https://kombatlink-assets.azureedge.net/img/avatar-placeholder.png',
  tournament: 'https://kombatlink-assets.azureedge.net/img/kombatLinkTournamentLogo.jpg',
  games:'https://static-cdn.jtvnw.net/cf_vods/d2nvs31859zcd8/e12e78a978_general_mittenz_20040098592_413486355/thumb/customab451ed9d09cc6ed-320x180.jpeg'
}

export type ImagePlaceholder = keyof typeof imagePlaceholders

const getUrl = (image: ImageType, placeholder?: ImagePlaceholder,parentContainerSize?:string,isEnableImageOptimzation?:boolean) => {
  
    if(parentContainerSize === ImageSize.thumbnail){
      return image?.ThumbnailCdnUrl ||  image?.ThumbnailUrl || (placeholder ? imagePlaceholders[placeholder] : '')
    }
    else if(parentContainerSize === ImageSize.small){
      return image?.SmallCdnUrl || image?.SmallUrl || (placeholder ? imagePlaceholders[placeholder] : '')
    }
    else if(parentContainerSize === ImageSize.medium){
      return image?.MediumCdnUrl || image?.MediumUrl || (placeholder ? imagePlaceholders[placeholder] : '')
    }
    else{
      return image?.CdnUrl || image?.Url || (placeholder ? imagePlaceholders[placeholder] : '')
    }
  
}

export const useImage = (imageId: number, placeholder?: ImagePlaceholder,parentContainerSize?:string) => {
  const getImage = useMemo(makeGetImage, [])
  const image = useSelector((state: ApplicationState) => getImage(state, { imageId }))
  const [cdnUrlError, setCdnUrlError] = useState(false)
  const [urlError, setUrlerror] = useState(false)
  const [imageSrc, setImageSrc] = useState(getUrl(image, placeholder,parentContainerSize))
  
  const handleError = () => {
    if (!image) {
      return
    }
    if(image.ThumbnailCdnUrl && !cdnUrlError){
      setCdnUrlError(true)
      setImageSrc(image.ThumbnailUrl)
    }
    else if(image.SmallCdnUrl && !cdnUrlError){
      setCdnUrlError(true)
      setImageSrc(image.SmallUrl)
    }
    else if(image.MediumCdnUrl && !cdnUrlError){
      setCdnUrlError(true)
      setImageSrc(image.MediumUrl)
    }
    else if (image.CdnUrl && !cdnUrlError) {
      setCdnUrlError(true)
      setImageSrc(image.Url)
    } else if ((image.Url || image.SmallUrl || image.MediumUrl) && !urlError) {
      setUrlerror(true)
      setImageSrc(imagePlaceholders[placeholder])
    }
  }

  useEffect(() => {
    const url = getUrl(image, placeholder,parentContainerSize)
    setImageSrc(url)
  }, [image, placeholder,parentContainerSize])

  return {
    imageSrc,
    handleError,
    mediaType: image?.MediaType,
    error: cdnUrlError && urlError,
  }
}
