import { createSelector } from 'reselect'
import { MatchModel, TournamentMatchesTree } from 'store/types/match-types'
import { RoundType } from 'store/types/rounds-types'
import { mapArrayToObject } from 'utils'
import { OperationTypes } from '../logic/operations-logic'
import { ApplicationState } from '../types/common'
import {
  makeGetIsAnyOperationWithTypeRunning,
  makeGetIsOperationWithTypeRunning,
  makeGetIsOperationWithTypeSuccessOrRunning,
} from './operations-selectors'
import { getRoundById, makeGetFirstRoundId } from './rounds-selectors'
import { getTournamentsById } from './tournaments-selectors'

const getState = (state: ApplicationState) => state

const getMatchesState = (state: ApplicationState) => state.matches

const getMatchesById = createSelector(getMatchesState, state => state?.byId || {})
const getMatchesIdsByRoundId = createSelector(getMatchesState, state => state?.idsByRoundId || {})
const getIdsByTournamentDetailId = createSelector(getMatchesState, state => state?.idsByTournamentDetailId || {})
export const getPendingMatchesIds = createSelector(getMatchesState, state => state?.pendingMatchesIds ?? [])
export const getCheckInMatchesIds = createSelector(getMatchesState, state => state?.checkInMatchesIds || [])
export const getActiveMatchesIds = createSelector(getMatchesState, state => state?.activeMatchesIds || [])
export const getFinishedMatchesIds = createSelector(getMatchesState, state => state?.finishedMatchesIds || [])

export const getCheckInMatches = createSelector(getMatchesById, getCheckInMatchesIds, (matches, ids) => {
  return ids.map(id => matches[id])
})

export const getActiveMatches = createSelector(getMatchesById, getActiveMatchesIds, (matches, ids) => {
  return ids.map(id => matches[id])
})

export const getFinishedMatches = createSelector(getMatchesById, getFinishedMatchesIds, (matches, ids) => {
  return ids.map(id => matches[id])
})

export const getIsCheckInMatchesLoading = makeGetIsAnyOperationWithTypeRunning(() => ({
  type: OperationTypes.loadCheckInMatches,
}))
export const getIsCheckInMatchesRequested = makeGetIsOperationWithTypeSuccessOrRunning(() => ({
  type: OperationTypes.loadCheckInMatches,
  objectId: null,
}))

export const getIsActiveMatchesLoading = makeGetIsAnyOperationWithTypeRunning(() => ({
  type: OperationTypes.loadActiveMatches,
}))
export const getIsActiveMatchesRequested = makeGetIsOperationWithTypeSuccessOrRunning(() => ({
  type: OperationTypes.loadActiveMatches,
  objectId: null,
}))

export const getIsFinishedMatchesLoading = makeGetIsAnyOperationWithTypeRunning(() => ({
  type: OperationTypes.loadFinishedMatches,
}))

export const getIsFinishedMatchesRequested = makeGetIsOperationWithTypeSuccessOrRunning(() => ({
  type: OperationTypes.loadFinishedMatches,
  objectId: null,
}))

interface TournamentTreeSelectorType {
  tournamentId: number
  isLosersBracket: boolean
}

export const getTournamentMatchesTree = createSelector(
  getMatchesById,
  getIdsByTournamentDetailId,  
  getTournamentsById,
  getRoundById,
  (_: ApplicationState, { tournamentId, isLosersBracket } : TournamentTreeSelectorType) => ({ tournamentId, isLosersBracket }),
  (allMatches, idsByTournamentId, tournaments, allRounds, { tournamentId, isLosersBracket = false }) => {
    if (!idsByTournamentId[tournamentId]) {
      return null
    }
    const tournament = tournaments[tournamentId]

    if (!tournament) {
      return null
    }
    
    const matches = idsByTournamentId[tournamentId].map(id => allMatches[id]).filter(m => allRounds[m.RoundId]?.IsLosersBracket == isLosersBracket)
    if (matches.length == 0) {
      return null
    }

    const rounds  = matches.reduce((prev: RoundType[], current: MatchModel)=> { 
      if (prev.findIndex(r => r.Id == current.Id) < 0) 
        return [...prev, allRounds[current.RoundId]] 
      return prev
    }, [])

    const roundDict = mapArrayToObject(rounds, r => r.Id, res => res)

    const lastRound = rounds.filter(r => !r.IsExtraRound && r.IsLosersBracket == isLosersBracket ).sort((a, b) => b.RoundNumber - a.RoundNumber)[0]

    const root = matches.find(m => lastRound.Id == m.RoundId)
    const extraRound =  matches.find(m => roundDict[m.RoundId].IsExtraRound)

    const tournamentTree = getTournamentTree(lastRound.RoundNumber, matches, root, tournament.ParticipientsPerMatch ?? 2, roundDict)

    if (!extraRound) {
      return tournamentTree
    } else {
      return {
        Match: extraRound,
        Matches: [tournamentTree],
      }
    }
  }
)

export const makeGetTournamentMatchesTree = () => getTournamentMatchesTree

const getPrevRoundMatches = (match: MatchModel, prevRoundNumber: number, matches: MatchModel[], participantsPerMatch: number, allRounds: {[key:number] : RoundType}): MatchModel[] => {

  if (matches.filter(m => allRounds[m.RoundId].RoundNumber == prevRoundNumber).length === 0) {
    return []
  }
  const matchBExists = matches.filter(m => allRounds[m.RoundId].RoundNumber == prevRoundNumber).length > matches.filter(m => m.RoundId == match.RoundId).length
  
  const matchNumbers = matchBExists ? Array(participantsPerMatch).fill(0).map((x, ind) => match.MatchNumber + ind) : [match.MatchNumber]
  const prevMatches = matches.filter(m => matchNumbers.includes(m.MatchNumber) && allRounds[m.RoundId].RoundNumber == prevRoundNumber)
  return prevMatches
}

const getTournamentTree = (round: number, matches: MatchModel[], match: MatchModel, participantsPerMatch: number, allRounds: {[key:number] : RoundType}): TournamentMatchesTree => {  
  const prevRoundNumberId = matches.filter(m => allRounds[m.RoundId].RoundNumber < round).sort((a, b) => allRounds[b.RoundId].RoundNumber - allRounds[a.RoundId].RoundNumber)[0]?.RoundId

  const prevRoundNumber = allRounds[prevRoundNumberId]?.RoundNumber

  const prevMatches = getPrevRoundMatches(match, prevRoundNumber, matches, participantsPerMatch, allRounds)

  if (prevMatches.length > 0) {
    return {
      Match: match,
      Matches: prevMatches.map(pm => getTournamentTree(prevRoundNumber, matches, pm, participantsPerMatch, allRounds)),
    }
  }

  return {
    Match: match,
    Matches: [],
  }
}

export const makeGetRoundMatchesIds = () =>
  createSelector(
    getMatchesById,
    getMatchesIdsByRoundId,
    (_: ApplicationState, roundId: number) => roundId,
    (byId, ids, roundId) => (ids[roundId] || []).sort((a, b) => byId[a].MatchNumber - byId[b].MatchNumber)
  )

export const makeGetMatch = () =>
  createSelector(
    getMatchesById,
    (_: ApplicationState, matchId: number) => matchId,
    (byId, matchId) => byId[matchId]
  )

export const makeGetRoundMatches = () => {
  const getRoundMatchesIds = makeGetRoundMatchesIds()
  return createSelector(getMatchesById, getRoundMatchesIds, (byId, ids) => ids.map(id => byId[id]))
}

export const makeGetFirstRoundMatchesIds = () => {
  const getFirstRoundId = makeGetFirstRoundId()
  const getRoundMatchesIds = makeGetRoundMatchesIds()

  return createSelector(getState, getFirstRoundId, (state, firstRoundId) => {
    return getRoundMatchesIds(state, firstRoundId)
  })
}

const getLoadTournamentMatchesOperation = createSelector(
  (_, tournamentDetailId: number) => tournamentDetailId,
  tournamentDetailId => ({ type: OperationTypes.loadTournamentMatches, objectId: tournamentDetailId })
)
export const makeGetTournamentMatchesLoading = () => makeGetIsOperationWithTypeRunning(getLoadTournamentMatchesOperation)

export const getPendingMatchIdByRoundId = createSelector(
  getMatchesById,
  getPendingMatchesIds,
  (byId, ids) => {
    const matches = ids.map(id => byId[id])
    return mapArrayToObject(matches, x => x.RoundId, x => x.Id)
  }
)

export const makeGetMatchesIdsByTournamentDetailId = () =>
  createSelector(
    getIdsByTournamentDetailId,
    (_: ApplicationState, { tournamentDetailId }: { tournamentDetailId: number }) => tournamentDetailId,
    (ids, tournamentDetailId) => ids[tournamentDetailId] ?? []
  )

export const getIsPendingMatchesLoading = makeGetIsAnyOperationWithTypeRunning(() => ({
  type: OperationTypes.loadPendingMatches,
}))

export const getIsPendingMatchesRequested = makeGetIsOperationWithTypeSuccessOrRunning(() => ({
  type: OperationTypes.loadPendingMatches,
  objectId: null,
}))

export const getTempMatchesIds = createSelector(
  getMatchesById,
  byId => Object.keys(byId).map(id => parseInt(id)).filter(id => id < 0)
)
