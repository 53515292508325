import { ChatLefModel } from 'store/chat/chat-member/chat-member-types'
import { ChatReceivedMessageModel } from 'store/chat/chat-message/chat-message-types'
import { ChatDeletedModel, ChatMemberBlockedModel, TypingModel } from 'store/chat/chat/chat-types'
import { OnlineStatusModel } from 'store/types/user-types'
import { useSubscribe } from '../subscriber'

export const useOnMessageReceived = (listener: (chatMessage: ChatReceivedMessageModel) => void) => {
  useSubscribe('MessageReceived', listener)
}


export const useOnOnlineStatusReceived = (listener: (onlineStatus: OnlineStatusModel) => void) => {
  useSubscribe('OnlineStatusReceived', listener)
}


export const useOnChatLeftNotificationReceived = (listener: (model: ChatLefModel) => void) => {
  useSubscribe('ChatLeftNotificationReceived', listener)
}

export const useOnChatDeletedNotificationReceived = (listener: (model: ChatDeletedModel) => void) => {
  useSubscribe('ChatDeletedNotificationReceived', listener)
}


export const useOnChatMemberBlockedReceived = (listener: (model: ChatMemberBlockedModel) => void) => {
  useSubscribe('ChatMemberBlockedReceived', listener)
}

export const useSayWhoIsTyping = (listener: (model: TypingModel) => void) => {
  useSubscribe('SayWhoIsTyping', listener)
}

export const useReloadTournamentDetailReceived = (listener: (type:string,matchId: number) => void) => {
  useSubscribe('ReloadTournamentDetail', listener)
}