import { MutableRefObject, useEffect, useRef } from 'react'

export const useScrollToElement = (enabled: boolean, behavior?: ScrollBehavior): MutableRefObject<HTMLDivElement> => {
  const ref = useRef<HTMLDivElement>()
  const isScrolling = useRef<boolean>(false)

  useEffect(() => {
    if (enabled) {
      if (!isScrolling.current) {
        isScrolling.current = true
        ref.current.scrollIntoView({
          behavior: behavior,
          block: 'center',
        })
      }
    } else {
      isScrolling.current = false
    }
  }, [enabled, behavior])

  return ref
}
