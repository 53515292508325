import { Button } from 'components/_shared/Button'
import React from 'react'
import { useSelector } from 'react-redux'
import { useReloadPendingMatches } from 'store/logic/match-logic'
import { getAppUser } from 'store/selectors/app-selectors'
import { ParticipantType } from 'store/types/participants-types'
import { cn } from 'utils'
import { TournamentViewModel_kl } from 'models/tournament-viewmodel'

import styles from './TournamentRegistration.module.scss'
import { VenueTypeEnum } from 'consts'

interface Props {
  tournament: TournamentViewModel_kl  
  participants: ParticipantType[]
  onParticipantJoin: () => Promise<void>
  onParticipantLeave: () => Promise<void>
}

const PlayerRegistrationButton = ({tournament, participants, onParticipantJoin, onParticipantLeave}: Props) => {

  const user = useSelector(getAppUser)
  const reloadPendingMatches = useReloadPendingMatches()  
  
  const isLoggedInUserJoined = React.useMemo(() => {
    if (!user) return false
    if (!participants || !tournament) return false
    
    return participants.some(x => x.PlayerId === user.Id)
  }, [participants, tournament, user])
  
  const IsParticipentTicketPurchased =  React.useMemo(()=>{
    if (!user) return false
    if (!participants || !tournament) return false
    const tickets = tournament.TournamentTickets
    const participanttickets = tickets.filter(x=>x.IsParticipent === true && x.SoldOut === 1 && x.UserId === user?.Id)
    return  participanttickets.length > 0
  },[tournament])
  const signInText = React.useMemo(() => {
    if(tournament.VenueTypeId === VenueTypeEnum.Live)
        return isLoggedInUserJoined && IsParticipentTicketPurchased ? 'Cancel' : 'Join'
    else  
        return isLoggedInUserJoined ? 'Cancel' : 'Join'
  }, [tournament,isLoggedInUserJoined,IsParticipentTicketPurchased])

  
  const handleJoinClick = () => {
    if (!user || !tournament) {
      return
    }

    if (isLoggedInUserJoined) {
      void onParticipantLeave()
    } else {
      void onParticipantJoin()
    }
    void reloadPendingMatches()
  }
 
  
  return (
    <Button 
      filled color="blue" 
      className={cn(styles.registration__join, 'btn-block', styles.registration__join__online)}  
      onClick={handleJoinClick} 
    >
      <div className={styles.registration__join__wrapper}>
        <div className="col p-0">{signInText}</div>                                
      </div>
    </Button>       
  )
}

export default PlayerRegistrationButton
