import { TournamentClanSignUpModel } from 'models/clan-member-view-model'
import { useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import { EndPoints } from 'server/endpoints'
import { makeGetIsParticipantRequested, makeGetIsParticipantsRequested } from 'store/selectors/participant-selectors'
import { ParticipantType, participantsLoaded, ParticipantsModel, participantAdded, participantDeleted, participantsAllDeleted, notificationForParticipantsAllDeletedFromState } from 'store/types/participants-types'
import { fetchApi } from '../../server/server-utils'
import { AppThunkAction, GetState } from '../types/common'
import { lineUpsActionCreators } from './line-ups-logic'
import { cancel, OperationTypes, run, useRun } from './operations-logic'

const getIsParticipantsRequested = makeGetIsParticipantsRequested()
const getIsParticipantRequested = makeGetIsParticipantRequested()

const getParticpantsUri = (tournamentDetailId: number) => {
  return `tournament/${tournamentDetailId}/participant`
}
const removeParticipantTicketUri = (cartDetailId:number) => {
  return `TournamentCart/deletecart?cartDetailId=${cartDetailId}`
}
const getParticpantUri = (tournamentDetailId: number, participantId: number) => {
  return `tournament/${tournamentDetailId}/participant/${participantId}`
}

export function fetchParticpants(tournamentDetailId: number) {
  return fetchApi<ParticipantsModel>(getParticpantsUri(tournamentDetailId), 'GET')
}

export function loadParticpant(tournamentDetailId: number, participantId: number) {
  return fetchApi<ParticipantsModel>(getParticpantUri(tournamentDetailId, participantId), 'GET')
}

export function joinParticpant(tournamentDetailId: number) {
  return fetchApi<ParticipantType>(`${getParticpantsUri(tournamentDetailId)}/join`, 'POST')
}

export function joinParticpantByOrganizer(tournamentDetailId: number,userId:number) {
  return fetchApi<ParticipantType>(`${getParticpantsUri(tournamentDetailId)}/joinbyorganizer`, 'POST',{userId : userId})
}

export function unjoinParticpant(tournamentDetailId: number) {
  return fetchApi<number>(`${getParticpantsUri(tournamentDetailId)}/unjoin`, 'POST')
}
export function unjoinParticpantByOrganizer(tournamentDetailId: number,userId:number) {
  return fetchApi<number>(`${getParticpantsUri(tournamentDetailId)}/unjoinByOrganizer`, 'POST',{userId : userId})
}

export function RemoveTournamentParticipant(tournamentDetailId: number, tournamentParticipantId: number) {
  return fetchApi(`${getParticpantsUri(tournamentDetailId)}/removetournamentparticipant/`, 'POST',  {tournamentParticipantId : tournamentParticipantId})
}
export function RemoveTournamentParticipantTicket(cartDetailId: number) {
  return fetchApi(`${removeParticipantTicketUri(cartDetailId)}`, 'GET')
}
export function joinClanMembers(data: TournamentClanSignUpModel) {
  return fetchApi<any>(`${getParticpantsUri(data.tournamentId)}/joinclanmembers`,'POST',data)
}
export function joinClanMembersOnly(data: TournamentClanSignUpModel) {
  return fetchApi<any>('clanlineup/addmembers','POST',data)
}

// export const useCancelParticipation = () => {
//   const dispatch = useDispatch()
//   return useRun(OperationTypes.unjoin, null, async (tournamentDetailId: number,cartDetailId:number=null,isClanMember:boolean=false) => {
//     if(!isClanMember){
//       const id = await unjoinParticpant(tournamentDetailId)
//       dispatch(participantDeleted({tournamentDetailId: tournamentDetailId, participantId: id}))
//     }
//     if(cartDetailId!==null){
      
//       await RemoveTournamentParticipantTicket(cartDetailId)
//     }
    
//     return true
//   })
// }
export const useCancelParticipation = () => {
  const dispatch = useDispatch()
  return useRun(OperationTypes.unjoin, null, async (tournamentDetailId: number,userId:number=null,isClanMember:boolean=false) => {
    if(!isClanMember){
      const id = await unjoinParticpant(tournamentDetailId)
      dispatch(participantDeleted({tournamentDetailId: tournamentDetailId, participantId: id}))
    }
    if(userId!==null){
      await RemoveTournamentParticipantTicketByOrganizer(tournamentDetailId,userId,isClanMember)
      //await RemoveTournamentParticipantTicket(cartDetailId)
    }
    
    return true
  })
}

export const useAddParticipation = () => {
  const dispatch = useDispatch()
  return useRun(OperationTypes.join, null, async (tournamentDetailId: number) => {
    const participant = await joinParticpant(tournamentDetailId)
    dispatch(participantAdded(participant))
    return true
  })
}
export const useAddClanMembersParticipation = () => {
  const dispatch = useDispatch()
  return useRun(OperationTypes.join, null, async (data: TournamentClanSignUpModel) => {
    if(data.IsAddOnlyClanMember){
      const members = await joinClanMembersOnly(data)
      if(members){
        dispatch(lineUpsActionCreators.reloadLineUps(data.tournamentId))
        return true
      }
      else{
        return false
      }
    }
    else{
      const participant = await joinClanMembers(data)
      if(participant){
        dispatch(participantAdded(participant))
        dispatch(lineUpsActionCreators.reloadLineUps(data.tournamentId))
        dispatch(participantsActionCreators.reloadParticpants(data.tournamentId))
        return true
      }
      else{
        return false
      }
    }
    
    
  })
}


export const useAddParticipationByOrganizer = () => {
  const dispatch = useDispatch()
  return useRun(OperationTypes.join, null, async (tournamentDetailId: number,userId:number) => {
    const participant = await joinParticpantByOrganizer(tournamentDetailId,userId)
    dispatch(participantAdded(participant))
    return true
  })
}

export const useDeleteParticipant = () => {
  const dispatch = useDispatch()
  return useRun(OperationTypes.deleteParticipant, null, async (tournamentDetailId: number, tournamentParticipantId: number) => {
    await RemoveTournamentParticipant(tournamentDetailId, tournamentParticipantId)
    dispatch(participantDeleted({tournamentDetailId: tournamentDetailId, participantId: tournamentParticipantId}))
    return true
  })
}

export const loadParticpants = async (tournamentDetailId: number, dispatch: Dispatch, getState: GetState) => {
  if (getIsParticipantsRequested(getState(), tournamentDetailId)) {
    return
  }
  await run(OperationTypes.loadParticipants, tournamentDetailId, dispatch, getState, async () => {
    const model = await fetchParticpants(tournamentDetailId)
    dispatch(participantsLoaded(model))
    return true
  })
}
export const notificationfordeleteAllParticipantsFromState = async (tournamentDetailId: number, dispatch: Dispatch, getState: GetState) => {  
  await run(OperationTypes.notifiactionForDeleteAllParticipantsFromState, tournamentDetailId, dispatch, getState, async () => {
    dispatch(notificationForParticipantsAllDeletedFromState({tournamentDetailId:tournamentDetailId}))
    return true
  })
}
export const reloadParticpants = async (tournamentDetailId: number, dispatch: Dispatch, getState: GetState) => {
  cancel(OperationTypes.loadParticipants, tournamentDetailId, dispatch, getState)
  await loadParticpants(tournamentDetailId, dispatch, getState)
}

export const participantsActionCreators = {
  loadParticpants: (tournamentDetailId: number): AppThunkAction => async (dispatch: Dispatch, getState: GetState) => {
    return loadParticpants(tournamentDetailId, dispatch, getState)
  },
  loadParticpant: (tournamentDetailId: number, id: number): AppThunkAction => async (dispatch: Dispatch, getState: GetState) => {
    if (getIsParticipantRequested(getState(), id)) {
      return
    }
    await run(OperationTypes.loadParticipant, id, dispatch, getState, async () => {
      const participant = await loadParticpant(tournamentDetailId, id)
      dispatch(participantsLoaded(participant))
      return true
    })
  },
  reloadParticpants: (tournamentDetailId: number): AppThunkAction => async (dispatch: Dispatch, getState: GetState) => {
    return reloadParticpants(tournamentDetailId, dispatch, getState)
  },
  notificationfordeleteAllParticipantsFromState: (tournamentDetailId: number): AppThunkAction => async (dispatch: Dispatch, getState: GetState) => {
    return notificationfordeleteAllParticipantsFromState(tournamentDetailId, dispatch, getState)
  }
}

export const useCancelParticipationByOrganizer = () => {
  const dispatch = useDispatch()
  return useRun(OperationTypes.unjoin, null, async (tournamentDetailId: number,userId:number) => {
      const id = await unjoinParticpantByOrganizer(tournamentDetailId,userId)
      dispatch(participantDeleted({tournamentDetailId: tournamentDetailId, participantId: id}))
      await RemoveTournamentParticipantTicketByOrganizer(tournamentDetailId,userId,false)
    return true
  })
}

export function RemoveTournamentParticipantTicketByOrganizer(tournamentDetailId: number,userId:number,isClanMember? : boolean) {
  return fetchApi(`${EndPoints.TournamentCart.RemoveParticipantTicketByOrganizer}`, 'POST',  {tournamentDetailId : tournamentDetailId,userId : userId,isClanMember : isClanMember})
}

