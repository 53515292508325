import { ContainerName, useAppSelector } from 'components/_hooks'
import { UserAvatar } from 'components/_shared'
import React, { ReactNode, useMemo } from 'react'
import { makeGetUser } from 'store/selectors/user-selectors'
import { cn } from 'utils'
import styles from './ChatPerson.module.scss'

export interface ChatPersonProps {
  children?: ReactNode
  personNameClassName?: string
  personStatusClasnName?: string
  onClick?: CallableFunction
  userId: number
}

export const ChatPerson = ({ children, personNameClassName, personStatusClasnName, userId, onClick }:  ChatPersonProps) => {

  const getUser = useMemo(makeGetUser, [])

  const user = useAppSelector(state => getUser(state, userId))

  const handleClick = () => {
    if (onClick) {
      onClick()
    }
  }

  return (
    <div className="row m-0-15 align-items-stretch h-100">
      <div className="col-auto p-0 d-flex align-items-center position-relative">
        <UserAvatar containerName={ContainerName.AvatarsNormal} userId={userId} className={styles.chatPersonPicture} placeholder="dark"  />
        <div className={cn(styles.chatPersonStatus, personStatusClasnName, user?.IsOnline && styles.online)} />
      </div>
      <div className="col d-flex flex-column justify-content-center p-0">
        <div className={cn(styles.chatPersonName, personNameClassName)} onClick={handleClick}>{user?.Username || user?.Organizationname}</div>
      </div>
      {children}
    </div>
  )
}
