import { ContainerName, useClan, useClanLeader } from 'components/_hooks'
import { ButtonLink } from 'components/_shared'
import React from 'react'
import { cn } from 'utils'
import styles from '../MemberCard.module.scss'
import { Column, MemberAvatarColumn, MemberNameColumn } from '../Columns'
import { calcWinRate } from 'store/types/common'
import { getClanProfileUrl } from 'store/types/clan-types'

interface ClanCardProps {
  clanId: number
}

export const ClanCard = ({ clanId }: ClanCardProps) => {
  const { clan } = useClan(clanId)
  const { user: clanLeader } = useClanLeader(clan?.Id)

  const winRate = calcWinRate(clan)

  return (
    <div className={cn('col-auto', styles.memberContainer)}>
      <div className={styles.member}>
        <div className="row">
          <MemberAvatarColumn containerName={ContainerName.AvatarsNormal} imageId={clan.AvatarId} />
          <MemberNameColumn name={clan.Name} id={clanId} type="clan" />
          {clan && <Column className={styles.memberFirstColumn} label="Members" value={clan.MembersCount.toString()} />}
          {clanLeader && <Column className={styles.memberSecondThirdColumn} label="Captain" value={clanLeader.Username} />}
          <Column className={styles.memberFirstColumn} label="Win Ratio" value={winRate.toString()} />
          <Column className={styles.memberSecondColumn} label="Match" value={`${clan.TotalMatches} Battles`} />
          <Column className={styles.memberThirdColumn} label="EXP Points" value={`${clan.TotalExpPoints} Points`} />
          <div className={cn('col-12', styles.memberButtons)}>
            <div className="row justify-content-center flex-nowrap">
              <ButtonLink to={getClanProfileUrl(clanId)} color="grey" filled hoverColor="greenHover" className={cn(styles.memberButton, styles.left)}>
                View profile
              </ButtonLink>
              {/* 
              <Button onClick={handleSendMessageClick} color="grey" filled className={styles.memberButton} disabled dataTip="Coming soon">
                Send message
              </Button>
              */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
