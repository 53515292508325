import { ConsoleEnum } from 'consts/ConsoleType'
import { Dispatch } from 'redux'
import { fetchApi } from 'server/server-utils'
import { makeGetIsUserConsolesRequested } from 'store/selectors/console-selector'
import { GetState } from 'store/types/common'
import { userConsolesLoaded } from 'store/types/console-types'
import { cancel, OperationTypes, run } from './operations-logic'

const getIsUserConsolesRequested = makeGetIsUserConsolesRequested()

export const loadUserConsoles = (userId: number) => async (dispatch: Dispatch, getState: GetState) => {
  if (getIsUserConsolesRequested(getState(), { userId })) {
    return
  }
  await run(OperationTypes.loadUserConsoles, userId, dispatch, getState, async () => {
    const consoles = await fetchApi<ConsoleEnum[]>(`console/user/${userId}`, 'GET')
    dispatch(userConsolesLoaded({ consoles, userId }))
    return true
  })
}

export const reLoadUserConsoles = (userId: number) => async (dispatch: Dispatch, getState: GetState) => {
  cancel(OperationTypes.loadUserConsoles, userId, dispatch, getState)
  await loadUserConsoles(userId)(dispatch, getState)
}
