import { useMemo } from 'react'
import { Theme } from 'store/types/common'
import { useIsAdminRoute } from './useRoute'

export const useTheme = () => {
  const isAdminRoute = useIsAdminRoute()
  const theme: Theme = useMemo<Theme>(() => {
    if (isAdminRoute) {
      return 'light'
    }
    return 'dark'
  }, [isAdminRoute])

  return theme
}

type cssModule = { readonly [key: string]: string }

export const useThemeStyles = (dark: cssModule, light: cssModule) => {
  const theme = useTheme()

  const styles = useMemo(() => {
    return theme === 'dark' ? dark : light
  }, [dark, light, theme])

  return styles
}
