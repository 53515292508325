import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { loadPlayerProfileByUserId } from 'store/logic/user-logic'
import { loadMyReview } from 'store/logic/review-logic'
import { ReviewsList } from './_shared/RewiesList'
import { PublicUserType } from 'store/types/user-types'

export interface OrganizerReviewProps {
  user: PublicUserType
  loggedInuser: PublicUserType
}

export const OrganizerReview = ({user , loggedInuser}: OrganizerReviewProps) => {
  const dispatch = useDispatch()
  
  useEffect(() => {
    if (user?.Id) {
      dispatch(loadPlayerProfileByUserId(user?.Id))
      dispatch(loadMyReview(user?.Id))
    }
  }, [dispatch, user])
  
  return (
    <>
      <div className="d-flex mt-5">
        <ReviewsList user={user} loggedInuser={loggedInuser}/>
      </div>
    </>
  )
}
