import React, { ReactNode } from 'react'
import { cn } from 'utils'
import { useDropDownTableStyles } from './styles/useDropDownTableStyles'

export interface DropDownTableRadioProps {
  onClick: () => void
  checked: boolean
  children?: ReactNode
  className?: string
  classNameChecked?: string
}

export const DropDownTableRadio = ({ onClick, checked, className, children, classNameChecked }: DropDownTableRadioProps) => {
  const styles = useDropDownTableStyles()

  return (
    <>
      <label onClick={onClick} className={cn(styles.tableRadio, className, checked && styles.tableRadioChecked, checked && classNameChecked)} />
      {children}
    </>
  )
}
