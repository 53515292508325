import { useAppSelector, useAppUser } from 'components/_hooks'
import { Button } from 'components/_shared/Button'
import { ChatMemberStatus } from 'consts'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { useChatUsers } from 'store/chat/chat-member/chat-member-hooks'
import { addToChat, loadChatFilteredMembers } from 'store/chat/chat-member/chat-member-logic'
import { makeGetChatFilteredUserIds } from 'store/chat/chat-member/chat-member-selectors'
import { useChat } from 'store/chat/chat/chat-hooks'
import { сreateGroupChat } from 'store/chat/chat/chat-logic'
import { ChatContainer, ChatHeader, ChatInput, ChatInputLabel, ChatPersonInvite, ChatSearchInput } from '../../_shared'
import styles from './CreateGroupChat.module.scss'


export const CreateGroupChat = () => {

  const dispatch = useDispatch()
  const appUser = useAppUser()
  const { chat } = useChat()
  const chatUsers = useChatUsers(chat?.Id)
  const [chatName, setChatName] = useState(chat?.Name ?? '')
  const [userName, setUserName] = useState('')
  
  const getChatFilteredUserIds = useMemo(makeGetChatFilteredUserIds, [])
  const users = useAppSelector(getChatFilteredUserIds)

  const [selectedPersons, setSelectedPersons] = useState<number[]>([])

  const history = useHistory()


  const chatPersonsInvites = useMemo<ChatPersonInvite[]>(() => {
    const activeChatUsers =  chatUsers.filter(cu => cu.Member.Status == ChatMemberStatus.Active || cu.Member.Status == ChatMemberStatus.Blocked)
    const personInvites = users.filter(id => activeChatUsers.findIndex(cu => cu.User?.Id == id) < 0)
      .map(id => ({
        userId: id,
        isChecked: selectedPersons.findIndex(p => p == id) >= 0,
      }))

    const selected = selectedPersons.filter(x => personInvites.findIndex(y => y.userId == x) < 0)
      .map(x => ({
        userId: x,
        isChecked: true,
      }))

    return [...personInvites, ...selected]
  }, [chatUsers, selectedPersons, users])

 
  useEffect(() => {
    if (appUser?.Id) {
      dispatch(loadChatFilteredMembers(appUser.Id, userName))
    }
  }, [appUser?.Id, dispatch, userName])

  const handleChatPersonChange = (value: ChatPersonInvite) => {
    if (value.isChecked) {
      setSelectedPersons([...selectedPersons, value.userId])
    } else {
      setSelectedPersons([...selectedPersons.filter(id => id != value.userId)])
    }
  }


  const handleCreateGroup = () => {
    dispatch(сreateGroupChat({
      UserIds: selectedPersons,
      ChatName: chatName,
    }))
    history.push('/')    
  }

  
  const handleAddToChat = () => {
    dispatch(addToChat({
      Users: selectedPersons,
      ChatId: chat.Id,
    }))
    history.push('/')    
  }

  const isButtonDisabled = !chatName || chatPersonsInvites.filter(cp => cp.isChecked).length == 0


  return (
    <>
      <ChatHeader>
        <ChatHeader.BackButton />
        {chat ? (
          <ChatHeader.Container>Add to chat</ChatHeader.Container>
        ): (
          <ChatHeader.Container>Create group</ChatHeader.Container>
        )}
      </ChatHeader>
      <ChatContainer className={styles.chat}>
        <div className="container-fluid">
          <ChatInputLabel>Group name</ChatInputLabel>
          <ChatInput placeholder="Group name" value={chatName} onChange={setChatName} disabled={!!chat} />
          <ChatInputLabel>Select members</ChatInputLabel>
          <ChatSearchInput value={userName} onChange={setUserName}  />
        </div>
        {chatPersonsInvites.map(chatPerson => (          
          <ChatPersonInvite key={chatPerson.userId} chatPerson={chatPerson} onChange={handleChatPersonChange} />
        ))}
      </ChatContainer>
      <div className={styles.createButtonContainer}>
        {chat ? (
          <Button color="blue" filled low className="w-100" disabled={isButtonDisabled} onClick={handleAddToChat}>Add to chat</Button>
        ) : (
          <Button color="blue" filled low className="w-100" disabled={isButtonDisabled} onClick={handleCreateGroup}>Create group</Button>
        )}
      </div>
    </>
  )
}
