import React, { useState } from 'react'
import "./ChatSearch.module.scss"
function ChatSearch(props: any) {
  const { onSearch,onShowSearchInput } = props
  const [showSearchInput, setShowSearchInput] = useState(false)
  const onShowSearch = () => {
    onSearch('')
    setShowSearchInput(!showSearchInput)
    onShowSearchInput(!showSearchInput)
  }
  const onSearchInput = (e: any) => {
    onSearch(e.target.value)
  }
  return (
    <div className="col-auto chat-header__search-container chat-search"
      style={{ display: "flex" }} >
      {!showSearchInput && <div className="chat-header__search" onClick={onShowSearch}></div>}
      {showSearchInput && <input className="form-control" type="text" placeholder="Search..." onChange={onSearchInput} />}
      {showSearchInput && <a className="ChatBackButton_chatHeaderBack__1ungB _back" href="#" onClick={onShowSearch}></a>}
    </div>
  )
}

export default ChatSearch
