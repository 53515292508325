import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadGames } from 'store/logic/game-logic'
import { loadRegions } from 'store/logic/region-logic'
import { loadOrganizers } from 'store/logic/user-logic'
import { getGames, getGamesLoading } from 'store/selectors/game-selectors'
import { getRegions, getRegionsLoading } from 'store/selectors/region-selectors'
import { getOrganizers, getOrganizersLoading } from 'store/selectors/user-selectors'
import { GameType } from 'store/types/game-types'
import { RegionType } from 'store/types/region-types'
import { PublicUserType } from 'store/types/user-types'

interface UseFiltersResult {
    organizers: PublicUserType[]
    organizersLoading: boolean
    games: GameType[]
    gamesLoading: boolean
    regions: RegionType[]
    regionsLoading: boolean
}

export const useFilters = (): UseFiltersResult => {
  const dispatch = useDispatch()
  const organizers = useSelector(getOrganizers)
  const organizersLoading = useSelector(getOrganizersLoading)
  const games = useSelector(getGames)
  const gamesLoading = useSelector(getGamesLoading)
  const regions = useSelector(getRegions)
  const regionsLoading = useSelector(getRegionsLoading)

  useEffect(() => {
    dispatch(loadOrganizers)
    dispatch(loadGames)
    dispatch(loadRegions)
  }, [dispatch])

  return {
    organizers,
    organizersLoading,
    games,
    gamesLoading,
    regions,
    regionsLoading,
  }
}
