export enum TournamentTicketSentStatusEnum {
  Pending = 1,
  Active = 2,
  Sent = 3,
  Failed = 4
}

export const TournamentStatus = {
  [TournamentTicketSentStatusEnum.Pending]: 'Pending',
  [TournamentTicketSentStatusEnum.Active]: 'Active',
  [TournamentTicketSentStatusEnum.Sent]: 'Sent',
  [TournamentTicketSentStatusEnum.Failed]: 'Failed'
}
