import { useDialogWithParameter } from 'components/_hooks'
import { Button } from 'components/_shared/Button'
import React from 'react'
import { cn } from 'utils'
import { SaveChangesModal } from '../../SaveChangesModal/SaveChangesModal'

interface SaveChangesButtonProps {
  matchId?: number
  className?: string
  disabled?: boolean
}

export const SaveChangesButton = ({ matchId, className, disabled }: SaveChangesButtonProps) => {
  const handleClick = () => {
    openSaveChangesModal(matchId)
  }

  const [saveChangesModal, openSaveChangesModal] = useDialogWithParameter<number>((matchId, closeDialog) => (
    <SaveChangesModal
      onClose={closeDialog}
      matchId={matchId}
    />
  ))

  return (
    <>
      <Button
        onClick={handleClick}
        disabled={disabled}
        loading={false}
        filled
        color="blue"
        className={cn('btnSaveChanges', className)}
        dataTip={disabled ? 'Select places for all participants' : ''}
      >
        Save changes
      </Button>
      {saveChangesModal}
    </>
  )
}
