import { useKlHistory } from 'components/_hooks/useKlHistory'
import React from 'react'
import '../TournamentDetails.scss'


export const BackButton = () => {
  const history = useKlHistory()

  const onClick = () => {
    history.goBack()
  }


  return (  
    <div onClick={onClick} className="see-more go-back">
      <svg width="13" height="11" viewBox="0 0 13 11" fill="none" className="td__rFix" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.52989e-07 6.41669L9.28571 6.41669L6.0357 9.62501L7.42855 11L13 5.5L7.42858 -4.66605e-07L6.03573 1.37499L9.28571 4.58334L3.92712e-07 4.58334L5.52989e-07 6.41669Z"
          fill="white"
        />
      </svg>
      Go Back
    </div>
  )
}

