import { createSelector } from 'reselect'
import { OperationTypes } from 'store/logic/operations-logic'
import { ApplicationState } from '../types/common'
import { makeGetIsAnyOperationWithTypeRunning, makeGetIsOperationWithTypeRunning, makeGetIsOperationWithTypeSuccessOrRunning } from './operations-selectors'

const getState = (state: ApplicationState) => state.friendRequests

const getPendingFriendsIds = createSelector(getState, state => state?.pendingFriendsIds || [])

const getById = createSelector(getState, state => state?.byId || {})

const getIdByUserId = createSelector(getState, state => state?.idByUserId || {})

export const getIncomingRequestsIds = createSelector(getState, state => state?.incomingIds || [])

export const makeGetIsFriendRequested = () =>
  createSelector(
    getPendingFriendsIds,
    (_: ApplicationState, { friendId }: { friendId: number }) => friendId,
    (pendingFriendsIds, friendId) => pendingFriendsIds.includes(friendId)
  )

export const makeGetFriendRequest = () =>
  createSelector(
    getById,
    (_: ApplicationState, { id }: { id: number }) => id,
    (byId, id) => byId[id]
  )

export const makeGetFriendRequestByUserId = () =>
  createSelector(
    getIdByUserId,
    getById,
    (_: ApplicationState, { userId }: { userId: number }) => userId,
    (idByUserId, byId, userId) => byId[idByUserId[userId]]
  )

export const getFriendRequestsLoading = makeGetIsAnyOperationWithTypeRunning(() => ({
  type: OperationTypes.loadFriendRequests,
}))
export const getFriendRequestsRequested = makeGetIsOperationWithTypeSuccessOrRunning(() => ({
  type: OperationTypes.loadFriendRequests,
  objectId: null,
}))

const getIsResolveFriendRequestOperationType = createSelector(
  (_: ApplicationState, id: number) => id,
  id => ({ type: OperationTypes.resolveFriendRequest, objectId: id })
)
export const makeGetIsResolveFriendRequestLoading = () => makeGetIsOperationWithTypeRunning(getIsResolveFriendRequestOperationType)
