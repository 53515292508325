import { ContainerName, useTournamentParticipant } from 'components/_hooks'
import { useDialogWithParameter } from 'components/_hooks/useComponents/useDialog'
import { ParticipantAvatar, VideoPlayerModal } from 'components/_shared'
import { DivImg } from 'components/_shared/Img'
import MatchResultModal, { ParticipantsResults } from 'components/_shared/MatchResultModal/MatchResultModal'
import * as React from 'react'
import { useMemo } from 'react'
import ReactPlayer from 'react-player'
import { useSelector } from 'react-redux'
import { makeGetGame } from 'store/selectors/game-selectors'
import { makeGetMatchParticipantsByMatchId } from 'store/selectors/match-participant-selectors'
import { makeGetParticipantsByTournamentDetailId } from 'store/selectors/participant-selectors'
import { makeGetTournament } from 'store/selectors/tournaments-selectors'
import { ApplicationState } from 'store/types/common'
import { MatchLinkModel } from 'store/types/match-link-types'
import { MatchModel } from 'store/types/match-types'
import { cn } from 'utils'
import styles from './list.module.scss'

interface Props {
  match: MatchModel
  matchLink: MatchLinkModel
}

const defaultImage = 'https://static-cdn.jtvnw.net/cf_vods/d2nvs31859zcd8/e12e78a978_general_mittenz_20040098592_413486355/thumb/customab451ed9d09cc6ed-320x180.jpeg'

const MoviesStreamComponent = ({ match, matchLink }: Props) => {
  const getTournament = React.useMemo(makeGetTournament, [])
  const getGame = useMemo(makeGetGame, [])
  const tournament = useSelector((state: ApplicationState) => getTournament(state, match.TournamentDetailId))
  const game = useSelector((state: ApplicationState) => getGame(state, { gameId: tournament?.GameId }))

  const getMatchParticipants = React.useMemo(makeGetMatchParticipantsByMatchId, [])
  const matchParticipants = useSelector((state: ApplicationState) => getMatchParticipants(state, match.Id))

  const getParticipants = React.useMemo(makeGetParticipantsByTournamentDetailId, [])
  const participants = useSelector((state: ApplicationState) => getParticipants(state, match.TournamentDetailId))

  const stream = React.useMemo(() => {
    const participant = participants.find(p => p.Id == matchLink.TournamentParticipantId)
    return {
      participant: participant,
      videoUrl: matchLink.Link,
      screenshotId: game?.ImageId,
      screenshot: !game?.ImageId ? `/images/games/${game?.Id}.jpg` : defaultImage ,
    }
  }, [participants, matchLink, game])

  const [matchResultModal, openMatchResultModal] = useDialogWithParameter<ParticipantsResults>((participantsResults, closeDialog) => (
    <MatchResultModal closeDialog={closeDialog} participantsResults={participantsResults} />
  ))
  const [videoPlayerModal, openVideoPlayerModal] = useDialogWithParameter<string>((url, closeDialog) => (
    <VideoPlayerModal closeDialog={closeDialog} videoUrl={url} />
  ))

  const viewDetails = (event: React.MouseEvent) => {
    event.preventDefault()
    openMatchResultModal({ match: match, matchParticpants: matchParticipants, participants: participants })
  }

  const playVideo = (event: React.MouseEvent) => {
    event.preventDefault()
    if (stream?.videoUrl) {
      openVideoPlayerModal(stream.videoUrl)
    }
  }

  const { name } = useTournamentParticipant(stream?.participant?.Id)
 const closeDialog=()=>{

 }
  return (
    <>
      <div className={cn('col-auto', styles.movies__movie)}>
        <div className="row m-0">
          <div className={styles.movies__stream}>
          
            <DivImg 
              useBackgroundStyles={false} 
              className={cn(styles.movies__streamPreview, stream?.videoUrl && styles.hasPlayer)}
              imageId={stream.screenshotId}
              placeholder="games"
              //src={!game.ImageId ? `/images/games/${!game?.Id}.jpg` : undefined}
              onClick={playVideo}
            />
            {stream?.participant && (
              <div className={styles.movies__streamAuthor}>
                <ParticipantAvatar containerName={ContainerName.AvatarsNormal} participantId={stream.participant.Id} className={styles.movies__streamProfilePicture} withLink />
                <div className={styles.movies__streamUsername}>
                  {name}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="row m-0 align-items-center">
          <div className="col p-l-0 p-r-10">
            <div className={styles.movies__number}>
              <span>#{match.MatchNumber}</span>
            </div>
          </div>
          <div className="col-auto p-0">
            <a
              className={styles.movies__details}
              onClick={viewDetails}
            >
              View details
            </a>
          </div>
          {matchResultModal}
          {videoPlayerModal}
        </div>
      </div>
    </>
  )
}


export default MoviesStreamComponent
