import React from 'react'
import { SvgProps } from './common'

export const SoloModeSvg = ({ className }: SvgProps) => {
  return (
    <svg className={className} width="21" height="22">
      <path d="M10.351 10.5975C11.7408 10.5975 12.9441 10.0753 13.9274 9.04505C14.9107 8.01498 15.4091 6.75463 15.4091 5.29856C15.4091 3.843 14.9107 2.58248 13.9272 1.55207C12.9438 0.522169 11.7406 0 10.351 0C8.96114 0 7.75807 0.522169 6.77482 1.55224C5.79156 2.58231 5.29297 3.84283 5.29297 5.29856C5.29297 6.75463 5.79156 8.01515 6.77498 9.04522C7.75839 10.0751 8.96162 10.5975 10.351 10.5975Z" />
      <path d="M19.2006 16.917C19.1722 16.4883 19.1149 16.0207 19.0304 15.5269C18.9452 15.0294 18.8355 14.5591 18.7041 14.1292C18.5684 13.6849 18.3838 13.2462 18.1557 12.8257C17.9189 12.3893 17.6407 12.0093 17.3286 11.6966C17.0023 11.3695 16.6027 11.1065 16.1406 10.9146C15.6801 10.7238 15.1699 10.6271 14.624 10.6271C14.4096 10.6271 14.2023 10.7193 13.8019 10.9924C13.5555 11.1607 13.2673 11.3554 12.9456 11.5708C12.6705 11.7544 12.2978 11.9264 11.8375 12.0822C11.3884 12.2344 10.9324 12.3116 10.4824 12.3116C10.0323 12.3116 9.57651 12.2344 9.12694 12.0822C8.66711 11.9266 8.29445 11.7545 8.01968 11.5709C7.701 11.3576 7.41261 11.1629 7.16251 10.9922C6.76261 10.7191 6.55513 10.627 6.34076 10.627C5.79474 10.627 5.28461 10.7238 4.8243 10.9148C4.36256 11.1063 3.96281 11.3693 3.63613 11.6968C3.32419 12.0097 3.04589 12.3895 2.80941 12.8257C2.58142 13.2462 2.39685 13.6848 2.26099 14.1294C2.12977 14.5593 2.02002 15.0294 1.93478 15.5269C1.85035 16.02 1.79299 16.4878 1.76463 16.9175C1.73676 17.3384 1.72266 17.7753 1.72266 18.2166C1.72266 19.365 2.07113 20.2947 2.7583 20.9804C3.43698 21.657 4.335 22.0002 5.42704 22.0002H15.5387C16.6307 22.0002 17.5284 21.6571 18.2073 20.9804C18.8946 20.2952 19.2431 19.3654 19.2431 18.2164C19.2429 17.7732 19.2286 17.3359 19.2006 16.917Z" />
    </svg>
  )
}
