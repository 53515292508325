import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { makeGetParticipantsByTournamentDetailId } from 'store/selectors/participant-selectors'
import { ApplicationState } from 'store/types/common'
import { ParticipantType } from 'store/types/participants-types'

interface UseTournamentParticipantsResult {
  tournamentParticipants: ParticipantType[]
}

export const useTournamentParticipants = (tournamentDetailId?: number): UseTournamentParticipantsResult => {
  const getTournamentParticipants = useMemo(makeGetParticipantsByTournamentDetailId, [])
  const tournamentParticipants = useSelector((state: ApplicationState) => getTournamentParticipants(state, tournamentDetailId))

  return {
    tournamentParticipants,
  }
}
