export const APP_NAMESPACE="ticketsections"

export const SHAPE_TYPES = {
  RECT: "rect",
  CIRCLE: "circle",
  SQUARE:"square",
  ELLIPSE:"ellipse",
  ARC:"curve",
  TRIANGLE:'triangle'
};
export const DEFAULT_LAYOUT={
  CIRCLE: {
    STROKE: "#5F6D8C",
    FILL: "#6cd000",
    RADIUS:80
  },
  SQUARE:{
    STROKE: "#5F6D8C",
    FILL: "#6cd000",
    WIDTH: 150,
    HEIGHT: 150,
    ROTATION: 0
  },
  ELLIPSE: {
    STROKE: "#5F6D8C",
    FILL: "#ffffff",
    RADIUSX: 60,
    RADIUSY: 90,
    ROTATION: 0
  }
}
export const DEFAULT_LAYOUT_COLOR={
  CIRCLE: {
    STROKE: "#5F6D8C",
    FILL: "#6cd000"
  },
  SQUARE:{
    STROKE: "#5F6D8C",
    FILL: "#6cd000"
  },
  ELLIPSE: {
    STROKE: "#5F6D8C",
    FILL: "#6cd000"
  }
}
export const DEFAULTS = {
  RECT: {
    STROKE: "#5b6988",
    FILL: "#34415f",
    WIDTH: 100,
    HEIGHT: 50,
    ROTATION: 0,
  },
  CIRCLE: {
    STROKE: "#5b6988",
    FILL: "#34415f",
    RADIUS: 50,
  },
  SQUARE:{
    STROKE: "#5b6988",
    FILL: "#34415f",
    WIDTH: 90,
    HEIGHT: 90,
    ROTATION: 0
  },
  ELLIPSE: {
    STROKE: "#5b6988",
    FILL: "#34415f",
    RADIUSX: 60,
    RADIUSY: 90,
    ROTATION: 0
  },
  ARC:{
    STROKE: "#5b6988",
    FILL: "#34415f",
    INNERRADIUS:40,
    OUTERADIUS:70,
    ANGLE:"150",
    ROTATION:"-250"
  },
  TRIANGLE: {
    STROKE: "#5b6988",
    FILL: "#34415f",
    WIDTH: 100,
    HEIGHT: 100,
    ROTATION: 0,
    RADIUS:100,
    POINTS: [45, 56, 78, 300, 357, 67]
  },
};

export const LIMITS = {
  RECT: {
    MAX: 1000,
    MIN: 10,
  },
  SQUARE: {
    MAX: 1000,
    MIN: 10,
  },
  CIRCLE: {
    MAX: 500,
    MIN: 5,
  },
  ELLIPSE: {
    MAX: 500,
    MIN: 5,
  },
  TRIANGLE: {
    MAX: 500,
    MIN: 10,
  },
};

export const DRAG_DATA_KEY = "__drag_data_payload__";

export function degToRad(deg:any) {
  return (deg * Math.PI) / 180;
}

export function radToDeg(rad:any) {
  return (rad * 180) / Math.PI;
}
export function getPosition(centerPoint:any, radius:any, angle:any) {
  var rad = degToRad(angle);
  return {
    x: centerPoint.x + radius * Math.cos(rad),
    y: centerPoint.y + radius * Math.sin(rad)
  };
}
export function getAngle(p1:any, p2:any) {
  // angle in radians
  var angleRadians = Math.atan2(p2.y - p1.y, p2.x - p1.x);

  // angle in degrees
  var angleDeg = (angleRadians * 180) / Math.PI;

  return angleDeg;
}