import React from 'react'
import { cn } from 'utils'
import styles from '../modals.module.scss'

export interface ModalFileInputProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
  label: string
}

export const ModalFileInput = ({ onChange, disabled, label }:  ModalFileInputProps) => {
  return (
    <>
      <input
        className={styles.window__file_input}
        id="screenshots"
        type="file"
        accept="image/*"
        onChange={onChange}
        disabled={disabled}
      />
      <label className={cn(styles.window__file)} htmlFor="screenshots">
        {label}
      </label>
    </>
  )
}
