import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

const RECAPTCHA = () => {
  return (
    <div className="recaptcha">
      <div className="recaptcha-inner">
        <ReCAPTCHA size="invisible" badge="bottomright" sitekey="6Le5o48UAAAAAL2wyjCQXbpiVUgucJ8mcs2WZOap" />
      </div>
    </div>
  )
}

export default RECAPTCHA
