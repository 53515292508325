import { UserModel, AdminUsersViewModel } from '../../models/user-models'
import { ExecuteAPIGet, ExecuteAPIPost } from '../server-utils'
import { EndPoints } from '../endpoints'
import { OrganizationViewModel } from 'models/organization-viewmodel'
import { AdminMangerUserViewModel } from 'models/admin-manage-user-viewmodel'
import { AdminAnalysisViewModel } from '../../models/admin-analysis-viewmodel'
import { PlayerBattlesAndLoyaltyPointsViewModel } from 'models/Player_OrganizerHeaderViewModel'
import { CountryWiseRegistration } from 'models/user-country-wise-resgistration-viewmidel'
import { FilterPlayersDto } from 'models/filterplayer-viewmodel'
import { ListPlayersResult } from 'store/logic/pages/clan-page-logic'
import { ListPlayersResultModel } from 'store/types/pages/members-page-types'

export async function GetAllOrganizers(id?: number) {
  if (id) return await ExecuteAPIGet<UserModel[]>(EndPoints.User.GetAllOrganizers, `id=${id}`)
  else return await ExecuteAPIGet<UserModel[]>(EndPoints.User.GetAllOrganizers)
}

export async function GetAllByID(id: number) {
  return await ExecuteAPIGet<UserModel>(EndPoints.User.GetByID, `id=${id}`)
}

export async function Save(model: UserModel) {
  return await ExecuteAPIPost<number>(EndPoints.User.Save, model)
}

export async function LogoutUserApi() {
  return await ExecuteAPIPost(EndPoints.User.Logout)
}

export async function ValidateUserExist(userNameORemailAddress: string) {
  return await ExecuteAPIPost<number>(EndPoints.User.ValidateUserExist, {
    userNameORemailAddress,
  })
}

export async function ValidateSecurityCode(userId: number, securityCode: string) {
  return await ExecuteAPIPost<boolean>(EndPoints.User.ValidateSecurityCode, {
    userId,
    securityCode,
  })
}

export async function ResetPassword(userId: number, newPassword: string) {
  return await ExecuteAPIPost<boolean>(EndPoints.User.ResetPassword, { userId, newPassword })
}

export async function LogoutUser() {
  await LogoutUserApi()
  ClearUser()
}

export function ClearUser() {
  localStorage.clear()
  sessionStorage.clear()
}

export function ValidateEmail(email) {
  const emailformat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return email && email.match(emailformat)
}
export function ValidatePassword(password) {
  const passwordFormat = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/
  return password && password.match(passwordFormat)
}
export async function UpdateDisabled(disable: string, userId: number) {
  return await ExecuteAPIGet<number>(EndPoints.User.UpdateDisabled, `disable=${disable}&userId=${userId}`)
}
export async function ManageUsersList(isOrganizer: number, pageNo: number, userName: string) {
  return await ExecuteAPIGet<AdminUsersViewModel[]>(
    EndPoints.User.ManageUsersList,
    `isOrganizer=${isOrganizer}&pageNo=${pageNo}&userName=${userName}`
  )
}

export async function GetOrganizers() {
  return await ExecuteAPIGet<OrganizationViewModel[]>(EndPoints.User.GetOrganizers)
}

export async function AdminManageUsers(userId: number) {
  return await ExecuteAPIGet<AdminMangerUserViewModel>(EndPoints.User.AdminManageUsers, `userId=${userId}`)
}

export async function UserRegistrationRecord(date: string) {
  return await ExecuteAPIGet<AdminAnalysisViewModel[]>(EndPoints.User.UserRegistrationRecord, `date=${date}`)
}

export async function UserCoutryWiseRegistration(date: string, isOrganizer: number) {
  return await ExecuteAPIGet<CountryWiseRegistration[]>(EndPoints.User.UserCoutryWiseRegistration, `date=${date}&isOrganizer=${isOrganizer}`)
}
export async function PlayerBattlesAndLoyaltyPoints() {
  return await ExecuteAPIGet<PlayerBattlesAndLoyaltyPointsViewModel>(EndPoints.User.PlayerBattlesAndLoyaltyPoints)
}

export async function UpdateExpPoints(userId: number, points: number) {
  return await ExecuteAPIGet<number>(EndPoints.User.UpdateExpPoints, `userId=${userId}&points=${points}`)
}
export async function UpgradeToPremiumForFree(userId:number,ispremium:boolean){
  return await ExecuteAPIGet<number>(EndPoints.User.UpgradeToPremiumForFree, `userId=${userId}&isPremium=${ispremium}`)
}

export async function FilterPlayers(filters:FilterPlayersDto){
  return await ExecuteAPIPost<ListPlayersResult>(EndPoints.User.FilterPlayers, filters)
}

export async function FilterClans(filters:FilterPlayersDto){
  return await ExecuteAPIPost<ListPlayersResultModel>(EndPoints.User.FilterClans, filters)
}

export const UserUtils = {
  GetAllOrganizers,
  GetAllByID,
  Save,
  ValidateUserExist,
  ValidateSecurityCode,
  ResetPassword,
  LogoutUser,
  ValidateEmail,
  ValidatePassword,
  UpdateDisabled,
  ManageUsersList,
  GetOrganizers,
  AdminManageUsers,
  UserRegistrationRecord,
  UserCoutryWiseRegistration,
  PlayerBattlesAndLoyaltyPoints,
  UpdateExpPoints,
  UpgradeToPremiumForFree,
  FilterPlayers,
  FilterClans
}
