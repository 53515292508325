import { Dispatch } from 'redux'
import { GetState, PaginationModel } from 'store/types/common'
import { playerLiveTournamentsLoaded, playerOnlineTournamentsLoaded } from 'store/types/pages/player-events-page-types'
import { OperationTypes, run } from '../operations-logic'
import { getFilteredTournaments, TournamentFilters } from '../tournaments-logic'


export const loadPlayerOnlineTournaments = (filters: TournamentFilters, pagination: PaginationModel) => async (dispatch: Dispatch, getState: GetState) => {
  await run(OperationTypes.loadPlayerOnlineTournaments, null, dispatch, getState, async () => {
    try {
      const result = await getFilteredTournaments(filters, pagination)
      dispatch(playerOnlineTournamentsLoaded({
        tournaments: result.Tournaments,
        allPlayerOnlineTournamentsCount: result.AllTournamentsCount,
      }))
    } catch (error) {
      return false
    }
    return true
  })
}


export const loadPlayerLiveTournaments = (filters: TournamentFilters, pagination: PaginationModel) => async (dispatch: Dispatch, getState: GetState) => {
  await run(OperationTypes.loadPlayerLiveTournaments, null, dispatch, getState, async () => {
    try {
      const result = await getFilteredTournaments(filters, pagination)
      dispatch(playerLiveTournamentsLoaded({
        tournaments: result.Tournaments,
        allPlayerLiveTournamentsCount: result.AllTournamentsCount,
      }))
    } catch (error) {
      return false
    }
    return true
  })
}
