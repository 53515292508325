import * as React from 'react'

import PlayerTransactionsTicketsCard from './PlayerTransactionsTicketsCard'
import PlayerTransactionsLoyaltyPointsCard from './PlayerTransactionsLoyaltyPointsCard'
import PlayerTransactionsBonus from './PlayerTransactionsBonus'
import { MyTransactionViewModel } from '../../../models/my-transaction-viewmodel'
import { TransactionUtils } from '../../../server/utils/transaction-utils'
import { GeneralUtils } from '../../../server/utils/general-utils'
import { UserUtils } from '../../../server/utils/user-utils'
import { PlayerBattlesAndLoyaltyPointsViewModel } from '../../../models/Player_OrganizerHeaderViewModel'
import { PlayerPurcahseTicketdetailViewModel } from '../../../models/player-purchase-ticket-detail-viewmodel'
import { StripeUtils } from '../../../server/utils/stripe-utils'
import { UserPayment } from '../../../models/user-payment-models'
import { brandImagesGrey, kl_Currency, PaymentMethodModel } from '../../../models/billing-option-model'
import { Payments } from '../../../models/payment-models'
import { LoyaltyPointsModel } from '../../../models/loyalty-points-models'
import { LoyaltyPointsUtils } from '../../../server/utils/loyalty-point-utils'
import { UserModel } from '../../../models/user-models'
import { CoinBaseViewModel } from '../../../models/coin-base-models'
import { CoinBaseUtils } from '../../../server/utils/coin-base-utils'
import './PlayerTransactions.scss'
import { Button } from 'components/_shared'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { LocationUtils } from 'server/utils/location-utils'
import { Kl_CurrencyUtils } from 'server/utils/kl_currency-utils'
import { round2Places } from 'utils'
import { connect } from 'react-redux'
import { loadPlayerBattlesAndLoyaltyPoints } from 'store/logic/player-battle-and-loyaltypoint-logic'

interface DispatchProps {
  loadPlayerBattlesAndLoyaltyPoints: (id) => void;
}

type Props = RouteComponentProps & DispatchProps

interface State {
  tickets: boolean
  loyaltyPoints: boolean
  bonus: boolean
  pageNo: number
  totalPages: number
  myTransactionObj: MyTransactionViewModel
  type: string
  playerRecord: PlayerBattlesAndLoyaltyPointsViewModel
  playerticket: PlayerPurcahseTicketdetailViewModel[]
  paymentMethod: string
  loyaltyPoint: number
  userPayment: UserPayment
  paymentMethodList: PaymentMethodModel[]
  index: number
  checkedPayment: boolean
  hostedUrl: string
  currencyModel: kl_Currency
  CountryCode : string
}

class PlayerTransactions extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      tickets: true,
      loyaltyPoints: false,
      bonus: false,
      pageNo: 1,
      totalPages: 1,
      myTransactionObj: Object(),
      type: 'Tickets',
      playerRecord: Object(),
      playerticket: [],
      paymentMethod: 'Method',
      loyaltyPoint: 0,
      userPayment: Object(),
      paymentMethodList: [],
      index: -1,
      checkedPayment: false,
      hostedUrl: '',
      currencyModel: Object(),
      CountryCode : ''
    }
    this.myTransaction = this.myTransaction.bind(this)
    this.getPlayerBattlesAndLoyaltyPoints = this.getPlayerBattlesAndLoyaltyPoints.bind(this)
    this._getPaymentMethod = this._getPaymentMethod.bind(this)
    this.paymentCheckBoxHandler = this.paymentCheckBoxHandler.bind(this)
    this.savePayment = this.savePayment.bind(this)
    this.coinBaseTransactions = this.coinBaseTransactions.bind(this)
    this.redirectHostedUrl = this.redirectHostedUrl.bind(this)
    this.paymentMethodHandler = this.paymentMethodHandler.bind(this)
    this.saveLoyaltyPoints = this.saveLoyaltyPoints.bind(this)
    this.GetCurrencyFromCountryName = this.GetCurrencyFromCountryName.bind(this)
  }

  async componentDidMount() {
    await this.getData()
  }

  async getData() {
    const data: any = await LocationUtils.getCoords()
    const location = await LocationUtils.GetLocationInfo('country', data.latitude, data.longitude)
    this.setState({
      CountryCode: location.CountryCode
    })
    const countryName = location.CountryName
    await this.GetCurrencyFromCountryName(countryName)
    this.myTransaction()
    this.getPlayerBattlesAndLoyaltyPoints()
    this._getPaymentMethod()
  }
  async GetCurrencyFromCountryName(countryName:string)
  {
    const result = await Kl_CurrencyUtils.GetCurrencyFromCountryName(countryName)
    if (!result.isError) {
      this.setState({
        currencyModel: result.data
      })
    } else {
      GeneralUtils.showError(result.message)
    }
  }
  async myTransaction() {
    let { tickets, loyaltyPoints, bonus, type } = this.state
    const userId = localStorage.getItem('userid')
    GeneralUtils.showHideLoadingDiv(true)
    if (userId) {
      if (tickets == true && loyaltyPoints == false && bonus == false) {
        type = 'Tickets'
      } else if (tickets == false && loyaltyPoints == true && bonus == false) {
        type = 'Loyalty Point'
      } else if (tickets == false && loyaltyPoints == false && bonus == true) {
        type = 'Bonus'
      }
      const result = await TransactionUtils.MyTransaction(this.state.pageNo, type)
      if (!result.isError) {
        this.setState({
          myTransactionObj: result.data,
          pageNo: result.data.pageNo ? result.data.pageNo : 1,
          totalPages: result.data.totalPages ? result.data.totalPages : 1,
          type,
        })
        GeneralUtils.showHideLoadingDiv(false)
      } else {
        GeneralUtils.showError(result.message)
        GeneralUtils.showHideLoadingDiv(false)
      }
    } else {
      GeneralUtils.showHideLoadingDiv(false)
    }
  }

  async getPlayerBattlesAndLoyaltyPoints() {
    const userId: string = localStorage.getItem('userid')
    if (userId) {
      const result = await UserUtils.PlayerBattlesAndLoyaltyPoints()
      if (!result.isError) {
        this.setState({ playerRecord: result.data })
        this.props.loadPlayerBattlesAndLoyaltyPoints(userId)
      } else {
        GeneralUtils.showError(result.message)
      }
    }
  }
  async _getPaymentMethod() {
      const res = await StripeUtils.getPaymentMethod()
      if (res.data.length > 0) {
        this.setState({
          paymentMethodList: res.data,
        })
      }
  }
  async savePayment() {
    const { index, paymentMethodList,currencyModel } = this.state
    if (this.state.loyaltyPoint > 0 && this.state.paymentMethod == 'Credit card') {
      const userId = localStorage.getItem('userid')
      const payment: Payments = Object()
      const userPayment: UserPayment = Object()
      payment.stripeToken=""
      payment.pmTblId = paymentMethodList && paymentMethodList[index].id
      payment.id = paymentMethodList && paymentMethodList[index].id
      payment.email = paymentMethodList && paymentMethodList[index].email
      payment.cvv = paymentMethodList && paymentMethodList[index].cvc
      payment.cardNo = paymentMethodList && paymentMethodList[index].cardNo
      payment.expireDate = paymentMethodList && paymentMethodList[index].expMonth + '/' + paymentMethodList[index].expYear
      userPayment.amount = this.calculatePlayerLoyaltyPointAmount()
      userPayment.date = new Date()
      payment.userPayment = userPayment
      payment.PlayerLocalCurrencyId = currencyModel.Id
      

      if (userId) {
        GeneralUtils.showHideLoadingDiv(true)
        const stripeUser = await StripeUtils.stripCharge(payment)
        if (!stripeUser.isError) {
          await this.saveLoyaltyPoints()
          document.getElementById('payment').className = 'modal-container'
          GeneralUtils.showHideLoadingDiv(false)
        } else {
          GeneralUtils.showHideLoadingDiv(true)
          GeneralUtils.showError('Error Occurred')
        }
      }
    }
  }
  async saveLoyaltyPoints() {
    const { loyaltyPoint } = this.state
    const loyaltyPointModel: LoyaltyPointsModel = Object()
    loyaltyPointModel.points = parseInt(loyaltyPoint.toString())
    loyaltyPointModel.note ="Credit Conversion to Loyalty Points"
    await LoyaltyPointsUtils.Save(loyaltyPointModel)
    this.setState({ tickets: false, loyaltyPoints: true, bonus: false,loyaltyPoint : 0,paymentMethod:'Method' }, () => {
      this.myTransaction()
      this.getPlayerBattlesAndLoyaltyPoints()
    })
  }

  paymentCheckBoxHandler(index: number, checked: boolean) {
    this.setState({ checkedPayment: checked, index })
  }

  async coinBaseTransactions() {
    const { loyaltyPoint } = this.state
    const _user: string = localStorage.getItem('user')
    if (_user && this.state.loyaltyPoint > 0 && this.state.paymentMethod == 'Cryptocurrency') {
      const user: UserModel = JSON.parse(_user)
      const coinBaseModel: CoinBaseViewModel = Object()
      coinBaseModel.Amount = this.state.loyaltyPoint / 70
      coinBaseModel.CustomerName = user.username
      coinBaseModel.Name = user.username
      coinBaseModel.email = user.emailaddress
      coinBaseModel.Description = 'Loyalty Points'
      coinBaseModel.Type = 'LoyaltyPoints'
      coinBaseModel.LoyaltyPoint = parseInt(loyaltyPoint.toString())
      const createCharge = await CoinBaseUtils.createCoinBaseCharge(coinBaseModel)
      if (!createCharge.isError) {
        if (createCharge.data) {
          this.setState(
            {
              hostedUrl: createCharge.data,
            },
            () => {
              this.redirectHostedUrl()
            }
          )
        }
      } else {
        alert('An Error Occurred')
      }
    }
  }
  redirectHostedUrl() {
    const { hostedUrl } = this.state
    if (hostedUrl) window.open(hostedUrl)
  }

  paymentMethodHandler() {
    const { paymentMethod,loyaltyPoint } = this.state
    if (paymentMethod == 'Credit card' && loyaltyPoint !== 0) {
      document.getElementById('payment').className = 'modal-container--opened'
      document.documentElement.scrollTop = 0
    } else if (paymentMethod == 'Cryptocurrency') {
      document.getElementById('crypto').className = 'modal-container--opened'
      document.documentElement.scrollTop = 0
    }
  }

  calculatePlayerLoyaltyPointAmount(){
    return round2Places((this.state.loyaltyPoint / 70) * this.state.currencyModel.ExchangeRate,2)
  }
  render() {
    const {} = this.props
    const { bonus, loyaltyPoints, tickets, pageNo, myTransactionObj, totalPages, playerRecord, paymentMethodList, currencyModel } = this.state
    const pages = []
    for (let i = 1; i <= totalPages; i++) {
      if (i == pageNo + 5) {
        pages.push(<div key={'P_' + i.toString()} className="article__page-dots">...</div>)
      } else if ((!(i < pageNo - 1) && (i < pageNo + 5 || i == totalPages)) || i == 1) {
        pages.push(
          <a
          key={'P_' + i.toString()}
            className={`article__page ${i == pageNo ? 'article__page--active' : ''}`}
            onClick={() => {
              this.setState({ pageNo: i }, () => {
                this.myTransaction()
              })
            }}
          >
            {' '}
            {i}
          </a>
        )
      }
    }
    return (
      <div className="player-transaction-css">
        <div className="row">
          <div className="col p-0">
            <h1 className="article__header">Transactions</h1>
          </div>
          <div className="col-auto p-0">
            <Button
              onClick={() => this.props.history.push('/billing-option-player')}
              children="Billing Options"
              className="white-button article__header-button"
              color="white"
            />
          </div>
        </div>

        <div className="row p-t-32 m-0--30 player-transaction-top">
          <div className="col-4 player-transaction-top-left">
            <div className="row m-0">
              <div className="article__info info">
                <div className="row">
                  <div className="col-auto">
                    <div className="info__icon info__icon--money" />
                  </div>
                  <div className="col">
                    <div className="row m-0">
                      <div className="info__header">{playerRecord && playerRecord.TotalBattles}</div>
                    </div>
                    <div className="row m-0">
                      <div className="info__text">Total tournaments</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row m-0">
              <div className="article__info info">
                <div className="row">
                  <div className="col-auto">
                    <div className="info__icon info__icon--points" />
                  </div>
                  <div className="col">
                    <div className="row m-0">
                      <div className="info__header">{playerRecord && playerRecord.LoyaltyPoints} LP</div>
                    </div>
                    <div className="row m-0">
                      <div className="info__text">Loyalty Points</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row m-0">
              <div className="article__info info">
                <div className="row">
                  <div className="col-auto">
                    <div className="info__icon info__icon--bonus" />
                  </div>
                  <div className="col">
                    <div className="row m-0">
                      <div className="info__header">{myTransactionObj && myTransactionObj.bonus} Bonus</div>
                    </div>
                    <div className="row m-0">
                      <div className="info__text">Bonus</div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <div className="col player-transaction-top-right">
            <div className="article__buy-points buy">
              <div className="row">
                <div className="buy__header">Buy Loyalty Points</div>
              </div>
              <div className="row">
                <div className="buy__subheader buy__subheader--big">Do you want to top up your Loyalty Points?</div>
              </div>
              <div className="row loyalty-point-wrapper">
                <div className="col-8 loyalty-point-wrapper-left">
                  <div className="row">
                    <div className="buy__subheader">Select amount</div>
                  </div>
                  <div className="row">
                    <div
                      className="buy__label-container"
                      onClick={() => {
                        this.setState({ loyaltyPoint: 700 })
                      }}
                    >
                      <input className="buy__input" type="radio" checked={this.state.loyaltyPoint === 700 ? true : false} name="buy-points" id="buy-points-0" onChange={(e)=> {}} />
                      <label className="buy__label" htmlFor="buy-points-0">
                        700 Loyalty
                      </label>
                    </div>
                    <div
                      className="buy__label-container"
                      onClick={() => {
                        this.setState({ loyaltyPoint: 3500 })
                      }}
                    >
                      <input className="buy__input" type="radio" checked={this.state.loyaltyPoint === 3500 ? true : false} name="buy-points" id="buy-points-1" onChange={(e)=> {}} />
                      <label className="buy__label" htmlFor="buy-points-1">
                        3500 Loyalty
                      </label>
                    </div>
                    <div
                      className="buy__label-container"
                      onClick={() => {
                        this.setState({ loyaltyPoint: 7000 })
                      }}
                    >
                      <input className="buy__input" type="radio" checked={this.state.loyaltyPoint === 7000 ? true : false} name="buy-points" id="buy-points-2" onChange={(e)=> {}} />
                      <label className="buy__label" htmlFor="buy-points-2">
                        7000 Loyalty
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-4 loyalty-point-wrapper-right">
                <div className="col-auto buy__select-container">
                  <div className="row">
                    <div className="buy__subheader">Preferred Payment Method</div>
                  </div>
                  <div className="row">
                    <div
                      onClick={e => {
                        e.currentTarget.className = `buy__select buy__select--method select ${
                          e.currentTarget.className.indexOf('select--opened') > -1 ? '' : 'select--opened'
                        } `
                      }}
                      className="buy__select buy__select--method select"
                      style={{ zIndex: 500 }}
                    >
                      <div className="buy__select--method__title select__title">{this.state.paymentMethod}</div>
                      <section className="buy__select--method__container select__container">
                        <div
                          className={`buy__select--method__option select__option ${
                            this.state.paymentMethod == 'Credit card' ? 'select__option--selected' : ''
                          }`}
                          onClick={e => {
                            this.setState({ paymentMethod: 'Credit card' })
                          }}
                        >
                          Credit card
                        </div>
                        <div
                          className={`buy__select--method__option select__option ${
                            this.state.paymentMethod == 'Cryptocurrency' ? 'select__option--selected' : ''
                          }`}
                          onClick={e => {
                            this.setState({ paymentMethod: 'Cryptocurrency' })
                          }}
                        >
                          Cryptocurrency
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
                </div>
              </div>
              <div className="row">
                <div className="buy__hr" />
              </div>
              <div className="row align-items-center">
                <div className="col-lg col-12">
                  <div className="row align-items-start flex-nowrap">
                    <div className="buy__warning-icon" />
                    <div className="buy__warning-text">Loyalty Point purchase is non refundable and can not be redeem for cash.</div>
                  </div>
                </div>
                <div className="col-lg-auto col-12 p-0 d-flex justify-content-end">
                  <Button
                    onClick={() => {
                      this.paymentMethodHandler()
                    }}
                    children="purchase"
                    className="blue-button blue-button--filled buy__button buttons_blue_button_filled__2zape"
                    color="blue"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 p-0">
            <div className="row article__tab-menu-before-container">
              <div className="col">
                <div className="row">
                  <div className="article__tab-menu-container">
                    <button className="article__tab-menu-button article__tab-menu-button--left" />
                    <nav className="article__tab-menu tab-menu row justify-content-start align-items-lg-start align-items-end flex-nowrap">
                      <a
                        className={`col-auto tab-menu__link ${tickets ? 'tab-menu__link--active' : ''}`}
                        onClick={() => {
                          this.setState({ tickets: true, loyaltyPoints: false, bonus: false,pageNo:1 }, () => {
                            this.myTransaction()
                          })
                        }}
                        href="#tickets"
                      >
                        Tickets
                      </a>
                      <a
                        className={`col-auto tab-menu__link ${loyaltyPoints ? 'tab-menu__link--active' : ''}`}
                        onClick={() => {
                          this.setState({ tickets: false, loyaltyPoints: true, bonus: false,pageNo:1 }, () => {
                            this.myTransaction()
                          })
                        }}
                        href="#loyaltyPoints"
                      >
                        Loyalty Points
                      </a>
                      {/* <a
                        className={`col-auto tab-menu__link ${bonus ? 'tab-menu__link--active' : ''}`}
                        onClick={() => {
                          this.setState({ tickets: false, loyaltyPoints: false, bonus: true }, () => {
                            this.myTransaction()
                          })
                        }}
                        href="#bonus"
                      >
                        Bonus
                      </a> */}
                    </nav>
                    <button className="article__tab-menu-button article__tab-menu-button--right" />
                  </div>
                </div>
              </div>
              <div className="col-lg-auto col-12 article__radio-container">
                <div className="row justify-content-lg-end justify-content-sm-start" />
              </div>
            </div>
          </div>
        </div>

        {tickets && (
          <div id="tickets">
            <div className="row table__headers-row">
              <div className="table__column table__column--date">Date</div>
              <div className="table__column table__column--currency">Cost</div>
              <div className="table__column table__column--tournament">Tournament</div>
              <div className="table__column table__column--type">Type</div>
              <div className="table__column table__column--venue">Venue</div>
              <div className="table__column table__column--status">Status</div>
            </div>
            {myTransactionObj &&
              myTransactionObj.loyalCreditsBonus &&
                        myTransactionObj.loyalCreditsBonus.map((tickets,i) => <PlayerTransactionsTicketsCard key={'T_' + i.toString()} isPurchased ={tickets.isPurchased} refunded={tickets.refunded}  tickets={tickets} />)}
          </div>
        )}
        {loyaltyPoints && (
          <div id="loyaltyPoints">
            <div className="row table__headers-row">
              <div className="table__column table__column--date">Date</div>
              <div className="table__column table__column--points">Points</div>
              <div className="table__column table__column--points-type">Type</div>
            </div>

            {myTransactionObj &&
              myTransactionObj.loyalCreditsBonus &&
              myTransactionObj.loyalCreditsBonus.map((loyaltyPoints,i) => <PlayerTransactionsLoyaltyPointsCard key={'LP_'+ i.toString()} loyaltyPoints={loyaltyPoints} />)}
          </div>
        )}
        {bonus && (
          <div id="bonus">
            <div className="row table__headers-row">
              <div className="table__column table__column--date">Date</div>
              <div className="table__column table__column--points">Points</div>
              <div className="table__column table__column--tournament">Tournament</div>
              <div className="table__column table__column--description">Description</div>
            </div>
            {myTransactionObj &&
              myTransactionObj.loyalCreditsBonus &&
              myTransactionObj.loyalCreditsBonus.map((bonus,i) => <PlayerTransactionsBonus key={'B'+ i.toString()} bonus={bonus} />)}
          </div>
        )}
        {totalPages > 1 && (
          <div className="row justify-content-center">
            <div className="col-auto article__pages-container">
              <div className="row align-items-center">
                {pageNo > 1 && (
                  <a
                    className="article__prev-page"
                    onClick={() => {
                      this.setState({ pageNo: pageNo - 1 }, () => {
                        this.myTransaction()
                      })
                    }}
                  >
                    PREV
                  </a>
                )}
                <div className="col-auto">
                  <div className="row align-items-center" id="pages">
                    {pages}
                  </div>
                </div>
                {pageNo < totalPages && (
                  <a
                    className="article__next-page"
                    onClick={() => {
                      this.setState({ pageNo: pageNo + 1 }, () => {
                        this.myTransaction()
                      })
                    }}
                  >
                    NEXT
                  </a>
                )}
              </div>
            </div>
          </div>
        )}

        <aside className="modal-container" id="payment">
          <div className="modal-container__background" />
          <div className="modal-container__window window payment-model" style={{ zIndex: 99999 }}>
            <div
              className="window__close"
              onClick={() => {
                document.getElementById('payment').className = 'modal-container'
              }}
            />
            <div className="container-fluid">
              <div className="row">
                <div className="window__header" />
              </div>
              <div className="row">
                <div className="window__subheader">
                  Do you want to purchase {this.state.loyaltyPoint} points for {currencyModel.CurrencySymbol}
                  {this.calculatePlayerLoyaltyPointAmount()} with your existing account?
                </div>
              </div>

              {paymentMethodList &&
                paymentMethodList.map((paymentMethod, index) => {
                  return (
                      <div key={paymentMethod.id} className="card pb-2">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-1 col-sm-1 col-xs-1">
                              <input
                                id={index.toString()}
                                onChange={(e: any) => {
                                  this.paymentCheckBoxHandler(index, e.target.checked)
                                }}
                                checked={index == this.state.index ? this.state.checkedPayment : false}
                                type="checkbox"
                                name="payment-selection"
                              />
                            </div>
                            <div className="col-md-2 col-sm-2 col-xs-2">
                              <img src={brandImagesGrey.find(b => b.brand == paymentMethod.brand).imgSrc} alt="" />
                            </div>
                            <div className="col-md-3 col-sm-3 col-xs-3">Card</div>
                            <div className="col-md-3 col-sm-3 col-xs-3">**** **** {paymentMethod.cardNo}</div>
                            <div className="col-md-3 col-sm-3 col-xs-3">
                              {paymentMethod.expMonth} / {paymentMethod.expYear}
                            </div>
                          </div>
                        </div>
                      </div>
                  )
                })}
              <div className="row justify-content-center">
                <Button
                  onClick={() => {
                    document.getElementById('payment').className = 'modal-container'
                  }}
                  children="cancel"
                  className="window__button red-button window__cancel"
                  color="red"
                />

                {paymentMethodList && paymentMethodList.length > 0 && (
                  <Button
                    onClick={() => {
                      this.savePayment()
                    }}
                    children="confirm"
                    className="window__button green_button green_button_filled"
                    color="green"
                  />
                )}
                <Button
                  onClick={() => {
                    window.location.href = 'billing-option-player'
                  }}
                  children="Add Payment"
                  className="window__button blue-button buttons_blue_button_filled__2zape"
                  color="blue"
                />
              </div>
            </div>
          </div>
        </aside>

        <aside className="modal-container " id="crypto">
          <div className="modal-container__background" />
          <div className="modal-container__window window payment-model" style={{ zIndex: 99999 }}>
            <div
              className="window__close"
              onClick={() => {
                document.getElementById('crypto').className = 'modal-container'
              }}
            />
            <div className="container-fluid">
              <div className="row">
                <div className="window__header" />
              </div>
              <div className="row">
                <div className="window__subheader">
                  Do you want to purchase {this.state.loyaltyPoint} points for {currencyModel.CurrencySymbol}
                  {(this.state.loyaltyPoint / 70) * currencyModel.ExchangeRate}?
                </div>
              </div>
              <div className="row justify-content-center">
                <Button
                  onClick={() => {
                    document.getElementById('crypto').className = 'modal-container'
                  }}
                  children="cancel"
                  className="window__button red-button window__cancel"
                  color="red"
                />

                <Button
                  onClick={() => {
                    this.coinBaseTransactions()
                  }}
                  children="confirm"
                  className="window__button green-button green-button--filled"
                  color="green"
                />
              </div>
            </div>
          </div>
        </aside>
      </div>
    )
  }
}

const mapDispatchToProps: DispatchProps = {
  loadPlayerBattlesAndLoyaltyPoints,
};

export default withRouter(connect(null,mapDispatchToProps)(PlayerTransactions))

