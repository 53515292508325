import { useKlHistory } from 'components/_hooks/useKlHistory'
import * as React from 'react'
import AuthBackground from '../_shared/AuthBackground'
import styles from '../Login.module.scss'
import { cn } from 'utils'
import PasswordRecoveryRequest from './PasswordRecoveryRequest'
import PasswordRecoveryConfirm from './PasswordRecoveryConfirm'
import PasswordRecoverySubmit from './PasswordRecoverySubmit'


enum PasswordRecoveryMode {
  request,
  confirm,
  submit
}

const PasswordRecovery = () => {
  const history = useKlHistory()
  const handleBack = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault()
    history.goBack()
  }

  const [mode, setMode] = React.useState(PasswordRecoveryMode.request)
  const [email, setEmail] = React.useState('')
  const [verificationCode, setVerificationCode] = React.useState('')

  const onRequestSent = (email: string) => {
    setMode(PasswordRecoveryMode.confirm)
    setEmail(email)
  }


  const onCodeConfirmed = (code: string) => {
    setVerificationCode(code)    
    setMode(PasswordRecoveryMode.submit)
  }

  return (
    <>
      <AuthBackground />
      <div className="container-fluid pass-recovery_back">
        <div className="row justify-content-end back-container">
          <a
            href="#"
            onClick={handleBack}
            className={styles.back}
          >
            Back
          </a>
        </div>
      </div>
      <article className={cn(styles.passRecoveryContainer, styles.signIn, 'd-flex flex-row align-items-center')}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className={styles.signIn__header}>Password recovery</h1>
            </div>
          </div>
          { mode == PasswordRecoveryMode.request && (
            <PasswordRecoveryRequest onRequestSent={onRequestSent} />
          )}
          {mode == PasswordRecoveryMode.confirm && (
            <PasswordRecoveryConfirm onCodeConfirmed={onCodeConfirmed} email={email} />
          )}
          {mode == PasswordRecoveryMode.submit && (
            <PasswordRecoverySubmit verificationCode={verificationCode} email={email} />
          )}
        </div>
      </article>
    </>
  )
  
}

export default PasswordRecovery
