import { Reducer } from 'redux'
import { AdminTournamentsPageState, ADMIN_ACTIVE_TOURNAMENTS_LOADED, ADMIN_CANCELED_TOURNAMENTS_LOADED, ADMIN_FINISHED_TOURNAMENTS_LOADED, ADMIN_TOURNAMENTS_FILTERS_CHANGED, ADMIN_UPCOMING_TOURNAMENTS_LOADED, defaultAdminTournamentFilters, KnownAction } from 'store/types/pages/admin-tournaments-page-types'


const initialState: AdminTournamentsPageState = {
  filters: defaultAdminTournamentFilters,  
  allUpcomingTournamentsCount: 0,
  allActiveTournamentsCount: 0,
  allFinishedTournamentsCount: 0,
  allCanceledTournamentsCount:0,
  upcomingTournamentsIds: [],
  activeTournamentsIds: [],
  finishedTournamentsIds: [],
  canceledTournamentsIds: [],
}


const reducer: Reducer<AdminTournamentsPageState, KnownAction> = (state: AdminTournamentsPageState | undefined = initialState, action: KnownAction) => {
  switch (action.type) {
    case ADMIN_TOURNAMENTS_FILTERS_CHANGED:
      return {
        ...state,
        filters: action.payload.filters,
      }
    case ADMIN_UPCOMING_TOURNAMENTS_LOADED: {
      return {
        ...state,
        upcomingTournamentsIds: action.payload.tournaments.map(x => x.TournamentDetailId),
        allUpcomingTournamentsCount: action.payload.allAdminUpcomingTournamentsCount,
      }
    }
    case ADMIN_ACTIVE_TOURNAMENTS_LOADED: {
      return {
        ...state,
        activeTournamentsIds: action.payload.tournaments.map(x => x.TournamentDetailId),
        allActiveTournamentsCount: action.payload.allAdminActiveTournamentsCount,
      }
    }
    case ADMIN_FINISHED_TOURNAMENTS_LOADED: {
      return {
        ...state,
        finishedTournamentsIds: action.payload.tournaments.map(x => x.TournamentDetailId),
        allFinishedTournamentsCount: action.payload.allAdminFinishedTournamentsCount,
      }
    }
    case ADMIN_CANCELED_TOURNAMENTS_LOADED: {
      return {
        ...state,
        canceledTournamentsIds: action.payload.tournaments.map(x => x.TournamentDetailId),
        allCanceledTournamentsCount: action.payload.allAdminCanceledTournamentsCount,
      }
    }
    default:
      return state
  }
}

export default reducer
