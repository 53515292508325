import { useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import { EndPoints } from 'server/endpoints'
import { fetchApi } from 'server/server-utils'
import { getOrganizersRequested, makeGetIsTopFriendsRequested, makeGetIsPlayerProfileByUserIdRequested, makeGetIsUserRequested } from 'store/selectors/user-selectors'
import { GetState } from 'store/types/common'
import { FriendsFilters } from 'store/types/pages/friends-page-types'
import { playerProfileLoaded, PlayerProfileType } from 'store/types/player-profile-type'
import { coverImageUpdated, friendDeleted, ListUserResultModel, organizersLoaded, PublicUserType, topFriendsLoaded, totalOnlineUsersCountLoaded, userAvatarUpdated, usersLoaded, UsersModel, userUpdated } from 'store/types/user-types'
import { cancel, OperationTypes, run, useCancel, useRun } from './operations-logic'

export interface UpdateUserModel {
  Username: string
  Organizationname: string
  About: string
  CountryId: number
  RegionId: number
  Ps4Id: string
  XboxId: string
  SteamId: string
  BattlenetUsername: string
  RiotUsername: string
  Epicgameid: string
  NintendoId: string
  ValveUsername: string
  IOsId: string
  AndroidId: string
  Consoles: number[]
  Games: number[]
}

export const loadOrganizers = async (dispatch: Dispatch, getState: GetState) => {
  if (getOrganizersRequested(getState())) {
    return
  }
  await run(OperationTypes.loadOrganizers, null, dispatch, getState, async () => {
    try {
      const organizers = await fetchApi<PublicUserType[]>('user/organizers', 'GET')
      dispatch(organizersLoaded({ organizers }))
    } catch (error) {
      return false
    }
    return true
  })
}

export const useUpdateAvatar = (userId: number | undefined) => {
  const dispatch = useDispatch()
  return useRun(OperationTypes.updateAvatar, userId, async (imageId: number) => {
    const user = await fetchApi<UsersModel>(`user/avatar/${imageId}`, 'PUT')
    dispatch(userAvatarUpdated(user))
    return true
  })
}

export const useUploadCoverImage = (userId: number | undefined) => {
  const dispatch = useDispatch()
  return useRun(OperationTypes.updateCoverImage, userId, async (imageId: number) => {
    const user = await fetchApi<UsersModel>(`user/cover/${imageId}`, 'PUT')
    dispatch(coverImageUpdated(user))
    return true
  })
}

const getUserRequested = makeGetIsUserRequested()

export const loadUser = (userId: number) => async (dispatch: Dispatch, getState: GetState) => {
  if (getUserRequested(getState(), { userId })) {
    return
  }
  await run(OperationTypes.loadUser, userId, dispatch, getState, async () => {
    try {
      const model = await fetchApi<UsersModel>(`user/${userId}`, 'GET')
      dispatch(usersLoaded(model))
    } catch (error) {
      return false
    }
    return true
  })
}

export const reLoadUser = (userId: number) => async (dispatch: Dispatch, getState: GetState) => {
  await run(OperationTypes.reLoadUser, userId, dispatch, getState, async () => {
    try {
      const model = await fetchApi<UsersModel>(`user/${userId}`, 'GET')
      dispatch(usersLoaded(model))
    } catch (error) {
      return false
    }
    return true
  })
}

const getTopFriendsRequested = makeGetIsTopFriendsRequested()

export const loadTopFriends = (userId: number) => async (dispatch: Dispatch, getState: GetState) => {
  if (getTopFriendsRequested(getState(), { userId })) {
    return
  }
  await run(OperationTypes.loadTopUserFriends, userId, dispatch, getState, async () => {
    try {
      const filters: FriendsFilters = {
        userId: userId,
        name: '',
      }

      const model = await fetchApi<ListUserResultModel>('user/friends', 'POST', {
        filters: filters,
      })
      
      dispatch(topFriendsLoaded({
        ...model.UsersModel,
        userId,
        allFriendsCount: model.AllUsersCount,      
      }))
    } catch (error) {
      return false
    }
    return true
  })
}

export const reLoadTopFriends = (userId: number) => async (dispatch: Dispatch, getState: GetState) => {
  cancel(OperationTypes.loadTopUserFriends, userId, dispatch, getState)
  await loadTopFriends(userId)(dispatch, getState)
}

const fetchPlayerProfileByUserId = (userId: number) => {
  return fetchApi<PlayerProfileType>(`playerprofile/${userId}`, 'GET')
}

const getPlayerProfileByUserIdRequested = makeGetIsPlayerProfileByUserIdRequested()

export const loadPlayerProfileByUserId = (userId: number) => async (dispatch: Dispatch, getState: GetState) => {
  if (getPlayerProfileByUserIdRequested(getState(), {userId})) {
    return
  }
  await run(OperationTypes.loadPlayerProfileByUserId, userId, dispatch, getState, async () => {
    try {
      const playerProfile = await fetchPlayerProfileByUserId(userId)

      dispatch(playerProfileLoaded({playerProfile}))
    } catch (error){
      return false
    }
    return true
  })
}

export const useReloadPlayerProfileByUserId = (userId: number) => {
  const cancelLoadPlayerProfileByUserId = useCancel(OperationTypes.loadPlayerProfileByUserId, userId)
  const dispatch = useDispatch()
  return useRun(OperationTypes.loadPlayerProfileByUserId, userId, async () => {
    cancelLoadPlayerProfileByUserId()
    const playerProfile = await fetchPlayerProfileByUserId(userId)
    dispatch(playerProfileLoaded({ playerProfile }))
    return true
  })
}

export const useRemoveFriend = () => {
  const dispatch = useDispatch()
  return useRun(OperationTypes.removeFriend, null, async (userId: number, friendId: number) => {
    try {
      await fetchApi(`user/unfriend/${friendId}`, 'POST')
      dispatch(friendDeleted({ userId, friendId }))
    } catch (error) {
      return false
    }
    return true
  })
}

export const useUpdateUser = (userId: number) => {
  const dispatch = useDispatch()
  return useRun(OperationTypes.loadPlayerProfileByUserId, userId, async (user: UpdateUserModel) => {
    const newUser = await fetchApi<PublicUserType>('user/update', 'POST', user)
    dispatch(userUpdated({ userId, user: newUser }))
    return true
  })
}


export const requestResetPassword = async (email: string) => {
  const result = await fetchApi('auth/password/request/reset', 'POST', { email: email})
  return result
}


export const checkResetPasswordVerificationCode = async (email: string, code: string) => {
  const result = await fetchApi<boolean>('auth/password/check/code', 'POST', { email: email, code: code})
  return result
}

export const changePassword = async (email: string, code: string, password: string) => {
  const result = await fetchApi('auth/password/change', 'POST', { email: email, code: code, password: password})
  return result
}

export const getTotalOnlineUsersCount = () => async (dispatch: Dispatch, getState: GetState) => {
  await run(OperationTypes.loadTotalOnlineUserCount, null, dispatch, getState, async () => {
    try {
      const model = await fetchApi<number>(EndPoints.User.TotalOnlineUsersCount, 'GET')
      dispatch(totalOnlineUsersCountLoaded(model))
    } catch {
      return false
    }
    return true
  })
}