import { ContainerName, useGetImageSize } from 'components/_hooks'
import { Img } from 'components/_shared'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { makeGetBlog } from 'store/selectors/blog-selectors'
import { ApplicationState } from 'store/types/common'
import { defaultFormatDate } from 'utils'

import styles from './NewsList.module.scss'


export interface NewsRowProps {
  blogId: number
  isRead: boolean
  containerName?: ContainerName
}

export const NewsRow = ({ blogId, isRead, containerName }: NewsRowProps) => {
  const getBlog = useMemo(makeGetBlog, [])

  const blog = useSelector((state: ApplicationState) => getBlog(state, { blogId }))
  const getImageSize = useGetImageSize(containerName)
  const title = (blog?.Title.toLowerCase().replace(/\s-/g, "").replace(/,/g, "").trim().replace(/\s/g, "-")) || ''
  return (
    <>
      <div className="col-md-6 col-lg-4">
        {!isRead && (
          <>
            <div>
              <div className={styles.isNewLabelBackground} />
              <div className={styles.isNewLabel} style={{}}>
                New
              </div>
            </div>
          </>
        )}
        <div className={styles.item}>
          <Link to={`/news/${blogId}/${title}`}>
            <span className={styles.image}>
              <Img parentContainerSize={getImageSize} alt={blog?.Title} imageId={blog?.PreviewImageId} />
            </span>
            <span className={styles.head}>{blog?.Title}</span>
            <span className={styles.published}>Published: <b>{defaultFormatDate(blog?.Date)}</b></span>
            <span className={styles.preview} dangerouslySetInnerHTML={{ __html: blog?.ShortDescription }}></span>
          </Link>
          <Link to={`/news/${blogId}/${title}`} className={styles.readMore}>Read More</Link>
        </div>
      </div>
    </>
  )
}
