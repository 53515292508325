import { cn } from 'utils'
import { useDropDownTableStyles } from './styles/useDropDownTableStyles'

export interface DropDownTableOpenButtonProps {
  onClick: () => void
  opened: boolean
  className?: string
}

export const DropDownTableOpenButton = ({ onClick, opened, className }: DropDownTableOpenButtonProps) => {
  const styles = useDropDownTableStyles()

  return <label className={cn(styles.table__open, className, opened && styles.opened)} onClick={onClick} />
}
