import { Action, ActionCreatorsMapObject, AnyAction } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { State as TournamentsState } from '../types/tournaments-types'
import { State as OperationsState } from './../types/operations-types'
import { State as MatchsState } from './../types/match-types'
import { State as MatchParticipantState } from './../types/match-participant-types'
import { State as MatchSubmissionsState } from './match-submissions-types'
import { State as ParticipantState } from './../types/participants-types'
import { State as AppSettingsState } from './../types/app-types'
import { State as NotificationsState } from './../types/notification-types'
import { State as RoundState } from './../types/rounds-types'
import { State as DisputeState } from './../types/dispute-types'
import { State as UserState } from './../types/user-types'
import { State as ClanState } from './../types/clan-types'
import { State as DisputeDetailsState } from './../types/dispute-details-types'
import { GameState } from './../types/game-types'
import { RegionState } from './../types/region-types'
import { State as MatchLinkState } from './../types/match-link-types'
import { State as LineUpState } from './../types/line-up-types'
import { ImageState } from './../types/image-types'
import { State as MatchSettingsState } from './../types/match-settings-types'
import { State as DisputesPageState } from './../types/pages/disputes-page-types'
import { State as TournamentBracketPageState } from './../types/pages/tournament-bracket-types'
import { CountryState } from './country-types'
import { ConsoleState } from './console-types'
import { FriendsRequestState } from './friend-requests-types'
import { PlayerProfileState } from './player-profile-type'
import { BlogState } from './blog-types'
import { TournamentsPageState } from './pages/tournaments-page-types'
import { OrganizerEventsPageState } from './pages/organizer-events-page-types'
import { ClanInvitationState } from './clan-invitations-types'
import { ClansMemberState } from './clan-member-types'
import { ClanPageState } from './pages/clan-page-types'
import { MembersPageState } from './pages/members-page-types'
import { FriendsPageState } from './pages/friends-page-types'
import { PlayerEventsPageState } from './pages/player-events-page-types'
import { ReviewState } from './review-types'
import { AdminTournamentsPageState } from './pages/admin-tournaments-page-types'
import { ChatState } from 'store/chat/chat/chat-types'
import { ChatMemberState } from  'store/chat/chat-member/chat-member-types'
import { ChatMessagesState } from 'store/chat/chat-message/chat-message-types'
import { PlayerBattlesAndLoyaltyPointState } from './player-battle-and-loyaltypoint-type'
import { FeeState } from 'store/fee/fee-types'
import { AdminUserState } from 'store/admin-user/admin-user-types'
import { PointsRewardState } from './points-reward-types'
import { OrganizerTournamentAndMembersAndIncomeState } from './organizer-tournament-members-income-type'

export interface ApplicationState {
  tournaments: TournamentsState | undefined
  operations: OperationsState | undefined
  matches: MatchsState | undefined
  matchParticipants: MatchParticipantState | undefined
  matchSubmissions: MatchSubmissionsState | undefined
  participants: ParticipantState | undefined
  rounds: RoundState | undefined
  app: AppSettingsState | undefined
  notifications: NotificationsState | undefined
  disputes: DisputeState | undefined
  users: UserState | undefined
  clans: ClanState | undefined
  disputeDetails: DisputeDetailsState | undefined
  games: GameState | undefined
  regions: RegionState | undefined
  matchLinks: MatchLinkState | undefined
  lineUps: LineUpState | undefined
  images: ImageState | undefined
  matchSettings: MatchSettingsState | undefined
  countries: CountryState | undefined
  consoles: ConsoleState | undefined
  friendRequests: FriendsRequestState | undefined
  playerProfiles: PlayerProfileState | undefined
  blogs: BlogState | undefined
  clanInvitations: ClanInvitationState | undefined
  clansMembers: ClansMemberState | undefined
  reviews: ReviewState | undefined
  chat: ChatState | undefined
  chatMembers: ChatMemberState | undefined
  chatMessages: ChatMessagesState | undefined
  fees: FeeState | undefined
  adminUsers: AdminUserState | undefined
  pointsRewards: PointsRewardState | undefined
  
  disputesPage: DisputesPageState | undefined
  tournamentBracketPage: TournamentBracketPageState | undefined
  tournamentsPage: TournamentsPageState | undefined
  organizerEventsPage: OrganizerEventsPageState | undefined
  playerEventsPage: PlayerEventsPageState | undefined
  clanPage: ClanPageState | undefined
  membersPage: MembersPageState | undefined
  friendsPage: FriendsPageState | undefined
  adminTournamentsPage: AdminTournamentsPageState | undefined
  playerBattlesAndLoyaltyPoints: PlayerBattlesAndLoyaltyPointState | undefined
  organizerTournamentAndMembersAndIncome: OrganizerTournamentAndMembersAndIncomeState | undefined
}

export type GetState = () => ApplicationState

// --------------------------- For Thunk -------------------------------------------

declare module 'redux' {
  function bindActionCreators<M extends ActionCreatorsMapObject<any>>(
    actionCreators: M,
    dispatch: Dispatch
  ): {
    [N in keyof M]: ReturnType<M[N]> extends ThunkAction<any, any, any, any> ? (...args: Parameters<M[N]>) => ReturnType<ReturnType<M[N]>> : M[N]
  }

  export interface Dispatch<A extends Action = AnyAction> {
    <T extends A>(action: T): T
    <R>(asyncAction: ThunkAction<R, ApplicationState, void, A>): R
  }
}

export type BoundAction<T extends (...args: any[]) => ThunkAction<any, any, any, any>> = (...args: Parameters<T>) => ReturnType<ReturnType<T>>

export type BoundActions<A extends ActionCreatorsMapObject<any>> = {
  [P in keyof A]: BoundAction<A[P]>
}

export type AppThunkAction<A extends Action = AnyAction, R = Promise<void> | void> = ThunkAction<R, ApplicationState, void, A>

export interface ReduxNoPayloadAction<TActionType> {
  type: TActionType
}
export interface ReduxAction<TActionType, TActionPayload> {
  type: TActionType
  payload: TActionPayload
}

interface ActionOutput<TActionPayload> {
  type: string
  payload: TActionPayload
}
type MakeActionCreatorOutput<TActionPayload> = (payload?: TActionPayload) => ActionOutput<TActionPayload>

interface ActionNoPayloadOutput {
  type: string
}
type MakeActionNoPaylodCreatorOutput = () => ActionNoPayloadOutput

export function makeNoPayloadActionCreator(type: string): MakeActionNoPaylodCreatorOutput {
  return () => ({
    type,
  })
}
export function makeActionCreator<TActionPayload>(type: string):MakeActionCreatorOutput<TActionPayload> {
  return (payload?: TActionPayload) => ({
    type,
    payload,
  })
}

export interface PaginationModel {
  page: number
  pageSize: number
}

export const defaultPageSize = 10

export type PlayerType = 'user' | 'clan'

export type Theme = 'dark' | 'light'

export enum Permission {
  none,
  read,
  write,
}

export type YesNoType = 'yes' | 'no'

export type PageMode = 'create' | 'update' | 'read'

export type InputMode = 'readonly' | 'write'

interface MatchesStats {
  TotalMatches: number
  WinnedMatches: number
}

export const calcWinRate = (stats?: MatchesStats) => {
  if (!stats) {
    return 0
  }
  if (stats.TotalMatches === 0 || stats.WinnedMatches === 0) {
    return 0
  }

  return Math.round((stats.WinnedMatches / stats.TotalMatches) * 100)
}
