import { ContainerName, useGetImageSize, useTournamentParticipant } from 'components/_hooks'
import React from 'react'
import { ImagePlaceholder, Img } from '../Img'
import { KlLink } from '../KlLink/KlLink'

interface OwnProps {
  participantId: number
  className?: string
  placeholder?: ImagePlaceholder
  withLink?: boolean
  containerName?:ContainerName
}

export const ParticipantAvatar = ({ participantId, className, placeholder = 'dark', withLink,containerName }: OwnProps) => {
  const { playerId, imageId, profileUrl } = useTournamentParticipant(participantId)
  const getImageSize = useGetImageSize(containerName)
  return playerId && withLink ? (
    <KlLink to={profileUrl}>
      <Img parentContainerSize={getImageSize}  className={className} imageId={imageId} placeholder={placeholder} />
    </KlLink>
  ) : (
    <Img parentContainerSize={getImageSize}  className={className} imageId={imageId} placeholder={placeholder} />
  )
}
