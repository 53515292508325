import React from 'react'
import styles from '../modals.module.scss'

interface ModalBackgroundProps {
  onClose?: CallableFunction
  disabled?: boolean
}

export const ModalBackground = ({ onClose, disabled }: ModalBackgroundProps) => {
  const handleClick = () => {
    if (!disabled && onClose) {
      onClose()
    }
  }

  return <div className={styles.modal_container__background} onClick={handleClick} />
}
