import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { ArticleHeader, LoadingBox } from 'components/_shared'
import { useAppUser, useIntRouteParam, useUser } from 'components/_hooks'
import { loadUser } from 'store/logic/user-logic'
import { OrganizerReview } from './OrganizerReview'
import { PlayerReview } from './PlayerReview'

interface UserReviewRouteParams {
  userId: string
}

const UserReview = () => {
  const dispatch = useDispatch()
  const loggedInuser = useAppUser()
  const userId: number = useIntRouteParam<UserReviewRouteParams>(x => x.userId, loggedInuser?.Id)
  const { user, isUserLoading } = useUser(userId)
  
  useEffect(() => {
    if (userId) {
      dispatch(loadUser(userId))
    }
  }, [dispatch, userId])

  const name = user?.Role == 'Organizer' 
    ? user?.Organizationname 
    : user?.Username

  return (
    <>
      <LoadingBox loading={isUserLoading} size="big">
        <ArticleHeader text={`${name} Reviews`} />
        {user?.Role == 'Organizer' && <OrganizerReview user={user} loggedInuser={loggedInuser}/>}
        {user?.Role == 'Player' && <PlayerReview user={user} loggedInuser={loggedInuser}/>}
      </LoadingBox>
    </>
  )
}

export default UserReview
