import { Dispatch } from 'redux'
import { fetchApi } from 'server/server-utils'
import { makeGetIsCountriesRequested } from 'store/selectors/country-selectors'
import { GetState } from 'store/types/common'
import { countriesLoaded, CountryType } from 'store/types/country-types'
import { OperationTypes, run } from './operations-logic'

const getIsCountriesRequested = makeGetIsCountriesRequested()

export const loadCountries = (regionId: number) => async (dispatch: Dispatch, getState: GetState) => {
  if (getIsCountriesRequested(getState(), { regionId })) {
    return
  }
  await run(OperationTypes.loadCountries, regionId, dispatch, getState, async () => {
    try {
      const countries = await fetchApi<CountryType[]>(`country/${regionId}`, 'GET')
      dispatch(countriesLoaded({ countries }))
    } catch (error) {
      return false
    }
    return true
  })
}
