import { usePortal } from 'components/_hooks'
import React from 'react'
import { ReactNode } from 'react'
import { cn } from 'utils'
import styles from './Popup.module.scss'

interface PopupPortalProps {
  children: ReactNode
}

const PopupPortal = ({ children }: PopupPortalProps) => {
  return usePortal(children, 'popup')
}

export interface PopupProps {
  children: ReactNode
  onClose?: CallableFunction
  windowClassName?: string
}

export const Popup = ({
  children,
  onClose,
  windowClassName,
}: PopupProps) => {
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault()
    onClose()
  }

  return (
    <PopupPortal>
      <div className={cn(styles.window, windowClassName)}>
        {onClose && <a href="#" onClick={handleClick} className={cn(styles.window__close)} />}
        {children}
      </div>
    </PopupPortal>
  )
}
