import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { makeGetClanLeaderId } from 'store/selectors/clan-member-selectors'
import { ApplicationState } from 'store/types/common'
import { useUser } from './useModels'

export const useClanLeader = (clanId: number) => {
  const getClanLeaderId = useMemo(makeGetClanLeaderId, [])
  const clanLeaderId = useSelector((state: ApplicationState) => getClanLeaderId(state, { clanId }))
  return useUser(clanLeaderId)
}
