import { Reducer } from 'redux'
import { APP_USER_LOADED } from 'store/types/app-types'
import { CLAN_INVITATIONS_LOADED } from 'store/types/clan-invitations-types'
import { CLANS_MEMBERS_LOADED } from 'store/types/clan-member-types'
import { MATCH_PARTICIPANTS_LOADED } from 'store/types/match-participant-types'
import { MEMBERS_PAGE_LOADED } from 'store/types/pages/clan-page-types'
import { MEMBERS_PAGE_CLANS_LOADED, MEMBERS_PAGE_PLAYERS_LOADED } from 'store/types/pages/members-page-types'
import { PARTICIPANTS_LOADED } from 'store/types/participants-types'
import { calcById } from 'utils'
import { CLANS_LOADED, CLAN_DELETED, KnownAction, State } from '../types/clan-types'

const initialClanState: State = {
  byId: {},
}

const reducer: Reducer<State, KnownAction> = (state: State | undefined = initialClanState, action: KnownAction) => {
  switch (action.type) {
    case APP_USER_LOADED:
    case MATCH_PARTICIPANTS_LOADED:
    case CLANS_MEMBERS_LOADED:
    case CLAN_INVITATIONS_LOADED:
    case CLANS_LOADED:
    case PARTICIPANTS_LOADED: {
      return {
        ...state,
        byId: calcById(state, action.payload.Clans),
      }
    }
    case MEMBERS_PAGE_CLANS_LOADED:
    case MEMBERS_PAGE_PLAYERS_LOADED:
    case MEMBERS_PAGE_LOADED: {
      return {
        ...state,
        byId: calcById(state, action.payload.Model.Clans),
      }
    }
    case CLAN_DELETED: {
      return {
        ...state,
        byId: {
          [action.payload.clanId]: undefined,
        },
      }
    }
    default:
      return state
  }
}

export default reducer
