import React, { ReactNode } from 'react'
import { cn } from 'utils'
import styles from '../modals.module.scss'

export interface ModalFileLabelProps {
  children: ReactNode
  name?: string
  onClick: () => void
}

export const ModalFileLabel = ({ children, name, onClick }:  ModalFileLabelProps) => {
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault()
    onClick()
  }

  return (
    <div className={styles.window__file_label}>
      {children}
      {name && <div className={styles.window__file_name}>{name}</div>}
      <a href="#" className={cn(styles.window__cancel_file, styles.window__cancel_screenshot)} onClick={handleClick} /> 
    </div>
  )
}
