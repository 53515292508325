import React from 'react'
import { UserModel } from 'models/user-models'
import SelectComponent from '../SelectComponent'
import { ConsoleList, EventTypeEnum, kl_Tournament, TournamentSeatModel, TournamentTicketSectionDetail } from 'models/tournament-models'
import { CountryUtils } from 'server/utils/country-utils'
import { kl_RegionModel } from 'models/kl_region-model'
import { kl_CountryModel, kl_CityModel, kl_StateModel, kl_CityAddModel } from 'models/kl_country-model'
import { RegionUtils } from 'server/utils/region-utils'
import { GameModel } from 'models/game-models'
import { GamesUtils } from 'server/utils/games-utils'
import { Redirect } from 'react-router-dom'
import { ModalUtils } from 'server/utils/modal-utils'
import { GeneralUtils } from 'server/utils/general-utils'
import 'cropperjs/dist/cropper.css'
import { TournamentUtils } from 'server/utils/tournament-utils'
import {
  kl_TournamentViewModel,
  kl_TournamentRoundsViewModel,
  kl_TournamentSettingsViewModel,
  kl_TournamentTicketsViewModel,
  KlTournamentDetailViewModel,
  klTournamentRoundsViewModel,
  klTournamentTicketsViewModel,
  KlTournamentModel,
  klTournamentDataViewModel,
  RepeatTypeEnum,
} from 'models/kl_tournament_viewmodel'
import Geocode from 'react-geocode'
import { DateTimePicker } from './_shared/DateTimePicker'
import TimeZone from './_shared/TimeZoneSelector/TimeZoneSelector'
import { Kl_OrganizerPayout } from 'models/billing-option-model'
import { BillingOptionUtils } from 'server/utils/billing-option-utils'
import BillingOptionDailog from '../../BillingOptionPlayer/BillingOptionDailog'
import './TournamentWizard.scss'
import { isTwoPlayersStructure, TournamentModeEnum, TournamentStructureEnum, TournamentStructureEnumByName, TournamentStatusEnum, VenueTypeEnum } from 'consts'
import { LocationUtils } from 'server/utils/location-utils'
import { uploadTournamentCoverImage, uploadTournamentTicketImage } from 'store/logic/tournaments-logic'
import { ImageType, MediaType } from 'store/types/image-types'
import { fullFormatDate, getUser } from 'utils'
import { TournamentViewModel_kl } from 'models/tournament-viewmodel'
import { ConvertDateToFormattedDatetimeString } from './_shared/TimeZoneSelector/ZonedDate'
import { RepeatSettings, RoundsSettings, CreateButton, TournamentImageLoader, generateDoubleEliminationRounds, getMaxNumberOfParticipantOptions } from './_shared'
import { FileSelector, TextInput } from 'components/_shared'
import { CoverUploader, CoverUploaderCallback } from './_shared/ImageUploader'
import TournamentDetailStep from './_shared/TournamentDetailStep'
import { TimeZoneInfoDTO } from 'store/types/app-types'
import StateDailog from './_shared/StateDailog'
import CityDailog from './_shared/CityDailog'
import { TournamentSetup } from './_shared/Steps/TournamentSetup/TournamentSetup'
import { InfoText } from 'components/_library/InfoText/InfoText'
import { TournamentEventTypeEnum, TournamentEventTypeName } from 'consts/TournamentEventType'
import { APP_NAMESPACE } from './_shared/TicketSeatingArrangement/SectionArrangement/utils/constant/constant'

interface Props {
  tournamentDetailId?: number
  isAdmin?: boolean
  history: any
}

interface State {
  tournament: kl_TournamentViewModel
  mainTournamentsList: kl_Tournament[]
  sliderStep: number
  completedSteps: Object
  regions: kl_RegionModel[]
  allCountries: kl_CountryModel[]
  countries: kl_CountryModel[]
  games: GameModel[]
  placements: any[]
  selectedPlacements: number[]
  curSetting: kl_TournamentSettingsViewModel
  cancelCreation: boolean
  upgradetoPremium: boolean
  errorFields: any
  uploadingError: string
  showDialouge: boolean
  showClass: string
  organizerPayout: Kl_OrganizerPayout
  maxNumberOfParticipants: any[]
  userCountry: string
  userCountryCode: string
  cities: kl_CityModel[]
  tournamentDto: klTournamentDataViewModel
  isEditTournament: boolean
  isDraftTournament: boolean
  isSaveTournament: boolean
  isCanceledTournament: boolean
  ticketImage?: File
  initialTicketImage?: ImageType
  cover?: File
  coverDataUrl?: string
  coverMediaType?: MediaType
  coverId?: number
  initialCover?: ImageType
  mainTournamentDate: Date
  states: kl_StateModel[]
  timeZones: TimeZoneInfoDTO[]
  mainTournament: TournamentViewModel_kl
  isShowStateDailog: boolean
  isShowCityDailog: boolean
  isResumeTournament: boolean
  resumeCoverId?: number
  resumeTicketImageId?: number
  resumeTournamentId?: number
  isAnyTime?: boolean
  isFromMainEvent?:boolean
  TournamentTicketSection:TournamentTicketSectionDetail[]
  LayOutTypeId:number
  organizerCurrency:string
}

export class TournamentWizard extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    const user: UserModel = getUser()
    if (localStorage.getItem('DraftTournament') && !this.props.tournamentDetailId) {
      const _state: State = { ...JSON.parse(localStorage.getItem('DraftTournament')) }
      _state.isDraftTournament = true
      _state.tournament.TournamentDetails[0].RegistrationOpensOn = new Date(_state.tournament.TournamentDetails[0].RegistrationOpensOn)
      _state.tournament.TournamentDetails[0].RegistrationClosesOn = new Date(_state.tournament.TournamentDetails[0].RegistrationClosesOn)
      _state.tournament.TournamentDetails[0].StartDate = new Date(_state.tournament.TournamentDetails[0].StartDate)
      const tournamentDto: klTournamentDataViewModel = Object()
      _state.tournamentDto = tournamentDto
      this.state = { ..._state }
    } else {
      const tournament: kl_TournamentViewModel = Object()
      const tournamentDto: klTournamentDataViewModel = Object()
      if (this.props.isAdmin) {
        tournament.OrganizerId = 0
        tournament.EventTypeId = 1
      } else {
        tournament.OrganizerId = user.id
        tournament.EventTypeId = EventTypeEnum.Normal //user.IsPremium || this.props.isAdmin ? EventTypeEnum.Main : EventTypeEnum.Normal
        tournament.Organizationname = user.Organizationname
      }

      tournament.HostedByOrganizer = false
      tournament.AutomaticBracketResizing = false
      tournament.IsManualControl = false
      tournament.TournamentConsoles = []
      tournament.TournamentSettings = []
      tournament.TournamentRepeatSettings = []
      tournament.TournamentDetails = [Object()]
      tournament.TournamentDetails[0].TournamentStatusId = 1
      tournament.TournamentDetails[0].RegistrationOpensOn = new Date()
      tournament.TournamentDetails[0].RegistrationOpensOn.setMinutes(0)
      tournament.TournamentDetails[0].RegistrationOpensOn.setSeconds(0)
      tournament.TournamentDetails[0].RegistrationOpensOn.setMilliseconds(0)
      tournament.TournamentDetails[0].RegistrationOpensOn.setHours(tournament.TournamentDetails[0].RegistrationOpensOn.getHours() + 1)
      tournament.TournamentDetails[0].RegistrationClosesOn = new Date(tournament.TournamentDetails[0].RegistrationOpensOn)
      tournament.TournamentDetails[0].RegistrationClosesOn.setHours(tournament.TournamentDetails[0].RegistrationClosesOn.getHours() + 1)
      tournament.TournamentDetails[0].StartDate = new Date(tournament.TournamentDetails[0].RegistrationClosesOn)
      tournament.TournamentDetails[0].StartDate.setHours(tournament.TournamentDetails[0].StartDate.getHours() + 1)
      tournament.TournamentDetails[0].TournamentRounds = []
      tournament.TournamentDetails[0].TournamentTickets = []
      tournament.PrivateSlot = 0
      tournament.PublicSlot = 0
      

      this.state = {
        tournament,
        sliderStep: 0,
        completedSteps: {},
        regions: [],
        allCountries: [],
        countries: [],
        games: [],
        placements: [],
        selectedPlacements: [],
        curSetting: Object(),
        cancelCreation: false,
        upgradetoPremium: false,
        errorFields: {},
        mainTournamentsList: [],
        uploadingError: '',
        showDialouge: false,
        showClass: '',
        organizerPayout: Object(),
        maxNumberOfParticipants: [],
        userCountry: '',
        userCountryCode: '',
        cities: [],
        tournamentDto,
        isEditTournament: false,
        isDraftTournament: false,
        isSaveTournament: false,
        isCanceledTournament: false,
        mainTournamentDate: new Date(),
        states: [],
        timeZones: [],
        mainTournament: Object(),
        isShowStateDailog: false,
        isShowCityDailog: false,
        isResumeTournament: false,
        resumeCoverId: null,
        resumeTicketImageId: null,
        resumeTournamentId: null,
        isAnyTime: null,
        isFromMainEvent:false,
        TournamentTicketSection:[],
        LayOutTypeId:0,
        organizerCurrency:'USD'
      }
    }
    this.handleTournamentDetailDataChange = this.handleTournamentDetailDataChange.bind(this)
    this.generatePlacementList = this.generatePlacementList.bind(this)
    this.moveToStep = this.moveToStep.bind(this)
    this.handleTournamentSelectChange = this.handleTournamentSelectChange.bind(this)
    this.getRegions = this.getRegions.bind(this)
    this.getCountries = this.getCountries.bind(this)
    this.getGames = this.getGames.bind(this)
    this.handleTournamentConsolesChange = this.handleTournamentConsolesChange.bind(this)
    this.handlePlacementsChange = this.handlePlacementsChange.bind(this)
    this.handleTournamentSelectTimeChange = this.handleTournamentSelectTimeChange.bind(this)
    this.removeSetting = this.removeSetting.bind(this)
    this.addSetting = this.addSetting.bind(this)
    this.handleSettingsDataChange = this.handleSettingsDataChange.bind(this)
    this.handleRoundDataChange = this.handleRoundDataChange.bind(this)
    this.generateTournamentRounds = this.generateTournamentRounds.bind(this)
    this.handleTournamentStructureChange = this.handleTournamentStructureChange.bind(this)
    this.upgradeToPremium = this.upgradeToPremium.bind(this)
    this.cancelTournamentCreation = this.cancelTournamentCreation.bind(this)
    this.openPremiumModal = this.openPremiumModal.bind(this)
    this.saveTournament = this.saveTournament.bind(this)
    this.onChangeRepeatSettings = this.onChangeRepeatSettings.bind(this)
    this.joinDates = this.joinDates.bind(this)
    this.handleTicketDataChange = this.handleTicketDataChange.bind(this)
    this.GetVenueLocation = this.GetVenueLocation.bind(this)
    this.isDataValid = this.isDataValid.bind(this)
    this.getTournamentDetails = this.getTournamentDetails.bind(this)
    this.getMainTournaments = this.getMainTournaments.bind(this)
    this.onChangeStartDate = this.onChangeStartDate.bind(this)
    this._getOrganizerPayout = this._getOrganizerPayout.bind(this)
    this.isOrganizerVerified = this.isOrganizerVerified.bind(this)
    this.navigationWithStep = this.navigationWithStep.bind(this)
    this.getCities = this.getCities.bind(this)
    this.showStateDailog = this.showStateDailog.bind(this)
    this.saveState = this.saveState.bind(this)
    this.showCityDailog = this.showCityDailog.bind(this)
    this.saveCity = this.saveCity.bind(this)
    this.setupWindowEventListener = this.setupWindowEventListener.bind(this)
    this.preventWindowClose = this.preventWindowClose.bind(this)
    this.setLocation = this.setLocation.bind(this)
    this.handleTournamentRoundPlayDateTime = this.handleTournamentRoundPlayDateTime.bind(this)
    this.handlePrivateAndPublicSlotChange = this.handlePrivateAndPublicSlotChange.bind(this)
    this.handleSeatingArrangements = this.handleSeatingArrangements.bind(this)
  }

  async componentDidMount() {
    setTimeout(function () {
      eval('initTextEditor()')
    }, 100)

    if (Geocode) {
      Geocode.setApiKey(GeneralUtils.GoogleAPIKey)
      Geocode.setLanguage('en')
      Geocode.setRegion('us')
    }
    await this.getRegions()
    await this.getCountries()
    void this.getGames()
    void this.getMainTournaments()
    void this._getOrganizerPayout()
    localStorage.removeItem("IsPendingSaveTournament")
    await this.setupWindowEventListener()
    if (this.props.tournamentDetailId) {
      void this.getTournamentDetails()
    }

    const data: any = await LocationUtils.getCoords()
    const location = await LocationUtils.GetLocationInfo('country', data.latitude, data.longitude)
    this.setState({
      userCountry: location.CountryName,
      userCountryCode: location.CountryCode,
    })
    document.getElementsByClassName('wgcurrent')[0] && document.getElementsByClassName('wgcurrent')[0].setAttribute('style', 'display:none;')
    const currentStep: number = this.state.sliderStep
    this.navigationWithStep(currentStep)
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.preventWindowClose, false)
    localStorage.removeItem("IsPendingSaveTournament")
    document.getElementsByClassName('wgcurrent')[0] && document.getElementsByClassName('wgcurrent')[0].removeAttribute('style')
  }
  preventWindowClose = (e: BeforeUnloadEvent) => {
    const isPendingSaveTournament = localStorage.getItem("IsPendingSaveTournament")
    if (isPendingSaveTournament === "true") {
      e.preventDefault()
      e.returnValue = ''
      return ''
    }
    else {
      window.onbeforeunload = () => null
      window.onbeforeunload = () => { }
    }
  }
  sortTournamentRounds = (tournamentRounds:kl_TournamentRoundsViewModel[])=>{
    return tournamentRounds.sort(function(a, b) { 
      return ( new Date(a.PlayDateTime).valueOf() - new Date(b.PlayDateTime).valueOf() ||a.RoundNumber - b.RoundNumber);
    });
  }
  async setLocation(isRefresh?: boolean) {
    if ((this.state.userCountry === undefined || this.state.userCountry === '') &&
      (this.state.userCountryCode === undefined || this.state.userCountryCode === '')
    ) {
      const data: any = await LocationUtils.getCoords(isRefresh)
      const location = await LocationUtils.GetLocationInfo('country', data.latitude, data.longitude)
      this.setState({
        userCountry: location.CountryName,
        userCountryCode: location.CountryCode,
      })
    }
  }
  async setupWindowEventListener() {
    window.addEventListener("beforeunload", this.preventWindowClose, false)
  }
  async getTournamentDetails() {
    const { tournamentDetailId } = this.props
    const isEditEvent = localStorage.getItem('IsEditEvent')
    const isResumeEvent = localStorage.getItem('IsResumeEvent')
    const isIncludeCancelEvent = ((isEditEvent !== undefined || isResumeEvent !== undefined) && (isEditEvent == 'true' || isResumeEvent == 'true') ? true : false)
    localStorage.removeItem('IsEditEvent')
    localStorage.removeItem('IsResumeEvent')
    const isResumeTournament = (isResumeEvent !== undefined && isResumeEvent == 'true') ? true : false
    this.setState({ isCanceledTournament: isIncludeCancelEvent, isResumeTournament: isResumeTournament })
    const result = await TournamentUtils.GetTournamentViewModelByID(tournamentDetailId, isIncludeCancelEvent)
    if (!result.isError) {
      console.log("tournament_____",result.data)
      const tournament = result.data
      const resultRounds = await TournamentUtils.GetTournamentRounds(tournamentDetailId)
      if (!resultRounds.isError) {

        const tournamentRounds = resultRounds.data
        tournamentRounds.map((data, index) => {
          tournamentRounds[index].PlayDateTime = new Date(data.PlayDateTime)
        })
        if (tournamentRounds !== null && tournamentRounds.length > 0) {
          tournamentRounds.forEach(element => {
            element.ZonedPlayDateTime = null
            element.ZonedResultSubmissionDeadlineDate = null
          })
        }

        if (isResumeTournament) {
          tournament.TournamentSettings.map((data, index) => {
            data.Id = 0
            data.TournamentId = 0
          })
        }
        
        const tournamentCreateModel: kl_TournamentViewModel = Object()
        tournamentCreateModel.AutomaticBracketResizing = tournament.AutomaticBracketResizing
        tournamentCreateModel.MainTournamentId = tournament.MainTournamentId
        tournamentCreateModel.NumberOfQualifiersMoveForward = tournament.NumberOfQualifiersMoveForward
        tournamentCreateModel.City = tournament.City
        tournamentCreateModel.CityId = tournament.CityId
        tournamentCreateModel.CountryId = tournament.CountryId
        tournamentCreateModel.Country = tournament.Country
        tournamentCreateModel.TournamentConsoles = tournament.GameConsolesIds
        tournamentCreateModel.TournamentConsolesString = tournament.GameConsoles
        tournamentCreateModel.GameId = tournament.GameId
        tournamentCreateModel.GameName = tournament.GameName
        tournamentCreateModel.HostedByOrganizer = tournament.HostedByOrganizer
        tournamentCreateModel.Id = (isResumeTournament ? 0 : tournament.TournamentId)
        tournamentCreateModel.Information = tournament.Information
        tournamentCreateModel.MaxNumberOfParticipients = tournament.MaxNumberOfParticipients && parseInt(tournament.MaxNumberOfParticipients.toString())
        tournamentCreateModel.MaxNumberOfRounds = tournament.MaxNumberOfRounds
        tournamentCreateModel.Name = tournament.Name
        tournamentCreateModel.NumberOfPlacements = tournament.NumberOfPlacements
        tournamentCreateModel.Organizationname = tournament.Organizationname
        tournamentCreateModel.OrganizerId = (tournament.Organizerid !== null ? tournament.Organizerid : 0)
        tournamentCreateModel.ParticipientsPerMatch = tournament.ParticipientsPerMatch
        tournamentCreateModel.Province = tournament.Province
        tournamentCreateModel.ProvinceId = tournament.ProvinceId
        tournamentCreateModel.Region = tournament.Region
        tournamentCreateModel.RegionId = tournament.RegionId
        tournamentCreateModel.EventTypeId = tournament.EventTypeId
        tournamentCreateModel.TimeZoneId = tournament.TimeZoneId
        tournamentCreateModel.ContactEmail = tournament.ContactEmail
        tournamentCreateModel.DiscordLink = tournament.DiscordLink
        tournamentCreateModel.PlayersPerClan = tournament.PlayersPerClan
        tournamentCreateModel.TournamentSettings = tournament.TournamentSettings
        tournamentCreateModel.TournamentRepeatSettings = []
        tournamentCreateModel.Rules = tournament.Rules
        tournamentCreateModel.StreamingLink = tournament.StreamingLink
        tournamentCreateModel.TournamentMode = tournament.TournamentMode
        tournamentCreateModel.TournamentModeId = tournament.TournamentModeId
        tournamentCreateModel.TournamentStructureId = tournament.TournamentStructureId
        tournamentCreateModel.VenueLatitude = tournament.VenueLatitude ? tournament.VenueLatitude.toString() : ''
        tournamentCreateModel.VenueLocation = tournament.VenueLocation
        tournamentCreateModel.VenueLongitude = tournament.VenueLongitude ? tournament.VenueLongitude.toString() : ''
        tournamentCreateModel.VenueName = tournament.VenueName
        tournamentCreateModel.VenueSeatingCapacity = tournament.VenueSeatingCapacity
        tournamentCreateModel.VenueTypeId = tournament.VenueTypeId
        tournamentCreateModel.CoverId = tournament.CoverId
        tournamentCreateModel.TicketImageId = tournament.TicketImageId
        tournamentCreateModel.IsManualControl = tournament.IsManualControl
        tournamentCreateModel.PrivateSlot = tournament?.PrivateSlot
        tournamentCreateModel.PublicSlot = tournament?.PublicSlot
        tournamentCreateModel.NumberOfSubstitutes = tournament?.NumberOfSubstitutes
        tournamentCreateModel.IsTicketsAdded = tournament?.IsTicketsAdded
        tournamentCreateModel.TournamentDetails = [
          {
            Id: (isResumeTournament ? 0 : tournament.Id),
            TournamentId: isResumeTournament ? 0 : tournament.TournamentId,
            RepeatSettingId: 0,
            StartDate: new Date(tournament.StartDate),
            RegistrationOpensOn: new Date(tournament.RegistrationOpensOn),
            RegistrationClosesOn: new Date(tournament.RegistrationClosesOn),
            ActualNumberOfParticipients: tournament.ActualNumberOfParticipients,
            ActualNumberOfRounds: tournament.ActualNumberOfRounds,
            NumberOfParticipientsLaidOff: tournament.NumberOfParticipientsLaidOff,
            TournamentStatusId: 1,
            TournamentRounds: this.sortTournamentRounds(tournamentRounds),
            TournamentTickets: tournament.TournamentTickets,
            IsAnyTime: tournament.IsAnyTime,
            LayOutTypeId:tournament.LayOutTypeId

          },
        ]

        this.setState({
          tournament: tournamentCreateModel,
          isEditTournament: isResumeTournament ? false : true,
          completedSteps: { 0: true, 1: true, 2: true, 3: true, 4: true, 5: true },
          sliderStep: 2,
          selectedPlacements: [],
          placements: this.generatePlacementList(tournament.TournamentStructureId, tournament.MaxNumberOfParticipients, tournament.ParticipientsPerMatch),
          maxNumberOfParticipants: getMaxNumberOfParticipantOptions(tournament.TournamentStructureId, tournament.ParticipientsPerMatch),
          resumeTournamentId: tournament.TournamentId,
          coverId: tournamentCreateModel.CoverId,
          resumeCoverId: isResumeTournament ? tournamentCreateModel.CoverId : null,
          resumeTicketImageId: isResumeTournament ? tournamentCreateModel.TicketImageId : null,
          isAnyTime: tournament.IsAnyTime,
          isFromMainEvent:false,
          TournamentTicketSection:tournament?.TournamentTicketSection,
          LayOutTypeId:tournament?.LayOutTypeId

        }, async () => {
          const { errorFields } = this.state


          document.getElementsByClassName('ql-editor')[0].innerHTML = tournament.Rules

          if (tournamentCreateModel.RegionId === null || tournamentCreateModel.RegionId === undefined) {
            this.setState({ errorFields, sliderStep: 1 }, () => {
            });
            ((tournamentCreateModel.RegionId === null || tournamentCreateModel.RegionId === undefined) ? errorFields.RegionId = true : errorFields.RegionId = false)
          } else {
            this.handleTournamentSelectChange('RegionId', tournament.RegionId)
          }
          if (tournamentCreateModel.CountryId === null || tournamentCreateModel.CountryId === undefined) {
            ((tournamentCreateModel.CountryId === null || tournamentCreateModel.CountryId === undefined) ? errorFields.CountryId = true : errorFields.CountryId = false)
            this.setState({ errorFields, sliderStep: 1 }, () => {
            })
          } else { await this.handleTournamentSelectChange('CountryId', tournament.CountryId) }
          if (tournamentCreateModel.ProvinceId === null || tournamentCreateModel.ProvinceId === undefined) {
            ((tournamentCreateModel.ProvinceId === null || tournamentCreateModel.ProvinceId === undefined) ? errorFields.ProvinceId = true : errorFields.ProvinceId = false)
            this.setState({ errorFields, sliderStep: 1 }, () => {
            })
          } else { await this.handleTournamentSelectChange('ProvinceId', tournament.ProvinceId) }

          if (tournamentCreateModel.CityId === null || tournamentCreateModel.CityId === undefined) {
            ((tournamentCreateModel.CityId === null || tournamentCreateModel.CityId === undefined) ? errorFields.CityId = true : errorFields.CityId = false)
            this.setState({ errorFields, sliderStep: 1 }, () => {
            })
          }

          this.handlePlacementsChange([tournament.NumberOfPlacements])
          this.navigationWithStep(2)

          if (tournament.MainTournamentId !== null && tournament.MainTournamentId !== 0) {
            await this.getmainTournamentDate(tournamentCreateModel.MainTournamentId)
          }
          this.setState({ coverId: tournamentCreateModel.CoverId })
        })

      }
    }
  }

  async getGames() {
    const games = await GamesUtils.GetAll()
    if (!games.isError) {
      this.setState({ games: games.data })
    }
  }

  async getMainTournaments() {
    const result = await TournamentUtils.GetMainTournaments()
    if (!result.isError) {
      this.setState({ mainTournamentsList: result.data })

      if (!this.props.tournamentDetailId) {
        if (result.data !== undefined && result.data != null) {
          if (result.data.length === 1) {
            const { tournament } = this.state
            tournament.MainTournamentId = result.data[0].Id
            tournament.PlayersPerClan = result.data[0].PlayersPerClan
            tournament.NumberOfSubstitutes = result.data[0].NumberOfSubstitutes
            this.setState({
              tournament: tournament,
              isFromMainEvent:true
            })
          }
        }
      }
    }
  }

  async getRegions() {
    const regions = await RegionUtils.GetAll()
    if (!regions.isError) {
      this.setState({ regions: regions.data })
    }
  }

  async getCountries() {
    const countries = await CountryUtils.GetAll()
    if (!countries.isError) {
      this.setState({ allCountries: countries.data })
    }
  }

  async getCities(ProvinceId) {
    const cities = await CountryUtils.GetAllCitiesByStateId(ProvinceId)
    if (!cities.isError) {
      this.setState({ cities: cities.data })
    }
  }

  async getStates(countryId) {
    const states = await CountryUtils.GetAllStateByCountryId(countryId)
    if (!states.isError) {
      this.setState({ states: states.data })
    }
  }

  async getTimeZonebyCountryCode(CountryCode) {
    const TimeZone = await CountryUtils.GetAllTimeZoneByCountryCode(CountryCode)
    if (!TimeZone.isError) {
      this.setState({ timeZones: TimeZone.data })
      if (TimeZone.data && TimeZone.data.length > 0) {
        if (!this.state.isEditTournament) {
          this.handleTournamentSelectChange("TimeZoneId", TimeZone.data[0])
        }
      }
    }
  }

  onChangeRepeatSettings(repeatSettings: Date[]) {
    const tournament = this.state.tournament
    tournament.TournamentRepeatSettings = repeatSettings
    this.setState({ tournament })
  }

  joinDates(dates: Date[]): string {
    let dateString = ''

    for (let i = 0; i < dates.length; i++) {
      dateString += new Date((dateString ? ', ' : '') + dates[i]).toLocaleDateString() + ' - '
    }

    return dateString ? dateString : 'MM/dd/yyyy'
  }

  async removeSetting(index: number) {
    const tournament = this.state.tournament
    tournament.TournamentSettings.splice(index, 1)
    this.setState({ tournament })
  }

  async addSetting() {
    const tournament = this.state.tournament
    const curSetting = this.state.curSetting

    tournament.TournamentSettings.push({
      Id: 0,
      TournamentId: tournament.Id,
      SettingName: this.state.curSetting.SettingName,
      SettingValue: this.state.curSetting.SettingValue,
    })
    curSetting.SettingName = ''
    curSetting.SettingValue = ''

    this.setState({ tournament, curSetting })
  }

  generateTournamentRounds(
    tournamentStructureId: number,
    maxNumberOfParticipients: number,
    participientsPerMatch: number
  ): kl_TournamentRoundsViewModel[] {
    const tournamentRounds: kl_TournamentRoundsViewModel[] = []
    let playDateTime = this.state.tournament.TournamentDetails[0].StartDate

    if (tournamentStructureId == 1 && maxNumberOfParticipients > 1) {
      let contenders = maxNumberOfParticipients
      let matches = contenders / 2
      let playerNumber = 1
      let roundTitle = ''
      for (let round = 1; ; round++) {
        matches = contenders / 2
        playerNumber = 1
        for (let match = 1; match <= matches; match++) {
          roundTitle = contenders == 2 ? 'Final' : contenders == 4 ? 'Semifinal' : `Round ${round}`
          if (match == 1) {
            tournamentRounds.push({
              Id: round,
              TournamentDetailId: this.state.tournament.TournamentDetails[0].Id,
              RoundNumber: round,
              RoundTitle: roundTitle,
              PlayDateTime: playDateTime,
              ResultSubmissionDeadline: 30,
              IsLosersBracket: false,
              IsExtraRound: false,
              TournamentSchedule: [],
              ZonedPlayDateTime: null,
              ResultSubmissionDeadlineDate: null,
              ZonedResultSubmissionDeadlineDate: null,
            })
            if (playDateTime) {
              playDateTime = new Date(playDateTime)
              playDateTime.setTime(playDateTime.getTime() + 1000 * 60 * 60)
            }
          }
          tournamentRounds[tournamentRounds.length - 1].TournamentSchedule.push({
            Id: 0,
            TournamentRoundId: round,
            MatchNumber: match,
            MatchTitle: `${roundTitle} Participant ${playerNumber} and ${playerNumber + 1}`,
          })

          playerNumber = playerNumber + 2
        }

        if (contenders == 2) {
          break
        } else {
          contenders = contenders / 2
        }
      }
    } else if (tournamentStructureId == TournamentStructureEnum.DoubleElimination && maxNumberOfParticipients > 1) {
      return generateDoubleEliminationRounds(this.state.tournament.TournamentDetails[0].Id, maxNumberOfParticipients, playDateTime)
    } else if (tournamentStructureId == TournamentStructureEnum.SingleRoundFfa && maxNumberOfParticipients > 1) {
      tournamentRounds.push({
        Id: 1,
        TournamentDetailId: this.state.tournament.TournamentDetails[0].Id,
        RoundNumber: 1,
        RoundTitle: 'Single Round FFA',
        PlayDateTime: playDateTime,
        ResultSubmissionDeadline: 30,
        IsLosersBracket: false,
        IsExtraRound: false,
        TournamentSchedule: [],
        ZonedPlayDateTime: null,
        ResultSubmissionDeadlineDate: null,
        ZonedResultSubmissionDeadlineDate: null,
      })
      tournamentRounds[tournamentRounds.length - 1].TournamentSchedule.push({
        Id: 0,
        TournamentRoundId: 1,
        MatchNumber: 1,
        MatchTitle: 'Round',
      })
    } else if (tournamentStructureId == TournamentStructureEnum.EliminationRoundFfa && participientsPerMatch > 2) {
      let matches = maxNumberOfParticipients / participientsPerMatch
      const totalRounds = Math.round(Math.log(maxNumberOfParticipients) / Math.log(participientsPerMatch))

      for (let round = 1; round <= totalRounds; round++) {
        tournamentRounds.push({
          Id: round,
          TournamentDetailId: this.state.tournament.TournamentDetails[0].Id,
          RoundNumber: round,
          RoundTitle: round < totalRounds ? `Round ${round}` : 'Final',
          PlayDateTime: playDateTime,
          ResultSubmissionDeadline: 30,
          IsLosersBracket: false,
          IsExtraRound: false,
          TournamentSchedule: [],
          ZonedPlayDateTime: null,
          ResultSubmissionDeadlineDate: null,
          ZonedResultSubmissionDeadlineDate: null,
        })
        if (playDateTime) {
          playDateTime = new Date(playDateTime)
          playDateTime.setTime(playDateTime.getTime() + 1000 * 60 * 60)
        }
        for (let match = 1; match <= matches; match++) {
          tournamentRounds[tournamentRounds.length - 1].TournamentSchedule.push({
            Id: 0,
            TournamentRoundId: round,
            MatchNumber: match,
            MatchTitle: round < totalRounds ? `Round ${round} Match ${match}` : 'Final',
          })
        }
        matches = matches / participientsPerMatch
      }
    }

    return tournamentRounds
  }

  onChangeStartDate(date: Date) {
    const { tournament } = this.state
    this.handleTournamentDetailDataChange('StartDate', date)

    tournament.TournamentRepeatSettings = this.state.tournament.TournamentRepeatSettings.filter(f => f > date)
    this.setState({
      tournament,
    })
    this.setRoundPlayDateTime()
  }

  setRoundPlayDateTime() {
    const tournament = this.state.tournament
    let playDateTime = this.state.tournament.TournamentDetails[0].StartDate

    if (playDateTime) {
      for (let i = 0; i < tournament.TournamentDetails[0].TournamentRounds.length; i++) {
        playDateTime = new Date(playDateTime)
        if (i > 0) {
          playDateTime.setTime(
            playDateTime.getTime() + 1000 * 60 * (tournament.TournamentDetails[0].TournamentRounds[i].ResultSubmissionDeadline + 30)
          )
        }
        tournament.TournamentDetails[0].TournamentRounds[i].PlayDateTime = playDateTime
      }
    }

    this.setState({ tournament })
    this.generateTickets()
  }

  generatePlacementList(tournamentStructureId: number, maxNumberOfParticipients: number, participientsPerMatch: number): any[] {
    const placements: any[] = []

    if (tournamentStructureId < 3) {
      placements.push({ id: 1, text: '1st' })
      if (maxNumberOfParticipients >= 2) {
        placements.push({ id: 2, text: '2nd' })
        if (maxNumberOfParticipients >= 4) {
          placements.push({ id: 4, text: '3rd and 4th' })
          if (maxNumberOfParticipients >= 8) {
            placements.push({ id: 8, text: '5th, 6th, 7th, 8th' })
            if (maxNumberOfParticipients >= 16) {
              placements.push({ id: 16, text: '9th to 16th' })
              if (maxNumberOfParticipients >= 32) {
                placements.push({ id: 16, text: '17th to 32nd' })
                if (maxNumberOfParticipients >= 64) {
                  placements.push({ id: 16, text: '33rd to 64th' })
                  if (maxNumberOfParticipients >= 128) {
                    placements.push({ id: 16, text: '65th to 128th' })
                    if (maxNumberOfParticipients >= 256) {
                      placements.push({ id: 16, text: '129th to 256th' })
                      if (maxNumberOfParticipients >= 512) {
                        placements.push({ id: 16, text: '257th to 512th' })
                        if (maxNumberOfParticipients >= 1024) {
                          placements.push({ id: 16, text: '513th to 1024th' })
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    } else if (tournamentStructureId == 4) {
      placements.push({ id: 1, text: '1st' })
      placements.push({ id: 2, text: '2nd' })
      placements.push({ id: 3, text: '3rd' })
      if (participientsPerMatch == 4) {
        placements.push({ id: 4, text: '4th' })
      } else if (participientsPerMatch > 3 && participientsPerMatch < 6) {
        placements.push({ id: 5, text: '4th and 5th' })
      }
      if (participientsPerMatch > 5) {
        placements.push({ id: participientsPerMatch, text: `4th to ${participientsPerMatch}th` })
      }
      placements.push({
        id: participientsPerMatch * participientsPerMatch,
        text: `${participientsPerMatch + 1}th to ${participientsPerMatch * participientsPerMatch}th`,
      })
      placements.push({
        id: participientsPerMatch * participientsPerMatch * participientsPerMatch,
        text: `${placements[placements.length - 1].id + 1}th to ${placements[placements.length - 1].id * participientsPerMatch}th`,
      })
    }

    return placements
  }

  async generateTickets() {
    if (this.state.tournament.VenueTypeId === 2) {
      const tickets: kl_TournamentTicketsViewModel[] = []
      const tournament = this.state.tournament

      for (let i = 0; i < tournament.TournamentDetails[0].TournamentRounds.length; i++) {
        if (
          tournament.TournamentDetails[0].TournamentRounds[i].PlayDateTime &&
          tickets.findIndex(
            x => new Date(x.TicketDate).toDateString() == new Date(tournament.TournamentDetails[0].TournamentRounds[i].PlayDateTime).toDateString()
          ) === -1
        ) {
          tickets.push({
            Id: tickets.length + 1,
            TournamentDetailId: tournament.TournamentDetails[0].Id,
            DayNumber: tickets.length + 1,
            TicketPrice: null,
            TicketDate: new Date(tournament.TournamentDetails[0].TournamentRounds[i].PlayDateTime),
          })
        }
      }

      if (tickets.length > 1) {
        tickets.unshift({
          Id: 0,
          TournamentDetailId: tournament.TournamentDetails[0].Id,
          DayNumber: null,
          TicketDate: null,
          TicketPrice: null,
        })
      }

      tournament.TournamentDetails[0].TournamentTickets = tickets

      this.setState({ tournament })
    }
  }

  async handleTournamentStructureChange(paramName: string, val: any) {
    const tournament = this.state.tournament
    tournament[paramName] = val
    if (tournament.TournamentStructureId !== TournamentStructureEnum.SingleRoundFfa) {
      tournament.NumberOfPlacements = null
    }
    tournament.MaxNumberOfParticipients = tournament.MaxNumberOfParticipients && parseInt(tournament.MaxNumberOfParticipients.toString())
    if (paramName == 'ParticipientsPerMatch') {
      tournament.MaxNumberOfParticipients = tournament.ParticipientsPerMatch * tournament.ParticipientsPerMatch * tournament.ParticipientsPerMatch
    } else if (paramName == 'TournamentStructureId') {
      if (!this.props.tournamentDetailId) {
        tournament.MaxNumberOfParticipients = null
        tournament.ParticipientsPerMatch = null
      }
      tournament.HostedByOrganizer = !isTwoPlayersStructure(val)
    }

    if (!this.state.isEditTournament) {
      tournament.TournamentDetails[0].TournamentRounds = this.generateTournamentRounds(
        tournament.TournamentStructureId,
        tournament.MaxNumberOfParticipients,
        tournament.ParticipientsPerMatch
      )
    }

    if (tournament.EventTypeId !== EventTypeEnum.Qualifier) {
      if (paramName === 'TournamentStructureId') {
        tournament.PrivateSlot = undefined
        tournament.PublicSlot = undefined
      }
      if (paramName === 'MaxNumberOfParticipients') {
        tournament.PrivateSlot = undefined
        tournament.PublicSlot = tournament.MaxNumberOfParticipients
      }
    }

    this.setState({
      placements: this.generatePlacementList(tournament.TournamentStructureId, tournament.MaxNumberOfParticipients, tournament.ParticipientsPerMatch),
      tournament,
      selectedPlacements: [],
      maxNumberOfParticipants: getMaxNumberOfParticipantOptions(tournament.TournamentStructureId, tournament.ParticipientsPerMatch),
    })

    this.generateTickets()
  }
  handleTournamentRoundPlayDateTime = (isAnyTime?: boolean) => {
    const tournament = this.state.tournament
    if (!this.state.isEditTournament) {
      if (isAnyTime) {
        for (var i = 0; i < tournament.TournamentDetails[0].TournamentRounds.length; i++) {
          tournament.TournamentDetails[0].TournamentRounds[i].PlayDateTime = new Date(fullFormatDate(tournament.TournamentDetails[0].TournamentRounds[i].PlayDateTime))
          tournament.TournamentDetails[0].TournamentRounds[i].ZonedPlayDateTime = null
          tournament.TournamentDetails[0].TournamentRounds[i].ResultSubmissionDeadline = null
        }
      }
      else {

        tournament.TournamentDetails[0].TournamentRounds = this.generateTournamentRounds(
          tournament.TournamentStructureId,
          tournament.MaxNumberOfParticipients,
          tournament.ParticipientsPerMatch
        )
      }
    }
    this.setState({ tournament, isAnyTime })
    if (!this.state.isEditTournament) {
      this.generateTickets()
    }

  }
  async handleSeatingArrangements(data:TournamentSeatModel){
    
    let {tournament,TournamentTicketSection ,LayOutTypeId} = this.state
    LayOutTypeId = data.LayOutSectionId
    TournamentTicketSection = data.TournamentTicketSection
    this.setState({
      tournament:tournament,
      TournamentTicketSection:TournamentTicketSection,
      LayOutTypeId: data.LayOutSectionId
    })
   // tournament.TournamentDetails[0].TournamentTicketSection = data.TournamentTicketSection
    //this.setState({ tournament,TournamentTicketSection })

  }
  async handleRoundDataChange(paramName: string, val: any, index: number) {

    const tournament = this.state.tournament
    if (paramName == 'PlayDateTime') {
      const oldTime = tournament.TournamentDetails[0].TournamentRounds[index][paramName]
      tournament.TournamentDetails[0].TournamentRounds[index][paramName] = val

      if (oldTime.toDateString() != val.toDateString()) {
        this.generateTickets()
      }
    } else if (paramName == 'ResultSubmissionDeadline') {
      tournament.TournamentDetails[0].TournamentRounds[index][paramName] = val
    }
    this.setState({ tournament })
  }

  async handleTicketDataChange(paramName: string, val: any, id: number) {

    const tournament = this.state.tournament
    const ticket = tournament.TournamentDetails[0].TournamentTickets.find(t => t.Id == id)
    if (!ticket && paramName !== 'TicketPrice') {
      return
    }

    // if (isNaN(parseFloat(val))) {
    //   ticket[paramName] = null
    // } else {
    //   ticket[paramName] = parseFloat(val)
    // }

    const user: UserModel = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : Object()
    this.setState({ tournament, showDialouge: user.isorganizer && !user.IsVerified && user.Stripe_IdentityUrl === null ? true : false })
  }

  async getmainTournamentDate(val) {
    const response = await TournamentUtils.GetTournamentViewModelByID(val, true)
    if (!response.isError) {
      const MainTournament: TournamentViewModel_kl = response.data
      const { tournament } = this.state
      if (response.data.TournamentStatusId !== TournamentStatusEnum.Cancel) {

        tournament.TournamentModeId = MainTournament.TournamentModeId
        tournament.TournamentStructureId = MainTournament.TournamentStructureId
        tournament.PlayersPerClan = MainTournament.PlayersPerClan
        tournament.NumberOfSubstitutes = MainTournament.NumberOfSubstitutes
        this.handleTournamentDataChange('TournamentModeId', tournament.TournamentModeId)
        this.handleTournamentStructureChange('TournamentStructureId', tournament.TournamentStructureId)
        this.setState({ mainTournamentDate: MainTournament.StartDate, mainTournament: MainTournament, tournament: tournament,isFromMainEvent:true })
      }
      else {
        tournament.TournamentModeId = null
        tournament.TournamentStructureId = null
        tournament.MainTournamentId = null
        tournament.PlayersPerClan = null
        tournament.NumberOfSubstitutes = null
        this.navigationWithStep(0)
        this.setState({ sliderStep: 0, tournament: tournament,isFromMainEvent:false })
      }
    }
  }

  async handleTournamentSelectChange(paramName: string, val: any) {
    const tournament = this.state.tournament

    let countries = this.state.countries
    const states = this.state.states
    tournament[paramName] = val
    if (paramName == 'MainTournamentId') {
      this.getmainTournamentDate(val)
    }
    if (paramName === 'EventTypeId') {
      if (val === EventTypeEnum.Qualifier) {
        if (this.state.mainTournamentsList.length == 1) {
          this.getmainTournamentDate(this.state.mainTournamentsList[0].Id)
        }
        else {
          this.setState({ mainTournamentDate: new Date() })
        }
      }
    }

    if (paramName == 'RegionId') {
      countries = this.state.allCountries.filter(x => x.RegionId == val)
      tournament.Region = this.state.regions.find(x => x.Id == val).RegionName
    } else if (paramName == 'ProvinceId') {
      tournament.Province = states.find(x => x.Id == val).StateName
      await this.getCities(val)
    } else if (paramName == 'CountryId') {
      countries = this.state.allCountries.filter(x => x.RegionId == tournament.RegionId)
      tournament.Country = countries.find(x => x.Id == val).CountryName
      const CountryCode = countries.find(x => x.Id == val).CountryCode
      await this.getStates(val)
      await this.getTimeZonebyCountryCode(CountryCode)
    } else if (paramName == 'GameId') {
      tournament.GameName = this.state.games.find(x => x.id == val).Gamename
    } else if (paramName == 'TimeZoneId') {
      tournament.TimeZone = val
      tournament.TimeZoneId = val.Id
    }
    this.setState({ tournament, countries })
  }

  async handleTournamentSelectTimeChange(paramName: string, val: any) {
    const tournament = this.state.tournament
    if (tournament.TournamentDetails[0][paramName]) {
      tournament.TournamentDetails[0][paramName] = new Date(new Date(tournament.TournamentDetails[0][paramName]).toDateString() + ' ' + val)

      this.setState({ tournament })
    }
  }

  async handleTournamentConsolesChange(values: any[]) {
    const tournament = this.state.tournament
    tournament.TournamentConsoles = values
    tournament.TournamentConsolesString = ConsoleList.filter(x => values.findIndex(t => t == x.Id) != -1).map(x => x.ConsoleName)
    this.setState({ tournament })
  }

  async handlePlacementsChange(values: any[]) {
    const tournament = this.state.tournament
    if (values.length >= this.state.selectedPlacements.length) {
      let maxValue = 0

      for (let i = 0; i < values.length; i++) {
        if (maxValue < values[i]) {
          maxValue = values[i]
        }
      }

      values = []
      for (let i = 0; i < this.state.placements.length; i++) {
        if (this.state.placements[i].id <= maxValue) {
          values.push(this.state.placements[i].id)
        }
      }
    } else {
      const newValues: number[] = []
      for (let i = 0; i < this.state.placements.length; i++) {
        if (values.find(x => x == this.state.placements[i].id)) {
          newValues.push(this.state.placements[i].id)
        } else {
          break
        }
      }
      values = newValues
    }

    if (values.length) {
      tournament.NumberOfPlacements = values[values.length - 1]
      tournament.NumberOfQualifiersMoveForward = values[values.length - 1]
    } else {
      if (tournament.TournamentStructureId !== TournamentStructureEnum.SingleRoundFfa) { tournament.NumberOfPlacements = null }
      tournament.NumberOfQualifiersMoveForward = null
    }

    this.setState({ selectedPlacements: values })
  }

  handlePrivateAndPublicSlotChange = (paramName: string, val: string | boolean | Date | number | Date[]) => {
    const tournament = this.state.tournament
    const privateSlot = tournament.PrivateSlot !== undefined && tournament.PrivateSlot !== null ? tournament.PrivateSlot : 0
    const publicSlot = tournament.PublicSlot !== undefined && tournament.PublicSlot !== null ? tournament.PublicSlot : 0
    const MaxNumberOfParticipients = tournament.MaxNumberOfParticipients
    if (val > MaxNumberOfParticipients) {
      tournament[paramName] = ''
      if (paramName == 'PrivateSlot') {
        tournament['PublicSlot'] = MaxNumberOfParticipients
      }
      else {
        tournament['PrivateSlot'] = MaxNumberOfParticipients
      }
      this.setState({ tournament })
    }
    else {
      this.handleTournamentDataChange(paramName, val)
    }
  }

  handleTournamentDataChange = (paramName: string, val: string | boolean | Date | number | Date[]) => {
    const tournament = this.state.tournament
    tournament[paramName] = val
    this.setState({ tournament })

    if (paramName == 'CountryId' || paramName == 'City' || paramName == 'CityId' || paramName == 'VenueLocation' || paramName == 'VenueName') {
      this.GetVenueLocation()
      if (paramName === 'CityId') {
        tournament.City = this.state.cities.find(x => x.Id == val).CityName
        this.setState({ tournament })
      }
    } else if (paramName == 'TournamentModeId') {
      if (val == 1) tournament.TournamentMode = 'Solo'
      else tournament.TournamentMode = 'Clans'
    }
    else if (tournament.EventTypeId !== EventTypeEnum.Qualifier && (paramName == 'PrivateSlot' || paramName == 'PublicSlot')) {
      const privateSlot = tournament.PrivateSlot !== undefined && tournament.PrivateSlot !== null ? tournament.PrivateSlot : 0
      const publicSlot = tournament.PublicSlot !== undefined && tournament.PublicSlot !== null ? tournament.PublicSlot : 0
      const MaxNumberOfParticipients = tournament.MaxNumberOfParticipients
      if (MaxNumberOfParticipients !== undefined && MaxNumberOfParticipients !== null) {
        if (paramName == 'PrivateSlot') {
          if (privateSlot <= MaxNumberOfParticipients) {
            tournament.PublicSlot = MaxNumberOfParticipients - privateSlot
          }
          else {
            tournament.PublicSlot = undefined
          }
        }

        if (paramName == 'PublicSlot') {
          if (publicSlot <= MaxNumberOfParticipients) {
            tournament.PrivateSlot = MaxNumberOfParticipients - publicSlot
          }
          else {
            tournament.PrivateSlot = undefined
          }
        }

        this.setState({ tournament })
      }
    }
    if (paramName === 'IsManualControl') {
      if (val === false && !this.state.isEditTournament) {
        tournament.TournamentDetails[0].TournamentRounds = this.generateTournamentRounds(
          tournament.TournamentStructureId,
          tournament.MaxNumberOfParticipients,
          tournament.ParticipientsPerMatch
        )
      }
      this.setState({ isAnyTime: val === false ? null : this.state.isAnyTime, tournament })

    }
  }

  async GetVenueLocation() {
    const tournament = this.state.tournament

    if (tournament.CountryId && tournament.CityId && tournament.VenueLocation && tournament.VenueName) {
      const countryObj = this.state.allCountries.find(x => x.Id === tournament.CountryId)
      const tournamentCity = this.state.cities.find(x => x.Id === tournament.CityId)
      if (countryObj) {
        Geocode.setRegion(countryObj.CountryCode)
        Geocode.fromAddress(`${tournament.VenueName}, ${tournament.VenueLocation}, ${tournamentCity.CityName}, ${countryObj.CountryName}`).then(
          response => {
            if (response.results.length) {
              const { lat, lng } = response.results[0].geometry.location
              const tournament = this.state.tournament

              tournament.VenueLatitude = lat ? lat.toString() : ''
              tournament.VenueLongitude = lng ? lng.toString() : ''
              this.setState({ tournament })
            }
          }
        )
      }
    }
  }

  async handleTournamentDetailDataChange(paramName: string, val: string | boolean | Date | number) {
    const tournament = this.state.tournament

    tournament.TournamentDetails[0][paramName] = val
    this.setState({ tournament })
  }

  async handleSettingsDataChange(paramName: string, val: string | boolean | Date | number) {
    const curSetting = this.state.curSetting
    curSetting[paramName] = val
    this.setState({ curSetting })
  }

  isDataValid(step: number): boolean {
    
    if (this.state.sliderStep < step) {
      const tournament = this.state.tournament
      const isAnyTime = this.state.isAnyTime
      const mainTournament = this.state.mainTournament

      if (this.state.sliderStep == 0) {
        if (!tournament.Name?.trim()) {
          this.setState({ errorFields: { Name: true } })
          return false
        }
        if (tournament.EventTypeId === EventTypeEnum.Qualifier) {
          if (!tournament.MainTournamentId) {
            this.setState({ errorFields: { MainTournamentId: true } })
            return false
          }
        }
        if (!tournament.TournamentModeId) {
          this.setState({ errorFields: { TournamentModeId: true } })
          return false
        }
        if (!tournament.TournamentStructureId) {
          this.setState({ errorFields: { TournamentStructureId: true } })
          return false
        }
      } else if (this.state.sliderStep == 1) {
        if (!tournament.VenueTypeId) {
          this.setState({ errorFields: { VenueTypeId: true } })
          return false
        }
        if (!tournament.RegionId) {
          this.setState({ errorFields: { RegionId: true } })
          return false
        }
        if (!tournament.CountryId) {
          this.setState({ errorFields: { CountryId: true } })
          return false
        }
        if (!tournament.ProvinceId) {
          this.setState({ errorFields: { ProvinceId: true } })
          return false
        }
        if (!tournament.CityId) {
          this.setState({ errorFields: { CityId: true } })
          return false
        }
        if (!tournament.TimeZoneId) {
          this.setState({ errorFields: { TimeZoneId: true } })
          return false
        }
        if (tournament.VenueTypeId == 2 && !tournament.VenueName) {
          this.setState({ errorFields: { VenueName: true } })
          return false
        }
        if (tournament.VenueTypeId == 2 && !tournament.VenueLocation) {
          this.setState({ errorFields: { VenueLocation: true } })
          return false
        }
        // if (tournament.VenueTypeId == 2 && !tournament.VenueSeatingCapacity) {
        //   this.setState({ errorFields: { VenueSeatingCapacity: true } })
        //   return false
        // }
        if (!tournament.ContactEmail && !tournament.DiscordLink) {
          this.setState({ errorFields: { ContactEmailAndDiscordLink: true } })
          return false
        }
      } else if (this.state.sliderStep == 2) {
        if (!tournament.GameId) {
          this.setState({ errorFields: { GameId: true } })
          return false
        }
        if (tournament.TournamentConsoles.length === 0) {
          this.setState({ errorFields: { Consoles: true } })
          return false
        }
        if (tournament.TournamentStructureId === 4 && !tournament.ParticipientsPerMatch) {
          this.setState({ errorFields: { ParticipientsPerMatch: true } })
          return false
        }
        if (tournament.TournamentStructureId !== 4 && !tournament.MaxNumberOfParticipients) {
          this.setState({ errorFields: { MaxNumberOfParticipients: true } })
          return false
        }
        if (tournament.TournamentModeId === 2 && !tournament.PlayersPerClan) {
          this.setState({ errorFields: { PlayersPerClan: true } })
          return false
        }
        if (!tournament.NumberOfPlacements) {
          this.setState({ errorFields: { NumberOfPlacements: true } })
          return false
        }
        if (tournament.EventTypeId === 2 && tournament.TournamentStructureId !== TournamentStructureEnum.SingleRoundFfa && !tournament.NumberOfQualifiersMoveForward) {
          this.setState({ errorFields: { NumberOfQualifiersMoveForward: true } })
          return false
        }
        const tournamentDetail = tournament.TournamentDetails[0]
        if (!tournamentDetail.RegistrationOpensOn && tournament.EventTypeId !== EventTypeEnum.Main) {
          this.setState({ errorFields: { RegistrationOpensOn: true } })
          return false
        } else if (!tournament.IsTicketsAdded && tournamentDetail.RegistrationOpensOn.getTime() < new Date().getTime() && tournament.EventTypeId !== EventTypeEnum.Main) {
          this.setState({ errorFields: { InvalidRegistrationOpensOn: true } })
          return false
        }
        if (!tournamentDetail.RegistrationClosesOn) {
          this.setState({ errorFields: { RegistrationClosesOn: true } })
          return false
        } else if (!tournament.IsTicketsAdded && tournamentDetail.RegistrationClosesOn.getTime() < tournamentDetail.RegistrationOpensOn.getTime()) {
          this.setState({ errorFields: { InvalidRegistrationClosesOn: true } })
          return false
        }
        if (!tournamentDetail.StartDate) {
          this.setState({ errorFields: { StartDate: true } })
          return false
        } else if (
          !tournament.IsTicketsAdded && tournamentDetail.StartDate.getTime() < tournamentDetail.RegistrationOpensOn.getTime() ||
          !tournament.IsTicketsAdded && tournamentDetail.StartDate.getTime() < tournamentDetail.RegistrationClosesOn.getTime()
        ) {
          this.setState({ errorFields: { InvalidStartDate: true } })
          return false
        }
        if (tournament.EventTypeId === EventTypeEnum.Qualifier) {
          if (tournament.IsManualControl && isAnyTime && !tournament.IsTicketsAdded) {
            if (
              new Date(fullFormatDate(tournamentDetail.StartDate)) >= new Date(fullFormatDate(this.state.mainTournamentDate))) {
              this.setState({ errorFields: { InvalidStartDate1: true } })
              return false
            }
          }
          else {
            if (
              !tournament.IsTicketsAdded && tournamentDetail.StartDate.getTime() >= new Date(this.state.mainTournamentDate).getTime()) {
              this.setState({ errorFields: { InvalidStartDate1: true } })
              return false
            }
          }
        }
        // if (tournament.EventTypeId === EventTypeEnum.Qualifier &&
        //   tournamentDetail.StartDate.getTime() >= new Date(this.state.mainTournamentDate).getTime()) {
        //   this.setState({ errorFields: { InvalidStartDate1: true } })
        //   return false
        // }
        if (
          (tournament.RepeatTypeId === RepeatTypeEnum.EveryDay && tournament.TournamentRepeatSettings.length === 0) ||
          (tournament.RepeatTypeId === RepeatTypeEnum.EveryHour && !tournament.RepeatHoursInterval)
        ) {
          this.setState({ errorFields: { TournamentRepeatSettings: true } })
          return false
        }
        if(tournament.EventTypeId === EventTypeEnum.Qualifier && tournament.TournamentModeId === TournamentModeEnum.Clans){
          if(mainTournament.PlayersPerClan !== tournament.PlayersPerClan){
            this.setState({ errorFields: { isFromMainEvent: true } })
            return false
          }
          if(mainTournament.NumberOfSubstitutes !== tournament.NumberOfSubstitutes){
            this.setState({ errorFields: { isFromMainEvent: true } })
            return false
          }
          this.setState({ errorFields: { isFromMainEvent: false } })
        }
      } else if (this.state.sliderStep == 4) {
        const tournamentDetail = tournament.TournamentDetails[0]
        const minDate = new Date(tournamentDetail.StartDate)
        const errorFields = {}
        if (tournament.VenueTypeId == 2 && this.state.TournamentTicketSection.length === 0) {
          this.setState({ errorFields: { CreateEventTicketError: true } })
          return false
        }
        for (let i = 0; i < tournamentDetail.TournamentRounds.length; i++) {
          if (tournament.IsManualControl && isAnyTime) {
            if (!tournamentDetail.TournamentRounds[i].PlayDateTime) {
              errorFields[`TournamentRounds${i}`] = true
              this.setState({ errorFields })
              return false
            }
            if (tournamentDetail.TournamentRounds[i].PlayDateTime && !tournament.IsTicketsAdded) {
              if (
                new Date(fullFormatDate(tournamentDetail.TournamentRounds[i].PlayDateTime))

                >= new Date(fullFormatDate(this.state.mainTournamentDate)) &&
                tournament.EventTypeId === EventTypeEnum.Qualifier) {
                errorFields[`TournamentRoundsMain${i}`] = true
                this.setState({ errorFields })
                return false
              }
              if (new Date(fullFormatDate(tournamentDetail.TournamentRounds[i].PlayDateTime)) < new Date(fullFormatDate(minDate))) {
                errorFields[`InvalidTournamentRounds${i}`] = true
                this.setState({ errorFields })
                return false
              }
            }
          }
          else {
            if (!tournamentDetail.TournamentRounds[i].PlayDateTime || !tournamentDetail.TournamentRounds[i].ResultSubmissionDeadline) {
              errorFields[`TournamentRounds${i}`] = true
              this.setState({ errorFields })
              return false
            }
            if (tournamentDetail.TournamentRounds[i].PlayDateTime && !tournament.IsTicketsAdded) {
              if (!tournament.IsTicketsAdded &&
                (new Date(tournamentDetail.TournamentRounds[i].PlayDateTime).getTime() +
                  1000 *
                  60 * (tournamentDetail.TournamentRounds[i].ResultSubmissionDeadline
                    ? tournamentDetail.TournamentRounds[i].ResultSubmissionDeadline
                    : 30))
                >= new Date(this.state.mainTournamentDate).getTime() &&
                tournament.EventTypeId === EventTypeEnum.Qualifier) {
                errorFields[`TournamentRoundsMain${i}`] = true
                this.setState({ errorFields })
                return false
              }
              if (!tournament.IsTicketsAdded && new Date(tournamentDetail.TournamentRounds[i].PlayDateTime).getTime() < minDate.getTime()) {
                errorFields[`InvalidTournamentRounds${i}`] = true
                this.setState({ errorFields })
                return false
              } else {
                minDate.setTime(
                  new Date(tournamentDetail.TournamentRounds[i].PlayDateTime).getTime() +
                  1000 *
                  60 *
                  (tournamentDetail.TournamentRounds[i].ResultSubmissionDeadline
                    ? tournamentDetail.TournamentRounds[i].ResultSubmissionDeadline
                    : 30)
                )
              }
            }
          }
        }
      }
    }
    return true
  }

  async moveToStep(step: number) {
    if ((this.state.userCountry === undefined || this.state.userCountry === '') &&
      (this.state.userCountryCode === undefined || this.state.userCountryCode === '')
    ) {
      this.setLocation(true);
      return false
    }
    this.setState({ errorFields: {} })
    const isValid = this.isDataValid(step)
    if (isValid) {
      const tournament = step === 1 ? { ...this.state.tournament, name: this.state.tournament.Name.trim() } : this.state.tournament
      const completedSteps = this.state.completedSteps

      if (this.state.sliderStep == 1) {
        tournament.Rules = document.getElementsByClassName('ql-editor')[0].innerHTML
      }
      completedSteps[this.state.sliderStep] = true

      this.setState({ tournament, sliderStep: step, completedSteps })
      this.navigationWithStep(step)
    }
    window.scrollTo(0, 0)
    document.getElementsByClassName('article__article')[0].scrollIntoView()
  }

  componentDidUpdate() {
    if (document.getElementsByClassName('row article__error error error--region error-visible')[0]) {
      document.getElementsByClassName('row article__error error error--region error-visible')[0].parentElement.previousElementSibling.scrollIntoView()
    }

    if (document.getElementsByClassName('row article__error error error--city error-visible')[0]) {
      document.getElementsByClassName('row article__error error error--city error-visible')[0].parentElement.previousElementSibling.scrollIntoView()
    }

    if (document.getElementsByClassName('row article__error error error--discordLink error-visible')[0]) {
      document.getElementsByClassName('row article__error error error--discordLink error-visible')[0].scrollIntoView()
    }

    if (document.getElementsByClassName('row article__error error error--locationName error-visible')[0]) {
      document.getElementsByClassName('row article__error error error--locationName error-visible')[0].scrollIntoView()
    }

    if (document.getElementsByClassName('row article__error error error--venueLocation error-visible')[0]) {
      document.getElementsByClassName('row article__error error error--venueLocation error-visible')[0].scrollIntoView()
    }

    if (document.getElementsByClassName('row article__error error error--maxSeating error-visible')[0]) {
      document.getElementsByClassName('row article__error error error--maxSeating error-visible')[0].scrollIntoView()
    }

    if (document.getElementsByClassName('w-100 flex-row article__error error error--rounds-0 error-visible')[0]) {
      document
        .getElementsByClassName('w-100 flex-row article__error error error--rounds-0 error-visible')[0]
        .parentElement.previousElementSibling.scrollIntoView()
    }
  }

  cancelTournamentCreation = () => {
    localStorage.removeItem('DraftTournament')
    const { isDraftTournament } = this.state
    if (location.href.indexOf('/admin/') != -1 && isDraftTournament === true) {
      localStorage.setItem('isDraftTournament', 'true')
    }
    this.setState({ cancelCreation: true })
  }

  async upgradeToPremium() {
    this.setState({ upgradetoPremium: true })
  }

  async openPremiumModal() {
    ModalUtils.initModals()
    ModalUtils.openModal('premium')
    document.querySelector('.window__close').addEventListener('click', e => {
      e.preventDefault()
      ModalUtils.closeModals()
    })
  }

  checkValidationForTournamentDetail() {
    const tournament = this.state.tournament
    const isAnyTime = this.state.isAnyTime
    let x = true
    if (!tournament.Name) {
      this.setState({ errorFields: { Name: true }, sliderStep: 0 })
      x = false
      return false
    }
    if (tournament.EventTypeId === EventTypeEnum.Qualifier) {
      if (!tournament.MainTournamentId) {
        this.setState({ errorFields: { MainTournamentId: true }, sliderStep: 0 })
        x = false
        return false
      }
    }
    if (!tournament.TournamentModeId) {
      this.setState({ errorFields: { TournamentModeId: true, sliderStep: 0 } })
      x = false
      return false
    }
    if (!tournament.TournamentStructureId) {
      this.setState({ errorFields: { TournamentStructureId: true, sliderStep: 0 } })
      x = false
      return false
    }

    if (!tournament.VenueTypeId) {
      this.setState({ errorFields: { VenueTypeId: true }, sliderStep: 1 })
      x = false
      return false
    }
    if (!tournament.RegionId) {
      this.setState({ errorFields: { RegionId: true }, sliderStep: 1 })
      x = false
      return false
    }
    if (!tournament.CountryId) {
      this.setState({ errorFields: { CountryId: true }, sliderStep: 1 })
      x = false
      return false
    }
    if (!tournament.ProvinceId) {
      this.setState({ errorFields: { ProvinceId: true }, sliderStep: 1 })
      x = false
      return false
    }
    if (!tournament.CityId) {
      this.setState({ errorFields: { CityId: true }, sliderStep: 1 })
      x = false
      return false
    }
    if (!tournament.TimeZoneId) {
      this.setState({ errorFields: { TimeZoneId: true }, sliderStep: 1 })
      x = false
      return false
    }
    if (tournament.VenueTypeId == 2 && !tournament.VenueName) {
      this.setState({ errorFields: { VenueName: true }, sliderStep: 1 })
      x = false
      return false
    }
    if (tournament.VenueTypeId == 2 && !tournament.VenueLocation) {
      this.setState({ errorFields: { VenueLocation: true }, sliderStep: 1 })
      x = false
      return false
    }
    // if (tournament.VenueTypeId == 2 && !tournament.VenueSeatingCapacity) {
    //   this.setState({ errorFields: { VenueSeatingCapacity: true, sliderStep: 1 } })
    //   x = false
    //   return false
    // }
    if (!tournament.ContactEmail && !tournament.DiscordLink) {
      this.setState({ errorFields: { ContactEmailAndDiscordLink: true }, sliderStep: 1 })
      x = false
      return false
    }
    if (!tournament.GameId) {
      this.setState({ errorFields: { GameId: true }, sliderStep: 2 })
      x = false
      return false
    }
    if (tournament.TournamentConsoles.length === 0) {
      this.setState({ errorFields: { Consoles: true }, sliderStep: 2 })
      x = false
      return false
    }
    if (tournament.TournamentStructureId === 4 && !tournament.ParticipientsPerMatch) {
      this.setState({ errorFields: { ParticipientsPerMatch: true }, sliderStep: 2 })
      x = false
      return false
    }
    if (tournament.TournamentStructureId !== 4 && !tournament.MaxNumberOfParticipients) {
      this.setState({ errorFields: { MaxNumberOfParticipients: true }, sliderStep: 2 })
      x = false
      return false
    }
    if (tournament.TournamentModeId === 2 && !tournament.PlayersPerClan) {
      this.setState({ errorFields: { PlayersPerClan: true }, sliderStep: 2 })
      x = false
      return false
    }
    if (!tournament.NumberOfPlacements) {
      this.setState({ errorFields: { NumberOfPlacements: true }, sliderStep: 2 })
      x = false
      return false
    }
    if (tournament.EventTypeId === 2 && tournament.TournamentStructureId !== TournamentStructureEnum.SingleRoundFfa && !tournament.NumberOfQualifiersMoveForward) {
      this.setState({ errorFields: { NumberOfQualifiersMoveForward: true }, sliderStep: 2 })
      x = false
      return false
    }
    const tournamentDetail = tournament.TournamentDetails[0]
    if (!tournament.IsTicketsAdded && !tournamentDetail.RegistrationOpensOn && tournament.EventTypeId !== EventTypeEnum.Main) {
      this.setState({ errorFields: { RegistrationOpensOn: true }, sliderStep: 2 })
      x = false
      return false
    } else if (!tournament.IsTicketsAdded && tournamentDetail.RegistrationOpensOn.getTime() < new Date().getTime() && tournament.EventTypeId !== EventTypeEnum.Main) {
      this.setState({ errorFields: { InvalidRegistrationOpensOn: true }, sliderStep: 2 })
      x = false
      return false
    }
    if (!tournament.IsTicketsAdded && !tournamentDetail.RegistrationClosesOn) {
      this.setState({ errorFields: { RegistrationClosesOn: true }, sliderStep: 2 })
      x = false
      return false
    } else if (!tournament.IsTicketsAdded && tournamentDetail.RegistrationClosesOn.getTime() < tournamentDetail.RegistrationOpensOn.getTime()) {
      this.setState({ errorFields: { InvalidRegistrationClosesOn: true }, sliderStep: 2 })
      x = false
      return false
    }
    if (!tournament.IsTicketsAdded && !tournamentDetail.StartDate) {
      this.setState({ errorFields: { StartDate: true }, sliderStep: 2 })
      x = false
      return false
    } else if (!tournament.IsTicketsAdded &&
      tournamentDetail.StartDate.getTime() < tournamentDetail.RegistrationOpensOn.getTime() ||
      tournamentDetail.StartDate.getTime() < tournamentDetail.RegistrationClosesOn.getTime()
    ) {
      this.setState({ errorFields: { InvalidStartDate: true }, sliderStep: 2 })
      x = false
      return false
    }
    if (!tournament.IsTicketsAdded && tournament.EventTypeId === EventTypeEnum.Qualifier && !isAnyTime &&
      tournamentDetail.StartDate.getTime() >= new Date(this.state.mainTournamentDate).getTime()) {
      this.setState({ errorFields: { InvalidStartDate1: true }, sliderStep: 2 })
      x = false
      return false
    }
    if (
      (tournament.RepeatTypeId === RepeatTypeEnum.EveryDay && tournament.TournamentRepeatSettings.length === 0) ||
      (tournament.RepeatTypeId === RepeatTypeEnum.EveryHour && !tournament.RepeatHoursInterval)
    ) {
      this.setState({ errorFields: { TournamentRepeatSettings: true } })
      x = false
      return false
    }
    const minDate = new Date(tournamentDetail.StartDate)
    const errorFields = {}

    for (let i = 0; i < tournamentDetail.TournamentRounds.length; i++) {
      if (isAnyTime) {
        if (!tournament.IsTicketsAdded && !tournamentDetail.TournamentRounds[i].PlayDateTime) {
          errorFields[`TournamentRounds${i}`] = true
          this.setState({ errorFields })
          x = false
          return false
        }
        if (!tournament.IsTicketsAdded &&tournamentDetail.TournamentRounds[i].PlayDateTime) {
          if (
            new Date(fullFormatDate(tournamentDetail.TournamentRounds[i].PlayDateTime))

            >= new Date(fullFormatDate(this.state.mainTournamentDate)) &&
            tournament.EventTypeId === EventTypeEnum.Qualifier) {
            errorFields[`TournamentRoundsMain${i}`] = true
            this.setState({ errorFields })
            return false
          }
          if (new Date(fullFormatDate(tournamentDetail.TournamentRounds[i].PlayDateTime)) < new Date(fullFormatDate(minDate))) {
            errorFields[`InvalidTournamentRounds${i}`] = true
            this.setState({ errorFields })
            return false
          }
        }
      }
      else {
        if (!tournamentDetail.TournamentRounds[i].PlayDateTime || !tournamentDetail.TournamentRounds[i].ResultSubmissionDeadline) {
          errorFields[`TournamentRounds${i}`] = true
          this.setState({ errorFields })
          x = false
          return false
        }
        if (!tournament.IsTicketsAdded && tournamentDetail.TournamentRounds[i].PlayDateTime) {
          if ((new Date(tournamentDetail.TournamentRounds[i].PlayDateTime).getTime() +
            1000 *
            60 * (tournamentDetail.TournamentRounds[i].ResultSubmissionDeadline
              ? tournamentDetail.TournamentRounds[i].ResultSubmissionDeadline
              : 30))
            >= new Date(this.state.mainTournamentDate).getTime() &&
            tournament.EventTypeId === EventTypeEnum.Qualifier) {
            errorFields[`TournamentRoundsMain${i}`] = true
            this.setState({ errorFields })
            x = false
            return false
          }
          if (new Date(tournamentDetail.TournamentRounds[i].PlayDateTime).getTime() < minDate.getTime()) {
            errorFields[`InvalidTournamentRounds${i}`] = true
            this.setState({ errorFields })
            x = false
            return false
          } else {
            minDate.setTime(
              new Date(tournamentDetail.TournamentRounds[i].PlayDateTime).getTime() +
              1000 *
              60 *
              (tournamentDetail.TournamentRounds[i].ResultSubmissionDeadline
                ? tournamentDetail.TournamentRounds[i].ResultSubmissionDeadline
                : 30)
            )
          }
        }
      }
    }
    return x
  }

  async saveTournament() {
    if ((this.state.userCountry === undefined || this.state.userCountry === '') &&
      (this.state.userCountryCode === undefined || this.state.userCountryCode === '')
    ) {
      this.setLocation(true)
      return false
    }

    if (this.state.coverMediaType === MediaType.Video) {
      if (!this.state.coverId) {
        alert('Please wait until the video is uploaded')
        return
      }
    }
    localStorage.setItem('IsPendingSaveTournament', "true")
    const { tournament, tournamentDto, isResumeTournament, resumeCoverId, resumeTicketImageId,TournamentTicketSection,LayOutTypeId } = this.state
    const isMainEvent = tournament.EventTypeId === EventTypeEnum.Main
    const tournamentDetails = tournament.TournamentDetails.map<KlTournamentDetailViewModel>(x => ({
      ...x,
      StartDate: GeneralUtils.getDateString(x.StartDate),
      RegistrationOpensOn: isMainEvent ? null : GeneralUtils.getDateString(x.RegistrationOpensOn),
      RegistrationClosesOn: GeneralUtils.getDateString(x.RegistrationClosesOn),
      TournamentRounds: x.TournamentRounds.map<klTournamentRoundsViewModel>(tr => ({
        ...tr,
        PlayDateTime: tournament.IsManualControl && this.state.isAnyTime ? GeneralUtils.getDateString(new Date(fullFormatDate(tr.PlayDateTime))) : GeneralUtils.getDateString(new Date(tr.PlayDateTime)),
        ResultSubmissionDeadline: tournament.IsManualControl && this.state.isAnyTime ? null : tr.ResultSubmissionDeadline
      })),
      TournamentTickets: x.TournamentTickets.map<klTournamentTicketsViewModel>(tt => ({
        ...tt,
        TicketDate: tt.TicketDate ? GeneralUtils.getDateString(new Date(tt.TicketDate)) : null,
        
      })),
      IsAnyTime: this.state.isAnyTime,
      TournamentTicketSection:TournamentTicketSection,
      LayOutTypeId:LayOutTypeId
    }))
    if (this.checkValidationForTournamentDetail()) {
      tournament.TicketImageId = isResumeTournament && resumeTicketImageId === tournament.TicketImageId ? null : tournament.TicketImageId
      const newTournament: KlTournamentModel = {
        ...tournament,
        TournamentRepeatSettings: tournament.TournamentRepeatSettings.map<string>(rs => GeneralUtils.getDateString(rs)),
        TournamentDetails: tournamentDetails,
        CoverId: isResumeTournament && resumeCoverId === this.state.tournament.CoverId ? null : this.state.tournament.CoverId,
      }
      if (this.state.coverMediaType === MediaType.Video) {
        newTournament.CoverId = isResumeTournament && resumeCoverId === this.state.coverId ? null : this.state.coverId
      }
      tournamentDto.tournamentData = newTournament

      if (this.props.tournamentDetailId && !isResumeTournament) {
        const result = await TournamentUtils.UpdateTournament(tournamentDto.tournamentData)
        if (!result.isError) {
          if (result.data > 0) {
            localStorage.removeItem(APP_NAMESPACE)
            localStorage.removeItem("DefaultLayOut")
            const uploadPromises = []
            if (this.state.cover && this.state.coverMediaType !== MediaType.Video) {
              uploadPromises.push(uploadTournamentCoverImage(tournament.Id, this.state.cover))
            }
            if (this.state.ticketImage) {
              uploadPromises.push(uploadTournamentTicketImage(tournament.Id, this.state.ticketImage))
            }
            await Promise.all(uploadPromises)
            this.setState({ isSaveTournament: true })
            localStorage.removeItem('IsPendingSaveTournament')
            localStorage.removeItem('DraftTournament')
          }
        } else {
          localStorage.removeItem('IsPendingSaveTournament')
          GeneralUtils.showError(result.message)
        }
      }
      else {
        tournamentDto.tournamentData.OrganizerCountry = this.state.userCountry
        const result = await TournamentUtils.SaveTournament(tournamentDto.tournamentData)
        if (!result.isError) {
          if (result.data > 0) {
            localStorage.removeItem(APP_NAMESPACE)
            localStorage.removeItem("DefaultLayOut")
            const uploadPromises = []
            if (this.state.cover && this.state.coverMediaType !== MediaType.Video) {
              uploadPromises.push(uploadTournamentCoverImage(result.data, this.state.cover))
            }
            if (this.state.ticketImage) {
              uploadPromises.push(uploadTournamentTicketImage(result.data, this.state.ticketImage))
            }

            if (isResumeTournament) {
              const isUploadCover = resumeCoverId === this.state.coverId ? true : false
              const isUploadTicketImageCover = this.state.ticketImage ? false : true
              if (isUploadCover === true || isUploadTicketImageCover === true) {
                const isSave = await TournamentUtils.SaveCoverFromExistingBlob(this.state.resumeTournamentId, result?.data, isUploadCover, isUploadTicketImageCover)
              }
            }

            await Promise.all(uploadPromises)
            this.setState({ isSaveTournament: true })
            localStorage.removeItem('DraftTournament')
            localStorage.removeItem('IsPendingSaveTournament')
          }
        } else {
          localStorage.removeItem('IsPendingSaveTournament')
          GeneralUtils.showError(result.message)
        }
      }
    }
  }

  async saveAsDraft() {
    const user: UserModel = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : Object()
    await TournamentUtils.CreateDraftTournament({
      TournamentJson: JSON.stringify({
        ...this.state,
        cover: undefined,
        coverDataUrl: undefined,
        coverMediaType: undefined,
        initialCover: undefined,
        ticketImage: undefined,
        initialTicketImage: undefined,
      }),
    })
    GeneralUtils.showSuccess('Tournament saved as Draft', 3000)
    setTimeout(() => {
      this.setState({ cancelCreation: true })
    }, 2000)
  }

  isOrganizerVerified(data, result) {
    const user: UserModel = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : Object()

    this.setState(
      {
        showDialouge: false,
      },
      () => {
        user.IsVerified = false
        if (result !== null) {
          user.IsVerified = result.PaymentMethod == 2 ? true : false
          const { organizerPayout } = this.state
          organizerPayout.PaymentMethodId = result.PaymentMethod
          this.setState({ organizerPayout })
        }
        localStorage.setItem('user', JSON.stringify(user))
        if (data && data.hasOwnProperty('AccountLinkUrl') && data.AccountLinkUrl !== '') {
          user.Stripe_IdentityUrl = data.AccountLinkUrl
          localStorage.setItem('user', JSON.stringify(user))
          window.location.href = data.AccountLinkUrl
        }
      }
    )
  }

  async _getOrganizerPayout() {
    const user: UserModel = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : Object()
    if (user.id && user.isorganizer) {
      const res = await BillingOptionUtils.getOrganizerPayout()
      if (res.data) {
        this.setState({
          organizerPayout: res.data,
          organizerCurrency:res.data.CurrencyCode === ''?'USD':res.data.CurrencyCode
        })
      }
      
    }
  }

  navigationWithStep(step: number) {
    if (this.props.tournamentDetailId) {
      window.history.replaceState(
        null,
        '',
        location.href.indexOf('/admin/') >= 0 ? `/admin/edit-tournament/step${++step}` : `/edit-tournament/step${++step}`
      )
    }
    else {
      window.history.replaceState(
        null,
        '',
        location.href.indexOf('/admin/') >= 0 ? `/admin/create-tournament/step${++step}` : `/create-tournament/step${++step}`
      )
    }
  }

  handleCoverImageLoaded = (image: ImageType) => {
    this.setState({
      initialCover: image,
    })
  }

  handleTicketImageLoaded = (image: ImageType) => {
    this.setState({ initialTicketImage: image })
  }

  handleCoverChange = (callback: CoverUploaderCallback, file: File, dataUrl?: string, type?: MediaType) => {
    this.setState({
      cover: file,
      initialCover: undefined,
      coverDataUrl: dataUrl,
      coverMediaType: type,
      coverId: this.state.initialCover && this.state.initialCover?.Id === this.state.coverId ? this.state.coverId : undefined,
    }, () => {
      void callback(file, type)
    })
  }

  handleCoverUploaded = (coverId: number) => {
    this.setState({ coverId })
  }

  handleTicketImageChange = (file: File) => {
    this.setState({
      ticketImage: file,
      initialTicketImage: undefined,
    })
  }

  showStateDailog = (show: boolean) => {
    this.setState({
      isShowStateDailog: show
    })
  }

  async saveState(stateModel: kl_StateModel) {
    const result = await CountryUtils.AddState(stateModel)
    if (!result.isError && result.data) {
      const { states, tournament } = this.state
      states.push(result.data)
      tournament.ProvinceId = result.data.Id
      this.showStateDailog(false)
      this.handleTournamentSelectChange('ProvinceId', tournament.ProvinceId)
    }
    else {
      GeneralUtils.showError(result.message)
    }
  }

  showCityDailog = (show: boolean) => {
    this.setState({
      isShowCityDailog: show
    })
  }

  async saveCity(city: kl_CityAddModel) {
    const result = await CountryUtils.AddCity(city)
    if (!result.isError && result.data) {
      const { cities, tournament } = this.state
      cities.push(result.data)
      tournament.CityId = result.data.Id
      this.showCityDailog(false)
    }
    else {
      GeneralUtils.showError(result.message)
    }
  }

  render() {
    const isorganizer = (localStorage.getItem('isorganizer'))
    if (this.state.isSaveTournament) {
      return <Redirect push to={location.href.indexOf('/admin/') != -1 ? '/admin/tournaments/upcoming' : isorganizer == '1' ? '/events/upcoming' : '/tournaments'} />
    }
    const { mainTournament } = this.state
    if (this.state.cancelCreation) {
      const { isDraftTournament, isCanceledTournament} = this.state
      return <Redirect
        push to={location.href.indexOf('/admin/') != -1 ?
          isCanceledTournament ? '/admin/tournaments/canceled' : '/admin/tournaments' :
          isorganizer == '1' ?
            isDraftTournament ? '/events/draft' :
              isCanceledTournament ? '/events/canceled' : '/events/upcoming' : '/tournaments'}
      />
    } else if (this.state.upgradetoPremium) {
      localStorage.setItem('returnURL', '/create-tournament')
      return <Redirect push to="/upgrade-account" />
    }

    const { tournament, isEditTournament, isShowStateDailog, isShowCityDailog, mainTournamentsList } = this.state
    const errors = this.state.errorFields ? this.state.errorFields : Object()
    const isAdmin = window.location.href.indexOf('admin') > -1 ? true : false

    const user = getUser()

    return (
      <>
        <TournamentImageLoader imageId={tournament.CoverId} onImageLoaded={this.handleCoverImageLoaded} />
        <TournamentImageLoader imageId={tournament.TicketImageId} onImageLoaded={this.handleTicketImageLoaded} />
        <div>
          <div className={`${isAdmin ? 'adm adm_content' : 'article-comp'}`} id={`${isAdmin ? 'adm' : 'article-comp'}`}>
            <div className={`${isAdmin ? '' : 'article'}`} id={`${isAdmin ? '' : 'article'}`}>
              <div className="p-r-sm-5">
                <div className="container-fluid h-100">
                  <div className="row vh100">
                    <div className="col-12 pb-0 col-sm-12 col-md-6 col-lg-8 col-xl-8 article__article position-relative maincol-tournament-createx d-md-block d-lg-block d-xl-block">
                      <div className="row flex-column m-0 h-100 flex-nowrap">
                        <>
                          <div className="row justify-content-between article__header-container">
                            <div className="col p-0 p-r-20">
                              {!(user.IsPremium || this.props.isAdmin) && <h1 className="article__header">Create Tournament</h1>}
                              {(user.IsPremium || this.props.isAdmin) && <h1 className="admin_article__header">Create Tournament</h1>}
                            </div>
                            {/* {!(user.IsPremium || this.props.isAdmin) && (
                              <div className="col-auto p-0">
                                <button
                                  className="pink-button pink-button--filled article__go-to-premium"
                                  onClick={() => {
                                    this.openPremiumModal()
                                  }}
                                >
                                  go to premium
                                </button>
                              </div>
                            )} */}
                            <div className="col-auto p-0">
                              <button
                                className="red-button red-button--filled article__cancel"
                                onClick={() => {
                                  this.cancelTournamentCreation()
                                }}
                              >
                                cancel creation
                              </button>
                            </div>
                          </div>
                        </>
                        <div>
                          <div className="article__slider-container ct__article__slider-container">
                            <div
                              className="article__slides ct__article__slides"
                              style={{
                                transition: 'all 300ms ease 0s',
                                left: `${(this.state.sliderStep ? this.state.sliderStep : 0) * -100}%`,
                              }}
                            >
                              <div
                                className="article__container ct__article__container"
                                style={this.state.sliderStep != 0 ? { overflow: 'hidden', maxHeight: '100px' } : {}}
                              >
                                <TournamentSetup
                                  readOnly={isEditTournament}
                                  isPremium={user.IsPremium || this.props.isAdmin}
                                  name={tournament.Name}
                                  mode={tournament.TournamentModeId}
                                  eventTypeId={tournament.EventTypeId}
                                  structure={tournament.TournamentStructureId}
                                  isAutoResize={tournament.AutomaticBracketResizing}
                                  mainTournamentsList={mainTournamentsList}
                                  mainTournamentId={tournament.MainTournamentId}
                                  onChangeMainTournament={value => {
                                    void this.handleTournamentSelectChange('MainTournamentId', value)
                                  }}
                                  onSaveInDarft={() => {
                                    void this.saveAsDraft()
                                  }}
                                  onMoveToStep={() => {
                                    void this.moveToStep(1)
                                  }}
                                  onChengeAutoResize={value => {
                                    this.handleTournamentDataChange('AutomaticBracketResizing', value)
                                  }}
                                  onChangeMode={value => {
                                    this.handleTournamentDataChange('TournamentModeId', TournamentModeEnum[value])
                                  }}
                                  onChangeName={value => {
                                    this.handleTournamentDataChange('Name', value)
                                  }}
                                  onChangeEventType={value => {
                                    void this.handleTournamentSelectChange('EventTypeId', value.Id)
                                  }}
                                  onChangeStructure={value => {
                                    void this.handleTournamentStructureChange('TournamentStructureId', TournamentStructureEnumByName[value])
                                  }}
                                  onOpenUpgradeToPremium={() => { this.openPremiumModal() }}
                                />
                              </div>
                              <div
                                className="article__container ct__article__container"
                                style={this.state.sliderStep != 1 ? { overflow: 'hidden', maxHeight: '100px' } : {}}
                              >
                                <div className="row m-0">
                                  <div className="article__subheader ct__article__subheader">Venue</div>
                                </div>
                                <input
                                  className="article__checkbox"
                                  id="online"
                                  type="radio"
                                  name="event_type"
                                  value="online"
                                  data-name="venue"
                                  checked={tournament.VenueTypeId == 1}
                                  onChange={e => {
                                    this.handleTournamentDataChange('VenueTypeId', 1)
                                  }}
                                />
                                <input
                                  className="article__checkbox"
                                  id="live"
                                  type="radio"
                                  name="event_type"
                                  value="live"
                                  data-name="venue"
                                  checked={tournament.VenueTypeId == 2}
                                  onChange={e => {
                                    this.handleTournamentDataChange('VenueTypeId', 2)
                                  }}
                                />
                                <div className={'row m-0' + (isEditTournament ? ' disabled ' : '')}>
                                  <div className="col-sm-auto col-6 p-0 p-r-8">
                                    <label
                                      className={
                                        'article__width-1 ct__article__width-1 article__height-2 article__label article__label--online icon-svg' +
                                        (errors.VenueTypeId ? ' highlight-invalid-control' : '')
                                      }
                                      onClick={() => {
                                        this.handleTournamentDataChange('VenueTypeId', 1)
                                      }}
                                    >
                                      <svg className="article__label-icon ct__article__label-icon" width="33" height="30">
                                        <path className="path-fill-grey-2" d="M10.2461 22.0869H22.3381V29.0017H10.2461V22.0869Z" fill="#7C8495" />
                                        <path
                                          className="path-fill-light-grey-2"
                                          d="M16.2949 22.0869H22.3399V29.0017H16.2949V22.0869Z"
                                          fill="#727989"
                                        />
                                        <path
                                          className="path-fill-white-2"
                                          d="M0.955078 0.952148H31.6322V19.2257H0.955078V0.952148Z"
                                          fill="#D6D9DF"
                                        />
                                        <path
                                          className="path-fill-dark-white-2"
                                          d="M16.2949 0.952148H31.6324V19.2257H16.2949V0.952148Z"
                                          fill="#B0B5BF"
                                        />
                                        <path
                                          className="path-fill-grey-2"
                                          d="M12.4461 14.663H4.77344V13.6378C4.77344 11.5218 6.4911 9.80664 8.60978 9.80664C10.7287 9.80664 12.4461 11.5218 12.4461 13.6378V14.663Z"
                                          fill="#7C8495"
                                        />
                                        <path
                                          className="path-fill-grey-2"
                                          d="M10.7243 7.69316C10.7243 8.85982 9.77732 9.80527 8.60909 9.80527C7.44111 9.80527 6.49414 8.85982 6.49414 7.69316C6.49414 6.52675 7.44111 5.58105 8.60909 5.58105C9.77732 5.58105 10.7243 6.52675 10.7243 7.69316Z"
                                        />
                                        <path
                                          className="path-fill-black-2"
                                          d="M27.8133 14.663H20.1406V13.6378C20.1406 11.5218 21.858 9.80664 23.977 9.80664C26.0957 9.80664 27.8133 11.5218 27.8133 13.6378V14.663Z"
                                          fill="#00C8C8"
                                        />
                                        <path
                                          className="path-fill-black-2"
                                          d="M26.0915 7.69316C26.0915 8.85982 25.1445 9.80527 23.9765 9.80527C22.8083 9.80527 21.8613 8.85982 21.8613 7.69316C21.8613 6.52675 22.8083 5.58105 23.9765 5.58105C25.1445 5.58105 26.0915 6.52675 26.0915 7.69316Z"
                                          fill="#00C8C8"
                                        />
                                        <path
                                          className="path-fill-grey-2"
                                          d="M32.5862 0H0V23.9936H32.5862V0ZM1.9091 18.2736V1.90653H30.6771V18.2736H1.9091Z"
                                          fill="#7C8495"
                                        />
                                        <path
                                          className="path-fill-light-grey-2"
                                          d="M32.587 0H16.2949V1.90653H30.6779V18.2736H16.2949V23.9936H32.587V0Z"
                                          fill="#727989"
                                        />
                                        <path className="path-fill-grey-2" d="M6.76758 28.0498H25.8173V29.9566H6.76758V28.0498Z" fill="#7C8495" />
                                        <path
                                          className="path-fill-light-grey-2"
                                          d="M16.2949 28.0498H25.8188V29.9566H16.2949V28.0498Z"
                                          fill="#727989"
                                        />
                                        <path
                                          className="path-fill-light-grey-2"
                                          d="M27.8133 14.664H20.1406V13.6388C20.1406 11.5227 21.858 9.80762 23.977 9.80762C26.0957 9.80762 27.8133 11.5227 27.8133 13.6388V14.664Z"
                                          fill="#727989"
                                        />
                                        <path
                                          className="path-fill-light-grey-2"
                                          d="M26.0915 7.69316C26.0915 8.85982 25.1445 9.80527 23.9765 9.80527C22.8083 9.80527 21.8613 8.85982 21.8613 7.69316C21.8613 6.52675 22.8083 5.58105 23.9765 5.58105C25.1445 5.58105 26.0915 6.52675 26.0915 7.69316Z"
                                          fill="#727989"
                                        />
                                      </svg>
                                      <span className="article__label-text">Online event</span>
                                      <span className="article__label-director" />
                                    </label>
                                  </div>
                                  <div className="col-sm-auto col-6 p-0 p-l-8">
                                    <label
                                      className={
                                        'article__width-1 ct__article__width-1 article__height-2 article__label article__label--live' +
                                        (errors.VenueTypeId ? ' highlight-invalid-control' : '')
                                      }
                                      onClick={() => {
                                        this.handleTournamentDataChange('VenueTypeId', 2)
                                      }}
                                    >
                                      <svg className="article__label-icon ct__article__label-icon" width="31" height="30">
                                        <path
                                          className="path-fill-dark-grey"
                                          d="M24.5096 1.80046L21.9093 0C21.3887 0.569985 20.5144 0.681943 19.8622 0.230339L6.83203 19.0534C7.48426 19.505 7.68723 20.3629 7.33702 21.0508L9.93733 22.8512C10.4579 22.2813 11.3322 22.1693 11.9844 22.6209C12.6366 23.0725 12.8396 23.9303 12.4894 24.6182L15.0897 26.4187C15.6102 25.8487 16.4845 25.7368 17.1368 26.1884L30.1669 7.36519C29.5147 6.91359 29.3117 6.05576 29.6619 5.36786L27.0616 3.5674C26.5411 4.13738 25.6668 4.24934 25.0146 3.79774C24.3624 3.34619 24.1594 2.4883 24.5096 1.80046Z"
                                        />
                                        <path
                                          className="path-fill-light-grey"
                                          d="M15.1055 13.129L17.2877 13.7836L18.5845 11.9102L18.6364 14.1881L20.8187 14.8428L18.6684 15.596L18.7203 17.8739L17.3395 16.0615L15.1894 16.8148L16.4862 14.9414L15.1055 13.129Z"
                                        />
                                        <path
                                          className="path-fill-black"
                                          d="M14.5392 26.038L19.4692 5.02608L17.9285 4.66451L18.0594 3.30375L17.7803 3.23828L6.83203 19.0537C7.48426 19.5053 7.68723 20.3632 7.33702 21.0511L9.93739 22.8515C10.4579 22.2815 11.3322 22.1696 11.9845 22.6212C12.6367 23.0728 12.8397 23.9306 12.4894 24.6185L14.5392 26.038Z"
                                        />
                                        <path
                                          className="path-fill-dark-grey"
                                          d="M10.129 3.18354L7.05001 2.46094C6.77445 3.18195 6.00191 3.6065 5.22966 3.42525L0 25.7139C0.772245 25.8951 1.27541 26.619 1.20152 27.3874L4.28055 28.11C4.55611 27.389 5.32865 26.9644 6.10089 27.1457C6.87314 27.327 7.3763 28.0508 7.30242 28.8192L10.3814 29.5418C10.657 28.8208 11.4295 28.3963 12.2018 28.5775L17.4313 6.28896C16.6591 6.10777 16.1559 5.38387 16.2298 4.61542L13.1508 3.89282C12.8753 4.61383 12.1027 5.03839 11.3305 4.85714C10.5582 4.67576 10.0551 3.95198 10.129 3.18354Z"
                                        />
                                        <path
                                          className="path-fill-grey"
                                          d="M5.4579 7.41159C5.41949 7.41159 5.38037 7.40717 5.34131 7.39804L4.35136 7.1657C4.07798 7.10159 3.90835 6.82794 3.97257 6.55452C4.03667 6.28117 4.31029 6.11146 4.58367 6.17569L5.57362 6.40804C5.847 6.47215 6.01663 6.7458 5.95241 7.01921C5.89755 7.25344 5.68857 7.41159 5.4579 7.41159Z"
                                        />
                                        <path
                                          className="path-fill-grey"
                                          d="M13.6363 9.32956C13.5979 9.32956 13.5588 9.32514 13.5197 9.316L11.4753 8.83618C11.2019 8.77207 11.0323 8.49842 11.0965 8.225C11.1606 7.95165 11.434 7.78194 11.7076 7.84617L13.752 8.326C14.0254 8.39011 14.195 8.66376 14.1308 8.93717C14.0759 9.17146 13.867 9.32956 13.6363 9.32956ZM9.54753 8.37002C9.50911 8.37002 9.47005 8.3656 9.43093 8.35647L7.38651 7.87664C7.11313 7.81253 6.9435 7.53888 7.00773 7.26546C7.07183 6.99205 7.34533 6.82241 7.61883 6.88663L9.66324 7.36646C9.93662 7.43057 10.1062 7.70422 10.042 7.97763C9.98712 8.21192 9.77825 8.37002 9.54753 8.37002Z"
                                        />
                                        <path
                                          className="path-fill-grey"
                                          d="M16.6708 10.0425C16.6324 10.0425 16.5933 10.038 16.5542 10.0289L15.5642 9.79656C15.2909 9.73244 15.1212 9.4588 15.1855 9.18538C15.2496 8.91203 15.5232 8.74232 15.7966 8.80655L16.7865 9.03889C17.0599 9.10301 17.2295 9.37665 17.1653 9.65007C17.1104 9.88435 16.9015 10.0425 16.6708 10.0425Z"
                                        />
                                        <path
                                          className="path-fill-white"
                                          opacity="0.4"
                                          d="M5.5293 17.1685L7.79954 16.978L8.32009 14.7598L9.20269 16.8603L11.473 16.6699L9.74821 18.1586L10.6308 20.2592L8.6822 19.0786L6.95742 20.5673L7.47791 18.349L5.5293 17.1685Z"
                                        />
                                        <path
                                          className="path-fill-grey"
                                          d="M18.7992 4.0569C18.6993 4.0569 18.5985 4.02761 18.5102 3.96645L17.6742 3.38756C17.4433 3.2277 17.3857 2.91092 17.5455 2.68005C17.7054 2.44912 18.0222 2.39155 18.253 2.55141L19.089 3.13029C19.3199 3.29016 19.3775 3.60694 19.2176 3.83781C19.1189 3.98041 18.9604 4.0569 18.7992 4.0569Z"
                                        />
                                        <path
                                          className="path-fill-grey"
                                          d="M25.7052 8.83978C25.6053 8.83978 25.5045 8.81043 25.4162 8.74933L23.6897 7.55385C23.4588 7.39399 23.4012 7.0772 23.5611 6.84639C23.7209 6.61546 24.0377 6.55789 24.2685 6.71776L25.9951 7.91324C26.226 8.0731 26.2835 8.38988 26.1237 8.62069C26.0249 8.76323 25.8664 8.83978 25.7052 8.83978ZM22.2521 6.44882C22.1523 6.44882 22.0515 6.41948 21.9632 6.35837L20.2367 5.16289C20.0058 5.00303 19.9482 4.68625 20.108 4.45544C20.2679 4.22451 20.5847 4.16694 20.8155 4.3268L22.542 5.52228C22.7729 5.68214 22.8305 5.99893 22.6706 6.2298C22.5719 6.37234 22.4133 6.44882 22.2521 6.44882Z"
                                        />
                                        <path
                                          className="path-fill-grey"
                                          d="M28.2679 10.6135C28.168 10.6135 28.0672 10.5842 27.979 10.5231L27.1429 9.9442C26.912 9.78434 26.8544 9.46756 27.0143 9.23669C27.1741 9.00576 27.4909 8.94819 27.7217 9.10805L28.5578 9.68694C28.7887 9.8468 28.8462 10.1636 28.6864 10.3945C28.5877 10.537 28.4291 10.6135 28.2679 10.6135Z"
                                        />
                                      </svg>
                                      <span className="article__label-text">Live event</span>
                                      <span className="article__label-director" />
                                    </label>
                                  </div>
                                </div>
                                <div className={'row article__error error error--venue' + (errors.VenueTypeId ? ' error-visible' : '')}>
                                  <div className="error__icon" />
                                  <div className="error__text">Please select Tournament venue</div>
                                </div>
                                {/* <div className="article__width-2 p-r-35 d-flex flex-column">
                                  <div className="article__subheader ct__article__subheader">Select Event Type</div>
                                  {(user.IsPremium || this.props.isAdmin) && (
                                    <SelectComponent
                                      fieldId="EventTypeId"
                                      data={EventTypeList}
                                      textField="EventType"
                                      valueField="Id"
                                      selectedValue={tournament.EventTypeId}
                                      onSelectionChange={this.handleTournamentSelectChange}
                                      zIndex={505}
                                      class1="article__select article__select--event-type article__input article__width-2 article__height-1 select ct__select"
                                      class2="article__select--event-type__title select__title-ct ct__select__title-ct"
                                      class3="article__select--event-type__container select__container ct__select__container"
                                      class4="article__select--event-type__option select__option"
                                      isImage={false}
                                      isDisable={isEditTournament ? 'disabled' : ''}
                                    />
                                  )}
                                  {!(user.IsPremium || this.props.isAdmin) && (
                                    <input className="article__input article__width-2 article__height-1" type="text" value="Normal" readOnly />
                                  )}
                                </div>
                                {tournament.EventTypeId == EventTypeEnum.Qualifier && (
                                  <div className="article__width-2 m-r-30 d-flex flex-column">
                                    <div className="article__subheader ct__article__subheader">Select Main Event</div>
                                    <SelectComponent
                                      fieldId="MainTournamentId"
                                      data={mainTournamentsList}
                                      textField="Name"
                                      valueField="Id"
                                      placeholder="Select a tournament"
                                      selectedValue={tournament.MainTournamentId}
                                      onSelectionChange={this.handleTournamentSelectChange}
                                      zIndex={504}
                                      class1="article__select article__select--event-type article__input article__width-2 article__height-1 select ct__select"
                                      class2="article__select--event-type__title select__title-ct ct__select__title-ct"
                                      class3="article__select--event-type__container select__container ct__select__container"
                                      class4="article__select--event-type__option select__option"
                                      isImage={false}
                                      isDisable={isEditTournament ? 'disabled' : ''}
                                    />
                                  </div>
                                )} */}
                                <div className="article__width-2 p-r-35 d-flex flex-column">
                                  <div className="article__subheader ct__article__subheader">Region</div>
                                  <SelectComponent
                                    fieldId="RegionId"
                                    data={this.state.regions}
                                    textField="RegionName"
                                    valueField="Id"
                                    selectedValue={tournament.RegionId}
                                    onSelectionChange={this.handleTournamentSelectChange}
                                    zIndex={503}
                                    placeholder="Select Region"
                                    class1={
                                      'article__select article__select--region article__input article__width-2 article__height-1 select ct__select' +
                                      (errors.RegionId ? ' highlight-invalid-control' : '')
                                    }
                                    class2="article__select--region__title select__title-ct ct__select__title-ct"
                                    class3="article__select--region__container select__container ct__select__container"
                                    class4="article__select--region__option select__option"
                                    isImage={false}
                                  />
                                </div>
                                <div className="article__width-2 p-r-35 d-flex flex-column">
                                  <div className="article__subheader ct__article__subheader">Country</div>
                                  <SelectComponent
                                    fieldId="CountryId"
                                    data={this.state.countries}
                                    textField="CountryName"
                                    valueField="Id"
                                    selectedValue={tournament.CountryId}
                                    onSelectionChange={this.handleTournamentSelectChange}
                                    zIndex={502}
                                    searchable
                                    placeholder="Select Country"
                                    class1={
                                      'article__select article__select--region article__input article__width-2 article__height-1 select ct__select' +
                                      (errors.CountryId ? ' highlight-invalid-control' : '')
                                    }
                                    class2="article__select--region__title select__title-ct ct__select__title-ct"
                                    class3="article__select--region__container select__container ct__select__container"
                                    class4="article__select--region__option select__option"
                                    isImage={false}
                                  />
                                </div>
                                <div className="article__width-2 p-r-35 d-flex flex-column">
                                  <div className="article__subheader ct__article__subheader">State/Province</div>
                                  <div className="state-select-wrapper">
                                    <SelectComponent
                                      fieldId="ProvinceId"
                                      data={this.state.states}
                                      textField="StateName"
                                      valueField="Id"
                                      selectedValue={tournament.ProvinceId}
                                      onSelectionChange={this.handleTournamentSelectChange}
                                      zIndex={501}
                                      searchable
                                      placeholder="Select State"
                                      class1={
                                        'article__select article__select--region article__input article__width-2 article__height-1 select ct__select' +
                                        (errors.ProvinceId ? ' highlight-invalid-control' : '')
                                      }
                                      class2="article__select--region__title select__title-ct ct__select__title-ct"
                                      class3="article__select--region__container select__container ct__select__container"
                                      class4="article__select--region__option select__option"
                                      isImage={false}
                                    />
                                    {tournament.CountryId && this.state.states.length === 0 && (
                                      <>
                                        <div className="d-flex flex-row align-items-center plus-button">
                                          <button
                                            className='article__add-custom-field white-button'
                                            onClick={() => {
                                              this.showStateDailog(true)
                                            }}
                                          >
                                          </button>
                                        </div>
                                        <div>
                                          <StateDailog
                                            show={isShowStateDailog}
                                            countryId={tournament.CountryId}
                                            showStateDailog={this.showStateDailog}
                                            saveState={this.saveState}
                                          />
                                        </div>

                                      </>
                                    )}
                                  </div>
                                </div>
                                <div className="article__width-2 p-r-35 d-flex flex-column">
                                  <div className="article__subheader ct__article__subheader">City</div>
                                  <div className="state-select-wrapper">
                                    <SelectComponent
                                      fieldId="CityId"
                                      data={this.state.cities}
                                      textField="CityName"
                                      valueField="Id"
                                      selectedValue={tournament.CityId}
                                      onSelectionChange={this.handleTournamentDataChange}
                                      zIndex={500}
                                      searchable
                                      placeholder="Select City"
                                      class1={
                                        'article__select article__select--region article__input article__width-2 article__height-1 select ct__select' +
                                        (errors.City ? ' highlight-invalid-control' : '')
                                      }
                                      class2="article__select--region__title select__title-ct ct__select__title-ct"
                                      class3="article__select--region__container select__container ct__select__container"
                                      class4="article__select--region__option select__option"
                                      isImage={false}
                                    />
                                    {tournament.ProvinceId && this.state.cities.length === 0 && (
                                      <>
                                        <div className="d-flex article__height-1 plus-button">
                                          <button
                                            className='article__add-custom-field white-button'
                                            onClick={() => {
                                              this.showCityDailog(true)
                                            }}
                                          >
                                          </button>
                                        </div>
                                        <div>
                                          <CityDailog
                                            show={isShowCityDailog}
                                            countryId={tournament.CountryId}
                                            stateId={tournament.ProvinceId}
                                            showCityDailog={this.showCityDailog}
                                            saveCity={this.saveCity}
                                          />
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                                <TimeZone onChange={this.handleTournamentSelectChange} errors={errors} timeZoneId={tournament.TimeZoneId} timeZones={this.state.timeZones} />
                                <div className={'row article__error error error--region' + (errors.RegionId ? ' error-visible' : '')}>
                                  <div className="error__icon" />
                                  <div className="error__text">Please select Region</div>
                                </div>
                                <div className={'row article__error error error--country' + (errors.CountryId ? ' error-visible' : '')}>
                                  <div className="error__icon" />
                                  <div className="error__text">Please select Country</div>
                                </div>
                                <div className={'row article__error error error--city' + (errors.CityId ? ' error-visible' : '')}>
                                  <div className="error__icon" />
                                  <div className="error__text">Please select City</div>
                                </div>
                                <div className={'row article__error error error--city' + (errors.ProvinceId ? ' error-visible' : '')}>
                                  <div className="error__icon" />
                                  <div className="error__text">Please select state</div>
                                </div>
                                <div className={'row article__error error error--time-zone' + (errors.TimeZoneId ? ' error-visible' : '')}>
                                  <div className="error__icon" />
                                  <div className="error__text">Please select Time Zone</div>
                                </div>
                                <div className="row m-0">
                                  <div className="article__subheader ct__article__subheader">Rules</div>
                                </div>
                                <div className="row m-0">
                                  <div
                                    id="textEditorDiv"
                                    className={this.props.isAdmin ? 'admin_article__rule ct__admin_article__rule' : 'article__rules'}
                                  >
                                    <div className="col-12">
                                      <div />
                                    </div>
                                  </div>
                                </div>
                                <div className="article__online-event-container" />
                                {tournament.VenueTypeId === VenueTypeEnum.Online && (
                                  <>
                                    <div className="row m-0 w-100">
                                      <div className="article__big-subheader ct__article__big-subheader">
                                        <span>Location details</span>
                                        <div />
                                      </div>
                                    </div>
                                    <div className="row m-0 m-r--30">
                                      <div className="article__width-2 m-r-30 d-flex flex-column">
                                        <div className="article__subheader ct__article__subheader">
                                          Streaming Link (optional){' '}
                                          <div
                                            className={
                                              this.props.isAdmin
                                                ? 'admin_article__warning-icon ct__admin_article__warning-icon admin_info-icon ct__admin_info-icon'
                                                : 'info-icon ct__info-icon'
                                            }
                                          >
                                            <span className="tooltiptext ct__tooltiptext">
                                              Provide a link for your audience to watch your broadcast
                                          </span>
                                          </div>
                                        </div>
                                        <input
                                          className="article__input article__width-2 article__height-1"
                                          type="text"
                                          placeholder="Streaming Link"
                                          data-name="streamingLink"
                                          value={tournament.StreamingLink || ''}
                                          onChange={e => {
                                            this.handleTournamentDataChange('StreamingLink', e.target.value)
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </>
                                )}
                                <div className="article__live-event-container">
                                  <div className="row m-0 w-100">
                                    <div className="article__big-subheader ct__article__big-subheader">
                                      <span>Location details</span>
                                      <div />
                                    </div>
                                  </div>
                                  <div className="row m-0 m-r--30">
                                    <div className="article__width-2 m-r-30 d-flex flex-column">
                                      <div className="article__subheader ct__article__subheader">Venue name</div>
                                      <input
                                        className={
                                          'article__input article__width-2 article__height-1' + (errors.VenueName ? ' highlight-invalid-control' : '')
                                        }
                                        type="text"
                                        placeholder="Venue name"
                                        data-name="locationName"
                                        value={tournament.VenueName || ''}
                                        onChange={e => {
                                          this.handleTournamentDataChange('VenueName', e.target.value)
                                        }}
                                      />
                                    </div>
                                    <div className="article__width-2 m-r-30 d-flex flex-column">
                                      <div className="article__subheader ct__article__subheader">Venue address</div>
                                      <input
                                        className={
                                          'article__input article__width-2 article__height-1' +
                                          (errors.VenueLocation ? ' highlight-invalid-control' : '')
                                        }
                                        type="text"
                                        placeholder="Venue address"
                                        data-name="venueLocation"
                                        value={tournament.VenueLocation || ''}
                                        onChange={e => {
                                          this.handleTournamentDataChange('VenueLocation', e.target.value)
                                        }}
                                      />
                                    </div>
                                    {/* <div className="article__width-2 m-r-30 d-flex flex-column">
                                      <div className="article__subheader ct__article__subheader">Max seating</div>
                                      <input
                                        className={
                                          'article__input article__width-2 article__height-1' +
                                          (errors.VenueSeatingCapacity ? ' highlight-invalid-control' : '')
                                        }
                                        type="number"
                                        placeholder="50"
                                        data-name="maxSeating"
                                        value={tournament.VenueSeatingCapacity || ''}
                                        onChange={e => {
                                          this.handleTournamentDataChange('VenueSeatingCapacity', parseInt(e.target.value))
                                        }}
                                      />
                                    </div> */}
                                    {tournament.VenueTypeId === VenueTypeEnum.Live && (
                                      <div className="article__width-2 m-r-30 d-flex flex-column">
                                        <div className="article__subheader ct__article__subheader">
                                          Streaming Link (optional){' '}
                                          <div
                                            className={
                                              this.props.isAdmin
                                                ? 'admin_article__warning-icon ct__admin_article__warning-icon admin_info-icon ct__admin_info-icon'
                                                : 'info-icon ct__info-icon'
                                            }
                                          >
                                            <span className="tooltiptext ct__tooltiptext">
                                              Provide a link for your audience to watch your broadcast
                                          </span>
                                          </div>
                                        </div>
                                        <input
                                          className="article__input article__width-2 article__height-1"
                                          type="text"
                                          placeholder="Streaming Link"
                                          data-name="streamingLink"
                                          value={tournament.StreamingLink || ''}
                                          onChange={e => {
                                            this.handleTournamentDataChange('StreamingLink', e.target.value)
                                          }}
                                        />
                                      </div>
                                    )}
                                  </div>
                                  <div className={'row article__error error error--locationName' + (errors.VenueName ? ' error-visible' : '')}>
                                    <div className="error__icon" />
                                    <div className="error__text">Please Enter Location Name</div>
                                  </div>
                                  <div className={'row article__error error error--venueLocation' + (errors.VenueLocation ? ' error-visible' : '')}>
                                    <div className="error__icon" />
                                    <div className="error__text">Please Enter Venue Location</div>
                                  </div>
                                  {/* <div
                                    className={'row article__error error error--maxSeating' + (errors.VenueSeatingCapacity ? ' error-visible' : '')}
                                  >
                                    <div className="error__icon" />
                                    <div className="error__text">Please Enter max Seating</div>
                                  </div>
                                  <div className="row m-0 w-100">
                                    <div className="article__big-subheader ct__article__big-subheader">
                                      <span>Upload ticket image</span>
                                    </div>
                                  </div> */}
                                  {/* <div className="row m-0">
                                    <div className="article__crop-ticket-picture">
                                      <FileSelector
                                        fileTypes={[MediaType.Image]}
                                        onChange={this.handleTicketImageChange}
                                        initialValue={this.state.initialTicketImage}
                                        cropperWidth="100%"
                                        recommendedText="Recommended Image Size: 1300 x 400"
                                      />
                                    </div>
                                  </div> */}
                                </div>
                                <div className="row m-0 w-100">
                                  <div className="article__big-subheader ct__article__big-subheader">
                                    <span style={{ color: 'gray' }}>How players may contact you</span>
                                    <div />
                                  </div>
                                </div>
                                <div className="row m-0 m-r--30">
                                  <div className="article__width-2 m-r-30 d-flex flex-column">
                                    <div className="article__subheader ct__article__subheader">Email</div>
                                    <input
                                      className={
                                        'article__input article__width-2 article__height-1' +
                                        (errors.ContactEmailAndDiscordLink ? ' highlight-invalid-control' : '')
                                      }
                                      type="text"
                                      placeholder="Email"
                                      data-name="email"
                                      value={tournament.ContactEmail || ''}
                                      onChange={e => {
                                        this.handleTournamentDataChange('ContactEmail', e.target.value)
                                      }}
                                    />
                                  </div>
                                  <div className="article__width-2 m-r-30 d-flex flex-column">
                                    <div className="article__subheader ct__article__subheader">Discord link</div>
                                    <input
                                      className={
                                        'article__input article__width-2 article__height-1' +
                                        (errors.ContactEmailAndDiscordLink ? ' highlight-invalid-control' : '')
                                      }
                                      type="text"
                                      placeholder="Link"
                                      data-name="discordLink"
                                      value={tournament.DiscordLink || ''}
                                      onChange={e => {
                                        this.handleTournamentDataChange('DiscordLink', e.target.value)
                                      }}
                                    />
                                  </div>
                                </div>
                                <div
                                  className={
                                    'row article__error error error--discordLink' + (errors.ContactEmailAndDiscordLink ? ' error-visible' : '')
                                  }
                                >
                                  <div className="error__icon" />
                                  <div className="error__text">Please enter the Email or Discord link</div>
                                </div>
                                <div className="row article__buttons-container justify-content-end">
                                  <div className="col p-0">
                                    <button
                                      className="grey-button article__prev-stage"
                                      onClick={e => {
                                        return this.moveToStep(0)
                                      }}
                                    >
                                      back
                                    </button>
                                  </div>
                                  <div className="col-sm-auto col-6 p-0">
                                    <button
                                      className="grey-button grey-button--filled article__save-in-draft ct__article__save-in-draft"
                                      onClick={() => {
                                        this.saveAsDraft()
                                      }}
                                    >
                                      Save in draft
                                    </button>
                                  </div>
                                  <div className="col-sm-auto col-6 p-0">
                                    <button
                                      className="blue-button blue-button--filled article__next-step"
                                      onClick={e => {
                                        if (tournament.VenueTypeId == 1) {
                                          return this.moveToStep(2)
                                        }
                                        else if (tournament.VenueTypeId == 2) {
                                          return this.moveToStep(2)
                                          // if (this.state.ticketImage || this.state.initialTicketImage) {
                                          //   return this.moveToStep(2)
                                          // } else {
                                          //   alert('Please add ticket image!')
                                          // }
                                        }
                                      }}
                                    >
                                      Next step
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="article__container ct__article__container"
                                style={this.state.sliderStep != 2 ? { overflow: 'hidden', maxHeight: '100px' } : {}}
                              >
                                <div className="row m-0 m-r--30">
                                  <div className="article__width-2 m-r-30 d-flex flex-column">
                                    <div className="article__subheader ct__article__subheader">Game</div>
                                    <SelectComponent
                                      fieldId="GameId"
                                      data={this.state.games}
                                      textField="Gamename"
                                      valueField="id"
                                      selectedValue={tournament.GameId}
                                      searchable
                                      onSelectionChange={this.handleTournamentSelectChange}
                                      zIndex={500}
                                      placeholder="Select the game"
                                      class1={
                                        'article__input article__width-2 article__height-1 article__select article__select--game select ct__select' +
                                        (errors.GameId ? ' highlight-invalid-control' : '')
                                      }
                                      class2="article__select--game__title select__title-ct ct__select__title-ct"
                                      class3="article__select--game__container select__container ct__select__container"
                                      class4="article__select--game__option select__option"
                                      isImage={false}
                                    />
                                  </div>
                                  <div className="article__width-2 m-r-30 d-flex flex-column">
                                    <div className="article__subheader ct__article__subheader">Platform</div>
                                    <SelectComponent
                                      fieldId="ConsoleId"
                                      data={ConsoleList}
                                      textField="ConsoleName"
                                      valueField="Id"
                                      selectedValues={this.state.tournament.TournamentConsoles}
                                      multiSelect
                                      onSelectionsChange={this.handleTournamentConsolesChange}
                                      zIndex={499}
                                      placeholder="Select Platform"
                                      class1={
                                        'article__input article__width-2 article__height-1 article__select article__select--consoles select ct__select' +
                                        (errors.Consoles ? ' highlight-invalid-control' : '')
                                      }
                                      class2="article__select--consoles__title select__title-ct ct__select__title-ct"
                                      class3="article__select--consoles__container select__container ct__select__container"
                                      class4="article__select--consoles__option select__option"
                                      isImage={false}
                                    />
                                  </div>
                                  {tournament.TournamentStructureId == 4 && (
                                    <div className="article__width-2 m-r-30 d-flex flex-column article__players-per-match-container">
                                      <div className="article__subheader ct__article__subheader">Players / clans per match</div>
                                      <SelectComponent
                                        fieldId="ParticipientsPerMatch"
                                        textField="id"
                                        data={[{ id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }, { id: 7 }, { id: 8 }, { id: 9 }, { id: 10 }]}
                                        valueField="id"
                                        selectedValue={tournament.ParticipientsPerMatch}
                                        onSelectionChange={this.handleTournamentStructureChange}
                                        zIndex={498}
                                        placeholder="Players / clans count"
                                        class1={
                                          'article__input article__width-2 article__height-1 article__select article__select--players-per-match select ct__select' +
                                          (errors.ParticipientsPerMatch ? ' highlight-invalid-control' : '')
                                        }
                                        class2="article__select--players-per-match__title select__title-ct ct__select__title-ct"
                                        class3="article__select--players-per-match__container select__container ct__select__container"
                                        class4="article__select--players-per-match__option select__option"
                                        isImage={false}
                                      />
                                    </div>
                                  )}
                                  <div className="article__width-2 m-r-30 d-flex flex-column article__players-count-container">
                                    <div className="article__subheader ct__article__subheader">Maximum number of players / clans</div>
                                    {tournament.TournamentStructureId == TournamentStructureEnum.SingleRoundFfa && (
                                      <input
                                        className={
                                          'article__input article__width-2 article__height-1' +
                                          (errors.MaxNumberOfParticipients ? ' highlight-invalid-control' : '')
                                        }
                                        type="number"
                                        placeholder="Maximum number of Participants"
                                        min={2}
                                        max={100}
                                        value={tournament.MaxNumberOfParticipients}
                                        readOnly={isEditTournament ? true : false}
                                        onChange={e => {
                                          this.handleTournamentStructureChange('MaxNumberOfParticipients', e.target.value)
                                        }}
                                      />
                                    )}
                                    {tournament.TournamentStructureId != TournamentStructureEnum.SingleRoundFfa && (
                                      <SelectComponent
                                        fieldId="MaxNumberOfParticipients"
                                        textField="id"
                                        data={this.state.maxNumberOfParticipants}
                                        valueField="id"
                                        selectedValue={tournament.MaxNumberOfParticipients}
                                        onSelectionChange={this.handleTournamentStructureChange}
                                        zIndex={497}
                                        placeholder="Maximum number of Participants"
                                        class1={
                                          'article__input article__width-2 article__height-1 article__select article__select--max-players select  ct__select' +
                                          (errors.MaxNumberOfParticipients ? ' highlight-invalid-control' : '')
                                        }
                                        class2="select-319__title select__title-ct ct__select__title-ct"
                                        class3="select-319__container select__container ct__select__container"
                                        class4="select-319__option select__option"
                                        isImage={false}
                                        isDisable={isEditTournament ? 'disabled' : ''}
                                      />
                                    )}
                                  </div>
                                  {tournament.TournamentModeId == 2 && (
                                    <div className="article__width-2 m-r-30 d-flex flex-column article__clan-members-container">
                                      <div className="article__subheader ct__article__subheader">How many clan members needed to play</div>
                                      <SelectComponent
                                        fieldId="PlayersPerClan"
                                        textField="id"
                                        data={[
                                          { id: 2 },
                                          { id: 3 },
                                          { id: 4 },
                                          { id: 5 },
                                          { id: 6 },
                                          { id: 7 },
                                          { id: 8 },
                                          { id: 9 },
                                          { id: 10 },
                                          { id: 11 },
                                          { id: 12 },
                                          { id: 13 },
                                          { id: 14 },
                                          { id: 15 },
                                          { id: 16 },
                                          { id: 17 },
                                          { id: 18 },
                                          { id: 19 },
                                          { id: 20 },
                                        ]}
                                        valueField="id"
                                        selectedValue={tournament.PlayersPerClan}
                                        onSelectionChange={this.handleTournamentSelectChange}
                                        zIndex={496}
                                        placeholder="Clan members"
                                        isDisable={tournament.EventTypeId == EventTypeEnum.Qualifier && tournament.TournamentModeId == TournamentModeEnum.Clans?'disabled':''}
                                        class1={
                                          'article__input article__width-2 article__height-1 article__select article__select--clan-members select  ct__select' +
                                          (errors.PlayersPerClan ? ' highlight-invalid-control' : '')
                                        }
                                        isDisable={tournament.EventTypeId === EventTypeEnum.Qualifier && tournament.TournamentModeId === TournamentModeEnum.Clans?'disabled':''}
                                        class2="article__select--clan-members__title select__title-ct ct__select__title-ct"
                                        class3="article__select--clan-members__container select__container ct__select__container"
                                        class4="article__select--clan-members__option select__option"
                                        isImage={false}
                                      />

                                    </div>
                                    
                                  )}

                                  <div className="article__width-2 m-r-30 d-flex flex-column">
                                    {tournament.EventTypeId === EventTypeEnum.Qualifier && (
                                      <div className="article__subheader ct__article__subheader">How many participants will be awarded a seat to the Main event</div>
                                    )}

                                    {tournament.EventTypeId !== EventTypeEnum.Qualifier && (
                                      <div className="article__subheader ct__article__subheader">How many winning placements will be available</div>
                                    )}


                                    {tournament.TournamentStructureId == 3 && (
                                      <input
                                        className={
                                          'article__input article__width-2 article__height-1' +
                                          (errors.NumberOfPlacements ? ' highlight-invalid-control' : '')
                                        }
                                        type="number"
                                        placeholder="Number of winning placements"
                                        min={1}
                                        max={tournament.MaxNumberOfParticipients}
                                        value={tournament.NumberOfPlacements}
                                        onChange={e => {
                                          this.handleTournamentDataChange('NumberOfPlacements', parseInt(e.target.value))
                                        }}
                                      />
                                    )}
                                    {tournament.TournamentStructureId != 3 && (
                                      <SelectComponent
                                        fieldId="NumberOfPlacements"
                                        textField="text"
                                        data={this.state.placements}
                                        valueField="id"
                                        selectedValues={Object.assign([], this.state.selectedPlacements)}
                                        onSelectionsChange={this.handlePlacementsChange}
                                        zIndex={495}
                                        placeholder="None"
                                        multiSelect
                                        class1={
                                          'article__input article__width-2 article__height-1 article__select article__select--winning-placements select  ct__select' +
                                          (errors.NumberOfPlacements ? ' highlight-invalid-control' : '')
                                        }
                                        class2="article__select--winning-placements__title select__title-ct ct__select__title-ct"
                                        class3="article__select--winning-placements__container select__container ct__select__container"
                                        class4="article__select--winning-placements__option select__option"
                                        isImage={false}
                                      />
                                    )}
                                  </div>
                                  {tournament.TournamentModeId == 2 && (<div className="article__width-2 m-r-30 d-flex flex-column">
                                    <div className="article__subheader ct__article__subheader">How many substitutes</div>
                                    <input
                                      className={
                                        'article__input article__width-2 article__height-1' +
                                        (errors.NumberOfPlacements ? ' highlight-invalid-control' : '')
                                      }
                                      type="number"
                                      placeholder="Number of substitutes"
                                      min={1}
                                      readOnly={tournament.EventTypeId === EventTypeEnum.Qualifier && tournament.TournamentModeId === TournamentModeEnum.Clans}
                                      value={tournament.NumberOfSubstitutes}
                                      onChange={e => {
                                        this.handleTournamentDataChange('NumberOfSubstitutes', parseInt(e.target.value))
                                      }}
                                    />
                                    
                                  </div>
                                  )}
                                  {tournament.EventTypeId == 2 && tournament.TournamentStructureId != 3 && (
                                    <div className="article__width-2 m-r-30 d-flex flex-column article__amount-of-qualifier-winners-container">
                                      <div className="article__subheader ct__article__subheader">Amount of Qualifier winners moves to Main event</div>
                                      <input
                                        className={
                                          'article__input article__width-2 article__height-1' +
                                          (errors.NumberOfQualifiersMoveForward ? ' highlight-invalid-control' : '')
                                        }
                                        type="number"
                                        placeholder="Amount"
                                        data-name="qualifierWinners"
                                        readOnly
                                        min={1}
                                        max={tournament.MaxNumberOfParticipients}
                                        value={tournament.NumberOfQualifiersMoveForward}
                                        onChange={e => {
                                          this.handleTournamentDataChange('NumberOfQualifiersMoveForward', parseInt(e.target.value))
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                                <div className={'row article__error error error--game' + (errors.GameId ? ' error-visible' : '')}>
                                  <div className="error__icon" />
                                  <div className="error__text">Please select Game</div>
                                </div>
                                <div className={'row article__error error error--consoles' + (errors.Consoles ? ' error-visible' : '')}>
                                  <div className="error__icon" />
                                  <div className="error__text">Please select Consoles</div>
                                </div>
                                <div
                                  className={
                                    'row article__error error error--playersPerMatch' + (errors.ParticipientsPerMatch ? ' error-visible' : '')
                                  }
                                >
                                  <div className="error__icon" />
                                  <div className="error__text">Please select Players / Clans per match</div>
                                </div>
                                <div
                                  className={'row article__error error error--maxPlayers' + (errors.MaxNumberOfParticients ? ' error-visible' : '')}
                                >
                                  <div className="error__icon" />
                                  <div className="error__text">Please select Maximum number of players / clans</div>
                                </div>
                                <div className={'row article__error error error--clanMembers' + (errors.PlayersPerClan ? ' error-visible' : '')}>
                                  <div className="error__icon" />
                                  <div className="error__text">Please select clan members count</div>
                                </div>
                                <div
                                  className={
                                    'row article__error error error--winningPlacements' + (errors.NumberOfPlacements ? ' error-visible' : '')
                                  }
                                >
                                  <div className="error__icon" />
                                  <div className="error__text">Please select winning placements</div>
                                </div>
                                <div
                                  className={
                                    'row article__error error error--qualifierWinners' +
                                    (errors.NumberOfQualifiersMoveForward ? ' error-visible' : '')
                                  }
                                >
                                  <div className="error__icon" />
                                  <div className="error__text">Please enter the number of winner(s)”)</div>
                                </div>
                                {tournament.EventTypeId !== EventTypeEnum.Qualifier && (
                                  <>
                                    <div className="row m-0 w-100">
                                      <div className="article__big-subheader ct__article__big-subheader">
                                        <span>Registration</span>
                                        <div />
                                      </div>
                                    </div>
                                    <div className="row m-0 m-r--30">
                                      <div className="article__width-2 m-r-30 d-flex flex-column article__players-count-container">
                                        <input
                                          className={
                                            'article__input article__width-2 article__height-1 w-100'
                                          }
                                          type="number"
                                          placeholder="PRIVATE"
                                          min={0}
                                          value={tournament.PrivateSlot || ''}
                                          onChange={(e) => { this.handlePrivateAndPublicSlotChange('PrivateSlot', e.target.value) }}
                                          disabled={tournament.MaxNumberOfParticipients ? false : true}
                                          max={tournament?.MaxNumberOfParticipients || 0}
                                        />
                                        <div className="row m-0 w-100 flex-nowrap">
                                          <InfoText>
                                            Please add the user from the  participant tab.
                                      </InfoText>
                                        </div>
                                      </div>
                                      <div className="article__width-2 m-r-30 d-flex flex-column article__players-count-container">
                                        <input
                                          className={
                                            'article__input article__width-2 article__height-1 w-100'
                                          }
                                          type="number"
                                          placeholder="PUBLIC"
                                          min={0}
                                          value={tournament.PublicSlot || ''}
                                          onChange={(e) => { this.handlePrivateAndPublicSlotChange('PublicSlot', e.target.value) }}
                                          disabled={tournament.MaxNumberOfParticipients ? false : true}
                                          max={tournament?.MaxNumberOfParticipients || 0}
                                        />
                                        <div className="row m-0 w-100 flex-nowrap">
                                          <InfoText>
                                            Assign these available slots for public registration.
                                      </InfoText>
                                        </div>
                                      </div>

                                    </div>
                                  </>
                                )}
                                <div className="row m-0 w-100">
                                  <div className="article__big-subheader ct__article__big-subheader">
                                    <span>Cover Image/Video (Max 10 Sec. GIF,MP4, Video)</span>
                                  </div>
                                </div>
                                <div className="row m-0">
                                  <div className="article__crop-cover-picture">
                                    <CoverUploader
                                      onChange={this.handleCoverChange}
                                      initialValue={this.state.initialCover}
                                      onUploaded={this.handleCoverUploaded}
                                    />
                                  </div>
                                </div>
                                <div className="row m-0 w-100">
                                  <div className="article__big-subheader ct__article__big-subheader">
                                    <span>Details about the date and time</span>
                                    <div />
                                  </div>
                                </div>
                                {tournament.IsTicketsAdded?
                                <div className="row m-0 m-r--30 disabled">
                                    <DateTimePicker
                                      date={tournament.TournamentDetails[0].StartDate}
                                      dateLabel="Tournament start date"
                                      dateLabelClassName="article__subheader ct__article__subheader"
                                      onChange={(date: Date) => {
                                      }}
                                      showTimePicker={true}
                                      timeLabel={`Time ${(tournament.TimeZone && tournament.TimeZone.Abbr) || ''}`}
                                      dateClassName={
                                        errors.StartDate || errors.InvalidStartDate ? ' highlight-invalid-control opacity-4' : ' opacity-4'
                                      }
                                      dateContainerClassName="article__width-2 p-r-35 d-flex flex-column"
                                      dateWrapperClassName="article__input-date article__width-2 article__height-1"
                                      minDate={tournament.TournamentDetails[0].RegistrationClosesOn}
                                      timeClass1={
                                        'article__select article__select--time-starts article__input article__width-2 article__height-1 select ct__select' +
                                        (errors.StartDate || errors.InvalidStartDate ? ' highlight-invalid-control' : '')
                                      }
                                      timeClass2="article__select--time-starts__title select__title-ct ct__select__title-ct dim"
                                      timeClass3="article__select--time-starts__container select__container ct__select__container"
                                      timeClass4="article__select--time-starts__option select__option"
                                      timeLabelClassName="article__subheader ct__article__subheader"
                                      timeContainerClassName="article__width-2 m-r-30 d-flex flex-column"
                                      timeZIndex={599}
                                    />
                                </div>:
                                
                                <div className="row m-0 m-r--30">
                                    <DateTimePicker
                                      date={tournament.TournamentDetails[0].StartDate}
                                      dateLabel="Tournament start date"
                                      dateLabelClassName="article__subheader ct__article__subheader"
                                      onChange={(date: Date) => {
                                        this.onChangeStartDate(date)
                                      }}
                                      showTimePicker={true}
                                      timeLabel={`Time ${(tournament.TimeZone && tournament.TimeZone.Abbr) || ''}`}
                                      dateClassName={
                                        errors.StartDate || errors.InvalidStartDate ? ' highlight-invalid-control opacity-4' : ' opacity-4'
                                      }
                                      dateContainerClassName="article__width-2 p-r-35 d-flex flex-column"
                                      dateWrapperClassName="article__input-date article__width-2 article__height-1"
                                      minDate={tournament.TournamentDetails[0].RegistrationClosesOn}
                                      timeClass1={
                                        'article__select article__select--time-starts article__input article__width-2 article__height-1 select ct__select' +
                                        (errors.StartDate || errors.InvalidStartDate ? ' highlight-invalid-control' : '')
                                      }
                                      timeClass2="article__select--time-starts__title select__title-ct ct__select__title-ct dim"
                                      timeClass3="article__select--time-starts__container select__container ct__select__container"
                                      timeClass4="article__select--time-starts__option select__option"
                                      timeLabelClassName="article__subheader ct__article__subheader"
                                      timeContainerClassName="article__width-2 m-r-30 d-flex flex-column"
                                      timeZIndex={599}
                                    />
                                </div>
                                }
                                <div className={'row article__error error error--tournamentStarts' + (errors.StartDate ? ' error-visible' : '')}>
                                  <div className="error__icon" />
                                  <div className="error__text">Please fill in all fields</div>
                                </div>
                                <div
                                  className={'row article__error error error--tournamentStarts' + (errors.InvalidStartDate ? ' error-visible' : '')}
                                >
                                  <div className="error__icon" />
                                  <div className="error__text">Tournament must start after Registration Closes</div>
                                </div>
                                <div
                                  className={'row article__error error error--tournamentStarts' + (errors.InvalidStartDate1 ? ' error-visible' : '')}
                                >
                                  <div className="error__icon" />
                                  <div className="error__text"> You cannot select this date because your Main event is schedule for {ConvertDateToFormattedDatetimeString(new Date(this.state.mainTournamentDate), null, false, true)}
                                  </div>
                                </div>
                                {tournament.EventTypeId !== EventTypeEnum.Main && <>

                                {tournament.IsTicketsAdded ?
                                  <div className="row m-0 m-r--30 disabled">
                                  <DateTimePicker
                                    date={
                                      tournament.TournamentDetails[0].RegistrationOpensOn
                                        ? new Date(tournament.TournamentDetails[0].RegistrationOpensOn)
                                        : null
                                    }
                                    showTimePicker={true}
                                    dateLabel="Registration opens"
                                    dateLabelClassName="article__subheader ct__article__subheader"
                                    onChange={(date: Date) => {
                                    }}
                                    timeLabel={`Time ${(tournament.TimeZone && tournament.TimeZone.Abbr) || ''}`}
                                    dateClassName={
                                      errors.RegistrationOpensOn || errors.InvalidRegistrationOpensOn ? ' highlight-invalid-control' : ''
                                    }
                                    dateContainerClassName="article__width-2 p-r-35 d-flex flex-column"
                                    dateWrapperClassName="article__input-date article__width-2 article__height-1"
                                    maxDate={tournament.TournamentDetails[0].StartDate}
                                    timeClass1={
                                      'article__select article__select--time-opens article__input article__width-2 article__height-1 select ct__select' +
                                      (errors.RegistrationOpensOn || errors.InvalidRegistrationOpensOn ? ' highlight-invalid-control' : '')
                                    }
                                    timeClass2="article__select--time-opens__title select__title-ct ct__select__title-ct dim"
                                    timeClass3="article__select--time-opens__container select__container ct__select__container"
                                    timeClass4="article__select--time-opens__option select__option"
                                    timeLabelClassName="article__subheader ct__article__subheader"
                                    timeContainerClassName="article__width-2 m-r-30 d-flex flex-column"
                                    timeZIndex={597}
                                  />
                                  </div>
                                :                                
                                  <div className="row m-0 m-r--30">
                                    <DateTimePicker
                                      date={
                                        tournament.TournamentDetails[0].RegistrationOpensOn
                                          ? new Date(tournament.TournamentDetails[0].RegistrationOpensOn)
                                          : null
                                      }
                                      showTimePicker={true}
                                      dateLabel="Registration opens"
                                      dateLabelClassName="article__subheader ct__article__subheader"
                                      onChange={(date: Date) => {
                                        this.handleTournamentDetailDataChange('RegistrationOpensOn', date)
                                      }}
                                      timeLabel={`Time ${(tournament.TimeZone && tournament.TimeZone.Abbr) || ''}`}
                                      dateClassName={
                                        errors.RegistrationOpensOn || errors.InvalidRegistrationOpensOn ? ' highlight-invalid-control' : ''
                                      }
                                      dateContainerClassName="article__width-2 p-r-35 d-flex flex-column"
                                      dateWrapperClassName="article__input-date article__width-2 article__height-1"
                                      maxDate={tournament.TournamentDetails[0].StartDate}
                                      timeClass1={
                                        'article__select article__select--time-opens article__input article__width-2 article__height-1 select ct__select' +
                                        (errors.RegistrationOpensOn || errors.InvalidRegistrationOpensOn ? ' highlight-invalid-control' : '')
                                      }
                                      timeClass2="article__select--time-opens__title select__title-ct ct__select__title-ct dim"
                                      timeClass3="article__select--time-opens__container select__container ct__select__container"
                                      timeClass4="article__select--time-opens__option select__option"
                                      timeLabelClassName="article__subheader ct__article__subheader"
                                      timeContainerClassName="article__width-2 m-r-30 d-flex flex-column"
                                      timeZIndex={597}
                                    />
                                  </div>
                                  }
                                  <div
                                    className={
                                      'row article__error error error--registrationOpens' + (errors.RegistrationOpensOn ? ' error-visible' : '')
                                    }
                                  >
                                    <div className="error__icon" />
                                    <div className="error__text">Please fill in all fields</div>
                                  </div>
                                  <div
                                    className={
                                      'row article__error error error--registrationOpens' + (errors.InvalidRegistrationOpensOn ? ' error-visible' : '')
                                    }
                                  >
                                    <div className="error__icon" />
                                    <div className="error__text">Regisration Start Time must be in future</div>
                                  </div>
                                </>}
                                {tournament.IsTicketsAdded?
                                <div className="row m-0 m-r--30 disabled">
                                <DateTimePicker
                                  date={
                                    tournament.TournamentDetails[0].RegistrationClosesOn
                                      ? new Date(tournament.TournamentDetails[0].RegistrationClosesOn)
                                      : null
                                  }
                                  showTimePicker={true}
                                  dateLabel="Registration closes"
                                  dateLabelClassName="article__subheader ct__article__subheader"
                                  onChange={(date: Date) => {
                                    //this.handleTournamentDetailDataChange('RegistrationClosesOn', date)
                                  }}
                                  timeLabel={`Time ${(tournament.TimeZone && tournament.TimeZone.Abbr) || ''}`}
                                  dateClassName={
                                    errors.RegistrationOpensOn || errors.InvalidRegistrationOpensOn ? ' highlight-invalid-control' : ''
                                  }
                                  dateContainerClassName="article__width-2 p-r-35 d-flex flex-column"
                                  dateWrapperClassName="article__input-date article__width-2 article__height-1"
                                  minDate={tournament.TournamentDetails[0].RegistrationOpensOn}
                                  maxDate={tournament.TournamentDetails[0].StartDate}
                                  timeClass1={
                                    'article__select article__select--time-closes article__input article__width-2 article__height-1 select ct__select' +
                                    (errors.RegistrationClosesOn || errors.InvalidRegistrationClosesOn ? ' highlight-invalid-control' : '')
                                  }
                                  timeClass2="article__select--time-closes__title select__title-ct ct__select__title-ct dim"
                                  timeClass3="article__select--time-closes__container select__container ct__select__container"
                                  timeClass4="article__select--time-closes__option select__option"
                                  timeLabelClassName="article__subheader ct__article__subheader"
                                  timeContainerClassName="article__width-2 m-r-30 d-flex flex-column"
                                  timeZIndex={595}
                                /></div> :   
                                <div className="row m-0 m-r--30">
                                  <DateTimePicker
                                    date={
                                      tournament.TournamentDetails[0].RegistrationClosesOn
                                        ? new Date(tournament.TournamentDetails[0].RegistrationClosesOn)
                                        : null
                                    }
                                    showTimePicker={true}
                                    dateLabel="Registration closes"
                                    dateLabelClassName="article__subheader ct__article__subheader"
                                    onChange={(date: Date) => {
                                      this.handleTournamentDetailDataChange('RegistrationClosesOn', date)
                                    }}
                                    timeLabel={`Time ${(tournament.TimeZone && tournament.TimeZone.Abbr) || ''}`}
                                    dateClassName={
                                      errors.RegistrationOpensOn || errors.InvalidRegistrationOpensOn ? ' highlight-invalid-control' : ''
                                    }
                                    dateContainerClassName="article__width-2 p-r-35 d-flex flex-column"
                                    dateWrapperClassName="article__input-date article__width-2 article__height-1"
                                    minDate={tournament.TournamentDetails[0].RegistrationOpensOn}
                                    maxDate={tournament.TournamentDetails[0].StartDate}
                                    timeClass1={
                                      'article__select article__select--time-closes article__input article__width-2 article__height-1 select ct__select' +
                                      (errors.RegistrationClosesOn || errors.InvalidRegistrationClosesOn ? ' highlight-invalid-control' : '')
                                    }
                                    timeClass2="article__select--time-closes__title select__title-ct ct__select__title-ct dim"
                                    timeClass3="article__select--time-closes__container select__container ct__select__container"
                                    timeClass4="article__select--time-closes__option select__option"
                                    timeLabelClassName="article__subheader ct__article__subheader"
                                    timeContainerClassName="article__width-2 m-r-30 d-flex flex-column"
                                    timeZIndex={595}
                                  />
                                </div>}
                                <div
                                  className={
                                    'row article__error error error--registrationCloses' + (errors.RegistrationClosesOn ? ' error-visible' : '')
                                  }
                                >
                                  <div className="error__icon" />
                                  <div className="error__text">Please fill in all fields</div>
                                </div>
                                <div
                                  className={
                                    'row article__error error error--registrationCloses' +
                                    (errors.InvalidRegistrationClosesOn ? ' error-visible' : '')
                                  }
                                >
                                  <div className="error__icon" />
                                  <div className="error__text">Registration Closing time must be after Registration Opening time</div>
                                </div>
                                {tournament.EventTypeId != EventTypeEnum.Main && (
                                  <RepeatSettings
                                    tournament={tournament}
                                    errors={errors}
                                    tournamentRepeatSettings={this.state.tournament.TournamentRepeatSettings}
                                    handleTournamentDataChange={this.handleTournamentDataChange}
                                    onChangeRepeatSettings={this.onChangeRepeatSettings}
                                  />
                                )}
                                <div className={'row article__error error error--repeat' + (errors.TournamentRepeatSettings ? ' error-visible' : '')}>
                                  <div className="error__icon" />
                                  <div className="error__text">Please fill in all fields</div>
                                </div>
                                <div className="row m-0 w-100">
                                  <div className="article__big-subheader ct__article__big-subheader">
                                    <span>Host of the Tournament</span>
                                    <div />
                                  </div>
                                </div>
                                <div className="row m-0">
                                  {isTwoPlayersStructure(tournament.TournamentStructureId) && (
                                    <>
                                      <input
                                        className="article__checkbox"
                                        id="host-no"
                                        type="radio"
                                        name="host"
                                        value="no"
                                        checked={!tournament.HostedByOrganizer}
                                        onChange={e => {
                                          this.handleTournamentDataChange('HostedByOrganizer', false)
                                        }}
                                      />
                                      <label
                                        className="article__label article__width-0 article__height-1"
                                        onClick={e => {
                                          this.handleTournamentDataChange('HostedByOrganizer', false)
                                        }}
                                      >
                                        <span className="article__label-text">No</span>
                                      </label>
                                    </>
                                  )}

                                  <input
                                    className="article__checkbox"
                                    id="host-yes"
                                    type="radio"
                                    name="host"
                                    value="yes"
                                    checked={tournament.HostedByOrganizer}
                                    onChange={e => {
                                      this.handleTournamentDataChange('HostedByOrganizer', true)
                                    }}
                                  />
                                  <label
                                    className="article__label article__width-0 article__height-1"
                                    onClick={e => {
                                      this.handleTournamentDataChange('HostedByOrganizer', true)
                                    }}
                                  >
                                    <span className="article__label-text">Yes</span>
                                  </label>
                                </div>
                                <div className="row m-0 w-100 flex-nowrap">
                                  <div className="article__warning-icon ct__article__warning-icon" />
                                  <div className="article__warning-text warning-text ct__warning-text">
                                    {tournament.HostedByOrganizer
                                      ? 'Organizer will take on the responsibility for setting up lobby and room credentials for this tournament. Please go to this page “My Event/Active” inside the Brackets tab to assign each match the details.'
                                      : 'The tournament system will randomly select a participant from each match to be the host to take on the responsibility to set up Lobby and credentials for that match.'
                                    }
                                  </div>
                                </div>
                                <div className="row m-0 w-100">
                                  <div className="article__big-subheader ct__article__big-subheader">
                                    <span>Manual control</span>
                                    <div />
                                  </div>
                                </div>
                                <div className="row m-0">
                                  <input
                                    className="article__checkbox"
                                    id="manual-control-no"
                                    type="radio"
                                    name="manual-control"
                                    value="no"
                                    checked={!tournament.IsManualControl}
                                    onChange={e => {
                                      this.handleTournamentDataChange('IsManualControl', false)
                                    }}
                                  />
                                  <label
                                    className="article__label article__width-0 article__height-1"
                                    onClick={e => {
                                      this.handleTournamentDataChange('IsManualControl', false)
                                    }}
                                  >
                                    <span className="article__label-text">No</span>
                                  </label>
                                  <input
                                    className="article__checkbox"
                                    id="manual-control-yes"
                                    type="radio"
                                    name="manual-control"
                                    value="yes"
                                    checked={tournament.IsManualControl}
                                    onChange={e => {
                                      this.handleTournamentDataChange('IsManualControl', true)
                                    }}
                                  />
                                  <label
                                    className="article__label article__width-0 article__height-1"
                                    onClick={e => {
                                      this.handleTournamentDataChange('IsManualControl', true)
                                    }}
                                  >
                                    <span className="article__label-text">Yes</span>
                                  </label>
                                </div>
                                <div className="row m-0 w-100 flex-nowrap">
                                  <div className="article__warning-icon ct__article__warning-icon" />
                                  <div className="article__warning-text warning-text ct__warning-text">
                                    {tournament.IsManualControl
                                      ? 'Tournament automation and most notifications will be disabled. The organizer will have full control of the tournament flow for each match and round once the tournament has been initialized. Please go to this page to control your tournament “My Event/Active” inside the Brackets Tab.'
                                      : 'Tournament automation will be turned on, most system notifications will be activated and sent out to all participants. Please remind your participants they are required to - (Check-in, Submit results) after each Match.'
                                    }
                                  </div>
                                </div>
                                <div className="row article__buttons-container justify-content-end">
                                  <div className="col p-0">
                                    <button
                                      className="grey-button article__prev-stage"
                                      onClick={e => {
                                        return this.moveToStep(1)
                                      }}
                                    >
                                      back
                                    </button>
                                  </div>
                                  <div className="col-sm-auto col-6 p-0">
                                    <button
                                      className="grey-button grey-button--filled article__save-in-draft ct__article__save-in-draft"
                                      onClick={e => {
                                        return this.saveAsDraft()
                                      }}
                                    >
                                      Save in draft
                                    </button>
                                  </div>
                                  <div className="col-sm-auto col-6 p-0">
                                    <button
                                      className="blue-button blue-button--filled article__next-step"
                                      onClick={e => {
                                        if (this.state.initialCover || this.state.cover || this.state.coverId) {
                                          return this.moveToStep(3)
                                        } else {
                                          alert('Please Select Cover Image Or Video!')
                                        }
                                      }}
                                    >
                                      Next step
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="article__container ct__article__container"
                                style={this.state.sliderStep != 3 ? { overflow: 'hidden', maxHeight: '100px' } : {}}
                              >
                                {tournament.TournamentSettings.length > 0 && (
                                  <div className="article__additional-blocks-container">
                                    <div className="row m-0 m-r--30 article__additional-blocks">
                                      {tournament.TournamentSettings.length > 0 &&
                                        tournament.TournamentSettings.map((item, i) => {
                                          return (
                                            <section key={'AB' + i.toString()} className="article__custom-field article__width-2 article__height-custom-field ct__article__height-custom-field">
                                              <div
                                                className={
                                                  this.props.isAdmin
                                                    ? 'admin_article__custom-field-title article__custom-field-title'
                                                    : 'article__custom-field-title'
                                                }
                                              >
                                                {item.SettingName}
                                              </div>
                                              <div
                                                className={
                                                  this.props.isAdmin
                                                    ? 'admin_article__custom-field-text ct__admin_article__custom-field-text article__custom-field-text'
                                                    : 'article__custom-field-text'
                                                }
                                              >
                                                {item.SettingValue}
                                              </div>
                                              <button
                                                className="article__remove-custom-field ct__article__remove-custom-field"
                                                onClick={() => {
                                                  this.removeSetting(i)
                                                }}
                                              />
                                            </section>
                                          )
                                        })}
                                    </div>
                                    <div className="row m-0">
                                      <div className="article__big-subheader ct__article__big-subheader">
                                        <span>Additional blocks</span>
                                        <div />
                                      </div>
                                    </div>
                                  </div>
                                )}
                                <div className="row m-0 m-r--30">
                                  <div className="article__width-2 m-r-30 d-flex flex-column">
                                    <div className="article__subheader ct__article__subheader">Field's name (optional)</div>
                                    <input
                                      className="article__input article__input--custom-field-title article__width-2 article__height-1"
                                      type="text"
                                      placeholder="Example: Map"
                                      value={this.state.curSetting.SettingName || ''}
                                      onChange={e => {
                                        this.handleSettingsDataChange('SettingName', e.target.value)
                                      }}
                                    />
                                  </div>
                                  <div className="article__width-2 m-r-30 d-flex flex-column">
                                    <div className="article__subheader ct__article__subheader">Field's details (optional)</div>
                                    <input
                                      className="article__input article__input--custom-field-text article__width-2 article__height-1"
                                      type="text"
                                      placeholder="Example: Erangel"
                                      value={this.state.curSetting.SettingValue || ''}
                                      onChange={e => {
                                        this.handleSettingsDataChange('SettingValue', e.target.value)
                                      }}
                                    />
                                  </div>
                                  <div className="d-flex flex-row align-items-center">
                                    {this.state.curSetting.SettingName && this.state.curSetting.SettingValue && (
                                      <button
                                        className={
                                          this.props.isAdmin ? 'article__add-custom-field blue-button' : 'article__add-custom-field white-button'
                                        }
                                        onClick={() => {
                                          this.addSetting()
                                        }}
                                      >
                                        Add field
                                      </button>
                                    )}
                                  </div>
                                </div>
                                <div className="row m-0">
                                  <div className="article__subheader ct__article__subheader">Information block (optional)</div>
                                </div>
                                <div className="row m-0">
                                  <textarea
                                    className="article__textarea article__width-3 article__height-4"
                                    placeholder="Information..."
                                    data-name="information"
                                    value={tournament.Information || ''}
                                    onChange={e => {
                                      this.handleTournamentDataChange('Information', e.target.value)
                                    }}
                                  />
                                </div>
                                <div className="row article__buttons-container justify-content-end">
                                  <div className="col p-0">
                                    <button
                                      className="grey-button article__prev-stage"
                                      onClick={e => {
                                        return this.moveToStep(2)
                                      }}
                                    >
                                      back
                                    </button>
                                  </div>
                                  <div className="col-sm-auto col-6 p-0">
                                    <button
                                      className="grey-button grey-button--filled article__save-in-draft ct__article__save-in-draft"
                                      onClick={e => {
                                        return this.saveAsDraft()
                                      }}
                                    >
                                      Save in draft
                                    </button>
                                  </div>
                                  <div className="col-sm-auto col-6 p-0">
                                    <button
                                      className="blue-button blue-button--filled article__next-step"
                                      onClick={e => {
                                        return this.moveToStep(4)
                                      }}
                                    >
                                      Next step
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="article__container ct__article__container"
                                style={this.state.sliderStep != 4 ? { overflow: 'hidden', maxHeight: '100px' } : {}}
                              >
                                
                                <RoundsSettings
                                  errors={errors}
                                  handleRoundDataChange={this.handleRoundDataChange}
                                  onChangeTicketData={this.handleTicketDataChange}
                                  tournament={tournament}
                                  isAnyTime={this.state.isAnyTime}
                                  mainTournamentDate={this.state.mainTournamentDate}
                                  handleTournamentRoundPlayDateTime={this.handleTournamentRoundPlayDateTime}
                                  handleSeatingArrangements={this.handleSeatingArrangements}
                                  tournamentSectionDetail={this.state.TournamentTicketSection}
                                  organizerCurrency={this.state.organizerCurrency}
                                />
                                {this.state.showDialouge && (
                                  <BillingOptionDailog
                                    organizerPayout={this.state.organizerPayout}
                                    showClass="modal-container--opened"
                                    verified={this.isOrganizerVerified}
                                    userCountry={this.state.userCountry}
                                    userCountryCode={this.state.userCountryCode}
                                    currentRoute="my-profile"
                                  />
                                )}
                                 {tournament.VenueTypeId === VenueTypeEnum.Live && (<><div className="row m-0 w-100">
                                    <div className="article__big-subheader ct__article__big-subheader">
                                      <span>Upload ticket image</span>
                                    </div>
                                  </div>
                                  <div className="row m-0">
                                    <div className="article__crop-ticket-picture">
                                      <FileSelector
                                        fileTypes={[MediaType.Image]}
                                        onChange={this.handleTicketImageChange}
                                        initialValue={this.state.initialTicketImage}
                                        cropperWidth="100%"
                                        recommendedText="Recommended Image Size: 1300 x 400"
                                      />
                                    </div>
                                  </div></>)}
  
                                <div className="row article__buttons-container justify-content-end">
                                  <div className="col p-0">
                                    <button
                                      className="grey-button article__prev-stage"
                                      onClick={e => {
                                        return this.moveToStep(3)
                                      }}
                                    >
                                      back
                                    </button>
                                  </div>
                                  <div className="col-sm-auto col-6 p-0">
                                    <button
                                      className="grey-button grey-button--filled article__save-in-draft ct__article__save-in-draft"
                                      onClick={e => {
                                        return this.saveAsDraft()
                                      }}
                                    >
                                      Save in draft
                                    </button>
                                  </div>
                                  <div className="col-sm-auto col-6 p-0">
                                    <button
                                      className="blue-button blue-button--filled article__next-step"
                                      onClick={e => {
                                        
                                        if (tournament.VenueTypeId == 2) {
                                          if (this.state.ticketImage || this.state.initialTicketImage) {
                                            return this.moveToStep(5)
                                          }
                                          else if(this.state.TournamentTicketSection.length === 0){
                                            alert('Please create event tickets')
                                          } else {
                                            alert('Please add ticket image!')
                                          }
                                        }
                                        else{
                                          return this.moveToStep(5)
                                        }
                                        //return this.moveToStep(5)
                                      }}
                                    >
                                      Next step
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="article__container ct__article__container"
                                style={this.state.sliderStep != 5 ? { overflow: 'hidden', maxHeight: '100px' } : {}}
                              >
                                {this.state.sliderStep == 5 && (
                                  <div>
                                    <TournamentDetailStep
                                      tournament={tournament}
                                      isAdmin={this.props.isAdmin}
                                      coverDataUrl={this.state.coverDataUrl}
                                      coverMediaType={this.state.coverMediaType}
                                    />
                                  </div>
                                )}
                                <div className="row article__buttons-container justify-content-end">
                                  <div className="col p-0">
                                    <button
                                      className="grey-button article__prev-stage"
                                      onClick={e => {
                                        return this.moveToStep(4)
                                      }}
                                    >
                                      back
                                    </button>
                                  </div>
                                  <div className="col-sm-auto col-6 p-0">
                                    <button
                                      className="grey-button grey-button--filled article__save-in-draft ct__article__save-in-draft"
                                      onClick={e => {
                                        return this.saveAsDraft()
                                      }}
                                    >
                                      Save in draft
                                    </button>
                                  </div>
                                  <div className="col-sm-auto col-6 p-0">

                                    <CreateButton onClick={this.saveTournament} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 article__right-menu-ct right-menu-ct d-md-block d-lg-block d-xl-block position-relative">
                      <div className="container-fluid">
                        <div className="row">
                          <h2 className="right-menu-ct__header-text">Stages of creation</h2>
                        </div>
                        <div
                          className={`row flex-nowrap right-menu-ct__stage-container ${this.state.sliderStep == 0
                            ? 'right-menu-ct__stage-container--active'
                            : this.state.completedSteps[0]
                              ? 'right-menu-ct__stage-container--done'
                              : ''
                            }`}
                        >
                          <div className="right-menu-ct__stage-round" />
                          <div className="right-menu-ct__stage-text">Tournament Setup</div>
                          <a
                            className="right-menu-ct__stage-link"
                            onClick={e => {
                              this.moveToStep(0)
                            }}
                          >
                            Change
                          </a>
                        </div>
                        <div
                          className={`row flex-nowrap right-menu-ct__stage-container ${this.state.sliderStep == 1
                            ? 'right-menu-ct__stage-container--active'
                            : this.state.completedSteps[1]
                              ? 'right-menu-ct__stage-container--done'
                              : ''
                            }`}
                        >
                          <div className="right-menu-ct__stage-round" />
                          <div className="right-menu-ct__stage-text">Select Event Type</div>
                          <a
                            className="right-menu-ct__stage-link"
                            onClick={e => {
                              this.moveToStep(1)
                            }}
                          >
                            Change
                          </a>
                        </div>
                        <div
                          className={`row flex-nowrap right-menu-ct__stage-container ${this.state.sliderStep == 2
                            ? 'right-menu-ct__stage-container--active'
                            : this.state.completedSteps[2]
                              ? 'right-menu-ct__stage-container--done'
                              : ''
                            }`}
                        >
                          <div className="right-menu-ct__stage-round" />
                          <div className="right-menu-ct__stage-text">General Details</div>
                          <a
                            className="right-menu-ct__stage-link"
                            onClick={e => {
                              this.moveToStep(2)
                            }}
                          >
                            Change
                          </a>
                        </div>
                        <div
                          className={`row flex-nowrap right-menu-ct__stage-container ${this.state.sliderStep == 3
                            ? 'right-menu-ct__stage-container--active'
                            : this.state.completedSteps[3]
                              ? 'right-menu-ct__stage-container--done'
                              : ''
                            }`}
                        >
                          <div className="right-menu-ct__stage-round" />
                          <div className="right-menu-ct__stage-text">Tournament Settings</div>
                          <a
                            className="right-menu-ct__stage-link"
                            onClick={e => {
                              this.moveToStep(3)
                            }}
                          >
                            Change
                          </a>
                        </div>
                        <div
                          className={`row flex-nowrap right-menu-ct__stage-container ${this.state.sliderStep == 4
                            ? 'right-menu-ct__stage-container--active'
                            : this.state.completedSteps[4]
                              ? 'right-menu-ct__stage-container--done'
                              : ''
                            }`}
                        >
                          <div className="right-menu-ct__stage-round" />
                          <div className="right-menu-ct__stage-text">Game Schedule</div>
                          <a
                            className="right-menu-ct__stage-link"
                            onClick={e => {
                              this.moveToStep(4)
                            }}
                          >
                            Change
                          </a>
                        </div>
                        <div
                          className={`row flex-nowrap right-menu-ct__stage-container ${this.state.sliderStep == 5
                            ? 'right-menu-ct__stage-container--active'
                            : this.state.completedSteps[5]
                              ? 'right-menu-ct__stage-container--done'
                              : ''
                            }`}
                        >
                          <div className="right-menu-ct__stage-round" />
                          <div className="right-menu-ct__stage-text">Preview page</div>
                          <a
                            className="right-menu-ct__stage-link"
                            onClick={e => {
                              this.moveToStep(5)
                            }}
                          >
                            Change
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <aside className="modal-container" id="premium">
                <div className="modal-container__background" />
                <div className="modal-container__window window">
                  <div className="window__close" />
                  <div className="container-fluid">
                    <div className="row justify-content-center">
                      <div className="window__header">Upgrade your account</div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="window__subheader window__subheader--big text-center">
                        Try the premium account for 30 days Free Trial, to unlock all tournament creation features
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <button type="button"
                        className="window__button purple-button"
                        onClick={() => {
                          this.upgradeToPremium()
                        }}
                      >
                        upgrade
                      </button>
                      <button type="button"
                        className="window__close_premium window__button purple-button purple-button--filled"
                        onClick={(e) => {
                          e.preventDefault()
                          ModalUtils.closeModals()
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </>
    )
  }
}
