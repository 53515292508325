export const CryptoCurrencyData = [
  { value: '1', lable: 'Bitcoin', id: 'bitcoin' },
  { value: '2', lable: 'Bitcoin Cash', id: 'bitcoin-cash' },
  { value: '3', lable: 'Dai', id: 'dai' },
  { value: '4', lable: 'Ethereum', id: 'ethereum' },
  { value: '5', lable: 'Litecoin', id: 'litecoin' },
  { value: '6', lable: 'USD Coin', id: 'usd-coin' },
  { value: '7', lable: 'Stellar', id: 'stellar' },
  { value: '8', lable: 'Dogecoin', id: 'dogecoin' },
  { value: '9', lable: 'XRP', id: 'ripple' },
]
