import { useSelector } from 'react-redux'
import { getAppUser } from 'store/selectors/app-selectors'
import { getIsAdmin, getIsOrganizer } from 'store/types/user-types'
import { useTournament, useUser } from '../useModels'
import { Permission } from 'store/types/common'

export const useTournamentPermission = (tournamentDetailId?: number): Permission => {
  const appUser = useSelector(getAppUser)

  const { tournament } = useTournament(tournamentDetailId)
  const { user: organizer } = useUser(tournament?.OrganizerId)

  if (!tournament || !appUser) {
    return Permission.read
  }

  if (getIsOrganizer(appUser.Role)) {
    return tournament.OrganizerId === appUser.Id ? Permission.write : Permission.read
  }

  if (getIsAdmin(appUser.Role)) {
    return organizer && getIsAdmin(organizer.Role) ? Permission.write : Permission.read
  }

  return Permission.read
}
