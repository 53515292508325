import React, { useMemo } from 'react'
import { Modal } from 'components/_modals'
import styles from './WinnerModal.module.scss'
import { useMatch, useMatchPlayer } from 'components/_hooks'
import { MatchParticipantIdPlace, useFinishMatch } from 'store/logic/match-logic'
import { makeGetMatchParticipantsIds } from 'store/selectors/match-participant-selectors'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from 'store/types/common'
import { loadBracket } from '../utils/loadBracket'
import { cn } from 'utils'

interface WinnerModalProps {
  onClose: CallableFunction
  matchParticipantId: number
  matchId: number
}

export const WinnerModal = ({ onClose, matchParticipantId, matchId }: WinnerModalProps) => {
  const dispatch = useDispatch()
  const finishMatch = useFinishMatch(matchId)
  const { name } = useMatchPlayer(matchParticipantId)
  const getMatchParticipantsIds = useMemo(makeGetMatchParticipantsIds, [])
  const matchParticipantsIds = useSelector((state: ApplicationState) => getMatchParticipantsIds(state, matchId))
  const { tournament } = useMatch(matchId)

  const asyncOperation = async () => {
    const winner = matchParticipantsIds.find(id => id === matchParticipantId)
    const losers = matchParticipantsIds.filter(id => id !== winner)
    const winnerPlace = 1

    const matchParticipantPlaces: MatchParticipantIdPlace[]  = losers.map(((id, index) => {
      const ordinalNumber = index + 1
      return {
        matchParticipantId: id,
        place: winnerPlace + ordinalNumber,
      }
    }))
    
    matchParticipantPlaces.push({
      matchParticipantId: winner,
      place: winnerPlace,
    })

    await finishMatch({ matchParticipantPlaces })
    loadBracket(tournament?.TournamentDetailId, dispatch)
    return true
  }
  
  return (
    <Modal
      onClose={onClose}
      allowOk
      buttonColor="gold"
      className={cn(styles.winnerModal, styles.window)}
      asyncOperation={asyncOperation}
    >
      <Modal.Header className={styles.windowHeader}>Warning</Modal.Header>
      <Modal.SubHeader className={styles.windowSubheader}>Do you want to make <b>{name}</b><br/>the winner?</Modal.SubHeader>
    </Modal>
  )
}
