import React, { useMemo } from 'react'
import { ParticipantAvatar } from 'components/_shared'
import { cn } from 'utils'
import { ContainerName, useAppSelector, useTournamentParticipant } from 'components/_hooks'
import { makeGetUser } from 'store/selectors/user-selectors'

export interface ParticipantOnlineAvatarProps {
  className: string
  parentClassName: string
  withLink: boolean
  participantId: number,
  containerName?:ContainerName
}

const ParticipantOnlineAvatar = ({ participantId, withLink, className, parentClassName,containerName }: ParticipantOnlineAvatarProps) => {
  const getUser = useMemo(makeGetUser, [])  
  const { playerId, clanLeaderId } = useTournamentParticipant(participantId)

  const userId = useMemo(() => {
    return clanLeaderId || playerId
  }, [clanLeaderId, playerId])

  const user = useAppSelector(state => getUser(state, userId))
 
  return (
    <div className={cn('prsn_icon-indicator', parentClassName, user?.IsOnline && 'prsn_icon-indicator-active')}>
      <ParticipantAvatar containerName={containerName} participantId={participantId} className={className} withLink={withLink} />
    </div>
  )
}
export default ParticipantOnlineAvatar
