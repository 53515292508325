import { useKlHistory } from 'components/_hooks/useKlHistory'
import { Button } from 'components/_shared'
import React, { useState } from 'react'
import { UserUtils } from 'server/utils/user-utils'
import { changePassword } from 'store/logic/user-logic'
import { cn } from 'utils'

import styles from '../Login.module.scss'
import { PasswordInput } from '../SignUp/_shared'


interface Props {
  verificationCode: string
  email: string
}

const PasswordRecoverySubmit = ({verificationCode, email}: Props) => {

  const [newPassword, setNewPassword] = useState('')  
  const [confirmedPassword, setConfirmedPassword] = useState('')

  const [error, setError] = useState('')
  const [isBusy, setIsBusy] = useState(false)

  const history = useKlHistory()


  const handleSubmit = async () => {

    if (!UserUtils.ValidatePassword(newPassword)) {
      setError('Password must be min 8 characters, with at least 1 capital letter, 1 number and 1 symbol.')
      return
    }
    if (newPassword !== confirmedPassword) {
      setError('Password and Confirm password mismatch')
      return
    }

    try {
      setIsBusy(true)
      await changePassword(email, verificationCode, newPassword)
      history.push('/login')
    } catch(error) {
      setError(error.toString())
    }
    finally {
      setIsBusy(false)
    }
  }

  const canSubmit = !!newPassword && !isBusy && !!confirmedPassword
  
  return (
    <>
      <div className={cn('row', styles.row)}>
        <div className="col-lg-4 col-md-6">
          <div className="d-flex flex-column">
            <PasswordInput
              value={newPassword}
              onChange={setNewPassword}
              title="New password"
              placeholder="Enter new password"
              className={styles.signIn__container}
              inputClassName={styles.signIn__defaultInput}
              iconClassName={styles.signIn__visibleButton}
            />
            <PasswordInput
              value={confirmedPassword}
              onChange={setConfirmedPassword}
              title="Confirm new password"
              placeholder="Confirm new password"
              className={styles.signIn__container}
              inputClassName={styles.signIn__defaultInput}
              iconClassName={styles.signIn__visibleButton}
            />             
            {error && (
              <div className={styles.errorMessContainer}>
                <span className={styles.errorMess}>
                  <span className={styles.errorMess_icon} />
                  {error}
                </span>
              </div>
            )}
          </div>
          <div>
            <Button onClick={handleSubmit} className={styles.signIn__login} color="white" loading={isBusy} disabled={!canSubmit}>
              Submit
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default PasswordRecoverySubmit
