import { Reducer } from 'redux'
import { calcById } from 'utils'
import { KnownAction, GAMES_LOADED, GameState, USER_GAMES_LOADED, GAME_DETAIL_LOADED, GAME_UPDATED, TOP_GAMES_LOADED, GAME_RETIRED } from '../types/game-types'

export const initialGamesState: GameState = {
  byId: {},
  ids: [],
  retiredIds: [],
  idsByUserId: {},
  topGames: {
    PopularGamesIds: [],
    UpcomingGamesIds: [],
  }
}

const reducer: Reducer<GameState, KnownAction> = (state: GameState | undefined = initialGamesState, action: KnownAction) => {
  switch (action.type) {
    case GAMES_LOADED:
      return {
        ...state,
        byId: calcById(state, action.payload.Games),
        ids: action.payload.Games.filter(x => !x.IsRetired).map(x => x.Id),
        retiredIds: action.payload.Games.filter(x => x.IsRetired).map(x => x.Id),
      }
    case GAME_DETAIL_LOADED:
      return {
        ...state,
        byId: calcById(state, action.payload.games.Games),
      }
    case USER_GAMES_LOADED: {
      return {
        ...state,
        byId: calcById(state, action.payload.games),
        idsByUserId: {
          ...state.idsByUserId,
          [action.payload.userId]: action.payload.games.map(x => x.Id),
        },
      }
    }
    case GAME_UPDATED: {
      return {
        ...state,
        byId: calcById(state, [action.payload]),
      }
    }
    case TOP_GAMES_LOADED: {
      return {
        ...state,
        topGames: action.payload,
      }
    }
    case GAME_RETIRED: {
      const newIds = state.ids.filter(x => x !== action.payload.Id)
      const newRetiredIds = state.retiredIds.concat(action.payload.Id)
      return {
        ...state,
        ids: newIds,
        retiredIds: newRetiredIds,
      }
    }
    default:
      return state
  }
}

export default reducer
