import { UserModel } from 'models/user-models'
import { fetchApi } from 'utils'
import { OperationTypes, useRun, useRunWithResult } from './operations-logic'
import { UserLoginHistory } from 'models/user-login-history-model'
import { EndPoints } from 'server/endpoints'

export interface SignUpOrganizerModel
{
  firstName: string
  lastName: string
  birthDate: Date
  organizationName: string
  position: string
  email: string
  password: string
  regionId: number
  displayInDirectory: boolean
}

export interface SignUpPlayerModel
{
  username: string
  birthDate: Date
  regionId: number
  email: string
  password: string
  displayInDirectory: boolean
}

export interface SignInUserModel
{
  email: string
  password: string
  isOrganizer: boolean
}

export interface SignInAdminModel
{
  email: string
  password: string
}

export const useSignUpOrganizer = () => {
  return useRun(OperationTypes.signUp, null, async (model: SignUpOrganizerModel) => {
    await fetchApi('auth/organizer/signup', 'POST', model)
    return true
  })
}

export const useSignUpPlayer = () => {
  return useRun(OperationTypes.signUp, null, async (model: SignUpPlayerModel) => {
    await fetchApi('auth/player/signup', 'POST', model)
    return true
  })
}

export const useActivateUser = () => {
  return useRun(OperationTypes.activateUser, null, async (hash: string) => {
    await fetchApi(`auth/activate/${hash}`, 'POST')
    return true
  })
}

export const useSignInUser = () => {
  return useRunWithResult(OperationTypes.signIn, null, async (model: SignInUserModel) => {
    return await fetchApi<UserModel>('auth/signin', 'POST', model)
  })
}

export const useSignInAdmin = () => {
  return useRunWithResult(OperationTypes.signInAdmin, null, (model: SignInAdminModel) => {
    return fetchApi('auth/admin/signin', 'POST', model)
  })
}

export const useUserLoginHistory = () => {
  return useRunWithResult(OperationTypes.userLoginHistory, null, async (model: UserLoginHistory) => {
    return await fetchApi<UserLoginHistory>(EndPoints.UserLoginHistory.Add, 'POST', model)
  })
}