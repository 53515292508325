import { useThemeStyles } from 'components/_hooks'
import { TextInput } from 'components/_shared'
import React from 'react'
import { cn } from 'utils'
import stylesDark from './SearchInputDark.module.scss'
import stylesLight from './SearchInputLight.module.scss'

export interface SearchInputProps {
  placeholder?: string
  value?: string
  onChange?: (value: string) => void
  className?: string
  inputClassName?: string
  clearClassName?: string
  disabled?: boolean
}

export const SearchInput = ({ placeholder, onChange, value, className, inputClassName, clearClassName, disabled }: SearchInputProps) => {
  const styles = useThemeStyles(stylesDark, stylesLight)
  
  return (
    <TextInput
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      className={cn(styles.container, className)}
      withClear
      clearClassName={clearClassName}
      inputClassName={cn(styles.input, inputClassName)}
      disabled={disabled}
    />
  )
}
