import { Reducer } from 'redux'
import { APP_USER_LOADED } from 'store/types/app-types'
import { KnownAction, ImageState, IMAGE_LOADED, IMAGES_LOADED } from 'store/types/image-types'
import { calcById } from 'utils'
import { MATCH_PARTICIPANTS_LOADED } from 'store/types/match-participant-types'
import { PARTICIPANTS_LOADED } from 'store/types/participants-types'
import { COVER_IMAGE_UPDATED, TOP_FRIENDS_LOADED, USERS_LOADED, USER_AVATAR_UPDATED } from 'store/types/user-types'
import { LINE_UPS_LOADED } from 'store/types/line-up-types'
import { MATCH_SUBMISSIONS_LOADED, MATCH_SUBMISSION_ADDED } from 'store/types/match-submissions-types'
import { CLANS_LOADED } from 'store/types/clan-types'
import { GAME_MAIN_TOURNAMENTS_LOADED, GAME_QUALIFY_TOURNAMENTS_LOADED, GAME_UPCOMING_TOURNAMENTS_LOADED, ORGANIZER_RECENT_TOURNAMENTS_LOADED, TOP_TOURNAMENTS_LOADED } from 'store/types/tournaments-types'
import { MAIN_TOURNAMENTS_LOADED, QUALIFY_TOURNAMENTS_LOADED, UPCOMING_TOURNAMENTS_LOADED } from 'store/types/pages/tournaments-page-types'
import { REVIEWS_LOADED } from 'store/types/review-types'
import { MY_ORGANIZER_ACTIVE_TOURNAMENTS_LOADED, MY_ORGANIZER_CANCELED_TOURNAMENTS_LOADED, MY_ORGANIZER_FINISHED_TOURNAMENTS_LOADED, MY_ORGANIZER_UPCOMING_TOURNAMENTS_LOADED } from 'store/types/pages/organizer-events-page-types'
import { CLAN_INVITATIONS_LOADED } from 'store/types/clan-invitations-types'
import { CLANS_MEMBERS_LOADED } from 'store/types/clan-member-types'
import { FILTERED_PLAYERS_LOADED, MEMBERS_PAGE_LOADED } from 'store/types/pages/clan-page-types'
import { MEMBERS_PAGE_CLANS_LOADED, MEMBERS_PAGE_ORGANIZERS_LOADED, MEMBERS_PAGE_PLAYERS_LOADED } from 'store/types/pages/members-page-types'
import { FRIENDS_PAGE_LOADED } from 'store/types/pages/friends-page-types'
import { GAMES_LOADED } from 'store/types/game-types'
import { FRIEND_REQUESTS_LOADED } from 'store/types/friend-requests-types'
import { ADMIN_ACTIVE_TOURNAMENTS_LOADED, ADMIN_CANCELED_TOURNAMENTS_LOADED, ADMIN_FINISHED_TOURNAMENTS_LOADED, ADMIN_UPCOMING_TOURNAMENTS_LOADED } from 'store/types/pages/admin-tournaments-page-types'
import { BLOGS_LOADED, BLOG_ADDED, BLOG_BY_ID_LOADED, BLOG_UPDATED } from 'store/types/blog-types'
import { NEW_CHAT_MESSAGE_LOADED, UNREAD_CHATS_LOADED } from 'store/chat/chat-message/chat-message-types'
import { CHATS_LOADED, CHAT_LOADED, GROUP_CHAT_CREATED } from 'store/chat/chat/chat-types'
import { CHAT_FILTERED_USERS_LOADED } from 'store/chat/chat-member/chat-member-types'
import { ADMIN_USERS_LOADED } from 'store/admin-user/admin-user-types'

const initialState: ImageState = {
  byId: {},
}

const reducer: Reducer<ImageState, KnownAction> = (state: ImageState | undefined = initialState, action: KnownAction) => {
  switch (action.type) {
    case COVER_IMAGE_UPDATED:
    case CLANS_MEMBERS_LOADED:
    case APP_USER_LOADED:
    case MATCH_PARTICIPANTS_LOADED:
    case PARTICIPANTS_LOADED:
    case USERS_LOADED:
    case LINE_UPS_LOADED:
    case MATCH_SUBMISSIONS_LOADED:
    case MATCH_SUBMISSION_ADDED: 
    case CLANS_LOADED:
    case UPCOMING_TOURNAMENTS_LOADED:
    case MAIN_TOURNAMENTS_LOADED:
    case QUALIFY_TOURNAMENTS_LOADED:
    case MY_ORGANIZER_UPCOMING_TOURNAMENTS_LOADED:
    case MY_ORGANIZER_ACTIVE_TOURNAMENTS_LOADED:
    case MY_ORGANIZER_FINISHED_TOURNAMENTS_LOADED:
    case MY_ORGANIZER_CANCELED_TOURNAMENTS_LOADED:
    case TOP_TOURNAMENTS_LOADED:
    case REVIEWS_LOADED:
    case GAME_UPCOMING_TOURNAMENTS_LOADED:
    case GAME_MAIN_TOURNAMENTS_LOADED:
    case GAME_QUALIFY_TOURNAMENTS_LOADED: 
    case CLAN_INVITATIONS_LOADED:
    case TOP_FRIENDS_LOADED: 
    case ORGANIZER_RECENT_TOURNAMENTS_LOADED:
    case GAMES_LOADED:
    case FRIEND_REQUESTS_LOADED:
    case BLOGS_LOADED:
    case BLOG_BY_ID_LOADED:
    case BLOG_ADDED:
    case BLOG_UPDATED:
    case ADMIN_UPCOMING_TOURNAMENTS_LOADED:      
    case ADMIN_ACTIVE_TOURNAMENTS_LOADED:
    case ADMIN_FINISHED_TOURNAMENTS_LOADED: 
    case ADMIN_CANCELED_TOURNAMENTS_LOADED:  
    case UNREAD_CHATS_LOADED:
    case NEW_CHAT_MESSAGE_LOADED:      
    case CHAT_LOADED:      
    case CHAT_FILTERED_USERS_LOADED:
    case GROUP_CHAT_CREATED:      
    case CHATS_LOADED:
    case ADMIN_USERS_LOADED:
    case USER_AVATAR_UPDATED:
    {
      return {
        byId: calcById(state, action.payload.Images),
      }
    }
    case FRIENDS_PAGE_LOADED:   {
      return {
        byId: calcById(state, action.payload.friends.UsersModel.Images),
      }
    }
    case IMAGE_LOADED:
      return {
        byId: calcById(state, [action.payload.image]),
      }
    case IMAGES_LOADED:
      return {
        byId: calcById(state, action.payload.images),
      }
    case FILTERED_PLAYERS_LOADED: {
      return {
        byId: calcById(state, action.payload.players.Images),
      }
    }
    case MEMBERS_PAGE_ORGANIZERS_LOADED:
      return {
        byId: calcById(state, action.payload.UsersModel.Images),
      }
    case MEMBERS_PAGE_CLANS_LOADED:
    case MEMBERS_PAGE_PLAYERS_LOADED:
    case MEMBERS_PAGE_LOADED:  {
      return {
        byId: calcById(state, action.payload.Model.Images),
      }
    }
    default:
      return state
  }
}

export default reducer
