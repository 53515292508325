import { useThemeStyles } from 'components/_hooks'
import React from 'react'
import { ReactNode } from 'react'
import { cn } from 'utils'
import stylesDark from './Tabs.module.scss'
import stylesLight from './TabsLight.module.scss'
import { TabCheckbox } from './_shared/TabCheckbox'
import { TabCheckboxContainer } from './_shared/TabCheckboxContainer'
import { TabLink } from './_shared/TabLink'
import { TabMenu } from './_shared/TabMenu'

export interface TabsProps {
  children: ReactNode
  className?: string
}

export const Tabs = ({ children, className }: TabsProps) => {
  const styles = useThemeStyles(stylesDark, stylesLight)
  
  return (
    <div className={cn('row', styles.articleTabMenuBeforeContainer, className)}>
      {children}
    </div>
  )
}

Tabs.Checkbox = TabCheckbox
Tabs.CheckboxContainer = TabCheckboxContainer
Tabs.Link = TabLink
Tabs.Menu = TabMenu
