import React from 'react'
import { Link } from 'react-router-dom'
import { getClanProfileUrl } from 'store/types/clan-types'
import { getOrganizerProfileUrl, getUserProfileUrl } from 'store/types/user-types'
import { cn } from 'utils'
import styles from '../MemberCard.module.scss'

export type CardType = 'player' | 'organizer' | 'clan'

export interface MemberNameColumnProps {
  name: string
  clanName?: string
  isClanLeader?: boolean
  id: number
  type: CardType
}

const getProfileUrl = (id: number, type: CardType) => {
  switch (type) {
    case 'player':
      return getUserProfileUrl(id)
    case 'clan':
      return getClanProfileUrl(id)
    case 'organizer':
      return getOrganizerProfileUrl(id)
    default:
      throw Error('Invalid CardType')
  }
}

export const MemberNameColumn = ({ name, clanName, isClanLeader, id, type }:  MemberNameColumnProps) => {
  const profileUrl = getProfileUrl(id, type)

  return (
    <>
      <div className={cn('col-auto p-0', styles.memberSecondThirdColumn, 'd-flex align-items-center')}>
        <div className="row m-0">
          <div className="col-12">
            <Link className={styles.memberName} to={profileUrl}>
              {name}
            </Link>
          </div>
          <div className="col-12">
            <div className={styles.memberClan}>
              {isClanLeader &&
                <>
                  <input className={styles.memberCheckbox} type="checkbox" id={id.toString()} />
                  <label className={styles.memberFounder} htmlFor={id.toString()}>
                    <span className={styles.memberFounderText}>Clan Founder</span>
                  </label>
                </>
              }
              {clanName}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
