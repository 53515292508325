import React, { useEffect } from 'react'
import { MatchParticipantModel } from 'store/types/match-participant-types'
import styles from './MatchResult.module.scss'
import { cn, defaultFormatDateTime } from 'utils'
import { ParticipantType } from 'store/types/participants-types'
import { MatchModel } from 'store/types/match-types'
import SingleMatchResult from './SingleMatchResult'
import { TournamentStructureEnum } from 'consts/TournamentStructure'
import FfaMatchResult from './FfaMatchResult'
import { useDispatch, useSelector } from 'react-redux'
import { getTournament } from 'store/selectors/tournaments-selectors'
import { ApplicationState } from 'store/types/common'
import { TournamentModeEnum } from 'consts'
import { matchSettingsActionCreators } from 'store/logic/match-settings-logic'
import { Modal } from 'components/_modals'

export interface ParticipantsResults {
  match: MatchModel
  matchParticpants: MatchParticipantModel[]
  participants: ParticipantType[]
  isDispute?: boolean
}

interface OwnProps {
  closeDialog: CallableFunction
  participantsResults: ParticipantsResults
}

const MatchResultModal = ({ closeDialog, participantsResults }: OwnProps) => {
  const { match } = participantsResults

  const isFFa =
    match.TournamentStructureId == TournamentStructureEnum.SingleRoundFfa ||
    match.TournamentStructureId == TournamentStructureEnum.EliminationRoundFfa

  const tournament = useSelector((state: ApplicationState) => getTournament(state, match.TournamentDetailId))
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(matchSettingsActionCreators.loadMatchSettings(match.Id))
  }, [dispatch, match.Id])

  const subheader = participantsResults && participantsResults.isDispute
    ? ['The match in dispute state, here are the results provided by the',
      (!tournament || tournament.TournamentModeId === TournamentModeEnum.Solo) ? 'players.' : 'clans.',
      'Organizer will resolve it soon.'].join(' ')
    : null

  return (
    <Modal
      onClose={closeDialog}
      hideOk
      hideCancel
      buttonColor="blue"
      className={cn(styles.window, isFFa && styles.windowFfa)}
    >
      <div className="row">
        <div className="col-auto p-0">
          <div className={styles.window__header}>{`Round ${match.RoundNumber} Match ${match.MatchNumber}`}</div>
        </div>
        <div className="col-sm-auto col-12 p-0">
          <div className={cn(styles.window__label)}>{defaultFormatDateTime(match.PlayDate)}</div>
        </div>
      </div>
      <Modal.SubHeader className={cn(participantsResults && participantsResults.isDispute && styles.window__subheader__dispute)}>{subheader}</Modal.SubHeader>
      {isFFa && <FfaMatchResult matchParticpants={participantsResults.matchParticpants} participants={participantsResults.participants} match={match} />}
      {!isFFa && <SingleMatchResult matchParticpants={participantsResults.matchParticpants} participants={participantsResults.participants} match={match} />}
    </Modal>
  )
}

export default MatchResultModal
