import { Reducer } from 'redux'
import { defaultOrganizerEventsFilters, KnownAction, MY_ORGANIZER_ACTIVE_TOURNAMENTS_LOADED, MY_ORGANIZER_CANCELED_TOURNAMENTS_LOADED, MY_ORGANIZER_FINISHED_TOURNAMENTS_LOADED, MY_ORGANIZER_UPCOMING_TOURNAMENTS_LOADED, OrganizerEventsPageState, ORGANIZER_EVENTS_FILTERS_CHANGED } from 'store/types/pages/organizer-events-page-types'


const initialState: OrganizerEventsPageState = {
  filters: defaultOrganizerEventsFilters,  
  allUpcomingTournamentsCount: 0,
  allActiveTournamentsCount: 0,
  allFinishedTournamentsCount: 0,
  allCanceledTournamentsCount:0,
  upcomingTournamentsIds: [],
  activeTournamentsIds: [],
  finishedTournamentsIds: [],
  canceledTournamentsIds: [],
}


const reducer: Reducer<OrganizerEventsPageState, KnownAction> = (state: OrganizerEventsPageState | undefined = initialState, action: KnownAction) => {
  switch (action.type) {
    case ORGANIZER_EVENTS_FILTERS_CHANGED:
      return {
        ...state,
        filters: action.payload.filters,
      }
    case MY_ORGANIZER_UPCOMING_TOURNAMENTS_LOADED: {
      return {
        ...state,
        upcomingTournamentsIds: action.payload.tournaments.map(x => x.TournamentDetailId),
        allUpcomingTournamentsCount: action.payload.allMyOrganizerUpcomingTournamentsCount,
      }
    }
    case MY_ORGANIZER_ACTIVE_TOURNAMENTS_LOADED: {
      return {
        ...state,
        activeTournamentsIds: action.payload.tournaments.map(x => x.TournamentDetailId),
        allActiveTournamentsCount: action.payload.allMyOrganizerActiveTournamentsCount,
      }
    }
    case MY_ORGANIZER_FINISHED_TOURNAMENTS_LOADED: {
      return {
        ...state,
        finishedTournamentsIds: action.payload.tournaments.map(x => x.TournamentDetailId),
        allFinishedTournamentsCount: action.payload.allMyOrganizerFinishedTournamentsCount,
      }
    }
    case MY_ORGANIZER_CANCELED_TOURNAMENTS_LOADED: {
      return {
        ...state,
        canceledTournamentsIds: action.payload.tournaments.map(x => x.TournamentDetailId),
        allCanceledTournamentsCount: action.payload.allMyOrganizerCanceledTournamentsCount,
      }
    }
    default:
      return state
  }
}

export default reducer
