import { ContainerName, useAppUser } from 'components/_hooks'
import { ArticleHeader, LoadingBox, Pagination } from 'components/_shared'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadBlogs } from 'store/logic/blogs-logic'
import { getAllBlogsCount, getBlogsIds, getBlogsLoading, getReadBlogsIds } from 'store/selectors/blog-selectors'

import styles from './NewsList.module.scss'
import { NewsRow } from './NewsRow'

const pageSize = 12

export const NewsList = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(0)
  const appUser = useAppUser()

  const blogsIds = useSelector(getBlogsIds)
  const readBlogsIds = useSelector(getReadBlogsIds)
  const allBlogsCount = useSelector(getAllBlogsCount)
  const blogsLoading = useSelector(getBlogsLoading)

  const load = useCallback((page: number) => {
    dispatch(loadBlogs({
      page,
      pageSize,
    }))
  }, [dispatch])

  useEffect(() => {
    load(page)
  }, [load, page])

  return (
    <>
      <ArticleHeader text="News"></ArticleHeader>
      <div className={styles.blogContent}>
        <div className="row">
          <LoadingBox size="big" loading={blogsLoading} centered className="w-100">
            { blogsIds.map(id =>
              <NewsRow containerName={ContainerName.Blogs} key={id} blogId={id} isRead={!appUser || readBlogsIds.includes(id)}/>
            )}
          </LoadingBox>            
        </div>
      </div>
      <Pagination page={page} itemsPerPage={pageSize} allItemsCount={allBlogsCount} onChangePage={setPage} />
    </>
  )
}
