import images from 'images'
import React from 'react'


const AuthBackground = () => {
  return (
    <>
      <div className="video-container">
        <video className="background-video" autoPlay muted loop>
          <source src={images.awesome} type="video/mp4" />
        </video>
      </div>
      <div className="background" />
    </>
  )
}
export default AuthBackground
