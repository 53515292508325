import { useDialogWithParameter, useMemberClan } from 'components/_hooks'
import { AlertModal } from 'components/_modals'
import { Button } from 'components/_shared/Button'
import { TournamentModeEnum, VenueTypeEnum } from 'consts'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { lineUpsActionCreators, useAddLineUp, useDeleteMyLineUp } from 'store/logic/line-ups-logic'
import { getAppUser } from 'store/selectors/app-selectors'
import { getParticipantLineUps } from 'store/selectors/line-ups-selectors'
import { ApplicationState } from 'store/types/common'
import { ParticipantType } from 'store/types/participants-types'
import { cn } from 'utils'
import { TournamentViewModel_kl } from 'models/tournament-viewmodel'

import styles from './TournamentRegistration.module.scss'

interface Props {
  tournament: TournamentViewModel_kl
  participants: ParticipantType[]
  onParticipantJoin: () => Promise<void>
  onParticipantLeave: () => Promise<void>
}

const ClanRegistrationButton = ({ tournament, participants, onParticipantJoin, onParticipantLeave }: Props) => {

  const user = useSelector(getAppUser)
  const { clan, isClanLeader } = useMemberClan(user?.Id)

  const dispatch = useDispatch()
  const addLineUp = useAddLineUp()
  const deleteMyLineUp = useDeleteMyLineUp()

  React.useEffect(() => {
    void dispatch(lineUpsActionCreators.loadLineUps(tournament.Id))
  }, [dispatch, tournament.Id])


  const clanParticipant = React.useMemo(() => {

    if (!clan) {
      return null
    }
    return participants.find(x => x.Clan?.Id === clan.Id)
  }, [participants, clan])


  let clanLineUps = useSelector((state: ApplicationState) => getParticipantLineUps(state, clanParticipant?.Id))
  clanLineUps=clanLineUps.filter(x=>x.IsDelete!==true)

  const currentMemberLineUp = React.useMemo(() => {
    return clanLineUps.find(lu => lu.PlayerId == user.Id)
  }, [clanLineUps, user.Id])

  const isLoggedInUserJoined = React.useMemo(() => {

    if (!user) return false
    if (!participants || !tournament) return false

    if (isClanLeader) {
      return participants.some(x => clan && x.Clan?.Id === clan.Id && isClanLeader)
    }

    return !!currentMemberLineUp
  }, [clan, currentMemberLineUp, isClanLeader, participants, tournament, user])

  const signInText = React.useMemo(() => {
    if (!tournament || !user) {
      return ''
    }

    if (isClanLeader) {
      return isLoggedInUserJoined ? 'Cancel' : 'Join'
    }
    return isLoggedInUserJoined ? 'Cancel' : 'Join'

  }, [isClanLeader, isLoggedInUserJoined, tournament, user])

  const isHaveEnoughMembers = !isClanLeader || clan.MembersCount >= tournament.PlayersPerClan + (tournament.NumberOfSubstitutes !== undefined && tournament.NumberOfSubstitutes !== null?0:Number(tournament.NumberOfSubstitutes))
  const handleJoinClick = async () => {

    //added code for display message on join button click if not clan leader or not enough members require to this tournament
    if (tournament.TournamentModeId === TournamentModeEnum.Clans) {
      if (!(isClanLeader || participants.findIndex(p => clan && p.ClanId == clan.Id) >= 0) || !isHaveEnoughMembers) {
        openActionCompletedModal('Your clan leader will have to initialize the sign up.')
        return false
      }
    }

    if (!user || !tournament) {
      return
    }

    if (isClanLeader) {
      if (isLoggedInUserJoined) {
        await onParticipantLeave()
      } else {
        await onParticipantJoin()
      }
      void dispatch(lineUpsActionCreators.reloadLineUps(tournament.Id))
    } else {
      try {
        if (tournament.VenueTypeId === VenueTypeEnum.Online) {
          if (isLoggedInUserJoined) {
            await deleteMyLineUp(tournament.Id)
            openActionCompletedModal('Cancel participation from tournament successfully.')
          } else {
            await addLineUp(tournament.Id)
            openActionCompletedModal('Joined tournament successfully.')
          }
        }
        else {
          if (isLoggedInUserJoined) {
            await deleteMyLineUp(tournament.Id)
            await onParticipantLeave()

          } else {
            await onParticipantJoin()
          }
        }
        // if (isLoggedInUserJoined) {
        //   await deleteMyLineUp(tournament.Id)
        //   openActionCompletedModal('Cancel participation from tournament successfully.')
        // } else {
        //   await addLineUp(tournament.Id)
        //   openActionCompletedModal('Joined tournament successfully.')
        // }
      } catch (error) {
        openActionCompletedModal((error as Error).message)
      }
    }
  }
  const [actionCompletedModal, openActionCompletedModal] = useDialogWithParameter<string>((text, closeDialog) => (
    <AlertModal onClose={closeDialog} text={text} buttonText="Ok" />
  ))


  const isButtonDisabled = !isLoggedInUserJoined && tournament.PlayersPerClan <= clanLineUps.length
  //comment code for display message on join button click
  //const isButtonDisabled = !isLoggedInUserJoined && tournament.PlayersPerClan <= clanLineUps.length || !isHaveEnoughMembers
  //const buttonTip =   isHaveEnoughMembers ? '' : `Min ${tournament.PlayersPerClan} members require for this tournament` 

  return (
    <>
      <Button
        filled
        color="blue"
        className={cn(
          styles.registration__join,
          'btn-block',
          styles.registration__join__online,
          styles.registration__join__clan
        )}
        onClick={handleJoinClick}
        disabled={isButtonDisabled}
      //dataTip={buttonTip}
      >
        <div className={styles.registration__join__wrapper}>
          <div className={cn('col', 'p-0', styles.registration__join__text)}>{signInText}</div>
          {(isLoggedInUserJoined || !isClanLeader) && (
            <div className={cn('col-auto', tournament.VenueTypeId == VenueTypeEnum.Live ? 'p-1' : 'p-2', styles.registration__membersContainer)}>
              <div className="col-12 p-0">
                <div className={styles.registration__members}>
                  {clanLineUps.length}
                  <span>/{tournament.PlayersPerClan+(tournament.NumberOfSubstitutes==null?0:tournament.NumberOfSubstitutes)}</span>
                </div>
              </div>
              <div className="col-12 p-0">
                <div className={styles.registration__membersText}>Members</div>
              </div>
            </div>
          )}
        </div>
      </Button>
      {actionCompletedModal}
    </>
  )
}

export default ClanRegistrationButton
