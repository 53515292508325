import { ContainerName, useAppSelector, useFilters } from 'components/_hooks'
import { Select, TextInput } from 'components/_library'
import { CarouselSelect, LoadingBox } from 'components/_shared'
import { consoleLabelExtractor, ConsoleOption, ConsoleTypeOptions, consoleValueExtractor } from 'consts/ConsoleType'
import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { loadCountries } from 'store/logic/country-logic'
import { makeGetCountries, makeGetIsCountriesLoading } from 'store/selectors/country-selectors'
import { countryLabelExtractor, CountryType, countryValueExtractor } from 'store/types/country-types'
import { regionLabelExtractor, RegionType, regionValueExtractor } from 'store/types/region-types'
import { cn } from 'utils'
import styles from '../PlayerPage.module.scss'
import { UserGame } from './UserGame'

export interface PlayerFieldsProps {
  value: PlayerFieldsModel
  onChange: (model: PlayerFieldsModel) => void
}

export interface PlayerFieldsModel {
  Username: string
  Organizationname: string
  About: string
  Country: CountryType
  Region: RegionType
  Ps4Id: string
  XboxId: string
  SteamId: string
  BattlenetUsername: string
  RiotUsername: string
  Epicgameid: string
  NintendoId: string
  ValveUsername: string
  IOsId: string
  AndroidId: string
  Consoles: ConsoleOption[]
  Games: number[]
}

export const PlayerFields = ({ value, onChange }:  PlayerFieldsProps) => {
  const dispatch = useDispatch()

  const makeHandleFieldChange = (fieldName: keyof PlayerFieldsModel) => <T extends unknown>(fieldValue: T) => {
    onChange({
      ...value,
      [fieldName]: fieldValue,
    })
  }
  
  const getCountries = useMemo(makeGetCountries, [])
  const getIsCountriesLoading = useMemo(makeGetIsCountriesLoading, [])
  
  const countries = useAppSelector(state => getCountries(state, { regionId: value.Region?.Id }))
  const isCountriesLoading = useAppSelector(state => getIsCountriesLoading(state, { regionId: value.Region?.Id }))
  const { regions, regionsLoading, games: allGames, gamesLoading } = useFilters()
  const allGamesIds = useMemo(() => {
    return allGames.map(x => x.Id)
  }, [allGames])

  const handleRegionChange = (region: RegionType) => {
    onChange({
      ...value,
      Region: region,
      Country: value.Region?.Id !== region.Id ? undefined :value.Country,
    })
    dispatch(loadCountries(region.Id))
  }

  useEffect(() => {
    if (value.Region) {
      dispatch(loadCountries(value.Region.Id))
    }
  }, [dispatch, value.Region])

  return (
    <>
      <TextInput
        placeholder="Player username"
        onChange={makeHandleFieldChange('Username')}
        value={value.Username}
        className="mb-2 w-100"
        inputClassName="w-100"
      />
      <TextInput
        placeholder="About profile"
        onChange={makeHandleFieldChange('About')}
        value={value.About}
        className="mb-2 w-100"
        inputClassName="w-100"
      />
      <div className="d-flex">
        <Select
          options={regions}
          onChange={handleRegionChange}
          selected={value.Region}
          labelExtractor={regionLabelExtractor}
          valueExtractor={regionValueExtractor}
          type="single"
          loading={regionsLoading}
          required
          placeHolder="Region"
          containerClassName={cn(styles.selectContainer, 'w-100')}
        />
        <Select
          options={countries}
          onChange={makeHandleFieldChange('Country')}
          selected={value.Country}
          labelExtractor={countryLabelExtractor}
          valueExtractor={countryValueExtractor}
          type="single"
          loading={isCountriesLoading}
          required
          placeHolder="Country"
          disabled={!value.Region}
          containerClassName={cn(styles.selectContainer, 'w-100 ml-2')}
        />
      </div>
      <div className={cn(styles.rightTile, 'pb-2 mt-2')}>Games</div>
      <LoadingBox loading={gamesLoading} size="average">
        <CarouselSelect
          renderItem={(value, index, onClick, selected) => <UserGame containerName={ContainerName.GamesImagesCard} key={index} gameId={value} onClick={onClick} selected={selected} />}
          values={allGamesIds}
          window={10}
          className={styles.games}
          onChange={makeHandleFieldChange('Games')}
          selected={value.Games}
          valueExtractor={id => id}
        />
      </LoadingBox>
      <div className={cn(styles.rightTile, 'pb-2 mt-3')}>Platforms</div>
      <Select
        options={ConsoleTypeOptions}
        onChange={makeHandleFieldChange('Consoles')}
        selected={value.Consoles}
        labelExtractor={consoleLabelExtractor}
        valueExtractor={consoleValueExtractor}
        type="multi"
        placeHolder="Platforms"
        containerClassName={styles.selectContainer}
      />
      <div className="d-flex">
        <TextInput
          placeholder="PS4 Id"
          onChange={makeHandleFieldChange('Ps4Id')}
          value={value.Ps4Id}
          className="mb-2 w-100"
          inputClassName="w-100"
        />
        <TextInput
          placeholder="Xbox Id"
          onChange={makeHandleFieldChange('XboxId')}
          value={value.XboxId}
          className="mb-2 ml-2 w-100"
          inputClassName="w-100"
        />
      </div>
      <div className="d-flex">
        <TextInput
          placeholder="Steam Id"
          onChange={makeHandleFieldChange('SteamId')}
          value={value.SteamId}
          className="mb-2 w-100"
          inputClassName="w-100"
        />
        <TextInput
          placeholder="Battlenet Id"
          onChange={makeHandleFieldChange('BattlenetUsername')}
          value={value.BattlenetUsername}
          className="mb-2 ml-2 w-100"
          inputClassName="w-100"
        />
      </div>
      <div className="d-flex">
        <TextInput
          placeholder="RIOT ID"
          onChange={makeHandleFieldChange('RiotUsername')}
          value={value.RiotUsername}
          className="mb-2 w-100"
          inputClassName="w-100"
        />
        <TextInput
          placeholder="Epicgame Id"
          onChange={makeHandleFieldChange('Epicgameid')}
          value={value.Epicgameid}
          className="mb-2 ml-2 w-100"
          inputClassName="w-100"
        />
      </div>
      <div className="d-flex">
        <TextInput
          placeholder="Nintendo Id"
          onChange={makeHandleFieldChange('NintendoId')}
          value={value.NintendoId}
          className="mb-2 w-100"
          inputClassName="w-100"
        />
        <TextInput
          placeholder="DISCORD ID"
          onChange={makeHandleFieldChange('ValveUsername')}
          value={value.ValveUsername}
          className="mb-2 ml-2 w-100"
          inputClassName="w-100"
        />
      </div>
      <div className="d-flex">
        <TextInput
          placeholder="Ios Id"
          onChange={makeHandleFieldChange('IOsId')}
          value={value.IOsId}
          className="mb-2 w-100"
          inputClassName="w-100"
        />
        <TextInput
          placeholder="Android Id"
          onChange={makeHandleFieldChange('AndroidId')}
          value={value.AndroidId}
          className="mb-2 ml-2 w-100"
          inputClassName="w-100"
        />
      </div>
    </>
  )
}
