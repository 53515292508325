import * as React from 'react'
import { VenueTypeEnum } from 'consts/VenueType'
import { TournamentType } from 'store/types/tournaments-types'
import { useFormatDateTime, useTournament } from 'components/_hooks'
import { DropDownTable } from 'components/_shared'
import styles from '../EventsOrganizer.module.scss'
import { cn } from 'utils'
import { ResumeCancelEventModel } from 'models/resume-cancel-event-model'
import { useResumeEvent } from 'components/_hooks/useResumeEvent'

interface Props {
  tournamentId: number
  openResumeConfirmDialog: (resumeModel: ResumeCancelEventModel) => void
  deleteTournamentConfirmDialog: (tournament: TournamentType) => void
}

const CanceledTournamentsRow = ({ tournamentId, openResumeConfirmDialog, deleteTournamentConfirmDialog }: Props) => {
  const { tournament } = useTournament(tournamentId)

  const fromattedDate = useFormatDateTime(tournament.TournamentDetailId, tournament.StartDate, 'redux')

  const onResumeEvent = async (tournamentCardObj: TournamentType) => {
    openResumeConfirmDialog(await useResumeEvent(tournamentCardObj))
  }

  return (
    <DropDownTable.Row>
      <DropDownTable.MainRow>
        <DropDownTable.RowColumn className={cn(styles.table__column, styles.eventName)}>
          <DropDownTable.Link to={`/tournament-detail/${tournament.TournamentDetailId}`}>
            {tournament.Name}
          </DropDownTable.Link>
        </DropDownTable.RowColumn>
        <DropDownTable.RowColumn className={cn(styles.table__column, styles.date)}>
          {fromattedDate.date}
        </DropDownTable.RowColumn>
        <DropDownTable.RowColumn className={cn(styles.table__column, styles.time)}>
          {fromattedDate.time}
        </DropDownTable.RowColumn>
        <DropDownTable.RowColumn className={cn(styles.table__column, styles.venue)}>
          {tournament.VenueTypeId == VenueTypeEnum.Live ? 'Live' : 'Online'}
        </DropDownTable.RowColumn>
        <DropDownTable.RowColumn className={cn(styles.table__column, styles.location)}>
          {tournament.VenueLocation}
        </DropDownTable.RowColumn>
        <DropDownTable.RowColumn className={cn(styles.table__column, styles.id)}>
          {tournament.TournamentDetailId}
        </DropDownTable.RowColumn>
        <DropDownTable.RowColumn className={cn(styles.table__column, styles.buttons)}>
          <div className="row justify-content-sm-end m-0">
            <DropDownTable.Button onClick={() => onResumeEvent(tournament)} color="blue" filled>Resume</DropDownTable.Button>
            <DropDownTable.Button onClick={() => deleteTournamentConfirmDialog(tournament)} color="red" filled>Delete</DropDownTable.Button>
          </div>
        </DropDownTable.RowColumn>
      </DropDownTable.MainRow>
    </DropDownTable.Row>
  )
}

export default CanceledTournamentsRow
