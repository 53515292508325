import { useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import { OperationTypes, run, useRun } from 'store/logic/operations-logic'
import { GetState } from 'store/types/common'
import { fetchApi } from 'utils'
import { ChatMembersModel } from '../chat-member/chat-member-types'
import { chatDeleted, chatLoaded, CreateGroupChatModel, deleteTournamentChatFromStateLoaded, disputeChatLoaded, groupChatCreated, tournamentChatLoaded } from './chat-types'


export const loadTournamentChat = (tournamentDetailId: number) => async (dispatch: Dispatch, getState: GetState) => {
  await run(OperationTypes.loadTournamentChat, tournamentDetailId, dispatch, getState, async () => {
    try {
      const model = await fetchApi<ChatMembersModel>(`chat/tournament/${tournamentDetailId}`, 'GET')
      if (model) {
        dispatch(tournamentChatLoaded({
          chat: model,
          tournamentDetailId: tournamentDetailId,
        }))
      }
    } catch {
      return false
    }

    return true
  })
}

export const loadDisputeChat = (matchId: number) => async (dispatch: Dispatch, getState: GetState) => {
  await run(OperationTypes.loadDisputeChat, matchId, dispatch, getState, async () => {
    try {
      const model = await fetchApi<ChatMembersModel>(`chat/dispute/${matchId}`, 'GET')
      if (model) {
        dispatch(disputeChatLoaded({
          chat: model,
          matchId: matchId,
        }))
      }
    } catch {
      return false
    }

    return true
  })
}

export const loadChat = (chatId: number) => async (dispatch: Dispatch, getState: GetState) => {
  await run(OperationTypes.loadChat, chatId, dispatch, getState, async () => {
    try {
      const model = await fetchApi<ChatMembersModel>(`chat/${chatId}`, 'GET')
      dispatch(chatLoaded(model))
    } catch {
      return false
    }

    return true
  })
}

export const useDeleteChat = (chatId: number) => {
  const dispatch = useDispatch()
  return useRun(OperationTypes.deleteChat, chatId, async () => {
    try {
      await fetchApi(`chat/${chatId}`, 'DELETE')
      dispatch(chatDeleted(chatId))
    } catch {
      return false
    }
    return true
  })
}

export const сreateGroupChat = (model: CreateGroupChatModel)  => async (dispatch: Dispatch, getState: GetState) => {
  await run(OperationTypes.createGroupChat, null , dispatch, getState, async () => {
    try {
      const result = await fetchApi<ChatMembersModel>('chat/group', 'POST', model)
      dispatch(groupChatCreated(result))
    } catch {
      return false
    }

    return true
  })
}

export const useSendIsTyping = (chatId: number) => {
  return useRun(OperationTypes.isTyping, chatId, async (isTyping:boolean) => {
    await fetchApi<void>(`chatmessage/typing/${chatId}/${isTyping}`, 'GET')
    return true
  })
}

export const DeleteTournamentChat = (tournamentDetailId: number) => async (dispatch: Dispatch, getState: GetState) => {
  await run(OperationTypes.DeleteTournamentChatFromState, tournamentDetailId, dispatch, getState, async () => {
    try {
        dispatch(deleteTournamentChatFromStateLoaded({
          tournamentDetailId: tournamentDetailId
        }))
    } catch {
      return false
    }

    return true
  })
}