import React from 'react'
import ReactTooltip from 'react-tooltip'
import styles from './buttons.module.scss'

interface Props {
  onClick: () => void  
}


const RepositionButton = ({ onClick }: Props) => {
  return (
    <>
      <button
        className={styles.eli_btn_screen}
        type="button"
        aria-expanded="false"
        data-tip="Reposition Bracket"
        data-for="RepositionBracketTip"
        onClick={onClick}
      >
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0)">
            <path
              d="M0.749954 6.00005C1.16469 6.00005 1.50005 5.66469 1.50005 5.24995V2.25C1.50005 1.83595 1.83595 1.50005 2.25 1.50005H5.24995C5.66469 1.50005 6.00005 1.16469 6.00005 0.749954C6.00005 0.33522 5.66469 0 5.24995 0H2.25C1.00951 0 0 1.00951 0 2.25V5.24995C0 5.66469 0.33522 6.00005 0.749954 6.00005Z"
              fill="#fff"
            />
            <path
              d="M17.2501 12C16.8354 12 16.5 12.3354 16.5 12.7501V15.75C16.5 16.1641 16.1641 16.5 15.75 16.5H12.7501C12.3354 16.5 12 16.8354 12 17.2501C12 17.6648 12.3354 18 12.7501 18H15.75C16.9905 18 18 16.9905 18 15.75V12.7501C18 12.3354 17.6648 12 17.2501 12Z"
              fill="#fff"
            />
            <path
              d="M5.24995 16.5H2.25C1.83595 16.5 1.50005 16.1641 1.50005 15.75V12.7501C1.50005 12.3354 1.16469 12 0.749954 12C0.33522 12 0 12.3354 0 12.7501V15.75C0 16.9905 1.00951 18 2.25 18H5.24995C5.66469 18 6.00005 17.6648 6.00005 17.2501C6.00005 16.8354 5.66469 16.5 5.24995 16.5Z"
              fill="#fff"
            />
            <path
              d="M15.75 0H12.7501C12.3354 0 12 0.33522 12 0.749954C12 1.16469 12.3354 1.50005 12.7501 1.50005H15.75C16.1641 1.50005 16.5 1.83595 16.5 2.25V5.24995C16.5 5.66469 16.8354 6.00005 17.2501 6.00005C17.6648 6.00005 18 5.66469 18 5.24995V2.25C18 1.00951 16.9905 0 15.75 0Z"
              fill="#fff"
            />
            <path
              d="M3.75 5.81241V12.1875C3.75 13.3253 4.67464 14.2499 5.81241 14.2499H12.1875C13.3253 14.2499 14.2499 13.3253 14.2499 12.1875V5.81241C14.2499 4.67464 13.3253 3.75 12.1875 3.75H5.81241C4.67464 3.75 3.75 4.67464 3.75 5.81241Z"
              fill="#fff"
            />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="18" height="18" fill="white"></rect>
            </clipPath>
          </defs>
        </svg>
      </button>
      <ReactTooltip effect="solid" type="light" id="RepositionBracketTip" />
    </>
  )
}

export default RepositionButton
