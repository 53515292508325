import React from 'react'
import styles from './ChatHr.module.scss'

export const ChatHr = () => {
  return (
    <div className="row">
      <div className={styles.chatHr}></div>
    </div>
  )
}
