import React, { useEffect } from 'react'
import { useDialogWithParameter } from 'components/_hooks/useComponents/useDialog'
import { useDispatch, useSelector } from 'react-redux'
import { MatchModel } from 'store/types/match-types'
import { loadCheckInMatches } from 'store/logic/match-logic'
import { getCheckInMatchesIds, getIsCheckInMatchesLoading } from 'store/selectors/match-selectors'
import CheckInBattleRow from './CheckInBattleRow'
import CheckInModal from './CheckInModal'
import {
  BattleHeaderRow,
  ButtonsColumn,
  DateColumn,
  DeadlineColumn,
  EventNameColumn,
  RoundColumn,
  TypeColumn,
} from '../_shared/BattleRow/BattleRow'
import { LoadingBox } from 'components/_shared'
import { useHighlight } from 'components/_hooks'

export interface CheckInBattlesProps {
  roundId?: number
}

export const CheckInBattles = ({ roundId}: CheckInBattlesProps) => {
  const dispatch = useDispatch()
  const matchesIds = useSelector(getCheckInMatchesIds)
  const matchesLoading = useSelector(getIsCheckInMatchesLoading)
  const [highlightedRoundId, removeHighlightedRoundId] = useHighlight(roundId)

  useEffect(() => {
    dispatch(loadCheckInMatches)
  }, [dispatch])

  const [checkInModal, openCheckInModal] = useDialogWithParameter<MatchModel>((match, closeDialog) => (
    <CheckInModal closeDialog={closeDialog} match={match} />
  ))

  return (
    <>
      <BattleHeaderRow>
        <EventNameColumn>Name of the event</EventNameColumn>
        <TypeColumn>Type</TypeColumn>
        <RoundColumn>Round</RoundColumn>
        <DateColumn>Date</DateColumn>
        <DeadlineColumn>Deadline Time</DeadlineColumn>
        <ButtonsColumn />
      </BattleHeaderRow>
      <LoadingBox loading={matchesLoading} size="big">
        {matchesIds.map(id => (
          <CheckInBattleRow
            key={id}
            matchId={id}
            openCheckInModal={openCheckInModal}
            highlightedRoundId={highlightedRoundId}
            removeHighlightedRoundId={removeHighlightedRoundId}
          />
        ))}
      </LoadingBox>
      {checkInModal}
    </>
  )
}
