import { ArticleHeader } from 'components/_shared'
import React from 'react'
import { cn } from 'utils'
import styles from './Disclaimer.module.scss'

export const Disclaimer = () => {
  return (
    <>
      <ArticleHeader text="KombatLink Disclaimer" />
      <p className={cn(styles.text, 'row', 'mt-3')}>League of Legends and Riot Games are trademarks or registered trademarks of Riot Games, Inc. League of Legends © Riot Games, Inc. is not affiliated with KombatLink.com. Capcom logo is a registered trademark of Capcom Co., Ltd. All rights reserved, Capcom is not affiliated with KombatLink.com. EA Sports and the EA Sports logo are trademarks of Electronic Arts, Inc. All rights reserved. Electronic Arts, Inc is not affiliated with KombatLink.com. 2K Games is a registered trademark of Take-Two Interactive Software, Inc. All rights reserved. Take-Two Interactive Software, Inc is not affiliated with KombatLink.com. Xbox and Xbox One are registered trademarks of Microsoft Corporation. All rights reserved. Microsoft Corporation is not affiliated with KombatLink.com. Playstation and Playstation 4 are registered trademarks of Sony Computer Entertainment. All rights reserved. Sony Computer Entertainment is not affiliated with KombatLink.com. Wii is a registered trademark of Nintendo of America Inc. All rights reserved. Nintendo of America Inc. is not affiliated with KombatLink.com. All other trademarks are the property of their respective owners. KombatLink.com is the latest E-sport community to compete in online video game tournaments to win cash prizes and earn loyalty points to redeem gaming products. KombatLink.com makes it possible to play all your favorite Xbox 360 ©, Xbox One, PS4 ©, Wii ©, PC, and iOS Mobile games for cash and prizes at the comfort of your home. LET’s PLAY.</p>
    </>
  )
}
