import React, { useEffect, useMemo, useState } from 'react'
import { DropDownTable, LoadingBox, SubmissionModal } from 'components/_shared'
import { DisputeDropDownTable } from '..'
import styles from '../../Disputes.module.scss'
import { cn } from 'utils'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from 'store/types/common'
import { DisputeModel, DisputeStatus } from 'store/types/dispute-types'
import { makeGetIsMatchParticipantsLoading, makeGetMatchParticipants } from 'store/selectors/match-participant-selectors'
import { matchesSumbissionsActionCreators } from 'store/logic/match-submissions-logic'
import { DisputeDetailModal, DisputeDetailModalParams } from '../DisputeDetailModal'
import { useDialogWithParameter } from 'components/_hooks/useComponents/useDialog'
import { disputeDetailActionCreators } from 'store/logic/dispute-detail-logic'
import { ParticipantSubmissionModel } from 'store/types/match-submissions-types'
import { makeGetIsMatchSubmissionsLoading, makeGetSubmissionsByMatchId } from 'store/selectors/match-submissions-selectors'
import { makeGetDisputeDetailsByDisputeId, makeGetIsDisputeDetailsLoading } from 'store/selectors/dispute-detail-selectors'
import { useDisputedMatchParticipants } from './useDisputedMatchParticipants'
import { SubmissionModalType } from 'components/_shared/SubmissionModal/SubmissionModal/SubmissionModal'
import { getRoundTitle } from 'store/types/rounds-types'
import { useDispute, useIsAdminRoute, useScrollToElement, useUser } from 'components/_hooks'
import { loadMatchParticipants } from 'store/logic/match-participant-logic'
import DisputeChatButton from './DisputeChatButton'


export interface DisputeRowProps {
  disputeId: number
  openVideoPlayerModal: (videoUrl: string) => void
  openResolveDisputeModal: (dispute: DisputeModel) => void
  highlightedRoundId?: number
  removeHighlightedRoundId: () => void
}

export const DisputeRow = ({ disputeId, openVideoPlayerModal, openResolveDisputeModal, highlightedRoundId, removeHighlightedRoundId }: DisputeRowProps) => {
  const dispatch = useDispatch()
  const getMatchParticipants = useMemo(makeGetMatchParticipants, [])

  const getSubmissionsByMatchId = useMemo(makeGetSubmissionsByMatchId, [])
  const getDisputeDetailsByDisputeId = useMemo(makeGetDisputeDetailsByDisputeId, [])
  const getIsMatchParticipantsLoading = useMemo(makeGetIsMatchParticipantsLoading, [])
  const getIsMatchSubmissionsLoading = useMemo(makeGetIsMatchSubmissionsLoading, [])
  const getIsDisputeDetailsLoading = useMemo(makeGetIsDisputeDetailsLoading, [])

  const isAdminRoute = useIsAdminRoute()
  const { dispute, round, tournament } = useDispute(disputeId)
  const { user: organizer } = useUser(tournament?.OrganizerId)

  const disputeDetails = useSelector((state: ApplicationState) => getDisputeDetailsByDisputeId(state, dispute.Id))
  const disputeDetailsLoading = useSelector((state: ApplicationState) => getIsDisputeDetailsLoading(state, dispute.Id))
  const matchParticipants = useSelector((state: ApplicationState) => getMatchParticipants(state, dispute.MatchId))
  const submissions = useSelector((state: ApplicationState) => getSubmissionsByMatchId(state, dispute.MatchId))
  const matchParticipantsLoading = useSelector((state: ApplicationState) => getIsMatchParticipantsLoading(state, dispute.MatchId))
  const matchSubmissionsLoading = useSelector((state: ApplicationState) => getIsMatchSubmissionsLoading(state, dispute.MatchId))

  const sortedDisputedMatchParticipants = useDisputedMatchParticipants(
    matchParticipants,
    disputeDetails,
    submissions,
    matchSubmissionsLoading,
    disputeDetailsLoading,
    dispute.Status,
    tournament
  )

  const canResolve = useMemo(() => {
    if (dispute.Status === DisputeStatus.Closed) {
      return false
    }
    if (matchSubmissionsLoading || disputeDetailsLoading || matchParticipantsLoading) {
      return false
    }
    if (matchParticipants.length === 0) {
      return false
    }
    const hasInDispute = sortedDisputedMatchParticipants.some(x => x.inDispute)
    return !hasInDispute
  }, [dispute.Status, matchSubmissionsLoading, disputeDetailsLoading, matchParticipantsLoading, matchParticipants.length, sortedDisputedMatchParticipants])

  const [opened, setOpened] = useState(false)

  const [makeWinnerModal, openDisputeDetailModal] = useDialogWithParameter<DisputeDetailModalParams>((params, closeDialog) => (
    <DisputeDetailModal
      closeDialog={closeDialog}
      disputeDetail={params.disputeDetail}
      participantName={params.participantName}
      tournamentStructure={tournament?.TournamentStructureId}
      matchParticipantsLength={matchParticipants.length}
      matchId={dispute.MatchId}
      readonly={dispute.Status === DisputeStatus.Closed}
    />
  ))

  const [submissionModal, openSubmissionModal] = useDialogWithParameter<ParticipantSubmissionModel>((submission, closeDialog) => (
    <SubmissionModal
      closeDialog={closeDialog}
      submission={submission}
      matchId={dispute.MatchId}
      participantsLength={matchParticipants.length}
      tournamentStructure={tournament?.TournamentStructureId}
      type={SubmissionModalType.Readonly}
    />
  ))

  const handleOpenButtonClick = () => {
    setOpened(!opened)
  }

  const handleResolveClick = () => {
    openResolveDisputeModal(dispute)
  }

  const highlighted = useMemo(() => {
    return highlightedRoundId === round?.Id
  }, [highlightedRoundId, round?.Id])

  const ref = useScrollToElement(highlighted, matchParticipantsLoading ? 'auto' : 'smooth')

  useEffect(() => {
    if (opened) {
      dispatch(loadMatchParticipants(dispute.MatchId))
      if (dispute.Status === DisputeStatus.Open || submissions.length === 0) {
        dispatch(matchesSumbissionsActionCreators.loadMatchSubmissions(dispute.MatchId))
      }
      dispatch(disputeDetailActionCreators.loadDisputeDetails(dispute.Id))
    }
  }, [dispatch, dispute.Id, dispute.MatchId, dispute.Status, opened, submissions.length])


  return (
    <>
      <DropDownTable.Row ref={ref} onMouseEnter={highlighted ? removeHighlightedRoundId : undefined}>
        <DropDownTable.MainRow opened={opened} highlighted={highlighted}>
          <DisputeDropDownTable.RowColumn className={styles.tableColumnId}>{isAdminRoute ? `${organizer?.Organizationname || ''} (${dispute.Id})` : dispute.Id}</DisputeDropDownTable.RowColumn>
          <DisputeDropDownTable.RowColumn className={styles.tableColumnEventId}>#T{dispute.TournamentDetailId}</DisputeDropDownTable.RowColumn>
          <DisputeDropDownTable.RowColumn className={styles.tableColumnEvent}>{tournament?.Name}</DisputeDropDownTable.RowColumn>
          <DisputeDropDownTable.RowColumn className={styles.tableColumnRound}>{getRoundTitle(round, tournament?.TournamentStructureId)}</DisputeDropDownTable.RowColumn>
          <DisputeDropDownTable.RowColumn className={styles.tableColumnResolved}>
            <div className={cn(dispute.Status === DisputeStatus.Closed ? styles.yes : styles.no, 'd-inline-block')}>
              {dispute.Status === DisputeStatus.Closed ? 'YES' : 'NO'}
            </div>
          </DisputeDropDownTable.RowColumn>
          <DisputeDropDownTable.MainRowButtonsColumn>
            <div className="row justify-content-md-end justify-content-start m-0 flex-nowrap flex-sm-row flex-column">
              <DropDownTable.ButtonLink
                to={`/tournament-detail/${tournament?.TournamentDetailId}`}
                color="grey"
                filled
                className={cn(styles.tableButton, opened && styles.tableViewListing)}
              >
                VIEW LISTING
              </DropDownTable.ButtonLink>
              {dispute.Status === DisputeStatus.Open && (                
                <DisputeChatButton disputeOpened={opened} matchId={dispute.MatchId} />  
              )}          
              <DropDownTable.ButtonLink
                color={opened ? 'blue' : 'grey'}
                filled
                onClick={handleResolveClick}
                className={cn(styles.tableButton)}
                to="#"
                disabled={!opened || !canResolve}
                title={!opened ? 'Expand row to resolve dispute' : ''}
              >
                RESOLVE
                {opened && canResolve && <DropDownTable.Warning className={styles.warning}>To confirm this dispute case has been resolved, please click on the button</DropDownTable.Warning>}
              </DropDownTable.ButtonLink>
              <DropDownTable.OpenButton onClick={handleOpenButtonClick} opened={opened} className={styles.tableOpen} />
            </div>
          </DisputeDropDownTable.MainRowButtonsColumn>
        </DropDownTable.MainRow>
        <DropDownTable.SecondaryRowContainer opened={opened} className={styles.tableSecondaryRowContainer}>
          <LoadingBox loading={matchParticipantsLoading} size="big">
            {sortedDisputedMatchParticipants.map(x => (
              <DisputeDropDownTable.DisputeParticipantRow
                key={x.matchParticipantId}
                matchParticipantId={x.matchParticipantId}
                openVideoPlayerModal={openVideoPlayerModal}
                openDisputeDetailModal={openDisputeDetailModal}
                openSubmissionModal={openSubmissionModal}
                tournamentStructure={tournament?.TournamentStructureId}
                disputeId={disputeId}
                inDispute={x.inDispute}
                winner={x.winner}
                place={dispute.Status === DisputeStatus.Open ? x.place : undefined}
              />
            ))}
          </LoadingBox>
        </DropDownTable.SecondaryRowContainer>
      </DropDownTable.Row>
      {makeWinnerModal}
      {submissionModal}
    </>
  )
}
