import { ArticleHeader, useDropDownTableStyles } from 'components/_shared'

import styles from './OrganizerDisputes.module.scss'

import { cn } from 'utils'
import { RouteComponentProps } from 'react-router-dom'
import { Disputes } from 'components/_shared/Disputes/Disputes'
import { useIntMatchParam } from 'components/_hooks'

interface OrganizerDisputesRouteProps {
  roundId?: string
}

type OrganizerDisputesProps = RouteComponentProps<OrganizerDisputesRouteProps>

const OrganizerDisputes = ({ match }: OrganizerDisputesProps) => {
  const dropDownTableStyles = useDropDownTableStyles()

  const roundId = useIntMatchParam(match, x => x.roundId)

  return (
    <div className={cn(dropDownTableStyles.disputesMediaQueries, styles.disputesMediaQueries)}>
      <ArticleHeader text="Disputes" />
      <Disputes roundId={roundId}/>
    </div>
  )
}

export default OrganizerDisputes
