import { ContainerName, useGetImageSize } from 'components/_hooks'
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadUser, reLoadUser } from 'store/logic/user-logic'
import { makeGetIsUserLoading, makeGetUser } from 'store/selectors/user-selectors'
import { ApplicationState } from 'store/types/common'
import { getUserProfileUrl } from 'store/types/user-types'
import { ImagePlaceholder, Img } from '../Img'
import { KlLink } from '../KlLink/KlLink'
import { LoadingBox, LoadingSize } from '../Loading'

interface UserAvatarProps {
  userId: number
  linkClassName?: string
  className?: string
  placeholder?: ImagePlaceholder
  withLink?: boolean
  style?: React.CSSProperties
  alt?: string
  title?: string,
  containerName?: ContainerName
  isReload?: boolean
}

export const UserAvatar = ({ userId, linkClassName, className, placeholder, withLink, style, alt, title, containerName }: UserAvatarProps) => {
  const getUser = useMemo(makeGetUser, [])
  const user = useSelector((state: ApplicationState) => getUser(state, userId))
  const gameContainerSize = useGetImageSize(containerName)
  return user && withLink ? (
    <KlLink to={getUserProfileUrl(user.Id)} className={linkClassName}>
      <Img parentContainerSize={gameContainerSize} className={className} imageId={user.AvatarId} placeholder={placeholder} style={style} alt={alt} title={title} />
    </KlLink>
  ) : (
      <Img parentContainerSize={gameContainerSize} className={className} imageId={user?.AvatarId} placeholder={placeholder} style={style} alt={alt} title={title} />
    )
}

interface SelfLoadedUserAvatarProps extends UserAvatarProps {
  size?: LoadingSize,
  containerName?: ContainerName
}

export const SelfLoadedUserAvatar = ({ userId, linkClassName, className, placeholder, withLink, size, containerName, isReload }: SelfLoadedUserAvatarProps) => {
  const dispatch = useDispatch()
  const getIsUserLoading = useMemo(makeGetIsUserLoading, [])
  const isUserLoading = useSelector((state: ApplicationState) => getIsUserLoading(state, { userId }))
  useEffect(() => {
    if (isReload) {
      dispatch(reLoadUser(userId))
    }
    else {
      dispatch(loadUser(userId))
    }
  }, [dispatch, userId])

  return (
    <LoadingBox size={size || 'small'} loading={isUserLoading}>
      <UserAvatar containerName={containerName} userId={userId} className={className} linkClassName={linkClassName} withLink={withLink} placeholder={placeholder} />
    </LoadingBox>
  )
}
