import { TournamentStatusEnum } from '../../consts/TournamentStatusId'
import { TournamentModeEnum } from '../../consts/TournamentModeId'
import { DisputesLoadedAction } from './dispute-types'
import { isTwoPlayersStructure, TournamentStructureEnum, VenueTypeEnum } from 'consts'
import { makeActionCreator, ReduxAction } from './common'
import { ApprovedMatchLinksLoadedAction, PendingMatchLinksLoadedAction } from './match-link-types'
import { ActiveMatchesLoadedAction, CheckinMatchesLoadedAction, FinishedMatchesLoadedAction, PendingMatchesLoadedAction } from './match-types'
import { MatchesSettingsLoadedAction } from './match-settings-types'
import { TournamentConsolesType } from './console-types'
import { ImageType } from './image-types'
import { MainTournamentsLoadedAction, QualifyTournamentsLoadedAction, UpcomingTournamentsLoadedAction } from './pages/tournaments-page-types'
import { MyOrganizerActiveTournamentsLoadedAction, MyOrganizerCanceledTournamentsLoadedAction, MyOrganizerFinishedTournamentsLoadedAction, MyOrganizerUpcomingTournamentsLoadedAction } from './pages/organizer-events-page-types'
import { PlayerLiveTournamentsLoadedAction, PlayerOnlineTournamentsLoadedAction } from './pages/player-events-page-types'
import { AdminActiveTournamentsLoadedAction, AdminCanceledTournamentsLoadedAction, AdminFinishedTournamentsLoadedAction, AdminUpcomingTournamentsLoadedAction, CarouselUpdatedAction } from './pages/admin-tournaments-page-types'

export interface TournamentType {
  Id: number
  OrganizerId?: number
  Name: string
  TournamentModeId: TournamentModeEnum
  TournamentStructureId: TournamentStructureEnum
  AutomaticBracketResizing: boolean
  VenueTypeId: VenueTypeEnum
  EventTypeId: number
  MainTournamentId: number
  RegionId: number
  CountryId: number
  Province: string
  City: string
  VenueName: string
  VenueLocation: string
  VenueLatitude: string
  VenueLongitude: string
  VenueSeatingCapacity?: number
  StreamingLink: string
  ContactEmail: string
  DiscordLink: string
  GameId?: number
  ParticipientsPerMatch?: number
  MaxNumberOfParticipients?: number
  PlayersPerClan?: number
  MaxNumberOfRounds?: number
  NumberOfPlacements?: number
  NumberOfQualifiersMoveForward?: number
  HostedByOrganizer?: boolean
  Information: string
  IsDraft?: boolean
  Rules: string
  RepeatTypeId?: number
  RepeatHoursInterval?: number
  CoverId?: number
  TicketImageId?: number
  IsManualControl: boolean

  TournamentDetailId: number
  StartDate: string
  RegistrationOpensOn: string
  RegistrationClosesOn: string
  ActualNumberOfParticipients?: number
  ActualNumberOfRounds?: number
  ActualParticipantsPerMatch?: number
  NumberOfParticipientsLaidOff?: number
  BattlesGenerated: boolean
  ShowOnHomePage: boolean
  TournamentStatusId: TournamentStatusEnum

  TimeZoneId: string
    IsAnyTime?: boolean
    PrivateSlot: number | null
    PublicSlot: number | null
}

export interface State {
  byId: {
    [key: number]: TournamentType
  }
  finishedTournamentsIds: number[]
  allFinishedTournamentsCount: number 
  topTournamentsIds: number[]
  gameUpcomingTournamentsIds: number[]
  allGameUpcomingTournamentsCount: number
  gameMainTournamentsIds: number[]
  allGameMainTournamentsCount: number
  gameQualifyTournamentsIds: number[]
  allGameQualifyTournamentsCount: number
  recentTournamentsIdsByOrganizerId: {
    [key: number] : number[]
  }
}

export const SET_TOURNAMENT = 'SET_TOURNAMENT'
export interface SetTournamentAction {
  type: typeof SET_TOURNAMENT
  tournament: TournamentType
}
export function setTournament(tournament: TournamentType): SetTournamentAction {
  return {
    type: SET_TOURNAMENT,
    tournament,
  }
}

export const SET_TOURNAMENTS = 'SET_TOURNAMENTS'
export interface SetTournamentsAction {
  type: typeof SET_TOURNAMENTS
  tournaments: TournamentType[]
}
export function setTournaments(tournaments: TournamentType[]): SetTournamentsAction {
  return {
    type: SET_TOURNAMENTS,
    tournaments,
  }
}

export const FINISHED_TOURNAMENTS_LOADED = 'FINISHED_TOURNAMENTS_LOADED'
interface FinishedTournamentsLoadedPayload {
  tournaments: TournamentType[]
  allFinishedTournamentsCount: number
}
export type FinishedTournamentsLoadedAction = ReduxAction<typeof FINISHED_TOURNAMENTS_LOADED, FinishedTournamentsLoadedPayload>
export const finishedTournamentsLoaded = makeActionCreator<FinishedTournamentsLoadedPayload>(FINISHED_TOURNAMENTS_LOADED)

export const TOP_TOURNAMENTS_LOADED = 'TOP_TOURNAMENTS_LOADED'
interface TopTournamentsLoadedPayload {
  tournaments: TournamentType[]
  TournamentConsoles: TournamentConsolesType[] 
  Images: ImageType[]
}
export type TopTournamentsLoadedAction = ReduxAction<typeof TOP_TOURNAMENTS_LOADED, TopTournamentsLoadedPayload>
export const topTournamentsLoaded = makeActionCreator<TopTournamentsLoadedPayload>(TOP_TOURNAMENTS_LOADED)


export const ORGANIZER_RECENT_TOURNAMENTS_LOADED = 'ORGANIZER_RECENT_TOURNAMENTS_LOADED'
interface OrganizerRecentTournamentsLoadedPayload {
  organizerId: number
  tournaments: TournamentType[]
  TournamentConsoles: TournamentConsolesType[] 
  Images: ImageType[]
}
export type OrganizerRecentTournamentsLoadedAction = ReduxAction<typeof ORGANIZER_RECENT_TOURNAMENTS_LOADED, OrganizerRecentTournamentsLoadedPayload>
export const organizerRecentTournamentsLoaded = makeActionCreator<OrganizerRecentTournamentsLoadedPayload>(ORGANIZER_RECENT_TOURNAMENTS_LOADED)

export const GAME_UPCOMING_TOURNAMENTS_LOADED = 'GAME_UPCOMING_TOURNAMENTS_LOADED'
interface GameUpcomingTournamentsLoadedPayload {
  tournaments: TournamentType[]
  allGameUpcomingTournamentsCount: number
  TournamentConsoles: TournamentConsolesType[] 
  Images: ImageType[]
}
export type GameUpcomingTournamentsLoadedAction = ReduxAction<typeof GAME_UPCOMING_TOURNAMENTS_LOADED, GameUpcomingTournamentsLoadedPayload>
export const gameUpcomingTournamentsLoaded = makeActionCreator<GameUpcomingTournamentsLoadedPayload>(GAME_UPCOMING_TOURNAMENTS_LOADED)

export const GAME_MAIN_TOURNAMENTS_LOADED = 'GAME_MAIN_TOURNAMENTS_LOADED'
interface GameMainTournamentsLoadedPayload {
  tournaments: TournamentType[]
  allGameMainTournamentsCount: number
  TournamentConsoles: TournamentConsolesType[] 
  Images: ImageType[]
}
export type GameMainTournamentsLoadedAction = ReduxAction<typeof GAME_MAIN_TOURNAMENTS_LOADED, GameMainTournamentsLoadedPayload>
export const gameMainTournamentsLoaded = makeActionCreator<GameMainTournamentsLoadedPayload>(GAME_MAIN_TOURNAMENTS_LOADED)

export const GAME_QUALIFY_TOURNAMENTS_LOADED = 'GAME_QUALIFY_TOURNAMENTS_LOADED'
interface  GameQualifyTournamentsLoadedPayload {
  tournaments: TournamentType[]
  allGameQualifyTournamentsCount: number
  TournamentConsoles: TournamentConsolesType[] 
  Images: ImageType[]
}
export type GameQualifyTournamentsLoadedAction = ReduxAction<typeof GAME_QUALIFY_TOURNAMENTS_LOADED, GameQualifyTournamentsLoadedPayload>
export const gameQualifyTournamentsLoaded = makeActionCreator<GameQualifyTournamentsLoadedPayload>(GAME_QUALIFY_TOURNAMENTS_LOADED)


export const CLEAR_TOURNAMENTS = 'CLEAR_TOURNAMENTS'
interface ClearTournamentsPayload {
  tournamentsIds: number[]
}
export type ClearTournamentsAction = ReduxAction<typeof CLEAR_TOURNAMENTS, ClearTournamentsPayload>
export const clearTournaments = makeActionCreator<ClearTournamentsPayload>(CLEAR_TOURNAMENTS)

export type KnownAction = SetTournamentAction
  | SetTournamentsAction
  | DisputesLoadedAction
  | FinishedTournamentsLoadedAction
  | PendingMatchesLoadedAction
  | PendingMatchLinksLoadedAction
  | ApprovedMatchLinksLoadedAction
  | FinishedMatchesLoadedAction
  | CheckinMatchesLoadedAction
  | ActiveMatchesLoadedAction
  | ClearTournamentsAction
  | MatchesSettingsLoadedAction
  | TopTournamentsLoadedAction
  | GameUpcomingTournamentsLoadedAction
  | GameMainTournamentsLoadedAction
  | GameQualifyTournamentsLoadedAction
  | UpcomingTournamentsLoadedAction
  | MainTournamentsLoadedAction
  | QualifyTournamentsLoadedAction
  | MyOrganizerUpcomingTournamentsLoadedAction
  | MyOrganizerActiveTournamentsLoadedAction
  | MyOrganizerFinishedTournamentsLoadedAction
  | MyOrganizerCanceledTournamentsLoadedAction
  | PlayerOnlineTournamentsLoadedAction
  | PlayerLiveTournamentsLoadedAction
  | OrganizerRecentTournamentsLoadedAction
  | AdminUpcomingTournamentsLoadedAction
  | AdminActiveTournamentsLoadedAction
  | AdminFinishedTournamentsLoadedAction
  | AdminCanceledTournamentsLoadedAction
  | CarouselUpdatedAction

export const getMaxParticipantsNumberPerMatch = (tournament: TournamentType) => {
  if (isTwoPlayersStructure(tournament.TournamentStructureId)) {
    return 2
  }
  if (tournament.TournamentStructureId === TournamentStructureEnum.SingleRoundFfa) {
    return tournament.MaxNumberOfParticipients
  }
  if (tournament.TournamentStructureId === TournamentStructureEnum.EliminationRoundFfa) {
    return tournament.ParticipientsPerMatch
  }
  throw Error('Unsupported structure')
}

export const getIsRegistrationClosed = (tournament: TournamentType) => {
  const isRegistrationDatePassed = new Date() >= new Date(tournament.RegistrationClosesOn)
  return tournament.TournamentStatusId !== TournamentStatusEnum.Pending || isRegistrationDatePassed
}


export const getIsStartDatePassed = (tournament: TournamentType) => {
  return  new Date() > new Date(tournament.StartDate)
}

export const checkDatePassed = (startDate: string) => {
  return  new Date() > new Date(startDate)
}
