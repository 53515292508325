import { ReactNode } from 'react'
import { cn } from 'utils'
import { useDropDownTableStyles } from './styles/useDropDownTableStyles'

export interface DropDownTableMainRowProps {
  opened?: boolean
  children?: ReactNode
  className?: string
  highlighted?: boolean
}

export const DropDownTableMainRow = ({ opened, children, className, highlighted }: DropDownTableMainRowProps) => {
  const styles = useDropDownTableStyles()

  return <div className={cn('row', styles.tableMainRow, className, opened && styles.opened, highlighted && styles.highlighted)}>{children}</div>
}
