import { makeActionCreator, ReduxAction } from './common'
import { GameConsolesType } from './console-types'
import { ImageType } from './image-types'

export interface GameType {
  Id: number
  Gamename: string
  IsRetired: boolean
  ImageId?: number
}

export interface GamesModel {
  Games: GameType[]
  Images: ImageType[]
}

export interface ListGameType {
  Games: GameType[]
  GameConsoles: GameConsolesType[]
}

export interface TopGames {
  PopularGamesIds: number[]
  UpcomingGamesIds: number[]
}

export interface GameState {
  byId: {
    [key: number]: GameType
  }
  ids: number[]
  retiredIds: number []
  idsByUserId: {
    [key: number]: number[]
  }
  topGames: TopGames
}

export const GAMES_LOADED = 'GAMES_LOADED'
export type GamesLoadedAction = ReduxAction<typeof GAMES_LOADED, GamesModel>
export const gamesLoaded = makeActionCreator<GamesModel>(GAMES_LOADED)

export const GAME_UPDATED = 'GAME_UPDATED'
export type GameUpdatedAction = ReduxAction<typeof GAME_UPDATED, GameType>
export const gameUpdated = makeActionCreator<GameType>(GAME_UPDATED)

export const GAME_DETAIL_LOADED = 'GAME_DETAIL_LOADED'
interface GameDetailLoadedPayload {
  games: ListGameType
}
export type GameDetailLoadedAction = ReduxAction<typeof GAME_DETAIL_LOADED, GameDetailLoadedPayload>
export const gameDetailLoaded = makeActionCreator<GameDetailLoadedPayload>(GAME_DETAIL_LOADED)

export const USER_GAMES_LOADED = 'USER_GAMES_LOADED'
interface UserGamesLoadedPayload {
  games: GameType[]
  userId: number
}
export type UserGamesLoadedAction = ReduxAction<typeof USER_GAMES_LOADED, UserGamesLoadedPayload>
export const userGamesLoaded = makeActionCreator<UserGamesLoadedPayload>(USER_GAMES_LOADED)

export const TOP_GAMES_LOADED = 'TOP_GAMES_LOADED'
export type TopGamesLoadedAction = ReduxAction<typeof TOP_GAMES_LOADED, TopGames>
export const topGamesLoaded = makeActionCreator<TopGames>(TOP_GAMES_LOADED)

export const GAME_RETIRED = 'GAME_RETIRED'
export type GameRetiredAction = ReduxAction<typeof GAME_RETIRED, GameType>
export const gameRetired = makeActionCreator<GameType>(GAME_RETIRED)

export type KnownAction = GamesLoadedAction
  | UserGamesLoadedAction
  | GameDetailLoadedAction
  | GameUpdatedAction
  | TopGamesLoadedAction
  | GameRetiredAction

export const gameValueExtractor = (game: GameType) => game.Id
export const gameLabelExtractor = (game: GameType) => game.Gamename
