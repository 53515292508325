import { makeActionCreator, ReduxAction } from 'store/types/common'

export interface FeeModel {
  Premium: number
  Standard: number
}

export interface FeeState {
  fee: FeeModel
}

export const initialFee: FeeModel = {
  Premium: 0,
  Standard: 0,
}

export const FEE_LOADED = 'FEE_LOADED'
export type FeeLoadedAction = ReduxAction<typeof FEE_LOADED, FeeModel>
export const feeLoaded = makeActionCreator<FeeModel>(FEE_LOADED)

export type FeeKnownAction = FeeLoadedAction
