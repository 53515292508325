import { ContainerName, useAlertModal, useAppSelector, useDialog, useGetImageSize } from 'components/_hooks'
import { UpdateImageModal } from 'components/_modals'
import { Img, LoadingBox } from 'components/_shared'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useUpdateAvatar } from 'store/logic/user-logic'
import { getAppUser } from 'store/selectors/app-selectors'
import { makeGetIsUpdateAvatarLoading } from 'store/selectors/user-selectors'
import { ImageType, ImageTypeEnum } from 'store/types/image-types'

const getImageType = () => ImageTypeEnum.Avatar

export const OrganizerAvatar = () => {
  const user = useSelector(getAppUser)
  const updateAvatar = useUpdateAvatar(user?.Id)

  const getIsUpdateAvatarLoading = useMemo(makeGetIsUpdateAvatarLoading, [])
  const isUpdateAvatarLoading = useAppSelector(state => getIsUpdateAvatarLoading(state, { userId: user?.Id }))

  const [alertModal, openAlertModal] = useAlertModal()

  const handleSaveImage = async (image: ImageType) => {
    try {
      await updateAvatar(image.Id)
    }
    catch {
      openAlertModal('Avatar not saved, please try again')
    }
  }

  const [updateImageModal, openUpdateImageModal] = useDialog(closeDialog => (
    <UpdateImageModal
      onClose={closeDialog}
      header="Update the Avatar"
      dropText="Drag a picture to this window"
      fileTypes={['image/jpeg', 'image/jpg', 'image/png']}
      recommendedText="Note: Please choose a JPG, Png file from your computer"
      getImageTypeCallback={getImageType}
      saveImage={handleSaveImage}
      aspectRatioType="avatar"
      crop
      round      
    />
  ))
  const imageSize = useGetImageSize(ContainerName.AvatarsNormal)
  return (
    <>
      <div className="pfl_logo">
        <div className="align-items-center d-flex justify-content-center pfl_logo_img">
          <LoadingBox size="big" loading={isUpdateAvatarLoading}>
            <Img parentContainerSize={imageSize} className="img-fluid" imageId={user?.AvatarId} style={!user?.AvatarId ? { opacity: '0.5' } : undefined} />
          </LoadingBox>
        </div>
        <div className="pfl_logo_upload">
          <label className="btn-upload-small" onClick={openUpdateImageModal}>
            <img src="https://kombatlink-assets.azureedge.net/img/camera.svg" alt="" style={{ opacity: '1' }} />
          </label>
        </div>
      </div>
      {alertModal}
      {updateImageModal}
    </>
  )
}
