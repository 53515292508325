import { Reducer } from 'redux'
import { KnownAction, MY_REVIEW_LOADED, ReviewState, REVIEWS_LOADED, REVIEW_ADDED } from 'store/types/review-types'
import { calcById, groupArrayToObject } from 'utils'

const initialState: ReviewState = {
  byId: {},
  idsByUserId: {},
  allReviewsCount: 0,
  myReviewIdByUserId: {},
}

const reducer: Reducer<ReviewState, KnownAction> = (state: ReviewState | undefined = initialState, action: KnownAction) => {
  switch (action.type) {
    case REVIEWS_LOADED:
      return {
        ...state,
        allReviewsCount: action.payload.AllReviewsCount,
        byId: calcById(state, action.payload.Reviews),
        idsByUserId: {
          ...state.idsByUserId,
          ...groupArrayToObject(action.payload.Reviews, x => x.UserId, x => x.Id),
        },
      }
    case MY_REVIEW_LOADED:
    case REVIEW_ADDED :{
      return {
        ...state,
        byId: calcById(state, [action.payload.review]),
        myReviewIdByUserId: {
          ...state.myReviewIdByUserId,
          [action.payload.userId]: action.payload.review.Id,
        },
      }
    }
    default:
      return state
  }
}

export default reducer
