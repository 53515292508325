import { Button } from 'components/_shared'
import React from 'react'
import { requestResetPassword } from 'store/logic/user-logic'
import { cn } from 'utils'

import styles from '../Login.module.scss'

interface Props {
  onRequestSent: (email: string) => void
}

const PasswordRecoveryRequest = ({onRequestSent}: Props) => {
  const [email, setEmail] = React.useState('')
  const [error, setError] = React.useState('')
  const [isBusy, setIsBusy] = React.useState(false)


  const handleClearEmail = () => {
    setEmail('')
  }

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setEmail(value)
  }

  const handleSubmit = async () => {
    try {
      setIsBusy(true)
      await requestResetPassword(email)
      onRequestSent(email)
    } catch(error) {
      setError(error.toString())
    }
    finally {
      setIsBusy(false)
    }
  }

  const canSubmit = !!email && !isBusy

  return (
    <div className={cn('row', styles.row)}>
      <div className="col-lg-4 col-md-6">
        <div className="d-flex flex-column">
          <div id="email-container">
            <div className={styles.signIn__inputTitle}>Enter email adress</div>
            <div className={styles.signIn__container}>
              <input name="email" id="email" type="email" className={styles.signIn__defaultInput} placeholder="Email" value={email} onChange={handleChangeEmail} />
              <button className={styles.signIn__cleanButton} onClick={handleClearEmail} />
            </div>
          </div>
          {error && (
            <div className={styles.errorMessContainer}>
              <span className={styles.errorMess}>
                <span className={styles.errorMess_icon} />
                {error}
              </span>
            </div>
          )}
        </div>
        <div>
          <Button onClick={handleSubmit} className={styles.signIn__login} color="white" loading={isBusy} disabled={!canSubmit} >Submit</Button>
        </div>
      </div>
    </div>
  )
}

export default PasswordRecoveryRequest
