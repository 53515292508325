import * as React from 'react'

interface Props {}

interface State {}

class TournamentWalkthrough extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {}
  }

  render() {
    const {} = this.props
    const {} = this.state
    const onLoadPage = () => {
      const iframeplay = document.getElementById('howplay')
      const element = (iframeplay as any)?.contentDocument.getElementsByClassName('weglot-container')[0]
      element?.setAttribute('style', 'display: none;')
    }

    return (
      <>
        <div className="rule-start">
          <div className="roles" style={{ padding: '0em' }}>
            <iframe onLoad={onLoadPage} src="/faq/how-to-play" id="howplay" height="968px" width="100%"/>
          </div>
        </div>
      </>
    )
  }
}

export default TournamentWalkthrough
