import { Reducer } from 'redux'
import { State, KnownAction, PLACE_CHANGED, PLACE_CLEARED } from '../../types/pages/tournament-bracket-types'

const initialState: State = {
  placeByMatchParticipantId: {},
}

const reducer: Reducer<State, KnownAction> = (state: State | undefined = initialState, action: KnownAction) => {
  switch (action.type) {
    case PLACE_CHANGED:
      return {
        ...state,
        placeByMatchParticipantId: {
          ...state.placeByMatchParticipantId,
          [action.payload.matchParticipantId]: action.payload.place,
        },
      }
    case PLACE_CLEARED: {
      return {
        placeByMatchParticipantId: {},
      }
    }
    default:
      return state
  }
}

export default reducer
