import { createSelector } from 'reselect'
import { OperationTypes } from 'store/logic/operations-logic'
import { ApplicationState } from '../types/common'
import { makeGetIsOperationWithTypeRunning, makeGetIsOperationWithTypeSuccessOrRunning } from './operations-selectors'

const getState = (state: ApplicationState) => state.countries

const getById = createSelector(getState, state => state?.byId ?? {})

const getIdsByRegionId = createSelector(getState, state => state?.idsByRegionId ?? {})

export const makeGetCountry = () =>
  createSelector(
    getById,
    (_: ApplicationState, { countryId }: { countryId: number }) => countryId,
    (byId, countryId) => byId[countryId]
  )

export const makeGetCountries = () =>
  createSelector(
    getById,
    getIdsByRegionId,
    (_: ApplicationState, { regionId }: { regionId: number }) => regionId,
    (byId, ids, regionId) => (ids[regionId] ?? []).map(id => byId[id])
  )

const getLoadCountriesOperationType = createSelector(
  (_, { regionId }: { regionId: number }) => regionId,
  regionId => ({ type: OperationTypes.loadCountries, objectId: regionId })
)
export const makeGetIsCountriesLoading = () => makeGetIsOperationWithTypeRunning(getLoadCountriesOperationType)
export const makeGetIsCountriesRequested = () => makeGetIsOperationWithTypeSuccessOrRunning(getLoadCountriesOperationType)
