export interface APIResponse<T> {
  isError: boolean
  message?: string
  data?: T
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  custom_data?: any
}

export async function ExecuteAPIGet<T>(endPoint: string, queryString?: string, throwError?: boolean) {
  const action = async () => {
    const url = queryString ? `/api/${endPoint}?${queryString}` : `/api/${endPoint}`
    const response = await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      method: 'GET',
      credentials: 'include',
    })
    return handleResponse<T>(response)
  }

  if (throwError) {
    return action()
  } else {
    return catchError<T>(action)
  }
}

export type HTTPMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function fetchApi<T>(endPoint: string, method: HTTPMethod, data?: any) {
  const uri = `/api/${endPoint}`
  const encodedUri = encodeURI(uri)
  const fullUri = data && method === 'GET' ? `${encodedUri}?${ObjectToUrlQuery(data)}` : encodedUri

  let body: RequestInit['body']
  let headers: RequestInit['headers']

  if (data instanceof FormData) {
    body = data
    headers = {}
  } else {
    body = method !== 'GET' ? JSON.stringify(data) : undefined
    headers = {
      'Content-Type': 'application/json; charset=utf-8',
    }
  }

  const response = await fetch(fullUri, {
    headers,
    method,
    body,
    credentials: 'include',
  })
  return handleFetchResponse<T>(response)
}

export async function ExecuteAPIPost<T>(endPoint: string, data?: unknown) {
  try {
    const url = `/api/${endPoint}`
    const response = await fetch(url, {
      body: data ? JSON.stringify(data) : undefined,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      method: 'POST',
      credentials: 'include',
    })
    return handleResponse<T>(response)
  // eslint-disable-next-line no-empty
  } catch {}
}

export async function handleResponse<T>(response: Response) {
  if (response.ok) {
    const result = (await response.json()) as APIResponse<T>
    if (result.isError) {
      throw new Error(result.message)
    }
    return result
  }
  switch (response.status) {
    case 400: {
      const result = (await response.json()) as APIResponse<T>
      throw new Error(result.message)
    }
  }
  throw new Error('An error has occurred')
}

export async function handleFetchResponse<T>(response: Response) {
  if (response.ok) {
    const result = (await response.json()) as APIResponse<T>
    if (result.isError) {
      throw new Error(result.message)
    }
    return result.data
  }
  throw new Error('An error has occurred')
}

export async function executeFormPostData<T>(url: string, postData: unknown) {
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        Accept: 'application/json',
      },
      body: searchParams(postData),
      credentials: 'include',
    })
    return await response.json().then(result => result as T)
  // eslint-disable-next-line no-empty
  } catch {}
}

export function searchParams(data: unknown) {
  try {
    return Object.keys(data)
      .map(key => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
      })
      .join('&')
  // eslint-disable-next-line no-empty
  } catch (error) {}
}

function catchError<T>(action: () => Promise<APIResponse<T>>) {
  try {
    return action()
  // eslint-disable-next-line no-empty
  } catch {}
}

export interface QueryObject {
  [key: string]: unknown
}

export default function ObjectToUrlQuery<T extends QueryObject>(obj: T): string {
  const list = Object.keys(obj)
    .map(key => (obj[key] === undefined ? undefined : `${encodeURI(key)}=${encodeURIComponent(obj[key]?.toString())}`))
    .filter(s => s)
  return list.join('&')
}

export async function ExecuteFormGet<T>(url: string, queryString?: string) {
  try {
    if (queryString) {
      url = `${url}?${queryString}`
    }
    const response = await fetch(url, {
      headers: {
        Accept: '*/*',
      },
      method: 'GET',
    })
    return await response.json().then(result => result as T)
  // eslint-disable-next-line no-empty
  } catch (error) {}
}
