import { useState, useCallback, useRef, MutableRefObject } from 'react'

const observe = (resizeObserver: MutableRefObject<ResizeObserver | null>, onResize: ResizeObserverCallback, node: Element | null) => {
  if (resizeObserver.current) {
    resizeObserver.current.disconnect()
  }
  if (!node) {
    return
  }
  resizeObserver.current = new ResizeObserver(onResize)
  resizeObserver.current.observe(node)
}

export const useClientRect = <T extends HTMLElement>(): [DOMRect | null, (node: T) => void, () => void] => {
  const [rect, setRect] = useState<DOMRect | null>(null)
  const resizeObserver = useRef<ResizeObserver | null>(null)
  const nodeRef = useRef<Element | null>(null)

  const onResize = useCallback((entries: ResizeObserverEntry[]) => {
    setRect(entries[0].target.getBoundingClientRect())
  }, [])

  const ref = useCallback((node: Element) => {
    if (node !== null) {
      nodeRef.current = node
      observe(resizeObserver, onResize, nodeRef.current)
    }
  }, [onResize])

  const reInit = useCallback(() => {
    observe(resizeObserver, onResize, nodeRef.current)
  }, [onResize])
  
  return [rect, ref, reInit]
}
