import React from 'react'
import { cn } from 'utils'
import { DropDownTableColumn, DropDownTableColumnProps } from './DropDownTableColumn'
import { useDropDownTableStyles } from './styles/useDropDownTableStyles'

export type DropDownTableRowColumnProps = DropDownTableColumnProps

export const DropDownTableRowColumn = ({ className, ...otherProps }: DropDownTableRowColumnProps) => {
  const styles = useDropDownTableStyles()

  return <DropDownTableColumn {...otherProps} className={cn(styles.tableRowColumn, className)} />
}
