import { createSelector } from 'reselect'
import { ApplicationState } from '../../types/common'

const getState = (state: ApplicationState) => state.disputesPage

export const getPageInitialized = createSelector(getState, state => state?.pageInitialized || false)

export const getShowClosed = createSelector(getState, state => state?.showClosed || false)

export const getShowOpen = createSelector(getState, state => state?.showOpen || false)
