import { useThemeStyles } from 'components/_hooks'
import React, { ReactNode } from 'react'
import { cn } from 'utils'

import stylesDark from './LabelDark.module.scss'
import stylesLight from './LabelLight.module.scss'

interface LabelProps {
  className?: string
  children: ReactNode
}

export const Label = ({ className, children}: LabelProps) => {
  const styles = useThemeStyles(stylesDark, stylesLight)

  return (
    <>
      <div className={cn(className, styles.label)}>{children}</div>
    </>
  )
}
