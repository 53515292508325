import * as React from 'react'
import { kl_RegionModel } from '../../models/kl_region-model'
import { GeneralUtils } from '../../server/utils/general-utils'
import { RegionUtils } from '../../server/utils/region-utils'
import { TournamentEventPostModelView } from '../../models/tournament-event-view-model'
import { UserModel } from '../../models/user-models'
import { UserUtils } from '../../server/utils/user-utils'
import { GamesUtils } from '../../server/utils/games-utils'
import { GameModel } from '../../models/game-models'
import SelectComponent from '../_shared/SelectComponent'

interface Props {
  tournamentFilters?: TournamentEventPostModelView
  tournamentFiltersChange?: (tournamentFilters: TournamentEventPostModelView) => void
  filterId?: string
  filterClass?: string
}

interface State {
  regions: kl_RegionModel[]
  tournamentFilters: TournamentEventPostModelView
  organizers: UserModel[]
  games: GameModel[]
  filterId: string
  filterClass: string
}

class MyTicketsSearchBar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      organizers: [],
      games: [],
      regions: [],
      tournamentFilters: this.props.tournamentFilters,
      filterId: '',
      filterClass: '',
    }
    this.getData = this.getData.bind(this)
    this.getRegion = this.getRegion.bind(this)
    this.getOrganizers = this.getOrganizers.bind(this)
    this.onChangeTournamentFilters = this.onChangeTournamentFilters.bind(this)
    this.getGames = this.getGames.bind(this)
  }

  async componentDidMount() {
    await this.getData()
  }

  componentWillReceiveProps(props: Props) {
    this.setState({
      tournamentFilters: props.tournamentFilters,
      filterId: props.filterId,
      filterClass: props.filterClass,
    })
  }

  async getData() {
    this.getOrganizers()
    this.getGames()
    this.getRegion()
  }

  async getOrganizers() {
    const result = await UserUtils.GetAllOrganizers()
    if (!result.isError) {
      this.setState({ organizers: result.data })
    } else {
      GeneralUtils.showError(result.message)
    }
  }

  async getGames() {
    const result = await GamesUtils.GetAll()
    if (!result.isError) {
      this.setState({ games: result.data })
    } else {
      GeneralUtils.showError(result.message)
    }
  }

  async getRegion() {
    GeneralUtils.showHideLoadingDiv(true)
    const regions = await RegionUtils.GetAll()
    GeneralUtils.showHideLoadingDiv(false)
    if (!regions.isError) {
      this.setState({ regions: regions.data })
    }
  }

  onChangeTournamentFilters(properyName: string, val: number | string | string[]) {
    const { tournamentFilters } = this.state
    tournamentFilters[properyName] = val
    this.setState({ tournamentFilters }, () => {
      this.props.tournamentFiltersChange(tournamentFilters)
    })
  }

  render() {
    const {} = this.props
    const { regions, organizers, tournamentFilters, games } = this.state
    return (
      <>
        <div id="tickets-filter" className="col-xl-2 col-md-4 col-sm-6 col-12 article__select article-select">
          <div className="article-select__title">Name</div>
          <div className="search mb-0" style={{ bottom: '0px' }}>
            <input
              onChange={e => {
                this.onChangeTournamentFilters(e.target.name, e.target.value)
              }}
              name="name"
              className="search__input"
              type="text"
              placeholder="Search by name"
            />
            <a className="search__clear" href="javascript:void(0)" />
          </div>
        </div>
        <div className="col-xl-2 col-md-4 col-sm-6 col-12 article__select article-select">
          <div className="article-select__title">Organizer</div>
          <SelectComponent
            zIndex={499}
            fieldId="organizer"
            valueField="Organizationname"
            textField="Organizationname"
            placeholder="Select Organi.."
            searchable
            data={organizers}
            selectedValue={tournamentFilters.organizer}
            onSelectionChange={this.onChangeTournamentFilters}
            class1="article-select__select article-select__select--organizer select"
            class2="article-select__select--organizer__title select__title"
            class3="article-select__select--organizer__container select__container"
            class4="article-select__select--organizer__option select__option d-block"
            isImage={false}
          />
        </div>
        <div className="col-xl-2 col-md-4 col-sm-6 col-12 article__select article-select">
          <div className="article-select__title">Game</div>
          <SelectComponent
            zIndex={498}
            fieldId="game"
            valueField="id"
            textField="Gamename"
            placeholder="Select Game"
            searchable={false}
            data={games}
            selectedValue={tournamentFilters.game}
            onSelectionChange={this.onChangeTournamentFilters}
            class1="article-select__select article-select__select--games select"
            class2="article-select__select--organizer__title select__title"
            class3="article-select__select--organizer__container select__container"
            class4="article-select__select--organizer__option select__option d-block"
            isImage={false}
          />
        </div>
        <div className="col-xl-2 col-md-4 col-sm-6 col-12 article__select article-select">
          <div className="article-select__title">Date from</div>
          <div className="article-select__select article-select__input-date-container">
            <input
              onChange={e => {
                this.onChangeTournamentFilters('dateFrom', e.target.value)
              }}
              name="dateFrom"
              value={tournamentFilters.dateFrom && tournamentFilters.dateFrom.toLocaleString()}
              className="article-select__input-date"
              type="date"
            />
          </div>
        </div>
        <div className="col-xl-2 col-md-4 col-sm-6 col-12 article__select article-select">
          <div className="article-select__title">Date to</div>
          <div className="article-select__select article-select__input-date-container">
            <input
              onChange={e => {
                this.onChangeTournamentFilters('dateTo', e.target.value)
              }}
              name="dateTo"
              value={tournamentFilters.dateTo && tournamentFilters.dateTo.toLocaleString()}
              className="article-select__input-date"
              type="date"
            />
          </div>
        </div>
        <div className="col-xl-2 col-md-4 col-sm-6 col-12 article__select article-select">
          <div className="article-select__title">Region</div>
          <SelectComponent
            zIndex={499}
            fieldId="region"
            valueField="RegionName"
            textField="RegionName"
            placeholder="Select Region"
            searchable={false}
            data={regions}
            selectedValue={tournamentFilters.region}
            onSelectionChange={this.onChangeTournamentFilters}
            class1="article-select__select article-select__select--region select"
            class2="article-select__select--organizer__title select__title"
            class3="article-select__select--organizer__container select__container"
            class4="article-select__select--organizer__option select__option d-block"
            isImage={false}
          />
        </div>
      </>
    )
  }
}

export default MyTicketsSearchBar
