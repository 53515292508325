import { ClanMember } from './clan-member-types'
import { ClanType } from './clan-types'
import { makeActionCreator, ReduxAction } from './common'
import { ImageType } from './image-types'
import { CoverImageUpdatedAction, PublicUserType, UserAvatarUpdatedAction, UserUpdatedAction } from './user-types'

export type UserRole = 'Player' | 'Organizer' | 'StuffAdmin' | 'SuperAdmin'

export interface Features {
  LeadGeneration: boolean
}

export interface App {
  TimeZones: TimeZoneInfoDTO[]
  Offline: boolean
  Features: Features
}

export interface AppUser {
  LastNotificationId: number
  Users: PublicUserType[]
  Images: ImageType[]
  Clans: ClanType[]
  ClansMembers: ClanMember[]
}

export interface TimeZoneInfoDTO {
  Abbr: string
  DisplayName: string
  Id: string
}

export interface State {
  timeZones: TimeZoneInfoDTO[]
  offline: boolean
  lastNotificationId: number
  user: PublicUserType
  features: Features
}

export const APP_LOADED = 'APP_LOADED'
export type AppLoadedAction = ReduxAction<typeof APP_LOADED, App>
export const appLoaded = makeActionCreator<App>(APP_LOADED)

export const APP_USER_LOADED = 'APP_USER_LOADED'
export type AppUserLoadedAction = ReduxAction<typeof APP_USER_LOADED, AppUser>
export const appUserLoaded = makeActionCreator<AppUser>(APP_USER_LOADED)

export type KnownAction = AppLoadedAction
  | AppUserLoadedAction
  | UserUpdatedAction
  | UserAvatarUpdatedAction
  | CoverImageUpdatedAction
