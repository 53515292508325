import { EndPoints } from '../endpoints'
import { ExecuteAPIGet, ExecuteAPIPost } from '../server-utils'
import { kl_AdminRedemption } from '../../models/admin-redemption-model'
import { AdminRedemptionFormDataViewModel } from 'models/admin-redemption-form-data-view-model'

export const AdminRedemptionUtils = {
  GetAdminRedemption: async function GetAdminRedemption(data: any) {
    return await ExecuteAPIPost<any[]>(EndPoints.AdminRedemption.GetAdminRedemption, data)
  },
  Save: async function Save(model: kl_AdminRedemption) {
    return await ExecuteAPIPost<number>(EndPoints.AdminRedemption.Save, model)
  },
  Add: async function Add(model: kl_AdminRedemption) {
    return await ExecuteAPIPost<kl_AdminRedemption>(EndPoints.AdminRedemption.Add, model)
  },
  UpdateStatus: async function UpdateStatus(id : number,status :number) {
    return await ExecuteAPIGet<boolean>(EndPoints.AdminRedemption.UpdateStatus, `Id=${id}&Status=${status}`)
  },
  GetFiltredAdminRedemption: async function GetFiltredAdminRedemption(filter: AdminRedemptionFormDataViewModel) {
    return await ExecuteAPIPost<any[]>(EndPoints.AdminRedemption.GetFiltredAdminRedemptions, filter)
  }
}

