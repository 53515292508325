import { useDialog } from 'components/_hooks'
import { UpdateImageModal } from 'components/_modals'
import React from 'react'
import { useUpdateTournamentCoverImage } from 'store/logic/tournaments-logic'
import { ImageType, ImageTypeEnum, MediaType } from 'store/types/image-types'

interface UpdateCoverProps {
  tournamentId: number
  onUpdated: () => void
}

export const UpdateCover = ({ tournamentId, onUpdated }: UpdateCoverProps) => {

  const updateTournamentCover = useUpdateTournamentCoverImage(tournamentId)

  const getImageType = (mediaType: MediaType): ImageTypeEnum => {
    const imageType = mediaType === MediaType.Image
      ? ImageTypeEnum.TournamentCoverImage
      : ImageTypeEnum.TournamentCoverVideo
    return imageType
  }

  const saveCover = async (image: ImageType) => {
    await updateTournamentCover(image.Id)
    onUpdated()
  }

  const [updateImageModal, openUpdateImageModal] = useDialog(closeDialog => (
    <UpdateImageModal
      onClose={closeDialog}
      header="Update cover image"
      dropText="Drag a picture, gif or video to this window"
      fileTypes={['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'video/mp4']}
      recommendedText="Recommended Image Size: 2560 x 1440. Cover Image/Video (Max 10 Sec. GIF,MP4, Video)"
      getImageTypeCallback={getImageType}
      saveImage={saveCover}
      aspectRatioType="tournamentCover"
      crop
    />
  ))

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault()
    openUpdateImageModal()
  }

  return (
    <>
      <a
        className="article-header__change-header tournament-article-header__change-header ct__article-header__change-header"
        href="#"
        style={{ zIndex: 10 }}
        onClick={handleClick}
      >
        Update cover image
      </a>
      {updateImageModal}
    </>
  )
}
