import { Reducer } from 'redux'
import { CLAN_MEMBER_REMOVED } from 'store/types/clan-member-types'
import { ClanPageState, FILTERED_PLAYERS_CLEARED, FILTERED_PLAYERS_LOADED, KnownAction, MEMBERS_PAGE_CHANGED, MEMBERS_PAGE_LOADED, PLAYERS_INVITED, PLAYRES_FILTERS_CHANGED, SELECTED_PLAYERS_CHANGED } from 'store/types/pages/clan-page-types'

const clanPageInitialState: ClanPageState = {
  allPlayersCount: 0,
  playersIds: [],
  playersFilters: {
    page: 0,
    username: '',
  },
  selectedPlayersIds: [],
  allMembersCount: 0,
  membersIds: [],
  membersPage: 0,
}

const reducer: Reducer<ClanPageState, KnownAction> = (state: ClanPageState | undefined = clanPageInitialState, action: KnownAction) => {
  switch (action.type) {
    case FILTERED_PLAYERS_LOADED: {
      return {
        ...state,
        allPlayersCount: action.payload.allPlayersCount,
        playersIds: action.payload.players.Users.map(x => x.Id),
      }
    }
    case FILTERED_PLAYERS_CLEARED: {
      return {
        ...state,
        allPlayersCount: 0,
        playersIds: [],
      }
    }
    case PLAYRES_FILTERS_CHANGED: {
      return {
        ...state,
        playersFilters: action.payload,
      }
    }
    case SELECTED_PLAYERS_CHANGED: {
      return {
        ...state,
        selectedPlayersIds: action.payload,
      }
    }
    case PLAYERS_INVITED: {
      return {
        ...state,
        allPlayersCount: 0,
        playersFilters: {
          page: 0,
          username: '',
        },
        playersIds: [],
        selectedPlayersIds: [],
      }
    }
    case MEMBERS_PAGE_CHANGED: {
      return {
        ...state,
        membersPage: action.payload,
      }
    }
    case MEMBERS_PAGE_LOADED: {
      return {
        ...state,
        membersIds: action.payload.Model.ClansMembers.map(x => x.UserId),
        allMembersCount: action.payload.AllMembersCount,
      }
    }
    case CLAN_MEMBER_REMOVED: {
      return {
        ...state,
        membersIds: state.membersIds.filter(userId => userId !== action.payload.userId),
      }
    }
    default:
      return state
  }
}

export default reducer
