import { createSelector } from 'reselect'
import { OperationTypes } from 'store/logic/operations-logic'
import { ApplicationState } from 'store/types/common'
import { makeGetIsOperationWithTypeRunning, makeGetIsOperationWithTypeSuccessOrRunning } from './operations-selectors'


const getState = (state: ApplicationState) => state.matchSettings

const matchSettingsByMatchId = createSelector(getState, state => state.matchSettingsByMatchId || {})

export const makeGetMatchSettings = () =>
  createSelector(
    matchSettingsByMatchId,
    (_: ApplicationState, matchId: number) => matchId,
    (byMatchId, matchId) => {
      return byMatchId[matchId] 
    }
  )

export const getMatchSettings = createSelector(
  matchSettingsByMatchId,
  (_: ApplicationState, matchId: number) => matchId,
  (byMatchId, matchId) => {
    return byMatchId[matchId] 
  }
)


const getMatchLinksOperationType = createSelector(
  (_, matchId: number) => matchId,
  matchId => ({ type: OperationTypes.loadMatchSettings, objectId: matchId })
)

export const makeGetIsMatchSettingsLoading = () => makeGetIsOperationWithTypeRunning(getMatchLinksOperationType)
export const makeGetIsMatchSettingsRequested = () => makeGetIsOperationWithTypeSuccessOrRunning(getMatchLinksOperationType)
