import { createSelector } from 'reselect'
import { OperationTypes } from 'store/logic/operations-logic'
import { ApplicationState } from 'store/types/common'
import { makeGetIsAnyOperationWithTypeRunning } from '../operations-selectors'

const getState = (state: ApplicationState) => state.organizerEventsPage

export const getOrganizerEventsPageFilters = createSelector(getState, state => state?.filters)

export const getMyOrganizerUpcomingTournamentsIds = createSelector(getState, state => (state?.upcomingTournamentsIds ?? []))
export const getMyOrganizerActiveTournamentsIds = createSelector(getState, state => (state?.activeTournamentsIds ?? []))
export const getMyOrganizerFinishedTournamentsIds = createSelector(getState, state => (state?.finishedTournamentsIds ?? []))
export const getMyOrganizerCanceledTournamentsIds= createSelector(getState, state => (state?.canceledTournamentsIds ?? []))


export const getAllMyOrganizerUpcomingTournamentsCount = createSelector(getState, state => state?.allUpcomingTournamentsCount ?? 0)
export const getAllMyOrganizerActiveTournamentsCount = createSelector(getState, state => state?.allActiveTournamentsCount ?? 0)
export const getAllMyOrganizerFinishedTournamentsCount = createSelector(getState, state => state?.allFinishedTournamentsCount ?? 0)
export const getAllMyOrganizerCanceledTournamentsCount = createSelector(getState, state => state?.allCanceledTournamentsCount ?? 0)


export const getIsMyOrganizerUpcomingTournamentsLoading = makeGetIsAnyOperationWithTypeRunning(() => ({
  type: OperationTypes.loadMyOrganizerUpcomingTournaments,
}))

export const getIsMyOrganizerActiveTournamentsLoading = makeGetIsAnyOperationWithTypeRunning(() => ({
  type: OperationTypes.loadMyOrganizerActiveTournaments,
}))

export const getIsMyOrganizerFinishedTournamentsLoading = makeGetIsAnyOperationWithTypeRunning(() => ({
  type: OperationTypes.loadMyOrganizerFinishedTournaments,
}))


export const getIsMyOrganizerCanceledTournamentsLoading = makeGetIsAnyOperationWithTypeRunning(() => ({
  type: OperationTypes.loadMyOrganizerCanceledTournaments,
}))
