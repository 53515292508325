import { useFormatDateTime } from 'components/_hooks'
import React from 'react'


interface Props {
  tournamentDetailId: number
  startDate: Date
  onSelectTournament: (id: number) => void
  selectedTournamentDetailId: number
}


const QualifierTournamentDate = ({ tournamentDetailId, startDate, onSelectTournament, selectedTournamentDetailId }: Props) => {

  const date = useFormatDateTime(tournamentDetailId, startDate.toString(), 'redux')
  
  return (
    <label
      onClick={() => {
        onSelectTournament(tournamentDetailId)
      }}
      className={selectedTournamentDetailId === tournamentDetailId ? 'window__cancel-container _active' : 'window__cancel-container' } 
      htmlFor={`cancellation_${tournamentDetailId}`}
    >
      <span className="window__cancel-time">{date.date}</span>
      <span className="window__cancel-date">{`at ${date.time}`}</span>
    </label>
  )
}

export default QualifierTournamentDate
