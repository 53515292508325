import React, { ReactNode } from 'react'
import { TipPlace, WithToolTip } from 'components/_shared'
import { cn } from 'utils'
import stylesDark from './SelectDark.module.scss'
import stylesLight from './SelectLight.module.scss'
import { OptionTooltipIcon } from './SelectTypes'
import { useThemeStyles } from 'components/_hooks'

export interface SelectOptionProps<T> {
  selected?: boolean
  onSelect: (option: T) => void
  option: T
  labelExtractor?: (option: T) => string
  tooltipExtractor?: (option: T) => ReactNode | undefined
  optionTooltipIcon?: OptionTooltipIcon
  tipPlace?: TipPlace
}

export const SelectOption = <T extends unknown>({
  selected,
  onSelect,
  option,
  labelExtractor,
  tooltipExtractor,
  optionTooltipIcon,
  tipPlace,
}: SelectOptionProps<T>) => {
  const styles = useThemeStyles(stylesDark, stylesLight)
  
  const tooltip = tooltipExtractor ? tooltipExtractor(option) : undefined
  const hasTooltip = tooltip !== undefined

  const handleClick = () => {
    if (!hasTooltip) {
      onSelect(option)
    }
  }

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault()
  }

  return (
    <button className={cn(styles.item, selected && styles.selected)} onClick={handleClick}>
      {labelExtractor(option)}
      {hasTooltip && (
        <WithToolTip
          tip={tooltip}
          place={tipPlace}
          left={8}
          onMouseDown={handleMouseDown}
          className={styles.tooltip}
        >
          <div className={selected ? styles.selectedTip : (optionTooltipIcon === 'info' ? styles.info : styles.blocked)} />
        </WithToolTip>
      )}
    </button>
  )
}
