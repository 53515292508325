import { useHistory } from 'react-router'

interface historyState {
  kombatlink: boolean
}


export const useKlHistory = <T>() => {
  const history = useHistory<historyState & T>()
  return {
    push: (path: string, state?: T) => {
      history.push(path, { ...state, kombatlink: true })
    },
    replace: (path: string, state?: T) => {
      history.replace(path, { ...state, kombatlink: true })
    },
    goBack: () => {
      if (history.location.state?.kombatlink) {
        history.goBack()
      } else {
        history.push('/', { ...({} as T), kombatlink: true })
      }
    },

    state: history.location.state,
  }
}
