import { EventsTab } from 'components/_pages/common/Events/Events'
import { makeActionCreator, ReduxAction } from '../common'
import { GameType } from '../game-types'
import { RegionType } from '../region-types'
import { TournamentType } from '../tournaments-types'
import { PublicUserType } from '../user-types'


export const defaultPlayerEventsFilters: PlayerEventsPageFilters = {
  currentPage: 0,
  currentTab: 'live',
  name: '',
  selectedGames: [],
  selectedOrganizers: [],
  dateFrom: null,
  dateTo: null,
  selectedRegion: null,
}


export interface PlayerEventsPageFilters {
  currentTab: EventsTab
  currentPage: number
  name: string
  selectedGames: GameType[]
  selectedOrganizers: PublicUserType[]
  dateFrom: Date
  dateTo: Date
  selectedRegion: RegionType
}


export interface PlayerEventsPageState {
  filters: PlayerEventsPageFilters

  onlineTournamentsIds: number[]
  allOnlineTournamentsCount: number
  liveTournamentsIds: number[]
  allLiveTournamentsCount: number
}

export const PLAYER_EVENTS_FILTERS_CHANGED = 'PLAYER_EVENTS_FILTERS_CHANGED'
export interface PlayerEventsFilterChangedPayLoad {
  filters: PlayerEventsPageFilters
}

export type PlayerEventsFiltersChangedAction = ReduxAction<typeof PLAYER_EVENTS_FILTERS_CHANGED, PlayerEventsFilterChangedPayLoad>
export const playerEventsFiltersChanged = makeActionCreator<PlayerEventsFilterChangedPayLoad>(PLAYER_EVENTS_FILTERS_CHANGED)


export const PLAYER_ONLINE_TOURNAMENTS_LOADED = 'PLAYER_ONLINE_TOURNAMENTS_LOADED'
interface PlayerOnlineTournamentsLoadedPayload {
  tournaments: TournamentType[]
  allPlayerOnlineTournamentsCount: number
}
export type PlayerOnlineTournamentsLoadedAction = ReduxAction<typeof PLAYER_ONLINE_TOURNAMENTS_LOADED, PlayerOnlineTournamentsLoadedPayload>
export const playerOnlineTournamentsLoaded = makeActionCreator<PlayerOnlineTournamentsLoadedPayload>(PLAYER_ONLINE_TOURNAMENTS_LOADED)

export const PLAYER_LIVE_TOURNAMENTS_LOADED = 'PLAYER_LIVE_TOURNAMENTS_LOADED'
interface PlayerLiveTournamentsLoadedPayload {
  tournaments: TournamentType[]
  allPlayerLiveTournamentsCount: number
}
export type PlayerLiveTournamentsLoadedAction = ReduxAction<typeof PLAYER_LIVE_TOURNAMENTS_LOADED, PlayerLiveTournamentsLoadedPayload>
export const playerLiveTournamentsLoaded = makeActionCreator<PlayerLiveTournamentsLoadedPayload>(PLAYER_LIVE_TOURNAMENTS_LOADED)


export type KnownAction = PlayerOnlineTournamentsLoadedAction
  | PlayerLiveTournamentsLoadedAction
  | PlayerEventsFiltersChangedAction
