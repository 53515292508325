import { Reducer } from 'redux'
import { KnownAction, ConsoleState, USER_CONSOLES_LOADED } from 'store/types/console-types'
import { GAME_DETAIL_LOADED } from 'store/types/game-types'
import { ADMIN_ACTIVE_TOURNAMENTS_LOADED, ADMIN_CANCELED_TOURNAMENTS_LOADED, ADMIN_FINISHED_TOURNAMENTS_LOADED, ADMIN_UPCOMING_TOURNAMENTS_LOADED } from 'store/types/pages/admin-tournaments-page-types'
import { MY_ORGANIZER_ACTIVE_TOURNAMENTS_LOADED, MY_ORGANIZER_CANCELED_TOURNAMENTS_LOADED, MY_ORGANIZER_FINISHED_TOURNAMENTS_LOADED, MY_ORGANIZER_UPCOMING_TOURNAMENTS_LOADED } from 'store/types/pages/organizer-events-page-types'
import { MAIN_TOURNAMENTS_LOADED, QUALIFY_TOURNAMENTS_LOADED, UPCOMING_TOURNAMENTS_LOADED } from 'store/types/pages/tournaments-page-types'
import { GAME_MAIN_TOURNAMENTS_LOADED, GAME_QUALIFY_TOURNAMENTS_LOADED, GAME_UPCOMING_TOURNAMENTS_LOADED, ORGANIZER_RECENT_TOURNAMENTS_LOADED, TOP_TOURNAMENTS_LOADED } from 'store/types/tournaments-types'

export const initialConsoleState: ConsoleState = {
  idsByUserId: {},
  idsByTournamentDetailId: {},
  idsByGameId: {},
}

const reducer: Reducer<ConsoleState, KnownAction> = (state: ConsoleState | undefined = initialConsoleState, action: KnownAction) => {
  switch (action.type) {
    case USER_CONSOLES_LOADED: {
      return {
        ...state,
        idsByUserId: {
          ...state.idsByUserId,
          [action.payload.userId]: action.payload.consoles,
        },
      }
    }
    case GAME_DETAIL_LOADED: {
      return {
        ...state,
        idsByGameId: {
          ...state.idsByGameId,
          ...action.payload.games.GameConsoles.reduce((prev, current) => ({...prev, [current.GameId]: current.Consoles}), {}),
        },
      }
    }
    case UPCOMING_TOURNAMENTS_LOADED:
    case MAIN_TOURNAMENTS_LOADED:
    case QUALIFY_TOURNAMENTS_LOADED:
    case MY_ORGANIZER_UPCOMING_TOURNAMENTS_LOADED:
    case MY_ORGANIZER_ACTIVE_TOURNAMENTS_LOADED:
    case MY_ORGANIZER_FINISHED_TOURNAMENTS_LOADED:
    case MY_ORGANIZER_CANCELED_TOURNAMENTS_LOADED:
    case TOP_TOURNAMENTS_LOADED:
    case GAME_UPCOMING_TOURNAMENTS_LOADED:
    case GAME_MAIN_TOURNAMENTS_LOADED:
    case GAME_QUALIFY_TOURNAMENTS_LOADED:
    case ORGANIZER_RECENT_TOURNAMENTS_LOADED:
    case ADMIN_UPCOMING_TOURNAMENTS_LOADED:      
    case ADMIN_ACTIVE_TOURNAMENTS_LOADED:
    case ADMIN_FINISHED_TOURNAMENTS_LOADED: 
    case ADMIN_CANCELED_TOURNAMENTS_LOADED: {
      return {
        ...state,
        idsByTournamentDetailId: {
          ...state.idsByTournamentDetailId,
          ...action.payload.TournamentConsoles.reduce((prev, current) => ({...prev, [current.TournamentDetailId]: current.Consoles}), {}),
        },
      }
    } 
    default:
      return state
  }
}

export default reducer
