import { useThemeStyles } from 'components/_hooks'
import React, { ReactNode } from 'react'
import { cn } from 'utils'

import stylesDark from './FiltersDark.module.scss'
import stylesLight from './FiltersLight.module.scss'


export interface SelectsPopupProps {
  children?: ReactNode
  title: string
  opened: boolean
  close: CallableFunction
  className?: string
}

export const Filters = ({ title, children, opened, close, className }: SelectsPopupProps) => {
  const styles = useThemeStyles(stylesDark, stylesLight)

  const handleCloseClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()
    close()
  }

  return (
    <div className={cn(styles.articleSelectsPopup, className, opened && styles.opened, 'm-0--30 p-r-15 row')}>
      <div className="container-fluid">
        <div className="row m-l-0">
          <div className={styles.articleSelectsPopupHeader}>
            {title}
            <a className={styles.articleSelectsPopupClose} href="#" onClick={handleCloseClick}></a>
          </div>
        </div>
        <div className="row justify-content-start align-items-start">
          {children}
        </div>
      </div>
    </div>
  )
}
