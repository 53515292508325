import React from 'react'
import { TournamentUtils } from 'server/utils/tournament-utils'
import { UserUtils } from 'server/utils/user-utils'
import { UserModel } from 'models/user-models'
import { GeneralUtils } from 'server/utils/general-utils'
import { TournamentViewModel_kl } from 'models/tournament-viewmodel'
import { Redirect } from 'react-router-dom'
import { kl_TournementTicketsCartDetailViewModel } from 'models/tournament-cart-model'
import { kl_RegionModel } from 'models/kl_region-model'
import { RegionUtils } from 'server/utils/region-utils'
import TournamentDetailComponent from './TournamentDetailComponent'
import TournamentParticipantsComponent from './Participants/TournamentParticipantsComponent'
import TournamentWatchComponent from './TournamentWatchComponent'
import TournamentRules from './Rules/TournamentRules'
import TournamentWalkthrough from './TournamentWalkthrough'
import { TournamentTicketRecordViewModel } from 'models/tournament-ticket-record-viewmodel'
import { kl_TournamentRoundsViewModel, kl_TournamentViewModel } from 'models/kl_tournament_viewmodel'
import { ConsoleEnum } from 'models/kl_game-consoles-model'
import './TournamentDetails.scss'
import { ModalUtils } from 'server/utils/modal-utils'
import { EventTypeEnum } from 'models/tournament-models'
import { cancelTournament } from 'store/logic/tournaments-logic'
import TournamentDetailRegistrationContainer from './Registration/TournamentDetailRegistrationContainer'
import { TournamentBracket, TournamentDetailsCover } from 'components/_shared'
import { TournamentStatusEnum } from 'consts'
import { ParticipantType } from 'store/types/participants-types'
import { fetchParticpants } from 'store/logic/participants-logic'
import { cn, defaultFormatDate, getUser } from 'utils'
import { UpdateCover } from './_shared/UpdateCover'
import { MediaType } from 'store/types/image-types'
import { BackButton } from './_shared/BackButton'
import { ConvertDateToFormattedDateString, ConvertDateToFormattedTimeString } from 'components/_shared/TournamentWizard/_shared/TimeZoneSelector/ZonedDate'
import { ContainerName } from 'components/_hooks'

export const tournamentDetailTabs = {
  overview: 'Overview',
  participants: 'Participants',
  brackets: 'Brackets',
  rules: 'Rules',
  walkthrough: 'Walkthrough',
  settings: 'custom-match-settings',
  draft: 'Draft',
}

export type TournamentDetailTab = keyof typeof tournamentDetailTabs

type Props = {
  tournamentId?: number
  tournamentCreateModel?: kl_TournamentViewModel
  isAdmin?: boolean
  showFooter?: boolean
  coverDataUrl?: string
  coverMediaType?: MediaType
  isShowFromWizardPage?: boolean
  currentTab: TournamentDetailTab
  onChangeTab: (tab: TournamentDetailTab) => void
}

type State = {
  //currentTab: string
  tournament: TournamentViewModel_kl
  tournamentRounds: kl_TournamentRoundsViewModel[]
  organizer: UserModel
  regions: kl_RegionModel[]
  isRedirect: boolean
  isTournamentCancelled: boolean
  isEditTournament: boolean
  participants?: ParticipantType[]
  tournamentTicketRecords: TournamentTicketRecordViewModel[]
  message: string
  index: number
  checkedTicket: boolean
  tournamentTicketArray: kl_TournementTicketsCartDetailViewModel[]
  quantityError: string
  singleQuantity: number
  isParticipant: boolean
  joinTournamentMessage: string
  isTicketCartOpen: boolean
  repeatTournamentDetails: TournamentViewModel_kl[]
  selectedCancelRepeatTournament: number[]
  isEditTornament: boolean
  showCancelParticipationModal: boolean
}


const getTabClassName = (isActive: boolean) => {
  const className = 'col-auto tab-menu__link ct__tab-menu__link tournament--tab-menu__link'
  if (!isActive) {
    return className
  }
  return cn(className, 'tab-menu__link--active ct__tab-menu__link--active tournament--tab-menu__link--active')
}

class TournamentDetails extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      tournament: undefined,
      tournamentRounds: [],
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      organizer: Object(),
      regions: [],
      isRedirect: false,
      isTournamentCancelled: false,
      isEditTournament: false,
      tournamentTicketRecords: [],
      message: '',
      index: -1,
      checkedTicket: false,
      tournamentTicketArray: [],
      quantityError: '',
      singleQuantity: 0,
      isParticipant: false,
      joinTournamentMessage: '',
      isTicketCartOpen: false,
      repeatTournamentDetails: [],
      selectedCancelRepeatTournament: [],
      isEditTornament: false,
      showCancelParticipationModal: false,
      isShowFromWizardPage: false,
    } as State
  }

  async componentDidMount() {
    await this.getData() 
  }

  async componentDidUpdate(prevProps) {
    if (this.props.tournamentId !== prevProps.tournamentId)
    {
      await this.getData() 
    }
  }
 
  getRepeatTournament = async tournamentId => {
    const result = await TournamentUtils.GetRepeatTournament(tournamentId)
    if (!result.isError) {
      const repeatTournamentDetails = result.data
      this.setState({ repeatTournamentDetails: repeatTournamentDetails })
    } else {
      GeneralUtils.showError(result.message)
    }
  }

  selectCancelRepeatTournament = id => {
    const { selectedCancelRepeatTournament } = this.state
    const index = selectedCancelRepeatTournament.indexOf(parseInt(id))
    if (index != -1) {
      selectedCancelRepeatTournament.splice(index, 1)
    }
    else {
      selectedCancelRepeatTournament.push(parseInt(id))
    }
    this.setState({ selectedCancelRepeatTournament: selectedCancelRepeatTournament })
  }

  isSelected = id => {
    const { selectedCancelRepeatTournament } = this.state
    const index = selectedCancelRepeatTournament.indexOf(parseInt(id))
    if (index != -1) {
      return true
    }
    else {
      return false
    }
  }

  deleteRepeatTournaments = async () => {
    const { selectedCancelRepeatTournament } = this.state
    if (selectedCancelRepeatTournament.length > 0) {
      const result = await TournamentUtils.DeleteRepeatTournaments(selectedCancelRepeatTournament)
      if (!result.isError) {
        if (result.custom_data === true) {
          this.setState({
            isTournamentCancelled: true,
          })
        }
      }
    }
  }

  getData = async () => {
    if (this.props.tournamentId) {
      await this.getTournament()
      await this.getAllQualifierTournaments()
    } else if (this.props.tournamentCreateModel) {
      this.setTournamentFromProps()
    }
    await this.getRegion()
    await this.getOrganizer()
    await this.tournamentParticipants()
  }

  getRegion = async () => {
    GeneralUtils.showHideLoadingDiv(true)
    const regions = await RegionUtils.GetAll()
    if (!regions.isError) {
      this.setState({ regions: regions.data })
      GeneralUtils.showHideLoadingDiv(false)
    } else {
      GeneralUtils.showHideLoadingDiv(false)
    }
  }

  setTournamentFromProps = () => {
    const { tournamentCreateModel } = this.props
    const tournament = {
      AutomaticBracketResizing: tournamentCreateModel.AutomaticBracketResizing,
      CityId: tournamentCreateModel.CityId,
      City: tournamentCreateModel.City,
      CountryId: tournamentCreateModel.CountryId,
      Country: tournamentCreateModel.Country,
      GameConsolesIds: tournamentCreateModel.TournamentConsoles,
      GameId: tournamentCreateModel.GameId,
      GameName: tournamentCreateModel.GameName,
      HostedByOrganizer: tournamentCreateModel.HostedByOrganizer,
      Id: tournamentCreateModel.Id,
      Information: tournamentCreateModel.Information,    
      MaxNumberOfParticipients: tournamentCreateModel.MaxNumberOfParticipients,
      MaxNumberOfRounds: tournamentCreateModel.MaxNumberOfRounds,
      Name: tournamentCreateModel.Name,
      NumberOfPlacements: tournamentCreateModel.NumberOfPlacements,
      Organizationname: tournamentCreateModel.Organizationname,
      Organizerid: tournamentCreateModel.OrganizerId,
      ParticipientsPerMatch: tournamentCreateModel.ParticipientsPerMatch,
      Province: tournamentCreateModel.Province,
      Region: tournamentCreateModel.Region,
      RegionId: tournamentCreateModel.RegionId,
      RegistrationClosesOn: tournamentCreateModel.TournamentDetails[0].RegistrationClosesOn,
      RegistrationOpensOn: tournamentCreateModel.TournamentDetails[0].RegistrationOpensOn,
      Rules: tournamentCreateModel.Rules,
      StartDate: tournamentCreateModel.TournamentDetails[0].StartDate,
      StreamingLink: tournamentCreateModel.StreamingLink,
      TournamentMode: tournamentCreateModel.TournamentMode,
      TournamentModeId: tournamentCreateModel.TournamentModeId,
      TournamentStatus: 'Pending',
      TournamentStatusId: 2,
      TournamentStructure:
      tournamentCreateModel.TournamentStructureId == 1
        ? 'Single Elimination'
        : tournamentCreateModel.TournamentStructureId == 2
          ? 'Double Elimination'
          : tournamentCreateModel.TournamentStructureId == 3
            ? 'Single Round FFA (Free For All)'
            : tournamentCreateModel.TournamentStructureId == 4
              ? 'Elimination Round FFA (Free For All)'
              : '',
      TournamentStructureId: tournamentCreateModel.TournamentStructureId,
      VenueLatitude: tournamentCreateModel.VenueLatitude,
      VenueLocation: tournamentCreateModel.VenueLocation,
      VenueLongitude: tournamentCreateModel.VenueLongitude,
      VenueName: tournamentCreateModel.VenueName,
      VenueSeatingCapacity: tournamentCreateModel.VenueSeatingCapacity,
      VenueType: tournamentCreateModel.VenueTypeId == 1 ? 'Online Event' : tournamentCreateModel.VenueTypeId == 2 ? 'Live Event' : '',
      VenueTypeId: tournamentCreateModel.VenueTypeId,
      TournamentSettings: tournamentCreateModel.TournamentSettings,
      DiscordLink: tournamentCreateModel.DiscordLink,
      ContactEmail: tournamentCreateModel.ContactEmail,
      EventTypeId: tournamentCreateModel.EventTypeId,
      CoverId : this.props.coverDataUrl ? undefined : tournamentCreateModel.CoverId
    } as TournamentViewModel_kl 
    

    this.setState({
      tournament,
      tournamentRounds: tournamentCreateModel.TournamentDetails[0].TournamentRounds,
    })
  }
  getTournamentDetail = async(tournamentId)=>{
    const result = await TournamentUtils.GetTournamentViewModelByID(tournamentId, true)
    return result
  }
  getTournament = async () => {
    GeneralUtils.showHideLoadingDiv(true)
    const { tournamentId } = this.props
    const result = await this.getTournamentDetail(tournamentId, true)
    if (!result.isError) {
      const tournament = result.data
      this.setState({ tournament }, () => {
        void this.getTournamentRounds()
        if ((tournament.EventTypeId === EventTypeEnum.Qualifier || tournament.EventTypeId === EventTypeEnum.Normal) && tournament.RepeatTypeId != null) {
          void this.getRepeatTournament(tournament.TournamentId)
        }
      })
      GeneralUtils.showHideLoadingDiv(false)
    } else {
      GeneralUtils.showError(result.message)
      GeneralUtils.showHideLoadingDiv(false)
    }
  }
  reloadTournament = async(tournamentId:number)=>{
    const result = await this.getTournamentDetail(tournamentId, true)
    if (!result?.isError) {
      const tournament = result?.data
      this.setState({ tournament })
    }
  }

  getAllQualifierTournaments = async () => {
    const { tournament } = this.state
    tournament.allQualifierTournaments = []
    const qualifierData = await TournamentUtils.GetAllQualifierTournamentsByMainTournamentId(this.state.tournament.Id)
    const t = qualifierData.data.map(x => x.TournamentId)
    const unique = [...new Set(t)]
    const allQualifierTournaments = unique.map(value => qualifierData.data.filter(x => x.TournamentId === value))
    tournament.allQualifierTournaments = allQualifierTournaments
    this.setState({ tournament })
  }

  getTournamentRounds = async () => {
    GeneralUtils.showHideLoadingDiv(true)
    const { tournamentId } = this.props
    const result = await TournamentUtils.GetTournamentRounds(tournamentId)
    if (!result.isError) {
      const tournamentRounds = result.data
      this.setState({
        tournamentRounds,
      })
      GeneralUtils.showHideLoadingDiv(false)
    } else {
      GeneralUtils.showError(result.message)
      GeneralUtils.showHideLoadingDiv(false)
    }
  }

  getOrganizer = async () => {
    const { tournament } = this.state
    const result = await UserUtils.GetAllOrganizers(tournament.Organizerid)
    if (!result.isError) {
      if (result.data[0]) {
        this.setState({ organizer: result.data[0] })
      }
    } else {
      GeneralUtils.showError(result.message)
    }
  }

  tournamentParticipants = async () => {
    const { Id } = this.state.tournament
    if (Id > 0) {
      GeneralUtils.showHideLoadingDiv(true)
      const model = await fetchParticpants(Id)
      model.TournamentParticipants.sort((a, b) => a.PlaceFrom - b.PlaceFrom)
      this.setState({
        participants: model.TournamentParticipants,
      })
      GeneralUtils.showHideLoadingDiv(false)
    }
  }

  cancelTournament = async () => {

    const { tournament } = this.state
    const { tournamentId } = this.props
    if ((tournament.EventTypeId === EventTypeEnum.Qualifier || tournament.EventTypeId === EventTypeEnum.Normal) && tournament.RepeatTypeId != null) {
      ModalUtils.initModals()
      ModalUtils.openModal('cancellation')
      this.setState({
        selectedCancelRepeatTournament: [parseInt(tournamentId.toString())],
      })
    }
    else {
      await cancelTournament(tournament.Id)
      this.setState({
        isTournamentCancelled: true,
      })
    }
  }

  editTournament = () => {
    const { tournament } = this.state
    if (tournament.EventTypeId === EventTypeEnum.Qualifier && tournament.RepeatTypeId !== null) {
      document.getElementById('modal_repeated-event-warning').className = 'modal-container modal-container--opened'
    }
    else {
      this.setState({
        isEditTournament: true,
      })
    }
  }

  onTicketCartOpen = () => {
    this.setState({ isTicketCartOpen: true })
  }

  onTicketCartClose = () => {
    this.setState({ isTicketCartOpen: false })
  }

  onChangeTab = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, tab: TournamentDetailTab) => {
    event.preventDefault()
    this.props.onChangeTab(tab)
  }

  render() {
    const user = getUser()
    const url = window.location.href
    const isAdmin = url.toLowerCase().indexOf('/admin/') != -1
    const {
      tournament,
      isRedirect,
      isTournamentCancelled,
      isEditTournament,
      organizer,
      regions,
      participants,
      repeatTournamentDetails,
      selectedCancelRepeatTournament,
    } = this.state

    const isDetailpageReq = url.indexOf('tournament-detail') > -1
    if (isRedirect) {
      return <Redirect push to="/cart" />
    }

    if (isTournamentCancelled && isAdmin) {
      return <Redirect push to="/admin/tournaments" />
    } else if (isTournamentCancelled) {
      return <Redirect push to="/tournaments" />
    }

    if (isEditTournament && isAdmin) {
      return <Redirect push to={`/admin/edit-tournament/${tournament.Id}`} />
    } else if (isEditTournament) {
      return <Redirect push to={`/edit-tournament/${tournament.Id}`} />
    }

    return (
      <>
      <div className={this.props.currentTab == 'overview' ? 'show-tournament-header-cover' : ''}>
        {this.props.isShowFromWizardPage != true && (
          <BackButton />
        )}

        {tournament !== undefined && (
          <>
            {(isAdmin || (user && tournament.Organizerid == user.id)) && (!this.props.tournamentCreateModel || isAdmin) && (
              <>
                <div className="row m-0 cover-btn-container">
                  {isAdmin && (
                    <div className="col-md col-12 p-0-5">
                      <div className="article__tournament-title">
                        {tournament.GameName ? `${tournament.GameName}:` : ''} {tournament.Name} (T # {tournament.Id})
                      </div>
                    </div>
                  )}
                  {(isDetailpageReq || (isAdmin && tournament.Id)) && tournament.TournamentStatusId !== TournamentStatusEnum.Active && tournament.TournamentStatusId !== TournamentStatusEnum.Finished && tournament.TournamentStatusId !== TournamentStatusEnum.Cancel && (
                    <div className="col-md p-0-5 cover-btn-wrapper">
                      <a
                        className="red-button red-button--filled article__cancel-tournament float-right"
                        onClick={() => {this.cancelTournament()}}
                      >
                        cancel tournament
                      </a>

                      <a
                        className="m-0-10 blue-button blue-button--filled article__cancel-tournament float-right"
                        onClick={() => {
                          this.editTournament()
                        }}
                      >
                        Edit
                      </a>

                    </div>
                  )}
                </div>
                <br />
              </>
            )}

            <div className="content">
              <div className="tournament-content">
                <div className="row m-0">
                  <div className="col-12 m-0 p-0">
                    <div className="mb-0 article__header-container tournament-article__header-container">
                      <TournamentDetailsCover
                        coverId={tournament.CoverId}
                        coverDataUrl={this.props.coverDataUrl}
                        coverMediaType={this.props.coverMediaType}
                        containerName={ContainerName.TournamentDetail}
                      />
                      <div className="row article-header__content tournament--article-header__content ct__article-header__content flex-xl-nowrap flex-wrap justify-content-start align-items-start header-custom">
                        <div className="col p-0 mr-auto">
                          <div className="col-12 p-r-sm-5">
                            <div className="col-12 px-0 article-header__container">
                              <div className="article-header__consoles-container">
                                <div className="row p-0 m-0 d-flex flex-nowrap icon-wrapper">
                                  {tournament.GameConsolesIds && tournament.GameConsolesIds.length > 1 && (
                                    <div className="article-header__platform article-header__platform--cross-platform" />
                                  )}
                                  {tournament.GameConsolesIds && tournament.GameConsolesIds.find(x => x == ConsoleEnum.Xbox) && (
                                    <div className="article-header__platform article-header__platform--xbox" />
                                  )}
                                  {tournament.GameConsolesIds && tournament.GameConsolesIds.find(x => x == ConsoleEnum.Playstation) && (
                                    <div className="article-header__platform article-header__platform--ps" />
                                  )}
                                  {tournament.GameConsolesIds && tournament.GameConsolesIds.find(x => x == ConsoleEnum.PC) && (
                                    <div className="article-header__platform article-header__platform--pc" />
                                  )}
                                  {tournament.GameConsolesIds && tournament.GameConsolesIds.find(x => x == ConsoleEnum.Nintendo) && (
                                    <div className="article-header__platform article-header__platform--nintendo" />
                                  )}
                                  {tournament.GameConsolesIds && tournament.GameConsolesIds.find(x => x == ConsoleEnum.Android) && (
                                    <div className="rticle-header__platform article-header__platform--android">
                                      <div className="android-icon"></div>
                                      {' '}
                                      {/* <span style={{ color: 'white' }}>android</span> */}
                                    </div>
                                  )}
                                  {tournament.GameConsolesIds && tournament.GameConsolesIds.find(x => x == ConsoleEnum.iOS) && (
                                    <div className="rticle-header__platform article-header__platform--ios">
                                      {/* <span style={{ color: 'white' }}>ios</span> */}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            {!isAdmin && (
                              <div className="col-12 p-0 article-header__header-container">
                                <h1 className="article-header__header tournament--article-header__header ct__article-header__header">{tournament.Name}</h1>
                              </div>
                            )}
                            <div className="col-12 p-0 d-flex article-header__info-container ct__article-header__info-container flex-sm-row flex-column">
                              {this.props.tournamentId > 0 && (
                                <div className="article-header__info article-header__info--date">
                                  {defaultFormatDate(tournament.StartDate)}
                                  {/* {tournament.StartDate && new Date(tournament.StartDate).toCustomizedDateString()} */}
                                </div>
                              )}
                              {this.props.tournamentCreateModel && (
                                <div className="article-header__info article-header__info--date">
                                  {tournament.StartDate && new Date(tournament.StartDate).toLocaleDateString()}
                                </div>
                              )}
                              <div className="article-header__info article-header__info--status">{tournament.TournamentStatus}</div>
                              <div className="article-header__info article-header__info--region">{tournament.Region}</div>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{ verticalAlign: 'bottom', margin: 'auto' }}
                          className="col-auto article-header__registration-container ct__article-header__registration-container d-flex h-xl-100 align-items-xl-center"
                        >
                          <TournamentDetailRegistrationContainer tournament={tournament} reloadTournament={this.reloadTournament} />
                        </div>
                        {(isAdmin || (user && tournament.Organizerid == user.id)) && (
                          <UpdateCover tournamentId={tournament.TournamentId} onUpdated={this.getTournament} />
                        )}
                      </div>
                      <div className="article-header__tab-menu-container row m-0 align-items-end regi" style={{ zIndex: 9 }}>
                        <button
                          className="article-header__tab-menu-button article-header__tab-menu-button--left white"
                          onClick={() => {
                            document.getElementById('touranmentDetailTab').scrollLeft -= 50
                          }}
                        />
                        <nav
                          id="touranmentDetailTab"
                          className="article-header__tab-menu tab-menu row justify-content-start align-items-lg-start align-items-end flex-nowrap"
                        >
                          <a
                            id="tab1"
                            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                              this.onChangeTab(event, 'overview')
                            }}
                            className={getTabClassName(this.props.currentTab == 'overview')}
                          >
                            Overview
                          </a>
                          <a
                            id="tab2"
                            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                              this.onChangeTab(event, 'participants')
                            }}
                            className={getTabClassName(this.props.currentTab == 'participants')}
                          >
                            Participants
                          </a>
                          <a
                            id="tab3"
                            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                              this.onChangeTab(event, 'brackets')
                            }}
                            className={getTabClassName(this.props.currentTab == 'brackets')}
                          >
                            Brackets
                          </a>
                          {tournament && tournament.StreamingLink && tournament.StreamingLink.length > 0 && (
                            <a
                              id="tab4"
                              onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                                this.onChangeTab(event, 'settings')
                              }}
                              className={getTabClassName(this.props.currentTab == 'settings')}
                            >
                              Watch
                            </a>
                          )}
                          <a
                            id="tab5"
                            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                              this.onChangeTab(event, 'rules')
                            }}
                            className={getTabClassName(this.props.currentTab == 'rules')}
                          >
                            Rules
                          </a>
                          <a
                            id="tab6"
                            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                              this.onChangeTab(event, 'walkthrough')
                            }}
                            className={getTabClassName(this.props.currentTab == 'walkthrough')}
                          >
                            Walkthrough
                          </a>
                        </nav>
                        <button
                          className="article-header__tab-menu-button article-header__tab-menu-button--right white"
                          onClick={() => {
                            document.getElementById('touranmentDetailTab').scrollLeft += 50
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="">
                {this.props.currentTab == 'overview' && (
                  <TournamentDetailComponent
                    tournament={tournament}
                    organizer={organizer}
                    regions={regions}
                    isAdmin={isAdmin}
                    participants={participants}
                    timeZone={this.props.tournamentCreateModel && this.props.tournamentCreateModel.TimeZone}
                  />
                )}
                {this.props.currentTab == 'participants' && (
                  <TournamentParticipantsComponent
                    tournament={tournament}
                    reloadTournament={this.reloadTournament}
                  />
                )}
                {this.props.currentTab == 'brackets' && tournament.Id && (
                  <TournamentBracket
                    type="store"
                    tournamentDetailId={tournament.Id}
                    fullSreenDisabled={false}
                    isSearchEnabled
                  />
                )}
                {this.props.currentTab == 'brackets' && !tournament.Id && (
                  <TournamentBracket
                    type="mock"
                    fullSreenDisabled={false}
                    maxNumberOfParticipants={tournament.MaxNumberOfParticipients}
                    participantsPerMatch={tournament.ParticipientsPerMatch}
                    structure={tournament.TournamentStructureId}
                    isSearchEnabled={false}
                  />
                )}

                {this.props.currentTab == 'settings' && (
                  <TournamentWatchComponent
                    oganizerId={tournament.Organizerid}
                    videoLink={tournament.StreamingLink ? tournament.StreamingLink : 'https://www.twitch.tv/videos/106400740'}
                  />
                )}
                {this.props.currentTab == 'rules' && <TournamentRules rules={tournament.Rules} />}

                {this.props.currentTab == 'walkthrough' && <TournamentWalkthrough />}
                {this.props.currentTab == 'draft' && (
                  <TournamentDetailComponent
                    tournament={tournament}
                    organizer={organizer}
                    regions={regions}
                    isAdmin={isAdmin}
                    timeZone={this.props.tournamentCreateModel && this.props.tournamentCreateModel.TimeZone}
                  />
                )}
              </div>
            </div>
          </>
        )}

        <aside className="modal-container" id="cancellation">
          <div className="modal-container__background"></div>
          <div className="modal-container__window window">
            <div className="window__close" onClick={() => ModalUtils.closeModals()}></div>
            <div className="container-fluid">
              <div className="row">
                <div className="window__header pl-0 text-left">Cancellation</div>
              </div>
              <div className="row">
                <div className="window__big-subheader">Select the dates of the tournaments you want to remove</div>
              </div>
              <div className="row m-0--20 justify-content-start">
                {repeatTournamentDetails &&
                  repeatTournamentDetails.map((item,i) => {
                    return (
                      <div key={'TR'+ i.toString()}>
                        <input
                          className="window__checkbox" id={`cancellation_${item.Id}`} type="checkbox"
                          checked={this.isSelected(item.Id)}
                          onChange={() => {
                            this.selectCancelRepeatTournament(item.Id)
                          }}
                        />
                        <label className="window__cancel-container" htmlFor={`cancellation_${item.Id}`}>
                          <span className="window__cancel-time">{ConvertDateToFormattedDateString(item.StartDate, item.ZonedRegistrationOpensOn, isAdmin, user.isorganizer)}</span>
                          <span className="window__cancel-date">at {ConvertDateToFormattedTimeString(item.StartDate, item.ZonedRegistrationOpensOn, isAdmin, user.isorganizer)}</span>
                        </label>
                        </div>
                    )
                  })}
              </div>
              <div className="row m-t-30 justify-content-center">
                <button className="window__button window__button--cancel red-button" onClick={() => ModalUtils.closeModals()}>CANCEL</button>
                <button disabled={(selectedCancelRepeatTournament && selectedCancelRepeatTournament.length > 0 ? false : true)} className={'window__button window__button--delete red-button red-button--filled ' + (selectedCancelRepeatTournament && selectedCancelRepeatTournament.length > 0 ? '' : 'button-cancel-opacity')} onClick={() => this.deleteRepeatTournaments()}>DELETE SELECTED</button>
              </div>
            </div>
          </div>
        </aside>
      </div>
      </>
    )
  }
}

export default TournamentDetails
