import React from 'react'
import { ChatMessageModel, ChatMessageTypeEnum } from 'store/chat/chat-message/chat-message-types'
import { cn } from 'utils'
import styles from '../ChatMessages.module.scss'

export interface SystemChatMessageProps {
  chatMessage: ChatMessageModel
}

const SystemChatMessage = ({chatMessage} : SystemChatMessageProps) => {
  return (
    <div className="container-fluid">
      <div className="row m-0">
        <div 
          className={cn(
            styles.chatSystemMessage, 
            chatMessage.ChatMessageType == ChatMessageTypeEnum.Warning && styles.chatWarningMessage,  
            chatMessage.ChatMessageType == ChatMessageTypeEnum.Info && styles.chatInfoMessage,
            chatMessage.ChatMessageType == ChatMessageTypeEnum.Success && styles.chatSuccessMessage
          )}
        >
          {chatMessage.Text}
        </div>
      </div>
    </div>
  )
}

export default SystemChatMessage
