export const setMeta = (path: string) => {
  setTitle(path)
  setKeywords(path)
  setDescription(path)
}

export const setTitle = (path: string) => {
  const title = titleByPath[path] || defaultTile
  document.title = title
}

export const setKeywords = (path: string) => {
  const keywords = keywordsByPath[path] || defaultKeywords
  setContentAttr('keywords', keywords)
}

export const setDescription = (path: string) => {
  const description = descriptionByPath[path] || defaultDescription
  setContentAttr('description', description)
}

const setContentAttr = (tag: string, value: string) => {
  const elem = document.getElementsByName(tag)
  if (elem && elem[0]) {
    elem[0].setAttribute('content', value)
  }
}

const defaultDescription = 'Make Your Own Custom Bracket Online at kombatlink.com. Here you can organize the best Contest Bracket with your Tournament league and championship manager.'

const descriptionByPath: {[key:string] : string} = {
  '/victory-road-list': 'Give your audience and players an overview with our victory road feature at Kombatlink. Here you can post your listing to a global community of gamers.',
  '/victory-road-map': 'Give your audience and players an overview of Kombatlink that includes a world map of all tournament locations played. Look at kombatlink.com here you can find all the interactive map locations.',
  '/tournaments': 'Make your own custom game tournament at kombatlink.com. Create and share your sports tournament schedule our online competition manager allows you to create a tournament in seconds.',
  '/faq/how-to-create-tournament': 'Learn about how to set up run and host a video game tournament at kombatlink.com. Here you can know about guide to create live tournament event with online tournament creator and share your sports tournament publicly.',
  '/faq/why-choose': 'Find the best Ticketing Software for your organization and tournaments at kombatlink.com. here you find the details of Powerful tools for tournament organizers that every esport tournament organizer knows.',
  '/faq/how-to-play': 'Learn about how tournaments work at kombatlink.com. Here you can know about guide to how to play tournaments and the details of the live tournament guide.',
  '/games':'Kombatlink offers Game Tournament Bracket Generator platform. Click here and get full details for Online Gaming Tournaments for PC.'
}

const defaultKeywords = 'Bracket Creator Online, Contest Bracket Maker, Custom Bracket League, Custom Bracket Maker, Make Brackets Online, Make Your Bracket Online, tournament bracket builder, tournament brackets maker'

const keywordsByPath: {[key:string] : string} = {
  '/victory-road-list': 'Esports Brackets',
  '/victory-road-map': 'Interactive Map',
  '/tournaments': 'Create Your Own Tournament, create your own tournament bracket, crypto game tournaments, custom tournament bracket, Custom Tournament Brackets, Custom Tournament Builder, make a custom tournament bracket, Make A Tournament, Make Your Own Tournament, make your own tournament bracket, Make Your Own Tournament Bracket Online, NFT game tournaments, Online Tournament Bracket, online tournament maker, Play to earn game tournaments, Tournament Bracket Maker, Tournament Bracket Makers, Tournament Builder Online, Tournament Group Generator, Tournament Maker, Tournament Maker Online, Tournament Website Builder, Video Game Tournament Bracket',
  '/faq/how-to-create-tournament': 'Online Tournament Creator, Create a Tournament',
  '/faq/why-choose': 'Event Ticketing System Comparison, Digital Ticketing Solutions',
  '/faq/how-to-play': 'How Do Tournaments Work',
  '/games':'Game Bracket Generator, Game Bracket Maker, Game Tournament Bracket, Tournament Run Game, Online Gaming Tournaments for PC'
}

const titleByPath: {[key:string] : string} = {
  '/':'Custom Bracket League | Contest Bracket Maker | Make Your Own Bracket Online | Kombatlink',
  '/victory-road-list': 'Esports Brackets | Victory Road List - Kombatlink',
  '/victory-road-map': 'Interactive Map | Victory Road Map - Kombatlink',
  '/tournaments': 'Make Your Own Custom Tournament | Online Tournament Maker For Game | Tournament Brackets Host Page – Kombatlink',
  '/faq/how-to-create-tournament': 'Online Tournament Creator | Create A Tournament | How To Host Organizer and Run A Video Game Tournament | Kombatlink',
  '/faq/why-choose': ' Event Ticketing System Comparison, Digital Ticketing Solutions',
  '/faq/how-to-play': 'How Do Tournaments Work - Kombatlink',
  '/games':'Game Tournament Bracket Generator | Online Gaming Tournaments for PC. | Kombatlink'
}

const defaultTile = 'Custom Bracket League | Contest Bracket Maker | Make Your Own Bracket Online | Kombatlink'
