export enum TournamentStatusEnum {
  Pending = 1,
  Active = 2,
  Finished = 3,
  Cancel = 4,
  Draft = 5,
}

export const TournamentStatus = {
  [TournamentStatusEnum.Pending]: 'Pending',
  [TournamentStatusEnum.Active]: 'Active',
  [TournamentStatusEnum.Finished]: 'Finished',
  [TournamentStatusEnum.Cancel]: 'Cancel',
  [TournamentStatusEnum.Draft]: 'Draft',
}
