import { AdminTournamentsTab } from 'components/_pages/admin/AdminTournament/AdminTournaments'
import { TournamentModeEnum } from 'consts'
import { ConsoleEnum } from 'consts/ConsoleType'
import { TournamentEventTypeEnum } from 'consts/TournamentEventType'
import { makeActionCreator, ReduxAction } from '../common'
import { TournamentConsolesType } from '../console-types'
import { IDictionary } from '../dictionary-types'
import { GameType } from '../game-types'
import { ImageType } from '../image-types'
import { TournamentType } from '../tournaments-types'
import { PublicUserType } from '../user-types'


export const defaultAdminTournamentFilters: AdminTournamentsPageFilters = {
  currentPage: 0,
  currentTab: 'upcoming',
  isLiveEvent: true,
  isOnlineEvent: true,
  name: '',  
  selectedOrganizers: [],
  selectedConsoles: [],
  selectedGames: [],
  selectedMode: null,
  selectedEventType: null,
  dateFrom: null,
  dateTo: null,
  kombatlinkOrganizer: false,
}


export interface AdminTournamentsPageFilters {
  currentTab: AdminTournamentsTab
  currentPage: number
  isOnlineEvent: boolean
  isLiveEvent: boolean
  name: string
  selectedOrganizers: PublicUserType[]
  selectedConsoles: IDictionary<ConsoleEnum>[]
  selectedGames: GameType[]
  selectedMode: IDictionary<TournamentModeEnum>
  selectedEventType: IDictionary<TournamentEventTypeEnum>
  dateFrom: Date
  dateTo: Date
  kombatlinkOrganizer: boolean
}


export interface AdminTournamentsPageState {
  filters: AdminTournamentsPageFilters
  upcomingTournamentsIds: number[]
  allUpcomingTournamentsCount: number
  activeTournamentsIds: number[]
  allActiveTournamentsCount: number
  finishedTournamentsIds: number[]
  allFinishedTournamentsCount: number
  canceledTournamentsIds: number[]
  allCanceledTournamentsCount: number
}


export const ADMIN_TOURNAMENTS_FILTERS_CHANGED = 'ADMIN_TOURNAMENTS_FILTERS_CHANGED'
export interface AdminTournamentsFilterChangedPayLoad {
  filters: AdminTournamentsPageFilters
}

export type AdminTournamentsFiltersChangedAction = ReduxAction<typeof ADMIN_TOURNAMENTS_FILTERS_CHANGED, AdminTournamentsFilterChangedPayLoad>
export const adminTournamentsFiltersChanged = makeActionCreator<AdminTournamentsFilterChangedPayLoad>(ADMIN_TOURNAMENTS_FILTERS_CHANGED)


export const ADMIN_UPCOMING_TOURNAMENTS_LOADED = 'ADMIN_UPCOMING_TOURNAMENTS_LOADED'
interface AdminTournamentsUpcomingTournamentsLoadedPayload {
  tournaments: TournamentType[]
  allAdminUpcomingTournamentsCount: number
  TournamentConsoles: TournamentConsolesType[] 
  Images: ImageType[]
}
export type AdminUpcomingTournamentsLoadedAction = ReduxAction<typeof ADMIN_UPCOMING_TOURNAMENTS_LOADED, AdminTournamentsUpcomingTournamentsLoadedPayload>
export const adminUpcomingTournamentsLoaded = makeActionCreator<AdminTournamentsUpcomingTournamentsLoadedPayload>(ADMIN_UPCOMING_TOURNAMENTS_LOADED)

export const ADMIN_ACTIVE_TOURNAMENTS_LOADED = 'ADMIN_ACTIVE_TOURNAMENTS_LOADED'
interface AdminrActiveTournamentsLoadedPayload {
  tournaments: TournamentType[]
  allAdminActiveTournamentsCount: number
  TournamentConsoles: TournamentConsolesType[] 
  Images: ImageType[]
}
export type AdminActiveTournamentsLoadedAction = ReduxAction<typeof ADMIN_ACTIVE_TOURNAMENTS_LOADED, AdminrActiveTournamentsLoadedPayload>
export const adminActiveTournamentsLoaded = makeActionCreator<AdminrActiveTournamentsLoadedPayload>(ADMIN_ACTIVE_TOURNAMENTS_LOADED)

export const ADMIN_FINISHED_TOURNAMENTS_LOADED = 'ADMIN_FINISHED_TOURNAMENTS_LOADED'
interface AdminFinishedTournamentsLoadedPayload {
  tournaments: TournamentType[]
  allAdminFinishedTournamentsCount: number
  TournamentConsoles: TournamentConsolesType[] 
  Images: ImageType[]
}
export type AdminFinishedTournamentsLoadedAction = ReduxAction<typeof ADMIN_FINISHED_TOURNAMENTS_LOADED, AdminFinishedTournamentsLoadedPayload>
export const adminFinishedTournamentsLoaded = makeActionCreator<AdminFinishedTournamentsLoadedPayload>(ADMIN_FINISHED_TOURNAMENTS_LOADED)


export const ADMIN_CANCELED_TOURNAMENTS_LOADED = 'ADMIN_CANCELED_TOURNAMENTS_LOADED'
interface AdminCanceledTournamentsLoadedPayload {
  tournaments: TournamentType[]
  allAdminCanceledTournamentsCount: number
  TournamentConsoles: TournamentConsolesType[] 
  Images: ImageType[]
}
export type AdminCanceledTournamentsLoadedAction = ReduxAction<typeof ADMIN_CANCELED_TOURNAMENTS_LOADED, AdminCanceledTournamentsLoadedPayload>
export const adminCanceledTournamentsLoaded = makeActionCreator<AdminCanceledTournamentsLoadedPayload>(ADMIN_CANCELED_TOURNAMENTS_LOADED)


export const CAUROSEL_UPDATED = 'CAUROSEL_UPDATED'
interface CarouselUpdatedPayload {
  tournamentId: number
  isChecked: boolean
}
export type CarouselUpdatedAction = ReduxAction<typeof CAUROSEL_UPDATED, CarouselUpdatedPayload>
export const carouselUpdated = makeActionCreator<CarouselUpdatedPayload>(CAUROSEL_UPDATED)


export type KnownAction = AdminTournamentsFiltersChangedAction 
  | AdminUpcomingTournamentsLoadedAction 
  | AdminActiveTournamentsLoadedAction
  | AdminFinishedTournamentsLoadedAction
  | AdminCanceledTournamentsLoadedAction
