import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { CheckInBattles } from './CheckIn/CheckInBattles'
import { PendingBattles } from './PendingBattles/PendingBattles'
import { ActiveBattles } from './ActiveBattles/ActiveBattles'
import { FinishedBattles } from './FinishedBattles/FinishedBattles'
import { useIntMatchParam, useUnionMatchParam } from 'components/_hooks'
import { battlesTabs, BattlesTab } from './consts'
import './Battles.scss'
import { ArticleHeader, Tabs } from 'components/_shared'

interface BattlesRouteProps {
  tab: BattlesTab
  roundId?: string
}

type OwnProps = RouteComponentProps<BattlesRouteProps>

const Battles = ({ match }: OwnProps) => {
  const tab = useUnionMatchParam(battlesTabs, match, m => m.tab, 'pending')
  const roundId = useIntMatchParam(match, m => m.roundId)


  return (
    <>
      <ArticleHeader text="My Battles" />
      <div className="row m-0--30">
        <div className="col-12">
          <Tabs>
            <Tabs.Menu>
              <Tabs.Link url="/battles/pending" active={tab === 'pending'}>Pending</Tabs.Link>
              <Tabs.Link url="/battles/checkin" active={tab === 'checkin'}>Check-in</Tabs.Link>
              <Tabs.Link url="/battles/submission" active={tab === 'submission'}>Submission</Tabs.Link>
              <Tabs.Link url="/battles/finished" active={tab === 'finished'}>Finished</Tabs.Link>
            </Tabs.Menu>
          </Tabs>
        </div>
      </div>
      {tab === 'pending' && <PendingBattles roundId={roundId} />}
      {tab === 'checkin' && <CheckInBattles roundId={roundId} />}
      {tab === 'submission' && <ActiveBattles roundId={roundId} />}
      {tab === 'finished' && <FinishedBattles roundId={roundId} />}
    </>
  )
}

export default Battles
