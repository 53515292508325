import { useState } from 'react'

export function useDialog(dialogCreator: (closeDialog: CallableFunction) => JSX.Element | undefined
): [JSX.Element | undefined, () => void, () => void] {
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const closeDialog = () => {
    setIsDialogOpen(false)
  }

  const dialog = isDialogOpen ? dialogCreator(closeDialog) : undefined

  return [
    dialog,
    () => setIsDialogOpen(true),
    closeDialog,
  ]
}

export function useDialogWithParameter<T>(
  dialogCreator: (p: T, closeDialog: CallableFunction) => JSX.Element | undefined
): [JSX.Element | undefined, (parameter: T) => void, () => void] {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [parameter, setParameter] = useState<T>()

  const closeDialog = () => {
    setIsDialogOpen(false)
  }

  const dialog = isDialogOpen ?  dialogCreator(parameter as T, closeDialog) : undefined

  return [
    dialog,
    (parameter: T) => {
      setParameter(parameter)
      setIsDialogOpen(true)
    },
    closeDialog,
  ]
}
