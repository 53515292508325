import SelectComponent from 'components/_shared/SelectComponent'
import React, { useCallback, useEffect } from 'react'
import { TimeZoneInfoDTO } from 'store/types/app-types'
import { getLocalTimezone } from 'utils'

interface OwnProps {
  onChange
  errors
  timeZoneId
}

interface StateProps {
  timeZones: TimeZoneInfoDTO[]
}

type Props = OwnProps & StateProps

const TimeZone = ({ timeZones, onChange, errors, timeZoneId }: Props) => {
  const onSelectionChange = useCallback(
    (paramName: string, val: any) => {
      const timeZone = timeZones.find(tz => tz.Id == val)
      onChange(paramName, timeZone)
    },
    [timeZones, onChange]
  )

  useEffect(() => {
    if (!timeZoneId && timeZones && timeZones.length > 0) {
      const localTimeZoneId = getLocalTimezone()
      if (localTimeZoneId) {
        onSelectionChange('TimeZoneId', localTimeZoneId)
      }
    }
  }, [timeZoneId, timeZones])

  return (
    <div className="article__width-2 p-r-35 d-flex flex-column">
      <div className="article__subheader">Time Zone</div>
      {timeZones && (
        <SelectComponent
          fieldId="TimeZoneId"
          data={timeZones}
          textField="DisplayName"
          valueField="Id"
          selectedValue={timeZoneId}
          onSelectionChange={onSelectionChange}
          zIndex={499}
          placeholder="Select Time Zone"
          isImage={false}
          class1={
            'article__select article__select--region article__input article__width-2 article__height-1 select ct__select' +
            (errors.TimeZoneId ? ' highlight-invalid-control' : '')
          }
          class2="article__select--region__title select__title-ct ct__select__title-ct"
          class3="article__select--region__container select__container ct__select__container"
          class4="article__select--region__option select__option"
        />
      )}
    </div>
  )
}

export default TimeZone
