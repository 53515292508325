import { useThemeStyles } from 'components/_hooks'
import React, { useState } from 'react'
import { cn } from 'utils'
import stylesCommon from './TextInput.module.scss'
import stylesDark from './TextInputDark.module.scss'
import stylesLight from './TextInputLight.module.scss'

export type TextInputTypes = 'text' | 'email' | 'password' | 'tel' | 'url'

export interface TextInputProps {
  placeholder?: string
  value?: string
  onChange?: (value: string) => void
  className?: string
  withClear?: boolean
  inputClassName?: string
  clearClassName?: string
  disabled?: boolean
  type?: TextInputTypes
}

export const TextInput = ({ placeholder, onChange, value, className, withClear, inputClassName, clearClassName, disabled, type }: TextInputProps) => {
  const styles = useThemeStyles(stylesDark, stylesLight)
  
  return (
    <SimpleTextInput
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      className={cn(styles.container, className)}
      withClear={withClear}
      clearClassName={cn(styles.clear, clearClassName)}
      inputClassName={cn(styles.input, inputClassName)}
      disabled={disabled}
      type={type}
    />
  )
}

export const SimpleTextInput = ({ placeholder, onChange, value, className, withClear, inputClassName, clearClassName, disabled, type = 'text' }: TextInputProps) => {
  const [clearVisible, setClearVisible] = useState(false)

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()
    if (disabled) {
      return
    }
    if (onChange) {
      onChange('')
    }
    setClearVisible(false)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled) {
      return
    }
    const value = event.target.value
    if (onChange) {
      onChange(value)
    }
    setClearVisible(value !== '')
  }

  return (
    <div className={className}>
      <input autoComplete="new-password" disabled={disabled} className={inputClassName} type={type} placeholder={placeholder} value={value} onChange={handleChange} />
      {withClear && <a className={cn(stylesCommon.simpleClear, clearClassName, clearVisible && stylesCommon.visible)} href="#" onClick={handleClick}></a>}
    </div>
  )
}
