import { useThemeStyles } from 'components/_hooks'
import React from 'react'
import { cn } from 'utils'
import stylesDark from './TextAreaDark.module.scss'
import stylesLight from './TextAreaLight.module.scss'

interface TextAreaProps {
  placeholder?: string
  value?: string
  onChange?: (value: string) => void
  className?: string
  inputClassName?: string
  disabled?: boolean
}

export const TextArea = ({ placeholder, onChange, value, className, inputClassName, disabled }: TextAreaProps) => {
  const styles = useThemeStyles(stylesDark, stylesLight)
  
  return (
    <SimpleTextArea
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      className={className}
      inputClassName={cn(styles.textarea, inputClassName)}
      disabled={disabled}
    />
  )
}

export const SimpleTextArea = ({ placeholder, onChange, value, className, inputClassName, disabled }: TextAreaProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value
    onChange(value)
  }

  return (
    <div className={className}>
      <textarea disabled={disabled} className={inputClassName} placeholder={placeholder} value={value} onChange={handleChange} />
    </div>
  )
}
