import { useThemeStyles } from 'components/_hooks'
import React from 'react'
import { ReactNode } from 'react'
import { cn } from 'utils'
import stylesDark from './FilterContainerDark.module.scss'
import stylesLight from './FilterContainerLight.module.scss'
export interface FilterContainerProps {
  children: ReactNode
  title: string
  className?: string
}

export const FilterContainer = ({ children, title, className }: FilterContainerProps) => {

  const styles = useThemeStyles(stylesDark, stylesLight)

  return (
    <div className={cn(styles.articleSelect, 'col-auto', className)}>
      <div className={styles.articleSelectTitle}>{title}</div>
      {children}
    </div>
  )
}
