import { ContainerName, useGetImageSize } from 'components/_hooks'
import { useAppSelector } from 'components/_hooks/useAppSelector'
import { Img, KlLink } from 'components/_shared'
import React, { useMemo } from 'react'
import ReactStars from 'react-rating-stars-component'
import { makeGetReview } from 'store/selectors/review-selectors'
import { makeGetUser } from 'store/selectors/user-selectors'
import { getIsOrganizer, getIsPlayer, getOrganizerProfileUrl, getUserProfileUrl } from 'store/types/user-types'
import { defaultFormatDate } from 'utils'

export interface OrganizerReviewCardProps {
  reviewId: number
}

export const OrganizerReviewCard = ({ reviewId }:  OrganizerReviewCardProps) => {
  const getReview = useMemo(makeGetReview, [])
  const getUser = useMemo(makeGetUser, [])

  const review = useAppSelector(state => getReview(state, { id: reviewId }))
  const user = useAppSelector(state => getUser(state, review?.ReviewerId))

  const url = useMemo(() => {
    if (!user) {
      return '#'
    }
    if (getIsPlayer(user.Role)) {
      return getUserProfileUrl(user.Id)
    }
    if (getIsOrganizer(user.Role)) {
      return getOrganizerProfileUrl(user.Id)
    }
    return '#'
  }, [user])
  const imageSize = useGetImageSize(ContainerName.AvatarsNormal)

  return (
    <div className="col-12 col-md-6 rvws-col">
      <div className="rvws">
        <div className="rvws_head">
          <div className="rvws_head_l">
            <div className="rvws_aut">
              <div className="rvws_aut_l">
                <Img parentContainerSize={imageSize} imageId={user?.AvatarId} className="usr-ico usr-ico-md" placeholder="dark" />
              </div>
              <div className="rvws_aut_r">
                <h3 className="rvws_ttl">
                  <KlLink to={url}>{user?.Username}</KlLink>
                </h3>
              </div>
            </div>
          </div>
          <div className="rvws_head_r">
            <ReactStars
              count={5}
              size={24}
              activeColor="#ec5418"
              classNames={'mr-2'}
              value={review.Rating}
              edit={false}
              isHalf
            />
          </div>
        </div>
        <div className="rvws_body">
          <div className="rvws_txt rvws_txt-ico">
            <p>{review.Text}</p>
          </div>
        </div>
        <div className="rvws_foot">
          <div className="rvws_date"><span>{defaultFormatDate(review.Date)}</span></div>
        </div>
      </div>
    </div>
  )
}
