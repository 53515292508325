import { ClanMemberRemovedAction, ClansMembersModel } from '../clan-member-types'
import { makeActionCreator, ReduxAction } from '../common'
import { PublicUsersModel } from '../user-types'

export interface PlayersFilters {
  page: number
  username: string
}

export interface ListClanMembersDto {
  AllMembersCount: number
  Model: ClansMembersModel
}

export interface ClanPageState {
  playersIds: number[]
  allPlayersCount: number
  playersFilters: PlayersFilters
  selectedPlayersIds: number[]
  membersIds: number[]
  allMembersCount: number
  membersPage: number
}

export const FILTERED_PLAYERS_LOADED = 'clanpage/players/loaded'
interface FilteredPlayersLoadedPayload {
  players: PublicUsersModel
  allPlayersCount: number
}

export type FilteredPlayersLoadedAction = ReduxAction<typeof FILTERED_PLAYERS_LOADED, FilteredPlayersLoadedPayload>
export const filteredPlayersLoaded = makeActionCreator<FilteredPlayersLoadedPayload>(FILTERED_PLAYERS_LOADED)

export const FILTERED_PLAYERS_CLEARED = 'clanpage/players/cleared'
export type FilteredPlayersClearedAction = ReduxAction<typeof FILTERED_PLAYERS_CLEARED, null>
export const filteredPlayersCleared = makeActionCreator(FILTERED_PLAYERS_CLEARED)

export const PLAYRES_FILTERS_CHANGED = 'clanpage/playersFilters/changed'
export type PlayersFiltersChangedAction = ReduxAction<typeof PLAYRES_FILTERS_CHANGED, PlayersFilters>
export const playersFiltersChanged = makeActionCreator<PlayersFilters>(PLAYRES_FILTERS_CHANGED)

export const SELECTED_PLAYERS_CHANGED = 'clanpage/selectedPlayers/changed'
export type SelectedPlayersChangedAction = ReduxAction<typeof SELECTED_PLAYERS_CHANGED, number[]>
export const selectedPlayersChanged = makeActionCreator<number[]>(SELECTED_PLAYERS_CHANGED)

export const PLAYERS_INVITED = 'clanpage/players/invited'
export type PlayersInvitedAction = ReduxAction<typeof PLAYERS_INVITED, null>
export const playersInvited = makeActionCreator(PLAYERS_INVITED)

export const MEMBERS_PAGE_CHANGED = 'clanpage/membersPage/changed'
export type MembersPageChangedAction = ReduxAction<typeof MEMBERS_PAGE_CHANGED, number>
export const membersPageChanged = makeActionCreator<number>(MEMBERS_PAGE_CHANGED)

export const MEMBERS_PAGE_LOADED = 'clanpage/members/loaded'
export type MembersPageLoadedAction = ReduxAction<typeof MEMBERS_PAGE_LOADED, ListClanMembersDto>
export const membersPageLoaded = makeActionCreator<ListClanMembersDto>(MEMBERS_PAGE_LOADED)

export type KnownAction = FilteredPlayersLoadedAction
  | FilteredPlayersClearedAction
  | PlayersFiltersChangedAction
  | SelectedPlayersChangedAction
  | PlayersInvitedAction
  | MembersPageChangedAction
  | MembersPageLoadedAction
  | ClanMemberRemovedAction
