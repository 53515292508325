import React from 'react'
import { cn } from 'utils'
import styles from '../modals.module.scss'

interface WindowHrProps {
  className?: string
}

export const WindowHr = ({ className }: WindowHrProps) => {
  return (
    <div className="row">
      <div className={cn(styles.window__hr, className)} />
    </div>
  )
}
