import moment from 'moment'
import momentTz from 'moment-timezone'

const dateFormat = 'MM/DD/YYYY'
const timeFormat = 'h:mm A'
const timeFormatZ = `${timeFormat} z`
const dateTimeFormat = `${dateFormat}, ${timeFormat}`
const dateTimeFormatZ = `${dateFormat}, ${timeFormatZ}`
const dateFullFormat = 'MMM DD, YYYY'
const dateFullFormatZ = `${dateFullFormat} z`

type DateType = Date | string | moment.Moment

export const fullFormatDate = (date: DateType, timeZone?: string) => {
  return formatDate(date, timeZone? dateFullFormatZ : dateFullFormat, timeZone)
}

export const defaultFormatDate = (date: DateType, timeZone?: string) => {
  return formatDate(date, dateFormat, timeZone)
}

export const defaultFormatTime = (date: DateType, timeZone?: string) => {
  return formatDate(date, timeZone ? timeFormatZ : timeFormat, timeZone)
}

export const defaultFormatDateTime = (date: DateType, timeZone?: string) => {
  return formatDate(date, timeZone ? dateTimeFormatZ : dateTimeFormat, timeZone)
}

export interface DateFormats {
  date: string
  time: string
  dateTime: string
}

export const getDefaultDateFormats = (date: DateType, timeZone?: string): DateFormats => {
  const mDate = moment(date)
  return {
    date: defaultFormatDate(mDate, timeZone),
    time: defaultFormatTime(mDate, timeZone),
    dateTime: defaultFormatDateTime(mDate, timeZone),
  }
}

export const fromNow = (date: DateType) => {
  return moment(date).fromNow()
}

export const parseDate = (date: string, format: string): Date => {
  return moment(date, format).toDate()
}

export const formatDate = (date: DateType, format: string, timeZone?: string) => {
  let momentDate = moment(date)
  if (timeZone) {
    momentDate = momentTz(momentDate).tz(timeZone)
  }
  return momentDate.format(format)
}

export const addDays = (date: Date, amount: number) => {
  return moment(date).add(amount, 'days').toDate()
}

export const isSameDay=(first:DateType,second : DateType) => {
    return  moment(first).isSame(second);
}

export const isBefore=(first:DateType,second : DateType) => {
  return  moment(first).isBefore(second);
}

export const getFormatDateForLoginHistory=(date:DateType)=>{
  let momentDate = moment(date)
  const current = moment()
  const IsSameDay = momentDate.isSame(current, 'd')
  if(IsSameDay)
  {
    const duration = moment.duration(current.diff(momentDate))
    const m = duration.minutes()
    if(duration.hours() === 0 && duration.minutes() < 60)
    {
      return duration.minutes() + ' minutes ago'
    }
    else{
      let message = duration.hours() + ' hours'
      if(duration.minutes() > 0)
      {
        message += ' and ' + duration.minutes() + ' minutes'
      }
      message += ' ago'
      return message
    }
  }
else{
  return momentDate.format(dateTimeFormat)
}
}