import { useEffect, useMemo } from 'react'
import { useState } from 'react'
import { delay } from 'utils'

export const useCarousel = <T>(items: T[], window: number) => {
  const count = items.length
  const [offset, setOffset] = useState(0)
  const [withTransition, setWithTransition] = useState(true)
  const [reset, setReset] = useState(false)

  const forward = () => {
    if (reset || items.length < window) {
      return
    }
    const newOffset = offset - 1
    setOffset(newOffset)
    setReset(newOffset === 0)
    setWithTransition(true)
  }

  const back = () => {
    if (reset || items.length < window) {
      return
    }
    const newOffset = offset + 1
    setOffset(newOffset)
    setReset(newOffset === count + 1)
    setWithTransition(true)
  }

  const extendedItems = useMemo<T[]>(() => {
    if (!items || items.length < window) {
      return items
    }

    return [
      ...items.slice(-window),
      ...items,
      ...items.slice(0, window),
    ]
  }, [items, window])

  useEffect(() => {
    if (!reset) {
      return
    }

    const resetOffset = async () => {
      if (offset === 0) {
        await delay(400)
        setOffset(count)
        setWithTransition(false)
        setReset(false)
      } else if (offset === count + 1) {
        await delay(400)
        setOffset(1)
        setWithTransition(false)
        setReset(false)
      }
    }

    void resetOffset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, reset])

  useEffect(() => {
    setOffset(items.length < window ? 0 : window)
  }, [items.length, window])

  return {
    withTransition,
    offset: -offset,
    forward,
    back,
    items: extendedItems,
  }
}
