import { Area, AspectRatioType, KlCropper } from 'components/_shared'
import { useCallback, useState } from 'react'

export interface CropperParams {
  aspectRatioType?: AspectRatioType
  src: string
  containerClassName?: string
  onChangeCroppedAreaPixels: (pixels: Area) => void
  zoomClassName?: string
  round?: boolean
}

export const useCropper = ({
  aspectRatioType,
  src,
  containerClassName,
  zoomClassName,
  onChangeCroppedAreaPixels,
  round,
}: CropperParams): [
  getCroppedImg: (imageSrc: string, pixelCrop: Area, rotation?: number) => Promise<{ canvas: HTMLCanvasElement; image: HTMLImageElement }>, 
  cropper: JSX.Element,
  setZoom: (value: number) => void
] => {
  const [zoom, setZoom] = useState(1)
  
  const createImage = (url: string): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
      const image = new Image()
      image.addEventListener('load', () => resolve(image))
      image.addEventListener('error', (error: ErrorEvent) => reject(error))
      image.src = url
    })

  const getRadianAngle = (degreeValue: number) => {
    return (degreeValue * Math.PI) / 180
  }

  const getCroppedImg = useCallback(async (imageSrc: string, pixelCrop: Area, rotation = 0) => {
    const image = await createImage(imageSrc)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    const maxSize = Math.max(image.width, image.height)
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea
    canvas.height = safeArea

    if (ctx) {
      // translate canvas context to a central location on image to allow rotating around the center.
      ctx.translate(safeArea / 2, safeArea / 2)
      ctx.rotate(getRadianAngle(rotation))
      ctx.translate(-safeArea / 2, -safeArea / 2)

      // draw rotated image and store data.
      ctx.drawImage(
        image,
        safeArea / 2 - image.width * 0.5,
        safeArea / 2 - image.height * 0.5
      )
      const data = ctx.getImageData(0, 0, safeArea, safeArea)

      // set canvas width to final desired crop size - this will clear existing context
      canvas.width = pixelCrop.width
      canvas.height = pixelCrop.height

      // paste generated rotate image with correct offsets for x,y crop values.
      ctx.putImageData(
        data,
        Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
        Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
      )
    }
    // As Base64 string
    // return canvas.toDataURL('image/jpeg');

    return { canvas, image }
  }, [])

  const handleChangeCroppedAreaPixels = (pixels: Area) => {
    onChangeCroppedAreaPixels(pixels)
  }
  
  const cropper = (
    <>
      <KlCropper
        aspectRatioType={aspectRatioType}
        src={src}
        containerClassName={containerClassName}
        onChangeCroppedAreaPixels={handleChangeCroppedAreaPixels}
        zoomClassName={zoomClassName}
        round={round}
        zoom={zoom}
        onZoomChange={setZoom}
      />
    </>
  )

  return [getCroppedImg, cropper, setZoom]
}
