import { Button } from 'components/_shared'
import React from 'react'
import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getOrganizerEventsPageFilters } from 'store/selectors/pages/organizer-events-page-selectors'
import { ApplicationState } from 'store/types/common'
import { defaultOrganizerEventsFilters, organizerEventsFiltersChanged } from 'store/types/pages/organizer-events-page-types'
import styles from '../EventsOrganizer.module.scss'


const NotFoundTournaments = () => {
  const dispatch = useDispatch()
  const storedFilters = useSelector((state: ApplicationState) => getOrganizerEventsPageFilters(state)) 
  
  const isDefaultFilters = useMemo(() => {
    return storedFilters.currentPage == defaultOrganizerEventsFilters.currentPage 
      && storedFilters.isLiveEvent == defaultOrganizerEventsFilters.isLiveEvent
      && storedFilters.isOnlineEvent == defaultOrganizerEventsFilters.isOnlineEvent
      && storedFilters.selectedConsoles == defaultOrganizerEventsFilters.selectedConsoles
      && storedFilters.selectedGames == defaultOrganizerEventsFilters.selectedGames
      && storedFilters.selectedInterval == defaultOrganizerEventsFilters.selectedInterval
      && storedFilters.selectedMode == defaultOrganizerEventsFilters.selectedMode
      && storedFilters.name == defaultOrganizerEventsFilters.name
      && storedFilters.dateFrom == defaultOrganizerEventsFilters.dateFrom
      && storedFilters.dateTo == defaultOrganizerEventsFilters.dateTo
      && storedFilters.selectedEventType == defaultOrganizerEventsFilters.selectedEventType
  }, [storedFilters])

  const handleResetFilters = () => {
    dispatch(organizerEventsFiltersChanged(
      {
        filters: {
          ...defaultOrganizerEventsFilters,
          currentPage: 0, 
          currentTab: storedFilters.currentTab,        
        },
      }
    ))
  }

  return (    
    <div className={styles.clearFilters}>
      <div className={styles.clearFiltersText}>                
        There are no tournaments for these filters
      </div>
      {!isDefaultFilters && (
        <Button color="blue" onClick={handleResetFilters} filled > 
          Reset filters
        </Button>
      )}     
    </div>   
  )
}

export default NotFoundTournaments
