import { useAppSelector, useCanManageMatch, useMatchSettingsPermission, useTournamentParticipant } from 'components/_hooks'
import { useDialogWithParameter } from 'components/_hooks/useComponents/useDialog'
import CheckedInModal from 'components/_shared/MatchResultModal/CheckedInModal'
import { TournamentStructureEnum } from 'consts'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeGetMatchParticipants } from 'store/selectors/match-participant-selectors'
import { makeGetMatch } from 'store/selectors/match-selectors'
import { makeGetTournament } from 'store/selectors/tournaments-selectors'
import { ApplicationState, Permission } from 'store/types/common'
import { getMaxParticipantsNumberPerMatch } from 'store/types/tournaments-types'
import { cn } from 'utils'
import { BracketMatchParticipant } from './BracketMatchParticipant/BracketMatchParticipant'
import LobbyDetailModal from './LobbyDetailModal'
import { SaveChangesButton } from './Buttons/SaveChangesButton/SaveChangesButton'
import { useAllPlacesSelected } from './Buttons/SaveChangesButton/useAllPlacesSelected'
import { matchSettingsActionCreators } from 'store/logic/match-settings-logic'
import { getMatchSettings } from 'store/selectors/match-settings-selectors'
import { matchesSumbissionsActionCreators } from 'store/logic/match-submissions-logic'
import { makeGetUser } from 'store/selectors/user-selectors'
import { getAppUser } from 'store/selectors/app-selectors'
import { getIsOrganizer } from 'store/types/user-types'

export interface BracketMatchProps {
  matchId: number
  margin: number
  onParticipantSelected: (participantId?: number) => void
  selectedParticipantId?: number
  filter: string
  openSubmissionResultModal: (v?: any) => void
}

export const BracketMatch = ({ matchId, margin, onParticipantSelected, selectedParticipantId, filter, openSubmissionResultModal }: BracketMatchProps) => {
  const getMatch = useMemo(makeGetMatch, [])
  const getMatchParticipants = useMemo(makeGetMatchParticipants, [])
  const getTournament = useMemo(makeGetTournament, [])
  const match = useSelector((state: ApplicationState) => getMatch(state, matchId))
  const matchParticipants = useSelector((state: ApplicationState) => getMatchParticipants(state, matchId))
  const tournament = useSelector((state: ApplicationState) => getTournament(state, match.TournamentDetailId))
  const canManageBracket = useCanManageMatch(matchId)
  const matchSettingsPermission = useMatchSettingsPermission(matchId)
  const isAllPlacesSelected = useAllPlacesSelected(matchId)
  const dispatch = useDispatch()
  const matchSettings = useSelector((state: ApplicationState) => getMatchSettings(state, matchId))
  const isLobbyDetailAvailable = ((matchSettings && (matchSettings?.RoomName !== undefined && matchSettings?.RoomName !== null)) ? true : false)
  const user = useSelector(getAppUser)
  const isOrganizer = getIsOrganizer(user?.Role)
  let isShowResult = false //isOrganizer !== undefined ? isOrganizer : false
  const now = new Date()
  const isValidDate = match?.PlayDate ? new Date(match?.PlayDate) < now : false

  const fullMatchParticipants = useMemo(() => {
    const full = [...matchParticipants]

    const maxValue = getMaxParticipantsNumberPerMatch(tournament)

    while (full.length < maxValue) {
      full.push(undefined)
    }

    return full
  }, [matchParticipants, tournament])

  const showSaveButton = canManageBracket
    && tournament?.TournamentStructureId === TournamentStructureEnum.EliminationRoundFfa
    && isAllPlacesSelected

  const getUser = useMemo(makeGetUser, [])
  if (user && match && isValidDate) {
      if (!isOrganizer) {
        fullMatchParticipants.map((x, index) => {
          const { playerId, clanLeaderId } = useTournamentParticipant(x?.TournamentParticipantId)
          const userId = clanLeaderId || playerId
          if (user?.Id === userId) {
            isShowResult = true
            return
          }
        })
      }
      else {
        isShowResult = true
      }
  }

  const handleShowDetails = () => {
    openMatchResultModal(matchId)
  }

  const handleShowSettings = () => {
    openMatchSettingsModal(matchId)
  }

  useEffect(() => {
    dispatch(matchSettingsActionCreators.loadMatchSettings(match.Id))
    dispatch(matchesSumbissionsActionCreators.loadMatchSubmissions(match.Id))
  }, [dispatch, match])

  const [matchSettingsModal, openMatchSettingsModal] = useDialogWithParameter<number>((matchId, closeDialog) => (
    <LobbyDetailModal onClose={closeDialog} matchId={matchId} />
  ))

  const [matchResultModal, openMatchResultModal] = useDialogWithParameter<number>((matchId, closeDialog) => (
    <CheckedInModal closeDialog={closeDialog} matchId={matchId} />
  ))
  return (
    <div className="eli_users-group eli_users-group--has-winner" style={{ marginTop: margin, marginBottom: margin }}>
      <div className={"eli_users-group_hdr eli_general-width eli_general-padding" + (isValidDate ? ' result-show' : '')}>
        <div className="eli_users-group_hdr_l">
          <span>#{match.MatchNumber}</span>
        </div>
        <div className="eli_users-group_hdr_m">
          <div className="eli_users-group_hdr_line" />
        </div>
        {matchSettingsPermission !== Permission.none && (
          <div className="eli_users-group_hdr_r">
            <button className={cn('open_modal_checked', 'open_modal_settings', (isLobbyDetailAvailable === true ? 'lobby_detail_available' : ''))} type="button" onClick={handleShowSettings}>Lobby Details</button>
          </div>
        )}
        {!tournament.IsManualControl &&
          <div className="eli_users-group_hdr_r">
            <button className="open_modal_checked" type="button" onClick={handleShowDetails}>Checked-in list</button>
          </div>
        }
      </div>
      {fullMatchParticipants.map((x, index) => (
        <BracketMatchParticipant
          key={index}
          tournamentParticipantId={x?.TournamentParticipantId}
          matchId={matchId}
          matchParticipantId={x?.Id}
          onParticipantSelected={onParticipantSelected}
          selectedParticipantId={selectedParticipantId}
          filter={filter}
          openSubmissionResultModal={openSubmissionResultModal}
          index={index}
          showMatchResult={isShowResult}
        />
      ))}
      {showSaveButton &&
        <div className="eli_usr eli_general-width eli_general-padding">
          <SaveChangesButton className="w-100" matchId={matchId} />
        </div>
      }
      {matchResultModal}
      {matchSettingsModal}
    </div>
  )
}
