import React, { ReactNode } from 'react'
import { cn } from 'utils'
import { Loading, LoadingSize } from './Loading'
import styles from './LoadingBox.module.scss'

interface LoadingBoxProps {
  loading?: boolean
  children?: ReactNode
  size: LoadingSize
  centered?: boolean
  fullScreen?: boolean
  className?: string
  loadingClassName?: string
}

export const LoadingBox = ({
  loading,
  children,
  size,
  centered = true,
  fullScreen,
  className,
  loadingClassName,
}: LoadingBoxProps) => {
  return (
    <>
      {loading
        ? (
          <div className={cn(fullScreen && styles.container, fullScreen && 'align-items-center d-flex justify-content-center', className)}>
            <Loading size={size} centered={centered} className={loadingClassName} />
          </div>
        )
        : children
      }
    </>
  )
}
