import { createSelector } from 'reselect'
import { OperationTypes } from 'store/logic/operations-logic'
import { ApplicationState } from 'store/types/common'
import { makeGetIsAnyOperationWithTypeRunning } from '../operations-selectors'


const getState = (state: ApplicationState) => state.tournamentsPage

export const getFilters = createSelector(getState, state => state?.filters)

export const getUpcomingTournamentsIds = createSelector(getState, state => (state?.upcomingTournamentsIds ?? []))
export const getMainTournamentsIds = createSelector(getState, state => (state?.mainTournamentsIds ?? []))
export const getQualifyTournamentsIds = createSelector(getState, state => (state?.qualifyTournamentsIds ?? []))

export const getAllUpcomingTournamentsCount = createSelector(getState, state => state?.allUpcomingTournamentsCount ?? 0)
export const getAllMainTournamentsCount = createSelector(getState, state => state?.allMainTournamentsCount ?? 0)
export const getAllQualifyTournamentsCount = createSelector(getState, state => state?.allQualifyTournamentsCount ?? 0)


export const getUpcomingTournamentsLoading = makeGetIsAnyOperationWithTypeRunning(() => ({
  type: OperationTypes.loadUpcomingTournaments,
}))

export const getMainTournamentsLoading = makeGetIsAnyOperationWithTypeRunning(() => ({
  type: OperationTypes.loadMainTournaments,
}))
export const getQualifyTournamentsLoading = makeGetIsAnyOperationWithTypeRunning(() => ({
  type: OperationTypes.loadQualifyTournaments,
}))
