import { ReactNode } from 'react'
import { cn } from 'utils'
import { useDropDownTableStyles } from './styles/useDropDownTableStyles'

export interface DropDownTableHeadersRowProps {
  children?: ReactNode
  className?: string
}

export const DropDownTableHeadersRow = ({ children, className }: DropDownTableHeadersRowProps) => {
  const styles = useDropDownTableStyles()

  return <div className={cn('row', styles.tableHeadersRow, className)}>{children}</div>
}
