import React, { useEffect, useMemo, useState } from 'react'
import styles from './MatchResult.module.scss'
import { cn, defaultFormatDateTime } from 'utils'
import { TournamentStructureEnum } from 'consts/TournamentStructure'
import { makeGetMatch } from 'store/selectors/match-selectors'
import { ApplicationState } from 'store/types/common'
import { useDispatch, useSelector } from 'react-redux'
import FfaMatchResult from './FfaMatchResult'
import SingleMatchResult from './SingleMatchResult'
import { makeGetMatchParticipantsByMatchId } from 'store/selectors/match-participant-selectors'
import { makeGetParticipantsByTournamentDetailId } from 'store/selectors/participant-selectors'
import WatchTab from './Tabs/WatchTab'
import LineupsTab from './Tabs/LineupsTab'
import { matchSettingsActionCreators } from 'store/logic/match-settings-logic'
import { Modal } from 'components/_modals'

interface OwnProps {
  closeDialog: CallableFunction
  matchId: number
}

enum ModalTabEnum {
  Watch = 0,
  Lineups = 1,
}

const CheckedInModal = ({ closeDialog, matchId }: OwnProps) => {
  const getMatch = useMemo(makeGetMatch, [])
  const match = useSelector((state: ApplicationState) => getMatch(state, matchId))

  const getMatchParticipants = useMemo(makeGetMatchParticipantsByMatchId, [])
  const matchParticipants = useSelector((state: ApplicationState) => getMatchParticipants(state, matchId))
  const getTournamentParticipants = useMemo(makeGetParticipantsByTournamentDetailId, [match.TournamentDetailId])
  const tournamentParticipants = useSelector((state: ApplicationState) => getTournamentParticipants(state, match.TournamentDetailId))

  const isFFa =
    match.TournamentStructureId == TournamentStructureEnum.SingleRoundFfa ||
    match.TournamentStructureId == TournamentStructureEnum.EliminationRoundFfa

  const [currentTab, setCurrentTab] = useState(ModalTabEnum.Watch)

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(matchSettingsActionCreators.loadMatchSettings(matchId))
  }, [dispatch, matchId])

  return (
    <Modal
      onClose={closeDialog}
      hideOk
      hideCancel
      buttonColor="blue"
      className={cn(styles.window, isFFa && styles.windowFfa)}
    >
      <div className="row">
        <div className="col-auto p-0">
          <div className={styles.window__header}>{`Round ${match.RoundNumber} Match ${match.MatchNumber}`}</div>
        </div>
        <div className="col-sm-auto col-12 p-0">
          <div className={cn(styles.window__label)}>{defaultFormatDateTime(match.PlayDate)}</div>
        </div>
      </div>
      {isFFa && <FfaMatchResult matchParticpants={matchParticipants} participants={tournamentParticipants} match={match} />}
      {!isFFa && <SingleMatchResult matchParticpants={matchParticipants} participants={tournamentParticipants} match={match} />}
      <div className="m-checked_tabs">
        <div className="m-checked_tabs_hdr">
          <div className="m-checked_tabs_hdr_inn">
            <button
              className={cn('m-checked_tabs_contol', currentTab == ModalTabEnum.Watch && 'active')}
              data-tab-control="1"
              type="button"
              onClick={() => setCurrentTab(ModalTabEnum.Watch)}
            >
              Watch
            </button>
            <button
              className={cn('m-checked_tabs_contol', currentTab == ModalTabEnum.Lineups && 'active')}
              data-tab-control="2"
              type="button"
              onClick={() => setCurrentTab(ModalTabEnum.Lineups)}
            >
              Lineups
            </button>
          </div>
        </div>
        <div className="m-checked_tabs_body">
          {currentTab == ModalTabEnum.Watch && <WatchTab matchParticipants={matchParticipants} />}
          {currentTab == ModalTabEnum.Lineups && (
            <LineupsTab matchParticipants={matchParticipants} participants={tournamentParticipants} />
          )}
        </div>
      </div>
    </Modal>
  )
}

export default CheckedInModal
