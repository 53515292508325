import { IDictionary } from 'store/types/dictionary-types'

export enum TournamentModeEnum {
  Solo = 1,
  Clans = 2,
}

export const TournamentModeName = {
  [TournamentModeEnum.Solo]: 'Solo',
  [TournamentModeEnum.Clans]: 'Clans',
}


export const TournamentModeOptions = [
  ...Object.keys(TournamentModeName).map<IDictionary<TournamentModeEnum>>(key => ({
    id: +key,
    name: TournamentModeName[+key as TournamentModeEnum],
  })),
]
