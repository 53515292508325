import { OperationTypes } from 'store/logic/operations-logic'
import { makeGetIsAnyOperationWithTypeRunning } from './operations-selectors'

export const getIsSignUpLoading = makeGetIsAnyOperationWithTypeRunning(() => ({
  type: OperationTypes.signUp,
}))

export const getIsActivateUserLoading = makeGetIsAnyOperationWithTypeRunning(() => ({
  type: OperationTypes.activateUser,
}))

export const getIsSignInLoading = makeGetIsAnyOperationWithTypeRunning(() => ({
  type: OperationTypes.signIn,
}))

export const getIsSignInAdminLoading = makeGetIsAnyOperationWithTypeRunning(() => ({
  type: OperationTypes.signInAdmin,
}))

