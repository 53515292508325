import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadImage } from 'store/logic/image-logic'
import { makeGetImage } from 'store/selectors/image-selectors'
import { ApplicationState } from 'store/types/common'
import { MediaType } from 'store/types/image-types'
import { Img, Video } from '../Img'
import styles from 'components/OrganizerProfile/OrganizerProfile.module.scss'
import { ContainerName, useGetImageSize } from 'components/_hooks'

interface RecentOrganizerTournamentCoverProps {
  coverId: number
  tournamentName?: string
  containerName?:ContainerName
}

export const RecentOrganizerTournamentCover = ({ coverId, tournamentName,containerName }: RecentOrganizerTournamentCoverProps) => {
  const dispatch = useDispatch()
  const getCover = useMemo(makeGetImage, [])
  const cover = useSelector((state: ApplicationState) => getCover(state, { imageId: coverId }))
  const getImageContainerSize = useGetImageSize(containerName)
  useEffect(() => {
    if (coverId) {
      dispatch(loadImage(coverId))
    }
  }, [coverId, dispatch])

  const handleVideoMouseOver = (e: React.MouseEvent<HTMLVideoElement, MouseEvent>) => {
    void e.currentTarget.play()
  }

  const handleVideoMouseLeave = (e: React.MouseEvent<HTMLVideoElement, MouseEvent>) => {
    void e.currentTarget.pause()
  }

  return (
    <div className={styles.recentCL}>
      <a onClick={(e) => e.preventDefault()} className={styles.recentCImg}>
        {cover?.MediaType === MediaType.Video
          ? (
            <Video
              className={styles.recentCImg__boxSize}
              muted
              style={{ zIndex: 1 }}
              onMouseOver={handleVideoMouseOver}
              onMouseLeave={handleVideoMouseLeave}
              imageId={coverId}
              placeholder="tournament"
            />
          )
          : (
            <Img parentContainerSize={getImageContainerSize}
              alt={tournamentName}
              className={styles.recentCImg__boxSize}
              imageId={coverId}
              placeholder="tournament"
            />
          )
        }
      </a>
    </div>
  )
}
