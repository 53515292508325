import { ParticipantsResults } from 'components/_shared/MatchResultModal/MatchResultModal'
import React from 'react'
import { TournamentMatchesTree } from 'store/types/match-types'
import { cn } from 'utils'
import MovieCard from './MovieCard'
import MovieCardRow from './MovieCardRow'
import styles from './TournamentBracket.module.scss'

interface OwnProps {
  tournamentTree: TournamentMatchesTree
  openMatchResultModal: (participantResults: ParticipantsResults) => void
  openPlayerModal: (videoUrl: string) => void
  filterName: string
}

const Bracket = ({ tournamentTree, openMatchResultModal, openPlayerModal, filterName }: OwnProps) => {
  const isFirstRound = !tournamentTree.Matches || tournamentTree.Matches.length <= 0
  if (isFirstRound) {
    return (
      <MovieCardRow filterName={filterName} matchId={tournamentTree.Match.Id} openMatchResultModal={openMatchResultModal} openPlayerModal={openPlayerModal} />    
    )
  }

  const hasLine = tournamentTree.Matches.length > 1
  return (
    <div className={cn('row', styles.movies__row)}>
      <div 
        className={cn('col-auto', styles.movies__column,  hasLine && styles.movies__column__block, hasLine && styles[`movies__column__block${tournamentTree.Matches.length}`], styles.colAuto)}
      >
        {tournamentTree.Matches.map(m => (
          <Bracket key={m.Match.Id} tournamentTree={m} openMatchResultModal={openMatchResultModal} openPlayerModal={openPlayerModal} filterName={filterName} />
        ))}
      </div>
      <MovieCard key={tournamentTree.Match.Id} matchId={tournamentTree.Match.Id} openMatchResultModal={openMatchResultModal} openPlayerModal={openPlayerModal} filterName={filterName} />
    </div>
  )
}

export default Bracket
