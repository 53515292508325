import { createSelector } from 'reselect'
import { OperationTypes } from 'store/logic/operations-logic'
import { ApplicationState } from 'store/types/common'
import { makeGetIsAnyOperationWithTypeRunning } from '../operations-selectors'

const getState = (state: ApplicationState) => state.playerEventsPage

export const getPlayerEventsPageFilters = createSelector(getState, state => state?.filters)

export const getPlayerOnlineTournamentsIds = createSelector(getState, state => (state?.onlineTournamentsIds ?? []).sort((a, b) => b - a))
export const getPlayerLiveTournamentsIds = createSelector(getState, state => (state?.liveTournamentsIds ?? []).sort((a, b) => b - a))


export const getAllPlayerOnlineTournamentsCount = createSelector(getState, state => state?.allOnlineTournamentsCount ?? 0)
export const getAllPlayerLiveTournamentsCount = createSelector(getState, state => state?.allLiveTournamentsCount ?? 0)

export const getPlayerOnlineTournamentsLoading = makeGetIsAnyOperationWithTypeRunning(() => ({
  type: OperationTypes.loadPlayerOnlineTournaments,
}))

export const getPlayerLiveTournamentsLoading = makeGetIsAnyOperationWithTypeRunning(() => ({
  type: OperationTypes.loadPlayerLiveTournaments,
}))
