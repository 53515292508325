import React from 'react'
import { cn } from 'utils'
import { ChatPerson } from '../ChatPerson/ChatPerson'
import styles from './ChatPersonInvite.module.scss'

export interface ChatPersonInvite {
  userId: number
  isChecked: boolean
}
export interface ChatPersonInviteProps {
  chatPerson: ChatPersonInvite
  onChange: (value: ChatPersonInvite) => void
}

export const ChatPersonInvite = ({chatPerson, onChange}:  ChatPersonInviteProps) => {

  const userId = chatPerson.userId

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange({...chatPerson, isChecked: event.target.checked})
  }

  return (
    <section className={cn(styles.chatPersonContainer, styles.chatPersonContainerCreateChat)}>
      <ChatPerson personNameClassName={styles.chatPersonName} userId={userId}>
        <div className="col-auto d-flex align-items-center p-0">
          <input type="checkbox" id={userId.toString()} className={styles.chatPersonCheckbox}  checked={chatPerson.isChecked} onChange={handleChange} />
          <label htmlFor={userId.toString()} className={styles.chatPersonLabel}></label>
        </div>
      </ChatPerson>
    </section>
  )
}
