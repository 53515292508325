import { useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import { fetchApi } from 'server/server-utils'
import { AppThunkAction, GetState, PaginationModel } from 'store/types/common'
import { approvedMatchLinksLoaded, ListMatchLinkModel, matchLinkAdded, matchLinkDeleted, MatchLinkModel, matchLinkPusblished, matchLinksLoaded, pendingMatchLinksLoaded, tournamentMatchLinksLoaded } from 'store/types/match-link-types'
import { cancel, OperationTypes, run, useRun } from './operations-logic'

export const useAddMatchLink = () => {
  const dispatch = useDispatch()
  return useRun(OperationTypes.addMatchLink, null, async (matchId: number, link: string) => {
    const mathLink = await fetchApi<MatchLinkModel>(`matchLink?matchId=${matchId}&link=${link}`, 'POST')
    dispatch(matchLinkAdded({ matchLink: mathLink }))
    return true
  })
}

export const usePublishMatchLink = (id: number) => {
  const dispatch = useDispatch()
  return useRun(OperationTypes.publishMatchLink, id, async (id: number) => {
    const mathLink = await fetchApi<MatchLinkModel>(`matchLink/${id}/publish`, 'POST')
    dispatch(matchLinkPusblished({ matchLink: mathLink }))
    return true
  })
}

export const useDeleteMatchLink = (id: number) => {
  const dispatch = useDispatch()
  return useRun(OperationTypes.deleteMatchLink, id, async (id: number) => {
    const mathLink = await fetchApi<MatchLinkModel>(`matchLink/${id}/delete`, 'DELETE')
    dispatch(matchLinkDeleted({ matchLink: mathLink }))
    return true
  })
}

export const loadMatchLinks = async (matchId: number, dispatch: Dispatch, getState: GetState) => {
  await run(OperationTypes.loadMatchLinks, matchId, dispatch, getState, async () => {
    try {
      const mathLinks = await fetchApi<MatchLinkModel[]>(`match/${matchId}/links`, 'GET')
      dispatch(matchLinksLoaded({ matchId: matchId, matchLinks: mathLinks }))
    } catch (error) {
      return false
    }
    return true
  })
}

export const reloadMatchLinks = (matchId: number, dispatch: Dispatch, getState: GetState) => {
  cancel(OperationTypes.loadMatchLinks, matchId, dispatch, getState)
  return loadMatchLinks(matchId, dispatch, getState)
}

export const matchLinksActionCreators = {
  loadMatchLinks: (matchId: number): AppThunkAction => (dispatch: Dispatch, getState: GetState) => {
    return loadMatchLinks(matchId, dispatch, getState)
  },
  loadTournamentMatchLinks: (tournamentId: number): AppThunkAction => async (dispatch: Dispatch, getState: GetState) => {
    await run(OperationTypes.loadTournamentMatchLinks, tournamentId, dispatch, getState, async () => {
      try {
        const mathLinks = await fetchApi<MatchLinkModel[]>(`matchlink/tournament/${tournamentId}`, 'GET')
        dispatch(tournamentMatchLinksLoaded({ matchLinks: mathLinks }))
      } catch (error) {
        return false
      }
      return true
    })
  },
  loadPendingMatchLinks: (pagination: PaginationModel): AppThunkAction => async (dispatch: Dispatch, getState: GetState) => {
    await run(OperationTypes.loadPendingMatchLinks, null, dispatch, getState, async () => {
      try {
        const model = await fetchApi<ListMatchLinkModel>('matchlink/pending', 'POST', pagination)
        dispatch(pendingMatchLinksLoaded(model))
      } catch (error) {
        return false
      }
      return true
    })
  },
  loadApprovedMatchLinks: (pagination: PaginationModel): AppThunkAction => async (dispatch: Dispatch, getState: GetState) => {
    await run(OperationTypes.loadApprovedMatchLinks, null, dispatch, getState, async () => {
      try {
        const model = await fetchApi<ListMatchLinkModel>('matchlink/approved', 'POST', pagination)
        dispatch(approvedMatchLinksLoaded(model))
      } catch (error) {
        return false
      }
      return true
    })
  },
}
