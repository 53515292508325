import React, { useMemo } from 'react'
import { cn } from 'utils'
import { ModalWindow, ModalWindowProps } from './ModalWindow'
import styles from '../modals.module.scss'

export enum ModalWindowAnimationType {
  prev,
  next,
}

export interface ModalAnimationParams {
  animationType: ModalWindowAnimationType
  show?: boolean
}

export interface AnimatedModalWindowProps extends ModalWindowProps, ModalAnimationParams {}

export const AnimatedModalWindow = ({ className, animationType, show, ...props }: AnimatedModalWindowProps) => {
  const animationTypeStyle = useMemo(() => {
    if (show) {
      return ''
    }

    return animationType === ModalWindowAnimationType.prev ? styles.window__prev : styles.window__next
  }, [animationType, show])

  return <ModalWindow {...props} className={cn(styles.window__multi, animationTypeStyle, className)} />
}
