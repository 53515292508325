import { PlayerBattlesAndLoyaltyPointsViewModel } from 'models/Player_OrganizerHeaderViewModel'
import { makeActionCreator, ReduxAction } from './common'

export interface PlayerBattlesAndLoyaltyPointState {
  PlayerBattlesAndLoyaltyPoints: PlayerBattlesAndLoyaltyPointsViewModel
}

interface PlayerBattlesAndLoyaltyPointsLoadedPayload {
  PlayerBattlesAndLoyaltyPoints: PlayerBattlesAndLoyaltyPointsViewModel
}

export const PLAYER_BATTLES_AND_LOYALTY_POINTS_LOADED = 'PLAYER_BATTLES_AND_LOYALTY_POINTS_LOADED'
export type PlayerBattlesAndLoyaltyPointLoadedAction = ReduxAction<typeof PLAYER_BATTLES_AND_LOYALTY_POINTS_LOADED, PlayerBattlesAndLoyaltyPointsLoadedPayload>
export const PlayerBattlesAndLoyaltyPointsLoaded = makeActionCreator<PlayerBattlesAndLoyaltyPointsLoadedPayload>(PLAYER_BATTLES_AND_LOYALTY_POINTS_LOADED)

export type PlayerBattlesAndLoyaltyPointKnownAction = PlayerBattlesAndLoyaltyPointLoadedAction

