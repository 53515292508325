import { makeActionCreator, ReduxAction } from './common'
import { Tournament_Members_IncomeViewModel } from 'models/Player_OrganizerHeaderViewModel'

export interface OrganizerTournamentAndMembersAndIncomeState {
  OrganizerTournamentAndMembersAndIncome: Tournament_Members_IncomeViewModel
  isLoaded?: boolean
}

interface OrganizerTournamentAndMembersAndIncomeLoadedPayload {
  OrganizerTournamentAndMembersAndIncome: Tournament_Members_IncomeViewModel
}

export const ORGANIZER_TOURNAMENT_AND_MEMBERS_AND_INCOME_LOADED = 'ORGANIZER_TOURNAMENT_AND_MEMBERS_AND_INCOME_LOADED'
export type OrganizerTournamentAndMembersAndIncomeLoadedAction = ReduxAction<typeof ORGANIZER_TOURNAMENT_AND_MEMBERS_AND_INCOME_LOADED, OrganizerTournamentAndMembersAndIncomeLoadedPayload>
export const OrganizerTournamentAndMembersAndIncomeLoaded = makeActionCreator<OrganizerTournamentAndMembersAndIncomeLoadedPayload>(ORGANIZER_TOURNAMENT_AND_MEMBERS_AND_INCOME_LOADED)

export type OrganizerTournamentAndMembersAndIncomeKnownAction = OrganizerTournamentAndMembersAndIncomeLoadedAction