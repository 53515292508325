import { createSelector } from 'reselect'
import { OperationTypes } from 'store/logic/operations-logic'
import { ApplicationState } from '../types/common'
import { makeGetIsOperationWithTypeRunning, makeGetIsOperationWithTypeSuccessOrRunning } from './operations-selectors'

const getState = (state: ApplicationState) => state.clansMembers

const getByUserId = createSelector(getState, state => state?.byUserId || {})

const getByClanId = createSelector(getState, state => state?.byClanId || {})

const getLeaderIdByClanId = createSelector(getState, state => state?.leaderIdByClanId || {})

export const makeGetClanMember = () =>
  createSelector(
    getByUserId,
    (_: ApplicationState, { userId }: { userId: number }) => userId,
    (byUserId, userId) => byUserId[userId]
  )

export const makeGetClanMembersIds = () =>
  createSelector(
    getByClanId,
    (_: ApplicationState, { clanId }: { clanId: number }) => clanId,
    (byClanId, clanId) => byClanId[clanId]
  )

export const makeGetClanLeaderId = () =>
  createSelector(
    getLeaderIdByClanId,
    (_: ApplicationState, { clanId }: { clanId: number }) => clanId,
    (leaderIdByClanId, clanId) => leaderIdByClanId[clanId]
  )

const getLoadClanMemberOperationType = createSelector(
  (_, { userId }: { userId: number }) => userId,
  userId => ({ type: OperationTypes.loadClanMember, objectId: userId })
)
export const makeGetIsLoadClanMemberLoading = () => makeGetIsOperationWithTypeRunning(getLoadClanMemberOperationType)
export const makeGetIsLoadClanMemberRequested = () => makeGetIsOperationWithTypeSuccessOrRunning(getLoadClanMemberOperationType)
