import { Reducer } from 'redux'
import { State, KnownAction, DISPUTES_PAGE_INITIALIZED_CHANGED, SHOW_CLOSED_CHANGED, SHOW_OPEN_CHANGED } from '../../types/pages/disputes-page-types'

const initialState: State = {
  pageInitialized: false,
  showClosed: true,
  showOpen: true,
}

const reducer: Reducer<State, KnownAction> = (state: State | undefined = initialState, action: KnownAction) => {
  switch (action.type) {
    case DISPUTES_PAGE_INITIALIZED_CHANGED: {
      return {
        ...state,
        pageInitialized: action.payload.pageInitialized,
      }
    }
    case SHOW_CLOSED_CHANGED: {
      return {
        ...state,
        showClosed: action.payload.showClosed,
      }
    }
    case SHOW_OPEN_CHANGED: {
      return {
        ...state,
        showOpen: action.payload.showOpen,
      }
    }
    default:
      return state
  }
}

export default reducer
