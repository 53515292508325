import { makeActionCreator, ReduxAction } from './common'
import { ImageType } from './image-types'
import { PublicUserType } from './user-types'

export interface FriendsRequestState {
  pendingFriendsIds: number[]
  incomingIds: number[]
  byId: {
    [key: number]: FriendRequestType
  }
  idByUserId: {
    [key: number]: number
  }
}

export interface FriendRequestType {
  Id: number
  FromUserId: number
  ToUserId: number
}

export interface FriendRequestModel {
  FromUserId: number
  ToUserId: number
}

export const FRIEND_REQUESTS_LOADED = 'FRIEND_REQUESTS_LOADED'
interface FriendRequestsLoadedPayload {
  OutgoingRequests: FriendRequestType[]
  IncomingRequests: FriendRequestType[]
  Users: PublicUserType[]
  Images: ImageType[]
}
export type FriendRequestsLoadedAction = ReduxAction<typeof FRIEND_REQUESTS_LOADED, FriendRequestsLoadedPayload>
export const friendRequestsLoaded = makeActionCreator<FriendRequestsLoadedPayload>(FRIEND_REQUESTS_LOADED)


export const FRIEND_REQUEST_ADDED = 'FRIEND_REQUEST_ADDED'
interface FriendRequestAddedPayload {
  userId: number
}
export type FriendRequestAddedAction = ReduxAction<typeof FRIEND_REQUEST_ADDED, FriendRequestAddedPayload>
export const friendRequestAdded = makeActionCreator<FriendRequestAddedPayload>(FRIEND_REQUEST_ADDED)

export const FRIEND_REQUEST_RESOLVED = 'FRIEND_REQUEST_RESOLVED'
interface FriendRequestResolvedPayload {
  friendRequestId: number
}
export type FriendRequestResolvedAction = ReduxAction<typeof FRIEND_REQUEST_RESOLVED, FriendRequestResolvedPayload>
export const friendRequestResolved = makeActionCreator<FriendRequestResolvedPayload>(FRIEND_REQUEST_RESOLVED)

export type KnownAction = FriendRequestsLoadedAction
  | FriendRequestAddedAction
  | FriendRequestResolvedAction
