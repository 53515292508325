import 'react-datepicker/dist/react-datepicker.css'
import 'bootstrap/dist/css/bootstrap-reboot.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../css/old/libs/all-libs.scss'
import '../css/old/menu/OldMenu.scss'
import './App.scss'

import React, { useMemo, Suspense } from 'react'
import { Switch } from 'react-router-dom'
import RECAPTCHA from 'App/_shared/ReCAPTCHA'
import LoadingOld from './_shared/LoadingOld'
import { AppRoutes } from 'components/_routes'
import AppContainer from 'components/AppContainer/AppContainer'
import { useSelector } from 'react-redux'
import { getAppUser } from 'store/selectors/app-selectors'
import { LoadingBox } from 'components/_shared'
import ScrollToTop from './_shared/ScrollToTop'

const App = () => {
  const user = useSelector(getAppUser)
  const displayRecaptcha = useMemo(() => {
    return ((user !== undefined && user.Id) ? false : true)
  }, [user])
  
  return (
    <>
      <AppContainer>
        <ScrollToTop />
        <Switch>        
          <Suspense fallback={<LoadingBox size="extra" fullScreen loading />}>
            <AppRoutes />
            {displayRecaptcha && <RECAPTCHA />}
          </Suspense>
        </Switch>
        <LoadingOld display={false} />
      </AppContainer>
    </>
  )
}

export default App
