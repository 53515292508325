import { TournamentStructure, VenueType } from 'consts'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { makeGetParticipantsByTournamentDetailId } from 'store/selectors/participant-selectors'
import { ApplicationState } from 'store/types/common'
import TournamentResultModel from './TournamentResultModal'
import styles from './list.module.scss'
import { cn } from 'utils'
import { getCurrentUser } from 'store/types/user-types'
import { useDialogWithParameter } from 'components/_hooks/useComponents/useDialog'
import { TournamentType } from 'store/types/tournaments-types'
import { makeGetUser } from 'store/selectors/user-selectors'
import { ContainerName, useTournamentParticipant } from 'components/_hooks'
import ParticipantOnlineAvatar from 'components/_shared/ParticipantAvatar/ParticipantOnlineAvtar'

interface Props {
  tournament: TournamentType
}

const VictoryRoadTournamentCard = ({ tournament }: Props) => {

  const getParticipants = React.useMemo(makeGetParticipantsByTournamentDetailId, [])
  const participants = useSelector((state: ApplicationState) => getParticipants(state, tournament.TournamentDetailId))

  const history = useHistory()

  const onOpenBracket = () => {
    history.push(`/victory-road/bracket/${tournament.TournamentDetailId}`)
  }

  const user = getCurrentUser()

  const getOrginizer = React.useMemo(makeGetUser, [])
  const organizeer = useSelector((state: ApplicationState) => getOrginizer(state, tournament.OrganizerId))

  const [tournamentResultModal, openTournamentResultModal] = useDialogWithParameter<TournamentType>((tournament, closeDialog) => (
    <TournamentResultModel closeDialog={closeDialog} tournament={tournament} />
  ))

  const showWinners = (event: React.MouseEvent) => {
    event.preventDefault()
    openTournamentResultModal(tournament)
  }

  const tournamentWinner = participants.find(tp => tp.PlaceFrom == 1)
  const { name: winnerName } = useTournamentParticipant(tournamentWinner?.Id)

  return (
    <>
      <div className="col-xl-auto col-12 p-0-10">
        <div className={cn(styles.article__tournamentInfoContainer, styles.tournamentInfo)}>
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <div className="row">
                  <div className={styles.tournamentInfo__header}>
                    {`${tournament.Name} (${tournament.TournamentDetailId})`}
                  </div>
                </div>
                <div className="row">
                  <div className={cn(styles.tournamentInfo__label, styles.tournamentInfo__label__green)}>
                    {TournamentStructure[tournament.TournamentStructureId]}
                  </div>
                </div>
              </div>
              <div className="col-auto p-0 position-relative">
                <button className={cn(styles.tournamentInfo__openBracket)} onClick={onOpenBracket} />
                <div className={styles.tournamentInfo__notifierContainer}>
                  <div className={styles.tournamentInfo__notifierContent}>Click to see how it went</div>
                </div>
              </div>
            </div>
            <div className={cn('row', styles.tournamentInfo__hr)} />
            <div className="row m-0--20">
              <div className="col-sm-6 col-12 p-0-20">
                <div className="row">
                  <div className={cn(styles.tournamentInfo__info, styles.tournamentInfo__info__organizer)}>
                    {user.id != tournament.OrganizerId && (
                      <Link to={`/public-profile-view/${tournament.OrganizerId}`}>
                        {organizeer?.Organizationname} {tournament.OrganizerId}
                      </Link>
                    )}
                    {user.id == tournament.OrganizerId && (
                      <Link to={'/my-profile'}>
                        {organizeer?.Organizationname} {tournament.OrganizerId}
                      </Link>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className={cn(styles.tournamentInfo__info, styles.tournamentInfo__info__venue)}>{VenueType[tournament.VenueTypeId]}</div>
                </div>
              </div>
              <div className={cn('col-sm-6', 'col-12', 'p-0-20', styles.tournamentInfo__winner)}>
                {tournamentWinner && (
                  <div className="row m-0 position-relative align-items-center old-kl-css">
                    <ParticipantOnlineAvatar
                      parentClassName={null}
                      withLink
                      participantId={tournamentWinner.Id}
                      className="article__participant-picture"
                      containerName={ContainerName.AvatarsNormal}
                    />
                    <a className={styles.tournamentInfo__winnerUsername} href="#">
                      {winnerName}
                    </a>
                    <div className={styles.tournamentInfo__winnerPrize} />
                  </div>
                )}
                <div className="row m-0">
                  <a
                    className={styles.tournamentInfo__openWinners}
                    onClick={showWinners}
                    href="#"
                  >
                    View all winners
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {tournamentResultModal}
      </div>
    </>
  )
}


export default VictoryRoadTournamentCard
