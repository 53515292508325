import React from 'react'
import { BracketMatch } from './BracketMatch'

export interface BracketMatchesGroupProps {
  matchesIds: number[]
  margin: number
  matchMargin: number
  onParticipantSelected: (participantId?: number) => void
  selectedParticipantId?: number
  filter: string
  openSubmissionResultModal: (v?: any) => void
}

export const BracketMatchesGroup = ({
  matchesIds,
  margin,
  matchMargin,
  onParticipantSelected,
  selectedParticipantId,
  filter,
  openSubmissionResultModal
}: BracketMatchesGroupProps) => {
  return (
    <div className="eli_grid_users-group" style={{ marginTop: margin, marginBottom: margin }}>
      {matchesIds.map(id => (
        <BracketMatch
          key={id}
          matchId={id}
          margin={matchMargin}
          onParticipantSelected={onParticipantSelected}
          selectedParticipantId={selectedParticipantId}
          filter={filter}
          openSubmissionResultModal= {openSubmissionResultModal}
        />
      ))}
    </div>
  )
}
