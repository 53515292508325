import * as React from 'react'
import { TournamentCartUtils } from '../../server/utils/tournament-cart-utils'
import { TournamentEventViewModel } from '../../models/tournament-event-view-model'
import { defaultFormatDate, defaultFormatTime } from 'utils'
import { TournamentViewModel_kl } from 'models/tournament-viewmodel'
import { unjoinParticpant } from 'store/logic/participants-logic'
import { TournamentCartRefundStatus } from 'consts/TournamentCartRefundStatus'
import { GeneralUtils } from 'server/utils/general-utils'
import { kl_AdminRedemption } from 'models/admin-redemption-model'
import { PaymentMethod } from 'consts/PaymentMethod'
import { AdminRedemptionUtils } from 'server/utils/admin-redemption-utils'
import { RequestType } from 'consts/RequestType'
interface Props {
  event: TournamentEventViewModel
  i: number
  refereshMyTickets?: () => void
}

interface State {
  time: string
  tournament: TournamentViewModel_kl
}

class MyTicketsCard extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      time: '',
      tournament: undefined,
    }
  }

  componentDidMount() {
  }

  // refundPayment() {
  //   const { event } = this.props
  //   if (event.UserPaymentId == 1) this.srtripeRefund()
  //   else if (event.UserPaymentId == 2) this.cryptoRefund()
  //   else if (event.UserPaymentId == 3) this.loyaltyRefund()
  // }
  // async srtripeRefund() {
  //   const { event } = this.props
  //   const tranferFundObj: TransferFundViewModel = Object()
  //   tranferFundObj.organizerId = event.UserId
  //   tranferFundObj.adminId = 0
  //   tranferFundObj.totalAmount = event.TotalPrice
  //   const result = await StripeUtils.TransferFund(tranferFundObj)
  //   if (!result.isError) {
  //     await TournamentCartUtils.UpdateRefunded(event.CartDetailId, true)
  //     alert('Loyalty points refunded successfully')
  //   }
  //   alert('Payment refund successfully')
  // }
  // cryptoRefund() { }

  // async loyaltyRefund() {
  //   const { event } = this.props
  //   const loyaltyPointModel: LoyaltyPointsModel = Object()
  //   loyaltyPointModel.note = 'Tournament Ticket Refund'
  //   loyaltyPointModel.points = event.TotalPrice * 70
  //   loyaltyPointModel.userid = event.UserId
  //   const result = await LoyaltyPointsUtils.Save(loyaltyPointModel)
  //   if (!result.isError) {
  //     await TournamentCartUtils.UpdateRefunded(event.CartDetailId, true)
  //     alert('Loyalty points refunded successfully')
  //   }
  // }

  isValidDate(event: TournamentEventViewModel) {
    let isValidDate = false
    const now = new Date()
    if (event && event.RegistrationClosesOn) {
      isValidDate = now < new Date(event.RegistrationClosesOn)
    }
    return isValidDate
  }

  async deleteCartAndUnJoinParticipant(event: TournamentEventViewModel) {
    const that = this;
      await TournamentCartUtils.UpdateRefundedStatus(event.TournamentTicketQuantityId, TournamentCartRefundStatus.Refunded).then(async (res) => {
        if (!res.isError) {
          GeneralUtils.showSuccess("Your participation has been canceled and refunded your payment.")
          that.props.refereshMyTickets()
        }
        else {
            
          GeneralUtils.showError(res.message)
        }
      }).catch((error) => {
          
      GeneralUtils.showError(error.message)
    })
  }

    async adminRedemptionRequest(event: TournamentEventViewModel) {
    const that = this;
    if(event.TicketPrice === null || Number(parseFloat((event.TicketPrice==null?0:event.TicketPrice.toString()))) === 0){
        await TournamentCartUtils.UpdateRefundedStatus(event.TournamentTicketQuantityId, TournamentCartRefundStatus.Refunded).then(async (res) => {
        if (!res.isError && res.data === true) {
          GeneralUtils.showSuccess("Your payment has been refunded successfully.")
          that.props.refereshMyTickets()
        }
        else {
          GeneralUtils.showError(res.message)
        }
      }).catch(function (error) {
          GeneralUtils.showError(error.message)
      })
    }
    else{
      const adminRedemption: kl_AdminRedemption = Object()
      adminRedemption.Amount = Number(parseFloat(event.TicketPrice.toString()))
      adminRedemption.TournamentId = event.Id
      adminRedemption.TournamentTicketQuantityId = event.TournamentTicketQuantityId
      adminRedemption.Status = 1
      adminRedemption.CoinType = null
      adminRedemption.RequestTypeId = RequestType.Refund
        adminRedemption.PaymentMethod = 
        event.UserPaymentId == PaymentMethod.Credit
          ? PaymentMethod[event.UserPaymentId].toString()
          : event.UserPaymentId == PaymentMethod.LoyaltyPoints
            ? PaymentMethod[event.UserPaymentId].toString()
            : event.UserPaymentId == PaymentMethod.Crypto
              ? PaymentMethod[event.UserPaymentId].toString()
              : null
      
      await AdminRedemptionUtils.Add(adminRedemption).then(async function (result) {
        if (!result.isError && result.data && result.data.Id > 0) {
          await TournamentCartUtils.UpdateRefundedStatus(event.TournamentTicketQuantityId, TournamentCartRefundStatus.Pending).then(function (res) {
            if (!res.isError && res.data === true) {
              GeneralUtils.showSuccess("Your refund payment request has been send successfully.")
              that.props.refereshMyTickets()
            }
            else {
              GeneralUtils.showError(res.message)
            }
          }).catch(function (error) {
            GeneralUtils.showError(result.message)
          })
        }
        else {
          GeneralUtils.showError(result.message)
        }
      }).catch(function (error) {
        GeneralUtils.showError(error.message)
      })
    }
    
  }

  async cancelTicket(event: TournamentEventViewModel) {
    if (event.IsParticipient) {
      await this.deleteCartAndUnJoinParticipant(event);
    }
    else {
      await this.adminRedemptionRequest(event)
    }
  }

  render() {
    const { event, i } = this.props
    const { time } = this.state
    return (
      <>
        {event && i == 0 && (
          <div className="p-3 table__column table__headers-row d-none d-sm-none d-md-none d-lg-flex d-xl-flex ">
            <div className="p-0 col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 bold">Name </div>
            <div className="p-0 col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 bold"> Start Date </div>
            <div className="p-0 col-12 col-sm-12 col-md-1 col-lg-1 col-xl-1 bold"> Time </div>
            <div className="p-0 col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 bold"> Role </div>
            <div className="p-0 col-12 col-sm-12 col-md-1 col-lg-1 col-xl-1 bold"> Region </div>
            <div className="p-0 col-12 col-sm-12 col-md-1 col-lg-1 col-xl-1 bold"> ID </div>
            <div className="p-0 col-12 col-sm-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 bold" />
          </div>
        )}
        <div className="row table__column table__row p-3 d-sm-inline-flex d-md-inline-flex d-lg-flex d-xl-flex ">
          <div className="p-0 col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 my-ticket-table__column--name">{event.Name} </div>
          <div className="p-0 col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 my-ticket-table__column--date">
            {' '}
            {event.Date && event.Date!=null && event.Date!==''? defaultFormatDate(event.Date):'-'}{' '}
          </div>
          <div className="p-0 col-12 col-sm-6 col-md-6 col-lg-1 col-xl-1 my-ticket-table__column--time"> {event.Date && event.Date!=null && event.Date!==''?defaultFormatTime(event.Date):'-'} </div>
          <div className="p-0 col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 my-ticket-table__column--role">
            {' '}
            {event.IsParticipient ? 'Participant' :  'Spectator ' + (event.Date != null && event.DayNumber != null ? '(Day ' + event.DayNumber.toString() + ')':'(Package Deal)') }{' '}
          </div>
          <div className="p-0 col-12 col-sm-6 col-md-6 col-lg-1 col-xl-1 my-ticket-table__column--location"> {event.Region} </div>
          <div className="p-0 col-12 col-sm-6 col-md-6 col-lg-1 col-xl-1 my-ticket-table__column--id"> {event.Id} </div>
          <div className="p-0 col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 my-ticket-table__column--button d-flex">
            {/* {event.RefundTicket && (
              <button className="btn-sm mr-2 table__button--refund orange-button orange-button--filled" onClick={() => this.refundPayment()}>
                REFUND TICKET{' '}
              </button>
            )} */}
            {this.isValidDate(event) && event.RefundedStatus === null && (
              <button className="btn-sm mr-2 table__button--refund orange-button orange-button--filled p-4" onClick={() => this.cancelTicket(event)}>
                CANCEL
              </button>
            )}
            {event.RefundedStatus === TournamentCartRefundStatus.Refunded && (
              <button className="btn-sm table__button--print grey-button grey-button--filled p-4">
                REFUNDED
              </button>
            )}

{event.RefundedStatus === TournamentCartRefundStatus.Pending && (
              <button className="btn-sm mr-2 table__button--refund orange-button orange-button--filled p-4">
                REFUND PENDING
              </button>
            )}
            {event.RefundedStatus === TournamentCartRefundStatus.Canceled && (
              <button className="btn-sm mr-2 table__button--refund orange-button orange-button--filled p-4">
                REFUND Canceled
              </button>
            )}

            {event.RefundedStatus === null && (
              <button
                className="btn-sm table__button--print grey-button grey-button--filled p-4"
                onClick={() => {
                  window.location.href = `/tickets/ticket/${event.TournamentTicketQuantityId}`
                }}
              >
                PRINT TICKET
              </button>
            )}
          </div>
        </div>
      </>
    )
  }
}

export default MyTicketsCard
