import { useAlertModal } from 'components/_hooks'
import { ProfileCard } from 'components/_shared/ProfileCard/ProfileCard'
import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { declineFriendRequest, useAcceptFriendRequest } from 'store/logic/friend-request-logic'
import { reLoadTopFriends } from 'store/logic/user-logic'
import { makeGetFriendRequest, makeGetIsResolveFriendRequestLoading } from 'store/selectors/friend-request-selectors'
import { makeGetUser } from 'store/selectors/user-selectors'
import { ApplicationState } from 'store/types/common'
import { getUserProfileUrl } from 'store/types/user-types'

interface FriendRequestProps {
  id: number
}

export const FriendRequest = ({ id }: FriendRequestProps) => {
  const dispatch = useDispatch()
  const getFriendRequest = useMemo(makeGetFriendRequest, [])
  const getIsResolveFriendRequestLoading = useMemo(makeGetIsResolveFriendRequestLoading, [])
  const getUser = useMemo(makeGetUser, [])
  
  const friendRequest = useSelector((state: ApplicationState) => getFriendRequest(state, { id }))
  const isResolveFriendRequestLoading = useSelector((state: ApplicationState) => getIsResolveFriendRequestLoading(state, friendRequest?.Id))
  const user = useSelector((state: ApplicationState) => getUser(state, friendRequest.FromUserId))

  const acceptRequest = useAcceptFriendRequest()
  const declineRequest = declineFriendRequest(friendRequest?.Id)

  const [alertModal, openAlertModal] = useAlertModal()

  const handleDecline = () => {
    try {
      dispatch(declineRequest)
    }
    catch (error) {
      openAlertModal((error as Error).message)
    }
  }

  const handleAccept = async () => {
    try {
      await acceptRequest(friendRequest?.Id)
      dispatch(reLoadTopFriends(friendRequest?.ToUserId))
      dispatch(reLoadTopFriends(friendRequest?.FromUserId))
    }
    catch (error) {
      openAlertModal((error as Error).message)
    }
  }

  return (
    <>
      <ProfileCard 
        className="mt-2"
        name={user?.Username}
        label={'View profile'}
        link={getUserProfileUrl(user?.Id)}
        imageId={user?.AvatarId}
        withButtons
        onAccept={handleAccept}
        onDecline={handleDecline}
        disabled={isResolveFriendRequestLoading}
      />
      {alertModal}
    </>
  )
}
