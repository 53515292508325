import React, { useEffect, useMemo } from 'react'
import styles from '../SignUp.module.scss'
import { SignUpDatePicker } from './SignUpDatePicker'
import { SignUpTextInput } from './SignUpTextInput'
import { Select } from 'components/_shared'
import { useSelector } from 'react-redux'
import { getRegions, getRegionsLoading } from 'store/selectors/region-selectors'
import { regionLabelExtractor, RegionType, regionValueExtractor } from 'store/types/region-types'
import { PasswordInput } from './PasswordInput'
import { DisplayInDirectoryType } from './consts'
import { cn } from 'utils'
import { RadioInput } from 'components/_library/RadioInput/RadioInput'

interface PlayerSignUpProps {
  email: string
  onEmailChange: (value: string) => void
  password: string
  onPasswordChange: (value: string) => void
  passwordConfirm: string
  onPasswordConfirmChange: (value: string) => void
  birthDate?: Date
  onBirthDateChange: (value?: Date) => void
  displayInDirectory: DisplayInDirectoryType
  onDisplayInDirectoryChange: (value: DisplayInDirectoryType) => void
  region: RegionType
  onRegionChange: (value: RegionType) => void
  username: string
  onUsernameChange: (value: string) => void
  display: boolean
  onFilledChange: (value: boolean) => void
}

export const PlayerSignUp = ({
  email,
  onEmailChange,
  password,
  onPasswordChange,
  passwordConfirm,
  onPasswordConfirmChange,
  birthDate,
  onBirthDateChange,
  displayInDirectory,
  onDisplayInDirectoryChange,
  region,
  onRegionChange,
  username,
  onUsernameChange,
  onFilledChange,
  display,
}: PlayerSignUpProps) => {
  const regions = useSelector(getRegions)
  const regionsLoading = useSelector(getRegionsLoading)

  const isFilled = useMemo(() => {
    return email.length > 0
      && password.length > 0
      && passwordConfirm.length > 0
      && username.length > 0
      && birthDate !== undefined
      && region !== undefined
  }, [birthDate, email.length, password.length, passwordConfirm.length, region, username.length])

  const handleDisplayInDirectoryChange = (value: string) => {
    onDisplayInDirectoryChange(value as DisplayInDirectoryType)
  }

  useEffect(() => {
    onFilledChange(isFilled)
  }, [isFilled, onFilledChange])
  
  return (
    <div className={cn('row', !display && 'd-none')}>
      <div className="col-lg-4 col-md-6">
        <div className="d-flex flex-column">
          <SignUpTextInput
            value={username}
            onChange={onUsernameChange}
            title="Username"
            placeholder="Username"
            withClear
          />
          <SignUpDatePicker
           value={birthDate}
           onChange={onBirthDateChange}
           title="Date of Birth"
           min="1900-01-01"
           checkOnBlur={true}
          />
          <div className={styles.signUpInputTitle}>Region</div>
          <Select
            options={regions}
            onChange={onRegionChange}
            selected={region}
            labelExtractor={regionLabelExtractor}
            valueExtractor={regionValueExtractor}
            type="single"
            loading={regionsLoading}
            required
            placeHolder="Region"
            containerClassName={styles.regionSelectContainerClassName}
          />
        </div>
      </div>
      <div className="col-lg-4 col-md-6">
        <div className="d-flex flex-column">
          <SignUpTextInput
            value={email}
            onChange={onEmailChange}
            title="Email"
            placeholder="Email"
            withClear
          />
          <PasswordInput
            value={password}
            onChange={onPasswordChange}
            title="Password"
            placeholder="Enter password"
            className={styles.signUpContainer}
            inputClassName={styles.signUpDefaultInput}
            iconClassName={styles.signUpVisibleButton}
          />
          <PasswordInput
            value={passwordConfirm}
            onChange={onPasswordConfirmChange}
            title="Password Confirmation"
            placeholder="Confirm password"
            className={styles.signUpContainer}
            inputClassName={styles.signUpDefaultInput}
            iconClassName={styles.signUpVisibleButton}
          />
        </div>
      </div>
      <div className="col-lg-4 col-md-6">
        <div className="d-flex flex-column">
          <div className={styles.signUpInputTitle}>Do you want to be listed in player directory?</div>
          <div className="d-flex flex-row justify-content-between">
            <RadioInput
              name="listed"
              value="yes"
              checked={displayInDirectory === 'yes'}
              label="Yes"
              withDot
              onChange={handleDisplayInDirectoryChange}
            />
            <RadioInput
              name="listed"
              value="no"
              checked={displayInDirectory === 'no'}
              label="No"
              withDot
              onChange={handleDisplayInDirectoryChange}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
