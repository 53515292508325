import { makeActionCreator, ReduxAction } from './common'
import { ImageType } from './image-types'
import { PublicUserType } from './user-types'

export interface LineUpType {
  Id: number
  TournamentParticipantId: number
  PlayerId: number
  IsSubstitute?:boolean
  IsDefault?:boolean
  IsDelete?:boolean
}

export interface LineUpsType {
  LineUps: LineUpType[]
  Users: PublicUserType[]
  Images: ImageType[]
}

export interface State {  
  byId: {
    [key: number]: LineUpType
  }
  lineUpIdsByParticipantId: {
    [key: number]: number[]
  }
}


export const LINE_UPS_LOADED = 'LINE_UPS_LOADED'

export type LineUpsLoadedAction = ReduxAction<typeof LINE_UPS_LOADED, LineUpsType>
export const lineUpsLoaded = makeActionCreator<LineUpsType>(LINE_UPS_LOADED)


export const LINE_UP_ADDED = 'LINE_UP_ADDED' 
interface LineUpAddedPayload {
  lineUp: LineUpType
}

export type LineUpAddedAction = ReduxAction<typeof LINE_UP_ADDED, LineUpAddedPayload>
export const lineUpAdded = makeActionCreator<LineUpAddedPayload>(LINE_UP_ADDED)

export const LINE_UP_DELETED = 'LINE_UP_DELETED' 
interface LineUpDeletedPayload {
  lineUp: LineUpType
}

export type LineUpDeletedAction = ReduxAction<typeof LINE_UP_DELETED, LineUpDeletedPayload>
export const lineUpDeleted = makeActionCreator<LineUpDeletedPayload>(LINE_UP_DELETED)


export type KnownAction = LineUpsLoadedAction 
| LineUpAddedAction 
| LineUpDeletedAction 
