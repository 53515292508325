import * as React from 'react'
import { Kl_OrganizerPayout } from '../../models/billing-option-model'
import { StripeCurrencyViewModel } from '../../models/kl_country-model'
// import { Kl_CurrencyUtils } from '../../server/utils/kl_currency-utils'
import { kl_CountryModel } from '../../models/kl_country-model'
import { Kl_BankAccountFields } from '../../models/kl_BankAccountFields-models'
import { OrganizerBankAccountFieldsModel, OrganizerBankAccountModel } from '../../models/organizer-bank-account-models'
import { StripeUtils } from '../../server/utils/stripe-utils'
import { GeneralUtils } from '../../server/utils/general-utils'
import { Loading } from 'components/_shared'
interface Props {
  organizerPayout: Kl_OrganizerPayout
  showClass: string
  verified: (data, result) => void
  userCountry: string
  userCountryCode: string
  currentRoute: string
}

interface State {
  organizerPayout: Kl_OrganizerPayout
  acountNumberMatch: string
  currencyList: StripeCurrencyViewModel[]
  allCountries: kl_CountryModel[]
  kl_BankAccountFields: Kl_BankAccountFields[]
  selectedCountry: string
  selectedCurrency: string
  organizerBankAccountModel: OrganizerBankAccountModel
  organizerBankAccountFieldsModel: OrganizerBankAccountFieldsModel
  showClass: string
  errorMessage: string
  selctedCountryCode: string
  loading: boolean
}

class BillingOptionDailog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      organizerPayout: Object(),
      acountNumberMatch: '',
      currencyList: [],
      allCountries: [],
      kl_BankAccountFields: [],
      selectedCountry: '',
      selectedCurrency: '',
      organizerBankAccountModel: Object(),
      organizerBankAccountFieldsModel: Object(),
      showClass: '',
      errorMessage: '',
      selctedCountryCode: '',
      loading: false
    }

    this.onChangeOrganizerPayout = this.onChangeOrganizerPayout.bind(this)
    this._saveOrganizerPayout = this._saveOrganizerPayout.bind(this)
    this.onChangePaymentMethod = this.onChangePaymentMethod.bind(this)
    this.onChangeCurrency = this.onChangeCurrency.bind(this)
    this.handleAccountNumber = this.handleAccountNumber.bind(this)
    // this._getCountryFromStripe = this._getCountryFromStripe.bind(this);
    // this._getCurrencyList = this._getCurrencyList.bind(this)
    this.onChangeBankAccountDetailHandler = this.onChangeBankAccountDetailHandler.bind(this)
    this.GetBankAccountFields = this.GetBankAccountFields.bind(this)
  }
  componentWillReceiveProps(props: Props) {
    if (props.organizerPayout) {
      this.setState({
        organizerPayout: props.organizerPayout,
        acountNumberMatch: props.organizerPayout.AccountNumber,
        showClass: props.showClass,
        selectedCountry: props.userCountry,
        selctedCountryCode: props.userCountryCode,
      })
    }
  }

  componentDidMount() {
    if (this.props.organizerPayout) {
      this.setState({
        organizerPayout: this.props.organizerPayout,
        acountNumberMatch: this.props.organizerPayout.AccountNumber,
        showClass: this.props.showClass,
        selectedCountry: this.props.userCountry,
        selctedCountryCode: this.props.userCountryCode,
      })
    }
    // this._getCurrencyList()
    this.GetBankAccountFields()
  }
  handleAccountNumber(e) {
    let { organizerPayout, acountNumberMatch } = this.state
    acountNumberMatch = e.target.value
    if (organizerPayout.AccountNumber == acountNumberMatch) {
      this.setState({
        acountNumberMatch,
      })
    }
  }

  // async _getCurrencyList() {
  //   const currencies = await Kl_CurrencyUtils.GetAllStripeCurrency()
  //   if (!currencies.isError) {
  //     const arrayUniqueByKey = [...new Map(currencies.data.map(item => [item.CurrencyCode, item])).values()]
  //     this.setState({
  //       currencyList: arrayUniqueByKey,
  //     })
  //   }
  // }

  onChangeOrganizerPayout(e) {
    const { organizerPayout } = this.state
    organizerPayout[e.target.name] = e.target.value
    this.setState({
      organizerPayout,
    })
  }
  onChangePaymentMethod(e) {
    const { organizerPayout } = this.state
    organizerPayout.PaymentMethodId = parseInt(e.target.value)
    this.setState({
      organizerPayout,
    })
  }
  onChangeCurrency(e) {
    const { organizerPayout } = this.state
    organizerPayout.CurrencyId = parseInt(e.target.value)
    this.setState({
      organizerPayout,
    })
  }
  onChangeBankAccountDetailHandler(name, value) {
    const organizerBankAccountFieldsModel = this.state.organizerBankAccountFieldsModel
    organizerBankAccountFieldsModel[name] = value
    const { kl_BankAccountFields } = this.state
    const data = kl_BankAccountFields.length > 0 ? kl_BankAccountFields : []
    organizerBankAccountFieldsModel.Id = data[0].Id
    this.setState({ organizerBankAccountFieldsModel })
  }
  getOrganizerBankAccountDetails() {
    const { organizerBankAccountModel, organizerBankAccountFieldsModel, kl_BankAccountFields } = this.state
    if (Object.keys(organizerBankAccountFieldsModel).length === 0) {
      return organizerBankAccountModel
    }
    const CalculateRoutingNumber = kl_BankAccountFields.filter(x => x.Id == organizerBankAccountFieldsModel.Id)[0].CalculateRoutingNumber
    organizerBankAccountModel.AccountNumber = organizerBankAccountFieldsModel.AccountNumber
    if (CalculateRoutingNumber != null && CalculateRoutingNumber.includes('-')) {
        const RoutingNumber = CalculateRoutingNumber.split('-')
        organizerBankAccountModel.TransitNumber = organizerBankAccountFieldsModel[RoutingNumber[0]]

        organizerBankAccountModel.InstitutionNumber = organizerBankAccountFieldsModel[RoutingNumber[1]]
      organizerBankAccountModel.RoutingNumber =
        organizerBankAccountFieldsModel[RoutingNumber[0]] + '-' + organizerBankAccountFieldsModel[RoutingNumber[1]]
    } else if (CalculateRoutingNumber != null && CalculateRoutingNumber.includes('+')) {
        const RoutingNumber = CalculateRoutingNumber.split('+')
        organizerBankAccountModel.TransitNumber = organizerBankAccountFieldsModel[RoutingNumber[0]]

        organizerBankAccountModel.InstitutionNumber = organizerBankAccountFieldsModel[RoutingNumber[1]]
      organizerBankAccountModel.RoutingNumber = organizerBankAccountFieldsModel[RoutingNumber[0]] + organizerBankAccountFieldsModel[RoutingNumber[1]]
    } else {
      organizerBankAccountModel.RoutingNumber = organizerBankAccountFieldsModel.RoutingNumber
    }
    organizerBankAccountModel.PaymentMethod = 1
    return organizerBankAccountModel
  }
  async GetBankAccountFields() {
    const result = await StripeUtils.GetBankAccountFields(this.props.userCountry)
    if (!result.isError) {
      this.setState({
        kl_BankAccountFields: result.data,
      })

      GeneralUtils.showHideLoadingDiv(false)
    } else {
      GeneralUtils.showError(result.message)
      GeneralUtils.showHideLoadingDiv(false)
    }
  }

  renderBankAccountFields() {
    const { kl_BankAccountFields } = this.state
    const data = kl_BankAccountFields.length > 0 ? kl_BankAccountFields : []
    if (data.length > 0) {
      if (!data[0].IsCountrySupported) {
        return (
          <div className="col-12">
            {' '}
            <h4 className="window__subheader text-danger">{GeneralUtils.NotSupportedPayoutMessage}</h4>
          </div>
        )
      }

      const result: any[] = JSON.parse(data[0].Bankfields)

      return (
        result &&
        result.length > 0 &&
        result.map((item, i) => {
          return (
            <div key={i.toString()} className="col-md-6 col-12 ">
              <div className="row m-0 flex-column">
                <h4 className="window__subheader">{item.label}</h4>
                <input
                  type="text"
                  id={item.name}
                  name={item.name}
                  onChange={e => {
                    this.onChangeBankAccountDetailHandler(e.target.name, e.target.value)
                  }}
                  className="window__input"
                  placeholder={item.placeholder}
                />
              </div>
            </div>
          )
        })
      )
    }
  }
  async _saveOrganizerPayout() {
    const organizerBankAccountModel = this.getOrganizerBankAccountDetails()
    if (Object.keys(organizerBankAccountModel).length === 0) {
      return
    }
    organizerBankAccountModel.UserName = ''
    organizerBankAccountModel.CountryId = 0
    organizerBankAccountModel.Currency = ''
    organizerBankAccountModel.CountryCode = this.state.selctedCountryCode
    organizerBankAccountModel.Country = this.state.selectedCountry
    // organizerBankAccountModel.serName = ""

    const result = await StripeUtils.OrganizerStripeOnboarding(organizerBankAccountModel)

    if (result.data.Status) {
      localStorage.setItem('currentroute', this.props.currentRoute)
      this.props.verified(result.data, organizerBankAccountModel)
      document.getElementById('modal_bank_account_details').className = 'modal-container'
      this.setState({
          loading: false
      })
    } else {
      GeneralUtils.showError(result.message)
      this.setState({
          errorMessage: result.message,
          loading: false
      })
    }
  }

  render() {
    const {} = this.props
    const { showClass } = this.state
    return (
      <>
        <aside className={'bil_modal-container modal-container ' + `${showClass}`} id="modal-edit-payout">
          <div className="modal-container__background" />
          <div className="bil_modal-container__window modal-container__window window">
            <a
              href="javascript:void(0)"
              className="window__close"
              onClick={() => {
                this.props.verified(false, null)
                document.getElementById('modal-edit-payout').className = 'bil_modal-container modal-container'
              }}
            />
            <div className="window__header-container">
              <div className="row">
                <div className="col-12">
                  <div className="bil_modal_hdr">
                    <div className="bil_modal_hdr_i">
                      <h2 className="bil_modal_hdr_ttl">Payout Section</h2>
                    </div>
                    <div className="bil_modal_hdr_i">
                      <button disabled={this.state.loading}
                        className="blue-button blue-button--filled"
                        type="button"
                        onClick={() => {
                          this.setState({
                            loading:true
                          },()=>{
                            this._saveOrganizerPayout()
                          })
                        }}
                      >
                        Save Details
                        {this.state.loading && <Loading size="small" className="ml-2" />}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="window__container">
              <div className="container-fluid p-0">
                <div className="row overflow-auto">
                  <div className="col-md-6 col-12">
                    <div className="row m-0 flex-column">
                      <h4 className="window__subheader">Payment Method</h4>
                      <select
                        id="select-add-new-payment"
                        className="form-control  window__input"
                        name="PaymentMethod"
                        onChange={e => {
                          this.onChangeBankAccountDetailHandler(e.target.name, e.target.value)
                        }}
                      >
                        <option value="1" selected>
                          Bank Account
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="row m-0 flex-column">
                      <h4 className="window__subheader">Country</h4>
                      <input type="text" className="form-control window__input" disabled value={this.state.selectedCountry} />
                      {/* <select
                        disabled
                        className="window__input"
                        name="countryCode"
                        value={this.state.selectedCountry}
                        onChange={(e) => {}}
                      >
                        {this.state.allCountries &&
                          this.state.allCountries.length > 0 &&
                          this.state.allCountries.map(
                            ({ CountryName, Id }, index) => (
                              <option key={index} value={Id}>{CountryName}</option>
                            )
                          )}
                      </select> */}
                    </div>
                  </div>
                  {/* <div className="col-md-6 col-12">
                    <div className="row m-0 flex-column">
                      <h4 className="window__subheader">Currency</h4>
                      <input type="text" className="form-control window__input" disabled value={this.state.selectedCurrency} />
                    </div>
                  </div> */}

                  {this.state.selectedCountry !== '' && this.renderBankAccountFields()}
                  {this.state.errorMessage != '' && (
                    <div className="col-md-12 col-12">
                      <div className="row m-0 flex-column">
                        <h6 className="text-danger">{this.state.errorMessage}</h6>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </aside>
      </>
    )
  }
}

export default BillingOptionDailog
