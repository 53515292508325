import React, { ComponentType, ReactNode } from 'react'
import { Route, RouteComponentProps } from 'react-router-dom'
import { cn } from 'utils'

interface OwnProps {
  component?: ComponentType<RouteComponentProps<unknown>> | React.ComponentType<unknown>
  path: string
  exact?: boolean
  oldCss?: boolean
  children?: ReactNode
}

export const RouteWithoutLayout = ({ component, path, exact, oldCss, children }: OwnProps) => {
  const renderComponent = (props: RouteComponentProps) => {
    const Component = component

    return (
      <div className={cn(oldCss && 'old-kl-css')}>
        {children || <Component {...props} />}
      </div>
    )
  }

  return <Route exact={exact} path={path} component={renderComponent} />
}
