export interface APIResponse<T> {
  isError: boolean
  message?: string
  data?: T
}

export type HTTPMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function fetchApi<T>(endPoint: string, method: HTTPMethod, data?: any) {
  const uri = `/api/${endPoint}`
  const encodedUri = encodeURI(uri)
  const fullUri = data && method === 'GET' ? `${encodedUri}?${ObjectToUrlQuery(data)}` : encodedUri

  let body: RequestInit['body']
  let headers: RequestInit['headers']

  if (data instanceof FormData) {
    body = data
    headers = {}
  } else {
    body = method !== 'GET' ? JSON.stringify(data) : undefined
    headers = {
      'Content-Type': 'application/json; charset=utf-8',
    }
  }

  const response = await fetch(fullUri, {
    headers,
    method,
    body,
    mode: 'cors',
    credentials: 'include',
  })
  return handleFetchResponse<T>(response)
}

export async function handleFetchResponse<T>(response: Response) {
  if (response.ok) {
    const result = (await response.json()) as APIResponse<T>
    if (result.isError) {
      throw new Error(result.message)
    }
    return result.data
  }
  throw new Error('An error has occurred')
}

export interface QueryObject {
  [key: string]: unknown
}

export default function ObjectToUrlQuery<T extends QueryObject>(obj: T): string {
  const list = Object.keys(obj)
    .map(key => (obj[key] === undefined ? undefined : `${encodeURI(key)}=${encodeURIComponent(obj[key]?.toString())}`))
    .filter(s => s)
  return list.join('&')
}
