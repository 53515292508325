export enum TournamentSortFieldEnum {
  RegisrationCloseOn = 1,
  StartDate = 2,
  Name = 3
}


export const TournamentSortField = {
  [TournamentSortFieldEnum.RegisrationCloseOn]: 'Signup Deadline',
  [TournamentSortFieldEnum.StartDate]: 'Start Date',
  [TournamentSortFieldEnum.Name]: 'Name',
}


export const TournamentSortFieldOptions = [
  ...Object.keys(TournamentSortField).map(key => ({
    id: +key,
    name: TournamentSortField[+key as TournamentSortFieldEnum],
  })),
]
