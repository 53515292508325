import { createSelector } from 'reselect'
import { OperationTypes } from 'store/logic/operations-logic'
import { ApplicationState } from 'store/types/common'
import { makeGetIsOperationWithTypeRunning, makeGetIsOperationWithTypeSuccessOrRunning } from './operations-selectors'

const getState = (state: ApplicationState) => state.reviews

export const getById = createSelector(getState, state => state?.byId || {})

export const getIdsByUserId = createSelector(getState, state => state?.idsByUserId || {})

export const getAllReviewsCount = createSelector(getState, state => state?.allReviewsCount ?? 0)

export const getMyReviewIdByUserId = createSelector(getState, state => state?.myReviewIdByUserId || {})

export const makeGetMyReviewId = () =>
  createSelector(
    getMyReviewIdByUserId,
    (_: ApplicationState, { userId } : { userId: number} ) => userId,
    (byId, userId) => byId[userId]
  )

export const makeGetReview = () =>
  createSelector(
    getById,
    (_: ApplicationState, { id } : { id: number} ) => id,
    (byId, id) => byId[id]
  )

export const makeGetIdsByUserId = () => 
  createSelector(
    getIdsByUserId,
    (_: ApplicationState, { userId }: { userId: number }) => userId,
    (ids, userId) => ids[userId] ?? []
  )

const getAddReviewOperationType = createSelector(
  (_, { userId } : { userId: number }) => userId,
  userId => ({ type: OperationTypes.addReview, objectId: userId })
)
export const makeGetIsAddReviewLoading = () => makeGetIsOperationWithTypeRunning(getAddReviewOperationType)

const getLoadMyReviewOperationType = createSelector(
  (_: ApplicationState, { userId }: { userId: number }) => userId,
  userId => ({ type: OperationTypes.loadMyReview, objectId: userId })
)
export const makeGetIsMyReviewLoading = () => makeGetIsOperationWithTypeRunning(getLoadMyReviewOperationType)
export const makeGetIsMyReviewRequested = () => makeGetIsOperationWithTypeSuccessOrRunning(getLoadMyReviewOperationType)

const getLoadReviewsOperationType = createSelector(
  (_: ApplicationState, { userId }: { userId: number }) => userId,
  userId => ({ type: OperationTypes.loadReviews, objectId: userId })
)
export const makeGetIsReviewsLoading = () => makeGetIsOperationWithTypeRunning(getLoadReviewsOperationType)
export const makeGetIsReviewsRequested = () => makeGetIsOperationWithTypeSuccessOrRunning(getLoadReviewsOperationType)
