import React, { ReactNode } from 'react'
import { cn } from 'utils'
import styles from './ChatSubheader.module.scss'

export interface ChatSubheaderProps {
  children: ReactNode
  className?: string
}

export const ChatSubheader = ({ children, className }:  ChatSubheaderProps) => {
  return (
    <div className="row m-0">
      <h3 className={cn(styles.chatSubheader, className)}>{children}</h3>
    </div>
  )
}
