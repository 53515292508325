import { ContainerName, useClanMember, useUser } from 'components/_hooks'
import { ProfileCard } from 'components/_shared'
import React from 'react'
import { getClanMemberRoleName } from 'store/types/clan-member-types'
import { getUserProfileUrl } from 'store/types/user-types'
import { cn } from 'utils'
import styles from '../../ClanPage.module.scss'

export interface ClanMemberProps {
  userId: number
}

export const ClanMember = ({ userId }:  ClanMemberProps) => {
  const { user } = useUser(userId)
  const clanMember = useClanMember(userId)

  return (
    <ProfileCard
      className={cn(styles.card, 'm-1')}
      link={getUserProfileUrl(user.Id)}
      name={user.Username}
      imageId={user.AvatarId}
      label={getClanMemberRoleName(clanMember.Role)}
      containerName={ContainerName.AvatarsNormal}
    />
  )
}
