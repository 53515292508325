import { LoadingBox, Pagination } from 'components/_shared'
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadClanMembers } from 'store/logic/pages/clan-page-logic'
import { getAllMembersCount, getMembersIds, getMembersPage, makeGetIsClanMembersLoading } from 'store/selectors/pages/clan-page-selectors'
import { ApplicationState } from 'store/types/common'
import { membersPageChanged } from 'store/types/pages/clan-page-types'
import { cn } from 'utils'
import { ClanMember } from './ClanMember'
import styles from '../../ClanPage.module.scss'

const pageSize = 12

export interface ClanMembersProps {
  clanId: number
}

export const ClanMembers = ({ clanId }:  ClanMembersProps) => {
  const dispatch = useDispatch()
  const allMembersCount = useSelector(getAllMembersCount)
  const membersIds = useSelector(getMembersIds)
  const membersPage = useSelector(getMembersPage)
  const getIsClanMembersLoading = useMemo(makeGetIsClanMembersLoading, [])
  const isClanMembersLoading = useSelector((state: ApplicationState) => getIsClanMembersLoading(state, { clanId }))

  const handlePageChanged = (value: number) => {
    dispatch(membersPageChanged(value))
  }

  useEffect(() => {
    if (clanId) {
      dispatch(loadClanMembers(clanId, {
        page: membersPage,
        pageSize,
      }))
    }
  }, [clanId, dispatch, membersPage])

  return (
    <>
      <div className={cn(styles.rightTile, 'pb-2 mt-2')}>Clan Members</div>
      <LoadingBox loading={isClanMembersLoading} size="big">
        <div className={cn(styles.membersContainer, 'd-flex flex-wrap')}>
          {membersIds.length > 0 
            ? membersIds.map(id => <ClanMember key={id} userId={id} />)
            : <div className={cn(styles.rightValue, 'pb-2 mt-2')}>No members</div>
          }
        </div>
        <Pagination page={membersPage} itemsPerPage={pageSize} allItemsCount={allMembersCount} onChangePage={handlePageChanged} />
      </LoadingBox>
    </>
  )
}
