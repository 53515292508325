import { Reducer } from 'redux'
import { KnownAction, MATCHES_SETTINGS_LOADED, MATCH_SETTINGS_SET, State } from 'store/types/match-settings-types'
import { arrayToObject } from 'utils/array-utils'

export const initialMatchSettingsState: State = {
  matchSettingsByMatchId: {},
}


const reducer: Reducer<State, KnownAction> = (state: State | undefined = initialMatchSettingsState, action: KnownAction) => {
  switch (action.type) {
    case MATCH_SETTINGS_SET: {
      return {
        ...state,
        matchSettingsByMatchId: {
          ...state.matchSettingsByMatchId,
          [action.payload.MatchId]: action.payload,
        },     
      }
    }
    case MATCHES_SETTINGS_LOADED: {
      return {
        ...state,
        matchSettingsByMatchId: {
          ...state.matchSettingsByMatchId,
          ...arrayToObject(action.payload.MatchesSettings, x => x.MatchId),
        },     
      }
    }    
    default:
      return state
  }
}


export default reducer
