import { useRef, useEffect, ReactNode, ReactPortal } from 'react'
import ReactDOM from 'react-dom'

export const useBodyPortal = <T extends HTMLElement>(t: T) => {
  const ref = useRef<T>(t)

  useEffect(() => {
    const div = ref.current
    
    const body = document.getElementsByTagName('body')[0]
    body.appendChild(div)

    return () => {
      body.removeChild(div)
    }
  }, [])

  return ref.current
}

const popupRoot = document.getElementById('popup-root')
const roots = {
  popup: popupRoot,
}

export type RootNode = keyof typeof roots

export const usePortal = (node: ReactNode, rootNode: RootNode): ReactPortal => {
  const elem = useRef(document.createElement('div'))

  useEffect(() => {
    const current = elem.current

    const root = roots[rootNode]
    root.appendChild(current)

    return () => {
      root.removeChild(current)
    }
  }, [elem, rootNode])

  return ReactDOM.createPortal(
    node,
    elem.current,
  )
}
