export enum DateIntervalEnum {
  Today = 1,
  ThisWeek = 2,
  ThisMonth = 3,
  ThisYear = 4,
}


export const DateInterval = {
  [DateIntervalEnum.Today]: 'Today',
  [DateIntervalEnum.ThisWeek]: 'This week',
  [DateIntervalEnum.ThisMonth]: 'This month',
  [DateIntervalEnum.ThisYear]: 'This year',
}


export const DateIntervalOptions = [
  ...Object.keys(DateInterval).map(key => ({
    id: +key,
    name: DateInterval[+key as DateIntervalEnum],
  })),
]
