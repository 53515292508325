import { createSelector } from 'reselect'
import { OperationTypes } from 'store/logic/operations-logic'
import { TopGames } from 'store/types/game-types'
import { ApplicationState } from '../types/common'
import { makeGetIsAnyOperationWithTypeRunning, makeGetIsOperationWithTypeRunning, makeGetIsOperationWithTypeSuccess, makeGetIsOperationWithTypeSuccessOrRunning } from './operations-selectors'

const getState = (state: ApplicationState) => state.games

const getById = createSelector(getState, state => state?.byId ?? {})

export const getGamesIds = createSelector(getState, state => state?.ids ?? [])

export const getRetiredGamesIds = createSelector(getState, state => state?.retiredIds ?? [])

const getIdsByUserId = createSelector(getState, state => state?.idsByUserId || [])

export const getTopGames = createSelector(getState, state => state?.topGames || <TopGames>{})

export const getGamesLoading = makeGetIsAnyOperationWithTypeRunning(() => ({
  type: OperationTypes.loadGames,
}))

export const getGamesRequested = makeGetIsOperationWithTypeSuccessOrRunning(() => ({
  type: OperationTypes.loadGames,
  objectId: null,
}))

export const getGames = createSelector(
  getById,
  getGamesIds,
  (byId, ids) => ids.map(id => byId[id])
)

export const getRetiredGames = createSelector(
  getById,
  getRetiredGamesIds,
  (byId, ids) => ids.map(id => byId[id])
)

export const makeGetGamesIdsByUserId = () =>
  createSelector(
    getIdsByUserId,
    (_: ApplicationState, { userId }: { userId: number }) => userId,
    (ids, userId) => ids[userId] ?? []
  )

const getLoadUserGamesOperationType = createSelector(
  (_: ApplicationState, { userId }: { userId: number }) => userId,
  userId => ({ type: OperationTypes.loadUserGames, objectId: userId })
)
export const makeGetIsUserGamesLoading = () => makeGetIsOperationWithTypeRunning(getLoadUserGamesOperationType)
export const makeGetIsUserGamesRequested = () => makeGetIsOperationWithTypeSuccessOrRunning(getLoadUserGamesOperationType)
export const makeGetIsUserGamesLoaded = () => makeGetIsOperationWithTypeSuccess(getLoadUserGamesOperationType)

const getAddUserGameOperationType = createSelector(
  (_: ApplicationState, { userId }: { userId: number }) => userId,
  userId => ({ type: OperationTypes.addUserGame, objectId: userId })
)
export const makeGetIsAddUserGameLoading = () => makeGetIsOperationWithTypeRunning(getAddUserGameOperationType)

const getRemoveUserGameOperationType = createSelector(
  (_: ApplicationState, { userId }: { userId: number }) => userId,
  userId => ({ type: OperationTypes.removeUserGame, objectId: userId })
)
export const makeGetIsRemoveUserGameLoading = () => makeGetIsOperationWithTypeRunning(getRemoveUserGameOperationType)

export const makeGetGame = () =>
  createSelector(
    getById,
    (_: ApplicationState, { gameId }: { gameId: number }) => gameId,
    (byId, gameId) => byId[gameId]
  )
  
const getLoadGameDetailOperationType = createSelector(
  (_: ApplicationState, gameId: number) => gameId,
  gameId => ({ type: OperationTypes.loadGameDetail, objectId: gameId })
)
export const makeGetIsGameDetailLoading = () => makeGetIsOperationWithTypeRunning(getLoadGameDetailOperationType)
export const makeGetIsGameDetailRequested = () => makeGetIsOperationWithTypeSuccessOrRunning(getLoadGameDetailOperationType)
  
const getUpdateGameOperationType = createSelector(
  (_: ApplicationState, gameId: number) => gameId,
  gameId => ({ type: OperationTypes.updateGame, objectId: gameId })
)
export const makeGetIsUpdateGameLoading = () => makeGetIsOperationWithTypeRunning(getUpdateGameOperationType)

export const getIsAddGameLoading = makeGetIsAnyOperationWithTypeRunning(() => ({
  type: OperationTypes.addGame,
}))

export const getIsTopGamesLoading = makeGetIsAnyOperationWithTypeRunning(() => ({
  type: OperationTypes.loadTopGames,
}))

export const getIsTopGamesRequested = makeGetIsOperationWithTypeSuccessOrRunning(() => ({
  type: OperationTypes.loadTopGames,
  objectId: null,
}))
