import React from 'react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { loadOrganizers } from 'store/logic/user-logic'

export const DataLoader = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadOrganizers)
  }, [dispatch])

  return (
    <>
    </>
  )
}
