import { Reducer } from 'redux'
import { APP_USER_LOADED } from 'store/types/app-types'
import { CLANS_MEMBERS_LOADED } from 'store/types/clan-member-types'
import { LINE_UPS_LOADED } from 'store/types/line-up-types'
import { MATCH_PARTICIPANTS_LOADED } from 'store/types/match-participant-types'
import { PARTICIPANTS_LOADED } from 'store/types/participants-types'
import { FILTERED_PLAYERS_LOADED, MEMBERS_PAGE_LOADED } from 'store/types/pages/clan-page-types'
import { REVIEWS_LOADED } from 'store/types/review-types'
import { calcById } from 'utils'
import { FRIEND_DELETED, KnownAction, ONLINE_STATUS_CHANGED, ORGANIZERS_LOADED, State, TOP_FRIENDS_LOADED, TOP_ORGANIZER_MEMBERS_LOADED, TOTAL_ONLINE_USERS_COUNT_LOADED, USERS_LOADED, USER_AVATAR_UPDATED, USER_UPDATED } from '../types/user-types'
import { MEMBERS_PAGE_CLANS_LOADED, MEMBERS_PAGE_ORGANIZERS_LOADED, MEMBERS_PAGE_PLAYERS_LOADED } from 'store/types/pages/members-page-types'
import { FRIENDS_PAGE_LOADED } from 'store/types/pages/friends-page-types'
import { FRIEND_REQUESTS_LOADED } from 'store/types/friend-requests-types'
import { NEW_CHAT_MESSAGE_LOADED, UNREAD_CHATS_LOADED } from 'store/chat/chat-message/chat-message-types'
import { CHATS_LOADED, CHAT_LOADED, GROUP_CHAT_CREATED } from 'store/chat/chat/chat-types'
import { CHAT_FILTERED_USERS_LOADED } from 'store/chat/chat-member/chat-member-types'
import { DISPUTES_LOADED } from 'store/types/dispute-types'

export const initialUsersState: State = {
  byId: {},
  organizersIds: [],
  topFriendsIdsByUserId: {},
  topMembersIdsByOrganizerId: {},
  totalOnlineUsersCount:0,
  isChangeTotalOnlineUsersCount: undefined
}

const reducer: Reducer<State, KnownAction> = (state: State | undefined = initialUsersState, action: KnownAction) => {
  switch (action.type) {
    case MATCH_PARTICIPANTS_LOADED:
    case PARTICIPANTS_LOADED:
    case LINE_UPS_LOADED:
    case APP_USER_LOADED:
    case USERS_LOADED:
    case REVIEWS_LOADED:
    case CLANS_MEMBERS_LOADED:
    case FRIEND_REQUESTS_LOADED:
    case UNREAD_CHATS_LOADED:      
    case NEW_CHAT_MESSAGE_LOADED:
    case CHAT_LOADED:
    case CHAT_FILTERED_USERS_LOADED:
    case GROUP_CHAT_CREATED:
    case CHATS_LOADED:
    case USER_AVATAR_UPDATED:
      return {
        ...state,
        byId: calcById(state, action.payload.Users),
      }
    case MEMBERS_PAGE_ORGANIZERS_LOADED: {
      return {
        ...state,
        byId: calcById(state, action.payload.UsersModel.Users),
      }
    }
    case MEMBERS_PAGE_CLANS_LOADED:
    case MEMBERS_PAGE_PLAYERS_LOADED:
    case MEMBERS_PAGE_LOADED:
      return {
        ...state,
        byId: calcById(state, action.payload.Model.Users),
      }
    case ORGANIZERS_LOADED:
      return {
        ...state,
        byId: calcById(state, action.payload.organizers),
        organizersIds: action.payload.organizers.map(x => x.Id),
      }
    case TOP_FRIENDS_LOADED: {
      return {
        ...state,
        byId: calcById(state, action.payload.Users),
        topFriendsIdsByUserId: {
          ...state.topFriendsIdsByUserId,
          [action.payload.userId]: {
            ids: action.payload.Users.map(x => x.Id),
            allFriendsCount:  action.payload.allFriendsCount,
          },
        },
      }
    }
    case TOP_ORGANIZER_MEMBERS_LOADED: {
      return {
        ...state,        
        byId: calcById(state, action.payload.Users),
        topMembersIdsByOrganizerId: {
          ...state.topMembersIdsByOrganizerId,
          [action.payload.OrganizerId]: {
            ids: action.payload.Users.map(x => x.Id),
            allMembersCount:  action.payload.AllMembersCount,
          },
        },
      }
    }
    case FRIENDS_PAGE_LOADED: {
      return {
        ...state,
        byId: calcById(state, action.payload.friends.UsersModel.Users),
      }
    }
    case FRIEND_DELETED: {
      return {
        ...state,
        topFriendsIdsByUserId: {
          ...state.topFriendsIdsByUserId,
          [action.payload.userId]: {
            ids: (state.topFriendsIdsByUserId[action.payload.userId]?.ids ?? []).filter(id => id !== action.payload.friendId),
            allFriendsCount: state.topFriendsIdsByUserId[action.payload.userId]?.allFriendsCount ?? 0 - 1,
          },
          [action.payload.friendId]: {
            ids: (state.topFriendsIdsByUserId[action.payload.friendId]?.ids ?? []).filter(id => id !== action.payload.userId),
            allFriendsCount: state.topFriendsIdsByUserId[action.payload.friendId]?.allFriendsCount ?? 0 - 1,
          },
        },
      }
    }
    case USER_UPDATED: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.userId]: action.payload.user,
        },
      }
    }
    case ONLINE_STATUS_CHANGED: {
      const user = state.byId[action.payload.UserId]
      if (!user) {
        return {
          ...state,
          isChangeTotalOnlineUsersCount:true
        }
      }
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.UserId]: {
            ...user,
            IsOnline: action.payload.IsOnline,
          },
        },
        isChangeTotalOnlineUsersCount:true
      }
    }
    case FILTERED_PLAYERS_LOADED: {
      return {
        ...state,
        byId: calcById(state, action.payload.players.Users),
      }
    }
    case TOTAL_ONLINE_USERS_COUNT_LOADED: {
      return {
        ...state,
        totalOnlineUsersCount: action.payload,
        isChangeTotalOnlineUsersCount:false
      }
    }
    case DISPUTES_LOADED: {
      return {
        ...state,
        byId: calcById(state, action.payload.Organizers),
      }
    }
    default:
      return state
  }
}

export default reducer
