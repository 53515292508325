import { Reducer } from 'redux'
import { MEMBERS_PAGE_PLAYERS_LOADED } from 'store/types/pages/members-page-types'
import { PlayerProfileState, PLAYER_PROFILE_LOADED, KnownAction } from 'store/types/player-profile-type'
import { arrayToObject } from 'utils'

const initialPlayerProfileState: PlayerProfileState = {
  playerProfileIdByUserId: {},
}

const reducer: Reducer<PlayerProfileState, KnownAction> = (state: PlayerProfileState | undefined = initialPlayerProfileState, action: KnownAction) => {
  switch (action.type) {
    case PLAYER_PROFILE_LOADED: {
      return {
        ...state,
        playerProfileIdByUserId: {
          ...state.playerProfileIdByUserId,
          [action.payload.playerProfile.UserId]: action.payload.playerProfile,
        },
      }
    }
    case MEMBERS_PAGE_PLAYERS_LOADED: {
      
      return {
        ...state,
        playerProfileIdByUserId: {
          ...state.playerProfileIdByUserId,
          ...arrayToObject(action.payload.Model.PlayerProfiles, x => x.UserId),
        },
      }
    }
    default:
      return state
  }
}

export default reducer
