import { useMemo } from 'react'
import { MatchParticipantModel } from 'store/types/match-participant-types'
import { useFindTournamentParticipant } from './useFindTournamentParticipant'
import {  useMatch, useMatchParticipants } from './useModels'

export const useFindMatchParticipant = (matchId: number, userId: number): MatchParticipantModel => {
  const { tournament } = useMatch(matchId)
  const { matchParticipants } = useMatchParticipants(matchId)
  const tournamentParticipant = useFindTournamentParticipant(tournament?.TournamentDetailId, userId)

  const matchParticipant = useMemo(() => {
    return matchParticipants.find(x => x.TournamentParticipantId === tournamentParticipant?.Id)
  }, [matchParticipants, tournamentParticipant?.Id])

  return matchParticipant
}
