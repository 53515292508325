export enum NotificationType {
  TournamentInitialized = 0,
  TournamentFailed = 1,
  RoundFailed = 2,
  MatchFailed = 3,
  MatchDisputed = 4,
  TournamentCanceled = 5,
  NewRoundCheckIn = 6,
  TournamentFinished = 7,
  TournamentParticipantVictory = 8,
  ParticipantMatchVictory = 9,
  ParticipantMatchDisqualifyNotSubmittedResult = 10,
  ParticipantMatchDisqualifyNotCheckIn = 11,
  OpponentSubmittedLoseResult = 12,
  ParticipantSubmittedLoseResult = 13,
  CheckInReminder = 14,
  SubmitReminder = 15,
  DisputeResolvedParticipantWon = 16,
  DisputeResolvedParticipantLost = 17,
  ParticipantMatchLost = 18,
  ParticipantMovedToLoserBracket = 19,
  ExtraRoundCheckIn = 21,
  ParticipantAutoResizeDrop = 22,
  MatchLinkProvided = 23,
  MatchLinkPublished = 24,
  MatchLinkDeleted = 25,
  OrganizerCanceledTournament = 26,
  RoundStarted = 27,
  MatchFinished = 28,
  TournamentParticipantJoin = 29,
  TournamentParticipantUnjoin = 30,
  SendFriendRequest = 31,
  ParticipantMatchVictoryOpponentDisquilifiedNotSubmittedResult = 33,
  ParticipantMatchVictoryOpponentDisquilifiedNotCheckIn = 34,
  MatchHostSelected = 35,
  MatchSettingsChanged = 36,
  HostSettingsReminder = 37,
  ParticipantMatchVictoryOpponentDisquilifiedNotProvidedMatchSettings = 38,
  ParticipantMatchDisqualifyNotProvidedSettings = 39,
  ClanInvitationSent = 42,
  ClanDisbanded = 44,
  InvitationAccepted = 47,
  MemberLeftClan = 48,
  WithdrawOrRefundRequest = 49,
  RefundRequestWhenCancelTournament = 50,
  LiveTournamentParticipantTicket = 51,
  RefundParticipantClanMemberTicket = 52,
  OrganizerAddSoloPlayer = 53,
  OrganizerAddClanLeaderAndMembers = 54,
  OrganizerRemoveSoloPlayer = 55,
  OrganizerRemoveClanAndMembers = 56,
  OrganizerRemoveClanMember = 57,
  SendClanLeaderWhenOrganizerRemoveClanMember = 58,
  AddClanMemberByOrganizer = 59,
  SendClanLeaderWhenOrganizerAddClanMember = 60,
  LiveMainTournamentParticipantTicket = 61,
  LiveMainTournamentParticipantTicketForSolo = 62

}
