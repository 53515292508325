import { ContainerName, useMatch, useParticipant, useTournamentParticipant } from 'components/_hooks'
import { useMatchPlayer } from 'components/_hooks'
import { Button, KlLink } from 'components/_shared'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { makeGetTournamentParticipantNumber } from 'store/selectors/match-participant-selectors'
import { ApplicationState } from 'store/types/common'
import { MatchStatusEnum } from 'store/types/match-types'
import { cn } from 'utils'
import { PlaceSelector } from './PlaceSelector'
import ParticipantOnlineAvatar from 'components/_shared/ParticipantAvatar/ParticipantOnlineAvtar'
import { makeGetSubmissionsByMatchId } from 'store/selectors/match-submissions-selectors'

export interface BracketMatchParticipantProps {
  matchId: number
  tournamentParticipantId?: number
  matchParticipantId?: number
  onParticipantSelected: (participantId?: number) => void
  selectedParticipantId?: number
  filter: string
  openSubmissionResultModal: (v?: any) => void
  index? : number
  showMatchResult:boolean
}

export const BracketMatchParticipant = ({
  tournamentParticipantId,
  matchParticipantId,
  matchId,
  onParticipantSelected,
  selectedParticipantId,
  filter,
  openSubmissionResultModal,
  index,
  showMatchResult
}: BracketMatchParticipantProps) => {
  const getTournamentParticipantNumber = useMemo(makeGetTournamentParticipantNumber, [])

  const { name, profileUrl, matchParticipant } = useMatchPlayer(matchParticipantId)
  
  const { IsBot } = useTournamentParticipant(tournamentParticipantId);
  const { match, tournament } = useMatch(matchId)
  const now = new Date()
  const isValidDate = match?.PlayDate ? new Date(match?.PlayDate) < now : false
  const getSubmissionsByMatchId = useMemo(makeGetSubmissionsByMatchId, [])
  const matchSubmissions = useSelector((state: ApplicationState) => getSubmissionsByMatchId(state, match?.Id))
  const submission = useMemo(() => {
    return matchSubmissions.find(x => x.MatchParticipantId === matchParticipant?.Id)
  }, [matchSubmissions])
  const isSubmitResultAvailable = submission !== undefined ? true : false 

  const matchParticipantNumber = useSelector((state: ApplicationState) =>
    getTournamentParticipantNumber(state, {
      tournamentParticipantId,
      tournamentDetailId: tournament.TournamentDetailId,
    })
  )

  const isFiltred = useMemo(() => {
    if (!filter) {
      return false
    }
    return name && name.toLowerCase().includes(filter.toLowerCase())
  }, [filter, name])
  const isWinner = matchParticipant?.Place === 1
  const isActive = isWinner || match.Status != MatchStatusEnum.Finished

  const handleMouseEnter = () => {
    onParticipantSelected(tournamentParticipantId)
  }

  const handleMouseLeave = () => {
    onParticipantSelected()
  }

  return (
    <>
      <div
        className={cn(
          'eli_usr eli_general-width eli_general-padding',
          isActive && 'eli_usr--winner',
          tournamentParticipantId && selectedParticipantId === tournamentParticipantId && 'eli_usr--hover',
          isFiltred && 'eli_usr--search-highlight'
        )}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className={"eli_usr_inn " + (isValidDate ? ' result-box ': '') + (isValidDate && index === 0 ? ' first-result-box' : '')}>
          <div className="eli_usr_l">
            <span className="eli_usr_num">{matchParticipantNumber || '?'}</span>
          </div>
          <div className="d-flex justify-content-between w-100">
            <div className="eli_usr_m">
              <KlLink className="prsn" to={profileUrl ?? '#'} disabled={IsBot === true || !profileUrl}>
                <div className="prsn_l">
                  <ParticipantOnlineAvatar
                    parentClassName={null}
                    withLink={!IsBot}
                    participantId={tournamentParticipantId}
                    className="prsn_icon"
                    containerName={ContainerName.AvatarsNormal}
                  />
                </div>
                <div className="prsn_r">
                {!IsBot && <span className="prsn_ttl">{name ?? 'Pending'}</span>}
                {IsBot && <span className="prsn_ttl">{'By-Pass'}</span>}
                </div>
                
              </KlLink>
            </div>
            <PlaceSelector matchId={matchId} matchParticipantId={matchParticipantId} />
            {isValidDate && showMatchResult &&(
              <div className="result-container">
              <a className={"result-link" + (isSubmitResultAvailable===true ? ' result_detail_available' : '')} onClick={()=>{openSubmissionResultModal(matchParticipant)}}>Result</a>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
