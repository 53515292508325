import { ContainerName, useGetImageSize } from 'components/_hooks'
import { Img, Video } from 'components/_shared/Img'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { makeGetImage } from 'store/selectors/image-selectors'
import { ApplicationState } from 'store/types/common'
import { MediaType } from 'store/types/image-types'
import { cn } from 'utils'
import styles from '../Tournament.module.scss'
interface TournamentCoverProps {
  coverId: number
  tournamentName?: string
}

const TournamentCardCover = ({ coverId, tournamentName }: TournamentCoverProps) => {
  const getCover = useMemo(makeGetImage, [])
  const cover = useSelector((state: ApplicationState) => getCover(state, { imageId: coverId }))
 

  const handleVideoMouseOver = (e: React.MouseEvent<HTMLVideoElement, MouseEvent>) => {
    void e.currentTarget.play()
  }

  const handleVideoMouseLeave = (e: React.MouseEvent<HTMLVideoElement, MouseEvent>) => {
    void e.currentTarget.pause()
  }
  const imageSize = useGetImageSize(ContainerName.TournamentCardCover)

  return (
    <div className={cn('row', styles.game__cover)}>
      <div className="col-12 p-0">
        {cover?.MediaType === MediaType.Video && (
          <Video
            imageId={coverId}
            className={styles.game__video}
            muted
            loop
            style={{ zIndex: 1 }}
            onMouseOver={handleVideoMouseOver}
            onMouseLeave={handleVideoMouseLeave}
            placeholder="tournament"
          />
        )}
        {cover?.MediaType === MediaType.Image && (
          <Img parentContainerSize={imageSize}
            alt={tournamentName}
            imageId={coverId}
            className={styles.game__picture}
            placeholder="tournament"
          />
        )}
        {!cover && (
          <Img
            src="https://kombatlink-assets.azureedge.net/img/kombatLinkTournamentLogo.jpg"
            className={styles.game__picture}
          />
        )}
      </div>
    </div>
  )
}

export default TournamentCardCover
