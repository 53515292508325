import React, { CSSProperties, ReactNode, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { makeGetTournament } from 'store/selectors/tournaments-selectors'
import { ApplicationState } from 'store/types/common'
import { getMaxParticipantsNumberPerMatch } from 'store/types/tournaments-types'
import { cn } from 'utils'
import { BracketRound } from './BracketRound'
import { EmptyComponent } from './EmptyComponent'

const LosersRoundContainer = ({ children, style }: { children: ReactNode; style?: CSSProperties }) => {
  return (
    <div className="eli_row eli_grid--extra_row" style={style}>
      {children}
    </div>
  )
}

const calcGroupHeight = (participantsPerMatch: number) => {
  if (participantsPerMatch < 2) {
    throw Error('Invalid number of participants per match')
  }

  const participantElemesHeights = Array(participantsPerMatch).fill(71)
  participantElemesHeights[0] = 70
  participantElemesHeights[participantElemesHeights.length - 1] = 90

  return 20 + participantElemesHeights.reduce((a, v) => a + v, 0)
}

export interface BracketGridProps {
  roundsIds: number[]
  tournamentDetailId: number
  isLosersBracket?: boolean
  filter: string
  openSubmissionResultModal: (v?: any) => void
}

const WinnerRoundsContainer = ({ children }: { children: ReactNode }) => {
  return <div className="eli_row eli_grid--elimination_row">{children}</div>
}

export const BracketGrid = ({ roundsIds, tournamentDetailId, filter, isLosersBracket = false,openSubmissionResultModal }: BracketGridProps) => {
  const [selectedParticipantId, setSelectedParticipantId] = useState<number>()
  const getTournament = useMemo(makeGetTournament, [])
  const tournament = useSelector((state: ApplicationState) => getTournament(state, tournamentDetailId))

  const RoundsContainer = useMemo(() => {
    return isLosersBracket ? EmptyComponent : WinnerRoundsContainer
  }, [isLosersBracket])

  const roundsGroups = useMemo(() => {
    if (isLosersBracket) {
      const groups = []
      let i = 0
      roundsIds.forEach((id, index) => {
        if (!groups[i]) {
          groups[i] = []
        }
        groups[i].push(id)
        if (index % 2 === 0) {
          i++
        }
      })
      return groups
    }

    return [roundsIds]
  }, [isLosersBracket, roundsIds])

  const RoundContainer = useMemo(() => {
    return isLosersBracket ? LosersRoundContainer : EmptyComponent
  }, [isLosersBracket])

  const halfOfUsersGroupHeight = useMemo(() => {
    if (!tournament) {
      return 0
    }

    const participantsNumberPerMatch = getMaxParticipantsNumberPerMatch(tournament)
    return calcGroupHeight(participantsNumberPerMatch) / 2
  }, [tournament])

  return (
    <div className={cn('eli_grid', isLosersBracket ? 'eli_grid--extra' : 'eli_grid--elimination')}>
      <RoundsContainer>
        {roundsGroups.map((group, index) => (
          <RoundContainer key={index} style={isLosersBracket ? { marginTop: index * halfOfUsersGroupHeight } : {}}>
            {group.map(id => (
              <BracketRound
                key={id}
                roundId={id}
                onParticipantSelected={setSelectedParticipantId}
                selectedParticipantId={selectedParticipantId}
                halfOfUsersGroupHeight={halfOfUsersGroupHeight}
                firstRoundId={roundsIds[0]}
                filter={filter}
                openSubmissionResultModal= {openSubmissionResultModal}
              />
            ))}
          </RoundContainer>
        ))}
      </RoundsContainer>
    </div>
  )
}
