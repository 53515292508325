import { createSelector } from 'reselect'
import { OperationTypes } from 'store/logic/operations-logic'
import { ApplicationState } from '../types/common'
import { makeGetIsOperationWithTypeRunning, makeGetIsOperationWithTypeSuccessOrRunning } from './operations-selectors'

const getParticipantsState = (state: ApplicationState) => state.participants

const getParticipantsById = createSelector(getParticipantsState, state => state?.byId || {})

const getParticipantsIdsByTournamentDetailId = createSelector(getParticipantsState, state => state?.idsByTournamentDetailId || {})

export const makeGetAllParticipants=()=> createSelector(getParticipantsState, state => state?.byId || [])

export const makeGetParticipantById = () =>
  createSelector(
    getParticipantsById,
    (_, participantId: number) => participantId,
    (byId, participantId) => byId[participantId]
  )

export const getParticipantsByTournamentDetailId = createSelector(
  getParticipantsById,
  getParticipantsIdsByTournamentDetailId,
  (_: ApplicationState, tournamentDetailId: number) => tournamentDetailId,
  (byId, byTournament, tournamentDetailId) => {
    const ids = byTournament[tournamentDetailId] || []
    return ids.map(id => byId[id])
  }
)

export const makeGetParticipantsByTournamentDetailId = () => getParticipantsByTournamentDetailId

const getLoadParticipantsOperationType = createSelector(
  (_, tournamentDetailId: number) => tournamentDetailId,
  tournamentDetailId => ({ type: OperationTypes.loadParticipants, objectId: tournamentDetailId })
)
export const makeGetIsParticipantsLoading = () => makeGetIsOperationWithTypeRunning(getLoadParticipantsOperationType)
export const makeGetIsParticipantsRequested = () => makeGetIsOperationWithTypeSuccessOrRunning(getLoadParticipantsOperationType)


const getLoadParticipantOperationType = createSelector(
  (_, id: number) => id,
  id => ({ type: OperationTypes.loadParticipant, objectId: id })
)

export const makeGetIsParticipantLoading = () => makeGetIsOperationWithTypeRunning(getLoadParticipantOperationType)
export const makeGetIsParticipantRequested = () => makeGetIsOperationWithTypeSuccessOrRunning(getLoadParticipantOperationType)

export const makeGetParticipantsIds = () =>
  createSelector(
    getParticipantsIdsByTournamentDetailId,
    (_: ApplicationState, { tournamentDetailId }: { tournamentDetailId: number }) => tournamentDetailId,
    (ids, tournamentDetailId) => ids[tournamentDetailId] ?? []
  )

export const makeGetParticipants = () => {
  const getParticipantsIds = makeGetParticipantsIds()

  return createSelector(
    getParticipantsById,
    getParticipantsIds,
    (byId, ids) => ids.map(id => byId[id])
  )
}
