import React, { useState } from 'react'
import styles from '../SignUp.module.scss'

interface PasswordInputProps {
  placeholder?: string
  value?: string
  onChange?: (value: string) => void
  className?: string
  inputClassName?: string
  iconClassName?: string
  title: string
}

export const PasswordInput = ({ placeholder, onChange, value, className, inputClassName, iconClassName, title }: PasswordInputProps) => {
  const [visible, setVisible] = useState(false)

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    (event.currentTarget.previousElementSibling as HTMLInputElement).focus()
    event.preventDefault()
    setVisible(!visible)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    onChange(value)
  }

  return (
    <>
      <div className={styles.signUpInputTitle}>{title}</div>
      <div className={className}>
        <input autoComplete="new-password" className={inputClassName} type={visible ? 'text' : 'password'} placeholder={placeholder} value={value} onChange={handleChange} />
        <a className={iconClassName} href="#" onClick={handleClick}></a>
      </div>
    </>
  )
}
