import { Reducer } from 'redux'
import { arrayToObject, distinct, mapArrayToObject } from 'utils'
import { State, KnownAction, DISPUTE_DETAILS_LOADED, DISPUTE_DETAIL_UPDATED, DISPUTE_DETAIL_ADDED } from '../types/dispute-details-types'

const initialState: State = {
  byId: {},
  idsByDisputeId: {},
  idByMatchParticipantId: {},
}

const concatIds = (newIds: number[], stateIds?: number[]) => {
  return distinct((stateIds || []).concat(newIds))
}

const reducer: Reducer<State, KnownAction> = (state: State | undefined = initialState, action: KnownAction) => {
  switch (action.type) {
    case DISPUTE_DETAILS_LOADED:
      return {
        ...state,
        byId: {
          ...state.byId,
          ...arrayToObject(action.payload.disputeDetails, x => x.Id),
        },
        idsByDisputeId: {
          ...state.idsByDisputeId,
          [action.payload.disputeId]: action.payload.disputeDetails.map(x => x.Id),
        },
        idByMatchParticipantId: {
          ...state.idByMatchParticipantId,
          ...mapArrayToObject(
            action.payload.disputeDetails,
            x => x.MatchParticipantId,
            x => x.Id
          ),
        },
      }
    case DISPUTE_DETAIL_ADDED:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.disputeDetail.Id]: action.payload.disputeDetail,
        },
        idsByDisputeId: {
          ...state.idsByDisputeId,
          [action.payload.disputeDetail.DisputeId]: concatIds(
            [action.payload.disputeDetail.Id],
            state.idsByDisputeId[action.payload.disputeDetail.DisputeId]
          ),
        },
        idByMatchParticipantId: {
          ...state.idByMatchParticipantId,
          [action.payload.disputeDetail.MatchParticipantId]: action.payload.disputeDetail.Id,
        },
      }
    case DISPUTE_DETAIL_UPDATED:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.disputeDetail.Id]: action.payload.disputeDetail,
        },
      }
    default:
      return state
  }
}

export default reducer
