import { makeActionCreator, ReduxAction } from 'store/types/common'
import { ImageType } from 'store/types/image-types'
import { PublicUserType } from 'store/types/user-types'
import { ChatLeftAction, ChatMemberModel } from '../chat-member/chat-member-types'
import { ChatDeletedAction, ChatModel, ChatsLoadedAction } from '../chat/chat-types'

export enum ChatMessageTypeEnum {
  Standard = 0,
  Warning = 1,
  Info = 2,
  Success = 3
}
export interface ChatMessageModel {
  Id: number
  ChatId: number
  ChatMemberId?: number
  SentDate: string
  Text: string
  ChatMessageType: ChatMessageTypeEnum
}

export interface ChatReceivedMessageModel {
  ChatMessage: ChatMessageModel
  UserId?: number
}

export interface ChatMessagesModel {
  ChatMessages: ChatMessageModel[]
  Chats: ChatModel[]
  ChatMembers: ChatMemberModel[]
  Users: PublicUserType[]
  Images: ImageType[]
}

export interface ChatUnreadModel {
  Chat: ChatModel
  LastUnreadMessageId: number
  UnreadMessagesCount: number
}

export interface ChatUnreadType {
  chatId: number
  lastUnreadMessageId: number
  unreadMessagesCount: number
}

export interface ChatsUnreadsModel {
  Chats: ChatUnreadModel[]
  ChatMessages: ChatMessageModel[]
  ChatMembers: ChatMemberModel[]
  Users: PublicUserType[]
  Images: ImageType[]
} 

export interface ChatMessagesState {
  byId: {
    [id: number]: ChatMessageModel
  }
  idsbyChatId: {
    [chatId: number]: number[]
  }
  unreadChats: ChatUnreadType[]
}

interface ChatMessagesLoadedPayload {
  chatId: number
  messages: ChatMessageModel[]
}

export const CHAT_MESSAGES_LOADED = 'CHAT_MESSAGES_LOADED'
export type ChatMessagesLoadedAction = ReduxAction<typeof CHAT_MESSAGES_LOADED, ChatMessagesLoadedPayload>
export const chatMessagesLoaded = makeActionCreator<ChatMessagesLoadedPayload>(CHAT_MESSAGES_LOADED)

export const CHAT_MESSAGE_RECEIVED = 'CHAT_MESSAGE_RECEIVED'
export type ChatMessageReceivedAction = ReduxAction<typeof CHAT_MESSAGE_RECEIVED, ChatMessageModel>
export const chatMessageReceived = makeActionCreator<ChatMessageModel>(CHAT_MESSAGE_RECEIVED)


export const UNREAD_CHATS_LOADED = 'UNREAD_CHATS_LOADED'
export type UnreadChatsLoadedAction = ReduxAction<typeof UNREAD_CHATS_LOADED, ChatsUnreadsModel>
export const unreadChatsLoaded = makeActionCreator<ChatsUnreadsModel>(UNREAD_CHATS_LOADED)

export const NEW_CHAT_MESSAGE_LOADED = 'NEW_CHAT_MESSAGE_LOADED'
export type NewChatMessageLoadedAction = ReduxAction<typeof NEW_CHAT_MESSAGE_LOADED, ChatMessagesModel>
export const newChatMessageLoaded = makeActionCreator<ChatMessagesModel>(NEW_CHAT_MESSAGE_LOADED)

export interface ChatMessageReadPayload {
  ChatId: number
  ChatMessageId: number
}

export const CHAT_MESSAGE_READ = 'CHAT_MESSAGE_READ'
export type ChatMessageReadAction = ReduxAction<typeof CHAT_MESSAGE_READ, ChatMessageReadPayload>
export const chatMessageRead = makeActionCreator<ChatMessageReadPayload>(CHAT_MESSAGE_READ)

export type ChatMessageKnownAction = ChatMessagesLoadedAction 
  | ChatMessageReceivedAction
  | UnreadChatsLoadedAction
  | NewChatMessageLoadedAction
  | ChatsLoadedAction
  | ChatDeletedAction
  | ChatLeftAction
  | ChatMessageReadAction
  