import TournamentDetails, { TournamentDetailTab } from 'components/TournamentDetail/TournamentDetails'
import { kl_TournamentViewModel } from 'models/kl_tournament_viewmodel'
import React, { useState } from 'react'
import { MediaType } from 'store/types/image-types'


interface OwnProps {
  tournament: kl_TournamentViewModel
  isAdmin: boolean
  coverDataUrl: string
  coverMediaType: MediaType
}


const TournamentDetailStep = ({tournament, isAdmin, coverDataUrl, coverMediaType}: OwnProps) => {

  const [tab, setTab] = useState<TournamentDetailTab>('overview')

  return (
    <TournamentDetails
      tournamentCreateModel={tournament}
      isAdmin={isAdmin}
      coverDataUrl={coverDataUrl}
      coverMediaType={coverMediaType}
      isShowFromWizardPage
      currentTab={tab}
      onChangeTab={setTab}
    />
  )
}
export default TournamentDetailStep
