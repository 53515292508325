import { Dispatch } from 'redux'
import { GetState, PaginationModel } from 'store/types/common'
import { filteredPlayersLoaded, ListClanMembersDto, membersPageLoaded } from 'store/types/pages/clan-page-types'
import { PublicUsersModel } from 'store/types/user-types'
import { fetchApi } from 'utils'
import { OperationTypes, run } from '../operations-logic'

export interface ListPlayersResult {
  AllPlayersCount: number
  Players: PublicUsersModel
}

export const searchPlayers = (username: string, pagination: PaginationModel) => async (dispatch: Dispatch, getState: GetState) => {
  await run(OperationTypes.searchPlayers, null, dispatch, getState, async () => {
    try {
      const result = await fetchApi<ListPlayersResult>('user/players/search', 'POST', {
        username,
        pagination,
      })
      dispatch(filteredPlayersLoaded({
        players: result.Players,
        allPlayersCount: result.AllPlayersCount,
      }))
    } catch (error) {
      return false
    }
    return true
  })
}

export const loadClanMembers = (clanId: number, pagination: PaginationModel) => async (dispatch: Dispatch, getState: GetState) => {
  await run(OperationTypes.loadClanMembers, clanId, dispatch, getState, async () => {
    try {
      const model = await fetchApi<ListClanMembersDto>(`clanmember/${clanId}/list`, 'POST', pagination)
      dispatch(membersPageLoaded(model))
    } catch (error) {
      return false
    }
    return true
  })
}
