import { ContainerName, useAppSelector, useGetImageSize } from 'components/_hooks'
import { Img, KlLink } from 'components/_shared'
import React, { Fragment, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { loadGames, loadTopGames } from 'store/logic/game-logic'
import { getTopGames, makeGetGame } from 'store/selectors/game-selectors'
import { ApplicationState } from 'store/types/common'
import styles from './Home.module.scss'

function PopularGames() {

  const dispatch = useDispatch()

  const topGames = useAppSelector(getTopGames)

  const popularGamesIds = topGames.PopularGamesIds
  const upcomingGamesIds = topGames.UpcomingGamesIds

  useEffect(() => {
    dispatch(loadGames)
    dispatch(loadTopGames)
  }, [dispatch])

  return (

    <Fragment >
      <div className={"d-flex align-items-center justify-content-between " + styles.popularGameTitle}>
        <h3 className={styles.gap_small_bottom + " col ml-2 mr-2 " + styles.tt_u + " " + styles.gap_top + " " + styles.gameTitle} data-aos="zoom-in">POPULAR GAMES</h3>
        <div className={"col-2" + styles.seemore}>
          <Link to="/games" className="text-right">See More..</Link>
        </div>
      </div>

      <div className={"row-xl-4 row-l-4 row-m-2 row-s-2 m-2 " + styles.flexs + " " + styles.equal_height + " " + styles.bdr_top + " " + styles.game_list}>
        {popularGamesIds?.map(gameId =>
          <GameCard id={gameId} key={'popularGame_' + gameId} />
        )}
      </div>

      <div className={"d-flex align-items-center justify-content-between " + styles.popularGameTitle}>
        <h3 className={styles.gap_small_bottom + " col ml-2 mr-2 " + styles.tt_u + " " + styles.gap_top + " " + styles.gameTitle} data-aos="zoom-in">UPCOMING GAMES</h3>
        <div className={"col-2" + styles.seemore}>
          <Link to="/tournaments" className="text-right">See More..</Link>
        </div>
      </div>

      <div className={"row-xl-4 row-l-4 row-m-2 row-s-2 m-2 " + styles.flexs + " " + styles.equal_height + " " + styles.bdr_top + " " + styles.game_list}>
        {upcomingGamesIds?.map(gameId =>
          <GameCard id={gameId} key={'upcomingGame_' + gameId} />
        )}
      </div>
    </Fragment>

  )
}

export default PopularGames


const GameCard = ({ id }: { id: number }) => {
  const cdn = 'https://kombatlink-assets.azureedge.net/img'
  const getGame = useMemo(makeGetGame, [])

  const game = useSelector((state: ApplicationState) => getGame(state, { gameId: id }))
  const gameContainerSize = useGetImageSize(ContainerName.GamesImagesCard)

  return (
    <div className={"col-xl-2 col-l-2 col-m-6 col-s-6 " + styles.gal_box} data-aos="zoom-in">
      <Img parentContainerSize={gameContainerSize} alt={game?.Gamename} imageId={game?.ImageId} src={!game?.ImageId ? `/images/games/${game?.Id}.jpg` : undefined} />
      <div className={styles.gal_txt}>
        <h4>
          <KlLink to={`/game-detail/${game?.Id}`}>
            <img src={`${cdn}/search.png`} alt="search" />
          </KlLink>
        </h4>
      </div>
    </div>
  )
}