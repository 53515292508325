import { ConsoleEnum } from 'consts/ConsoleType'
import { Dispatch } from 'redux'
import { makeGetTopOrganizerMembersRequested } from 'store/selectors/user-selectors'
import { GetState, PaginationModel } from 'store/types/common'
import { ListPlayersResultModel, membersPageClansLoaded, membersPageOrganizersLoaded, membersPagePlayersLoaded } from 'store/types/pages/members-page-types'
import { ListUserResultModel, topOrganizerMembersLoaded } from 'store/types/user-types'
import { fetchApi } from 'utils'
import { OperationTypes, run } from '../operations-logic'

export interface PlayersFiltersDto {
  name: string
  gamesIds: number[]
  consoles: ConsoleEnum[]
  regionsIds: number[]
  pagination: PaginationModel
}

export const loadMembersPlayers = (dto: PlayersFiltersDto) => async (dispatch: Dispatch, getState: GetState) => {
  await run(OperationTypes.loadPlayers, null, dispatch, getState, async () => {
    try {
      const result = await fetchApi<ListPlayersResultModel>('user/players/list', 'POST', dto)
      dispatch(membersPagePlayersLoaded(result))
    } catch (error) {
      return false
    }
    return true
  })
}

const getTopOrganizerMembersRequested = makeGetTopOrganizerMembersRequested()

export const loadTopMembersPlayers = (organizerId: number) => async (dispatch: Dispatch, getState: GetState) => {
  if (getTopOrganizerMembersRequested(getState(), organizerId)) {
    return
  }
  const filters: PlayersFiltersDto = {
    consoles: [],
    gamesIds: [],
    name: '',
    pagination: {
      page: 0,
      pageSize: 12,
    },
    regionsIds: [],
  }
  await run(OperationTypes.loadTopOrganizerMembers, organizerId, dispatch, getState, async () => {
    const result = await fetchApi<ListPlayersResultModel>('user/players/list', 'POST', filters)
    dispatch(topOrganizerMembersLoaded({ 
      OrganizerId: organizerId,
      Users: result.Model.Users,
      AllMembersCount: result.AllPlayersCount,
    }))
    return true
  })
}


export const loadMembersClans = (dto: PlayersFiltersDto) => async (dispatch: Dispatch, getState: GetState) => {
  await run(OperationTypes.loadClans, null, dispatch, getState, async () => {
    try {
      const result = await fetchApi<ListPlayersResultModel>('clan/list', 'POST', dto)
      dispatch(membersPageClansLoaded(result))
    } catch (error) {
      return false
    }
    return true
  })
}


export const loadMembersOrganizers = (dto: PlayersFiltersDto) => async (dispatch: Dispatch, getState: GetState) => {
  await run(OperationTypes.loadClans, null, dispatch, getState, async () => {
    try {
      const result = await fetchApi<ListUserResultModel>('user/organizers/list', 'POST', dto)
      dispatch(membersPageOrganizersLoaded(result))
    } catch (error) {
      return false
    }
    return true
  })
}
