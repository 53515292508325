import { Reducer } from 'redux'
import { calcById, getNewObjectWithArray, getObjectsWithArrayWithoutIds, groupArrayToObject } from 'utils'
import { CHAT_LEFT } from '../chat-member/chat-member-types'
import { CHATS_LOADED, CHAT_DELETED } from '../chat/chat-types'
import { ChatMessageKnownAction, ChatMessagesState, CHAT_MESSAGES_LOADED, CHAT_MESSAGE_READ, CHAT_MESSAGE_RECEIVED, NEW_CHAT_MESSAGE_LOADED, UNREAD_CHATS_LOADED } from './chat-message-types'


const initialChatMemberState: ChatMessagesState = {
  byId: {},
  idsbyChatId: {},
  unreadChats: [],
}

const reducer: Reducer<ChatMessagesState, ChatMessageKnownAction> = (state: ChatMessagesState | undefined = initialChatMemberState, action: ChatMessageKnownAction) => {
  switch (action.type) {
    case CHATS_LOADED: {   
      return {
        ...state,
        byId: calcById(state, action.payload.ChatMessages),
        idsbyChatId: {
          ...getNewObjectWithArray(state.idsbyChatId, action.payload.ChatMessages, x => x.ChatId, x => x.Id)          
        },     
      }
    }
    case CHAT_MESSAGES_LOADED: {
      return {
        ...state,
        byId: calcById(state, action.payload.messages),
        idsbyChatId: {
          ...state.idsbyChatId,
          [action.payload.chatId]:state.idsbyChatId[action.payload.chatId] === undefined ?  action.payload.messages.map(m => m.Id) : Array.from(new Set(state.idsbyChatId[action.payload.chatId].concat(action.payload.messages.map(m => m.Id)))),
        },
        unreadChats: [...state.unreadChats.filter(ch => ch.chatId != action.payload.chatId)],        
      }
    }
    case CHAT_MESSAGE_RECEIVED: {
      const messages = state.idsbyChatId[action.payload.ChatId] ?? []
      return {
        ...state,
        byId: calcById(state, [action.payload]),
        idsbyChatId: {
          ...state.idsbyChatId,
          [action.payload.ChatId]: [action.payload.Id, ...messages],
        },     
      }
    }
    case NEW_CHAT_MESSAGE_LOADED: {
      return {
        ...state,
        unreadChats: [...state.unreadChats.filter(uc => uc.chatId != action.payload.Chats[0].Id), {
          chatId: action.payload.Chats[0].Id,
          lastUnreadMessageId: action.payload.ChatMessages[0].Id,
          unreadMessagesCount: (state.unreadChats.find(uc => uc.chatId == action.payload.Chats[0].Id)?.unreadMessagesCount || 0) + 1,
        }],
      }
    }
    case UNREAD_CHATS_LOADED: {
      return {
        ...state,
        byId: calcById(state, action.payload.ChatMessages),
        idsbyChatId: {
          ...state.idsbyChatId,
          ...groupArrayToObject(action.payload.ChatMessages, key => key.ChatId, item => item.Id),
        },
        unreadChats: action.payload.Chats.map(ch => ({ 
          chatId: ch.Chat.Id, lastUnreadMessageId: ch.LastUnreadMessageId, unreadMessagesCount: ch.UnreadMessagesCount,
        })),
      }
    }
    case CHAT_LEFT:
    case CHAT_DELETED: {
      return {
        ...state,
        idsbyChatId: getObjectsWithArrayWithoutIds(state.idsbyChatId, [action.payload]),
        unreadChats: [...state.unreadChats.filter(ch => ch.chatId != action.payload)],        
      }
    }
    case CHAT_MESSAGE_READ: {
      return {
        ...state,
        unreadChats: [...state.unreadChats.filter(ch => ch.chatId != action.payload.ChatId)],   
      }
    }
    default:
      return state
  }
}

export default reducer
