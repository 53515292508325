import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { makeGetClanMember } from 'store/selectors/clan-member-selectors'
import { ApplicationState } from 'store/types/common'

export const useClanMember = (userId: number) => {
  const getClanMember = useMemo(makeGetClanMember, [])
  const clanMember = useSelector((state: ApplicationState) => getClanMember(state, { userId }))
  
  return clanMember
}
