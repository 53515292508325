import React from 'react'
import SelectComponent from 'components/_shared/SelectComponent'

import { kl_TournamentTicketsViewModel, kl_TournamentViewModel } from 'models/kl_tournament_viewmodel'

import { DateTimePicker } from 'components/_shared/TournamentWizard/_shared/DateTimePicker'
import { ConvertDateToFormattedDatetimeString } from './TimeZoneSelector/ZonedDate'
import {TicketSeatingArrangementModal} from '../_shared/TicketSeatingArrangement/TicketSeatingArrangementModal'
import { useDialogWithParameter } from 'components/_hooks'
import { TournamentSeatModel, TournamentTicketSectionDetail } from 'models/tournament-models'
import { TicketSaleViewModel } from 'models/ticket-sale-viewmodel'
import { APP_NAMESPACE } from './TicketSeatingArrangement/SectionArrangement/utils/constant/constant'
import { InfoText } from 'components/_library/InfoText/InfoText'
import { UserModel } from 'models/user-models'
interface Props {
  tournament: kl_TournamentViewModel
  errors
  onChangeTicketData: (paramName: string, val: any, id: number) => Promise<void>
  handleRoundDataChange
  mainTournamentDate : Date
  handleTournamentRoundPlayDateTime: (IsAnyTime:boolean) => void
  isAnyTime?:boolean
  handleSeatingArrangements:(paramName: any) => Promise<void>
  tournamentSectionDetail:TournamentTicketSectionDetail[]
  organizerCurrency?:string
}

const getDayOrderNumber = function (number: number): string {
  switch (number) {
    case 1:
      return '1st'
    case 2:
      return '2nd'
    case 3:
      return '3rd'
    default:
      return `${number}th`
  }
}

export const RoundsSettings = ({ tournament, errors, onChangeTicketData, handleRoundDataChange,mainTournamentDate,handleTournamentRoundPlayDateTime,isAnyTime,handleSeatingArrangements,tournamentSectionDetail,organizerCurrency}: Props) => {
  const user: UserModel = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : Object()
  const handleTicketPriceChange = React.useCallback(
    (val: any, id: number) => {
      onChangeTicketData('TicketPrice', val, id)
    },
    [onChangeTicketData]
  )
  const close=(data:TournamentSeatModel)=>{
    handleSeatingArrangements(data)
  }
  
  
  const packageTicket = React.useMemo(() => {
    return tournament.TournamentDetails[0].TournamentTickets.find(ticket => !ticket.TicketDate)
  }, [tournament.TournamentDetails[0].TournamentTickets])

  const [ticketSeatingArrangementModal, openTicketSeatingArrangementModal] = useDialogWithParameter<TournamentSeatModel>((TournamentSeatModel,closeDialog) => (
    <TicketSeatingArrangementModal
      tournamentSeatModel={TournamentSeatModel}
      closeDialog={closeDialog}
      close={close}
      packageTicket={packageTicket}
      isOrganizerVerified={user.isorganizer && !user.IsVerified && user.Stripe_IdentityUrl === null?false:true}
      ticketsCount={tournament.TournamentDetails[0] ?tournament.TournamentDetails[0].TournamentTickets.length:0}
      handleTicketPriceChange={handleTicketPriceChange}
      organizerCurrency={organizerCurrency}
    />
  ))

  const tickets = React.useMemo(() => {
    return tournament.TournamentDetails[0].TournamentTickets.filter(ticket => ticket.TicketDate)
  }, [tournament.TournamentDetails[0].TournamentTickets])

  const getTicketPriceHeader = React.useCallback(
    (ticket: kl_TournamentTicketsViewModel, number: number): string => {
      return `Price of ${getDayOrderNumber(number)} day (${new Date(ticket.TicketDate).toLocaleDateString()})`
    },
    [tournament.TournamentDetails[0].TournamentTickets]
  )

  return (
    <div className="article__rounds-container">
      {tournament.IsManualControl && 
      <>
      {tournament.IsTicketsAdded ?<div className="article__big-subheader ct__ct__article__big-subheader article__big-subheader--no-lines disabled">
            <input type="checkbox" disabled  checked={isAnyTime} onChange={(e)=>{
              //handleTournamentRoundPlayDateTime(e.target.checked)
            }}/>
            <label className="ml-2 disabled">Any Time</label>
          
          </div>:
          <div className="article__big-subheader ct__ct__article__big-subheader article__big-subheader--no-lines">
            <input type="checkbox"  checked={isAnyTime} onChange={(e)=>{
              handleTournamentRoundPlayDateTime(e.target.checked)
            }}/>
            <label className="ml-2">Any Time</label>
          
          </div>}
          
          <div className="row m-0 w-100 flex-nowrap">
              <div className="article__warning-icon ct__article__warning-icon" />
              <div className="article__warning-text warning-text ct__warning-text">
                Select Anytime to disable both time events from showing on the bracket page
              </div>
          </div>
                
          </>   
      }
      {tournament.TournamentDetails[0].TournamentRounds.length > 0 &&
        tournament.TournamentDetails[0].TournamentRounds.map((item, i) => {
          return (
           
            <div key={'RS' + i.toString()}>
              <div className="row m-0">
                <div className="article__big-subheader ct__ct__article__big-subheader article__big-subheader--no-lines">{item.RoundTitle}</div>
              </div>
              {tournament.IsTicketsAdded ?
              
              <div className="row m-0 m-r--30 article__round-container disabled">
              <DateTimePicker
                date={item.PlayDateTime ? new Date(item.PlayDateTime) : new Date()}
                dateLabel="Play Date"
                dateLabelClassName="article__subheader ct__article__subheader"
                onChange={(date: Date) => {
                  
                }}
                showTimePicker={!isAnyTime}
                timeLabel={`Play Time ${(tournament.TimeZone && tournament.TimeZone.Abbr) || ''}`}
                dateClassName={errors['TournamentRounds' + i] || errors['InvalidTournamentRounds' + i] ? ' highlight-invalid-control' : ''}
                dateContainerClassName="article__width-2 p-r-35 d-flex flex-column"
                dateWrapperClassName="article__input-date article__width-2 article__height-1"
                timeClass1={
                  'article__select article__select--time-closes article__input article__width-2 article__height-1 select ct__select' +
                  (errors['TournamentRounds' + i] || errors['InvalidTournamentRounds' + i] ? ' highlight-invalid-control' : '')
                }
                timeClass2="article__select--time-closes__title select__title-ct ct__select__title-ct"
                timeClass3="article__select--time-closes__container select__container ct__select__container"
                timeClass4="article__select--time-closes__option select__option"
                timeLabelClassName="article__subheader ct__article__subheader"
                timeContainerClassName="article__width-2 m-r-30 d-flex flex-column"
                timeZIndex={439 - i}
              />

             {!isAnyTime && <div className="article__width-2 m-r-30 d-flex flex-column disabled">
                <div className="article__subheader ct__article__subheader disabled">Deadline to submit results</div>
                <SelectComponent
                  fieldId="ResultSubmissionDeadline"
                  indexInsideCollection={i}
                  textField="text"
                  data={[
                    { text: '+15 minutes', value: 15 },
                    { text: '+30 minutes', value: 30 },
                    { text: '+45 minutes', value: 45 },
                    { text: '+1 hour', value: 60 },
                    { text: '+1 hour and 15 minutes', value: 75 },
                    { text: '+1 hour and 30 minutes', value: 90 },
                    { text: '+1 hour and 45 minutes', value: 105 },
                    { text: '+2 hours', value: 120 },
                    { text: '+2 hours and 15 minutes', value: 135 },
                    { text: '+2 hours and 30 minutes', value: 150 },
                    { text: '+2 hours and 45 minutes', value: 165 },
                    { text: '+3 hours', value: 180 },
                  ]}
                  valueField="value"
                  selectedValue={item.ResultSubmissionDeadline}
                  onSelectionChange={handleRoundDataChange}
                  zIndex={438 - i}
                  placeholder="Select Deadline"
                  class1={
                    'article__input article__select article__select--round-deadline article__width-2 article__height-1 select ct__select' +
                    (errors['TournamentRounds' + i] || errors['InvalidTournamentRounds' + i] ? ' highlight-invalid-control' : '')
                  }
                  class2="select-142__title select__title-ct ct__select__title-ct"
                  class3="select-142__container select__container ct__select__container"
                  class4="select-142__option select__option"
                  isImage={false}
                />
              </div>}
              <div className="article__hr" />
            </div>
              :
              <div className="row m-0 m-r--30 article__round-container">
                <DateTimePicker
                  date={item.PlayDateTime ? new Date(item.PlayDateTime) : new Date()}
                  dateLabel="Play Date"
                  dateLabelClassName="article__subheader ct__article__subheader"
                  onChange={(date: Date) => {
                    handleRoundDataChange('PlayDateTime', date, i)
                  }}
                  showTimePicker={!isAnyTime}
                  timeLabel={`Play Time ${(tournament.TimeZone && tournament.TimeZone.Abbr) || ''}`}
                  dateClassName={errors['TournamentRounds' + i] || errors['InvalidTournamentRounds' + i] ? ' highlight-invalid-control' : ''}
                  dateContainerClassName="article__width-2 p-r-35 d-flex flex-column"
                  dateWrapperClassName="article__input-date article__width-2 article__height-1"
                  timeClass1={
                    'article__select article__select--time-closes article__input article__width-2 article__height-1 select ct__select' +
                    (errors['TournamentRounds' + i] || errors['InvalidTournamentRounds' + i] ? ' highlight-invalid-control' : '')
                  }
                  timeClass2="article__select--time-closes__title select__title-ct ct__select__title-ct"
                  timeClass3="article__select--time-closes__container select__container ct__select__container"
                  timeClass4="article__select--time-closes__option select__option"
                  timeLabelClassName="article__subheader ct__article__subheader"
                  timeContainerClassName="article__width-2 m-r-30 d-flex flex-column"
                  timeZIndex={439 - i}
                />

               {!isAnyTime && <div className="article__width-2 m-r-30 d-flex flex-column">
                  <div className="article__subheader ct__article__subheader">Deadline to submit results</div>
                  <SelectComponent
                    fieldId="ResultSubmissionDeadline"
                    indexInsideCollection={i}
                    textField="text"
                    data={[
                      { text: '+15 minutes', value: 15 },
                      { text: '+30 minutes', value: 30 },
                      { text: '+45 minutes', value: 45 },
                      { text: '+1 hour', value: 60 },
                      { text: '+1 hour and 15 minutes', value: 75 },
                      { text: '+1 hour and 30 minutes', value: 90 },
                      { text: '+1 hour and 45 minutes', value: 105 },
                      { text: '+2 hours', value: 120 },
                      { text: '+2 hours and 15 minutes', value: 135 },
                      { text: '+2 hours and 30 minutes', value: 150 },
                      { text: '+2 hours and 45 minutes', value: 165 },
                      { text: '+3 hours', value: 180 },
                    ]}
                    valueField="value"
                    selectedValue={item.ResultSubmissionDeadline}
                    onSelectionChange={handleRoundDataChange}
                    zIndex={438 - i}
                    placeholder="Select Deadline"
                    class1={
                      'article__input article__select article__select--round-deadline article__width-2 article__height-1 select ct__select' +
                      (errors['TournamentRounds' + i] || errors['InvalidTournamentRounds' + i] ? ' highlight-invalid-control' : '')
                    }
                    class2="select-142__title select__title-ct ct__select__title-ct"
                    class3="select-142__container select__container ct__select__container"
                    class4="select-142__option select__option"
                    isImage={false}
                  />
                </div>}
                <div className={'w-100 flex-row article__error error error--rounds-0' + (errors['TournamentRounds' + i] ? ' error-visible' : '')}>
                  <div className="error__icon" />
                  <div className="error__text">Please fill in all necessary fields</div>
                </div>
                <div className={'w-100 flex-row article__error error error--rounds-0' + (errors['TournamentRoundsMain' + i] ? ' error-visible' : '')}>
                  <div className="error__icon" />
                  <div className="error__text"> You cannot select this date because your Main event is schedule for {ConvertDateToFormattedDatetimeString(new Date(mainTournamentDate),null,false,true)}</div>
                </div>
                <div
                  className={'w-100 flex-row article__error error error--rounds-0' + (errors['InvalidTournamentRounds' + i] ? ' error-visible' : '')}
                >
                  <div className="error__icon" />
                  <div className="error__text">Play Time must be after tournament start time and previous round play time</div>
                </div>
                <div className="article__hr" />
              </div>
              
              
              
              }



              
            </div>
          )
        })}
     
     
      
      {tournament.VenueTypeId === 2 && (
        <div className="row m-0 m-r--30 d-none">
          <div className="article__width-2 m-r-30 d-flex flex-column">
            <div className="article__subheader ct__article__subheader">Total days</div>
            <input className="article__input article__width-2 article__height-1" type="text" value={tickets.length || ''} readOnly />
          </div>
          {packageTicket && (
            <div className="article__width-2 m-r-30 d-flex flex-column price-container">
              <div className="article__subheader ct__article__subheader">{`Package Price for all ${tickets.length} days`}</div>
              <input
                className="article__input article__width-2 article__height-1"
                type="text"
                placeholder="Free"
                data-name="viewersPrice:all"
                data-day="all"
                value={packageTicket.TicketPrice || ''}
                onChange={e => {
                  handleTicketPriceChange(e.target.value, packageTicket.Id)
                }}
              />
            </div>
          )}
          {tickets.map((ticket, index) => (
            <div key={'RSTP' + index.toString()} className="article__width-2 m-r-30 d-flex flex-column price-container">
              <div className="article__subheader ct__article__subheader">{getTicketPriceHeader(ticket, index + 1)}</div>
              <input
                className="article__input article__width-2 article__height-1"
                type="text"
                placeholder="Free"
                data-name={`viewersPrice:${index}`}
                data-day={index + 1}
                value={ticket.TicketPrice || ''}
                onChange={e => {
                  handleTicketPriceChange(e.target.value, ticket.Id)
                }}
              />
            </div>
          ))}
          
          
        </div>
     
        
      )}
       {tournament.VenueTypeId === 2 &&
       <> 
       <div className="row m-0 w-100 flex-nowrap">
       
         <InfoText>
            Use this drop and drag builder to help you replicate the seating layout for the venue the event will take place.
         </InfoText>
       
       </div>
       <div className="row m-0 w-100" style={{ zIndex: 580,justifyContent:'start' }}>
         {tournament.IsTicketsAdded ? 
         <button className="blue-button blue-button--filled article__next-step disabled">Create Event Ticket</button>
          :
          <button className="blue-button blue-button--filled article__next-step" onClick=
          {e => {
            const tournamentSeatModel:TournamentSeatModel=Object()
            tournamentSeatModel.LayOutSectionId = Number(tournament.TournamentDetails[0].LayOutTypeId == null?0:tournament.TournamentDetails[0].LayOutTypeId)
            tournamentSeatModel.TournamentTicketSection = tournamentSectionDetail
            const detail = tournamentSectionDetail.map((x:any)=>{
              let shapeProperties =JSON.parse(x.ShapeProperties)
              shapeProperties.Id = x.Id
              x.ShapeProperties = JSON.stringify(shapeProperties)
              return x
            })
            localStorage.setItem(APP_NAMESPACE,JSON.stringify(detail))
            if(tournamentSeatModel.LayOutSectionId){
              localStorage.setItem("DefaultLayOut",tournamentSeatModel.LayOutSectionId.toString())
            }
            // else{
            //   localStorage.removeItem("DefaultLayOut")
            // }
            openTicketSeatingArrangementModal(tournamentSeatModel)
          }}
         >
         Seating Layout
         </button>
        }
       
       <div className={'w-100 flex-row article__error error error--rounds-0' + (errors['CreateEventTicketError'] ? ' error-visible' : '')}>
                  <div className="error__icon" />
                  <div className="error__text">Please Create Event Tickets </div>
                </div>
    </div></>}
      <>{ticketSeatingArrangementModal}</>
    </div>
  )
}
