import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { makeGetMatchSettings } from 'store/selectors/match-settings-selectors'
import { ApplicationState } from 'store/types/common'

export const useMatchSettings = (matchId: number) => {
  const getMatchSettings = useMemo(makeGetMatchSettings, [])
  const matchSettings = useSelector((state: ApplicationState) => getMatchSettings(state, matchId))
  return { matchSettings }
}
