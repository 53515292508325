import { useFilters } from 'components/_hooks'
import { FilterContainer, Filters, SearchInput, Select } from 'components/_shared'
import { consoleLabelExtractor, ConsoleOption, ConsoleTypeOptions, consoleValueExtractor } from 'consts/ConsoleType'
import React from 'react'
import { gameLabelExtractor, GameType, gameValueExtractor } from 'store/types/game-types'
import { PlayersFilters } from 'store/types/pages/members-page-types'
import { regionLabelExtractor, RegionType, regionValueExtractor } from 'store/types/region-types'
import styles from '../MembersPage.module.scss'

interface MembersFiltersProps {
  filtersModalOpened: boolean
  closeFiltersModal: () => void
  playersFilters: PlayersFilters
  onChange: (value: PlayersFilters) => void
}

export const MembersFilters = ({
  closeFiltersModal,
  filtersModalOpened,
  playersFilters,
  onChange,
}: MembersFiltersProps) => {
  const { games, gamesLoading, regions, regionsLoading } = useFilters()

  const handleNameChange = (value: string) => {
    onChange({
      ...playersFilters,
      name: value,
      page: 0,
    })
  }

  const handleGamesChange = (games: GameType[]) => {
    onChange({
      ...playersFilters,
      games,
      page: 0,
    })
  }

  const handleConsolesChange = (consoles: ConsoleOption[]) => {
    onChange({
      ...playersFilters,
      consoles,
      page: 0,
    })
  }

  const handleRegionsChange = (regions: RegionType[]) => {
    onChange({
      ...playersFilters,
      regions,
      page: 0,
    })
  }

  return (
    <Filters title="Filter Options" opened={filtersModalOpened} close={closeFiltersModal} className={styles.articleSelectsPopup}>
      <FilterContainer title="Name" className={styles.articleSelect}>
        <SearchInput onChange={handleNameChange} placeholder="Name (min 3 characters)" value={playersFilters.name} className={styles.search} />
      </FilterContainer>
      <FilterContainer title="Game" className={styles.articleSelect}>
        <Select
          options={games}
          onChange={handleGamesChange}
          selected={playersFilters.games}
          labelExtractor={gameLabelExtractor}
          placeHolder="All"
          valueExtractor={gameValueExtractor}
          type="multi"
          loading={gamesLoading}
          withSearch
        />
      </FilterContainer>
      <FilterContainer title="Platforms" className={styles.articleSelect}>
        <Select
          options={ConsoleTypeOptions}
          onChange={handleConsolesChange}
          selected={playersFilters.consoles}
          labelExtractor={consoleLabelExtractor}
          valueExtractor={consoleValueExtractor}
          type="multi"
          placeHolder="All"
        />
      </FilterContainer>
      <FilterContainer title="Region" className={styles.articleSelect}>
        <Select
          options={regions}
          onChange={handleRegionsChange}
          selected={playersFilters.regions}
          labelExtractor={regionLabelExtractor}
          valueExtractor={regionValueExtractor}
          type="multi"
          loading={regionsLoading}
          placeHolder="All"
        />
      </FilterContainer>
    </Filters>
  )
}
