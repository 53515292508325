import { useState } from 'react'
import Cropper from 'react-easy-crop'

interface KlCropperProps {
  aspectRatioType?: AspectRatioType
  src: string
  onChangeCroppedAreaPixels: (pixels: Area) => void
  containerClassName?: string
  zoomClassName?: string
  round?: boolean
  zoom: number
  onZoomChange: (value: number) => void
}

export interface Area {
  x: number
  y: number
  width: number
  height: number
}

const AspectRatio = {
  game: 870 / 411,
  news: 870 / 411,
  avatar: 2/2,
  tournamentCover: 2560 / 1440,
  organizerCover: 5 / 2,
}

export type AspectRatioType = 'game' | 'avatar' | 'tournamentCover' | 'news' | 'organizerCover'

export const KlCropper = ({
  aspectRatioType,
  src,
  containerClassName,
  onChangeCroppedAreaPixels,
  zoomClassName,
  round,
  zoom,
  onZoomChange,
 }: KlCropperProps) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })

  const handleZoomChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newZoom = parseInt(event.target.value)
    onZoomChange(newZoom/30)
  }
  const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
    onChangeCroppedAreaPixels(croppedAreaPixels)
  }

  return (
    <>
      <div className="w-100">
        <Cropper
          classes={
            {
              containerClassName: containerClassName
            }
          }
          crop={crop}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={onZoomChange}
          image={src}
          zoom={zoom}
          aspect={aspectRatioType && AspectRatio[aspectRatioType]}
          cropShape={round ? 'round' : 'rect'}
          showGrid={!round}
        />
      </div>
      <input type="range" className={zoomClassName} min="30" max="90" value={zoom * 30} onChange={handleZoomChange} step={0.1} />
    </>
  )
}
