import React, { useCallback } from "react";

import { SHAPE_TYPES } from "../constant/constant"
// import { useShapes } from "./state";
import { Circle } from "../components/shapes/Circle"
import { Rectangle } from "../components/shapes/Rectangle"
import { Square } from "../components/shapes/Square"
import { Ellipse } from "../components/shapes/Ellipse"
import { Arc } from "../components/shapes/Arc"
import { Triangle } from "../components/shapes/Triangle"

export const Shape =({ shape,selectedShape,setSelectedShape,moveShape,transformShape,operationType,openSectionDetail,handleUpdateText,IsCartPage }) => {
  if (shape.SectionShapeType === SHAPE_TYPES.RECT) {
    return <Rectangle {...shape}  operationType={operationType} isSelected={shape.Id === selectedShape?true:false}  setSelectedShape={setSelectedShape} moveShape={moveShape} transformShape={transformShape} openSectionDetail={openSectionDetail} handleUpdateText={handleUpdateText} IsCartPage={IsCartPage} />;
  } else if (shape.SectionShapeType === SHAPE_TYPES.CIRCLE) {
    return <Circle {...shape} operationType={operationType} isSelected={shape.Id === selectedShape?true:false} setSelectedShape={setSelectedShape} moveShape={moveShape} transformShape={transformShape} openSectionDetail={openSectionDetail} handleUpdateText={handleUpdateText} IsCartPage={IsCartPage}/>;
  }
  else if (shape.SectionShapeType === SHAPE_TYPES.SQUARE) {
  return <Square {...shape}  operationType={operationType} isSelected={shape.Id === selectedShape?true:false} setSelectedShape={setSelectedShape} moveShape={moveShape} transformShape={transformShape} openSectionDetail={openSectionDetail} handleUpdateText={handleUpdateText} IsCartPage={IsCartPage}/>;
}
else if (shape.SectionShapeType === SHAPE_TYPES.ELLIPSE) {
  return <Ellipse {...shape} operationType={operationType} isSelected={shape.Id === selectedShape?true:false} setSelectedShape={setSelectedShape} moveShape={moveShape} transformShape={transformShape} openSectionDetail={openSectionDetail} handleUpdateText={handleUpdateText} IsCartPage={IsCartPage}/>;
}
else if (shape.SectionShapeType === SHAPE_TYPES.ARC) {
  return <Arc {...shape}  operationType={operationType} isSelected={shape.Id === selectedShape?true:false} setSelectedShape={setSelectedShape} moveShape={moveShape} transformShape={transformShape} openSectionDetail={openSectionDetail} handleUpdateText={handleUpdateText} IsCartPage={IsCartPage} />;
}
else if (shape.SectionShapeType === SHAPE_TYPES.TRIANGLE) {
  return <Triangle {...shape}  operationType={operationType} isSelected={shape.Id === selectedShape?true:false} setSelectedShape={setSelectedShape} moveShape={moveShape} transformShape={transformShape} openSectionDetail={openSectionDetail} handleUpdateText={handleUpdateText} IsCartPage={IsCartPage}/>;
}

  return null;
}
