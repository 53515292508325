import { TournamentModeEnum } from 'consts'
import { useSelector } from 'react-redux'
import { getAppUser } from 'store/selectors/app-selectors'
import { useMemberClan } from '../useMemberClan'
import { Permission } from 'store/types/common'
import { useMatch, useMatchSettings, useUser } from '../useModels'
import { useFindMatchParticipant } from '../useFindMatchParticipant'
import { getIsAdmin, getIsOrganizer } from 'store/types/user-types'

export const useMatchSettingsPermission = (matchId: number): Permission => {
  const user = useSelector(getAppUser)
  const { isClanLeader } = useMemberClan(user?.Id)
  const matchParticipant = useFindMatchParticipant(matchId, user?.Id)
  const { tournament } = useMatch(matchId)
  const { matchSettings } = useMatchSettings(matchId)
  const { user: organizer } = useUser(tournament?.OrganizerId)
  const isAdminTournament = getIsAdmin(organizer?.Role)

  if (!tournament || !user) {
    return Permission.none
  }

  const isOrganizer = getIsOrganizer(user.Role)
  const isAdmin = getIsAdmin(user.Role)

  if (tournament.HostedByOrganizer) {
    if (isAdmin) {
      return isAdminTournament ? Permission.write : Permission.none
    }

    if (isOrganizer) {
      return tournament.OrganizerId === user.Id ? Permission.write : Permission.none
    }

    return matchParticipant ? Permission.read : Permission.none
  }

  if (isAdmin) {
    return isAdminTournament ? Permission.read : Permission.none
  }

  if (isOrganizer) {
    return tournament.OrganizerId === user.Id ? Permission.read : Permission.none
  }

  if (!matchParticipant) {
    return Permission.none
  }

  if (!matchSettings) {
    return Permission.read
  }

  if (matchSettings.HostedByMatchParticipantId !== matchParticipant.Id) {
    return Permission.read
  }

  if (tournament.TournamentModeId === TournamentModeEnum.Solo) {
    return Permission.write
  }

  return isClanLeader ? Permission.write : Permission.read
}
