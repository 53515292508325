import React from 'react'
import { Modal } from 'components/_modals'
import styles from './CancelParticipationModal.module.scss'
import { useCancelParticipation } from 'store/logic/participants-logic'
import { useReloadPendingMatches } from 'store/logic/match-logic'
import { EventTypeEnum } from 'models/tournament-models'

export interface CancelParticipationModalProps {
  onClose: CallableFunction
  tournamentDetailId: number
  EventTypeId: number
  onDone?: () => void
}

export const CancelParticipationModal = ({ onClose, tournamentDetailId, onDone,EventTypeId }: CancelParticipationModalProps) => {
  const cancelParticipation = useCancelParticipation()
  const reloadPendingRounds = useReloadPendingMatches()

  const asyncOperation = async () => {
    await cancelParticipation(tournamentDetailId)
    void reloadPendingRounds()
    if (onDone) {
      onDone()
    }
    return true
  }

  return (
    <Modal
      onClose={onClose}
      allowOk
      asyncOperation={asyncOperation}
      buttonColor="red"
      className={styles.window}
    >
      <Modal.Header className={styles.windowHeader}>
        Warning
      </Modal.Header>
      <Modal.SubHeader className={styles.windowSubheader}>
        {EventTypeId == EventTypeEnum.Main
          ? 'If you unregister from this tournament then you will not be able to enroll back in again unless you play in another qualifier tournament again please confirm'
          : 'Do you want to cancel your participation in the event?'}
      </Modal.SubHeader>
    </Modal>
  )
}
