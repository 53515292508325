import { useAppUser } from 'components/_hooks'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { ChatLefModel, chatMemberLeft, typingMessageReceived } from 'store/chat/chat-member/chat-member-types'
import { loadNewChatMessage } from 'store/chat/chat-message/chat-message-logic'
import { chatMessageReceived, ChatReceivedMessageModel } from 'store/chat/chat-message/chat-message-types'
import { useOpenedChatId, useOpenedUserId } from 'store/chat/chat/chat-hooks'
import { loadChat } from 'store/chat/chat/chat-logic'
import { ChatDeletedModel, chatDeletedNotificationReceived, ChatMemberBlockedModel, TypingModel } from 'store/chat/chat/chat-types'
import { matchSettingsActionCreators, reloadMatchSettings } from 'store/logic/match-settings-logic'
import { matchesSumbissionsActionCreators } from 'store/logic/match-submissions-logic'
import { loadParticpant } from 'store/logic/participants-logic'
import { useOnChatDeletedNotificationReceived, useOnChatLeftNotificationReceived, useOnChatMemberBlockedReceived, useOnMessageReceived, useOnOnlineStatusReceived,useReloadTournamentDetailReceived,useSayWhoIsTyping } from 'store/signalr/hubs/chat-hub-events'
import { onlineStatusChanged, OnlineStatusModel } from 'store/types/user-types'

const SignalRConnection = () => {
  const dispatch = useDispatch()
  const appUser = useAppUser()
  const openedChatId = useOpenedChatId()
  const openedUserId = useOpenedUserId()

  const reciveMessageCallback = useCallback((recievedMessage: ChatReceivedMessageModel) => {
    dispatch(chatMessageReceived(recievedMessage.ChatMessage))

    if ((!openedChatId || openedChatId !== recievedMessage.ChatMessage.ChatId) &&
        (!openedUserId || openedUserId !== recievedMessage.UserId)
        && recievedMessage.UserId != appUser?.Id) {
      dispatch(loadNewChatMessage(recievedMessage.ChatMessage.ChatId, recievedMessage.ChatMessage.Id))
    }
  }, [appUser?.Id, dispatch, openedChatId, openedUserId])

  useOnMessageReceived(reciveMessageCallback)

  const chatMemberLeftRecieved = useCallback((model: ChatLefModel) => {
    dispatch(chatMemberLeft(model))
  }, [dispatch])

  useOnChatLeftNotificationReceived(chatMemberLeftRecieved)

  const chatDeletedRecieved = useCallback((model: ChatDeletedModel) => {
    dispatch(chatDeletedNotificationReceived(model.ChatId))
  }, [dispatch])
  useOnChatDeletedNotificationReceived(chatDeletedRecieved)


  const chatMemberBlockedRecieved = useCallback((model: ChatMemberBlockedModel) => {
    dispatch(chatMessageReceived(model.ChatMessage))
    if (!openedChatId || openedChatId !== model.ChatId) {
      dispatch(loadNewChatMessage(model.ChatId, model.ChatMessage.Id))
    }
    dispatch(loadChat(model.ChatId))
  }, [dispatch, openedChatId])

  useOnChatMemberBlockedReceived(chatMemberBlockedRecieved)


  const onlineStatusCallback = useCallback((onlineStatus: OnlineStatusModel) => {
    dispatch(onlineStatusChanged(onlineStatus))
  }, [dispatch])

  useOnOnlineStatusReceived(onlineStatusCallback)

  const typingCallback = useCallback((model: TypingModel) => {
    dispatch(typingMessageReceived(model))
  }, [appUser?.Id, dispatch, openedChatId, openedUserId])

  useSayWhoIsTyping(typingCallback)

  const reloadTournamentDetailCallback = useCallback((type:string,matchId: number) => {
   if(type ==='lobbydetails')
   {
      dispatch(matchSettingsActionCreators.reloadMatchSettings(matchId))
   }
   else if(type === 'submitresult')  
   {
      dispatch(matchesSumbissionsActionCreators.loadMatchSubmissions(matchId))
   }
  }, [dispatch])

  useReloadTournamentDetailReceived(reloadTournamentDetailCallback)

  return (
    <>
    </>
  )
}

export default SignalRConnection
