import { useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import { fetchApi } from 'server/server-utils'
import { getFriendRequestsRequested } from 'store/selectors/friend-request-selectors'
import { GetState } from 'store/types/common'
import { friendRequestAdded, FriendRequestModel, friendRequestResolved, friendRequestsLoaded, FriendRequestType } from 'store/types/friend-requests-types'
import { ImageType } from 'store/types/image-types'
import { PublicUserType } from 'store/types/user-types'
import { cancel, OperationTypes, run, useRun } from './operations-logic'

interface FriendRequestDto {
  IncomingRequests: FriendRequestType[]
  OutgoingRequests: FriendRequestType[]
  Users: PublicUserType[]
  Images: ImageType[]
}

const fetchFriendRequests = () => {
  return fetchApi<FriendRequestDto>('friendrequest', 'GET')
}

export const loadFriendRequests = () => async (dispatch: Dispatch, getState: GetState) => {
  if (getFriendRequestsRequested(getState())) {
    return
  }
  await run(OperationTypes.loadFriendRequests, null, dispatch, getState, async () => {
    try {
      const friendRequests = await fetchFriendRequests()
      dispatch(friendRequestsLoaded(friendRequests))
    } catch (error) {
      return false
    }
    return true
  })
}

export const reLoadFriendRequests = () => async (dispatch: Dispatch, getState: GetState) => {
  cancel(OperationTypes.loadFriendRequests, null, dispatch, getState)
  await loadFriendRequests()(dispatch, getState)
}

export const useSendFriendRequest = (userId: number) => {
  const dispatch = useDispatch()
  return useRun(OperationTypes.sendFriendRequest, userId, async (model: FriendRequestModel) => {
    try {
      const result = await fetchApi<FriendRequestType>('friendrequest/sendfriendrequest', 'POST', model)
      dispatch(friendRequestAdded({ userId: result.ToUserId }))
    } catch (error) {
      return false
    }
    return true
  })
}

export const useAcceptFriendRequest = () => {
  const dispatch = useDispatch()
  return useRun(OperationTypes.resolveFriendRequest, null, async (friendRequestId: number) => {
    await fetchApi(`friendrequest/${friendRequestId}/accept`, 'POST')
    dispatch(friendRequestResolved({ friendRequestId }))
    return true
  })
}

export const declineFriendRequest = (friendRequestId: number) => async (dispatch: Dispatch, getState: GetState) => {
  await run(OperationTypes.resolveFriendRequest, null, dispatch, getState, async () => {
    await fetchApi(`friendrequest/${friendRequestId}/decline`, 'POST')
    dispatch(friendRequestResolved({ friendRequestId }))
    return true
  })
}

export const getFriendRequest = (friendrequestId: number) => {
  return fetchApi<FriendRequestType>(`friendrequest/${friendrequestId}`, 'GET')
}
