import React from 'react'
import { SvgProps } from './common'

export const ClansModeSvg = ({ className }: SvgProps) => {
  return (
    <svg className={className} width="41" height="25">
      <path d="M32.8706 9.08354C34.0618 9.08354 35.0933 8.63597 35.9361 7.7529C36.7789 6.86999 37.2061 5.78968 37.2061 4.54163C37.2061 3.294 36.7789 2.21355 35.936 1.33035C35.093 0.447573 34.0617 0 32.8706 0C31.6793 0 30.6481 0.447573 29.8053 1.33049C28.9625 2.21341 28.5352 3.29386 28.5352 4.54163C28.5352 5.78968 28.9625 6.87013 29.8055 7.75305C30.6484 8.63582 31.6797 9.08354 32.8706 9.08354V9.08354Z"></path>
      <path d="M7.87064 9.08354C9.06183 9.08354 10.0933 8.63597 10.9361 7.7529C11.7789 6.86999 12.2061 5.78968 12.2061 4.54163C12.2061 3.294 11.7789 2.21355 10.936 1.33035C10.093 0.447573 9.06169 0 7.87064 0C6.67931 0 5.6481 0.447573 4.80531 1.33049C3.96252 2.21341 3.53516 3.29386 3.53516 4.54163C3.53516 5.78968 3.96252 6.87013 4.80545 7.75305C5.64838 8.63582 6.67972 9.08354 7.87064 9.08354V9.08354Z"></path>
      <path d="M20.351 12.5975C21.7408 12.5975 22.9441 12.0753 23.9274 11.0451C24.9107 10.015 25.4091 8.75463 25.4091 7.29856C25.4091 5.843 24.9107 4.58248 23.9272 3.55207C22.9438 2.52217 21.7406 2 20.351 2C18.9611 2 17.7581 2.52217 16.7748 3.55224C15.7916 4.58231 15.293 5.84283 15.293 7.29856C15.293 8.75463 15.7916 10.0152 16.775 11.0452C17.7584 12.0751 18.9616 12.5975 20.351 12.5975V12.5975Z"></path>
      <path 
        fillRule="evenodd" 
        clipRule="evenodd" 
        d="M40.3117 13.3093C40.3841 13.7326 40.4333 14.1334 40.4576 14.5008C40.4816 14.8599 40.4938 15.2347 40.494 15.6147C40.494 16.5994 40.1953 17.3965 39.6061 17.9837C39.0243 18.5638 38.2548 18.8579 37.3188 18.8579H30.2265C30.2195 18.6657 30.2098 18.4761 30.1973 18.29C30.1689 17.8614 30.1116 17.3937 30.0272 16.8999C29.9419 16.4024 29.8322 15.9321 29.7008 15.5023C29.5651 15.058 29.3805 14.6192 29.1524 14.1988C28.9156 13.7624 28.6374 13.3824 28.3253 13.0697C27.999 12.7426 27.5994 12.4795 27.1373 12.2877C26.7762 12.138 26.3843 12.0462 25.9688 12.0136C26.0817 11.6661 26.2293 11.3236 26.408 10.994C26.6107 10.6201 26.8492 10.2946 27.1166 10.0264C27.3966 9.7457 27.7392 9.52026 28.135 9.35611C28.5296 9.19239 28.9668 9.10938 29.4348 9.10938C29.6186 9.10938 29.7964 9.18836 30.1392 9.42243C30.3536 9.56875 30.6008 9.73563 30.8739 9.91849C31.1094 10.0759 31.4289 10.2233 31.823 10.3567C32.2083 10.4872 32.599 10.5534 32.9848 10.5534C33.3706 10.5534 33.7614 10.4872 34.1463 10.3567C34.5409 10.2232 34.8603 10.0757 35.0961 9.91835C35.3719 9.73376 35.6189 9.56688 35.8301 9.42258C36.1733 9.1885 36.351 9.10952 36.5348 9.10952C37.0026 9.10952 37.44 9.19239 37.8347 9.35596C38.2308 9.52041 38.5733 9.74585 38.853 10.0262C39.1205 10.2943 39.3589 10.62 39.5619 10.994C39.7575 11.3544 39.9157 11.7305 40.032 12.1113C40.1446 12.4798 40.2387 12.8829 40.3117 13.3093Z"
      >
      </path>
      <path 
        fillRule="evenodd" 
        clipRule="evenodd" 
        d="M10.7318 18.8579C10.7387 18.6663 10.7484 18.4769 10.7607 18.2905C10.7891 17.8608 10.8464 17.3931 10.9309 16.8999C11.0161 16.4024 11.1259 15.9323 11.2571 15.5024C11.3929 15.0578 11.5775 14.6192 11.8055 14.1988C12.042 13.7626 12.3203 13.3827 12.6322 13.0699C12.9589 12.7424 13.3587 12.4794 13.8204 12.2879C14.185 12.1366 14.5808 12.0444 15.0009 12.0125C14.8881 11.6655 14.7406 11.3232 14.562 10.994C14.359 10.62 14.1206 10.2943 13.8531 10.0262C13.5734 9.74585 13.2309 9.52041 12.8348 9.35596C12.4401 9.19239 12.0027 9.10952 11.5349 9.10952C11.3511 9.10952 11.1734 9.1885 10.8302 9.42258C10.619 9.56688 10.3719 9.73376 10.0962 9.91835C9.8604 10.0757 9.54097 10.2232 9.14642 10.3567C8.76149 10.4872 8.37065 10.5534 7.98489 10.5534C7.59914 10.5534 7.20844 10.4872 6.82309 10.3567C6.42896 10.2233 6.10953 10.0759 5.87401 9.91849C5.60086 9.73563 5.35367 9.56875 5.1393 9.42243C4.79652 9.18836 4.61868 9.10938 4.43494 9.10938C3.96692 9.10938 3.52966 9.19239 3.13512 9.35611C2.73933 9.52026 2.3967 9.7457 2.11668 10.0264C1.8493 10.2946 1.61076 10.6201 1.40807 10.994C1.21265 11.3544 1.05444 11.7304 0.937988 12.1115C0.825516 12.4799 0.731445 12.8829 0.658386 13.3093C0.586014 13.732 0.53685 14.133 0.512543 14.5013C0.488647 14.8621 0.476562 15.2366 0.476562 15.6148C0.476562 16.5991 0.775253 17.396 1.36426 17.9837C1.94598 18.5637 2.71571 18.8579 3.65175 18.8579H10.7318Z"
      >
      </path>
      <path d="M29.2006 19.29C29.1722 18.8614 29.1149 18.3937 29.0304 17.8999C28.9452 17.4024 28.8355 16.9321 28.7041 16.5023C28.5684 16.058 28.3838 15.6192 28.1557 15.1988C27.9189 14.7624 27.6407 14.3824 27.3286 14.0697C27.0023 13.7426 26.6027 13.4795 26.1406 13.2877C25.6801 13.0968 25.1699 13.0002 24.624 13.0002C24.4096 13.0002 24.2023 13.0923 23.8019 13.3654C23.5555 13.5338 23.2673 13.7285 22.9456 13.9438C22.6705 14.1274 22.2978 14.2995 21.8375 14.4552C21.3884 14.6075 20.9324 14.6847 20.4824 14.6847C20.0323 14.6847 19.5765 14.6075 19.1269 14.4552C18.6671 14.2996 18.2944 14.1276 18.0197 13.944C17.701 13.7306 17.4126 13.5359 17.1625 13.3652C16.7626 13.0921 16.5551 13 16.3408 13C15.7947 13 15.2846 13.0968 14.8243 13.2879C14.3626 13.4794 13.9628 13.7424 13.6361 14.0699C13.3242 14.3827 13.0459 14.7626 12.8094 15.1988C12.5814 15.6192 12.3969 16.0578 12.261 16.5024C12.1298 16.9323 12.02 17.4024 11.9348 17.8999C11.8503 18.3931 11.793 18.8608 11.7646 19.2905C11.7368 19.7115 11.7227 20.1484 11.7227 20.5897C11.7227 21.7381 12.0711 22.6678 12.7583 23.3534C13.437 24.03 14.335 24.3732 15.427 24.3732H25.5387C26.6307 24.3732 27.5284 24.0302 28.2073 23.3534C28.8946 22.6683 29.2431 21.7384 29.2431 20.5895C29.2429 20.1462 29.2286 19.709 29.2006 19.29V19.29Z"></path>
    </svg>
  )
}
