import { createSelector } from 'reselect'
import { OperationTypes } from 'store/logic/operations-logic'
import { ApplicationState } from '../types/common'
import { makeGetFirstRoundMatchesIds } from './match-selectors'
import { makeGetIsOperationWithTypeRunning, makeGetIsOperationWithTypeSuccessOrRunning } from './operations-selectors'

const getState = (state: ApplicationState) => state

const getMatchParticipantsState = (state: ApplicationState) => state.matchParticipants

const getMatchParticipantsById = createSelector(getMatchParticipantsState, state => state?.byId || {})

const getMatchParticipantsIdsByMatchId = createSelector(getMatchParticipantsState, state => state?.idsByMatchId || {})

export const makeGetMatchParticipantsByMatchId = () =>
  createSelector(
    getMatchParticipantsById,
    getMatchParticipantsIdsByMatchId,
    (_: ApplicationState, matchId: number) => matchId,
    (byId, byMatch, matchId) => {
      const ids = byMatch[matchId] || []
      return ids.map(id => byId[id])
    }
  )

const getLoadMatchParticipantsOperationType = createSelector(
  (_, matchId: number) => matchId,
  matchId => ({ type: OperationTypes.loadMatchParticipants, objectId: matchId })
)
export const makeGetIsMatchParticipantsLoading = () => makeGetIsOperationWithTypeRunning(getLoadMatchParticipantsOperationType)
export const makeGetIsMatchParticipantsRequested = () => makeGetIsOperationWithTypeSuccessOrRunning(getLoadMatchParticipantsOperationType)

export const makeGetMatchParticipantsIds = () =>
  createSelector(
    getMatchParticipantsIdsByMatchId,
    (_: ApplicationState, matchId: number) => matchId,
    (idsByMatchId, matchId) => (idsByMatchId[matchId] || []).sort((a, b) => a - b)
  )

export const makeGetMatchParticipant = () =>
  createSelector(
    getMatchParticipantsById,
    (_: ApplicationState, id: number) => id,
    (byId, id) => byId[id]
  )

export const makeGetMatchParticipants = () => {
  const getMatchParticipantsIds = makeGetMatchParticipantsIds()
  return createSelector(getMatchParticipantsById, getMatchParticipantsIds, (byId, ids) => ids.map(id => byId[id]))
}

export const makeGetTournamentParticipantNumbers = () => {
  const getFirstRoundMatchesIds = makeGetFirstRoundMatchesIds()
  const getMatchParticipants = makeGetMatchParticipants()

  return createSelector(getState, getFirstRoundMatchesIds, (state, matchesIds) => {
    const tournamentParticipantNumbers: { [id: number]: number } = {}
    let currentMatchParticipantNumber = 1
    matchesIds.forEach(matchId => {
      const matchParticipants = getMatchParticipants(state, matchId)
      matchParticipants.forEach(matchParticipant => {
        tournamentParticipantNumbers[matchParticipant.TournamentParticipantId] = currentMatchParticipantNumber
        currentMatchParticipantNumber += 1
      })
    })
    return tournamentParticipantNumbers
  })
}

export const makeGetTournamentParticipantNumber = () => {
  const getMatchParticipantNumbers = makeGetTournamentParticipantNumbers()

  return createSelector(
    getMatchParticipantNumbers,
    (_: ApplicationState, { tournamentParticipantId }: { tournamentParticipantId: number }) => tournamentParticipantId,
    (tournamentParticipantNumbers, tournamentParticipantId) => {
      return tournamentParticipantNumbers[tournamentParticipantId]
    }
  )
}
