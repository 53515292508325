import { ContainerName, useAppUser, useGetImageSize, useIntRouteParam } from 'components/_hooks'
import React, { useEffect, useMemo } from 'react'
import styles from './News.module.scss'
import { ArticleHeader, Img, LoadingBox } from 'components/_shared'
import { cn, defaultFormatDate } from 'utils'
import { Link } from 'react-router-dom'
import { makeGetBlog, makeGetBlogSectionsIdsByBlogId, makeGetIsBlogLoading } from 'store/selectors/blog-selectors'
import { ApplicationState } from 'store/types/common'
import { useDispatch, useSelector } from 'react-redux'
import { loadBlog } from 'store/logic/blogs-logic'
import { readBlog } from 'store/logic/user-read-blogs-logic'
import { BlogSection } from 'components/_shared/BlogSection/BlogSection'

interface NewsRouteParams {
  id: string
}

export const News = () => {
  const dispatch = useDispatch()
  const id: number = useIntRouteParam<NewsRouteParams>(x => x.id)
  const appUser = useAppUser()

  const getIsBlogLoading = useMemo(makeGetIsBlogLoading, [])
  const getBlog = useMemo(makeGetBlog, [])
  const getBlogSectionsIdsByBlogId = useMemo(makeGetBlogSectionsIdsByBlogId, [])

  const isBlogLoading = useSelector((state: ApplicationState) => getIsBlogLoading(state, { blogId: id }))
  const blog = useSelector((state: ApplicationState) => getBlog(state, { blogId: id }))
  const sectionsIds = useSelector((state: ApplicationState) => getBlogSectionsIdsByBlogId(state, { blogId: id }))
  const getImageSize = useGetImageSize(ContainerName.BlogDetails)
  useEffect(() => {
    console.log(blog?.Id)
    if (!blog) {
      dispatch(loadBlog(id))
    }
    if (appUser?.Id && blog?.Id) {
      dispatch(readBlog(appUser?.Id, blog?.Id))
    }
  }, [appUser, blog, dispatch, id])

  return (
    <>
      <ArticleHeader text={blog?.Title} />
      <LoadingBox loading={isBlogLoading} size="big">
        <div className={styles.breadcrumb}>
          <ul className="d-flex">
            <li><Link to="/news">News</Link></li>
            <li className={styles.active}>{blog?.Title}</li>
          </ul>
        </div>
        <div className={cn(styles.blogContent, styles.detail)}>
          <div className="row">
            <div className="col-md-12">
              <div className={styles.item}>
                <span className={styles.image}>
                  <Img parentContainerSize={getImageSize} alt={blog?.Title} imageId={blog?.PreviewImageId} className={styles.img} />
                </span>
                <span className={styles.head}>{blog?.Title}</span>
                <span className={cn(styles.published, 'mb-2')}>Published: <b>{defaultFormatDate(blog?.Date)}</b></span>
                {sectionsIds?.map(id => <BlogSection blogTitle={blog?.Title} id={id} key={id} imageClassName={styles.img} textClassName={styles.content} />)}
              </div>
            </div>
          </div>
        </div>
      </LoadingBox>
    </>
  )
}
