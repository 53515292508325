import { ArticleHeader } from 'components/_shared'
import React from 'react'
import styles from './Rules.module.scss'

export const Rules = () => {
  return (
    <>
      <ArticleHeader text="KombatLink Rules" />
      <div className="row flex-column">
        <div className={styles.medHead}>General Rules:</div>
        <p className={styles.text}>Any member of KombatLink community caught violating the Terms Of Services will receive an initial warning along with a thirty day account suspension upon first offense. KombatLink takes violation of the Terms Of Service seriously and will not tolerate repeat offenders and will ban them permanently.</p>
        <div className={styles.medHead}>Match Result Disputes:</div>
        <p className={styles.text}>Any and all match evidence must be submitted to KombatLink within the submission timeline for those matches by clicking on the “submit results” button found in your “My battles” page. Once the submission timeline expires, all match results will be final. Evidence submitted within the timeline will be reviewed by site administrators to determine the outcome of the match. The KombatLink Support team has the sole discretion to ask users to provide additional evidence. Any dispute that is filed without evidence will be considered invalid. If only one party submits evidence during this time frame, the user failing to provide evidence will be considered to forfeit the match, and the opposing user will prevail as the winner by default.</p>
        <div className={styles.medHead}>Forfeit Match:</div>
        <ul className={styles.list}>
          <li>If a player does not Check-in to a scheduled challenge or tournament by the start time, this will become an automatic forfeit.</li>
          <li>Players who do not report game results within the predetermined timeline following a match will be deemed to have forfeited that match.</li>
          <li>If a player claims a “win” in a match that was either: not played, not completed, drawn or lost, an automatic forfeit and suspension will be issued, following on the suspension guideline listed above plus a penalty fee equal to the amount of the entry fee for that challenge or tournament.</li>
          <li>If a player submits “false evidence”; evidence that has previously been submitted or archived from a previous match, evidence that has been altered in any way, or false evidence that has been created, then that user will automatically forfeit the match and immediately face a suspension (based on the suspension guideline listed above) plus a penalty fee equal to the amount of the entry fee for that challenge or tournament.</li>
          <li>Absolutely no cheating will be tolerated. Any player breaking in game rules will forfeit that match and their opponent will win by default.</li>
          <li>When a player disconnects from a match while their opponent has a significant advantage, the user who disconnected will receive an automatic loss.</li>
          <li>Players who are caught using IP booting matches will face an automatic loss and suspension.</li>
        </ul>
        <div className={styles.medHead}>Chat, Message Board Policy:</div>
        <p className={styles.text}>KombatLink reserves the right to temporarily suspend or permanently ban members who violate these rules of conduct, or who in any way abuse the community purpose of the chat areas remove any posts for any reason.<br />The following contents and behavior are prohibited.</p>
        <ul className={styles.list}>
          <li>No personal or confidential information</li>
          <li>Pornography contents will not be allowed.</li>
          <li>Any sort of programming codes or commands are not allowed in messages.</li>
          <li>Advertising or promotion of other companies or URLs are not allowed.</li>
          <li>No spamming.</li>
          <li>Anything illegal will not be allowed</li>
          <li>No bullying of any members, or encouragement of others to bully will be tolerated.</li>
          <li> Violent, threatening and harassing language of any kind will not be tolerated.</li>
          <li>No racial slurs will be allowed.</li>
          <li>No impersonating someone to mislead or in a deceptive manner.</li>
        </ul>
        <p className={styles.text}>Information disclosed in the Site chat rooms, message boards, becomes property of KombatLink and is open to the public.</p>
        <div className={styles.medHead}>Marketplace Policy:</div>
        <p className={styles.text}>Only game related products will be allowed to be listed for sale in the marketplace. (e.g. consoles, games, headset, controllers, posters, toys, memorabilia, gaming chairs, etc..)<br />
          KombatLink reserves the right to remove any sale listing that is not gaming related.  Any fees related to listing will not be reimburse to the seller.<br />
          KombatLink reserves the right to terminate the accounts of sellers who misrepresent their items.
        </p>
      </div>
    </>
  )
}
