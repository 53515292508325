import { Dispatch } from 'redux'
import { lineUpsActionCreators } from 'store/logic/line-ups-logic'
import { matchesActionCreators } from 'store/logic/match-logic'
import { loadTournamentMatchesParticipants } from 'store/logic/match-participant-logic'
import { roundsActionCreators } from 'store/logic/rounds-logic'
import { tournamentsActionCreators } from 'store/logic/tournaments-logic'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const loadBracket = (tournamentDetailId: number, dispatch: Dispatch<any>) => {
  dispatch(tournamentsActionCreators.loadTournament(tournamentDetailId))
  dispatch(roundsActionCreators.reLoadRounds(tournamentDetailId))
  dispatch(matchesActionCreators.loadTournamentMatches(tournamentDetailId))
  dispatch(loadTournamentMatchesParticipants(tournamentDetailId))
  dispatch(lineUpsActionCreators.reloadLineUps(tournamentDetailId))
}
