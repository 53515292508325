import { PlayerBattlesAndLoyaltyPointsViewModel } from 'models/Player_OrganizerHeaderViewModel'
import { Dispatch } from 'redux'
import { EndPoints } from 'server/endpoints'
import { fetchApi } from 'server/server-utils'
import { GetState } from 'store/types/common'
import { PlayerBattlesAndLoyaltyPointsLoaded } from 'store/types/player-battle-and-loyaltypoint-type'
import { OperationTypes, run } from './operations-logic'

export const loadPlayerBattlesAndLoyaltyPoints = (userId: number) => async (dispatch: Dispatch, getState: GetState) => {
  await run(OperationTypes.loadPlayerBattlesAndLoyaltyPoints, userId, dispatch, getState, async () => {
    try {
      const model = await fetchApi<PlayerBattlesAndLoyaltyPointsViewModel>(EndPoints.User.PlayerBattlesAndLoyaltyPoints, 'GET')
      dispatch(PlayerBattlesAndLoyaltyPointsLoaded({
        PlayerBattlesAndLoyaltyPoints : model
      }))
    } catch {
      return false
    }
    return true
  })
}
