import { SetOperationStatus, OperationStatus, OperationObject } from '../types/operations-types'
import { Dispatch } from 'redux'
import { getOperations, makeGetIsOperationWithTypeRunning } from '../selectors/operations-selectors'
import { ApplicationState, GetState } from 'store/types/common'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'

// -----------------------------------------------------------
// ------------------ Operation types ------------------------
// -----------------------------------------------------------
export enum OperationTypes {
  unjoin = 'unjoin',
  join = 'join',
  deleteParticipant = 'deleteParticipant',
  loadApp = 'loadApp',
  loadCheckInMatches = 'loadCheckInMatches',
  loadActiveMatches = 'loadActiveMatches',
  loadFinishedMatches = 'loadFinishedMatches',
  loadMatchParticipants = 'loadMatchParticipants',
  loadParticipants = 'loadParticipants',
  loadParticipant = 'loadParticipant',
  loadMatchSubmissions = 'loadMatchSubmissions',
  loadMatchesSubmissions = 'loadMatchesSubmissions',
  loadTournamentMatches = 'loadTournamentMatches',
  loadTournamentMatchesParticipants = 'loadTournamentMatchesParticipants',
  updateParticipantSubmissionLink = 'updateParticipantSubmissionLink',
  checkIn = 'checkIn',
  submit = 'submit',
  loadNotifications = 'loadNotifications',
  loadUnreadNotificationsCount = 'loadUnreadNotificationsCount',
  readNotification = 'readNotification',
  deleteNotification = 'deleteNotification',
  loadTournament = 'loadTournament',
  loadRounds = 'loadRounds',
  loadDisputes = 'loadDisputes',
  loadDisputeDetails = 'loadDisputeDetails',
  addDisputeDetails = 'addDisputeDetails',
  updateDisputeDetails = 'updateDisputeDetails',
  resolveDispute = 'resolveDispute',
  loadOrganizers = 'loadOrganizers',
  loadGames = 'loadGames',
  loadRegions = 'loadRegions',
  loadPlayerOnlineTournaments = 'loadPlayerOnlineTournaments',  
  loadPlayerLiveTournaments = 'loadPlayerLiveTournaments',  
  loadFinishedTournaments = 'loadFinishedTournaments',
  loadMatchLinks = 'loadMatchLinks',
  addMatchLink = 'addMatchLink',
  loadTournamentMatchLinks = 'loadTournamentMatchLinks',
  loadPendingMatches = 'loadPendingRounds',
  loadPendingMatchLinks = 'loadPendingMatchLinks',
  loadApprovedMatchLinks = 'loadApprovedMatchLinks',
  publishMatchLink = 'publishMatchLink',
  deleteMatchLink = 'deleteMatchLink',
  loadLineUps = 'loadLineUps',
  addLineUp = 'addLineUp',
  deleteLineUp = 'deleteLineUp',
  updateAvatar = 'updateAvatar',
  updateCoverImage = 'updateCoverImage',
  loadUser = 'loadUser',
  loadImage = 'loadImage',
  uploadImage = 'uploadImage',
  uploadImages = 'uploadImages',
  loadClan = 'loadClan',
  loadMatchSettings = 'loadMatchSettings',
  setMatchSettings = 'setMatchSettings',
  finishMatch = 'finishMatch',
  loadNotificationQueue = ' loadNotificationQueue',
  loadUserGames = 'loadUserGames',
  loadTopGames = 'loadTopGames',
  loadUserConsoles = 'loadUserConsoles',
  loadTopUserFriends = 'loadTopUserFriends',
  loadUserFriends = 'loadUserFriends',
  loadAppUser = 'loadAppUser',
  sendFriendRequest = 'sendFriendRequest',
  loadFriendRequests = 'loadFriendRequests',
  removeFriend = 'removeFriend',
  loadUpcomingTournaments = 'loadUpcomingTournaments',
  loadMainTournaments = 'loadMainTournaments',
  loadQualifyTournaments = 'loadQualifyTournaments',
  loadPlayerProfileByUserId = 'loadPlayerProfileByUserId',
  loadCountries = 'loadCountries',
  updateUser = 'updateUser',
  addUserGame = 'addUserGame',
  removeUserGame = 'removeUserGame',
  signUp = 'signUp',
  signIn = 'signIn',
  signInAdmin = 'signInAdmin',
  loadMyOrganizerUpcomingTournaments = 'loadMyOrganizerUpcomingTournaments',
  loadMyOrganizerActiveTournaments = 'loadMyOrganizerActiveTournaments',
  loadMyOrganizerFinishedTournaments = 'loadMyOrganizerFinishedTournaments',
  loadMyOrganizerCanceledTournaments = 'loadMyOrganizerCanceledTournaments',
  loadClanMembers = 'loadClanMembers',
  searchPlayers = 'searchPlayers',
  loadTopTournaments = 'loadTopTournaments',
  loadBlogs = 'loadBlogs',
  loadBlogById = 'loadBlogById',
  activateUser = 'activateUser',
  invitePlayers = 'invitePlayers',
  loadClanInvitations = 'loadClanInvitations',
  loadGameUpcomingTournaments = 'loadGameUpcomingTournaments',
  loadGameMainTournaments = 'loadGameMainTournaments',
  loadGameQualifyTournaments = 'loadGameQualifyTournaments',
  loadGameDetail = 'loadGameDetail',
  loadTopOrganizerMembers = 'loadTopOrganizerMembers',
  updateInvitation = 'updateInvitation',
  addClan = 'addClan',
  updateClan = 'updateClan',
  loadClanMember = 'loadUserClan',
  leaveClan = 'leaveClan',
  disbandClan = 'disbandClan',
  loadPlayers = 'loadPlayers',
  loadClans = 'loadClans',
  loadOrganizersPage = 'loadOrganizersPage',
  loadOrganizerRecentTournaments = 'loadOrganizerRecentTournaments',
  loadReviews = 'loadReviews',
  addReview = 'addReview',
  loadMyReview = 'loadMyReview',
  loadUnreadBlogsCount = 'loadUnreadBlogsCount',
  resolveFriendRequest = 'resolveFriendRequest',
  readBlogs = 'readBlogs',
  updateGame = ' updateGame',
  addGame = ' addGame',
  addBlog = 'addBlog',
  deleteBlog = 'deleteBlog',
  updateBlog = 'updateBlog',
  loadAdminUpcomingTournaments = 'loadAdminUpcomingTournaments',
  loadAdminActiveTournaments = 'loadAdminActiveTournaments',
  loadAdminFinishedTournaments = 'loadAdminFinishedTournaments',
  loadAdminCanceledTournaments = 'loadAdminCanceledTournaments',
  updateCaurosel = 'updateCarousel',
  sendMessage = 'sendMessage',
  loadPrivateChat = 'loadPrivateChat',
  loadPrivateChatMembers = 'loadPrivateChatMembers',
  createPrivateChat = 'createPrivateChat',
  connectToChat = 'connectToChat',
  disconnectFromChat = 'disconnectFromChat',
  loadTournamentChat = 'loadTournamentChat',
  loadChatMessages = 'loadChatMessages',
  sendChatMessage = 'sendChatMessage',
  loadDisputeChat = 'loadDisputeChat',
  loadUnreadChats = 'loadUnreadChats',
  chatMessageRead = 'chatMessageRead',
  loadNewMessage = 'loadNewMessage',
  loadChat = 'loadChat',
  deleteChat = 'deleteChat',
  leaveChat = 'leaveChat',
  addToChat = 'addToChat',
  blockMember = 'blockMember',
  unblockMember = 'unblockMember',
  loadPlayerBattlesAndLoyaltyPoints='loadPlayerBattlesAndLoyaltyPoints',
  loadFee = 'loadFee',
  updateFee = 'updateFee',
  loadChatFilteredUsers= 'loadChatFilteredUsers',
  createGroupChat = 'createGroupChat',
  loadChats = 'loadChats',
  loadAdmins = 'loadAdmins',
  loadPointsRewards = 'loadPointsRewards',
  updatePointsRewards = 'updatePointsRewards',
  enableAdmin = 'enableAdmin',
  disableAdmin = 'disableAdmin',
  createAdmin = 'createAdmin',
  loadOrganizerTournamentAndMembersAndIncome = 'loadOrganizerTournamentAndMembersAndIncome',
  loadTotalOnlineUserCount='loadTotalOnlineUserCount',
  updateTournamentCover = 'updateTournamentCover',
  notifiactionForDeleteAllParticipantsFromState = 'notifiactionForDeleteAllParticipantsFromState',
  isTyping = 'isTyping',
  DeleteTournamentChatFromState = 'DeleteTournamentChatFromState',
  userLoginHistory = 'userLoginHistory',
  reLoadUser = 'loadUser',
  reLoadImage = 'loadImage',
}


export function useRun<Args extends any[]>(
  type: OperationTypes,
  objectId: any,
  func: (...operationParameters: Args) => Promise<boolean>,
  description?: string
) {
  const dispatch = useDispatch()
  const isRunning = useSelector((state: ApplicationState) => makeGetIsOperationWithTypeRunning(() => ({ type, objectId }))(state))

  const execute = useCallback(
    async (...operationParameters: Args) => {
      if (isRunning) {
        return true
      }
      const operation: OperationObject = {
        id: getId(),
        type,
        objectId,
        status: OperationStatus.Running,
        canceled: false,
        description,
        timeStart: new Date(),
      }
      dispatch(SetOperationStatus(operation))
      let result = false
      try {
        result = await func(...operationParameters)
      } finally {
        if (result) {
          operation.status = OperationStatus.Success
        } else {
          operation.status = OperationStatus.Failed
        }
        operation.timeStop = new Date()
        dispatch(SetOperationStatus(operation))
      }
      return result
    },
    [isRunning, objectId]
  )

  return execute
}

export function useRunWithDispatch<Args extends any[]>(
  type: OperationTypes,
  objectId: any,
  func: (dispatch: Dispatch, ...operationParameters: Args) => Promise<boolean>,
  description?: string
) {
  const dispatch = useDispatch()
  const isRunning = useSelector((state: ApplicationState) => makeGetIsOperationWithTypeRunning(() => ({ type, objectId }))(state))

  const execute = useCallback(
    async (...operationParameters: Args) => {
      if (isRunning) {
        return true
      }
      const operation: OperationObject = {
        id: getId(),
        type,
        objectId,
        status: OperationStatus.Running,
        canceled: false,
        description,
        timeStart: new Date(),
      }
      dispatch(SetOperationStatus(operation))
      let result = false
      try {
        result = await func(dispatch, ...operationParameters)
      } finally {
        if (result) {
          operation.status = OperationStatus.Success
        } else {
          operation.status = OperationStatus.Failed
        }
        operation.timeStop = new Date()
        dispatch(SetOperationStatus(operation))
      }
      return result
    },
    [isRunning, objectId]
  )

  return execute
}

export function useRunWithResult<T, Args extends any[]>(
  type: OperationTypes,
  objectId: any,
  func: (...operationParameters: Args) => Promise<T>,
  description?: string
) {
  const dispatch = useDispatch()
  const isRunning = useSelector((state: ApplicationState) => makeGetIsOperationWithTypeRunning(() => ({ type, objectId }))(state))

  const execute = useCallback(
    async (...operationParameters: Args) => {
      if (isRunning) {
        return null
      }
      const operation: OperationObject = {
        id: getId(),
        type,
        objectId,
        status: OperationStatus.Running,
        canceled: false,
        description,
        timeStart: new Date(),
      }
      dispatch(SetOperationStatus(operation))
      let result: T = undefined
      try {
        result = await func(...operationParameters)
      } finally {
        if (result !== undefined) {
          operation.status = OperationStatus.Success
        } else {
          operation.status = OperationStatus.Failed
        }
        operation.timeStop = new Date()
        dispatch(SetOperationStatus(operation))
      }
      return result
    },
    [objectId]
  )

  return execute
}

export function useCancel(type: OperationTypes, objectId: any) {
  const dispatch = useDispatch()
  const operations = useSelector(getOperations).filter(o => o.type === type && o.objectId === objectId && !o.canceled)

  const cancelOperations = useCallback(() => {
    operations.forEach(operation => {
      operation.canceled = true
      dispatch(SetOperationStatus(operation))
    })
  }, [operations])

  return cancelOperations
}

// -----------------------------------------------------------
// --------------------- Run function ------------------------
// -----------------------------------------------------------
export async function run(
  type: OperationTypes,
  objectId: any,
  dispatch: Dispatch,
  getState: GetState,
  fun: () => Promise<boolean>,
  description?: string
): Promise<boolean> {
  const state = getState()
  const isRunning = makeGetIsOperationWithTypeRunning(() => ({ type, objectId }))(state)
  if (isRunning) {
    return true
  }
  const operation: OperationObject = {
    id: getId(),
    type,
    objectId,
    status: OperationStatus.Running,
    canceled: false,
    description,
    timeStart: new Date(),
  }
  dispatch(SetOperationStatus(operation))
  let result = false
  try {
    result = await fun()
  } finally {
    if (result) {
      operation.status = OperationStatus.Success
    } else {
      operation.status = OperationStatus.Failed
    }
    operation.timeStop = new Date()
    dispatch(SetOperationStatus(operation))
  }
  return result
}

// -----------------------------------------------------------
// --------------------- Cancel function ------------------------
// -----------------------------------------------------------
export function cancel(type: OperationTypes, objectId: any, dispatch: Dispatch, getState: GetState) {
  const state = getState()
  const operations = (state.operations?.list ?? []).filter(o => o.type === type && o.objectId === objectId && !o.canceled)
  operations.forEach(operation => {
    operation.canceled = true
    dispatch(SetOperationStatus(operation))
  })
}

export function cancelAllByType(type: OperationTypes, dispatch: Dispatch, getState: GetState) {
  const state = getState()
  const operations = (state.operations?.list ?? []).filter(o => o.type === type && !o.canceled)
  operations.forEach(operation => {
    operation.canceled = true
    dispatch(SetOperationStatus(operation))
  })
}

// --------------------------- Private -------------------------
let id = 0
function getId(): number {
  return id++
}
