import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { makeGetMatchParticipantsByMatchId } from 'store/selectors/match-participant-selectors'
import { ApplicationState } from 'store/types/common'
import { MatchParticipantModel } from 'store/types/match-participant-types'

interface UseMatchParticipantResult {
  matchParticipants: MatchParticipantModel[]
}

export const useMatchParticipants = (matchId?: number): UseMatchParticipantResult => {
  const getMatchParticipants = useMemo(makeGetMatchParticipantsByMatchId, [])
  const matchParticipants = useSelector((state: ApplicationState) => getMatchParticipants(state, matchId))

  return {
    matchParticipants,
  }
}
