import { useFilters, useUnionMatchParam } from 'components/_hooks'
import { ArticleHeader, FilterContainer, Filters, HeaderButton, Tabs, Select } from 'components/_shared'
import { TournamentModeEnum, TournamentModeOptions, TournamentStatusEnum, VenueTypeEnum } from 'consts'
import { ConsoleEnum, ConsoleTypeOptions } from 'consts/ConsoleType'
import { DateIntervalEnum, DateIntervalOptions } from 'consts/DateInterval'
import { TournamentEventTypeEnum } from 'consts/TournamentEventType'
import { TournamentSortFieldEnum, TournamentSortFieldOptions } from 'consts/TournamentSortField'
import React, { useState, useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { TournamentFilters } from 'store/logic/tournaments-logic'
import { getFilters } from 'store/selectors/pages/tournaments-page-selectors'
import { ApplicationState } from 'store/types/common'
import { IDictionary } from 'store/types/dictionary-types'
import { gameLabelExtractor, GameType, gameValueExtractor } from 'store/types/game-types'
import { filtersChanged } from 'store/types/pages/tournaments-page-types'
import { regionLabelExtractor, RegionType, regionValueExtractor } from 'store/types/region-types'
import { organizerLabelExtractor, organizerValueExtractor, PublicUserType } from 'store/types/user-types'
import MainTournaments from './Shared/MainTournaments'
import QualifyTournaments from './Shared/QualifyTournaments'
import UpcomingTournaments from './Shared/UpcomingTournaments'
import styles from './Tournament.module.scss'

export const tournamentsTabs = {
  upcoming: 'upcoming',
  qualifier: 'qualifier',
  main: 'main',
}


export type TournamentsTab = keyof typeof tournamentsTabs

interface TournamentsRouteProps {
  tab: TournamentsTab
  id?: string
}

export type EventsProps = RouteComponentProps<TournamentsRouteProps>

const getEventType = (tab: TournamentsTab) => {
  if (tab == tournamentsTabs.qualifier) {
    return TournamentEventTypeEnum.Qualifier
  }
  if (tab == tournamentsTabs.main) {
    return TournamentEventTypeEnum.Main
  }
  return null
}

export const Tournaments = ({ match }: EventsProps) => {

  const dispatch = useDispatch()
  const storedFilters = useSelector((state: ApplicationState) => getFilters(state))

  const tab = useUnionMatchParam(tournamentsTabs, match, m => m.tab, storedFilters.currentTab)

  const [filtersModalOpened, setFiltersModalOpened] = useState(false)

  const { games, gamesLoading, organizers, organizersLoading, regions, regionsLoading } = useFilters()

  const handleFilterClick = () => {
    setFiltersModalOpened(true)
  }
  const handleCloseFiltersModal = () => {
    setFiltersModalOpened(false)
  }


  const isOnlineEvent = storedFilters.isOnlineEvent
  const isLiveEvent = storedFilters.isLiveEvent

  const selectedOrganizers = storedFilters.selectedOrganizers
  const selectedConsoles = storedFilters.selectedConsoles
  const selectedGames = storedFilters.selectedGames
  const selectedMode = storedFilters.selectedMode
  const selectedInterval = storedFilters.selectedInterval
  const selectedSortField = storedFilters.selectedSortField
  const selectedRegion = storedFilters.selectedRegion

  useEffect(() => {
    if (tab !== storedFilters.currentTab) {
      dispatch(filtersChanged(
        {
          filters: {
            ...storedFilters,
            currentPage: 0,
            currentTab: tab,
          },
        }
      ))
    }
  }, [storedFilters, dispatch, tab])

  const onChangeIsOnlineEvent = () => {
    dispatch(filtersChanged(
      {
        filters: {
          ...storedFilters,
          isOnlineEvent: !storedFilters.isOnlineEvent,
          currentPage: 0,
        },
      }
    ))
  }

  const onChangeIsLiveEvent = () => {
    dispatch(filtersChanged(
      {
        filters: {
          ...storedFilters,
          isLiveEvent: !storedFilters.isLiveEvent,
          currentPage: 0,
        },
      }
    ))
  }


  const filters = useMemo<TournamentFilters | undefined>(() => {
    return {
      name: '',
      venueTypes: [isOnlineEvent && VenueTypeEnum.Online, isLiveEvent && VenueTypeEnum.Live].filter(x => x),
      organizersIds: selectedOrganizers.filter(x => x.Id !== 0).map(x => x.Id),
      gamesIds: selectedGames.map(x => x.Id),
      regionsIds: selectedRegion ? [selectedRegion.Id] : [],
      kombatlinkOrganizer: selectedOrganizers.some(x => x.Id === 0),
      tournamentStructures: [],
      tournamentMode: selectedMode?.id,
      consoles: selectedConsoles.map(x => x.id),
      tournamentSortField: selectedSortField?.id,
      dateInterval: selectedInterval?.id,
      tournamentStatuses: [TournamentStatusEnum.Pending, TournamentStatusEnum.Active],
      eventType: getEventType(tab),
    }
  }, [isLiveEvent, isOnlineEvent, selectedConsoles, selectedGames, selectedInterval?.id, selectedMode?.id, selectedOrganizers, selectedRegion, selectedSortField?.id, tab])

  const handleOrganizersChange = (value: PublicUserType[]) => {
    dispatch(filtersChanged(
      {
        filters: {
          ...storedFilters,
          selectedOrganizers: value,
          currentPage: 0,
        },
      }
    ))
  }
  const handleChangeConsoles = (value: IDictionary<ConsoleEnum>[]) => {
    dispatch(filtersChanged(
      {
        filters: {
          ...storedFilters,
          selectedConsoles: value,
          currentPage: 0,
        },
      }
    ))
  }
  const handleGamesChange = (value: GameType[]) => {
    dispatch(filtersChanged(
      {
        filters: {
          ...storedFilters,
          selectedGames: value,
          currentPage: 0,
        },
      }
    ))
  }

  const handleChangeMode = (value: IDictionary<TournamentModeEnum>) => {
    dispatch(filtersChanged(
      {
        filters: {
          ...storedFilters,
          selectedMode: value,
          currentPage: 0,
        },
      }
    ))
  }

  const handleChangeInterval = (value: IDictionary<DateIntervalEnum>) => {
    dispatch(filtersChanged(
      {
        filters: {
          ...storedFilters,
          selectedInterval: value,
          currentPage: 0,
        },
      }
    ))
  }

  const handleChangeSortField = (value: IDictionary<TournamentSortFieldEnum>) => {
    dispatch(filtersChanged(
      {
        filters: {
          ...storedFilters,
          selectedSortField: value,
          currentPage: 0,
        },
      }
    ))
  }

  const handleRegionChange = (value: RegionType) => {
    dispatch(filtersChanged(
      {
        filters: {
          ...storedFilters,
          selectedRegion: value,
          currentPage: 0,
        },
      }
    ))
  }


  return (
    <>
      <ArticleHeader text="Custom Tournament" secondText="Make Your Own Tournament Bracket Online" >
        <HeaderButton onClick={handleFilterClick} clasName="article__open-selects">Filter Options</HeaderButton>
      </ArticleHeader>
      {/* <div className="row">
        <h2 className={styles.h2}>
        Kombatlink is the leading platform where you can <b>make a tournament </b>to enjoy video games online. 
        We provide you with different options on <b>Custom Tournament Brackets</b> for <b>Tournament Maker</b>. 
        Here you can <b>Create Your Own Tournament</b> and play video games online. 
        We welcome <b>Tournament Organizer Online</b> and let them engage with their members. 
        You can explore different options from our list of <b>Tournament Brackets Online</b>. 
        We consistently make new updates to the <b>Online Tournament Bracket</b>. 
        Check out the <b>Video Game Tournament Bracket</b> and make your own event online. 
        Whether your members want to play solo or in a group, they will play <b>Ultimate Battle Tournament</b> online. 
          </h2>
      </div> */}
      <div className="row m-0--30">
        <div className="col-12">
          <Tabs>
            <Tabs.Menu>
              <Tabs.Link url="/tournaments/upcoming" active={tab === tournamentsTabs.upcoming}>Upcoming Tournaments</Tabs.Link>
              <Tabs.Link url="/tournaments/qualifier" active={tab === tournamentsTabs.qualifier}>Qualifier Tournament</Tabs.Link>
              <Tabs.Link url="/tournaments/main" active={tab === tournamentsTabs.main}>Main Event</Tabs.Link>
            </Tabs.Menu>
            <Tabs.CheckboxContainer>
              <Tabs.Checkbox checked={isOnlineEvent} onChange={onChangeIsOnlineEvent} id="OnlineEvent">Online event</Tabs.Checkbox>
              <Tabs.Checkbox checked={isLiveEvent} onChange={onChangeIsLiveEvent} id="LiveEvent">Live event</Tabs.Checkbox>
            </Tabs.CheckboxContainer>
          </Tabs>
        </div>
      </div>
      <Filters title="Filter Options" opened={filtersModalOpened} close={handleCloseFiltersModal} className={styles.article__selectsPopup}>
        <FilterContainer title="Organizer" className={styles.article__select}>
          <Select
            options={organizers}
            onChange={handleOrganizersChange}
            selected={selectedOrganizers}
            labelExtractor={organizerLabelExtractor}
            placeHolder="All"
            valueExtractor={organizerValueExtractor}
            withSearch
            type="multi"
            loading={organizersLoading}
          />
        </FilterContainer>
        <FilterContainer title="Consoles" className={styles.article__select}>
          <Select
            options={ConsoleTypeOptions}
            onChange={handleChangeConsoles}
            selected={selectedConsoles}
            labelExtractor={(option: IDictionary<ConsoleEnum>) => option.name}
            placeHolder="All"
            valueExtractor={(option: IDictionary<ConsoleEnum>) => option.id}
            type="multi"
          />
        </FilterContainer>
        <FilterContainer title="Game" className={styles.article__select}>
          <Select
            options={games}
            onChange={handleGamesChange}
            selected={selectedGames}
            labelExtractor={gameLabelExtractor}
            placeHolder="All Games"
            valueExtractor={gameValueExtractor}
            type="multi"
            loading={gamesLoading}
            withSearch
          />
        </FilterContainer>
        <FilterContainer title="Type" className={styles.article__select}>
          <Select
            options={TournamentModeOptions}
            onChange={handleChangeMode}
            selected={selectedMode}
            labelExtractor={(option: IDictionary<TournamentModeEnum>) => option.name}
            placeHolder="All"
            valueExtractor={(option: IDictionary<TournamentModeEnum>) => option.id}
            type="single"
          />
        </FilterContainer>
        <FilterContainer title="Select time" className={styles.article__select}>
          <Select
            options={DateIntervalOptions}
            onChange={handleChangeInterval}
            selected={selectedInterval}
            labelExtractor={(option: IDictionary<DateIntervalEnum>) => option.name}
            placeHolder="All"
            valueExtractor={(option: IDictionary<DateIntervalEnum>) => option.id}
            type="single"
          />
        </FilterContainer>
        <FilterContainer title="Sort by" className={styles.article__select}>
          <Select
            options={TournamentSortFieldOptions}
            onChange={handleChangeSortField}
            selected={selectedSortField}
            labelExtractor={(option: IDictionary<TournamentSortFieldEnum>) => option.name}
            placeHolder="Sort by"
            valueExtractor={(option: IDictionary<TournamentSortFieldEnum>) => option.id}
            type="single"
          />
        </FilterContainer>
        <FilterContainer title="Region" className={styles.article__select}>
          <Select
            options={regions}
            onChange={handleRegionChange}
            selected={selectedRegion}
            labelExtractor={regionLabelExtractor}
            valueExtractor={regionValueExtractor}
            type="single"
            loading={regionsLoading}
            placeHolder="All"
          />
        </FilterContainer>
      </Filters>

      {tab === tournamentsTabs.upcoming && (
        <UpcomingTournaments filters={filters} />
      )}
      {tab === tournamentsTabs.main && (
        <MainTournaments filters={filters} />
      )}
      {tab === tournamentsTabs.qualifier && (
        <QualifyTournaments filters={filters} />
      )}
    </>
  )
}
