import { makeActionCreator, ReduxAction } from './common'
import { MembersPagePlayersLoadedAction } from './pages/members-page-types'

export interface PlayerProfileType {
  UserId: number
  TotalMatches: number
  WinnedMatches: number
  TotalExperience: number
  Rating: number
  ReviewsCount: number
}

export interface PlayerProfileState {
  playerProfileIdByUserId: {
    [key: number]: PlayerProfileType
  }
}

interface PlayerProfileLoadedPayload {
  playerProfile: PlayerProfileType
}

export const PLAYER_PROFILE_LOADED = 'PLAYER_PROFILE_LOADED'
export type PlayerProfileLoadedAction = ReduxAction<typeof PLAYER_PROFILE_LOADED, PlayerProfileLoadedPayload>
export const playerProfileLoaded = makeActionCreator<PlayerProfileLoadedPayload>(PLAYER_PROFILE_LOADED)

export type KnownAction = PlayerProfileLoadedAction
  | MembersPagePlayersLoadedAction
