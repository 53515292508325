import React, { lazy } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
const CommonRoutes = lazy(() => import('./CommonRoutes'))
import AuthRoutes from './AuthRoutes'
import PolicyRoutes from './PolicyRoutes'
import { RouteWithoutLayout } from './Route/RouteWithoutLayout'
import { CommonPages } from 'components/_pages/common'
import { useSelector } from 'react-redux'
import { getAppOffline, getFeatures } from 'store/selectors/app-selectors'
import { useAppUser } from 'components/_hooks'

export const AppRoutes = () => {
  const offline = useSelector(getAppOffline)
  const features = useSelector(getFeatures)
  const appUser = useAppUser()

  if (offline) {
    return (
      <>
        <RouteWithoutLayout exact path="/" component={CommonPages.Offline} />
        <Route>
          <Redirect to="/" />
        </Route>
      </>
    )
  }

  if (features.LeadGeneration) {
    return (
      <>
        <AuthRoutes />
        <PolicyRoutes />
        <Route path="/" exact>
          <Redirect from="/" to="/register" />
        </Route>
      </>
    )
  }

  return (
    <Switch>
      <Route path="/*" exact component={CommonRoutes} />
    </Switch>
  )
}
