import { AspectRatioType, FileType, KlFileSelector } from 'components/_shared'
import React, { useCallback, useState } from 'react'
import { useRunUploadImage } from 'store/logic/image-logic'
import { ImageType, ImageTypeEnum, MediaType } from 'store/types/image-types'
import { useAlertModal } from '.'

export interface ImageUploaderParams {
  dropText: string
  recommendedText: string
  fileTypes: FileType[]
  saveImage?: (image: ImageType) => Promise<void>
  onUploaded?: (imageId: number) => void
  onChange?: (file: File, mediaType: MediaType) => void
  onCrop?: (file: File) => void
  catchUploadError?: boolean
  disabled?: boolean
  crop?: boolean
  imageId?: number
  aspectRatioType?: AspectRatioType
  getImageTypeCallback: (mediaType: MediaType) => ImageTypeEnum
  round?: boolean
}

export const useImageUploader = ({
  dropText,
  recommendedText,
  fileTypes,
  saveImage,
  onUploaded,
  onChange,
  catchUploadError,
  disabled,
  crop,
  imageId,
  aspectRatioType,
  onCrop,
  getImageTypeCallback,
  round,
}: ImageUploaderParams): [upload: () => Promise<ImageType | null>, fileSelector: JSX.Element] => {
  const [file, setFile] = useState<File>()
  const uploadImage = useRunUploadImage()
  const [locked, setLocked] = useState(false)
  const [errorModal, openErrorModal] = useAlertModal()
  const [imageType, setImageType] = useState<ImageTypeEnum>()

  const upload = useCallback(async () => {
    let image: ImageType | null | undefined
    if (imageId) {
      return null
    }
    setLocked(true)
    try {
      image = await uploadImage(file as File, imageType as ImageTypeEnum)
      if (onUploaded) {
        onUploaded((image as ImageType).Id)
      }
      if (saveImage) {
        await saveImage(image as ImageType)
      }
    } catch (error) {
      if (catchUploadError) {
        let message = (error as Error).message
        message = message === 'Failed to fetch' ? 'An error has occurred. Please reload the page.' : message

        openErrorModal(message)
      } else {
        throw error
      }
    } finally {
      setLocked(false)
    }
    return image
  }, [catchUploadError, file, imageId, imageType, onUploaded, openErrorModal, saveImage, uploadImage])

  const handleChange = (value?: File, mediaType?: MediaType) => {
    if (mediaType) {
      const newImageType = getImageTypeCallback(mediaType)
      setImageType(newImageType)
    }
    setFile(value)
    if (onChange && value && mediaType) {
      onChange(value, mediaType)
    }
  }

  const handleOnCrop = (file: File) => {
    setFile(file)
    if (onCrop) {
      onCrop(file)
    }
  }
  
  const fileSelector = (
    <>
      <KlFileSelector
        onChange={handleChange}
        file={file as File}
        dropText={dropText}
        fileTypes={fileTypes}
        recommendedText={recommendedText}
        disabled={locked || disabled}
        crop={crop}
        imageId={imageId}
        aspectRatioType={aspectRatioType}
        onCrop={handleOnCrop}
        round={round}
      />
      {errorModal}
    </>
  )

  return [upload, fileSelector]
}
