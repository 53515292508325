import { ExecuteAPIGet } from '../server-utils'
import { EndPoints } from '../endpoints'
import { TicketSaleViewModel } from '../../models/ticket-sale-viewmodel'

export const TournamentTicketUtils = {
  TicketSale: async function TicketSale(duration: string, organizerId?: number) {
    if (organizerId == undefined) {
      organizerId = -1
    }
    return await ExecuteAPIGet<TicketSaleViewModel[]>(EndPoints.TournamentTicket.TicketSale, `duration=${duration}&organizerId=${organizerId}`)
  },
  UpdateScanTicketCount: async function UpdateScanTicketCount(tournamentTicketQuantityId: number) {
    return await ExecuteAPIGet<boolean>(EndPoints.TournamentTicket.UpdateScanTicketCount, `tournamentTicketQuantityId=${tournamentTicketQuantityId}`)
  },
}
