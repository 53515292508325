import { Modal, ModalType, ModalWindowAnimationType } from 'components/_modals'
import { useMatchSettingsPermission } from 'components/_hooks'
import { LoadingBox } from 'components/_shared/Loading'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { lineUpsActionCreators } from 'store/logic/line-ups-logic'
import { loadMatchParticipants } from 'store/logic/match-participant-logic'
import { matchSettingsActionCreators, useSetMatchSettings } from 'store/logic/match-settings-logic'
import { makeGetMatch } from 'store/selectors/match-selectors'
import { getMatchSettings, makeGetIsMatchSettingsLoading } from 'store/selectors/match-settings-selectors'
import { ApplicationState, Permission } from 'store/types/common'
import { MatchStatusEnum } from 'store/types/match-types'


interface OwnProps {
  onClose: CallableFunction
  matchId: number
  isAnimated?: boolean
  isShow?: boolean
}

const LobbyDetailModal = ({onClose, matchId, isAnimated, isShow}: OwnProps) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(matchSettingsActionCreators.loadMatchSettings(matchId))    
  }, [dispatch, matchId])
  
  useEffect(() => {
    dispatch(loadMatchParticipants(matchId))
  },[dispatch, matchId])

  const getMatch = useMemo(makeGetMatch, [] )
  const match = useSelector((state: ApplicationState) => getMatch(state, matchId))
  const getIsMatchSettingsLoading = useMemo(makeGetIsMatchSettingsLoading, [])
  const isMatchSettingsLoading = useSelector((state: ApplicationState) => getIsMatchSettingsLoading(state, matchId))

  useEffect(() => {
    if (match?.TournamentDetailId) {
      dispatch(lineUpsActionCreators.loadLineUps(match.TournamentDetailId))
    }
  },[dispatch, match?.TournamentDetailId])

  const matchSettings = useSelector((state: ApplicationState) => getMatchSettings(state, matchId))
    
  const matchSettingsPermission = useMatchSettingsPermission(matchId)

  const isReadOnly = match?.Status == MatchStatusEnum.Finished || matchSettingsPermission !== Permission.write

  const [roomName, setRoomName] = useState(matchSettings?.RoomName || '')
  const [password, setPassword] = useState(matchSettings?.Password || '')

  React.useEffect(() => {
    if (matchSettings) {
      setRoomName(matchSettings.RoomName || '')
      setPassword(matchSettings.Password || '')
    }
  }, [matchSettings])

  const setSettings = useSetMatchSettings(matchId)
  const handleSetSettings = () => setSettings(roomName, password)

  const isModalValid = !!roomName.trim()


  return (
    <Modal 
      hideCancel 
      hideOk={isReadOnly}       
      allowOk={!isReadOnly && !isMatchSettingsLoading && isModalValid}
      okButtonText="Save" 
      buttonColor="blue"  
      onClose={onClose} 
      className="p-5" 
      asyncOperation={handleSetSettings} 
      type={isAnimated && ModalType.animated || undefined}
      animationType={isAnimated && ModalWindowAnimationType.next || undefined}
      show={isShow} 
    >
      <Modal.Header>Lobby Detail</Modal.Header>
      <LoadingBox size="big" loading={isMatchSettingsLoading}>
        {matchSettingsPermission == Permission.write && (
          <>
            <Modal.SubHeader>Provide the credentials to the private room for this match here. If none is required then just fill in the two fields below with N/A</Modal.SubHeader>
            <Modal.Hr />
          </>
        )}       
        <Modal.SubHeader>Room name</Modal.SubHeader>
        <Modal.Input placeholder={isReadOnly ? '' : 'Enter room name here...'} onChange={setRoomName} value={roomName} disabled={isReadOnly} />
        <Modal.SubHeader>Password</Modal.SubHeader>
        <Modal.Input placeholder={isReadOnly ? '' : 'Enter password here...'} onChange={setPassword} value={password} disabled={isReadOnly} />
      </LoadingBox>
    </Modal>
  )
}

export default LobbyDetailModal
