import { useMemo } from 'react'
import { match, useParams } from 'react-router-dom'
import { Dictionary, useUnion } from './useUnion'

export const getMatchParam = <TParams, TResult>(m: match<TParams>, getParam: (m: TParams) => TResult, defaultValue?: TResult) => {
  return getParam(m.params) || defaultValue
}

export const getIntMatchParam = <TParams>(m: match<TParams>, getParam: (m: TParams) => string, defaultValue?: string) => {
  const param = getMatchParam(m, getParam, defaultValue)
  return parseInt(param) || undefined
}

export const useMatchParam = <TParams, TResult>(m: match<TParams>, getParam: (m: TParams) => TResult, defaultValue?: TResult) => {
  const param = useMemo(() => {
    return getMatchParam(m, getParam, defaultValue)
  }, [getParam, m, defaultValue])

  return param
}

export const useUnionMatchParam = <TParams, TResult>(unionValues: Dictionary, m: match<TParams>, getParam: (m: TParams) => TResult, defaultValue?: TResult) => {
  const param = useMemo(() => {
    return getMatchParam(m, getParam, defaultValue)
  }, [getParam, m, defaultValue])
  return useUnion(unionValues, param, defaultValue)
}

export const useIntMatchParam = <TParams>(m: match<TParams>, getParam: (m: TParams) => string, defaultValue?: string) => {
  const param = useMemo(() => {
    return getMatchParam(m, getParam, defaultValue)
  }, [getParam, m, defaultValue])

  return parseInt(param) || undefined
}

export const useIntRouteParam = <RouteParams extends { [K in keyof RouteParams]?: string }>(getParam: (params: RouteParams) => string, defaultValue?: number): number | undefined => {
  const params = useParams<RouteParams>()
  const param = getParam(params)
  return parseInt(param) || defaultValue || undefined
}

export const useRouteParam = <RouteParams extends { [K in keyof RouteParams]?: string }>(getParam: (params: RouteParams) => string, defaultValue?: string): string | undefined => {
  const params = useParams<RouteParams>()
  const param = getParam(params)
  return param || defaultValue || undefined
}

export const useUnionRouteParam = <RouteParams, TResult>(unionValues: Dictionary, getParam: (m: RouteParams) => TResult, defaultValue?: TResult) => {
  const params = useParams<RouteParams>()
  const param = getParam(params)
  return useUnion(unionValues, param, defaultValue)
}
