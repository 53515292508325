import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { getAppUser } from 'store/selectors/app-selectors'
import { getIsAdmin } from 'store/types/user-types'
import Cropper from 'react-cropper'
import { MediaType, ImageType } from 'store/types/image-types'
import { Img } from 'components/_shared'
import { Video } from 'components/_shared/Img'
import { Button } from '../Button'
import { Dropzone, Zoom } from './_shared'
import { Theme } from 'store/types/common'

const getFileType = (file: File): MediaType => {
  return file.type.includes('image') ? MediaType.Image : MediaType.Video
}

interface FileSelectorProps {
  fileTypes: MediaType[]
  onChange: (file?: File, dataUrl?: string, type?: MediaType,isReset?:boolean) => void
  maxVideoDuration?: number
  initialValue?: ImageType
  cropperWidth: string
  recommendedText?: string
  theme?: Theme
  disabled?: boolean
}

export const FileSelector = ({
  fileTypes,
  onChange,
  maxVideoDuration,
  initialValue,
  cropperWidth,
  recommendedText,
  theme = 'dark',
  disabled,
}: FileSelectorProps) => {
  const user = useSelector(getAppUser)
  const isVideoAvailable = useMemo(() => {
    return fileTypes.includes(MediaType.Video) && (user.IsPremium || getIsAdmin(user.Role))
  }, [fileTypes, user.IsPremium, user.Role])
  const [cropper, setCropper] = useState<Cropper>()

  const [rawFile, setRawFile] = useState<File>()
  const [rawFileDataUrl, setRawFileDataUrl] = useState<string>()
  const [croppedFileDataUrl, setCroppedFileDataUrl] = useState<string>()
  const [image, setImage] = useState(initialValue)

  const [zoom, setZoom] = useState(1)

  const rawMediaType = useMemo<MediaType | undefined>(() => {
    if (!rawFile) {
      return undefined
    }

    return getFileType(rawFile)
  }, [rawFile])

  const showImage = image !== undefined
  const showCroppedImage = !showImage && croppedFileDataUrl !== undefined
  const showCropper = !showImage && !showCroppedImage && rawFileDataUrl !== undefined
  const showDropzone = !showImage && !showCroppedImage && !showCropper

  //#region handlers
  const reset = () => {
    setImage(undefined)
    setRawFile(undefined)
    setRawFileDataUrl(undefined)
    setCroppedFileDataUrl(undefined)
    setZoom(50)
    onChange(undefined,undefined,undefined,true)
  }

  const handleCrop = () => {
    if (rawFile) {
      const dataUrl = cropper.getCroppedCanvas().toDataURL()
      setCroppedFileDataUrl(dataUrl)
      setRawFileDataUrl(undefined)

      cropper.getCroppedCanvas().toBlob(blob => {
        const file = new File([blob], rawFile.name, { type: rawFile.type })
        onChange(file, dataUrl, rawMediaType)
      })
    }
  }

  const handleResetClick = () => {
    reset()
  }

  const handleVideoLoaded = (e: React.SyntheticEvent<HTMLVideoElement, Event>) => {
    if (maxVideoDuration && e.currentTarget.duration > maxVideoDuration) {
      reset()
      let videoDurationMessage=''
      if(maxVideoDuration > 60){
        videoDurationMessage = parseInt(((maxVideoDuration-1)/60).toString()).toString() +' minutes'
      }
      else{
        videoDurationMessage = (maxVideoDuration -1).toString()+' seconds'
      }
      alert('Please upload a video which is not longer than '+videoDurationMessage)
    } else {
      onChange(rawFile, croppedFileDataUrl, rawMediaType)
    }
  }

  const handleCropperInitialized = useCallback((value: Cropper) => {
    setCropper(value)
  }, [])
  //#endregion

  useEffect(() => {
    if (cropper) {
      const ratio = zoom / 50
      cropper.zoomTo(ratio)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoom])

  useEffect(() => {
    if (initialValue) {
      setImage(initialValue)
    }
  }, [initialValue])

  return (
    <>
      {showDropzone &&
        <>
          <Dropzone
            fileTypes={fileTypes}
            isVideoAvailable={isVideoAvailable}
            onChange={onChange}
            onCroppedFileDataUrlChange={setCroppedFileDataUrl}
            onRawFileChange={setRawFile}
            onRawFileDataUrlChange={setRawFileDataUrl}
          />
          {recommendedText &&
            <div className="row">
              <div className="crop__recommended-size">
                <div className="crop__recommended-size-icon" />
                <div>{recommendedText}</div>
              </div>
            </div>
          }
        </>
      }
      {(showCropper || showCroppedImage || showImage) &&
        <section className="crop__preview preview--visible">
          <div className="container-fluid">
            <div className="row">
              {showCropper && <Cropper
                style={{ height: 400, width: cropperWidth }}
                initialAspectRatio={1300 / 600}
                aspectRatio={1300 / 600}
                src={rawFileDataUrl.toString()}
                viewMode={1}
                guides={false}
                minCropBoxHeight={100}
                minCropBoxWidth={325}
                background={false}
                responsive={true}
                checkOrientation={false}
                onInitialized={handleCropperInitialized}
              />}
              {(showCroppedImage && rawMediaType === MediaType.Image || showImage && image.MediaType === MediaType.Image) &&
                <Img
                  src={showCroppedImage ? croppedFileDataUrl : undefined}
                  imageId={showImage ? image.Id : undefined}
                  style={{ height: 'auto', width: '100%' }}
                />
              }
              {(showCroppedImage && rawMediaType === MediaType.Video || showImage && image.MediaType === MediaType.Video) &&
                <Video
                  src={showCroppedImage ? croppedFileDataUrl : undefined}
                  imageId={showImage ? image.Id : undefined}
                  width="100%"
                  height="360px"
                  controls
                  onLoadedMetadata={handleVideoLoaded}
                />
              }
            </div>
            <div className="row d-flex align-items-center mt-3">
              {showCropper &&
                <>
                  <Zoom value={zoom} onChange={setZoom} theme={theme} />
                  <Button onClick={handleCrop} color="blue" filled className="mx-3 btn-done">Done</Button>
                </>
              }
              <Button disabled={disabled} onClick={handleResetClick} className="btn-reset" color="darkGrey" filled>Reset</Button>
            </div>
          </div>
        </section>
      }
    </>
  )
}
