import React from 'react'
import { cn } from 'utils'
import styles from '../MemberCard.module.scss'

export interface ColumnProps {
  className: string
  label: string
  value: string
}

export const Column = ({ className, label, value }:  ColumnProps) => {
  return (
    <div className={cn('col-auto', className)}>
      <div className="row">
        <div className={cn('col-12', styles.memberHeader)}>
          {label}
        </div>
        <div className={cn('col-12', styles.memberInfo)}>
          {value}
        </div>
      </div>
    </div>
  )
}
