import React from 'react'
import { RouteWithoutLayout } from './Route/RouteWithoutLayout'
import { AuthPages } from 'components/_pages/auth'
import { LoginRoute } from './Route/LoginRoute'
import { useSelector } from 'react-redux'
import { getFeatures } from 'store/selectors/app-selectors'

export const AuthRoutes = () => {
  const features = useSelector(getFeatures)

  if (features.LeadGeneration) {
    return (
      <>
        <LoginRoute exact path="/register" component={AuthPages.SignUp} />
        <LoginRoute oldCss exact path="/register/success" component={AuthPages.RegisterSuccess} />
      </>
    )
  }
  
  return (
    <>
      <LoginRoute oldCss exact path="/login" component={AuthPages.Login} />
      <LoginRoute exact path="/register" component={AuthPages.SignUp} />
      <LoginRoute oldCss exact path="/register/success" component={AuthPages.RegisterSuccess} />
      <RouteWithoutLayout oldCss exact path="/password-recovery" component={AuthPages.PasswordRecovery} />
      <RouteWithoutLayout oldCss exact path="/activate/:hash" component={AuthPages.Activate} />
    </>
  )
}

export default AuthRoutes
