import { useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import { makeGetIsMyReviewRequested } from 'store/selectors/review-selectors'
import { GetState, PaginationModel } from 'store/types/common'
import { myReviewLoaded, Review, reviewAdded, reviewsLoaded, ReviewsModel } from 'store/types/review-types'
import { fetchApi } from 'utils'
import { OperationTypes, run, useRun } from './operations-logic'
import { useReloadPlayerProfileByUserId } from './user-logic'

interface AddReviewModel {
  userId: number
  rating: number
  text: string
}

export const loadReviews = (userId: number, pagination: PaginationModel) => async (dispatch: Dispatch, getState: GetState) => {
  await run(OperationTypes.loadReviews, userId, dispatch, getState, async () => {
    try {
      const model =  await fetchApi<ReviewsModel>(`review/${userId}/list`, 'GET', pagination)
      dispatch(reviewsLoaded(model))
    } catch (error) {
      return false
    }
    return true
  })
}

const getMyReviewRequested = makeGetIsMyReviewRequested()

export const loadMyReview = (userId: number) => async(dispatch: Dispatch, getState: GetState) => {
  if (getMyReviewRequested(getState(), { userId })) {
    return
  }
  await run(OperationTypes.loadMyReview, userId, dispatch, getState, async () => {
    try {
      const review =  await fetchApi<Review>(`review/${userId}/myreview`, 'GET')
      dispatch(myReviewLoaded({ review, userId }))
    } catch (error) {
      return false
    }
    return true
  })
}

export const useAddReview = (userId: number) => {
  const reloadPlayerProfileByUserId = useReloadPlayerProfileByUserId(userId)
  const dispatch = useDispatch()
  return useRun(OperationTypes.addReview, null, async (model: AddReviewModel) => {
    const review =  await fetchApi<Review>('review', 'POST', model)
    await reloadPlayerProfileByUserId()
    dispatch(reviewAdded({ userId, review }))
    return true
  })
}
