import { ContainerName, useGetImageSize } from 'components/_hooks'
import React from 'react'
import { cn } from 'utils'
import { Button, Img, KlLink } from '..'
import styles from './ProfileCard.module.scss'
import { ProfileCardCheckbox } from './ProfileCardCheckbox'

interface ProfileCardProps {
  name: string
  label: string
  link: string
  imageId?: number
  src?: string
  className?: string
  withCheck?: boolean
  withButtons?: boolean
  checked?: boolean
  elementId?: number
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onDecline?: () => void
  onAccept?: () => void
  disabled?: boolean
  fullSize?: boolean
  containerName?:ContainerName
}

export const ProfileCard = ({ 
  imageId, 
  src, 
  name, 
  link, 
  label, 
  className, 
  withCheck, 
  checked, 
  elementId, 
  onChange, 
  withButtons, 
  onDecline, 
  onAccept,
  disabled,
  fullSize,
  containerName
}: ProfileCardProps) => {
  let style = cn(styles.card, className, 'flex-column')
  style = fullSize ? style : cn(style, styles.fixedSize)
  const imageSize=useGetImageSize(containerName)
  return (
    <>
      <div className={style}>
        <div className="d-flex">
          <KlLink disabled={disabled} to={link}>
            <Img parentContainerSize={imageSize} imageId={imageId} src={src} placeholder="user" className={styles.avatar} />
          </KlLink>
          <div className="d-flex flex-column ml-2 flex-fill">
            <div className="d-flex justify-content-between">
              <KlLink disabled={disabled} to={link} className={styles.name}>{name}</KlLink>
              {withCheck && <ProfileCardCheckbox disabled={disabled} checked={checked} id={`${elementId}`} onChange={onChange}  /> }
            </div>
            
            <KlLink disabled={disabled} to={link} className={cn(styles.detail, 'align-items-center d-flex flex-fill justify-content-center mt-2 px-1')}>{label}</KlLink>
          </div>
        </div>
        {withButtons && 
          <div className="d-flex mt-2">
            <Button disabled={disabled} onClick={onAccept} filled color="green" className="mr-1">Accept</Button>
            <Button disabled={disabled} onClick={onDecline} filled color="red" className="ml-1">Decline</Button>
          </div>
        }
      </div>
    </>
  )
}
