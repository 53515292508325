import React from 'react'
import { useHistory } from 'react-router'
import styles from './ChatBackButton.module.scss'

export interface ChatBackButtonProps {
  onClick?: CallableFunction
}

export const ChatBackButton = ({onClick}:  ChatBackButtonProps) => {
  const history = useHistory()

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault()
    if (!onClick) {
      history.goBack()
    } else {
      onClick()
    }
  }

  return (
    <div className="col-auto p-0">
      <a className={styles.chatHeaderBack} href="#" onClick={handleClick}></a>
    </div>
  )
}
