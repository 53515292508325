import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import { cn } from 'utils'
import styles from './HorizontalScroll.module.scss'


interface HorizontalScrollProps {
  children?: React.ReactNode
  leftPosition: number
  onChangeLeftPosition: (number) => void
  withoutGradient?: boolean 
  hasSrollRight?: boolean
}

export const HorizontalScroll = ({ children, leftPosition, onChangeLeftPosition, withoutGradient, hasSrollRight = false }: HorizontalScrollProps) => {
  const ref = React.useRef<HTMLDivElement>()
  const [scrollStep, setScrollStep] = React.useState((ref?.current && Math.round(ref.current.clientWidth / 2)) || 500)

  const [isScrollLeft, setIsScrollLeft] = React.useState(false)
  const [isScrollRight, setIsScrollRight] = React.useState(hasSrollRight)

  useMemo(() => {
    if (ref.current) {
      ref.current.scrollTo({
        top: 0,
        left: leftPosition,
        behavior: 'smooth',
      })

      if (leftPosition > 0) {
        if (!isScrollLeft) {
          setIsScrollLeft(true)
        }
      } else {
        if (isScrollLeft) {
          setIsScrollLeft(false)
        }
      }

      if (leftPosition > 0 && ref.current.scrollWidth - leftPosition < ref.current.offsetWidth) {
        setIsScrollRight(false)
      } else {
        setIsScrollRight(true)
      }
    }
  }, [isScrollLeft, leftPosition, ref.current?.scrollWidth])

  const onMoveRight = () => {
    const nextPosition = ref.current.scrollLeft + scrollStep
    if (nextPosition > ref.current.scrollWidth) {
      onChangeLeftPosition(ref.current.scrollWidth)
    } else {
      if (ref.current.scrollWidth - leftPosition > ref.current.offsetWidth) {
        onChangeLeftPosition(nextPosition)
      } else {
        setIsScrollRight(false)
      }
    }
  }

  const onMoveLeft = () => {
    const nextPosition = ref.current.scrollLeft - scrollStep
    onChangeLeftPosition(nextPosition < 0 ? 0 : nextPosition)
  }

  useEffect(() => {
    if (ref && ref.current.clientWidth) {
      setScrollStep(Math.round(ref.current.clientWidth / 2))
    }
  }, [ref?.current?.clientWidth])

  return (
    <div className={cn(styles.content, withoutGradient && styles.inernal)}>
      <div className={cn(styles.scrollBlock_left, !withoutGradient && cn(styles.scrollBlock__arrow__gradient), isScrollLeft && 'd-block')}>
        <div className={styles.scrollBlock_left_inn}>
          <div className={styles.scrollBlock_right_arrow} onClick={onMoveLeft}></div>
        </div>
      </div>
      <div className={cn(styles.scrollBlock_right,  !withoutGradient && cn(styles.scrollBlock__arrow__gradient), isScrollRight && 'd-block')}>
        <div className={styles.scrollBlock_right_inn}>
          <div className={styles.scrollBlock_right_arrow} onClick={onMoveRight}></div>
        </div>
      </div>
      <div id="scroll-block" className={styles.scrollBlock} ref={ref}>
        <div className={styles.scrollBlock_inn}>
          {children}
        </div>
      </div>
    </div>
  )
}

interface NewHorizontalScrollProps {
  children: ReactNode
  left: number
  onChange: (value) => void
  className?: string
  renderLeftArrow: (onClick: () => void) => ReactNode
  displayLeftArrow: boolean
  onDisplayLeftArrowChange: (value: boolean) => void
  renderRightArrow: (onClick: () => void) => ReactNode
  displayRightArrow: boolean
  onDisplayRightArrowChange: (value: boolean) => void
}

export const NewHorizontalScroll = ({
  children,
  left,
  onChange,
  className,
  renderLeftArrow,
  renderRightArrow,
  displayLeftArrow,
  onDisplayLeftArrowChange,
  displayRightArrow,
  onDisplayRightArrowChange,
}: NewHorizontalScrollProps) => {
  const ref = React.useRef<HTMLDivElement>()
  const [step, setStep] = useState(0)

  useEffect(() => {
    ref.current.scrollTo({
      left: left,
      behavior: 'smooth',
    })

    const { clientWidth, scrollWidth } = ref.current
    
    onDisplayLeftArrowChange(left > 0)
    onDisplayRightArrowChange(left + clientWidth < scrollWidth)
  }, [left, step, onDisplayLeftArrowChange, onDisplayRightArrowChange])

  useEffect(() => {
    setStep(Math.round(ref.current.clientWidth / 2))
  }, [setStep])

  const handleLeftArrowClick = () => {
    if (left - step >= 0) {
      onChange(left - step)
    }
  }

  const handleRightArrowClick = () => {
    const { clientWidth, scrollWidth } = ref.current
    if (left + clientWidth < scrollWidth) {
      onChange(left + step)
    }
  }

  return (
    <>
      {displayLeftArrow && renderLeftArrow(handleLeftArrowClick)}
      <div className={cn(className)} ref={ref}>
        {children}
      </div>
      {displayRightArrow && renderRightArrow(handleRightArrowClick)}
    </>
  )
}
