import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { makeGetClan, makeGetIsClanLoading } from 'store/selectors/clan-selectors'
import { ClanType } from 'store/types/clan-types'
import { ApplicationState } from 'store/types/common'
import { ImageType } from 'store/types/image-types'
import { useImage } from './useImage'
interface UseClanResult {
  clan?: ClanType
  image?: ImageType
  isClanLoading: boolean
}

export const useClan = (clanId?: number): UseClanResult => {
  const getClan = useMemo(makeGetClan, [])
  const getIsClanLoading = useMemo(makeGetIsClanLoading, [])
  const clan = useSelector((state: ApplicationState) => getClan(state, clanId))
  const isClanLoading = useSelector((state: ApplicationState) => getIsClanLoading(state, { clanId }))
  const { image } = useImage(clan?.AvatarId)

  return {
    clan,
    image,
    isClanLoading,
  }
}
