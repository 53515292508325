import { LoadingBox, Pagination } from 'components/_shared'
import TournamentCardV2 from 'components/_shared/TournamentCard/TournamentCardV2'
import { TournamentBannerDisplayFlag } from 'components/_shared/TournamentCard/_shared/TournamentFlashingBanner'
import React, { useCallback, useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadGameMainTournaments, TournamentFilters } from 'store/logic/tournaments-logic'
import { getAllGameMainTournamentsCount, getGameMainTournamentsIds, getGameMainTournamentsLoading } from 'store/selectors/tournaments-selectors'

interface Props {
  filters: TournamentFilters
}
const pageSize = 8

const GameTournamentsMain = ({filters}: Props) => {
 
  const dispatch = useDispatch()

  const [page, setPage] = useState(0)

  const tournaments = useSelector(getGameMainTournamentsIds)
  const allTournamentsCount = useSelector(getAllGameMainTournamentsCount)
  const isTournamentsLoading = useSelector(getGameMainTournamentsLoading)

  const loadTournaments = useCallback((filters: TournamentFilters, pageNumber: number) => {  
    dispatch(loadGameMainTournaments(filters, {
      page: pageNumber,
      pageSize,
    }))  
  }, [dispatch])

  useEffect(() => {
    setPage(0)
    loadTournaments(filters, 0) 
  }, [dispatch, filters, loadTournaments, page])

  const onChangePage = (pageNumber: number) => {
    setPage(pageNumber)
    loadTournaments(filters, pageNumber)
  }

  return (
    <>
      <LoadingBox size="big" loading={isTournamentsLoading}>
        <div className="row m-0--31">
          {tournaments.map(id =>
            <TournamentCardV2 key={id} tournamentId={id} displayBannerOption={TournamentBannerDisplayFlag.Running} />
          )}          
        </div>
      </LoadingBox>
      <Pagination page={page} itemsPerPage={pageSize} allItemsCount={allTournamentsCount} onChangePage={onChangePage} />
    </>
  )
}

export default GameTournamentsMain
