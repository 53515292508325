import * as React from 'react'
import { BillingOptionUtils } from '../../../server/utils/billing-option-utils'
import { Kl_OrganizerPayout } from '../../../models/billing-option-model'
import { UserModel } from '../../../models/user-models'
import { StripeUtils } from '../../../server/utils/stripe-utils'
import { StripeAccountViewModel } from '../../../models/stripe-account-viewmodel'
import { TransactionUtils } from '../../../server/utils/transaction-utils'
import { AdminRedemptionUtils } from '../../../server/utils/admin-redemption-utils'
import { kl_AdminRedemption } from '../../../models/admin-redemption-model'
import { withStripe } from 'components/_shared/withStripe'
import SelectComponent from 'components/_shared/SelectComponent'
import { CryptoCurrencyData } from '../../../consts/CryptoCurrencyType'
import styles from './../Transactions.module.scss'
import { round2Places } from 'utils'
import StorageUtils from 'server/utils/storage-utils'
import { GeneralUtils } from 'server/utils/general-utils'
import { RequestType } from 'consts/RequestType'
import BillingOptionDailog from 'components/BillingOptionPlayer/BillingOptionDailog'
import { LocationUtils } from 'server/utils/location-utils'
interface State {
    cheque: boolean
    eTransfer: boolean
    directDebit: boolean
    term_condtion: boolean
    organizerBillingOption: Kl_OrganizerPayout
    totalAmount: number
    amount: string
    serviceCharges: number
    total: number
    fullAmount: boolean
    uploadFileData: any
    coin: boolean
    coinTotalAmount: number
    CoinType: number
    convertAmount: any
    CurrentCurrency: string
    currencyData: any
    selectedCurrency: string
    userCountry: string
    userCountryCode: string
    userCurrency: string
    currencySymbol: string
    ExchangeRate?: number
    BaseCurrencyExchangeRate: number
    showDialouge: boolean
}

class WithdrawTransactions extends React.Component<any, State> {
    constructor(props: any) {
        super(props)
        this.state = {
            cheque: false,
            directDebit: true,
            eTransfer: false,
            coin: false,
            term_condtion: false,
            organizerBillingOption: Object(),
            totalAmount: 0,
            amount: '0',
            serviceCharges: 1,
            total: 0,
            fullAmount: false,
            uploadFileData: '',
            coinTotalAmount: 0,
            CoinType: 1,
            convertAmount: 0,
            CurrentCurrency: 'bitcoin',
            selectedCurrency: '1',
            currencyData: [],
            userCountry: '',
            userCountryCode: '',
            userCurrency: '',
            currencySymbol: '',
            ExchangeRate: 1,
            BaseCurrencyExchangeRate: 1,
            showDialouge: false
        }
        this.organizerBillingHandler = this.organizerBillingHandler.bind(this)
        this.getBillingOption = this.getBillingOption.bind(this)
        this.handlePayment = this.handlePayment.bind(this)
        this.getStripeToken = this.getStripeToken.bind(this)
        this.withdrawlBalance = this.withdrawlBalance.bind(this)
        this.fileHandler = this.fileHandler.bind(this)
        this.organizerAmount = this.organizerAmount.bind(this)
        this.saveAdminRedemption = this.saveAdminRedemption.bind(this)
        this.convertCurrencyToUSD = this.convertCurrencyToUSD.bind(this)
        this.handleCurrencyChange = this.handleCurrencyChange.bind(this)
        this.getcurrencyFromCountry = this.getcurrencyFromCountry.bind(this)
        this.getCountryCurrencyDataById = this.getCountryCurrencyDataById.bind(this)
        this.getExchangeRateFromCurrencySymbol = this.getExchangeRateFromCurrencySymbol.bind(this)
        this.intializeCurrencies = this.intializeCurrencies.bind(this)
        this.checkStripeAccountExists = this.checkStripeAccountExists.bind(this)
        this.enableUserLocation = this.enableUserLocation.bind(this)
    }
    async componentDidMount() {

        await this.checkStripeAccountExists()
        //this.organizerAmount()
        this.intializeCurrencies()
    }
    async checkStripeAccountExists() {
        const { directDebit } = this.state
        const user = StorageUtils.getUser()
        if (user && !user.IsVerified && directDebit) {
            document.getElementById('modal_stripe_verification').className = 'modal-container modal-container--opened'
            await this.enableUserLocation(false)
            await this.getCountryCurrencyDataById()
        }
        else {
            this.getBillingOption()
        }
    }
    async enableUserLocation(showDialouge:boolean) {

        const cordinates: any = await LocationUtils.getCoords()
        if (cordinates) {
            const userLocation = await LocationUtils.GetLocationInfo('country', cordinates.latitude, cordinates.longitude)
            this.setState({
                userCountry: userLocation.CountryName,//userLocation.CountryName,
                userCountryCode: userLocation.CountryCode
            }, () => {
                if(showDialouge){
                    this.setState({ showDialouge: true }, () => {
                        document.getElementById('modal_stripe_verification').className = 'modal-container'
                    })
                }
            })
        }
    }
    async getBillingOption() {
        const billingOption = await BillingOptionUtils.getOrganizerPayout()
        if (!billingOption.isError && billingOption.data) {

            this.setState({ organizerBillingOption: billingOption.data }, async () => {
                await this.getCountryCurrencyDataById()
            })
        }
    }
    intializeCurrencies() {
        this.setState({
            currencyData: CryptoCurrencyData,
        })
    }

    organizerBillingHandler(value) {

        const { serviceCharges, ExchangeRate } = this.state
        let total: number = (Number(parseFloat(value === "" ? 0 : value).toFixed(2))) - (ExchangeRate * Number(parseFloat(serviceCharges.toString()).toFixed(2)))
        if (total <= 0) {
            total = 0
        }
        this.setState({ amount: value, total })
    }

    handlePayment(e) {
        this.getStripeToken(e)
    }

    async getStripeToken(e) {
        await this.props.stripe
            .createToken({
                country: 'us',
                currency: 'us',
            })
            .then(result => { })
            .catch(err => { })
    }

    async withdrawlBalance() {
        const { total } = this.state
        const stripeAccountObj: StripeAccountViewModel = Object()
        stripeAccountObj.totalAmount = total
        // stripeAccountObj.uploadFileData = uploadFileData;
        if (this.state.term_condtion) {
            const res = await StripeUtils.WithdrawlBalance(stripeAccountObj)
            if (!res.isError) {
                await this.saveAdminRedemption()
            } else {
                alert(res.data)
            }
        } else {
            alert('Please check Terms & Condition')
        }
    }

    fileHandler(event) {
        const data = new FormData()
        const fileData = new FileReader()
        const file = event.files[0]
        data.append('file', file)
        fileData.readAsDataURL(file)
        fileData.onloadend = () => {
            const fileDataString = fileData.result
            const imageBase64: string = fileDataString.toString()
            const image = imageBase64.replace(/^data:image\/\w+;base64,/, '')
            this.setState({ uploadFileData: image })
        }
    }

    async organizerAmount() {
        const isOrganizer = localStorage.getItem('isorganizer')
        if (isOrganizer == '1') {
            const result = await TransactionUtils.OrganizerAmount()
            if (!result.isError) {
                this.setState({ totalAmount: parseFloat(Number(result.data).toFixed(2)) })
            }
        }
    }

    async saveAdminRedemption() {

        const { totalAmount, total, cheque, eTransfer, directDebit, amount, coin, coinTotalAmount, CoinType, term_condtion, BaseCurrencyExchangeRate } = this.state
        const userId: string = localStorage.getItem('userid')
        const isOrganizer: string = localStorage.getItem('isorganizer')
        const user: UserModel = StorageUtils.getUser()
        if (!term_condtion) {
            alert('Please check Terms & Condition')
            return
        }
        if (directDebit && !user.IsVerified) {
            document.getElementById('modal_stripe_verification').className = "modal-container modal-container--opened"
            return
        }
        if (total <= 0) {
            alert('The total should be greater than 0 for withdrawal request')
            return
        }
        if (userId && isOrganizer == '1' && term_condtion) {
            const ServiceCharge = !coin ? parseFloat((this.state.serviceCharges * this.state.ExchangeRate).toString())
                : +parseFloat((this.state.serviceCharges).toString()).toFixed(2)
            const amountWithdraw = coin ? coinTotalAmount - total : totalAmount - total
            const _totalAmount = coin ? coinTotalAmount : totalAmount
            if (_totalAmount > 0 && amountWithdraw > 0) {
                const adminRedemption: kl_AdminRedemption = Object()
                adminRedemption.Amount = Number(parseFloat(total.toString()))
                adminRedemption.Status = 1
                adminRedemption.RequestTypeId = RequestType.Withdrawal
                adminRedemption.CoinType = coin ? CoinType : null
                adminRedemption.OrignalAmount = Number(parseFloat(amount.toString()))
                adminRedemption.ServiceFee = ServiceCharge
                adminRedemption.BaseCurrencyExchangeRate = BaseCurrencyExchangeRate
                if (cheque || eTransfer || directDebit || coin) {
                    adminRedemption.PaymentMethod = cheque ? 'Check' : eTransfer ? 'E-transfer' : directDebit ? 'Bank Deposit' : 'Coin'
                    await AdminRedemptionUtils.Add(adminRedemption).then(async function (result) {
                        if (!result.isError && result.data && result.data.Id > 0) {
                            GeneralUtils.showSuccess('Successfully Sent Request For Approval')
                        }
                        else {
                            GeneralUtils.showError(result.message)
                        }
                    }).catch(function (error) {
                        GeneralUtils.showError(error.message)
                    })

                }
            } else {
                alert('Sorry you have insufficient funds, please try a different amount')
            }
        }
    }
    async convertCurrencyToUSD(amount, type = '') {
        const { serviceCharges, CurrentCurrency } = this.state

        const result = await TransactionUtils.GetExchangeCurrencyPrice(CurrentCurrency)
        if (!result.isError) {
            const usd_price = JSON.parse(result.data)[CurrentCurrency]
            const value = Number(amount)
            this.setState(
                {
                    convertAmount: round2Places((usd_price.usd * value), 2).toString(),
                },
                () => {
                    const total = Number(Number(round2Places(usd_price.usd * value, 2) - serviceCharges).toFixed(2))
                    this.setState({ total: (total <= 0 ? 0 : total) })
                }
            )
        }
    }
    handleCurrencyChange(id, val, index) {
        const { currencyData } = this.state
        const setCurrency = currencyData[val - 1]
        if (setCurrency) {
            this.setState(
                {
                    CoinType: Number(setCurrency.value),
                    CurrentCurrency: setCurrency.id,
                    selectedCurrency: val,
                },
                () => {
                    this.convertCurrencyToUSD(this.state.amount)
                }
            )
        }
    }
    async getcurrencyFromCountry() {
        const { userCountry } = this.state
        const result = await BillingOptionUtils.getCurrencyByCountry(userCountry)
        if (!result?.isError) {
            this.setState({
                userCurrency: result?.data
            })


        }
    }
    async getCountryCurrencyDataById() {
        GeneralUtils.showHideLoadingDiv(true)
        var result = await TransactionUtils.GetCurrencyDataFromCountry(this.state.userCountry)
        if (!result.isError) {
            this.setState({
                currencySymbol: result.data.currencySymbol,
                userCurrency: result.data.currencyName,
                userCountry: result?.data.countryName
            }, async () => {
                await this.getExchangeRateFromCurrencySymbol(result.data.id)
            })
        }
        GeneralUtils.showHideLoadingDiv(false)
    }
    async getExchangeRateFromCurrencySymbol(currencyId: number) {
        var result = await BillingOptionUtils.getExchangeRateFromCurrency(currencyId)

        if (!result.isError) {
            this.setState({
                ExchangeRate: round2Places(result.data.CurrentCurrencyExchangeRate, 2),
                BaseCurrencyExchangeRate: round2Places(result.data.BaseCurrencyExchangeRate, 2),
            }, () => {
                this.organizerAmount()
            })
        }
    }
    isOrganizerVerified = (data, result) => {
        const user = StorageUtils.getUser()
        this.setState(
            {
                showDialouge: false
            },
            () => {
                if (result !== null) {
                    user.IsVerified = result.PaymentMethod == 2 ? true : false
                    const { organizerBillingOption } = this.state
                    organizerBillingOption.PaymentMethodId = result.PaymentMethod
                    this.setState({ organizerBillingOption })
                }
                localStorage.setItem('user', JSON.stringify(user))
                if (data && data.hasOwnProperty('AccountLinkUrl') && data.AccountLinkUrl !== '') {
                    user.Stripe_IdentityUrl = data.AccountLinkUrl
                    localStorage.setItem('user', JSON.stringify(user))
                    window.location.href = data.AccountLinkUrl
                }
            }
        )
    }

    render() {
        const { } = this.props
        const user: UserModel = StorageUtils.getUser()
        // const currency: kl_CurrencyModel = JSON.parse(localStorage.getItem('currency'))

        const { eTransfer, directDebit, cheque, term_condtion, organizerBillingOption, amount, coin, currencySymbol } = this.state
        return (
            <>
                <article className="" id="article">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                                <div className="col-12 article__withdraw withdraw">
                                    <div className="row flex-column m-0">
                                        <a
                                            onClick={() => {
                                                window.history.back()
                                            }}
                                            className="withdraw__back white"
                                        >
                                            Back
                    </a>
                                        <div className="withdraw-wrapper">
                                            <h1 className="withdraw__header__request">Request Withdrawal №234256</h1>
                                            <div className="withdraw__balance d-flex flex-column align-items-center justify-content-center">
                                                <div className="withdraw__balance-header">
                                                    {!coin ? (this.state.totalAmount ? currencySymbol + " " + this.state.totalAmount : currencySymbol + ' 0') : '$ ' + this.state.coinTotalAmount}
                                                </div>
                                                <div className="withdraw__balance-text"> Available balance </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-auto p-0-10 d-flex flex-row flex-wrap withdraw__amount-container order-md-1 order-2">
                                                {/* <div className="col p-0-5">
                          <h4 className="withdraw__subheader withdraw__subheader--amount"> Enter the amount </h4>
                        </div>
                        <div className="col-auto p-0-5 d-flex flex-row">
                          <input type="checkbox" id="fullAmount" className="withdraw__checkbox" checked={this.state.fullAmount} />
                          <label
                            className="withdraw__full-amount-label"

                            onChange={() => {
                              this.setState({
                                fullAmount: !this.state.fullAmount,
                                amount: this.state.totalAmount,
                              })
                            }}
                          />
                          <div className="withdraw__full-amount-text">Full amount</div>
                        </div>
                        <div className="col-12 p-0-5">
                          <input
                            type="text"
                            className="withdraw__input withdraw__input-2"
                            id="amount"
                            value={amount}
                            onChange={e => {
                              this.organizerBillingHandler(e)
                            }}
                            placeholder="Amount"
                          />
                        </div> */}
                                            </div>
                                            <div className="col-md col-12 p-0-30 order-md-2 order-1">
                                                <div className="row justify-content-md-end">
                                                    {/* <div className="withdraw__balance d-flex flex-column align-items-center justify-content-center">
                            <div className="withdraw__balance-header">
                              {!coin ? (this.state.totalAmount ? '$' + this.state.totalAmount : '$0') : this.state.coinTotalAmount}
                            </div>
                            <div className="withdraw__balance-text"> Available balance </div>
                          </div> */}
                                                </div>
                                            </div>
                                        </div>
                                        <input
                                            type="radio"
                                            name="payment-method"
                                            checked={cheque}
                                            onChange={(e) =>{}}
                                            id="cheque"
                                            className="withdraw__checkbox withdraw__checkbox--cheque"
                                        />
                                        <input
                                            type="radio"
                                            name="payment-method"
                                            checked={eTransfer}
                                            onChange={(e) =>{}}
                                            id="e-transfer"
                                            className="withdraw__checkbox withdraw__checkbox--e-transfer"
                                        />
                                        <input
                                            type="radio"
                                            name="payment-method"
                                            checked={directDebit}
                                            onChange={(e) =>{}}
                                            id="direct-debit"
                                            className="withdraw__checkbox withdraw__checkbox--direct-debit"
                                        />
                                        <input type="radio" name="payment-method" checked={coin} onChange={(e) =>{}} id="coin" className="withdraw__checkbox withdraw__checkbox--coin" />
                                        <div className="row">
                                            <div className="col-12">
                                                <h4 className="withdraw__subheader">Payment Method</h4>
                                            </div>
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col-12 p-0-10 withdraw__tab-menu-container position-relative">
                                                        <div id="nav22" className="row m-0 withdraw__tab-menu">
                                                            {/* <label id="tab1" onClick={() => { this.setState({ cheque: true, directDebit: false, eTransfer: false, coin: false }) }} className="withdraw__payment-method-label withdraw__input-1">CHEQUE</label>
                                                            <label id="tab2" onClick={() => { this.setState({ cheque: false, directDebit: false, eTransfer: true, coin: false }) }} className="withdraw__payment-method-label withdraw__input-1">E-TRANSFER</label> */}
                                                            <label
                                                                id="tab3"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        cheque: false,
                                                                        directDebit: true,
                                                                        eTransfer: false,
                                                                        coin: false,
                                                                        amount: '0',
                                                                        totalAmount: 0,
                                                                        coinTotalAmount: 0,
                                                                        total: 0,
                                                                        convertAmount: 0,
                                                                        fullAmount: false,
                                                                        currencySymbol: this.state.currencySymbol,
                                                                        userCountry: '',
                                                                        userCountryCode: '',
                                                                        userCurrency: ''
                                                                    }, async() => {
                                                                            if (!user.IsVerified) {
                                                                                document.getElementById('modal_stripe_verification').className = 'modal-container modal-container--opened'
                                                                            }
                                                                            else {
                                                                                await this.getBillingOption()
                                                                            }
                                                                    })
                                                                }}
                                                                className={this.state.directDebit ? `${"withdraw__payment-method-label withdraw__input-1 " + styles.withdraw__payment__method__label__active}` : "withdraw__payment-method-label withdraw__input-1"}
                                                            >
                                                                BANK DEPOSIT
                              </label>
                                                            <label
                                                                id="tab4"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        cheque: false,
                                                                        directDebit: false,
                                                                        eTransfer: false,
                                                                        coin: true,
                                                                        amount: '0',
                                                                        totalAmount: 0,
                                                                        coinTotalAmount: 0,
                                                                        total: 0,
                                                                        convertAmount: 0,
                                                                        fullAmount: false,
                                                                        ExchangeRate: 1,
                                                                        userCountry: 'United States',
                                                                        userCountryCode: 'us',
                                                                        userCurrency: 'Us Dollar'
                                                                    })
                                                                }}
                                                                className={this.state.coin ? `${"withdraw__payment-method-label withdraw__input-1 " + styles.withdraw__payment__method__label__active}` : "withdraw__payment-method-label withdraw__input-1"}

                                                            >
                                                                CRYPTOCURRENCY
                              </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="container-fluid p-0 withdraw__cheque-container">
                                            <div className="row">
                                                <div className="col-auto">
                                                    <div className="withdraw__warning-icon" />
                                                </div>
                                                <div className="col p-0">
                                                    <div className="withdraw__warning-text">Available for Canadian Residence and US residence only!</div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 p-0-10">
                                                    <div className="row m-0">
                                                        <div className="col-auto d-flex flex-column p-0-5">
                                                            <h4 className="withdraw__subheader">First name</h4>
                                                            <input defaultValue='' type="text" className="withdraw__input withdraw__input-1 withdraw__input--first-name" placeholder="Alex" />
                                                        </div>
                                                        <div className="col-auto d-flex flex-column p-0-5">
                                                            <h4 className="withdraw__subheader">Last name</h4>
                                                            <input defaultValue='' type="text" className="withdraw__input withdraw__input-1 withdraw__input--last-name" placeholder="Baker" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 p-0-10">
                                                    <div className="row m-0">
                                                        <div className="col-auto d-flex flex-column p-0-5">
                                                            <h4 className="withdraw__subheader">Street address</h4>
                                                            <input defaultValue='' type="text" className="withdraw__input withdraw__input-1 withdraw__input--street" placeholder="1010 EASY ST" />
                                                        </div>
                                                        <div className="col-auto d-flex flex-column p-0-5">
                                                            <h4 className="withdraw__subheader">City</h4>
                                                            <input defaultValue='' type="text" className="withdraw__input withdraw__input-1 withdraw__input--city" placeholder="Ottawa" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 p-0-10">
                                                    <div className="row m-0">
                                                        <div className="col-auto d-flex flex-column p-0-5">
                                                            <h4 className="withdraw__subheader">Province/State</h4>
                                                            <input defaultValue='' type="text" className="withdraw__input withdraw__input-1 withdraw__input--state" placeholder="Ontario" />
                                                        </div>
                                                        <div className="col-auto d-flex flex-column p-0-5">
                                                            <h4 className="withdraw__subheader">Canada/USA</h4>
                                                            <div
                                                                className="article-select article-select--canada-usa select-container withdraw__input-1 select"
                                                                onClick={e => {
                                                                    e.currentTarget.className = `article-select article-select--canada-usa select-container withdraw__input-1 select ${e.currentTarget.className.indexOf('select--opened') > -1 ? '' : 'select--opened'
                                                                        } `
                                                                }}
                                                            >
                                                                <div className="article-select--canada-usa__title select__title">Canada</div>
                                                                <section className="article-select--canada-usa__container select__container">
                                                                    <div className="article-select--canada-usa__option select__option select__option--selected">Canada</div>
                                                                    <div className="article-select--canada-usa__option select__option">United States</div>
                                                                </section>
                                                            </div>
                                                        </div>
                                                        <div className="col-auto d-flex flex-column p-0-5">
                                                            <h4 className="withdraw__subheader">Postal Code/Zip code</h4>
                                                            <input defaultValue='' type="text" className="withdraw__input withdraw__input-1 withdraw__input--code" placeholder="K1A OB1" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="container-fluid p-0 withdraw__e-transfer-container">
                                            <div className="row">
                                                <div className="col-auto">
                                                    <div className="withdraw__warning-icon" />
                                                </div>
                                                {/* <div className="col p-0">
                                                    <div className="withdraw__warning-text">
                                                        Available for Canadian Residence only!
								</div>
                                                </div> */}
                                            </div>
                                            <div className="row">
                                                <div className="col-auto d-flex flex-column">
                                                    <h4 className="withdraw__subheader">Email address</h4>
                                                    <input
                                                        type="email"
                                                        className="withdraw__input withdraw__input-2 withdraw__input--email"
                                                        value={user && user.emailaddress}
                                                        onChange={(e)=>{}}
                                                        placeholder="Email address"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-auto">
                                                    <div className="withdraw__warning-icon withdraw__warning-icon--email" />
                                                </div>
                                                <div className="col p-0">
                                                    <div className="withdraw__warning-text withdraw__warning-text--email">
                                                        Please provide the email address associated with you Interact e-Transfer account.
                          </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="container-fluid p-0 withdraw__direct-debit-container">
                                            <div className="row">
                                                <div className="col-12 p-0-10">
                                                    <div className="row m-0">
                                                        <div className="col-md-6 d-flex flex-column p-0-5">
                                                            <h4 className="withdraw__subheader">Currency</h4>
                                                            <input
                                                                disabled
                                                                type="text"
                                                                className="withdraw__input withdraw__input--routing-number"
                                                                placeholder="currency"
                                                                value={this.state.userCurrency || ''}
                                                            />
                                                            {/* <div
                                className="select-container article-select article-select--currency withdraw__input-2 select"
                                onClick={e => {
                                  e.currentTarget.className = `select-container article-select article-select--currency withdraw__input-2 select ${
                                    e.currentTarget.className.indexOf('select--opened') > -1 ? '' : 'select--opened'
                                  } `
                                }}
                              >
                                <div className="article-select--currency__title select__title">CAD - Canadian Dollar</div>
                                <section className="article-select--currency__container select__container _border">
                                  <div className="article-select--currency__option select__option select__option--selected">
                                    CAD - Canadian Dollar
                                  </div>
                                  <div className="article-select--currency__option select__option">USD - US Dollar</div>
                                </section>
                              </div> */}
                                                        </div>
                                                        <div className="col-md-6 d-flex flex-column p-0-5">
                                                            <div className="col-auto p-0-5 d-flex flex-row lbl-with-check">
                                                                <h4 className="withdraw__subheader withdraw__subheader--amount"> Enter the amount(in {currencySymbol}) </h4>
                                                                <span>
                                                                    <input type="checkbox" id="fullAmountDebit" className="withdraw__checkbox" onChange={(e)=>{}} checked={this.state.fullAmount} />
                                                                    <label
                                                                        className="withdraw__full-amount-label"
                                                                        onClick={(e) => {
                                                                            this.setState({
                                                                                fullAmount: !this.state.fullAmount,
                                                                                amount:
                                                                                    (document.getElementById('fullAmountDebit') as HTMLInputElement).checked == false
                                                                                        ? !coin
                                                                                            ? this.state.totalAmount.toString()
                                                                                            : this.state.coinTotalAmount.toString()
                                                                                        : '0',
                                                                            }, () => {
                                                                                this.organizerBillingHandler(this.state.amount)
                                                                            })
                                                                        }}
                                                                    />

                                                                    <div className="withdraw__full-amount-text">Full amount</div>
                                                                </span>
                                                            </div>

                                                            <div className="col-12 p-0-5">
                                                                <input
                                                                    type="text"
                                                                    className="withdraw__input"
                                                                    id="bank-deposite-amount"
                                                                    value={amount || ''}
                                                                    onChange={(e) => {
                                                                        this.organizerBillingHandler(e.target.value)
                                                                    }}
                                                                    placeholder="Amount"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row withdraw__currency-container withdraw__currency-container--usa">
                                                <div className="col-12">
                                                    <div className="row">
                                                        <div className="col-12 p-0-10">
                                                            <div className="row m-0">
                                                                <div className="col-md-6 d-flex flex-column p-0-5">
                                                                    <h4 className="withdraw__subheader"> Country of bank account </h4>
                                                                    <input
                                                                        type="text"
                                                                        disabled
                                                                        className="withdraw__input withdraw__input--routing-number"
                                                                        placeholder="country"
                                                                        value={this.state.userCountry || ''}
                                                                    />
                                                                    {/* <div
                                    className="select-container article-select article-select--country withdraw__input-2 select"
                                    onClick={e => {
                                      e.currentTarget.className = `select-container article-select article-select--country withdraw__input-2 select ${
                                        e.currentTarget.className.indexOf('select--opened') > -1 ? '' : 'select--opened'
                                      } `
                                    }}
                                  >
                                    <div className="article-select--country__title select__title">United States</div>
                                    <section className="article-select--country__container select__container _border" style={{ zIndex: 99999 }}>
                                      <div className="article-select--country__option select__option select__option--selected">United States</div>
                                      <div className="article-select--country__option select__option">Canada</div>
                                    </section>
                                  </div> */}
                                                                </div>
                                                                <div className="col-md-6 d-flex flex-column p-0-5">
                                                                    <h4 className="withdraw__subheader">Routing number</h4>
                                                                    <input type="text" value={organizerBillingOption && organizerBillingOption.RoutingNumber } className="withdraw__input withdraw__input--routing-number" placeholder="11000000" readOnly />

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row withdraw__currency-container withdraw__currency-container--canada">
                                                <div className="col-12">
                                                    <div className="row">
                                                        <div className="col-12 p-0-10">
                                                            <div className="row m-0">
                                                                <div className="col-md-6 d-flex flex-column p-0-5">
                                                                    <h4 className="withdraw__subheader">Transit number</h4>
                                                                    <input
                                                                        type="text"
                                                                        className="withdraw__input withdraw__input--transit-number" readOnly
                                                                        value={(organizerBillingOption && organizerBillingOption.TransitNumber) || ''}
                                                                        placeholder="12345"
                                                                    />
                                                                </div>
                                                                <div className="col-md-6 d-flex flex-column p-0-5">
                                                                    <h4 className="withdraw__subheader">Institution number</h4>
                                                                    <input
                                                                        type="text"
                                                                        className="withdraw__input withdraw__input--institution-number" readOnly
                                                                        value={organizerBillingOption && organizerBillingOption.InstitutionNumber}
                                                                        placeholder="000"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 p-0-10">
                                                    <div className="row m-0">
                                                        <div className="col-md-6 d-flex flex-column p-0-5">
                                                            <h4 className="withdraw__subheader">Account number</h4>
                                                            {/* <IdealBankElement /> */}
                                                            {/* <IbanElement supportedCountries={['SEPA']} className="withdraw__input withdraw__input-1 withdraw__input--account-number" /> */}
                                                            <input
                                                                type="text"
                                                                className="withdraw__input withdraw__input--account-number" readOnly
                                                                value={organizerBillingOption && organizerBillingOption.AccountNumber}
                                                                placeholder="000123456789"
                                                            />
                                                        </div>
                                                        <div className="col-md-6 d-flex flex-column p-0-5">
                                                            <h4 className="withdraw__subheader">Confirm account number</h4>
                                                            <input
                                                                type="text"
                                                                className="withdraw__input withdraw__input--confirm-account-number" readOnly
                                                                value={organizerBillingOption && organizerBillingOption.AccountNumber}
                                                                placeholder="000123456789"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-auto">
                                                    <div className="withdraw__warning-icon" />
                                                </div>
                                                <div className="col p-0">
                                                    <div className="withdraw__warning-text">Your bank account must be a checking account.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="container-fluid p-0 withdraw__coin-container">
                                            {/* <div className="row">
                                                <div className="col-auto">
                                                    <div className="withdraw__warning-icon"></div>
                                                </div>
                                                <div className="col p-0">
                                                    <div className="withdraw__warning-text">
                                                        Available for Canadian Residence only!
								</div>
                                                </div>
                                            </div> */}
                                            <div className="row">
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-3 article__select article-select">
                                                    <div className="withdraw__subheader">Select cryptocurrency</div>
                                                    <SelectComponent
                                                        zIndex={499}
                                                        fieldId="value"
                                                        valueField="value"
                                                        textField="lable"
                                                        placeholder="Select cryptocurrency"
                                                        searchable={false}
                                                        data={this.state.currencyData}
                                                        selectedValue={this.state.selectedCurrency}
                                                        onSelectionChange={this.handleCurrencyChange}
                                                        class1="article-select__select article-select__select--region select withdraw__input"
                                                        class2="article-select__select--organizer__title select__title"
                                                        class3="article-select__select--organizer__container select__container"
                                                        class4="article-select__select--organizer__option select__option d-block"
                                                        isImage
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 d-flex flex-column p-0-5">
                                                    <div className="col-auto p-0-5 d-flex flex-row lbl-with-check">
                                                        <h4 className="withdraw__subheader withdraw__subheader--amount"> Enter the amount to withdraw </h4>
                                                        <span>
                                                            <input type="checkbox" id="fullAmount" className="withdraw__checkbox" onChange={()=>{}} checked={this.state.fullAmount} />
                                                            <label
                                                                className="withdraw__full-amount-label"
                                                                onClick={e => {
                                                                    this.setState(
                                                                        {
                                                                            fullAmount: !this.state.fullAmount,
                                                                            amount:
                                                                                (document.getElementById('fullAmount') as HTMLInputElement).checked == false
                                                                                    ? !coin
                                                                                        ? this.state.totalAmount.toString()
                                                                                        : this.state.coinTotalAmount.toString()
                                                                                    : '0',
                                                                        },
                                                                        () => {
                                                                            {
                                                                                coin && this.convertCurrencyToUSD(this.state.amount)
                                                                            }
                                                                        }
                                                                    )
                                                                }}
                                                            />

                                                            <div className="withdraw__full-amount-text">Full amount</div>
                                                        </span>
                                                    </div>

                                                    <div className="col-12 p-0-5">
                                                        <input
                                                            type="text"
                                                            className="withdraw__input"
                                                            id="amount"
                                                            value={amount || ''}
                                                            onChange={(e) => {
                                                                this.setState({ amount: e.target.value }, () => {
                                                                    this.convertCurrencyToUSD(this.state.amount)
                                                                })
                                                            }}
                                                            placeholder="Amount"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4 d-flex flex-column mt-3 p-0-5">
                                                    <h4 className="withdraw__subheader" />
                                                    <input
                                                        readOnly
                                                        type="text"
                                                        min="0"
                                                        className="withdraw__input withdraw__input--email"
                                                        value={this.state.convertAmount + ' USD'}
                                                    />
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-12 mb-2 d-flex">
                                                        <div className="col-auto">
                                                            <div className="withdraw__warning-icon withdraw__warning-icon--email" />
                                                        </div>
                                                        <div className="col-p-0">
                                                            <div className="withdraw__warning-text withdraw__warning-text--email withdaw__warning-text-justify wrap-text">
                                                                The network fee will be taken from your withdraw amount you will receive your payments minus the fee.
                              </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 d-flex flex-column">
                                                    <h4 className="withdraw__subheader">Provide the receiving address for currency</h4>
                                                    <input
                                                        type="email"
                                                        className="withdraw__input withdraw__input-2 withdraw__input--email"
                                                        value={organizerBillingOption && organizerBillingOption.CoinAddress}
                                                        onChange={(e)=>{}}
                                                        placeholder="Coin address"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-auto">
                                                    <div className="withdraw__warning-icon withdraw__warning-icon--email" />
                                                </div>
                                                <div className="col p-0">
                                                    <div className="withdraw__warning-text withdraw__warning-text--email withdaw__warning-text-justify wrap-text">
                                                        Please use a valid address that will support currency,if you enter a wrong address,then money will be lost. Kombatlink is
                                                        not responsible for lost currencies, so please enter the address correctly.
                          </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="container-fluid p-0 withdraw__confirm-container">
                                            <div className="row">
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-8">
                                                    <div className="row m-0 flex-nowrap">
                                                        <div className="col-md-1">
                                                            <input id="term_condtion" type="checkbox" className="withdraw__checkbox" onChange={(e)=>{}} checked={term_condtion} />
                                                            <label
                                                                onClick={() => {
                                                                    this.setState({ term_condtion: !this.state.term_condtion })
                                                                }}
                                                                className="withdraw__agree-licence-label "
                                                            />
                                                        </div>
                                                        <div className="col-md-11">
                                                            <div className="withdraw__agree-licence-text">
                                                                Agree with Kombatlink&nbsp;
                                <a onClick={(e)=>{e.preventDefault()}} className="color-blue">
                                                                    Terms and Conditions
                                </a>
                                &nbsp;&amp;&nbsp;
                                <a onClick={(e)=>{e.preventDefault()}} className="color-blue">
                                                                    Privacy Policy
                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="transactionbtnlast" className="col-12 col-sm-12 col-md-12 col-lg-4 p-2 d-flex">
                                                    <div className="m-2">
                                                        {!coin && (
                                                            <button className="withdraw__confirm blue-button blue-button--filled" onClick={() =>
                                                                !user.IsVerified ?
                                                                    document.getElementById('modal_stripe_verification').className = 'modal-container modal-container--opened'
                                                                    : this.withdrawlBalance()}>
                                                                CONFIRM
                                                            </button>
                                                        )}
                                                        {coin && (
                                                            <button className="withdraw__confirm blue-button blue-button--filled" onClick={() => this.saveAdminRedemption()}>
                                                                CONFIRM
                                                            </button>
                                                        )}
                                                    </div>
                                                    {/* <div className="upload-btn-wrapper m-2" id="transactionpart1">
                                                        {!coin && <button className="withdraw__confirm btn btn-success btn-fill">Upload File</button>}
                                                        <input type="file" name="myfile" onChange={(event) => this.fileHandler(event.target)} />
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 article__right-menu">
                                <section className="article__summary summary" id="transactionpart">
                                    <div className="row flex-column">
                                        <div className="col-12">
                                            <h2 className="summary__header">Withdraw summary</h2>
                                        </div>
                                        <div className="col-12 summary__order">
                                            <div className="row">
                                                <div className="col">
                                                    <div className="summary__order-title">Total Withdraw</div>
                                                </div>
                                                <div className="col-auto">
                                                    <div className="summary__order-price">
                                                        {!coin ? currencySymbol + (amount === "" ? " 0.00" : " " + parseFloat((parseFloat(amount)).toString()).toFixed(2)) : ('$ ' + parseFloat(this.state.convertAmount === "" ? 0 : this.state.convertAmount).toFixed(2))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 summary__order">
                                            <div className="row">
                                                <div className="col">
                                                    <div className="summary__order-title">Service Charge</div>
                                                </div>
                                                <div className="col-auto">
                                                    <div className="summary__order-price">
                                                        {!coin ? currencySymbol + " " + parseFloat((this.state.serviceCharges * this.state.ExchangeRate).toString()).toFixed(2)
                                                            : "$ " + parseFloat((this.state.serviceCharges).toString()).toFixed(2)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="row m-0 summary__total">
                                                <div className="col p-0">
                                                    <div className="summary__total-text">TOTAL</div>
                                                </div>
                                                <div className="col-auto p-0">
                                                    <div className="summary__total-price">
                                                        {!coin ? currencySymbol + " " + parseFloat(this.state.total.toString()).toFixed(2) : '$ ' + parseFloat(this.state.total.toString()).toFixed(2)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                        <div className="row">
                            {this.state.showDialouge && user.Stripe_IdentityUrl === null && (
                                <BillingOptionDailog
                                    organizerPayout={this.state.organizerBillingOption}
                                    showClass="modal-container--opened"
                                    verified={this.isOrganizerVerified}
                                    userCountry={this.state.userCountry}
                                    userCountryCode={this.state.userCountryCode}
                                    currentRoute={window.location.pathname}

                                />

                            )}



                        </div>
                    </div>
                    <aside className="modal-container" id="modal_stripe_verification">
                        <div className="modal-container__background" />
                        <div className="profile-modal profile-modal-medium modal-container__window window">
                            <div className="window__close"
                                onClick={() => {
                                    document.getElementById('modal_stripe_verification').className = 'modal-container'
                                }}
                            />
                            <div className="profile-modal_inn">
                                <p className="profile-modal_ttl--big text-center">

                                    Please create an account with our payment partner Stripe and complete the verification process to get paid directly to your bank account

                                </p>
                                <div className="disable-premium_buttons">

                                    {user.Stripe_IdentityUrl === null &&
                                        <button
                                        className="btn-violet-primery open-modal_disable-success"
                                        onClick={async () => {

                                            this.enableUserLocation(true)
                                        }}
                                        >

                                        Verify
                                        </button>}

                                    {user.Stripe_IdentityUrl !== null &&
                                        <button
                                            className="btn-violet-primery open-modal_disable-success"
                                            onClick={async () => {
                                                (window.location.href = user.Stripe_IdentityUrl)
                                            }}
                                        >
                                            Verify
                                        </button>

                                    }


                                </div>
                            </div>
                        </div>
                    </aside>
                </article>
            </>
        )
    }
}
export default withStripe(WithdrawTransactions)
