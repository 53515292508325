import React, { useEffect, useMemo } from 'react'
import { OrganizerProfileModel } from '../../models/organizer-profile-models'
import { TournamentUtils } from '../../server/utils/tournament-utils'
import { GeneralUtils } from '../../server/utils/general-utils'
import { Tournament_Members_IncomeViewModel } from '../../models/Player_OrganizerHeaderViewModel'
import { getUser } from 'utils'
import { RouteComponentProps } from 'react-router-dom'
import { Img, LoadingBox, UserAvatar } from 'components/_shared'
import { useDispatch, useSelector } from 'react-redux'
import { loadUser } from 'store/logic/user-logic'
import { makeGetIsUserLoading, makeGetUser } from 'store/selectors/user-selectors'
import { ApplicationState } from 'store/types/common'
import { RecentTournaments } from './_shared/RecentTournaments'
import { OrganizerReviews } from './_shared/OrganizerReviews'
import { AddOrganizerReview } from './_shared/AddOrganizerReview'
import { ContainerName, useGetImageSize } from 'components/_hooks'

interface PublicProfileViewRouteProps {
  organizerId: string
}

type PublicProfileViewProps = RouteComponentProps<PublicProfileViewRouteProps>

interface State {
  _organizerProfileObj: OrganizerProfileModel
  pageNo: number
  tournamentMembersIncome: Tournament_Members_IncomeViewModel
}

class PublicProfileView extends React.Component<PublicProfileViewProps, State> {
  constructor(props: PublicProfileViewProps) {
    super(props)
    this.state = {
      _organizerProfileObj: Object(),
      pageNo: 1,
      tournamentMembersIncome: Object(),
    }
  }

  componentDidMount() {
    void this.getData()
  }

  get organizerId() {
    return parseInt(this.props.match.params.organizerId)
  }

  getData = () => {
    const user = getUser()
    void this.organizerProfile(this.organizerId, user.id)
    void this.getTournamentMemberIncome()
  }

  organizerProfile = async (profileId: number, isorganizer: number, fromDate?: Date, toDate?: Date) => {
    if (profileId > 0) {
      GeneralUtils.showHideLoadingDiv(true)
      const result = await TournamentUtils.OrganizerProfile(profileId, isorganizer, fromDate, toDate)
      if (!result.isError) {
        this.setState({ _organizerProfileObj: result.data })
        GeneralUtils.showHideLoadingDiv(false)
      } else {
        GeneralUtils.showError(result.message)
        GeneralUtils.showHideLoadingDiv(false)
      }
    }
  }

  async getTournamentMemberIncome() {
    const userId: number = this.organizerId
    const result = await TournamentUtils.TournamentAndMembersAndIncome(userId)
    if (!result.isError) {
      this.setState({ tournamentMembersIncome: result.data })
    } else {
      GeneralUtils.showError(result.message)
    }
  }

  render() {
    const { _organizerProfileObj, tournamentMembersIncome } = this.state

    return (
      <>
        <div className="pfl">
          <div className="row m-0">
            <div className="col-12">
              <div className="pfl_ttl">
                <div className="pfl_ttl_l">
                  <h1 className="article__header">{_organizerProfileObj.username} profile</h1>
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="pfl_img">
                <div className="pfl_img_img" style={{ height: '300px', overflow: 'hidden' }}>
                  <ReadonlyOrganizerCoverImage userId={this.organizerId} />
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="pfl_company">
                <div className="pfl_company_l">
                  <div className="pfl_company_l_l">
                    <div className="pfl_logo">
                      <ReadonlyOrganizerCover userId={this.organizerId} />
                    </div>
                  </div>
                  <div className="pfl_company_l_r">
                    <div className="pfl_title">
                      <h2 className="pfl_title_ttl"> {_organizerProfileObj.username}</h2>
                      <div className="pfl_verif white-tooltip-theme">
                        {_organizerProfileObj.isVerified ? (
                          <div className="pfl_verif_active">
                            <span className="pfl_verif_active_ico" />
                            <span>Verified</span>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pfl_company_r">
                  <div className="pfl_company_r_inn">
                    <ul className="social-links">
                      <li>
                        <a href={_organizerProfileObj.facebook}>
                          <img src="https://kombatlink-assets.azureedge.net/img/facebook.svg" />
                        </a>
                      </li>
                      <li>
                        <a href={_organizerProfileObj.twitter}>
                          <img src="https://kombatlink-assets.azureedge.net/img/twitter.svg" />
                        </a>
                      </li>
                      <li>
                        <a href={_organizerProfileObj.instagram}>
                          <img src="https://kombatlink-assets.azureedge.net/img/instagram.svg" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="row">
                <div className="col-12 p-0 pfl_col-left">
                  <div className="pfl_info-container">
                    <div className="section-ttl">
                      <div className="section-ttl_l">
                        <h2 className="section-ttl_ttl">Additional Information</h2>
                      </div>
                      <div className="section-ttl_r" />
                    </div>
                    <div className="pfl_info_row mt-2">
                      <div className="pfl_info_col pfl_info_col-level">
                        <div className="pfl_info-level pfl_info" style={{ background: 'rgba(255,255,255,0.1)' }}>
                          <div className="pfl_info_l">
                            <div className="pfl_info_l_inn">
                              <img src="https://kombatlink-assets.azureedge.net/img/award-5.svg" alt="" />
                            </div>
                          </div>
                          <div className="pfl_info_r">
                            <h4 className="pfl_info-level_num">Legendary</h4>
                            <span className="pfl_info-level_ttl">level</span>
                          </div>
                        </div>
                      </div>
                      <div className="pfl_info_col pfl_info_col-number">
                        <div className="pfl_info-number pfl_info" style={{ background: '#682FBF' }}>
                          <div className="pfl_info_l">
                            <div className="pfl_info_l_inn">
                              <img src="https://kombatlink-assets.azureedge.net/img/trophy.svg" alt="" />
                            </div>
                          </div>
                          <div className="pfl_info_r">
                            <h4 className="pfl_info_num">{tournamentMembersIncome.TotalTournament}</h4>
                            <span className="pfl_info_ttl">Tournament Created</span>
                          </div>
                        </div>
                      </div>
                      <div className="pfl_info_col pfl_info_col-number">
                        <div className="pfl_info-number pfl_info" style={{ background: '#FD6969' }}>
                          <div className="pfl_info_l">
                            <div className="pfl_info_l_inn">
                              <img src="https://kombatlink-assets.azureedge.net/img/people.svg" alt="" />
                            </div>
                          </div>
                          <div className="pfl_info_r">
                            <h4 className="pfl_info_num">{tournamentMembersIncome?.TotalMembers?.length ?? 0}</h4>
                            <span className="pfl_info_ttl">Total members</span>
                          </div>
                        </div>
                      </div>
                      <div className="pfl_info_col pfl_info_col-number">
                        <div className="pfl_info-number pfl_info" style={{ background: '#49C3FD' }}>
                          <div className="pfl_info_l">
                            <div className="pfl_info_l_inn">
                              <img src="https://kombatlink-assets.azureedge.net/img/cash.svg" alt="" />
                            </div>
                          </div>
                          <div className="pfl_info_r">
                            <h4 className="pfl_info_num">${tournamentMembersIncome.Income}</h4>
                            <span className="pfl_info_ttl">Money earned</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="pfl_pers-container">
                    <div className="section-ttl">
                      <div className="section-ttl_l">
                        <h2 className="section-ttl_ttl">Personal Information</h2>
                      </div>
                      <div className="section-ttl_r">
                        <button id="open-edit-profile" className="btn-edit" type="button">
                          <img src="https://kombatlink-assets.azureedge.net/img/pencil.svg" alt="" />
                          Edit
                        </button>
                      </div>
                    </div>
                    <div className="pfl_pers_row row">
                      <div className="col-12 col-md-6 col-xl-4">
                        <div className="pfl_pers pfl_pers-small">
                          <h3 className="pfl_pers_ttl">Email Address</h3>
                          <div className="pfl_pers_txt">
                            <a href={`mailto: + ${_organizerProfileObj.emailaddress}`}>{_organizerProfileObj.emailaddress}</a>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-4">
                        <div className="pfl_pers pfl_pers-small">
                          <h3 className="pfl_pers_ttl">Location</h3>
                          <div className="pfl_pers_txt">
                            <span>
                              {_organizerProfileObj.city}, {_organizerProfileObj.country}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-4">
                        <div className="pfl_pers pfl_pers-small">
                          <h3 className="pfl_pers_ttl">Phone number</h3>
                          <div className="pfl_pers_txt">
                            <a href="tel:9059671111">{_organizerProfileObj.tel}</a>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="pfl_pers pfl_pers-big">
                          <h3 className="pfl_pers_ttl">About</h3>
                          <div className="pfl_pers_txt">
                            <p style={{ textAlign: 'justify' }}>{_organizerProfileObj.about}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <OrganizerReviews userId={this.organizerId} />
                  <AddOrganizerReview userId={this.organizerId} />
                </div>
                <RecentTournaments organizerId={this.organizerId} />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default PublicProfileView

interface ReadonlyOrganizerAvatarProps {
  userId: number
  className?: string
  containerName?:ContainerName
}

export const ReadonlyOrganizerCoverImage = ({ userId,containerName } :ReadonlyOrganizerAvatarProps) => {
  const dispatch = useDispatch()
  const getIsUserLoading = useMemo(makeGetIsUserLoading, [])
  const getUser = useMemo(makeGetUser, [])

  const isUserLoading = useSelector((state: ApplicationState) => getIsUserLoading(state, { userId }))
  const user = useSelector((state: ApplicationState) => getUser(state, userId))
  const getContainerImageSize = useGetImageSize(containerName)
  useEffect(() => {
    dispatch(loadUser(userId))
  }, [dispatch, userId])

  return (
    <LoadingBox size="big" loading={isUserLoading}>
      <Img parentContainerSize={getContainerImageSize} imageId={user?.CoverImageId} />
    </LoadingBox>
  )
}

const ReadonlyOrganizerCover = ({ userId } :ReadonlyOrganizerAvatarProps) => {
  const dispatch = useDispatch()
  const getIsUserLoading = useMemo(makeGetIsUserLoading, [])

  const isUserLoading = useSelector((state: ApplicationState) => getIsUserLoading(state, { userId }))
  
  useEffect(() => {
    dispatch(loadUser(userId))
  }, [dispatch, userId])

  return (
    <div className="pfl_logo_img align-items-center d-flex justify-content-center">
      <LoadingBox size="big" loading={isUserLoading}>
        <UserAvatar userId={userId} className="img-fluid" />
      </LoadingBox>
    </div>
  )
}
