import { createSelector } from 'reselect'
import { OperationTypes } from 'store/logic/operations-logic'
import { ApplicationState } from 'store/types/common'
import { defaultFilters } from 'store/types/pages/members-page-types'
import { makeGetIsAnyOperationWithTypeRunning } from '../operations-selectors'

const getState = (state: ApplicationState) => state.membersPage

export const getAllPlayersCount = createSelector(getState, state => state?.allPlayersCount ?? 0)

export const getPlayersIds = createSelector(getState, state => (state?.playersIds ?? []))

export const getPlayersFilters = createSelector(getState, state => (state?.playersFilters ?? defaultFilters))

export const getIsPlayersLoading = makeGetIsAnyOperationWithTypeRunning(() => ({
  type: OperationTypes.loadPlayers,
}))

export const getAllClansCount = createSelector(getState, state => state?.allClansCount ?? 0)

export const getClansIds = createSelector(getState, state => (state?.clansIds ?? []))

export const getClansFilters = createSelector(getState, state => (state?.clansFilters ?? defaultFilters))

export const getIsClansLoading = makeGetIsAnyOperationWithTypeRunning(() => ({
  type: OperationTypes.loadClans,
}))

export const getAllOrganizersCount = createSelector(getState, state => state?.allOrganizersCount ?? 0)

export const getOrganizersIds = createSelector(getState, state => (state?.organizersIds ?? []))

export const getOrganizersFilters = createSelector(getState, state => (state?.organizersFilters ?? defaultFilters))

export const getIsOrganizersLoading = makeGetIsAnyOperationWithTypeRunning(() => ({
  type: OperationTypes.loadOrganizersPage,
}))

