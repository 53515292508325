import { Button } from 'components/_shared'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getTempMatchesIds } from 'store/selectors/match-selectors'
import { getTempRoundsIds } from 'store/selectors/rounds-selectors'
import { getTempTournamentsIds } from 'store/selectors/tournaments-selectors'
import { clearMatches } from 'store/types/match-types'
import { clearRounds } from 'store/types/rounds-types'
import { clearTournaments } from 'store/types/tournaments-types'

interface CreateButtonProps {
  onClick(): Promise<void>
}

export const CreateButton = ({ onClick }: CreateButtonProps) => {
  const dispatch = useDispatch()
  const tournamentsIds = useSelector(getTempTournamentsIds)
  const roundsIds = useSelector(getTempRoundsIds)
  const matchesIds = useSelector(getTempMatchesIds)

  const [isDisabled, setIsDisabled] = useState(false)

  const handleClick = async () => {
    if (!isDisabled) {
      setIsDisabled(true)
      try {
        dispatch(clearTournaments({ tournamentsIds }))
        dispatch(clearRounds({ tournamentsIds, roundsIds }))
        dispatch(clearMatches({ tournamentsIds, roundsIds, matchesIds }))    
        await onClick()
      }
      finally {
        setIsDisabled(false)
      }
    }
  }

  return (
    <Button
      className="green-button green-button--filled article__create-tournament"
      onClick={handleClick}
      disabled={isDisabled}
      loading={isDisabled}
    >
      Publish
    </Button>

  )
}
