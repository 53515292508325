import * as React from 'react'
import { Payments } from '../../models/payment-models'
import { StripeUtils } from '../../server/utils/stripe-utils'
import { UserModel } from '../../models/user-models'
import { UserSubscription } from '../../models/user-subscription-model'
import { UserPayment } from '../../models/user-payment-models'
import { withStripe } from 'components/_shared/withStripe'
const stripeElements = require('react-stripe-elements')
const CardNumberElement = stripeElements.CardNumberElement
const CardExpiryElement = stripeElements.CardExpiryElement
const CardCvcElement = stripeElements.CardCvcElement

interface State {
  payment: Payments
  userSubscription: UserSubscription
  userPayment: UserPayment
}
class Payment extends React.Component<any, State> {
  constructor(props: any) {
    super(props)
    this.state = {
      payment: Object(),
      userSubscription: Object(),
      userPayment: Object(),
    }
    this.handleChange = this.handleChange.bind(this)
    this.handlePayment = this.handlePayment.bind(this)
    this.getStripeToken = this.getStripeToken.bind(this)
    this.handleSubscription = this.handleSubscription.bind(this)
    this._getUserSubscription = this._getUserSubscription.bind(this)
    this._getUserPayment = this._getUserPayment.bind(this)
    this.handleCharge = this.handleCharge.bind(this)
    this.handleRefund = this.handleRefund.bind(this)
  }
  componentDidMount() {
    const user: UserModel = JSON.parse(localStorage.getItem('user'))
    const { payment } = this.state
    payment.userid = user.id
    payment.name = user.username
    payment.email = user.emailaddress
    payment.postalCode = user.postal
    this.setState({ payment })

    this._getUserSubscription(user.id)
    this._getUserPayment(user.id)
  }
  async _getUserSubscription(userid: number) {
    const result = await StripeUtils.getUserSubscription()
    if (result.data) {
      this.setState({
        userSubscription: result.data,
      })
    } else {
      this.setState({
        userSubscription: {
          id: null,
          stripe_cusid: '',
          stripe_subsid: '',
          stripe_pmid: '',
          userid,
          stripe_planid: '',
        },
      })
    }
  }

  async _getUserPayment(userid: number) {
    const result = await StripeUtils.getUserPayment()
    if (result.data) {
      this.setState({
        userPayment: result.data,
      })
    } else {
      this.setState({
        userPayment: {
          id: null,
          date: new Date(),
          amount: 0,
          userid,
          type: '',
          stripe_chargeid: '',
          stripe_cusid: '',
          stripe_pmid: '',
        },
      })
    }
  }

  handleChange(paramName: string, val: string | boolean | Date) {
    const payment = this.state.payment
    payment[paramName] = val
    this.setState({ payment })
  }
  handlePayment(e, charge?: boolean) {
    this.getStripeToken(e, charge)
  }

  async getStripeToken(e, charge?: boolean) {
    // let user: ContactModel = JSON.parse(StorageUtils.getItem('user'));
    // showHideLoadingDiv(true);
    // // e.preventDefault();
    const { payment, userSubscription } = this.state
    const zip = payment.postalCode
    if (!zip && isNaN(parseInt(zip))) {
    } else {
      const token = await this.props.stripe.createToken({
        name: this.state.payment.name,
        address_zip: zip,
      })
      payment.stripeToken = token.token.id
      if (!userSubscription.stripe_subsid) {
        this.handleSubscription()
      }
      if (charge) {
        this.handleCharge()
      }
    }
  }
  async handleSubscription() {
    const { payment, userSubscription } = this.state
    payment.userSubscription = userSubscription
    const planId = 'price_1H4OVkCKfxLW6yPpeTLM0PS9'
    payment.userSubscription.stripe_planid = planId
  }
  async handleCharge() {
    const { payment, userPayment } = this.state
    const amount = 10
    userPayment.amount = amount
    const date = new Date()
    userPayment.date = date
    userPayment.type = 'A'
    payment.userPayment = userPayment
    await StripeUtils.stripCharge(payment)
  }
  async handleRefund() {}

  render() {
    const { payment } = this.state
    return (
      <>
        <article className="sign-in d-flex flex-row align-items-center">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1 className="sign-in__header">Payment</h1>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="d-flex flex-column">
                  <div id="email-container">
                    <div className="sign-in__input-title">Name</div>
                    <div className="sign-in__container">
                      <input
                        name="name"
                        id="name"
                        type="text"
                        className="sign-in__default-input"
                        placeholder="Name"
                        value={payment.name}
                        onChange={e => {
                          this.handleChange('name', e.target.value)
                        }}
                      />
                      <button
                        className="sign-in__clean-button"
                        onClick={e => {
                          this.handleChange('name', '')
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div id="email-container">
                  <div className="sign-in__input-title">Email</div>
                  <div className="sign-in__container">
                    <input
                      name="email"
                      id="email"
                      type="email"
                      className="sign-in__default-input"
                      placeholder="Email"
                      value={payment.email}
                      onChange={e => {
                        this.handleChange('email', e.target.value)
                      }}
                    />
                    <button
                      className="sign-in__clean-button"
                      onClick={e => {
                        this.handleChange('email', '')
                      }}
                    />
                  </div>
                </div>
                <div id="email-container">
                  <div className="sign-in__input-title">Phone No.</div>
                  <div className="sign-in__container">
                    <input
                      name="phoneNo"
                      id="phoneNo"
                      type="text"
                      className="sign-in__default-input"
                      placeholder="Phone No"
                      value={payment.phoneNo}
                      onChange={e => {
                        this.handleChange('phoneNo', e.target.value)
                      }}
                    />
                    <button
                      className="sign-in__clean-button"
                      onClick={e => {
                        this.handleChange('phoneNo', '')
                      }}
                    />
                  </div>
                </div>
                <div id="email-container">
                  <div className="sign-in__input-title">Postal code</div>
                  <div className="sign-in__container">
                    <input
                      name="postalCode"
                      id="postalCode"
                      type="text"
                      className="sign-in__default-input"
                      placeholder="Postal code"
                      value={payment.postalCode}
                      onChange={e => {
                        this.handleChange('postalCode', e.target.value)
                      }}
                    />
                    <button
                      className="sign-in__clean-button"
                      onClick={e => {
                        this.handleChange('postalCode', '')
                      }}
                    />
                  </div>
                </div>
                <div id="email-container">
                  <div className="sign-in__input-title">Card Number</div>
                  <div className="sign-in__container">
                    <CardNumberElement style={{ base: { fontSize: '18px', color: '#DCDCDC' } }} />
                  </div>
                </div>
                <div id="email-container">
                  <div className="sign-in__input-title"> Expiry date</div>
                  <div className="sign-in__container">
                    <CardExpiryElement style={{ base: { fontSize: '18px', color: '#DCDCDC' } }} />
                  </div>
                </div>
                <div id="email-container">
                  <div className="sign-in__input-title">Cvc</div>
                  <div className="sign-in__container">
                    <CardCvcElement style={{ base: { fontSize: '18px', color: '#DCDCDC' } }} />
                  </div>
                </div>

                <div id="login-container" className="mt-4">
                  <button
                    className="sign-in__login blue-button blue-button--filled"
                    onClick={e => {
                      this.handlePayment(e)
                    }}
                  >
                    Subscribe
                  </button>
                </div>
                <div id="login-container" className="mt-4">
                  <button
                    className="sign-in__login blue-button blue-button--filled"
                    onClick={e => {
                      this.handlePayment(e, true)
                    }}
                  >
                    charge
                  </button>
                </div>
              </div>
            </div>
          </div>
        </article>
      </>
    )
  }
}

export default withStripe(Payment)
