import React, { useRef, useState } from 'react'
import { cn } from 'utils'
import { HomePopupViewModel } from 'models/home-page-popup-viewmodel'
import styles from './HomePopupModalNew.module.scss'

interface Props {
  homePopup: HomePopupViewModel
}

const SliderHomePopupModalNewInfo = ({ homePopup }: Props) => {
  const ref = useRef(null);
  const [readMore, setReadMore] = useState<boolean>(false)
  const [showLink, setShowLink] = React.useState(false);

  const showMore = () => {
    setReadMore(!readMore)
  }

  React.useLayoutEffect(() => {
    if (ref !== null && ref.current !== null) {
      if (ref.current.clientHeight < ref.current.scrollHeight) {
        setShowLink(true);
      }
    }
  }, [ref]);

  return (
    <>
      <div ref={ref} className={cn(styles.description, (readMore === true ? cn(styles.show_description) : ''))}>
        <div dangerouslySetInnerHTML={{ __html: homePopup && homePopup.Description }}></div>
      </div>
      {showLink && !readMore && (
        <a className={cn(styles.view_more)} onClick={() => showMore()}>
          {readMore === true ? 'Read Less' : 'Read More'}
        </a>
      )}

      <div className={cn(styles.popup_btn_wrapper)}>
        <a target='_blank' href={`${homePopup.ButtonLink}`} tabIndex={0} className={cn(styles.popup_btn)}>
          {homePopup.Button}
        </a>
      </div>
    </>
  )
}

export default SliderHomePopupModalNewInfo
