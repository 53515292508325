import { ClanMemberRole } from 'consts'
import { AppUserLoadedAction } from './app-types'
import { ClanDeletedAction, ClanType } from './clan-types'
import { makeActionCreator, ReduxAction } from './common'
import { ImageType } from './image-types'
import { MatchParticipantsLoadedAction } from './match-participant-types'
import { MembersPageLoadedAction } from './pages/clan-page-types'
import { MembersPageClansLoadedAction, MembersPagePlayersLoadedAction } from './pages/members-page-types'
import { ParticipantsLoadedAction } from './participants-types'
import { PlayerProfileType } from './player-profile-type'
import { PublicUserType } from './user-types'

export interface ClanMember {
  UserId: number
  ClanId: number
  Role: ClanMemberRole
  JoinDate: string
}

export interface ClansMembersModel {
  ClansMembers: ClanMember[]
  Users: PublicUserType[]
  Clans: ClanType[]
  Images: ImageType[]
  PlayerProfiles: PlayerProfileType[]
}

type UserId = number

export interface ClansMemberState {
  byUserId: {
    [userId: number]: ClanMember
  }
  byClanId: {
    [clanId: number]: UserId[]
  }
  leaderIdByClanId: {
    [clanId: number]: UserId
  }
}

export const CLANS_MEMBERS_LOADED = 'clansMembers/loaded'
export type ClansMembersLoadedAction = ReduxAction<typeof CLANS_MEMBERS_LOADED, ClansMembersModel>
export const clansMembersLoaded = makeActionCreator<ClansMembersModel>(CLANS_MEMBERS_LOADED)

interface ClanMemberRemovedPayload {
  userId: number
  clanId: number
}
export const CLAN_MEMBER_REMOVED = 'clansMembers/removed'
export type ClanMemberRemovedAction = ReduxAction<typeof CLAN_MEMBER_REMOVED, ClanMemberRemovedPayload>
export const clanMemberRemoved = makeActionCreator<ClanMemberRemovedPayload>(CLAN_MEMBER_REMOVED)

export type KnownAction = ClansMembersLoadedAction
  | AppUserLoadedAction
  | MembersPageLoadedAction
  | ClanMemberRemovedAction
  | ClanDeletedAction
  | MembersPagePlayersLoadedAction
  | MembersPageClansLoadedAction
  | ParticipantsLoadedAction
  | MatchParticipantsLoadedAction

export const getClanMemberRoleName = (role: ClanMemberRole) => {
  if (role === ClanMemberRole.Leader) {
    return 'Founder'
  }

  return 'Member'
}
