import React, { useEffect, useMemo, useRef, useState } from 'react'
import { cn } from 'utils'
import { Loading } from 'components/_shared'
import stylesDark from './SelectDark.module.scss'
import stylesLight from './SelectLight.module.scss'
import { SelectOption } from './SelectOption'
import { OpenedState, SelectProps } from './SelectTypes'
import { useThemeStyles } from 'components/_hooks'
import { InputError } from '../InputError/InputError'

export const Select = <T extends unknown>({
  withSearch,
  options,
  labelExtractor,
  valueExtractor,
  disabled,
  placeHolder,
  loading,
  containerClassName,
  alwaysOpenBelow = true,
  className,
  tooltipExtractor,
  optionTooltipIcon,
  tipPlace,
  closeOnBlur = true,
  valid = true,
  ...props
}: SelectProps<T>) => {
  const styles = useThemeStyles(stylesDark, stylesLight)

  disabled = loading || disabled
  const [opened, setOpened] = useState<OpenedState>('none')
  const [search, setSearch] = useState('')
  const selectContainerRef = useRef<HTMLDivElement>()
  const timeoutId = useRef<number>()

  const open = () => {
    if (alwaysOpenBelow) {
      setOpened('below')
    } else {
      const elem = selectContainerRef.current
      const containerBottom = elem.getBoundingClientRect().top + elem.scrollHeight
      const parentBottom = (elem.offsetParent as HTMLDivElement).offsetParent.getBoundingClientRect().bottom
      const isEnoughBelow = containerBottom <= parentBottom
      setOpened(isEnoughBelow ? 'below' : 'above')
    }
  }

  const close = () => {
    setOpened('none')
  }

  const isOpened = useMemo(() => {
    return opened !== 'none'
  }, [opened])

  const toggleIsOpened = () => {
    if (!disabled) {
      if (isOpened) {
        close()
      } else {
        open()
      }
    }
  }

  const handleSelect = (option: T) => {
    if (props.type === 'single') {
      close()
      if (props.required || !props.selected) {
        props.onChange(option)
      }
      else {
        if (valueExtractor(props.selected) === valueExtractor(option)) {
          props.onChange(undefined)
        } else {
          props.onChange(option)
        }
      }
    } else {
      const filteredOptions = props.selected.filter(x => valueExtractor(x) !== valueExtractor(option))
      if (filteredOptions.length !== props.selected.length) {
        props.onChange(filteredOptions)
      } else {
        props.onChange([...props.selected, option])
      }
    }
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value)
    event.preventDefault()
  }

  const isSelected = (option: T) => {
    const value = valueExtractor(option)
    if (props.type === 'single') {
      if (!props.selected) {
        return false
      }
      return valueExtractor(props.selected) === value
    }
    return props.selected.some(x => valueExtractor(x) === value)
  }

  const title = useMemo(() => {
    if (props.type === 'single') {
      if (!props.selected) {
        return placeHolder
      }
      return labelExtractor(props.selected)
    }
    if (props.selected.length === 0) {
      return placeHolder
    }
    return props.selected.map(x => labelExtractor(x)).join(', ')
  }, [props.selected, props.type, labelExtractor, placeHolder])

  const filteredOptions = useMemo(() => {
    if (!search) {
      return options
    }

    return options.filter(x => labelExtractor(x).toLowerCase().includes(search.toLowerCase()))
  }, [search, options, labelExtractor])

  useEffect(() => {
    if (!isOpened) {
      setSearch('')
    }
  }, [isOpened])

  const reverse = useMemo(() => {
    return opened === 'above'
  }, [opened])

  const hasSelected = props.type === 'single' ? props.selected !== undefined : props.selected.length > 0

  const handleBlur = () => {
    if (closeOnBlur) {
      timeoutId.current = setTimeout(close)
    }
  }

  const handleFocus = () => {
    clearTimeout(timeoutId.current)
  }

  return (
    <div className="position-relative">
      <div
        onBlur={handleBlur}
        onFocus={handleFocus}
        className={cn(!valid && styles.error, styles.selectWrapper, styles.container, isOpened && styles.opened, reverse && styles.reverse, containerClassName)}
        
      >
        <button className={cn(styles.title, hasSelected && styles.selected)} onClick={toggleIsOpened}>
          {loading
            ? <Loading size="small" />
            : (
              <div className={styles.text}>{title}</div>
            )
          }
          <div className={styles.arrow} />
        </button>
        <div className={cn(styles.listContainer, className)} ref={selectContainerRef}>
          {withSearch &&
            <div className={styles.searchContainer}>
              <input value={search} onChange={handleSearchChange} className={styles.search} placeholder="Search" />
            </div>
          }
          <div className={cn(styles.list, withSearch && styles.withSearch)}>
            {filteredOptions.map(x =>
              <SelectOption
                key={valueExtractor(x)}
                onSelect={handleSelect}
                option={x}
                labelExtractor={labelExtractor}
                selected={isSelected(x)}
                optionTooltipIcon={optionTooltipIcon}
                tipPlace={tipPlace}
                tooltipExtractor={tooltipExtractor}
              />
            )}
          </div>
        </div>
      </div>
      {!valid && <InputError text={`Please Select the ${placeHolder}`}/>}
    </div>
  )
}
