import React, { useEffect, useMemo } from 'react'

import { DropDownTable } from 'components/_library'
import { cn } from 'utils'
import styles from '../../Disputes.module.scss'
import { useOpenChat } from 'store/chat/chat/chat-hooks'
import { useDispatch } from 'react-redux'
import { useAppSelector, useAppUser } from 'components/_hooks'
import { loadDisputeChat } from 'store/chat/chat/chat-logic'
import { makeGetDisputeChat } from 'store/chat/chat/chat-selectors'


interface Props {
  disputeOpened: boolean
  matchId: number
}

const DisputeChatButton = ({disputeOpened, matchId}: Props) => {

  const openChat = useOpenChat()
  const dispatch = useDispatch()
  const user = useAppUser()

  useEffect(() => {
    if (user?.Id) {
      dispatch(loadDisputeChat(matchId))
    }
  }, [dispatch, matchId, user?.Id])

  const getDisputeChat = useMemo(makeGetDisputeChat, [])
  const chat = useAppSelector(state => getDisputeChat(state, {matchId: matchId}))

  const handleOpenChat = () => {
    if (chat?.Id) {
      openChat(chat.Id)
    }
  }

  if (!chat) {
    return (
      <>
      </>
    )
  }
  
  return (    
    <DropDownTable.ButtonLink
      onClick={handleOpenChat}
      color="grey"
      filled
      preventDefault
      className={cn(styles.tableButton, disputeOpened && styles.tableDisputeChat)}
      to="#"
    >
      DISPUTE CHAT
    </DropDownTable.ButtonLink>
  )
}

export default DisputeChatButton
