import * as React from 'react'
import { TournamentTicketViewModel } from '../../models/tournament-tickets-viewmodel'
import { TournamentUtils } from '../../server/utils/tournament-utils'
import { GeneralUtils } from '../../server/utils/general-utils'
import { TournamentCartUtils } from '../../server/utils/tournament-cart-utils'
const QRCode = require('qrcode.react')
import print from 'print-js'
import './TournamentTicket.scss'
import { defaultFormatDate, defaultFormatTime } from 'utils/dateUtils'
import { round2Places } from 'utils'
import {isMobileOnly,isTablet,isDesktop} from 'react-device-detect';
interface Props {
  ticketId: number
}

interface State {
  tournamentTickets: TournamentTicketViewModel
  tournamentTicket: TournamentTicketViewModel
  date: string
  time: string
  isQrCameraMode: boolean
  userId: number
  scannerError: any
}

class TournamentParticipentTicket extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      tournamentTickets: Object(),
      tournamentTicket: Object(),
      date: '',
      time: '',
      isQrCameraMode: false,
      userId: 0,
      scannerError: undefined,
    }
    this.getTournamentTicket = this.getTournamentTicket.bind(this)
    this.getData = this.getData.bind(this)
    this.handleScan = this.handleScan.bind(this)
    this.handleScanError = this.handleScanError.bind(this)
  }
  async componentDidMount() {
    await this.getData()
  }

  async getData() {
    this.getTournamentTicket()
  }

  downloadQR = (tournametTicket: TournamentTicketViewModel) => {
    var canvas = document.getElementById("QRCode" + tournametTicket.TournamentTicketQuantityId) as any;
    const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = tournametTicket.Name + ".png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  async getTournamentTicket() {
    const userId: string = localStorage.getItem('userid')
    if (userId && this.props.ticketId) {
      const result = await TournamentUtils.TournamentTicket(this.props.ticketId)
      if (!result.isError) {
        this.setState({ tournamentTickets: result.data, userId: parseInt(userId) }, () => {
        })
      } else {
        GeneralUtils.showError(result.message)
      }
    }
  }

  async handleScan(data) {
    let { tournamentTicket, userId, scannerError } = this.state
    if (data && !scannerError) {
      data = JSON.parse(data)
      tournamentTicket.TournamentTicketQuantityId = data && data.TournamentTicketQuantityId
      tournamentTicket.TournamentDetailId = data && data.TournamentDetailId && parseInt(data.TournamentDetailId)
      tournamentTicket.TicketDate = data && data.TicketDate
      tournamentTicket.TicketPrice = data && data.ticketPrice && parseInt(data.ticketPrice)
      tournamentTicket.TicketCartDetailId = data && data.TicketCartDetailId && parseInt(data.TicketCartDetailId)
      tournamentTicket.Quantity = data && data.Quantity && parseInt(data.Quantity)
      tournamentTicket.AttendenceCount = data && data.AttendenceCount && parseInt(data.AttendenceCount)
      tournamentTicket.DayNumber = data && data.DayNumber && parseInt(data.DayNumber)
      tournamentTicket.UserId = data && data.userId && parseInt(data.userId)
      tournamentTicket.TournamentStatusId = data && data.TournamentStatusId && parseInt(data.TournamentStatusId)
      tournamentTicket.SectionName = data && data.SectionName
      userId = data.userId
      if (tournamentTicket.Quantity) {
        if (tournamentTicket.AttendenceCount == null) {
          tournamentTicket.AttendenceCount = 0
        }
        if (tournamentTicket.AttendenceCount <= tournamentTicket.Quantity) {
          let attendencCount = parseInt(tournamentTicket.AttendenceCount.toString())
          attendencCount += 1
          const result = await TournamentCartUtils.UpdateCartAttendenceCount(
            parseInt(tournamentTicket.TicketCartDetailId.toString()),
            parseInt(tournamentTicket.Quantity.toString()),
            attendencCount
          )
          if (result.isError) {
            alert('Internal Server Error')
          }
        } else {
          alert('Ticket LimitReached')
        }
      } else {
        alert('You Did\'nt Purchase Any Ticket')
      }
      this.setState({ tournamentTicket, userId, isQrCameraMode: false })
    } else {
      console.log(scannerError)
    }
  }

  handleScanError = (err: any) => {
    this.setState({ scannerError: err })
  }

  printAll() {
    print('printView', 'html')
  }
  

  renderImageAsPerScreenSize(){
    const { tournamentTickets } = this.state
    if(isMobileOnly){
      return tournamentTickets.TicketImageThumbnail
    }
    else if(isTablet){
      return tournamentTickets.TicketImageSmall
    }
    if(isDesktop){
      return tournamentTickets.TicketImageMedium
    }
    else{
      return tournamentTickets.TicketImage
    }
  }
  render() {
    const { } = this.props
    const { tournamentTickets } = this.state
    return (
      <>
        <div className="tournament-ticket">
          <div className="row">
            <div className="col col-xs-12 col-sm-3 col-md-3 col-lg-2">
              <button
                className="bttn_blue-primery mb-3"
                onClick={() => {
                  this.printAll()
                }}
              >
                PRINT
              </button>
            </div>
            <div className="col col-xs-12 col-sm-3 col-md-3 col-lg-2">
              <button className="bttn_blue-primery mb-3" onClick={() => { this.downloadQR(tournamentTickets) }}>Download</button>
            </div>
          </div>
          <div id="printDiv">
          
             <div className="card" style={{ paddingBottom: '10px' }}>
              <div className="card-body" id={'print_div_'}>
                <div className="row">
                  <div className="col-md-9">
                    <div className="col-md-12 card-header">
                      <h5 className="card-title">
                        {tournamentTickets && tournamentTickets.Name}
                        {tournamentTickets.PlayerParticipant && (<small> (Participant Ticket)</small>)}
                        {!tournamentTickets.PlayerParticipant && tournamentTickets.DayNumber === null && (<small>( Full package)</small>)}
                        {!tournamentTickets.PlayerParticipant && tournamentTickets.DayNumber != null && tournamentTickets.TicketDate !== null && (<small>( {`Day ${tournamentTickets.DayNumber} Entry Ticket`})</small>)}
                      </h5>

                    </div>
                    <div className="col-md-12 d-flex card-text-wrapper">
                      <div className="col-md col-sm col-lg card-text">
                        <table>
                          <tbody>
                            <tr>
                              <td><label>Tournament #</label></td>
                            </tr>
                            <tr>
                              <td><label><span>{tournamentTickets && tournamentTickets.TournamentDetailId}</span></label></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="col-md col-sm col-lg card-text">
                        <table>
                          <tbody>
                            <tr>
                              <td><label>Date</label></td>
                            </tr>
                            <tr>
                              <td><span>{tournamentTickets.TicketDate && defaultFormatDate(tournamentTickets.TicketDate)}</span></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="col-md col-sm col-lg card-text">
                        <table>
                          <tbody>
                            <tr>
                              <td><label>Time</label></td>
                            </tr>
                            <tr>
                              <td><span>{tournamentTickets.TicketDate && defaultFormatTime(tournamentTickets.TicketDate)}</span></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="col-md col-sm col-lg card-text">
                        <table>
                          <tbody>
                            <tr>
                              <td><label>Venue</label></td>
                            </tr>
                            <tr>
                              <td><span>{tournamentTickets && tournamentTickets.VenueType}</span></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="col-md col-sm col-lg card-text">
                        <table>
                          <tbody>
                            <tr>
                              <td><label>Location</label></td>
                            </tr>
                            <tr>
                              <td><span>{tournamentTickets && tournamentTickets.VenueLocation}</span></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      {tournamentTickets?.SectionName && <div className="col-md col-sm col-lg card-text">
                        <table>
                          <tbody>
                            <tr>
                              <td><label>Section-Row-Seat</label></td>
                            </tr>
                            <tr>
                              <td><span>{tournamentTickets?.SectionName+'-'+tournamentTickets?.RowNumber+'-'+tournamentTickets?.SeatNumber}</span></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>}
                    </div>
                    <div className="col-md col-sm col-lg card-image" >
                      <img src={this.renderImageAsPerScreenSize()} />

                    </div>
                  </div>
                  <div className="col-md-3 barcode-wrapper">
                    <p className="barcode-title">#{tournamentTickets && tournamentTickets.TournamentTicketQuantityId}</p>
                    <div className="barcode">{tournamentTickets.TournamentDetailId && (
                      <div>
                        <QRCode
                          style={{ verticalAlign: 'middle', alignContent: 'center' }}
                          id={"QRCode" + tournamentTickets.TournamentTicketQuantityId}
                          value={JSON.stringify({
                            TournamentTicketQuantityId: tournamentTickets.TournamentTicketQuantityId,
                            TournamentDetailId: tournamentTickets.TournamentDetailId && tournamentTickets.TournamentDetailId,
                            TicketDate: tournamentTickets.TicketDate && tournamentTickets.TicketDate.toString(),
                            TicketCartDetailId: tournamentTickets.TicketCartDetailId,
                            Quantity: tournamentTickets.Quantity && tournamentTickets.Quantity,
                            DayNumber: tournamentTickets.DayNumber && tournamentTickets.DayNumber,
                            TournamentStatusId: tournamentTickets.TournamentStatusId && tournamentTickets.TournamentStatusId,
                            AttendenceCount: tournamentTickets.AttendenceCount ? tournamentTickets.AttendenceCount : 0,
                            userId: this.state.userId,
                            ticketPrice: tournamentTickets.PlayerParticipant
                              ? 'FREE'
                              : tournamentTickets.ClanMember
                                ? 'FREE'
                                : tournamentTickets.CurrencySymbol + " " + tournamentTickets.TicketPrice && tournamentTickets.TicketPrice.toString(),
                            Name: tournamentTickets.Name,
                            Username: tournamentTickets.Username,
                            CoverImage: tournamentTickets.CoverImage,
                            CoverImageThumbnail:tournamentTickets.CoverImageThumbnail,
                            CoverImageSmall:tournamentTickets.CoverImageSmall,
                            CoverImageMedium:tournamentTickets.CoverImageMedium

                          })}
                          size={160}
                          bgColor={'#ffffff'}
                          fgColor={'#000000'}
                          level={'L'}
                          includeMargin={true}
                          renderAs={'canvas'}
                        />
                      </div>
                    )}</div>


                    {tournamentTickets && tournamentTickets.PlayerParticipant ? (
                      <div className="ticket-fees">
                        <p>Free</p>
                      </div>
                    ) : tournamentTickets.ClanMember ? (
                      <div className="ticket-fees">
                        <p>Free</p>
                      </div>
                    ) : (
                      <div className="ticket-fees">
                        <p>{
                        tournamentTickets.UserPaymentId === 1?
                        tournamentTickets.CurrencySymbol + " " + round2Places(tournamentTickets.TicketPrice, 2)
                        :tournamentTickets.UserPaymentId === 2?
                        "$" + " " + round2Places(tournamentTickets.TicketPrice, 2)
                        :round2Places(tournamentTickets.TicketPrice, 2)+" LP"}</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        
          <div id="printView">
            <h1>
              {tournamentTickets && tournamentTickets.Name}
              {tournamentTickets.PlayerParticipant && (<small> (Participant Ticket)</small>)}
              {!tournamentTickets.PlayerParticipant && tournamentTickets.DayNumber === null && (<small>( Full package)</small>)}
              {!tournamentTickets.PlayerParticipant && tournamentTickets.DayNumber != null && tournamentTickets.TicketDate !== null && (<small>( {`Day ${tournamentTickets.DayNumber} Entry Ticket`})</small>)}
            </h1>
            <table style={{ width: '1500px' }}>
              <thead >
                <tr border="1px" bordercolor="#ccc">
                  <th style={{ width: '400px' }}>Tournament #</th>
                  <th style={{ width: '400px' }}>Date</th>
                  <th style={{ width: '400px' }}>Time</th>
                  <th style={{ width: '400px' }}>Vanue</th>
                  <th style={{ width: '400px' }}>Location</th>
                  {!tournamentTickets.PlayerParticipant && <th style={{ width: '400px' }}>Section-Row-Seat</th>}
                  <th style={{ width: '400px' }}>#{tournamentTickets && tournamentTickets.TournamentTicketQuantityId}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td align="center">{tournamentTickets && tournamentTickets.TournamentDetailId}</td>
                  <td align="center">{tournamentTickets.TicketDate && defaultFormatDate(tournamentTickets.TicketDate)}</td>
                  <td align="center">{tournamentTickets.TicketDate && defaultFormatTime(tournamentTickets.TicketDate)}</td>
                  <td align="center">{tournamentTickets && tournamentTickets.VenueType}</td>
                  <td align="center">{tournamentTickets && tournamentTickets.VenueLocation}</td>
                  {!tournamentTickets.PlayerParticipant && <td align="center">{tournamentTickets && tournamentTickets?.SectionName+'-'+tournamentTickets?.RowNumber+'-'+tournamentTickets?.SeatNumber}</td>}
                  <td align="center">{tournamentTickets.TournamentDetailId && (
                    <div>
                      <QRCode
                        style={{ verticalAlign: 'middle', alignContent: 'center' }}
                        id={"QRCode" + tournamentTickets.TournamentTicketQuantityId}
                        value={JSON.stringify({
                          TournamentTicketQuantityId: tournamentTickets.TournamentTicketQuantityId,
                          TournamentDetailId: tournamentTickets.TournamentDetailId && tournamentTickets.TournamentDetailId,
                          TicketDate: tournamentTickets.TicketDate && tournamentTickets.TicketDate.toString(),
                          TicketCartDetailId: tournamentTickets.TicketCartDetailId,
                          Quantity: tournamentTickets.Quantity && tournamentTickets.Quantity,
                          DayNumber: tournamentTickets.DayNumber && tournamentTickets.DayNumber,
                          TournamentStatusId: tournamentTickets.TournamentStatusId && tournamentTickets.TournamentStatusId,
                          AttendenceCount: tournamentTickets.AttendenceCount ? tournamentTickets.AttendenceCount : 0,
                          userId: this.state.userId,
                          ticketPrice: tournamentTickets.PlayerParticipant
                            ? 'FREE'
                            : tournamentTickets.ClanMember
                              ? 'FREE'
                              : tournamentTickets.UserPaymentId === 1?
                              tournamentTickets.CurrencySymbol + " " + round2Places(tournamentTickets.TicketPrice, 2).toString()
                              :tournamentTickets.UserPaymentId === 2?
                              "$" + " " + round2Places(tournamentTickets.TicketPrice, 2).toString()
                              :round2Places(tournamentTickets.TicketPrice, 2)+" LP",
                          Name: tournamentTickets.Name,
                          Username: tournamentTickets.Username,
                          CoverImage: tournamentTickets.CoverImage,
                          CoverImageThumbnail:tournamentTickets.CoverImageThumbnail,
                          CoverImageSmall:tournamentTickets.CoverImageSmall,
                          CoverImageMedium:tournamentTickets.CoverImageMedium

                        })}
                        size={160}
                        bgColor={'#ffffff'}
                        fgColor={'#000000'}
                        level={'L'}
                        includeMargin={true}
                        renderAs={'canvas'}
                      />
                    </div>
                  )}</td>

                </tr>
                <tr>
                  <td colSpan={6}></td>
                  <td align="center">{tournamentTickets && tournamentTickets.PlayerParticipant ? (
                    <div className="ticket-fees">
                      <p>Free</p>
                    </div>
                  ) : tournamentTickets.ClanMember ? (
                    <div className="ticket-fees">
                      <p>Free</p>
                    </div>
                  ) : (
                    <div className="ticket-fees">
                      <p>{tournamentTickets.UserPaymentId === 1?
                              tournamentTickets.CurrencySymbol + " " + round2Places(tournamentTickets.TicketPrice, 2).toString()
                              :tournamentTickets.UserPaymentId === 2?
                              "$" + " " + round2Places(tournamentTickets.TicketPrice, 2).toString()
                              :round2Places(tournamentTickets.TicketPrice, 2)+" LP"}</p>
                    </div>
                  )}</td>

                </tr>
              </tbody>

            </table>
            <img src={tournamentTickets.TicketImage} style={{ width: '400' }} />
          </div>

        </div>
      </>
    )
  }
}

export default TournamentParticipentTicket
