import { useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import { getIsActiveMatchesRequested, getIsCheckInMatchesRequested, getIsFinishedMatchesRequested, getIsPendingMatchesRequested } from 'store/selectors/match-selectors'
import { MatchParticipantModel, updateMatchParticipant } from 'store/types/match-participant-types'
import { activeMatchesLoaded, checkinMatchesLoaded, finishedMatchesLoaded, MatchesModel, MatchModel, pendingMatchesLoaded, setTournamentMatches } from 'store/types/match-types'
import { fetchApi } from '../../server/server-utils'
import { AppThunkAction, GetState } from '../types/common'
import { cancel, cancelAllByType, OperationTypes, run, useCancel, useRun } from './operations-logic'

interface CheckInModel {
  streamingLink: string
}

export interface MatchParticipantIdPlace {
  matchParticipantId: number
  place: number
}

interface FinishMatchDto {
  matchParticipantPlaces: MatchParticipantIdPlace[]
}

const fetchPendingMatches = () => {
  return fetchApi<MatchesModel>('match/pending', 'GET')
}

export const loadPendingMatches = async (dispatch: Dispatch, getState: GetState) => {
  if (getIsPendingMatchesRequested(getState())) {
    return
  }
  await run(OperationTypes.loadPendingMatches, null, dispatch, getState, async () => {
    try {
      const model = await fetchPendingMatches()
      dispatch(pendingMatchesLoaded(model))
    } catch (error) {
      return false
    }
    return true
  })
}

export const reloadPendingMatches = async (dispatch: Dispatch, getState: GetState) => {
  cancelAllByType(OperationTypes.loadMatchParticipants, dispatch, getState)
  cancelAllByType(OperationTypes.loadParticipants, dispatch, getState)
  cancel(OperationTypes.loadPendingMatches, null, dispatch, getState)
  await loadPendingMatches(dispatch, getState)
}

export const useReloadPendingMatches = () => {
  const cancelLoadPendingMatches = useCancel(OperationTypes.loadPendingMatches, null)
  const cancelLoadMatchParticipants = useCancel(OperationTypes.loadMatchParticipants, null)
  const cancelLoadParticipants = useCancel(OperationTypes.loadParticipants, null)
  const dispatch = useDispatch()
  return useRun(OperationTypes.loadPendingMatches, null, async () => {
    cancelLoadPendingMatches()
    cancelLoadMatchParticipants()
    cancelLoadParticipants()
    const model = await fetchPendingMatches()
    dispatch(pendingMatchesLoaded(model))
    return true
  })
}

export const loadCheckInMatches = async (dispatch: Dispatch, getState: GetState) => {
  if (getIsCheckInMatchesRequested(getState())) {
    return
  }
  await run(OperationTypes.loadCheckInMatches, null, dispatch, getState, async () => {
    try {
      const matches = await fetchApi<MatchesModel>('match/checkin', 'GET')
      dispatch(checkinMatchesLoaded(matches))
    } catch (error) {
      return false
    }
    return true
  })
}

export const reloadCheckInMatches = async (dispatch: Dispatch, getState: GetState) => {
  cancel(OperationTypes.loadCheckInMatches, null, dispatch, getState)
  cancelAllByType(OperationTypes.loadMatchParticipants, dispatch, getState)
  cancelAllByType(OperationTypes.loadParticipants, dispatch, getState)
  await loadCheckInMatches(dispatch, getState)
}

export const loadActiveMatches = async (dispatch: Dispatch, getState: GetState) => {
  if (getIsActiveMatchesRequested(getState())) {
    return
  }
  await run(OperationTypes.loadActiveMatches, null, dispatch, getState, async () => {
    try {
      const model = await fetchApi<MatchesModel>('match/active', 'GET')
      dispatch(activeMatchesLoaded(model))
    } catch (error) {
      return false
    }
    return true
  })
}

export const reloadActiveMatches = async (dispatch: Dispatch, getState: GetState) => {
  cancel(OperationTypes.loadActiveMatches, null, dispatch, getState)
  cancelAllByType(OperationTypes.loadMatchParticipants, dispatch, getState)
  cancelAllByType(OperationTypes.loadParticipants, dispatch, getState)
  cancelAllByType(OperationTypes.loadMatchSubmissions, dispatch, getState)
  await loadActiveMatches(dispatch, getState)
}

export const loadFinishedMatches = async (dispatch: Dispatch, getState: GetState) => {
  if (getIsFinishedMatchesRequested(getState())) {
    return
  }
  await run(OperationTypes.loadFinishedMatches, null, dispatch, getState, async () => {
    try {
      const model = await fetchApi<MatchesModel>('match/finished', 'GET')
      dispatch(finishedMatchesLoaded(model))
    } catch (error) {
      return false
    }
    return true
  })
}

export const reloadFinishedMatches = async (dispatch: Dispatch, getState: GetState) => {
  cancel(OperationTypes.loadFinishedMatches, null, dispatch, getState)
  cancelAllByType(OperationTypes.loadMatchParticipants, dispatch, getState)
  cancelAllByType(OperationTypes.loadParticipants, dispatch, getState)
  cancelAllByType(OperationTypes.loadMatchSubmissions, dispatch, getState)
  await loadFinishedMatches(dispatch, getState)
}

export const useFinishMatch = (matchId: number) => {
  return useRun(OperationTypes.finishMatch, matchId, async (dto: FinishMatchDto) => {
    await fetchApi(`match/${matchId}/finish`, 'POST', dto)
    return true
  })
}

export const matchesActionCreators = {
  checkIn: (matchId: number, model: CheckInModel): AppThunkAction => async (dispatch: Dispatch, getState: GetState) => {
    await run(OperationTypes.checkIn, matchId, dispatch, getState, async () => {
      const matchParticipant = await fetchApi<MatchParticipantModel>(`match/${matchId}/checkin`, 'POST', model)
      dispatch(updateMatchParticipant({ matchParticipant }))
      return true
    })
  },
  loadTournamentMatches: (tournamentDetailId: number): AppThunkAction => async (dispatch: Dispatch, getState: GetState) => {
    await run(OperationTypes.loadTournamentMatches, tournamentDetailId, dispatch, getState, async () => {
      const matches = await fetchApi<MatchModel[]>(`match/all?tournamentDetailId=${tournamentDetailId}`, 'GET')
      dispatch(setTournamentMatches({ Matches: matches }))
      return true
    })
  },
}
