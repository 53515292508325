import App from './App/App'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import configureStore from './store/configureStore'
import { BrowserRouter } from 'react-router-dom'
import { stopReportingRuntimeErrors } from 'react-error-overlay'

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  stopReportingRuntimeErrors()
}


const store = configureStore()

const AppComponent = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  )
}

ReactDOM.render(
  <AppComponent />,
  document.getElementById('root')
)
