import React, { ReactNode } from 'react'
import { cn } from 'utils'
import { useDropDownTableStyles } from './styles/useDropDownTableStyles'

export interface DropDownTableSecondaryRowContainerProps {
  opened: boolean
  children?: ReactNode
  className?: string
}

export const DropDownTableSecondaryRowContainer = ({ opened, children, className }: DropDownTableSecondaryRowContainerProps) => {
  const styles = useDropDownTableStyles()

  return (
    <>
      <div className={cn('row', styles.tableSecondaryRowContainer, className, opened && styles.secondaryRowOpened)}>
        <div className="container-fluid">{children}</div>
      </div>
    </>
  )
}
