import { Reducer } from 'redux'
import { KnownAction, State } from 'store/types/line-up-types'
import { calcById, calcByIdWithoutIds } from 'utils'
import { getNewObjectWithArray, groupArrayToObject } from 'utils/array-utils'


export const initialLineUpsState: State = {
  lineUpIdsByParticipantId: {},
  byId: {},
}


const reducer: Reducer<State, KnownAction> = (state: State | undefined = initialLineUpsState, action: KnownAction) => {
  switch (action.type) {
    case 'LINE_UPS_LOADED': {
      return {
        ...state,
        byId: calcById(state,  action.payload.LineUps),
        lineUpIdsByParticipantId: {
          ...state.lineUpIdsByParticipantId,
          ...groupArrayToObject(action.payload.LineUps, key => key.TournamentParticipantId, res => res.Id),
        },
      }
    }
    case 'LINE_UP_ADDED': {
      return {  
        ...state,
        byId: calcById(state, [action.payload.lineUp]),
        lineUpIdsByParticipantId: {
          ...state.lineUpIdsByParticipantId,
          ...getNewObjectWithArray(state.lineUpIdsByParticipantId, [action.payload.lineUp], key => key.TournamentParticipantId, res => res.Id),
        },      
      }
    }
    case 'LINE_UP_DELETED': {
      return {
        ...state,
        lineUpsById: calcByIdWithoutIds(state, [action.payload.lineUp.Id]),
        lineUpIdsByParticipantId: {
          ...state.lineUpIdsByParticipantId,
          [action.payload.lineUp.TournamentParticipantId]: state.lineUpIdsByParticipantId[action.payload.lineUp.TournamentParticipantId]
            .filter(id => id != action.payload.lineUp.Id),
        },
      }
    }
    default:
      return state
  }
}

export default reducer
