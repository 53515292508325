import { createSelector, ParametricSelector, Selector, OutputSelector, OutputParametricSelector } from 'reselect'
import { ApplicationState } from '../../store/types/common'
import { OperationStatus } from '../../store/types/operations-types'
import { State } from '../../store/types/operations-types'

export type outType1<Tout = boolean> = OutputSelector<ApplicationState, Tout, (res1: State | undefined, res2: { type: string; objectId: any }) => Tout>
export type outType2<T, Tout = boolean> = OutputParametricSelector<
  ApplicationState,
  T,
  Tout,
  (res1: State | undefined, res2: { type: string; objectId: any }) => Tout
>
export type outType3<Tout = boolean> = OutputSelector<ApplicationState, Tout, (res1: State | undefined, res2: { type: string }) => Tout>
export type outType4<T, Tout = boolean> = OutputParametricSelector<ApplicationState, T, Tout, (res1: State | undefined, res2: { type: string }) => Tout>

const getState = (state: ApplicationState) => state.operations

export function makeGetIsOperationWithTypeSuccessOrRunning(getOperation: Selector<ApplicationState, { type: string; objectId: any }>): outType1
export function makeGetIsOperationWithTypeSuccessOrRunning<T>(
  getOperation: ParametricSelector<ApplicationState, T, { type: string; objectId: any }>
): outType2<T>
export function makeGetIsOperationWithTypeSuccessOrRunning(getOperation: any) {
  return createSelector(
    [getState, getOperation],
    (state, { type, objectId }) =>
      !!(
        state &&
        state.list.find(
          op =>
            (op.status === OperationStatus.Running || op.status === OperationStatus.Success) &&
            op.type === type &&
            op.objectId === objectId &&
            !op.canceled
        )
      )
  )
}

export function makeGetIsOperationWithTypeRunning(getOperation: Selector<ApplicationState, { type: string; objectId: any }>): outType1
export function makeGetIsOperationWithTypeRunning<T>(
  getOperation: ParametricSelector<ApplicationState, T, { type: string; objectId: any }>
): outType2<T>
export function makeGetIsOperationWithTypeRunning(getOperation: any) {
  const a = createSelector(
    [getState, getOperation],
    (state, { type, objectId }) =>
      !!(state && state.list.find(op => op.status === OperationStatus.Running && op.type === type && op.objectId === objectId && !op.canceled))
  )
  return a
}

export function makeGetIsAnyOperationWithTypeRunning(getOperation: Selector<ApplicationState, { type: string }>): outType3
export function makeGetIsAnyOperationWithTypeRunning<T>(getOperation: ParametricSelector<ApplicationState, T, { type: string }>): outType4<T>
export function makeGetIsAnyOperationWithTypeRunning(getOperation: any) {
  const a = createSelector(
    [getState, getOperation],
    (state, { type }) => !!(state && state.list.find(op => op.status === OperationStatus.Running && op.type === type && !op.canceled))
  )
  return a
}

export function makeGetIsOperationWithTypeSuccess(getOperation: Selector<ApplicationState, { type: string; objectId: any }>): outType1
export function makeGetIsOperationWithTypeSuccess<T>(
  getOperation: ParametricSelector<ApplicationState, T, { type: string; objectId: any }>
): outType2<T>
export function makeGetIsOperationWithTypeSuccess(getOperation: any) {
  return createSelector(
    [getState, getOperation],
    (state, { type, objectId }) =>
      !!(state && state.list.find(op => op.status === OperationStatus.Success && op.type === type && op.objectId === objectId && !op.canceled))
  )
}

export function makeGetIsOperationWithTypeTimeLastSuccess(
  getOperation: Selector<ApplicationState, { type: string; objectId: any }>
): outType1<Date | undefined>
export function makeGetIsOperationWithTypeTimeLastSuccess<T>(
  getOperation: ParametricSelector<ApplicationState, T, { type: string; objectId: any }>
): outType2<T, Date | undefined>
export function makeGetIsOperationWithTypeTimeLastSuccess(getOperation: any) {
  return createSelector(
    [getState, getOperation],
    (state, { type, objectId }) =>
      state && state.list.find(op => op.status === OperationStatus.Success && op.type === type && op.objectId === objectId && !op.canceled)?.timeStop
  )
}

export const getOperations = createSelector(getState, state => state?.list || [])
