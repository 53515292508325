import { NotificationVisibility } from 'consts'
import { createSelector } from 'reselect'
import { OperationTypes } from '../logic/operations-logic'
import { ApplicationState } from '../types/common'
import {
  makeGetIsAnyOperationWithTypeRunning,
  makeGetIsOperationWithTypeRunning,
  makeGetIsOperationWithTypeSuccessOrRunning,
} from './operations-selectors'

const getState = (state: ApplicationState) => state.notifications

const getNotificationsById = createSelector(getState, state => state?.notificationsById || {})

const getNotificationsIds = createSelector(getState, state => state?.notificationsIds || [])

const getNotificationsQueueIds = createSelector(getState, state => state?.notificationQueueIds || [])

export const getNotifications = createSelector(getNotificationsById, getNotificationsIds, (notifications, ids) =>
  ids.map(id => notifications[id]).filter(x => x.Visibility === NotificationVisibility.Visible).sort((a, b) => b.Id - a.Id)
)

export const getNotificationsLoading = makeGetIsAnyOperationWithTypeRunning(() => ({
  type: OperationTypes.loadNotifications,
}))
export const getNotificationsRequested = makeGetIsOperationWithTypeSuccessOrRunning(() => ({
  type: OperationTypes.loadNotifications,
  objectId: null,
}))

export const getAllNotificationsCount = createSelector(getState, state => state?.allNotificationsCount || 0)

export const getUnreadNotificationsCount = createSelector(getState, state => state?.unreadNotificationsCount || 0)
export const getUnreadNotificationsCountRequested = makeGetIsOperationWithTypeSuccessOrRunning(() => ({
  type: OperationTypes.loadUnreadNotificationsCount,
  objectId: null,
}))

const getNotificationsLoadingOperation = createSelector(
  (_: ApplicationState, id: number) => id,
  id => ({ type: OperationTypes.readNotification, objectId: id })
)
export const makeGetReadNotificationLoading = () => makeGetIsOperationWithTypeRunning(getNotificationsLoadingOperation)


export const makeGetLastNotification = () => createSelector(
  getNotificationsById,
  getNotificationsIds, 
  (notifications, ids) =>
    ids.map(id => notifications[id]).filter(x => x.Visibility === NotificationVisibility.Visible).sort((a, b) => b.Id - a.Id)[0]
)


export const makeGetQueueUnreadNotification = () => createSelector(
  getNotificationsById,
  getNotificationsQueueIds,
  (notifications, ids) => {
    if (!ids || ids.length == 0) {
      return
    }
    return notifications[ids[0]] 
  }
)


export const getIsNotificationQueueRequested = makeGetIsOperationWithTypeSuccessOrRunning(() => ({
  type: OperationTypes.loadNotificationQueue,
  objectId: null,
}))
