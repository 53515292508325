import { ContainerName, useTournamentParticipant } from 'components/_hooks'
import ParticipantOnlineAvatar from 'components/_shared/ParticipantAvatar/ParticipantOnlineAvtar'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { getParticipantLineUps } from 'store/selectors/line-ups-selectors'
import { ApplicationState } from 'store/types/common'
import { ParticipantType } from 'store/types/participants-types'
import { cn } from 'utils'
import LineUpRow from './LineupRow'

interface OwnProps {
  participant: ParticipantType
}

const LineUpsRow = ({ participant }: OwnProps) => {

  const [isShowDetail, setIsShowDetail] = useState(false)
 
  const lineUps = useSelector((state:ApplicationState) => getParticipantLineUps(state, participant.Id))
   var playerlineUps = lineUps.filter(x=>x.IsSubstitute!==true)
   var substituteLineups = lineUps.filter(x=>x.IsSubstitute==true)
   var clanLineUps = playerlineUps.concat(substituteLineups)
   const { IsBot } = useTournamentParticipant(participant?.Id)
  return (
    <div className="m-checked_i">
      <div className="m-checked_i_main">
        <div className="m-checked_row">
          <div className="m-checked_col flex-grow-1">
            <a href="#" className="prsn">
              <div className="prsn_l">
                <ParticipantOnlineAvatar
                  participantId={participant.Id}
                  withLink={!IsBot}
                  className="prsn_icon"
                  parentClassName=""
                  containerName={ContainerName.AvatarsNormal}
                />
              </div>
              <div className="prsn_r">
                <span className="prsn_ttl">{!IsBot ?participant.Clan?.Name:'By-Pass'}</span>
              </div>
            </a>
          </div>
          {!IsBot && <div className="m-checked_col">
            <button
              className={cn('btn-open-drop', 'm-checked_open-drop', isShowDetail && 'active')}
              type="button"
              onClick={() => setIsShowDetail(!isShowDetail)}
            />
          </div>}
        </div>
      </div>
      {!IsBot && <div className={cn('m-checked_i_drop', isShowDetail && 'open')}>
        <div className="m-checked_list">
          {clanLineUps.map(lineUp => (
            <LineUpRow lineUp={lineUp} key={lineUp.Id} />            
          ))}
        </div>
      </div>}
    </div>
  )
}

export default LineUpsRow
