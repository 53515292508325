import { createSelector } from 'reselect'
import { OperationTypes } from 'store/logic/operations-logic'
import { makeGetIsOperationWithTypeRunning, makeGetIsOperationWithTypeSuccessOrRunning } from 'store/selectors/operations-selectors'

const geConnectToChatLoadingOperationType = createSelector(
  (_, chatId: number) => chatId,
  chatId => ({ type: OperationTypes.connectToChat, objectId: chatId })
)
export const makeGetIsConnectToChatLoading = () => makeGetIsOperationWithTypeRunning(geConnectToChatLoadingOperationType)
export const makeGetIsConnectToChatRequested = () => makeGetIsOperationWithTypeSuccessOrRunning(geConnectToChatLoadingOperationType)

const geDisconnectFromChatLoadingOperationType = createSelector(
  (_, chatId: number) => chatId,
  chatId => ({ type: OperationTypes.disconnectFromChat, objectId: chatId })
)
export const makeGetIsDisconnectFormChatLoading = () => makeGetIsOperationWithTypeRunning(geDisconnectFromChatLoadingOperationType)
export const makeGetIsDisconnectFormChatRequested = () => makeGetIsOperationWithTypeSuccessOrRunning(geDisconnectFromChatLoadingOperationType)
