import React, { ReactNode } from 'react'
import styles from './ChatInputLabel.module.scss'

export interface ChatInputLabelProps {
  children: ReactNode
}

export const ChatInputLabel = ({ children }:  ChatInputLabelProps) => {
  return (
    <div className="row m-0">
      <h5 className={styles.chatHeader}>{children}</h5>
    </div>
  )
}
