import { ButtonColor } from 'components/_shared'
import React, { ReactNode } from 'react'
import { Modal } from '../Modal'
import styles from './ConfirmModal.module.scss'

export interface ConfirmModalProps {
  onClose: CallableFunction
  header: ReactNode
  subHeader: ReactNode
  asyncOperation: () => Promise<boolean>
  buttonColor: ButtonColor
}

export const ConfirmModal = ({
  onClose,
  asyncOperation,
  buttonColor,
  header,
  subHeader,
}:  ConfirmModalProps) => {
  return (
    <Modal
      onClose={onClose}
      allowOk
      asyncOperation={asyncOperation}
      buttonColor={buttonColor}
      className={styles.window}
    >
      <Modal.Header className={styles.windowHeader}>
        {header}
      </Modal.Header>
      <Modal.SubHeader className={styles.windowSubheader}>
        {subHeader}
      </Modal.SubHeader>
    </Modal>
  )
}
