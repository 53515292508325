import React from 'react'
import styles from './Home.module.scss'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const testimonials = [
  {
    name: "Rohit Barbaruah",
    role: "Admin",
    org: "PixelScope entertainment",
    text: "Awesome experience working with this team. Thanks for providing such a great service."
  },
  {
    name: "Slowblade",
    role: "Owner",
    org: "Raptor X eSports",
    text: "Seriously, creating a tournament event can't be any easier then this."
  },
  {
    name: "DSK",
    role: "IGL",
    org: "HunterZ",
    text: "Great management, easy to use solution"
  },
  {
    name: "Sarthak",
    role: "IGL",
    org: "Vision",
    text: "One of the best organization out there!"
  },
  {
    name: "Manoj Shetty",
    role: "Admin",
    org: "Villager lite",
    text: "Using their event ticketing solution saved us so much time."
  },
  {
    name: "Killmonger",
    role: "IGL",
    org: "Gratify",
    text: "Awesome match up mechanics and bracket generator!"
  },
  {
    name: "Regan",
    role: "Owner",
    org: "FUTV",
    text: "Cool looking wesbite and the team behnd this is super nice"
  },
  {
    name: "Manoj Shetty",
    role: "Admin",
    org: "Villager lite",
    text: "Using their event ticketing solution saved us so much time."
  },
  {
    name: "Pratyanush Saha",
    role: "Owner",
    org: "Team Vanity",
    text: "Signing up the team for events was seamless."
  },
  {
    name: "Sashank",
    role: "Owner",
    org: "Weird Esports",
    text: "Tracking and making last minute changes to all of our events was so easy. Great product!"
  },
  {
    name: "Sagar Paul",
    role: "Owner",
    org: "DSC",
    text: `Website is pretty good, and the management team is good too!
    registration process in the website is pretty smooth and very active!`
  },
]

const options = {
  loop: true,
  margin: 10,
  dots: false,
  autoplay: true,
  responsiveClass: true,
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 1,
    },
    600: {
      items: 2,
    },
    1000: {
      items: 3,
    }
  },
};

function HomeTestimonials() {
  const cdn = 'https://kombatlink-assets.azureedge.net/img'
  return (
    <div className={styles.content_wrapper + " m-0 fullpage-content " + styles.testi_wrap}>
      <div className="text-center" data-aos="zoom-in">
        <h1 className={styles.stayTitle}>SEE WHAT OUR TOs HAVE TO SAY</h1>
        <h2 className={styles.testimonialTitle}>Testimonials</h2>
      </div>
      <OwlCarousel className='owl-theme' {...options}>
        {testimonials.map((testimonial, index) => (
          <div key={'testimonials' + index} className={"item " + styles.testi_box} data-aos="zoom-in">
            <div className={styles.testi_txt}>
              <p><img src={`${cdn}/inv.png`} alt="inv" /></p>
              <p>{testimonial.text}</p>
              <h3>{testimonial.name}</h3>
              <p className={styles.tag}>
                {testimonial.role && <>{testimonial.role}, </>}{testimonial.org}</p>
            </div>
          </div>
        ))}
      </OwlCarousel>
    </div>
  )
}

export default HomeTestimonials