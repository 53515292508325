import { isTwoPlayersStructure, TournamentStructureEnum } from 'consts/TournamentStructure'
import React, { useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { ApplicationState, BoundActions } from 'store/types/common'
import { ConfirmSubmitModal } from '../ConfirmSubmitModal/ConfirmSubmitModal'
import styles from './SubmissionModal.module.scss'
import { matchesSumbissionsActionCreators } from 'store/logic/match-submissions-logic'
import { ParticipantSubmissionModel, ScreenshotType } from 'store/types/match-submissions-types'
import { Modal, ModalType, ModalWindowAnimationType } from 'components/_modals'
import { cn } from 'utils'
import { ImageTypeEnum } from 'store/types/image-types'
import { LoadingBox } from 'components/_shared/Loading'
import { useUploadImage } from 'store/logic/image-logic'
import Screenshot from './Screenshot'
import ImgPreview from './ImgPreview'
import { participantsActionCreators } from 'store/logic/participants-logic'

export enum SubmissionModalType {
  Submission = 0,
  Dispute = 1,
  Readonly = 2
}

export interface SubmissionModalProps {
  closeDialog: CallableFunction
  submission?: ParticipantSubmissionModel
  participantsLength: number
  tournamentStructure: TournamentStructureEnum
  matchId: number
  type: SubmissionModalType
}


type DispatchProps = BoundActions<typeof participantsActionCreators>

type Props = SubmissionModalProps & DispatchProps

const SubmissionModal = ({
  closeDialog,
  submitResult,
  submission,
  matchId,
  participantsLength,
  tournamentStructure,
  type,
}: Props) => {
  const [isOperationInProgress, setOperationInProgress] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(type === SubmissionModalType.Submission)
  const [evidenceLink, setEvidenceLink] = useState(submission?.EvidenceLink || '')
  const [isWon, setIsWon] = useState(submission?.IsFirstPlace ?? null)
  const [place, setPlace] = useState(submission?.Place ?? null)
  const [files, setFiles] = useState<ScreenshotType[]>([])
  const [notes, setNotes] = useState(submission?.Notes || '')  
  const [isImageLoading, setIsImageLoading] = useState(false)


  React.useEffect(() => {
    if (submission?.ScreenshotIds?.length > 0) {
      setFiles(submission.ScreenshotIds.map<ScreenshotType>(scr => ({ imageId: scr, name: scr.toString()})))
    }
  }, [submission?.ScreenshotIds])

  const placementOptions = useMemo(() => {
    if (participantsLength > 0) {
      return Array(participantsLength)
        .fill(0)
        .map((_, ind) => ind + 1)
    }
    return []
  }, [participantsLength])

  const onSubmit = async () => {
    await submitResult(matchId, {
      isWon,
      evidenceLink,
      place,
      imageIds: files.map(f => f.imageId),
      notes,
    })
    return true
  }

  const isSelectPlaceEnabled = useMemo(() => {
    return !isTwoPlayersStructure(tournamentStructure)
  }, [tournamentStructure])

  const canSubmit = useMemo(() => {
    return (isSelectPlaceEnabled || isWon === true || isWon === false)
     && (!isSelectPlaceEnabled || place != null)  
     && (type !== SubmissionModalType.Dispute || !!evidenceLink || files.length > 0)
  }, [isSelectPlaceEnabled, isWon, place, type, evidenceLink, files.length])

  const uploadImage = useUploadImage()

  const onChangeFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files[0]
    setIsImageLoading(true)
    const imageModel = await uploadImage(file, ImageTypeEnum.Evidence)
    setFiles([
      ...files, 
      {
        imageId: imageModel.Id,
        name: file.name,
      }])
    setIsImageLoading(false)
  }

  const onRemoveFile = file => {
    const index = files.indexOf(file)
    if (index >= 0) {
      setFiles([...files.slice(0, index), ...files.slice(index + 1)])
    }
  }

  const keyValueExtractor = (option: number) => option

  const handleConfirm = () => {
    setShowConfirmation(false)
  }

  const isReadOnly = useMemo(() => {
    return type === SubmissionModalType.Readonly
  }, [type])

  const placementLabel = useMemo(() => {
    if (isSelectPlaceEnabled) {
      return isReadOnly ? 'Placement' : 'Select Placement'
    }
    return isReadOnly ? 'WIN or LOSE' : 'Did you WIN or LOSE?'
  }, [isSelectPlaceEnabled, isReadOnly])

  const header = useMemo(() => {
    switch (type) {
      case SubmissionModalType.Submission :
        return 'Please indicate the result below when you are able to'
      case SubmissionModalType.Dispute: 
        return 'The match is in dispute, please resubmit your results'
      case SubmissionModalType.Readonly:
        return 'Submission'  
      default:
        return ''
    }
  }, [type])

  const warnining = useMemo(() => {
    switch (type) {
      case SubmissionModalType.Submission :
        return 'Uploading evidence is optional but if your opponent disputes the result you don’t need to re-submit this evidence.'
      case SubmissionModalType.Dispute: 
        return 'Uploading evidence is required. If no evidence is provided, then this dispute ticket will be disregarded.'
      case SubmissionModalType.Readonly:
        return undefined
      default:
        return undefined
    }
  }, [type])

  return (
    <Modal.Container>
      <Modal.Background onClose={closeDialog} disabled={isOperationInProgress} />
      <ConfirmSubmitModal show={showConfirmation} onClose={closeDialog} onConfirm={handleConfirm} />
      <Modal
        onClose={closeDialog}
        allowOk={canSubmit}
        asyncOperation={onSubmit}
        buttonColor="blue"
        okButtonText="Submit result"
        hideOk={isReadOnly}
        cancelButtonText={isReadOnly ? 'Close' : 'Cancel'}
        className={cn(styles.submissionModal, styles.window__big)}
        type={ModalType.animated}
        animationType={ModalWindowAnimationType.next}
        show={!showConfirmation}
        onOperationInProgress={setOperationInProgress}
      >
        <Modal.Header>{header}</Modal.Header>
        <Modal.SubHeader>{placementLabel}</Modal.SubHeader>
        {isSelectPlaceEnabled ? (
          <Modal.Select
            options={placementOptions}
            keyExtractor={keyValueExtractor}
            valueExtractor={keyValueExtractor}
            onChange={setPlace}
            selectedOption={place}
            disabled={isReadOnly}
          />
        ) : (
          <div className="row">
            <Modal.Radio id="win" value="yes" checked={isWon === true} onChange={() => setIsWon(true)} disabled={isReadOnly}>
              Won
            </Modal.Radio>
            <Modal.Radio id="lose" value="no" checked={isWon === false} onChange={() => setIsWon(false)} disabled={isReadOnly}>
              Lost
            </Modal.Radio>
          </div>
        )}
        <Modal.Hr />
        <Modal.SubHeader>{isReadOnly ? 'Notes' : 'You may leave a notes for your match here'}</Modal.SubHeader>
        <Modal.Textarea value={notes} onChange={setNotes} placeholder={isReadOnly ? '' : 'Enter your comment here...'} disabled={isReadOnly} />
        <Modal.SubHeader>Evidence link</Modal.SubHeader>
        <Modal.Input placeholder={isReadOnly ? '' : 'Enter link here...'} onChange={setEvidenceLink} value={evidenceLink} disabled={isReadOnly} />
        <div className="row">
          {!isReadOnly && (
            <>
              <LoadingBox size="small" loading={isImageLoading}>
                <Modal.FileInput
                  label="Upload screenshots (max 5)"
                  onChange={onChangeFile}
                  disabled={files && files.length >= 5} 
                />
              </LoadingBox>
              <div className="col-12">
                {files && files.length > 0 && (
                  <div className="row" style={{ margin: '-5px -15px 40px 0' }}>
                    {files &&
                      files.map((file, index) => (
                        <Screenshot screenshot={file} key={index} onRemove={onRemoveFile} />
                      ))}
                  </div>
                )}
              </div>
            </>
          )}
        
        </div>        
        {type === SubmissionModalType.Readonly && submission?.ScreenshotIds?.length > 0 && (
          <>
            <Modal.SubHeader>Screenshots</Modal.SubHeader>
            <div className={styles.screenshotsPreview}>
              {submission.ScreenshotIds.map((imageId, ind) => (
                <ImgPreview key={ind} imageId={imageId} />
              ))}
            </div>            
          </>
        )}
        {warnining && (
          <Modal.Warning>{warnining}</Modal.Warning>
        )}
      </Modal>
    </Modal.Container>
  )
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  ...bindActionCreators(matchesSumbissionsActionCreators, dispatch),
})

export default connect<{}, DispatchProps, SubmissionModalProps, ApplicationState>(null, mapDispatchToProps)(SubmissionModal)
