import * as React from 'react'
import { Link } from 'react-router-dom'
import styles from '../auth/Login.module.scss'

class TermsOfService extends React.Component {
  render() {
    return (
      <>
        <div className="background" />
        <div className="container-fluid">
          <div className="row justify-content-end back-container">
            <Link to="/" className="back">
              Back
            </Link>
          </div>
        </div>
        <article className="d-flex flex-row align-items-center">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1 className={styles.signIn__header}>Terms of Use</h1>
                <h2 className={styles.signIn__subheader}>Last Modified on April 1, 2020</h2>
                <br />
                <div className="additional-info mb-5">
                  <p>
                    IMPORTANT: PLEASE READ THE TERMS OF USE CAREFULLY BEFORE CONTINUING TO USE THIS WEBSITE, MOBILE APPLICATION OR ASSOCIATED
                    SERVICES.
                  </p>
                  <p>
                    SECTION 25 OF THIS TERMS OF USE AGREEMENT CONTAINS A BINDING CLASS ACTION WAIVER. THIS SECTION MAY AFFECT YOUR RIGHTS ABOUT HOW TO
                    RESOLVE DISPUTES THAT YOU MAY HAVE WITH US. READ IT CAREFULLY.
                  </p>
                  <p>
                    This Terms of Use (the “Agreement”) is a legal agreement between you as an individual and The Song Group Inc. (“Kombatlink”),
                    whose principal place of business is at 7111 Syntex Dr., 3rd Floor, Mississauga, Ontario L5N-8C3, Canada. It governs your access
                    to, conduct in, and other terms and conditions relating to our operation and your use of the “Kombatlink” platform, which includes
                    the www.kombatlink.com website, the Kombatlink mobile application, the Kombatlink marketplace, any social components (such as
                    on-line communities, player matches, user-to-user communication, and chat rooms), as well as other elements that may be added from
                    time to time (collectively, the “Service”).
                  </p>

                  <ol className="nested-list unindented-list">
                    <li>
                      <h3 className="subheader inline-block">THIS AGREEMENT</h3>
                      <p>
                        By using the Service, you agree to be bound by the terms of this Agreement. This Agreement represents the entire agreement
                        concerning the Service between you and Kombatlink, and it supersedes any prior proposal, representation, agreement,
                        negotiation, offer, counteroffer, acceptance, understanding, or any contrary terms between the parties. If you do not agree to
                        the terms of this Agreement, you are not authorized to use the Service, should immediately delete your account, and cease all
                        use of the Service.
                      </p>
                      <p>
                        We reserve the right to change these Terms from time to time with or without notice to you. You acknowledge and agree that it
                        is your responsibility to periodically review the Service and these Terms. Your continued use of the Service after such
                        modifications will constitute acknowledgment and acceptance of the modified Terms.
                      </p>
                      <p>
                        BY USING THE SERVICE, YOU AGREE TO BE BOUND BY THESE TERMS. IF YOU DO NOT WISH TO BE BOUND BY THE THESE TERMS, PLEASE EXIT THE
                        SERVICE. YOUR SOLE REMEDY FOR DISSATISFACTION WITH THE SERVICE, ANY PRODUCT OR SERVICE AVAILABLE ON THE SERVICE, OR THESE
                        TERMS IS TO CEASE USING THE SERVICE AND THOSE PARTICULAR PRODUCTS OR SERVICES.
                      </p>
                    </li>

                    <li>
                      <h3 className="subheader inline-block">ROLES AND DEFINITIONS</h3>
                      <p>There are various user roles and definitions related to the Service. These are as follows:</p>
                      <ol className="nested-list">
                        <li>&nbsp;“Kombatlink” is us, the operator of the Service.</li>
                        <li>
                          &nbsp;“Organizers” are users who use the Service to host and run an Event and manage the various Players in the Event. There
                          are different subscription levels for Organizers, which will be discussed below.
                        </li>
                        <li>&nbsp;“Players” are users who participate in Events run by an Organizer` using the Service.</li>
                        <li>
                          &nbsp;“Events” are gameplay tournament events run by an Organizer, in which Players participate in gameplay for prizes
                          determined by the Organizer. These Events may take online or “live” and in-person, as determined by the Organizer.
                        </li>
                        <li>
                          &nbsp;“Tickets” are virtual tickets or tickets that can be printed which are sold or given away by Organizers to Players, in
                          order for them to participate in Events and to the Fans to spectate the tournament.
                        </li>
                      </ol>
                    </li>

                    <li>
                      <h3 className="subheader inline-block">KOMBATLINK’S ROLE</h3>
                      <p>
                        Kombatlink’s Service is a platform to provide Organizers the ability to host gameplay Events, manage their Players and to
                        market their events. (see Section 7 below for more information). Each Event will be more fully described by that Event’s
                        Organizer, including descriptions of the Event time, venue location, participants, and games being played.
                      </p>
                      <p>
                        Because Kombatlink is the platform, we are not the organizer, creator, or owner of any Events hosted using the Service.
                        Organizers are the sole organizers, creators, and owners of any Events. They are solely responsible for ensuring that any
                        Event (i) meets all applicable local, state, provincial, national, and other laws or regulations, and (ii) that any prizes or
                        other aspects of the Event are delivered and described accurately and fairly.
                      </p>
                    </li>

                    <li>
                      <h3 className="subheader inline-block">YOUR ACCOUNT</h3>
                      <p>
                        Whether you are an Organizer or a Player, Kombatlink grants you a non-exclusive, non-transferable, revocable, limited license,
                        subject to all limitations provided in this Agreement, to access and use the Service using a personal computer or mobile
                        device, solely in accordance with the terms of this Agreement. This access will be granted through the generation of an
                        account (an “Account”), which will allow access to certain portions of the Service. You may not rent, lease, or lend the
                        Service or your Account, or offer it for any commercial use or purpose. You agree to fully comply with all applicable laws
                        regarding use of the Service.
                      </p>
                      <p>
                        You must be at least 13 years old to access the Service and create a Player account. You must be at least 18 years old to
                        access, use, and create an Organizer account on the Service. If you are between 13 and 18 years old, your parent or guardian
                        may create an Organizer account for you.
                      </p>
                      <p>
                        As a material inducement to Kombatlink entering into this Agreement with you, you represent to Kombatlink that you are at
                        least 13 years old if you sign up as a Player, or 18 years old (or the parent or guardian of a child at least 13 years old) if
                        you sign up as an Organizer. You also represent that you are not subject to any prohibitions or restrictions that would
                        prevent or inhibit you from entering into legally enforceable agreements.
                      </p>
                      <p>
                        Kombatlink reserves the right to disable your account, disqualify you from the Service, and remove your content, either
                        temporarily or permanently, at any time, if they find or suspect that you are abusing the Service in accordance with whatever
                        community standards we apply, at our sole discretion. You are responsible for all content uploaded with your Account and any
                        Events that you organize as an Organizer, and subject to whatever legal restrictions you would normally be under for
                        distributing such content.
                      </p>
                    </li>

                    <li>
                      <h3 className="subheader inline-block">ACCOUNT AND VIRTUAL ITEM OWNERSHIP</h3>
                      <p>
                        Virtual goods, Loyalty Points, and virtual currency (“Virtual Items”) may be offered as a feature of the Service. Your
                        election to make a Virtual Item purchase will be an offer to Kombatlink to purchase at the prices and on the terms set forth
                        int his Agreement and on the Service. Completion of the transaction will constitute Kombatlink’s acceptance of your offer.
                        Kombatlink has no liability as a result of any failure of any transaction to be completed. No transaction is deemed completed
                        until such time as Kombatlink has received payment from the credit card processor for the Virtual Items that are the subject
                        of the applicable transaction.
                      </p>
                      <p>
                        You may not buy, sell, give or trade any Virtual Item or Account, nor attempt to buy, sell, give or trade any Virtual Item or
                        Account. Kombatlink owns, has licensed, or otherwise has rights to all of the content that appears on the Service, including
                        Virtual Items and Accounts. You agree that you have no right or title in or to any such content, including Virtual Items
                        appearing or originating on the Service, or any other attributes associated with an Account. Kombatlink does not recognize any
                        purported transfers of Virtual Items executed outside of the Service, or the purported sale, gift or trade in the “real world”
                        of anything that appears or originates on the Service. Virtual Items that may be originally acquired by “farming” are subject
                        to confiscation by Kombatlink. You may not sell Virtual Items for “real” money or exchange those Virtual Items for value
                        outside of the Service. Under no circumstances will refunds be given for Virtual Items - all sales are final.
                      </p>
                    </li>

                    <li>
                      <h3 className="subheader inline-block">LIVE CHAT AND FORUM</h3>
                      <p>
                        You are responsible for your conduct as a member of the community and as a user of the Service. You agree that you will not
                        engage in conduct or communication (written, verbal, or nonverbal), either yourself, or by or through your avatar, which:
                      </p>
                      <ul>
                        <li>
                          is threatening, bullying, defamatory, abusive, obscene, lewd, sexually provocative or suggestive, pornographic, or which in
                          any manner could give rise to any civil or criminal liability under applicable law;
                        </li>
                        <li>
                          could infringe or lead to infringement of any copyright, trademark, publicity or privacy right, or any other intellectual
                          property or personal right of any person or entity;
                        </li>
                        <li>
                          is or could be taken as slurs, hate speech or attacks on individuals or groups on the basis of race, color, gender, age,
                          religion, national origin, disability, sexual preferences, or gender identity;
                        </li>
                        <li>constitutes spam (sending the same message multiple times or to multiple people will be treated as spam);</li>
                        <li>is a solicitation or advertisement for any lewd or inappropriate personal conduct, commercial product or activity;</li>
                        <li>
                          encourages or constitutes behavior that does not support a safe and comfortable environment for all users, whish conduct may
                          include but not be limited to bullying, vigilantism, engaging in any conduct or activity that is threatening, harmful,
                          harassing, abusive, vulgar, hateful, defamatory, lewd, sexually provocative, suggestive, or explicit, inflammatory, profane,
                          racially or ethnically objectionable or discriminatory, religious or political, or in any manner encourages inappropriate,
                          disrespectful, abusive, or unlawful conduct or otherwise makes the Service an uncomfortable experience for anyone;
                        </li>
                        <li>
                          restricts, inhibits, or discourages any other user from using the Service or contains a virus or other harmful component;
                        </li>
                        <li>
                          hacks, modifies or otherwise makes use of cheats, mods, automation software (bots) or any other unauthorized third-party
                          software designed to modify the Service experience;
                        </li>
                        <li>violates any local, state, federal or international laws or gives rise to civil liability;</li>
                        <li>
                          violates or infringes any rights of third parties (including but not limited to copyright, trademark, rights of privacy or
                          publicity, defamation or any other proprietary right);
                        </li>
                        <li>imposes an unreasonable or disproportionately large load on the Service or otherwise interferes with the Service;</li>
                        <li>is a chain letter, or constitutes junk mail;</li>
                        <li>
                          specifies or claims that that you are affiliated with Kombatlink when you are not, including without limitation a
                          “Moderator,” or any other employee or agent of Kombatlink;
                        </li>
                        <li>requests account login information from other players;</li>
                        <li>“spoofs” (use of any means to disguise your online identity or alter original attribute information);</li>
                        <li>uses or possesses programs to ‘crack’ the Service or other Internet security tools;</li>
                        <li>
                          uploads files that contain viruses, Trojan horses, worms, time bombs, cancelbots, corrupted files or data, or any other
                          similar software or programs that may damage or inhibit the operation of the Service;
                        </li>
                        <li>restricts or inhibits any user from using and enjoying the Service; or</li>
                        <li>
                          does anything else that Kombatlink in its sole determination deems harmful to the Service’s or to Kombatlink’s integrity or
                          business.
                        </li>
                      </ul>
                      <p>
                        You agree that there is no expectation of privacy in connection with your interactions with other users in and through the
                        Service. You further agree that the contents of any messages or other communication sent from your Account, whether in or
                        through chat rooms, direct user-to-user communication, or by other means, is the property of Kombatlink and may be accessed,
                        reproduced, or distributed by Kombatlink as it sees fit. Kombatlink will fully cooperate with law enforcement and other
                        governmental entities in policing the content of the Service. If you are under the age of 21, Kombatlink reserves the right to
                        release transcripts of your chats, or other intra-Service communication, to your parents, guardian, or other adult authority
                        figures.
                      </p>
                      <p>
                        We may, at our sole discretion, immediately suspend or terminate your access to the Service should your conduct, in our sole
                        determination, fail to conform with this Agreement.
                      </p>
                    </li>

                    <li>
                      <h3 className="subheader inline-block">FOR ORGANIZERS</h3>
                      <p>
                        This Section contains terms specific to Organizers using the Service to conduct Events. Please read these carefully, as these
                        affect your rights and liabilities with regard to those Events. These terms are in addition to any similar terms contained in
                        this Agreement.
                      </p>
                      <ol className="nested-list">
                        <li>
                          &nbsp;Laws and Regulations. Organizers acknowledge and agree that their Events may be subject to laws and regulations in the
                          jurisdiction in which you reside and access the Service, or where you are running the Event. You agree that you are{' '}
                          <strong>
                            <span className="subheader">solely responsible</span>
                          </strong>{' '}
                          for complying with these laws and regulations. If the laws and regulations prohibit your access or participation in the
                          Service or the operation of Events, the Service is void in your jurisdiction. Your access or participation in the Service or
                          any Events{' '}
                          <strong>
                            <span className="subheader">is at your own risk</span>
                          </strong>
                          , and you agree not to hold us liable or responsible for any consequences of accessing or participation in the Service or
                          the Events from such a jurisdiction.
                        </li>
                        <li>
                          &nbsp;Disclaimer. WE MAKE NO REPRESENTATIONS OR WARRANTIES, EXPRESS OR IMPLIED, AS TO THE LAWFULNESS OF ANY EVENT, ANY
                          PURCHASES MADE BY PLAYERS OR OTHER USERS, OR ANY USE OF THE SERVICE, NOR DOES ANY PERSON AFFILIATED, OR CLAIMING
                          AFFILIATION, WITH US HAVE THE AUTHORITY TO MAKE ANY SUCH REPRESENTATIONS OR WARRANTIES.
                        </li>
                        <li>
                          &nbsp;Event Contents; Third Parties. You acknowledge that Kombatlink has no control over and does not guarantee the quality,
                          safety, accuracy. or legality of any Event or Event content, the truth or accuracy of any information provided by Players,
                          or the ability of any Player to perform or actually complete a transaction. Kombatlink has no responsibility to you for, and
                          hereby disclaims all liability arising from, the acts or omissions of any third parties that Kombatlink uses or requires to
                          provide the Service, that you choose to assist with any Event, or that you choose to contract with when using the Service.
                        </li>
                        <li>
                          &nbsp;Tickets. You acknowledge that you are solely responsible for checking the validity of any Tickets purchased and used
                          by Players. Kombatlink is not liable in any way for any failure to do so, or to follow any applicable procedures with regard
                          to Ticket validity. Kombatlink is not liable in any way for damages or other costs associated with Tickets, involving fraud
                          or other issues associated with the purchase of Tickets through third parties or otherwise in violation of this Agreement.
                        </li>
                        <li>
                          &nbsp;Release. You hereby agree to release Kombatlink from all damages, losses, liabilities, and costs of every kind and
                          nature, known and unknown, arising out of any dispute between you and a third party (including Players) in connection with
                          the Service or any Event organized by you using the Service. In addition, you waive any applicable law or statute, which
                          says, in substance: “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE RELEASING PARTY DOES NOT KNOW OR SUSPECT TO EXIST
                          IN HIS FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM MUST HAVE MATERIALLY AFFECTED HIS SETTLEMENT WITH
                          THE RELEASED PARTY.”
                        </li>
                      </ol>
                    </li>

                    <li>
                      <h3 className="subheader inline-block">PLAYER MATCHES</h3>
                      <p>
                        “Player Matches” refers to matches between users that are organized and facilitated by an Organizer using the Service as the
                        platform.
                      </p>
                      <p>
                        Players who either engage in or conspire in the falsification of Player Match results will be subject to penalties including
                        suspension, termination of their Account, and a penalty fee.
                      </p>
                      <ol className="nested-list">
                        <li>
                          &nbsp;Eligibility. In order to be eligible for the Service and to participate in any Player Match, you must meet the
                          following requirements:
                          <ul>
                            <li>You must have a valid Account on the Service, a device capable of running the Service and any related games;</li>
                            <li>You must have the capacity and authority to enter into a contract with Kombatlink, including this Agreement; and</li>
                            <li>You must agree to and abide by this Agreement at all times.</li>
                          </ul>
                        </li>
                        <li>
                          &nbsp;Cheating and Abuse. While using the Service or participating in Player Matches, you agree that you will not engage in
                          any activity that:
                          <ul>
                            <li>interrupts or attempts to interrupt the Service;</li>
                            <li>open or use multiple accounts to access the Service;</li>
                            <li>involves the use of unauthorized or altered software or hardware to assist play,</li>
                            <li>including but not limited to the use of or collusion with so-called bots or bot-nets;</li>
                            <li>
                              involves intentional poor play or low scoring in order to gain a competitive advantage is games where doing such
                              activities does so;
                            </li>
                            <li>involves collusion with other players;</li>
                            <li>“launders” money by deliberately transferring it between different accounts;</li>
                            <li>involves the harassment of other participants or the posting of objectionable material;</li>
                            <li>unfairly or unnaturally alters your or other Players’ chances of winning a Player Match.</li>
                          </ul>
                          <p>
                            If you engage in any of the above activities or any other behavior that is deemed by us to be an unfair method of
                            participating in the Service, at Kombatlink’s sole discretion, we reserve the right to terminate your Account, invalidate
                            any winnings, and disclose or report any money laundering similar illegal activity to law enforcement and regulatory
                            authorities. Without limiting our other available remedies, we may institute or seek any injunctive relief, civil and
                            criminal proceedings against you and any of your co-conspirators arising out of or related to your commission of any
                            cheating or abuse, including recovering all of our fees and expenses (including reasonable attorneys’ fees) in connection
                            with such efforts.
                          </p>
                        </li>
                        <li>
                          &nbsp;Hacking. Any attempt to gain unauthorized access to our systems or any other user’s account, interfere with the
                          Service, or deliberately damage or undermine the Service is subject to civil or criminal prosecution and will result in
                          immediate termination of your Account. You acknowledge that we are not responsible for any damage, loss, or injury resulting
                          from hacking, tampering, or other unauthorized access or use of Service by you or any other Player.
                        </li>
                        <li>
                          &nbsp;Player Match Disputes. In the event of a dispute over the results of a Player Match, the Player disputing the results
                          may contact the Organizer, and include a link to video evidence. You agree that all decisions made by either Kombatlink or
                          the Organizer regarding Player Match disputes are final.
                        </li>
                      </ol>
                    </li>

                    <li>
                      <h3 className="subheader inline-block">MARKETPLACE</h3>
                      <p>
                        The “Marketplace” is a section of the Service where products related to video gaming are permitted to be bought and sold by
                        users. Examples of these products include, but are not limited to, games, consoles, accessories, equipment, furniture, and
                        collectibles. Users may also purchase top-up loyalty point packages. Loyalty points are subject to the terms of Section 5 of
                        this Agreement.
                      </p>
                      <p>
                        Users may exchange these products for money through transactions facilitated by the Service. Users agree that they will be
                        deemed the sole parties to all transactions arising from the Marketplace, regardless of any service charge and other fees
                        collected by Kombatlink as compensation for the use of the Marketplace, and indemnify Kombatlink from all responsibilities
                        that may arise from the transaction otherwise not foreseen. For clarity, Kombatlink is not a party to any transaction between
                        users on the Marketplace.
                      </p>
                      <p>
                        While Sellers are encouraged and required where possible to list the full details and descriptions of the items they are
                        selling, Kombatlink makes no warranties to the accuracy of these descriptions on the Marketplace, nor to the quality of the
                        items sold.
                      </p>
                      <p>
                        Logistics regarding the responsibility for shipping charges, how soon the items will be shipped, country custom fees and
                        taxes, and all other matters leading to the conclusion of the transaction are solely the responsibility of those party to the
                        transaction, of which Kombatlink is not one.
                      </p>
                      <p>
                        Kombatlink’s customer support assistance with transactions and disputes will be agreed not to make Kombatlink a party to the
                        transaction in any way.
                      </p>
                    </li>

                    <li>
                      <h3 className="subheader inline-block">PAYMENT PROCESSING</h3>
                      <p>
                        The Organizer of an Event may choose to process payments related to an Event themselves, or they may use the Service to
                        process payments (for which we charge a processing fee which is described to the Organizer when they create the Event, as
                        described below). If the Organizer uses their own payment processing method, Kombatlink does not process any payments and has
                        no liability regarding such payments.
                      </p>
                      <p>
                        If the Organizer uses the Service to process payments, Kombatlink is acting as the Organizer’s agent for the limited purpose
                        of processing such payments through Kombatlink’s own third-party payment processors, and returning any such payments (minus
                        the Kombatlink and any third-party payment processing fees) to the Organizer.
                      </p>
                    </li>

                    <li>
                      <h3 className="subheader inline-block">FEES AND PAYMENTS</h3>
                      <p>
                        Some aspects of the Service involve payments and fees. To see the most updated payments and fees, please visit the Service.
                      </p>
                      <p>
                        You agree to pay all fees, payments and applicable taxes incurred by your Account you used to access the Service. Except as
                        otherwise provided, Kombatlink does not issue refunds or exchanges for any products or services purchased on or through the
                        Service.
                      </p>
                      <ol className="nested-list">
                        <li>
                          &nbsp;European Union-Specific Section. If you reside in the European Union and you purchase a product or subscription from
                          Kombatlink, you may have the right to withdraw from a purchase within seven calendar days, commencing on the day after the
                          date of purchase (the “Cooling Off Period”). However, you lose your right of withdrawal if the performance of the services
                          begins before the end of this Cooling Off Period.
                          <br />
                          This Cooling Off Period may not be applicable regarding transactions with other users, as this consumer protection law is
                          meant to protect consumers from businesses, and while Kombatlink may assist with user-to-user transactions and disputes,
                          Kombatlink is not a party to these transactions.
                        </li>
                        <li>
                          &nbsp;User-to-User Transaction Payments. All transactions and disputes between users are between the parties to the
                          transaction: users hereby indemnify Kombatlink from involvement in disputes with other users.
                        </li>
                        <li>
                          &nbsp;Penalty Fees. A penalty fee may be charged in connection with disciplinary actions taken against users found to be
                          involved in cheating.
                        </li>
                        <li>
                          &nbsp;Inactivity Fee. An inactivity fee may be charged to Accounts for each month an Account is inactive, all the way up
                          until the Account has no more funds and is terminated.
                        </li>
                        <li>
                          &nbsp;Withdrawal of Funds. Withdrawal requests take up to 24 hours to process, and payments to users may take an average of
                          between 5-10 business days, depending upon the method of redemption selected by the user. Because payments and withdrawals
                          are processed for Kombatlink by a third party, Kombatlink cannot guarantee their timeliness, and makes no representation or
                          warranty to their timeliness.
                        </li>
                      </ol>
                    </li>

                    <li>
                      <h3 className="subheader inline-block">SUBSCRIPTIONS</h3>
                      <ol className="nested-list">
                        <li>
                          &nbsp;Subscriptions. Kombatlink may give Organizers the opportunity to purchase a recurring monthly subscription to access
                          certain features of the Service (a “Subscription”). Your purchase of a Subscription will grant you access to the specific
                          Service features detailed on the Service when you make the Subscription purchase, including extended Event creation features
                          and a lower payment processing fee. There are no refunds for Subscription purchases.
                        </li>
                        <li>
                          &nbsp;Duration. If you purchase a Subscription, your monthly Subscription will start when Kombatlink confirms your payment.
                          The Subscription will continue for a period lasting from the date of confirmation of your payment to the same date in the
                          following month (which may be anywhere from 28 to 31 days, depending on the month) (each, a “Subscription Period”). At the
                          end of each Subscription Period, you will automatically be signed up and billed for an additional month at the then-current
                          pricing. If at any point you do not wish your Subscription to auto-renew, you may uncheck the auto-renew box in your Account
                          at any point at least 24 hours prior to the end of your current Subscription Period.
                        </li>
                        <li>
                          &nbsp;Eligibility. In order to be eligible to purchase and maintain a Subscription, you must be in compliance with this
                          Agreement and any other Service terms communicated to you and have an Account that is otherwise in good standing, at
                          Kombatlink’s sole discretion.
                        </li>
                        <li>
                          &nbsp;Fees and Features. Kombatlink reserves the right, at its sole discretion, to modify the amount of the subscription
                          fees for the Subscription or the specific features included in a Subscription. In the event of a modification, Kombatlink
                          will send you an email notification at least 30 days before the price or feature modifications are made. With the
                          notification, we will remind you that your continued use after the end of your current Subscription Period means that you
                          accept the new fees and features.
                        </li>
                        <li>
                          &nbsp;Cancellation. If you disagree with those price modifications or otherwise wish to cancel your Subscription, you may
                          cancel your Subscription through your Account or by notifying Kombatlink at{' '}
                          <a href="mailto:support@kombatlink.com" target="_system">
                            support@kombatlink.com
                          </a>
                          . Please note that your cancellation must be made at least 24 hours before any price changes come into effect, otherwise you
                          will be deemed to have accepted the modified pricing.
                        </li>
                        <li>
                          &nbsp;Free Trial. Kombatlink may offer a free trial period for Subscriptions (the “Free Trial”). The Free Trial is subject
                          to this Agreement and any other related terms detailed on the Service. The Free Trial lasts for 30 day, and you can cancel
                          at any time prior to the end of that 30-day Free Trial period. The end date of the Free Trial will be noted in your Account.
                          <br />
                          If you cancel prior to the end of the Free Trial, you will no longer have access to the features included in the
                          Subscription. If you do not cancel prior to the end of the Free Trial, you will be billed the normal Subscription fee and be
                          subject to the Subscription terms in this Section. Kombatlink reserves the right to cancel your Free Trial, or to modify the
                          features offered in the Free Trial, at any time.
                        </li>
                      </ol>
                    </li>

                    <li>
                      <h3 className="subheader inline-block">SUPPORT SERVICES</h3>
                      <p>
                        Kombatlink may, in its sole discretion, provide you with support services related to the Service (“Support Services”).
                        Kombatlink is not required to provide Support Services unless otherwise required by applicable law. No failure to provide, or
                        to continue to provide, Support Services will be a default of Kombatlink under this Agreement. Any supplemental software code
                        provided to you as part of the Support Services shall be treated as part of the Service, as between you and Kombatlink shall
                        be and remain the sole property of Kombatlink, and shall be subject to the terms and conditions of this Agreement.
                      </p>
                      <p>
                        Kombatlink support may be reached by using a contact form on the Service, or via email at{' '}
                        <a href="mailto:support@kombatlink.com" target="_system">
                          support@kombatlink.com
                        </a>
                        .
                      </p>
                    </li>

                    <li>
                      <h3 className="subheader inline-block">LINKS</h3>
                      <p>
                        The Service may contain links to websites and content operated or hosted by other parties. These links are provided by
                        Kombatlink for your convenience, but use of these links is at your own risk. Kombatlink is not responsible for the content
                        available on other sites. Such links do not imply Kombatlink’s endorsement of information or material on any other site, and
                        Kombatlink disclaims all liability with regard to your access to that material which lies beyond the link.
                      </p>
                    </li>

                    <li>
                      <h3 className="subheader inline-block">SOFTWARE UPDATES</h3>
                      <p>
                        Kombatlink periodically tries to improve the Service through updates. These updates may come swiftly and without warning, and
                        this will be the only notification you receive regarding them before their implementation.
                      </p>
                    </li>

                    <li>
                      <h3 className="subheader inline-block">TERM, TERMINATION, MODIFICATION OF TERMS OF USE</h3>
                      <p>
                        The term of this Agreement will commence upon your first use or download of the Service, and will continue for as long as you
                        continue to make use of the Service. Without prejudice to any other of its rights, Kombatlink may unilaterally terminate or
                        modify this Agreement at any time and for any reason or for no reason, with no notice to you.
                      </p>
                      <p>
                        For example, but not in limitation, Kombatlink may elect to terminate this Agreement and your rights in connection with the
                        Service, if Kombatlink, in its sole determination, discontinues the Service, stops supporting or maintaining the Service,
                        ceases to provide updates, no longer offers the Service for license, or believes you have failed to comply with the terms and
                        conditions of this Agreement.
                      </p>
                      <p>
                        In the event of termination arising as a result of discontinuation of the Service by Kombatlink, no refunds will be given for
                        outstanding virtual goods, currency, or other in-game items. In the event of termination arising from your failure to comply
                        with the terms of the Agreement: (i) no refunds will be made; and (ii) you must promptly destroy all copies of the Service in
                        your possession. If modification of the Agreement by Kombatlink materially affects your rights in connection with the Service,
                        which determination will be made by Kombatlink in its sole determination, Kombatlink may, but is under no obligation to,
                        notify you by sending an email message to your last email address known to Kombatlink. Kombatlink will have no liability if
                        you do not receive Kombatlink’s notification.
                      </p>
                    </li>

                    <li>
                      <h3 className="subheader inline-block">COPYRIGHTS AND TRADEMARKS</h3>
                      <ol className="nested-list">
                        <li>
                          &nbsp;Proprietary Rights – The Service. As between you and Kombatlink, all title, including but not limited to copyrights,
                          in and to the Service and any copies thereof, and any content created by you in or in connection with the Service (such as,
                          but not limited to the contents of any chats, user-to-user communication, and other text) are owned by Kombatlink. As
                          between you and Kombatlink, all title and intellectual property rights in and to the content which may be accessed through
                          use of the Service is the property of Kombatlink and may be protected by applicable copyright or other intellectual property
                          laws and treaties.
                        </li>
                        <li>
                          &nbsp;Proprietary Rights - Virtual Items. As between you and Kombatlink, all title, including but not limited to copyrights,
                          in and to Virtual Items that may accrue to you through use of the Service, and any copies thereof, whether or not acquired
                          with consideration, are owned by Kombatlink, provided for entertainment purposes only for your exclusive use only in
                          connection with the Service. These Virtual Items may not be sold, bartered, or traded without the consent of Kombatlink. No
                          property or other proprietary rights in or to Virtual Items will accrue to you under any circumstances. This Agreement
                          grants you no rights to use such content except in and in connection with the Service, as expressly and unambiguously set
                          forth herein. All rights not expressly granted are reserved by Kombatlink.
                        </li>
                        <li>
                          &nbsp;Protection of Laws. Excluding user generated or submitted content, we own or hold licenses to the materials and names
                          featured on the Service. These materials and names are protected by Canadian, United States, and other foreign copyright and
                          trademark laws separately from this agreement: we just want to make sure you know. If you use the materials or trademarks on
                          the Service in a way that is not allowed by this Agreement, you will be violating the Agreement, and may also be violating
                          copyright, trademark, and other laws. In that case, we will revoke your permission to use the Service. Title to everything
                          protected remains with us or its respective authors. All rights not expressly granted are reserved.
                        </li>
                        <li>
                          &nbsp;Maintenance of Copyright and Trademark Notices. You must not remove or alter any copyright or trademark notices that
                          appear anywhere within the Service.
                        </li>
                        <li>
                          &nbsp;Prohibition on Reverse Engineering, Decompilation, and Disassembly. You may not reverse engineer, decompile, or
                          disassemble the Service, or take any steps to recreate of recover the source code of the Service, except and only to the
                          extent that such activity is expressly permitted by applicable law.
                        </li>
                      </ol>
                    </li>

                    <li>
                      <h3 className="subheader inline-block">NOTICE FOR CLAIMS OF COPYRIGHT INFRINGEMENT</h3>
                      <p>
                        If you are a copyright owner or agent thereof and believe that content posted on the Service by a Kombatlink user infringes
                        upon your copyright, please submit notice, pursuant to the Digital Millennium Copyright Act (17 U.S.C. § 512(c)) to our
                        Copyright Agent with the following information:
                      </p>
                      <ul>
                        <li>an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright;</li>
                        <li>a description of the copyrighted work that you claim has been infringed;</li>
                        <li>the URL of the location on our website containing the material that you claim is infringing;</li>
                        <li>your address, telephone number, and email address;</li>
                        <li>
                          a statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its
                          agent, or the law; and
                        </li>
                        <li>
                          a statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are
                          the copyright owner or authorized to act on the copyright owner’s behalf.
                        </li>
                      </ul>
                      <p>
                        Our Copyright Agent can be reached by mail at: Kombatlink, ATTN: Stephen McArthur, 11400 W. Olympic Blvd, Suite 200, Los
                        Angeles California, 90064; or by email at{' '}
                        <a href="mailto:stephen@smcarthurlaw.com" target="_system">
                          stephen@smcarthurlaw.com
                        </a>
                        . Please note that attachments cannot be accepted at the email address for security reasons. Accordingly, any notification of
                        infringement submitted electronically with an attachment will not be received or processed.
                      </p>
                      <p>
                        If you believe in good faith that a notice of copyright infringement has been wrongly filed against you, the DMCA permits you
                        to send Kombatlink a counter-notice. Notices and counter-notices must meet the statutory requirements imposed by 17 USC § 512.
                        Notices and counter-notices with respect to the Service should be sent to Kombatlink’s Copyright Agent. The Kombatlink
                        copyright agent should be contacted only if you believe that your work has been used or copied in a way that constitutes
                        copyright infringement and such infringement is occurring through use of the Service. Please note that these notifications and
                        counter-notifications are legal notices. Kombatlink may provide copies of such notices to the participants in the dispute or
                        third parties, at our discretion and as required by law. Our Privacy Policy does not protect information provided in these
                        notices and counter-notices.
                      </p>
                    </li>

                    <li>
                      <h3 className="subheader inline-block">INDEMNIFICATION</h3>
                      <p>
                        You agree to indemnify Kombatlink and each of its officers, directors, agents, payment processing partners, other partners and
                        employees, harmless from all damages (whether direct, indirect, incidental, consequential or otherwise), loss, liability, and
                        costs (including reasonable attorneys’ fees) resulting from any claim, demand, suit, proceeding (whether before an arbitrator,
                        court, mediator or otherwise) (each an “Indemnification Claim”) relating to or arising out of: (i) your breach of this
                        Agreement (including any terms or policies incorporated into this Agreement); (ii) your use of the Services in violation of
                        this Agreement or any other Service policies; (iii) your breach of any applicable local, state, provincial, national or other
                        law, rule or regulation or the rights of any third party; and (iv) if you are an Organizer, your Events (including where you
                        have used the Service in connection with an Event), unless the Indemnification Claim arises out of Kombatlink’s gross
                        negligence or willful misconduct. In certain circumstances, Kombatlink may choose to handle the Indemnification Claim by
                        ourselves, in which case you agree to cooperate with Kombatlink in any way we request.
                      </p>
                    </li>

                    <li>
                      <h3 className="subheader inline-block">NO WARRANTIES</h3>
                      <p>
                        Kombatlink expressly disclaims any warranty in, to, or for the Service. The Service is provided ‘As Is’ and ‘Where Is’ without
                        any express or implied warranty of any kind.
                      </p>
                      <p>
                        KOMBATLINK EXPRESSLY DISCLAIMS ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR
                        CONTINUOUS OPERATION.
                      </p>
                      <p>
                        The entire risk associated with operation of the Service is assumed by you. Kombatlink does not warrant or assume
                        responsibility for the accuracy or completeness of any information, text, graphics, links or other items contained within the
                        Service. Kombatlink makes no warranties respecting any harm that may be caused by the transmission of a computer virus, worm,
                        time bomb, logic bomb, or other such computer program. Kombatlink further expressly disclaims any warranty or representation
                        to any third party who may operate or otherwise utilize the Service. Kombatlink cannot guarantee the integrity of the Service
                        or that it will be free from attacks by third parties that may result in incomplete or erroneous results from operation, or
                        damage to hardware devices in which the Service is loaded and operating.
                      </p>
                    </li>

                    <li>
                      <h3 className="subheader inline-block">LIMITATION OF LIABILITY</h3>
                      <p>
                        In no event shall Kombatlink be liable for any damages (including, without limitation, direct, special, incidental,
                        consequential, or punitive damages, lost profits, business interruption, or lost information) rising out of your use of, or
                        inability to use, the Service, or the use or inability to use the Service by any third party that may have access to the
                        Service by or through you, even if Kombatlink has/have been advised of the possibility of such damages, and without regard to
                        whether such damages, or claims of damages arise based in contract, tort or otherwise. Kombatlink shall have no liability with
                        respect to the content of the Service or any part thereof, including but not limited to, errors or omissions contained
                        therein, libel, infringements of rights of publicity, privacy, trademark rights, business interruption, personal injury, loss
                        of privacy, moral rights or the disclosure of confidential information. The limitation of liability set forth herein may not
                        be enforceable in certain jurisdictions, or under certain circumstances, as a matter of local law, and is not intended to
                        replace or supersede local law.
                      </p>
                    </li>

                    <li>
                      <h3 className="subheader inline-block">ASSUMPTION OF RISK</h3>
                      <p>
                        You understand and agree that Events and other uses of the Service may carry inherent risk, and by participating in those
                        Events, you choose to assume those risks voluntarily. In-person and online Events may carry risk of illness, bodily injury,
                        disability, or death, and you freely and willfully assume those risks by choosing to participate in those Events.
                      </p>
                    </li>

                    <li>
                      <h3 className="subheader inline-block">PRIVACY POLICY</h3>
                      <p>
                        Kombatlink reserves the right to track and report your activity inside the Service and our other software applications for
                        analytical purposes. Kombatlink cautions you against giving out any personally identifying information about yourself, your
                        children, or any other person in any Service. In an effort to preserve your privacy, Kombatlink agrees that it will treat any
                        personally identifying information that you submit through the Service in accordance with the terms outlined in its{' '}
                        <Link to="/privacy-policy" className="color-blue">
                          Privacy Statement
                        </Link>
                        .
                      </p>
                    </li>

                    <li>
                      <h3 className="subheader inline-block">DISCLOSURES REQUIRED BY LAW</h3>
                      <p>
                        Kombatlink reserves the right at all times to disclose any information, including personally identifiable information about
                        you, as necessary to satisfy any applicable law, regulation, legal process or governmental request. Kombatlink reserves the
                        right to fully cooperate with any law enforcement authorities or court order requesting or directing Kombatlink to disclose
                        the identity of anyone publishing or otherwise making available any materials that are believed to violate this Agreement.
                      </p>
                      <p>
                        BY ACCEPTING THIS AGREEMENT YOU WAIVE ALL RIGHTS AND AGREE TO HOLD KOMBATLINK HARMLESS FROM ANY CLAIMS RESULTING FROM ANY
                        ACTION TAKEN BY KOMBATLINK DURING OR AS A RESULT OF ITS INVESTIGATIONS OR FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF
                        INVESTIGATIONS BY EITHER KOMBATLINK OR LAW ENFORCEMENT AUTHORITIES.
                      </p>
                    </li>

                    <li>
                      <h3 className="subheader inline-block">BINDING ARBITRATION</h3>
                      <div className="bordered-box">
                        <p>
                          Any dispute or claim relating in any way to your use of the Service (each, a “Claim”) will be resolved by binding
                          arbitration, rather than in court (except that you may assert claims in small-claims court if your claims qualify). You
                          agree that each Claim must be brought individually.
                        </p>
                        <p>
                          YOU AND KOMBATLINK AGREE THAT (i) THERE IS NO RIGHT OR AUTHORITY FOR ANY DISPUTE TO BE ARBITRATED ON A CLASS ACTION BASIS OR
                          TO UTILIZE CLASS ACTION PROCEDURES; (ii) THERE IS NO RIGHT OR AUTHORITY FOR ANY DISPUTE TO BE BROUGHT IN A PURPORTED
                          REPRESENTATIVE CAPACITY OR AS A PRIVATE ATTORNEY GENERAL; AND (iii) NO ARBITRATION MAY BE JOINED WITH ANY OTHER ARBITRATION.
                        </p>
                        <p>
                          The Ontario Arbitration Act and federal and provincial arbitration law apply to this Agreement and this binding arbitration
                          clause.
                        </p>
                        <p>
                          Arbitration is a process with no judge or jury – an arbitrator will review the arguments in the dispute and award damages
                          and other relief just like a court would. The arbitrator must follow this Agreement as a court otherwise would. Court review
                          of the arbitration award is limited under the Ontario Arbitration Act.
                        </p>
                        <p>
                          To start an arbitration, you must send an email to{' '}
                          <a href="mailto:support@kombatlink.com" target="_system">
                            support@kombatlink.com
                          </a>{' '}
                          describing your Claim and requesting arbitration, or we may do the same by sending a written notice requesting arbitration
                          to your address. The proceedings will be conducted through the ADR Institute of Canada. You can view these rules at
                          http://adric.ca/rules-codes/arbrules/. The payment of the initial filing fees will be made by the party filing the Claim,
                          and any other filing and other fees will be apportioned as directed by the ADR Institute of Canada rules. The arbitration
                          will take place in Toronto, Ontario, Canada, unless the parties agree to video, phone, or internet connection appearances.
                        </p>
                        <p>
                          Except as otherwise set forth below, you may seek any remedies available to you under federal, state or local laws in an
                          arbitration action. As part of the arbitration, both you and Kombatlink will have the opportunity for discovery of
                          non-privileged information that is relevant to the Claim. The arbitrator will provide a written statement of the
                          arbitrator’s decision regarding the Claim, the award given (including any attorneys’ fees and costs awarded), and the
                          arbitrator’s findings and conclusions on which the arbitrator’s decision is based.
                        </p>
                        <p>
                          Nothwithstanding the terms of this Section, either of us may bring a lawsuit in court for equitable relief, for any misuse
                          or infringement of intellectual property rights, or for any Claim related to, or arising from, allegations of theft, piracy,
                          invasion of privacy, or unauthorized use of the Service.
                        </p>
                        <p>
                          BY AGREEING TO THIS ARBITRATION PROVISION, YOU UNDERSTAND THAT YOU AND KOMBATLINK WAIVE THE RIGHT TO SUE IN COURT AND HAVE A
                          JURY TRIAL.
                        </p>
                        <p>
                          You and Kombatlink agree that if any portion this Section is found illegal or unenforceable, that portion will be severed
                          and the remainder of the Section will be given full force and effect.
                        </p>
                      </div>
                    </li>

                    <li>
                      <h3 className="subheader inline-block">MISCELLANEOUS TERMS</h3>
                      <ol className="nested-list">
                        <li>
                          &nbsp;Entire Agreement. This Agreement constitutes the entire agreement between you and Kombatlink and governs the terms and
                          conditions of your use of the Service, and supersedes all prior or contemporaneous communications and proposals, whether
                          electronic, oral or written, between you and Kombatlink with respect to the Service. Notwithstanding the foregoing, you may
                          also be subject to additional terms and conditions, posted policies (including but not limited to the Privacy Policy),
                          guidelines, or rules that may apply when you use or purchase certain elements of the Service, Affiliate or advertiser
                          services, third-party content or third-party software. Kombatlink may revise this Agreement at any time by updating this
                          posted document. Accordingly, you should review the Agreement periodically to determine if any changes have been made. Your
                          continued use of the Service after any changes have been made to this Agreement signifies and confirms your acceptance of
                          any such changes or amendments to the Terms of Use.
                        </li>
                        <li>
                          &nbsp;No Waiver. The failure of Kombatlink to exercise or enforce any right or provision of this Agreement shall not operate
                          as a waiver of such right or provision. Any waiver of this Agreement by Kombatlink must be in writing and signed by an
                          authorized representative of Kombatlink.
                        </li>
                        <li>
                          &nbsp;Severability. If any provision of this Agreement is found by a court of competent jurisdiction to be invalid, the
                          parties’ intentions as reflected in the provision, and the other provisions of this Agreement remain in full force and
                          effect.
                        </li>
                        <li>
                          &nbsp;Section Titles. The section titles in this Agreement are for convenience only and have no legal or contractual effect.
                        </li>
                        <li>
                          &nbsp;No Partnership. Nothing contained in the Agreement shall be construed to constitute either party as a partner, joint
                          venturer, employee or agent of the other party, nor shall either party hold itself out as such. Neither party has any right
                          or authority to incur, assume or create, in writing or otherwise, any warranty, liability or other obligation of any kind,
                          express or implied, in the name of or on behalf of the other party, it being intended by both parties that each shall remain
                          independent contractors responsible for its own actions.
                        </li>
                      </ol>
                      <p>
                        Please report any violations of this Agreement to Kombatlink at{' '}
                        <a href="mailto:support@kombatlink.com" target="_system">
                          support@kombatlink.com
                        </a>
                        .
                      </p>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </article>
      </>
    )
  }
}

export default TermsOfService
