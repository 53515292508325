import { applyMiddleware, combineReducers, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import thunk from 'redux-thunk'
import { ApplicationState } from '../store/types/common'
import tournaments from './reducers/tournaments'
import operations from './reducers/operations'
import matches from './reducers/matches'
import matchParticipants from './reducers/match-participants'
import matchSubmissions from './reducers/match-submissions'
import participants from './reducers/participants'
import app from './reducers/app'
import notifications from './reducers/notifications'
import rounds from './reducers/rounds'
import disputes from './reducers/disputes'
import users from './reducers/users'
import clans from './reducers/clans'
import disputeDetails from './reducers/dispute-details'
import games from './reducers/games'
import regions from './reducers/regions'
import matchLinks from './reducers/match-Links'
import lineUps from './reducers/line-ups'
import images from './reducers/images'
import matchSettings from './reducers/match-settings'
import countries from './reducers/countries'
import consoles from './reducers/consoles'
import friendRequests from './reducers/friend-requests'
import playerProfiles from './reducers/player-profiles'
import blogs from './reducers/blogs'
import clanInvitations from './reducers/clan-invitations'
import clansMembers from './reducers/clans-members'
import reviews from './reducers/reviews'
import chat from './chat/chat/chat-reducer'
import chatMembers from './chat/chat-member/chat-member-reducer'
import chatMessages from './chat/chat-message/chat-message-reducer'
import fees from './fee/fee-reducer'
import pointsRewards from './reducers/points-rewards'

import disputesPage from './reducers/pages/disputes-page-reducer'
import tournamentBracketPage from './reducers/pages/tournament-bracket-reducer'
import tournamentsPage from './reducers/pages/tournaments-page-reducer'
import organizerEventsPage from './reducers/pages/organizer-events-page-reducer'
import playerEventsPage from './reducers/pages/player-events-page-reducer'
import clanPage from './reducers/pages/clan-page-reducer'
import membersPage from './reducers/pages/members-page-reducer'
import friendsPage from './reducers/pages/friends-page-reducer'
import adminTournamentsPage from './reducers/pages/admin-tournaments-page-reducer'
import playerBattlesAndLoyaltyPoints from './reducers/player-battle-and-loyaltypoint'
import { adminUserReducer } from './admin-user/admin-user-reducer'
import organizerTournamentAndMembersAndIncome from './reducers/organizer-tournament-members-income'

export default function configureStore(initialState?: ApplicationState) {
  const middleware = [thunk]

  const composeEnhancers = composeWithDevTools

  const rootReducer = combineReducers({
    tournaments,
    operations,
    matches,
    matchParticipants,
    matchSubmissions,
    participants,
    app,
    notifications,
    rounds,
    disputes,
    users,
    clans,
    disputeDetails,
    games,
    regions,
    matchLinks,
    lineUps,
    images,
    matchSettings,
    countries,
    consoles,
    friendRequests,
    playerProfiles,
    blogs,
    clanInvitations,
    clansMembers,
    reviews,
    chat,
    chatMembers,
    chatMessages,
    fees,
    pointsRewards,
    adminUsers: adminUserReducer,
    
    disputesPage,
    tournamentBracketPage,
    tournamentsPage,
    organizerEventsPage,
    playerEventsPage,
    clanPage,
    membersPage,
    friendsPage,
    adminTournamentsPage,
    playerBattlesAndLoyaltyPoints,
    organizerTournamentAndMembersAndIncome
  })

  return createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(...middleware)))
}
