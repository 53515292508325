import * as React from 'react'
import { defaultFormatDateTime, round2Places } from 'utils'
import { OrganizerTransactionViewModel } from '../../models/organizer-transaction-viewmodel'
import styles from './Transactions.module.scss'

interface Props {
  transaction: OrganizerTransactionViewModel
  userCurrencySymbol?:string
}

interface State {
  showhid: boolean
  sale: number
  fees: number
  balance: number
}

class AvailableTransactionsCard extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      showhid: false,
      sale: 0,
      fees: 0,
      balance: 0,
    }
    this.ticketCalculation = this.ticketCalculation.bind(this)
  }
  componentDidMount() {
    this.ticketCalculation()
  }
  ticketCalculation() {
    const { transaction } = this.props
    const fees:number = round2Places(transaction.OrignalCartTotal,2) - round2Places(transaction.CartTotal,2)
    const sale: number = transaction.EventTickets
    
    this.setState({ sale,fees })
  }
  render() {
    const { transaction,userCurrencySymbol } = this.props
    return (
      <>
      <div className="col-12 p-0">
          <div className={`${styles.table__row} p-0`}>
              <div className="col-12 p-0">
                <div className={`${styles.table__main_row} table_main_row`}>
                    <div className={`${styles.table__column} ${styles.table__column__id}`}>{transaction.EventId}</div>
                    <div className={`${styles.table__column} ${styles.table__column__name}`}>{transaction.TournamentName}</div>
                    {/* <div className={`${styles.table__column} ${styles.table__column__price}`}>{transaction.TicketPrice}</div> */}
                    <div className={`${styles.table__column} ${styles.table__column__sales}`}>{this.state.sale}</div>
                    <div className={`${styles.table__column} ${styles.table__column__venue}`}>{transaction.VenueType}</div>
                    <div className={`${styles.table__column} ${styles.table__column__date}`}>
                      {transaction.StartDate && defaultFormatDateTime(transaction.StartDate)}
                    </div>

                    {/* <div className={`${styles.table__column} ${styles.table__column__sale__date}`}>12-08-20</div> */}
                    
                    <div className={`${styles.table__column} ${styles.table__column__amount}`}>{userCurrencySymbol+' '+round2Places(transaction.OrignalCartTotal,2)}</div>
                    <div className={`${styles.table__column} ${styles.table__column__fees}`}>{userCurrencySymbol+' '+round2Places(this.state.fees,2)}</div>
                    <div className={`${styles.table__column} ${styles.table__column__amount}`}>{userCurrencySymbol+' '+round2Places(transaction.CartTotal,2)}</div>
                {/* <div className={`${styles.table__column} ${styles.table__column__balance}`}>${this.state.balance}</div> */}
                </div>
            </div>
          </div>
        </div>
        
      </>
    )
  }
}

export default AvailableTransactionsCard
