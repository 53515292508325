import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getAppUserRole } from 'store/selectors/app-selectors'
import { makeGetTournament } from 'store/selectors/tournaments-selectors'
import { ApplicationState } from 'store/types/common'
import { getIsOrganizer, getIsAdmin } from 'store/types/user-types'
import { DateFormats, getDefaultDateFormats, getJSTimezone, getUser as getUserFromStorage } from 'utils'

type UserSource = 'localStorage' | 'redux'

export const useFormatDateTime = (tournamentDetailId: number, date: string, userSource: UserSource) : DateFormats => {
  const getTournament = useMemo(makeGetTournament, [])
  const tournament = useSelector((state: ApplicationState) => getTournament(state, tournamentDetailId))
  const userRole = useSelector(getAppUserRole)
  
  if (!date) {
    return {
      date: '',
      dateTime: '',
      time: '',
    }
  }

  const fromLocalStorage = userSource === 'localStorage'
  const isOrganizer = fromLocalStorage
    ? getUserFromStorage().isorganizer
    : getIsOrganizer(userRole)
  const isAdmin = fromLocalStorage
    ? window.location.href.toLowerCase().indexOf('/admin/') !== -1
    : getIsAdmin(userRole)
    
  const displayTimeZone = (isAdmin || isOrganizer) && tournament?.TimeZoneId
  const timeZone = displayTimeZone ? getJSTimezone(tournament.TimeZoneId) : undefined
  
  return getDefaultDateFormats(date, timeZone)
}
