import React, { ReactNode } from 'react'
import { cn } from 'utils'
import styles from '../modals.module.scss'

interface ModalContainerProps {
  children: ReactNode
}

export const ModalContainer = ({ children }: ModalContainerProps) => {
  return <aside className={cn(styles.modal_container, styles.opened)}>{children}</aside>
}
