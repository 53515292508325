import { AlertModal } from 'components/_modals'
import React from 'react'
import { useDialogWithParameter } from './useDialog'

export const useAlertModal = () => {
  return useDialogWithParameter<string>((text, closeDialog) => (
    <AlertModal
      onClose={closeDialog}
      text={text}
      buttonText="OK"
    />
  ))
}
