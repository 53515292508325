import { ContainerName, useGetImageSize } from 'components/_hooks'
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadImage } from 'store/logic/image-logic'
import { makeGetImage } from 'store/selectors/image-selectors'
import { ApplicationState } from 'store/types/common'
import { MediaType } from 'store/types/image-types'
import { DivImg, Video } from '../Img'

interface TournamentDetailsCoverProps {
  coverId?: number
  coverDataUrl?: string
  coverMediaType?: MediaType
  containerName?:ContainerName
}

export const TournamentDetailsCover = ({ coverId, coverDataUrl, coverMediaType,containerName }: TournamentDetailsCoverProps) => {
  const dispatch = useDispatch()
  const getCover = useMemo(makeGetImage, [])
  const cover = useSelector((state: ApplicationState) => getCover(state, { imageId: coverId }))
  const imageSize = useGetImageSize(containerName)
  useEffect(() => {
    if (coverId) {
      dispatch(loadImage(coverId))
    }
  }, [coverId, dispatch])

  return (
    <>
      {(cover?.MediaType || coverMediaType) === MediaType.Video && (
        <div className="article-header__background tournament--article-header__background ct__article-header__background">
          <Video
            imageId={coverId}
            src={coverDataUrl}
            controls
            muted
            loop
            autoPlay
            width="100%"
            height="100%"
          />
        </div>
      )}
      {(cover?.MediaType || coverMediaType) === MediaType.Image && (
        <DivImg parentContainerSize={imageSize}
          className="article-header__background tournament--article-header__background ct__article-header__background"
          imageId={coverId}
          src={coverDataUrl}
          placeholder="tournament"
        />
      )}
      {!cover && !coverDataUrl && (
        <DivImg
          className="article-header__background tournament--article-header__background ct__article-header__background"
          src="https://kombatlink-assets.azureedge.net/img/kombatLinkTournamentLogo.jpg"
        />
      )}
    </>
  )
}
