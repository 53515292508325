import { Reducer } from 'redux'
import { State, OperationsAction, SET_OPERATION_STATUS } from '../types/operations-types'

const unloadedState: State = {
  list: [],
}

const reducer: Reducer<State, OperationsAction> = (state: State | undefined, action: OperationsAction): State => {
  if (state === undefined) {
    return unloadedState
  }
  switch (action.type) {
    case SET_OPERATION_STATUS:
      return {
        ...state,
        list: [...state.list.filter(op => op.id !== action.operation.id), action.operation],
      }
    default:
      return state
  }
}

export default reducer
