import { IDictionary } from 'store/types/dictionary-types'

export enum TournamentStructureEnum {
  SingleElimination = 1,
  DoubleElimination = 2,
  SingleRoundFfa = 3,
  EliminationRoundFfa = 4,
}

export const TournamentStructure = {
  [TournamentStructureEnum.SingleElimination]: 'Single Elimination',
  [TournamentStructureEnum.DoubleElimination]: 'Double Elimination',
  [TournamentStructureEnum.SingleRoundFfa]: 'Single Round FFA',
  [TournamentStructureEnum.EliminationRoundFfa]: 'Elimination Round FFA',
}

export const TournamentStructureEnumByName: {[key: string]: TournamentStructureEnum} = {
  'Single Elimination': TournamentStructureEnum.SingleElimination,
  'Double Elimination': TournamentStructureEnum.DoubleElimination,
  'Single Round FFA': TournamentStructureEnum.SingleRoundFfa,
  'Elimination Round FFA': TournamentStructureEnum.EliminationRoundFfa,
}

export const isTwoPlayersStructure = (tournamentStructure: TournamentStructureEnum) => {
  switch (tournamentStructure) {
    case TournamentStructureEnum.SingleElimination:
    case TournamentStructureEnum.DoubleElimination:
      return true
  }
  return false
}


export const TournamentStructureOptions = [
  ...Object.keys(TournamentStructure).map<IDictionary<TournamentStructureEnum>>(key => ({
    id: +key,
    name: TournamentStructure[+key as TournamentStructureEnum],
  })),
]
