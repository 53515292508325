import { Loading } from 'components/_shared/Loading'
import React, { useState } from 'react'
import { useIsSendChatMessageLoading } from 'store/chat/chat-message/chat-message-hooks'
import { useSendChatMessage } from 'store/chat/chat-message/chat-message-logic'
import { useSendIsTyping } from 'store/chat/chat/chat-logic'
import styles from './ChatMessageInput.module.scss'

export interface ChatMessageInputProps {
  chatId?: number
  disabled?: boolean
  isBlocked? : boolean
}

export const ChatMessageInput = ({ chatId, disabled, isBlocked }:  ChatMessageInputProps) => {
  const [message, setMessage] = useState('')
  const sendChatMessage = useSendChatMessage(chatId)
  const sendIsTyping = useSendIsTyping(chatId)
  const isSendChatMessageLoading = useIsSendChatMessageLoading(chatId)

  disabled = disabled || !chatId || isSendChatMessageLoading

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.currentTarget.value)
  }

  const handleFocus = () => {
    if(disabled)
    {
      return
    }
    void sendIsTyping(true)
  }

  const handleBlur = () => {
    if(disabled)
    {
      return
    }
    void sendIsTyping(false)
  }
  
  const handleSendClick = () => {
    if (disabled || message.trim().length <= 0) {
      return
    }
    void sendChatMessage(message)
    setMessage('')
  }

  return (
    <div className={styles.chatField}>
      <div className="container-fluid">
        <div className="row m-0">
          <div className="col p-0 p-l-5">
            <textarea 
              className={styles.chatFieldTextarea} 
              placeholder={isBlocked ? 'You was blocked' :'Type a message here...'} 
              onChange={handleChange} 
              value={message} 
              disabled={isBlocked}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
          </div>
          <div className="col-auto p-r-0">
            {isSendChatMessageLoading && <Loading size="small" className={styles.loading} />}
            {!isSendChatMessageLoading && <button disabled={disabled} className={styles.chatFieldButton} type="button" onClick={handleSendClick} />}
          </div>
        </div>
      </div>
    </div>
  )
}
