import { Modal, ModalType, ModalWindowAnimationType } from 'components/_modals'
import { ConfirmSubmitModal } from 'components/_shared/SubmissionModal/ConfirmSubmitModal/ConfirmSubmitModal'
import { TournamentClanSignUpModel } from 'models/clan-member-view-model'
import React, { useState } from 'react'
import { useReloadPendingMatches } from 'store/logic/match-logic'
import { useAddClanMembersParticipation } from 'store/logic/participants-logic'
import styles from './ClanMemberModule.module.scss'


interface Props {
  closeDialog: CallableFunction,
  clanMemberUsers:TournamentClanSignUpModel
  close:CallableFunction 
}

const options=[{ id: 1, type: 'Playing' }, { id: 2, type: 'Substitute' }]

const ClanMemberSignUpModal = ({clanMemberUsers,closeDialog,close}: Props) => {
  const [isOperationInProgress, setOperationInProgress] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [tournamentClanSignUpModel,setTournamentClanSignUpModel] =useState<TournamentClanSignUpModel>(clanMemberUsers)
  const [error,seterror] = useState('')
  const addClanMemberParticipation = useAddClanMembersParticipation()
  const reloadPendingMatches = useReloadPendingMatches()
  const hasClanMemberSignUp=()=>{
    const numberOfPlayersSelected = tournamentClanSignUpModel.clanMemberData && tournamentClanSignUpModel.clanMemberData.filter(x=>x.IsSubstitute === false && x.IsSelected === true).length || 0
    const numberOfSubstituteSelected = tournamentClanSignUpModel.clanMemberData && tournamentClanSignUpModel.clanMemberData.filter(x=>x.IsSubstitute === true && x.IsSelected === true).length || 0
    let message=''
    const playersPerClan = clanMemberUsers.TotalPlayers - clanMemberUsers.SubstituteCount
    const submessage= clanMemberUsers?.SubstituteCount > 1 ? ' substitutes ':' substitute '
    const playermessage= (playersPerClan - 1) > 1 ? ' players ':' player '
    let globalplayermessage = ''
    let globalsubmessage = ''
    if(numberOfPlayersSelected !== playersPerClan){
      if(numberOfPlayersSelected > playersPerClan){
        message = "maximum "+playersPerClan+playermessage + "is allowed"
      }
      else{
        if(playersPerClan-numberOfPlayersSelected > 1){
          message = (playersPerClan)-numberOfPlayersSelected +playermessage+ "is allowed"
        }
        else if(playersPerClan-numberOfPlayersSelected <= 1){
          message = (playersPerClan)-numberOfPlayersSelected + " more " +playermessage + "is required"
        }
        else{
          message = (playersPerClan)-numberOfPlayersSelected +playermessage+ "is required"
        }
      }
    }
    globalplayermessage = message
    message = ''
    if(clanMemberUsers?.SubstituteCount === 0 && numberOfSubstituteSelected!==0){
      message = "no substitute is allowed"
    }
    else{
      if(numberOfSubstituteSelected !== clanMemberUsers.SubstituteCount){
        
        if(numberOfSubstituteSelected > clanMemberUsers?.SubstituteCount){
          message = "maximum "+clanMemberUsers?.SubstituteCount+submessage + "is allowed"
        }
        else if(numberOfSubstituteSelected < clanMemberUsers?.SubstituteCount){
          const remaining = clanMemberUsers?.SubstituteCount - numberOfSubstituteSelected
          if(remaining <=1){
            message = remaining +" more " + submessage+ "is required"
          }
          else if(remaining > 1){
            message = clanMemberUsers.SubstituteCount - numberOfSubstituteSelected + submessage+ "is required"
          }
          else{
            message = clanMemberUsers.SubstituteCount - numberOfSubstituteSelected + submessage+ "is required"
          }
        }
        else{
          message = clanMemberUsers.SubstituteCount - numberOfSubstituteSelected + submessage+ "is required"
        }
      }
    }
    globalsubmessage = message
    message = ''
   
    if(globalplayermessage !=='' || globalsubmessage !== ''){
      if(message.startsWith("maximum")){
        message= message.replace("maximum","Maximum")
      }
      if(message.toLowerCase().includes("maximum")){
        message+='is allowed'
      }
      else{
        message+='is required'
      }
      if(globalplayermessage !== '' && globalsubmessage !==''){
        globalplayermessage += ", and "
      }
      seterror(globalplayermessage + globalsubmessage)
      return false
    }
    return true
  }
  const onCloseDilouge = ()=>{
    closeDialog()
      // if(hasClanMemberSignUp()){
      //   closeDialog()
      //   close()
      // }
  }
  const onSubmit = async () => {
    if(!hasClanMemberSignUp()){
      return false
    }
    let clanMemberData  = clanMemberUsers.clanMemberData.filter(x=>x.IsSelected === true)
    clanMemberUsers.clanMemberData = clanMemberData 
    const listClanMembers = clanMemberUsers.clanMemberData.filter(x=>x.IsDisabled !== true)
    clanMemberUsers.clanMemberData=listClanMembers
    const result = await addClanMemberParticipation(clanMemberUsers)
    if(result === true){
      void reloadPendingMatches()
      close(true)
      return true
    }
    else{
      return false
    }
  }
  const onSelectClanMember = (id:number,val:any)=>{
    const memberIndex = clanMemberUsers.clanMemberData.findIndex(x=>x.PlayerId === id)
    clanMemberUsers.clanMemberData[memberIndex].IsSelected = val
    setTournamentClanSignUpModel({...clanMemberUsers})
  }
  const onChangeMemberType = (id:number,val:any)=>{
    const memberIndex = clanMemberUsers.clanMemberData.findIndex(x=>x.PlayerId === id)
    clanMemberUsers.clanMemberData[memberIndex].IsSubstitute = val == 2?true:false
    setTournamentClanSignUpModel({...clanMemberUsers})
  }

  return (
    <Modal.Container>
    <Modal.Background onClose={onCloseDilouge} disabled={isOperationInProgress} />
    <ConfirmSubmitModal show={false} onClose={onCloseDilouge} onConfirm={()=>{}} />
    <Modal 
    onClose={onCloseDilouge}
    allowOk={true}
    asyncOperation={onSubmit}
    buttonColor="blue"
    okButtonText="Submit"
    hideOk={false}
    cancelButtonText={'Close'}
    className={styles.window}
    type={ModalType.animated}
    animationType={ModalWindowAnimationType.next}
    show={!showConfirmation}
    onOperationInProgress={setOperationInProgress} >
      
      <Modal.Header className="text-center">ClanMember Signup</Modal.Header>
      <div className="">
        <div className="container-fluid p-0">
                <div className="row">
                  <div style={{ width: '100%' }} className={styles.scroll}>
                    <table id="tblCitylist" className="table table-bordered">
                      <tbody>
                      <tr id="header">
                        <th></th>
                        <th>Username</th>
                        <th>Player Type</th>
                      </tr>
                      {clanMemberUsers.clanMemberData && clanMemberUsers.clanMemberData.map(function (item, key) {
                        return (
                          <tr key={key} className={item.IsDisabled?styles.disabledRow:''}>
                            <td><input disabled={item.IsClanLeader} checked={item.IsSelected} type="checkbox" name={key.toString()} onChange={(e)=>{onSelectClanMember(item.PlayerId,e.target.checked)}}/></td>
                            <td> {item.Username} </td>
                            <td>
                            <select disabled={item.IsClanLeader}
                              onChange={e => {
                                  onChangeMemberType(item.PlayerId,e.target.value)
                              }}
                              style={{ height: '34px', paddingLeft: '10px', paddingRight: '10px' }}
                              className={item.IsDisabled?styles.disableRow:''+"table__select table__select--status select"}
                              data-placeholder="Method"
                              data-select2-id="select-add-new-payment"
                              aria-hidden="true"
                              value={item.IsSubstitute?2:1}
                            >
                            {
                              options &&
                                options.map((v, i) => {
                                  return (
                                    <option className="p-1" value={v.id} >
                                      {v.type}
                                    </option>
                                  )
                                })}
                            </select>

                            </td>
                          </tr>
                        )
                      })}
                      
                      </tbody>
                    </table>
                  </div>
                  
                </div>
              </div>
      </div>
      {error!=='' && <span className="text-danger">{error}</span>}
         
    </Modal>
    </Modal.Container>
  )
}

export default ClanMemberSignUpModal
