import { Img, KlLink } from 'components/_shared'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { makeGetGame } from 'store/selectors/game-selectors'
import { ApplicationState } from 'store/types/common'
import images from 'images'
import { cn } from 'utils'
import styles from '../PlayerPage.module.scss'
import { ContainerName, useGetImageSize } from 'components/_hooks'

interface UserGameProps {
  gameId: number
  onClick?: (gameId: number) => void
  selected?: boolean
  containerName?:ContainerName
}

export const UserGame = ({ gameId, onClick, selected,containerName } : UserGameProps) => {
  const getGame = useMemo(makeGetGame, [])
  const game = useSelector((state: ApplicationState) => getGame(state, { gameId: gameId }))
  const gameLink = `/game-detail/${gameId}`
  const getImageSize = useGetImageSize(containerName)
  const handleClick = () => {
    if (onClick) {
      onClick(gameId)
    }
  }

  return (
    <div className={cn(styles.game)} onClick={handleClick}>
      <Img parentContainerSize={getImageSize} alt={game?.Gamename} imageId={game?.ImageId} src={!game?.ImageId ? `/images/games/${gameId}.jpg` : undefined} className={styles.gameImg}/>
      {
        selected && (
          <div className={styles.okIkonContainer}>
            <img className={styles.okIcon} src={images.ok}/>
          </div>
        )}
      <div className={cn(styles.gameName, 'my-1')}>
        <KlLink to={gameLink}>{game?.Gamename}</KlLink>
      </div>
    </div>
  )
}
