import { Button } from 'components/_shared'
import React from 'react'
import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPlayerEventsPageFilters } from 'store/selectors/pages/player-events-page-selectors'
import { ApplicationState } from 'store/types/common'
import { defaultPlayerEventsFilters, playerEventsFiltersChanged } from 'store/types/pages/player-events-page-types'
import styles from './Events.module.scss'


const NotFoundTournaments = () => {
  const dispatch = useDispatch()
  const storedFilters = useSelector((state: ApplicationState) => getPlayerEventsPageFilters(state)) 
  
  const isDefaultFilters = useMemo(() => {
    return storedFilters.currentPage == defaultPlayerEventsFilters.currentPage 
      && storedFilters.selectedGames == defaultPlayerEventsFilters.selectedGames
      && storedFilters.selectedOrganizers == defaultPlayerEventsFilters.selectedOrganizers
      && storedFilters.name == defaultPlayerEventsFilters.name
      && storedFilters.dateFrom == defaultPlayerEventsFilters.dateFrom
      && storedFilters.dateTo == defaultPlayerEventsFilters.dateTo
      && storedFilters.selectedRegion == defaultPlayerEventsFilters.selectedRegion
  }, [storedFilters])

  const handleResetFilters = () => {
    dispatch(playerEventsFiltersChanged(
      {
        filters: {
          ...defaultPlayerEventsFilters,
          currentPage: 0, 
          currentTab: storedFilters.currentTab,        
        },
      }
    ))
  }

  return (    
    <div className={styles.clearFilters}>
      <div className={styles.clearFiltersText}>                
        There are no tournaments for these filters
      </div>
      {!isDefaultFilters && (
        <Button color="blue" onClick={handleResetFilters} filled > 
          Reset filters
        </Button>
      )}     
    </div>   
  )
}

export default NotFoundTournaments
