import MatchResultModal, { ParticipantsResults } from 'components/_shared/MatchResultModal/MatchResultModal'
import { TournamentStructure } from 'consts'
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { roundsActionCreators } from 'store/logic/rounds-logic'
import { tournamentsActionCreators } from 'store/logic/tournaments-logic'
import { makeGetTournamentMatchesTree } from 'store/selectors/match-selectors'
import { makeGetRoundsByTournamentDetailId } from 'store/selectors/rounds-selectors'
import { makeGetTournament } from 'store/selectors/tournaments-selectors'
import { ApplicationState } from 'store/types/common'
import { cn } from 'utils'
import styles from './TournamentBracket.module.scss'
import VideoPlayerModal from '../../_shared/VideoPlayerModal/VideoPlayerModal'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { matchesSumbissionsActionCreators } from 'store/logic/match-submissions-logic'
import { Button, HorizontalScroll, SearchInput } from 'components/_shared'
import { matchesActionCreators } from 'store/logic/match-logic'
import { useDialogWithParameter } from 'components/_hooks/useComponents/useDialog'
import TournamentBracketSection from './TournamentBrackeSection'
import RepositionButton from 'components/_shared/TournamentBracket/_shared/Buttons/RepositionButton'
import { loadTournamentMatchesParticipants } from 'store/logic/match-participant-logic'
import { loadGames } from 'store/logic/game-logic'
import { matchLinksActionCreators } from 'store/logic/match-links-logic'


interface TournamentBracketPageRouteProps {
  tournamentDetailId?: string
}
type TournamentBracketPageProps = RouteComponentProps<TournamentBracketPageRouteProps>


const VictoryBracket = ({
  match,
}: TournamentBracketPageProps) => {

  const tournamentDetailId = useMemo(() => parseInt(match.params.tournamentDetailId),
    [match.params.tournamentDetailId])

  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadGames)
    dispatch(tournamentsActionCreators.loadTournament(tournamentDetailId))
    dispatch(roundsActionCreators.loadRounds(tournamentDetailId))
    dispatch(matchesActionCreators.loadTournamentMatches(tournamentDetailId))
    dispatch(loadTournamentMatchesParticipants(tournamentDetailId))
    dispatch(matchesSumbissionsActionCreators.loadTournamentMatchesSubmissions(tournamentDetailId))
    dispatch(matchLinksActionCreators.loadTournamentMatchLinks(tournamentDetailId))
  }, [dispatch, tournamentDetailId])

  const getRoundsByTournamentDetailId = useMemo(makeGetRoundsByTournamentDetailId, [])
  const rounds = useSelector((state: ApplicationState) => getRoundsByTournamentDetailId(state, { tournamentDetailId }))

  const getTournamentTree = useMemo(makeGetTournamentMatchesTree, [])
  const tournamentMatchesTree = useSelector((state: ApplicationState) => getTournamentTree(state, { tournamentId: tournamentDetailId, isLosersBracket: false }))
  const tournamentMatchesLoserTree = useSelector((state: ApplicationState) => getTournamentTree(state, { tournamentId: tournamentDetailId, isLosersBracket: true }))


  const getTournament = useMemo(makeGetTournament, [])
  const tournament = useSelector((state: ApplicationState) => getTournament(state, tournamentDetailId))


  const [filter, setFilter] = React.useState('')

  const [matchResultModal, openMatchResultModal] = useDialogWithParameter<ParticipantsResults>((participantsResults, closeDialog) => (
    <MatchResultModal closeDialog={closeDialog} participantsResults={participantsResults} />
  ))

  const [vieoPlayerModal, openPlayerModal] = useDialogWithParameter<string>((videoUrl, closeDialog) => (
    <VideoPlayerModal closeDialog={closeDialog} videoUrl={videoUrl} />
  ))

  const onComeBack = React.useCallback(() => {
    history.go(-1)
  }, [history])

  const winnerRounds = rounds.filter(r => !r.IsLosersBracket)
  const losersRounds = rounds.filter(r => r.IsLosersBracket)
  const [scrollPosition, setScrollPosition] = React.useState(0)
  const [loserBracketScrollPosition, setLoserBracketScrollPosition] = React.useState(0)


  const handleReposition = () => {
    setScrollPosition(0)
    setLoserBracketScrollPosition(0)
  }

  return (
    <>
      <header
        className={cn(
          styles.header,
          'row',
          'justify-content-sm-between',
          'align-items-sm-center',
          'flex-sm-row',
          'flex-column',
          'justify-content-center'
        )}
      >
        <div className="header__logo" />
        <Button color="blue" filled onClick={onComeBack}>
          back
        </Button>
      </header>
      {tournament && (
        <div className="row">
          <article className={cn(styles.article, 'container-fluid')}>
            <div className={cn('row', styles.article__headerContainer, 'align-items-center')}>
              <div className="col-auto p-0">
                <h1 className={styles.article__header}>{`${tournament.Name} (T#${tournament.Id})`}</h1>
              </div>
              <div className="col-md col-12 p-0">
                <div className={cn(styles.article__label, styles.article__labelGreen)}>{TournamentStructure[tournament.TournamentStructureId]}</div>
              </div>
              <div className="col-lg-auto col-12 p-0 mr-3">
                <SearchInput value={filter} onChange={setFilter} placeholder="Search by name" />
              </div>
              <RepositionButton onClick={handleReposition} />
            </div>
            <div className="row">
              <div className={cn('container-fluid', styles.article__bracket)}>
                {losersRounds && losersRounds.length > 0 && (
                  <div className={cn('row', styles.article__headerBracket, 'align-items-center')}>
                    <div className="col-auto p-0">
                      <h1 className={styles.article__header}>Winners&apos; Bracket</h1>
                    </div>
                  </div>
                )}
                <HorizontalScroll leftPosition={scrollPosition} onChangeLeftPosition={setScrollPosition}>
                  <TournamentBracketSection
                    tournament={tournament}
                    tournamentTree={tournamentMatchesTree}
                    openMatchResultModal={openMatchResultModal}
                    openPlayerModal={openPlayerModal}
                    rounds={winnerRounds}
                    filterName={filter}
                  />
                </HorizontalScroll>

                {losersRounds && losersRounds.length > 0 && (
                  <>
                    <div className={cn('row', styles.article__headerBracket, 'align-items-center')}>
                      <div className="col-auto p-0">
                        <h1 className={styles.article__header}>Losers&apos; Bracket</h1>
                      </div>
                    </div>
                    <HorizontalScroll leftPosition={loserBracketScrollPosition} onChangeLeftPosition={setLoserBracketScrollPosition}>
                      <TournamentBracketSection
                        tournament={tournament}
                        tournamentTree={tournamentMatchesLoserTree}
                        openMatchResultModal={openMatchResultModal}
                        openPlayerModal={openPlayerModal}
                        rounds={losersRounds}
                        filterName={filter}
                      />
                    </HorizontalScroll>
                  </>
                )}
              </div>
            </div>
          </article>
        </div>
      )}
      {matchResultModal}
      {vieoPlayerModal}
    </>
  )
}


export default VictoryBracket
