import * as React from 'react'

import { SelectedSeatsModel, ShoppingCardModel } from '../../models/shopping-card-model'
import ShoppingCardComponent from './ShoppingCardComponent'
import { UserModel } from '../../models/user-models'
import { TournamentCartUtils } from '../../server/utils/tournament-cart-utils'
import { Payments,UserStripePrimaryCardModel } from '../../models/payment-models'
import { StripeUtils } from '../../server/utils/stripe-utils'
import { LoyaltyPointsUtils } from '../../server/utils/loyalty-point-utils'
import { LoyaltyPointsModel } from '../../models/loyalty-points-models'
import { CoinBaseViewModel } from '../../models/coin-base-models'
import { CoinBaseUtils } from '../../server/utils/coin-base-utils'
import { TournamentCartAndCartDetailPostModel, TournamentCartDetailPostModel } from '../../models/tournament-cart-model'
const stripeElements = require('react-stripe-elements')
const CardNumberElement = stripeElements.CardNumberElement
const CardExpiryElement = stripeElements.CardExpiryElement
const CardCvcElement = stripeElements.CardCvcElement

import './cart.scss'
import { withStripe } from 'components/_shared/withStripe'
import './ShoppingCardPage.scss'
import { unjoinParticpant } from "store/logic/participants-logic"
import { LocationUtils } from "server/utils/location-utils"
import { UserPayment } from "models/user-payment-models"
import { UserUtils } from "server/utils/user-utils"
import { round2Places } from "utils/math"
import StorageUtils  from 'server/utils/storage-utils'
import { Loading } from 'components/_shared'
import { EventTypeEnum } from 'models/tournament-models'
import { GeneralUtils } from 'server/utils/general-utils'
import { TournamentTicketSentStatusEnum } from 'consts/TournamentTicketSentStatusId'
import { defaultFormatDate, defaultFormatTime } from 'utils/dateUtils'
const postalCodes = require('postal-codes-js');
interface State {
  shoppingCardList: ShoppingCardModel[]
  isSummary: boolean
  totalPrice: number
  paymentDetail: Payments
  isAgreed: boolean
  cartItemWarning: boolean
  paymentMethod: string
  hostedUrl: string
  userModel: UserModel
  userCountry: string
  userCountryCode: string
  currencySymbol: string
  mainShoppingCardList: ShoppingCardModel[]
  ExchangeRate: number
  BaseCurrencyExchangeRate: number
  playerLoyaltyPoints: number
  CurrentLoyaltyPointsExchangeRate: number
  CurrentCryptoCurrencyExchangeRate: number
  OrganizerBaseCurrencyId?: number
  OrganizerStripeCurrencyId?: number
  PlayerLocalCurrencyId?: number
  LoyaltyPointsValue: number
  TotalLoyaltyPoints: number
  baseCurrencyId: number
  stripeCardNumberValid: boolean
  stripeCardCvcValid: boolean
  stripeCardExpValid: boolean
  currencyCode: string
  isOnlyParticipantAvailable:boolean 
  loading:boolean
  invalidTournaments:ShoppingCardModel[]
  isValidateCart:boolean
  updateQuantityLoaderId:number
  isValidPostalCode:boolean
  userPrimaryCard:UserStripePrimaryCardModel
  ticketSelectedSeats:SelectedSeatsModel[]
  IsAdd:boolean
  openId:number
}

class ShoppingCard extends React.Component<any, State> {
  constructor(props: any) {
    super(props)
    this.state = {
      shoppingCardList: [],
      isSummary: true,
      totalPrice: 0,
      paymentDetail: Object(),
      isAgreed: false,
      cartItemWarning: false,
      paymentMethod: 'Credit',
      hostedUrl: '',
      userModel: Object(),
      isOnlyParticipantAvailable: false,
      userCountry: "",
      userCountryCode: "",
      currencySymbol: "",
      mainShoppingCardList: [],
      ExchangeRate: 1,
      BaseCurrencyExchangeRate:1,
      playerLoyaltyPoints: 0,
      CurrentLoyaltyPointsExchangeRate: 1,
      CurrentCryptoCurrencyExchangeRate: 1,
      OrganizerBaseCurrencyId: null,
      OrganizerStripeCurrencyId: null,
      PlayerLocalCurrencyId: null,
      LoyaltyPointsValue: 1,
      TotalLoyaltyPoints: 0,
      baseCurrencyId: 0,
      stripeCardNumberValid: true,
      stripeCardCvcValid: true,
      stripeCardExpValid: true,
      currencyCode: "",
      loading:false,
      invalidTournaments:[],
      isValidateCart:true,
      updateQuantityLoaderId:0,
      isValidPostalCode:true,
      userPrimaryCard:Object(),
      ticketSelectedSeats:[],
      IsAdd:false,
      openId:0
    }

    this.handlerAddQuantity = this.handlerAddQuantity.bind(this)
    this.getCart = this.getCart.bind(this)
    this.onChangePaymentDetail = this.onChangePaymentDetail.bind(this)
    this.handlePayment = this.handlePayment.bind(this)
    this._stripCharge = this._stripCharge.bind(this)
    this.handleDeleteCartItem = this.handleDeleteCartItem.bind(this)
    this.nextButtonHandler = this.nextButtonHandler.bind(this)
    this.checkOutHandler = this.checkOutHandler.bind(this)
    this.checkOutByLoyaltyPoints = this.checkOutByLoyaltyPoints.bind(this)
    this.checkOutByCrypto = this.checkOutByCrypto.bind(this)
    this.redirectHostedUrl = this.redirectHostedUrl.bind(this)
    this.updateCartDetailAndCart = this.updateCartDetailAndCart.bind(this)
    this.calculatePrice = this.calculatePrice.bind(this)
    this.handleCardElementOnChange = this.handleCardElementOnChange.bind(this)
    this.deleteCart = this.deleteCart.bind(this)
    this.getcartstatus = this.getcartstatus.bind(this)
    this.setLoading = this.setLoading.bind(this)
    this.checkOnlyParticipantTicketAdded=this.checkOnlyParticipantTicketAdded.bind(this)
    this.validateTicket = this.validateTicket.bind(this)
    this.validateShoppingCard = this.validateShoppingCard.bind(this)
    this.checkoutCart=this.checkoutCart.bind(this)
    this.calculateShoppingCartPrice=this.calculateShoppingCartPrice.bind(this)
    this.calculateSpectatorQuantityWithPrice = this.calculateSpectatorQuantityWithPrice.bind(this)
    this.validPostalCode = this.validPostalCode.bind(this)
    this.handleSetSelectedSeats = this.handleSetSelectedSeats.bind(this)
    this.calculateCartDetailSectionPrice = this.calculateCartDetailSectionPrice.bind(this)
    this.renderSeats=this.renderSeats.bind(this)
    this.renderSection = this.renderSection.bind(this)
    this.calculateTotalSectionPrice = this.calculateTotalSectionPrice.bind(this)
    this.calculateSelectedSeatsQuantityPerCard = this.calculateSelectedSeatsQuantityPerCard.bind(this)
    
  }
  async componentDidMount() {
    const cordinates: any = await LocationUtils.getCoords()
    const userLocation = await LocationUtils.GetLocationInfo(
      "country",
      cordinates.latitude,
      cordinates.longitude
    )
    
    this.setState(
      {
        userCountry: userLocation.CountryName,
        userCountryCode: userLocation.CountryCode
      },
      () => {
        this.getCart()
      }
    )
  }
  
  onChangePaymentDetail(name, val) {
    const { paymentDetail } = this.state
    paymentDetail[name] = val
    this.setState({
      paymentDetail,
    })
  }

  async handlerAddQuantity(ShoppingCard: ShoppingCardModel,isAddition?:boolean = false,SeatId:number,SectionId:number) {
        let isValid = true
        const { shoppingCardList, mainShoppingCardList } = this.state
        if(ShoppingCard.IsTournamentExpired || !ShoppingCard.IsValid){
          const index = shoppingCardList.findIndex(
            (x) =>
                x.Id === ShoppingCard.Id 
                
          )
          alert(ShoppingCard.TicketMessage)
          await this.handleSetSelectedSeats(SectionId,ShoppingCard.TicketId,ShoppingCard.Id)
          
          
        } 
        else if (ShoppingCard.Id > 0 && !ShoppingCard.IsTournamentExpired && ShoppingCard.IsValid) {
            if (ShoppingCard.ParticipantTicket) {
                this.setState({
                    updateQuantityLoaderId: ShoppingCard.Id
                }, async() => {
                    await this.AddOrUpdateCartDetail(ShoppingCard,isAddition)
                })
            }
            else {
                const price = round2Places(Number(ShoppingCard.Price), 2)
               
                const index = shoppingCardList.findIndex(
                    (x) =>
                        x.Id === ShoppingCard.Id &&
                        x.ParticipantTicket === false &&
                        x.TournamentDetailId === ShoppingCard.TournamentDetailId
                )
                shoppingCardList[index].Price = price.toString()
                if (shoppingCardList.length > 0 && isValid) {
                 // this.AddOrUpdateCartDetail(ShoppingCard,isAddition,SeatId,SectionId)
                    this.setState({
                        updateQuantityLoaderId: ShoppingCard.Id
                    }, async () => {
                        await this.AddOrUpdateCartDetail(ShoppingCard,isAddition,SeatId,SectionId)
                    })
                }
            }
        }
  }
  async handleDeleteCartItem(cardDetailId: number, IsParticipant: boolean) {
        let { shoppingCardList } = this.state
        const index = shoppingCardList.findIndex(
            (x) => x.CartDetailId == cardDetailId
        )
        const res = await TournamentCartUtils.DeleteCart(cardDetailId)
        if (!res.isError) {
            if (IsParticipant) {
                await unjoinParticpant(
                    shoppingCardList[index].TournamentDetailId
                )
            }
            if (
                shoppingCardList.findIndex((e) => e.CartDetailId == cardDetailId) != -1
            ) {
                shoppingCardList.splice(
                    shoppingCardList.findIndex((x) => x.CartDetailId == cardDetailId),
                    1
                )
            }
          
            this.setState({
                shoppingCardList: [...shoppingCardList]
            }, () => {
                this.calculatePrice(this.state.shoppingCardList, true)
            })
        }
  }
  

  async getCart() {
    GeneralUtils.showHideLoadingDiv(true)
    const { shoppingCardList, paymentDetail, userCountry,userPrimaryCard } = this.state

    let { mainShoppingCardList,isValidPostalCode } = this.state
    const user: UserModel = StorageUtils.getUser()
    let TournamentDetailId:any = StorageUtils.getCartTournamentDetailId()
    TournamentDetailId = TournamentDetailId===null?0:Number(TournamentDetailId)
    const res = await TournamentCartUtils.GetCart(TournamentDetailId, userCountry)
    const result = await UserUtils.PlayerBattlesAndLoyaltyPoints()
    let playerLoyaltyPoints = result.data.LoyaltyPoints
    if (
      res.data.hasOwnProperty("ShoppingCardModelView") &&
      res.data["ShoppingCardModelView"].length > 0
    ) {
      let CurrentCryptoCurrencyExchangeRate =
        res.data.CurrentCryptoCurrencyExchangeRate
      let CurrentLoyaltyPointsExchangeRate =
        res.data.CurrentLoyaltyPointsExchangeRate

      let PlayerLocalCurrencyId = res.data.PlyerLocalCurrencyId
      let LoyaltyPointsValue = res.data.LoyaltyPointsValue
      let baseCurrencyId = res.data.baseCurrencyId
      
      const cardList: ShoppingCardModel[] = res.data.ShoppingCardModelView
      paymentDetail.email = user && user.emailaddress
      paymentDetail.name = user && user.username
      let cartIndex = 1
      cardList.forEach(async (ele) => {
        let cardObject: ShoppingCardModel = Object()
        let title = ""
        cardObject = ele
        cardObject.UserId=user.id
        if (!cardObject.ParticipantTicket) {
          if (
            cardObject.DayNumber !== null &&
            cardObject.Time !== null
          ) {
            title = cardObject.Title + " (Day " + cardObject.DayNumber + ")"
          } else {
            title = cardObject.Title + " (Full Pass)"
          }
        } else {
          title = cardObject.Title + " (Participant)"
          
        }
        
        cardObject.Quantity = ele.Quantity
        cardObject.IsAdd =  cardObject.Quantity >= 1
        cardObject.IsValid = true
        cardObject.Title = title
        cardObject.TotalAmount = this.state.totalPrice
        cardObject.Time =
          cardObject.Time !== null
            ? defaultFormatTime(cardObject.Time)
            : null
        cardObject.Date = defaultFormatDate(cardObject.Date)
        cardObject.TicketPrice = cardObject.ParticipantTicket
          ? 0
          : round2Places(Number(cardObject.TicketPrice), 2)
        cardObject && (!cardObject.Quantity || cardObject.Quantity < 1)
          ? cardObject.ParticipantTicket
            ? (cardObject.Quantity = 0)
            : (cardObject.Quantity = 0)
          : (cardObject.Quantity = cardObject.Quantity)
        cardObject.LocalTicketPrice = cardObject.ParticipantTicket
          ? 0
          : round2Places(Number(cardObject.LocalTicketPrice), 2)
        cardObject.Price = cardObject.ParticipantTicket
          ? "0"
          : this.calculateCurrentCartPrice(cardObject,"Credit",CurrentLoyaltyPointsExchangeRate,CurrentCryptoCurrencyExchangeRate,LoyaltyPointsValue,1).toString()
        cardObject.Id=cartIndex
        cardObject.IsTournamentExpired = false
        
        shoppingCardList.push(cardObject)
        cartIndex+=1
        
      })
      
      const sum = shoppingCardList.filter(x=>x.IsTournamentExpired === false && x.IsValid == true).reduce((sum, record) => {
        return sum + round2Places(Number(record.Price), 2)
      }, 0)
      mainShoppingCardList = JSON.parse(JSON.stringify(shoppingCardList))
      if(res?.data.hasOwnProperty('UserPrimaryCard') && res?.data['UserPrimaryCard'].cardNo!=''){
        userPrimaryCard.cardNo = res?.data['UserPrimaryCard'].cardNo
        userPrimaryCard.expireDate = res?.data['UserPrimaryCard'].expireDate
        userPrimaryCard.postalCode = res?.data['UserPrimaryCard'].postalCode
        userPrimaryCard.pmTblId = res?.data['UserPrimaryCard'].pmTblId
        paymentDetail.postalCode = userPrimaryCard.postalCode
        isValidPostalCode = true
      }
      this.setState(
        {
          shoppingCardList,
          totalPrice: sum,
          currencySymbol:
            shoppingCardList.length > 0
              ? shoppingCardList[0].CurrencySymbol
              : "",
          currencyCode:
            shoppingCardList.length > 0 ? shoppingCardList[0].CurrencyCode : "",
          mainShoppingCardList: mainShoppingCardList,
          ExchangeRate:
            shoppingCardList.length > 0 ? shoppingCardList[0].ExchangeRate : 1,
          playerLoyaltyPoints: playerLoyaltyPoints,
          CurrentCryptoCurrencyExchangeRate,
          CurrentLoyaltyPointsExchangeRate,
          PlayerLocalCurrencyId,
          LoyaltyPointsValue,
          baseCurrencyId,
          BaseCurrencyExchangeRate:shoppingCardList.length > 0 ? shoppingCardList[0].BaseCurrencyExchangeRate : 1,
          userPrimaryCard:{...userPrimaryCard},
          isValidPostalCode:isValidPostalCode
        },
        () => {
          GeneralUtils.showHideLoadingDiv(false)
          const isOnlyParticipantAvailable = this.checkOnlyParticipantTicketAdded() || (this.state.totalPrice === 0 && this.calculateSpectatorQuantityWithPrice())?true:false
          this.setState({ isOnlyParticipantAvailable })
        }
      )
    }
  }
  handlePayment(e?) {
    
    const { elements } = this.props
    const {userPrimaryCard} = this.state
    const onlyParticipantTicketsAdded = this.checkOnlyParticipantTicketAdded()
    if(!onlyParticipantTicketsAdded){
      
      if(userPrimaryCard.hasOwnProperty('postalCode')){
        this._stripCharge()
      }
      else{
        const cardNumberError = elements.getElement("cardNumber")
        const cardExpiryError = elements.getElement("cardExpiry")
        const cardCvcError = elements.getElement("cardCvc")
          this.setState(
          {
            stripeCardNumberValid: cardNumberError._complete,
            stripeCardExpValid: cardExpiryError._complete,
            stripeCardCvcValid: cardCvcError._complete,
          },
          () => {
            const {
              stripeCardNumberValid,
              stripeCardExpValid,
              stripeCardCvcValid,
            } = this.state
            if (stripeCardNumberValid && stripeCardExpValid && stripeCardCvcValid) {
              this.getStripeToken(e)
            }
            else{
              this.setLoading(false)
            }
          }
        )
      }
    }
    else{
      this._stripCharge()
    }
  }
  async getStripeToken(e?) {
    const { paymentDetail } = this.state
    const zip = paymentDetail.postalCode
    if (!zip && isNaN(parseInt(zip))) {
      this.setLoading(false)
    } else {
      const token = await this.props.stripe.createToken({
        name: paymentDetail.name,
        address_zip: zip,
      })
      paymentDetail.stripeToken = token.token.id
      await this._stripCharge()
    }
  }
  async _stripCharge() {
    const {
      paymentDetail,
      shoppingCardList,
      totalPrice,
      PlayerLocalCurrencyId,
      ExchangeRate,
      currencyCode,
      userPrimaryCard
    } = this.state
    try{
      this.setLoading(true)
      let ShoppingCardList = shoppingCardList.filter((x) => x.IsValid === true && x.IsTournamentExpired == false)
      let userPayment: UserPayment = Object()
      userPayment.amount = round2Places(totalPrice, 2)
      paymentDetail.userPayment = userPayment
      paymentDetail.userPayment.date = new Date()
      paymentDetail.shopingViewModel = ShoppingCardList
      paymentDetail.isOnlyParticipantAvailable = this.checkOnlyParticipantTicketAdded() || (this.state.totalPrice === 0 && this.calculateSpectatorQuantityWithPrice())?true:false
      paymentDetail.PlayerLocalCurrencyId = PlayerLocalCurrencyId
      paymentDetail.ExchangeRate = round2Places(ExchangeRate,2)
      paymentDetail.pmTblId = userPrimaryCard.pmTblId
      let res = await StripeUtils.stripCharge(paymentDetail)
      if (!res.isError && res.data !== null) {
        
        res = await this.updateCartDetailAndCart(true, res.data)
        if (!res.isError) {
          localStorage.removeItem('carttournamentdetailid')
          window.location.href = "/my-tickets"
        }

        fbq('track', 'Purchase', {
          currency: currencyCode,
          value: round2Places(totalPrice, 2),
        })
        gtag('event','purchase',{
          currency: currencyCode,
          value: round2Places(totalPrice, 2),
        })
      }
    }
    catch(e){
      if(e && e.toString().includes("Error")){
        console.log(e)
        alert("An error occured")
      }
    }
    finally{
      this.setLoading(false)
    }
    
  }

  nextButtonHandler() {
    if (
      this.state.shoppingCardList &&
      this.state.shoppingCardList.length == 0 &&
      !this.state.isOnlyParticipantAvailable
    )
      this.setState({ cartItemWarning: true })
    else this.setState({ cartItemWarning: false })
  }
    checkoutCart() {
        const { paymentMethod, shoppingCardList } = this.state
        const shoppingCartData:ShoppingCardModel[] = shoppingCardList.filter(x=>x.IsTournamentExpired == false && x.IsValid === true)
        const selectedSeats = shoppingCartData.map(x=>x.SelectedSeats)
        let totalQuantity = 0
        selectedSeats.map((x:SelectedSeatsModel[])=>{
          x.map((y)=>{
            if(y.IsTaken === true && y.IsPurchased === false){
                totalQuantity+=1
            }
          })
        })
        if (totalQuantity !== 0) {
            if (this.checkOnlyParticipantTicketAdded() || (this.calculateSpectatorQuantityWithPrice() && this.state.totalPrice === 0)) this._stripCharge()
            else if (paymentMethod == "Credit") this.handlePayment()
            else if (paymentMethod == "LoyaltyPoints") this.checkOutByLoyaltyPoints()
            else if (paymentMethod == "Crypto") this.checkOutByCrypto()
            //else GeneralUtils.showError("Select payment method")
            else alert("Select payment method")

        }
        else {
            alert("Please Add Tickets to the Cart to Checkout")
            //GeneralUtils.showError("Please Add Tickets to the Cart to Checkout")
            this.setLoading(false)
        }
    }
  async checkOutHandler(isConfirm:boolean = false) {
    
    if(!isConfirm){
      const invalidTournaments = await this.validateShoppingCard()
      this.setState({
        //invalidTournaments:invalidTournaments,
        isValidateCart:invalidTournaments === 0
      },()=>{
        
        if(invalidTournaments === 0){
          this.checkoutCart()
        }
        else{
          document.getElementById('modal_invalid_tournaments').className = 'modal-container modal-container--opened'
        }
      })
    }
    else{
      this.checkoutCart()
    }
  }

  async updateCartDetailAndCart(credit?: Boolean, paymentId?: string) {
    const {
      shoppingCardList,
      paymentMethod,
      PlayerLocalCurrencyId,
      ExchangeRate,    
      BaseCurrencyExchangeRate
    } = this.state
    try{
      this.setLoading(true)
      const userParticipantCart = shoppingCardList.filter(x=>x.ParticipantTicket === true  && x.Quantity > 0 && x.IsTournamentExpired == false && x.IsValid == true)
      let userSpectatorCart = shoppingCardList.filter(x=>x.ParticipantTicket === false && x.Quantity > 0 && x.IsTournamentExpired == false && x.IsValid == true)
      let usershoppingCardList = userParticipantCart.concat(userSpectatorCart)
      //const userSelectedSeats = usershoppingCardList.flatMap(x=>x.SelectedSeats)
      let cartIds = usershoppingCardList.map((x) => x.CartId)
      cartIds = [...new Set(cartIds)]
      cartIds = cartIds.filter(x=>x > 0 && x !== null)
      const cartListPostModel: TournamentCartAndCartDetailPostModel[] = []
      for (var i = 0 ; i < cartIds.length ; i++) {
        
        let cart = usershoppingCardList.filter((x) => x.CartId == cartIds[i])
        let totalPrice = Number(this.calculatePrice(cart))
        const OrganizerBaseCurrencyId = cart.filter(
          (x) => x.CartId === cartIds[i]
        )[0].OrganizerBaseCurrencyId
        const OrganizerStripeCurrencyId = cart.filter(
          (x) => x.CartId === cartIds[i]
        )[0].OrganizerStripeCurrencyId
        const cartPostModel: TournamentCartAndCartDetailPostModel = Object()

        const total: number = round2Places(totalPrice, 2)
        cartPostModel.UserId = StorageUtils.getUser().id
        cartPostModel.CartId = cartIds[i]
        
        cartPostModel.CartTotal = cartPostModel.IsPaid!=2?total:cartPostModel.CartTotal
        cartPostModel.TicketSentStatus = paymentMethod === "Crypto"?TournamentTicketSentStatusEnum.Pending:TournamentTicketSentStatusEnum.Active
        cartPostModel.IsPaid = cart.filter(
          (x) => x.CartId === cartIds[i]
        )[0].IsPaid
        if(cartPostModel.IsPaid === null || cartPostModel.IsPaid === 1){
            cartPostModel.UserPaymentId = cart[0].ParticipantTicket?0:(
            paymentMethod == "Credit"
              ? Number(paymentId)
              : paymentMethod == "LoyaltyPoints"
              ? 3
              : paymentMethod == "Crypto"
              ? 2
              : 0)
        }
        
        cartPostModel.OrganizerBaseCurrencyId = OrganizerBaseCurrencyId
        cartPostModel.OrganizerStripeCurrencyId = OrganizerStripeCurrencyId
        
        cartPostModel.PlayerLocalCurrencyId = PlayerLocalCurrencyId
        cartPostModel.ExchangeRate = round2Places(ExchangeRate,2)
            
          
        cartPostModel.BaseCurrencyExchangeRate = BaseCurrencyExchangeRate
        const cartDetailPostModel: TournamentCartDetailPostModel[] = []
        for (let index = 0 ; index < cart.length ; index++) {
          
          const selectedSeats = cart[index].SelectedSeats.filter(x=>x.IsTaken === true  && x.IsPurchased === false && x.IsValidSeat===true && x.IsTournamentValid === true && x.IsSoldOutSectionSeats !== true)
          const inValidselectedSeats = cart[index].SelectedSeats.filter(x=>x.IsTaken === true  && x.IsPurchased === true && x.IsValidSeat === false )
         
          const cartDetails = selectedSeats.map(x=>x.CartDetailId)
          const cartDetailIds=[...new Set(cartDetails)]
          for(let cartDetailInex = 0;cartDetailInex <cartDetailIds.length;cartDetailInex++){
            const cartDetailPostModelObject: TournamentCartDetailPostModel = Object()
            cartDetailPostModelObject.Quantity = cart[index].Quantity
            cartDetailPostModelObject.IsParticipient = cart[index].ParticipantTicket
            cartDetailPostModelObject.TournamentDetailId = cart[index].TournamentDetailId
            const seatsModel: SelectedSeatsModel[] = []
            const cartDetailSelectedSeats = selectedSeats.filter(x=>x.CartDetailId === cartDetailIds[cartDetailInex])
            let totalCartDetailPrice:number = 0
            for (let seatIndex = 0; seatIndex < cartDetailSelectedSeats.length;seatIndex++){
              const seatModel: SelectedSeatsModel = Object()
              seatModel.SeatId = cartDetailSelectedSeats[seatIndex].SeatId
              seatModel.SectionId = cartDetailSelectedSeats[seatIndex].SectionId
              seatModel.CartDetailId = cartDetailSelectedSeats[seatIndex].CartDetailId
              seatModel.TicketId = cart[index].TicketId
              seatModel.SingleTicketPrice = cartDetailPostModelObject.IsParticipient === true?"0":round2Places(Number(this.calculateCartDetailSectionPrice(cart[index],cartDetailIds[cartDetailInex],seatModel.SectionId,seatModel.SeatId,1)),2)?.toString()
              seatModel.CartId = cartDetailSelectedSeats[seatIndex].CartId
              seatModel.IsValidSeat = true
              seatsModel.push(seatModel)
              totalCartDetailPrice+=Number(seatModel.SingleTicketPrice)
            }
            for (let inValidseatIndex = 0; inValidseatIndex < inValidselectedSeats.length;inValidseatIndex++){
              const inValidSeatIndex = cart[index].SelectedSeats.findIndex(x=>x.SeatId === inValidselectedSeats[inValidseatIndex].SeatId)
              if(inValidSeatIndex >= 0){
                const seatModel: SelectedSeatsModel = Object()
                seatModel.SeatId = cart[index].SelectedSeats[inValidSeatIndex].SeatId
                seatModel.SectionId = cart[index].SelectedSeats[inValidSeatIndex].SectionId
                seatModel.CartDetailId = cart[index].SelectedSeats[inValidSeatIndex].CartDetailId
                seatModel.TicketId = cart[index].TicketId
                seatModel.CartId = cart[index].SelectedSeats[inValidSeatIndex].CartId
                seatModel.SingleTicketPrice = 0
                seatModel.IsValidSeat = false
                seatsModel.push(seatModel)
              }
            }
            cartDetailPostModelObject.Quantity = cartDetailSelectedSeats.length
            cartDetailPostModelObject.CartDetailId = cartDetailIds[cartDetailInex]
            cartDetailPostModelObject.SelectedSeats = seatsModel
            cartDetailPostModelObject.TotalPrice = totalCartDetailPrice
            cartDetailPostModelObject.ClanId = cart[index].ClanId
            cartDetailPostModel.push(cartDetailPostModelObject)
          }
          
          
          // if(cartPostModel.IsPaid === 2){
          //   cartDetailPostModelObject.TotalPrice = round2Places(cart[index].Price,2)
          // }
          // else{
          //   cartDetailPostModelObject.TotalPrice = round2Places(Number(this.calculateShoppingCartPrice(cart[index],cart[index].Quantity)),2)
          // }
          
          
        }
        const totalQuantity = cartDetailPostModel.reduce((sum, record) => {
          return (
            sum +record.Quantity
          )
        }, 0)

        if(totalQuantity >0){
          if(paymentMethod === "Crypto")
            cartPostModel.IsPaid = 1
          else
            cartPostModel.IsPaid = 2
        }
        cartPostModel.CartDetailModel = cartDetailPostModel
        
        const SpectatorTicketCount = cartDetailPostModel.filter(x=>x.IsParticipient===false)
        const ParticipentTicketCount = cartDetailPostModel.filter(x=>x.IsParticipient===true)
        let SpectatorTicketQuantityCount = SpectatorTicketCount.length> 0? SpectatorTicketCount.reduce((sum, record) => {
          return (
            sum +record.Quantity
          )
        }, 0):0
        let ParticipentTicketQuantityCount = ParticipentTicketCount.length> 0? ParticipentTicketCount.reduce((sum, record) => {
          return (
            sum +record.Quantity
          )
        }, 0):0
        if((SpectatorTicketQuantityCount > 0 || ParticipentTicketQuantityCount === 1) && paymentMethod!=="Crypto"){
          cartPostModel.PaymentOn = new Date()
        }
        else{
          cartPostModel.PaymentOn = null
        }
        
        cartListPostModel.push(cartPostModel)
      }
      console.log(cartListPostModel)
      
      return await TournamentCartUtils.UpdateCartAndCartDetailAfterPurchase(
        cartListPostModel
      )
    }
    finally{
      this.setLoading(false)
    }
   
    
  }
  async checkOutByLoyaltyPoints() {
    let { playerLoyaltyPoints, totalPrice } = this.state
    let exchangeLoyaltyPoints = totalPrice
    if (exchangeLoyaltyPoints > playerLoyaltyPoints) {
      alert("You don't have enough loyalty points to checkout!!")
      //GeneralUtils.showError("You don't have enough loyalty points to checkout!!")
      this.setLoading(false)
      return
    }
    try {
      const result = await this.updateCartDetailAndCart()
      if (!result.isError) {
        const loyaltyPointModel: LoyaltyPointsModel = Object()
        loyaltyPointModel.note = "Reverse Loyalty Points for Tournament Payment"
        loyaltyPointModel.points = Math.round(
         -exchangeLoyaltyPoints
        )
        const saveLoyaltyPoints = await LoyaltyPointsUtils.Save(
          loyaltyPointModel
        )
        if (!saveLoyaltyPoints.isError) {

          fbq('track', 'Purchase', {
            currency: 'LoyaltyPoints',
            value: loyaltyPointModel.points,
          })
          gtag('event','purchase',{
            currency: 'LoyaltyPoints',
            value: loyaltyPointModel.points
          })
          localStorage.removeItem('carttournamentdetailid')
          window.location.href = "/my-tickets"
        }
        
      }
    }
    catch(e) {
      if(e){
        alert("An error occured")
        console.log(e)
        //const error = e.toString().split("Error:")
        //GeneralUtils.showError(error[1].trim())
      }
      return
    }
    finally{
      this.setLoading(false)
    }
    
  }

  async checkOutByCrypto() {
    try{
      this.setLoading(true)
      const result = await this.updateCartDetailAndCart()
      if (!result.isError) {
        const { shoppingCardList } = this.state
        const userParticipantCart = shoppingCardList.filter(x=>x.ParticipantTicket === true && (x.CartId > 0 && x.CartId!==null && x.CartDetailId!==null && x.CartDetailId!==0) && x.IsTournamentExpired == false && x.IsValid === true)
        const userSpectatorCart = shoppingCardList.filter(x=>(x.CartId > 0 && x.CartId!==null) && x.ParticipantTicket === false && x.Quantity > 0 && x.IsTournamentExpired == false && x.IsValid === true)
        const usershoppingCardList = userParticipantCart.concat(userSpectatorCart)
        let cartIds = usershoppingCardList.map((x) => x.CartId)
        usershoppingCardList.map((x)=>{
          const selectedSeats = x.SelectedSeats.filter(x=>x.IsTaken === true && x.IsPurchased === false && x.IsSoldOutSectionSeats != true && x.IsTournamentValid === true && x.CartId !== null && x.CartId > 0)
          if(selectedSeats.length > 0){
            selectedSeats.map(x=>x.CartId).forEach((a)=>{
              cartIds.push(a)
            })
          }
        })
        const user: UserModel = StorageUtils.getUser()
        if (user) {
          
          const coinBaseModel: CoinBaseViewModel = Object()
          coinBaseModel.Amount = round2Places(this.state.totalPrice, 2)
          coinBaseModel.CustomerName = user.username
          coinBaseModel.Name = user.username
          coinBaseModel.email = user.emailaddress
          coinBaseModel.Description = "Ticket Purchase"
          coinBaseModel.Type = "Crypto"
          coinBaseModel.CartIds = [...new Set(cartIds)].join(",")
          const createCharge = await CoinBaseUtils.createCoinBaseCharge(
            coinBaseModel
          )
          if (!createCharge.isError) {

            fbq('track', 'Purcase', {
              currency: coinBaseModel.Type,
              value: round2Places(this.state.totalPrice, 2),
            })
            gtag('event','purchase',{
              currency: coinBaseModel.Type,
              value: round2Places(this.state.totalPrice, 2),
            })

            if (createCharge.data) {
              this.setState(
                {
                  hostedUrl: createCharge.data,
                },
                async () => {
                  this.redirectHostedUrl()
                  
                  await this.getcartstatus(coinBaseModel.CartIds)
                }
              )
            }
          } else {
            alert('An Error Occurred')
          }
        } else {
          alert('Please Login')
        }
      }
    }
    catch(e){
      if(e){
        console.log("An Error occured")
        console.log(e)
       
      }
    }
    finally{
      this.setLoading(false)
    }
   
  }
  redirectHostedUrl() {
    const { hostedUrl } = this.state
    if (hostedUrl) window.open(hostedUrl)
  }
  calculatePrice(shoppingCardList:ShoppingCardModel[],setState:boolean=false) {
    let {
      paymentMethod,
      CurrentCryptoCurrencyExchangeRate,
      CurrentLoyaltyPointsExchangeRate,
      LoyaltyPointsValue,
    } = this.state
    shoppingCardList = shoppingCardList.filter(x=>x.IsTournamentExpired === false && x.IsValid === true)
    let totalPrice = 0
    
    if (paymentMethod === "Credit") {
       shoppingCardList.map((x,y)=>{
        if(x.SelectedSeats == null || (x.SelectedSeats!==null && x.SelectedSeats.length === 0)){
          totalPrice += 0
        }
        else{
          totalPrice += x.SelectedSeats.filter(x=>x.IsTaken === true  && x.IsPurchased === false && x.IsSoldOutSectionSeats!==true && x.IsTournamentValid===true).reduce((sum, record) => {
            return (
              sum +
              round2Places(
                Number(
                  record.LocalTicketPrice * round2Places(x.ExchangeRate,2)
                ),
                2
              )
            )
          }, 0)
        }
      })
    } else if (paymentMethod === "Crypto") {
      shoppingCardList.map((x,y)=>{
        if(x.SelectedSeats== null){
          totalPrice += 0
        
        }
        else{
          totalPrice += x.SelectedSeats.filter(x=>x.IsTaken === true  && x.IsPurchased === false && x.IsSoldOutSectionSeats!==true && x.IsTournamentValid===true).reduce((sum, record) => {
            let Price = round2Places(
              CurrentCryptoCurrencyExchangeRate *
                Number(
                  record.LocalTicketPrice * (round2Places(x.ExchangeRate,2))
                ),
              2
            )
            return sum + Price
          }, 0)
        }
      })
    } else {
      shoppingCardList.map((x,y)=>{
        if(x.SelectedSeats== null){
          totalPrice += 0
        
        }
        else{
          totalPrice += x.SelectedSeats.filter(x=>x.IsTaken === true  && x.IsPurchased === false && x.IsSoldOutSectionSeats !== true && x.IsTournamentValid===true).reduce((sum, record) => {
            return Math.round(
              sum +
                round2Places(
                  CurrentLoyaltyPointsExchangeRate *
                    LoyaltyPointsValue *
                    (record.LocalTicketPrice *
                      round2Places(x.ExchangeRate,2)),
                  2
                )
            )
          }, 0)
        }
      })

    }
    if(setState){
      this.setState({
        totalPrice: totalPrice,
        TotalLoyaltyPoints:
          paymentMethod === "LoyaltyPoints" ? Math.round(totalPrice) : 1,
      },()=>{

        if(this.state.totalPrice > 0 && !this.state.isSummary){
          this.setState({
            isSummary:(this.state.paymentDetail.postalCode && this.validPostalCode(this.state.paymentDetail.postalCode) && this.state.paymentDetail.name && this.state.paymentDetail.email)?false:true
          })
        }
      })
    }
    return totalPrice
  }
  handleCardElementOnChange = (element, name: string) => {
    this.setState({
      ...this.state,
      [name]: !element.empty && element.complete ? true : false,
    })
  }
  async deleteCart() {
    const { shoppingCardList } = this.state
    const userCart = shoppingCardList.filter(
      (x) => x.IsTournamentExpired === true
    )
    const userCartId = userCart.map((x) => x.CartId)
    const userCartIdUnique = [...new Set(userCartId)].join(",")
    const result = await TournamentCartUtils.DeleteUserCart(
      userCartIdUnique
    )
    if (!result.isError) {
      document.getElementById("modal_delete-cart").className =
        "modal-container"
      this.setState(
        {
          shoppingCardList: [],
        },
        () => {
          this.getCart()
        }
      )
    }
  }
  async getcartstatus(cartId: string) {
    const Ids = cartId
    let cryptoInterval: any =
      localStorage.getItem("cryptoInterval") == null
        ? 0
        : parseInt(localStorage.getItem("cryptoInterval"))
    if (
      localStorage.getItem("isPending") === "Completed"
    ) {
      clearInterval(cryptoInterval)
      GeneralUtils.showSuccess("The checkout using crypto currency has been Completed",5000)
      localStorage.removeItem('carttournamentdetailid')
      localStorage.removeItem("isPending")
      window.location.href = "/my-tickets"
    }
    else if (
      localStorage.getItem("isPending") === "Cancelled"
    ) {
      clearInterval(cryptoInterval)
      GeneralUtils.showError("The checkout using crypto currency has been Cancelled",5000)
      localStorage.removeItem('carttournamentdetailid')
      localStorage.removeItem("isPending")
      window.location.href = "/my-tickets"
    } else {
      const result = await TournamentCartUtils.GetCartStatus(Ids)
      if (!result.isError) {
          cryptoInterval = setInterval(
            async () => await this.getcartstatus(Ids),
            5000
          )
          localStorage.setItem("cryptoInterval", cryptoInterval.toString())
          localStorage.setItem("isPending", result.data.toString())
        } else {
          clearInterval(cryptoInterval)
        
      }
    }
  }
  setLoading(loading:boolean){
    this.setState({
      loading:loading
    })
  }
  checkOnlyParticipantTicketAdded(){
    let onlyParticipantTicketsAdded = false
    const{shoppingCardList}=this.state
    if(shoppingCardList && shoppingCardList.length > 0){
      onlyParticipantTicketsAdded= shoppingCardList.filter(x=>x.ParticipantTicket === false && x.Quantity > 0).length === 0
    }
    return onlyParticipantTicketsAdded
  }
  async validateShoppingCard(){
    const {shoppingCardList} = this.state
    let invalidTournamentCount = 0
    const result = await TournamentCartUtils.ValidateCartOnCheckOut(shoppingCardList)
    if(!result?.isError && result?.data){
        const invalidCart = result.data
        let invalidTournaments:ShoppingCardModel[]=[]
        invalidCart.map(x=>{
          if(!x.IsValid || x.IsTournamentExpired || x.SelectedSeats.filter(x=>x.IsPurchased === true && x.IsValidSeat === false).length > 0){
            const currentTicketCart = shoppingCardList.findIndex(y=>y.TicketId === x.TicketId)
            shoppingCardList[currentTicketCart].IsValid = x.IsValid
            shoppingCardList[currentTicketCart].IsTournamentExpired = x.IsTournamentExpired
            shoppingCardList[currentTicketCart].TicketMessage = ""
            shoppingCardList[currentTicketCart].SelectedSeats = x.SelectedSeats
            //x.SelectedSeats = [...x.SelectedSeats.filter(x=>x.IsPurchased === true && x.IsTaken === true && x.IsValidSeat === false)]
           
            invalidTournamentCount++
            invalidTournaments.push(x)
          }
        })
        if(invalidTournamentCount > 0){
          this.setState({
            shoppingCardList:[...shoppingCardList],
            invalidTournaments:[...invalidTournaments]
          })
        }
        else{
          this.setState({
            shoppingCardList:[...shoppingCardList],
            invalidTournaments:[]
          })
        }
    }
    return invalidTournamentCount
  }
  generateMessageForTicket(name:string,daynumber:any,time:any,message:string){
    if(message === "Registration is Closed"){
      message = message + " for Tournament " + name
    }
    else if(message === "Sold Out!!"){
      message = "Tickets for Tournament " + name +" has been " + message
    }
    return message
  }
  validateTicket(ShoppingCard:ShoppingCardModel,todayDate:any){
    const now = todayDate
    let isValidDate = false
    let message = ''
    
    if(ShoppingCard.ParticipantTicket && ShoppingCard.Quantity >= 1 && (ShoppingCard.IsPaid === null || ShoppingCard.IsPaid === 1)){
      if(EventTypeEnum.Main !== ShoppingCard.EventTypeId){
        isValidDate = new Date(ShoppingCard.RegistrationOpensOn) <= now && now < new Date(ShoppingCard.RegistrationClosesOn)
        if(!isValidDate){
          message = ShoppingCard.Title +' - Participation for this tournament has been closed'
        }
      }
      else if(EventTypeEnum.Main === ShoppingCard.EventTypeId){
        isValidDate = now < new Date(ShoppingCard.RegistrationClosesOn)
        if(!isValidDate){
          message = ShoppingCard.Title +' - Participation for this tournament has been closed'
        }
      }
    }
    else if(!ShoppingCard.ParticipantTicket){
      isValidDate = new Date(ShoppingCard.RegistrationOpensOn) <= now && now < new Date(ShoppingCard.StartDate)
      if(!isValidDate){
        message = 'Tournament '+ShoppingCard.Title +' has already Started' 
      }
    } 
    return message
  }
  updateGloablShoppingCart(shoppingCardList:ShoppingCardModel[],ShoppingCard:ShoppingCardModel){
    let {mainShoppingCardList} = this.state
    
    if(ShoppingCard.ParticipantTicket){
      const index = shoppingCardList.findIndex(x=>x.Id === ShoppingCard.Id)
      shoppingCardList[index].CartId = ShoppingCard.CartId
      shoppingCardList[index].TicketId = ShoppingCard.TicketId
      shoppingCardList[index].Quantity = ShoppingCard.Quantity
      mainShoppingCardList[index].TicketId=ShoppingCard.TicketId
      mainShoppingCardList[index].SelectedSeats = ShoppingCard.SelectedSeats
    }
    else{
      const tournamentShoppingCart = shoppingCardList.filter(x=>x.TournamentDetailId === ShoppingCard.TournamentDetailId && x.ParticipantTicket === false)
      tournamentShoppingCart.map(x=>{
        const mainIndex = mainShoppingCardList.findIndex(a=>a.Id === x.Id)
        const index = shoppingCardList.findIndex(a=>a.Id === x.Id)
        if(x.Id === ShoppingCard.Id){
          shoppingCardList[index].Quantity = ShoppingCard.Quantity
          mainShoppingCardList[index].Quantity=ShoppingCard.Quantity
          mainShoppingCardList[mainIndex].SelectedSeats = shoppingCardList[index].SelectedSeats
        }
        if(index >= 0)
            shoppingCardList[index].CartId = ShoppingCard.CartId
        if(mainIndex >= 0)
            mainShoppingCardList[mainIndex].CartId=ShoppingCard.CartId
      })
    }
    var result:any=Object()
    result.shoppingCardList = [...shoppingCardList]
    result.mainShoppingCardList = [...mainShoppingCardList]
    
    return result
    
  
  }
  calculateCartDetailSectionPrice(ShoppingCard:ShoppingCardModel,CartDetailId:number,SectionId:number,SeatId:number,Quantity:number = 1){
    const{paymentMethod,CurrentLoyaltyPointsExchangeRate,CurrentCryptoCurrencyExchangeRate,LoyaltyPointsValue} = this.state
    let totalPrice = 0
    const currentCartDetail = ShoppingCard.SelectedSeats.filter(x=>x.CartDetailId === CartDetailId && x.SectionId === SectionId && x.SeatId === SeatId)
    if(currentCartDetail.length === 0){
      return totalPrice
    }
    if(paymentMethod === "Credit"){
      totalPrice = currentCartDetail.filter(x=>x.IsTaken === true && x.IsPurchased === false && x.IsSoldOutSectionSeats!==true && x.IsTournamentValid===true).reduce((sum, record) => {
        return (sum + round2Places(record.LocalTicketPrice, 2) *
        Quantity *
        round2Places(ShoppingCard.ExchangeRate,2))
      }, 0)
    }
    else if(paymentMethod === "LoyaltyPoints"){
      totalPrice=currentCartDetail.filter(x=>x.IsTaken === true && x.IsPurchased === false && x.IsSoldOutSectionSeats!==true && x.IsTournamentValid===true).reduce((sum, record) => {
          return Math.round(sum + round2Places(
            CurrentLoyaltyPointsExchangeRate *
              LoyaltyPointsValue *
              (record.LocalTicketPrice * Quantity * round2Places(ShoppingCard.ExchangeRate,2)),
            2
          ))
        }, 0)
    }
    else{
      totalPrice=currentCartDetail.filter(x=>x.IsTaken === true && x.IsPurchased === false && x.IsSoldOutSectionSeats!==true && x.IsTournamentValid===true).reduce((sum, record) => {
        return (sum + round2Places(
          CurrentCryptoCurrencyExchangeRate *
            Number(
              record.LocalTicketPrice * Quantity * (round2Places(ShoppingCard.ExchangeRate,2))
            ),
          2
        ))
      }, 0)
    }
    
    return totalPrice
  }
  calculateCurrentCartPrice(ShoppingCard:ShoppingCardModel,paymentMethod:any,CurrentLoyaltyPointsExchangeRate:any,CurrentCryptoCurrencyExchangeRate:any,LoyaltyPointsValue:any,Quantity:number = 1){
    
    let totalPrice = 0
    if(ShoppingCard.SelectedSeats===null){
      return totalPrice
    }
    if(paymentMethod === "Credit"){
      totalPrice = ShoppingCard.SelectedSeats.filter(x=>x.IsTaken === true && x.IsPurchased === false && x.IsSoldOutSectionSeats!==true && x.IsTournamentValid===true).reduce((sum, record) => {
        return (sum + round2Places(record.LocalTicketPrice, 2) *
        Quantity *
        round2Places(ShoppingCard.ExchangeRate,2))
      }, 0)
    }
    else if(paymentMethod === "LoyaltyPoints"){
      totalPrice=ShoppingCard.SelectedSeats.filter(x=>x.IsTaken === true && x.IsPurchased === false && x.IsSoldOutSectionSeats!==true && x.IsTournamentValid===true).reduce((sum, record) => {
          return Math.round(sum + round2Places(
            CurrentLoyaltyPointsExchangeRate *
              LoyaltyPointsValue *
              (record.LocalTicketPrice * Quantity * round2Places(ShoppingCard.ExchangeRate,2)),
            2
          ))
        }, 0)
    }
    else{
      totalPrice=ShoppingCard.SelectedSeats.filter(x=>x.IsTaken === true && x.IsPurchased === false && x.IsSoldOutSectionSeats!==true && x.IsTournamentValid===true).reduce((sum, record) => {
        return (sum + round2Places(
          CurrentCryptoCurrencyExchangeRate *
            Number(
              record.LocalTicketPrice * Quantity * (round2Places(ShoppingCard.ExchangeRate,2))
            ),
          2
        ))
      }, 0)
    }
    
    return totalPrice
  }


  calculateShoppingCartPrice(ShoppingCard:ShoppingCardModel,Quantity:number = 1){
    
    const{paymentMethod,CurrentLoyaltyPointsExchangeRate,CurrentCryptoCurrencyExchangeRate,LoyaltyPointsValue} = this.state
    let totalPrice = 0
    if(ShoppingCard.SelectedSeats === null){
      return totalPrice
    }
    return this.calculateCurrentCartPrice(ShoppingCard,paymentMethod,CurrentLoyaltyPointsExchangeRate,CurrentCryptoCurrencyExchangeRate,LoyaltyPointsValue,Quantity)
  }
  async AddOrUpdateCartDetail(cartDetails:ShoppingCardModel,isAddition:boolean = false,SeatId:number=0,SectionId:number = 0){
    try{
      const {shoppingCardList} = this.state
      cartDetails.IsAddition = isAddition
      var newCartDetails={...cartDetails}
      if(cartDetails.ParticipantTicket !==  true){
        newCartDetails.SelectedSeats = newCartDetails.SelectedSeats.filter(x=>x.SeatId == SeatId && x.SectionId == SectionId && x.IsTaken == true && x.IsPurchased === false && x.IsSoldOutSectionSeats!==true && x.IsTournamentValid===true)
      }
      const result = await TournamentCartUtils.AddOrUpdateCart(newCartDetails)
      if(!result.isError && result.data){
          const shoppingCartIndex = shoppingCardList.findIndex(x=>x.Id === cartDetails.Id)
          if(isAddition){
            const index = cartDetails.SelectedSeats.findIndex(x=>x.SeatId == SeatId && x.SectionId == SectionId && x.IsTaken == true && x.IsPurchased === false)
            cartDetails.CartId = result.data.SelectedSeats.filter(x=>x.SeatId == SeatId && x.SectionId == SectionId)[0].CartId
            cartDetails.SelectedSeats[index].CartId = result.data.SelectedSeats.filter(x=>x.SeatId == SeatId && x.SectionId == SectionId && x.IsTaken == true && x.IsPurchased === false && x.IsSoldOutSectionSeats!==true && x.IsTournamentValid===true)[0].CartId
            cartDetails.SelectedSeats[index].CartDetailId = result.data.SelectedSeats.filter(x=>x.SeatId == SeatId && x.SectionId == SectionId && x.IsTaken == true && x.IsPurchased === false && x.IsSoldOutSectionSeats!==true && x.IsTournamentValid===true)[0].CartDetailId
            cartDetails.SelectedSeats[index].TicketId=result.data.TicketId
            cartDetails.SelectedSeats[index].IsPaid = cartDetails.ParticipantTicket?2:1
          }
          else{
            
            const index = cartDetails.SelectedSeats.findIndex(x=>x.SeatId == SeatId && x.SectionId == SectionId && x.IsTaken == true && x.IsPurchased === false && x.CartDetailId > 0)
            cartDetails.SelectedSeats.splice(index,1)
            const totalTicketsCart = shoppingCardList.filter(x=>x.TournamentDetailId === cartDetails.TournamentDetailId)
            if(cartDetails.DayNumber === null && cartDetails.Time === null){
              if(totalTicketsCart.length > 0){
                totalTicketsCart.map((x:ShoppingCardModel)=>{
                    let selectedSeats= x.SelectedSeats
                    const seatIndex = selectedSeats.findIndex(a=>a.SeatId === SeatId)
                    if(seatIndex >= 0){
                      selectedSeats.splice(seatIndex,1)
                      x.SelectedSeats = selectedSeats
                      const cardIndex = shoppingCardList.findIndex(s=>s.Id === x.Id)
                      shoppingCardList[cardIndex].SelectedSeats = selectedSeats
                    }
                })
              }
            }
            else{
              const packageTicketCartIndex =totalTicketsCart.findIndex(a=>a.DayNumber === null && a.Time === null)
              
              if(packageTicketCartIndex >=0){
                const dayTicketsCart =totalTicketsCart.filter(a=>a.DayNumber !== null && a.Time !== null)
                const totalSeatIdsCount =dayTicketsCart.map((x)=>{
                    if(x.SelectedSeats!=null && x.SelectedSeats.findIndex(a=>a.SeatId === SeatId) >=0){
                      return SeatId
                    }
                })
                if(totalSeatIdsCount.filter(x=>x == SeatId).length <=1){
                  let selectedSeats= totalTicketsCart[packageTicketCartIndex].SelectedSeats
                  const seatIndex = selectedSeats.findIndex(a=>a.SeatId === SeatId)
                  if(seatIndex >= 0){
                    selectedSeats.splice(seatIndex,1)
                    totalTicketsCart[packageTicketCartIndex].SelectedSeats = selectedSeats
                    const cardIndex = shoppingCardList.findIndex(s=>s.Id === totalTicketsCart[packageTicketCartIndex].Id)
                    shoppingCardList[cardIndex].SelectedSeats = selectedSeats
                  }
                }
              }
            }
          }  
          
          const selectedSeats = [...cartDetails.SelectedSeats.filter(x=>x.IsTaken === true && x.IsPurchased === false && x.IsSoldOutSectionSeats!==true && x.IsTournamentValid===true)]
          cartDetails.SelectedSeats=selectedSeats
          shoppingCardList[shoppingCartIndex].SelectedSeats = shoppingCardList[shoppingCartIndex].SelectedSeats.concat(cartDetails.SelectedSeats)
          shoppingCardList[shoppingCartIndex].SelectedSeats = shoppingCardList[shoppingCartIndex].SelectedSeats.filter(x=>x.CartId !== null && x.CartId > 0 && x.CartDetailId !== null && x.CartDetailId > 0)
          shoppingCardList[shoppingCartIndex].SelectedSeats = GeneralUtils.uniqueByProperties(shoppingCardList[shoppingCartIndex].SelectedSeats,['CartDetailId','CartId','SeatId','SectionId'])
          const currentTournamentShoppingCart=shoppingCardList.filter(x=>x.TournamentDetailId === cartDetails.TournamentDetailId)
          const totalSelectedSeats = currentTournamentShoppingCart.filter(x=>x.TournamentDetailId === cartDetails.TournamentDetailId).flatMap(x=>x.SelectedSeats)
          const isValidSeats = totalSelectedSeats.filter(x=>x.IsTaken === true && x.IsPurchased === false && x.IsSoldOutSectionSeats!==true && x.IsTournamentValid === true).length > 0
          if(!isValidSeats){
            currentTournamentShoppingCart.map((x,index)=>{
              x.CartId = 0
              const currentIndex = shoppingCardList.findIndex(s=>s.Id === x.Id)
              shoppingCardList[currentIndex].CartId = 0
              currentTournamentShoppingCart[index].CartId = 0
            })
          }
          const newGlobalShoppingCart=this.updateGloablShoppingCart([...shoppingCardList],{...cartDetails})
          this.setState({
              shoppingCardList: [...newGlobalShoppingCart.shoppingCardList],
              mainShoppingCardList: newGlobalShoppingCart.mainShoppingCardList,
              updateQuantityLoaderId:0,
          },async ()=>{
            this.handleSetSelectedSeats(SectionId,cartDetails.TicketId,cartDetails.Id)
            this.calculatePrice([...newGlobalShoppingCart.shoppingCardList],true)
          })  
      }
    }
    catch{
      this.setState({
        updateQuantityLoaderId:0
      })
    }
  }
  calculateSpectatorQuantityWithPrice(){
    const {shoppingCardList} = this.state
    const spectatorTicketsCart=shoppingCardList.filter(x=>x.ParticipantTicket === false && x.CartId!==null && x.Quantity >= 1)
    return spectatorTicketsCart.length > 0
  }
  validPostalCode(postalCode:any){
    
    const {userCountryCode,userPrimaryCard} = this.state
    if(userPrimaryCard.hasOwnProperty('postalCode')){
      return true
    }
    const isValid = postalCodes.validate(userCountryCode, postalCode)
    this.setState({
      isValidPostalCode:isValid === true?true:false
    })
    return isValid ===true?true:false
    
  }
  async handleSetSelectedSeats(SectionId:number,TicketId:number,Id:number){
    this.setState({
      updateQuantityLoaderId:Id
    },async()=>{
      const result = await TournamentCartUtils.GetUserSelectedSeats(SectionId,TicketId)
      if(!result?.isError && result?.data){
        const {shoppingCardList} = this.state
        const index = shoppingCardList.findIndex(x=>x.Id === Id)

        if(shoppingCardList[index].SelectedSeats === null){
         shoppingCardList[index].SelectedSeats=[]
        }
        
        shoppingCardList[index].SelectedSeats = shoppingCardList[index].SelectedSeats.concat(result?.data)
        shoppingCardList[index].SelectedSeats = shoppingCardList[index].SelectedSeats.filter(x=>x.CartId !== null && x.CartId > 0 && x.CartDetailId !== null && x.CartDetailId > 0)
        shoppingCardList[index].SelectedSeats = GeneralUtils.uniqueByProperties(shoppingCardList[index].SelectedSeats,['CartDetailId','CartId','SeatId','SectionId'])
        
        const currentTournamentShoppingCart=shoppingCardList.filter(x=>x.TournamentDetailId === shoppingCardList[index].TournamentDetailId)
        const totalSelectedSeats = currentTournamentShoppingCart.filter(x=>x.TournamentDetailId === shoppingCardList[index].TournamentDetailId).flatMap(x=>x.SelectedSeats)
        const isValidSeats = totalSelectedSeats.filter(x=>x.IsTaken === true && x.IsPurchased === false && x.IsSoldOutSectionSeats!==true && x.IsTournamentValid === true).length > 0
        if(!isValidSeats){
          currentTournamentShoppingCart.map((x,index)=>{
            x.CartId = 0
            const currentIndex = shoppingCardList.findIndex(s=>s.Id === x.Id)
            shoppingCardList[currentIndex].CartId = 0
            currentTournamentShoppingCart[index].CartId = 0
          })
        }
        this.setState({
         shoppingCardList:[...shoppingCardList],
         updateQuantityLoaderId:0
        })
      }  
    })
  }
  renderSection(Id:number){
    const {shoppingCardList} = this.state
    let sectionName:any[]=[]
    const shoppingCardIndex = shoppingCardList.findIndex(x=>x.Id === Id)
    if(shoppingCardIndex >=0 && shoppingCardList[shoppingCardIndex].ParticipantTicket!==true){
      const selectedSeats= shoppingCardList[shoppingCardIndex].SelectedSeats.filter(x=>x.TicketId == shoppingCardList[shoppingCardIndex].TicketId && x.IsTaken === true && x.IsPurchased == false && x.IsSoldOutSectionSeats!==true && x.IsTournamentValid===true)
      if(selectedSeats.length > 0){
        selectedSeats.map((x)=>{
          if(sectionName.filter(a=>a.SectionId == x.SectionId).length === 0){
            const sectionData={
              SectionId:x.SectionId,
              SectionName:x.SectionName
            }
            sectionName.push(sectionData)
          }
        })
      }
    }
    return sectionName
  }
  renderSeats(Id:number,sectionId:number){
    const {shoppingCardList} = this.state
    let selectedSeats:SelectedSeatsModel[]=[]
    const shoppingCardIndex = shoppingCardList.findIndex(x=>x.Id === Id)
    if(shoppingCardIndex >=0 && shoppingCardList[shoppingCardIndex].ParticipantTicket!==true){
      selectedSeats = shoppingCardList[shoppingCardIndex].SelectedSeats.filter(x=>x.SectionId == sectionId && x.TicketId == shoppingCardList[shoppingCardIndex].TicketId && x.IsTaken === true && x.IsPurchased == false && x.IsSoldOutSectionSeats!==true && x.IsTournamentValid===true)
      return selectedSeats
    }
    return selectedSeats
  }
  openSeatDetail(Id:number){
    let isOpen:number=Id
    if(document.getElementById("seatSection "+Id)?.classList.contains("d-none")){
      document.getElementById("seatSection "+Id)?.classList.remove("d-none")
      isOpen = Id
    }
    else{
      document.getElementById("seatSection "+Id)?.classList.add("d-none")
    }
    this.setState({
      openId:isOpen
    })    

  } 
  calculateTotalSectionPrice(SelectedSeats:SelectedSeatsModel[],ShoppingCard:ShoppingCardModel){
    const{paymentMethod,CurrentLoyaltyPointsExchangeRate,CurrentCryptoCurrencyExchangeRate,LoyaltyPointsValue} = this.state
    let totalPrice = 0
    if(SelectedSeats===null){
      return totalPrice
    }
    if(paymentMethod === "Credit"){
      totalPrice = SelectedSeats.filter(x=>x.IsTaken === true && x.IsPurchased === false && x.IsSoldOutSectionSeats!==true && x.IsTournamentValid===true).reduce((sum, record) => {
        return (sum + round2Places(record.LocalTicketPrice, 2) *
        round2Places(ShoppingCard.ExchangeRate,2))
      }, 0)
    }
    else if(paymentMethod === "LoyaltyPoints"){
      totalPrice=SelectedSeats.filter(x=>x.IsTaken === true && x.IsPurchased === false && x.IsSoldOutSectionSeats!==true && x.IsTournamentValid===true).reduce((sum, record) => {
          return Math.round(sum + round2Places(
            CurrentLoyaltyPointsExchangeRate *
              LoyaltyPointsValue *
              (record.LocalTicketPrice *  round2Places(ShoppingCard.ExchangeRate,2)),
            2
          ))
        }, 0)
    }
    else{
      totalPrice=SelectedSeats.filter(x=>x.IsTaken === true && x.IsPurchased === false && x.IsSoldOutSectionSeats!==true && x.IsTournamentValid===true).reduce((sum, record) => {
        return (sum + round2Places(
          CurrentCryptoCurrencyExchangeRate *
            Number(
              record.LocalTicketPrice * (round2Places(ShoppingCard.ExchangeRate,2))
            ),
          2
        ))
      }, 0)
    }
    return totalPrice
  }
  calculateSelectedSeatsQuantityPerCard(CardId:number){
    const {shoppingCardList}  = this.state
    const currentCard = shoppingCardList.findIndex(x=>x.Id == CardId)
    if(currentCard >= 0){
        const currentSeats = shoppingCardList[currentCard].SelectedSeats
        const validSeats = currentSeats.filter(x=>x.CartId > 0 && x.CartDetailId > 0 && x.IsTaken == true && x.IsPurchased === false && x.IsSoldOutSectionSeats !== true && x.IsTournamentValid === true && x.IsValidSeat === true).length
        return validSeats
    }
    else{
      return 0
    }

  }
  render() {
    const {} = this.props
    const {
      shoppingCardList,
      isSummary,
      totalPrice,
      paymentDetail,
      isAgreed,
      paymentMethod,
      CurrentLoyaltyPointsExchangeRate,
      CurrentCryptoCurrencyExchangeRate,
      LoyaltyPointsValue,
      stripeCardNumberValid,
      stripeCardCvcValid,
      stripeCardExpValid,
      updateQuantityLoaderId,
      userPrimaryCard
    } = this.state
    const onlyParticipantTicketsAdded = this.checkOnlyParticipantTicketAdded()
    

    return (
      <div className="ShoppingCardPage">
        <article className="row cart-article-row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 cart-article-col-left">
            <div
              style={{ padding: "6% 5% 10%" }}
              className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12  article__content"
            >
              <div className="row">
                <a
                  className="article__back"
                  onClick={() => {
                    localStorage.removeItem('carttournamentdetailid')
                    window.history.back()
                  }}
                >
                  Back
                </a>
              </div>

              <div className="row article__header-container-ticket">
                <div className="col p-0">
                  <h1 className="article__header">Event Tickets</h1>
                </div>
                <div className="col-auto p-0 p-l-25 after-header-container">
                  <div className="orders__table-headers">
                    <div className="row flex-nowrap m-0">
                      <div className="orders__table-header orders__table-header--ac" />
                      <div className="orders__table-header orders__table-header--amount">
                        AMOUNT
                      </div>
                      <div className="orders__table-header orders__table-header--price">
                        PRICE
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row flex-column align-items-center">
                {shoppingCardList && shoppingCardList.length == 0 && (
                  <div style={{ color: "white" }}>No item in the Cart</div>
                )}
                {shoppingCardList &&
                  shoppingCardList.map((card, i) => {
                    
                    return (
                      <ShoppingCardComponent
                        key={i}
                        index={i}
                        ShoppingCard={card}
                        addQuantity={this.handlerAddQuantity}
                        deleteCartItem={this.handleDeleteCartItem}
                        CurrentCryptoCurrencyExchangeRate={
                          CurrentCryptoCurrencyExchangeRate
                        }
                        CurrentLoyaltyPointsExchangeRate={
                          CurrentLoyaltyPointsExchangeRate
                        }
                        LoyaltyPointsValue={LoyaltyPointsValue}
                        paymentMethod={paymentMethod}
                        updateQuantityLoader={updateQuantityLoaderId}
                        
                        setSelectedSeats={this.handleSetSelectedSeats}
                        
                      />
                    )
                  })}
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 cart-article-col-right">
            <div className="article__right-menu right-menu1 cart-section">
              <section className="article__summary summary">
                {isSummary && (
                  <div className="col-12 flex-column">
                    <div className="col-12">
                      <h2 className="summary__header">Order Summary</h2>
                    </div>
                    {shoppingCardList &&
                      shoppingCardList.map((t, i) => {
                        return (
                          t.IsValid && !t.IsTournamentExpired &&
                          t.Quantity > 0 && (
                            <div className="col summary__order">
                              <div className="row">
                              <div className="col-auto col-md-4">
                                {!t.ParticipantTicket && this.calculateSelectedSeatsQuantityPerCard(t.Id) > 0 && <button className="blue-button blue-button--filled btn btn-lg col-12"onClick={()=>{this.openSeatDetail(t.Id)}} >Seat Details</button>}
                                </div>
                                <div className="col-auto col-md-5">
                                
                                
                                  <div className="summary__order-title">
                                    {this.calculateSelectedSeatsQuantityPerCard(t.Id) > 0 ? this.calculateSelectedSeatsQuantityPerCard(t.Id)+ "X"+ t.Title : ""}{" "}
                                  </div>
                                </div>
                                {this.calculateSelectedSeatsQuantityPerCard(t.Id) > 0 && <div className="col-auto col-md-3">
                                  {paymentMethod === "Credit" && (
                                    <div className="summary__order-price">
                                      {" "}
                                      {t.ParticipantTicket
                                        ? "Free"
                                        : `${t.CurrencySymbol}` +
                                          `${round2Places(this.calculateShoppingCartPrice(t,1),2)}` +
                                          ` ${t.CurrencyCode}`}
                                    </div>
                                  )}
                                  {paymentMethod === "Crypto" && (
                                    <div className="summary__order-price">
                                      {" "}
                                      {t.ParticipantTicket
                                        ? "Free"
                                        : `$${round2Places(this.calculateShoppingCartPrice(t,1),2)} USD`}
                                    </div>
                                  )}
                                  {paymentMethod === "LoyaltyPoints" && (
                                    <div className="summary__order-price p-0">
                                      {" "}
                                      {t.ParticipantTicket
                                        ? "Free"
                                        : `${round2Places(this.calculateShoppingCartPrice(t,1),2)} LP`}
                                    </div>
                                  )}
                                </div>}
                                
                                {!t.ParticipantTicket && <div id={"seatSection "+t.Id} className="col-auto my-3 table table-responsive d-none">
                                {this.state.openId > 0 && this.calculateSelectedSeatsQuantityPerCard(t.Id) > 0 && this.renderSection(t.Id) && this.renderSection(t.Id).map((y:any)=>{
                                  return <>
                                  <hr className="mt-2 mb-2"/>
                                    <div className="row">
                                      <div className="col-auto col-md-6 summary__order-title">Section: {y.SectionName}</div>

                                      <div className="col-auto col-md-6">
                                      {paymentMethod === "Credit" && (
                                        <div className="summary__order-price">
                                          {" "}
                                          {t.ParticipantTicket
                                            ? "Free"
                                            : `${t.CurrencySymbol}` +
                                              `${round2Places(this.calculateTotalSectionPrice(this.renderSeats(t.Id,y.SectionId),t),2)}` +
                                              ` ${t.CurrencyCode}`}
                                        </div>
                                      )}
                                      {paymentMethod === "Crypto" && (
                                          <div className="summary__order-price">
                                            {" "}
                                            {t.ParticipantTicket
                                              ? "Free"
                                              : `$${round2Places(this.calculateTotalSectionPrice(this.renderSeats(t.Id,y.SectionId),t),2)} USD`}
                                          </div>
                                      )}
                                      {paymentMethod === "LoyaltyPoints" && (
                                          <div className="summary__order-price p-0">
                                            {" "}
                                            {t.ParticipantTicket
                                              ? "Free"
                                              : `${round2Places(this.calculateTotalSectionPrice(this.renderSeats(t.Id,y.SectionId),t),2)} LP`}
                                          </div>
                                      )}
                                        
                                        
                                        </div>
                                        
                                    </div>
                                    <table className="table table-bordered">
                                    <thead>
                                    <tr>
                                       {/* <th className="font-white">Section #</th> */}
                                       <th className="font-white">Row #</th>
                                       <th className="font-white">Seat #</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                    
                                      this.renderSeats(t.Id,y.SectionId).map((x:SelectedSeatsModel)=>{
                                        return <tr>
                                         {/* <td className="font-white">{x.SectionName}</td> */}
                                         <td className="font-white">{x.RowNumber}</td>
                                         <td className="font-white">{x.SeatNumber}</td>
                                        </tr>
                                     })}
                                      </tbody>
                                  </table>
                                  
                                  </>
                                })}
                                </div>}
                                
                              </div>
                            </div>
                          )
                        )
                      })}

                    <div className="col">
                      <div className="row m-0 summary__total">
                        <div className="col p-0">
                          <div className="summary__total-text">TOTAL</div>
                        </div>
                        <div className="col-auto p-0">
                          {paymentMethod === "Credit" && (
                            <div className="summary__total-price">
                              {this.state.currencySymbol}{" "}
                              {round2Places(totalPrice, 2)}{" "}
                              {this.state.currencyCode}
                            </div>
                          )}
                          {paymentMethod === "Crypto" && (
                            <div className="summary__total-price">
                              {"$ " + round2Places(totalPrice, 2) + " USD"}
                            </div>
                          )}
                          {paymentMethod === "LoyaltyPoints" && (
                            <div className="summary__total-price">
                              {round2Places(totalPrice, 2) + " LP"}
                            </div>
                          )}
                          {/* <div className="summary__total-price"> {this.state.currencySymbol}{round2Places(totalPrice,2)}</div> */}
                        </div>
                      </div>
                    </div>
                    {totalPrice > 0 && <div className="col">
                      <div className="row">
                        <div className="col">
                          <h3 className="summary__subheader">
                            Payment Details
                          </h3>
                        </div>
                        {/*<div className="col-auto"><a className="summary__edit" href="javascript:void(0)">Edit</a></div>*/}
                      </div>
                    </div>}
                    {totalPrice > 0 && <div className="col">
                      <div className="row row-paymentDetails">
                        <div className="col-auto  no-padding col-paymentDetails">
                          <div className="summary__detail">
                            {/* John Walker */}
                            <input
                              className="form-control custom_input_payment cart"
                              value={paymentDetail.name}
                              autoComplete="off"
                              name="name"
                              placeholder="John Walker"
                              onChange={(e) => {
                                this.onChangePaymentDetail(
                                  e.target.name,
                                  e.target.value
                                )
                                e.currentTarget.className =
                                  "form-control custom_input_payment"
                              }}
                            />
                            {!paymentDetail.name && (
                              <div style={{ color: "red", marginLeft: "15px" }}>
                                ! Name missing
                              </div>
                            )}
                          </div>
                        </div>
                       <div className="col-auto no-padding col-paymentDetails">
                          <div className="summary__date">
                            <input
                              className="form-control custom_input_payment cart"
                              autoComplete="off"
                              name="postalCode"
                              placeholder="Postal Code"
                              disabled={userPrimaryCard && userPrimaryCard.postalCode?true:false}
                              onChange={(e) => {
                                this.onChangePaymentDetail(
                                  e.target.name,
                                  e.target.value
                                )
                              }}
                              value={userPrimaryCard && userPrimaryCard.postalCode ? userPrimaryCard.postalCode:this.state.paymentDetail.postalCode}
                            />
                            {!paymentDetail.postalCode  &&
                              !this.checkOnlyParticipantTicketAdded() &&  (
                                <div className={"cart-warrning"}>
                                  ! Postal Code missing
                                </div>
                              )}
                              {paymentDetail.postalCode  &&
                              !this.state.isValidPostalCode &&  (
                                <div className={"cart-warrning"}>
                                  ! Invalid Postal Code
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="col-auto no-padding col-paymentDetails">
                          <div className="summary__detail">
                            <input
                              className="form-control custom_input_payment cart"
                              value={paymentDetail.email}
                              autoComplete="off"
                              name="email"
                              placeholder="Email"
                              onChange={(e) => {
                                this.onChangePaymentDetail(
                                  e.target.name,
                                  e.target.value
                                )
                              }}
                            />
                            {!paymentDetail.email && (
                              <div style={{ color: "red" }}>
                                ! Email missing
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>}
                    <div
                      className="col"
                      onClick={() => {
                        if((this.calculateSpectatorQuantityWithPrice() && totalPrice === 0) || this.checkOnlyParticipantTicketAdded()){
                          this.setState({ isSummary: false })
                        }
                        else
                        if (
                          paymentDetail.name &&
                            paymentDetail.email &&
                            paymentDetail.postalCode && this.validPostalCode(paymentDetail.postalCode) 
                        ) {
                          this.setState({ isSummary: false })
                        }
                      }}
                    >
                      {this.state.cartItemWarning && (
                        <div className="text-white">Cart is empty !</div>
                      )}
                      <button
                        className="summary__next blue-button blue-button--filled"
                        onClick={() => {
                          this.nextButtonHandler()
                        }}
                      >
                        NEXT
                      </button>
                    </div>
                  </div>
                )}
              </section>

              <section className="article__payment payment payment--next">
                {isSummary == false && (
                  <div className="row flex-column">
                    {!onlyParticipantTicketsAdded && this.state.totalPrice > 0 && (
                      <div>
                        <div className="col">
                          <h2 className="payment__header">Payment info</h2>
                        </div>
                        <div className="col">
                          <div className="payment__subheader">
                            Payment method
                          </div>
                        </div>
                        <div className="col">
                          <div className="row">
                            <div
                              className="col-sm-6 col-12 p-r-5"
                              onClick={() =>
                                this.setState(
                                  { paymentMethod: "Credit" },
                                  () => {
                                    this.calculatePrice(this.state.shoppingCardList,true)
                                  }
                                )
                              }
                            >
                              <input
                                className="payment__radio"
                                id="method-1"
                                checked={paymentMethod == "Credit"}
                                type="radio"
                                name="method"
                                value="credit"
                              />
                              <label className="payment__input payment__label payment__label--credit">
                                CREDIT CARD
                              </label>
                            </div>
                            <div
                              className="col-sm-6 col-12 p-l-5"
                              onClick={() =>
                                this.setState(
                                  { paymentMethod: "Crypto" },
                                  () => {
                                    this.calculatePrice(this.state.shoppingCardList,true)
                                  }
                                )
                              }
                            >
                              <input
                                className="payment__radio"
                                id="method-2"
                                checked={paymentMethod == "Crypto"}
                                type="radio"
                                name="method"
                                value="credit"
                              />
                              <label className="payment__input payment__label payment__label--crypto">
                                CRYPTO
                              </label>
                            </div>
                            {paymentMethod === "Crypto" && (
                              <div className="col-sm-12 col-12 p-l-15 mb-3">
                                <span style={{ color: "#fff" } }>
                                  Checkout using Crypto Currency will take 10-15
                                  minutes to reflect the payment confirmation
                                </span>
                              </div>
                            )}
                            <div
                              className="col-sm-12 col-12 p-l-15"
                              onClick={() =>
                                this.setState(
                                  { paymentMethod: "LoyaltyPoints" },
                                  () => {
                                    this.calculatePrice(this.state.shoppingCardList,true)
                                  }
                                )
                              }
                            >
                              <input
                                className="payment__radio"
                                id="method-2"
                                checked={paymentMethod == "LoyaltyPoints"}
                                type="radio"
                                name="method"
                                value="credit"
                              />
                              <label className="payment__input payment__label payment__label--cad">
                                Loyalty Points Available:{" "}
                                {round2Places(
                                  this.state.playerLoyaltyPoints,
                                  2
                                )}
                                LP
                              </label>
                            </div>
                          </div>
                        </div>
                        {paymentMethod === "Credit" && !userPrimaryCard.hasOwnProperty('cardNo') ? <>
                          <div className="col">
                              {paymentMethod == "Credit" && !onlyParticipantTicketsAdded && (
                                <div className="payment__subheader">
                                  Card Number
                                </div>
                              )}
                            </div>
                            <div className="col">
                            {paymentMethod == "Credit" && !onlyParticipantTicketsAdded && (
                              <CardNumberElement
                                style={{ base: { color: "#fff" } }}
                                onChange={(e) => {
                                  this.handleCardElementOnChange(
                                    e,
                                    "stripeCardNumberValid"
                                  )
                                }}
                                className="payment__input"
                              />
                            )}
                            
                          </div>
                            {!stripeCardNumberValid && !onlyParticipantTicketsAdded && (
                              <div className="col text-danger">
                                Please Enter Valid Card Number
                              </div>
                            )}
                            {paymentMethod == "Credit" && !onlyParticipantTicketsAdded && (
                              <div className="col">
                                <div className="row">
                                  <div className="col-6">
                                    <div className="payment__subheader">
                                      Expiration
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="payment__subheader">CVC</div>
                                  </div>
                                </div>
                              </div>
                            )}
                            {paymentMethod == "Credit" && !onlyParticipantTicketsAdded && (
                              <div className="col">
                                <div className="row">
                                  <div className="col-6 p-r-5">
                                    <CardExpiryElement
                                      className="payment__input"
                                      style={{ base: { color: "#fff" } }}
                                      onChange={(e) => {
                                        this.handleCardElementOnChange(
                                          e,
                                          "stripeCardExpValid"
                                        )
                                      }}
                                    />
                                    {!stripeCardExpValid && (
                                      <span className="text-danger">
                                        Please Enter Valid Expiration Month and Year
                                      </span>
                                    )}
                                  </div>

                                  <div className="col-6 p-l-5">
                                    <CardCvcElement
                                      className="payment__input"
                                      style={{ base: { color: "#fff" } }}
                                      onChange={(e) => {
                                        this.handleCardElementOnChange(
                                          e,
                                          "stripeCardCvcValid"
                                        )
                                      }}
                                    />
                                    {!stripeCardCvcValid && (
                                      <span className="col text-danger">
                                        Please Enter Valid CVC
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                              
                            )}
                          </>:
                          <>
                            {paymentMethod === "Credit" && <div className="col card-detail">
                                <div className="row">
                                  <div className="col-7 mb-2">
                                    <div className="cart-text">
                                      Card Number 
                                    </div>
                                  </div>
                                  <div className="col-4 mb-2">
                                    <div className="cart-text">
                                      Expiration 
                                    </div>
                                  </div>
                                  
                                </div>
                                <div className="row">
                                  <div className="col-7">
                                    <div className="payment__subheader">
                                     {'****' + userPrimaryCard.cardNo}
                                    </div>
                                  </div>
                                  <div className="col-4">
                                    <div className="payment__subheader">
                                    {userPrimaryCard.expireDate} 
                                    </div>
                                  </div>
                                  
                                </div>
                              </div>}
                          </>
                        }
                       
                        {/* <div className="col">
                          {paymentMethod == "Credit" && !onlyParticipantTicketsAdded && (
                            <div className="payment__subheader">
                              Card Number
                            </div>
                          )}
                        </div> */}
                        {/* <div className="col">
                          {paymentMethod == "Credit" && !onlyParticipantTicketsAdded && !userPrimaryCard && (
                            <CardNumberElement
                              style={{ base: { color: "#fff" } }}
                              onChange={(e) => {
                                this.handleCardElementOnChange(
                                  e,
                                  "stripeCardNumberValid"
                                )
                              }}
                              className="payment__input"
                            />
                          )}
                          
                        </div> */}
                        {/* {!stripeCardNumberValid && !onlyParticipantTicketsAdded && (
                          <div className="col text-danger">
                            Please Enter Valid Card Number
                          </div>
                        )} */}
                        {/* {paymentMethod == "Credit" && !onlyParticipantTicketsAdded && (
                          <div className="col">
                            <div className="row">
                              <div className="col-6">
                                <div className="payment__subheader">
                                  Expiration
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="payment__subheader">CVC</div>
                              </div>
                            </div>
                          </div>
                        )} */}
                        {/* {paymentMethod == "Credit" && !onlyParticipantTicketsAdded && (
                          <div className="col">
                            <div className="row">
                              <div className="col-6 p-r-5">
                                <CardExpiryElement
                                  className="payment__input"
                                  style={{ base: { color: "#fff" } }}
                                  onChange={(e) => {
                                    this.handleCardElementOnChange(
                                      e,
                                      "stripeCardExpValid"
                                    )
                                  }}
                                />
                                {!stripeCardExpValid && (
                                  <span className="text-danger">
                                    Please Enter Valid Expiration Month and Year
                                  </span>
                                )}
                              </div>

                              <div className="col-6 p-l-5">
                                <CardCvcElement
                                  className="payment__input"
                                  style={{ base: { color: "#fff" } }}
                                  onChange={(e) => {
                                    this.handleCardElementOnChange(
                                      e,
                                      "stripeCardCvcValid"
                                    )
                                  }}
                                />
                                {!stripeCardCvcValid && (
                                  <span className="col text-danger">
                                    Please Enter Valid CVC
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        )} */}
                      </div>
                    )}
                    <div className="col">
                      <div
                        className="payment__agree-licence-container"
                        onClick={() => {
                          isAgreed
                            ? this.setState({ isAgreed: false })
                            : this.setState({ isAgreed: true })
                        }}
                      >
                        <input
                          className="payment__agree-licence"
                          id="agree-licence"
                          checked={isAgreed}
                          type="checkbox"
                        />
                        <label className="payment__agree-licence-label" />
                        <div>
                          Agree with Kombatlink
                          <a className="color-blue" href="javascript:void(0)">
                            &nbsp;Terms and Conditions
                          </a>
                          &nbsp;&amp;
                          <a className="color-blue" href="javascript:void(0)">
                            &nbsp;Privacy Policy
                          </a>
                        </div>
                      </div>
                      {shoppingCardList &&
                        shoppingCardList.map((shop, i) => {
                          return (
                            shop.IsValid && !shop.IsTournamentExpired &&
                            shop.Quantity > 0 && (
                              <div className="col payment__order">
                                <div className="row">
                                  <div className="col p-0">
                                  
                                    <div className="payment__order-title">
                                    {this.calculateSelectedSeatsQuantityPerCard(shop.Id) > 0 ? this.calculateSelectedSeatsQuantityPerCard(shop.Id)+"X "+shop.Title : ""}{" "}
                                     
                                    </div>
                                  </div>
                                  {this.calculateSelectedSeatsQuantityPerCard(shop.Id) > 0 && <div className="col-auto p-0">
                                    {/* <div className="payment__order-price">{shop.CurrencySymbol} {shop.Price}</div> */}

                                    {paymentMethod === "Credit" && (
                                      <div className="payment__order-price">
                                        {" "}
                                        {shop.ParticipantTicket
                                          ? "Free"
                                          : `${shop.CurrencySymbol}` + ' '+
                                            `${round2Places(this.calculateShoppingCartPrice(shop,1),2)}` + ' '+
                                            `${shop.CurrencyCode}`}
                                      </div>
                                    )}
                                    {paymentMethod === "Crypto" && (
                                      <div className="payment__order-price">
                                        {" "}
                                        {shop.ParticipantTicket
                                          ? "Free"
                                          : `$${round2Places(this.calculateShoppingCartPrice(shop,1),2)} USD`}
                                      </div>
                                    )}
                                    {paymentMethod === "LoyaltyPoints" && (
                                      <div className="payment__order-price">
                                        {" "}
                                        {shop.ParticipantTicket
                                          ? "Free"
                                          : `${round2Places(this.calculateShoppingCartPrice(shop,1),2)} LP`}
                                      </div>
                                    )}
                                  </div>}
                                </div>
                              </div>
                            )
                          )
                        })}
                    </div>
                    <div className="col">
                      <div className="row m-0 payment__total">
                        <div className="col p-0">
                          <div className="payment__total-text">TOTAL</div>
                        </div>
                        <div className="col-auto p-0">
                          {paymentMethod === "Credit" && (
                            <div className="payment__total-price">
                              {this.state.currencySymbol}{" "}
                              {round2Places(totalPrice, 2)}{" "}
                              {this.state.currencyCode}
                            </div>
                          )}
                          {paymentMethod === "Crypto" && (
                            <div className="payment__total-price">
                              {"$ " + round2Places(totalPrice, 2) + " USD"}
                            </div>
                          )}
                          {paymentMethod === "LoyaltyPoints" && (
                            <div className="payment__total-price">
                              {Math.round(round2Places(totalPrice, 2)) + " LP"}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="row">
                        <div className="col-6 p-r-5">
                          <button
                            className="payment__back grey-button grey-button--filled"
                            onClick={() => {
                              this.setState({ isSummary: true })
                            }}
                          >
                            GO BACK
                          </button>
                        </div>
                        <div className="col-6 p-l-5">
                          <button
                            className="payment__checkout blue-button blue-button--filled"
                            disabled={this.state.loading}
                            onClick={(e) => {
                              
                              if (isAgreed) {
                               
                                this.setLoading(true)
                                this.checkOutHandler()
                                
                              } else {
                                //GeneralUtils.showError("Please check Terms and Conditions")
                                alert("Please check off TERMS and Conditions")
                              }
                            }}
                          >
                            CHECKOUT
                            {this.state.loading && <Loading size="small" className="ml-2" />}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </section>
            </div>
          </div>
        </article>
        <aside className="modal-container " id="next-modal">
          <div className="modal-container__background" />
          <div className="modal-container__window window">
            <div
              className="window__close"
              onClick={() => {
                document.getElementById("next-modal").className =
                  "modal-container"
              }}
            />
            <div className="container-fluid">
              <div className="row">
                <div className="window__subheader">No item in the cart!</div>
              </div>
            </div>
          </div>
        </aside>
        <aside className="modal-container" id="modal_delete-cart">
          <div className="modal-container__background" />
          <div className="delete-cart-modal profile-modal-medium disable-premium modal-container__window window">
            <div
              className="window__close"
              onClick={async () => {
                await this.deleteCart()
              }}
            />
            <div className="profile-modal_inn">
              <>
                <h3 className="profile-modal_ttl--big text-center">
                  One or more tickets you have currently selected is no longer
                  available
                </h3>
              </>
            </div>
          </div>
        </aside>
        <aside className="modal-container" id="modal_invalid_tournaments" style={{ zIndex: 999999 }}>
          <div className="modal-container__background" />
          <div className="identity-verify-modal identity-verify-modal-medium window">
            <div
              className="window__close"
              onClick={async () => {
                this.setLoading(false)
                document.getElementById('modal_invalid_tournaments').className = 'modal-container'
              }}
            />
            <div className="identity-verify-modal_inn">
              <p className="profile-modal_ttl--big mt-4 mb-4 ml-2 text-center">
                The items selected are not longer available. Do you wish to continue to check out?
                {!this.state.isValidateCart && this.state.invalidTournaments && this.state.invalidTournaments.length > 0 &&
                  this.state.invalidTournaments.map((x)=>{
                    
                    return (
                    <>
                      <div className="row ml-2 mt-2">
                        <h3 className="ml-4 mt-4 text-danger tournament-invalid-text-wrap">Ticket: {x.Title}</h3>
                      </div>
                      {x.TicketMessage!=='' && x.TicketMessage!==null && <div className="row ml-2 mt-2">
                        <span className="ml-2 mt-4 text-danger tournament-invalid-text-wrap">-&nbsp;{this.generateMessageForTicket(x.Title,x.DayNumber,x.Time,x.TicketMessage)}</span>
                      </div>}
                      {x.SelectedSeats.length>0 && 
                      <>
                        <div className="row ml-2 mt-2">
                        <span className="ml-4 mt-4 text-danger tournament-invalid-text-wrap">-&nbsp;Below seats has been already sold out!!</span>
                        </div>
                        <div className="row ml-2 mt-2 table table-responsive">
                        <table className="ml-4 mt-4 table-bordered">
                          <tr>
                            <th>Ticket</th>
                            <th>Section</th>
                            <th className="ml-4">Row Number</th>
                            <th className="ml-4">Seat Number</th>
                          </tr>
                        {x.SelectedSeats.filter(a=>a.IsPurchased === true).map((y)=>{
                            return <tr className="ml-4">
                               <td className="ml-4">{x.Title}</td>
                              <td className="ml-4">{y.SectionName}</td>
                              <td className="ml-4">{y.RowNumber}</td>
                              <td className="ml-4">{y.SeatNumber}</td>
                            </tr>
                        })}
                        </table>
                        </div>
                        </>
                      
                      }
                      <hr/>
                    </>
                    )
                  })
                }

              </p>
            </div>
            <div className="disable-premium_buttons">
              <button
                  className="btn-modal-primery open-modal_disable-success"
                  onClick={async () => {
                    this.setLoading(true)
                    this.checkOutHandler(true)
                    document.getElementById('modal_invalid_tournaments').className = 'modal-container'
                  }}
                >
                  Confirm
                </button>
              </div>
                         
          </div>
        </aside>

        {/* //f */}
        <aside className="modal-container" id="modal_ticket_seats" style={{ zIndex: 999999 }}>
          <div className="modal-container__background" />
          <div className="identity-verify-modal identity-verify-modal-medium window">
            <div
              className="window__close"
              onClick={() => {
                
                document.getElementById('modal_ticket_seats').className = 'modal-container'
              }}
            />
            <div className="identity-verify-modal_inn">
              <p className="profile-modal_ttl--big mt-4 mb-4 ml-2 text-center">
                Selected Seats
                </p>
                {this.state.ticketSelectedSeats.length > 0 &&
                <div className="row ml-2 mt-2 table table-responsive">
                   <table className="col-md-12 table-bordered">
                          <tr>
                           
                            <th>Section #</th>
                            <th className="ml-4">Row #</th>
                            <th className="ml-4">Seat #</th>
                          </tr>


             
                  {this.state.ticketSelectedSeats.map((x)=>{
                    return <tr className="ml-4">
                               
                              <td className="ml-4">{x.SectionName}</td>
                              <td className="ml-4">{x.RowNumber}</td>
                              <td className="ml-4">{x.SeatNumber}</td>
                            </tr>
                  })}
                  </table>
                  </div>}
            </div>
            <div className="disable-premium_buttons">
              <button
                  className="btn-modal-primery open-modal_disable-success"
                  onClick={() => {
                    
                    document.getElementById('modal_ticket_seats').className = 'modal-container'
                  }}
                >
                  Close
                </button>
              </div>
                         
          </div>
        </aside>
      </div>
    )
  }
}

export default withStripe(ShoppingCard)
