import { cn } from 'utils'
import { useDropDownTableStyles } from './styles/useDropDownTableStyles'

export interface DropDownTableHideButtonProps {
  onClick: () => void
  hidden?: boolean
}

export const DropDownTableHideButton = ({ onClick, hidden }: DropDownTableHideButtonProps) => {
  const styles = useDropDownTableStyles()

  return <label className={cn(styles.tableHide, hidden && styles.hidden)} onClick={onClick} />
}
