import { Button } from 'components/_shared'
import React from 'react'
import { cn, formatDate } from 'utils'
import TicketQRCode, { TicketQRData } from './TicketQRCode'
import styles from './QRScanner.module.scss'
import {isMobileOnly,isTablet,isDesktop} from 'react-device-detect';
import { useSelector } from 'react-redux'
interface OwnProps {
  ticket: TicketQRData
  onClose: CallableFunction
}

const TicketDetails = ({ticket, onClose} : OwnProps) => {
  const userName = ticket.Username
  const ticketDate = ticket.ticketPrice=="FREE" || ticket.DayNumber==null?'':formatDate(new Date(ticket.TicketDate),'LL')
  let tournamentName = ticket.Name
  if(ticket.ticketPrice=="FREE"){
    tournamentName = tournamentName +" (Participent Ticket)"
  }
  else{
    if(ticket.DayNumber!=null){
      tournamentName = tournamentName + " (Day "+ticket.DayNumber +" Spectator Ticket )"
    }
  }

  const handleBack = () => {
    onClose()
  }
  const renderImageAsPerScreenSize = (ticket:TicketQRData) =>{
      if(isMobileOnly){
        return ticket.CoverImageThumbnail
      }
      else if(isTablet){
        return ticket.CoverImageSmall
      }
      else if(isDesktop){
        return ticket.CoverImageMedium
      }
      else{
        return ticket.CoverImage
      }
  }
  
  return (
    <>
      <div className="row justify-content-end">
        <Button onClick={handleBack} color="blue" >
          Back
        </Button>
      </div>
      <div>
        <div className={cn('row', 'justify-content-center')}>         
          <div className={styles.ticket__detail}>
            <img src={renderImageAsPerScreenSize(ticket)} className={styles.ticket_cover} />
            <div className={styles.ticket_tournamentName}>
              {tournamentName}
            </div>
            {ticketDate!=='' && <div>
              {ticketDate}
            </div>}
            <div className={styles.qrCode}>
              <TicketQRCode ticket={ticket} />
            </div>
            <div className={styles.footer}>
              <div className={styles.user_name}>
                {userName}
              </div>            
              <div className={styles.ticket_id}>
                #T{ticket && ticket.TournamentDetailId}
              </div>
            </div>
          </div>
        </div>
      </div>      
    </>
  )
}

export default TicketDetails
