import React from 'react'
import { Carousel } from './Carousel'

interface CarouselSelectProps<T> {
  selected: T[]
  valueExtractor: (option: T) => string | number
  onChange: (values: T[]) => void
  values: T[]
  window: number
  className?: string
  renderItem: (value: T, index: number, onClick: (value: T) => void, selected: boolean) => JSX.Element
}

export const CarouselSelect = <T extends unknown>({
  selected,
  values,
  window,
  className,
  renderItem,
  valueExtractor,
  onChange,
} : CarouselSelectProps<T>) => {
  const handleClick = (option: T) => {
    const filteredOptions = selected.filter(x => valueExtractor(x) !== valueExtractor(option))
    if (filteredOptions.length !== selected.length) {
      onChange(filteredOptions)
    } else {
      onChange([...selected, option])
    }
  }

  const selectRenderItem = (item: T, index: number, onClick: (value: T) => void) => {
    const isSelected = selected.some(x => valueExtractor(x) === valueExtractor(item))
    return renderItem(item, index, onClick, isSelected)
  }

  return (
    <Carousel
      renderItem={selectRenderItem}
      values={values}
      window={window}
      className={className}
      onClick={handleClick}
    />
  )
}
