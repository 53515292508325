import { imagePlaceholders } from 'components/_shared/Img';
import {isMobileOnly,isTablet,isDesktop} from 'react-device-detect';

export const useGetImageSize = (containername:ContainerName) => {
  if(containername === ContainerName.TournamentCardCover){
    if(isMobileOnly){          
      return ImageSize.thumbnail
    }
    else if(isTablet || isDesktop){
        
      return ImageSize.small
    }
    else{
      return ImageSize.orignal
    }
  }
  else if(containername === ContainerName.TournamentSlider){
    if(isMobileOnly){          
      return ImageSize.thumbnail
    }
    else if(isTablet || isDesktop){ 
      return ImageSize.medium
    }
    else{
      return ImageSize.orignal
    
    }
  }
  else if(containername === ContainerName.TournamentDetail){
    if(isMobileOnly){          
      return ImageSize.thumbnail
    }
    else if(isTablet || isDesktop){ 
      return ImageSize.medium
    }
    else{
      return ImageSize.orignal
    }
  }
  else if(containername === ContainerName.GamesImagesCard){
    if(isMobileOnly){          
      return ImageSize.thumbnail
    }
    else if(isTablet || isDesktop){ 
      return ImageSize.small
    }
    else{
      return ImageSize.orignal
    }
  }
  
  else if(containername === ContainerName.GamesImagesCardDetail){
    if(isMobileOnly){          
      return ImageSize.thumbnail
    }
    else if(isTablet){ 
      return ImageSize.small
    }
    else if(isDesktop){ 
      return ImageSize.medium
    }
    else{
      return ImageSize.orignal
    }
  }
  else if(containername === ContainerName.AvatarsProfile){
    if(isMobileOnly || isTablet || isDesktop){          
      return ImageSize.medium
    }
    else{
      return ImageSize.orignal
    }
  }
  else if(containername === ContainerName.AvatarsNormal){
    if(isMobileOnly){          
      return ImageSize.thumbnail
    }
    else if(isTablet || isDesktop){ 
      return ImageSize.small
    }
    else{
      return ImageSize.orignal
    }
  }
  else if(containername === ContainerName.OrganizerCoverProfile){
    if(isMobileOnly){          
      return ImageSize.thumbnail
    }
    else if(isTablet){ 
      return ImageSize.small
    }
    else{
      return ImageSize.orignal
    }
  }
  else if(containername === ContainerName.Blogs){
    if(isMobileOnly){          
      return ImageSize.thumbnail
    }
    else if(isTablet || isDesktop){ 
      return ImageSize.small
    }
    else{
      return ImageSize.orignal
    }
  }
  else if(containername === ContainerName.BlogDetails){
    if(isMobileOnly){          
      return ImageSize.thumbnail
    }
    else{
      return ImageSize.orignal
    }
  }
  else if(containername === ContainerName.TournamentTickets){
    if(isMobileOnly){          
      return ImageSize.thumbnail
    }
    else if(isTablet){          
      return ImageSize.small
    }
    if(isDesktop){          
      return ImageSize.medium
    }
    else{
      return ImageSize.orignal
    }
  }
  else if(containername === ContainerName.HomeAdPoup){
    if(isMobileOnly){          
      return ImageSize.thumbnail
    }
    else if(isTablet){          
      return ImageSize.small
    }
    if(isDesktop){          
      return ImageSize.medium
    }
    else{
      return ImageSize.orignal
    }
  }
  else if(containername === ContainerName.HomeAdPoupAdmin){
    if(isMobileOnly || isTablet || isDesktop){          
      return ImageSize.thumbnail
    }
    else{
      return ImageSize.orignal
    }
  }
  else{
    return ImageSize.orignal
  }
}
export const getImageSizeContainer = (parentContainerSize:ContainerName,image:any)=>{
  if(image){
    const getImageSize = useGetImageSize(parentContainerSize)
    if(getImageSize === ImageSize.thumbnail){
      return image?.ThumbnailCdnUrl ||  image?.ThumbnailUrl
    }
    else if(getImageSize === ImageSize.small){
      return image?.SmallCdnUrl || image?.SmallUrl
    }
    else if(getImageSize === ImageSize.medium){
      return image?.MediumCdnUrl || image?.MediumUrl
    }
    else{
      return image?.CdnUrl || image?.Url
    }
  }
  else{
    return imagePlaceholders.noavatar
  }
}
export enum ContainerName{
  TournamentCardCover = "TournamentCardCover",
  TournamentSlider = "TournamentSlider",
  TournamentDetail = "TournamentDetail",
  GamesImagesCard="GamesImagesCard",
  GamesImagesCardDetail="GamesImagesCardDetail",
  AvatarsProfile="AvatarsProfile",
  AvatarsNormal="AvatarsNormal",
  OrganizerCoverProfile ="OrganizerCoverProfile",
  Blogs ="Blogs",
  BlogDetails ="BlogDetails",
  TournamentTickets="TournamentTickets",
  HomeAdPoup="HomeAdPoup",
  HomeAdPoupAdmin="HomeAdPoupAdmin"
}
export enum ImageSize{
  small="small",
  medium="medium",
  thumbnail="thumbnail",
  orignal="orignal"
}