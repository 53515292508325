import { useAppSelector, useAppUser } from 'components/_hooks'
import { ChatType } from 'consts'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { makeGetUser } from 'store/selectors/user-selectors'
import { useChatMembers } from '../chat-member/chat-member-hooks'
import { getIsChatOpened, getIsHubsConnected, getIsHubsConnecting, getOpenedChatId, getOpenedUserId, makeGetChat, makeGetPrivateChatIdByUserId } from './chat-selectors'
import { chatsClosed, chatOpened, userChatOpened, chatClosed, chatListOpened } from './chat-types'

export const useIsChatOpened = () => useAppSelector(getIsChatOpened)

export const useOpenedChatId = () => useAppSelector(getOpenedChatId)

export const useOpenedUserId = () => useAppSelector(getOpenedUserId)


export const useOpenChatList = () => {
  const dispatch = useDispatch()

  return () => {
    dispatch(chatListOpened(true))
  }
}

export const useOpenChat = () => {
  const dispatch = useDispatch()

  return (chatId: number) => {
    dispatch(chatOpened(chatId))
  }
}
export const useOpenUserChat = () => {
  const dispatch = useDispatch()

  return (userId: number) => {
    dispatch(userChatOpened(userId))
  }
}

export const useCloseChat = () => {
  const dispatch = useDispatch()

  return () => {
    dispatch(chatClosed())
  }
}

export const useCloseChats = () => {
  const dispatch = useDispatch()

  return () => {
    dispatch(chatsClosed())
  }
}

export const useIsHubsConnected = () => useAppSelector(getIsHubsConnected)
export const useIsHubsConnecting = () => useAppSelector(getIsHubsConnecting)

export const usePrivateChatByUserId = (userId: number) => {
  const getPrivateChatIdByUserId = useMemo(makeGetPrivateChatIdByUserId, [])
  return useAppSelector(state => getPrivateChatIdByUserId(state, { userId }))
}

export const useChatById = (chatId: number) => {
  const getChat = useMemo(makeGetChat, [])
  return useAppSelector(state => getChat(state, { chatId }))
}

export const useChat = () => {
  const openedChatId = useOpenedChatId()
  const openedUserId = useOpenedUserId()
  const privateChatIdByUserId = usePrivateChatByUserId(openedUserId)
  const chatId = privateChatIdByUserId ?? openedChatId
  const chat = useChatById(privateChatIdByUserId ?? openedChatId)

  return {
    openedChatId,
    openedUserId,
    privateChatIdByUserId,
    chat,
    chatId,
  }
}


export const useChatName = (id: number) => {
  const chat = useChatById(id)
  const appUser = useAppUser()
  const chatMembers = useChatMembers(id)  
  const chatMember = chatMembers.find(cm => cm.UserId != appUser?.Id)
  const getUser = useMemo(makeGetUser, [])
  const user = useAppSelector(state => getUser(state, chatMember?.UserId))

  if (chat?.Type == ChatType.Group) {
    return {
      chatName: chat.Name || 'Group Chat',
      chatUserId: null,
    }
  }

  return {
    chatName: user?.Username || user?.Organizationname  || 'Chat',
    chatUserId: user?.Id,
  }
}
