import { createSelector } from 'reselect'
import { ApplicationState } from '../../types/common'

const getState = (state: ApplicationState) => state.tournamentBracketPage

export const getPlaceByMatchParticipantId = createSelector(getState, state => state?.placeByMatchParticipantId ?? {})

export const makeGetPlace = () =>
  createSelector(
    getPlaceByMatchParticipantId,
    (_: ApplicationState, { matchParticipantId }: { matchParticipantId: number }) => matchParticipantId,
    (placeByMatchParticipantId, matchParticipantId) => placeByMatchParticipantId[matchParticipantId]
  )
