import { Button } from 'components/_shared'
import React from 'react'
import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getFilters } from 'store/selectors/pages/tournaments-page-selectors'
import { ApplicationState } from 'store/types/common'
import { defaultTournamentsFilters, filtersChanged } from 'store/types/pages/tournaments-page-types'
import styles from '../Tournament.module.scss'


const NotFoundTournaments = () => {
  const dispatch = useDispatch()
  const storedFilters = useSelector((state: ApplicationState) => getFilters(state)) 
  
  const isDefaultFilters = useMemo(() => {
    return storedFilters.currentPage == defaultTournamentsFilters.currentPage 
      && storedFilters.isLiveEvent == defaultTournamentsFilters.isLiveEvent
      && storedFilters.isOnlineEvent == defaultTournamentsFilters.isOnlineEvent
      && storedFilters.selectedConsoles == defaultTournamentsFilters.selectedConsoles
      && storedFilters.selectedGames == defaultTournamentsFilters.selectedGames
      && storedFilters.selectedInterval == defaultTournamentsFilters.selectedInterval
      && storedFilters.selectedMode == defaultTournamentsFilters.selectedMode
      && storedFilters.selectedOrganizers == defaultTournamentsFilters.selectedOrganizers
      && storedFilters.selectedRegion == defaultTournamentsFilters.selectedRegion
      && storedFilters.selectedSortField == defaultTournamentsFilters.selectedSortField
  }, [storedFilters])

  const handleResetFilters = () => {
    dispatch(filtersChanged(
      {
        filters: {
          ...defaultTournamentsFilters,
          currentPage: 0, 
          currentTab: storedFilters.currentTab,        
        },
      }
    ))
  }

  return (    
    <div className={styles.clearFilters}>
      <div className={styles.clearFiltersText}>                
        There are no tournaments for these filters
      </div>
      {!isDefaultFilters && (
        <Button color="blue" onClick={handleResetFilters} filled > 
          Reset filters
        </Button>
      )}     
    </div>   
  )
}

export default NotFoundTournaments
