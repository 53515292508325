import * as React from 'react'
import { defaultFormatDate } from 'utils/dateUtils'
import { LoyalCreditsBonusViewModel } from '../../../models/my-transaction-viewmodel'

interface Props {
  loyaltyPoints: LoyalCreditsBonusViewModel
}

interface State {}

class PlayerTransactionsLoyaltyPointsCard extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {}
  }

  render() {
    const { loyaltyPoints } = this.props
    const {} = this.state
    return (
      <>
        <div className="row table__row">
          <div className="table__column table__column--date">{loyaltyPoints && defaultFormatDate(loyaltyPoints.date)}</div>
          <div className="table__column table__column--points">{loyaltyPoints && loyaltyPoints.currencyOrPoints}</div>
          <div className="table__column table__column--points-type">{loyaltyPoints && loyaltyPoints.type}</div>
        </div>
      </>
    )
  }
}

export default PlayerTransactionsLoyaltyPointsCard
