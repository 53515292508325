import React, { ReactNode } from 'react'
import { cn } from 'utils'
import styles from './ChatContainer.module.scss'

export interface ChatContainerProps {
  children: ReactNode
  className?: string
}

export const ChatContainer = ({ children, className }:  ChatContainerProps) => {
  return (
    <div className={cn(styles.chat, className)} id="scrollableDiv">
      {children}
    </div>
  )
}
