import React from 'react'
import { Button, ButtonLink } from 'components/_shared'
import StorageUtils from 'server/utils/storage-utils'
import styles from './CookiePolicyModal.module.scss'


interface Props {
  onClose: CallableFunction
}

const CookiePolicyModal = ({ onClose }: Props) => {
  const onAccept = () => {
    StorageUtils.setItem('cookie-consent-status', 'allowed')
    onClose()
  }

  return (
    <div className={styles.windowCookieFooter}>
      <div className={styles.windowCookieHeader}>Cookie Policy</div>
      <div className="row">
        <div className={`${styles.windowCookieText} col-lg-8 col-md-8 col-sm-12 col-xs-12`}>
          KombatLink uses cookies that help the website functions and to track how you interact with it so that we can provide you with
          improved and customized user experience.
          <br />
          We will only use cookies if you consent to it by clicking on Accept. You can learn more about the policy.
        </div>
        <div className="windowCookieButton col-lg-4 col-md-4 col-sm-12 col-xs-12 d-flex align-items-center justify-content-end">
          <ButtonLink color="blue" className={styles.windowCookieButton} to="/privacy-policy/user-data-usage-policy" >
            learn more
          </ButtonLink>
          <Button onClick={onAccept} className={styles.windowCookieButton} color="blue" filled>
            yes, i accept
          </Button>
        </div>
      </div>
    </div>
  )
}

export default CookiePolicyModal
