import { useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import { fetchApi } from 'server/server-utils'
import { getGamesRequested, getIsTopGamesRequested, makeGetIsGameDetailRequested, makeGetIsUserGamesRequested } from 'store/selectors/game-selectors'
import { GetState } from 'store/types/common'
import { gameDetailLoaded, gameRetired, gamesLoaded, GamesModel, GameType, gameUpdated, ListGameType, TopGames, topGamesLoaded, userGamesLoaded } from 'store/types/game-types'
import { cancel, OperationTypes, run, useCancel, useRun } from './operations-logic'

interface UpdateGameModel {
  Gamename: string
  IsRetired: boolean
  ImageId?: number
}

interface AddGameModel {
  Gamename: string
  ImageId?: number
}

export const reloadGames = async (dispatch: Dispatch, getState: GetState) => {
  cancel(OperationTypes.loadGames, null, dispatch, getState)
  return loadGames(dispatch, getState)
}

export const loadGames = async (dispatch: Dispatch, getState: GetState) => {
  if (getGamesRequested(getState())) {
    return
  }
  await run(OperationTypes.loadGames, null, dispatch, getState, async () => {
    try {
      const games = await fetchApi<GamesModel>('games', 'GET')
      dispatch(gamesLoaded(games))
    } catch (error) {
      return false
    }
    return true
  })
}

const getIsGameDetailRequested = makeGetIsGameDetailRequested()

export const loadGameDetail = (gameId: number) => async (dispatch: Dispatch, getState: GetState) => {
  if (getIsGameDetailRequested(getState(), gameId)) {
    return
  }
  await run(OperationTypes.loadGameDetail, null, dispatch, getState, async () => {
    try {
      const games = await fetchApi<ListGameType>(`games/${gameId}`, 'GET')
      dispatch(gameDetailLoaded({ games }))
    } catch (error) {
      return false
    }
    return true
  })
}

const getIsUserGamesRequested = makeGetIsUserGamesRequested()

const fetchUserGames = (userId: number) => {
  return fetchApi<GameType[]>(`games/user/${userId}`, 'GET')
}

export const loadUserGames = (userId: number) => async (dispatch: Dispatch, getState: GetState) => {
  if (getIsUserGamesRequested(getState(), { userId })) {
    return
  }
  await run(OperationTypes.loadUserGames, userId, dispatch, getState, async () => {
    const games = await fetchUserGames(userId)
    dispatch(userGamesLoaded({ games, userId }))
    return true
  })
}

export const loadTopGames = async (dispatch: Dispatch, getState: GetState) => {
  if (getIsTopGamesRequested(getState())) {
    return
  }
  await run(OperationTypes.loadTopGames, null, dispatch, getState, async () => {
    const games = await fetchApi<TopGames>('games/topgames', 'GET')
    dispatch(topGamesLoaded(games))
    return true
  })
}

export const reLoadUserGames = (userId: number) => async (dispatch: Dispatch, getState: GetState) => {
  cancel(OperationTypes.loadUserGames, userId, dispatch, getState)
  await loadUserGames(userId)(dispatch, getState)
}

export const useReloadUserGames = (userId: number) => {
  const cancelLoadUserGames = useCancel(OperationTypes.loadUserGames, userId)
  const dispatch = useDispatch()
  return useRun(OperationTypes.loadUserGames, userId, async () => {
    cancelLoadUserGames()
    const games = await fetchUserGames(userId)
    dispatch(userGamesLoaded({ games, userId }))
    return true
  })
}

export const useAddUserGame = (userId: number) => {
  const reloadUserGames = useReloadUserGames(userId)
  return useRun(OperationTypes.addUserGame, userId, async (gameId: number) => {
    try {
      await fetchApi(`games/${gameId}/favorite`, 'POST')
      await reloadUserGames()
    } catch (error) {
      return false
    }
    return true
  })
}

export const useRemoveUserGame = (userId: number) => {
  const reloadUserGames = useReloadUserGames(userId)
  return useRun(OperationTypes.removeUserGame, userId, async (gameId: number) => {
    try {
      await fetchApi(`games/${gameId}/favorite`, 'DELETE')
      await reloadUserGames()
    } catch (error) {
      return false
    }
    return true
  })
}

export const useUpdateGame = (gameId: number) => {
  const dispatch = useDispatch()
  return useRun(OperationTypes.updateGame, gameId, async (model: UpdateGameModel) => {
    const game = await fetchApi<GameType>(`games/${gameId}`, 'PUT', model)
    if (game?.IsRetired) {
      dispatch(gameRetired(game))
    } else {
      dispatch(gameUpdated(game))
    }
    return true
  })
}

export const useAddGame = () => {
  const dispatch = useDispatch()
  return useRun(OperationTypes.addGame, null, async (model: AddGameModel) => {
    const game = await fetchApi<GameType>('games', 'POST', model)
    dispatch(gameUpdated(game))
    return true
  })
}
