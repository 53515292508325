import { makeActionCreator, ReduxAction } from './common'
import { MatchModel } from './match-types'
import { RoundType } from './rounds-types'
import { TournamentType } from './tournaments-types'

export interface State {
  matchSettingsByMatchId: {
    [key: number] : MatchSettingsType
  }
}


export interface MatchSettingsType {
  MatchId: number
  HostedByMatchParticipantId?: number
  RoomName: string
  Password: string
}


export interface MatchesSettingsModel {
  MatchesSettings: MatchSettingsType[]
  Matches: MatchModel[]
  Rounds: RoundType[]
  Tournaments: TournamentType[]
}

export const MATCHES_SETTINGS_LOADED = 'MATCHES_SETTINGS_LOADED'

export type MatchesSettingsLoadedAction = ReduxAction<typeof MATCHES_SETTINGS_LOADED, MatchesSettingsModel>
export const matchesSettingsLoaded = makeActionCreator<MatchesSettingsModel>(MATCHES_SETTINGS_LOADED)


export const MATCH_SETTINGS_SET = 'MATCH_SETTINGS_SET'

export type MatchSettingsSetAction = ReduxAction<typeof MATCH_SETTINGS_SET, MatchSettingsType>
export const matchSettingsSet = makeActionCreator<MatchSettingsType>(MATCH_SETTINGS_SET)


export type KnownAction = MatchesSettingsLoadedAction | MatchSettingsSetAction
