import RegisterSuccess from './Register/RegisterSuccess'
import { Activate } from './Register/Activate'
import Login from './Login/Login'
import PasswordRecovery from './Password/PasswordRecovery'
import { SignUp } from './SignUp/SignUp'

export const AuthPages = {
  RegisterSuccess,
  Activate,
  Login,
  PasswordRecovery,
  SignUp,
}
