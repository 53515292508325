import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr'
import { useEffect } from 'react'
import { useAppUser } from 'components/_hooks'
import { eventEmitter, HubEvent, hubEvents } from './subscriber'
import { useDispatch } from 'react-redux'
import { hubConnectionStateChanged } from 'store/chat/chat/chat-types'

export class Hubs {
  public _connection: HubConnection = undefined
  
  public connect = async () => {
    if (!this._connection) {
      this._connection = this.buildConnection()

      this.subscribe(this._connection)
      eventEmitter.emit('HubConnectionStateChanged', this)
      try {
        await this.start(this._connection)
        eventEmitter.emit('HubConnectionStateChanged', this)
      } catch {
        eventEmitter.emit('HubConnectionStateChanged', this)
      }
    }
    return this._connection
  }
  
  public abort = async () => {
    if (this._connection) {
      await this._connection.stop()
    }
  }

  public get isConnected() {
    if (!this._connection) {
      return false
    }

    return this._connection.state === HubConnectionState.Connected
  }

  public get isConnecting() {
    if (!this._connection) {
      return false
    }

    return this._connection.state === HubConnectionState.Connecting
      || this._connection.state === HubConnectionState.Reconnecting
  }


  private buildConnection = () => {
    return new HubConnectionBuilder()
      .withUrl('/signalr')
      .build()
  }
  
  private start = (connection: HubConnection) => {
    return connection.start()
      .catch(error => console.log(error))
  }
  
  private subscribe = (connection: HubConnection) => {
    const subscribeOnEvent = this.makeHubSubscriber(connection)
    Object.keys(hubEvents).forEach(subscribeOnEvent)
  }
  
  private makeHubSubscriber = (connection: HubConnection) => (eventType: HubEvent) => {
    connection.on(eventType, (...args: unknown[]) => {
      eventEmitter.emit(eventType, ...args)
    })
  }
}

export const hubs = new Hubs()

export const useStartSignalr = () => {
  const appUser = useAppUser()
  const dispatch = useDispatch()

  useEffect(() => {
    if (appUser) {
      void hubs.connect()
    } else {
      void hubs.abort()
    }
  }, [appUser])

  useEffect(() => {
    const listener = (hubs: Hubs) => {
      dispatch(hubConnectionStateChanged(hubs._connection.state))
    }

    eventEmitter.addListener('HubConnectionStateChanged', listener)

    return () => {
      eventEmitter.removeListener('HubConnectionStateChanged', listener)
    }
  }, [dispatch])
}
