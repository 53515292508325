import * as React from 'react'
import ReactDatePicker from 'react-datepicker'
import { kl_TournamentViewModel, RepeatTypeEnum } from 'models/kl_tournament_viewmodel'
import { isBefore, isSameDay } from 'utils/dateUtils'

interface Props {
  tournament: kl_TournamentViewModel
  tournamentRepeatSettings: Date[]
  errors: any
  handleTournamentDataChange
  onChangeRepeatSettings
}

const joinDates = function (dates: Date[]): string {
  let dateString = ''

  for (let i = 0; i < dates.length; i++) {
    dateString += new Date((dateString ? ', ' : '') + dates[i]).toLocaleDateString() + ' - '
  }

  return dateString ? dateString : 'MM/dd/yyyy'
}

export const RepeatSettings = ({
  tournament,
  tournamentRepeatSettings,
  errors,
  handleTournamentDataChange,
  onChangeRepeatSettings,
}: Props) => {
  const onChangeRepeatSettingsInternal = React.useCallback(
    (date: Date) => {
      const newDate = new Date(date)
      newDate.setHours(0)
      newDate.setMinutes(0)
      // tournament.TournamentRepeatSettings.push(tournament && tournament.TournamentDetails && tournament.TournamentDetails[0] && tournament.TournamentDetails[0] && tournament.TournamentDetails[0].StartDate && tournament.TournamentDetails[0].StartDate);
      const isPresent = tournament.TournamentRepeatSettings.find(d => isSameDay(newDate,d))
      const newRepeatSettings = tournament.TournamentRepeatSettings.filter(d => (isPresent ? !isSameDay(newDate,d) : true))
        .concat(isPresent ? [] : [newDate])
        .sort((d1, d2) => (isBefore(d1,d2) ? -1 : 1))

      onChangeRepeatSettings(newRepeatSettings)
    },
    [tournament, onChangeRepeatSettings]
  )

  const selectedDatesText = React.useMemo(() => joinDates([tournament.TournamentDetails[0].StartDate, ...tournament.TournamentRepeatSettings]), [
    tournament.TournamentDetails[0].StartDate,
    tournament.TournamentRepeatSettings,
  ])
  const minDate = React.useMemo(() => {
    const date = new Date(tournament.TournamentDetails[0].StartDate)
    date.setDate(date.getDate() + 1)
    return date
  }, [tournament.TournamentDetails[0].StartDate])

  return (
    <>
      <>
        <div className="row m-0 w-100">
          <div className="article__big-subheader ct__article__big-subheader">
            <span>Repeat Tournament</span>
            <div />
          </div>
        </div>
        <input
          className="article__checkbox"
          id="repeat-every-hour"
          type="radio"
          name="repeat"
          value="every_hour"
          data-name="tournamentRepeat"
          checked={tournament.RepeatTypeId === RepeatTypeEnum.EveryHour}
          onChange={() => {
            handleTournamentDataChange('RepeatTypeId', RepeatTypeEnum.EveryHour)
          }}
        />
        <input
          className="article__checkbox"
          id="repeat-every-day"
          type="radio"
          name="repeat"
          value="every_day"
          data-name="tournamentRepeat"
          checked={tournament.RepeatTypeId === RepeatTypeEnum.EveryDay}
          onChange={() => {
            handleTournamentDataChange('RepeatTypeId', RepeatTypeEnum.EveryDay)
            handleTournamentDataChange('RepeatHoursInterval', null)
          }}
        />
        <div className="row m-0">
          <input
            className="article__checkbox"
            id="repeat-none"
            type="radio"
            name="repeat"
            value="none"
            data-name="tournamentRepeat"
            checked={!tournament.RepeatTypeId}
            onChange={() => {
              handleTournamentDataChange('RepeatTypeId', null)
              handleTournamentDataChange('RepeatHoursInterval', null)
              handleTournamentDataChange('TournamentRepeatSettings', [])
            }}
          />
          <label
            htmlFor="repeat-none"
            className="article__label article__width-0 article__height-1"
            onClick={() => {
              handleTournamentDataChange('RepeatTypeId', null)
              handleTournamentDataChange('RepeatHoursInterval', null)
              handleTournamentDataChange('TournamentRepeatSettings', [])
            }}
          >
            <span className="article__label-text">None</span>
          </label>
          <label
            htmlFor="repeat-every-hour"
            className="article__label article__label--repeat-every-hour article__width-0 article__height-1"
            onClick={() => {
              handleTournamentDataChange('RepeatTypeId', null)
            }}
          >
            <span className="article__label-text">Every Hour</span>
          </label>
          <label
            htmlFor="repeat-every-day"
            className="article__label article__label--repeat-every-day article__width-0 article__height-1"
            onClick={() => {
              handleTournamentDataChange('RepeatTypeId', RepeatTypeEnum.EveryDay)
              handleTournamentDataChange('RepeatHoursInterval', null)
            }}
          >
            <span className="article__label-text">Every Day</span>
          </label>
        </div>
      </>
      {tournament.RepeatTypeId == 1 && (
        <div className="article__repeat-every-hour-container">
          <div className="row m-0">
            <div className="article-width-2 m-r-30 d-flex flex-column">
              <div className="article__subheader ct__article__subheader">Select the duration of days</div>
              <div className="article__input-date article__width-2 article__height-1">
                <ReactDatePicker
                  data-placement="bottom-start"
                  className={`${errors.TournamentRepeatSettings ? ' highlight-invalid-control' : ''}`}
                  onChange={(date: Date) => onChangeRepeatSettingsInternal(date)}
                  dateFormat="MMMM d, yyyy"
                  highlightDates={tournament.TournamentRepeatSettings}
                  placeholderText={selectedDatesText}
                  shouldCloseOnSelect={false}
                  minDate={minDate}
                  customInput={<input type="text" value={selectedDatesText} aria-label="" aria-describedby="button-addon4" />}
                />
              </div>
            </div>
            <div className="m-r-30 d-flex flex-column">
              <div className="article__subheader ct__article__subheader">Select Hour</div>
              <div className="row m-0">
                <input
                  className="article__checkbox"
                  id="1-hour"
                  type="radio"
                  name="every-day-hour"
                  checked={tournament.RepeatHoursInterval === 1}
                  onChange={() => {
                    handleTournamentDataChange('RepeatHoursInterval', 1)
                  }}
                />
                <label
                  htmlFor="1-hour"
                  className={
                    'article__label article__width-mini article__height-1' + (errors.TournamentRepeatSettings ? ' highlight-invalid-control' : '')
                  }
                  onClick={() => {
                    handleTournamentDataChange('RepeatHoursInterval', 1)
                  }}
                >
                  <span className="article__label-text">1 hour</span>
                </label>
                <input
                  className="article__checkbox"
                  id="2-hours"
                  type="radio"
                  name="every-day-hour"
                  checked={tournament.RepeatHoursInterval === 2}
                  onChange={() => {
                    handleTournamentDataChange('RepeatHoursInterval', 2)
                  }}
                />
                <label
                  htmlFor="2-hours"
                  className={
                    'article__label article__width-mini article__height-1' + (errors.TournamentRepeatSettings ? ' highlight-invalid-control' : '')
                  }
                  onClick={() => {
                    handleTournamentDataChange('RepeatHoursInterval', 2)
                  }}
                >
                  <span className="article__label-text">2 hour</span>
                </label>
                <input
                  className="article__checkbox"
                  id="3-hours"
                  type="radio"
                  name="every-day-hour"
                  checked={tournament.RepeatHoursInterval === 3}
                  onChange={() => {
                    handleTournamentDataChange('RepeatHoursInterval', 3)
                  }}
                />
                <label
                  htmlFor="3-hours"
                  className={
                    'article__label article__width-mini article__height-1' + (errors.TournamentRepeatSettings ? ' highlight-invalid-control' : '')
                  }
                  onClick={() => {
                    handleTournamentDataChange('RepeatHoursInterval', 3)
                  }}
                >
                  <span className="article__label-text">3 hours</span>
                </label>
                <input
                  className="article__checkbox"
                  id="4-hours"
                  type="radio"
                  name="every-day-hour"
                  checked={tournament.RepeatHoursInterval === 4}
                  onChange={() => {
                    handleTournamentDataChange('RepeatHoursInterval', 4)
                  }}
                />
                <label
                  htmlFor="4-hours"
                  className={
                    'article__label article__width-mini article__height-1' + (errors.TournamentRepeatSettings ? ' highlight-invalid-control' : '')
                  }
                  onClick={() => {
                    handleTournamentDataChange('RepeatHoursInterval', 4)
                  }}
                >
                  <span className="article__label-text">4 hours</span>
                </label>
                <input
                  className="article__checkbox"
                  id="6-hours"
                  type="radio"
                  name="every-day-hour"
                  checked={tournament.RepeatHoursInterval === 6}
                  onChange={() => {
                    handleTournamentDataChange('RepeatHoursInterval', 6)
                  }}
                />
                <label
                  htmlFor="6-hours"
                  className={
                    'article__label article__width-mini article__height-1' + (errors.TournamentRepeatSettings ? ' highlight-invalid-control' : '')
                  }
                  onClick={() => {
                    handleTournamentDataChange('RepeatHoursInterval', 6)
                  }}
                >
                  <span className="article__label-text">6 hours</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      )}
      {tournament.RepeatTypeId === RepeatTypeEnum.EveryDay && (
        <div className="article__repeat-every-day-container">
          <div className="row m-0">
            <div className="article__subheader ct__article__subheader">Select the duration of days</div>
          </div>
          <div className="row m-0">
            <div className="article__input-date article__width-2 article__height-1">
              <ReactDatePicker
                data-placement="bottom-start"
                className={`${errors.TournamentRepeatSettings ? ' highlight-invalid-control' : ''}`}
                onChange={(date: Date) => onChangeRepeatSettingsInternal(date)}
                dateFormat="MMMM d, yyyy"
                highlightDates={tournamentRepeatSettings}
                placeholderText={selectedDatesText}
                shouldCloseOnSelect={false}
                minDate={minDate}
                customInput={<input type="text" value={selectedDatesText} aria-label="" aria-describedby="button-addon4" />}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}
