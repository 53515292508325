import { ClanInvitation } from 'components/PlayerPage/_shared/ClanInvitation'
import { useAppUser, useClanLeader, useClanMember, useDialog, useAlertModal, ContainerName, useGetImageSize } from 'components/_hooks'
import { UpdateImageModal } from 'components/_modals'
import { Button, ConfirmActionModal, Img, LoadingBox, ProfileCard } from 'components/_shared'
import { ClanMemberRole } from 'consts'
import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useDisbandClan } from 'store/logic/clan-logic'
import { useLeaveClan } from 'store/logic/clan-member-logic'
import { loadClanMembers } from 'store/logic/pages/clan-page-logic'
import { makeGetIsUploadImageLoading } from 'store/selectors/image-selectors'
import { getMembersPage, makeGetIsClanMembersLoading } from 'store/selectors/pages/clan-page-selectors'
import { ClanType } from 'store/types/clan-types'
import { ApplicationState, PageMode } from 'store/types/common'
import { ImageType, ImageTypeEnum, MediaType } from 'store/types/image-types'
import { getUserProfileUrl } from 'store/types/user-types'
import { cn } from 'utils'
import styles from './ClanLeftMenu.module.scss'

export interface ClanLeftMenuProps {
  avatarId?: number
  onAvatarIdChange: (imageId: number) => void
  pageMode: PageMode
  clan?: ClanType
  invitationId: number
}

export const ClanLeftMenu = ({ avatarId, onAvatarIdChange, pageMode, clan, invitationId }:  ClanLeftMenuProps) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [errorModal, openErrorModal] = useAlertModal()
  const { user } = useClanLeader(clan?.Id)
  const appUser = useAppUser()
  const clanMember = useClanMember(appUser?.Id)
  const leaveClan = useLeaveClan()
  const disbandClan = useDisbandClan()
  const membersPage = useSelector(getMembersPage)

  const getIsUploadImageLoading = useMemo(makeGetIsUploadImageLoading, [])
  const getIsClanMembersLoading = useMemo(makeGetIsClanMembersLoading, [])

  const isClanMembersLoading = useSelector((state: ApplicationState) => getIsClanMembersLoading(state, { clanId: clan?.Id }))
  const isUploadImageLoading = useSelector((state: ApplicationState) => getIsUploadImageLoading(state, { id: 'clanAvatar' }))

  const clanWinRate = clan?.TotalMatches ? Math.round((clan?.WinnedMatches/clan?.TotalMatches) * 100) : 0
  const isClanMember = clan && clanMember && clan.Id === clanMember.ClanId && clanMember.Role === ClanMemberRole.Member
  const isClanLeader = clan && clanMember && clan.Id === clanMember.ClanId && clanMember.Role === ClanMemberRole.Leader

  const handleConfirmleaveClan = async () => {
    if (!isClanMember) {
      return
    }

    try {
      await leaveClan(clanMember.ClanId, clanMember.UserId)

      dispatch(loadClanMembers(clan?.Id, {
        page: membersPage,
        pageSize: 12,
      }))
      return true
    }
    catch (error) {
      closeLeaveClanModal()
      openErrorModal((error as Error).message)
      return false
    }
  }

  const handleLeaveClick = () => {
    if (!isClanMember) {
      return
    }

    openLeaveClanModal()
  }

  const handleDisbandClick = () => {
    if (!isClanLeader) {
      return
    }
    openDisbandClanModal()
  }

  const handleConfirmDisbandClan = async () => {
    if (!isClanLeader) {
      return
    }

    try {
      await disbandClan(clan?.Id)
      history.push('/player')
      return true
    }
    catch (error) {
      closeDisbandClanModal()
      openErrorModal((error as Error).message)
      return false
    }
  }

  const handleChangeAvatar = (image: ImageType) => {
    return new Promise<void>(resolve => {
      onAvatarIdChange(image.Id)
      resolve()
    })
  }

  const getImageType = (mediaType: MediaType): ImageTypeEnum => {
    return ImageTypeEnum.ClanAvatar
  }

  const [updateImageModal, openUpdateImageModal] = useDialog(closeDialog => (
    <UpdateImageModal
      onClose={closeDialog}
      header="Update the Avatar"
      dropText="Drag a picture to this window"
      fileTypes={['image/jpeg', 'image/jpg', 'image/png']}
      recommendedText="Note: Please choose a JPG, Png file from your computer"
      getImageTypeCallback={getImageType}
      saveImage={handleChangeAvatar}
      aspectRatioType="avatar"
      crop
      round
    />
  ))

  const [leaveClanModal, openLeaveClanModal, closeLeaveClanModal] = useDialog(closeDialog =>
    <ConfirmActionModal
      onConfirm={handleConfirmleaveClan}
      onClose={closeDialog}
      text="Are you sure you want to leave your clan?"
      buttonText="Confirm"
      buttonColor="red"
    />
  )
  const getClanMemberProfileContainerSize = useGetImageSize(ContainerName.AvatarsProfile)

  const [disbandClanModal, openDisbandClanModal, closeDisbandClanModal] = useDialog(closeDialog =>
    <ConfirmActionModal
      onConfirm={handleConfirmDisbandClan}
      onClose={closeDialog}
      text="Are you sure you want to disband your clan?"
      buttonText="Confirm"
      buttonColor="red"
    />
  )
  
  return (
    <>
      <div className={styles.left}>
        <LoadingBox size="extra" loading={isUploadImageLoading} className={cn('align-items-center d-flex justify-content-center', styles.avatar)}>
          <Img parentContainerSize={getClanMemberProfileContainerSize} imageId={avatarId} placeholder="noavatar" className={styles.avatar} />
        </LoadingBox>
        {pageMode !== 'read' && <Button disabled={isUploadImageLoading} color="blue" filled onClick={openUpdateImageModal} className="w-100 mt-2" low>Upload Avatar</Button>}
        
        {pageMode !== 'create' &&
          <>
            <div className={cn(styles.leftTile, 'mt-3 mb-2', styles.greenBorder)}>Clan founder</div>
            <LoadingBox loading={!user && isClanMembersLoading} size="average">
              <ProfileCard
                label="View profile"
                link={getUserProfileUrl(user?.Id)}
                name={user?.Username}
                imageId={user?.AvatarId}
                fullSize
                containerName={ContainerName.AvatarsNormal}
              />
            </LoadingBox>
            {pageMode === 'read' && isClanLeader && <Button color="red" filled onClick={handleDisbandClick} className="w-100 mt-2" low>Disband clan</Button>}
            {pageMode === 'read' && isClanMember && <Button color="blue" filled onClick={handleLeaveClick} className="w-100 mt-2" low>Leave clan</Button>}
            { invitationId && (
              <>
                <div className={cn(styles.leftTile, 'mt-3', styles.greenBorder)}>Clan invites</div>
                <ClanInvitation key={invitationId} id={invitationId}/>
              </>
            )}
            <div className={cn(styles.stats, 'mt-3')}>
              <div className={cn(styles.leftTile, styles.purple)}><span>Clan Stats</span></div>
              <ul>
                <li>
                  <span className={styles.statTitle}>Clan Win Ratio</span>
                  <span className={styles.data}>{clanWinRate}%</span>
                  <span className={styles.bar}><span
                    style={{width: `${clanWinRate}%`}}
                    className={styles.ratio}
                  />
                  </span>
                </li>
                <li>
                  <span className={styles.statTitle}>Clan Match</span>
                  <span className={styles.data}>{clan?.TotalMatches ?? 0} Battles</span>
                </li>
                <li>
                  <span className={styles.statTitle}>Clan EXP Points</span>
                  <span className={styles.data}>{clan?.TotalExpPoints ?? 0} point</span>
                </li>
              </ul>
            </div>
          </>
        }
      </div>
      {errorModal}
      {leaveClanModal}
      {disbandClanModal}
      {updateImageModal}
    </>
  )
}
