import React from 'react'

interface Props {
  display: boolean
}

class LoadingOld extends React.Component<Props> {
  render() {
    const { display } = this.props
    return (
      <div>
        <div
          id="loadingDiv"
          className="modal in modal-loading"
          data-backdrop="false"
          role="dialog"
          style={{ display: display ? 'block' : 'none', background: '#0000003a' }}
        >
          <svg
            style={{ position: 'absolute', top: '40%', left: '40%' }}
            width="15%"
            height="15%"
            viewBox="0 0 45 45"
            xmlns="http://www.w3.org/2000/svg"
            stroke="#337840"
          >
            <g fill="none" fillRule="evenodd" transform="translate(1 1)" strokeWidth="2">
              <circle cx="22" cy="22" r="17.0118" strokeOpacity="0">
                <animate attributeName="r" begin="1.5s" dur="3s" values="6;22" calcMode="linear" repeatCount="indefinite" />
                <animate attributeName="strokeOpacity" begin="1.5s" dur="3s" values="1;0" calcMode="linear" repeatCount="indefinite" />
                <animate attributeName="strokeWidth" begin="1.5s" dur="3s" values="2;0" calcMode="linear" repeatCount="indefinite" />
              </circle>
              <circle cx="22" cy="22" r="9.01182" strokeOpacity="0">
                <animate attributeName="r" begin="3s" dur="3s" values="6;22" calcMode="linear" repeatCount="indefinite" />
                <animate attributeName="strokeOpacity" begin="3s" dur="3s" values="1;0" calcMode="linear" repeatCount="indefinite" />
                <animate attributeName="strokeWidth" begin="3s" dur="3s" values="2;0" calcMode="linear" repeatCount="indefinite" />
              </circle>
              <circle cx="22" cy="22" r="2.25887">
                <animate attributeName="r" begin="0s" dur="1.5s" values="6;1;2;3;4;5;6" calcMode="linear" repeatCount="indefinite" />
              </circle>
            </g>
          </svg>
        </div>
        {!display && (
          <>
            <aside className="modal-container" id="success-photo">
              <div className="modal-container__background" />
              <div className="modal-container__window window" style={{ background: '#8BC34A' }}>
                <div
                  className="window__close"
                  onClick={e => {
                    const elem = window.document.getElementById('success-photo')
                    if (elem) {
                      elem.className = 'modal-container'
                    }
                  }}
                />
                <div className="container-fluid">
                  <div className="row justify-content-center">
                    <div className="window__header">
                      {' '}
                      <img src="https://kombatlink-assets.azureedge.net/img/success.png" alt="" />
                      <div className="window__header" style={{ color: 'white', textAlign: 'center' }}>
                        {' '}
                        <div id="successmessage" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </aside>

            <aside className="modal-container" id="error-photo">
              <div className="modal-container__background" />
              <div className="modal-container__window window" style={{ background: '#e85e6c' }}>
                <div
                  className="window__close"
                  onClick={e => {
                    const elem = window.document.getElementById('error-photo')
                    if (elem) {
                      elem.className = 'modal-container'
                    }
                  }}
                />
                <div className="container-fluid">
                  <div className="row justify-content-center">
                    <div className="window__header">
                      {' '}
                      <img src="https://kombatlink-assets.azureedge.net/img/error.png" alt="" />
                      <div className="window__header" style={{ color: 'white', textAlign: 'center' }}>
                        {' '}
                        <div id="errormessage" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </aside>
          </>
        )}
      </div>
    )
  }
}

export default LoadingOld
