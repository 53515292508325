import { HubConnectionState } from '@microsoft/signalr'
import { ChatType } from 'consts'
import { makeActionCreator, makeNoPayloadActionCreator, ReduxAction, ReduxNoPayloadAction } from 'store/types/common'
import { ImageType } from 'store/types/image-types'
import { PublicUserType } from 'store/types/user-types'
import { ChatLeftAction, ChatMemberModel, ChatMembersModel, PrivateChatMembersLoadedAction } from '../chat-member/chat-member-types'
import { ChatMessageModel, NewChatMessageLoadedAction, UnreadChatsLoadedAction } from '../chat-message/chat-message-types'


export const getGroupChatName = (chatReleationType?: ChatRelationTypeEnum) => {
  if (chatReleationType == ChatRelationTypeEnum.Tournament) {
    return 'T'
  } else if (chatReleationType == ChatRelationTypeEnum.Dispute) {
    return 'D'
  }
  return 'G'
}

export enum ChatRelationTypeEnum {
  Tournament = 1,
  Dispute = 2,
}
export interface ChatModel {
  Id: number
  Type: ChatType
  Name?: string
  CreatedDate: string
  RelationType?: ChatRelationTypeEnum
  IsDeleted: boolean
  UnreadMessageCount?: number
}

export interface ChatDeletedModel {
  ChatId: number
}

export interface ChatMemberBlockedModel {
  ChatId: number
  ChatMemberId: number
  ChatMessage: ChatMessageModel
}


export interface ChatsListModel {
  Chats: ChatModel[]
  ChatMessages: ChatMessageModel[]
  ChatMembers: ChatMemberModel[]
  Users: PublicUserType[]
  Images: ImageType[]
} 
export interface CreateGroupChatModel {
  UserIds: number[]
  ChatName: string
}

export interface TypingModel {
  ChatId: number
  UserId: number
  IsTyping:boolean
}
export interface ChatState {
  isOpened: boolean
  hubConnectionState: HubConnectionState
  byId: {
    [id: number]: ChatModel
  }
  privateChatIdByUserId: {
    [userId: number]: number
  }
  chatIdByTournamentId: {
    [tournamentDetailId: number]: number
  }
  disputChatIdByMatchId: {
    [matchId: number]: number
  }
  openedChatId?: number
  openedUserId?: number
}

export const USER_CHAT_OPENED = 'USER_CHAT_OPENED'
export type UserChatOpenedAction = ReduxAction<typeof USER_CHAT_OPENED, number>
export const userChatOpened = makeActionCreator<number>(USER_CHAT_OPENED)

export const CHAT_LIST_OPENED = 'CHAT_LIST_OPENED'
export type ChatListOpenedAction = ReduxAction<typeof CHAT_LIST_OPENED, boolean>
export const chatListOpened = makeActionCreator<boolean>(CHAT_LIST_OPENED)


export const CHAT_OPENED = 'CHAT_OPENED'
export type ChatOpenedAction = ReduxAction<typeof CHAT_OPENED, number>
export const chatOpened = makeActionCreator<number>(CHAT_OPENED)

export const CHAT_CLOSED = 'CHAT_CLOSED'
export type ChatClosedAction = ReduxNoPayloadAction<typeof CHAT_CLOSED>
export const chatClosed = makeNoPayloadActionCreator(CHAT_CLOSED)

export const CHATS_CLOSED = 'CHATS_CLOSED'
export type ChatsClosedAction = ReduxNoPayloadAction<typeof CHATS_CLOSED>
export const chatsClosed = makeNoPayloadActionCreator(CHATS_CLOSED)

export const HUB_CONNECTION_STATE_CHANGED = 'HUB_CONNECTION_STATE_CHANGED'
export type HubConnectionStateChangedAction = ReduxAction<typeof HUB_CONNECTION_STATE_CHANGED, HubConnectionState>
export const hubConnectionStateChanged = makeActionCreator<HubConnectionState>(HUB_CONNECTION_STATE_CHANGED)

interface TournamentChatLoadedPayload {
  tournamentDetailId: number
  chat: ChatMembersModel
}
interface DeleteTournamentChatFromStateLoadedPayload {
  tournamentDetailId: number
}

export const TOURNAMENT_CHAT_LOADED = 'TOURNAMENT_CHAT_LOADED'
export type TournamentChatLoadedAction = ReduxAction<typeof TOURNAMENT_CHAT_LOADED, TournamentChatLoadedPayload>
export const tournamentChatLoaded = makeActionCreator<TournamentChatLoadedPayload>(TOURNAMENT_CHAT_LOADED)

export const DELETE_TOURNAMENT_CHAT_FROM_STATE_LOADED = 'DELETE_TOURNAMENT_CHAT_FROM_STATE_LOADED'
export type DeleteTournamentChatForomStateLoadedAction = ReduxAction<typeof DELETE_TOURNAMENT_CHAT_FROM_STATE_LOADED, DeleteTournamentChatFromStateLoadedPayload>
export const deleteTournamentChatFromStateLoaded = makeActionCreator<DeleteTournamentChatFromStateLoadedPayload>(DELETE_TOURNAMENT_CHAT_FROM_STATE_LOADED)

interface DisputeChatLoadedPayload {
  matchId: number
  chat: ChatMembersModel
}

export const DISPUTE_CHAT_LOADED = 'DISPUTE_CHAT_LOADED'
export type DisputeChatLoadedAction = ReduxAction<typeof DISPUTE_CHAT_LOADED, DisputeChatLoadedPayload>
export const disputeChatLoaded = makeActionCreator<DisputeChatLoadedPayload>(DISPUTE_CHAT_LOADED)

export const CHAT_LOADED = 'CHAT_LOADED'
export type ChatLoadedAction = ReduxAction<typeof CHAT_LOADED, ChatMembersModel>
export const chatLoaded = makeActionCreator<ChatMembersModel>(CHAT_LOADED)

export const CHAT_DELETED = 'CHAT_DELETED'
export type ChatDeletedAction = ReduxAction<typeof CHAT_DELETED, number>
export const chatDeleted = makeActionCreator<number>(CHAT_DELETED)


export const CHAT_DELETED_NOTIFICATION_RECEIVED = 'CHAT_DELETED_NOTIFICATION_RECEIVED'
export type ChatDeletedNotificationReceivedAction = ReduxAction<typeof CHAT_DELETED_NOTIFICATION_RECEIVED, number>
export const chatDeletedNotificationReceived = makeActionCreator<number>(CHAT_DELETED_NOTIFICATION_RECEIVED)


export const CHATS_LOADED = 'CHATS_LOADED'
export type ChatsLoadedAction = ReduxAction<typeof CHATS_LOADED, ChatsListModel>
export const chatsLoaded = makeActionCreator<ChatsListModel>(CHATS_LOADED)


export const GROUP_CHAT_CREATED = 'GROUP_CHAT_CREATED'
export type GroupChatCreatedAction = ReduxAction<typeof GROUP_CHAT_CREATED, ChatMembersModel>
export const groupChatCreated = makeActionCreator<ChatMembersModel>(GROUP_CHAT_CREATED)

export type ChatKnownAction = ChatOpenedAction
  | UserChatOpenedAction
  | ChatClosedAction
  | ChatsClosedAction
  | HubConnectionStateChangedAction
  | PrivateChatMembersLoadedAction
  | TournamentChatLoadedAction
  | DisputeChatLoadedAction
  | UnreadChatsLoadedAction
  | NewChatMessageLoadedAction
  | ChatLoadedAction
  | GroupChatCreatedAction
  | ChatsLoadedAction
  | ChatDeletedAction
  | ChatLeftAction
  | ChatDeletedNotificationReceivedAction
  | ChatListOpenedAction
  | DeleteTournamentChatForomStateLoadedAction