import { ChatType } from 'consts'
import React from 'react'
import { useChatMessage } from 'store/chat/chat-message/chat-message-hooks'
import { MemberChatMessage } from './MemberChatMessage'
import { OwnChatMessage } from './OwnChatMessage'
import SystemChatMessage from './SystemChatMessage'

export interface ChatMessageProps {
  myChatMemberId?: number
  chatMessageId: number
  chatType: ChatType
}

export const ChatMessage = ({ myChatMemberId, chatMessageId, chatType }:  ChatMessageProps) => {
  const chatMessage = useChatMessage(chatMessageId)

  const isMyMessage = chatMessage.ChatMemberId === myChatMemberId

  if (isMyMessage) {
    return (
      <OwnChatMessage chatMessage={chatMessage} />
    )
  }

  const isSystemMessage = chatMessage.ChatMemberId == null

  if (isSystemMessage) {
    return (<SystemChatMessage chatMessage={chatMessage} />)
  }

  return (
    <MemberChatMessage chatMessage={chatMessage} chatType={chatType} />
  )
}
