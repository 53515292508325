import { makeActionCreator, ReduxAction } from './common'

export interface DisputeDetailType {
  Id: number
  DisputeId: number
  Place: number
  Comment: string
  MatchParticipantId: number
  SubmitDate: string
  SubmitDateTicks: number
}

export interface State {
  byId: {
    [key: number]: DisputeDetailType
  }
  idsByDisputeId: {
    [key: number]: number[]
  }
  idByMatchParticipantId: {
    [key: number]: number
  }
}

export const DISPUTE_DETAILS_LOADED = 'DISPUTE_DETAILS_LOADED'
interface DisputeDetailsLoadedPayload {
  disputeId: number
  disputeDetails: DisputeDetailType[]
}
export type DisputeDetailsLoadedAction = ReduxAction<typeof DISPUTE_DETAILS_LOADED, DisputeDetailsLoadedPayload>
export const disputeDetailsLoaded = makeActionCreator<DisputeDetailsLoadedPayload>(DISPUTE_DETAILS_LOADED)

export const DISPUTE_DETAIL_ADDED = 'DISPUTE_DETAIL_ADDED'
interface DisputeDetailAddedPayload {
  disputeDetail: DisputeDetailType
}
export type DisputeDetailAddedAction = ReduxAction<typeof DISPUTE_DETAIL_ADDED, DisputeDetailAddedPayload>
export const disputeDetailAdded = makeActionCreator<DisputeDetailAddedPayload>(DISPUTE_DETAIL_ADDED)

export const DISPUTE_DETAIL_UPDATED = 'DISPUTE_DETAIL_UPDATED'
interface DisputeDetailUpdatedPayload {
  disputeDetail: DisputeDetailType
}
export type DisputeDetailUpdatedAction = ReduxAction<typeof DISPUTE_DETAIL_UPDATED, DisputeDetailUpdatedPayload>
export const disputeDetailUpdated = makeActionCreator<DisputeDetailUpdatedPayload>(DISPUTE_DETAIL_UPDATED)

export type KnownAction = DisputeDetailsLoadedAction | DisputeDetailUpdatedAction | DisputeDetailAddedAction
