import { useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import { fetchApi } from 'server/server-utils'
import { makeGetIsLinUpsRequested } from 'store/selectors/line-ups-selectors'
import { AppThunkAction, GetState } from 'store/types/common'
import { lineUpAdded, lineUpDeleted, lineUpsLoaded, LineUpsType, LineUpType } from 'store/types/line-up-types'
import { cancel, OperationTypes, run, useRun } from './operations-logic'

const getIsLinUpsRequested = makeGetIsLinUpsRequested()
export function deleteMyLineUp(tournamentDetailId: number) {
  return fetchApi<LineUpType>(`clanlineup/my/${tournamentDetailId}`, 'DELETE')
}
export const useAddLineUp = () => {
  const dispatch = useDispatch()
  return useRun(OperationTypes.addLineUp, null, async (tournamentDetailId: number) => {
    const lineUp = await fetchApi<LineUpType>(`clanlineup/${tournamentDetailId}`, 'POST')
    dispatch(lineUpAdded({ lineUp: lineUp }))   
    return true
  })
}


export const useDeleteMyLineUp = () => {  
  const dispatch = useDispatch()
  return useRun(OperationTypes.deleteLineUp, null, async (tournamentDetailId: number) => {
    const lineUp = await deleteMyLineUp(tournamentDetailId)
    dispatch(lineUpDeleted({ lineUp: lineUp }))
    return true
  })
}


export const useDeleteLineUp = () => {  
  const dispatch = useDispatch()
  return useRun(OperationTypes.deleteLineUp, null, async (id: number) => {
    const lineUp = await fetchApi<LineUpType>(`clanlineup/${id}`, 'DELETE')
    dispatch(lineUpDeleted({ lineUp: lineUp }))
    return true
  })
}
export const useReloadLineUp = () => {  
  const dispatch = useDispatch()
  return useRun(OperationTypes.loadLineUps, null, async (tournamentDetailId: number) => {
    const lineUps = await fetchApi<LineUpsType>(`clanlineup/list/${tournamentDetailId}`, 'GET')
    dispatch(lineUpsLoaded(lineUps))
    return true
  })
}
export const loadLineUps = async (tournamentDetailId: number, dispatch: Dispatch, getState: GetState) => {
  if (getIsLinUpsRequested(getState(), tournamentDetailId)) {
    return
  }
  await run(OperationTypes.loadLineUps, tournamentDetailId, dispatch, getState, async () => {
    try {     
      const lineUps = await fetchApi<LineUpsType>(`clanlineup/list/${tournamentDetailId}`, 'GET')
      dispatch(lineUpsLoaded(lineUps))
    } catch (error) {
      return false
    }
    return true 
  })
}

export const reloadLineUps = async (tournamentDetailId: number, dispatch: Dispatch, getState: GetState) => {
  cancel(OperationTypes.loadLineUps, tournamentDetailId, dispatch, getState)
  await loadLineUps(tournamentDetailId, dispatch, getState)
}


export const lineUpsActionCreators = {
  loadLineUps: (tournamentDetailId: number): AppThunkAction => (dispatch: Dispatch, getState: GetState) => {
    return loadLineUps(tournamentDetailId, dispatch, getState)
  },
  reloadLineUps: (tournamentDetailId: number): AppThunkAction => (dispatch: Dispatch, getState: GetState) => {
    return reloadLineUps(tournamentDetailId, dispatch, getState)
  },
}

