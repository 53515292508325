import { useAppSelector } from 'components/_hooks'
import { useMemo } from 'react'
import { makeGetUsers } from 'store/selectors/user-selectors'
import { mapArrayToObject } from 'utils'
import { makeGetChatMemeber, makeGetChatMemebers, makeGetChatMemebersIds, makeGetIsPrivateChatMembersLoading } from './chat-member-selectors'

export const useIsPrivateChatMembersLoading = (userId: number) => {
  const getIsPrivateChatMembersLoading = useMemo(makeGetIsPrivateChatMembersLoading, [])
  return useAppSelector(state => getIsPrivateChatMembersLoading(state, { userId }))
}

export const useChatMembersIds = (chatId: number) => {
  const getChatMemebersIds = useMemo(makeGetChatMemebersIds, [])
  return useAppSelector(state => getChatMemebersIds(state, { chatId }))
}

export const useChatMembers = (chatId: number) => {
  const getChatMemebers = useMemo(makeGetChatMemebers, [])
  return useAppSelector(state => getChatMemebers(state, { chatId }))
}

export const useChatMember = (chatMemberId: number) => {
  const getChatMemebers = useMemo(makeGetChatMemeber, [])
  return useAppSelector(state => getChatMemebers(state, { chatMemberId }))
}


export const useUserChatMember = (chatId: number, userId: number) => {
  const chatMembers = useChatMembers(chatId)
  return chatMembers.find(cm => cm.UserId == userId)
}

export const useTypingUserChatMember = (chatId: number, userId: number) => {
  const chatMembers = useChatMembers(chatId)
  return chatMembers.filter(cm => cm.UserId !== userId && cm.isTyping === true)
}

export const useChatUsers = (chatId: number) => {
  const chatMembers = useChatMembers(chatId)

  const usersIds = chatMembers?.map(cm => cm.UserId) || []
  const getUsers = useMemo(makeGetUsers, [])
  const users = useAppSelector(state => getUsers(state, usersIds))
  const groupedUsers = mapArrayToObject(users, u => u.Id, u => u)

  return chatMembers.map(cm => ({
    User: groupedUsers[cm.UserId],
    Member: cm,
  }))
}
