import { Reducer } from 'redux'
import { DISPUTES_LOADED } from 'store/types/dispute-types'
import { APPROVED_MATCH_LINKS_LOADED, PENDING_MATCH_LINKS_LOADED } from 'store/types/match-link-types'
import { MATCHES_SETTINGS_LOADED } from 'store/types/match-settings-types'
import { ACTIVE_MATCHES_LOADED, CHECKIN_MATCHES_LOADED, FINISHED_MATCHES_LOADED, PENDING_MATCHES_LOADED } from 'store/types/match-types'
import { ADMIN_ACTIVE_TOURNAMENTS_LOADED, ADMIN_CANCELED_TOURNAMENTS_LOADED, ADMIN_FINISHED_TOURNAMENTS_LOADED, ADMIN_UPCOMING_TOURNAMENTS_LOADED, CAUROSEL_UPDATED } from 'store/types/pages/admin-tournaments-page-types'
import { MY_ORGANIZER_ACTIVE_TOURNAMENTS_LOADED, MY_ORGANIZER_CANCELED_TOURNAMENTS_LOADED, MY_ORGANIZER_FINISHED_TOURNAMENTS_LOADED, MY_ORGANIZER_UPCOMING_TOURNAMENTS_LOADED } from 'store/types/pages/organizer-events-page-types'
import { PLAYER_LIVE_TOURNAMENTS_LOADED, PLAYER_ONLINE_TOURNAMENTS_LOADED } from 'store/types/pages/player-events-page-types'
import { MAIN_TOURNAMENTS_LOADED, QUALIFY_TOURNAMENTS_LOADED, UPCOMING_TOURNAMENTS_LOADED } from 'store/types/pages/tournaments-page-types'
import { arrayToObject, calcByIdWithoutIds } from 'utils'
import { State, KnownAction, SET_TOURNAMENT, SET_TOURNAMENTS, FINISHED_TOURNAMENTS_LOADED, CLEAR_TOURNAMENTS, TournamentType, TOP_TOURNAMENTS_LOADED, 
  GAME_UPCOMING_TOURNAMENTS_LOADED, GAME_MAIN_TOURNAMENTS_LOADED, GAME_QUALIFY_TOURNAMENTS_LOADED, ORGANIZER_RECENT_TOURNAMENTS_LOADED } from '../types/tournaments-types'

export const initialTournamentsState: State = {
  byId: {},
  finishedTournamentsIds: [],
  allFinishedTournamentsCount: 0,
  topTournamentsIds: [],
  gameUpcomingTournamentsIds: [],
  allGameUpcomingTournamentsCount: 0,
  gameMainTournamentsIds: [],
  allGameMainTournamentsCount: 0,
  gameQualifyTournamentsIds: [],
  allGameQualifyTournamentsCount: 0,
  recentTournamentsIdsByOrganizerId: {

  },
}

export const calcTournamentsById = (state: State, models: TournamentType[]): { [id: number]: TournamentType } => {
  return {
    ...state.byId,
    ...arrayToObject(models, x => x.TournamentDetailId),
  }
}

const reducer: Reducer<State, KnownAction> = (state: State | undefined = initialTournamentsState, action: KnownAction) => {
  switch (action.type) {
    case SET_TOURNAMENT:
      return {
        ...state,
        byId: calcTournamentsById(state, [{...action.tournament}]),
      }
    case SET_TOURNAMENTS:
      return {
        ...state,
        byId: calcTournamentsById(state, action.tournaments),
      }    
    case FINISHED_TOURNAMENTS_LOADED: {
      return {
        ...state,
        byId: calcTournamentsById(state, action.payload.tournaments),
        finishedTournamentsIds: action.payload.tournaments.map(x => x.TournamentDetailId),
        allFinishedTournamentsCount: action.payload.allFinishedTournamentsCount,
      }
    }    
    case PLAYER_LIVE_TOURNAMENTS_LOADED:
    case PLAYER_ONLINE_TOURNAMENTS_LOADED:
    case MY_ORGANIZER_UPCOMING_TOURNAMENTS_LOADED:      
    case MY_ORGANIZER_ACTIVE_TOURNAMENTS_LOADED:
    case MY_ORGANIZER_FINISHED_TOURNAMENTS_LOADED: 
    case MY_ORGANIZER_CANCELED_TOURNAMENTS_LOADED:
    case ADMIN_UPCOMING_TOURNAMENTS_LOADED:      
    case ADMIN_ACTIVE_TOURNAMENTS_LOADED:
    case ADMIN_FINISHED_TOURNAMENTS_LOADED: 
    case ADMIN_CANCELED_TOURNAMENTS_LOADED: {
      return {
        ...state,
        byId: calcTournamentsById(state, action.payload.tournaments),
      }
    }
    case TOP_TOURNAMENTS_LOADED: {
      return {
        ...state,
        byId: calcTournamentsById(state, action.payload.tournaments),
        topTournamentsIds: action.payload.tournaments.map(x => x.TournamentDetailId),
      }
    }
    case ORGANIZER_RECENT_TOURNAMENTS_LOADED: {
      return {
        ...state,
        byId: calcTournamentsById(state, action.payload.tournaments),
        recentTournamentsIdsByOrganizerId: {
          ...state.recentTournamentsIdsByOrganizerId,
          [action.payload.organizerId]: action.payload.tournaments.map(x => x.TournamentDetailId),
        },
      }
    }
    case GAME_UPCOMING_TOURNAMENTS_LOADED: {
      return {
        ...state,
        byId: calcTournamentsById(state, action.payload.tournaments),
        gameUpcomingTournamentsIds: action.payload.tournaments.map(x => x.TournamentDetailId),
        allGameUpcomingTournamentsCount: action.payload.allGameUpcomingTournamentsCount,
      }
    }
    case GAME_MAIN_TOURNAMENTS_LOADED: {
      return {
        ...state,
        byId: calcTournamentsById(state, action.payload.tournaments),
        gameMainTournamentsIds: action.payload.tournaments.map(x => x.TournamentDetailId),
        allGameMainTournamentsCount: action.payload.allGameMainTournamentsCount,
      }
    }
    case GAME_QUALIFY_TOURNAMENTS_LOADED: {
      return {
        ...state,
        byId: calcTournamentsById(state, action.payload.tournaments),
        gameQualifyTournamentsIds: action.payload.tournaments.map(x => x.TournamentDetailId),
        allGameQualifyTournamentsCount: action.payload.allGameQualifyTournamentsCount,
      }
    }
    case UPCOMING_TOURNAMENTS_LOADED:
    case MAIN_TOURNAMENTS_LOADED:
    case QUALIFY_TOURNAMENTS_LOADED:  {
      return {
        ...state,
        byId: calcTournamentsById(state, action.payload.tournaments),
      }
    }
    case DISPUTES_LOADED:
    case APPROVED_MATCH_LINKS_LOADED:
    case PENDING_MATCH_LINKS_LOADED:
    case PENDING_MATCHES_LOADED:
    case CHECKIN_MATCHES_LOADED:
    case ACTIVE_MATCHES_LOADED:
    case FINISHED_MATCHES_LOADED:
    case MATCHES_SETTINGS_LOADED:
      return {
        ...state,
        byId: calcTournamentsById(state, action.payload.Tournaments),
      }
    case CLEAR_TOURNAMENTS:
      return {
        ...state,
        byId: calcByIdWithoutIds(state, action.payload.tournamentsIds),
      }
    case CAUROSEL_UPDATED: {
      return {
        ...state,
        byId:  {
          ...state.byId,
          [action.payload.tournamentId]: {
            ...state.byId[action.payload.tournamentId],
            ShowOnHomePage: action.payload.isChecked,
          },
        },
      }
    }
    default:
      return state
  }
}

export default reducer
