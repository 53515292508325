import { AppLoadedAction } from './app-types'
import { makeActionCreator, ReduxAction } from './common'

export interface CountryType {
  Id: number
  RegionId: number
  CountryName: string
}

export interface CountryState {
  byId: {
    [key: number]: CountryType
  }
  idsByRegionId: {
    [key: number]: number[]
  }
}

export const COUNTRIES_LOADED = 'COUNTRIES_LOADED'
interface CountriesLoadedPayload {
  countries: CountryType[]
}
export type CountriesLoadedAction = ReduxAction<typeof COUNTRIES_LOADED, CountriesLoadedPayload>
export const countriesLoaded = makeActionCreator<CountriesLoadedPayload>(COUNTRIES_LOADED)

export type KnownAction = AppLoadedAction
  | CountriesLoadedAction

export const countryValueExtractor = (country: CountryType) => country.Id
export const countryLabelExtractor = (country: CountryType) => country.CountryName
