import React, { ReactNode, useMemo } from 'react'
import { ImagePlaceholder, useImage } from './useImage'

interface DivImgProps {
  imageId?: number
  src?: string
  className?: string
  placeholder?: ImagePlaceholder
  children?: ReactNode
  style?: React.CSSProperties
  useBackgroundStyles?: boolean
  onClick?: (event: React.MouseEvent) => void
  parentContainerSize?:string
}

export const DivImg = ({
  imageId,
  className,
  placeholder,
  src,
  children,
  style,
  useBackgroundStyles = true,
  onClick,
  parentContainerSize
}: DivImgProps) => {
  const { imageSrc } = useImage(imageId, placeholder,parentContainerSize)

  const backgroundStyles = useMemo(() => {
    if (useBackgroundStyles) {
      return {
        backgroundPosition: 'center top',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        height:'100%'
      }
    }
    return {}
  }, [useBackgroundStyles])

  return (
    <div
      onClick={onClick}
      className={className}
      style={{
        backgroundImage: `url(${src || imageSrc})`,
        ...backgroundStyles,
        ...style,
      }}
    >
      {children}
    </div>
  )
}
