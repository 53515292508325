import { ConsoleEnum } from 'consts/ConsoleType'
import { makeActionCreator, ReduxAction } from './common'
import { GameDetailLoadedAction } from './game-types'
import { AdminActiveTournamentsLoadedAction, AdminCanceledTournamentsLoadedAction, AdminFinishedTournamentsLoadedAction, AdminUpcomingTournamentsLoadedAction } from './pages/admin-tournaments-page-types'
import { MyOrganizerActiveTournamentsLoadedAction, MyOrganizerCanceledTournamentsLoadedAction, MyOrganizerFinishedTournamentsLoadedAction, MyOrganizerUpcomingTournamentsLoadedAction } from './pages/organizer-events-page-types'
import { MainTournamentsLoadedAction, QualifyTournamentsLoadedAction, UpcomingTournamentsLoadedAction } from './pages/tournaments-page-types'
import { GameMainTournamentsLoadedAction, GameQualifyTournamentsLoadedAction, GameUpcomingTournamentsLoadedAction,  OrganizerRecentTournamentsLoadedAction,  TopTournamentsLoadedAction } from './tournaments-types'

export interface TournamentConsolesType {
  TournamentDetailId: number
  Consoles: ConsoleEnum[]
}
export interface GameConsolesType {
  GameId: number
  Consoles: ConsoleEnum[]
}

export interface ConsoleState {
  idsByUserId: {
    [key: number]: ConsoleEnum[]
  }
  idsByTournamentDetailId: {
    [key: number]: ConsoleEnum[]
  }
  idsByGameId: {
    [key: number]: ConsoleEnum[]
  }
}

export const USER_CONSOLES_LOADED = 'USER_CONSOLES_LOADED'
interface UserConsolesLoadedPayload {
  consoles: ConsoleEnum[]
  userId: number
}
export type UserConsolesLoadedAction = ReduxAction<typeof USER_CONSOLES_LOADED, UserConsolesLoadedPayload>
export const userConsolesLoaded = makeActionCreator<UserConsolesLoadedPayload>(USER_CONSOLES_LOADED)

export type KnownAction = UserConsolesLoadedAction 
  | UpcomingTournamentsLoadedAction 
  | MainTournamentsLoadedAction
  | QualifyTournamentsLoadedAction
  | MyOrganizerUpcomingTournamentsLoadedAction 
  | MyOrganizerActiveTournamentsLoadedAction
  | MyOrganizerFinishedTournamentsLoadedAction
  | MyOrganizerCanceledTournamentsLoadedAction
  | TopTournamentsLoadedAction
  | GameUpcomingTournamentsLoadedAction
  | GameMainTournamentsLoadedAction
  | GameQualifyTournamentsLoadedAction
  | GameDetailLoadedAction
  | OrganizerRecentTournamentsLoadedAction
  | AdminUpcomingTournamentsLoadedAction
  | AdminActiveTournamentsLoadedAction
  | AdminFinishedTournamentsLoadedAction
  | AdminCanceledTournamentsLoadedAction
