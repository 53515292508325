import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch } from 'redux'
import { makeGetIsClanInvitationsRequested } from 'store/selectors/clan-invitations-selectors'
import { clanInvitationsLoaded, ClansInvitationsModel } from 'store/types/clan-invitations-types'
import { clansMembersLoaded, ClansMembersModel } from 'store/types/clan-member-types'
import { ApplicationState, GetState } from 'store/types/common'
import { fetchApi } from 'utils'
import { cancel, OperationTypes, useCancel, useRun } from './operations-logic'

export const useInvitePlayers = () => {
  return useRun(OperationTypes.invitePlayers, null, async (playersIds: number[]) => {
    await fetchApi('invitation', 'POST', { playersIds })
    return true
  })
}

export const fetchClanInivitations = async (dispatch: Dispatch) => {
  try {
    const model = await fetchApi<ClansInvitationsModel>('invitation', 'GET')
    dispatch(clanInvitationsLoaded(model))
  } catch (error) {
    return false
  }
  return true
}

export const useLoadClanInvitations = (userId: number) => {
  const dispatch = useDispatch()
  const getIsClanInvitationsRequested = useMemo(makeGetIsClanInvitationsRequested, [])
  const isClanInvitationsRequested = useSelector((state: ApplicationState) => getIsClanInvitationsRequested(state, { userId }))

  return useRun(OperationTypes.loadClanInvitations, userId, async () => {
    if (isClanInvitationsRequested) {
      return true
    }
    return fetchClanInivitations(dispatch)
  })
}

export const useReloadClanInvitations = (userId: number) => {
  const dispatch = useDispatch()
  const cancelLoadClanInvitations = useCancel(OperationTypes.loadClanInvitations, userId)
  return () => {
    cancelLoadClanInvitations()
    return fetchClanInivitations(dispatch)
  }
}

export const reloadClanInvitations = (userId: number) => async (dispatch: Dispatch, getState: GetState) => {
  cancel(OperationTypes.loadClanInvitations, userId, dispatch, getState)
  return fetchClanInivitations(dispatch) 
}

export const useAcceptInvitation = (userId: number, invitationId: number) => {
  const dispatch = useDispatch()
  const reloadClanInvitations = useReloadClanInvitations(userId)
  return useRun(OperationTypes.updateInvitation, invitationId, async (clanId: number) => {
    const model = await fetchApi<ClansMembersModel>(`invitation/${clanId}/accept`, 'POST')
    dispatch(clansMembersLoaded(model))
    void reloadClanInvitations()
    return true
  })
}

export const useDeclineInvitation = (userId: number, invitationId: number) => {
  const reloadClanInvitations = useReloadClanInvitations(userId)
  return useRun(OperationTypes.updateInvitation, invitationId, async (clanId: number) => {
    await fetchApi(`invitation/${clanId}/decline`, 'POST')
    void reloadClanInvitations()
    return true
  })
}
