import React from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { useMatchParam, useUnionMatchParam } from 'components/_hooks'
import TournamentDetails, { TournamentDetailTab, tournamentDetailTabs } from 'components/TournamentDetail/TournamentDetails'

interface TournamentDetailsPageRouteProps {
  tournamentId: string
  tab: TournamentDetailTab
}

interface TournamentDetailsPageProps extends RouteComponentProps<TournamentDetailsPageRouteProps> {
  tournamentId: number
  tab: TournamentDetailTab
}

export const TournamentDetailsPage = ({ match }: TournamentDetailsPageProps) => {
  const tournamentId = useMatchParam(match, m => parseInt(m.tournamentId))

  const tab = useUnionMatchParam(tournamentDetailTabs, match, m => m.tab, 'overview')

  const history = useHistory()
  const onChangeTab = (tab: TournamentDetailTab) => {
    history.replace(`/tournament-detail/${tournamentId}/${tab}`)
  } 
  
  return (
    <>
      <TournamentDetails tournamentId={tournamentId} showFooter currentTab={tab} onChangeTab={onChangeTab} />
    </>
  )
}
