import React from 'react'
import styles from './InputError.module.scss'

interface InputErrorProps {
  text: string
}

export const InputError = ({ text }: InputErrorProps) => {
  return (
    <>
      <div className={styles.error}>
        <div className={styles.icon}></div>
        <div className={styles.text}>{text}</div>
      </div>
    </>
  )
}
