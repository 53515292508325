import React, { useEffect, useMemo } from 'react'
import styles from '../SignUp.module.scss'
import { PasswordInput } from './PasswordInput'
import { SignUpDatePicker } from './SignUpDatePicker'
import { SignUpTextInput } from './SignUpTextInput'
import { Select } from 'components/_shared'
import { cn } from 'utils'
import { useSelector } from 'react-redux'
import { getRegions, getRegionsLoading } from 'store/selectors/region-selectors'
import { regionLabelExtractor, RegionType, regionValueExtractor } from 'store/types/region-types'
import { DisplayInDirectoryType } from './consts'

interface OrganizerSignUpProps {
  email: string
  onEmailChange: (value: string) => void
  password: string
  onPasswordChange: (value: string) => void
  passwordConfirm: string
  onPasswordConfirmChange: (value: string) => void
  birthDate?: Date
  onBirthDateChange: (value?: Date) => void
  displayInDirectory: DisplayInDirectoryType
  onDisplayInDirectoryChange: (value: DisplayInDirectoryType) => void
  region: RegionType
  onRegionChange: (value: RegionType) => void
  firstName: string
  onFirstNameChange: (value: string) => void
  lastName: string
  onLastNameChange: (value: string) => void
  organizationName: string
  onOrganizationNameChange: (value: string) => void
  position: string
  onPositionChange: (value: string) => void
  display: boolean
  onFilledChange: (value: boolean) => void
}

export const OrganizerSignUp = ({
  email,
  onEmailChange,
  password,
  onPasswordChange,
  passwordConfirm,
  onPasswordConfirmChange,
  birthDate,
  onBirthDateChange,
  displayInDirectory,
  onDisplayInDirectoryChange,
  region,
  onRegionChange,
  firstName,
  onFirstNameChange,
  lastName,
  onLastNameChange,
  organizationName,
  onOrganizationNameChange,
  position,
  onPositionChange,
  onFilledChange,
  display,
}: OrganizerSignUpProps) => {
  const regions = useSelector(getRegions)
  const regionsLoading = useSelector(getRegionsLoading)

  const isFilled = useMemo(() => {
    return firstName.length > 0
      && lastName.length > 0
      && birthDate !== undefined
      && organizationName.length > 0
      && position.length > 0
      && email.length > 0
      && password.length > 0
      && passwordConfirm.length > 0
      && region != undefined
  }, [birthDate, email.length, firstName.length, lastName.length, organizationName.length, password.length, passwordConfirm.length, position.length, region])

  const handleDisplayInDirectoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onDisplayInDirectoryChange(event.currentTarget.value as DisplayInDirectoryType)
  }

  useEffect(() => {
    onFilledChange(isFilled)
  }, [isFilled, onFilledChange])

  return (
    <div className={cn('row', !display && 'd-none')}>
      <div className="col-lg-4 col-md-6">
        <div className="d-flex flex-column">
          <SignUpTextInput
            value={firstName}
            onChange={onFirstNameChange}
            title="First Name"
            placeholder="First Name"
            withClear
          />
          <SignUpTextInput
            value={lastName}
            onChange={onLastNameChange}
            title="Last Name"
            placeholder="Last Name"
            withClear
          />
          <SignUpDatePicker
            value={birthDate}
            onChange={onBirthDateChange}
            title="Date of Birth"
            min="1900-01-01"
            checkOnBlur={true}

          />
          <SignUpTextInput
            value={organizationName}
            onChange={onOrganizationNameChange}
            title="Organization Name"
            placeholder="Organization Name"
            withClear
          />
        </div>
      </div>
      <div className="col-lg-4 col-md-6">
        <div className="d-flex flex-column">
          <SignUpTextInput
            value={position}
            onChange={onPositionChange}
            title="Role"
            placeholder="Role"
            withClear
          />
          <SignUpTextInput
            value={email}
            onChange={onEmailChange}
            title="Email"
            placeholder="Email"
            withClear
          />
          <PasswordInput
            value={password}
            onChange={onPasswordChange}
            title="Password"
            placeholder="Enter password"
            className={styles.signUpContainer}
            inputClassName={styles.signUpDefaultInput}
            iconClassName={styles.signUpVisibleButton}
          />
          <PasswordInput
            value={passwordConfirm}
            onChange={onPasswordConfirmChange}
            title="Password Confirmation"
            placeholder="Confirm password"
            className={styles.signUpContainer}
            inputClassName={styles.signUpDefaultInput}
            iconClassName={styles.signUpVisibleButton}
          />
        </div>
      </div>
      <div className="col-lg-4 col-md-6">
        <div className="d-flex flex-column">
          <div className={styles.signUpInputTitle}>Region</div>
          <Select
            options={regions}
            onChange={onRegionChange}
            selected={region}
            labelExtractor={regionLabelExtractor}
            valueExtractor={regionValueExtractor}
            type="single"
            loading={regionsLoading}
            required
            placeHolder="Region"
            containerClassName={styles.regionSelectContainerClassName}
          />
          <div className={styles.signUpInputTitle}>Do you want to be listed in organizer directory?</div>
          <div className="d-flex flex-row justify-content-between">
            <input id="organizerDisplayInDirectoryYes" checked={displayInDirectory === 'yes'} value="yes" type="radio" name="organizerListed" className={styles.signUpListed} onChange={handleDisplayInDirectoryChange} />
            <label htmlFor="organizerDisplayInDirectoryYes" className={cn(styles.signUpListedLabel, styles.listedLabel)}>
              <span className={styles.listedLabelRound}></span>
              Yes
            </label>
            <input id="organizerDisplayInDirectoryNo" checked={displayInDirectory === 'no'} value="no" type="radio" name="organizerListed" className={styles.signUpListed} onChange={handleDisplayInDirectoryChange} />
            <label htmlFor="organizerDisplayInDirectoryNo" className={cn(styles.signUpListedLabel, styles.listedLabel)}>
              <span className={styles.listedLabelRound}></span>
              No
            </label>
          </div>
        </div>
      </div>
    </div>
  )
}
