import { KlLink, KlLinkProps } from 'components/_shared/KlLink/KlLink'
import { cn } from 'utils'
import { useDropDownTableStyles } from './styles/useDropDownTableStyles'

export type DropDownTableLinkProps = KlLinkProps

export const DropDownTableLink = ({ className, ...otherProps }: DropDownTableLinkProps) => {
  const styles = useDropDownTableStyles()

  return <KlLink {...otherProps} className={cn(styles.tableLink, className)} />
}
