import { ContainerName, useMemberClan } from 'components/_hooks'
import { DropDownTable } from 'components/_shared'
import MemberOnlineAvatar from 'components/_shared/ParticipantAvatar/MemberOnlineAvatar'
import React from 'react'
import { useSelector } from 'react-redux'
import { makeGetUser } from 'store/selectors/user-selectors'
import { ApplicationState } from 'store/types/common'
import { LineUpType } from 'store/types/line-up-types'
import { cn } from 'utils'
import styles from './participants.module.scss'


interface Props {
  lineUp: LineUpType
  canDeleteMember: boolean
  onDelete: (id: number) => void
}
const LineUpRow = ({lineUp, canDeleteMember, onDelete}: Props) => {
  const getUser = React.useMemo(makeGetUser, [])
  const user = useSelector((state: ApplicationState) => getUser(state, lineUp.PlayerId))

  const {isClanLeader} = useMemberClan(lineUp.PlayerId)


  return (
    <>
      <DropDownTable.SecondaryRow key={lineUp.Id}>
        <DropDownTable.SecondaryRowColumn>
          <div className="row align-items-center m-0 li-wrapper">  
            <MemberOnlineAvatar 
              userId={user?.Id}
              parentClassName={null} 
              withLink 
              className={cn('article__participant-picture','article__participant-picture--member')}
              containerName={ContainerName.AvatarsNormal}
            />
            <div className={styles.article__participantUsername}>
              {lineUp.IsSubstitute?user?.Username+" -(Substitute)":user?.Username+" -(Playing)"}
            </div>
            {isClanLeader && (
              <div className="pl-1">
                <img src="https://kombatlink-assets.azureedge.net/img/crown-accent.svg" alt="" />
              </div>
            )}
          </div>
        </DropDownTable.SecondaryRowColumn>
        <DropDownTable.SecondaryRowColumn>
          <div className="row justify-content-sm-end m-0">
            {canDeleteMember && !isClanLeader && (
              <DropDownTable.Button className={styles.delete__button} onClick={() => onDelete(lineUp.Id)} color="white" hoverColor="redHover">              
                Remove
              </DropDownTable.Button>
            )}    
          </div>  
        </DropDownTable.SecondaryRowColumn>    
      </DropDownTable.SecondaryRow>

    </>
  )
}

export default LineUpRow
