import { Dispatch } from 'redux'
import { fetchApi } from 'server/server-utils'
import { AppThunkAction, GetState } from 'store/types/common'
import {
  MatchSubmissionsModel,
  ParticipantSubmissionModel,
  matchSubmissionsLoaded,
  matchSubmissionAdded,
} from 'store/types/match-submissions-types'
import { cancel, OperationTypes, run } from './operations-logic'

interface SubmitModel {
  isWon?: boolean
  place?: number
  evidenceLink: string
  imageIds: number[]
  notes: string
}

export const matchesSumbissionsActionCreators = {
  loadMatchSubmissions: (matchId: number): AppThunkAction => async (dispatch: Dispatch, getState: GetState) => {
    await run(OperationTypes.loadMatchSubmissions, matchId, dispatch, getState, async () => {
      try {
        const matchSubmissions = await fetchApi<MatchSubmissionsModel>(`match/${matchId}/submissions`, 'GET')
        dispatch(matchSubmissionsLoaded(matchSubmissions))
      } catch (error) {
        return false
      }
      return true
    })
  },
  loadTournamentMatchesSubmissions: (tournamentId: number): AppThunkAction => async (dispatch: Dispatch, getState: GetState) => {
    await run(OperationTypes.loadMatchesSubmissions, tournamentId, dispatch, getState, async () => {
      try {
        const matchesSubmissions = await fetchApi<MatchSubmissionsModel>(`tournament/${tournamentId}/submissions`, 'GET')
        dispatch(matchSubmissionsLoaded(matchesSubmissions))
      } catch (error) {
        return false
      }
      return true
    })
  },
  submitResult: (matchId: number, model: SubmitModel): AppThunkAction => async (dispatch: Dispatch, getState: GetState) => {
    await run(OperationTypes.submit, matchId, dispatch, getState, async () => {
      const matchSubmission = await fetchApi<ParticipantSubmissionModel>(`match/${matchId}/submit`, 'POST', { ...model })
      dispatch(matchSubmissionAdded({
        Images: [],
        Submission: matchSubmission,
      }))
      return true
    })
  },
}
