import { useCanManageMatch, useTournament } from 'components/_hooks'
import { TournamentStructureEnum } from 'consts'
import React from 'react'
import { SaveChangesButton } from './Buttons/SaveChangesButton/SaveChangesButton'
import { BracketHeaderItem } from './BracketHeaderItem'
import { useFindSingleFfaMatch } from './utils/useFindSingleFfaMatch'
import { useAllPlacesSelected } from './Buttons/SaveChangesButton/useAllPlacesSelected'

export interface BracketHeaderProps {
  roundsIds: number[]
  tournamentDetailId: number
}

export const BracketHeader = ({ roundsIds, tournamentDetailId }: BracketHeaderProps) => {
  const match = useFindSingleFfaMatch(roundsIds[0])
  const canManageBracket = useCanManageMatch(match?.Id)
  const { tournament } = useTournament(tournamentDetailId)
  const isAllPlacesSelected = useAllPlacesSelected(match?.Id)

  const showSaveButton = canManageBracket && tournament?.TournamentStructureId === TournamentStructureEnum.SingleRoundFfa

  return (
    <div className="eli_hdr eli_hdr--small">
      <div className="eli_hdr_inn">
        {roundsIds.map(id => (
          <BracketHeaderItem key={id} roundId={id} />
        ))}
        {showSaveButton && (
          <div className="eli_hdr_i eli_general-width eli_general-padding">
            <div className="eli_hdr_i_i ml30">
              <SaveChangesButton matchId={match?.Id} disabled={!isAllPlacesSelected} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
