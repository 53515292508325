import React, { ReactNode } from 'react'
import styles from './KlLink.module.scss'
import { cn } from 'utils'
import { ButtonColor, ButtonHoverColor as ButtonHoverColor, ButtonHoverClassByColor, useButtonClassByColor, useFilledButtonClassByColor } from '../Button'
import { Loading } from '../Loading'
import { useKlHistory } from 'components/_hooks/useKlHistory'

export interface KlLinkProps {
  to: string
  disabled?: boolean
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => Promise<void> | void
  className?: string
  children?: ReactNode
  preventDefault?: boolean
  title?: string
  loading?: boolean
}

export const KlLink = ({ disabled, onClick, to, className, children, title, loading }: KlLinkProps) => {
  const history = useKlHistory()

  disabled = disabled || loading
  
  const handleLinkClick = async (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault()

    if (disabled) {
      return
    }

    await handleClick(event)
    if (to !== '#') {
      history.push(to)
    }
  }

  const handleClick = async (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (!disabled && onClick) {
      await onClick(event)
    }
  }

  return (
    <a href={to} onClick={handleLinkClick} className={cn(styles.KlLinkPointer, className)} title={title}>
      {children}
      {loading && <Loading size="small" className="ml-2" />}
    </a>
  )
}

export interface ButtonLinkProps extends KlLinkProps {
  color?: ButtonColor
  filled?: boolean
  hoverColor?: ButtonHoverColor
  low?: boolean
}

export const ButtonLink = ({ onClick, color, to, className, filled, children, disabled, title, hoverColor, loading, preventDefault, low }: ButtonLinkProps) => {
  const buttonColor = useButtonClassByColor(color)
  const filledButtonColor = useFilledButtonClassByColor(color)
  
  return (
    <KlLink
      disabled={disabled}
      onClick={onClick}
      to={to}
      className={cn(
        styles.KlLink,
        disabled && styles.disabled,
        buttonColor,
        !!hoverColor && ButtonHoverClassByColor[hoverColor],
        filled && filledButtonColor, className,
        low && styles.low
      )}
      title={title}
      loading={loading}
      preventDefault={preventDefault}
    >
      {children}
    </KlLink>
  )
}

export const HeaderLink = ({ color, to, children, title }: ButtonLinkProps) => {
  return (
    <ButtonLink to={to} className={`${styles.header__link} tFix`} color={color} filled title={title}>
      {children}
    </ButtonLink>
  )
}

export const HeaderLinkNew = ({ color, to, children, title }: ButtonLinkProps) => {
  return (
    <ButtonLink to={to} className={`${styles.header__link}`} color={color} filled title={title}>
      {children}
    </ButtonLink>
  )
}
