import React from 'react'
import { cn } from 'utils'
import styles from './ChatWindow.module.scss'
import { MemoryRouter, Route, Switch } from 'react-router-dom'
import { AboutChat, CreateGroupChat, Chat, ChatMemberProfile } from '../_pages'
import { History } from 'history'
import ChatList from '../_pages/ChatList/ChatList'

interface ChatWindowProps {
  externalHistory: History
}

export const ChatExternalHistoryContext = React.createContext<History>(undefined)

export const ChatWindow = ({ externalHistory }: ChatWindowProps) => {
  return (
    <ChatExternalHistoryContext.Provider value={externalHistory}>
      <aside className={cn(styles.chatContainer)}>
        <MemoryRouter>
          <Switch>
            <Route exact path="/" component={ChatList} />
            <Route exact path="/chat" component={Chat} />
            <Route exact path="/group/create" component={CreateGroupChat} />
            <Route exact path="/chat/about" component={AboutChat} />
            <Route exact path="/chat/members/:id" component={ChatMemberProfile} /> 
          </Switch>
        </MemoryRouter>
      </aside>
    </ChatExternalHistoryContext.Provider>
  )
}
