import { useAlertModal } from './useComponents'


export const useTryApi = <Args extends unknown[]>(asyncOperation: (...operationParameters: Args) => Promise<unknown>): [(...operationParameters: Args) => Promise<void>, JSX.Element] => {
  const [errorModal, openErrorModal] = useAlertModal()

  const func = async (...operationParameters: Args) => {
    try {
      await asyncOperation(...operationParameters)
    } catch (error) {
      let message = (error as Error).message
      message = message === 'Failed to fetch' ? 'An error has occurred. Please reload the page.' : message

      openErrorModal(message)

      throw error
    }
  }

  return [func, errorModal]
}
