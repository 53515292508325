import { MatchParticipantStatus } from 'consts/MatchParticipantStatus'
import { makeActionCreator, ReduxAction } from './common'
import { ParticipantsModel } from './participants-types'

export interface MatchParticipantModel {
  Id: number
  MatchId: number
  TournamentParticipantId: number
  ParticipantStatus: MatchParticipantStatus
  PlayerId: number // obsolete
  ClanId: number // obsolete
  Place?: number
  StreamingLink?: string
}

export interface MatchParticipantsModel extends ParticipantsModel {
  MatchParticipants: MatchParticipantModel[]
}

export interface State {
  idsByMatchId: {
    [key: number]: number[]
  }
  byId: {
    [key: number]: MatchParticipantModel
  }
}

export const MATCH_PARTICIPANTS_LOADED = 'MATCH_PARTICIPANTS_LOADED'
export type MatchParticipantsLoadedAction = ReduxAction<typeof MATCH_PARTICIPANTS_LOADED, MatchParticipantsModel>
export const matchParticipantsLoaded = makeActionCreator<MatchParticipantsModel>(MATCH_PARTICIPANTS_LOADED)

export interface UpdateMatchParticipantPayload {
  matchParticipant: MatchParticipantModel
}
export const UPDATE_MATCH_PARTICIPANT = 'UPDATE_MATCH_PARTICIPANT'
export type UpdateMatchParticipantAction = ReduxAction<typeof UPDATE_MATCH_PARTICIPANT, UpdateMatchParticipantPayload>
export const updateMatchParticipant = makeActionCreator<UpdateMatchParticipantPayload>(UPDATE_MATCH_PARTICIPANT)

export type KnownAction = UpdateMatchParticipantAction | MatchParticipantsLoadedAction
