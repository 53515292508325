import { useEffect, useState } from 'react'

export const useHighlight = (targetId: number): [highlightedId: number, removeHighlighted: () => void] => {
  const [highlightedId, setHighlightedId] = useState<number>()

  const removeHighlighted = () => {
    setHighlightedId(undefined)
  }

  useEffect(() => {
    setHighlightedId(targetId)
  }, [targetId])

  return [highlightedId, removeHighlighted]
}
