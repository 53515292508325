import React, { useEffect, useMemo, useState } from 'react'
import { DropDownTable, LoadingBox } from 'components/_shared'
import styles from './Events.module.scss'
import { makeGetTournament } from 'store/selectors/tournaments-selectors'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from 'store/types/common'
import { useDialog, useFormatDateTime, useOpponents } from 'components/_hooks'
import { getIsRegistrationClosed, TournamentType } from 'store/types/tournaments-types'
import { EventParticipantRow } from './EventParticipantRow'
import { AlertModal } from 'components/_modals'
import { getPendingRoundsIds, makeGetRound, makeGetRoundsIdsByTournamentDetailId } from 'store/selectors/rounds-selectors'
import { getBattlesPageByRoundStatus } from 'store/types/rounds-types'
import { intersect } from 'utils'
import { getActiveMatchesIds, getCheckInMatchesIds, getFinishedMatchesIds, getIsActiveMatchesLoading, getIsCheckInMatchesLoading, getIsFinishedMatchesLoading, getIsPendingMatchesLoading, getPendingMatchesIds, makeGetMatch, makeGetMatchesIdsByTournamentDetailId } from 'store/selectors/match-selectors'
import { getBattlesPageByMatchStatus } from 'store/types/match-types'
import { loadMatchParticipants } from 'store/logic/match-participant-logic'
import { makeGetIsMatchParticipantsLoading } from 'store/selectors/match-participant-selectors'

export interface EventRowProps {
  tournamentDetailId: number
  openCancelParticipationModal: (tournament: TournamentType) => void
}

export const EventRow = ({ tournamentDetailId, openCancelParticipationModal }: EventRowProps) => {
  const dispatch = useDispatch()

  const getTournament = useMemo(makeGetTournament, [])
  const getMatchesIdsByTournamentDetailId = useMemo(makeGetMatchesIdsByTournamentDetailId, [])
  const getRoundsIdsByTournamentDetailId = useMemo(makeGetRoundsIdsByTournamentDetailId, [])
  const getMatch = useMemo(makeGetMatch, [])
  const getRound = useMemo(makeGetRound, [])
  const getPendingRound = useMemo(makeGetRound, [])
  const getIsMatchParticipantLoading = useMemo(makeGetIsMatchParticipantsLoading, [])

  //#region vars
  const tournament = useSelector((state: ApplicationState) => getTournament(state, tournamentDetailId))
  const isPendingMatchesLoading = useSelector(getIsPendingMatchesLoading)
  const isCheckInMatchesLoading = useSelector(getIsCheckInMatchesLoading)
  const isActiveMatchesLoading = useSelector(getIsActiveMatchesLoading)
  const isFinishedMatchesLoading = useSelector(getIsFinishedMatchesLoading)
  const pendingRoundsIds = useSelector(getPendingRoundsIds)
  const pendingMatchesIds = useSelector(getPendingMatchesIds)
  const checkInMatchesIds = useSelector(getCheckInMatchesIds)
  const activeMatchesIds = useSelector(getActiveMatchesIds)
  const finishedMatchesIds = useSelector(getFinishedMatchesIds)
  const matchesIdsByTournamentDetailId = useSelector((state: ApplicationState) => getMatchesIdsByTournamentDetailId(state, { tournamentDetailId }))
  const roundsIdsByTournamentDetailId = useSelector((state: ApplicationState) => getRoundsIdsByTournamentDetailId(state, { tournamentDetailId }))

  const [opened, setOpened] = useState(false)

  const startDate = useFormatDateTime(tournamentDetailId, tournament.StartDate, 'redux')

  const isRegistraionClosed = useMemo(() => {
    return getIsRegistrationClosed(tournament)
  }, [tournament])

  const isMatchesLoading = useMemo(() => {
    return isPendingMatchesLoading || isCheckInMatchesLoading || isActiveMatchesLoading || isFinishedMatchesLoading
  }, [isActiveMatchesLoading, isCheckInMatchesLoading, isFinishedMatchesLoading, isPendingMatchesLoading])

  const matchId = useMemo(() => {
    const tournamentPendingMatchesId = intersect(pendingMatchesIds, matchesIdsByTournamentDetailId)
    if (tournamentPendingMatchesId.length > 0) {
      return tournamentPendingMatchesId[0]
    }

    const tournamentCheckinMatchesId = intersect(checkInMatchesIds, matchesIdsByTournamentDetailId)
    if (tournamentCheckinMatchesId.length > 0) {
      return tournamentCheckinMatchesId[0]
    }

    const tournamentActiveMatchesId = intersect(activeMatchesIds, matchesIdsByTournamentDetailId)
    if (tournamentActiveMatchesId.length > 0) {
      return tournamentActiveMatchesId[0]
    }

    const tournamentFinishedMatchesIds = intersect(finishedMatchesIds, matchesIdsByTournamentDetailId)
    if (tournamentFinishedMatchesIds.length > 0) {
      return tournamentFinishedMatchesIds[0]
    }
  }, [activeMatchesIds, checkInMatchesIds, finishedMatchesIds, matchesIdsByTournamentDetailId, pendingMatchesIds])

  const match = useSelector((state: ApplicationState) => getMatch(state, matchId))

  const pendingRoundId = useMemo(() => {
    const tournamentPendingRoundsIds = intersect(pendingRoundsIds, roundsIdsByTournamentDetailId)
    if (tournamentPendingRoundsIds.length > 0) {
      return tournamentPendingRoundsIds[0]
    }
  }, [pendingRoundsIds, roundsIdsByTournamentDetailId])

  const pendingRound = useSelector((state: ApplicationState) => getPendingRound(state, pendingRoundId))
  const round = useSelector((state: ApplicationState) => getRound(state, match?.RoundId))

  const battleUrl = useMemo(() => {
    if (pendingRound) {
      return getBattlesPageByRoundStatus(pendingRound)
    }

    if (match && round) {
      return getBattlesPageByMatchStatus(match, round)
    }
    return pendingRound ? getBattlesPageByRoundStatus(pendingRound) : ''
  }, [match, pendingRound, round])

  const matchParticipantsLoading = useSelector((state: ApplicationState) => getIsMatchParticipantLoading(state, matchId))

  const { opponents } = useOpponents(matchId)
  //#endregion

  //#region handlers
  const toggleOpened = () => {
    setOpened(!opened)
  }

  const handleCancelClick = () => {
    if (!isRegistraionClosed) {
      openCancelParticipationModal(tournament)
    }
  }

  const handleGoTyMyBattleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (!battleUrl) {
      event.preventDefault()
      openNotFoundModal()
    }
  }
  //#endregion

  const doNotLoadParticipants = useMemo(() => {
    if (!match) {
      return true
    }
    if (pendingRound) {
      if (pendingRound.Id !== match.RoundId) {
        return true
      }
    }
    return false
  }, [match, pendingRound])

  //#region  effects
  useEffect(() => {
    if (doNotLoadParticipants) {
      return
    }
    if (match && opened) {
      dispatch(loadMatchParticipants(match.Id))
    }
  }, [dispatch, doNotLoadParticipants, match, opened, pendingRoundId])
  //#endregion

  const [notFoundModal, openNotFoundModal] = useDialog(closeDialog => (
    <AlertModal
      onClose={closeDialog}
      text="Battle not found"
      buttonText="OK"
    />
  ))

  return (
    <>
      <DropDownTable.Row>
        <DropDownTable.MainRow opened={opened}>
          <DropDownTable.RowColumn className={styles.tableColumnTournament}>
            <DropDownTable.Link to={`/tournament-detail/${tournamentDetailId}`}>
              {tournament.Name}
            </DropDownTable.Link>
          </DropDownTable.RowColumn>
          <DropDownTable.RowColumn className={styles.tableColumnDate}>{startDate.date}</DropDownTable.RowColumn>
          <DropDownTable.RowColumn className={styles.tableColumnTime}>{startDate.time}</DropDownTable.RowColumn>
          <DropDownTable.RowColumn className={styles.tableColumnEventId}>#T{tournament.TournamentDetailId}</DropDownTable.RowColumn>
          <DropDownTable.Column className={styles.tableColumnButtons}>
            <div className="row m-0 justify-content-md-end">
              {isRegistraionClosed ? (
                <DropDownTable.ButtonLink
                  to={`/tournament-bracket/${tournamentDetailId}`}
                  color="grey"
                  filled
                  className={styles.tableButton}
                  preventDefault
                >
                  Play schedule
                </DropDownTable.ButtonLink>

              ): (
                <DropDownTable.ButtonLink
                  to="#"
                  color="red"
                  filled
                  className={styles.tableButton}
                  disabled={isRegistraionClosed}
                  onClick={handleCancelClick}
                  preventDefault
                >
                  Cancel
                </DropDownTable.ButtonLink>
              )}              
              <DropDownTable.ButtonLink
                to={battleUrl || '#'}
                color="grey"
                hoverColor="greenHover"
                filled
                className={styles.tableButton}
                loading={isMatchesLoading}
                onClick={handleGoTyMyBattleClick}
              >
                Go to my battle
              </DropDownTable.ButtonLink>
              <DropDownTable.OpenButton onClick={toggleOpened} opened={opened} className={styles.tableOpen} />
            </div>
          </DropDownTable.Column>
        </DropDownTable.MainRow>
        <DropDownTable.SecondaryRowContainer opened={opened} className={styles.tableSecondaryRowContainer}>
          <LoadingBox size="average" loading={matchParticipantsLoading}>
            {opponents.length > 0
              ? (
                <>
                  {opponents.map(x => <EventParticipantRow key={x.Id} tournamentParticipantId={x.TournamentParticipantId} />)}
                </>
              )
              : (
                <>
                  <span style={{ color: 'white' }}>No opponents</span>
                </>
              )
            }
          </LoadingBox>
        </DropDownTable.SecondaryRowContainer>
      </DropDownTable.Row>
      {notFoundModal}
    </>
  )
}
