import { useClan, useAlertModal } from 'components/_hooks'
import { ProfileCard } from 'components/_shared/ProfileCard/ProfileCard'
import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAcceptInvitation, useDeclineInvitation } from 'store/logic/clan-invitation-logic'
import { loadClanMembers } from 'store/logic/pages/clan-page-logic'
import { makeGetClanInvitation, makeGetIsUpdateInvitationLoading } from 'store/selectors/clan-invitations-selectors'
import { getMembersPage } from 'store/selectors/pages/clan-page-selectors'
import { getClanProfileUrl } from 'store/types/clan-types'
import { ApplicationState } from 'store/types/common'

interface ClanInvitationProps {
  id: number
}

export const ClanInvitation = ({ id }: ClanInvitationProps) => {
  const dispatch = useDispatch()
  const getClanInvitation = useMemo(makeGetClanInvitation, [])
  const getIsUpdateInvitationLoading = useMemo(makeGetIsUpdateInvitationLoading, [])
  const clanInvitation = useSelector((state: ApplicationState) => getClanInvitation(state, id))
  const isUpdateInvitationLoading = useSelector((state: ApplicationState) => getIsUpdateInvitationLoading(state, clanInvitation.Id))
  const { clan } = useClan(clanInvitation.ClanId)
  const membersPage = useSelector(getMembersPage)

  const acceptInvite = useAcceptInvitation(clanInvitation.UserId, clanInvitation.Id)
  const declineInvite = useDeclineInvitation(clanInvitation.UserId, clanInvitation.Id)

  const [alertModal, openAlertModal] = useAlertModal()

  const handleDecline = async () => {
    try {
      await declineInvite(clan.Id)
    }
    catch (error) {
      openAlertModal((error as Error).message)
    }
  }

  const handleAccept = async () => {
    try {
      await acceptInvite(clan.Id)
      dispatch(loadClanMembers(clan.Id, {
        page: membersPage,
        pageSize: 12,
      }))
    }
    catch (error) {
      openAlertModal((error as Error).message)
    }
  }

  return (
    <>
      <ProfileCard 
        className="mt-2"
        name={clan.Name}
        label={`${clan.MembersCount} Members`}
        link={getClanProfileUrl(clan.Id)}
        imageId={clan.AvatarId}
        withButtons
        onAccept={handleAccept}
        onDecline={handleDecline}
        disabled={isUpdateInvitationLoading}
      />
      {alertModal}
    </>
  )
}
