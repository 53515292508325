import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'


export const useIsAdminRoute = () => {
  const location = useLocation()
  const isAdmin: boolean = useMemo<boolean>(() => {
    if (location.pathname.includes('/admin')) {
      if (location.pathname === '/admin/login') {
        return false
      }
      return true
    }
    return false
  }, [location.pathname])

  return isAdmin
}
