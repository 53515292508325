import { Dispatch } from 'redux'
import { fetchApi } from 'server/server-utils'
import { makeGetIsRoundsRequested } from 'store/selectors/rounds-selectors'
import { AppThunkAction, GetState } from 'store/types/common'
import { roundsLoaded, RoundType } from 'store/types/rounds-types'
import { cancel, OperationTypes, run } from './operations-logic'
 
const getIsRoundsRequested = makeGetIsRoundsRequested()

const loadRounds = async (tournamentDetailId: number, dispatch: Dispatch, getState: GetState) => {
  if (getIsRoundsRequested(getState(), tournamentDetailId)) {
    return
  }
  await run(OperationTypes.loadRounds, tournamentDetailId, dispatch, getState, async () => {
    const rounds = await fetchApi<RoundType[]>(`tournament/${tournamentDetailId}/rounds`, 'GET')
    dispatch(roundsLoaded({ Rounds: rounds }))
    return true
  })
}

export const reLoadRounds = async (tournamentDetailId: number, dispatch: Dispatch, getState: GetState) => {
  cancel(OperationTypes.loadRounds, tournamentDetailId, dispatch, getState)
  await loadRounds(tournamentDetailId, dispatch, getState)
}

export const roundsActionCreators = {
  loadRounds: (tournamentDetailId: number): AppThunkAction => async (dispatch: Dispatch, getState: GetState) => {
    await loadRounds(tournamentDetailId, dispatch, getState)
  },
  reLoadRounds: (tournamentDetailId: number): AppThunkAction => async (dispatch: Dispatch, getState: GetState) => {
    await reLoadRounds(tournamentDetailId, dispatch, getState)
  }, 
}
