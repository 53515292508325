import React from 'react'
import { cn } from 'utils'
import styles from './Structures.module.scss'

export const SingleRoundFFAStructure = () => {
  return (
    <span className={cn('article__label-structure', styles.structure)}>
      <span className="row m-0 align-items-center">
        <span className="col-auto p-0">
          <span className={cn(styles.container, styles.width_2)}>
            <span className="row">
              <span className="col-12">
                <span className="row m-0 justify-content-between">
                  <span className={cn(styles.player, styles.playerBlue)}></span>
                  <span className={cn(styles.director, styles.directorBlue)}></span>
                </span>
              </span>
              <span className="col-12">
                <span className="row m-0 justify-content-between">
                  <span className={cn(styles.player, styles.playerGreen)}></span>
                  <span className={cn(styles.director, styles.directorGreen)}></span>
                </span>
              </span>
              <span className="col-12">
                <span className="row m-0 justify-content-between">
                  <span className={cn(styles.player, styles.playerPurple)}></span>
                  <span className={cn(styles.director, styles.directorPurple)}></span>
                </span>
              </span>
              <span className="col-12">
                <span className="row m-0 justify-content-between">
                  <span className={styles.player}></span>
                  <span className={styles.director}></span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </span>
    </span>
  )
}
