import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { loadPlayerProfileByUserId, loadTopFriends } from 'store/logic/user-logic'
import { LeftPlayerMenu } from 'components/PlayerPage/_shared/LeftPlayerMenu'
import { loadMyReview } from 'store/logic/review-logic'
import { ReviewsList } from './_shared/RewiesList'
import { PublicUserType } from 'store/types/user-types'
import { loadFriendRequests } from 'store/logic/friend-request-logic'

export interface PlayerReviewProps {
  user: PublicUserType
  loggedInuser: PublicUserType
}

export const PlayerReview = ({ loggedInuser, user }: PlayerReviewProps) => {
  const dispatch = useDispatch()
  
  const isPublicView = useMemo(() => {
    if (!loggedInuser || !user) {
      return true
    }

    return loggedInuser.Id !== user.Id
  }, [loggedInuser, user])
  
  useEffect(() => {
    if (user?.Id) {
      dispatch(loadTopFriends(user?.Id))
      dispatch(loadPlayerProfileByUserId(user?.Id))
      dispatch(loadMyReview(user?.Id))
    }
  }, [dispatch, user])
  
  useEffect(() => {
    if (isPublicView && loggedInuser) {
      dispatch(loadTopFriends(loggedInuser.Id))
      dispatch(loadFriendRequests())
    }
  }, [dispatch, loggedInuser, isPublicView])

  return (
    <>
      <div className="d-flex mt-5">
        <LeftPlayerMenu
          isPublicView={isPublicView}
          userId={user?.Id}
          hideAllReviewLink
        />
        <ReviewsList user={user} loggedInuser={loggedInuser}/>
      </div>
    </>
  )
}
