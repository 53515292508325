import { ContainerName, useUser } from 'components/_hooks'
import { Button, ButtonLink } from 'components/_shared'
import React from 'react'
import { useOpenUserChat } from 'store/chat/chat/chat-hooks'
import { getOrganizerProfileUrl } from 'store/types/user-types'
import { cn } from 'utils'
import { Column, MemberAvatarColumn, MemberNameColumn } from '../Columns'
import styles from '../MemberCard.module.scss'

interface OrganizerCardProps {
  userId: number
}

export const OrganizerCard = ({ userId }: OrganizerCardProps) => {
  const { user } = useUser(userId)
  const openUserChat = useOpenUserChat()

  const handleSendMessageClick = () => {
    openUserChat(userId)
  }

  return (
    <div className={cn('col-auto', styles.memberContainer)}>
      <div className={styles.member}>
        <div className="row">
          <MemberAvatarColumn containerName={ContainerName.AvatarsNormal} imageId={user.AvatarId} />
          <MemberNameColumn name={user.Organizationname} id={userId} type="organizer" />
          <Column className={styles.memberFirstColumn} label="Members" value={user.MembersCount.toString()} />
          <div className={cn('col-12', styles.memberButtons)}>
            <div className="row justify-content-center flex-nowrap">
              <ButtonLink to={getOrganizerProfileUrl(userId)} color="grey" filled hoverColor="greenHover" className={cn(styles.memberButton, styles.left)}>
                View profile
              </ButtonLink>
              <Button onClick={handleSendMessageClick} color="grey" filled className={styles.memberButton}>
                Send message
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
