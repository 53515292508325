import { ContainerName, useGetImageSize } from 'components/_hooks'
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadImage } from 'store/logic/image-logic'
import { makeGetImage } from 'store/selectors/image-selectors'
import { ApplicationState } from 'store/types/common'
import { MediaType } from 'store/types/image-types'
import { Img, Video } from '../Img'

interface TournamentCoverProps {
  coverId: number
  tournamentName?: string
  containerName?:ContainerName
}

export const TournamentCover = ({ coverId, tournamentName,containerName }: TournamentCoverProps) => {
  const dispatch = useDispatch()
  const getCover = useMemo(makeGetImage, [])
  const cover = useSelector((state: ApplicationState) => getCover(state, { imageId: coverId }))
  const getContainerImageSize = useGetImageSize(containerName)
  useEffect(() => {
    if (coverId) {
      dispatch(loadImage(coverId))
    }
  }, [coverId, dispatch])

  const handleVideoMouseOver = (e: React.MouseEvent<HTMLVideoElement, MouseEvent>) => {
    void e.currentTarget.play()
  }

  const handleVideoMouseLeave = (e: React.MouseEvent<HTMLVideoElement, MouseEvent>) => {
    void e.currentTarget.pause()
  }

  return (
    <div className="row tournament--card__row" style={{ height: '200px', overflow: 'hidden' }}>
      <div className="col-12 p-0">
        {cover?.MediaType === MediaType.Video && (
          <Video
            imageId={coverId}
            className="background-video game-background-video"
            muted
            loop
            style={{ zIndex: 1 }}
            onMouseOver={handleVideoMouseOver}
            onMouseLeave={handleVideoMouseLeave}
            placeholder="tournament"
          />
        )}
        {cover?.MediaType === MediaType.Image && (
          <Img parentContainerSize={getContainerImageSize}
            alt={tournamentName}
            imageId={coverId}
            className="game__picture tournament--game__picture"
            placeholder="tournament"
          />
        )}
        {!cover && (
          <Img
            src="https://kombatlink-assets.azureedge.net/img/kombatLinkTournamentLogo.jpg"
            className="game__picture tournament--game__picture"
          />
        )}
      </div>
    </div>
  )
}
