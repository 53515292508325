export enum VenueTypeEnum {
  Online = 1,
  Live = 2,
}

export const VenueType = {
  [VenueTypeEnum.Online]: 'Online Event',
  [VenueTypeEnum.Live]: 'Live Event',
}

export const VenueTypeEnumByName: {[key: string]: VenueTypeEnum} = {
  'Online Event': VenueTypeEnum.Online,
  'Live Event': VenueTypeEnum.Live,
}

export const VenueTypeOptions = [
  ...Object.keys(VenueType).map(key => ({
    id: +key,
    name: VenueType[+key as VenueTypeEnum],
  })),
]
