import { Reducer } from 'redux'
import { calcById, getNewObjectWithArray } from 'utils'
import { State, KnownAction, UPDATE_MATCH_PARTICIPANT, MATCH_PARTICIPANTS_LOADED, MatchParticipantModel } from '../types/match-participant-types'

const initialMatchParticipantState: State = {
  idsByMatchId: {},
  byId: {},
}

const calcIdsByMatchId = (state: State, matchParticipants: MatchParticipantModel[]) => {
  return {
    ...state.idsByMatchId,
    ...getNewObjectWithArray(state.idsByMatchId, matchParticipants, x => x.MatchId, x => x.Id),
  }
}

const calcState = (state: State, matchParticipants: MatchParticipantModel[]): State => {
  return {
    ...state,
    byId: calcById(state, matchParticipants),
    idsByMatchId: calcIdsByMatchId(state, matchParticipants),
  }
}

const reducer: Reducer<State, KnownAction> = (state: State | undefined = initialMatchParticipantState, action: KnownAction) => {
  switch (action.type) {
    case UPDATE_MATCH_PARTICIPANT:
      return calcState(state, [{...action.payload.matchParticipant}])
    case MATCH_PARTICIPANTS_LOADED:
      return calcState(state, action.payload.MatchParticipants)
    default:
      return state
  }
}

export default reducer
