import { Button, ButtonColor } from 'components/_shared'
import { NotificationButtonType } from 'consts'
import React, { useState } from 'react'
import { NotificationModel } from 'store/types/notification-types'
import { cn } from 'utils'
import { notificationLinkButtonColorByType } from './NotificationButtonLink'
import styles from './NotificationRow.module.scss'

interface Props {
  notification: NotificationModel
  onButtonClickAsync: (notification: NotificationModel) => Promise<void>
  className?: string
}

const notificationButtonColorByType: { [key: number]: ButtonColor } = {
  ...notificationLinkButtonColorByType,
  [NotificationButtonType.Neutral]: 'blue',
}

const NotificationButton = ({
  notification, 
  onButtonClickAsync,
  className = cn(styles.full, styles.button),
}: Props) => {

  const [isProcessing, setIsProcessing] = useState(false)

  const handleButtonClick = async () => {   
    setIsProcessing(true)
    await onButtonClickAsync(notification)  
    setIsProcessing(false)
  }

  return (
    <Button
      onClick={handleButtonClick}
      className={className}
      filled
      color={notificationButtonColorByType[notification.Button.Type]}
      loading={isProcessing}
      disabled={isProcessing}
    >
      {notification.Button.Text}
    </Button>
  )
}

export default NotificationButton
