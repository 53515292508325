import * as React from 'react'

class CalendarSVG extends React.Component {
  render() {
    return (
      <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.61951 9.93162C5.61951 9.70612 5.45796 9.52344 5.25864 9.52344H3.99934C3.80025 9.52344 3.63867 9.70612 3.63867 9.93162V11.3573C3.63867 11.583 3.80025 11.7658 3.99934 11.7658H5.25864C5.45796 11.7658 5.61951 11.583 5.61951 11.3573V9.93162Z"
          fill="#49C3FD"
        />
        <path
          d="M8.76385 9.93162C8.76385 9.70612 8.60227 9.52344 8.40334 9.52344H7.14388C6.94478 9.52344 6.7832 9.70612 6.7832 9.93162V11.3573C6.7832 11.583 6.94478 11.7658 7.14388 11.7658H8.40334C8.60227 11.7658 8.76385 11.583 8.76385 11.3573V9.93162Z"
          fill="#49C3FD"
        />
        <path
          d="M11.9105 9.93162C11.9105 9.70612 11.7489 9.52344 11.5499 9.52344H10.2906C10.0912 9.52344 9.92969 9.70612 9.92969 9.93162V11.3573C9.92969 11.583 10.0912 11.7658 10.2906 11.7658H11.5499C11.7489 11.7658 11.9105 11.583 11.9105 11.3573V9.93162Z"
          fill="#49C3FD"
        />
        <path
          d="M5.61951 13.4963C5.61951 13.2704 5.45796 13.0879 5.25864 13.0879H3.99934C3.80025 13.0879 3.63867 13.2704 3.63867 13.4963V14.9217C3.63867 15.1473 3.80025 15.33 3.99934 15.33H5.25864C5.45796 15.33 5.61951 15.1473 5.61951 14.9217V13.4963Z"
          fill="#49C3FD"
        />
        <path
          d="M8.76385 13.4963C8.76385 13.2704 8.60227 13.0879 8.40334 13.0879H7.14388C6.94478 13.0879 6.7832 13.2704 6.7832 13.4963V14.9217C6.7832 15.1473 6.94478 15.33 7.14388 15.33H8.40334C8.60227 15.33 8.76385 15.1473 8.76385 14.9217V13.4963Z"
          fill="#49C3FD"
        />
        <path
          d="M11.9105 13.4963C11.9105 13.2704 11.7489 13.0879 11.55 13.0879H10.2906C10.0912 13.0879 9.92969 13.2704 9.92969 13.4963V14.9217C9.92969 15.1473 10.0912 15.33 10.2906 15.33H11.55C11.7489 15.33 11.9105 15.1473 11.9105 14.9217V13.4963Z"
          fill="#49C3FD"
        />
        <path
          d="M14.1626 2.00293V4.18047C14.1626 5.1647 13.4574 5.95783 12.5882 5.95783H11.5951C10.7259 5.95783 10.0113 5.1647 10.0113 4.18047V1.99512H5.535V4.18047C5.535 5.1647 4.82049 5.95783 3.95141 5.95783H2.95808C2.08897 5.95783 1.38381 5.1647 1.38381 4.18047V2.00293C0.624192 2.02886 0 2.74053 0 3.61527V16.3767C0 17.2679 0.637969 17.9998 1.42507 17.9998H14.1213C14.9072 17.9998 15.5463 17.2663 15.5463 16.3767V3.61527C15.5463 2.74053 14.9222 2.02886 14.1626 2.00293ZM13.7013 15.582C13.7013 15.9671 13.4255 16.2796 13.0852 16.2796H2.43401C2.09372 16.2796 1.81792 15.9671 1.81792 15.582V8.99026C1.81792 8.60498 2.09369 8.29253 2.43401 8.29253H13.0852C13.4255 8.29253 13.7013 8.60498 13.7013 8.99026L13.7013 15.582Z"
          fill="#49C3FD"
        />
        <path
          d="M2.9579 4.79187H3.94026C4.23843 4.79187 4.4802 4.51855 4.4802 4.18094V0.611148C4.4802 0.27351 4.23843 0 3.94026 0H2.9579C2.6597 0 2.41797 0.27351 2.41797 0.611148V4.18094C2.41797 4.51855 2.6597 4.79187 2.9579 4.79187Z"
          fill="#49C3FD"
        />
        <path
          d="M11.5809 4.79187H12.5633C12.8613 4.79187 13.103 4.51855 13.103 4.18094V0.611148C13.103 0.27351 12.8613 0 12.5633 0H11.5809C11.2828 0 11.041 0.27351 11.041 0.611148V4.18094C11.041 4.51855 11.2828 4.79187 11.5809 4.79187Z"
          fill="#49C3FD"
        />
      </svg>
    )
  }
}

export default CalendarSVG
