import { useAppUser, useIntMatchParam } from 'components/_hooks'
import { Button } from 'components/_shared'
import { ChatMemberRole, ChatMemberStatus } from 'consts'
import React from 'react'
import { useDispatch } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { useChatMember, useUserChatMember } from 'store/chat/chat-member/chat-member-hooks'
import { blockMember, unblockMember } from 'store/chat/chat-member/chat-member-logic'
import { useChatById } from 'store/chat/chat/chat-hooks'
import { ChatContainer, ChatHeader, ChatHr, ChatMember, ChatSubheader, ChatTransparentLabel } from '../../_shared'

import styles from './ChatMemberProfile.module.scss'


interface ChatMemberRouteProps {
  id: string
}

type OwnProps = RouteComponentProps<ChatMemberRouteProps>

export const ChatMemberProfile = ({match}: OwnProps) => {

  const chatMemberId = useIntMatchParam(match, m => m.id)
  const chatMember = useChatMember(chatMemberId)

  const isBlocked = chatMember?.Status == ChatMemberStatus.Blocked

  const chat = useChatById(chatMember.ChatId)

  const appUser = useAppUser()

  const loggedChatMember = useUserChatMember(chat.Id, appUser?.Id)
  const isCreator = loggedChatMember?.Role == ChatMemberRole.Creator

  const canBlock = isCreator && chatMember.UserId != appUser?.Id

  const dispatch = useDispatch()

  const handleBlockClick = () => {
    if (chatMember) {
      if (isBlocked) {        
        dispatch(unblockMember(chatMember.Id))
      } else {
        dispatch(blockMember(chatMember.Id))
      }
    }
  }


  return (
    <>
      <ChatHeader>
        <ChatHeader.BackButton />
        <ChatHeader.Container>Chat member profile</ChatHeader.Container>
      </ChatHeader>
      <ChatContainer>
        <div className="container-fluid">
          <ChatTransparentLabel>Chat Name</ChatTransparentLabel> 
          <ChatSubheader className="m-b-0">{chat.Name}</ChatSubheader>
          <ChatHr />
        </div>
        <ChatMember chatMemberId={chatMember.Id} /> 
        <div className="d-flex m-2">
          {canBlock ? (
            <Button onClick={handleBlockClick} color={isBlocked ? 'green' : 'red'} className={styles.blockUserButton}>
              {isBlocked ? 'Unblock user' : 'Block user'}
            </Button>    
          ) : (
            <>
            </>
          )}

        </div>      
      </ChatContainer>
    </>
  )
}
