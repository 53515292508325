import { HorizontalScroll } from 'components/_shared'
import { ParticipantsResults } from 'components/_shared/MatchResultModal/MatchResultModal'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { makeGetMatchLinks } from 'store/selectors/match-links-selectors'
import { makeGetMatchParticipantsByMatchId } from 'store/selectors/match-participant-selectors'
import { makeGetMatch } from 'store/selectors/match-selectors'
import { makeGetParticipantsByTournamentDetailId } from 'store/selectors/participant-selectors'
import { ApplicationState } from 'store/types/common'
import { MatchLinkStatusEnum } from 'store/types/match-link-types'
import { MatchParticipantModel } from 'store/types/match-participant-types'
import { cn } from 'utils'
import MovieCardDetail, { ParticipantMovieCard } from './MovieCardDetail'
import styles from './TournamentBracket.module.scss'

interface OwnProps {
  matchId: number
  openMatchResultModal: (participantResults: ParticipantsResults) => void
  openPlayerModal: (videoUrl: string) => void
  filterName: string
}


const MovieCard = ({ matchId, openMatchResultModal, openPlayerModal, filterName }: OwnProps) => {

  const getMatch = React.useMemo(makeGetMatch, [])
  const match = useSelector((state: ApplicationState) => getMatch(state, matchId))

  const getMatchParticipants = React.useMemo(makeGetMatchParticipantsByMatchId, [])
  const matchParticipants = useSelector((state: ApplicationState) => getMatchParticipants(state, matchId))

  const getMathLinks = React.useMemo(makeGetMatchLinks, [])
  const links = useSelector((state: ApplicationState) => getMathLinks(state, matchId))
  const approvedLinks = links.filter(l => l.Status == MatchLinkStatusEnum.Approved)

  const getParticipants = React.useMemo(makeGetParticipantsByTournamentDetailId, [])
  const participants = useSelector((state: ApplicationState) => getParticipants(state, match.TournamentDetailId))

  const getParticipantMovideCard = React.useCallback((matchParticipant: MatchParticipantModel): ParticipantMovieCard => {
    if (!matchParticipant) {
      return null
    }
    const participant = participants.find(p => matchParticipant.TournamentParticipantId == p.Id)
    if (!participant) {
      return null
    }

    const matchLink = approvedLinks.find(ms => ms.TournamentParticipantId == participant.Id)

    const name = participant.Player?.Username || participant.Clan?.Name

    return {
      participant,
      name: name,
      screenshot: '',
      videoUrl: matchLink?.Link,
      isWin: matchParticipant.Place == 1,
      isFiltered: filterName && name && name.toLowerCase().includes(filterName.toLowerCase()),
    }
  }, [filterName, approvedLinks, participants])

  const winnerCard = useMemo<ParticipantMovieCard>(() => {
    if (!matchParticipants || matchParticipants.length == 0) {
      return {
        isWin: false,
        name: '',
        participant: null,
        screenshot: '',
        videoUrl: '',
        isFiltered: false,
      }
    }
    const winnerMatchParticipant = matchParticipants.filter(mp => !!mp.Place).sort((a,b) => a.Place - b.Place)[0]

    if (winnerMatchParticipant) {
      return getParticipantMovideCard(winnerMatchParticipant)
    }

    return getParticipantMovideCard(matchParticipants[0])
  }, [getParticipantMovideCard, matchParticipants])

 
  const cards = useMemo(() => {
    const matchParticipantCards = matchParticipants.filter(mp => {
      
      const participant = participants.find(p => mp.TournamentParticipantId == p.Id)
      if (!participant) {
        return false
      }
      const matchLink = approvedLinks.find(ms => ms.TournamentParticipantId == participant.Id)
      return !!matchLink?.Link
    }).map(mp => getParticipantMovideCard(mp))
    if (matchParticipantCards && matchParticipantCards.length > 0) {
      return matchParticipantCards
    }
    return [winnerCard]
  },[matchParticipants, winnerCard, participants, approvedLinks, getParticipantMovideCard])

  const [scrollPosition, setScrollPosition] = React.useState(0)

  const handleShowResults = (event: React.MouseEvent) => {
    event.preventDefault()
    openMatchResultModal({ match, matchParticpants: matchParticipants, participants })
  }

  return (
    <>
      <div className={cn(styles.colAuto, styles.movies__movie)}>
        <div className="row align-items-center">
          <div className="col p-l-0 p-r-10">
            <div className={styles.movies__number}>
              <span>{`#${match.IsLosersBracket ? 'LB' : 'WB'} ${match.RoundNumber}.${match.MatchNumber}`}</span>
            </div>
          </div>
          <div className={cn('col-auto', 'p-0', styles.colAuto)}>
            <a
              className={styles.movies__details}
              href="#"
              onClick={handleShowResults}
            >
              View details
            </a>
          </div>
        </div>
        {cards.length > 2 ? (
          <HorizontalScroll leftPosition={scrollPosition} onChangeLeftPosition={setScrollPosition} withoutGradient>
            <div 
              className={
                cn('row', 'm-0--6', 'flex-nowrap', styles.movie__card__scrolled, cards.length > 2 && cards.findIndex(c => c.isFiltered) >= 0 && styles.movie__card__highlighted )
              }
            >
              {cards.map(card => (
                <MovieCardDetail key={card?.participant?.Id} matchId={match.Id} participantCard={card} openPlayerModal={openPlayerModal} />
              )
              )}
            </div>        
          </HorizontalScroll> 
        ) : (
          <div className="row m-0--6 flex-nowrap">
            {cards.map(card => (
              <MovieCardDetail key={card?.participant?.Id} matchId={match.Id} participantCard={card} openPlayerModal={openPlayerModal} />
            )
            )}
          </div>  
        )}       
      </div>
    </>
  )
}

export default MovieCard
