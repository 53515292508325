import { useDialogWithParameter } from 'components/_hooks'
import { FileSelector } from 'components/_shared'
import React, { useState } from 'react'
import { useAppendImage, useCreateImage } from 'store/logic/image-logic'
import {
  ImageType,
  ImageTypeEnum,
  MediaType,
} from 'store/types/image-types'
import { UploadPopup } from './UploadPopup/UploadPopup'

export type CoverUploaderCallback = (file?: File, type?: MediaType) => Promise<void>

interface ImageUploaderProps {
  onChange: (callback: CoverUploaderCallback, file?: File, dataUrl?: string, type?: MediaType) => void
  initialValue?: ImageType
  onUploaded: (coverId: number) => void
  maxVideoDuration?:number
}

const blockSize = 1024 * 1024 * 4 // 4 MB

const bytesToMb = (size: number) => {
  const mb = Math.round(size / 1024 / 1024)
  if (mb < 1) {
    return '1MB'
  }
  return `${mb}MB`
}

export const CoverUploader = ({
  onChange,
  initialValue,
  onUploaded,
  maxVideoDuration
}: ImageUploaderProps) => {
  const createImage = useCreateImage()
  const appendImage = useAppendImage()
  const [progressText, setProgressText] = useState('')
  const [loading, setLoading] = useState(false)

  const [filePopup, openFilePopup, closeFilePopup] = useDialogWithParameter<string>(fileName => (
    <UploadPopup fileName={fileName} progressText={progressText} loading={loading} />
  ))

  const handleChange = (file?: File, dataUrl?: string, type?: MediaType) => {
    onChange(uploadCover, file, dataUrl, type)
  }

  const uploadCover = async (file?: File, type?: MediaType) => {
    if (file) {
      if (type !== MediaType.Video) {
        return
      }
      setProgressText(`${0}/${bytesToMb(file.size)}`)
      openFilePopup(file.name)

      const pageCloseHandler = (e: BeforeUnloadEvent) => {
        e.preventDefault()
        e.returnValue = ''
      }
      window.addEventListener('beforeunload', pageCloseHandler)

      setLoading(true)
      const image = await createImage({
        fileName: file.name,
        type: ImageTypeEnum.TournamentCoverVideo,
      })
      for (let offset = 0; offset < file.size; offset += blockSize) {
        const remaining = file.size - offset
        const length = remaining > blockSize ? blockSize : remaining
        const end = offset + length
        const block = file.slice(offset, end)

        await appendImage(image.Id, {
          block,
        })
        setProgressText(`${bytesToMb(end)}/${bytesToMb(file.size)}`)
      }
      setProgressText('Completed')
      setLoading(false)
      onUploaded(image.Id)
      window.removeEventListener('beforeunload', pageCloseHandler)
    } else {
      closeFilePopup()
    }
  }

  return (
    <>
      <FileSelector
        fileTypes={[MediaType.Image, MediaType.Video]}
        onChange={handleChange}
        maxVideoDuration={maxVideoDuration? maxVideoDuration:11}
        initialValue={initialValue}
        cropperWidth="100%"
        recommendedText="Recommended Image Size: 2560 x 1440"
      />
      {filePopup}
    </>
  )
}
