import { RoundStatus, TournamentStructureEnum } from 'consts'
import { makeActionCreator, ReduxAction } from './common'
import { DisputesLoadedAction } from './dispute-types'
import { ApprovedMatchLinksLoadedAction, PendingMatchLinksLoadedAction } from './match-link-types'
import { MatchesSettingsLoadedAction } from './match-settings-types'
import { ActiveMatchesLoadedAction, CheckinMatchesLoadedAction, FinishedMatchesLoadedAction, PendingMatchesLoadedAction } from './match-types'

export interface RoundType {
  Id: number
  TournamentDetailId: number
  RoundNumber: number
  RoundTitle: string
  PlayDateTime: string
  ResultSubmissionDeadlineDate: string
  ZonedPlayDateTime: string
  IsLosersBracket: boolean
  Status: RoundStatus
  IsExtraRound: boolean
}

export function getRoundTitle(round: RoundType, tournamentStructure: TournamentStructureEnum): string {
  if (!round) {
    return ''
  }
  if (round.IsExtraRound) {
    return 'ER'
  }
  if (tournamentStructure == TournamentStructureEnum.DoubleElimination) {
    return `${round.IsLosersBracket ? 'LB' : 'WB'} ${round.RoundNumber}`
  }
  return round.RoundNumber.toString()
}

export interface State {
  byId: {
    [key: number]: RoundType
  }
  idsByTournamentDetailId: {
    [key: number]: number[]
  }
  pendingRoundsIds: number[]
}

export const ROUNDS_LOADED = 'ROUNDS_LOADED'
interface RoundsLoadedPayload {
  Rounds: RoundType[]
}
export type RoundsLoadedAction = ReduxAction<typeof ROUNDS_LOADED, RoundsLoadedPayload>
export const roundsLoaded = makeActionCreator<RoundsLoadedPayload>(ROUNDS_LOADED)

export const CLEAR_ROUNDS = 'CLEAR_ROUNDS'
interface ClearRoundsPayload {
  roundsIds: number[]
  tournamentsIds: number[]
}
export type ClearRoundsAction = ReduxAction<typeof CLEAR_ROUNDS, ClearRoundsPayload>
export const clearRounds = makeActionCreator<ClearRoundsPayload>(CLEAR_ROUNDS)

export type KnownAction = RoundsLoadedAction
  | DisputesLoadedAction
  | PendingMatchesLoadedAction
  | PendingMatchLinksLoadedAction
  | ApprovedMatchLinksLoadedAction
  | FinishedMatchesLoadedAction
  | CheckinMatchesLoadedAction
  | ActiveMatchesLoadedAction
  | ClearRoundsAction
  | MatchesSettingsLoadedAction

export const getBattlesPageByRoundStatus = (round: RoundType) => {
  switch (round.Status) {
    case RoundStatus.Pending:
      return `/battles/pending/${round.Id}`
    case RoundStatus.ChekIn:
      return `/battles/checkin/${round.Id}`
    case RoundStatus.Active:
    case RoundStatus.Dispute:
      return `/battles/submission/${round.Id}`
    case RoundStatus.Finished:
      return `/battles/finished/${round.Id}` 
  }
}
