import { ExecuteAPIGet } from '../server-utils'
import { EndPoints } from '../endpoints'
import { kl_Currency } from '../../models/billing-option-model'

export const Kl_CurrencyUtils = {
  GetAll: async function GetAll() {
    return await ExecuteAPIGet<kl_Currency[]>(EndPoints.Currency.GetAll)
  },
  GetCurrencyFromCountryName: async function GetCurrencyFromCountryName(countryName : string) {
    return await ExecuteAPIGet<kl_Currency>(EndPoints.Currency.GetCurrencyFromCountryName, `countryName=${countryName}`)
  },
}
