import { createSelector } from 'reselect'
import { OperationTypes } from 'store/logic/operations-logic'
import { ApplicationState } from '../types/common'
import { makeGetIsOperationWithTypeRunning, makeGetIsOperationWithTypeSuccessOrRunning } from './operations-selectors'

const getState = (state: ApplicationState) => state.consoles

const getIdsByUserId = createSelector(getState, state => state?.idsByUserId || {})

const getIdsByTournamentDetailId = createSelector(getState, state => state?.idsByTournamentDetailId || {})

const getIdsByGameId = createSelector(getState, state => state?.idsByGameId || {})


export const makeGetConsolesByUserId = () => createSelector(
  getIdsByUserId,
  (_: ApplicationState, { userId }: { userId: number }) => userId,
  (ids, userId) => {
    return (ids[userId] ?? [])
  }
)

export const makeGetConsolesByTournamentDetailId = () => createSelector(
  getIdsByTournamentDetailId,
  (_: ApplicationState, tournamentDetailId: number) => tournamentDetailId,
  (ids, tournamentDetailId) => {
    return ids[tournamentDetailId] ?? []
  }
)

export const makeGetConsolesByGameId = () => createSelector(
  getIdsByGameId,
  (_: ApplicationState, gameId: number) => gameId,
  (ids, gameId) => {
    return ids[gameId] ?? []
  }
)

const getLoadUserConsolesOperationType = createSelector(
  (_, { userId }: { userId: number }) => userId,
  userId => ({ type: OperationTypes.loadUserConsoles, objectId: userId })
)
export const makeGetIsUserConsolesLoading = () => makeGetIsOperationWithTypeRunning(getLoadUserConsolesOperationType)
export const makeGetIsUserConsolesRequested = () => makeGetIsOperationWithTypeSuccessOrRunning(getLoadUserConsolesOperationType)
