import { useThemeStyles } from 'components/_hooks'
import React, { ReactNode } from 'react'
import { cn } from 'utils'

import stylesDark from './InfoTextDark.module.scss'
import stylesLight from './InfoTextLight.module.scss'

interface InfoTextProps {
  children: ReactNode
  className?: string
}

export const InfoText = ({ children, className }: InfoTextProps) => {
  const styles = useThemeStyles(stylesDark, stylesLight)

  return (
    <>
      <div className={cn(className, styles.icon)} />
      <div className={cn(className, styles.text)}>
        {children}
      </div>
    </>
  )
}
