import { KlLink } from 'components/_shared'
import React from 'react'
import styles from '../Home.module.scss'

interface Props {
  link: string
}

const SeeMoreLink = ({link}: Props) => {
  return (
    <KlLink className={styles.seeMore} to={link}>See More
      <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.52989e-07 6.41669L9.28571 6.41669L6.0357 9.62501L7.42855 11L13 5.5L7.42858 -4.66605e-07L6.03573 1.37499L9.28571 4.58334L3.92712e-07 4.58334L5.52989e-07 6.41669Z" fill="white"></path>
      </svg>
    </KlLink>     
  )
}

export default SeeMoreLink
