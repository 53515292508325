import React from 'react'
import { ContainerName, useTournamentParticipant } from 'components/_hooks'
import { DropDownTable } from 'components/_shared'
import { cn } from 'utils'
import styles from './Events.module.scss'
import ParticipantOnlineAvatar from 'components/_shared/ParticipantAvatar/ParticipantOnlineAvtar'
import { useOpenUserChat } from 'store/chat/chat/chat-hooks'

export interface EventParticipantRowProps {
  tournamentParticipantId: number
}

export const EventParticipantRow = ({ tournamentParticipantId }: EventParticipantRowProps) => {
  const { name, profileUrl, playerId, clanLeaderId } = useTournamentParticipant(tournamentParticipantId)
  
  const openUserChat = useOpenUserChat()

  const handleOpenChat = () => {
    openUserChat(clanLeaderId || playerId)
  }

  return (
    <DropDownTable.SecondaryRow className={styles.tableSecondaryRow}>
      <DropDownTable.SecondaryRowColumn className={cn(styles.tableColumn, styles.tableColumnUser, 'd-flex flex-row align-items-center flex-wrap')}>
        <div className="row align-items-center m-0 old-kl-css">
          <ParticipantOnlineAvatar
            parentClassName="prsn_icon-indicator-small"
            withLink
            participantId={tournamentParticipantId}
            className={styles.tableProfilePicture}
            containerName={ContainerName.AvatarsNormal}
            
          />
        </div>
        <DropDownTable.Link to={profileUrl}>{name}</DropDownTable.Link>
      </DropDownTable.SecondaryRowColumn>

      <DropDownTable.SecondaryRowColumn className={cn(styles.tableColumn, styles.tableColumnButton)}>
        <DropDownTable.Button className={styles.tableButton} color="grey" onClick={handleOpenChat}>Contact</DropDownTable.Button>        
      </DropDownTable.SecondaryRowColumn>
      <DropDownTable.SecondaryRowColumn className={cn(styles.tableColumn, styles.tableColumnTime)} />
      <DropDownTable.SecondaryRowColumn className={cn(styles.tableColumn, styles.tableColumnEventId)} />
      <DropDownTable.SecondaryRowColumn className={cn(styles.tableColumn, styles.tableColumnButtons)} />
    </DropDownTable.SecondaryRow>
  )
}
