import { AppUserLoadedAction } from './app-types'
import { ClanInvitationsLoadedAction } from './clan-invitations-types'
import { ClansMembersLoadedAction } from './clan-member-types'
import { makeActionCreator, ReduxAction } from './common'
import { ImageType } from './image-types'
import { MatchParticipantsLoadedAction } from './match-participant-types'
import { MembersPageLoadedAction } from './pages/clan-page-types'
import { MembersPageClansLoadedAction, MembersPagePlayersLoadedAction } from './pages/members-page-types'
import { ParticipantsLoadedAction } from './participants-types'

export interface ClanType {
  Id: number
  Name: string
  WantToBeFound: boolean
  TotalExpPoints: number
  AboutUs: string
  AvatarId?: number
  TotalMatches: number
  WinnedMatches: number
  MembersCount: number
}

export interface ClansModel {
  Clans: ClanType[]
  Images: ImageType[]
}

export interface State {
  byId: {
    [key: number]: ClanType
  }
}

export const CLANS_LOADED = 'CLANS_LOADED'
export type ClansLoadedAction = ReduxAction<typeof CLANS_LOADED, ClansModel>
export const clansLoaded = makeActionCreator<ClansModel>(CLANS_LOADED)

interface ClanDeletedPayload {
  clanId: number
}
export const CLAN_DELETED = 'CLAN_DELETED'
export type ClanDeletedAction = ReduxAction<typeof CLAN_DELETED, ClanDeletedPayload>
export const clanDeleted = makeActionCreator<ClanDeletedPayload>(CLAN_DELETED)

export type KnownAction = MatchParticipantsLoadedAction
  | ParticipantsLoadedAction
  | ClansLoadedAction
  | AppUserLoadedAction
  | ClansMembersLoadedAction
  | ClanInvitationsLoadedAction
  | MembersPageLoadedAction
  | ClanDeletedAction
  | MembersPagePlayersLoadedAction
  | MembersPageClansLoadedAction

export const getClanProfileUrl = (clanId: number) => {
  return `/clan/${clanId}`
}
