import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadFinishedMatches } from 'store/logic/match-logic'
import { getFinishedMatchesIds, getIsFinishedMatchesLoading } from 'store/selectors/match-selectors'
import { useDialogWithParameter } from 'components/_hooks/useComponents/useDialog'
import UpdateLinkModal, { UpdateLinkModalParams } from './UpdateLinkModal'
import FinishedBattleRow from './FinishedBattleRow'
import {
  BattleHeaderRow,
  ButtonsColumn,
  ClassColumn, 
  DateColumn,
  EventNameColumn,
  LinkColumn,
  RoundColumn,
  TimeColumn,
  TypeColumn,
} from '../_shared/BattleRow/BattleRow'
import MatchResultModal, { ParticipantsResults } from 'components/_shared/MatchResultModal/MatchResultModal'
import { useEffect } from 'react'
import { LoadingBox } from 'components/_shared'
import { useHighlight } from 'components/_hooks'

export interface FinishedBattlesProps {
  roundId?: number
}

export const FinishedBattles = ({ roundId }: FinishedBattlesProps) => {
  const dispatch = useDispatch()
  const matchesIds = useSelector(getFinishedMatchesIds)
  const matchesLoading = useSelector(getIsFinishedMatchesLoading)
  const [highlightedRoundId, removeHighlighted] = useHighlight(roundId)

  useEffect(() => {
    dispatch(loadFinishedMatches)
  }, [dispatch])

  const [updateLinkModal, openUpdateLinkModal] = useDialogWithParameter<UpdateLinkModalParams>((data, closeDialog) => (
    <UpdateLinkModal closeDialog={closeDialog} matchId={data.matchId} matchLink={data.matchLink} />
  ))

  const [matchResultModal, openMatchResultModal] = useDialogWithParameter<ParticipantsResults>((participantsResults, closeDialog) => (
    <MatchResultModal closeDialog={closeDialog} participantsResults={participantsResults} />
  ))
  
  return (
    <>
    <div className="tbl-header">
      <BattleHeaderRow>
        <EventNameColumn>Name of the event</EventNameColumn>
        <ClassColumn>Class</ClassColumn>
        <TypeColumn>Type</TypeColumn>
        <DateColumn>Date</DateColumn>
        <TimeColumn>Time</TimeColumn>
        <RoundColumn>Round</RoundColumn>
        <LinkColumn>Link</LinkColumn>
        <ButtonsColumn />
      </BattleHeaderRow>
      <LoadingBox loading={matchesLoading} size="big">
        {matchesIds.map(id => (
          <FinishedBattleRow
            key={id}
            matchId={id}
            openUpdateLinkModal={openUpdateLinkModal}
            openMatchResultModal={openMatchResultModal}
            highlightedRoundId={highlightedRoundId}
            removeHighlighted={removeHighlighted}
          />
        ))}
      </LoadingBox>
      {updateLinkModal}
      {matchResultModal}
      </div>
    </>
  )
}
