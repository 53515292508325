import { makeActionCreator, ReduxAction } from '../common'

export type PlaceByMatchParticipantId = {
  [matchParticipantId: number]: number
}
export type MatchParticipantIdByPlace = {
  [place: number]: number
}

export interface State {
  placeByMatchParticipantId: PlaceByMatchParticipantId
}

export const PLACE_CLEARED = 'PLACE_CLEARED'
export interface PlaceClearedPayLoad {
  matchParticipantId: number
  place: number
}
export type PlaceClearedAction = ReduxAction<typeof PLACE_CLEARED, PlaceClearedPayLoad>
export const placeCleared = makeActionCreator<PlaceClearedPayLoad>(PLACE_CLEARED)

export const PLACE_CHANGED = 'PLACE_CHANGED'
export interface PlaceChangedPayLoad {
  matchParticipantId: number
  place: number
}
export type PlaceChangedAction = ReduxAction<typeof PLACE_CHANGED, PlaceChangedPayLoad>
export const placeChanged = makeActionCreator<PlaceChangedPayLoad>(PLACE_CHANGED)

export type KnownAction = PlaceClearedAction
  | PlaceChangedAction
