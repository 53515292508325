import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { makeGetMatch } from 'store/selectors/match-selectors'
import { ApplicationState } from 'store/types/common'
import { MatchModel } from 'store/types/match-types'
import { RoundType } from 'store/types/rounds-types'
import { TournamentType } from 'store/types/tournaments-types'
import { useRound } from './useRound'

interface UseMatchResult {
  tournament?: TournamentType
  round?: RoundType
  match?: MatchModel
}

export const useMatch = (matchId?: number): UseMatchResult => {
  const getMatch = useMemo(makeGetMatch, [])
  const match = useSelector((state: ApplicationState) => getMatch(state, matchId))
  const { tournament, round } = useRound(match?.RoundId)

  return {
    tournament,
    round,
    match,
  }
}
