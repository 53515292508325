import React, { useMemo, useState } from 'react'
import styles from '../UserReview.module.scss'
import ReactStars from 'react-rating-stars-component'
import { Button, TextArea } from 'components/_shared'
import { useAppSelector } from 'components/_hooks/useAppSelector'
import { makeGetIsAddReviewLoading } from 'store/selectors/review-selectors'
import { useAddReview } from 'store/logic/review-logic'
import { useAlertModal } from 'components/_hooks'

export interface AddReviewProps {
  userId: number
}

export const AddReview = ({ userId }:  AddReviewProps) => {
  const [rating, setRating] = useState<number>()
  const [text, setText] = useState<string>('')
  const getIsAddReviewLoading = useMemo(makeGetIsAddReviewLoading, [])
  const addReview = useAddReview(userId)
  const [errorModal, openErrorModal] = useAlertModal()

  const isAddReviewLoading = useAppSelector(state => getIsAddReviewLoading(state, { userId }))

  const handleSaveReview = async () => {
    try {
      await addReview({
        userId,
        rating,
        text,
      })
    }
    catch (error) {
      openErrorModal((error as Error).message)
    }
  }
  
  return (
    <>
      <div className={styles.messageContainer}>
        <div className={styles.reviewLabel}><b>Review</b>: From 1 to 5 stars, how would you rank your intercation with this member?</div>
        <ReactStars
          count={5}
          size={36}
          activeColor="#ec5418"
          classNames={styles.star}
          value={rating}
          onChange={setRating}
        />
      </div>
      <div className={styles.textLabel}><b>Your Message:</b></div>
      <TextArea inputClassName={styles.reviewText} value={text} onChange={setText} />
      <div className={styles.buttonContainer}>
        <Button loading={isAddReviewLoading} disabled={!rating || !text} color="green" filled onClick={handleSaveReview} className="w-100">Save Review</Button>
      </div>
      {errorModal}
    </>
  )
}
