import { useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import { fetchApi } from 'server/server-utils'
import { getDisputesRequested } from 'store/selectors/dispute-selectors'
import { AppThunkAction, GetState, PaginationModel } from 'store/types/common'
import { DisputeModel, disputesLoaded, DisputeStatus, disputeUpdated } from 'store/types/dispute-types'
import { MatchModel } from 'store/types/match-types'
import { RoundType } from 'store/types/rounds-types'
import { TournamentType } from 'store/types/tournaments-types'
import { cancel, OperationTypes, run, useRun } from './operations-logic'

interface ListDisputesModel {
  Disputes: DisputeModel[]
  Tournaments: TournamentType[]
  Rounds: RoundType[]
  Matches: MatchModel[]
  AllDisputesCount: number
}

export interface DisputesFilter {
  pagination?: PaginationModel
  disputeStatuses?: DisputeStatus[]
}

export const useResolveDispute = (disputeId: number) => {
  const dispatch = useDispatch()
  return useRun(OperationTypes.resolveDispute, disputeId, async () => {
    const dispute = await fetchApi<DisputeModel>(`dispute/${disputeId}/resolve`, 'POST')
    dispatch(disputeUpdated({ dispute }))
    return true
  })
}

const loadDisputes = async (filter: DisputesFilter, dispatch: Dispatch, getState: GetState) => {
  if (getDisputesRequested(getState())) {
    return
  }
  await run(OperationTypes.loadDisputes, null, dispatch, getState, async () => {
    try {
      const response = await fetchApi<ListDisputesModel>('dispute', 'POST', filter)
      dispatch(
        disputesLoaded({
          ...response,
          Reload: (filter.pagination?.page ?? 0) === 0,
        })
      )
    } catch (error) {
      return false
    }
    return true
  })
}

export const disputeActionCreators = {
  loadDisputes: (filter: DisputesFilter): AppThunkAction => async (dispatch: Dispatch, getState: GetState) => {
    await loadDisputes(filter, dispatch, getState)
  },
  reLoadDisputes: (filter: DisputesFilter): AppThunkAction => async (dispatch: Dispatch, getState: GetState) => {
    cancel(OperationTypes.loadDisputes, null, dispatch, getState)
    await loadDisputes(filter, dispatch, getState)
  },
}
