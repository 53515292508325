import React, { useEffect, useMemo, useState } from 'react'
import { DropDownTable, LoadingBox, Pagination, VideoPlayerModal } from '../'
import { DisputeDropDownTable } from './_shared'

import stylesCommon from './Disputes.module.scss'
import './Disputes-common.scss'

import { useDispatch, useSelector } from 'react-redux'
import { disputeActionCreators, DisputesFilter } from 'store/logic/dispute-logic'
import { getAllDisputesCount, getDisputesIds, getDisputesLoading } from 'store/selectors/dispute-selectors'
import { useDialogWithParameter } from 'components/_hooks/useComponents/useDialog'
import { DisputeModel, DisputeStatus } from 'store/types/dispute-types'
import { getPageInitialized, getShowClosed, getShowOpen } from 'store/selectors/pages/disputes-page-selectors'
import { initializedChanged, showClosedChanged, showOpenChanged } from 'store/types/pages/disputes-page-types'
import { ResolveDisputeModal } from './_shared/ResolveDisputeModal/ResolveDisputeModal'
import { useHighlight, useThemeStyles } from 'components/_hooks'
import stylesDark from './DisputesDark.module.scss'
import stylesLight from './DisputesLight.module.scss'

const pageSize = 6

interface DisputesProps {
  roundId?: number
}

export const Disputes = ({ roundId }: DisputesProps) => {
  const styles = useThemeStyles(stylesDark, stylesLight)
  const dispatch = useDispatch()
  const disputesIds = useSelector(getDisputesIds)
  const disputesLoading = useSelector(getDisputesLoading)
  const showOpen = useSelector(getShowOpen)
  const showClosed = useSelector(getShowClosed)
  const pageInitialized = useSelector(getPageInitialized)
  const [pageInitializedLocal, setPageInitializedLocal] = useState(false)
  const [highlightedRoundId, removeHighlightedRoundId] = useHighlight(roundId || 0)
  const [page, setPage] = useState(0)

  const allDisputesCount = useSelector(getAllDisputesCount)

  const handleOpenClick = () => {
    setPage(0)
    dispatch(showOpenChanged({ showOpen: !showOpen }))
  }

  const handleClosedClick = () => {
    setPage(0)
    dispatch(showClosedChanged({ showClosed: !showClosed }))
  }

  const disputesFilters: DisputesFilter = useMemo(() => {
    const disputeStatuses: DisputeStatus[] = []
    if (showOpen) {
      disputeStatuses.push(DisputeStatus.Open)
    }
    if (showClosed) {
      disputeStatuses.push(DisputeStatus.Closed)
    }
    return {
      disputeStatuses,
      pagination: {
        page,
        pageSize,
      }
    }
  }, [showOpen, showClosed, page])

  useEffect(() => {
    setPageInitializedLocal(pageInitialized)
  }, [pageInitialized])

  useEffect(() => {
    dispatch(disputeActionCreators.loadDisputes(disputesFilters))
    if (!pageInitialized) {
      dispatch(initializedChanged({ pageInitialized: true }))
    }
  }, [dispatch, disputesFilters, pageInitialized])

  useEffect(() => {
    if (pageInitializedLocal) {
      dispatch(disputeActionCreators.reLoadDisputes(disputesFilters))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, disputesFilters])

  const [vieoPlayerModal, openVideoPlayerModal] = useDialogWithParameter<string>((videoUrl, closeDialog) => (
    <VideoPlayerModal closeDialog={closeDialog} videoUrl={videoUrl} />
  ))

  const [resolveDisputeModal, openResolveDisputeModal] = useDialogWithParameter<DisputeModel>((dispute, closeDialog) => (
    <ResolveDisputeModal closeDialog={closeDialog} dispute={dispute} />
  ))

  return (
    <>
      <DropDownTable.HeadersRow className={stylesCommon.tableHeadersRow}>
        <DisputeDropDownTable.Column className={stylesCommon.tableColumnId}>Dispute ID</DisputeDropDownTable.Column>
        <DisputeDropDownTable.Column className={stylesCommon.tableColumnEventId}>Event ID</DisputeDropDownTable.Column>
        <DisputeDropDownTable.Column className={stylesCommon.tableColumnEvent}>Event</DisputeDropDownTable.Column>
        <DisputeDropDownTable.Column className={stylesCommon.tableColumnRound}>Round</DisputeDropDownTable.Column>
        <DisputeDropDownTable.Column className={stylesCommon.tableColumnResolved}>Resolved</DisputeDropDownTable.Column>
        <DisputeDropDownTable.HeadersRowButtonsColumn>
          <div className="row justify-content-end">
            <DropDownTable.Radio
              checked={showOpen}
              onClick={handleOpenClick}
              className={styles.tableColumnLabel}
              classNameChecked={styles.tableColumnLabelChecked}
            >
              <div className={styles.tableColumnLabelText}>Open</div>
            </DropDownTable.Radio>
            <DropDownTable.Radio
              checked={showClosed}
              onClick={handleClosedClick}
              className={styles.tableColumnLabel}
              classNameChecked={styles.tableColumnLabelChecked}
            >
              <div className={styles.tableColumnLabelText}>Closed</div>
            </DropDownTable.Radio>
          </div>
        </DisputeDropDownTable.HeadersRowButtonsColumn>
      </DropDownTable.HeadersRow>
      <LoadingBox loading={disputesLoading} size="big">
        {disputesIds.map(x => (
          <DisputeDropDownTable.DisputeRow
            key={x}
            disputeId={x}
            openVideoPlayerModal={openVideoPlayerModal}
            openResolveDisputeModal={openResolveDisputeModal}
            removeHighlightedRoundId={removeHighlightedRoundId}
            highlightedRoundId={highlightedRoundId}
          />
        ))}
      </LoadingBox>

      <Pagination page={page} itemsPerPage={pageSize} allItemsCount={allDisputesCount} onChangePage={setPage} />
      
      {vieoPlayerModal}
      {resolveDisputeModal}
    </>
  )
}
