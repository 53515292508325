import { MatchModel } from 'store/types/match-types'
import { RoundType } from 'store/types/rounds-types'
import { isTwoPlayersStructure, RoundStatus, TournamentStructureEnum } from 'consts'
import getId from 'utils/getId'
import { div } from 'utils'

enum BracketStructure {
  Simple,
  Winner,
  Loser,
}

const getIsLosersBracket = (bracketStructure: BracketStructure) => bracketStructure === BracketStructure.Loser

interface TournamentBracket {
  structure: TournamentStructureEnum
  rounds: RoundType[]
  matches: MatchModel[]
}

export class TournamentBuilder {
  private readonly _tournamentDetailId: number
  private readonly _participantsPerMatch: number
  private readonly _maxNumberOfParticipants: number
  private readonly _structure: TournamentStructureEnum

  constructor(tournamentDetailId: number, structure: TournamentStructureEnum, maxNumberOfParticipants: number, participantsPerMatch: number) {
    if (maxNumberOfParticipants < participantsPerMatch) {
      throw new Error('Participants per match must be more than max number of participants')
    }
    if (maxNumberOfParticipants / participantsPerMatch !== Math.floor(maxNumberOfParticipants / participantsPerMatch)) {
      throw new Error('Number of participants must be a multiple of participants per match')
    }
    if (isTwoPlayersStructure(structure)) {
      if (participantsPerMatch !== 2) {
        throw new Error('Participants per match mast be equals 2 for this bracket structure')
      }
    }

    this._tournamentDetailId = tournamentDetailId
    this._structure = structure
    this._participantsPerMatch = participantsPerMatch
    this._maxNumberOfParticipants = maxNumberOfParticipants
  }

  public build = (): TournamentBracket => {
    const rounds = []
    const matches = []

    if (this._structure === TournamentStructureEnum.DoubleElimination) {
      const winnerBracket = this.buildBracket(BracketStructure.Winner)
      rounds.push(...winnerBracket.rounds)
      matches.push(...winnerBracket.matches)

      const grandFinalRoundNumber = rounds[rounds.length - 1].RoundNumber + 1
      const grandFinal = this.createRoundModel(grandFinalRoundNumber, false) // round for finalists of Winner and Loser brackets
      const grandFinalMatch = this.createMatchModel(grandFinal.Id, 1)
      rounds.push(grandFinal)
      matches.push(grandFinalMatch)

      const extraRoundNumber = rounds[rounds.length - 1].RoundNumber + 1
      const extraRound = this.createRoundModel(extraRoundNumber, false) // round for finalists of Grand Final round
      const extraRoundMatch = this.createMatchModel(extraRound.Id, 1)
      rounds.push(extraRound)
      matches.push(extraRoundMatch)

      const loserBracket = this.buildBracket(BracketStructure.Loser)
      rounds.push(...loserBracket.rounds)
      matches.push(...loserBracket.matches)
    } else {
      const bracket = this.buildBracket(BracketStructure.Simple)
      rounds.push(...bracket.rounds)
      matches.push(...bracket.matches)
    }

    return {
      structure: this._structure,
      rounds,
      matches,
    }
  }

  private buildBracket = (structure: BracketStructure) => {
    const rounds = this.generateRounds(structure)
    const matches = this.generateMatches(rounds, structure)

    return {
      rounds,
      matches,
    }
  }

  //#region rounds
  private generateRounds = (bracketStructure: BracketStructure) => {
    const isLosersBracket = getIsLosersBracket(bracketStructure)
    const roundsNumber = this.calcRoundsNumber(bracketStructure)
    return Array(roundsNumber)
      .fill(0)
      .map((_, i) => this.createRoundModel(i + 1, isLosersBracket))
  }

  private calcRoundsNumber = (bracketStructure: BracketStructure) => {
    switch (bracketStructure) {
      case BracketStructure.Simple:
      case BracketStructure.Winner:
        return this.calcWinnerRoundsNumber()
      case BracketStructure.Loser:
        return this.calcLosersRoundsNumber()
      default:
        throw new Error('Not supported bracket structure')
    }
  }

  private calcWinnerRoundsNumber = () => {
    let roundsNumber = 1

    while (Math.pow(this._participantsPerMatch, roundsNumber) < this._maxNumberOfParticipants) {
      roundsNumber += 1
    }

    return roundsNumber
  }

  private calcLosersRoundsNumber = () => {
    const winnersRoundsNumber = this.calcWinnerRoundsNumber()
    return (winnersRoundsNumber - 1) * 2
  }

  private createRoundModel = (roundNumber: number, isLosersBracket: boolean): RoundType => {
    return {
      Id: -getId(),
      RoundNumber: roundNumber,
      RoundTitle: `Round ${roundNumber}`,
      Status: RoundStatus.Pending,
      TournamentDetailId: this._tournamentDetailId,
      IsLosersBracket: isLosersBracket,
    } as RoundType
  }
  //#endregion

  //#region matches
  private generateMatches = (rounds: RoundType[], bracketStructure: BracketStructure): MatchModel[] => {
    const matchesByRound = rounds.map(x => this.generateRoundMatches(x, rounds.length, bracketStructure))
    return [].concat.apply([], matchesByRound)
  }

  private generateRoundMatches = (round: RoundType, numberOfRounds: number, bracketStructure: BracketStructure) => {
    const calcNumberOfMatches = getIsLosersBracket(bracketStructure) ? this.calcNumberOfLoserMatches : this.calcNumberOfWinnerMatches
    const numberOfMatches = calcNumberOfMatches(numberOfRounds, round.RoundNumber)
    return Array(numberOfMatches)
      .fill(0)
      .map((_, i) => this.createMatchModel(round.Id, i + 1))
  }

  private calcNumberOfWinnerMatches = (numberOfRounds: number, roundNumber: number) => {
    return Math.pow(this._participantsPerMatch, numberOfRounds - roundNumber)
  }

  private calcNumberOfLoserMatches = (numberOfRounds: number, roundNumber: number) => {
    return Math.pow(this._participantsPerMatch, numberOfRounds / 2 - div(roundNumber, 2) - (roundNumber % 2))
  }

  private createMatchModel = (roundId: number, matchNumber: number ): MatchModel => {
    return {
      Id: -getId(),
      RoundId: roundId,
      MatchNumber: matchNumber,
      TournamentDetailId: this._tournamentDetailId,
    } as MatchModel
  }
  //#endregion
}
