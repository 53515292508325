import { createSelector } from 'reselect'
import { OperationTypes } from 'store/logic/operations-logic'
import { PlayersFilters } from 'store/types/pages/clan-page-types'
import { ApplicationState } from '../../types/common'
import { makeGetIsAnyOperationWithTypeRunning, makeGetIsOperationWithTypeRunning, makeGetIsOperationWithTypeSuccessOrRunning } from '../operations-selectors'

const getState = (state: ApplicationState) => state.clanPage

export const getAllPlayersCount = createSelector(getState, state => state?.allPlayersCount ?? 0)

export const getPlayersIds = createSelector(getState, state => (state?.playersIds ?? []))

export const getSelectedPlayersIds = createSelector(getState, state => (state?.selectedPlayersIds ?? []))

export const getPlayersFilters = createSelector(getState, state => (state?.playersFilters ?? {
  page: 0,
  username: '',
} as PlayersFilters))

export const getIsSearchPlayersLoading = makeGetIsAnyOperationWithTypeRunning(() => ({
  type: OperationTypes.searchPlayers,
}))

export const getAllMembersCount = createSelector(getState, state => state?.allMembersCount ?? 0)

export const getMembersIds = createSelector(getState, state => (state?.membersIds ?? []))

export const getMembersPage = createSelector(getState, state => state?.membersPage ?? 0)

const getLoadClanMembersOperationType = createSelector(
  (_: ApplicationState, { clanId }: { clanId: number }) => clanId,
  clanId => ({ type: OperationTypes.loadClanMembers, objectId: clanId })
)
export const makeGetIsClanMembersLoading = () => makeGetIsOperationWithTypeRunning(getLoadClanMembersOperationType)
export const makeGetIsClanMembersRequested = () => makeGetIsOperationWithTypeSuccessOrRunning(getLoadClanMembersOperationType)
