import { createSelector } from 'reselect'
import { OperationTypes } from 'store/logic/operations-logic'
import { ApplicationState } from '../types/common'
import { makeGetIsAnyOperationWithTypeRunning, makeGetIsOperationWithTypeRunning, makeGetIsOperationWithTypeSuccessOrRunning } from './operations-selectors'

const getState = (state: ApplicationState) => state.clanInvitations

const getById = createSelector(getState, state => state?.byId || {})

const getIdByClanId = createSelector(getState, state => state?.idByClanId || {})

export const getClanInvitationsIds = createSelector(getState, state => (state?.ids ?? []))

export const makeGetClanInvitationIdByClanId = () =>
  createSelector(
    getIdByClanId,
    (_: ApplicationState, { clanId }: { clanId: number}) => clanId,
    (idByClanId, clanId) => idByClanId[clanId]
  )

export const makeGetClanInvitation = () =>
  createSelector(
    getById,
    (_: ApplicationState, id: number) => id,
    (byId, id) => byId[id]
  )

const getLoadClanInvitationsOperationType = createSelector(
  (_: ApplicationState, { userId }: { userId: number }) => userId,
  userId => ({ type: OperationTypes.loadClanInvitations, objectId: userId })
)
export const makeGetIsClanInvitationsLoading = () => makeGetIsOperationWithTypeRunning(getLoadClanInvitationsOperationType)
export const makeGetIsClanInvitationsRequested = () => makeGetIsOperationWithTypeSuccessOrRunning(getLoadClanInvitationsOperationType)

export const getIsInvitePlayersLoading = makeGetIsAnyOperationWithTypeRunning(() => ({
  type: OperationTypes.invitePlayers,
}))

const getIsUpdateClanInvitationLoadOperationType = createSelector(
  (_: ApplicationState, id: number) => id,
  id => ({ type: OperationTypes.updateInvitation, objectId: id })
)
export const makeGetIsUpdateInvitationLoading = () => makeGetIsOperationWithTypeRunning(getIsUpdateClanInvitationLoadOperationType)
