import { Dispatch } from 'redux'
import { GetState, PaginationModel } from 'store/types/common'
import { FriendsFilters, friendsPageLoaded } from 'store/types/pages/friends-page-types'
import { ListUserResultModel } from 'store/types/user-types'
import { fetchApi } from 'utils'
import { OperationTypes, run } from '../operations-logic'


export const loadPageFriends = (userId: number, pagination: PaginationModel) => async (dispatch: Dispatch, getState: GetState) => {
  await run(OperationTypes.loadUserFriends, userId, dispatch, getState, async () => {
    try {
      const filters: FriendsFilters = {
        userId: userId,
        name: '',
      }      
      const model = await fetchApi<ListUserResultModel>('user/friends', 'POST', {
        filters: filters,
        pagination: pagination,
      })

      dispatch(friendsPageLoaded({
        userId: userId,
        friends: model,
      }))
    } catch (error) {
      return false
    }
    return true
  })
}
