import { LoadingBox, Pagination } from 'components/_shared'
import React,{ useEffect, useMemo, useState } from 'react'
import { cn } from 'utils'
import styles from '../UserReview.module.scss'
import { getAllReviewsCount, makeGetIdsByUserId, makeGetIsMyReviewLoading, makeGetIsReviewsLoading, makeGetMyReviewId } from 'store/selectors/review-selectors'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from 'store/types/common'
import { getIsAdmin, getIsOrganizer, PublicUserType } from 'store/types/user-types'
import { Review } from './Review'
import { loadReviews } from 'store/logic/review-logic'
import { AddReview } from './AddReview'

const pageSize = 5

const canReview = (user: PublicUserType, appUser: PublicUserType) => {
  if (!user || !appUser) {
    return false
  }
  if (user?.Id !== appUser?.Id) {
    if (getIsOrganizer(user?.Role) && getIsOrganizer(appUser?.Role)) {
      return false
    }
    if (getIsAdmin(appUser?.Role)) {
      return false
    }
    return true
  }
  return false
}

export interface ReviewsListProps {
  user: PublicUserType
  loggedInuser: PublicUserType
}

export const ReviewsList = ({ user, loggedInuser }: ReviewsListProps) => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(0)

  const getIsReviewsLoading = useMemo(makeGetIsReviewsLoading, [])
  const getIsMyReviewLoading = useMemo(makeGetIsMyReviewLoading, [])
  const getReviewIdsByUserId = useMemo(makeGetIdsByUserId, [])
  const getMyReviewIdByUserId = useMemo(makeGetMyReviewId, [])
  
  const allReviewsCount = useSelector(getAllReviewsCount)
  const isReviewsLoading = useSelector((state: ApplicationState) => getIsReviewsLoading(state, { userId: user?.Id }))
  const isMyReviewLoading = useSelector((state: ApplicationState) => getIsMyReviewLoading(state, { userId: user?.Id }))
  const reviewIds = useSelector((state: ApplicationState) => getReviewIdsByUserId(state, { userId: user?.Id}))
  const myReviewId = useSelector((state: ApplicationState) => getMyReviewIdByUserId(state, { userId: user?.Id}))


  useEffect(() => {
    if (user?.Id) {
      dispatch(loadReviews(user?.Id, {
        page,
        pageSize,
      }))
    }
  }, [dispatch, page, user])

  const name = user?.Role == 'Organizer' 
    ? user?.Organizationname 
    : user?.Username

  return (
    <>
      <LoadingBox loading={isReviewsLoading || isMyReviewLoading} size="average" centered className="w-100">
        <div className="d-flex flex-column flex-fill pl-4 overflow-hidden">
          {myReviewId &&
            <>
              <div className={cn(styles.rightTile, 'pb-2')}>My Review</div>
              <Review id={myReviewId} starSize={24}/>
            </>
          }
          {
            (reviewIds?.length > 0 || (!reviewIds?.length && !myReviewId))
              && <div className={cn(styles.rightTile, myReviewId && 'mt-4', 'pb-2')}>{`${name} Reviews`}</div>
          }
          {
            reviewIds?.length > 0 
              ? reviewIds.map(x => <Review key={x} id={x} starSize={24}/>)
              : !myReviewId && <div className={styles.rightValue}>No reviews</div>
          }
          <Pagination page={page} itemsPerPage={pageSize} allItemsCount={allReviewsCount} onChangePage={setPage} />
          {canReview(user, loggedInuser) && !myReviewId &&
            <AddReview userId={user.Id} />
          }
        </div>
      </LoadingBox>
    </>
  )
}
