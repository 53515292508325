import React, { useEffect, useMemo } from 'react'
import { TournamentModeEnum, TournamentModeName } from 'consts/TournamentModeId'
import { useDispatch, useSelector } from 'react-redux'
import { makeGetIsMatchParticipantsLoading, makeGetMatchParticipantsByMatchId } from 'store/selectors/match-participant-selectors'
import { ApplicationState } from 'store/types/common'
import { MatchStatusEnum } from 'store/types/match-types'
import { defaultFormatDateTime } from 'utils'
import { getParticipantsByTournamentDetailId } from 'store/selectors/participant-selectors'
import {
  TableButton,
  ButtonsColumn,
  DeadlineColumn,
  EventNameColumn,
  RoundColumn,
  TypeColumn,
  BattleRow,
  StartDateColumn,
} from '../_shared/BattleRow/BattleRow'
import { makeGetIsMatchSubmissionsLoading, makeGetSubmissionsByMatchId } from 'store/selectors/match-submissions-selectors'
import { ParticipantSubmissionModel } from 'store/types/match-submissions-types'
import { ParticipantsResults } from 'components/_shared/MatchResultModal/MatchResultModal'
import { DropDownTable, LoadingBox, SubmissionModal } from 'components/_shared'
import { useDialogWithParameter } from 'components/_hooks/useComponents/useDialog'
import { SubmissionModalType } from 'components/_shared/SubmissionModal/SubmissionModal/SubmissionModal'
import { useMatch, useMemberClan, useOpponents, useScrollToElement } from 'components/_hooks'
import { matchesSumbissionsActionCreators } from 'store/logic/match-submissions-logic'
import { getRoundTitle } from 'store/types/rounds-types'
import { loadMatchParticipants } from 'store/logic/match-participant-logic'
import { TournamentParticipantRow } from '../_shared/TournamentParticipantRow'
import { getAppUser } from 'store/selectors/app-selectors'
import DisputeChatButton from './DisputeChatButton'

export interface ActiveBattleRowProps {
  matchId: number
  highlightedRoundId?: number
  removeHighlightedRoundId: () => void
  openMatchResultModal: (participantResults: ParticipantsResults) => void
}

const ActiveBattleRow = ({
  matchId,
  openMatchResultModal,
  highlightedRoundId,
  removeHighlightedRoundId,
}: ActiveBattleRowProps) => {
  const dispatch = useDispatch()

  const user = useSelector(getAppUser)
  const {isClanLeader} = useMemberClan(user?.Id)

  const getMatchParticipantsByMatchId = useMemo(makeGetMatchParticipantsByMatchId, [])
  const getSubmissionsByMatchId = useMemo(makeGetSubmissionsByMatchId, [])
  const getIsMatchParticipantsLoading = useMemo(makeGetIsMatchParticipantsLoading, [])
  const getIsMatchSubmissionsLoading = useMemo(makeGetIsMatchSubmissionsLoading, [])

  const { match, round, tournament } = useMatch(matchId)
  const matchParticipants = useSelector((state: ApplicationState) => getMatchParticipantsByMatchId(state, matchId))
  const matchParticipantsLoading = useSelector((state: ApplicationState) => getIsMatchParticipantsLoading(state, matchId))
  const matchSubmissions = useSelector((state: ApplicationState) => getSubmissionsByMatchId(state, matchId))

  const matchResultsLoading = useSelector((state: ApplicationState) => getIsMatchSubmissionsLoading(state, matchId))
  const participants = useSelector((state: ApplicationState) => getParticipantsByTournamentDetailId(state, round?.TournamentDetailId))

  const { me, opponents } = useOpponents(matchId)

  const mySubmission = useMemo(() => {
    return matchSubmissions.find(x => x.MatchParticipantId === me?.Id)
  }, [matchSubmissions, me?.Id])

  const isResultSubmitted = mySubmission ? true : false
  const isDispute = match?.Status === MatchStatusEnum.Dispute

  const canSubmit = !mySubmission || mySubmission.CanResubmit

  const handleSubmit = () => {
    openSubmissionModal(mySubmission)
  }

  const handleShowSubmittedResult = () => {
    openSubmissionModal(mySubmission)
  }

  const handleShowResults = () => {
    if (isDispute) {
      openMatchResultModal({
        matchParticpants: matchParticipants.map(mp => {
          const matchSubmission = matchSubmissions.find(ms => ms.MatchParticipantId == mp.Id)
          return { ...mp, Place: matchSubmission?.Place ?? null }
        }),
        participants,
        match,
        isDispute: true,
      })
    }
  }

  useEffect(() => {
    dispatch(loadMatchParticipants(matchId))
    dispatch(matchesSumbissionsActionCreators.loadMatchSubmissions(matchId))
  }, [dispatch, matchId])

  const highlighted = useMemo(() => {
    return highlightedRoundId === round?.Id
  }, [highlightedRoundId, round?.Id])

  const ref = useScrollToElement(highlighted, matchParticipantsLoading ? 'auto' : 'smooth')

  const [submissionModal, openSubmissionModal] = useDialogWithParameter<ParticipantSubmissionModel>((submission, closeDialog) => (
    <SubmissionModal
      closeDialog={closeDialog}
      submission={submission}
      participantsLength={matchParticipants?.length ?? 0}
      tournamentStructure={tournament?.TournamentStructureId}
      matchId={matchId}
      type={!canSubmit ? SubmissionModalType.Readonly : (
        match?.Status === MatchStatusEnum.Dispute ? SubmissionModalType.Dispute : SubmissionModalType.Submission)}
    />
  ))

  const isSubmitButtonVisible = useMemo(() => {
    return !tournament.IsManualControl
      && (tournament.TournamentModeId == TournamentModeEnum.Solo || isClanLeader || !canSubmit)
  }, [tournament, isClanLeader, canSubmit])

  const warningText = useMemo(() => {
    if (tournament?.TournamentModeId == TournamentModeEnum.Clans && !isClanLeader) {
      if (isDispute) {
        return 'Match is disputed, your captain needs to submit the right results'
      }
      return 'Your captain needs to submit the results before the deadline'
    }
    if (isDispute) {
      return 'Match is disputed, submit the right results'
    }
    return 'Submit the results before the deadline'
  }, [isClanLeader, isDispute, tournament?.TournamentModeId])

  return (
    <>
      <div ref={ref} className="row table__row battle-row" onMouseEnter={highlighted ? removeHighlightedRoundId : undefined}>
        <div className="col-12">
          <input className="table__checkbox" type="checkbox" id={`open-table-${matchId}`} />
          <BattleRow highlighted={highlighted}>
            <EventNameColumn>
              <DropDownTable.Link to={`/tournament-detail/${tournament?.TournamentDetailId}`}>{tournament?.Name}</DropDownTable.Link>
            </EventNameColumn>
            <TypeColumn>{TournamentModeName[tournament?.TournamentModeId]}</TypeColumn>
            <StartDateColumn>{defaultFormatDateTime(round.PlayDateTime)}</StartDateColumn>
            <RoundColumn>{getRoundTitle(round, tournament?.TournamentStructureId)}</RoundColumn>
            <DeadlineColumn>
              {!tournament.IsManualControl
                ? (
                  <>
                    {defaultFormatDateTime(round.ResultSubmissionDeadlineDate)}
                    {!matchParticipantsLoading && !isResultSubmitted && !isDispute && (
                      <div className="table__warning">
                        <div className="table__warning-content">
                          <div className="table__warning-text">{warningText}</div>
                        </div>
                      </div>
                    )}
                    {!matchParticipantsLoading && isDispute && (
                      <div className="table__warning">
                        <div className="table__warning-content">
                          <div className="table__warning-text">{warningText}</div>
                        </div>
                      </div>
                    )}
                  </>
                )
                : <>-</>
              }
            </DeadlineColumn>
            <ButtonsColumn>
              <div className="row justify-content-sm-end flex-sm-row flex-column m-0 btn-wrapper">
                {isDispute && (
                  <DisputeChatButton matchId={matchId} /> 
                )}
                {isSubmitButtonVisible && (
                  <>
                    {(!isResultSubmitted || canSubmit) ? (
                      <TableButton
                        color="blue"
                        filled
                        disabled={!canSubmit}
                        onClick={handleSubmit}
                        loading={matchResultsLoading}
                      >
                        Submit Results
                      </TableButton>
                    ) : (
                      <TableButton
                        color="blue"
                        filled
                        onClick={handleShowSubmittedResult}
                        loading={matchResultsLoading}
                      >
                        Submitted Results
                      </TableButton>
                    )}

                  </>
                )}

                <TableButton disabled={!isDispute} color="grey" filled onClick={handleShowResults}>
                  Results
                </TableButton>
                <label className="table__open" htmlFor={`open-table-${matchId}`} />
              </div>
            </ButtonsColumn>
          </BattleRow>
          <div className="row table__secondary-row-container">
            <div className="container-fluid">
              <LoadingBox loading={matchParticipantsLoading} size="average">
                {opponents.map(x => (
                  <TournamentParticipantRow key={x.Id} participantId={x.TournamentParticipantId} />
                ))}
              </LoadingBox>
            </div>
          </div>
        </div>
      </div>
      {submissionModal}
    </>
  )
}

export default ActiveBattleRow
