import { ReactNode } from 'react'
import { TipPlace } from 'components/_shared'

export type OptionTooltipIcon = 'lock' | 'info'

export interface Option<T> {
  value: T
  label: string
}

export const optionValueExtractor = <T extends unknown>(option: Option<T>) => option.value
export const optionLabelExtractor = <T extends unknown>(option: Option<T>) => option.label

export type SelectType = 'single' | 'multi'

export interface CommonSelectProps<T> {
  options: T[]
  withSearch?: boolean
  labelExtractor?: (option: T) => string
  valueExtractor?: (option: T) => string | number
  tooltipExtractor?: (option: T) => ReactNode | undefined
  disabled?: boolean
  placeHolder?: string
  type: SelectType
  loading?: boolean
  className?: string
  containerClassName?: string
  openedClassName?: string
  titleClassName?: string
  height?: number
  alwaysOpenBelow?: boolean
  optionTooltipIcon?: OptionTooltipIcon
  tipPlace?: TipPlace
  closeOnBlur?: boolean
  valid?: boolean
}

export interface SingleSelectProps<T> {
  onChange: (option: T) => void
  selected?: T
  type: 'single'
  required?: boolean
}

export interface MultiSelectProps<T> {
  onChange: (options: T[]) => void
  selected: T[]
  type: 'multi'
}

export type SelectProps<T> = CommonSelectProps<T> & (SingleSelectProps<T> | MultiSelectProps<T>)

export type OpenedState = 'none' | 'below' | 'above'
