import { DropDownTable } from 'components/_shared'
import { TournamentViewModel_kl } from 'models/tournament-viewmodel'
import React from 'react'
import { ParticipantType } from 'store/types/participants-types'
import styles from './participants.module.scss'

interface Props {
  tournament: TournamentViewModel_kl
  openPrivateParticipantModal: (v: any) => void
  participants : ParticipantType[]
}

const PrivateDefaultRow = ({ tournament, openPrivateParticipantModal,participants }: Props) => {
  return (
    <DropDownTable.Row>
      <DropDownTable.MainRow opened={true}>
        <DropDownTable.RowColumn>
          <div className="align-items-center m-0 parti-user" onClick={() => { openPrivateParticipantModal({tournament: tournament,participants: participants}) }}>
            <div className={styles.article__participantUsername} >
            Click to add participants
            </div>
          </div>
        </DropDownTable.RowColumn>
      </DropDownTable.MainRow>
    </DropDownTable.Row>
  )
}

export default PrivateDefaultRow