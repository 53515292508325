import { UserModel } from 'models/user-models'
import { AppUserLoadedAction, UserRole } from './app-types'
import { ClansMembersLoadedAction } from './clan-member-types'
import { makeActionCreator, ReduxAction, YesNoType } from './common'
import { ImageType } from './image-types'
import { LineUpsLoadedAction } from './line-up-types'
import { MatchParticipantsLoadedAction } from './match-participant-types'
import { ParticipantsLoadedAction } from './participants-types'
import { FilteredPlayersLoadedAction, MembersPageLoadedAction } from './pages/clan-page-types'
import { MembersPageClansLoadedAction, MembersPageOrganizersLoadedAction, MembersPagePlayersLoadedAction } from './pages/members-page-types'
import { ReviewsLoadedAction } from './review-types'
import { FriendsPageLoadedAction } from './pages/friends-page-types'
import { FriendRequestsLoadedAction } from './friend-requests-types'
import { NewChatMessageLoadedAction, UnreadChatsLoadedAction } from 'store/chat/chat-message/chat-message-types'
import { ChatLoadedAction, ChatsLoadedAction, GroupChatCreatedAction } from 'store/chat/chat/chat-types'
import { ChatFilteredUsersLoadedAction } from 'store/chat/chat-member/chat-member-types'
import { DisputesLoadedAction } from './dispute-types'

export interface PublicUserType {
  Id: number
  Username: string
  Role: UserRole
  Organizationname: string
  About?: string
  Ps4Id?: string
  XboxId?: string
  SteamId?: string
  BattlenetUsername?: string
  RiotUsername?: string
  Epicgameid?: string
  NintendoId?: string
  ValveUsername?: string
  IOsId?: string
  AndroidId?: string
  CountryId?: number
  RegionId?: number
  AvatarId?: number
  CoverImageId?: number
  IsPremium: boolean
  IsOnline?: boolean
  MembersCount: number
}

export interface UserType extends PublicUserType {
  Emailaddress: string
  RecCreatedDateTime: string
  Disabled: YesNoType
}

export interface ListUserResultModel {
  UsersModel: PublicUsersModel
  AllUsersCount: number
}

export interface PublicUsersModel {
  Users: PublicUserType[]
  Images: ImageType[]
}

export interface UsersModel {
  Users: UserType[]
  Images: ImageType[]
}

export interface UserStatusModel {
  UserId?: number
  Status: boolean
}

export interface CreateAdminModel {
  username: string
  email: string
  isSuperAdmin: boolean
  password: string
}

export interface State {
  byId: {
    [key: number]: PublicUserType
  }
  organizersIds: number[]
  topFriendsIdsByUserId: {
    [key: number]: {
      ids: number[]
      allFriendsCount: number
    }
  }  
  topMembersIdsByOrganizerId: {
    [key: number]: {
      ids: number[]
      allMembersCount: number
    }
  }  
  totalOnlineUsersCount: number
  isChangeTotalOnlineUsersCount: boolean
}

export const ORGANIZERS_LOADED = 'ORGANIZERS_LOADED'
interface OrganizersLoadedPayload {
  organizers: PublicUserType[]
}
export type OrganizersLoadedAction = ReduxAction<typeof ORGANIZERS_LOADED, OrganizersLoadedPayload>
export const organizersLoaded = makeActionCreator<OrganizersLoadedPayload>(ORGANIZERS_LOADED)

export const USERS_LOADED = 'USERS_LOADED'
export type UsersLoadedAction = ReduxAction<typeof USERS_LOADED, PublicUsersModel>
export const usersLoaded = makeActionCreator<PublicUsersModel>(USERS_LOADED)

interface TopFriendsLoadedPayload extends PublicUsersModel {
  userId: number
  allFriendsCount: number
}

export const TOP_FRIENDS_LOADED = 'TOP_FRIENDS_LOADED'
export type TopFriendsLoadedAction = ReduxAction<typeof TOP_FRIENDS_LOADED, TopFriendsLoadedPayload>
export const topFriendsLoaded = makeActionCreator<TopFriendsLoadedPayload>(TOP_FRIENDS_LOADED)

interface FriendDeletedPayload {
  userId: number
  friendId: number
}

export interface TopOrganizerMembersPayload {
  OrganizerId: number
  Users: PublicUserType[]
  AllMembersCount: number
}

export const TOP_ORGANIZER_MEMBERS_LOADED = 'TOP_ORGANIZER_MEMBERS_LOADED'
export type TopOrganizerMembersLoadedAction = ReduxAction<typeof TOP_ORGANIZER_MEMBERS_LOADED, TopOrganizerMembersPayload>
export const topOrganizerMembersLoaded = makeActionCreator<TopOrganizerMembersPayload>(TOP_ORGANIZER_MEMBERS_LOADED)

export const FRIEND_DELETED = 'FRIEND_DELETED'
export type FriendDeletedAction = ReduxAction<typeof FRIEND_DELETED, FriendDeletedPayload>
export const friendDeleted = makeActionCreator<FriendDeletedPayload>(FRIEND_DELETED)

interface UserUpdatedPayload {
  userId: number
  user: PublicUserType
}

export const USER_UPDATED = 'USER_UPDATED'
export type UserUpdatedAction = ReduxAction<typeof USER_UPDATED, UserUpdatedPayload>
export const userUpdated = makeActionCreator<UserUpdatedPayload>(USER_UPDATED)

export interface OnlineStatusModel {
  UserId: number
  IsOnline: boolean
}

export const ONLINE_STATUS_CHANGED = 'ONLINE_STATUS_CHANGED'
export type OnlineStatusChangedAction = ReduxAction<typeof ONLINE_STATUS_CHANGED, OnlineStatusModel>
export const onlineStatusChanged = makeActionCreator<OnlineStatusModel>(ONLINE_STATUS_CHANGED)
 
export const TOTAL_ONLINE_USERS_COUNT_LOADED = 'TOTAL_ONLINE_USERS_COUNT_LOADED'
export type TotalOnlineUsersCountLoadedAction = ReduxAction<typeof TOTAL_ONLINE_USERS_COUNT_LOADED, number>
export const totalOnlineUsersCountLoaded = makeActionCreator<number>(TOTAL_ONLINE_USERS_COUNT_LOADED)

export const USER_AVATAR_UPDATED = 'USER_AVATAR_UPDATED'
export type UserAvatarUpdatedAction = ReduxAction<typeof USER_AVATAR_UPDATED, UsersModel>
export const userAvatarUpdated = makeActionCreator<UsersModel>(USER_AVATAR_UPDATED)

export const COVER_IMAGE_UPDATED = 'COVER_IMAGE_UPDATED'
export type CoverImageUpdatedAction = ReduxAction<typeof COVER_IMAGE_UPDATED, UsersModel>
export const coverImageUpdated = makeActionCreator<UsersModel>(COVER_IMAGE_UPDATED)

export type KnownAction = MatchParticipantsLoadedAction
  | OrganizersLoadedAction
  | ParticipantsLoadedAction
  | UsersLoadedAction
  | LineUpsLoadedAction
  | TopFriendsLoadedAction
  | AppUserLoadedAction
  | FriendDeletedAction
  | UserUpdatedAction
  | TopOrganizerMembersLoadedAction
  | ReviewsLoadedAction
  | FilteredPlayersLoadedAction
  | ClansMembersLoadedAction
  | MembersPageLoadedAction
  | MembersPagePlayersLoadedAction
  | FriendsPageLoadedAction
  | MembersPageClansLoadedAction
  | MembersPageOrganizersLoadedAction
  | FriendRequestsLoadedAction
  | UnreadChatsLoadedAction
  | NewChatMessageLoadedAction
  | OnlineStatusChangedAction
  | ChatLoadedAction
  | ChatFilteredUsersLoadedAction
  | GroupChatCreatedAction
  | ChatsLoadedAction
  | TotalOnlineUsersCountLoadedAction
  | DisputesLoadedAction
  | UserAvatarUpdatedAction
  | CoverImageUpdatedAction

export const organizerValueExtractor = (organizer: PublicUserType) => organizer.Id
export const organizerLabelExtractor = (organizer: PublicUserType) => organizer.Organizationname

export const getIsOrganizer = (userRole: UserRole) => userRole === 'Organizer'
export const getIsPlayer = (userRole: UserRole) => userRole === 'Player'
export const getIsSuperAdmin = (userRole: UserRole) => userRole === 'SuperAdmin'
export const getIsStuffAdmin = (userRole: UserRole) => userRole === 'StuffAdmin'
export const getIsAdmin = (userRole: UserRole) => getIsSuperAdmin(userRole) || getIsStuffAdmin(userRole)

export const getRoleName = (userRole: UserRole): string => {
  switch (userRole) {
    case 'Organizer':
      return 'Organizer'
    case 'Player':
      return 'Player'
    case 'StuffAdmin':
      return 'Staff Admin'
    case 'SuperAdmin':
      return 'Super Admin'
    default:
      return 'Player'
  }
}

export const kombatlinkOrganizer: PublicUserType = {
  Id: 0,
  Username: 'Kombatlink',
  Organizationname: 'Kombatlink',
  Role: 'Organizer',
  IsPremium: true,
  MembersCount: 0,
}


export const getCurrentUser = (): UserModel => {
  if (JSON.parse(localStorage.getItem('user'))) {
    return JSON.parse(localStorage.getItem('user')) as UserModel
  }
  return {} as UserModel
}

export const getUserProfileUrl = (userId: number) => {
  return `/player/${userId}`
}

export const getOrganizerProfileUrl = (userId: number) => {
  return `/public-profile-view/${userId}`
}

export const isEqualRoles = (userRole: UserRole, requiredRole: UserRole) => {
  if (getIsAdmin(requiredRole)) {
    return getIsAdmin(userRole)
  }

  return userRole === requiredRole
}

export const getPlayerRank = (totalexppoints?: number): string => {
  if(totalexppoints === null || totalexppoints === undefined || totalexppoints <= 10000)
  {
    return 'RECRUIT'
  }
  else if(totalexppoints > 10000 && totalexppoints <= 20000)
  {
    return 'IRON'
  }
  else if(totalexppoints > 20000 && totalexppoints <= 50000)
  {
    return 'BRONZE'
  }
  else if(totalexppoints > 50000 && totalexppoints <= 100000)
  {
    return 'SILVER'
  }
  else if(totalexppoints > 100000 && totalexppoints <= 200000)
  {
    return 'GOLD'
  }
  else if(totalexppoints > 200000 && totalexppoints <= 400000)
  {
    return 'PLATINUM'
  }
  else if(totalexppoints > 400000 && totalexppoints <= 800000)
  {
    return 'MASTER'
  }
  else{
    return 'IMMORTAL'
  }
}
