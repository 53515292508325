import React, { ReactNode } from 'react'
import { ButtonLink } from 'components/_shared'
import styles from './HeaderButton.module.scss'
import { cn } from 'utils'
import { ButtonColor } from '../Button'

type displayType = 'always' | 'small-screen'

interface HeaderButtonProps {
  children: ReactNode
  clasName?: string
  display?: displayType
  onClick?: () => void
  to?: string
  color?: ButtonColor
  loading?: boolean
  disabled?: boolean
}

export const HeaderButton = ({
  children,
  clasName,
  onClick,
  display = 'small-screen',
  to = '#',
  color='blue',
  loading,
  disabled,
}: HeaderButtonProps) => {
  return (
    <div className={cn('col-auto p-0', styles.headerButton)}>
      <ButtonLink disabled={disabled} loading={loading} to={to} color={color} className={cn(styles.articleOpenSelects, display === 'small-screen' && styles.articleOpenSelectsSmallScreen, clasName)} filled onClick={onClick}>{children}</ButtonLink>
    </div>
  )
}
