import { SliderTournamentCover } from 'components/_shared'
import React from 'react'

import styles from './Slider.module.scss'
import { cn } from 'utils'
import { ContainerName } from 'components/_hooks'

interface Props {
  coverId: number
  index: number
  width: number
  activeIndex: number
  title?:string
  autoPlay : boolean|undefined
  isStopMouseEvent : boolean|undefined
  containerName?:ContainerName
}

const SliderImage = ({activeIndex, coverId, index, width,title,autoPlay,isStopMouseEvent,containerName}: Props) => {
  
  const isActive = activeIndex === index
  const isPrev1 = index + 1 === activeIndex 
  const isPrev2 = index + 2 === activeIndex 

  const isNext = index > activeIndex

  return (
    <div
      className={cn(styles.sldrNavI, styles.slickSlide, isActive && styles.slickCurrent,
        isActive && styles.slickActive, isPrev1 && styles.sldrNavIPrev1,
        isPrev2 && styles.sldrNavIPrev2, isNext && styles.sldrNavINext,
        'home-popup-slide'
      )} 
      tabIndex={isActive ? 0 : -1}
      style={{
        width: `${width}px`,
      }} 
      data-slick-index={index} 
      aria-hidden={isActive ? 'false' :'true'}
    >
      
      {title &&(
        <div className={cn(styles.fix_height,styles.slider_title)}>{title}</div>
      )}

      <SliderTournamentCover containerName={containerName} coverId={coverId} isActive={isActive} autoPlay={autoPlay} isStopMouseEvent={isStopMouseEvent}  />
    </div>   
  )
}

export default SliderImage
