/* eslint-disable react/jsx-no-undef */
import React, { useEffect, useMemo } from 'react'
import styles from '../PlayerPage.module.scss'
import { Button, ButtonLink, LoadingBox, UserAvatar } from 'components/_shared'
import { cn } from 'utils'
import { getIsAdmin, getIsPlayer } from 'store/types/user-types'
import { makeGetIsTopFriendsLoading, makeGetIsPlayerProfileByUserIdLoading, makeGetTopFriendsIds, makeGetIsUpdateAvatarLoading, makeGetFriendsIds } from 'store/selectors/user-selectors'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from 'store/types/common'
import { loadPlayerProfileByUserId, loadTopFriends, loadUser, useRemoveFriend, useUpdateAvatar } from 'store/logic/user-logic'
import { makeGetFriendRequestByUserId, makeGetIsFriendRequested } from 'store/selectors/friend-request-selectors'
import { ContainerName, useAppSelector, useAppUser, useClan, useClanMember, useDialog, useMemberClan } from 'components/_hooks'
import { getClanProfileUrl } from 'store/types/clan-types'
import { UserFriend } from './UserFriend'
import { ProfileCard } from 'components/_shared/ProfileCard/ProfileCard'
import { ClanInvitation } from './ClanInvitation'
import { makeGetIsLoadClanMemberLoading } from 'store/selectors/clan-member-selectors'
import { UserRating } from 'components/_shared/UserRating/UserRating'
import { loadFriendRequests, useSendFriendRequest } from 'store/logic/friend-request-logic'
import { FriendRequest } from './FriendRequest'
import { loadPageFriends } from 'store/logic/pages/friends-page-logic'
import { makeGetPlayerProfileByUser } from 'store/selectors/player-profile-selectors'
import { loadClanMember } from 'store/logic/clan-member-logic'
import { useLoadClanInvitations } from 'store/logic/clan-invitation-logic'
import { useOpenUserChat } from 'store/chat/chat/chat-hooks'
import { UpdateImageModal } from 'components/_modals'
import { ImageType, ImageTypeEnum, MediaType } from 'store/types/image-types'
interface LeftPlayerMenuProps {
  isPublicView: boolean
  userId: number
  clanInvitationsIds?: number[]
  isEditing?: boolean
  hideAllFriendsButton?: boolean
  hideAllReviewLink?: boolean
  incomingRequestsIds?: number[]
}

export const LeftPlayerMenu = ({
  isPublicView,
  userId, 
  clanInvitationsIds,
  isEditing = false,
  hideAllFriendsButton = false,
  hideAllReviewLink = false,
  incomingRequestsIds,
}: LeftPlayerMenuProps) => {
  const dispatch = useDispatch()
  const appUser = useAppUser()
  const isAdmin = appUser ? getIsAdmin(appUser?.Role) : false

  const loadClanInvitations = useLoadClanInvitations(userId)
  const openUserChat = useOpenUserChat()

  const sendFriendRequest = useSendFriendRequest(userId)
  const removeFriend = useRemoveFriend()
  const updateAvatar = useUpdateAvatar(userId)

  const getIsLoggedInUserFriendsLoading = useMemo(makeGetIsTopFriendsLoading, [])
  const getLoggedInUserFriendsIds = useMemo(makeGetFriendsIds, [])
  const getIsFriendRequest = useMemo(makeGetIsFriendRequested, [])
  const getIsUserFriendsLoading = useMemo(makeGetIsTopFriendsLoading, [])
  const getIsPlayerProfileByUserIdLoading = useMemo(makeGetIsPlayerProfileByUserIdLoading, [])
  const getFriendsIds = useMemo(makeGetTopFriendsIds, [])
  const getIsLoadClanMemberLoading = useMemo(makeGetIsLoadClanMemberLoading, [])
  const getFriendRequestByUserId = useMemo(makeGetFriendRequestByUserId, [])
  const getPlayerProfile = useMemo(makeGetPlayerProfileByUser, [])
  const getIsUpdateAvatarLoading = useMemo(makeGetIsUpdateAvatarLoading, [])
  
  const isLoggedInUserFriendsLoading = useSelector((state: ApplicationState) => getIsLoggedInUserFriendsLoading(state, { userId: appUser?.Id }))
  const isPlayerProfileByUserIdLoading = useSelector((state: ApplicationState) => getIsPlayerProfileByUserIdLoading(state, { userId }))
  const loggedInUserFriendsIds = useSelector((state: ApplicationState) => getLoggedInUserFriendsIds(state, { userId: appUser?.Id }))
  const isFriendRequested = useSelector((state: ApplicationState) => getIsFriendRequest(state, { friendId: userId }))
  const isUserFriendsLoading = useSelector((state: ApplicationState) => getIsUserFriendsLoading(state, { userId }))
  const isLoadClanMemberLoading = useSelector((state: ApplicationState) => getIsLoadClanMemberLoading(state, { userId }))
  const isUpdateAvatarLoading = useAppSelector(state => getIsUpdateAvatarLoading(state, { userId }))
  const friendsIds = useSelector((state: ApplicationState) => getFriendsIds(state, { userId }))
  const friendRequestByUserId = useSelector((state: ApplicationState) => getFriendRequestByUserId(state, {userId}))
  const playerProfile = useSelector((state: ApplicationState) => getPlayerProfile(state, { userId }))

  const friendRequests = isPublicView ? (incomingRequestsIds?.filter(x => x === friendRequestByUserId?.Id) || []) : incomingRequestsIds
  
  const isFriend = useMemo(() => {
    return appUser !== undefined
      && isPublicView
      && loggedInUserFriendsIds.some(id => id === userId)
  }, [isPublicView, loggedInUserFriendsIds, appUser, userId])

  const handleRemovedFriendClick = async () => {
    await removeFriend(appUser?.Id, userId)
    dispatch(loadPageFriends(userId, {page: 0, pageSize: 12 }))
    dispatch(loadPageFriends(appUser?.Id, {page: 0, pageSize: 12 }))
  }

  const handleAddFriendClick = async () => {
    await sendFriendRequest({
      FromUserId: appUser?.Id,
      ToUserId: userId,
    })
  }

  const handleSendMessageClick = () => {
    openUserChat(userId)
  }

  const handleSaveImage = async (image: ImageType) => {
    await updateAvatar(image.Id)
  }

  const getImageType = (mediaType: MediaType): ImageTypeEnum => {
    return ImageTypeEnum.Avatar
  }

  const [updateImageModal, openUpdateImageModal] = useDialog(closeDialog => (
    <UpdateImageModal
      onClose={closeDialog}
      header="Update the Avatar"
      dropText="Drag a picture to this window"
      fileTypes={['image/jpeg', 'image/jpg', 'image/png']}
      recommendedText="Note: Please choose a JPG, Png file from your computer"
      getImageTypeCallback={getImageType}
      saveImage={handleSaveImage}
      aspectRatioType="avatar"
      crop
      round
    />
  ))
  
  const { clan } = useMemberClan(userId)
  const { clan: myClan } = useMemberClan(appUser?.Id)
  const isMyClanMember = !!clan && clan.Id == myClan?.Id && isPublicView
 
  const clanWinRate = clan?.TotalMatches ? Math.round((clan?.WinnedMatches/clan?.TotalMatches) * 100) : 0
  const playerProfileWinRate = playerProfile?.TotalMatches ? Math.round((playerProfile?.WinnedMatches/playerProfile?.TotalMatches) * 100) : 0

  const canSendFriendRequest = isPublicView && appUser && appUser.Id != userId && getIsPlayer(appUser.Role)
  const canCreateClan = !clan && !isEditing && appUser &&  appUser.Id == userId

  const rating = +(playerProfile?.Rating ?? 0).toFixed(1)

  useEffect(() => {
    if (userId) {
      if (appUser?.Id !== userId) {
        dispatch(loadUser(userId))
        dispatch(loadTopFriends(userId))
      }
      if (appUser) {
        dispatch(loadTopFriends(appUser.Id))
      }
      dispatch(loadPlayerProfileByUserId(userId))
      dispatch(loadClanMember(userId))
      if (appUser && !isPublicView) {
        void loadClanInvitations()
      }
      if (appUser) {
        dispatch(loadFriendRequests())
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appUser, dispatch, userId])

  const canSendMessage = isFriend || isAdmin || isMyClanMember

  return (
    <>
      <div className={cn(styles.left, 'mb-2')}>
        <LoadingBox size="big" loading={isUpdateAvatarLoading}>
          <UserAvatar containerName={ContainerName.AvatarsProfile} userId={userId} placeholder="noavatar" className={styles.avatar} />
        </LoadingBox>
        {
          isUpdateAvatarLoading && (
            <div className={styles.emptyBlock}></div>
          )
        }
        {isEditing && (
          <Button disabled={isUpdateAvatarLoading} color="blue" filled onClick={openUpdateImageModal} className="w-100 mt-2" low>Change Avatar</Button>
        )}
        {canCreateClan && <ButtonLink to="/clan" disabled={isUpdateAvatarLoading} color="pink" filled className="w-100 mt-2" low>Create clan</ButtonLink>}
        {appUser?.Id !== userId && canSendMessage && (
          <div className="mt-3">
            <Button color="blue" filled onClick={handleSendMessageClick} className="w-100" low>Send message</Button>
          </div>
        )}
        {canSendFriendRequest &&  (
          <div className="mt-3">
            <LoadingBox loading={isLoggedInUserFriendsLoading} size="average">
              {isFriend
                ? <Button color="red" filled onClick={handleRemovedFriendClick} className="w-100" low>Unfriend</Button>
                : friendRequests?.length === 0 && 
                  <Button color="green" filled disabled={isFriendRequested} onClick={handleAddFriendClick} className="w-100" low>{isFriendRequested ? 'Friend Request Sent' : 'Send Friend Request'}</Button>
              }
            </LoadingBox>
          </div>
        )}
        { friendRequests && friendRequests.length > 0 && (
          <>
            <div className={cn(styles.leftTile, 'mt-3', styles.greenBorder)}>Friend requests</div>
            {friendRequests.map(id => <FriendRequest key={id} id={id}/>)}
          </>
        )}
        {!isPublicView && clanInvitationsIds && clanInvitationsIds.length > 0 && (
          <>
            <div className={cn(styles.leftTile, 'mt-3', styles.greenBorder)}>Clan invites</div>
            {clanInvitationsIds.map(id => <ClanInvitation key={id} id={id}/>)}
          </>
        )}
        {clan && (
          <>
            <div className={cn(styles.leftTile, 'mt-3', styles.greenBorder)}>Registered clan</div>
            <LoadingBox loading={isLoadClanMemberLoading} size="average">
              <ProfileCard 
                name={clan?.Name} 
                imageId={clan?.AvatarId} 
                link={getClanProfileUrl(clan?.Id)} 
                label={`${clan.MembersCount} Members`}
                className="mt-2"
                fullSize
                containerName={ContainerName.AvatarsNormal}
              />
            </LoadingBox>
          </>
        )}
        <div className={cn(styles.leftTile, 'mt-3', styles.greenBorder)}>Friend list</div>
        <LoadingBox loading={isUserFriendsLoading} size="average">
          {friendsIds.length > 0
            ? (
              <>
                <div className={cn(styles.friends, 'd-flex flex-wrap')}>
                  {friendsIds.map(id => (<UserFriend containerName={ContainerName.AvatarsNormal} key={id} userId={id} />))}
                </div>
                {!hideAllFriendsButton && (
                  <ButtonLink to={`/player/${userId}/friends`} color="white" className={cn('w-100 mt-2', styles.button)} low>View all friends</ButtonLink>
                )}
              </>
            )
            : (
              <div className={cn(styles.leftValue, 'mt-2 ml-0')}>No friends</div>
            )
          }
        </LoadingBox>
        <div className={cn(styles.userStats, 'mt-3')}>
          <div className={cn(styles.leftTile, styles.purple)}><span>User Stats</span></div>
          <LoadingBox loading={isPlayerProfileByUserIdLoading} size="average">
            <ul>
              <li>
                <span className={styles.statTitle}>Solo Win Ratio</span>
                <span className={styles.data}>{playerProfileWinRate}%</span>
                <span className={styles.bar}><span
                  style={{width: `${playerProfileWinRate}%`}}
                  className={styles.ratio}
                />
                </span>
              </li>
              <li>
                <span className={styles.statTitle}>Solo Match</span>
                <span className={styles.data}>{playerProfile?.TotalMatches || 0} Battles</span>
              </li>
              <li>
                <span className={styles.statTitle}>Solo EXP Points</span>
                <span className={styles.data}>{playerProfile?.TotalExperience || 0} points</span>
              </li>
              {
                clan && (
                  <>
                    <li>
                      <span className={styles.statTitle}>Clan Win Ratio</span>
                      <span className={styles.data}>{clanWinRate}%</span>
                      <span className={styles.bar}><span
                        style={{width: `${clanWinRate}%`}}
                        className={styles.ratio}
                      />
                      </span>
                    </li>
                    <li>
                      <span className={styles.statTitle}>Clan Match</span>
                      <span className={styles.data}>{clan?.TotalMatches || 0} Battles</span>
                    </li>
                    <li>
                      <span className={styles.statTitle}>Clan EXP Points</span>
                      <span className={styles.data}>{clan?.TotalExpPoints || 0} points</span>
                    </li>
                  </>
                )
              }
            </ul>
          </LoadingBox>
        </div>
        
        <div className={cn(styles.leftTile, styles.blueBorder, 'mt-3')}><span>Review Player</span></div>
        <LoadingBox loading={isPlayerProfileByUserIdLoading} size="average">
          <UserRating rating={rating} reviewCount={playerProfile?.ReviewsCount ?? 0} userId={userId}/>
          {!hideAllReviewLink && <ButtonLink to={`/user/${userId}/review`} color="white" className={cn('w-100 mt-2', styles.button)} low>View all reviews</ButtonLink>}
        </LoadingBox>
      </div>
      {updateImageModal}
    </>
  )
}
