import { ContainerName, useGetImageSize } from 'components/_hooks'
import { Img, LoadingBox, LoadingSize } from 'components/_shared'
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadImage, reLoadImage } from 'store/logic/image-logic'
import { makeGetImage, makeGetIsImageLoading } from 'store/selectors/image-selectors'
import { ApplicationState } from 'store/types/common'
import { cn } from 'utils'

interface ClanAvatarProps {
  imageId: number,
  containerName?: ContainerName,
  className?: string,
  isReload?: boolean
}

interface SelfLoadedClanAvatarProps extends ClanAvatarProps {
  size?: LoadingSize,
}

export const ClanAvatar = ({ imageId, containerName, size, className, isReload }: SelfLoadedClanAvatarProps) => {
  const getImageSize = useGetImageSize(containerName)
  const dispatch = useDispatch()
  const getCover = useMemo(makeGetImage, [])
  const cover = useSelector((state: ApplicationState) => getCover(state, { imageId: imageId }))
  const getContainerImageSize = useGetImageSize(containerName)
  const getIsImageLoading = useMemo(makeGetIsImageLoading, [])
  const isImageLoading = useSelector((state: ApplicationState) => getIsImageLoading(state, { imageId }))
  useEffect(() => {
    if (imageId) {
      if (isReload) {
        dispatch(reLoadImage(imageId))
      }
      else {
        dispatch(loadImage(imageId))
      }
    }
  }, [imageId, dispatch])
  return (
    <LoadingBox size={size || 'small'} loading={isImageLoading}>
      <Img parentContainerSize={getImageSize} imageId={imageId} placeholder="user" className={className} />
    </LoadingBox>
  )
}
