import { arrayToObject } from 'utils'

interface IWithId {
  Id: number
}

interface IWithById<T extends IWithId> {
  byId: {
    [id: number]: T
  }
}

export const calcById = <T extends IWithId>(state: IWithById<T>, models: T[]): { [id: number]: T } => {
  return {
    ...state.byId,
    ...arrayToObject(models, x => x.Id),
  }
}

export const calcByIdWithoutIds = <T extends IWithId>(state: IWithById<T>, ids: number[]): { [id: number]: T } => {
  const newById = {
    ...state.byId,
  }

  ids.forEach(id => {
    newById[id] = undefined
  })

  return newById
}
