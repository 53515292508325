import { ConsoleOption } from 'consts/ConsoleType'
import { ClansMembersModel } from '../clan-member-types'
import { makeActionCreator, ReduxAction } from '../common'
import { GameType } from '../game-types'
import { RegionType } from '../region-types'
import { ListUserResultModel } from '../user-types'

export const membersPageSize = 8

export const defaultFilters: PlayersFilters = {
  name: '',
  games: [],
  consoles: [],
  regions: [],
  page: 0,
}

export interface PlayersFilters {
  name: string
  games: GameType[]
  consoles: ConsoleOption[]
  regions: RegionType[]
  page: number
}

export interface ListPlayersResultModel {
  AllPlayersCount: number
  Model: ClansMembersModel
}

export interface MembersPageState {
  allPlayersCount: number
  playersFilters: PlayersFilters
  playersIds: number[]

  allClansCount: number
  clansFilters: PlayersFilters
  clansIds: number[]

  allOrganizersCount: number
  organizersFilters: PlayersFilters
  organizersIds: number[]
}

export const MEMBERS_PAGE_PLAYERS_LOADED = 'memberspage/players/loaded'
export type MembersPagePlayersLoadedAction = ReduxAction<typeof MEMBERS_PAGE_PLAYERS_LOADED, ListPlayersResultModel>
export const membersPagePlayersLoaded = makeActionCreator<ListPlayersResultModel>(MEMBERS_PAGE_PLAYERS_LOADED)

export const MEMBERS_PAGE_PLAYERS_FILTERS_CHANGED = 'memberspage/playersFilters/changed'
export type MembersPagePlayersFiltersChangedAction = ReduxAction<typeof MEMBERS_PAGE_PLAYERS_FILTERS_CHANGED, PlayersFilters>
export const membersPagePlayersFiltersChanged = makeActionCreator<PlayersFilters>(MEMBERS_PAGE_PLAYERS_FILTERS_CHANGED)

export const MEMBERS_PAGE_CLANS_LOADED = 'memberspage/clans/loaded'
export type MembersPageClansLoadedAction = ReduxAction<typeof MEMBERS_PAGE_CLANS_LOADED, ListPlayersResultModel>
export const membersPageClansLoaded = makeActionCreator<ListPlayersResultModel>(MEMBERS_PAGE_CLANS_LOADED)

export const MEMBERS_PAGE_CLANS_FILTERS_CHANGED = 'memberspage/clansFilters/changed'
export type MembersPageClansFiltersChangedAction = ReduxAction<typeof MEMBERS_PAGE_CLANS_FILTERS_CHANGED, PlayersFilters>
export const membersPageClansFiltersChanged = makeActionCreator<PlayersFilters>(MEMBERS_PAGE_CLANS_FILTERS_CHANGED)

export const MEMBERS_PAGE_ORGANIZERS_LOADED = 'memberspage/organizers/loaded'
export type MembersPageOrganizersLoadedAction = ReduxAction<typeof MEMBERS_PAGE_ORGANIZERS_LOADED, ListUserResultModel>
export const membersPageOrganizersLoaded = makeActionCreator<ListUserResultModel>(MEMBERS_PAGE_ORGANIZERS_LOADED)

export const MEMBERS_PAGE_ORGANIZERS_FILTERS_CHANGED = 'memberspage/organizersFilters/changed'
export type MembersPageOrganizersFiltersChangedAction = ReduxAction<typeof MEMBERS_PAGE_ORGANIZERS_FILTERS_CHANGED, PlayersFilters>
export const membersPageOrganizersFiltersChanged = makeActionCreator<PlayersFilters>(MEMBERS_PAGE_ORGANIZERS_FILTERS_CHANGED)

export type KnownAction = MembersPagePlayersLoadedAction
  | MembersPagePlayersFiltersChangedAction
  | MembersPageClansLoadedAction
  | MembersPageClansFiltersChangedAction
  | MembersPageOrganizersLoadedAction
  | MembersPageOrganizersFiltersChangedAction
