import React from 'react'
import { MatchParticipantModel } from 'store/types/match-participant-types'
import { ParticipantType } from 'store/types/participants-types'
import LineUpsRow from './LineUpsRow'
import './LineupsTab.scss'

interface OwnProps {
  matchParticipants: MatchParticipantModel[]
  participants: ParticipantType[]
}

const LineupsTab = ({ matchParticipants, participants }: OwnProps) => {
  const clanParticipants = React.useMemo(() => {
    return participants.filter(p => !!p.ClanId &&  matchParticipants.findIndex(mp => mp.TournamentParticipantId == p.Id) >= 0)
  }, [matchParticipants, participants])
  return (
    <div className="m-checked_tabs_tab active" data-tab="2">
      <div className="m-checked_tabs_tab_inn">
        {clanParticipants?.length > 0 ? (
          <div className="m-checked_list">
            {clanParticipants.map(participant => (
              <LineUpsRow participant={participant} key={participant.Id} />
            ))}
          </div>
        ) : (
          <p className="m-checked_tabs_tab_empty">This match does not have any lineups.</p>
        )}
      </div>
    </div>
  )
}

export default LineupsTab
